import bind from 'bind-decorator';
import * as React from 'react';

interface Props {
    /** The label for this text input. */
    label: React.ReactNode;
    hideLabel?: boolean;
    /**
     * A grey description that sits below the textarea, offering a more detailed explanation
     * of what the input does. Linked to the <textarea> via "aria-describedby"
     */
    description?: React.ReactNode;
    // No children for this component
    children?: never;
    value: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    required: boolean;
    showErrors: boolean;
}

/**
 * A <textarea> with a label and optionally a description below.
 *
 * Matches the design at
 * https://app.zeplin.io/project/5b7d78e144f1281a779b7799/screen/5beec46df9d5b53eec4b6479
 *
 * Expands to the full width of the container it's in, but you should set the
 * height you want using CSS, if it's other than the default.
 *
 * TODO: add the optional "400 characters left" part?
 */
export class TextBox extends React.PureComponent<Props> {
    private id: string;

    public static defaultProps = {
        required: false,
        showErrors: false,
    };

    constructor(props: Props) {
        super(props);
        this.id = uniqueId();
    }

    public render() {
        const isError = this.props.required && this.props.showErrors && !this.props.value;
        const className = `lx-textbox ${isError ? 'lx-textbox-error' : ''}`;

        const textareaId = this.id;
        const descriptionId = `${this.id}Description`;

        return (
            <div className={className}>
                <label
                    htmlFor={textareaId}
                    className={`lx-textbox-label ${this.props.hideLabel ? 'sr-only' : ''}`}
                >
                    {this.props.label}{this.props.required ? '*' : null}
                </label>
                <textarea
                    id={textareaId}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    value={this.props.value}
                    aria-describedby={this.props.description ? descriptionId : undefined}
                />
                {
                    this.props.description &&
                    <p id={descriptionId} className='lx-textbox-description'>{this.props.description}</p>
                }
            </div>
        );
    }

    @bind private onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }
}

let lastId = 0;
/** Helper function to generate a unique HTML ID. */
function uniqueId(): string { return `TextBox${++lastId}`; }
