// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * 
     * @type {number}
     * @memberof Skill
     */
    readonly id?: number;
    /**
     * The title of the skill.
     * @type {string}
     * @memberof Skill
     */
    title: string;
    /**
     * Description of the skill.
     * @type {string}
     * @memberof Skill
     */
    description: string;
}

export function SkillFromJSON(json: any): Skill {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': json['description'],
    };
}

export function SkillToJSON(value?: Skill): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
    };
}


