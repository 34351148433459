import { Action } from 'redux';

import { LXThunkAction } from 'global/types';

export enum Types {
    // this only triggers a reload for the current user's own avatar image
    RELOAD_OWN_AVATAR_IMAGE = 'RELOAD_OWN_AVATAR_IMAGE',
}

export interface ReloadAvatarImage extends Action {
    readonly type: Types.RELOAD_OWN_AVATAR_IMAGE;
}

export type ActionTypes = ReloadAvatarImage;

// action creator to trigger a reload of the current user's avatar
export const reloadOwnAvatarImage = (): LXThunkAction<void> => {
    return async (dispatch, getState) => {
        dispatch({type: Types.RELOAD_OWN_AVATAR_IMAGE});
    };
};
