import { Icon, IconSymbol } from 'elements';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';
import Skeleton from 'react-loading-skeleton';

interface Props {
    disabled?: boolean;
    iconName?: IconSymbol;
    iconSize?: string;
    label: MessageDescriptor;
    labelValues?: {};
    onClick(): void;
    showSkeleton?: boolean;
}

export class UserCardButton extends React.PureComponent<Props> {
    public render() {
        const iconSize = this.props.iconSize || '18';
        return <button
            className={
                `unstyled action-button ` +
                `${this.props.disabled ? 'under-construction' : ''}`}
            disabled={this.props.disabled}
            onClick={this.props.onClick}>
            {this.props.iconName
                ? <div className='card-button-icon'>{this.props.showSkeleton ? <Skeleton circle={true} /> : <Icon name={this.props.iconName} zoom={iconSize} />}</div>
            : null}
            <div className='card-button-text'>
                {this.props.showSkeleton ? <Skeleton /> : <WrappedMessage
                    message={this.props.label}
                    values={this.props.labelValues}
                />}
            </div>
        </button>;
    }
}
