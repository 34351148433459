import * as React from 'react';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface Props {
    embed: any;
    type: string;
}

export class MentorshipEmbedResponded extends React.PureComponent<Props> {

    public render() {
        const embed = this.props.embed;
        const type = this.props.type;

        if (type === 'canceled.mentor') {
            return (
                <div className='mentorship-embed-responded-canceled'>
                    <WrappedMessage
                        message={messages.canceledByMenteeMessageMentor}
                        values={{fullName: embed.mentorship.mentee.full_name}}
                    />
                </div>
            );
        } else if (type === 'canceled.mentee') {
            return (
                <div className='mentorship-embed-responded-canceled'>
                    <WrappedMessage
                        message={messages.canceledByMenteeMessageMentee}
                        values={{fullName: embed.mentorship.mentor.full_name}}
                    />
                </div>
            );
        } else if (type === 'accepted.mentor') {
            return (
                <div className='mentorship-embed-responded-accepted'>
                    <WrappedMessage
                        message={messages.acceptedMessageMentor}
                    />
                </div>
            );
        } else if (type === 'accepted.mentee') {
            return (
                <div className='mentorship-embed-responded-accepted'>
                    <WrappedMessage
                        message={messages.acceptedMessageMentee}
                        values={{fullName: embed.mentorship.mentor.full_name}}
                    />
                </div>
            );
        } else if (type === 'refused.mentor') {
            return (
                <div className='mentorship-embed-responded-refused'>
                    <WrappedMessage
                        message={messages.refusedMessageMentor}
                    />
                </div>
            );
        } else if (type === 'refused.mentee') {
            return (
                <div className='mentorship-embed-responded-refused'>
                    <WrappedMessage
                        message={messages.refusedMessageMentee}
                        values={{fullName: embed.mentorship.mentor.full_name}}
                    />
                </div>
            );
        } else if (type === 'stopped-by-mentor.mentor') {
            return (
                <div className='mentorship-embed-responded-stopped'>
                    <WrappedMessage
                        message={messages.stoppedByMentorMessageMentor}
                        values={{
                            fullName: embed.mentorship.mentee.full_name,
                            reason: embed.mentorship.reason,
                        }}
                    />
                </div>
            );
        } else if (type === 'stopped-by-mentor.mentee') {
            return (
                <div className='mentorship-embed-responded-stopped'>
                    <WrappedMessage
                        message={messages.stoppedByMentorMessageMentee}
                        values={{
                            fullName: embed.mentorship.mentor.full_name,
                            reason: embed.mentorship.reason,
                        }}
                    />
                </div>
            );
        } else if (type === 'stopped-by-mentee.mentor') {
            return (
                <div className='mentorship-embed-responded-stopped'>
                    <WrappedMessage
                        message={messages.stoppedByMenteeMessageMentor}
                        values={{
                            fullName: embed.mentorship.mentee.full_name,
                            reason: embed.mentorship.reason,
                        }}
                    />
                </div>
            );
        } else if (type === 'stopped-by-mentee.mentee') {
            return (
                <div className='mentorship-embed-responded-stopped'>
                    <WrappedMessage
                        message={messages.stoppedByMenteeMessageMentee}
                        values={{
                            fullName: embed.mentorship.mentor.full_name,
                            reason: embed.mentorship.reason,
                        }}
                    />
                </div>
            );
        }
        return ( null );
    }
}
