// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerQualification
 */
export interface CareerQualification {
    /**
     * 
     * @type {string}
     * @memberof CareerQualification
     */
    career: string;
    /**
     * 
     * @type {string}
     * @memberof CareerQualification
     */
    qualification: string;
    /**
     * Percentage of professionals with this qualification.
     * @type {string}
     * @memberof CareerQualification
     */
    percentage: string;
    /**
     * Short description for the career qualification.
     * @type {string}
     * @memberof CareerQualification
     */
    description?: string;
}

export function CareerQualificationFromJSON(json: any): CareerQualification {
    return {
        'career': json['career'],
        'qualification': json['qualification'],
        'percentage': json['percentage'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CareerQualificationToJSON(value?: CareerQualification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'career': value.career,
        'qualification': value.qualification,
        'percentage': value.percentage,
        'description': value.description,
    };
}


