import {ContainerOne} from 'elements/components/Containers';
import * as React from 'react';

export interface ItemSectionProps extends React.PropsWithChildren {
    title?: React.ReactNode;
    isMandatory?: boolean;
    sectionName: string;
    toggleSwitch?: JSX.Element;
    extraClasses?: string;
}

/**
 * Encapsulates components in a section with a title, designed to be used in
 * edit item pages. For example, the box around the Individual Authors edit field
 * (https://app.zeplin.io/project/5d8d3ae41e40c461ec49a80f/screen/5d8e1eb6debace615bc236d6).
 */
export class ItemSection extends React.PureComponent<ItemSectionProps> {
    public renderTitle() {
        if (this.props.title !== undefined) {
            return (
                <div className='item-section-title'>
                    <h2>{this.props.title}{this.props.isMandatory ? '*' : null}</h2>
                    <div className='item-section-title-toggle-switch'>
                        {this.props.toggleSwitch}
                    </div>
                </div>
            );
        }
        return (null);
    }

    public render() {
        return (
            <div
                className={`item-section ${this.props.extraClasses ? this.props.extraClasses : ''}`}
                data-section={this.props.sectionName}
            >
                <ContainerOne canOverflow={true}>
                    {this.renderTitle()}
                    <div className='item-section-content'>
                        {this.props.children}
                    </div>
                </ContainerOne>
            </div>
        );
    }
}

/**
 * ItemSection alternative for cases where we don't want the the fancy styles
 * with a title. Use to ensure we have a consistent padding, etc. between
 * items. (eg. document editor, rich text editor sections.
 */
export class ThinItemSection extends React.PureComponent<React.PropsWithChildren> {
    public render() {
        return (
            <div className='thin-item-section'>
                {this.props.children}
            </div>
        );
    }
}
