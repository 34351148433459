import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from 'elements/components/Icons';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { FileFieldProps } from './models';
import { intl } from 'i18n';

/**
 * A widget that allows the user to specify a document, by dragging-and-dropping
 * or clicking or entering a URL.
 *
 * Matches the design at
 * https://app.zeplin.io/project/5d8d3ae41e40c461ec49a80f/screen/5d8e125f9289ba61a4d8f30c
 */
export const DocumentField: React.FunctionComponent<FileFieldProps> = ({
    currentFile,
    currentUrl,
    allowUpload = true,
    allowUrlInput = false,
    onChange,
    required,
    showErrors,
    docType,
    label = <WrappedMessage message={messages.defaultDocumentLabel} />,
}) => {
    const id = uniqueId();

    // Is there currently a file set?
    const hasFile = Boolean(currentFile || currentUrl);
    // And what is its filename?
    const currentFileName = (
        currentFile ? currentFile.name :
        currentUrl ? currentUrl.split('/').pop() :
        ''
    );

    const isError = required && showErrors && !hasFile;
    const fullLabel = label ? <>{label}{required ? '*' : ''}</> : null;

    // If the user is entering in a new URL (allowUrlInput is true), this state variable
    // will store the new URL:
    const [newUrl, setNewUrl] = React.useState('');

    // When the "Delete" button gets clicked:
    const onDeleteClick = () => { onChange(/* newFile: */ undefined, /* newUrl: */ ''); };

    // Our handler for drag-and-drop / file uploads.
    // It's wrapped in useCallback as an optimization - see https://reactjs.org/docs/hooks-reference.html#usecallback
    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        onChange(acceptedFiles[0], /* newUrl: */ '');
    }, [onChange]);

    // Build the dropzone. We set 'noClick' if there is already an file,
    // so that clicking on the file won't open the file upload dialog
    // (users can click on the "Change" button to do that).
    // Note that due to the rules of React hooks, we must always call this
    // useDropzone() method every time, even it allowUpload is false.
    const dropZone = useDropzone({onDrop, accept: 'application/pdf', multiple: false, noClick: hasFile});
    const dropZoneRootProps = allowUpload ? dropZone.getRootProps() : {};
    const fileInputElement = allowUpload ? <input {...dropZone.getInputProps()} /> : null;
    const isDragActive = allowUpload && dropZone.isDragActive;

    return <>
        {fullLabel && <p id={`${id}Label`} className={'lx-file-field-label'}>{fullLabel}</p>}
        <div
            className={`lx-file-field ${isError ? 'lx-file-field-error' : ''}`}
            id={id}
            aria-labelledby={`${id}Label`}
        >
            {hasFile ?
                // There is currently a file set:
                <>
                    <div className='lx-file-header-image'>
                        {docType === 'googleDoc' ?
                            <Icon name={'file-google-upload'}/> :
                            <Icon name={'file-pdf-upload'}/>
                        }
                    </div>
                    {/* Toolbar to allow changing the file */}
                    <div className='lx-file-field-current-img-toolbar document-uploader'>
                        <div className='lx-file-name'>
                            {/* Show the filename of the current file in the bottom left */}
                            <span className='filename' aria-hidden={true}>{currentFileName}</span>
                        </div>
                        <div className='lx-file-actions'>
                            {/* Change button: If uploads are allowed, this opens the file upload popup */}
                            {allowUpload &&
                                <>
                                {fileInputElement}
                                <button
                                    className='btn btn-link btn-small'
                                    onClick={() => {
                                        if (dropZone.inputRef.current) { dropZone.inputRef.current.click(); }
                                    }}
                                >
                                    <Icon name='arrow-up' />
                                    <WrappedMessage message={messages.changeDocument}/>
                                </button>
                                </>
                            }
                            {/* Delete button */}
                            <button className='btn btn-link btn-small' onClick={onDeleteClick}>
                                <Icon name='trashcan' />
                                <WrappedMessage message={messages.deleteDocument}/>
                            </button>
                        </div>
                    </div>
                </>
            : // Else if there is currently no file set:
                <>
                    {/* Display the dropzone, if allowed */}
                    {allowUpload &&
                        <div
                            className={`lx-file-field-dropzone ${isDragActive ? 'drag-active' : ''}`}
                            {...dropZoneRootProps}
                        >
                            {fileInputElement}
                            <Icon name='plus' zoom='35' />
                            <p className='main'><WrappedMessage message={messages.uploadADocument} /></p>
                            <p><WrappedMessage message={messages.uploadDocumentInstructions} /></p>
                            <p><WrappedMessage message={messages.uploadDocumentRequirements} /></p>
                        </div>
                    }
                    {/* "----Or----" divider between upload and URL field, if both are enabled */}
                    {
                        (allowUpload && allowUrlInput) && <p className='lx-file-field-or'>
                            <span><WrappedMessage message={messages.or} /></span>
                        </p>
                    }
                    {/* Display the "Enter URL" component, if allowed */}
                    {allowUrlInput &&
                        <div className='input-group lx-file-field-url-wrapper' role='group'>
                            <input
                                className='form-control'
                                placeholder={intl.formatMessage(messages.enterDocumentUrl)}
                                value={newUrl}
                                onChange={(event) => setNewUrl(event.target.value)}
                            />
                            <div className='input-group-append'>
                                <button
                                    className='btn btn-primary'
                                    disabled={!newUrl /* Disable if no URL has been entered */}
                                    onClick={() => onChange(/* newFile: */ undefined, newUrl)}
                                >
                                    <WrappedMessage message={messages.saveUrl}/>
                                </button>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    </>;
};

let lastId = 0;
/** Helper function to generate a unique HTML ID. */
function uniqueId(): string { return `DocumentField${++lastId}`; }
