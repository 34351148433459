import * as React from 'react';
import { APIPermissions } from 'labxchange-client';
import { BlockProps } from './models';
import { componentForType } from './utils';
import { connect } from 'react-redux';
import { getUserPermissions } from 'auth/selectors';
import { RootState } from 'global/state';

interface ReduxStateProps {
    userPermissions?: APIPermissions;
}

export class BlockInternal extends React.Component<BlockProps & ReduxStateProps, {}> {

    public render() {
        const BlockComponent = componentForType(this.props.itemMetadata.type);
        return <BlockComponent
            itemMetadata={this.props.itemMetadata}
            blockData={this.props.blockData}
            onExpandBlock={this.props.onExpandBlock}
            isPathwayChild={this.props.isPathwayChild}
            currentItemIndex={this.props.currentItemIndex}
            pathwayItems={this.props.pathwayItems}
            pathwayMetadata={this.props.pathwayMetadata}
            classroomName={this.props.classroomName}
            username={this.props.username}
        />;
    }
}

export const Block = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
    }),
)(BlockInternal);
