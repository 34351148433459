import * as React from 'react';
import { PlainText } from 'elements/components/RichText';

// Return an array of [start index, end index) pairs for each time `keyword` is found in `text.
function getHlRanges(text: string, keyword: string): [number, number][] {
    if (text === '' || keyword === '') return [];
    text = text.toLowerCase();
    keyword = keyword.toLowerCase();

    const ranges: [number, number][] = [];
    let startIndex = 0;
    while (true) {
        const index = text.indexOf(keyword, startIndex);
        if (index === -1) { break; }
        const endIndex = Math.min(index + keyword.length, text.length);
        ranges.push([index, endIndex]);
        startIndex = endIndex;
    }
    return ranges;
}

// Return an array of react nodes, which will be the `text` partitioned into highlighted and non highlighted segments.
// Highlighted segments will correspond to text matching one or more `keywords`.
export function highlighter(text: string, keywords: string[]): React.ReactNode[] {
    // Find all [start_index, end_index) ranges for each keyword that should be highlighted.
    const ranges = keywords.reduce<[number, number][]>(
        (acc, keyword) => acc.concat(getHlRanges(text, keyword)), []
    );
    if (ranges.length === 0) { return [<PlainText innerHtml={text} />]; }

    // Merge overlapping ranges.
    ranges.sort((a, b) => a[0] - b[0]);
    const mergedRanges = [ranges[0]];
    for (let i = 1; i < ranges.length; i++) {
        const [leftStart, leftEnd] = mergedRanges[mergedRanges.length-1];
        const [rightStart, rightEnd] = ranges[i];
        if (rightStart <= leftEnd) {
            mergedRanges[mergedRanges.length-1] = [leftStart, Math.max(rightEnd, leftEnd)];
        } else {
            mergedRanges.push([rightStart, rightEnd]);
        }
    }

    // Flatten the merged ranges into an array of indices, including the start and end index of the text.
    // This also means that `indices` is guaranteed to have at least 2 elements.
    const indices = [0].concat(([] as number[]).concat.apply([], mergedRanges), text.length);

    // For each adjacent pair of indices, create a node from a slice of `text` at those indices,
    // alternating between highlighted and non highlighted.
    const nodes = [];
    let highlight = false;
    for (let i = 0; i < indices.length - 1; i++) {
        const start = indices[i];
        const end = indices[i+1];
        if (end - start > 0) {
            const s = text.slice(start, end);
            const node = <PlainText innerHtml={s} className={`${highlight ? 'highlighted': ''}`} />;
            nodes.push(node);
        }
        highlight = !highlight;
    }
    return nodes;
}
