import { defineMessages } from 'react-intl';

const messages = defineMessages({
    loginClassText: {
        id:'loginClassText',
        defaultMessage: `You need to log in or create an account to enroll in
        this class. You will also need the class code. Would you like to get
        started?`,
        description: 'Body text for the login modal',
    },
    loginClassCancel: {
        id:'loginClassCancel',
        defaultMessage: 'Cancel',
        description: 'Cancel button text for the login modal.',
    },
    loginClassSignUp: {
        id:'loginClassSignUp',
        defaultMessage: 'Get started',
        description: 'Getting started action button text for the login modal.',
    },
});

export default messages;
