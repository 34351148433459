import { defineMessages } from 'react-intl';

const messages = defineMessages({
    classroomMembershipRequestRequestedText: {
        id:'classroomMembershipRequestRequestedText',
        defaultMessage: 'Approve request to join class?',
        description: 'Toast text on UserCard when educator receives an enrollment request on Clasroom Learners Tab',
    },
    classroomMembershipRequestAcceptedText: {
        id:'classroomMembershipRequestAcceptedText',
        defaultMessage: '{fullName} has been added to your class.',
        description: 'Toast text on UserCard when educator accepts an enrollment request on Clasroom Learners Tab',
    },
    classroomMembershipRequestRejectedText: {
        id:'classroomMembershipRequestRejectedText',
        defaultMessage: '{fullName} has been rejected from your class.',
        description: 'Toast text on UserCard when educator rejects an enrollment request on Clasroom Learners Tab',
    },
    errorUpdatingClassroomMembershipRequest: {
        id:'errorUpdatingClassroomMembershipRequest',
        defaultMessage: 'There was an error updating your response to the classroom enrollment request.',
        description: 'Remove learner modal title.',
    },
    classroomMembershipRequestButtonCancel: {
        id:'classroomMembershipRequestButtonCancel',
        defaultMessage: 'OK',
        description: 'Button text on UserCard after an educator responds to an enrollment request on Clasroom Learners Tab',
    },
    classroomMembershipRequestButtonUndo: {
        id:'classroomMembershipRequestButtonUndo',
        defaultMessage: 'Undo',
        description: 'Button text on UserCard after an educator responds to an enrollment request on Clasroom Learners Tab',
    },
    classroomMembershipRequestButtonYes: {
        id:'classroomMembershipRequestButtonYes',
        defaultMessage: 'Yes',
        description: 'Button text on UserCard to accept a learner\' request to join.',
    },
    classroomMembershipRequestButtonNo: {
        id:'classroomMembershipRequestButtonNo',
        defaultMessage: 'No',
        description: 'Button text on UserCard to reject a learner\' request to join.',
    },
});

export default messages;
