import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import * as LoginActions from 'auth/actions';

import { getDateOfBirth, getOnboardingStatus, getRole, getUsername } from 'auth/selectors';
import { authLocalStorageManager, AuthProviderEnum } from 'auth/utils';
import { ClassroomsApi, UsersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { ProfileOnboardingStatusEnum, ProfileProfileVisibilityEnum, ProfileRoleEnum } from 'labxchange-client';
import { CalendarDate } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import * as UserActions from 'user/actions';
import { WrappedMessage } from 'utils';
import messages from '../displayMessages';
import { UserAgeView } from 'auth/components/UserAge';
import { SelectRoleView } from 'auth/components/SelectRoleView';
import { getStore } from 'global/store';
import { getHasVerifiedEmail } from 'auth/selectors';
import { ActivateAccount } from 'auth/components/ActivateAccount';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { EducatorSubjectSelection } from '../../../auth/components/EducatorSubjectSelection';
import { JoinClassView } from 'auth/components/JoinClassView';

enum OnboardingSteps {
    None = 0,
    Birthdate,
    EmailActivation,
    RolePicker,
    JoinAClass,
    EducatorLastStep,
    Completed,
}

interface OnboardingPageProps extends RouteComponentProps<{}> {}

interface ReduxStateProps {
    loggedInUsername: string;
    hasVerifiedEmail: boolean;
    onboardingStatus: string | null;
    dateOfBirth: string | null;
    userRole: string | null;
}

interface ReduxActionProps {
    reloadAvatarImage: typeof UserActions.reloadOwnAvatarImage;
}

interface OnboardingPageState {
    redirectTo?: LocationDescriptor;
    step: OnboardingSteps;
    invalidClassCode: boolean;
}

type Props = OnboardingPageProps & ReduxStateProps & ReduxActionProps;

class OnboardingPageInternal extends React.PureComponent<Props, OnboardingPageState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            step: this.getStep(),
            invalidClassCode: false,
        };
        this.updateProfile();
    }

    @bind public initializePage(){
        this.setState({step: this.getStep()});
        this.updateProfile();
    }

    public async componentDidUpdate(prevProps: Props) {
        if (this.props.loggedInUsername !== prevProps.loggedInUsername) {
            this.initializePage();
        }
    }

    public getStep() {
        // If loggedInUsername is not present.
        // User should be logged in to do onboarding
        const signUpMedium = authLocalStorageManager.signUpMedium;
        if (!this.props.loggedInUsername) {
            authLocalStorageManager.onboardingStatus = null;
            return OnboardingSteps.None;
        }

        // If user onboarding status is already completed for unknown reason
        // it will be redirect to explore page.
        if (this.props.onboardingStatus === ProfileOnboardingStatusEnum.Completed) {
            return OnboardingSteps.Completed;
        }

        // Check if user is active and ask him to verify email
        // Don't show if the user already seen this page during onboarding
        const viewedEmailVerificationStep = authLocalStorageManager.viewedEmailVerificationStep;
        if (signUpMedium === AuthProviderEnum.Email && !viewedEmailVerificationStep && !this.props.hasVerifiedEmail ) {
            return OnboardingSteps.EmailActivation;
        }

        // Try to retrieve user birthday from localStorage
        let birthdate = authLocalStorageManager.userBirthdate;

        if (birthdate === null) {
            // If user profile doesn't have birthday we remove
            // localStorage 'userBirthdate' key to tell onboarder
            // middleware to redirect to sign up view (for birthday prompt)
            // and we redirect user to sign up view.
            if (this.props.dateOfBirth === undefined || this.props.dateOfBirth === null) {
                    authLocalStorageManager.onboardingStatus = null;
                    authLocalStorageManager.userBirthdate = null;
                    return OnboardingSteps.Birthdate;
            } else {
                birthdate = CalendarDate.fromIsoString(this.props.dateOfBirth);
            }
        }

        // We need to determine user age based on its birthday
        const isBelow18 = birthdate.ageInYears <= 18;
        if (isBelow18) {
            return OnboardingSteps.JoinAClass;
        }

        // Don't show if the user already seen this page during onboarding or if user is not below 18.
        const viewedRoleSelectionStep = authLocalStorageManager.viewedRoleSelectionStep;
        if (!viewedRoleSelectionStep && !isBelow18) {
            return OnboardingSteps.RolePicker;
        }

        const signupUsingJoinClass = authLocalStorageManager.signupUsingJoinClassButton;
        // if user has started onboardning from join a class link OR user has a role and it is learner, ask them to join a class.
        if (signupUsingJoinClass || (authLocalStorageManager.role && authLocalStorageManager.role === ProfileRoleEnum.Learner )) {
            return OnboardingSteps.JoinAClass;
        }
        else {
            return OnboardingSteps.EducatorLastStep;
        }
    }

    @bind private async updateProfile() {
        // Try to retrieve user birthday from localStorage
        let birthdate = authLocalStorageManager.userBirthdate;

        // If loggedInUsername is not present.
        // User should be logged in to do onboarding
        if (!this.props.loggedInUsername) {
            return;
        }

        if (birthdate === null) {
            if (this.props.dateOfBirth === undefined || this.props.dateOfBirth === null) {
                return;
            } else {
                birthdate = CalendarDate.fromIsoString(this.props.dateOfBirth);
            }
        }

        // We need to determine user age based on its birthday
        const isBelow18 = birthdate.ageInYears <= 18;

        // Prepare user profile data to update
        const updateData = {
            date_of_birth: birthdate.isoString,
        } as any;

        if(authLocalStorageManager.signUpMedium !== AuthProviderEnum.Email){
            authLocalStorageManager.hasVerifiedEmail = true;
            authLocalStorageManager.viewedEmailVerificationStep = true;
        }
        if (isBelow18) {
            // If user is below 18, it can only be a learner
            updateData.role = ProfileRoleEnum.Learner;
            // DMs are disabled by default for users under 18, but enabled by
            // default otherwise.
            updateData.direct_messages_enabled = false;
            const signUpMedium = authLocalStorageManager.signUpMedium;
            const viewedEmailVerificationStep = authLocalStorageManager.viewedEmailVerificationStep;
            if ((signUpMedium === AuthProviderEnum.Email && !viewedEmailVerificationStep && !this.props.hasVerifiedEmail) || !authLocalStorageManager.viewedJoinAClassStep ) {
                updateData.onboarding_status = ProfileOnboardingStatusEnum.StepOne;
                authLocalStorageManager.onboardingStatus = ProfileOnboardingStatusEnum.StepOne;
            } else {
                updateData.onboarding_status = ProfileOnboardingStatusEnum.Completed;
                authLocalStorageManager.onboardingStatus = ProfileOnboardingStatusEnum.Completed;
            }
        } else {
            updateData.direct_messages_enabled = true;
            updateData.profile_visibility = ProfileProfileVisibilityEnum.LabxchangeUsers;
            updateData.include_in_search_results = true;
        }

        try {
            // Update user profile based on prepared data
            await UsersApi.profilesPartialUpdate({
                id: this.props.loggedInUsername, data: updateData,
            });

            // Clean localStorage
            authLocalStorageManager.userBirthdate = (birthdate);
            await LoginActions.checkLoginStatus(getStore().dispatch);
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorMessageUnableToUpdateUserProfile}/>,
                {
                    exception: err,
                    details: 'Unable to update user birthdate',
                },
            );
        }
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} push={true}/>;
        }

        switch(this.state.step) {
            case OnboardingSteps.Birthdate:
                return <UserAgeView
                    minimumAge={13}
                    onSuccess={this.onBirthdateUpdate}
                    onFailure={this.onBirthdateFail}
                    completeOnboarding={this.completeOnboarding}
                />;
            case OnboardingSteps.EmailActivation:
                return <ActivateAccount
                    onSuccess={() => {
                        analyticsInstance.track(EVENT_NAMES.OnboardingEmailActivationSuccess, { url: window.location.toString() });
                        this.initializePage();
                    }}
                    onSkip={() => {
                        // If the users clicks on "Skip", don't show this again
                        // on the onboarding flow.
                        analyticsInstance.track(EVENT_NAMES.OnboardingEmailActivationSkip, { url: window.location.toString() });
                        authLocalStorageManager.viewedEmailVerificationStep = true;
                        this.initializePage();
                    }}
                    history={this.props.history}
                    completeOnboarding={this.completeOnboarding}
                />;
            case OnboardingSteps.EducatorLastStep:
                return <EducatorSubjectSelection
                    loggedInUserName={this.props.loggedInUsername}
                    completeOnboarding={this.completeOnboarding}
                />;
            case OnboardingSteps.RolePicker:
                return <SelectRoleView
                    onDone={this.onSelectRole}
                    onSkip={() => this.onSelectRole()}
                    completeOnboarding={this.completeOnboarding}
                />;
            case OnboardingSteps.JoinAClass:
                return <JoinClassView
                    onEnterClassCode={this.onEnterClassCode}
                    onSkip={this.onSkipClassCode}
                    invalidClassCode={this.state.invalidClassCode}
                    onInvalidClassCodeChange={() => this.setState({invalidClassCode: false})}
                    completeOnboarding={this.completeOnboarding}
                />;
            case OnboardingSteps.Completed:
                return (<Redirect to={ROUTES.Library.HOME} push={true}/>);
            default:
                return null;
        }
    }

    @bind private onBirthdateUpdate() {
        /// We reinitialize the page to see the next step
        analyticsInstance.track(EVENT_NAMES.OnboardingBirthdayUpdateSuccess, { url: window.location.toString() });
        this.initializePage();
    }

    @bind private async onBirthdateFail() {
        analyticsInstance.track(EVENT_NAMES.OnboardingBirthdayUpdateFailure, { url: window.location.toString() });
        await UsersApi.deleteAccount();
        LoginActions.logout(ROUTES.General.SIGNUP_DENIED_AGE);
    }

    @bind private async onSelectRole(role?: ProfileRoleEnum) {
        authLocalStorageManager.viewedRoleSelectionStep = true;
        // if user has started signup process from clicking on join a class button
        // we will always show them Join a class step
        const signupUsingJoinClass = authLocalStorageManager.signupUsingJoinClassButton;
        // If no role passed, it means that user wants to skip it,
        // we will also skip join a class for such users.
        if (role === undefined) {
            analyticsInstance.track(EVENT_NAMES.OnboardingRoleSelectionSkip, { url: window.location.toString() });
            if (signupUsingJoinClass) {
                this.setState({
                    step: OnboardingSteps.JoinAClass
                });
            } else {
                this.completeOnboarding();
                return;
            }
        }
        try {
            analyticsInstance.track(EVENT_NAMES.OnboardingRoleSelectionSuccess, { url: window.location.toString() });
            if (role === ProfileRoleEnum.Learner) {
                this.setState({
                    step: OnboardingSteps.JoinAClass
                });
                const data = {
                    role,
                    onboarding_status: ProfileOnboardingStatusEnum.StepOne
                };
                await UsersApi.profilesPartialUpdate({
                    id: this.props.loggedInUsername, data,
                });
            } else {
                const onboardingStatus = ProfileOnboardingStatusEnum.StepOne;
                const data = {
                    role,
                    onboarding_status: onboardingStatus,
                };
                await UsersApi.profilesPartialUpdate({
                    id: this.props.loggedInUsername, data,
                });
                authLocalStorageManager.role = ProfileRoleEnum.Educator;

                if (signupUsingJoinClass) {
                    this.setState({
                        step: OnboardingSteps.JoinAClass
                    });
                }
                else {
                    this.setState({
                        step: OnboardingSteps.EducatorLastStep
                    });
                }
            }
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorMessageUnableToUpdateUserProfile}/>,
                {
                    exception: err,
                    details: 'Unable to update user role',
                },
            );
        }
    }

    @bind private async onEnterClassCode(code: string) {

        try {
            const classroom = await ClassroomsApi.join({data: {joinCode: code}});
            if (classroom && classroom.id) {
                authLocalStorageManager.signedUpUsingClassCode = classroom.id;
            }
            this.trackOnboardingRequestToJoinClassButtonClicked(true);
            this.completeOnboarding();
        } catch (err) {
            this.trackOnboardingRequestToJoinClassButtonClicked(false);
            this.setState({invalidClassCode: true});
        }
    }

    @bind private trackOnboardingRequestToJoinClassButtonClicked (isSuccessful: boolean) {
        analyticsInstance.track(
            EVENT_NAMES.OnboardingRequestToJoinClassButtonClicked,
            {
                isSuccessful,
                url: window.location.toString(),
            }
        );
    }

    @bind private async onSkipClassCode() {
        analyticsInstance.track(EVENT_NAMES.OnboardingJoinClassSkiped, { url: window.location.toString() });
        this.completeOnboarding();
    }

    @bind private async completeOnboarding() {
        authLocalStorageManager.onboardingStatus = ProfileOnboardingStatusEnum.Completed;

        // before completion, remove these variables from localStorage.
        authLocalStorageManager.viewedJoinAClassStep = null;
        authLocalStorageManager.viewedRoleSelectionStep = null;
        authLocalStorageManager.signupUsingJoinClassButton = null;
        const data = {
            onboarding_status: ProfileOnboardingStatusEnum.Completed,
        };
        await UsersApi.profilesPartialUpdate({
            id: this.props.loggedInUsername, data,
        });

        await LoginActions.checkLoginStatus(getStore().dispatch);
        this.setState({step: OnboardingSteps.Completed});
    }
}

export const OnboardingPage = connect<ReduxStateProps, ReduxActionProps, RootState>(
    (state: RootState) => ({
        loggedInUsername: getUsername(state),
        hasVerifiedEmail: getHasVerifiedEmail(state),
        onboardingStatus: getOnboardingStatus(state),
        dateOfBirth: getDateOfBirth(state),
        userRole: getRole(state),
    }), {
        reloadAvatarImage: UserActions.reloadOwnAvatarImage,
    },
)(OnboardingPageInternal);
