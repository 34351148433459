import Analytics, { PageData } from 'analytics';
import tabEventsPlugin from 'analytics-plugin-tab-events';

import { Event } from 'tracking/Event';

import { AnalyticsApi } from 'global/api';

import { EVENT_NAMES } from 'tracking/constants';
import { createAnalyticsV2EventWithDelay, getOrCreateSessionId } from './utils';


interface PageDataPayload {
  event: string;
  properties: PageData & { eventData: Event | undefined };
  options?: any;
  meta: {
    ts: number;
  };
}

interface TrackDataPayload {
  event: string;
  properties: Event & { eventData: Event | undefined };
  options?: any;
  meta: {
    ts: number;
  };
}

/**
 * Analytics library plugin to send data back to the LX backend.
 *
 * @param config: used to pass in object defined by type `LxAnalyticsProviderConfig`
 * @returns Analytics plugin instance
 */
function LxAnalyticsProviderPlugin() {
    return {
      name: 'lx-analytics-backend',
      config: {},
      page: ({ payload }: { payload: PageDataPayload }) => {
        const data = Object.assign({}, payload.properties);
        // Note: `eventData` will not have all properties
        // of the type Event. This is just being done so that
        // type checking starts working again from this point.
        // The nature of the `analytics` library plugin system
        // doesn't have a great typing implementation, so
        // typescript isn't able to infer all variables, hence
        // the "hack" below.
        const eventData: Event = data.eventData!;
        // Remove `eventData` from properties so all that's left
        // are the original parameters passed through
        // `analytics.track()`.
        delete data.eventData;
        const requestedParams = {
          ...eventData,
          // The `url` is included in the default properties.
          data,
          eventType: EVENT_NAMES.PageViewed,
          createdAt: new Date(payload.meta.ts),
        };
        // Send the event immediately if specified in the passed
        // options (defaults to false). This is for instances with
        // the possibility of DOM unloading e.g. redirection during
        // SSO, which cancels all waiting jobs
        if (payload.options.sendImmediately) {
          AnalyticsApi.createEventCreate({
            data: { ...requestedParams, sentAt: new Date() },
          });
        } else {
          createAnalyticsV2EventWithDelay(requestedParams);
        }
      },
      track: ({ payload }: { payload: TrackDataPayload }) => {
        const data = Object.assign({}, payload.properties);
        // Note: `eventData` will not have all properties
        // of the type Event. This is just being done so that
        // type checking starts working again from this point.
        // The nature of the `analytics` library plugin system
        // doesn't have a great typing implementation, so
        // typescript isn't able to infer all variables, hence
        // the "hack" below.
        const eventData: Event = data.eventData!;
        // Remove `eventData` from properties so all that's left
        // are the original parameters passed through
        // `analytics.track()`.
        delete data.eventData;
        const requestedParams = {
          ...eventData,
          data,
          eventType: payload.event,
          createdAt: new Date(payload.meta.ts),
        };
        // Send the event immediately if specified in the passed
        // options (defaults to false). This is for instances with
        // the possibility of DOM unloading e.g. redirection during
        // SSO, which cancels all waiting jobs
        if (payload.options.sendImmediately) {
          AnalyticsApi.createEventCreate({
            data: { ...requestedParams, sentAt: new Date() },
          });
        } else {
          createAnalyticsV2EventWithDelay(requestedParams);
        }
      },
    };
}

/**
 * Analytics library plugin to annotate additional data required for every request.
 *
 * @returns Analytics plugin instance
 */
function LxExtraAnalyticsData() {
  return {
    name: 'lx-extra-analytics-data',
    config: {
      getExtraData: (payload: TrackDataPayload|PageDataPayload): Partial<Event> => {
        return {
          eventVersion: 1,
          // Session information
          sessionId: getOrCreateSessionId(),
          // DPR: used to identify if user is using any
          // HiDPI settings (like on Retina screens).
          // Defaults to 1 if not provided by browser.
          devicePixelRatio: window.devicePixelRatio || 1,
          // Window information
          viewportHeight: window.innerHeight,
          viewportWidth: window.innerWidth,
          // Current URL from the payload
          url: payload.properties?.url,
          referrer: document.referrer,
        };
      },
    },
    pageStart: ({ payload, config }: { payload: PageDataPayload, config: any }) => {
      // Update `eventData` values in place if they exist,
      // so that other plugins and calls can manipulate them too.
      let eventData = config.getExtraData(payload);
      if (payload.properties.eventData) {
        eventData = {
          ...eventData,
          ...payload.properties.eventData,
        };
      }

      payload.properties = {
        ...payload.properties,
        eventData,
      };
    },
    trackStart: ({ payload, config }: { payload: TrackDataPayload, config: any }) => {
      // Update `eventData` values in place if they exist,
      // so that other plugins and calls can manipulate them too.
      let eventData = config.getExtraData(payload);
      if (payload.properties.eventData) {
        eventData = {
          ...eventData,
          ...payload.properties.eventData,
        };
      }

      payload.properties = {
        ...payload.properties,
        eventData,
      };
    },
  };
}

/**
 * Initialize analytics & load plugins.
 *
 * Minor issue using this library: type checking for the eventType doesn't work,
 * because our custom plugin has no way of passing `EventEventTypeEnum` to
 * `eventName` of the method `Track` (and type overrides didn't work!).
 */
export const analyticsInstance = Analytics({
    app: 'labxchange',
    plugins: [
      tabEventsPlugin(),
      LxExtraAnalyticsData(),
      LxAnalyticsProviderPlugin(),
    ],
});
