// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    NotificationContent,
    NotificationContentFromJSON,
    NotificationContentToJSON,
} from './';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    ntype: NotificationNtypeEnum;
    /**
     * 
     * @type {any}
     * @memberof Notification
     */
    context: any;
    /**
     * 
     * @type {NotificationContent}
     * @memberof Notification
     */
    content: NotificationContent;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    unread: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    readAt: Date;
}

export function NotificationFromJSON(json: any): Notification {
    return {
        'id': json['id'],
        'ntype': json['ntype'],
        'context': (json['context']),
        'content': NotificationContentFromJSON(json['content']),
        'createdAt': new Date(json['created_at']),
        'unread': json['unread'],
        'readAt': new Date(json['read_at']),
    };
}

export function NotificationToJSON(value?: Notification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'ntype': value.ntype,
        'context': (value.context),
        'content': NotificationContentToJSON(value.content),
        'created_at': value.createdAt.toISOString(),
        'unread': value.unread,
        'read_at': value.readAt.toISOString(),
    };
}

/**
* @export
* @enum {string}
*/
export enum NotificationNtypeEnum {
    ClassJoinRequest = 'class_join_request',
    ClassJoinReject = 'class_join_reject',
    ClassJoinAccept = 'class_join_accept',
    ClassDelete = 'class_delete',
    ClassArchive = 'class_archive',
    ClassAssignContent = 'class_assign_content',
    ClassPostContent = 'class_post_content',
    ClassRemoveMember = 'class_remove_member',
    ClassLeaveMember = 'class_leave_member',
    ClassAddCoEducator = 'class_add_co_educator',
    ClassForumActivityFollowed = 'class_forum_activity_followed',
    ClassForumEducatorNewThread = 'class_forum_educator_new_thread',
    ClassForumDeletedComment = 'class_forum_deleted_comment',
    ClassForumDeletedThread = 'class_forum_deleted_thread',
    ClassForumEducatorRulesThread = 'class_forum_educator_rules_thread',
    MentorshipRequest = 'mentorship_request',
    MentorshipRequestMentee = 'mentorship_request_mentee',
    MentorshipRequestAccept = 'mentorship_request_accept',
    MentorshipRequestReject = 'mentorship_request_reject',
    MentorshipEndByMentee = 'mentorship_end_by_mentee',
    MentorshipEndByMentor = 'mentorship_end_by_mentor',
    MentorshipAssignContent = 'mentorship_assign_content',
    MentorshipCompleteContent = 'mentorship_complete_content',
    DiscussionForumNewActivityFollowed = 'discussion_forum_new_activity_followed',
    DiscussionForumFavoritedThread = 'discussion_forum_favorited_thread',
    DiscussionForumDeletedThread = 'discussion_forum_deleted_thread',
    DiscussionForumDeletedComment = 'discussion_forum_deleted_comment',
    ContentUpdateFavorite = 'content_update_favorite',
    ContentUpdateClone = 'content_update_clone',
    ContentUpdateRemovedClassEducator = 'content_update_removed_class_educator',
    ContentUpdateRemovedClassLearner = 'content_update_removed_class_learner',
    ContentUpdateRemovedMentorshipMentor = 'content_update_removed_mentorship_mentor',
    ContentUpdateRemovedMentorshipMentee = 'content_update_removed_mentorship_mentee',
    ContentUpdateRemovedPathwayOwner = 'content_update_removed_pathway_owner',
    ContentUpdateChildPathwayOwner = 'content_update_child_pathway_owner',
    ContentUpdateClassEducator = 'content_update_class_educator',
    ContentUpdateChildAssessmentOwner = 'content_update_child_assessment_owner',
    AccountUpdateSwitchEducatorRole = 'account_update_switch_educator_role',
    AccountUpdateDeletedAccount = 'account_update_deleted_account',
    AccountUpdateNewAccount = 'account_update_new_account',
    AccountActivateEmail = 'account_activate_email',
    AccountResetPasswordEmail = 'account_reset_password_email',
    AccountUpdatePasswordChanged = 'account_update_password_changed',
    AccountUpdatePasswordChangedEmail = 'account_update_password_changed_email',
    ReportedClassDoesntViolateTos = 'reported_class_doesnt_violate_tos',
    ReportedClassDoesntViolateTosFlagger = 'reported_class_doesnt_violate_tos_flagger',
    ReportedClassDeletedViolateTos = 'reported_class_deleted_violate_tos',
    ReportedClassDeletedViolateTosFlagger = 'reported_class_deleted_violate_tos_flagger',
    ReportedClassDeletedViolateTosMember = 'reported_class_deleted_violate_tos_member',
    ModeratedThreadDeleted = 'moderated_thread_deleted',
    ModeratedThreadDeletedFlagger = 'moderated_thread_deleted_flagger',
    ModeratedThreadOk = 'moderated_thread_ok',
    ModeratedThreadOkFlagger = 'moderated_thread_ok_flagger',
    ModeratedReplyDeleted = 'moderated_reply_deleted',
    ModeratedReplyDeletedFlagger = 'moderated_reply_deleted_flagger',
    ModeratedReplyOk = 'moderated_reply_ok',
    ModeratedReplyOkFlagger = 'moderated_reply_ok_flagger',
    ModeratedContentOk = 'moderated_content_ok',
    ModeratedContentOkFlagger = 'moderated_content_ok_flagger',
    ModeratedContentDeleted = 'moderated_content_deleted',
    ModeratedContentDeletedFlagger = 'moderated_content_deleted_flagger',
    MessagesNew = 'messages_new',
    MessagesReported = 'messages_reported',
    AccountSuspendedTosViolation = 'account_suspended_tos_violation',
    AccountUnSuspended = 'account_un_suspended',
    AccountSuspendedSdn = 'account_suspended_sdn',
    ProductInterview = 'product_interview',
    PolicyTermsUpdate = 'policy_terms_update'
}


