// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ModerationRequest
 */
export interface ModerationRequest {
    /**
     * 
     * @type {string}
     * @memberof ModerationRequest
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof ModerationRequest
     */
    contentType: ModerationRequestContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModerationRequest
     */
    reason: ModerationRequestReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ModerationRequest
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ModerationRequest
     */
    threadId?: number;
}

export function ModerationRequestFromJSON(json: any): ModerationRequest {
    return {
        'objectId': json['object_id'],
        'contentType': json['content_type'],
        'reason': json['reason'],
        'description': json['description'],
        'threadId': !exists(json, 'thread_id') ? undefined : json['thread_id'],
    };
}

export function ModerationRequestToJSON(value?: ModerationRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'object_id': value.objectId,
        'content_type': value.contentType,
        'reason': value.reason,
        'description': value.description,
        'thread_id': value.threadId,
    };
}

/**
* @export
* @enum {string}
*/
export enum ModerationRequestContentTypeEnum {
    LibraryItemmetadata = 'library-itemmetadata',
    DiscussionsPost = 'discussions-post',
    ClassroomsClassroom = 'classrooms-classroom'
}
/**
* @export
* @enum {string}
*/
export enum ModerationRequestReasonEnum {
    Accessibility = 'accessibility',
    Offensive = 'offensive',
    Unavailable = 'unavailable',
    Quality = 'quality',
    Copyright = 'copyright',
    Other = 'other'
}


