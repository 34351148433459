import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { Modal } from 'elements';
import messages from 'elements/displayMessages';
import { Button } from 'ui/components';
import { WrappedMessage } from 'utils';

interface Props extends React.PropsWithChildren {
    onCanceled: () => void;
    onConfirmed: () => void;
    modalClassNames?: string;
    confirmClassNames?: string;
    title: MessageDescriptor;
    titleValues?: {};
    body?: MessageDescriptor;
    bodyValues?: {};
    confirmButtonText: MessageDescriptor;
    cancelButtonText: MessageDescriptor;
    closeButtonText: MessageDescriptor;
    disabled?: boolean;
    iconName?: 'warning-icon' | 'success-icon' | 'copy-icon';
}

export class ModalConfirmation extends React.PureComponent<Props, {}> {

    public static defaultProps = {
        closeButtonText: messages.elementsModalCloseText,
    };

    public render() {
        const iconName = this.props.iconName ?? 'warning-icon';

        return (
            <Modal
                onRequestClose={this.props.onCanceled}
                className={`modal-confirmation ${this.props.modalClassNames}`}
                content={
                    <>
                        <div className={iconName}></div>
                        <div className='title-text'>
                            <WrappedMessage message={this.props.title} values={this.props.titleValues}/>
                        </div>
                        { this.props.body &&
                            <p className='body-text'>
                                <WrappedMessage message={this.props.body} values={this.props.bodyValues} />
                            </p>
                        }
                        {this.props.children}
                    </>
                }
                footer={
                    <>
                        <Button
                            btnStyle='outline'
                            label={this.props.cancelButtonText}
                            onClick={this.props.onCanceled} />
                        <button
                            className={`primary-button ${this.props.confirmClassNames}`}
                            onClick={this.props.onConfirmed}
                            disabled={this.props.disabled}>
                            <WrappedMessage message={this.props.confirmButtonText} />
                        </button>
                    </>
                }
            />
        );
    }
}
