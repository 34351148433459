import { defineMessages } from 'react-intl';

const messages = defineMessages({
    recommendedResources: {
        id:'recommendedSuggestions',
        defaultMessage: 'Recommended resources',
        description: 'Label that goes on top of recommended resources list on search pages.',
    },
});

export default messages;
