import * as React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    /* Current time value in seconds */
    timeStamp: number;
    buttonStyle?: 'strong' | 'normal' | 'light';
    onTimeStampChange?: (timeStamp: number) => void;
    interval?: number; // Default is 1 second
    disableLeft?: boolean;
    disableRight?: boolean;
}

/**
 * A widget that allows the user to specify a timestamp in (MM:SS) for referring to a
 * particular time in a video.
 *
 * To restrict the range of allowed timestamp values, simply reject values that
 * are too high or too low in the onTimeStampChange() handler.
 *
 * Matches the design at
 * https://app.zeplin.io/project/5b8ffe32b9f8014bd239739f/screen/5beee4faae2b6a3eb6a60ad3
 */
export const TimestampInput: React.FunctionComponent<Props> = ({
    timeStamp,
    buttonStyle = 'strong',
    onTimeStampChange = () => {/* */},
    interval = 1,
    disableLeft = false,
    disableRight = false,
    ...otherProps
}) => {
    // Convert timestamp to a string like "3:44"
    const timeStampString = timestampStringFromNumber(timeStamp);

    // Is the user currently editing the text value?
    const [isEditing, setIsEditing] = React.useState(false);
    // If they are, use 'tempStringValue' to track what they're typing.
    // We need this because if we validate and update the timestamp while they type
    // it has annoying side effects like moving the cursor around. So we let them
    // type whatever they want and only validate it on blur.
    const [tempStringValue, setTempStringValue] = React.useState(timeStampString);
    // Whenever the timestamp prop changes, force update the temp string value too:
    React.useEffect(() => { setTempStringValue(timeStampString); }, [timeStamp]);

    // Event handler when the user manually changes the text value
    const handleNewValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempStringValue(event.target.value);
    };

    // When the user is done editing (blurs focus), update the timestamp value.
    React.useEffect(() => {
        if (!isEditing) {
            // The user is done editing.
            const newValue = numberFromTimestampString(tempStringValue);
            if (newValue !== timeStamp) {
                onTimeStampChange(newValue);
            } else {
                // The following line avoids a bug where if the value is '0:01' and
                // the user edits it to '0:001' then clicks off and back on, we want
                // to show the normalized value '0:01' and not go back to '0:001'.
                setTempStringValue(timeStampString);
            }
        }
    }, [isEditing]);

    return <div className='input-group lx-timestamp-input'>
        <div className='input-group-prepend'>
            <button type='button' className={`btn btn-secondary ${buttonStyle} ${disableLeft ? 'disabled' : ''}`}
                onClick={() => onTimeStampChange(timeStamp - interval)}>-</button>
        </div>
        <input
            {...otherProps}
            type='text'
            value={isEditing ? tempStringValue : timeStampString}
            onFocus={() => { setIsEditing(true); }}
            onBlur={() => { setIsEditing(false); }}
            onChange={handleNewValue}
            size={timeStampString.length + 1}
            maxLength={11}
        />
        <div className='input-group-append'>
            <button type='button' className={`btn btn-secondary ${buttonStyle} ${disableRight ? 'disabled' : ''}`}
                onClick={() => onTimeStampChange(timeStamp + interval)}>+</button>
        </div>
    </div>;
};

/**
 * Given a numeric timestamp (in seconds) like 1054.1, this
 * returns it as a string like "17:34".
 * @param timeStamp The timestamp value, in seconds.
 */
export function timestampStringFromNumber(timeStamp: number): string {
    timeStamp = Math.round(timeStamp); // The string will never contain a decimal value
    const numHours = Math.floor(timeStamp / 3600.0);
    const numMinutes = Math.floor(timeStamp % 3600 / 60);
    const numSeconds = Math.floor(timeStamp % 60);
    const zeroPad = (num: number, places: number = 2) => String(num).padStart(places, '0');
    const timeStampString = `${numHours ? numHours + ':' + zeroPad(numMinutes) : numMinutes}:${zeroPad(numSeconds)}`;
    return timeStampString;
}

/**
 * Given a string like "17:34" (MM:SS or H:MM:SS or H:MM:SS.S), parse it
 * and return the total value in seconds.
 */
export function numberFromTimestampString(timeStampString: string): number {
    let numSeconds = 0;
    const parts = timeStampString.split(':');

    // Parse the seconds
    const secondsPart = parts.pop();
    const secondsPartNum = secondsPart ? parseFloat(secondsPart) : 0;
    if (!isNaN(secondsPartNum) && secondsPartNum > 0) {
        numSeconds += secondsPartNum;
    }

    // Parse the minutes
    const minutesPart = parts.pop();
    const minutesPartNum = minutesPart ? parseInt(minutesPart, 10) : 0;
    if (!isNaN(minutesPartNum) && minutesPartNum > 0) {
        numSeconds += minutesPartNum * 60;
    }

    // Parse the hours, if present
    const hoursPart = parts.pop();
    const hoursPartNum = hoursPart ? parseInt(hoursPart, 10) : 0;
    if (!isNaN(hoursPartNum) && hoursPartNum > 0) {
        numSeconds += hoursPartNum * 3600;
    }

    return numSeconds;
}
