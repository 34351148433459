import React, { useState, useEffect } from 'react';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';

import { FeaturedItems, Cluster } from 'labxchange-client';
import { Icon } from 'elements';
import { Spinner } from 'ui/components';
import { ClustersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import uiMessages from 'ui/components/displayMessages';
import { staticToDynamicCluster } from 'clusters/components/ClustersPage/ClustersPage';

import { ClusterCard } from './ClusterCard';

interface ClustersCarouselProps {
    clusters: FeaturedItems;
    isUserLoggedIn?: boolean;
    layoutSize: string;
}

type ClustersType = { [key: string]: Cluster };

export const ClustersCarousel: React.FC<ClustersCarouselProps> = (props) => {
    const isMobile = props.layoutSize === 'mobile' || props.layoutSize === 'small';
    const [currentId, setCurrentId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [clusters, setClusters] = useState<ClustersType>({});
    const history = useHistory();

    useEffect(() => {
        const loadAllClustersDetails = async () => {
            setLoading(true);
            try {
                const fetchPromises = props.clusters.items.map(
                    cluster => {
                        const clusterKey = cluster.metadata.id;
                        const clusterSlug = staticToDynamicCluster[clusterKey];
                        return ClustersApi.read({ id: clusterSlug || clusterKey });
                    }
                );
                const clustersArray = await Promise.all(fetchPromises);
                const clustersMap = clustersArray.reduce((acc: ClustersType, detail, index) => {
                    const id = props.clusters.items[index].metadata.id;
                    acc[id] = detail;
                    return acc;
                }, {});
                setLoading(false);
                setClusters(clustersMap);
            } catch (error) {
                setLoading(false);
            }
        };

        loadAllClustersDetails();
    }, [props.clusters]);

    const onViewClusterCard = (clusterId: string) => {
        history.push(ROUTES.Library.CLUSTER_SLUG(clusterId));
    };

    const goToNext = () => {
        const numItems = props.clusters.items.length;
        const incrId = currentId + 1;
        setCurrentId(incrId >= numItems ? 0 : incrId);
    };

    const goToPrev = () => {
        const numItems = props.clusters.items.length;
        const decrId = currentId - 1;
        setCurrentId(decrId < 0 ? numItems - 1 : decrId);
    };

    const renderClusterCards = () => {
        if (isMobile) {
            return props.clusters.items.map((clusterItem, index) => (
                <ClusterCard
                    key={index}
                    cluster={clusterItem}
                    clusterDetails={clusters[clusterItem.metadata.id]}
                    onView={onViewClusterCard}
                />
            ));
        }
        const cluster = props.clusters.items[currentId];
        return (
            <ClusterCard
                cluster={cluster}
                clusterDetails={clusters[cluster.metadata.id]}
                onView={onViewClusterCard}
            />
        );
    };

    if (loading) {
        return (
            <div className='spinner-wrapper'>
                <Spinner />
            </div>
        );
    }

    return (
        <div className='clusters-carousel'>
            <button
                className={`previous-button ${isMobile ? 'hide' : ''}`}
                title={intl.formatMessage(uiMessages.uiPrevious)}
                onKeyDown={goToPrev}
                onClick={goToPrev}
            >
                <Icon name='chevron-left' />
            </button>
            {renderClusterCards()}
            <button
                className={`next-button ${isMobile ? 'hide' : ''}`}
                title={intl.formatMessage(uiMessages.uiNext)}
                onKeyDown={goToNext}
                onClick={goToNext}
            >
                <Icon name='chevron-right' />
            </button>
        </div>
    );
};
