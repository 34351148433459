import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'pageTitleOrgSearch',
        defaultMessage: 'Organizations',
        description: 'Page title for the organization search page',
    },
    collaborator: {
        id: 'orgOfficialCollab',
        defaultMessage: 'LabXchange Collaborator',
        description: 'Text shown near an organization title when the organization is a collaborator type',
    },
    clustersCount: {
        id: 'orgClustersCount',
        defaultMessage: '{count, plural, =1 {# Cluster} other {# Clusters}}',
        description: 'display the number of clusters for an organization',
    },
    pathwaysCount: {
        id: 'orgPathwaysCount',
        defaultMessage: '{count, plural, =1 {# Pathway} other {# Pathways}}',
        description: 'display the number of pathways for an organization',
    },
    contentCount: {
        id: 'orgContentCount',
        defaultMessage: '{count, plural, =1 {# Content item} other {# Content items}}',
        description: 'display the number of content items for an organization',
    },
    viewProfile: {
        id: 'orgViewProfile',
        defaultMessage: 'View profile',
        description: 'button to view organization profile',
    },
    viewInLibrary: {
        id: 'orgViewInLibrary',
        defaultMessage: 'View in library',
        description: 'button to view content of this organization in the library',
    },
});

export default messages;
