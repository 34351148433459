import { defineMessages } from 'react-intl';

const messages = defineMessages({
    curriculumsPageHeaderTitle: {
        id:'curriculumsPageHeaderTitle',
        defaultMessage: 'Browse by curriculum',
        description: 'Header title for the curriculums list page',
    },
    curriculumsPageHeaderPill: {
        id:'curriculumsPageHeaderPill',
        defaultMessage: 'New!',
        description: 'Text for the header pill message',
    },
    curriculumsPageLoadingError: {
        id:'curriculumsPageLoadingError',
        defaultMessage: 'Encountered an error while loading curriculums',
        description: 'Error message when loading curriculums fails',
    },
    curriculumsPageSectionTitle: {
        id:'curriculumsPageSectionTitle',
        defaultMessage: 'Currently available curricula',
        description: 'Section title for listed curriculums on the page',
    },
    curriculumsPageSectionParagraph: {
        id:'curriculumsPageSectionParagraph',
        defaultMessage: 'Explore a diverse array of resources meticulously designed to meet the standards and objectives of your region\'s specific educational framework.',
        description: 'Section title for listed curriculums on the page',
    },
    curriculumsPageCardButtonText: {
        id:'curriculumsPageCardButtonText',
        defaultMessage: 'Explore curriculum',
        description: 'Button text for the curriculum card on curriclums list page',
    },
    curriculumsPageComingSoonText: {
        id:'curriculumsPageComingSoonText',
        defaultMessage: 'Coming soon!',
        description: 'Text for the coming soon section title on the curriculums page',
    },
    curriculumsPageRequestTitle: {
        id:'curriculumsPageRequestTitle',
        defaultMessage: 'Don\'t see your curriculum?',
        description: 'Text for the request curriculum section title on the curriculums page',
    },
    curriculumsPageRequestParagraphText: {
        id:'curriculumsPageRequestParagraphText',
        defaultMessage: 'Share your feedback and submit a request to add your country\'s curriculum to LabXchange.',
        description: 'Text for the request curriculum paragraph on the curriculums page',
    },
    curriculumsPageRequestButtonText: {
        id:'curriculumsPageRequestButtonText',
        defaultMessage: 'Request your curriculum',
        description: 'Text for the request curriculum button on the curriculums page',
    },
    curriculumTopicPageSidebarError: {
        id:'curriculumTopicPageSidebarError',
        defaultMessage: 'Unable to load data for the provided curriculum.',
        description: 'Error message for sidebar of curriculum topic page',
    },
    curriculumTopicPageSidebarOpenBtnText: {
        id:'curriculumTopicPageSidebarOpenBtnText',
        defaultMessage: 'Curriculum',
        description: 'Text for the sidebar open button',
    },
    curriculumModuleOverviewLink: {
        id:'curriculumModuleOverviewLink',
        defaultMessage: 'Overview',
        description: 'Link text for multiple curriculum learning objectives parent',
    },
    curriculumPageTitle: {
        id:'curriculumPageTitle',
        defaultMessage: '{name} - Curriculum overview',
        description: 'Heading for curriculum overview page',
    },
    learningObjectivesTotalCount: {
        id:'learningObjectivesTotalCount',
        defaultMessage: '{count} in total',
        description: 'Count tag for learning objectives',
    },
    learningObjectivesCountOnly: {
        id:'learningObjectivesCountOnly',
        defaultMessage: '{count}',
        description: 'Count tag for learning objectives',
    },
    learningObjectivesShowMore: {
        id:'learningObjectivesShowMore',
        defaultMessage: 'Show more',
        description: 'Text for the show more button',
    },
    learningObjectivesShowLess: {
        id:'learningObjectivesShowLess',
        defaultMessage: 'Show less',
        description: 'Text for the show less button',
    },
    tagContentPageHeaderTitle: {
        id:'tagContentPageHeaderTitle',
        defaultMessage: 'Curriculum Tagging',
        description: 'Header title for the tag content page',
    },
    tagContentTableTagBtnLabel: {
        id:'tagContentTableTagBtnLabel',
        defaultMessage: 'Tag',
        description: 'Label for the tag button in the tag content table',
    },
    tagContentTableReTagBtnLabel: {
        id:'tagContentTableReTagBtnLabel',
        defaultMessage: 'Re-tag',
        description: 'Label for the re-tag button in the tag content table',
    },
    tagContentTableTaggedStatusLabel: {
        id:'tagContentTableTaggedStatusLabel',
        defaultMessage: 'Tagged',
        description: 'Label for the tagged status in the tag content table',
    },
    tagContentTableNotTaggedStatusLabel: {
        id:'tagContentTableNotTaggedStatusLabel',
        defaultMessage: 'Not tagged',
        description: 'Label for the not tagged status in the tag content table',
    },
    tagContentSelectCurriculumAndSubject: {
        id:'tagContentSelectCurriculumAndSubject',
        defaultMessage: 'Please select a curriculum and subject.',
        description: 'Message when no curriculum and subject is selected',
    },
    tagContentNoItemsFound: {
        id:'tagContentNoItemsFound',
        defaultMessage: 'No items found for this curriculum and subject.',
        description: 'Message when no items are found for the selected curriculum and subject',
    },
    tagContentTableAssetTitle: {
        id:'tagContentTableAssetTitle',
        defaultMessage: 'Title',
        description: 'Table header for asset title',
    },
    tagContentTableTagContent: {
        id:'tagContentTableTagContent',
        defaultMessage: 'Tag Content',
        description: 'Table header for tag content',
    },
    tagContentTableStatus: {
        id:'tagContentTableStatus',
        defaultMessage: 'Status',
        description: 'Table header for status',
    },
    tagContentModalTitle: {
        id:'tagContentFeedbackModalTitle',
        defaultMessage: 'Tag content',
        description: 'Title for the feedback modal',
    },
    tagContentModalAssetLabel: {
        id:'tagContentModalAssetLabel',
        defaultMessage: 'Content',
        description: 'Label for the asset in the tag content modal',
    },
    tagContentModalCurriculumLabel: {
        id:'tagContentModalCurriculumLabel',
        defaultMessage: 'Curriculum',
        description: 'Label for the curriculum in the tag content modal',
    },
    tagContentCurriculumDropdownPlaceholder: {
        id:'tagContentCurriculumDropdownPlaceholder',
        defaultMessage: 'Select curriculum',
        description: 'Label for the curriculum dropdown',
    },
    tagContentCurriculumDropdownAria: {
        id:'tagContentCurriculumDropdownAria',
        defaultMessage: 'Curriculum Dropdown',
        description: 'Aria label for the curriculum dropdown',
    },
    tagContentModalSubjectLabel: {
        id:'tagContentModalSubjectLabel',
        defaultMessage: 'Subject',
        description: 'Label for the subject in the tag content modal',
    },
    tagContentSubjectDropdownPlaceholder: {
        id:'tagContentSubjectDropdownPlaceholder',
        defaultMessage: 'Select subject',
        description: 'Label for the subject dropdown',
    },
    tagContentSubjectDropdownAria: {
        id:'tagContentSubjectDropdownAria',
        defaultMessage: 'Subject Dropdown',
        description: 'Aria label for the subject dropdown',
    },
    tagContentModalIsRelevantQuestion: {
        id:'tagContentModalIsRelevantQuestion',
        defaultMessage: 'Is this content relevant?',
        description: 'Question for the tag content modal',
    },
    tagContentModalYesBtnLabel: {
        id:'tagContentModalYesBtnLabel',
        defaultMessage: 'Yes',
        description: 'Label for the yes button in the tag content modal',
    },
    tagContentModalNoBtnLabel: {
        id:'tagContentModalNoBtnLabel',
        defaultMessage: 'No',
        description: 'Label for the no button in the tag content modal',
    },
    tagContentModalCurriculumQuestion: {
        id:'tagContentModalCurriculumQuestion',
        defaultMessage: 'Where does this content fit in the curriculum?',
        description: 'Question in the tag content modal',
    },
    tagContentModalPODropdownPlaceholder: {
        id:'tagContentModalPODropdownPlaceholder',
        defaultMessage: 'Select performance objectives',
        description: 'Label for the performance objectives dropdown',
    },
    tagContentModalPODropdownSearchPlaceholder: {
        id:'tagContentModalPODropdownSearchPlaceholder',
        defaultMessage: 'Search',
        description: 'Label for inline search within performance expectations dropdown',
    },
    tagContentModalSubmitBtnLabel: {
        id:'tagContentModalSubmitBtnLabel',
        defaultMessage: 'Submit',
        description: 'Label for the submit button in the tag content modal',
    },
    tagContentModalTellUsMoreOptionalLabel: {
        id:'tagContentModalTellUsMoreOptionalLabel',
        defaultMessage: 'Please tell us more (optional)',
        description: 'Label for the optional text area in the tag content modal',
    },
    tagContentModalTellUsMoreLabel: {
        id:'tagContentModalTellUsMoreLabel',
        defaultMessage: 'Please tell us more',
        description: 'Label for the optional text area in the tag content modal',
    },
    tagContentPageTotalAssets: {
        id:'tagContentPageTotalAssets',
        defaultMessage: 'Total content: {count}',
        description: 'Total assets count on the tag content page',
    },
});

export default messages;
