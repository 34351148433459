// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfession
 */
export interface UserProfession {
    /**
     * 
     * @type {string}
     * @memberof UserProfession
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfession
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfession
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfession
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfession
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof UserProfession
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfession
     */
    startYear?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfession
     */
    endMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfession
     */
    endYear?: number;
}

export function UserProfessionFromJSON(json: any): UserProfession {
    return {
        'jobTitle': json['job_title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'startMonth': !exists(json, 'start_month') ? undefined : json['start_month'],
        'startYear': !exists(json, 'start_year') ? undefined : json['start_year'],
        'endMonth': !exists(json, 'end_month') ? undefined : json['end_month'],
        'endYear': !exists(json, 'end_year') ? undefined : json['end_year'],
    };
}

export function UserProfessionToJSON(value?: UserProfession): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'job_title': value.jobTitle,
        'description': value.description,
        'company': value.company,
        'country': value.country,
        'state': value.state,
        'start_month': value.startMonth,
        'start_year': value.startYear,
        'end_month': value.endMonth,
        'end_year': value.endYear,
    };
}


