import classNames from 'classnames';
import * as LoginActions from 'auth/actions';
import moment from 'moment-timezone';
import * as React from 'react';
import messages from './displayMessages';
import uiMessages from 'ui/components/displayMessages';
import { AccountDetails, Profile } from 'labxchange-client';
import { bind } from 'bind-decorator';
import { Button, CalendarDate, Collapsible, LocaleSwitcher, ModalConfirmation, StandardPageLayout } from 'ui/components';
import { connect } from 'react-redux';
import { CountryFieldEditForm, DateOfBirthFieldEditForm, TextFieldEditForm } from 'ui/components/SingleFieldEditForm/TextFieldEditForm';
import { getLoggedInStatus, getUsername } from 'auth/selectors';
import { Icon } from 'elements';
import { intl } from 'i18n';
import { localeInfo } from 'i18n';
import { LocationDescriptor } from 'history';
import { MessageDescriptor } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { NotificationSettingsComponent } from './NotificationSettingsComponent';
import { Redirect } from 'react-router';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { RootState } from 'global/state';
import { RouteComponentProps } from 'react-router';
import { ROUTES } from 'global/constants';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { UI_IS_SM } from 'ui/breakpoints';
import { UsersApi } from 'global/api';
import { WrappedMessage } from 'utils';

interface SettingsSectionProps {
    id: string;
    title: MessageDescriptor;
    children?: React.ReactNode;
}

interface SettingsSectionState {
    isCollapsible: boolean;
    isOpen: boolean;
    modalState: ModalState;
}

export class SettingsSection extends React.Component<SettingsSectionProps, SettingsSectionState> {
    public constructor(props: SettingsSectionProps) {
        super(props);
        this.state = {
            isCollapsible: this.isMobileView(),
            isOpen: !this.isMobileView(),
            modalState: ModalState.None,
        };
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    public render() {
        let indicator = null;
        if (this.state.isCollapsible) {
            indicator = (
                <Icon
                    className='settings-section-indicator'
                    name={this.state.isOpen ? 'chevron-up' : 'chevron-down'}
                    zoom='1.4em'
                />
            );
        }

        const title = (
            <div className='info-heading'>
                <h2 className='section-title'>
                    <WrappedMessage message={this.props.title} />
                    {indicator}
                </h2>
            </div>
        );

        return <>
            <div id={this.props.id} ref={this.props.id} className='info-box'>
                <Collapsible
                    title={title}
                    isOpen={this.state.isOpen}
                    isCollapsible={this.state.isCollapsible}
                    onToggle={this.onToggle}
                >
                    {this.props.children}
                </Collapsible>
            </div>
        </>;
    }

    @bind private onToggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    @bind private isMobileView() {
        return UI_IS_SM.matches;
    }

    @bind private onResize() {
        this.setState({
            isCollapsible: this.isMobileView(),
        });
    }
}

enum ModalState {
    None = 0,
    ConfirmDeleteFirst,
    ConfirmDeleteFinal,
    PerformDeleteAccount,
    ResetPassword,
}

interface ReduxStateProps {
    loggedInUsername: string;
    isLoggedIn: boolean;
}

interface Props extends RouteComponentProps<{}>, ReduxStateProps { }

interface State {
    loading: boolean;
    userAccountDetails?: AccountDetails;
    userProfileDetails?: Profile;
    modalState: ModalState;
    timeZoneValue: string | undefined;
    fullName?: string;
    country?: string;
    dateOfBirthValue?: CalendarDate;
    redirectTo?: LocationDescriptor;
}

export class UserProfileSettingsInternal extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            timeZoneValue: undefined,
            country: undefined,
            dateOfBirthValue: undefined,
            modalState: ModalState.None,
        };
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public async componentDidUpdate(prevProps: Props) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            this.setState({ userAccountDetails: undefined });
            await this.loadData();
        }
    }

    public async loadData() {
        try {
            const userAccount = await UsersApi.accountDetail();
            const userProfile = await UsersApi.profilesRead({ id: this.props.loggedInUsername });
            const unformattedTZ = userAccount.timezone ? userAccount.timezone : null;
            if (unformattedTZ) {
                // Use the moment-timezone package to format the timezone. For example, (GMT+00:00)Africa/Timbuktu.
                const timezone = (' (GMT' + moment.tz(unformattedTZ!).format('Z') + ')' + unformattedTZ!);
                this.setState({ timeZoneValue: timezone });
            }
            this.setState({
                loading: false,
                userAccountDetails: userAccount,
                userProfileDetails: userProfile,
                dateOfBirthValue: userAccount.dateOfBirth ? CalendarDate.fromJsDate(userAccount.dateOfBirth) : undefined,
                country: userAccount.country,
                fullName: userAccount.fullName,
            });
        } catch (err) {
            this.setState({ loading: false });
        }
    }

    public render() {
        if (this.state.redirectTo) { return <Redirect to={this.state.redirectTo} push={true} />; }
        if (this.state.userAccountDetails === undefined) {
            if (this.state.loading) {
                return (
                    <StandardPageLayout headerFeature={this.renderHeader()}>
                        <p><WrappedMessage message={uiMessages.uiLoading} /></p>
                    </StandardPageLayout>
                );
            } else {
                // We weren't able to find the user profile
                return <RegistrationGate
                    title={intl.formatMessage(messages.notLoggedInSettingsAlertTitle)}
                    body={intl.formatMessage(messages.notLoggedInSettingsAlertBody)}
                    primaryButtonText={uiMessages.uiSignUp}
                    secondaryButtonText={uiMessages.uiSignIn}
                    image='/assets/images/access.svg'
                />;
            }
        }

        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                containerClassName='container-fluid'
                otherClassName='account-settings-container p-0'
                headerFeature={this.renderHeader()}
            >
                <div className='account-settings-wrapper white-box'>
                    <div className='side-bar'>
                        <div className='profile-icon'>
                        </div>
                        <div className='section-nav'>
                            <nav aria-label={intl.formatMessage(messages.pageTitle)}>
                                <div className={classNames(
                                    'section',
                                    {
                                        active: this.props.location.hash === '#basic-info' ||
                                            this.props.location.hash === ''
                                    })}>
                                    <a href='#basic-info' className='nav-item text-header'>
                                        <WrappedMessage message={messages.BasicAccountInfoTitle} />
                                    </a>
                                </div>
                                <div className={classNames(
                                    'section',
                                    { active: this.props.location.hash === '#reset-password' })}>
                                    <a href='#reset-password' className='nav-item text-header'>
                                        <WrappedMessage message={messages.ResetPasswordTitle} />
                                    </a>
                                </div>
                                <div className={classNames(
                                    'section',
                                    { active: this.props.location.hash === '#notifications' })}>
                                    <a href='#notifications' className='nav-item text-header'>
                                        <WrappedMessage message={messages.notificationSettingsTitle} />
                                    </a>
                                </div>
                                <div className={classNames(
                                    'section',
                                    { active: this.props.location.hash === '#delete-account' })}>
                                    <a href='#delete-account' className='nav-item text-header'>
                                        <WrappedMessage message={messages.DeleteAccountSideBarText} />
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className='content-bar'>
                        <SettingsSection id='basic-info' title={messages.BasicAccountInfoTitle}>
                            <div className='basic-info-details'>
                                <TextFieldEditForm
                                    label={messages.EmailTitle}
                                    value={this.state.userAccountDetails.email}
                                    disabled={true}
                                    testId={'email'}
                                />

                                <TextFieldEditForm
                                    label={messages.FullnameTitle}
                                    value={this.state.fullName}
                                    onSave={(newValue: string) => this.updateFullName(newValue)}
                                    disabled={false}
                                    fullWidth={true}
                                    testId={'fullname'}
                                />

                                <CountryFieldEditForm
                                    label={messages.CountryTitle}
                                    value={this.state.country}
                                    onSave={(newValue: string) => this.updateCountry(newValue)}
                                    disabled={false}
                                />

                                <DateOfBirthFieldEditForm
                                    label={messages.dateOfBirthTitle}
                                    value={this.state.dateOfBirthValue}
                                    userRole={this.state.userProfileDetails?.role}
                                    onSave={(newValue: CalendarDate) => this.updateDateOfBirth(newValue)}
                                    disabled={false}
                                />
                            </div>
                        </SettingsSection>
                        <SettingsSection id='language-switcher' title={messages.LanguageSwitcherTitle}>
                            <div className='language-switcher-details'>
                                <p className='pb-2'><WrappedMessage message={messages.currentLanguage} /></p>
                                {localeInfo.nativeName}{' '}
                                <LocaleSwitcher btnType='text' />
                            </div>
                        </SettingsSection>
                        <SettingsSection id='reset-password' title={messages.ResetPasswordTitle}>
                            <div className='reset-password-section'>
                                <Button
                                    className='reset-password-button'
                                    btnStyle='normal'
                                    newWindow={true}
                                    href={this.state.userAccountDetails.email ? '' : 'https://account.edx.org/#basic-information'}
                                    onClick={() => this.setState({ modalState: ModalState.ResetPassword })}
                                    label={messages.ResetPasswordTitle}
                                />
                            </div>
                        </SettingsSection>
                        <SettingsSection id='notifications' title={messages.notificationSettingsTitle}>
                            <div className='notification-settings-section'>
                                <NotificationSettingsComponent />
                            </div>
                        </SettingsSection>
                        <SettingsSection id='delete-account' title={messages.DeleteAccountTitle}>
                            <div className='delete-text-section'>
                                <div className='delete-wrapper'>
                                    <p>
                                        <WrappedMessage message={messages.deleteMessageLine1} />
                                        <NavLink to={ROUTES.Users.PROFILE_SLUG(this.props.loggedInUsername)} >
                                            <WrappedMessage message={messages.profileLink} />
                                        </NavLink>
                                    </p>
                                    <p><WrappedMessage message={messages.deleteMessageLine2} /></p>
                                    <p><WrappedMessage message={messages.deleteMessageLine3} /></p>
                                    <a href='#basic-info'>
                                        <WrappedMessage message={messages.deleteMessageLine4} />
                                    </a>
                                    <div className='warning-box'>
                                        <span className='warning-icon'></span>
                                        <WrappedMessage message={messages.deleteWarningText1} />
                                    </div>
                                    <div className='warning-box'>
                                        <span className='warning-icon'></span>
                                        <WrappedMessage message={messages.deleteWarningText2} />
                                    </div>
                                    <Button
                                        className='delete-account-button'
                                        btnStyle='outline'
                                        onClick={this.showDeleteAccountModal}
                                        label={messages.deleteButton}
                                    />
                                </div>
                            </div>
                        </SettingsSection>
                    </div>
                </div>
                {this.renderModals()}
            </StandardPageLayout>
        );
    }

    @bind private async handleResetPassword() {
        await UsersApi.resetPassword({ email: this.state.userAccountDetails?.email });
        this.setState({ redirectTo: ROUTES.General.RESET_PASSWORD_EMAIL });
        return;
    }

    private handleSaveNewValue = async (field: string, newValue: string) => {
        if (newValue === undefined) {
            return;
        }
        try {
            const data: any = {};
            data[field] = newValue;
            // TODO: Improve OpenAPI spec for this method so that
            // types and methods can be inferred.
            await UsersApi.profilesPartialUpdate({
                id: this.props.loggedInUsername,
                data,
            });
        } catch (err) { showErrorMessage('Unable to save new value.', { exception: err }); }
    }

    private async updateDateOfBirth(newDoB: CalendarDate) {
        await this.handleSaveNewValue('date_of_birth', newDoB.isoString);
        this.setState({ dateOfBirthValue: newDoB });
    }

    private async updateFullName(fullName: string) {
        await this.handleSaveNewValue('full_name', fullName);
        this.setState({ fullName });
    }

    private async updateCountry(country: string) {
        await this.handleSaveNewValue('country', country);
        this.setState({ country });
    }

    @bind private showDeleteAccountModal() {
        this.setState({ modalState: ModalState.ConfirmDeleteFirst });
    }

    @bind private async performDeleteAccount() {
        try {
            // Delete the account
            await UsersApi.deleteAccount();

            // Redirect to the FAQ page, after logout complete.
            LoginActions.logout(ROUTES.General.FAQ);
        } catch (err) {
            showErrorMessage('Error occurred while deleting account.', { exception: err });
        }
    }

    private renderHeader() {
        return (
            <div className='settings-page-header-content extended'>
                <h1 className='account-settings-title'>
                    <WrappedMessage message={messages.AccountSettingsTitle} />
                </h1>
            </div>
        );
    }

    private renderModals() {
        switch (this.state.modalState) {
            case ModalState.None:
                return;
            case ModalState.ConfirmDeleteFirst:
                return (
                    <ModalConfirmation
                        onCanceled={() => this.setState({ modalState: ModalState.None })}
                        onConfirmed={() => this.setState({ modalState: ModalState.ConfirmDeleteFinal })}
                        modalClassNames='delete-account-modal'
                        title={messages.deleteAccountModalTitleFirst}
                        body={messages.deleteAccountModalConfirmationFirst}
                        confirmButtonText={messages.deleteAccountModalConfirmButtonFirst}
                        cancelButtonText={uiMessages.uiCancelButton}
                        closeButtonText={uiMessages.uiCancelButton}
                    />
                );
            case ModalState.ConfirmDeleteFinal:
                return (
                    <ModalConfirmation
                        onCanceled={() => this.setState({ modalState: ModalState.None })}
                        onConfirmed={() => this.setState({ modalState: ModalState.PerformDeleteAccount })}
                        confirmClassNames='btn-danger'
                        modalClassNames='delete-account-modal'
                        title={messages.deleteAccountModalTitleFinal}
                        body={messages.deleteAccountModalConfirmationFinal}
                        confirmButtonText={messages.deleteAccountModalConfirmButtonFinal}
                        cancelButtonText={uiMessages.uiCancelButton}
                        closeButtonText={uiMessages.uiCancelButton}
                    />
                );
            case ModalState.PerformDeleteAccount:
                this.performDeleteAccount();
                return;
            case ModalState.ResetPassword:
                return <ModalConfirmation
                    onCanceled={() => this.setState({ modalState: ModalState.None })}
                    onConfirmed={this.handleResetPassword}
                    confirmClassNames='btn-danger'
                    confirmButtonText={messages.resetPasswordSubmitButton}
                    cancelButtonText={uiMessages.uiCancelButton}
                    closeButtonText={uiMessages.uiCancelButton}
                    title={messages.resetPasswordSubmitTitle}
                    body={messages.resetPasswordSubmitBody}
                    bodyValues={{ email: this.state.userAccountDetails?.email }}
                />;
            default: return;
        }
    }
}

export const UserProfileSettings = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        loggedInUsername: getUsername(state),
        isLoggedIn: getLoggedInStatus(state),
    }),
)(UserProfileSettingsInternal);
