import { TagData } from 'search/components/Tag/types';
import { defineMessages } from 'react-intl';
import { intl } from 'i18n';

export enum TaxonomyCategory {
    SubjectArea,
    ContentType,
    Country,
    BackgroundKnowledge,
    VideoDuration,
    ContentSource,
    PopularTags,
    Role,
    Institution,
    Interests,
    AreaOfStudy,
    Location,
    Forum,
    Language,
    OrganizationType,
    Empty,
}

export interface TaxonomyData {
    id: string;
    category: TaxonomyCategory;
    tags: ReadonlyArray<TagData>;
}

export enum ContentTypes {
    All = 'All',
    Pathway = 'ItemType:pathway',
    Cluster = 'ItemType:cluster',
    Simulation = 'ItemType:simulation',
    Interactive = 'ItemType:interactive',
    Video = 'ItemType:video',
    Text = 'ItemType:text',
    QuestionSet = 'ItemType:assignment',
    Image = 'ItemType:image',
    CaseStudy = 'ItemType:case_study',
    TeachingGuide = 'ItemType:teaching_guide',
    Narrative = 'ItemType:narrative',
    Document = 'ItemType:document',
    Question = 'ItemType:assessment',
    Audio = 'ItemType:audio',
    Textbook = 'ItemType:book',
    AnnnotatedVideo = 'ItemType:annotated_video',
    Link = 'ItemType:link',
}

const messages = defineMessages({
    contentTypeFilterAll: {
        id: 'contentTypeFilterAll',
        defaultMessage: 'All'
    },
    contentTypeFilterPathways: {
        id: 'contentTypeFilterPathways',
        defaultMessage: 'Pathways'
    },
    contentTypeFilterClusters: {
        id: 'contentTypeFilterClusters',
        defaultMessage: 'Clusters'
    },
    contentTypeFilterSimulations: {
        id: 'contentTypeFilterSimulations',
        defaultMessage: 'Simulations'
    },
    contentTypeFilterInteractives: {
        id: 'contentTypeFilterInteractives',
        defaultMessage: 'Interactives'
    },
    contentTypeFilterVideos: {
        id: 'contentTypeFilterVideos',
        defaultMessage: 'Videos'
    },
    contentTypeFilterTexts: {
        id: 'contentTypeFilterTexts',
        defaultMessage: 'Texts'
    },
    contentTypeFilterQuestionSets: {
        id: 'contentTypeFilterQuestionSets',
        defaultMessage: 'Question Sets'
    },
    contentTypeFilterImages: {
        id: 'contentTypeFilterImages',
        defaultMessage: 'Images'
    },
    contentTypeFilterCaseStudies: {
        id: 'contentTypeFilterCaseStudies',
        defaultMessage: 'Case Studies'
    },
    contentTypeFilterTeachingGuides: {
        id: 'contentTypeFilterTeachingGuides',
        defaultMessage: 'Teaching Guides'
    },
    contentTypeFilterNarratives: {
        id: 'contentTypeFilterNarratives',
        defaultMessage: 'Narratives'
    },
    contentTypeFilterDocuments: {
        id: 'contentTypeFilterDocuments',
        defaultMessage: 'Documents'
    },
    contentTypeFilterQuestions: {
        id: 'contentTypeFilterQuestions',
        defaultMessage: 'Questions'
    },
    contentTypeFilterAudio: {
        id: 'contentTypeFilterAudio',
        defaultMessage: 'Audio'
    },
    contentTypeFilterTextbooks: {
        id: 'contentTypeFilterTextbooks',
        defaultMessage: 'Textbooks'
    },
    contentTypeFilterAnnotatedVideos: {
        id: 'contentTypeFilterAnnotatedVideos',
        defaultMessage: 'Annotated Videos'
    },
    contentTypeFilterLink: {
        id: 'contentTypeFilterLink',
        defaultMessage: 'Google Doc'
    }
});

// We need these mappings because in the code, we use content type IDS for comparison,
// but on the UI, we want to show the readable form of these filters.
export const singularToPluralMapping = {
    [ContentTypes.All]: intl.formatMessage(messages.contentTypeFilterAll),
    [ContentTypes.Pathway]: intl.formatMessage(messages.contentTypeFilterPathways),
    [ContentTypes.Cluster]: intl.formatMessage(messages.contentTypeFilterClusters),
    [ContentTypes.Simulation]: intl.formatMessage(messages.contentTypeFilterSimulations),
    [ContentTypes.Interactive]: intl.formatMessage(messages.contentTypeFilterInteractives),
    [ContentTypes.Video]: intl.formatMessage(messages.contentTypeFilterVideos),
    [ContentTypes.Text]: intl.formatMessage(messages.contentTypeFilterTexts),
    [ContentTypes.QuestionSet]: intl.formatMessage(messages.contentTypeFilterQuestionSets),
    [ContentTypes.Image]: intl.formatMessage(messages.contentTypeFilterImages),
    [ContentTypes.CaseStudy]: intl.formatMessage(messages.contentTypeFilterCaseStudies),
    [ContentTypes.TeachingGuide]: intl.formatMessage(messages.contentTypeFilterTeachingGuides),
    [ContentTypes.Narrative]: intl.formatMessage(messages.contentTypeFilterNarratives),
    [ContentTypes.Document]: intl.formatMessage(messages.contentTypeFilterDocuments),
    [ContentTypes.Question]: intl.formatMessage(messages.contentTypeFilterQuestions),
    [ContentTypes.Audio]: intl.formatMessage(messages.contentTypeFilterAudio),
    [ContentTypes.Textbook]: intl.formatMessage(messages.contentTypeFilterTextbooks),
    [ContentTypes.AnnnotatedVideo]: intl.formatMessage(messages.contentTypeFilterAnnotatedVideos),
    [ContentTypes.Link]: intl.formatMessage(messages.contentTypeFilterLink),
};
