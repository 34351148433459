import { LxConfig } from 'global/constants';
import { getStore } from 'global/store';

import { fetchNotifications } from './actions';

export let fetchNotificationsTimer: number;

export async function fetchNotificationsPoll(dispatch = true) {
    /* Clear timer, if one is running. */
    if (fetchNotificationsTimer) {
        window.clearTimeout(fetchNotificationsTimer);
    }

    /* Dispatch the action and wait. */
    if (dispatch) {
        await getStore().dispatch(fetchNotifications() as any);
    }

    /* Schedule the next tick. */
    fetchNotificationsTimer = window.setTimeout(fetchNotificationsPoll, LxConfig.NotificationPollDefaultInterval);
}
