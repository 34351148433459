import { defineMessages } from 'react-intl';

const messages = defineMessages({
    navlinkContent: {
        id:'navlinkContent',
        defaultMessage: 'Content',
        description: 'Header Navlink Tab to Content',
    },
    navlinkReport: {
        id:'navlinkReport',
        defaultMessage: 'Progress',
        description: 'Header Navlink Tab to classroom progress report.',
    },
    navlinkLearners: {
        id:'navlinkLearners',
        defaultMessage: 'Learners',
        description: 'Header Navlink Tab to Learners',
    },
    navlinkDiscussion: {
        id:'navlinkDiscussion',
        defaultMessage: 'Discussion',
        description: 'Header Navlink Tab to Discussion',
    },
    learnerView: {
        id:'learnerView',
        defaultMessage: 'Learner view',
        description: 'Label for the learner view toggle in the educator view of the classroom page.',
    },
    classSettingsButtonText: {
        id:'classSettingsButtonText',
        defaultMessage: 'Class Settings',
        description: 'Label for Class Settings button, which is right aligned on the header of a classroom page, if the currently logged in user has permissions to edit the classroom',
    },
    copyClassCodeButtonText: {
        id:'copyClassCodeButtonText',
        defaultMessage: 'Copy code',
        description: 'Label for Copy code button, in the header of a classroom page, for users with permissions to edit a classroom',
    },
    copyClassCodeButtonTextAria: {
        id:'copyClassCodeButtonTextAria',
        defaultMessage: 'Copy class code',
        description: 'Aria label for copy code button on classroom page',
    },
});

export default messages;
