import { getLoggedInStatus } from 'auth/selectors';
import { NotificationsApi } from 'global/api';
import { LXThunkAction } from 'global/types';
import { NotificationResponse } from 'labxchange-client';
import { Action } from 'redux';

export enum Types {
    CACHE_NOTIFICATIONS = 'CACHE_NOTIFICATIONS',
    MARK_ALL_NOTIFICATIONS_READ = 'MARK_ALL_NOTIFICATIONS_READ',
    MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ',
    MARK_NOTIFICATION_UNREAD = 'MARK_NOTIFICATION_UNREAD',
}

export interface FetchNotifications extends Action {
    readonly type: Types.CACHE_NOTIFICATIONS;
    readonly response: Readonly<NotificationResponse>;
}

export interface MarkAllNotificationsRead extends Action {
    readonly type: Types.MARK_ALL_NOTIFICATIONS_READ;
}

export interface MarkNotificationRead extends Action {
    readonly type: Types.MARK_NOTIFICATION_READ;
    readonly notificationId: string;
}

export interface MarkNotificationUnread extends Action {
    readonly type: Types.MARK_NOTIFICATION_UNREAD;
    readonly notificationId: string;
}

export type ActionTypes =
    | FetchNotifications
    | MarkAllNotificationsRead
    | MarkNotificationRead
    | MarkNotificationUnread;

export const fetchNotifications = (): LXThunkAction<void> => async (dispatch, getState) => {
    const isLoggedIn = getLoggedInStatus(getState());
    if (isLoggedIn) {
        const response = await NotificationsApi.list();
        dispatch({type: Types.CACHE_NOTIFICATIONS, response});
    }
};

export const markAllNotificationsRead = (): LXThunkAction<void> => async (dispatch, getState) => {
    NotificationsApi.markAllRead();
    dispatch({type: Types.MARK_ALL_NOTIFICATIONS_READ});
};

export const markNotificationRead = (notificationId: string): LXThunkAction<void> => async (dispatch, getState) => {
    NotificationsApi.markRead({id: notificationId});
    dispatch({type: Types.MARK_NOTIFICATION_READ, notificationId});
};

export const markNotificationUnread = (notificationId: string): LXThunkAction<void> => async (dispatch, getState) => {
    NotificationsApi.markUnread({id: notificationId});
    dispatch({type: Types.MARK_NOTIFICATION_UNREAD, notificationId});
};
