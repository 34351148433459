// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FeaturedItems
 */
export interface FeaturedItems {
    /**
     * 
     * @type {string}
     * @memberof FeaturedItems
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedItems
     */
    description: string;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof FeaturedItems
     */
    items: Array<ItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof FeaturedItems
     */
    seeMoreLink: string;
}

export function FeaturedItemsFromJSON(json: any): FeaturedItems {
    return {
        'name': json['name'],
        'description': json['description'],
        'items': (json['items'] as Array<any>).map(ItemResponseFromJSON),
        'seeMoreLink': json['see_more_link'],
    };
}

export function FeaturedItemsToJSON(value?: FeaturedItems): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'items': (value.items as Array<any>).map(ItemResponseToJSON),
        'see_more_link': value.seeMoreLink,
    };
}


