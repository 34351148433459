import * as React from 'react';
import { ThreadWithPosts } from 'labxchange-client';
import { DiscussionsApi } from 'global/api';
import messages from 'discussions/displayMessages';
import { ShareModal } from 'ui/components/ShareModal';

import { DiscussionBreadcrumb } from 'library/components/Breadcrumb';
import { ROUTES } from 'global/constants';
import { RouteComponentProps } from 'react-router';
import { ClassroomDiscussionThreadInternal } from 'classrooms/components';
import { connect } from 'react-redux';
import { RootState } from 'global/state';
import { getUsername } from 'auth/selectors';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/actions';
import { WrappedMessage } from 'utils';
import uiMessages from 'ui/components/displayMessages';
import { InvalidContent } from 'library/components/InvalidContent';
import { CommunityLayout } from 'dashboard';

interface MatchProps {
    subCategory: string;
    thread: string;
}

interface InternalProps extends ReduxStateProps, RouteComponentProps<MatchProps> {
}

interface InternalState {
    thread?: ThreadWithPosts;
    loading: boolean;
    shareModalVisible: boolean;
}

export class DiscussionThreadInternal extends React.PureComponent<InternalProps, InternalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            shareModalVisible: false,
        };
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public async componentDidUpdate(prevProps: InternalProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({thread: undefined});
            await this.loadData();
        }
    }

    public async loadData() {
        this.setState({loading: true});
        try {
            const thread = await DiscussionsApi.threadsRead({
                id: this.props.match.params.thread,
            });
            this.setState({thread});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorLoadingDiscussion} />, {exception: err});
        }
        this.setState({loading: false});
    }

    public render() {
        if (this.state.loading) {
            return (
                <CommunityLayout pageTitle={uiMessages.uiLoading}>
                    <WrappedMessage message={uiMessages.uiLoading} />
                </CommunityLayout>
            );
        }
        if (this.state.thread) {
            const threadId = this.props.match.params.thread;
            const categoryId = this.state.thread.thread.category;
            const categoryName = this.state.thread.thread.categoryName;
            const subCategoryId = this.state.thread.thread.subCategory;
            const subCategoryName = this.state.thread.thread.subCategoryName;
            return <CommunityLayout
                pageTitle={messages.discussionsCategoryPageTitle}
                pageTitleValues={{name: this.state.thread.thread.title}}
            >
                <DiscussionBreadcrumb
                    navLinksInfo={[
                        {
                            route: ROUTES.Community.CATEGORY_ROUTE_SLUG(categoryId ? categoryId : ''),
                            message: categoryName ? categoryName : '',
                        },
                        {
                            route: ROUTES.Community.SUBCATEGORY_ROUTE_SLUG(subCategoryId ? subCategoryId : ''),
                            message: subCategoryName ? subCategoryName : '',
                        },
                    ]}
                />

                {this.state.shareModalVisible && <ShareModal
                    getItemUrl={() => ROUTES.Community.SUBCATEGORY_THREAD_ROUTE_SLUG(subCategoryId, threadId)}
                    itemTitle={this.state.thread.thread.title}
                    itemDescription={this.state.thread.posts[0].html}
                    itemImageUrl={'/assets/images/icons/discussion.svg'}
                    onClose={() => {
                        this.setState({shareModalVisible: false});
                    }}
                />}
                <ClassroomDiscussionThreadInternal
                    subCategoryId={subCategoryId}
                    threadId={threadId}
                    thread={this.state.thread}
                    onShare={() => this.setState({shareModalVisible: true})}
                    {...this.props}
                />
            </CommunityLayout>;
        } else {
            return <InvalidContent />;
        }
    }
}

interface ReduxStateProps {
    loggedInUsername?: string;
}

export const DiscussionThread = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        loggedInUsername: getUsername(state),
    }),
)(DiscussionThreadInternal);
