// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Classroom,
    ClassroomFromJSON,
    ClassroomToJSON,
    ClassroomAssignmentCreate,
    ClassroomAssignmentCreateFromJSON,
    ClassroomAssignmentCreateToJSON,
    ClassroomAssignmentResponse,
    ClassroomAssignmentResponseFromJSON,
    ClassroomAssignmentResponseToJSON,
    ClassroomDetail,
    ClassroomDetailFromJSON,
    ClassroomDetailToJSON,
    ClassroomEducatorList,
    ClassroomEducatorListFromJSON,
    ClassroomEducatorListToJSON,
    ClassroomError,
    ClassroomErrorFromJSON,
    ClassroomErrorToJSON,
    ClassroomGenericResponse,
    ClassroomGenericResponseFromJSON,
    ClassroomGenericResponseToJSON,
    ClassroomImageUpload,
    ClassroomImageUploadFromJSON,
    ClassroomImageUploadToJSON,
    ClassroomItem,
    ClassroomItemFromJSON,
    ClassroomItemToJSON,
    ClassroomItemCreate,
    ClassroomItemCreateFromJSON,
    ClassroomItemCreateToJSON,
    ClassroomItemCreateMany,
    ClassroomItemCreateManyFromJSON,
    ClassroomItemCreateManyToJSON,
    ClassroomItemOrderRequest,
    ClassroomItemOrderRequestFromJSON,
    ClassroomItemOrderRequestToJSON,
    ClassroomItemUpdate,
    ClassroomItemUpdateFromJSON,
    ClassroomItemUpdateToJSON,
    ClassroomJoinRequest,
    ClassroomJoinRequestFromJSON,
    ClassroomJoinRequestToJSON,
    ClassroomLeaveRequest,
    ClassroomLeaveRequestFromJSON,
    ClassroomLeaveRequestToJSON,
    ClassroomList,
    ClassroomListFromJSON,
    ClassroomListToJSON,
    ClassroomMembership,
    ClassroomMembershipFromJSON,
    ClassroomMembershipToJSON,
    ClassroomMembershipAcceptAll,
    ClassroomMembershipAcceptAllFromJSON,
    ClassroomMembershipAcceptAllToJSON,
    ClassroomMembershipList,
    ClassroomMembershipListFromJSON,
    ClassroomMembershipListToJSON,
    ClassroomMembershipUpdate,
    ClassroomMembershipUpdateFromJSON,
    ClassroomMembershipUpdateToJSON,
    ClassroomReport,
    ClassroomReportFromJSON,
    ClassroomReportToJSON,
    ClassroomThreadCreate,
    ClassroomThreadCreateFromJSON,
    ClassroomThreadCreateToJSON,
    ClassroomThreadDelete,
    ClassroomThreadDeleteFromJSON,
    ClassroomThreadDeleteToJSON,
    ClassroomThreadFollow,
    ClassroomThreadFollowFromJSON,
    ClassroomThreadFollowToJSON,
    ClassroomThreadLike,
    ClassroomThreadLikeFromJSON,
    ClassroomThreadLikeToJSON,
    ClassroomThreadPostCreate,
    ClassroomThreadPostCreateFromJSON,
    ClassroomThreadPostCreateToJSON,
    ClassroomThreadPostDelete,
    ClassroomThreadPostDeleteFromJSON,
    ClassroomThreadPostDeleteToJSON,
    ClassroomThreadPostUpdate,
    ClassroomThreadPostUpdateFromJSON,
    ClassroomThreadPostUpdateToJSON,
    ClassroomThreadUpdate,
    ClassroomThreadUpdateFromJSON,
    ClassroomThreadUpdateToJSON,
    ClassroomUpdate,
    ClassroomUpdateFromJSON,
    ClassroomUpdateToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    LearnerReport,
    LearnerReportFromJSON,
    LearnerReportToJSON,
    Thread,
    ThreadFromJSON,
    ThreadToJSON,
    ThreadList,
    ThreadListFromJSON,
    ThreadListToJSON,
    ThreadPost,
    ThreadPostFromJSON,
    ThreadPostToJSON,
    ThreadWithPosts,
    ThreadWithPostsFromJSON,
    ThreadWithPostsToJSON,
} from '../models';

export interface DeleteRequest {
    id: string;
}

export interface ClassProgressRequest {
    id: string;
    cached?: boolean;
    csvExport?: boolean;
}

export interface ClassroomItemsCreateRequest {
    data: ClassroomItemCreate;
}

export interface ClassroomItemsCreateManyRequest {
    data: ClassroomItemCreateMany;
}

export interface ClassroomItemsDeleteRequest {
    id: string;
}

export interface ClassroomItemsListRequest {
    page?: number;
    classroomId?: string;
}

export interface ClassroomItemsPartialUpdateRequest {
    id: string;
    data: ClassroomItemUpdate;
}

export interface ClassroomItemsReadRequest {
    id: string;
}

export interface ClassroomItemsReorderRequest {
    data: ClassroomItemOrderRequest;
}

export interface ClassroomItemsUpdateRequest {
    id: string;
    data: ClassroomItem;
}

export interface ClassroomMembershipsAcceptAllRequest {
    data: ClassroomMembershipAcceptAll;
}

export interface ClassroomMembershipsAssignRequest {
    id: string;
    data: ClassroomAssignmentCreate;
}

export interface ClassroomMembershipsListMembershipsForClassroomRequest {
    id: string;
    status?: string;
    currentPage?: number;
    paginationSize?: number;
    ordering?: string;
    searchQuery?: string;
}

export interface ClassroomMembershipsPartialUpdateRequest {
    id: string;
    data: ClassroomMembershipUpdate;
}

export interface ClassroomMembershipsUpdateRequest {
    id: string;
    data: ClassroomMembership;
}

export interface ClassroomThreadsCreateRequest {
    data: ClassroomThreadCreate;
}

export interface ClassroomThreadsDeleteRequest {
    id: string;
    data: ClassroomThreadDelete;
}

export interface ClassroomThreadsFollowRequest {
    id: string;
    data: ClassroomThreadFollow;
}

export interface ClassroomThreadsLikeRequest {
    id: string;
    data: ClassroomThreadLike;
}

export interface ClassroomThreadsListRequest {
    classroomId?: string;
    currentPage?: number;
    paginationSize?: number;
    ordering?: string;
}

export interface ClassroomThreadsPartialUpdateRequest {
    id: string;
    data: ClassroomThreadUpdate;
}

export interface ClassroomThreadsPostCreateRequest {
    id: string;
    data: ClassroomThreadPostCreate;
}

export interface ClassroomThreadsPostDeleteRequest {
    id: string;
    data: ClassroomThreadPostDelete;
}

export interface ClassroomThreadsPostPartialUpdateRequest {
    id: string;
    data: ClassroomThreadPostUpdate;
}

export interface ClassroomThreadsReadRequest {
    id: string;
    classroomId?: string;
}

export interface CopyRequest {
    id: string;
}

export interface CreateRequest {
    data: Classroom;
}

export interface ImageCreateRequest {
    id: string;
    image?: Blob;
}

export interface ImageDeleteRequest {
    id: string;
}

export interface InactiveAsLearnerRequest {
    page?: number;
}

export interface JoinRequest {
    data: ClassroomJoinRequest;
}

export interface LearnerProgressRequest {
    id: string;
    username?: string;
}

export interface LeaveRequest {
    data: ClassroomLeaveRequest;
}

export interface ListRequest {
    page?: number;
}

export interface ListAsEducatorRequest {
    page?: number;
    search?: string;
}

export interface ListAsLearnerRequest {
    page?: number;
}

export interface MarkIncompleteRequest {
    id: string;
    data: any;
}

export interface PartialUpdateRequest {
    id: string;
    data: ClassroomUpdate;
}

export interface ReadRequest {
    id: string;
}

export interface RejoinRequest {
    id: string;
    data: any;
}

export interface UpdateRequest {
    id: string;
    data: Classroom;
}

/**
 * no description
 */
export class ClassroomsApi extends runtime.BaseAPI {

    /**
     * Delete a classroom
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling _delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a classroom
     */
    async _delete(requestParameters: DeleteRequest): Promise<void> {
        await this._deleteRaw(requestParameters);
    }

    /**
     * Returns the classroom members' assignment progress and scores.
     */
    async classProgressRaw(requestParameters: ClassProgressRequest): Promise<runtime.ApiResponse<ClassroomReport>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classProgress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cached !== undefined) {
            queryParameters['cached'] = requestParameters.cached;
        }

        if (requestParameters.csvExport !== undefined) {
            queryParameters['csv_export'] = requestParameters.csvExport;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}/class_progress`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomReportFromJSON(jsonValue));
    }

    /**
     * Returns the classroom members' assignment progress and scores.
     */
    async classProgress(requestParameters: ClassProgressRequest): Promise<ClassroomReport> {
        const response = await this.classProgressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create ClassroomItem, which represents an association between an ItemMetadata and a Classroom.
     */
    async classroomItemsCreateRaw(requestParameters: ClassroomItemsCreateRequest): Promise<runtime.ApiResponse<ClassroomItemCreate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomItemsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomItemCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomItemCreateFromJSON(jsonValue));
    }

    /**
     * Create ClassroomItem, which represents an association between an ItemMetadata and a Classroom.
     */
    async classroomItemsCreate(requestParameters: ClassroomItemsCreateRequest): Promise<ClassroomItemCreate> {
        const response = await this.classroomItemsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Bulk create ClassroomItems. If one fails, they all fail.
     */
    async classroomItemsCreateManyRaw(requestParameters: ClassroomItemsCreateManyRequest): Promise<runtime.ApiResponse<ClassroomItemCreateMany>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomItemsCreateMany.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items/create_many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomItemCreateManyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomItemCreateManyFromJSON(jsonValue));
    }

    /**
     * Bulk create ClassroomItems. If one fails, they all fail.
     */
    async classroomItemsCreateMany(requestParameters: ClassroomItemsCreateManyRequest): Promise<ClassroomItemCreateMany> {
        const response = await this.classroomItemsCreateManyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Classrooms Content API
     */
    async classroomItemsDeleteRaw(requestParameters: ClassroomItemsDeleteRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomItemsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Viewset for Classrooms Content API
     */
    async classroomItemsDelete(requestParameters: ClassroomItemsDeleteRequest): Promise<string> {
        const response = await this.classroomItemsDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * List ClassroomItems for a Classroom that a user can edit, or is a member of. Currently we don't use this, as items are inlined in Classroom Detail.
     */
    async classroomItemsListRaw(requestParameters: ClassroomItemsListRequest): Promise<runtime.ApiResponse<Array<ClassroomItem>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.classroomId !== undefined) {
            queryParameters['classroom_id'] = requestParameters.classroomId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomItemFromJSON));
    }

    /**
     * List ClassroomItems for a Classroom that a user can edit, or is a member of. Currently we don't use this, as items are inlined in Classroom Detail.
     */
    async classroomItemsList(requestParameters: ClassroomItemsListRequest): Promise<Array<ClassroomItem>> {
        const response = await this.classroomItemsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update ClassroomItem
     */
    async classroomItemsPartialUpdateRaw(requestParameters: ClassroomItemsPartialUpdateRequest): Promise<runtime.ApiResponse<ClassroomItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomItemsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomItemsPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomItemUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomItemFromJSON(jsonValue));
    }

    /**
     * Update ClassroomItem
     */
    async classroomItemsPartialUpdate(requestParameters: ClassroomItemsPartialUpdateRequest): Promise<ClassroomItem> {
        const response = await this.classroomItemsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Classrooms Content API
     */
    async classroomItemsReadRaw(requestParameters: ClassroomItemsReadRequest): Promise<runtime.ApiResponse<ClassroomItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomItemsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomItemFromJSON(jsonValue));
    }

    /**
     * Viewset for Classrooms Content API
     */
    async classroomItemsRead(requestParameters: ClassroomItemsReadRequest): Promise<ClassroomItem> {
        const response = await this.classroomItemsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update classrooms items orders
     */
    async classroomItemsReorderRaw(requestParameters: ClassroomItemsReorderRequest): Promise<runtime.ApiResponse<Array<ClassroomItem>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomItemsReorder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items/reorder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomItemOrderRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomItemFromJSON));
    }

    /**
     * Update classrooms items orders
     */
    async classroomItemsReorder(requestParameters: ClassroomItemsReorderRequest): Promise<Array<ClassroomItem>> {
        const response = await this.classroomItemsReorderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Classrooms Content API
     */
    async classroomItemsUpdateRaw(requestParameters: ClassroomItemsUpdateRequest): Promise<runtime.ApiResponse<ClassroomItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomItemsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomItemsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomItemToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomItemFromJSON(jsonValue));
    }

    /**
     * Viewset for Classrooms Content API
     */
    async classroomItemsUpdate(requestParameters: ClassroomItemsUpdateRequest): Promise<ClassroomItem> {
        const response = await this.classroomItemsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update all pending classroom memberships to accepted
     */
    async classroomMembershipsAcceptAllRaw(requestParameters: ClassroomMembershipsAcceptAllRequest): Promise<runtime.ApiResponse<Array<ClassroomMembership>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomMembershipsAcceptAll.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-memberships/accept_all`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomMembershipAcceptAllToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomMembershipFromJSON));
    }

    /**
     * Update all pending classroom memberships to accepted
     */
    async classroomMembershipsAcceptAll(requestParameters: ClassroomMembershipsAcceptAllRequest): Promise<Array<ClassroomMembership>> {
        const response = await this.classroomMembershipsAcceptAllRaw(requestParameters);
        return await response.value();
    }

    /**
     * Assign item a classroom learner
     */
    async classroomMembershipsAssignRaw(requestParameters: ClassroomMembershipsAssignRequest): Promise<runtime.ApiResponse<Array<ClassroomAssignmentResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomMembershipsAssign.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomMembershipsAssign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-memberships/{id}/assign`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomAssignmentCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomAssignmentResponseFromJSON));
    }

    /**
     * Assign item a classroom learner
     */
    async classroomMembershipsAssign(requestParameters: ClassroomMembershipsAssignRequest): Promise<Array<ClassroomAssignmentResponse>> {
        const response = await this.classroomMembershipsAssignRaw(requestParameters);
        return await response.value();
    }

    /**
     * List ClassroomMemberships for a particular classroom, optionally filtered by status
     */
    async classroomMembershipsListMembershipsForClassroomRaw(requestParameters: ClassroomMembershipsListMembershipsForClassroomRequest): Promise<runtime.ApiResponse<ClassroomMembershipList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomMembershipsListMembershipsForClassroom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.paginationSize !== undefined) {
            queryParameters['pagination_size'] = requestParameters.paginationSize;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['search_query'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-memberships/{id}/list_memberships_for_classroom`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomMembershipListFromJSON(jsonValue));
    }

    /**
     * List ClassroomMemberships for a particular classroom, optionally filtered by status
     */
    async classroomMembershipsListMembershipsForClassroom(requestParameters: ClassroomMembershipsListMembershipsForClassroomRequest): Promise<ClassroomMembershipList> {
        const response = await this.classroomMembershipsListMembershipsForClassroomRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update ClassroomMembership state
     */
    async classroomMembershipsPartialUpdateRaw(requestParameters: ClassroomMembershipsPartialUpdateRequest): Promise<runtime.ApiResponse<ClassroomMembership>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomMembershipsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomMembershipsPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-memberships/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomMembershipUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomMembershipFromJSON(jsonValue));
    }

    /**
     * Update ClassroomMembership state
     */
    async classroomMembershipsPartialUpdate(requestParameters: ClassroomMembershipsPartialUpdateRequest): Promise<ClassroomMembership> {
        const response = await this.classroomMembershipsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Classroom Membership API
     */
    async classroomMembershipsUpdateRaw(requestParameters: ClassroomMembershipsUpdateRequest): Promise<runtime.ApiResponse<ClassroomMembership>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomMembershipsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomMembershipsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-memberships/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomMembershipToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomMembershipFromJSON(jsonValue));
    }

    /**
     * Viewset for Classroom Membership API
     */
    async classroomMembershipsUpdate(requestParameters: ClassroomMembershipsUpdateRequest): Promise<ClassroomMembership> {
        const response = await this.classroomMembershipsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a classroom thread
     */
    async classroomThreadsCreateRaw(requestParameters: ClassroomThreadsCreateRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Create a classroom thread
     */
    async classroomThreadsCreate(requestParameters: ClassroomThreadsCreateRequest): Promise<Thread> {
        const response = await this.classroomThreadsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a classroom thread
     */
    async classroomThreadsDeleteRaw(requestParameters: ClassroomThreadsDeleteRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsDelete.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadDeleteToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Delete a classroom thread
     */
    async classroomThreadsDelete(requestParameters: ClassroomThreadsDeleteRequest): Promise<string> {
        const response = await this.classroomThreadsDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Follow a thread
     */
    async classroomThreadsFollowRaw(requestParameters: ClassroomThreadsFollowRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsFollow.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsFollow.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}/follow`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadFollowToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Follow a thread
     */
    async classroomThreadsFollow(requestParameters: ClassroomThreadsFollowRequest): Promise<Thread> {
        const response = await this.classroomThreadsFollowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Like a thread
     */
    async classroomThreadsLikeRaw(requestParameters: ClassroomThreadsLikeRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsLike.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsLike.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}/like`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadLikeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Like a thread
     */
    async classroomThreadsLike(requestParameters: ClassroomThreadsLikeRequest): Promise<Thread> {
        const response = await this.classroomThreadsLikeRaw(requestParameters);
        return await response.value();
    }

    /**
     * List classroom threads
     */
    async classroomThreadsListRaw(requestParameters: ClassroomThreadsListRequest): Promise<runtime.ApiResponse<ThreadList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.classroomId !== undefined) {
            queryParameters['classroom_id'] = requestParameters.classroomId;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.paginationSize !== undefined) {
            queryParameters['pagination_size'] = requestParameters.paginationSize;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadListFromJSON(jsonValue));
    }

    /**
     * List classroom threads
     */
    async classroomThreadsList(requestParameters: ClassroomThreadsListRequest): Promise<ThreadList> {
        const response = await this.classroomThreadsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a classroom thread
     */
    async classroomThreadsPartialUpdateRaw(requestParameters: ClassroomThreadsPartialUpdateRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Update a classroom thread
     */
    async classroomThreadsPartialUpdate(requestParameters: ClassroomThreadsPartialUpdateRequest): Promise<Thread> {
        const response = await this.classroomThreadsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new thread post
     */
    async classroomThreadsPostCreateRaw(requestParameters: ClassroomThreadsPostCreateRequest): Promise<runtime.ApiResponse<ThreadPost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsPostCreate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsPostCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}/post`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadPostCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadPostFromJSON(jsonValue));
    }

    /**
     * Create a new thread post
     */
    async classroomThreadsPostCreate(requestParameters: ClassroomThreadsPostCreateRequest): Promise<ThreadPost> {
        const response = await this.classroomThreadsPostCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new thread post
     */
    async classroomThreadsPostDeleteRaw(requestParameters: ClassroomThreadsPostDeleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsPostDelete.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsPostDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}/post`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadPostDeleteToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Create a new thread post
     */
    async classroomThreadsPostDelete(requestParameters: ClassroomThreadsPostDeleteRequest): Promise<any> {
        const response = await this.classroomThreadsPostDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new thread post
     */
    async classroomThreadsPostPartialUpdateRaw(requestParameters: ClassroomThreadsPostPartialUpdateRequest): Promise<runtime.ApiResponse<ThreadPost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsPostPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling classroomThreadsPostPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}/post`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomThreadPostUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadPostFromJSON(jsonValue));
    }

    /**
     * Create a new thread post
     */
    async classroomThreadsPostPartialUpdate(requestParameters: ClassroomThreadsPostPartialUpdateRequest): Promise<ThreadPost> {
        const response = await this.classroomThreadsPostPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a thread with its posts
     */
    async classroomThreadsReadRaw(requestParameters: ClassroomThreadsReadRequest): Promise<runtime.ApiResponse<ThreadWithPosts>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling classroomThreadsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.classroomId !== undefined) {
            queryParameters['classroom_id'] = requestParameters.classroomId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/classroom-threads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadWithPostsFromJSON(jsonValue));
    }

    /**
     * Retrieve a thread with its posts
     */
    async classroomThreadsRead(requestParameters: ClassroomThreadsReadRequest): Promise<ThreadWithPosts> {
        const response = await this.classroomThreadsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the newly created classroom's data.
     * Copy a classroom and its items, marking them as unposted.  Do not copy discussions or membership.
     */
    async copyRaw(requestParameters: CopyRequest): Promise<runtime.ApiResponse<Classroom>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling copy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}/copy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomFromJSON(jsonValue));
    }

    /**
     * Returns the newly created classroom's data.
     * Copy a classroom and its items, marking them as unposted.  Do not copy discussions or membership.
     */
    async copy(requestParameters: CopyRequest): Promise<Classroom> {
        const response = await this.copyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create classroom if logged-in user has the appropriate permissions
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<Classroom>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling create.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomFromJSON(jsonValue));
    }

    /**
     * Create classroom if logged-in user has the appropriate permissions
     */
    async create(requestParameters: CreateRequest): Promise<Classroom> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send a POST to /api/v1/classrooms/<classroom id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images associated with classrooms
     */
    async imageCreateRaw(requestParameters: ImageCreateRequest): Promise<runtime.ApiResponse<ClassroomImageUpload>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const formData = new FormData();
        if (requestParameters.image !== undefined) {
            formData.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/classrooms/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomImageUploadFromJSON(jsonValue));
    }

    /**
     * Send a POST to /api/v1/classrooms/<classroom id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images associated with classrooms
     */
    async imageCreate(requestParameters: ImageCreateRequest): Promise<ClassroomImageUpload> {
        const response = await this.imageCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send a POST to /api/v1/classrooms/<classroom id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images associated with classrooms
     */
    async imageDeleteRaw(requestParameters: ImageDeleteRequest): Promise<runtime.ApiResponse<ClassroomImageUpload>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomImageUploadFromJSON(jsonValue));
    }

    /**
     * Send a POST to /api/v1/classrooms/<classroom id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images associated with classrooms
     */
    async imageDelete(requestParameters: ImageDeleteRequest): Promise<ClassroomImageUpload> {
        const response = await this.imageDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * List inactive Classrooms for Learner Learner either left, or the Educator archived
     */
    async inactiveAsLearnerRaw(requestParameters: InactiveAsLearnerRequest): Promise<runtime.ApiResponse<Array<Classroom>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/inactive_as_learner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomFromJSON));
    }

    /**
     * List inactive Classrooms for Learner Learner either left, or the Educator archived
     */
    async inactiveAsLearner(requestParameters: InactiveAsLearnerRequest): Promise<Array<Classroom>> {
        const response = await this.inactiveAsLearnerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Allows a learner to join a classroom
     */
    async joinRaw(requestParameters: JoinRequest): Promise<runtime.ApiResponse<Classroom>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling join.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/join`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomJoinRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomFromJSON(jsonValue));
    }

    /**
     * Allows a learner to join a classroom
     */
    async join(requestParameters: JoinRequest): Promise<Classroom> {
        const response = await this.joinRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns classroom assignments and progress for the current user.
     */
    async learnerProgressRaw(requestParameters: LearnerProgressRequest): Promise<runtime.ApiResponse<LearnerReport>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling learnerProgress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}/learner_progress`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LearnerReportFromJSON(jsonValue));
    }

    /**
     * Returns classroom assignments and progress for the current user.
     */
    async learnerProgress(requestParameters: LearnerProgressRequest): Promise<LearnerReport> {
        const response = await this.learnerProgressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Allows a learner to leave a classroom
     */
    async leaveRaw(requestParameters: LeaveRequest): Promise<runtime.ApiResponse<ClassroomGenericResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling leave.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/leave`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomLeaveRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomGenericResponseFromJSON(jsonValue));
    }

    /**
     * Allows a learner to leave a classroom
     */
    async leave(requestParameters: LeaveRequest): Promise<ClassroomGenericResponse> {
        const response = await this.leaveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Classrooms API
     */
    async listRaw(requestParameters: ListRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Viewset for Classrooms API
     */
    async list(requestParameters: ListRequest): Promise<InlineResponse2001> {
        const response = await this.listRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all Classrooms for the currently logged in educator
     */
    async listAsEducatorRaw(requestParameters: ListAsEducatorRequest): Promise<runtime.ApiResponse<Array<ClassroomEducatorList>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/list_as_educator`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomEducatorListFromJSON));
    }

    /**
     * List all Classrooms for the currently logged in educator
     */
    async listAsEducator(requestParameters: ListAsEducatorRequest): Promise<Array<ClassroomEducatorList>> {
        const response = await this.listAsEducatorRaw(requestParameters);
        return await response.value();
    }

    /**
     * List active Classrooms for learner This means classes that they are enrolled in which are not archived
     */
    async listAsLearnerRaw(requestParameters: ListAsLearnerRequest): Promise<runtime.ApiResponse<Array<ClassroomList>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/list_as_learner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassroomListFromJSON));
    }

    /**
     * List active Classrooms for learner This means classes that they are enrolled in which are not archived
     */
    async listAsLearner(requestParameters: ListAsLearnerRequest): Promise<Array<ClassroomList>> {
        const response = await this.listAsLearnerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Educator may mark completed classroom item as incompleted.
     */
    async markIncompleteRaw(requestParameters: MarkIncompleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncomplete.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling markIncomplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}/mark_incomplete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Educator may mark completed classroom item as incompleted.
     */
    async markIncomplete(requestParameters: MarkIncompleteRequest): Promise<any> {
        const response = await this.markIncompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a classroom
     */
    async partialUpdateRaw(requestParameters: PartialUpdateRequest): Promise<runtime.ApiResponse<Classroom>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomFromJSON(jsonValue));
    }

    /**
     * Update a classroom
     */
    async partialUpdate(requestParameters: PartialUpdateRequest): Promise<Classroom> {
        const response = await this.partialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a single classroom
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<ClassroomDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomDetailFromJSON(jsonValue));
    }

    /**
     * Retrieve a single classroom
     */
    async read(requestParameters: ReadRequest): Promise<ClassroomDetail> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

    /**
     * Allows a learner to re-join a classroom if they were previously a member
     */
    async rejoinRaw(requestParameters: RejoinRequest): Promise<runtime.ApiResponse<Classroom>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rejoin.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling rejoin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}/rejoin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomFromJSON(jsonValue));
    }

    /**
     * Allows a learner to re-join a classroom if they were previously a member
     */
    async rejoin(requestParameters: RejoinRequest): Promise<Classroom> {
        const response = await this.rejoinRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Classrooms API
     */
    async updateRaw(requestParameters: UpdateRequest): Promise<runtime.ApiResponse<Classroom>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling update.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling update.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/classrooms/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassroomToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassroomFromJSON(jsonValue));
    }

    /**
     * Viewset for Classrooms API
     */
    async update(requestParameters: UpdateRequest): Promise<Classroom> {
        const response = await this.updateRaw(requestParameters);
        return await response.value();
    }

}
