// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CompletedPathway,
    CompletedPathwayFromJSON,
    CompletedPathwayToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompletedPathwayResponse
 */
export interface CompletedPathwayResponse {
    /**
     * 
     * @type {Array<CompletedPathway>}
     * @memberof CompletedPathwayResponse
     */
    items: Array<CompletedPathway>;
    /**
     * 
     * @type {number}
     * @memberof CompletedPathwayResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof CompletedPathwayResponse
     */
    count: number;
}

export function CompletedPathwayResponseFromJSON(json: any): CompletedPathwayResponse {
    return {
        'items': (json['items'] as Array<any>).map(CompletedPathwayFromJSON),
        'page': json['page'],
        'count': json['count'],
    };
}

export function CompletedPathwayResponseToJSON(value?: CompletedPathwayResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'items': (value.items as Array<any>).map(CompletedPathwayToJSON),
        'page': value.page,
        'count': value.count,
    };
}


