import * as React from 'react';
import messages from '../displayMessages';
import useAddQueryParam, { ProgressPercentageEnum, authLocalStorageManager } from 'auth/utils';
import { AlertBox, AuthPageLayout, Button, CalendarDate, TextInput } from 'ui/components';
import { intl } from 'i18n';

interface Props {
    minimumAge?: number;
    onSuccess: () => void;
    onFailure: () => void;
    completeOnboarding: () => void;
}

export const UserAgeView: React.FunctionComponent<Props> = (props) => {

    React.useEffect(() => {
        setTimeout(onCloseAlert, 4000);
    }, []);

    useAddQueryParam('step', 'age');

    const [birthdate, setBirthdate] = React.useState<CalendarDate | undefined>(undefined);
    const [buttonClicked, setButtonClicked] = React.useState(false);
    const [alertClassName, setAlertClassName] = React.useState('select-role-success-alert');

    const onChangeValue = (value: string) => {
        setBirthdate(CalendarDate.fromIsoString(value));
    };

    const isValidDate = () => {
        if (buttonClicked) return false;
        if (birthdate === undefined) return false;
        return birthdate.day <= 31 && birthdate.month <= 12
            && birthdate.year > 1900;
    };


    const onSubmit = () => {
        setButtonClicked(true);
        if (birthdate === undefined) {
            return;
        }
        const minimumAge = props.minimumAge || 13;
        if (birthdate.ageInYears >= minimumAge) {
            authLocalStorageManager.userBirthdate = birthdate;
            props.onSuccess();
            return;
        }
        props.onFailure();
    };

    const onCloseAlert = () => {
        setAlertClassName( 'select-role-success-alert out');
    };

    return (
        <AuthPageLayout
            imageUrl='/assets/images/auth-layouts/scientists.svg'
            title={intl.formatMessage(messages.lxAuthUserAgeTitle)}
            subtitle={intl.formatMessage(messages.lxAuthUserAgeSubtitle)}
            progressPercentage={ProgressPercentageEnum.BirthdayInput}
        >
            <AlertBox
                className={alertClassName}
                type='success'
                bodyMessage={messages.lxAuthAccountCreatedMsg}
                showCloseIcon={true}
                onClose={onCloseAlert}
            />
            <div className='age-container'>
                <TextInput
                    className='birthdate-input'
                    inputKey='birthdate-input'
                    value=''
                    onChangeValue={onChangeValue}
                    type='date'
                    placeholder={messages.lxAuthUserAgeTextPlaceholder}
                    feedbackMessage={messages.lxAuthSignUpAgeWarning}
                />
                <Button
                    className='submit-button'
                    btnStyle='primary'
                    label={messages.lxAuthSubmitLabel}
                    disabled={!isValidDate()}
                    onClick={onSubmit}
                />
            </div>
        </AuthPageLayout>
    );
};
