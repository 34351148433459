// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemMetadata,
    ItemMetadataFromJSON,
    ItemMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface CurriculumTaggingDataItem
 */
export interface CurriculumTaggingDataItem {
    /**
     * 
     * @type {ItemMetadata}
     * @memberof CurriculumTaggingDataItem
     */
    metadata: ItemMetadata;
    /**
     * 
     * @type {boolean}
     * @memberof CurriculumTaggingDataItem
     */
    status: boolean;
}

export function CurriculumTaggingDataItemFromJSON(json: any): CurriculumTaggingDataItem {
    return {
        'metadata': ItemMetadataFromJSON(json['metadata']),
        'status': json['status'],
    };
}

export function CurriculumTaggingDataItemToJSON(value?: CurriculumTaggingDataItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'metadata': ItemMetadataToJSON(value.metadata),
        'status': value.status,
    };
}


