// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationSummary
 */
export interface OrganizationSummary {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    logoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    largeLogoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    badgeIconUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSummary
     */
    darkTheme: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    brandColor: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSummary
     */
    organizationType: OrganizationSummaryOrganizationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSummary
     */
    enableAdditionalLicenseOptions: boolean;
}

export function OrganizationSummaryFromJSON(json: any): OrganizationSummary {
    return {
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'description': json['description'],
        'logoUrl': json['logo_url'],
        'largeLogoUrl': json['large_logo_url'],
        'badgeIconUrl': json['badge_icon_url'],
        'darkTheme': json['dark_theme'],
        'brandColor': json['brand_color'],
        'url': json['url'],
        'organizationType': json['organization_type'],
        'enableAdditionalLicenseOptions': json['enable_additional_license_options'],
    };
}

export function OrganizationSummaryToJSON(value?: OrganizationSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'description': value.description,
        'logo_url': value.logoUrl,
        'large_logo_url': value.largeLogoUrl,
        'badge_icon_url': value.badgeIconUrl,
        'dark_theme': value.darkTheme,
        'brand_color': value.brandColor,
        'url': value.url,
        'organization_type': value.organizationType,
        'enable_additional_license_options': value.enableAdditionalLicenseOptions,
    };
}

/**
* @export
* @enum {string}
*/
export enum OrganizationSummaryOrganizationTypeEnum {
    SPONSOR = 'SPONSOR',
    PARTNER = 'PARTNER',
    COMMUNITY = 'COMMUNITY',
    STRATEGICALLY = 'STRATEGIC-ALLY',
    OTHER = 'OTHER'
}


