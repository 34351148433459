import * as React from 'react';

import { intl } from 'i18n';
import messages from '../displayMessages';

interface LabXChangeLogoProps {
    type: 'full' | 'trim' | 'tagline';
    width?: number;
    height?: number;
    theme: 'dark' | 'light';
}

export const LabXChangeLogo: React.FC<LabXChangeLogoProps> = ({type, width, height, theme}) => (
    <img src={type === 'full'
              ? (theme === 'light' ? '/assets/images/labxchange-logo-full-light.svg' : '/assets/images/labxchange-logo-full.svg')
              : (type === 'tagline' ? '/assets/images/labxchange-logo-full-light-tagline.svg' : (
                (theme === 'light' ? '/assets/images/labxchange-logo-trim-light.svg' : '/assets/images/labxchange-logo-trim.svg')
              ))}
         width={width} height={height}
         alt={intl.formatMessage(messages.uiHeaderNavBarBrand)}
         className='lx-logo'
         title={intl.formatMessage(messages.uiHeaderNavBarBrand)}
     />
);
