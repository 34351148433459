import React from 'react';
import { Link } from 'react-router-dom';
import { SectionSlabsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { WrappedMessage } from 'utils';

import messages from './displayMessages';
import PathwaysCarousel from './PathwaysCarousel';
import classNames from 'classnames';
import { convertSlabToFeaturedItems } from 'subjects/components/page/utils';
import { SectionSlab } from 'labxchange-client';

interface PathwaysCarouselContainerProps {
    pathwaysSlug?: string;
    isMobile: boolean;
}


export const PathwaysCarouselContainer: React.FC<PathwaysCarouselContainerProps> = ({pathwaysSlug, isMobile}) => {

    const [subjectPathways, setSubjectPathways] = React.useState<SectionSlab | undefined>(undefined);

    React.useEffect(() => {
        fetchSlab();
    }, []);

    const fetchSlab = async () => {
        setSubjectPathways(undefined);
        if (pathwaysSlug) {
            const response: SectionSlab = await SectionSlabsApi.slabsRead({ id: pathwaysSlug });
            if (response) {
                setSubjectPathways(response);
            }
        }
    };

    const morePathwaysLink = (
        <Link
            to={`${ROUTES.Library.HOME}?t=Language%3Aen&t=ItemType%3Apathway`}
            className='underline-link link-spacing mobile-row'
        >
            <WrappedMessage message={messages.morePathwaysText} />
        </Link>
    );

    return (
        <div className='pathways-container section pathway-section scroll-section'>
            <h1 className='positive-margin'><WrappedMessage message={messages.pathwayCardsHeading}/></h1>
            <div className='pathways-description'>
                <span className={classNames({'pathways-text-mobile' : isMobile, 'pathways-text-desktop': !isMobile})}>
                    <WrappedMessage message={messages.pathwayCardsDescription}/>
                    <Link className='underline-link' to={ROUTES.General.SIGN_UP}>
                        <WrappedMessage message={messages.createAccountText}/>
                    </Link>
                </span>
                {!isMobile && morePathwaysLink}
            </div>
            <PathwaysCarousel pathways={subjectPathways && convertSlabToFeaturedItems(subjectPathways)}/>
            {isMobile && morePathwaysLink}
        </div>
    );
};

export default PathwaysCarouselContainer;
