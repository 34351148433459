/**
 * This is a wrapper for `labxchange-client/apis/AnalyticsApi` to convert all analytics
 * requests into a Beacon call by passing a `keepalive: true` to fetch request.
 * https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
 */


import * as runtime from 'labxchange-client/runtime';
import { AnalyticsApi as _AnalyticsApi } from 'labxchange-client';
import {
    Event,
    EventFromJSON,
    EventToJSON,
} from './Event';

export interface CreateEventCreateRequest {
    data: Event;
}

export interface RequestOpts {
    path: string;
    method: runtime.HTTPMethod;
    headers: runtime.HTTPHeaders;
    query?: runtime.HTTPQuery;
    body?: runtime.HTTPBody;
    keepalive?: boolean;
}

export class AnalyticsApi extends _AnalyticsApi {

    private analyticsMiddleware: runtime.Middleware[];

    constructor(protected configuration: runtime.Configuration) {
        super();
        this.analyticsMiddleware = configuration.middleware;
    }

    protected async request(context: RequestOpts): Promise<Response> {
        const { url, init } = this.createAnalyticsFetchParams(context);
        const response = await this.analyticsFetchApi(url, init);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    private createAnalyticsFetchParams(context: RequestOpts) {
        let url = this.configuration.basePath + context.path;
        if (context.query !== undefined && Object.keys(context.query).length !== 0) {
            // only add the querystring to the URL if there are query parameters.
            // this is done to avoid urls ending with a "?" character which buggy webservers
            // do not handle correctly sometimes.
            url += '?' + runtime.querystring(context.query);
        }
        const body = context.body instanceof FormData ? context.body : JSON.stringify(context.body);
        const init = {
            method: context.method,
            headers: context.headers,
            keepalive: context.keepalive || true, // By default set to true for all analytics calls.
            body,
        };
        return { url, init };
    }

    private analyticsFetchApi = async (url: string, init: RequestInit) => {
        let fetchParams = { url, init };
        for (const middleware of this.analyticsMiddleware) {
            if (middleware.pre) {
                fetchParams = await middleware.pre({
                    fetch: this.analyticsFetchApi,
                    ...fetchParams,
                }) || fetchParams;
            }
        }
        let response = await this.configuration.fetchApi(fetchParams.url, fetchParams.init);
        for (const middleware of this.analyticsMiddleware) {
            if (middleware.post) {
                response = await middleware.post({
                    fetch: this.analyticsFetchApi,
                    url,
                    init,
                    response: response.clone(),
                }) || response;
            }
        }
        return response;
    }

    /**
     * Endpoint to submit analytics events and store them in the data warehouse.
     */
    async createEventCreateRaw(requestParameters: CreateEventCreateRequest): Promise<runtime.ApiResponse<Event>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling createEventCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                // tslint:disable
                headerParameters['Authorization'] = this.configuration.accessToken(
                    'LabXChange API', ['read', 'write']
                );
            } else {
                // tslint:disable
                headerParameters['Authorization'] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/analytics/create-event`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
    }

    /**
     * Endpoint to submit analytics events and store them in the data warehouse.
     */
    async createEventCreate(requestParameters: CreateEventCreateRequest): Promise<Event> {
        const response = await this.createEventCreateRaw(requestParameters);
        return await response.value();
    }

}
