// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BannerResponse,
    BannerResponseFromJSON,
    BannerResponseToJSON,
} from '../models';

export interface ListRequest {
    page?: number;
}

/**
 * no description
 */
export class BannersApi extends runtime.BaseAPI {

    /**
     * This is a public view: anonymous users should also be able to see banners.
     * List active banners.  Inactive banners will be ommitted entirely from the response.
     */
    async listRaw(requestParameters: ListRequest): Promise<runtime.ApiResponse<BannerResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/banners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BannerResponseFromJSON(jsonValue));
    }

    /**
     * This is a public view: anonymous users should also be able to see banners.
     * List active banners.  Inactive banners will be ommitted entirely from the response.
     */
    async list(requestParameters: ListRequest): Promise<BannerResponse> {
        const response = await this.listRaw(requestParameters);
        return await response.value();
    }

}
