import { checkLoginStatus } from 'auth/actions';
import bind from 'bind-decorator';
import { ClassroomsApi, LibraryApi } from 'global/api';
import { getStore } from 'global/store';
import {
    Author,
    Classroom,
    ClassroomEducator,
    ClassroomMembershipStatusEnum,
    PeopleAppliedFilterFilterOnEnum,
    SubjectArea,
} from 'labxchange-client';
import * as React from 'react';
import { CopyrightAcknowledgement } from 'ui/components/CopyrightAcknowledgement/CopyrightAcknowledgement';

import { Icon, Modal } from 'elements';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';

import messages from 'classrooms/displayMessages';

import { ImageFilePicker, ImagePickerData } from 'ui/components/ImageFilePicker/ImageFilePicker';
import { AuthorsField } from 'ui/components/AuthorsField';
import { connect } from 'react-redux';
import { RootState } from 'global/state';
import { getUserFullName, getUsername } from 'auth/selectors';
import { showErrorMessage } from '../../../ui/components';
import { intl } from 'i18n';

import { ClassroomEducatorType } from 'classrooms/components';


interface ReduxStateProps {
    loggedInUsername?: string;
    loggedInUserFullName?: string;
}

interface Props {
    classroom?: Classroom;
    close: () => void;
    onArchiveUpdate?: () => void;
    onDelete?: () => void;
    onUpdated?: (classroom: Classroom) => void;
    create: boolean;
}

interface ImageLoadState {
    image: File|null;
    imagePreviewUrl: string; // for displaying the image preview
}

interface Valid {
    name: boolean;
    description: boolean;
    subject: boolean;
    maxStudents: boolean;
}

interface State extends Classroom {
    acknowledgedCopyright: boolean;
    imageLoadState?: ImageLoadState;
    valid: Valid;
    displayValidationMessages: boolean;
    sending: boolean;
    educatorAuthors: Author[];
    subjectAreas: SubjectArea[];
}

interface ValidationMessageContainerProps {
    message: MessageDescriptor;
}

const ValidationMessageContainer: React.FC<ValidationMessageContainerProps> = ({message}) => (
    <div className='invalid-feedback show-validation-error'>
        <WrappedMessage message={message} />
    </div>
);

class ClassroomEditModalInternal extends React.PureComponent<Props & ReduxStateProps, State> {

    constructor(props: Props & ReduxStateProps) {
        super(props);

        const validation = {
            name: false,
            description: false,
            subject: false,
            maxStudents: false,
        };

        if (props.classroom) {
            this.state = {
                ...props.classroom,
                acknowledgedCopyright: false,
                valid: validation,
                displayValidationMessages: false,
                sending: false,
                subjectAreas: [],
                educatorAuthors: props.classroom.educators? props.classroom.educators.reduce((filtered: Author[], educator) => {
                    if (educator.type !== ClassroomEducatorType.Owner) {
                        filtered.push({
                            username: educator.username,
                            fullName: educator.fullName ? educator.fullName : educator.username,
                        });
                    }
                    return filtered;
                }, []) : [],
            };
        } else {
            this.state = {
                name: '',
                description: '',
                subject: 'Biological Sciences',
                maxStudents: 5,
                imageUrl: '',
                archived: false,
                acknowledgedCopyright: false,
                valid: validation,
                displayValidationMessages: false,
                sending: false,
                membershipsCount: 0,
                itemsCount: 0,
                educatorAuthors: [],
                subjectAreas: []
            };
        }
    }

    public async componentDidMount() {
        const subjectAreas = await LibraryApi.getSubjectAreas();
        this.setState({ subjectAreas });
    }

    public renderButtons() {
        // "Create" modal
        return this.props.create ? (
            <button type='button'
                className='primary-button'
                onClick={this.onSave}
                disabled={
                    !this.state.acknowledgedCopyright ||
                    !this.state.name ||
                    !this.state.description ||
                    this.state.sending
                }
            >
                <WrappedMessage message={messages.classroomModalCreateButton} />
            </button>
            // "Update" modal
        ) : (
            <>
                <div className='secondary-button-wrapper'>
                    {this.props.classroom?.permissions?.canDeleteClassObject &&
                        <button type='button'
                            className='unstyled'
                            data-testid='delete-classroom'
                            onClick={this.props.onDelete}
                        >
                            <Icon name='trashcan' zoom='x1' />
                            <WrappedMessage message={messages.classroomModalDeleteButton} />
                        </button>
                    }
                    <button type='button'
                        className='unstyled'
                        data-testid='archive-classroom'
                        onClick={this.props.onArchiveUpdate}
                    >
                        <Icon name='archive' zoom='x1' />
                        <WrappedMessage message={
                            this.props.classroom && this.props.classroom.archived ?
                            messages.classroomModalUpdateUnarchiveButton : messages.classroomModalUpdateArchiveButton}
                        />
                    </button>
                </div>
                <button type='button'
                    className='primary-button'
                    data-testid='save-classroom'
                    onClick={this.onSave}
                >
                    <WrappedMessage message={messages.classroomModalUpdateButton} />
                </button>
            </>
        );
    }

    public renderMemberOptions(classroom?: Classroom) {
        if (classroom && classroom.members) {
            const activeMembers = classroom.members.filter(
                (member) => member.status === ClassroomMembershipStatusEnum.Joined);
            const activeMembersCount = activeMembers.length;

            return (
                <>
                    {activeMembersCount <= 5 && <option>5</option>}
                    {activeMembersCount <= 10 && <option>10</option>}
                    {activeMembersCount <= 25 && <option>25</option>}
                    {activeMembersCount <= 50 && <option>50</option>}
                    {activeMembersCount <= 100 && <option>100</option>}
                    {activeMembersCount <= 300 && <option>300</option>}
                </>
            );
        } else {
            return (
                <>
                    <option>5</option>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>300</option>
                </>
            );
        }
    }

    public renderBody() {

        const s = this.state;

        const hasExistingImage = Boolean(this.props.classroom && this.props.classroom.imageUrl);
        const hasImageSelectedForUpload = Boolean(s.imageLoadState && s.imageLoadState.imagePreviewUrl);

        let canModifyEducators = true;
        if (this.props.classroom?.permissions?.canModifyEducatorsObject === false) {
            canModifyEducators = false;
        }
        return (
            <form className='classroom-edit-form' noValidate>
                <div className='form-group'>
                    <label htmlFor='classroom-name'>
                        <div className='classroom-edit-field'>
                            <WrappedMessage message={messages.classroomModalName} />*
                        </div>
                    </label>
                    <input type='text'
                        id='classroom-name'
                        className='form-control'
                        placeholder={intl.formatMessage(messages.classroomModalNamePlaceholder)}
                        onChange={this.onNameChange}
                        value={this.state.name}
                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    />
                    {s.displayValidationMessages && !s.valid.name ?
                        <ValidationMessageContainer message={messages.classroomModalInvalidName} />
                        : null
                    }
                </div>

            {/* Subject and Max Students are grouped together  */}

                <div className='form-row'>
                    <div className='form-group left-side col-md-8'>
                        {/* Subject  */}
                        <label htmlFor={'classroom-subject'}>
                            <div className='classroom-edit-field'>
                                <WrappedMessage message={messages.classroomModalSubject} />*
                            </div>
                        </label>
                        <select
                            id='classroom-subject'
                            className='form-control'
                            onBlur={this.onSubjectChange}
                            onChange={this.onSubjectChange}
                            value={this.state.subject}
                            // TODO: i18n for this select's options
                        >
                         {this.state.subjectAreas
                            .filter(subjectArea => subjectArea.name !== 'Other')
                            .map(subjectArea => (
                                <option key={subjectArea.name}>{subjectArea.name}</option>
                            ))
                        }

                        </select>
                        {s.displayValidationMessages && !s.valid.subject ?
                            <ValidationMessageContainer message={messages.classroomModalInvalidSubject} />
                            : null
                        }
                    </div>
                    <div className='form-group right-side col-md-4'>
                        {/* Max Students */}
                        <label htmlFor={'classroom-max-students'}>
                            <div className='classroom-edit-field'>
                                <WrappedMessage message={messages.classroomModalMaxStudents} />*
                            </div>
                        </label>
                        <select
                            id='classroom-max-students'
                            className='form-control'
                            onBlur={this.onMaxStudentsChange}
                            onChange={this.onMaxStudentsChange}
                            value={this.state.maxStudents}
                        >
                            {this.renderMemberOptions(this.props.classroom)}
                        </select>
                        {s.displayValidationMessages && !s.valid.maxStudents ?
                            <ValidationMessageContainer message={messages.classroomModalInvalidMaxStudents} />
                            : null
                        }
                    </div>
                </div>

                {/* Description and Image Upload  */}
                <div className='form-row'>
                    <div className='form-group left-side col-md-8'>
                        {/* Description */}
                        <label htmlFor={'classroom-description'}>
                            <div className='classroom-edit-field'>
                                <WrappedMessage message={messages.classroomModalDescription} />*
                            </div>
                        </label>
                        <textarea
                            name='classroom-description'
                            placeholder={intl.formatMessage(messages.classroomModalDescriptionPlaceholder)}
                            className='form-control classroom-edit-description-field'
                            onChange={this.onDescriptionChange}
                            value={this.state.description}
                        />
                        {s.displayValidationMessages && !s.valid.description ?
                            <ValidationMessageContainer message={messages.classroomModalInvalidDescription} />
                            : null
                        }
                    </div>
                    <div className='form-group right-side col-md-4'>
                        <div className='classroom-edit-image-picker'>
                            <label htmlFor={'classroom-crud-modal-image-file-picker'}>
                                <div className='classroom-edit-field'>
                                    <WrappedMessage message={messages.classroomModalImageUpload} />
                                </div>
                            </label>
                            <ImageFilePicker
                                id='classroom-crud-modal-image-file-picker'
                                onFileChanged={this.onFileChanged}
                            >
                                {
                                (!hasExistingImage && !hasImageSelectedForUpload) ?
                                    <div className='image-upload-placeholder'>
                                        <Icon name='plus' zoom='26' />
                                        <WrappedMessage message={messages.classroomModalUploadPreviewLabel} />
                                    </div> : null
                                }
                                {
                                (hasExistingImage && !hasImageSelectedForUpload) ?
                                    <img src={this.props.classroom!.imageUrl}
                                        className='image-upload-placeholder'
                                        alt={intl.formatMessage(messages.classroomModalUploadPreviewLabel)}
                                    /> : null
                                }
                                {
                                (hasImageSelectedForUpload) ?
                                    <img src={this.state.imageLoadState!.imagePreviewUrl}
                                        className='image-upload-placeholder'
                                        alt={intl.formatMessage(messages.classroomModalUploadPreviewLabel)}
                                    /> : null
                                }
                            </ImageFilePicker>
                        </div>
                    </div>
                </div>
                {canModifyEducators &&
                    <AuthorsField
                        onUpdate={this.onAuthorsUpdate}
                        authors={this.state.educatorAuthors}
                        isNew={false}   // this makes the first author to be pre-populated with current user
                        titleMessage={messages.classroomModalEducatorsTitle}
                        currentUser={{
                            username: this.props.loggedInUsername,
                            fullName: this.props.loggedInUserFullName!,
                        }}
                        searchFilters={[{
                            filterOn: PeopleAppliedFilterFilterOnEnum.Role,
                            filterValues: ['educator'],
                        }]}
                        addAnotherLabel={messages.classroomModalAddEducatorLabel}
                        avoidSuggestCurrentUser={true}
                    />
                }
                {
                    this.props.create &&
                        <div className='classroom-modal-checkboxes'>
                            <CopyrightAcknowledgement
                                showErrors={false}
                                isChecked={this.state.acknowledgedCopyright}
                                onClick={this.onCheckAcknowledgedCopyright}
                            />
                        </div>
                }
            </form>
        );
    }

    @bind private async onAuthorsUpdate(authors: Author[]) {
        this.setState({educatorAuthors: authors});
    }

    public render() {
        const headerMessage = this.props.create ? messages.classroomModalCreateHeader :
            messages.classroomModalUpdateHeader;

        return (
            <Modal
                onRequestClose={this.props.close}
                size='large'
                className='classroom-edit'
                showTopBar={true}
                title={<WrappedMessage message={headerMessage} />}
                content={this.renderBody()}
                footer={this.renderButtons()}
            />
        );
    }

    private async updateImage(classroomId: string) {
        if (!this.state.imageLoadState || this.state.imageLoadState.image === null) { return; }

        const image = this.state.imageLoadState.image;
        await ClassroomsApi.imageCreate({id: classroomId, image});
    }

    @bind private async onSave(event: React.MouseEvent) {
        event.preventDefault();

        if (!this.validate()) { return; }

        this.setState({sending: true}, async () => {
            let results: Classroom;
            if (this.props.create) {
                try {
                    results = await ClassroomsApi.create({
                        data: {
                            ...this.state,
                            educators: this.cleanEducatorAuthors(this.state.educatorAuthors),
                        },
                    });
                } catch (err) {
                    showErrorMessage(<WrappedMessage message={messages.classroomModalCreateError} />);
                    this.setState({sending: false});
                    return;
                }
            } else {
                try {
                    results = await ClassroomsApi.partialUpdate({
                        id: this.props.classroom!.id!,
                        data: {
                            ...this.state,
                            educators: this.cleanEducatorAuthors(this.state.educatorAuthors),
                        },
                    });
                } catch (err) {
                    showErrorMessage(<WrappedMessage message={messages.classroomModalUpdateError} />);
                    this.setState({sending: false});
                    return;
                }
            }
            await this.updateImage(results.id!);
            if (this.props.onUpdated) {
                this.props.onUpdated(results);
            }
            this.setState({sending: false});
            checkLoginStatus(getStore().dispatch);
            this.props.close();
        });
    }

    cleanEducatorAuthors(educatorAuthors: Author[]): ClassroomEducator[] {
        return educatorAuthors.reduce((filtered: ClassroomEducator[], educator) => {
            if (educator.username) {
                filtered.push({
                    username: educator.username,
                    fullName: educator.fullName,
                });
            }
            return filtered;
        }, []);
    }

    @bind private onCheckAcknowledgedCopyright(newValue: boolean) {
        this.setState({acknowledgedCopyright: !this.state.acknowledgedCopyright});
    }

    @bind private onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ name: e.target.value });
    }

    @bind private onSubjectChange(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ subject: e.target.value });
    }

    @bind private onMaxStudentsChange(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ maxStudents: parseInt(e.target.value, 10) });
    }

    @bind private onDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ description: e.target.value });
    }

    @bind private onFileChanged(data: ImagePickerData) {
        this.setState({ imageLoadState: data });
    }

    @bind private validate() {
        const s = this.state;

        const nameValid = s.name.length > 0;
        const subjectValid = s.subject.length > 0;
        const maxStudentsValid = s.maxStudents > 0;
        const descriptionValid = s.description.length > 0;

        this.setState({
            ...s,
            valid: {
                name: nameValid,
                subject: subjectValid,
                maxStudents: maxStudentsValid,
                description: descriptionValid,
            },
        });

        const valid = nameValid && subjectValid && maxStudentsValid && descriptionValid;
        this.setState({ displayValidationMessages: !valid });

        return valid;
    }
}

export const ClassroomEditModal = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        loggedInUsername: getUsername(state),
        loggedInUserFullName: getUserFullName(state),
    }),
)(ClassroomEditModalInternal);
