import { ROUTES } from 'global/constants';
import { APIPermissions, OrganizationResponse } from 'labxchange-client';
import * as React from 'react';
import { NavTabs, NavTabsLink } from 'ui/components';
import { DashboardDropdown } from 'ui/components/DashboardDropdown';
import { DropdownLinkProps } from 'ui/components/Dropdown';
import { WrappedMessage } from 'utils';
import messages from '../displayMessages';

interface Props {
    userPermissions?: APIPermissions;
    organization: OrganizationResponse;
    links?: DropdownLinkProps[];
}

export const OrganizationDashboardHeader: React.FC<Props> = (
    { userPermissions, organization, links }) => {
    const baseLinks = [
        {
            key: 'dashboard-homepage',
            content: <WrappedMessage message={messages.uiNavDropdownDashboardLink}/>,
            to: ROUTES.Dashboard.HOME,
        },
    ];
    return (
        <>
            <DashboardDropdown
                title={messages.organizationDashboard}
                titleValues={{organizationName: organization.org.name}}
                links={[...baseLinks, ...links || []]}
            />
            <NavTabs>
                <NavTabsLink
                    to={ROUTES.Dashboard.ORGANIZATION_CONTENT_SLUG(organization.org.slug)} exact
                    message={messages.uiNavTabOrganizationContent}
                    values={{organizationName: organization.org.name}}
                />
                <NavTabsLink
                    to={ROUTES.Dashboard.ORGANIZATION_PROFILE_ADMIN_SLUG(organization.org.slug)} exact
                    message={messages.uiNavTabOrganizationProfileSettings}
                />
                <NavTabsLink
                    to={ROUTES.Dashboard.ORGANIZATION_PEOPLE_ADMIN_SLUG(organization.org.slug)} exact
                    message={messages.uiNavTabOrganizationPeople}
                />
                <NavTabsLink
                    to={ROUTES.Dashboard.ORGANIZATION_SOURCES_ADMIN_SLUG(organization.org.slug)} exact
                    message={messages.uiNavTabOrganizationSources}
                />
                <NavTabsLink
                    to={ROUTES.Organizations.PROFILE_SLUG(organization.org.slug)}
                    message={messages.uiNavTabOrganizationProfile}
                    values={{organizationName: organization.org.name}}
                />
            </NavTabs>
        </>
    );
};
