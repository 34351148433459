import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { CardsList } from 'home/components/TeachPage/CardsList';
import { useLayoutSize } from 'utils';

interface SkeletonSlabsProps {
  count: number;
}

export const SkeletonSlabs: React.FC<SkeletonSlabsProps> = ({ count }) => {
  const layoutSize = useLayoutSize();

  const renderSkeletonSlabs = () => {
    return Array.from(Array(count).keys()).map((index: number) => {
      return (
        <div className='cards-container section' key={index}>
          <div className='more-header'>
            <Skeleton width={layoutSize === 'small' ? 250 : 450} height={40} />
          </div>
          <div className='list-cards'>
            <CardsList layoutSize={layoutSize} />
          </div>
        </div>
      );
    });
  };

  return <>{renderSkeletonSlabs()}</>;
};

export default SkeletonSlabs;
