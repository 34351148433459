import React from 'react';
import Select, { ValueType } from 'react-select';
import Skeleton from 'react-loading-skeleton';

import { SelectDropdownOption } from '../types';
import { contentSubjectsOptions, getContentTypesOptions } from '../utils';

interface ContentPageHeaderProps {
  loading: boolean;
  onSubjectChange: (selectedOption: ValueType<SelectDropdownOption>) => void;
  onContentChange: (selectedOption: ValueType<SelectDropdownOption>) => void;
  subjectDefaultValue?: SelectDropdownOption;
  contentDefaultValue?: SelectDropdownOption;
  subject: string;
}

export const ContentPageHeader: React.FC<ContentPageHeaderProps> = (props) => {
  return (
    <header className='content-page-header'>
      <div className='content-dropdowns-wrapper container'>
        {props.loading ?
          <div className='content-header-skeleton'>
            <Skeleton width={200} height={60} />
            <Skeleton className='second-skeleton' width={200} height={60} />
          </div> :
          <>
            <Select
              className='subject-select'
              classNamePrefix='subject'
              options={contentSubjectsOptions}
              onChange={props.onSubjectChange}
              defaultValue={props.subjectDefaultValue}
              isSearchable={false}
            />
            <Select
              className='content-select'
              classNamePrefix='content'
              options={getContentTypesOptions(props.subject)}
              onChange={props.onContentChange}
              defaultValue={props.contentDefaultValue}
              isSearchable={false}
            />
          </>
        }
      </div>
    </header>
  );
};

export default ContentPageHeader;
