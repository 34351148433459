// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettingsUpdate
 */
export interface NotificationSettingsUpdate {
    /**
     * Receive notifications about your classes? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyClasses?: boolean;
    /**
     * Receive notifications about your classes? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyClassesEmail?: boolean;
    /**
     * Receive notifications about your class discussions? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyClassDiscussions?: boolean;
    /**
     * Receive notifications about your class discussions? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyClassDiscussionsEmail?: boolean;
    /**
     * Receive notifications about mentorship? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyMentorship?: boolean;
    /**
     * Receive notifications about mentorship? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyMentorshipEmail?: boolean;
    /**
     * Receive notifications about new messages? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyNewMessages?: boolean;
    /**
     * Receive notifications about new messages? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyNewMessagesEmail?: boolean;
    /**
     * Receive notifications about the discussion forum? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyDiscussionForum?: boolean;
    /**
     * Receive notifications about the discussion forum? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyDiscussionForumEmail?: boolean;
    /**
     * Receive notifications about your content? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyContentUpdate?: boolean;
    /**
     * Receive notifications about your content? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyContentUpdateEmail?: boolean;
    /**
     * Receive notifications about product interviews? (notification)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyProductInterviews?: boolean;
    /**
     * Receive notifications about product interviews? (email)
     * @type {boolean}
     * @memberof NotificationSettingsUpdate
     */
    notifyProductInterviewsEmail?: boolean;
}

export function NotificationSettingsUpdateFromJSON(json: any): NotificationSettingsUpdate {
    return {
        'notifyClasses': !exists(json, 'notify_classes') ? undefined : json['notify_classes'],
        'notifyClassesEmail': !exists(json, 'notify_classes_email') ? undefined : json['notify_classes_email'],
        'notifyClassDiscussions': !exists(json, 'notify_class_discussions') ? undefined : json['notify_class_discussions'],
        'notifyClassDiscussionsEmail': !exists(json, 'notify_class_discussions_email') ? undefined : json['notify_class_discussions_email'],
        'notifyMentorship': !exists(json, 'notify_mentorship') ? undefined : json['notify_mentorship'],
        'notifyMentorshipEmail': !exists(json, 'notify_mentorship_email') ? undefined : json['notify_mentorship_email'],
        'notifyNewMessages': !exists(json, 'notify_new_messages') ? undefined : json['notify_new_messages'],
        'notifyNewMessagesEmail': !exists(json, 'notify_new_messages_email') ? undefined : json['notify_new_messages_email'],
        'notifyDiscussionForum': !exists(json, 'notify_discussion_forum') ? undefined : json['notify_discussion_forum'],
        'notifyDiscussionForumEmail': !exists(json, 'notify_discussion_forum_email') ? undefined : json['notify_discussion_forum_email'],
        'notifyContentUpdate': !exists(json, 'notify_content_update') ? undefined : json['notify_content_update'],
        'notifyContentUpdateEmail': !exists(json, 'notify_content_update_email') ? undefined : json['notify_content_update_email'],
        'notifyProductInterviews': !exists(json, 'notify_product_interviews') ? undefined : json['notify_product_interviews'],
        'notifyProductInterviewsEmail': !exists(json, 'notify_product_interviews_email') ? undefined : json['notify_product_interviews_email'],
    };
}

export function NotificationSettingsUpdateToJSON(value?: NotificationSettingsUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'notify_classes': value.notifyClasses,
        'notify_classes_email': value.notifyClassesEmail,
        'notify_class_discussions': value.notifyClassDiscussions,
        'notify_class_discussions_email': value.notifyClassDiscussionsEmail,
        'notify_mentorship': value.notifyMentorship,
        'notify_mentorship_email': value.notifyMentorshipEmail,
        'notify_new_messages': value.notifyNewMessages,
        'notify_new_messages_email': value.notifyNewMessagesEmail,
        'notify_discussion_forum': value.notifyDiscussionForum,
        'notify_discussion_forum_email': value.notifyDiscussionForumEmail,
        'notify_content_update': value.notifyContentUpdate,
        'notify_content_update_email': value.notifyContentUpdateEmail,
        'notify_product_interviews': value.notifyProductInterviews,
        'notify_product_interviews_email': value.notifyProductInterviewsEmail,
    };
}


