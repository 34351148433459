import { RootState } from 'global/state';
import { Banner } from 'labxchange-client';
import { intl } from 'i18n';
import messages from './displayMessages';
import { ROUTES } from 'global/constants';
import {
    ActivateEmailBannerId,
    ActivateEmailDismissCookie,
    PolicyAndTermsUpdatesBannerId,
} from './actions';
import { getCookie } from 'utils';

export const getVisibleBanners = (state: RootState) => {
    const banners: Banner[] = state.bannerState.banners.filter((banner) => {
        let bannerId = banner.id;
        if (bannerId && !banner.action) {
            if (banner.regexUrl !== undefined) {
                try {
                  const pattern = new RegExp(banner.regexUrl);
                  const path = window.location.href;

                  /// I change the banner id to display a new banner in each page that comply with the regex pattern
                  bannerId = banner.id + ':' + btoa(path);
                  return !state.bannerState.dismissed.has(bannerId) && pattern.test(path);
                }
                catch (e) {
                  /// If the expression is invalid
                  return false;
                }
            }
            return !state.bannerState.dismissed.has(bannerId);
        }
        return false;
    });

    /// Activate email banner
    /// Only show this banner to users created with the new workflow
    if (state.loginState.name && !state.loginState.hasVerifiedEmail && !state.loginState.needsAuthMigration) {
        /// Verify the dismiss cookie
        const cookieValue = getCookie(ActivateEmailDismissCookie);
        const msgLink = intl.formatMessage(messages.lxAuthActivateEmailBannerLinkLabel);
        if (cookieValue === null) {
            /// Add the activate email banner
            banners.push({
                id: ActivateEmailBannerId,
                content: intl.formatMessage(
                    messages.lxAuthActivateEmailBannerContent,
                    {
                        link: `<a href='${ROUTES.General.EMAIL_CONFIRMATION}?sending'>${msgLink}</a>`
                    }
                ),
            });
        }
    }

    // Policy Update Banner Implementation
    const currentDomain = window.location.hostname;
    const prodDomain = 'www.labxchange.org';
    if(currentDomain === prodDomain){
        // removing isPolicyBannerDismissed check adds a jerk on screen so its necessary to double-check to prevent that
        const isPolicyBannerDismissed = localStorage.getItem('dismissedBanners')?.includes(PolicyAndTermsUpdatesBannerId);
        if (!isPolicyBannerDismissed) {
            // Get the current date
            const currentDate = new Date();

            // Set the release date of the product
            const releaseDate = new Date('Sept 27, 2024');

            // Calculate the date after which the banner should be hidden i.e., 10th of next month
            const hideBannerDate = new Date(releaseDate);
            hideBannerDate.setMonth(hideBannerDate.getMonth() + 1);
            hideBannerDate.setDate(12); // Set the date to the 12 of next month

            // Check if the current date is after the release date and before the hide banner date
            if (currentDate >= releaseDate && currentDate <= hideBannerDate) {
                const PolicyLink = intl.formatMessage(messages.policyLinkLabel);
                // Add policy update banner
                banners.push({
                    id: PolicyAndTermsUpdatesBannerId,
                    content: intl.formatMessage(
                        messages.policyAndTermsUpdateBannerContent,
                        {
                            policyLink: `<a target="_blank" href='https://about.labxchange.org/privacy-notice' style="background: #0d0100">${PolicyLink}</a>`
                        }
                    ),
                    disableDismiss: true,
                });
            }
        }
    }


    if (!banners) {
        return undefined;
    }
    return banners;
};
