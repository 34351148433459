// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomThreadLike
 */
export interface ClassroomThreadLike {
    /**
     * 
     * @type {string}
     * @memberof ClassroomThreadLike
     */
    classroom: string;
}

export function ClassroomThreadLikeFromJSON(json: any): ClassroomThreadLike {
    return {
        'classroom': json['classroom'],
    };
}

export function ClassroomThreadLikeToJSON(value?: ClassroomThreadLike): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'classroom': value.classroom,
    };
}


