import * as LoginActions from 'auth/actions';
import bind from 'bind-decorator';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { getLoggedInStatus, getUserPermissions } from 'auth/selectors';
import { OrganizationsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { APIPermissions, OrganizationMembership } from 'labxchange-client';
import { NavTabs, NavTabsLink, StandardPageLayout } from 'ui/components';
import { DashboardDropdown } from 'ui/components/DashboardDropdown';
import { DropdownLinkProps } from 'ui/components/Dropdown';
import { WrappedMessage } from 'utils';
import messages from './Dashboard/displayMessages';
import libraryMessages from 'dashboard/components/displayMessages';
import { UI_IS_SM } from 'ui/breakpoints';
import { withRouter } from 'react-router';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import uiMessages from 'ui/components/displayMessages';
import { intl } from 'i18n';


interface StateProps {
    isLoggedIn?: boolean;
    userPermissions?: APIPermissions;
}

export interface TabLink {
    to: string;
    message: MessageDescriptor;
    exact: boolean;
    // Link is available only for educators
    onlyEducator: boolean;
}

interface Props extends StateProps, RouteComponentProps<{}> {
    children: React.ReactNode;
    searchUrl?: string;
    header?: React.ReactNode;
    tabs: TabLink[];
    pageTitle?: MessageDescriptor;
    pageTitleValues?: {[key: string]: string};
}

interface State {
    organizations: OrganizationMembership[];
    isMobileView: boolean;
}

/**
 * Base layout component for rendering Dashboard and Community pages.
 * - Render page tabs passing TabLink[]
 * - Pass header component
 */
export class TabbedPageLayoutInternal extends React.Component<Props & RouteComponentProps, State> {
    private mediaQuery = UI_IS_SM;  // This matches Bootstrap's definition of small (sm) breakpoint.

    constructor(props: Props) {
        super(props);
        this.state = {
            organizations: [],
            isMobileView: false,
        };
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();

        if (!this.userCanViewPage) {
            return;
        }

        const organizations = await OrganizationsApi.organizationMembershipsList();
        this.setState({ organizations });
    }

    public render() {
        // Prevent unauthenticated access.
        if (!this.userCanViewPage) {
            return <RegistrationGate
                title={intl.formatMessage(this.getRegistrationGateTitle)}
                body={intl.formatMessage(this.getRegistrationGateBody)}
                primaryButtonText={this.getRegistrationGatePrimaryButton}
                secondaryButtonText={this.getRegistrationGateSecondaryButton}
                secondaryButtonOnClick={this.getRegistrationGateSecondaryFunction}
                image='/assets/images/access.svg'
            />;
        }
        return (
            <StandardPageLayout
                pageTitle={this.props.pageTitle}
                pageTitleValues={this.props.pageTitleValues}
                searchUrl={this.props.searchUrl}
                headerFeature={
                    <div className='learner-dashboard-header'>
                        {this.props.header || this.renderDropdown()}
                        <NavTabs>
                            {this.props.tabs.map((item, index) => {
                                if (item.onlyEducator && this.userIsEducator !== true) {
                                    return null;
                                }
                                return <NavTabsLink
                                    key={index}
                                    to={item.to}
                                    exact={item.exact}
                                    message={item.message}
                                />;
                            })}
                        </NavTabs>
                    </div>
                }
            >
                {this.props.children}
            </StandardPageLayout>
        );
    }

    private renderDropdown() {
        if (this.organizationLinks.length > 0) {
            return <DashboardDropdown title={messages.dashboardTitle} links={this.organizationLinks} />;
        }
        return (
            <div className='inactive-dashboard-dropdown container'>
                <h1><WrappedMessage message={messages.dashboardTitle} /></h1>
            </div>
        );
    }

    private get organizationLinks() {
        let links: DropdownLinkProps[] = [];

        if (this.state.organizations && this.state.organizations.length > 0) {
            links = this.state.organizations.map((organization: OrganizationMembership) => ({
                key: organization.organization.slug,
                content: <WrappedMessage
                    message={libraryMessages.organizationDashboard}
                    values={{organizationName: organization.organization.name}}
                />,
                to: ROUTES.Dashboard.ORGANIZATION_CONTENT_SLUG(organization.organization.slug),
            }));
        }
        return links;
    }

    private get userIsEducator() {
        const { userPermissions } = this.props;
        let showEducatorDashboard = false;
        if (userPermissions && userPermissions.profile) {
            if (userPermissions.profile.canAccessEducatorDashboard) {
                showEducatorDashboard = true;
            }
        }
        return showEducatorDashboard;
    }

    /*
    * Anonymous user may not view Dashboard/Community pages.
    */
    private get userCanViewPage() {
        if (!this.props.isLoggedIn) {
            return false;
        }
        if (this.props.match.path === ROUTES.Dashboard.EDUCATOR_CLASSROOMS){
            if (this.props.userPermissions === undefined) {
              return false;
            }
            const canCreateClass = 'canCreateClass';
            return this.props.userPermissions.classrooms[canCreateClass];
        }
        return true;
    }

    @bind private setIsMobileView(): void {
        this.setState({isMobileView: this.mediaQuery.matches});
    }

    private get getRegistrationGateTitle() {
        switch (this.props.match.path) {
            case ROUTES.Community.DISCUSSIONS:
                return messages.notLoggedInCommunityAlertTitle;
            case ROUTES.Community.MENTEES:
                return messages.notLoggedInMentorsAlertTitle;
            case ROUTES.Community.MENTORS:
                return messages.notLoggedInMentorsAlertTitle;
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return messages.notEducatorClassroomAlertTitle;
            default:
                return messages.notLoggedInDashboardAlertTile;
        }
    }

    private get getRegistrationGateBody() {
        switch (this.props.match.path) {
            case ROUTES.Community.DISCUSSIONS:
                return messages.notLoggedInCommunityAlertBody;
            case ROUTES.Community.MENTEES:
                return messages.notLoggedInMentorsAlertBody;
            case ROUTES.Community.MENTORS:
                return messages.notLoggedInMentorsAlertBody;
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return messages.notEducatorClassroomAlertBody;
            default:
                return messages.notLoggedInDashboardAlertBody;
        }
    }

    private get getRegistrationGatePrimaryButton() {
        if (!this.props.isLoggedIn) {
            return uiMessages.uiSignUp;
        }
        switch (this.props.match.path) {
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return undefined;
            default:
                return uiMessages.uiSignUp;
        }
    }

    private get getRegistrationGateSecondaryButton() {
        if (!this.props.isLoggedIn) {
            return uiMessages.uiSignIn;
        }
        switch (this.props.match.path) {
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return uiMessages.uiLogOut;
            default:
                return uiMessages.uiSignIn;
        }
    }

    private get getRegistrationGateSecondaryFunction () {
        if (!this.props.isLoggedIn) {
            return undefined;
        }
        switch (this.props.match.path) {
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return this.handleLogOut;
            default:
                return undefined;
        }
    }

    private handleLogOut() {
        LoginActions.logout();
    }

}


export const TabbedPageLayout = connect<StateProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        userPermissions: getUserPermissions(state),
    })
)(withRouter(TabbedPageLayoutInternal));
