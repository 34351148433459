
export const enum ProblemResponseType {
    ChoiceResponse = 'choiceresponse',
    MultipleChoiceResponse = 'multiplechoiceresponse',
    OptionResponse = 'optionresponse',
    StringResponse = 'stringresponse',
    UnknownResponse = 'unknownresponse',
}

export interface ValueType {
    id: number,
    label: string,
    value: string,
}

export const ShortAnswerTypeText: ValueType = {
    id: 1, value: 'text', label: 'text'
};

export const ShortAnswerTypeNumber: ValueType = {
    id: 2, value: 'number', label: 'number'
};

export const ShortAnswerType = [
    ShortAnswerTypeText,
    ShortAnswerTypeNumber,
];

interface SingleSelectType {
    value: 'radio' | 'select';
    label: string;
}

export const SingleSelectTypeRadio: SingleSelectType = {
    value: 'radio',
    label: 'Radio button'
};

export const SingleSelectTypeSelect: SingleSelectType = {
    value: 'select',
    label: 'Dropdown menu'
};

export const SingleSelectTypes = [
    SingleSelectTypeRadio,
    SingleSelectTypeSelect,
];

export interface HintSetting {
    id: number,
    value: string,
}

export interface HintSettings {
    hints: HintSetting[],
}

export interface ScoringSettings {
    attemptsOptions: ValueType[],
    pointsOptions: ValueType[],
    selectedAttemptsOption: ValueType,
    selectedPointOption: ValueType,
}

export interface GroupFeedback {
    id: number,
    answers: string[],
    feedback: string,
}

export interface GeneralFeedback {
    id: number,
    correct: boolean,
    feedback: string,
}

export interface MultiSelectAnswer {
    // IDs are sequencial uppercase letters - "A", "B", etc.
    // The IDs are not stored in the olx; they are generated from the order of answers (first = "A", etc.).
    id: string,
    title: string,
    correct: boolean,
    selectedFeedback?: string,
    unselectedFeedback?: string,
}

export interface MultiSelectAnswers {
    generalFeedbackList: GeneralFeedback[],
    multiSelectAnswersList: MultiSelectAnswer[],
}

export interface MultiSelectEditor {
    content: string;
}

export interface ShortAnswer {
    id: number,
    value: string | number;
    currentType: any;
    correct: boolean;
    feedback?: string;
}

export interface ShortAnswers {
    shortAnswersList: ShortAnswer[],
}

export interface ShortAnswerEditor {
    content: string;
}

export interface SingleSelectAnswer {
    id: number;
    title: string;
    correct: boolean;
    feedback?: string;
}

export interface SingleSelectAnswers {
    selectedType: SingleSelectType,
    singleSelectAnswersList: any[],
}

export interface SingleSelectEditor {
    content: string;
}

export const enum ProblemBlockType {
    New = 'lx_question',
    Old = 'problem',
}

export interface GlobalProblemBlockEditorState {
    problemResponseType: ProblemResponseType,
    hintSettings: HintSettings,
    scorringSettings: ScoringSettings,
    multiSelectAnswers: MultiSelectAnswers,
    multiSelectEditor: MultiSelectEditor,
    shortAnswers: ShortAnswers,
    shortAnswerEditor: ShortAnswerEditor,
    singleSelectAnswers: SingleSelectAnswers,
    singleSelectEditor: SingleSelectEditor,
    blockType: ProblemBlockType,
}

export const getDefaultState = (): GlobalProblemBlockEditorState => {
    return {
        problemResponseType: ProblemResponseType.UnknownResponse,
        blockType: ProblemBlockType.New,
        hintSettings: {
            hints: [],
        },
        scorringSettings: {
            attemptsOptions: [],
            pointsOptions: [],
            selectedAttemptsOption: { id: 1, value: '1', label: '1' },
            selectedPointOption: { id: 1, value: '1', label: '1' },
        },
        multiSelectAnswers: {
            generalFeedbackList: [],
            multiSelectAnswersList: [],
        },
        multiSelectEditor: {
            content: '',
        },
        shortAnswers: {
            shortAnswersList: [],
        },
        shortAnswerEditor: {
            content: '',
        },
        singleSelectAnswers: {
            selectedType: SingleSelectTypeRadio,
            singleSelectAnswersList: [],
        },
        singleSelectEditor: {
            content: '',
        }
    };
};
