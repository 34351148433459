// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountDeletionRequest
 */
export interface AccountDeletionRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountDeletionRequest
     */
    notes?: string;
}

export function AccountDeletionRequestFromJSON(json: any): AccountDeletionRequest {
    return {
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function AccountDeletionRequestToJSON(value?: AccountDeletionRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'notes': value.notes,
    };
}


