// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AnalyticsEvent
 */
export interface AnalyticsEvent {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEvent
     */
    eventType: AnalyticsEventEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEvent
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsEvent
     */
    orgSlug?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AnalyticsEvent
     */
    data?: { [key: string]: string; };
}

export function AnalyticsEventFromJSON(json: any): AnalyticsEvent {
    return {
        'eventType': json['event_type'],
        'objectId': !exists(json, 'object_id') ? undefined : json['object_id'],
        'orgSlug': !exists(json, 'org_slug') ? undefined : json['org_slug'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function AnalyticsEventToJSON(value?: AnalyticsEvent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'event_type': value.eventType,
        'object_id': value.objectId,
        'org_slug': value.orgSlug,
        'data': value.data,
    };
}

/**
* @export
* @enum {string}
*/
export enum AnalyticsEventEventTypeEnum {
    AssetFavoriteMarked = 'AssetFavoriteMarked',
    AssetFavoriteUnmarked = 'AssetFavoriteUnmarked',
    AssetCreated = 'AssetCreated',
    AssetDeleted = 'AssetDeleted',
    AssetCloned = 'AssetCloned',
    AssetCompleted = 'AssetCompleted',
    ClassMembershipRequested = 'ClassMembershipRequested',
    ClassMembershipLeft = 'ClassMembershipLeft',
    ClassMembershipRequestApproved = 'ClassMembershipRequestApproved',
    ClassMembershipRequestDeclined = 'ClassMembershipRequestDeclined',
    ClassAssetAdded = 'ClassAssetAdded',
    ClassAssetRemoved = 'ClassAssetRemoved',
    ClassCreated = 'ClassCreated',
    ClassCloned = 'ClassCloned',
    ClassArchived = 'ClassArchived',
    ClassDeleted = 'ClassDeleted',
    MentorshipEnabled = 'MentorshipEnabled',
    MentorshipDisabled = 'MentorshipDisabled',
    MentorshipRequested = 'MentorshipRequested',
    MentorshipRequestApproved = 'MentorshipRequestApproved',
    MentorshipRequestRefused = 'MentorshipRequestRefused',
    MentorshipTerminatedByMentor = 'MentorshipTerminatedByMentor',
    MentorshipTerminatedByMentee = 'MentorshipTerminatedByMentee',
    MentorshipAssetAssigned = 'MentorshipAssetAssigned',
    MentorshipAssetUnassigned = 'MentorshipAssetUnassigned',
    UserLoggedIn = 'UserLoggedIn',
    UserLoggedOut = 'UserLoggedOut',
    UserAccountDeleted = 'UserAccountDeleted',
    UserRoleChanged = 'UserRoleChanged',
    PeopleSearchPerformed = 'PeopleSearchPerformed',
    PeopleSearchNextPageVisited = 'PeopleSearchNextPageVisited',
    PeopleSearchPreviousPageVisited = 'PeopleSearchPreviousPageVisited',
    PeopleSearchPageNumberVisited = 'PeopleSearchPageNumberVisited',
    PeopleCardClicked = 'PeopleCardClicked',
    AssetSearchPerformed = 'AssetSearchPerformed',
    AssetSearchNextPageVisited = 'AssetSearchNextPageVisited',
    AssetSearchPreviousPageVisited = 'AssetSearchPreviousPageVisited',
    AssetSearchPageNumberVisited = 'AssetSearchPageNumberVisited',
    OrganizationSearchPerformed = 'OrganizationSearchPerformed',
    OrganizationSearchNextPageVisited = 'OrganizationSearchNextPageVisited',
    OrganizationSearchPreviousPageVisited = 'OrganizationSearchPreviousPageVisited',
    OrganizationSearchPageNumberVisited = 'OrganizationSearchPageNumberVisited',
    OrganizationCardClicked = 'OrganizationCardClicked',
    OrganizationWebsiteClicked = 'OrganizationWebsiteClicked',
    OrganizationSocialMediaClicked = 'OrganizationSocialMediaClicked',
    OrganizationLabXchangeProfileVisited = 'OrganizationLabXchangeProfileVisited',
    AssetCardClicked = 'AssetCardClicked',
    AssetCardInSearchClicked = 'AssetCardInSearchClicked',
    AssetCardInSimilarRecommendationsClicked = 'AssetCardInSimilarRecommendationsClicked',
    AssetCardInAuthorRecommendationsClicked = 'AssetCardInAuthorRecommendationsClicked',
    AssetViewed = 'AssetViewed',
    AssetShared = 'AssetShared',
    PathwayAssetNextClicked = 'PathwayAssetNextClicked',
    VideoAssetPlayed = 'VideoAssetPlayed',
    VideoAssetPaused = 'VideoAssetPaused',
    AssetSourceProviderLinkClicked = 'AssetSourceProviderLinkClicked',
    AssetSourceSourceLinkClicked = 'AssetSourceSourceLinkClicked',
    AssetSourceInfoLinkClicked = 'AssetSourceInfoLinkClicked',
    AssetSourceEnrollLinkClicked = 'AssetSourceEnrollLinkClicked'
}


