// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * Get project highlight quote.
 * @export
 * @interface CareerQuote
 */
export interface CareerQuote {
    /**
     * HTML formatted quote text.
     * @type {string}
     * @memberof CareerQuote
     */
    quote: string;
    /**
     * Quote author full name.
     * @type {string}
     * @memberof CareerQuote
     */
    authorName: string;
    /**
     * Quote author designation.
     * @type {string}
     * @memberof CareerQuote
     */
    authorDesignation: string;
    /**
     * Quote author image.
     * @type {string}
     * @memberof CareerQuote
     */
    readonly authorImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerQuote
     */
    quoteType: CareerQuoteQuoteTypeEnum;
}

export function CareerQuoteFromJSON(json: any): CareerQuote {
    return {
        'quote': json['quote'],
        'authorName': json['author_name'],
        'authorDesignation': json['author_designation'],
        'authorImage': !exists(json, 'author_image') ? undefined : json['author_image'],
        'quoteType': json['quote_type'],
    };
}

export function CareerQuoteToJSON(value?: CareerQuote): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'quote': value.quote,
        'author_name': value.authorName,
        'author_designation': value.authorDesignation,
        'quote_type': value.quoteType,
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerQuoteQuoteTypeEnum {
    ProjectHighlight = 'project-highlight',
    Career = 'career'
}


