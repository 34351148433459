import { ItemsApi } from 'global/api';
import { ItemMetadata, ItemTranslation } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { Button, Icon, Modal, Spinner } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { getLanguageNativeName } from 'i18n';
import bind from 'bind-decorator';
import { intl } from 'i18n';

export interface LanguageItem {
    language: string;
    languageNative?: string;
    type?: string;
    item?: string;
    isSelected?: boolean;
    isOriginal?: boolean;
}

interface Props {
    metadata: ItemMetadata;
    translations?: LanguageItem[];
    onClose: () => void;
    onConfirm: (translation: ItemTranslation) => void;
}

interface State {
    loading: boolean;
    translations: LanguageItem[];
    selectedTranslation: LanguageItem;
    originalTranslation: LanguageItem;
}

export class TranslationSelectorModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            translations: [],
            selectedTranslation: {
                language: this.props.metadata.language,
                item: this.props.metadata.id,
                type: this.props.metadata.type,
            },
            originalTranslation: {
                language: this.props.metadata.language,
                item: this.props.metadata.id,
                type: this.props.metadata.type,
            }
        };
    }

    public async componentDidMount() {
        if (!this.props.translations) {
            this.loadTranslations();
        } else {
            this.setState({
                translations: this.props.translations,
                loading: false,
             });
        }
    }

    public render() {
        return (
            <Modal
                onRequestClose={this.props.onClose}
                size='normal'
                content={
                    <div className='translation-selector-modal'>
                        <h2>
                            <WrappedMessage
                                message={messages.languageSelectorModalTitle}
                            />
                        </h2>
                        {this.state.loading ? <div className='lx-spinner'><Spinner/></div>
                        : <>
                            <LanguageSelectorList
                                languages={this.state.translations}
                                selectedLanguage={this.state.selectedTranslation}
                                originalLanguage={this.state.originalTranslation}
                                onSelectItem={(translation) => this.setState({selectedTranslation: translation})}
                            />
                            <Button
                                btnStyle='primary'
                                label={messages.languageSelectorModalConfirm}
                                onClick={() => this.props.onConfirm(
                                    {
                                        language: this.state.selectedTranslation.language,
                                        item: this.state.selectedTranslation.item,
                                        type: this.state.selectedTranslation.type,
                                    } as ItemTranslation)
                                }
                            />
                        </>}
                    </div>
                }
            />
        );
    }

    @bind async loadTranslations() {
        try {
            const translations = await ItemsApi.languages({id: this.props.metadata.id});
            this.setState({translations, loading: false});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadingTranslationsError}/>);
            this.props.onClose();
        }
    }
}

interface LanguageSelectorProps {
    languages: LanguageItem[];
    selectedLanguage?: LanguageItem;
    originalLanguage?: LanguageItem;
    onSelectItem: (language: LanguageItem) => void;
}

interface LanguageSelectorState {
    filterString: string;
}

class LanguageSelectorList extends  React.PureComponent<LanguageSelectorProps, LanguageSelectorState> {
    constructor(props: LanguageSelectorProps) {
        super(props);
        this.state = {
            filterString: '',
        };
    }

    public render() {
        const rows = [];
        let searchQuery: string = this.state.filterString.trim().toLocaleLowerCase();
        searchQuery = searchQuery.replace(/  +/g, ' ');
        const filteredLanguages: LanguageItem[] = [];
        this.props.languages.forEach((language) => {
            if (getLanguageNativeName(language.language).toLocaleLowerCase().indexOf(searchQuery) !== -1) {
                filteredLanguages.push(language);
            }
        });
        for (let i = 0; i < filteredLanguages.length; i += 2) {
            const buttons = [i, i + 1].filter(index => index < filteredLanguages.length).map((index) => {
                const translation = filteredLanguages[index];
                let isSelected = false;
                if (this.props.selectedLanguage) {
                    isSelected = (
                        (this.props.selectedLanguage.language === translation.language) &&
                        (this.props.selectedLanguage.item === translation.item)
                    );
                }
                let isOriginal = false;
                if (this.props.originalLanguage) {
                    isOriginal = (
                        (this.props.originalLanguage.language === translation.language) &&
                        (this.props.originalLanguage.item === translation.item)
                    );
                }

                return <LanguageSelectorItem
                    key={translation.language}
                    isOriginal={isOriginal}
                    isSelected={isSelected}
                    onClick={() => this.props.onSelectItem(translation)}
                    languageName={translation.languageNative || getLanguageNativeName(translation.language)}
                />;
            });
            rows.push(<div key={i} className='translation-selector-row'>
                {buttons}
            </div>);
        }
        const placeholderText = intl.formatMessage(messages.languageFilterPlaceholder);
        return (
            <div>
                <div className='language-search'>
                    <input
                        className='language-search-input'
                        type='text'
                        placeholder={placeholderText}
                        aria-label={placeholderText}
                        onChange={this.onChange}
                        value={this.state.filterString}
                    />
                    <span className='language-search-icon'>
                        <Icon name='search' fill='#668BA6' zoom='1.3em'/>
                    </span>
                </div>
                <div className='translations'>{rows}</div>
            </div>
        );
    }

    @bind private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            filterString: event.target.value,
        });
    }
}

interface LanguageItemProps {
    onClick: () => void;
    isOriginal: boolean;
    isSelected: boolean;
    languageName: string;
}

class LanguageSelectorItem extends  React.PureComponent<LanguageItemProps> {
    public render() {
        return <button
            onClick={() => this.props.onClick()}
            className={
                `btn` +
                `${this.props.isOriginal ? ' language-original' : ''}` +
                `${this.props.isSelected ? ' language-selected' : ''}`}>
            {this.props.languageName}
        </button>;
    }
}
