import * as React from 'react';
import { Icon } from 'ui/components';
import classNames from 'classnames';

interface Props {
    onClick?: () => void;
    children?: React.ReactNode;
    hoverType?: 'underline' | 'color';
    disableIcon?: boolean;
    ariaDescribedBy?: string;
    active?: boolean;
}

export class Tag extends React.PureComponent<Props> {

    public static defaultProps = {
        hoverType: 'underline',
        active: false,
    };

    public render() {
        const isInactive = this.props.onClick === undefined;
        return (
            <button
                className={classNames('tag', {
                    'tag-inactive': isInactive,
                    [this.props.hoverType ?? '']: true,
                    'active': this.props.active,
                  })
                }
                onClick={() => this.props.onClick && this.props.onClick()}
                disabled={isInactive}
                type='button'
                aria-describedby={this.props.ariaDescribedBy}
            >
                {this.props.children}
                {(!isInactive && !this.props.disableIcon) ? <Icon name='x' zoom='10'/> : null}
            </button>
        );
    }
}
