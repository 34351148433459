import { defineMessages } from 'react-intl';

const messages = defineMessages({
    contentPageTitle: {
        id:'organizationContentPageTitle',
        defaultMessage: 'Organization content - Educator Dashboard',
        description: 'HTML title for the content page on an organization dashboard',
    },
    notLoggedInOrgDashboardTitle: {
        id: 'notLoggedInOrgDashboardTitle',
        defaultMessage: 'Log in to view this dashboard',
        description: 'Title of the alert page when the user tries to go to a org dashboard without being logged in',
    },
    notLoggedInOrgDashboardBody: {
        id: 'notLoggedInOrgDashboardBody',
        defaultMessage: 'You may need to log in with different credentials to access this dashboard.',
        description: 'Body of the alert page when the user tries to go to a org dashboard without being logged in',
    },
});

export default messages;
