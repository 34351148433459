import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { LoginModalComponent } from '../elements/LoginModalComponent';
import { SSOComponent } from '../elements/SSOComponent';
import messages from '../displayMessages';
import { RootState } from 'global/state';
import { dismissModal } from './dispatch';
import * as LoginActions from 'auth/actions';
import { AuthProviderEnum, validatePassword } from 'auth/utils';
import { LxConfig, ROUTES } from 'global/constants';
import { EVENT_NAMES } from 'tracking/constants';
import { analyticsInstance } from 'tracking';
import { WrappedMessage } from 'utils';
import { Button, showErrorMessage, TextInput } from 'ui/components';
import { intl } from 'i18n';
import { history } from 'global/history';
import { NavLink } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import { UsersApi } from 'global/api';
import { LoginPasswordComponent } from '../elements/LoginPasswordComponent';
import { MessageDescriptor } from 'react-intl';
import { LoginPageComponent } from './LxLoginPageView';


enum LoginModalStep {
    SSO = 0,
    Email = 1,
    WrongEmail = 2,
    Password = 3,
}

interface DispatchProps {
    login: any;
}

/** Props that come from the global redux state. */
interface ReduxStateProps {
    show?: boolean;
}

interface Props extends ReduxStateProps, DispatchProps {
    store?: Store;
}

interface State {
    step: LoginModalStep;
    email: string;
    invalidEmail: boolean;
    invalidEmailMsg?: MessageDescriptor;
    enableButton: boolean;
    password: string;
    invalidPassword: boolean;
    showPassword: boolean;
}

class LoginModalInternal extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            step: LoginModalStep.SSO,
            email: '',
            invalidEmail: false,
            password: '',
            invalidPassword: false,
            enableButton: false,
            showPassword: false,
        };
    }

    public render() {
        const isFullPageView = window.location.pathname === ROUTES.General.LOG_IN;
        if (!this.props.show) {
            return null;
        }

        return (
            <>
                {isFullPageView ?
                    <LoginPageComponent
                        title={this.renderTitle()}
                        subtitle={this.renderSubtitle()}
                        termsAndPolicyMessage={this.renderTermsAndPolicyMessage()}
                        onRequestClose={() => {
                            analyticsInstance.track(EVENT_NAMES.SignInModalClosed, { url: window.location.toString() });
                            this.dismissModal();
                        }}
                        showBackButton={this.state.step !== LoginModalStep.SSO}
                        onRequestBack={this.onClickBack}
                        onSignUpButtonClick={this.onClickSignUp}
                        onClickPrivacyPolicy={this.dismissModal}
                        onClickTermsOfService={this.dismissModal}
                    >
                        {this.renderComponent()}
                    </LoginPageComponent> :
                    <LoginModalComponent
                        title={this.renderTitle()}
                        subtitle={this.renderSubtitle()}
                        ariaLabel={messages.lxAuthSignInLavel}
                        showSignUpButton={true}
                        showBackButton={this.state.step !== LoginModalStep.SSO}
                        onRequestBack={this.onClickBack}
                        termsAndPolicyMessage={this.renderTermsAndPolicyMessage()}
                        onRequestClose={() => {
                            analyticsInstance.track(EVENT_NAMES.SignInModalClosed, { url: window.location.toString() });
                            this.dismissModal();
                        }}
                        onSignUpButtonClick={this.onClickSignUp}
                        onClickPrivacyPolicy={this.dismissModal}
                        onClickTermsOfService={this.dismissModal}
                    >
                        {this.renderComponent()}
                    </LoginModalComponent>
                }
            </>
        );
    }

    @bind private renderComponent() {
        switch(this.state.step) {
            case LoginModalStep.SSO:
                return (
                    <SSOComponent
                        providers={LxConfig.EnabledAuthProviders}
                        topMessage={messages.lxAuthSignUpAgeWarning}
                        onClickProvider={this.onClickProvider}
                        onCheck={this.onCheckClick}
                        bottomMessage={messages.lxAuthSignUpNewsletterMessage}
                    />
                );
            case LoginModalStep.Email:
            case LoginModalStep.WrongEmail:
                return (
                    <div className='sign-in-container'>
                        <h5>{intl.formatMessage(messages.lxAuthEmailSignInTitle)}</h5>
                        <TextInput
                            className='sign-in-input'
                            inputKey='sign-in-email-input'
                            autoComplete='username'
                            value={this.state.email}
                            onChangeValue={this.onUpdateEmail}
                            onFocusOut={this.onFocusOutEmail}
                            type='text'
                            placeholder={messages.lxAuthEmailInputPlaceholder}
                            invalid={this.state.invalidEmail}
                            feedbackMessage={this.state.invalidEmailMsg}
                            feedbackMessageValues={this.getInvalidEmailMessageValues()}
                        />
                        {/* We added a hidden simple password input to allow the password managers
                            to fill the user credentials */}
                        <TextInput
                            hidden={true}
                            className='sign-in-input'
                            inputKey='sign-in-password-input'
                            autoComplete='current-password'
                            value={this.state.password}
                            onChangeValue={this.onUpdatePassword}
                            type={this.state.showPassword ? 'text' : 'password'}
                        />
                        <Button
                            className='next-button'
                            btnStyle='primary'
                            label={messages.lxAuthNextLabel}
                            disabled={!this.state.enableButton}
                            onClick={this.emailNext}
                        />
                    </div>
                );
            case LoginModalStep.Password:
                return (
                    <LoginPasswordComponent
                        inputKey='sign-in-password-input'
                        email={this.state.email}
                        password={this.state.password}
                        showPassword={this.state.showPassword}
                        invalidPassword={this.state.invalidPassword}
                        disableButton={!this.state.enableButton}
                        onUpdatePassword={this.onUpdatePassword}
                        onUpdateShowPassword={this.onShowPassword}
                        onClickResetPassword={this.onClickRecoverPassword}
                        onSubmit={this.onSubmitEmailLogin}
                    />
                );
        }
    }

    @bind private dismissModal() {
        this.setState({
            email: '',
            invalidEmail: false,
            password: '',
            showPassword: false,
            invalidPassword: false,
            enableButton: false,
            step: LoginModalStep.SSO,
        });
        dismissModal();
    }

    @bind private renderTitle() {
        switch (this.state.step) {
            case LoginModalStep.SSO:
            case LoginModalStep.Email:
            case LoginModalStep.Password:
                return messages.lxAuthSignInModalTitle;
            case LoginModalStep.WrongEmail:
                return messages.lxAuthPleaseTryAgainTitle;
        }
    }

    @bind private renderSubtitle() {
        switch (this.state.step) {
            case LoginModalStep.SSO:
                return messages.lxAuthSignInModalSSOSubtitle;
            case LoginModalStep.Email:
                return messages.lxAuthSignInModalEmailSubtitle;
            case LoginModalStep.Password:
                return messages.lxAuthSignInModalEmailSubtitle;
            case LoginModalStep.WrongEmail:
                return messages.lxAuthPleaseTryAgainSubtitle;
        }
    }

    @bind private renderTermsAndPolicyMessage() {
        switch (this.state.step) {
            case LoginModalStep.SSO:
                return messages.lxAuthContinueLabel;
            case LoginModalStep.Email:
            case LoginModalStep.Password:
            case LoginModalStep.WrongEmail:
                return messages.lxAuthSignInLavel;
        }
    }

    @bind private getInvalidEmailMessageValues() {
        if ( this.state.invalidEmailMsg === messages.lxAuthSingInEmailDoesntExist) {
            return {new_account:
                <NavLink
                    to={ROUTES.General.SIGN_UP}
                    onClick={this.dismissModal}
                >
                    {intl.formatMessage(messages.lxAuthNewAccountLabel)}
                </NavLink>
            };
        }
        return undefined;
    }

    @bind private onClickBack() {
        switch (this.state.step) {
            case LoginModalStep.SSO:
                break;
            case LoginModalStep.Email:
            case LoginModalStep.WrongEmail:
                this.setState({
                    step: LoginModalStep.SSO,
                    email: '',
                    invalidEmail: false,
                    enableButton: false,
                });
                break;
            case LoginModalStep.Password:
                this.setState({
                    step: LoginModalStep.Email,
                    password: '',
                    showPassword: false,
                    invalidPassword: false,
                    enableButton: false,
                });
                this.onFocusOutEmail(this.state.email);
                break;
        }
    }

    @bind private async emailNext() {
        analyticsInstance.track(EVENT_NAMES.SignInEmailFormSubmitClicked, { url: window.location.toString() });
        try {
            await UsersApi.emailExists({email: this.state.email});
            this.setState({
                step: LoginModalStep.Password,
                enableButton: validatePassword(this.state.password),
            });
            analyticsInstance.track(EVENT_NAMES.SignInEmailFormSubmitSuccess, { url: window.location.toString() });
        }
        catch (response) {
            if (response.status === 429) {
                showErrorMessage(<WrappedMessage message={messages.lxAuthRatelimit} />);
                analyticsInstance.track(EVENT_NAMES.SignInEmailFormSubmitFailure, {
                    error: messages.lxAuthRatelimit,
                    url: window.location.toString(),
                });
            }
            else if (response.status === 401 ) {
                this.setState({
                    step: LoginModalStep.WrongEmail,
                    invalidEmail: true,
                    invalidEmailMsg: messages.lxAuthSingInEmailDoesntExist,
                    enableButton: false,
                });
                analyticsInstance.track(EVENT_NAMES.SignInEmailFormSubmitFailure, {
                    error: messages.lxAuthSingInEmailDoesntExist,
                    url: window.location.toString(),
                });
            }
            else if (response.status === 302) {
                /// If the account exists we redirect to the respective page
                const provider = (await response.json()).provider;
                history.push(ROUTES.General.SSO_AUTH_PAGE, { email: this.state.email, provider});
                this.dismissModal();
                analyticsInstance.track(EVENT_NAMES.SignInEmailFormSubmitRedirected, { provider, url: window.location.toString(), });
            }
        }
    }

    @bind private onShowPassword(showPassword: boolean) {
        this.setState({showPassword});
    }

    @bind private onClickProvider(provider: AuthProviderEnum) {
        analyticsInstance.track(
            EVENT_NAMES.SignInSSOProviderClicked,
            { provider: provider.toLowerCase(), url: window.location.toString(), },
            { sendImmediately: true },
        );

        if (provider === AuthProviderEnum.Email) {
            this.setState({step: LoginModalStep.Email});
        }
        else if (provider === AuthProviderEnum.Edx) {
            /// Login with edx. This calls the old auth flow.
            this.props.login();
            return;
        }
        else {
            LoginActions.loginWithProvider(provider);
        }
    }

    @bind private onCheckClick(checked: boolean) {
        if (checked) {
            analyticsInstance.track(EVENT_NAMES.SignInModalSubscribeChecked, { url: window.location.toString() });
        } else {
            analyticsInstance.track(EVENT_NAMES.SignInModalSubscribeUnchecked, { url: window.location.toString() });
        }
    }

    @bind private onClickSignUp() {
        analyticsInstance.track(EVENT_NAMES.SignInModalSignupClicked, { url: window.location.toString() });
        this.dismissModal();
        LoginActions.redirectToLxSignUp();
    }

    @bind private onClickRecoverPassword() {
        analyticsInstance.track(EVENT_NAMES.SignInRecoverPasswordClicked, { url: window.location.toString() });
        this.dismissModal();
        LoginActions.redirectToResetPasswordPage(this.state.email);
    }

    @bind private onUpdateEmail(email: string) {
        const validEmail = EmailValidator.validate(email);
        this.setState({
            email: email.toLowerCase(),
            invalidEmail: false,
            enableButton: validEmail,
            invalidEmailMsg: undefined,
        });
    }

    @bind private onFocusOutEmail(email: string) {
        const validEmail = EmailValidator.validate(email);
        this.setState({
            email,
            invalidEmail: !validEmail,
            invalidEmailMsg: !validEmail ? messages.lxAuthSignInInvalidEmailMessage : undefined,
            enableButton: validEmail,
        });
    }

    @bind private onUpdatePassword(password: string) {
        this.setState({
            password,
            enableButton: validatePassword(password),
            invalidPassword: false,
        });
    }

    @bind private async onSubmitEmailLogin() {
        analyticsInstance.track(EVENT_NAMES.SignInPasswordFormSubmitClicked, { url: window.location.toString() });
        this.setState({enableButton: false});

        const response = await LoginActions.loginWithEmail(
            this.state.email,
            this.state.password
        );

        if (response === undefined) {
            return;
        }

        if (response.status === 200) {
            /// After the `labxchange_sessionid` cookie is set,
            /// we redirect to `/oauth2/authorize` to complete the login
            this.props.login();
            analyticsInstance.track(EVENT_NAMES.SignInPasswordFormSubmitSuccess, { url: window.location.toString() });
        }
        else if (response.status === 429) {
            /// "Too many failed login attempts. Try again later."
            const errorMessage = (await response.json()).error_message;
            showErrorMessage(errorMessage);
            analyticsInstance.track(EVENT_NAMES.SignInPasswordFormSubmitFailure, {
                error: errorMessage,
                url: window.location.toString(),
            });
        }
        else if (response.status === 401) {
            this.setState({invalidPassword: true});
            analyticsInstance.track(EVENT_NAMES.SignInPasswordFormSubmitFailure, {
                error: 'invalid password',
                url: window.location.toString(),
            });
        }
        else if (response.status === 302) {
            /// If the account exists we redirect to the respective page
            const provider = (await response.json()).provider;
            if (provider) {
                history.push(ROUTES.General.SSO_AUTH_PAGE, { email: this.state.email, provider});
                dismissModal();
            }
            else {
                showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToSignup} />);
            }
        }
        else {
            showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToLogin} />);
        }
    }
}

export const LoginModal = connect<ReduxStateProps, DispatchProps, {}, RootState>(
    (state: RootState) => ({
        show: state.loginModalState.showModal,
    }), {
        login: LoginActions.login,
    }
)(LoginModalInternal);
