// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClusterItem
 */
export interface ClusterItem {
    /**
     * 
     * @type {ItemResponse}
     * @memberof ClusterItem
     */
    item: ItemResponse;
    /**
     * 
     * @type {string}
     * @memberof ClusterItem
     */
    iconUrl: string;
    /**
     * 
     * @type {number}
     * @memberof ClusterItem
     */
    id: number;
}

export function ClusterItemFromJSON(json: any): ClusterItem {
    return {
        'item': ItemResponseFromJSON(json['item']),
        'iconUrl': json['icon_url'],
        'id': json['id'],
    };
}

export function ClusterItemToJSON(value?: ClusterItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'item': ItemResponseToJSON(value.item),
        'icon_url': value.iconUrl,
        'id': value.id,
    };
}


