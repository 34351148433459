import {Classroom, ClassroomEducator} from 'labxchange-client';

export enum ModalType {
    Archive,
    ClassroomArchived,
    Copy,
    Delete,
    JoinClassroom,
    LeaveClassroom,
    Login,
    NeedJoinCode,
    NewClassroom,
    None,
    RejoinConfirmation,
    RequestSent,
    Unarchive,
    Update,
    Welcome,
}

export enum ClassroomEducatorType {
    Owner = 100,
    Colleague = 200,
}

export function getFirstOwnerEducator(classroom: Classroom): ClassroomEducator {
    // assumes the class has educators and one owner at least
    if (classroom.educators) {
        return classroom.educators.find(element => element.type === ClassroomEducatorType.Owner)!;
    }
    return {username: ''};
}
