import * as React from 'react';

import { OrganizationsApi } from 'global/api';
import { ContentSource, OrganizationResponse } from 'labxchange-client';
import { ContentSourceInfoBox } from 'library/components/ContentSourceInfoBox';
import * as UI from 'ui/components';

interface Props {
    organizationData: OrganizationResponse;
}

export const OrganizationSourcesDashboard: React.FunctionComponent<Props> = ({
    organizationData,
}) => {
    const orgSlug = organizationData.org.slug;
    const [isLoading, setLoading] = React.useState(true);
    const [sourcesData, setSourcesData] = React.useState<ContentSource[]>([]);
    React.useEffect(() => {
        setLoading(true);
        OrganizationsApi.contentSources({id: orgSlug}).then((data) => {
            setSourcesData(data);
            setLoading(false);
        });
    }, [orgSlug]);

    if (isLoading) {
        return <UI.Spinner/>;
    }

    return <>
        <h1>Content Sources</h1>
        <p>Here are the content sources linked to this organization:</p>
        <div className='container'>
            <div className='row'>
                {sourcesData.map((contentSource) => (
                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                        <ContentSourceInfoBox key={contentSource.id}
                            className='container-one rounded p-2' itemId={'org-page'} {...contentSource}
                        />
                    </div>
                ))}
            </div>
        </div>
    </>;
};
