import { Icon } from 'elements';
import { ROUTES } from 'global/constants';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

export enum SourceType {
    Dashboard,
    Class,
}

interface SourceInfo {
    source: string;
    message: MessageDescriptor;
}

interface BackToButtonInternalProps {
    fallbackSource?: SourceInfo;
    overrideSource?: SourceInfo;
    urlSource?: string;
}

/*
 * A button that links back to a classroom or dashboard, with appropriate text.
 * Used if a learner navigated to an asset from a classroom or dashboard. It
 * takes an arbitrary string because this is usually tracked via a url search
 * parameter (and thus should not be trusted).
 */
export class BackToButtonInternal extends React.PureComponent<BackToButtonInternalProps, {}> {
    public render() {
        const urlSource = this.props.urlSource;
        const fallback = this.props.fallbackSource;
        const override = this.props.overrideSource;

        let source;
        let message;
        if (override) {
            source = override.source; // we trust this; it's set from the code
            message = override.message;
        } else if (urlSource) {
            source = urlSource;
            if (urlSource === ROUTES.Community.MENTORS) {
                message = messages.backToDashboard;
            } else if (urlSource === ROUTES.Community.MENTEES) {
                message = messages.backToEducatorDashboard;
            } else if (urlSource.startsWith(ROUTES.Classrooms.CLASSROOM_SLUG(''))) {
                message = messages.backToClass;
            } else if (urlSource.startsWith(ROUTES.Library.BOOK_SLUG(''))) {
                message = messages.backToBook;
            } else if (urlSource.startsWith(ROUTES.Library.CLUSTER_SLUG(''))) {
                message = messages.backToCluster;
            } else {
                // don't render the button if it's not a source url we support
                return null;
            }
        } else if (fallback !== undefined) {
            source = fallback.source; // we trust this; it's set from the code
            message = fallback.message;
        } else {
            // nothing in the url, and no fallback, don't render anything
            return null;
        }

        return (
            <NavLink className='back-to-button nudging-button-secondary' to={source}>
                <Icon name='chevron-left' zoom='17' />
                <WrappedMessage message={message}/>
            </NavLink>
        );
    }
}

interface BackToButtonContainerProps extends RouteComponentProps<{}> {
    // this is used to provide a default "back to" source.  It is overridden if
    // a source is in the url query params.
    fallbackSource?: SourceInfo;
    // and this takes precedence over anything else if set.
    overrideSource?: SourceInfo;
}

class BackToButtonContainer extends React.PureComponent<BackToButtonContainerProps, {}> {
    public render() {
        const source: string|undefined = new URLSearchParams(this.props.location.search).get('source') || undefined;
        return <BackToButtonInternal {...this.props} urlSource={source} />;
    }
}

export const BackToButton = withRouter(BackToButtonContainer);
