// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    AnnotatedVideoQuestion,
    AnnotatedVideoQuestionFromJSON,
    AnnotatedVideoQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnnotatedVideoAnnotation
 */
export interface AnnotatedVideoAnnotation {
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotatedVideoAnnotation
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotatedVideoAnnotation
     */
    end: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    link?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnnotatedVideoAnnotation
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    imageAlt?: string;
    /**
     * 
     * @type {AnnotatedVideoQuestion}
     * @memberof AnnotatedVideoAnnotation
     */
    question?: AnnotatedVideoQuestion;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    imageFile?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    authorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    authorUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    authorOrgId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoAnnotation
     */
    questionSetOrder?: string;
}

export function AnnotatedVideoAnnotationFromJSON(json: any): AnnotatedVideoAnnotation {
    return {
        'id': json['id'],
        'start': json['start'],
        'end': json['end'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'tags': json['tags'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'imageAlt': !exists(json, 'image_alt') ? undefined : json['image_alt'],
        'question': !exists(json, 'question') ? undefined : AnnotatedVideoQuestionFromJSON(json['question']),
        'imageFile': !exists(json, 'image_file') ? undefined : json['image_file'],
        'authorName': !exists(json, 'author_name') ? undefined : json['author_name'],
        'authorUrl': !exists(json, 'author_url') ? undefined : json['author_url'],
        'authorOrgId': !exists(json, 'author_org_id') ? undefined : json['author_org_id'],
        'questionSetOrder': !exists(json, 'question_set_order') ? undefined : json['question_set_order'],
    };
}

export function AnnotatedVideoAnnotationToJSON(value?: AnnotatedVideoAnnotation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'start': value.start,
        'end': value.end,
        'title': value.title,
        'description': value.description,
        'link': value.link,
        'tags': value.tags,
        'image_url': value.imageUrl,
        'image_alt': value.imageAlt,
        'question': AnnotatedVideoQuestionToJSON(value.question),
        'image_file': value.imageFile,
        'author_name': value.authorName,
        'author_url': value.authorUrl,
        'author_org_id': value.authorOrgId,
        'question_set_order': value.questionSetOrder,
    };
}


