import * as React from 'react';
import { defineMessages } from 'react-intl';
import SingleSelectItem from './SingleSelectItem';
import { intl } from 'i18n';

const messages = defineMessages({
    title: {
        id: 'SingleSelectAnswers.title',
        defaultMessage: 'Answers*'
    },
    description: {
        id: 'SingleSelectAnswers.description',
        defaultMessage: 'Enter the answers below and select whether an answer is correct or incorrect. Remember, you can have one correct answer.'
    },
    btnAddNewAnswer: {
        id: 'SingleSelectAnswers.btnAddNewAnswer',
        defaultMessage: '+ Add answer'
    }
})

export default class SingleSelectAnswers extends React.Component<any, any> {

    render() {
        const { formatMessage } = intl;
        return (
            <fieldset className='lxc-answers-wrapper'>
                <legend className='lxc-answers-title'>
                    {formatMessage(messages.title)}
                </legend>
                <div className='lxc-answers-description'>
                    {formatMessage(messages.description)}
                </div>
                <div className='lxc-answers-list'>
                    {
                        this.props.answersList.map((answer: any, index: number) => {
                            return <SingleSelectItem
                                key={index} {...answer}
                                singleSelectChangeAnswer={this.props.singleSelectChangeAnswer}
                                singleSelectRemoveAnswer={this.props.singleSelectRemoveAnswer}
                                answersList={this.props.answersList}
                            />
                        })
                    }
                    <div className='lxc-answers-another-option'>
                        <button className='lxc-answers-another-option-btn' type='button' onClick={this.props.singleSelectAddAnswer}>
                            {
                                this.props.answersList.length ? (
                                    <>+ Add <span className='lxc-hide-mobile'>another</span> answer</>
                                ) : formatMessage(messages.btnAddNewAnswer)
                            }
                        </button>
                    </div>
                </div>
            </fieldset>
        );
    }
};
