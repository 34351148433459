import { useEffect } from 'react';
import { InitOptions, loadSpace } from '@usersnap/browser';
import { SpaceEventName } from '@usersnap/browser/dist/types';
import { LxConfig } from 'global/constants';
import { useSelector } from 'react-redux';
import { getCanSubmitUsersnapFeedback } from 'usersnap/selectors';
import { getUserEmail} from 'auth/selectors';

const isAPIEvent = (event: any): event is { apiKey: string } => {
    return Object.keys(event).includes('apiKey');
};

export const Usersnap = ({ initParams }: { initParams?: InitOptions }) => {
    const canSubmitUsersnapFeedback = useSelector(getCanSubmitUsersnapFeedback);
    const useremail = useSelector(getUserEmail);
    useEffect(() => {
        if (!canSubmitUsersnapFeedback) {
            return;
        }
        // We only want to load this code if the user is allowed to submit feedback via Usersnap.
        loadSpace(LxConfig.UsersnapAPIKey).then(async (api) => {
            api.init({
                ...initParams,
                ...{
                    user: {
                        email: useremail,
                        userId: useremail,
                    },
                    custom: {
                        asset: document.title,
                    },
                },
            });
            api.on('load' as SpaceEventName, (event) => {
                if (isAPIEvent(event) && event.apiKey !== LxConfig.UsersnapTranslationProjectAPIKey) {
                    api.hide(event.apiKey);
                }
            });
        });
    }, [initParams, canSubmitUsersnapFeedback]);
    return null;
};
