import bind from 'bind-decorator';
import { FullDate } from 'elements';
import { LearningApi } from 'global/api';
import { CompletedPathway } from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import moment from 'moment-shortformat';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, JumpTo, Modal, Pagination, showErrorMessage, SmallTabBar, SmallTabButton } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

enum Tab {
    All = 'all',
    Favorites = 'favorited',
    Assigned = 'assigned',
}

interface State {
    currentTab: Tab;
    pathways: CompletedPathway[];
    pathwaysCount: number;
    assignedPathways: CompletedPathway[];
    assignedPathwaysCount: number;
    favoritedPathways: CompletedPathway[];
    favoritedPathwaysCount: number;
    showModal: boolean;
    currentPage: number;
    pageCount: number;
}

export class CompletedPathwaysWidget extends React.PureComponent<{}, State> {
    private defaultPage = 1;
    private defaultPageSize = 5;
    private defaultModalPageSize = 25;

    constructor(props: {}) {
        super(props);
        this.state = {
            currentTab: Tab.All,
            pathways: [],
            pathwaysCount: 0,
            assignedPathways: [],
            assignedPathwaysCount: 0,
            favoritedPathways: [],
            favoritedPathwaysCount: 0,
            showModal: false,
            currentPage: this.defaultPage,
            pageCount: 0,
        };
    }

    public componentDidMount() {
        this.loadPathways(Tab.All, this.defaultPage, this.defaultPageSize);
        this.loadPathways(Tab.Assigned, this.defaultPage, this.defaultPageSize);
        this.loadPathways(Tab.Favorites, this.defaultPage, this.defaultPageSize);
    }

    public render() {
        return (
            <div className='learner-progress-pathways-widget'>
                <h2>
                    <WrappedMessage message={messages.learnerProgressPathwaysTitle}/>
                </h2>
                {this.renderTabs()}
                {this.renderContent(this.defaultPageSize, false)}
                <Button
                    btnStyle='link'
                    label={messages.learnerProgressPathwaysSeeAll}
                    onClick={() => {
                        this.setState({showModal: !this.state.showModal});
                        this.loadPathways(
                            this.state.currentTab,
                            this.state.currentPage,
                            this.defaultModalPageSize,
                        );
                    }}
                />
                {this.renderModal()}
            </div>
        );
    }

    private getPathways() {
        switch (this.state.currentTab) {
            case Tab.All: return this.state.pathways;
            case Tab.Assigned: return this.state.assignedPathways;
            case Tab.Favorites: return this.state.favoritedPathways;
            default: return this.state.pathways;
        }
    }

    @bind private onClickTab(tab: Tab) {
        if (this.state.showModal) {
            this.loadPathways(tab, this.defaultPage, this.defaultModalPageSize);
            this.setState({currentTab: tab, currentPage: this.defaultPage});
        } else {
            this.setState({currentTab: tab});
        }
    }

    private renderContent(itemCount: number | undefined, showMonths: boolean) {
        let currentMonth: number;
        return <ol className='learner-progress-pathways-widget-content'>
            {this.getPathways().slice(0, itemCount).map((pathway, index) => {
                let showMonth = false;
                const pathwayDate = moment(pathway.completionDate);
                if (currentMonth === undefined) { currentMonth = pathwayDate.month(); }
                if (currentMonth !== pathwayDate.month() || index === 0) { showMonth = true; }
                return (
                    <div key={`pathway-${pathway.pathway.id}`}>
                        {showMonths && showMonth ?
                            <li className='month-separator'>
                                {pathwayDate.format('MMMM')}
                            </li>
                        : null}
                        <li>
                            <Link
                                target='_blank'
                                to={detailUrlForEntity(pathway.pathway)}>
                                {pathway.pathway.title}
                            </Link>
                            , <FullDate date={pathway.completionDate}/>
                        </li>
                    </div>
                );
            })}
        </ol>;
    }

    private renderTabs() {
        return <SmallTabBar>
            <SmallTabButton
                active={this.state.currentTab === Tab.All}
                onClick={() => this.onClickTab(Tab.All)}
                label={messages.learnerProgressPathwaysTabAll}
                labelValues={{count: this.state.pathwaysCount}}
            />
            <SmallTabButton
                active={this.state.currentTab === Tab.Favorites}
                onClick={() => this.onClickTab(Tab.Favorites)}
                label={messages.learnerProgressPathwaysTabFavorites}
                labelValues={{count: this.state.favoritedPathwaysCount}}
            />
            <SmallTabButton
                active={this.state.currentTab === Tab.Assigned}
                onClick={() => this.onClickTab(Tab.Assigned)}
                label={messages.learnerProgressPathwaysTabAssigned}
                labelValues={{count: this.state.assignedPathwaysCount}}
            />
        </SmallTabBar>;
    }

    private renderModal() {
        if (!this.state.showModal) { return; }
        return <Modal
            size='max'
            showTopBar={true}
            contentPadding={0}
            title={
                <WrappedMessage message={messages.learnerProgressPathwaysTitle} />
            }
            contentScrolls={true}
            onRequestClose={() => this.setState({
                showModal: false,
                currentPage: this.defaultPage,
            })}
            content={
                <div className={
                        `learner-progress-pathways-widget ` +
                        `learner-progress-pathways-widget-modal`}>
                    {this.renderTabs()}
                    {this.renderContent(undefined, true)}
                    <div className='learner-progress-pathways-widget-modal-pagination'>
                        <JumpTo
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            onPageSelect={this.onPageSelect}
                        />
                        <Pagination
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            onPageSelect={this.onPageSelect}
                        />
                    </div>
                </div>
            }
        />;
    }

    @bind private onPageSelect(page: number) {
        this.loadPathways(
            this.state.currentTab,
            page,
            this.defaultModalPageSize,
        );
    }

    private async loadPathways(tab: Tab, page: number, paginationSize: number) {
        try {
            const filters = {type: tab, page, paginationSize};
            const response = await LearningApi.progressCompletedPathways(filters);
            let newState: any;
            switch (tab) {
                case Tab.All: {
                    newState = {
                        pathways: response.items,
                        pathwaysCount: response.count,
                    };
                    break;
                }
                case Tab.Assigned: {
                    newState = {
                        assignedPathways: response.items,
                        assignedPathwaysCount: response.count,
                    };
                    break;
                }
                case Tab.Favorites: {
                    newState = {
                        favoritedPathways: response.items,
                        favoritedPathwaysCount: response.count,
                    };
                    break;
                }
            }
            newState = {
                ...newState,
                pageCount: Math.ceil(response.count / paginationSize),
                currentPage: response.page,
            };
            this.setState(newState);
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.loadingError}/>,
                {exception: err},
            );
        }
    }
}
