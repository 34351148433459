// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadPostPermissions
 */
export interface ThreadPostPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ThreadPostPermissions
     */
    canDeletePostObject?: boolean;
}

export function ThreadPostPermissionsFromJSON(json: any): ThreadPostPermissions {
    return {
        'canDeletePostObject': !exists(json, 'can_delete_post_object') ? undefined : json['can_delete_post_object'],
    };
}

export function ThreadPostPermissionsToJSON(value?: ThreadPostPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_delete_post_object': value.canDeletePostObject,
    };
}


