import { ROUTES } from 'global/constants';
import { ItemsApi } from 'global/api';
import { getItemTypeMeta } from 'items/models';
import { ItemMetadata, Subject } from 'labxchange-client';
import { TranslationSelectorModal } from 'library/components';
import messages, { getBackgroundKnowledgeLabel, getContentLicenseLabel, getContentLicenseUrl } from 'library/displayMessages';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { buildSearchURL } from 'search/utils';
import { Button, Icon, showErrorMessage } from 'ui/components';
import { ItemIcon } from 'library/components';
import { getLanguageNativeName } from 'i18n';
import { WrappedMessage } from 'utils';
import { bind } from 'bind-decorator';
import { LanguageItem } from 'library/components/TranslationSelectorModal/TranslationSelectorModal';


interface LanguageSelectorButtonProps {
    onClick: () => void;
}

export class LanguageSelectorButton extends React.PureComponent<LanguageSelectorButtonProps, {}> {
    public render() {
        return (
            <span className='language-selector-button'>
                (<Button
                    btnStyle='link'
                    label={messages.languageChangeLabel}
                    onClick={this.props.onClick}
                />)
            </span>
        );
    }
}

enum ModalState {
    None = 0,
    LanguageSelector
}

interface ItemMetadataTableProps extends RouteComponentProps<{}> {
    item: ItemMetadata;
}

interface SubjectTagsProps {
    subjectTags?: string[];
}

interface SubjectTagsState {
    subjectTagsExpanded: boolean;
}


export class SubjectTags extends React.PureComponent<SubjectTagsProps, SubjectTagsState> {

    constructor(props: SubjectTagsProps) {
        super(props);
        this.state = {
            subjectTagsExpanded: false,
        };
    }

    public render() {

        /* Render (sorted) subject tags so that individually picked subjects (as opposed to the whole subject area) are
         * shown in parentheses, when expanded.  For instance:
         *
         * "Biological Sciences, Global Health (Communicable Diseases, Mental Health), Science & Society"
         *
         * When unexpanded, render only the first tag, with the count of remaining tags as a suffix:
         *
         * "Biological Sciences +3"
         */

        const subjectTags = this.props.subjectTags ? this.props.subjectTags : [];
        subjectTags.sort();

        let subjectTagsDisplay = '---';
        const subjectTagsExpandable = subjectTags.length > 1;
        if (subjectTagsExpandable) {
            if (this.state.subjectTagsExpanded) {
                let prevTag;
                for (const tag of subjectTags.map((t) => t.split(':'))) {
                    if (prevTag === undefined) {
                        subjectTagsDisplay = tag[0];

                        if (tag[1] !== undefined) {
                            subjectTagsDisplay += ` (${tag[1]}`;
                        }
                    } else if (tag[0] !== prevTag[0]) {
                        if (prevTag[1] !== undefined) {
                            subjectTagsDisplay += ')';
                        }

                        subjectTagsDisplay += `, ${tag[0]}`;

                        if (tag[1] !== undefined) {
                            subjectTagsDisplay += ` (${tag[1]}`;
                        }
                    } else if (tag[1] !== undefined) {
                        subjectTagsDisplay += `, ${tag[1]}`;
                    }

                    prevTag = tag;
                }

                if (prevTag !== undefined && prevTag[1] !== undefined) {
                    subjectTagsDisplay += ')';
                }
            } else {
                const tag = subjectTags[0].split(':');
                const rest = subjectTags.length - 1;
                subjectTagsDisplay = tag[1] !== undefined ? tag[1] : tag[0];
                subjectTagsDisplay += ` +${rest}`;
            }
        } else if (subjectTags.length > 0) {
            const tag = subjectTags[0].split(':');
            subjectTagsDisplay = tag[1] !== undefined ? tag[1] : tag[0];
        }

        return (
            <small>
                {subjectTagsExpandable ?
                    <button
                        className='unstyled'
                        onClick={this.toggleSubjectTagsExpand}
                    >
                        {subjectTagsDisplay}
                    </button>
                :
                    <>
                        {subjectTagsDisplay}
                    </>
                }
            </small>
        );
    }

    @bind private toggleSubjectTagsExpand(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({subjectTagsExpanded: !this.state.subjectTagsExpanded});
    }
}


interface State {
    modalState: ModalState;
    translations: LanguageItem[];
    subjectTagsTranslation: Subject[];
}

/**
 * The ItemMetadataTable component displays item metadata as a table.
 *
 * Used on the ItemPage and elsewhere.
 */
export class ItemMetadataTableInternal extends React.PureComponent<ItemMetadataTableProps, State> {

    constructor(props: ItemMetadataTableProps) {
        super(props);
        this.state = {
            modalState: ModalState.None,
            subjectTagsTranslation: [],
            translations: [],
        };
    }

    async componentDidMount() {
        try {
            const translations = await ItemsApi.languages({id: this.props.item.id});
            const subjectTagsTranslation = await ItemsApi.subjectAreaTranslations({id: this.props.item.id});
            this.setState({ translations, subjectTagsTranslation });
        } catch (error) {
            showErrorMessage(<WrappedMessage message={messages.loadingTranslationsError}/>);
        }
    }

    public render() {
        const item = this.props.item;

        const totalRemixes = item.stats.remixes;
        const publicRemixes = item.stats.publicRemixes || 0;
        const privateRemixes = totalRemixes - publicRemixes;

        return (<>
            <table className='item-metadata table rounded mb-0'>
                <tbody>
                    <tr>
                        <td><ItemIcon itemType={item.type} /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataContentType}/></th>
                        <td>
                            <WrappedMessage
                                message={getItemTypeMeta(item.type).name}
                                values={{count: item.itemCount}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td><Icon name='language' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataLanguage}/></th>
                        <td className='language'>
                            {getLanguageNativeName(item.language)}{' '}
                            {this.state.translations.length > 1 ?
                                <LanguageSelectorButton onClick={
                                () => this.setState({modalState: ModalState.LanguageSelector})
                            }/> : null}
                        </td>
                    </tr>
                    <tr>
                        <td><Icon name='subject' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataSubject}/></th>
                        <td>{this.renderSubjects()}</td>
                    </tr>
                    { item.backgroundKnowledge.length > 0 &&
                    <tr>
                        <td><Icon name='background-knowledge' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataBackgroundKnowledge}/></th>
                        <td><WrappedMessage message={getBackgroundKnowledgeLabel(item.backgroundKnowledge)} /></td>
                    </tr>
                    }
                    <tr>
                        <td><Icon name='star' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataFavorites}/></th>
                        <td>{item.stats.favorites.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><Icon name='eye' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataViews}/></th>
                        <td>{item.stats.views.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><Icon name='remix' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataRemixes}/></th>
                        <td>
                            {totalRemixes.toLocaleString()}
                            {totalRemixes > 0 && <>{' ('}
                                {publicRemixes > 0 ?
                                    <NavLink to={buildSearchURL(ROUTES.Library.HOME, new Set(), new Set([`Containing:${item.id}`]))}>
                                        <WrappedMessage
                                            message={messages.metadataRemixesPublic}
                                            values={{value: publicRemixes.toLocaleString()}}
                                        />
                                    </NavLink>
                                    :
                                    <WrappedMessage
                                        message={messages.metadataRemixesPublic}
                                        values={{value: publicRemixes.toLocaleString()}}
                                    />
                                }{', '}
                                <WrappedMessage
                                    message={messages.metadataRemixesPrivate}
                                    values={{value: privateRemixes.toLocaleString()}}
                                />)
                            </>}
                        </td>
                    </tr>
                    <tr>
                        <td><Icon name='license' /></td>
                        <th scope='row'><WrappedMessage message={messages.metadataLicense}/></th>
                        <td>
                            <a href={getContentLicenseUrl(item.license)} target='_blank' rel='noopener noreferrer'>
                                <WrappedMessage message={getContentLicenseLabel(item.license)} />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            {this.renderModal()}
        </>);
    }

    private renderModal() {
        switch (this.state.modalState) {
            case ModalState.LanguageSelector:
                return <TranslationSelectorModal
                    translations={this.state.translations}
                    metadata={this.props.item}
                    onClose={() => this.setState({modalState: ModalState.None})}
                    onConfirm={(translation) => {
                        this.props.history.push(detailUrlForEntity(
                            {id: translation.item, type: translation.type},
                        ));}
                    }
                />;
            default: return null;
        }
    }

    private renderSubjects() {
        if (this.state.subjectTagsTranslation.length > 0) {
            return this.state.subjectTagsTranslation.map(i => i.name).join(', ');
        }
        /* Print the subject tags without the subject area prefix if present */
        return this.props.item.subjectTags.map((t) => t.substr(t.indexOf(':') + 1)).join(', ');
    }
}

export const ItemMetadataTable = withRouter(ItemMetadataTableInternal);
