import * as React from 'react';
import classNames from 'classnames';
import messages from '../../displayMessages';
import { WrappedMessage } from 'utils';
import { Icon } from 'ui/components';

interface Props {
    onShare?(): void;
    onFollow?(): void;
    onLike?(): void;
    liked?: boolean;
    followed?: boolean;
    outline?: boolean;
    enableHovering?: boolean;
    iconZoom?: string;
}

interface State {
    isLikeButtonHovered: boolean;
}

export class ThreadShareLikeFollowButtons extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLikeButtonHovered: false,
        };
    }

    public static defaultProps = {
        outline: true,
        enableHovering: true,
        iconZoom: 'x1.5',
    };

    public render() {
        let likeButtonMessage = messages.discussionsThreadLike;
        if (this.props.liked) {
            if (this.state.isLikeButtonHovered) { likeButtonMessage = messages.discussionsThreadUnlike; }
            else { likeButtonMessage = messages.discussionsThreadLiked; }
        }

        let followButtonMessage = messages.discussionsThreadFollow;
        if (this.props.followed) {
            followButtonMessage = messages.discussionsThreadUnfollow;
        }
        return <div className='thread-share-like-buttons'>
            {this.props.onLike ?
                <button
                    className={classNames(
                        'btn',
                        'like-button',
                        (this.props.onShare || this.props.onFollow) ? '' : 'only-one',
                        this.props.outline ? 'lx-btn-outline' : '',
                        this.props.enableHovering ? 'with-hovering' : '',
                    )}
                    onClick={this.props.onLike}
                    onMouseEnter={() => this.props.enableHovering && this.setState({isLikeButtonHovered: true})}
                    onMouseLeave={() => this.setState({isLikeButtonHovered: false})}
                >
                    <Icon
                        name='heart'
                        zoom={this.props.iconZoom}
                        outline={!this.props.liked}
                        fill={this.props.liked ? '#ED3431' : 'currentColor'}
                        outlineColor={'currentColor'}
                    />
                    <span className='tslf-text'><WrappedMessage message={likeButtonMessage}/></span>
                </button>
            : null}
            {this.props.onFollow ?
                <button
                    className={classNames(
                        'btn',
                        'follow-button',
                        this.props.outline ? 'lx-btn-outline' : '',
                    )}
                    onClick={this.props.onFollow}
                >
                    <Icon
                        name='bell'
                        zoom={this.props.iconZoom}
                        outline={!this.props.followed}
                        fill={this.props.followed ? '#FAAC48' : 'currentColor'}
                        outlineColor={'currentColor'}
                    />
                    <span className='tslf-text'><WrappedMessage message={followButtonMessage}/></span>
                </button>
            : null}
            {this.props.onShare ?
                <button className={`btn ${this.props.outline ? 'lx-btn-outline' : ''} share-button`} onClick={this.props.onShare}>
                    <Icon name='reply' zoom={this.props.iconZoom}/>
                    <WrappedMessage message={messages.discussionsThreadShare}/>
                </button>
            : null}
        </div>;
    }
}
