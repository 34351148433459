import * as React from 'react';
import classNames from 'classnames';

import { Button } from 'ui/components';
import messages from '../displayMessages';

interface FeedbackButtonProps {
  variant: 'pending' | 'submitted';
  position?: 'inline' | 'floating';
  onClick: () => void;
}

export const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  onClick, variant = 'pending', position = 'floating',
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const isFullScreen = queryParams.get('fullscreen') === 'true';
  const submitted = variant === 'submitted';
  const classes = position === 'inline' ?
    classNames('default-feedback-button', { submitted }) :
    classNames({
      'yellow-feedback-button': variant === 'pending',
      'submitted-feedback-button': submitted,
      'bottom-margin': isFullScreen,
    });
  const btnStyle = position === 'inline' ? 'unstyled' : 'primary';

  return (
    <Button
      className={classes}
      btnStyle={btnStyle}
      icon={submitted ? 'check' : 'thumbs-up-fab'}
      iconZoom='20'
      iconPosition='left'
      label={submitted ?
        messages.feedbackButtonSubmittedLabel :
        messages.feedbackButtonDefaultLabel
      }
      onClick={onClick}
    />
  );
};

export default FeedbackButton;
