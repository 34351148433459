import { combineReducers } from 'redux';
import { PROBLEM_BLOCK_EDITOR_RESET_STATE } from '../actions/action-types';
import {
    GlobalProblemBlockEditorState,
    getDefaultState,
} from '../../models';
import HintSettingReducer from './HintSettingReducer';
import ScorringSettingReducer from './ScorringSettingReducer';
import MultiSelectEditorReducer from './MultiSelectEditorReducer';
import MultiSelectAnswersReducer from './MultiSelectAnswersReducer';
import ShortAnswerReducer from './ShortAnswerReducer';
import ShortAnswerEditorReducer from './ShortAnswerEditorReducer';
import SingleSelectAnswersReducer from './SingleSelectAnswersReducer';
import SingleSelectEditorReducer from './SingleSelectEditorReducer';

// No-op reducers so we don't lose data from state.
// These are only set once: with PROBLEM_BLOCK_EDITOR_RESET_STATE
const ProblemResponseTypeReducer = function(state = getDefaultState().problemResponseType, _: any) { return state; };
const BlockTypeReducer = function(state = getDefaultState().blockType, _: any) { return state; };

const assessmentEditorStateReducerInternal = combineReducers({
    problemResponseType: ProblemResponseTypeReducer,
    hintSettings: HintSettingReducer,
    scorringSettings: ScorringSettingReducer,
    multiSelectAnswers: MultiSelectAnswersReducer,
    multiSelectEditor: MultiSelectEditorReducer,
    shortAnswers: ShortAnswerReducer,
    shortAnswerEditor: ShortAnswerEditorReducer,
    singleSelectAnswers: SingleSelectAnswersReducer,
    singleSelectEditor: SingleSelectEditorReducer,
    blockType: BlockTypeReducer,
});

export const assessmentEditorStateReducer = (state: GlobalProblemBlockEditorState = getDefaultState(), action: any) => {
    // After parsing an OLX file we call ASSESSMENT_EDITOR_RESET_STATE with the complete initial state.
    if (action.type === PROBLEM_BLOCK_EDITOR_RESET_STATE) {
        return Object.assign({}, state, action.newState);
    } else {
        // Otherwise pass the action to the reducers.
        return assessmentEditorStateReducerInternal(state, action);
    }
};
