import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ROUTES } from 'global/constants';
import { LearnerMentorsDashboard } from 'mentorship/components/LearnerMentorsDashboard';
import { EducatorMenteesDashboard } from 'mentorship/components/EducatorMenteesDashboard';
import messages from './displayMessages';
import { Redirect } from 'react-router-dom';
import { getUsername } from 'auth/selectors';
import { connect } from 'react-redux';
import { RootState } from 'global/state';
import { CommunityLayout } from './Layout';

interface ReduxStateProps {
    loggedInUsername?: string;
}
export class CommunityPageInternal extends React.PureComponent<ReduxStateProps & RouteComponentProps> {
    public render() {
        return (
            <CommunityLayout pageTitle={this.pageTitle()}>
                {this.renderContent()}
            </CommunityLayout>
        );
    }

    private pageTitle() {
        switch (this.props.match.path) {
            case ROUTES.Community.MENTEES:
                return messages.menteesTitle;
            case ROUTES.Community.MENTORS:
                return messages.mentorsTab;
            default:
                // TODO: Add unknown
                return messages.menteesTitle;
        }
    }

    private renderContent() {
        switch (this.props.match.path) {
            case ROUTES.Community.MENTEES: {
                if (this.props.loggedInUsername === undefined) {
                    return null;
                }
                return <EducatorMenteesDashboard loggedInUsername={this.props.loggedInUsername} />;
            }
            case ROUTES.Community.MENTORS:
                return <LearnerMentorsDashboard history={this.props.history} />;
            case ROUTES.Community.HOME:
                return <Redirect to={ROUTES.Community.DISCUSSIONS}/>;
            default:
                return null;
        }
    }
}

export const CommunityPage = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        loggedInUsername: getUsername(state),
    }),
)(withRouter(CommunityPageInternal));
