import { defineMessages } from 'react-intl';

const messages = defineMessages({
    userProfileOwnMentorship: {
        id:'userProfileOwnMentorship',
        defaultMessage: 'Mentorship',
        description: 'Mentorship user profile section for own user.',
    },
    userProfileMentorshipAvailable: {
        id:'userProfileMentorshipAvailable',
        defaultMessage: 'Available to mentor',
        description: 'Available to mentor toggle switch label.',
    },
    userProfileMentorshipProgramDescription: {
        id:'userProfileMentorshipProgramDescription',
        defaultMessage: `On LabXchange, mentorship is a way to offer advice
                         and guidance on studies, research, and careers.
                         Mentorship is not intended as a way to provide homework help or
                         academic tutoring. You will be able to exchange messages
                         with your mentees and assign them content.
                         You can accept or reject any mentorship requests you receive.
        `,
        description: 'LabXchange mentorship program description.',
    },
    userProfileMentorshipProgramLimitDescription: {
        id:'userProfileMentorshipProgramLimitDescription',
        defaultMessage: `If you can no longer accept mentees,
                         turn off the “available to mentor” button.
                         This will not remove any existing mentees,
                         but will indicate that you aren’t accepting new mentees for now.`,
        description: 'LabXchange mentorship program limit description.',
    },
    userProfileMentorshipProgramApplicationMessageDescription: {
        id:'userProfileMentorshipProgramApplicationMessageDescription',
        defaultMessage: 'Message displayed to potential mentees:',
        description: 'LabXchange mentorship program limit description.',
    },
    menteesTitle: {
        id:'menteesTitle',
        defaultMessage: 'Mentees',
        description: 'Mentees section title.',
    },
    updateButton: {
        id:'updateButton',
        defaultMessage: 'Update',
        description: 'Displayed on update button',
    },
    cancelLink: {
        id:'cancelLink',
        defaultMessage: 'Cancel',
        description: 'Text for cancel edit link',
    },
    ariaEditMentorshipMessageButton: {
        id:'ariaEditMentorshipMessageButton',
        defaultMessage: 'Edit mentorship message button',
        description: 'Aria label for the edit mentorship message button.',
    },
    ariaTextAreaMentorshipMessage: {
        id:'ariaTextAreaMentorshipMessage',
        defaultMessage: 'Edit mentorship message text area',
        description: 'Aria label for the mentorship message text area.',
    },
});

export default messages;
