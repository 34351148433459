import {getDefaultState} from '../../models';
import {SHORT_ANSWER_EDITOR_CONTENT_CHANGE} from '../actions/action-types';

const ShortAnswerEditorReducer = function(state = getDefaultState().shortAnswerEditor, action: any) {
    switch(action.type) {
        case SHORT_ANSWER_EDITOR_CONTENT_CHANGE:
            return Object.assign({}, state, {content: action.content});
        default:
            return state;
    }
};

export default ShortAnswerEditorReducer;
