import { getLoggedInStatus } from 'auth/selectors';
import { authLocalStorageManager } from 'auth/utils';
import bind from 'bind-decorator';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { About } from 'home/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import { SignupBirthdate } from './SignupBirthdate';

enum Step {
    AskBirthdate = 0,
    CreateYourAccount,
    RegistrationDenied,
}

interface SignupProps extends RouteComponentProps<{}> { }

interface ReduxStateProps {
    isLoggedIn: boolean;
}

interface SignupState {
    step: Step;
    cancel: boolean;
}

class SignupPageInternal extends React.PureComponent<SignupProps & ReduxStateProps, SignupState> {

    constructor(props: SignupProps & ReduxStateProps) {
        super(props);
        this.state = {
            step: Step.AskBirthdate,
            cancel: false,
        };
    }

    public getComponent() {
        switch (this.state.step) {
            case Step.AskBirthdate: {
                return (
                    <StandardPageLayout>
                        <SignupBirthdate
                            onCancel={() => this.onCancel()}
                            onSuccess={() => this.setState({step: Step.CreateYourAccount})}
                            onFailure={() => this.setState({step: Step.RegistrationDenied})}
                        />
                    </StandardPageLayout>
                );
            }
            case Step.CreateYourAccount: {
                return (<Redirect to={ROUTES.General.SIGN_IN} push={true}/>);
            }
            case Step.RegistrationDenied: {
                return (<Redirect to={ROUTES.General.SIGNUP_DENIED_AGE}/>);
            }
        }
    }

    public render() {
        if (this.state.cancel) {
            return <About />;
        }
        // If user is logged in and birthday is not requested
        // Then redirect to home.
        if (this.props.isLoggedIn && authLocalStorageManager.userBirthdate !== null) {
            return <Redirect to={ROUTES.All.HOME} push={true}/>;
        }
        return this.getComponent();
    }

    @bind private onCancel() {
        this.setState({
            cancel: true,
        });
    }
}

export const SignupPage = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(withRouter(SignupPageInternal));
