import * as React from 'react';
import { Redirect } from 'react-router';

import { authLocalStorageManager } from 'auth/utils';
import { ROUTES } from 'global/constants';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

/**
 * Display an error message and redirect the user to the login page.
 */
export const RedirectToLogin: React.FunctionComponent<{}> = () => {

    // Set the current URL so the user will get redirected back here:
    authLocalStorageManager.authRedirectToLocation = window.location;
    // To avoid an infinite series of redirects in the event of an auth_token
    // that is present but expired, clear it just in case:
    localStorage.removeItem('auth_token');

    return <>
        <div className='alert alert-warning' role='alert'>
            <WrappedMessage message={messages.loginRequired} />
        </div>
        <Redirect to={ROUTES.General.SIGN_IN} />
    </>;
};
