import { authLocalStorageManager } from 'auth/utils';
import { ProfileOnboardingStatusEnum } from 'labxchange-client';
import { ROUTES } from '../global/constants';
import { history } from '../global/history';
import { getStore } from '../global/store';

/**
 * Replace the current URL with the specified one, unless we're
 * already on that page.
 */
function replaceWithUrl(url: string) {
    if (history.location.pathname !== url) {
        // Without the timeout, the URL gets replaced, but the page does not re-render for some reason.
        setTimeout(() => history.replace(url), 0);
    }
}

/**
 * Check if the user has completed onboarding, and onboard them if not.
 * We check this when they login and any time the URL changes.
 */
export function checkOnboardingStatus() {

    const onboardingStatus = getStore().getState().loginState.onboardingStatus;
    const userRole = getStore().getState().loginState.role;
    const authRedirectTo = authLocalStorageManager.authRedirectTo;
    const hasClass = getStore().getState().loginState.hasClass;

    const LEARNER_ROLE = 'learner';
    // Onboarding status is fetched from the user profile,
    // so indicates that we're logged in and profile has been retrieved.
    if (onboardingStatus === null) { return; }

    // First, users must complete registration.
    if (onboardingStatus !== ProfileOnboardingStatusEnum.Completed) {
        replaceWithUrl(ROUTES.General.ONBOARDING);
    } else if (authRedirectTo) {
        // Now, users can be redirected back to their pre-auth URL, if any.
        // Clear the authRedirectTo so we never loop back here.
        authLocalStorageManager.authRedirectTo = null;

        // if user has signed up using class code, we will always redirect them to classes dashboard.
        if (authLocalStorageManager.signedUpUsingClassCode) {
            replaceWithUrl(ROUTES.Dashboard.LEARNER_CLASSROOMS);
        } else if (authRedirectTo === '/') {
            const redirectUrl = userRole === LEARNER_ROLE ? (hasClass ? ROUTES.Dashboard.LEARNER_CLASSROOMS : ROUTES.Subjects) : ROUTES.Teach;
            replaceWithUrl(redirectUrl);
        } else {
            replaceWithUrl(authRedirectTo);
        }
    }
}

history.listen(checkOnboardingStatus);
