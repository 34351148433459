import * as React from 'react';
import { Route, Switch } from 'react-router';

import { LoginRedirect } from 'auth/components/LoginRedirect';
import { ROUTES } from 'global/constants';

import { ContentRoutes } from './content';

export const MainRoutes = () => (
    <Switch>
        <Route exact path={ROUTES.Redirects.LOGIN_COMPLETE} component={LoginRedirect} />
        <Route path={ROUTES.All.HOME} component={ContentRoutes} />
    </Switch>
);
