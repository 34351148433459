import * as React from 'react';
import { getLoggedInStatus } from 'auth/selectors';
import { getStore } from 'global/store';
import { UsersApi } from '../../../global/api';
import { locale, setLanguageCookie } from 'i18n';
import { intl } from 'i18n';
import classNames from 'classnames';

import { WrappedMessage } from 'utils';
import { Button, Modal, Icon } from 'ui/components';
import { LanguageSelector } from './LanguageSelector';
import { EVENT_NAMES } from 'tracking/constants';
import { analyticsInstance } from '../../../tracking';
import { UILanguages } from '../../../i18n';
import messages from './displayMessages';

interface Props {
  className?: string;
}

export const LocaleSwitcherV2: React.FC<Props> = ({ className }) => {
  const [enabled, setEnabled] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(locale);

  const handleClick = () => {
    analyticsInstance.track(
        EVENT_NAMES.LocaleSwitcherFooterButtonClicked,
        { url: window.location.toString() }
    );
    setEnabled(true);
  };

  const onCloseModal = () => {
    setSelectedLanguage(locale);
    setEnabled(false);
  };

  const onConfirm = async () => {
    analyticsInstance.track(
      EVENT_NAMES.FooterSetLanguageButtonClicked,
      {
        selected_language: selectedLanguage,
        url: window.location.toString()
      },
      { sendImmediately: true },
    );
    const loggedIn = getLoggedInStatus(getStore().getState());
    if (loggedIn) {
      await UsersApi.changeLanguage({data: {preferredLanguage: selectedLanguage}});
    }
    setLanguageCookie(selectedLanguage);
    window.location.reload();
  };

  const renderModal = () => (
    <Modal
      className='modal-close-header-action'
      onRequestClose={onCloseModal}
      closeButtonText={messages.languageSelectCancel}
      size='normal'
      title={<WrappedMessage message={messages.languageSelectTitle} />}
      showTopBar={true}
      content={
        <div className='translation-selector-modal'>
          <LanguageSelector
            currentLanguage={selectedLanguage}
            onSetLanguage={(translation) => setSelectedLanguage(translation)}
          />
          <Button
            btnStyle='primary'
            fullWidth={true}
            label={messages.languageSelectConfirm}
            onClick={onConfirm}
          />
        </div>
      }
    />
  );

  return (
    <>
        <button
            className={classNames('locale-switcher-v2', 'font-xs-lt', className)}
            onClick={() => handleClick()}
            aria-label={intl.formatMessage(messages.changeLanguageIcon)}
        >
            <Icon name='globe-wireframe' zoom='20' />
            {UILanguages[selectedLanguage]?.nativeName || ''}
            <Icon name='chevron-down' />
        </button>
        {enabled && renderModal()}
    </>
  );
};

export default LocaleSwitcherV2;
