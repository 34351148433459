import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';
import { Icon } from 'ui/components';

interface Props {
    className?: string;
    bodyMessage: MessageDescriptor;
    type: 'warning' | 'success';
    bodyMessageValues?: any;
    link?: string;
    linkMessage?: MessageDescriptor;
    showCloseIcon?: boolean;
    onClose?: () => void;
}

export const AlertBox: React.FunctionComponent<Props> = (props) => {
    const className = `alert-box ${props.className ? props.className : ''} ${props.type}`;

    return (
        <div className={className}>
            <div className='alert-icon'></div>
            <div className='alert-text'>
                {intl.formatMessage(props.bodyMessage, props.bodyMessageValues)}
                { props.link && props.linkMessage &&
                    <a className='alert-link' href={props.link}>
                        {intl.formatMessage(props.linkMessage)}
                    </a>
                }
            </div>
            { props.showCloseIcon &&
                <button onClick={props.onClose} className='close-button' aria-label='close-alert'>
                    <span className='close-icon'><Icon name='x' zoom='14' /></span>
                </button>
            }
        </div>
    );
};
