// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface XBlockStaticUploadResult
 */
export interface XBlockStaticUploadResult {
    /**
     * 
     * @type {string}
     * @memberof XBlockStaticUploadResult
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof XBlockStaticUploadResult
     */
    filename: string;
    /**
     * 
     * @type {number}
     * @memberof XBlockStaticUploadResult
     */
    size: number;
}

export function XBlockStaticUploadResultFromJSON(json: any): XBlockStaticUploadResult {
    return {
        'url': json['url'],
        'filename': json['filename'],
        'size': json['size'],
    };
}

export function XBlockStaticUploadResultToJSON(value?: XBlockStaticUploadResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
        'filename': value.filename,
        'size': value.size,
    };
}


