import { defineMessages } from 'react-intl';

const messages = defineMessages({
    explorePageTitle: {
        id:'explorePageTitle',
        defaultMessage: 'Explore',
        description: 'HTML title for the Explore page.',
    },
    exploreContentTitle: {
        id:'exploreContentTitle',
        defaultMessage: 'Explore content',
        description: 'Explore page title.',
    },
    exploreContentBaseline: {
        id:'exploreContentBaseline',
        defaultMessage: `On LabXchange, there is something for everyone.`,
        description: 'Explore page baseline.',
    },
    contentOfTheWeek: {
        id:'contentOfTheWeek',
        defaultMessage: 'Content of the week',
        description: 'Content of the week label.',
    },
    // TODO: Plural is not working as expected
    favoritesCount: {
        id:'favoritesCount',
        defaultMessage: '{count, number} {count, plural, one {Favorite} other {Favorites}}',
        description: `
            The number of times this asset has been favorited or liked by users. Only term "Favorite" and "Favorites" need to be translated.
             Translator can ignore and leave remaining brackets or variables (count, number etc) as is.
             The syntax indicates that the phrase in {} after "one" will be used when the count is 1 and the phrase in {} after "other" will be used when the count is more than 1.
             An example of translated string can be "{count, number} {count, plural, one {Translation of Favorite} other {Translation of Favorites}}".
        `,
    },
    viewsCount: {
        id:'viewsCount',
        defaultMessage: '{count, number} {count, plural, one {View} other {Views}}',
        description: 'Views count.',
    },
    remixesCount: {
        id:'remixesCount',
        defaultMessage: '{count, number} {count, plural, one {Remix} other {Remixes}}',
        description: `
            The number of times this asset has been remixed. Remix is a numerical value that indicates how many times an asset was used in pathways.
             Only term "Remix" and "Remixes" need to be translated. Translator can ignore and leave remaining brackets or variables (count, number etc) as is.
             The syntax indicates that the phrase in {} after "one" will be used when the count is 1 and the phrase in {} after "other" will be used when the count is more than 1.
             An example of translated string can be "{count, number} {count, plural, one {Translation of Remix} other {Translation of Remixes}}".
        `,
    },
    viewContentButton: {
        id:'viewContentButton',
        defaultMessage: 'View content',
        description: 'View content button.',
    },
    multipleAuthorsName: {
        id:'multipleAuthorsName',
        defaultMessage: '{firstAuthor} +{count, number}',
        description: 'Representation of authors for content that was authored by more than one person.',
    },
    exploreByBaseline: {
        id:'exploreByBaseline',
        defaultMessage: 'Explore by',
        description: 'Explore by baseline',
    },
    exploreMoreButton: {
        id:'exploreMoreButton',
        defaultMessage: 'See more',
        description: 'Explore more button',
    },
    exploreMoreHomeButton: {
        id:'exploreMoreHomeButton',
        defaultMessage: 'Explore more',
        description: 'Explore more button for home page',
    },
    exploreMoreResourcesButton: {
        id:'exploreMoreResourcesButton',
        defaultMessage: 'More {category} resources ',
        description: 'Explore more button',
    },
    exploreMoreResourcesSRMessage: {
        id:'exploreMoreResourcesSRMessage',
        defaultMessage: '{categoryName} resources ',
        description: 'See more button SR text with category',
    },
    exploreMoreLearn: {
        id:'exploreMoreLearnerResourceName',
        defaultMessage: 'learner',
        description: 'Explore more button category wise text',
    },
    exploreMoreTeach: {
        id:'exploreMoreTeacherResourceName',
        defaultMessage: 'teaching',
        description: 'Explore more button category wise text',
    },
    exploreMoreResearch: {
        id:'exploreMoreResearchButton',
        defaultMessage: 'study',
        description: 'Explore more button category wise text',
    },
    exploreFooterTitle: {
        id:'exploreFooterTitle',
        defaultMessage: 'Not sure what you’re looking for?',
        description: 'Explore footer title',
    },
    exploreFooterBaseline: {
        id:'exploreFooterBaseline',
        defaultMessage: 'Search for content that matches your interests.',
        description: 'Explore footer baseline.',
    },
    exploreFooterButton: {
        id:'exploreFooterButton',
        defaultMessage: 'Get started',
        description: 'Explore footer button.',
    },
    categoryContentEmptyState: {
        id:'categoryContentEmptyState',
        defaultMessage: 'Please explore other sections!',
        description: 'Empty state message for category content.',
    },
    // #------------#
    // | Categories |
    // #------------#
    // * Featured subjects *
    featuredSubjectsCategory: {
        id:'featuredSubjectsCategory',
        defaultMessage: 'Featured Subjects',
        description: 'Featured subjects category.',
    },
    featuredSubjectsCategoryDescription: {
        id:'featuredSubjectsCategoryDescription',
        defaultMessage: 'Check it out! These are the LabXchange featured\'s subjects resources.',
        description: 'Featured subjects category description.',
    },
    introductinToLabXchangeSubCategory: {
        id:'introductinToLabXchangeSubCategory',
        defaultMessage: 'Introduction to LabXchange',
        description: 'Introduction to LabXchange subcategory.',
    },
    biologicalSciencesSubCategory: {
        id:'biologicalSciencesSubCategory',
        defaultMessage: 'Biological Sciences',
        description: 'Biological sciences subcategory.',
    },
    chemistrySubCategory: {
        id:'chemistrySubCategory',
        defaultMessage: 'Chemistry',
        description: 'Chemistry subcategory.',
    },
    globalHealthSubCategory: {
        id:'globalHealthSubCategory',
        defaultMessage: 'Global Health',
        description: 'Global health subcategory.',
    },
    healthScienceSubCategory: {
        id:'healthScienceSubCategory',
        defaultMessage: 'Health Science',
        description: 'Health science subcategory.',
    },
    scienceAndSocietySubCategory: {
        id:'scienceAndSocietySubCategory',
        defaultMessage: 'Science & Society',
        description: 'Science & society subcategory.',
    },
    scientificProcessSubCategory: {
        id:'scientificProcessSubCategory',
        defaultMessage: 'Scientific Process',
        description: 'Scientific process subcategory.',
    },
    prepareForCollegeSubCategory: {
        id:'prepareForCollegeSubCategory',
        defaultMessage: 'Prepare for College',
        description: 'Prepare for college subcategory.',
    },
    prepareForGraduateSchoolSubCategory: {
        id:'prepareForGraduateSchoolSubCategory',
        defaultMessage: 'Prepare for Graduate School',
        description: 'Prepare for graduate school subcategory.',
    },
    prepareForCareersSubCategory: {
        id:'prepareForCareersSubCategory',
        defaultMessage: 'Prepare for Careers',
        description: 'Prepare for careers subcategory.',
    },
    professionalDevelopmentSubCategory: {
        id:'professionalDevelopmentSubCategory',
        defaultMessage: 'Professional Development',
        description: 'Professional development subcategory.',
    },
    // * Content type *
    contentTypeCategory: {
        id:'contentTypeCategory',
        defaultMessage: 'Content Type',
        description: 'Content type category.',
    },
    contentTypeCategoryDescription: {
        id:'contentTypeCategoryDescription',
        defaultMessage: 'Check it out! These are the LabXchange content\'s type resources.',
        description: 'Content type category description.',
    },
    clusterSubCategory: {
        id:'clusterSubCategory',
        defaultMessage: 'Clusters',
        description: 'Clusters subcategory.',
    },
    pathwaySubCategory: {
        id:'pathwaySubCategory',
        defaultMessage: 'Pathways',
        description: 'Pathways subcategory.',
    },
    interactiveSubCategory: {
        id:'interactiveSubCategory',
        defaultMessage: 'Interactives',
        description: 'Interactives subcategory.',
    },
    simulationSubCategory: {
        id:'simulationSubCategory',
        defaultMessage: 'Simulations',
        description: 'Simulations subcategory.',
    },
    videoSubCategory: {
        id:'videoSubCategory',
        defaultMessage: 'Videos',
        description: 'Videos subcategory.',
    },
    narrativeSubCategory: {
        id:'narrativeSubCategory',
        defaultMessage: 'Narratives',
        description: 'Narratives subcategory.',
    },
    caseStudySubCategory: {
        id:'caseStudySubCategory',
        defaultMessage: 'Case Studies',
        description: 'Case studies subcategory.',
    },
    documentSubCategory: {
        id:'documentSubCategory',
        defaultMessage: 'Documents',
        description: 'Documents subcategory.',
    },
    assignmentSubCategory: {
        id:'assignmentSubCategory',
        defaultMessage: 'Question Sets',
        description: 'Assignments subcategory.',
    },
    teachingGuideSubCategory: {
        id:'teachingGuideSubCategory',
        defaultMessage: 'Teaching Guides',
        description: 'Teaching Guides subcategory',
    },
    textSubCategory: {
        id:'textSubCategory',
        defaultMessage: 'Text',
        description: 'Text subcategory.',
    },
    imageSubCategory: {
        id:'imageSubCategory',
        defaultMessage: 'Images',
        description: 'Images subcategory.',
    },
    audioSubCategory: {
        id:'audioSubCategory',
        defaultMessage: 'Audio',
        description: 'Audio subcategory.',
    },
    // * Content sources *
    contentSourcesCategory: {
        id:'contentSourcesCategory',
        defaultMessage: 'Content Sources',
        description: 'Content sources category.',
    },
    contentSourcesCategoryDescription: {
        id:'contentSourcesCategoryDescription',
        defaultMessage: 'Check it out! These are the LabXchange content\'s sources resources.',
        description: 'Content sources category description.',
    },
    abeSubCategory: {
        id:'abeSubCategory',
        defaultMessage: 'Amgen Biotech Experience  ',
        description: 'Amgen Biotech Experience subcategory.',
    },
    khanAcademySubCategory: {
        id:'khanAcademySubCategory',
        defaultMessage: 'Khan Academy',
        description: 'Khan Academy subcategory.',
    },
    phETSubCategory: {
        id:'phETSubCategory',
        defaultMessage: 'PhET',
        description: 'PhET subcategory.',
    },
    pgEdSubCategory: {
        id:'pgEdSubCategory',
        defaultMessage: 'pgEd',
        description: 'PgEd subcategory.',
    },
    nhgriSubCategory: {
        id:'nhgriSubCategory',
        defaultMessage: 'NHGRI',
        description: 'NHGRI subcategory.',
    },
    theJournalOfStoriesInScienceSubCategory: {
        id:'theJournalOfStoriesInScienceSubCategory',
        defaultMessage: 'The Journal of Stories in Science',
        description: 'The Journal of Stories in Science subcategory.',
    },
    wellcomeGenomeCampusSubCategory: {
        id:'wellcomeGenomeCampusSubCategory',
        defaultMessage: 'Wellcome Genome Campus',
        description: 'Wellcome Genome Campus subcategory.',
    },
    iBiologySubCategory: {
        id:'iBiologySubCategory',
        defaultMessage: 'iBiology',
        description: 'iBiology subcategory.',
    },
    harvardMedicalSchoolSubCategory: {
        id:'harvardMedicalSchoolSubCategory',
        defaultMessage: 'Harvard Medical School',
        description: 'Harvard Medical School subcategory.',
    },
    harvardUDMCBSubCategory: {
        id:'harvardUDMCBSubCategory',
        defaultMessage: 'Harvard University Department of Molecular and Cellular Biology',
        description: 'Harvard Medical School subcategory.',
    },
    harvardUniversityFASCenterOfSystemsBiologySubCategory: {
        id:'harvardUniversityFASCenterOfSystemsBiologySubCategory',
        defaultMessage: 'Harvard University FAS Center of Systems Biology',
        description: 'Harvard University FAS Center of Systems Biology subcategory.',
    },
    harvardXSubCategory: {
        id:'harvardXSubCategory',
        defaultMessage: 'HarvardX',
        description: 'HarvardX subcategory.',
    },
    scienceIRLSubCategory: {
        id:'scienceIRLSubCategory',
        defaultMessage: 'ScienceIRL',
        description: 'ScienceIRL subcategory.',
    },
    theConcordConsortiumSubCategory: {
        id:'theConcordConsortiumSubCategory',
        defaultMessage: 'The Concord Consortium',
        description: 'The Concord Consortium subcategory.',
    },
    bioBuilderSubCategory: {
        id:'bioBuilderSubCategory',
        defaultMessage: 'BioBuilder',
        description: 'BioBuilder subcategory.',
    },
    // * Community Favorites *
    communityFavoritesCategory: {
        id:'communityFavoritesCategory',
        defaultMessage: 'Community Favorites',
        description: 'Community Favorites category.',
    },
    communityFavoritesCategoryDescription: {
        id:'communityFavoritesCategoryDescription',
        defaultMessage: 'Check it out! These are the LabXchange community\'s favorite resources.',
        description: 'Community favorites category description.',
    },
    // * Trending topics *
    trendingTopicsCategory: {
        id:'trendingTopicsCategory',
        defaultMessage: 'Trending Topics',
        description: 'Trending topics category.',
    },
    trendingTopicsCategoryDescription: {
        id:'trendingTopicsCategoryDescription',
        defaultMessage: 'Check it out! These are the LabXchange trending\'s topics resources.',
        description: 'Trending topics category description.',
    },
    featuredItemsError: {
        id:'featuredItemsError',
        defaultMessage: 'Unable to load featured items.',
        description: 'Error message when an explore filter fails.',
    },
    seeMoreResourcesButton: {
        id:'seeMoreResourcesButton',
        defaultMessage: 'More',
        description: 'See more button',
    },
    groupedContentText: {
        id:'groupedContentText',
        defaultMessage: 'Curated Collections',
        description: 'Text content to show along with clusters and pathways.',
    },
    exploreByContentType: {
        id:'exploreByContentType',
        defaultMessage: 'Explore by content type',
        description: 'Explore by content type section heading title',
    },
    viewAllContentTypes: {
        id:'viewAllContentTypes',
        defaultMessage: 'View all types',
        description: 'Explore by content type section header link',
    },
});

export default messages;
