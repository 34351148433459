import {ROUTES} from 'global/constants';
import { clipboardCopy } from 'global/utils';
import * as React from 'react';
import { Route } from 'react-router';
import { Icon, NavTabs, NavTabsLink, ToggleSwitch } from 'ui/components';
import { WrappedMessage } from 'utils';
import { intl } from 'i18n';

import messages from './displayMessages';
import allMessages from '../../displayMessages';

interface Props {
    classroomId: string;
    classroomName: string;
    classroomJoinCode?: string;
    learnerViewEnabled: boolean;
    onClassSettings: () => void;
    onToggleLearnerView: (setFlag: boolean) => void;
}

export const ClassroomPageHeaderEducator: React.FC<Props> = (
    { classroomId, classroomName, classroomJoinCode, learnerViewEnabled, onClassSettings, onToggleLearnerView }) => (
        <>
            <div className='container container-sm-full'>
                <div className='classroom-header-educator'>
                    <div className='classroom-details'>
                        <h1 className='classroom-name'>{classroomName}</h1>
                        {document.queryCommandSupported('copy') &&
                            <div className='classroom-join-code-container'>
                                <div className='classroom-join-code'>Class code: {classroomJoinCode}</div>
                                <button className='unstyled classroom-copy-join-code'
                                    onClick={() => { clipboardCopy(classroomJoinCode); }}
                                    aria-label={intl.formatMessage(messages.copyClassCodeButtonTextAria)}
                                >
                                    <Icon name='file' zoom='20' />
                                    <WrappedMessage message={messages.copyClassCodeButtonText} />
                                </button>
                            </div>
                        }
                    </div>

                    <div className='class-settings-button'>
                        <button onClick={onClassSettings}>
                            <Icon name='gear' zoom='22' />
                            <WrappedMessage message={messages.classSettingsButtonText} />
                        </button>
                    </div>
                </div>
            </div>
            <NavTabs>
                <NavTabsLink
                    to={ROUTES.Classrooms.CLASSROOM_SLUG(classroomId)} exact
                    icon='file-submodule'
                    message={messages.navlinkContent}
                />
                <NavTabsLink
                    to={ROUTES.Classrooms.CLASSROOM_LEARNERS_SLUG(classroomId)} exact
                    icon='organization'
                    message={messages.navlinkLearners}
                />
                <NavTabsLink
                    to={ROUTES.Classrooms.CLASSROOM_PROGRESS_SLUG(classroomId)} exact
                    icon='rocket'
                    message={messages.navlinkReport}
                />
                <NavTabsLink
                    to={ROUTES.Classrooms.CLASSROOM_DISCUSSION_SLUG(classroomId)} exact
                    icon='comment-discussion'
                    message={messages.navlinkDiscussion}
                />
                <Route exact path={ROUTES.Classrooms.CLASSROOM_SLUG(classroomId)}>
                    <div className='learner-view-toggle-container'>
                        <ToggleSwitch
                            isChecked={learnerViewEnabled}
                            hideLabel={false}
                            displayText={intl.formatMessage(messages.learnerView)}
                            ariaText={intl.formatMessage(allMessages.learnerViewToggleAria)}
                            onToggleSwitchSelect={onToggleLearnerView}
                            labelPosition='left'
                        />
                    </div>
                </Route>
            </NavTabs>
        </>
    );
