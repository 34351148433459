// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemMetadata,
    ItemMetadataFromJSON,
    ItemMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface TotalScoreItem
 */
export interface TotalScoreItem {
    /**
     * 
     * @type {ItemMetadata}
     * @memberof TotalScoreItem
     */
    item: ItemMetadata;
    /**
     * 
     * @type {Date}
     * @memberof TotalScoreItem
     */
    completionDate: Date;
    /**
     * 
     * @type {number}
     * @memberof TotalScoreItem
     */
    weightedEarned: number;
    /**
     * 
     * @type {number}
     * @memberof TotalScoreItem
     */
    weightedPossible: number;
}

export function TotalScoreItemFromJSON(json: any): TotalScoreItem {
    return {
        'item': ItemMetadataFromJSON(json['item']),
        'completionDate': new Date(json['completion_date']),
        'weightedEarned': json['weighted_earned'],
        'weightedPossible': json['weighted_possible'],
    };
}

export function TotalScoreItemToJSON(value?: TotalScoreItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'item': ItemMetadataToJSON(value.item),
        'completion_date': value.completionDate.toISOString(),
        'weighted_earned': value.weightedEarned,
        'weighted_possible': value.weightedPossible,
    };
}


