import { Thread } from 'labxchange-client';
import * as React from 'react';
import { ThreadCard } from '../ThreadCard';

interface Props {
    threads: Thread[];
    getThreadLink(threadId: number): string;
    onPin?(threadId: number, pinned: boolean): void;
    onDelete?(threadId: number): void;
}

export const ThreadCardList: React.FC<Props> = (props) => {
    const threads = props.threads.map((thread) => {
        return (
            <ThreadCard
                key={`thread-${thread.id}`}
                thread={thread}
                threadLink={props.getThreadLink(thread.id)}
                onPin={props.onPin}
                onDelete={props.onDelete}
            />);
    });
    return (<div className='thread-card-list'>{threads}</div>);
};
