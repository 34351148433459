import * as React from 'react';

interface ContainerOneProps extends React.PropsWithChildren {
    theme?: undefined | 'navy';
    canOverflow?: boolean;
}

/**
 * A component with a white background, rounded corners and blue border.
 */
export const ContainerOne: React.FunctionComponent<ContainerOneProps> = (props) => {
    return (
        <div className={
                `container-one rounded` +
                (props.theme ? ` container-one-${props.theme}` : '') +
                `${props.canOverflow ? ' container-one-overflow' : ''}`
        }>
            {props.children}
        </div>
    );
};

interface ContainerInsetProps extends React.PropsWithChildren {
    justify?: 'start' | 'end' | 'center' | 'between' | 'around';
    otherClasses?: string; // Mix in classes like https://getbootstrap.com/docs/4.0/utilities/flex/
}

/**
 * An inset panel at the top or bottom of a container. Has a darker background.
 */
export const ContainerInset: React.FunctionComponent<ContainerInsetProps> = ({
    justify = 'center',
    otherClasses = 'p-3',
    children,
}) => {
    return (
        <div className={`container-inset d-flex justify-content-${justify} ${otherClasses}`}>
            {children}
        </div>
    );
};
