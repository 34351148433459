// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Tour
 */
export interface Tour {
    /**
     * 
     * @type {string}
     * @memberof Tour
     */
    title: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tour
     */
    items: Array<number>;
}

export function TourFromJSON(json: any): Tour {
    return {
        'title': json['title'],
        'items': json['items'],
    };
}

export function TourToJSON(value?: Tour): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'items': value.items,
    };
}


