import * as React from 'react';
import { useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';
import { Icon } from 'elements/components/Icons';
import { useOnClickOutside } from 'ui/hooks';

export interface Props {
    inputKey: string;
    className?: string;
    value: string;
    onChangeValue: (value: string) => void;
    onFocusOut?: (value: string) => void;
    type: 'text' | 'password' | 'date';
    placeholder?: MessageDescriptor;
    invalid?: boolean;
    feedbackMessage?: MessageDescriptor;
    feedbackMessageValues?: any;
    inputAccessory?: React.ReactNode;
    barAccessory?: React.ReactNode;
    hidden?: boolean;
    autoComplete?: string;
}

export const TextInput: React.FunctionComponent<Props> = (props) => {
    const alertIconClassName = props.inputAccessory ? 'alert-icon with-accessory' : 'alert-icon';
    const placeholderTitle = props.placeholder ? intl.formatMessage(props.placeholder): '';
    const feedbackMessageId = `${props.className}-feedback-message`;
    return (
        <div className={`lx-text-input ${props.className || ''}`} hidden={props.hidden}>
            { props.invalid &&
                <Icon key='test' name='alert-info-hex-2' className={alertIconClassName}/>
            }
            <>{ /*
                We can't add 'onClick={(event) => { event.preventDefault(); }}'
                to delete the datepicker, because also deletes the funcionality on mobile
            */ }</>
            <input
                required
                aria-label={placeholderTitle}
                autoComplete={props.autoComplete}
                className={props.invalid ? 'invalid' : ''}
                type={props.type}
                id={props.inputKey}
                value={props.type !== 'date' ? props.value: undefined}
                onChange={(event) => props.onChangeValue(event.target.value)}
                onBlur={(event) => { if (props.onFocusOut) props.onFocusOut(event.target.value); }}
                max='3000-12-12'
                {...((!props.invalid && props.feedbackMessage) && {'aria-describedby': feedbackMessageId})}
            />
            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
            <label
                className='placeholder'
                htmlFor={props.inputKey}
                title={placeholderTitle}
            />
            { props.feedbackMessage &&
                <div
                    id={feedbackMessageId}
                    className={`feedback-message ${props.invalid ? 'invalid' : ''}`}
                    {...(props.invalid && {'aria-live': 'polite'})}
                >
                    {
                        props.feedbackMessage ?
                        intl.formatMessage(props.feedbackMessage, props.feedbackMessageValues) :
                        ''
                    }
                </div>
            }
            { props.inputAccessory &&
                <div className={`input-accessory ${props.invalid ? 'invalid': ''}`}>
                    {props.inputAccessory}
                </div>
            }
            { props.barAccessory &&
                <div className='bar-accessory'>
                    {props.barAccessory}
                </div>
            }
        </div>
    );
};

export interface ChoiceOption {
    value: string;
    displayName: string;
}

export interface AutoCompleteTextInputProps {
    key: string;
    value?: ChoiceOption;
    onChangeValue: (value?: ChoiceOption) => void;
    choices: ChoiceOption[];
    placeholder?: MessageDescriptor;
    invalid?: boolean;
    errorMessage?: MessageDescriptor;
}

/**
 * Text input with autocomplete suggestions.
 *
 * This takes in a list of `ChoiceOption` items and
 * allows the user to "search" through them by typing
 * on an input.
 *
 * It returns a `ChoiceOption` if:
 * 1. The user started typing and selected one of the suggestions.
 * 2. The user typed the exact match for the displayName property on
 *    one of the `ChoiceOption` items available.
 *
 * Only retuns values through `onChangeValue` if a
 * valid option is selected (or typed), else it returns `undefined`.
 */
export const AutoCompleteTextInput = (props: AutoCompleteTextInputProps) => {
    const [inputValue, setInputValue] = useState(props.value?.displayName || '');
    const [showOptions, setShowOptions] = useState(false);
    const availableChoices = props.choices.filter((item) => {
        return item.displayName.toLowerCase().startsWith(inputValue.toLowerCase());
    });
    const isInvalid = props.invalid || availableChoices.length === 0;

    // Mechanism to hide suggestions if a click outside the
    // list is detected.
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    useOnClickOutside(dropdownRef, () => {setShowOptions(false);});

    /**
     * Tries matching the text in the input with one of
     * the `ChoiceOption` items, returns undefined if
     * not possible.
     *
     * @param newValue String coming from text input
     */
    const onValueChange = (newValue: string) => {
        setInputValue(newValue);
        setShowOptions(newValue !== '');
        const match = props.choices.find(
            (choice) => choice.displayName.toLowerCase() === newValue.toLowerCase()
        );
        if (match) {
            props.onChangeValue(match);
        } else {
            props.onChangeValue();
        }
    };

    return (
        <div className='lx-text-input'>
            { isInvalid &&
                <Icon key='test' name='alert-info-hex-2' className='alert-icon'/>
            }
            <input
                required
                className={isInvalid ? 'invalid' : showOptions ?  'show-options' : ''}
                type='text'
                id={props.key}
                value={inputValue}
                onChange={(event) => onValueChange(event.target.value)}
            />
            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
            <label
                className='placeholder'
                htmlFor={props.key}
                title={props.placeholder ? intl.formatMessage(props.placeholder): ''}
            />
            { isInvalid &&
                <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label
                    className='error-message'
                    title={props.errorMessage ? intl.formatMessage(props.errorMessage): ''}
                ></label>
                </>
            }
            { showOptions && (
                <div className='autocomplete-suggestions' ref={dropdownRef}>
                    {
                        availableChoices.map((item) => {
                            return <button
                                className='btn inner-internal-button lx-btn-outline lx-btn-left-icon'
                                onClick={() => {
                                    setInputValue(item.displayName);
                                    props.onChangeValue(item);
                                    setShowOptions(false);
                                }}
                            >
                                {item.displayName}
                            </button>;
                        })
                    }
                </div>
            )}
        </div>
    );
};
