import { bind } from 'bind-decorator';
import * as React from 'react';

import { HtmlTextBox, ThinItemSection } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { NarrativeEditorState, SpecificEditorProps } from './models';

type Props = SpecificEditorProps<NarrativeEditorState>;

/**
 * Editor UI for Narrative components (lx_narrative)
 */
export class NarrativeBlockEditor extends React.PureComponent<Props> {

    public render() {
        const editorState = this.props.editorState;
        if (editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return <>
                <ThinItemSection>
                    <HtmlTextBox
                        label={<WrappedMessage message={messages.narrativeEditorKeyPointsField} />}
                        required={true}
                        showErrors={this.props.showErrors}
                        defaultValue={editorState.keyPoints}
                        onChange={this.onKeyPointsChange}
                        editorStyle={HtmlTextBox.EditorStyle.KeyPoints}
                        description={<WrappedMessage message={messages.narrativeEditorKeyPointsDescriptionField} />}
                    />
                </ThinItemSection>
                <ThinItemSection>
                    <HtmlTextBox
                        label={<WrappedMessage message={messages.narrativeEditorNarrativeField} />}
                        required={true}
                        showErrors={this.props.showErrors}
                        defaultValue={editorState.narrative}
                        onChange={this.onNarrativeChange}
                        description={<WrappedMessage message={messages.narrativeEditorNarrativeDescriptionField} />}
                    />
                </ThinItemSection>
            </>;
        }
    }

    @bind private onKeyPointsChange(keyPoints: string) {
        this.props.onEditorStateChanged(
            {...this.props.editorState!, keyPoints},
        );
    }

    @bind private onNarrativeChange(narrative: string) {
        this.props.onEditorStateChanged(
            {...this.props.editorState!, narrative},
        );
    }

}
