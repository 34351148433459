import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';
import { Icon, IconSymbol } from '../Icons';

export interface BadgeProps {
    text: MessageDescriptor;
    textValues?: {};
    iconName: IconSymbol;
    className?: string;
    alternativeStyle?: boolean;
}

export class Badge extends React.PureComponent<BadgeProps> {
    public render() {
        return (
            <div className={
                `${this.props.className ? this.props.className : ''} ` +
                `${this.props.alternativeStyle ? 'lx-badge-alternative' : ''} ` +
                `lx-badge`}>
                <div className='badge-icon-container'><Icon name={this.props.iconName}/></div>
                <div className='badge-content-text'>
                    <WrappedMessage
                        message={this.props.text}
                        values={this.props.textValues}
                    />
                </div>
            </div>
        );
    }
}
