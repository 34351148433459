import * as React from 'react';

export interface OnAnalyticsEventParams {
    eventName: string;
    eventData: any;
}

export interface SandboxedIFrameProps {
    title: string;
    src: string;
    scrolling: 'auto' | 'yes' | 'no';
    width?: string;
    onRequestClose?: () => void;
    extraInformation?: {
        [key: string]: string | undefined,
    };
    onAnalyticsEvent? : ({ eventName, eventData }: OnAnalyticsEventParams) => void;
}

export const SandboxedIFrame = (props: SandboxedIFrameProps) => {
    const iframeRef = React.useRef<HTMLIFrameElement>(null);

    // Set up listener method to parse messages from the iframe
    const receivedWindowMessage = (event: MessageEvent) => {
        if (iframeRef.current === null || event.source !== iframeRef.current.contentWindow ||
            event.data.method === undefined) {
            return; // This is some other random message.
        }
        const {method, ...args} = event.data;
        if (method === 'event') {
            if (args.data.type === 'requestClose') {
                // Allow the iframed content to close the modal.
                if (props.onRequestClose) {
                    props.onRequestClose();
                }
            }
            else if (args.data.type === 'analytics') {
                if (props.onAnalyticsEvent) {
                    props.onAnalyticsEvent({
                        eventName: args.data.eventName,
                        eventData: args.data.eventData,
                    });
                }
            }
        }
    };
    React.useEffect(() => {
        window.addEventListener('message', receivedWindowMessage);
        return () => {
            window.removeEventListener('message', receivedWindowMessage);
        };
    }, []);


    // Send extraInformation to the iFrame after content loads
    const sendExtraInformationToIframe = () => {
        if (props.extraInformation && iframeRef.current != null && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                JSON.stringify(props.extraInformation),
                '*',
            );
        }
    };
    React.useEffect(() => {
        window.addEventListener('load', sendExtraInformationToIframe);
        return () => {
            window.removeEventListener('load', sendExtraInformationToIframe);
        };
    }, []);

    // Security warning!!!
    // Do not modify items on this list without a review from the security group.
    const allowAttributes = [
        'encrypted-media',
        'picture-in-picture',
    ];

    // Security warning!!!
    // Do not modify items on this list without a review from the security group.
    const sandboxAttributes = [
        'allow-downloads',
        'allow-forms',
        'allow-modals',
        'allow-popups',
        'allow-popups-to-escape-sandbox',
        // According to https://html.spec.whatwg.org/multipage/origin.html the allow-same-origin keyword is intended for two cases:
        // First, it can be used to allow content from the same site to be sandboxed to disable scripting, while still allowing access to the DOM of the sandboxed content.
        // Second, it can be used to embed content from a third-party site, sandboxed to prevent that site from opening popups, etc, without preventing the embedded page from communicating back to its originating site, using the database APIs to store data, etc.
        'allow-scripts',
        'allow-same-origin',
    ];

    return (
        <iframe
            ref={iframeRef}
            className='sandboxed-iframe'
            title={props.title}
            src={props.src}
            scrolling={props.scrolling}
            allowFullScreen={false}
            width={props.width}
            height='100%'
            allow={allowAttributes.join(';')}
            sandbox={sandboxAttributes.join(' ')}
            tabIndex={0}    // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
            referrerPolicy='origin-when-cross-origin'
        >
        </iframe>
    );
};
