// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TaggedContentResponse
 */
export interface TaggedContentResponse {
    /**
     * 
     * @type {string}
     * @memberof TaggedContentResponse
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentResponse
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentResponse
     */
    curriculum: string;
    /**
     * 
     * @type {string}
     * @memberof TaggedContentResponse
     */
    subject: string;
    /**
     * Get learning objectives associated with the tagged content.
     * @type {Array<string>}
     * @memberof TaggedContentResponse
     */
    readonly learningObjectives?: Array<string>;
    /**
     * Indicates whether the asset is relevant to the curriculum and subject.
     * @type {boolean}
     * @memberof TaggedContentResponse
     */
    isAssetRelevant: boolean;
    /**
     * Additional feedback from the user.
     * @type {string}
     * @memberof TaggedContentResponse
     */
    tellUsMoreFeedback?: string;
    /**
     * Timestamp when tagging was created.
     * @type {Date}
     * @memberof TaggedContentResponse
     */
    readonly createdAt?: Date;
    /**
     * Timestamp when tagging was last updated.
     * @type {Date}
     * @memberof TaggedContentResponse
     */
    readonly updatedAt?: Date;
}

export function TaggedContentResponseFromJSON(json: any): TaggedContentResponse {
    return {
        'user': json['user'],
        'asset': json['asset'],
        'curriculum': json['curriculum'],
        'subject': json['subject'],
        'learningObjectives': !exists(json, 'learning_objectives') ? undefined : json['learning_objectives'],
        'isAssetRelevant': json['is_asset_relevant'],
        'tellUsMoreFeedback': !exists(json, 'tell_us_more_feedback') ? undefined : json['tell_us_more_feedback'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function TaggedContentResponseToJSON(value?: TaggedContentResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'user': value.user,
        'asset': value.asset,
        'curriculum': value.curriculum,
        'subject': value.subject,
        'is_asset_relevant': value.isAssetRelevant,
        'tell_us_more_feedback': value.tellUsMoreFeedback,
    };
}


