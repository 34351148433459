import * as React from 'react';

export interface HexagonProps {
    className?: string;
    width?: number;
    height?: number;
    img?: string;
    text?: string;
}

export class Hexagon extends React.PureComponent<HexagonProps> {
    public render() {
        const width = this.props.width ? this.props.width : 52;
        const height = this.props.height ? this.props.height : 60;
        const viewBox = `0 0 ${width} ${height}`;
        const fill = this.props.img ? 'url(#hexagonImage)' : 'currentColor';
        const x = [
            0,
            Math.floor(width / 2),
            width,
        ];
        const y = [
            0,
            Math.floor(height / 4),
            Math.floor(3 * height / 4),
            height,
        ];
        const pointsArr = [
            [x[0], y[1]],
            [x[1], y[0]],
            [x[2], y[1]],
            [x[2], y[2]],
            [x[1], y[3]],
            [x[0], y[2]],
        ];
        const points = pointsArr.reduce((s, p) => {
            if (s) {
                s += ' ';
            }
            return `${s}${p[0]},${p[1]}`;
        }, '');

        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                className={`hexagon ${this.props.className}`}
                viewBox={viewBox}
                width={width}
                height={height}
                aria-hidden={true}
                version='1.1'
                fill={fill}>
                {this.props.img &&
                <defs>
                    <pattern id='hexagonImage' patternUnits='userSpaceOnUse' width={width} height={height}>
                        <image href={this.props.img} x='0' y='0' width={width} height={height} />
                    </pattern>
                </defs>
                }
                <polygon points={points}/>
                {this.props.text &&
                <text x={Math.floor(5 * width / 16)} y={y[2]}>{this.props.text}</text>
                }
            </svg>
        );
    }
}
