import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import GraphemeSplitter from 'grapheme-splitter';
import { getAvatarUrl, getUserFullName, getUsername } from 'auth/selectors';
import { Icon } from 'elements';
import { UsersApi } from 'global/api';
import { RootState } from 'global/state';
import { ImageFilePicker, ImagePickerData } from 'ui/components/ImageFilePicker/ImageFilePicker';
import messages from './displayMessages';
import { intl } from 'i18n';

interface Props {
    username?: string;
    // For non-users pass the full name so we can set the default SVG with the initial.
    fullName?: string;
    email?: string;
    displayText?: string;
    diameter?: string;
    onFileChanged?: (fileInfo: ImagePickerData) => void;
    avatarStyle?: 'large-profile';
}

interface ReduxStateProps {
    ownAvatarLastChangeTime?: number;
    loggedInUsername?: string;
    loggedInAvatarUrl?: string;
    loggedInUserFullName?: string;
    showSkeleton?: boolean;
}

interface State {
    fullName?: string;
    publicAvatarUrl?: string;
    avatarLastFetchTime: number;
}

/**
 * Generic user avatar component. Must provide exactly one of `username` and `fullName` to props.
 */
export class UserAvatarInternal extends React.PureComponent<Props & ReduxStateProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            fullName: undefined,
            publicAvatarUrl: undefined,
            avatarLastFetchTime: 0,
        };
    }

    public async componentDidMount() {
        this.fetchProfileDetails();
    }

    public async componentDidUpdate(prevProps: Props) {
        if (this.props.username === this.props.loggedInUsername) {
            if (this.props.ownAvatarLastChangeTime &&
                this.props.ownAvatarLastChangeTime > this.state.avatarLastFetchTime) {
                this.fetchProfileDetails();
            }
        }
    }

    public async fetchProfileDetails() {
        // TODO: The fields needed for displaying avatars should be inlined in the relevant responses so we don't
        // have to make a separate call to fetch for each.
        if (this.props.username === this.props.loggedInUsername) {
            // If we provided these 3 fields, there is no need to call the profiles endpoint.
            this.setState({
                fullName: this.props.loggedInUserFullName ? this.props.loggedInUserFullName : this.props.loggedInUsername,
                publicAvatarUrl: this.props.loggedInAvatarUrl,
            });
        } else if (this.props.username) {
            UsersApi.profilesRead({id: this.props.username}).then((userProfile) => {
                this.setState({
                    fullName: userProfile.fullName ? userProfile.fullName : this.props.username,
                    publicAvatarUrl: userProfile.publicAvatarUrl,
                    avatarLastFetchTime: new Date().getTime(),
                });
            }).catch((err) => {
                // Don't display an error. Fall back to showing the username initial.
                // tslint:disable-next-line:no-console
                console.error(err);
                this.setState({
                    fullName: this.props.username,
                });
            });
        } else if (this.props.fullName) {
            // In case the avatar is for a non-user, the full name must be passed in so the initial can be shown.
                this.setState({
                    fullName: this.props.fullName,
                });
        }
        else if (this.props.email) {
            UsersApi.imageFromEmail({email: this.props.email}).then((userProfile) => {
                const jsonUserProfile = JSON.parse(userProfile);
                this.setState({
                    publicAvatarUrl: jsonUserProfile.url,
                    fullName: jsonUserProfile.name,
                });
            }).catch((err) => {
                // Don't display an error. Fall back to showing the username initial.
                // tslint:disable-next-line:no-console
                console.error(err);
                this.setState({
                    fullName: this.props.username,
                });
            });
        }
    }

    public render() {
        const diameter = this.props.diameter ? this.props.diameter : '40px';
        if (this.props.showSkeleton) {
            return <div className='user-avatar-skeleton'>
                <Skeleton circle={true} style={{width: diameter, height: diameter}}/>
            </div>;
        }

        const avatarUrl = this.state.publicAvatarUrl;
        const style = this.props.avatarStyle || 'normal';

        // If the profile hasn't loaded yet, or there is no user, show the default avatar
        if (!this.state.fullName) {
                return (
                    <img
                        className={`rounded-circle user-avatar-image ${style}`}
                        src='/assets/images/default_profile_blue.png'
                        alt={intl.formatMessage(messages.defaultAvatar)}
                        height={diameter}
                        width={diameter}
                    />
                );
        }

        const name = this.state.fullName!;

        return (
        <>
            {avatarUrl
            ? <img
                className={`rounded-circle user-avatar-image ${style}`}
                src={avatarUrl}
                alt={intl.formatMessage(messages.userAvatar, {username: name})}
                height={diameter}
                width={diameter}
            />
            :   <svg
                xmlns='http://www.w3.org/2000/svg'
                className={`rounded-circle user-avatar-image ${style}`}
                style={{
                    backgroundColor: colorForName(name)
                }}
                viewBox='0 0 43 43'
                version='1.1'
                fill='white'
                fontSize='26px'
                height={diameter}
                width={diameter}
                aria-label={intl.formatMessage(messages.userAvatar, {username: name})}
                role='img'
            >
                <text
                    aria-hidden='true'
                    x='50%'
                    y='70%'
                    textAnchor='middle'
                >{initialForName(name).toUpperCase()}</text>
            </svg>
            }
            {this.props.displayText &&
                <ImageFilePicker id='user-profile-image-picker' onFileChanged={this.props.onFileChanged!}>
                    {<div>
                        <Icon name='device-camera' zoom='20' />
                        {this.props.displayText}
                    </div>}
                </ImageFilePicker>
            }
        </>
        );
    }
}

export const UserAvatar = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        ownAvatarLastChangeTime: state.user.ownAvatarLastChangeTime,
        loggedInUsername: getUsername(state),
        loggedInAvatarUrl: getAvatarUrl(state),
        loggedInUserFullName: getUserFullName(state),
    }),
)(UserAvatarInternal);


interface AvatarProps {
    diameter: string;
    avatarStyle: 'normal' | 'large-profile';
    src: string;
}

// This is a simpler form of UserAvatar that uses the same styles,
// but is supplied a `src` image url, instead of dynamically fetching the url given a name.
export class Avatar extends React.PureComponent<AvatarProps> {
    public static defaultProps = {
        avatarStyle: 'normal',
        diameter: '40px',
    };

    public render() {
        return (
            <img
                className={`rounded-circle user-avatar-image ${this.props.avatarStyle}`}
                src={this.props.src}
                alt=''
                height={this.props.diameter}
                width={this.props.diameter}
            />
        );
    }
}

/*
Return the first grapheme of the name.
*/
const initialForName = (name: string): string => {
    const splitter = new GraphemeSplitter();
    const split: string[] = splitter.splitGraphemes(name);
    return split[0];
};

/*
Return the color to use for the user's avatar.
*/
const colorForName = (name: string): string => {
    const defaultImgColorList = ['#A41034', '#003E6B', '#FAAC48', '#0063C3'];
    return defaultImgColorList[name ? name.length % 4 : 0];
};
