import { ROUTES } from 'global/constants';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';


interface QuickLinksProps {
    isUserLoggedin?: boolean;
}

interface QuickLink {
    title: MessageDescriptor;
    link: string;
    requiredLogin: boolean;
    iconPath: string;
    altText: MessageDescriptor;
}

const quickLinks: QuickLink[] = [
    {
        title: messages.viewSubjectsAndTopicsText,
        link: ROUTES.Subjects,
        requiredLogin: false,
        iconPath: '/assets/images/icons/subjects-and-topics.svg',
        altText: messages.viewSubjectsAndTopicsAltText
    },
    {
        title: messages.designYourOwnLessonText,
        link: ROUTES.Dashboard.EDUCATOR,
        requiredLogin: true,
        iconPath: '/assets/images/icons/design-your-lesson.svg',
        altText: messages.designYourOwnLessonAltText
    },
    {
        title: messages.createClassText,
        link: ROUTES.Dashboard.EDUCATOR_CLASSROOMS,
        requiredLogin: true,
        iconPath: '/assets/images/icons/create-a-class.svg',
        altText: messages.createClassAltText
    },
    {
        title: messages.createQuizzText,
        link: ROUTES.Dashboard.HOME,
        requiredLogin: true,
        iconPath: '/assets/images/icons/create-a-quiz.svg',
        altText: messages.createQuizzAltText
    }
];

export const QuickLinks: React.FunctionComponent<QuickLinksProps> = (props) => {

    return (
        <div className='quicklinks'>
            {quickLinks.map(quickLink =>
                <NavLink key={quickLink.link} className='quick-link' to={quickLink.link} >
                    <img src={quickLink.iconPath} alt={intl.formatMessage(quickLink.altText)}/>
                    <div className='text'>
                        <span><WrappedMessage message={quickLink.title}/></span>
                        {quickLink.requiredLogin && !props.isUserLoggedin &&
                            <span className='requires-account-text'>
                                (<WrappedMessage message={messages.requiresFreeAccountText} />)
                            </span>
                        }
                    </div>
                </NavLink>
            )}

        </div>
    );
};

interface QuickLinksSkeletonProps {
    isUserLoggedin: boolean;
    layoutSize: string;
}

export const QuickLinksSkeleton: React.FunctionComponent<QuickLinksSkeletonProps>= ({isUserLoggedin, layoutSize}) => {
    const isMobile = layoutSize === 'mobile' || layoutSize === 'small';

    return (
        <div className={classNames('quicklinks-container section', {'last-section' : isUserLoggedin})}>
            <div className='quicklinks-header'>
                <h1 className='sentence-case'><Skeleton width={isMobile ? 300 : 400} height={50}/></h1>
                {!isMobile && <Skeleton width={200} height={30}/>}
            </div>
        <div className='quicklinks'>
            {Array.from({ length: 4 }, (_, index) => (
                <div className='quick-link quick-link-skeleton'>
                    <Skeleton width={50} height={50} />
                    <Skeleton width={isMobile ? 200 : 275} height={36} />
                </div>

            ))}
        </div>
    </div>

    );
};
