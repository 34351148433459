import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ExploreSection,
  SectionSlab,
  SectionSlabAssetTypeEnum
} from 'labxchange-client';
import { StandardPageLayout } from 'ui/components';
import { ExploreSectionsApi } from 'global/api';

import TopicHeader from './TopicPageHeader';
import ClassicCardsSlab from './ClassicCardsSlab';
import PathwaysSlab from './PathwaysSlab';
import InteractiveCardsSlab from './InteractiveCardsSlab';
import ClustersSlab from './ClustersSlab';
import SkeletonSlabs from './SkeletonSlabs';

export const TopicPage: FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const topicSlug = pathSegments[pathSegments.length - 1];
  const [data, setData] = useState<ExploreSection | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sectionSlabs, setSectionSlabs] = useState<SectionSlab[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response: ExploreSection = await ExploreSectionsApi.sectionsRead({ id: topicSlug });
      setData(response);
      if (response.sectionSlabs) {
        setSectionSlabs(response.sectionSlabs);
      }
      setLoading(false);
    };

    fetchData();
  }, [topicSlug]);

  const renderSlabs = () => {
    return (
      sectionSlabs
      .sort((a, b) => a.order - b.order)
      .map((slab: SectionSlab, index: number) => {
        if (slab.assetType === SectionSlabAssetTypeEnum.Interactives) {
          return (
            <InteractiveCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.Pathways) {
          return (
            <PathwaysSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.SimpleCards) {
          return (
            <ClassicCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.Clusters) {
          return (
            <ClustersSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        }
        return null;
      })
    );
  };

  const renderSkeleton = () => {
    return <SkeletonSlabs count={4} />;
  };

  return (
    <StandardPageLayout
        backgroundStyle='light-header'
        mainClassName='topic-page'
        headerFeature={<TopicHeader topicTitle={data ? data.title : ''} loading={loading} />}
    >
      {(loading || !sectionSlabs) ? renderSkeleton() : renderSlabs()}
    </StandardPageLayout>
  );
};
