import * as React from 'react';
import { ExploreApi } from 'global/api';
import { ExploreHeader as ExploreHeaderResponse } from 'labxchange-client';
import { Card } from 'library/components/Card';
import Skeleton from 'react-loading-skeleton';
import { Redirect } from 'react-router-dom';
import { detailUrlForEntity } from 'library/utils';

interface ExploreHeaderState {
    loading: boolean;
    header?: ExploreHeaderResponse;
    redirect?: boolean;
}

export class ExploreHeader extends React.PureComponent<{}, ExploreHeaderState> {
    constructor(props: {}) {
        super(props);
        this.state = {loading: true};
    }

    public async componentDidMount() {
        try {
            const response = await ExploreApi.header();
            this.setState({
                loading: false,
                header: response,
            });
        } catch (err) {
            // The header can silently failed to load for the end-user
            // tslint:disable-next-line:no-console
            console.error(err);
            this.setState({loading: false});
        }
    }

    public renderLoading() {
        return <div className='explore-header explore-header-loading container'>
            <div className='explore-header-description'>
                <Skeleton height={48} width={120}/>
                <Skeleton height={24} width={180}/>
                <Skeleton height={24} width={240}/>
                <Skeleton height={24} width={240}/>
            </div>
            <ol className='explore-header-cards'>
                <li><Card showSkeleton={true} mobileViewMode={true}/></li>
                <li><Card showSkeleton={true} mobileViewMode={true}/></li>
                <li><Card showSkeleton={true} mobileViewMode={true}/></li>
            </ol>
        </div>;
    }

    public render() {
        if (this.state.redirect && this.state.header) {
            return <Redirect push={true} to={this.state.header.buttonLink}/>;
        }
        if (this.state.loading || !this.state.header) { return this.renderLoading(); }
        const cards = this.state.header.items.map((item, index) =>
            <li key={index}>
                <Card
                    metadata={item}
                    mobileViewMode={true}
                    detailUrl={detailUrlForEntity(item)}
                />
            </li>
        );
        return (
            <div className='explore-header container'>
                <div className='explore-header-description'>
                    <h1 className='explore-header-description-text heading-style-4'>
                        {this.state.header.description}
                    </h1>
                    <span className='explore-header-description-emphasized-word heading-style-1'>
                        {this.state.header.emphasizeWord}
                    </span>
                    <a className='btn lx-btn-ghost' href={this.state.header.buttonLink}>
                        {this.state.header.buttonText}
                    </a>
                </div>
                <ol className='explore-header-cards'>{cards}</ol>
            </div>
        );
    }
}
