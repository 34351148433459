import * as React from 'react';
import { bind } from 'bind-decorator';

import { Icon } from 'elements';
import { MentorshipsApi, UsersApi } from 'global/api';
import { isKeyboardEvent } from 'global/utils';
import { Mentorship, MentorshipStatusEnum, Profile } from 'labxchange-client';
import { ToggleSwitch } from 'ui/components/ToggleSwitch';
import { WrappedMessage } from 'utils';
import { UserAvatar } from '../../../user/components/UserAvatar';
import { UserProfileSection } from '../../../user/components/UserProfileSection';
import messages from './displayMessages';
import { intl } from 'i18n';

interface State {
    mentorshipMessage?: string;
    toggleMentorshipMessageEdit: boolean;
    mentorships: Mentorship[];
}

interface Props {
    profile: Profile;
    onToggleAvailability: (available: boolean) => void;
}

export class MentorshipOwnProfile extends React.PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            toggleMentorshipMessageEdit: false,
            mentorshipMessage: this.props.profile.mentorship ? this.props.profile.mentorship!.message : '',
            mentorships: [],
        };
    }

    public async componentDidMount() {
        try {
            await this.fetchMentorships();
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
    }

    public render() {
        return (
            <UserProfileSection title={messages.userProfileOwnMentorship} toggleSwitch={this.getTitleToggleSwitch()}
                sectionName='mentorship-settings'>
                {this.renderSectionContent()}
            </UserProfileSection>
        );
    }

    private renderSectionContent() {
        return (
            <div className='mentorship-own-profile'>
                <div className='description'>
                    <WrappedMessage message={messages.userProfileMentorshipProgramDescription} />
                </div>
                <div className='limit-description'>
                    <WrappedMessage message={messages.userProfileMentorshipProgramLimitDescription} />
                </div>
                <div className='application-message-description'>
                    <WrappedMessage message={messages.userProfileMentorshipProgramApplicationMessageDescription} />
                </div>
                {this.renderMentorshipMessage()}
                {this.renderMenteesList()}
            </div>
        );
    }

    private renderMenteesList() {
        const mentorships = this.state.mentorships;

        if (mentorships.length === 0) {
            return (null);
        }

        const mentorshipsElements = mentorships.map((mentorship) => {
                return (
                    <div key={mentorship.id}
                        className='mentee-list-card'>
                            <UserAvatar username={mentorship.mentee.username}/>
                        <div>
                            <div className='mentee-list-card-full-name'>{mentorship.mentee.fullName}</div>
                            <div className='mentee-list-card-message'><Icon name='mail' zoom='16' /></div>
                        </div>
                    </div>
                );
            });

        return (
            <div className='mentees'>
                <h3>
                    <WrappedMessage message={messages.menteesTitle}/>
                    {mentorships.length > 0 ? ' (' + mentorships.length + ')' : ''}
                </h3>
                <div className='mentees-list'>{mentorshipsElements}</div>
            </div>
        );
    }

    private getTitleToggleSwitch() {
        return (
            <ToggleSwitch
                isChecked={Boolean(this.props.profile.mentorship!.enabled)}
                displayText={intl.formatMessage(messages.userProfileMentorshipAvailable)}
                onToggleSwitchSelect={this.props.onToggleAvailability} />
        );
    }

    private async fetchMentorships() {
        try {
            const mentorships = await MentorshipsApi.list(
                { status: MentorshipStatusEnum.Accepted, mentor: this.props.profile.username },
            );
            this.setState({ mentorships });
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
    }

    private renderMentorshipMessage() {
        if (this.state.toggleMentorshipMessageEdit) {
            return (
                <div className='editable-message'>
                    <div className='edit-form'>
                        <textarea
                            className='form-control'
                            value={this.state.mentorshipMessage || ''}
                            aria-label={intl.formatMessage(messages.ariaTextAreaMentorshipMessage)}
                            onChange={this.updateMentorshipMessage} />
                        <div className='action-buttons'>
                            <button className='unstyled lx-btn cancel-link'
                                onClick={this.onCancelMentorshipMessageUpdate}>
                                <WrappedMessage message={messages.cancelLink} />
                            </button>
                            <button className='lx-btn update-button float-right mr-0'
                                    onClick={this.saveMentorshipMessage}>
                                <WrappedMessage message={messages.updateButton} />
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='editable-message'>
                {this.state.mentorshipMessage ? this.state.mentorshipMessage : null}
                    <button
                        className='unstyled edit-icon'
                        aria-label={intl.formatMessage(messages.ariaEditMentorshipMessageButton)}
                        onClick={this.renderMentorshipMessageEdit}
                    >
                        {this.state.toggleMentorshipMessageEdit ? null : <Icon name='pencil' zoom='16' />}
                    </button>
                </div>
            );
        }
    }

    @bind private updateMentorshipMessage(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ mentorshipMessage: event.target.value });
    }

    @bind private onCancelMentorshipMessageUpdate(
        event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleMentorshipMessageEdit: !this.state.toggleMentorshipMessageEdit });
    }

    @bind private async saveMentorshipMessage() {
        let mentorshipMessage = this.state.mentorshipMessage;
        if (mentorshipMessage) {
            mentorshipMessage = mentorshipMessage.trim();
        }
        const updateData = { mentorship: {message: mentorshipMessage }};
        try {
            await UsersApi.profilesPartialUpdate({ id: this.props.profile!.username, data: updateData });
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
        this.setState({
            toggleMentorshipMessageEdit: !this.state.toggleMentorshipMessageEdit,
            mentorshipMessage,
        });
    }

    @bind private renderMentorshipMessageEdit(
        event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        event.preventDefault();
        this.setState({ toggleMentorshipMessageEdit: !this.state.toggleMentorshipMessageEdit });
    }
}
