import {AnalyticsEventEventTypeEnum, Profile} from 'labxchange-client';
import * as React from 'react';
import {Link} from 'react-router-dom';

import {AnalyticsApi} from 'global/api';
import {ROUTES} from 'global/constants';
import { UserAvatar } from 'user/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import {UserCardButton} from './UserCardButton';
import Skeleton from 'react-loading-skeleton';
import {ClipLines} from 'ui/components';

interface UserCardProps {
    profile: Profile;
    extraClasses?: any[];
    menu?: React.ReactNode;
    accessory?: React.ReactNode;
    accessoryHeight?: string;
    extraDetailscomponent?: JSX.Element;
    isSelected?: boolean;
    onSelect?(): void;
    threeColumns?: boolean;
    highlightBorder?: boolean; // Sets a strong border on the card.
    backgroundColor?: 'blue';
    showSkeleton?: boolean;
    clipName?: boolean;
}

interface UserCardState {
    menuOpen: boolean;
}

export class UserCard extends React.PureComponent<UserCardProps, UserCardState> {

    constructor(props: any) {
        super(props);

        this.state = {
            menuOpen: false,
        };
    }

    public render() {

        let accessory = this.props.accessory;
        if (!accessory && this.props.onSelect) {
            accessory = (
                <UserCardButton
                    onClick={this.props.onSelect}
                    iconName={this.props.isSelected ? 'check' : 'plus'}
                    label={
                        this.props.isSelected ? messages.userCardSelectedButtonLabel : messages.userCardSelectButtonLabel
                    }
                />
            );
        }

        const accessoryHeight = this.props.accessoryHeight ? this.props.accessoryHeight : '52px';

        return (
            <div className={
                    `user-card` +
                    `${this.props.threeColumns ? ' user-card-three-columns' : ''} ` +
                    `${this.props.isSelected ? ' user-card-selected' : ''} ` +
                    `${this.props.highlightBorder ? ' user-card-highlight-border' : ''} ` +
                    `${this.props.backgroundColor ? ' user-card-background-' + this.props.backgroundColor : ''} ` +
                    `${this.props.extraClasses ? ' ' + this.props.extraClasses.join(' ') : ''}`
            }>
                {!this.props.showSkeleton && this.props.menu ?
                    <div className='user-card-menu'>
                        {this.props.menu}
                    </div> : null
                }
                <div className='body'>
                    <UserCardAbout
                        profile={this.props.profile}
                        onVisitProfile={this.onVisitProfile}
                        showSkeleton={this.props.showSkeleton}
                        clipName={this.props.clipName}
                    />
                </div>
                {accessory ?
                    <div className='accessory' style={{height: accessoryHeight}}>
                        {accessory}
                    </div>
                : null}
            </div>
        );
    }

    private onVisitProfile = () => {
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.PeopleCardClicked,
                objectId: this.props.profile.username,
                data: {
                    url: window.location.href,
                    pathname: window.location.pathname,
                },
            },
        });
    }
}

interface UserCardAboutProps {
    profile: Profile;
    onVisitProfile?(): void;
    showSkeleton?: boolean;
    clipName?: boolean;
}

const UserCardAbout: React.FunctionComponent<UserCardAboutProps> = (props) => {

    const profile = props.profile;
    const profileUrl = ROUTES.Users.PROFILE_SLUG(profile.username);

    const name = profile.fullName ? profile.fullName : profile.username;

    const title = (
        <Link
            className={props.clipName ? 'clipped-name' : ''}
            to={profileUrl}
            onClick={props.onVisitProfile}
            data-testid={name}
        >
            {props.clipName ?
                <ClipLines
                    lines={'2'}
                    text={name}
                />
                : name
            }
        </Link>
    );

    return (
        <div className='user-card-about'>
            <div className='image'>
                <Link
                    to={profileUrl}
                    aria-hidden='true'
                    tabIndex={-1}
                    onClick={props.onVisitProfile}
                >
                    <UserAvatar username={profile.username} diameter='56px' showSkeleton={props.showSkeleton}/>
                </Link>
            </div>
            <div className='details'>
                <header>
                    <h1>{props.showSkeleton? <Skeleton /> : title}</h1>
                </header>
                <div className='affiliation'>
                    {props.showSkeleton ? <Skeleton/> : <span>
                    {profile.jobTitle && profile.institution
                        ? <WrappedMessage message={messages.userCardAboutTitleAndInstitution}
                                          values={{title: profile.jobTitle, institution: profile.institution}}/>
                        : profile.jobTitle
                            ? <WrappedMessage message={messages.userCardAboutTitle}
                                              values={{title: profile.jobTitle}}/>
                            : profile.institution
                                ? <WrappedMessage message={messages.userCardAboutInstitution}
                                                  values={{institution: profile.institution}}/>
                                : profile.country
                                    ? <WrappedMessage message={messages.userCardAboutCountry}
                                                      values={{country: profile.country}}/>
                                    : null
                    }
                    </span>}
                </div>
            </div>
        </div>
    );
};
