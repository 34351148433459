import bind from 'bind-decorator';
import * as React from 'react';

import { WrappedMessage, showFilterTabsUi } from 'utils';
import messages from '../../displayMessages';
import { FilterTaxonomy, TaxonomyCategory, TaxonomyData } from '../Taxonomy';
import Skeleton from 'react-loading-skeleton';
import { intl } from 'i18n';

export interface FilterBarProps {
    showTagsClear: boolean;
    taxonomies: ReadonlyArray<TaxonomyData>;

    /**
     * Which filters are selected. Requires that IDs are unique among
     * all taxonomies and that ID strings begin with the ID of the
     * taxonomy.
     */
    selectedTagIds: ReadonlySet<string>;

    /** Event when the user toggles a tag in this taxonomy */
    onToggleTagSelect?: (selectedTagIdSet: ReadonlySet<string>) => void;

    onTagsClear?: () => void;

    /** Which taxonomy should be visibly expanded initially, if any. Optional. */
    initiallyExpandedTaxonomy: string;
    /** Which taxonomies should have a keyword search filter */
    showSearchForTaxonomies: ReadonlyArray<string>;

    /** Show the close button? */
    showCloseButton: boolean;
    onCloseButtonClick: () => void;

    showSkeleton?: boolean;
}

interface FilterBarState {
    openTaxonomyId: string;
    clearFlag: boolean;
}

export class FilterBar extends React.PureComponent<FilterBarProps, FilterBarState> {

    public static defaultProps = {
        initiallyExpandedTaxonomy: '',
        showSearchForTaxonomies: [],
        showCloseButton: true,
        onCloseButtonClick: () => null,
    };

    constructor(props: any) {
        super(props);

        this.state = {
            clearFlag : false,
            openTaxonomyId: this.props.initiallyExpandedTaxonomy,
        };
    }

    public render() {
        return (
            <div className='filter-bar-wrapper'>
                <section
                    className='filter-bar'
                    role='search'
                    aria-label={intl.formatMessage(messages.taxonomyFilterLabel)}
                >
                    {this.renderTopBar()}
                    {this.renderTaxonomies()}
                </section>
            </div>
        );
    }

    private renderTopBar(): React.ReactNode {
        return (
            <div className='top-bar'>
                <h2>
                    { this.props.showSkeleton ? <Skeleton /> : <WrappedMessage message={messages.filterHeading} /> }
                </h2>
                { !this.props.showSkeleton && <div>
                    {this.renderClear()}
                    {this.renderApply()}
                </div> }
            </div>
        );
    }

    private renderClear(): React.ReactNode | null {
        if (this.props.showTagsClear) {
            return (
                <button className='lx-btn clear-button' onClick={this.onTagsClear}>
                    <WrappedMessage message={messages.filterClear}/>
                </button>
            );
        }
        return null;
    }

    private renderApply(): React.ReactNode | null {
        if (this.props.showCloseButton) {
            return (
                <button onClick={this.props.onCloseButtonClick}>
                    <WrappedMessage message={messages.filterClose}/>
                </button>
            );
        }
        return null;
    }

    private renderTaxonomies(): React.ReactNode {
        const taxonomyItems = Array.from([...this.props.taxonomies],
            (taxonomyProps) =>
                <FilterTaxonomy
                    key={taxonomyProps.id}
                    id={taxonomyProps.id}
                    className={taxonomyProps.id.toLowerCase()}
                    isSelected={this.isFilterSelected(taxonomyProps.id)}
                    isOpen={this.state.openTaxonomyId === taxonomyProps.id}
                    category={taxonomyProps.category}
                    tags={taxonomyProps.tags}
                    selectedTagIds={this.props.selectedTagIds}
                    displayCount={true}
                    onToggleShow={this.onToggleShowTaxonomy}
                    onToggleTagSelect={this.props.onToggleTagSelect}
                    showSearch={this.props.showSearchForTaxonomies.includes(taxonomyProps.id)}
                    clearFlag={this.state.clearFlag}
                />,
        );
        return (
            <div className='taxonomies'>
                {this.props.showSkeleton ?
                    [...Array(6)].map((_, index) => <FilterTaxonomy
                        clearFlag={false}
                        key={index}
                        category={TaxonomyCategory.Empty}
                        displayCount={false}
                        id={index.toString()}
                        isOpen={false}
                        isSelected={false}
                        selectedTagIds={this.props.selectedTagIds}
                        tags={[]}
                        showSkeleton={this.props.showSkeleton}
                    />) :
                    taxonomyItems
                }
            </div>
        );
    }

    @bind private isFilterSelected(taxonomyId: string) {
        // Do not expand filters if either on the library search page or when dealing with a language filter.
        if (showFilterTabsUi() && taxonomyId !== 'Language') {
            if(Array.from(this.props.selectedTagIds).some(tagId => tagId.startsWith(taxonomyId))) {
                return true;
            }
        }
        return false;
    }

    @bind private onTagsClear(event: React.MouseEvent<HTMLButtonElement>): void {
        this.setState({clearFlag: !this.state.clearFlag});
        if (this.props.onTagsClear) {
            event.preventDefault();
            this.props.onTagsClear!();
        }
    }

    @bind private onToggleShowTaxonomy(id: string, isOpen: boolean) {
        this.setState({
            openTaxonomyId: isOpen ? id : '',
        });
    }
}
