// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Book,
    BookFromJSON,
    BookToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    RelatedItemMetadata,
    RelatedItemMetadataFromJSON,
    RelatedItemMetadataToJSON,
} from '../models';

export interface BookForItemRequest {
    id: string;
}

export interface ListRequest {
    page?: number;
}

export interface ReadRequest {
    id: string;
}

export interface RelatedBookItemRequest {
    id: string;
    previous?: boolean;
}

/**
 * no description
 */
export class BooksApi extends runtime.BaseAPI {

    /**
     * Find and return the book that the ItemMetadata (given by `pk`) is part of, if the item is part of a book. Otherwise, 404.
     */
    async bookForItemRaw(requestParameters: BookForItemRequest): Promise<runtime.ApiResponse<Book>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bookForItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/books/{id}/book_for_item`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BookFromJSON(jsonValue));
    }

    /**
     * Find and return the book that the ItemMetadata (given by `pk`) is part of, if the item is part of a book. Otherwise, 404.
     */
    async bookForItem(requestParameters: BookForItemRequest): Promise<Book> {
        const response = await this.bookForItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Readonly viewset for books.
     */
    async listRaw(requestParameters: ListRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/books`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Readonly viewset for books.
     */
    async list(requestParameters: ListRequest): Promise<InlineResponse200> {
        const response = await this.listRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a single Book by id.
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<Book>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/books/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BookFromJSON(jsonValue));
    }

    /**
     * Get a single Book by id.
     */
    async read(requestParameters: ReadRequest): Promise<Book> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

    /**
     * Find and return the next or previous item in the book related to the ItemMetadata (givey by `pk`)
     */
    async relatedBookItemRaw(requestParameters: RelatedBookItemRequest): Promise<runtime.ApiResponse<RelatedItemMetadata>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling relatedBookItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.previous !== undefined) {
            queryParameters['previous'] = requestParameters.previous;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/books/{id}/related_book_item`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RelatedItemMetadataFromJSON(jsonValue));
    }

    /**
     * Find and return the next or previous item in the book related to the ItemMetadata (givey by `pk`)
     */
    async relatedBookItem(requestParameters: RelatedBookItemRequest): Promise<RelatedItemMetadata> {
        const response = await this.relatedBookItemRaw(requestParameters);
        return await response.value();
    }

}
