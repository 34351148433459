import * as React from 'react';
import { intl} from 'i18n';

import { ItemResponse } from 'labxchange-client';

import { Icon } from 'elements';
import { useLayoutSize } from '../../../utils';
import { Card } from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import uiMessages from 'ui/components/displayMessages';

interface CardsListProps {
    cards: ItemResponse[];
}

export const CardsList: React.FC<CardsListProps> = (props) => {

    const layoutSize = useLayoutSize();
    const [currentPage, setCurrentPage] = React.useState(0);
    const cardsPerPage = layoutSize === 'desktop' || layoutSize === 'large' ? 4 : props.cards?.length;
    const totalPages = Math.ceil(props.cards?.length / cardsPerPage);

    const handleNextClick = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const displayedCards = props.cards ? props.cards.slice(startIndex, endIndex) : [];

    return (
        <div className='cards-list'>
            <button className={`btn-previous ${currentPage === 0 ? 'hide' : ''}`}
                    title={intl.formatMessage(uiMessages.uiPrevious)}
                    onClick={handlePrevClick}
            >
                <Icon name='chevron-left'/>
            </button>

            <>
                {displayedCards.map((cardProps: ItemResponse, index: number) => (
                    <Card
                        key={'more-by-source-' + index}
                        metadata={cardProps.metadata}
                        mobileViewMode={true}
                        detailUrl={detailUrlForEntity(cardProps.metadata)}
                        userAttributes={cardProps.userAttributes}
                    />
                ))}
            </>

            <button className={`btn-next ${currentPage === totalPages - 1 ? 'hide' : ''}`}
                    title={intl.formatMessage(uiMessages.uiNext)}
                    onClick={handleNextClick}
            >
                <Icon name='chevron-right'/>
            </button>
        </div>
    );
};
