import messages from 'library/displayMessages';
import * as React from 'react';
import classNames from 'classnames';
import { WrappedMessage } from 'utils';
import { UserAvatar } from 'user/components/UserAvatar';
import { Profile } from 'labxchange-client';
import { UsersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import Skeleton from 'react-loading-skeleton';

interface Props {
    profile?: Profile;
    username?: string;
    showSkeleton?: boolean;
}

interface State {
    profile?: Profile;
}

/**
 * The box at the top right of a unit page that displays where content comes from.
 */
export class OwnerInfoBox extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            profile: props.profile,
        };
    }

    private loadProfile() {
        if (this.props.username) {
            UsersApi.profilesRead({id: this.props.username}).then((profile) => {
                this.setState({
                    profile
                });
            }).catch((err) => {
                // Don't display an error
                // tslint:disable-next-line:no-console
                console.log(err);
            });
        }
    }

    public async componentDidMount() {
        if (!this.state.profile) {
            this.loadProfile();
        }
    }

    public render() {
        return (
            <div className='source owner-source'>
                <div className='source-content'>
                    <div className='owner-avatar-container'>
                        <UserAvatar username={this.props.username} diameter={'70px'} showSkeleton={this.props.showSkeleton}/>
                    </div>
                    <div className='owner-description'>
                        {this.props.showSkeleton? <Skeleton />: (this.state.profile && <>
                            <div className='owner-title'>
                                {this.state.profile.jobTitle}
                            </div>
                            <div className='owner-fullname'>
                                {this.state.profile.fullName ? this.state.profile.fullName : this.props.username}
                            </div>
                            <p className='source-text owner-bio'>
                                {this.state.profile.bio ? this.state.profile.bio : ''}
                            </p>
                        </>)}
                    </div>
                </div>
                <div className='source-actions action-button-div'>
                    {this.props.showSkeleton ? <div className='skeleton-source-action nudging-button-secondary'>
                        <Skeleton />
                    </div> : <a
                        href={this.props.username ? ROUTES.Users.PROFILE_SLUG(this.props.username) : '#'}
                        className={classNames(
                            'owner-view-profile',
                            'source-action',
                            'item-source-info-url',
                            'action-button',
                            {skeleton: this.props.showSkeleton},
                        )}
                        target='_blank' rel='noopener noreferrer'
                    >
                        <WrappedMessage message={messages.viewOwnerProfileButton} />
                    </a>}
                </div>
            </div>
        );
    }
}
