import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'global/constants';
import { Icon } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';

/**
 * Tabs for selecting what type of content to search for on the search page.
 */
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps<{}> {}

export const NavTabs: React.FC<Props> = (props) => (
    <div className='nav-tabs container d-flex justify-content-start align-items-end'>
        <NavLink className='nav-item text-header' activeClassName='active' to={`${ROUTES.Library.HOME}${props.location.search}`}>
            <Icon name='repo' zoom='22'/>
            <WrappedMessage message={messages.searchTabLibrary}/>
        </NavLink>
        <NavLink className='nav-item text-header' activeClassName='active' to={`${ROUTES.People.HOME}${props.location.search}`}>
            <Icon name='organization' zoom='22'/>
            <WrappedMessage message={messages.searchTabPeople}/>
        </NavLink>
        <NavLink className='nav-item text-header' activeClassName='active' to={`${ROUTES.Organizations.HOME}${props.location.search}`}>
            <Icon name='skyscraper' zoom='22'/>
            <WrappedMessage message={messages.searchTabOrganizations}/>
        </NavLink>
    </div>
);
