import * as React from 'react';

import defaultClassroomImage from 'assets/images/default-classroom.png';
import {
    CardBarButton,
    CardBarIconLabel,
    CardDetails,
    CardMedia,
    CardMediaOverlayText,
    CardStandard,
} from 'ui/components/Card/Cards';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';

interface LearnerDashboardInactiveClassroomCardProps {
    title: string;
    text: string;
    imageUrl?: string;
    code: React.ReactNode;
    archived: boolean;
    onClick: () => void;
    onRejoinClick: () => void;
}

export const LearnerDashboardInactiveClassroomCard: React.FC<LearnerDashboardInactiveClassroomCardProps> = (
    { title, text, imageUrl, code, archived, onClick, onRejoinClick}) => (
        <CardStandard
            media={
                <CardMedia
                    mediaUrl={imageUrl || defaultClassroomImage}
                    overlays={
                        [
                            <CardMediaOverlayText
                                message={
                                    <WrappedMessage
                                        message={messages.classCodeOverlayText}
                                        values={{ code }}
                                    />
                                }
                                additionalStyling='media-overlay-position-bottom-left'
                                key='classCodeCardMediaOverlay'
                            />,
                        ]
                    }
                />
            }
            details={
                <CardDetails
                    title={title}
                    text={text}
                />
            }
            barBottom={
                archived
                ? <CardBarIconLabel
                    iconName='archive'
                    text={intl.formatMessage(messages.archivedLabelText)}
                />
                : <CardBarButton
                    iconName='archive'
                    text={intl.formatMessage(messages.rejoinButtonText)}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRejoinClick();
                    }}
                />
            }
            showBarBottomSeparator={true}
            showBarTopSeparator={false}
            onClick={onClick}
            additionalStyling='learner-dashboard-card-component inactive'
        />
    );
