// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Unique ID. Cannot be changed once created.
     * @type {string}
     * @memberof Organization
     */
    id?: string;
    /**
     * A unique string with no spaces (like a username) for this organization (e.g. \"labxchange\"). This is used as part of the URL for the organization. Only letters, numbers, underscores, and hyphens are allowed.
     * @type {string}
     * @memberof Organization
     */
    slug: string;
    /**
     * e.g. LabXchange
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * Short description of the organization. limited html markup allowed - italics, bold, paragraphs, and links only
     * @type {string}
     * @memberof Organization
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    readonly coverImageUrl?: string;
    /**
     * Small logo of this organization. Small version to be used on profile icons and badges. This should just be a symbol with no words. SVG is the preferred format, otherwise transparent PNG.
     * @type {string}
     * @memberof Organization
     */
    readonly logo?: string;
    /**
     * Full size logo for the organization. This will be displayed in attribution boxes and other areas with a predominately light background. Only SVG or PNG allowed.
     * @type {string}
     * @memberof Organization
     */
    readonly largeLogo?: string;
    /**
     * Badge Icon for to display next to the content cards of this organization.
     * @type {string}
     * @memberof Organization
     */
    readonly badgeIcon?: string;
    /**
     * Full size landscape logo for the organization. This logo should use light colors and look good on a dark background. Used on the organization profile page. Only SVG or PNG allowed.
     * @type {string}
     * @memberof Organization
     */
    readonly profilePageLogo?: string;
    /**
     * Changes the color of the text description on the profile depending of the theme
     * @type {boolean}
     * @memberof Organization
     */
    darkTheme?: boolean;
    /**
     * Brand color - used as background for profile icon / badge icon.Should be a valid css color value.
     * @type {string}
     * @memberof Organization
     */
    brandColor?: string;
    /**
     * URL to the main webpage for this organization, e.g. https://labxchange.org/
     * @type {string}
     * @memberof Organization
     */
    url?: string;
    /**
     * URL to a Facebook page for this organization
     * @type {string}
     * @memberof Organization
     */
    facebookUrl?: string;
    /**
     * URL to an Instagram profile for this organization
     * @type {string}
     * @memberof Organization
     */
    instagramUrl?: string;
    /**
     * URL to a Twitter page for this organization
     * @type {string}
     * @memberof Organization
     */
    twitterUrl?: string;
    /**
     * URL to a LinkedIn page for this organization
     * @type {string}
     * @memberof Organization
     */
    linkedinUrl?: string;
    /**
     * URL to a Pinterest page for this organization
     * @type {string}
     * @memberof Organization
     */
    pinterestUrl?: string;
    /**
     * URL to a Academia page for this organization
     * @type {string}
     * @memberof Organization
     */
    academiaUrl?: string;
    /**
     * URL to a Youtube page for this organization
     * @type {string}
     * @memberof Organization
     */
    youtubeUrl?: string;
    /**
     * If the organization is also an edX partner, then this is the \"short code\" that identifies the organization on edX.org. It will appear in the organization's course IDs, e.g. \"MITx\" is the org ID seen in course IDs like \"course-v1:MITx+CTL.SC1x_3+1T2017\".
     * @type {string}
     * @memberof Organization
     */
    edxOrgId?: string;
    /**
     * Designates the type of organization
     * @type {string}
     * @memberof Organization
     */
    organizationType?: OrganizationOrganizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    country?: OrganizationCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    city?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    enableAdditionalLicenseOptions?: boolean;
}

export function OrganizationFromJSON(json: any): Organization {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'coverImageUrl': !exists(json, 'cover_image_url') ? undefined : json['cover_image_url'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'largeLogo': !exists(json, 'large_logo') ? undefined : json['large_logo'],
        'badgeIcon': !exists(json, 'badge_icon') ? undefined : json['badge_icon'],
        'profilePageLogo': !exists(json, 'profile_page_logo') ? undefined : json['profile_page_logo'],
        'darkTheme': !exists(json, 'dark_theme') ? undefined : json['dark_theme'],
        'brandColor': !exists(json, 'brand_color') ? undefined : json['brand_color'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'facebookUrl': !exists(json, 'facebook_url') ? undefined : json['facebook_url'],
        'instagramUrl': !exists(json, 'instagram_url') ? undefined : json['instagram_url'],
        'twitterUrl': !exists(json, 'twitter_url') ? undefined : json['twitter_url'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'pinterestUrl': !exists(json, 'pinterest_url') ? undefined : json['pinterest_url'],
        'academiaUrl': !exists(json, 'academia_url') ? undefined : json['academia_url'],
        'youtubeUrl': !exists(json, 'youtube_url') ? undefined : json['youtube_url'],
        'edxOrgId': !exists(json, 'edx_org_id') ? undefined : json['edx_org_id'],
        'organizationType': !exists(json, 'organization_type') ? undefined : json['organization_type'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'enableAdditionalLicenseOptions': !exists(json, 'enable_additional_license_options') ? undefined : json['enable_additional_license_options'],
    };
}

export function OrganizationToJSON(value?: Organization): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'description': value.description,
        'dark_theme': value.darkTheme,
        'brand_color': value.brandColor,
        'url': value.url,
        'facebook_url': value.facebookUrl,
        'instagram_url': value.instagramUrl,
        'twitter_url': value.twitterUrl,
        'linkedin_url': value.linkedinUrl,
        'pinterest_url': value.pinterestUrl,
        'academia_url': value.academiaUrl,
        'youtube_url': value.youtubeUrl,
        'edx_org_id': value.edxOrgId,
        'organization_type': value.organizationType,
        'country': value.country,
        'state': value.state,
        'city': value.city,
        'enable_additional_license_options': value.enableAdditionalLicenseOptions,
    };
}

/**
* @export
* @enum {string}
*/
export enum OrganizationOrganizationTypeEnum {
    SPONSOR = 'SPONSOR',
    PARTNER = 'PARTNER',
    COMMUNITY = 'COMMUNITY',
    STRATEGICALLY = 'STRATEGIC-ALLY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum OrganizationCountryEnum {
    AF = 'AF',
    AX = 'AX',
    AL = 'AL',
    DZ = 'DZ',
    AS = 'AS',
    AD = 'AD',
    AO = 'AO',
    AI = 'AI',
    AQ = 'AQ',
    AG = 'AG',
    AR = 'AR',
    AM = 'AM',
    AW = 'AW',
    AU = 'AU',
    AT = 'AT',
    AZ = 'AZ',
    BS = 'BS',
    BH = 'BH',
    BD = 'BD',
    BB = 'BB',
    BY = 'BY',
    BE = 'BE',
    BZ = 'BZ',
    BJ = 'BJ',
    BM = 'BM',
    BT = 'BT',
    BO = 'BO',
    BQ = 'BQ',
    BA = 'BA',
    BW = 'BW',
    BV = 'BV',
    BR = 'BR',
    IO = 'IO',
    BN = 'BN',
    BG = 'BG',
    BF = 'BF',
    BI = 'BI',
    CV = 'CV',
    KH = 'KH',
    CM = 'CM',
    CA = 'CA',
    KY = 'KY',
    CF = 'CF',
    TD = 'TD',
    CL = 'CL',
    CN = 'CN',
    CX = 'CX',
    CC = 'CC',
    CO = 'CO',
    KM = 'KM',
    CG = 'CG',
    CD = 'CD',
    CK = 'CK',
    CR = 'CR',
    CI = 'CI',
    HR = 'HR',
    CU = 'CU',
    CW = 'CW',
    CY = 'CY',
    CZ = 'CZ',
    DK = 'DK',
    DJ = 'DJ',
    DM = 'DM',
    DO = 'DO',
    EC = 'EC',
    EG = 'EG',
    SV = 'SV',
    GQ = 'GQ',
    ER = 'ER',
    EE = 'EE',
    SZ = 'SZ',
    ET = 'ET',
    FK = 'FK',
    FO = 'FO',
    FJ = 'FJ',
    FI = 'FI',
    FR = 'FR',
    GF = 'GF',
    PF = 'PF',
    TF = 'TF',
    GA = 'GA',
    GM = 'GM',
    GE = 'GE',
    DE = 'DE',
    GH = 'GH',
    GI = 'GI',
    GR = 'GR',
    GL = 'GL',
    GD = 'GD',
    GP = 'GP',
    GU = 'GU',
    GT = 'GT',
    GG = 'GG',
    GN = 'GN',
    GW = 'GW',
    GY = 'GY',
    HT = 'HT',
    HM = 'HM',
    VA = 'VA',
    HN = 'HN',
    HK = 'HK',
    HU = 'HU',
    IS = 'IS',
    IN = 'IN',
    ID = 'ID',
    IR = 'IR',
    IQ = 'IQ',
    IE = 'IE',
    IM = 'IM',
    IL = 'IL',
    IT = 'IT',
    JM = 'JM',
    JP = 'JP',
    JE = 'JE',
    JO = 'JO',
    KZ = 'KZ',
    KE = 'KE',
    KI = 'KI',
    KW = 'KW',
    KG = 'KG',
    LA = 'LA',
    LV = 'LV',
    LB = 'LB',
    LS = 'LS',
    LR = 'LR',
    LY = 'LY',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    MO = 'MO',
    MG = 'MG',
    MW = 'MW',
    MY = 'MY',
    MV = 'MV',
    ML = 'ML',
    MT = 'MT',
    MH = 'MH',
    MQ = 'MQ',
    MR = 'MR',
    MU = 'MU',
    YT = 'YT',
    MX = 'MX',
    FM = 'FM',
    MD = 'MD',
    MC = 'MC',
    MN = 'MN',
    ME = 'ME',
    MS = 'MS',
    MA = 'MA',
    MZ = 'MZ',
    MM = 'MM',
    NA = 'NA',
    NR = 'NR',
    NP = 'NP',
    NL = 'NL',
    NC = 'NC',
    NZ = 'NZ',
    NI = 'NI',
    NE = 'NE',
    NG = 'NG',
    NU = 'NU',
    NF = 'NF',
    KP = 'KP',
    MK = 'MK',
    MP = 'MP',
    OM = 'OM',
    PK = 'PK',
    PW = 'PW',
    PS = 'PS',
    PA = 'PA',
    PG = 'PG',
    PY = 'PY',
    PE = 'PE',
    PH = 'PH',
    PN = 'PN',
    PL = 'PL',
    PT = 'PT',
    PR = 'PR',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RU = 'RU',
    RW = 'RW',
    BL = 'BL',
    SH = 'SH',
    KN = 'KN',
    LC = 'LC',
    MF = 'MF',
    PM = 'PM',
    VC = 'VC',
    WS = 'WS',
    SM = 'SM',
    ST = 'ST',
    SA = 'SA',
    SN = 'SN',
    RS = 'RS',
    SC = 'SC',
    SL = 'SL',
    SG = 'SG',
    SX = 'SX',
    SK = 'SK',
    SI = 'SI',
    SB = 'SB',
    SO = 'SO',
    ZA = 'ZA',
    GS = 'GS',
    KR = 'KR',
    SS = 'SS',
    ES = 'ES',
    LK = 'LK',
    SD = 'SD',
    SR = 'SR',
    SJ = 'SJ',
    SE = 'SE',
    CH = 'CH',
    SY = 'SY',
    TW = 'TW',
    TJ = 'TJ',
    TZ = 'TZ',
    TH = 'TH',
    TL = 'TL',
    TG = 'TG',
    TK = 'TK',
    TO = 'TO',
    TT = 'TT',
    TN = 'TN',
    TR = 'TR',
    TM = 'TM',
    TC = 'TC',
    TV = 'TV',
    UG = 'UG',
    UA = 'UA',
    AE = 'AE',
    GB = 'GB',
    UM = 'UM',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VU = 'VU',
    VE = 'VE',
    VN = 'VN',
    VG = 'VG',
    VI = 'VI',
    WF = 'WF',
    EH = 'EH',
    YE = 'YE',
    ZM = 'ZM',
    ZW = 'ZW'
}


