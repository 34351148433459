import {AssessmentEditorState, SpecificEditorProps} from 'library/components/BlockEditor/models';
import * as React from 'react';
import {ProblemResponseType} from '../models';
import {setStateFromOlx} from '../parsers';
import MultiSelectContainer from "./MultiSelectContainer";
import ShortAnswerContainer from './ShortAnswerContainer';
import SingleSelectContainer from "./SingleSelectContainer";
import { WrappedMessage } from 'utils';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
    exception: {
        id: 'problemBlockEditor.exception',
        defaultMessage: 'This question cannot be edited using the visual editor.'
    },
});

type AssessmentBlockEditorProps = SpecificEditorProps<AssessmentEditorState>;

interface State {
    responseType?: ProblemResponseType;
}

export class ProblemBlockEditor extends React.PureComponent<AssessmentBlockEditorProps, State> {

    constructor(props: AssessmentBlockEditorProps) {
        super(props);

        if (props.editorState && props.editorState.olx) {
            try {
                const responseType = setStateFromOlx(props.editorState.olx);
                this.state = {
                    responseType,
                }
            } catch (e) {
                // Don't display the error because it's likely just that this problem uses advanced
                // OLX (e.g. two questions in one problem), so isn't supported by the visual editor.
                // tslint:disable-next-line:no-console
                console.error(e);
                this.state = {responseType: ProblemResponseType.UnknownResponse};
                // Make this as non-editable so that we preserve the existing OLX on save:
                props.onEditorStateChanged({...props.editorState, isEditable: false});
            }
        }
    }

    public render() {
        return (
            <div>
                { this.state.responseType === ProblemResponseType.ChoiceResponse &&
                    <MultiSelectContainer/>
                }
                { this.state.responseType === ProblemResponseType.MultipleChoiceResponse &&
                    <SingleSelectContainer/>
                }
                { this.state.responseType === ProblemResponseType.OptionResponse &&
                    <SingleSelectContainer/>
                }
                { this.state.responseType === ProblemResponseType.StringResponse &&
                    <ShortAnswerContainer/>
                }
                { this.state.responseType === ProblemResponseType.UnknownResponse &&
                    <div className='container-one rounded alert'>
                        <p><WrappedMessage message={messages.exception} /></p>
                    </div>
                }
            </div>
        );
    }
}
