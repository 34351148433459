import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleEducatorMenteesDashboard',
        defaultMessage: 'Mentees',
        description: 'Title of the educator dashboard page that lists all of educator\'s mentees',
    },
    showingResultCounter: {
        id:'showingResultCounter',
        defaultMessage: 'Showing {count, number} {count, plural, one {result} other {results}}',
        description: 'Number of results header.',
    },
    messageButton: {
        id:'messageButton',
        defaultMessage: 'Message',
        description: 'Send a message button.',
    },
    removeButton: {
        id:'removeButton',
        defaultMessage: 'Remove',
        description: 'Remove label.',
    },
    assignButton: {
        id:'assignButton',
        defaultMessage: 'Assign',
        description: 'Assign button label.',
    },
    progressButton: {
        id:'menteeProgressButton',
        defaultMessage: 'Progress',
        description: 'Label for button to view mentee\'s progress.',
    },
    assignItemSuccess: {
        id:'assignItemToMenteeSuccess',
        defaultMessage: 'Items assigned to mentee.',
        description: 'Message shown when items have been successfully assigned to a mentee.',
    },
    pastMenteesSection: {
        id:'pastMenteesSection',
        defaultMessage: 'Past mentees',
        description: 'Past mentees section.',
    },
    onFailedToStop: {
        id:'onFailedToStop',
        defaultMessage: 'Error while sending your stopped request. Please try again later.',
        description: 'Error message when trying to send mentorship stopped request.',
    },
    completed: {
        id:'completed',
        defaultMessage: 'completed',
        description: 'Completed status for assignments.',
    },
    seeAllAssignmentsButton: {
        id:'seeAllAssignmentsButton',
        defaultMessage: 'See all',
        description: 'See all assignments button label.',
    },
    assignedLabel: {
        id:'assignedLabel',
        defaultMessage: 'Assigned',
        description: 'Label for an assigned item title.',
    },
    failedToFetchMentorships: {
        id:'failedToFetchMentorships',
        defaultMessage: 'Unable to retrieve mentorships.',
        description: 'Unable to retrieve mentorships from API.',
    },
    failedToAddItem: {
        id:'failedToAddItemToMentee',
        defaultMessage: 'Unable to add item. Was it already added?',
        description: 'Error message for when a request to add an item with the content picker fails.',
    },
    menteesDescription: {
        id:'menteesDescription',
        defaultMessage: 'Your mentees are listed here. You can assign them content or send them messages.',
        description: 'Mentees description text',
    },
    unassignContentFailed: {
        id:'unassignContentFailed',
        defaultMessage: 'Failed to unassign content.',
        description: 'Error message if content unassignment failed.'
    },
    emptyMenteesDashboardTitle: {
        id:'emptyMenteesDashboardTitle',
        defaultMessage: 'You don\'t have any mentees yet.',
        description: 'Title for the empty mentees dashboard page.',
    },
    emptyMenteesDashboardSecondaryText: {
        id:'emptyMenteesDashboardSecondaryText',
        defaultMessage: 'On LabXchange, only learners can request mentorship. Learners who wish to be mentored must request mentorship on your profile page.',
        description: 'Secondary text for the empty classroom dashboard page.',
    },
    viewProgress: {
        id:'viewProgress',
        defaultMessage: 'View progress',
        description: 'Past mentee action button to view progress.'
    },
    deleteMentee: {
        id:'deleteMentee',
        defaultMessage: 'Delete',
        description: 'Past mentee action button to delete mentee.'
    },
    reportMentee: {
        id:'reportMentee',
        defaultMessage: 'Report',
        description: 'Past mentee action button to report mentee.'
    },
});

export default messages;
