import React from 'react';

import { SectionSlab } from 'labxchange-client';
import { useLayoutSize } from 'utils';

import { convertSlabToFeaturedItems } from './utils';
import { ClustersCarousel } from './ClustersCarousel';

interface ClustersSlabProps {
  heading: string;
  slab: SectionSlab;
}

export const ClustersSlab: React.FC<ClustersSlabProps> = (props) => {
  const layoutSize = useLayoutSize();
  return (
    <div className='clusters-container container'>
      <h1 className='slab-title'>{props.heading}</h1>
      <ClustersCarousel
        clusters={convertSlabToFeaturedItems(props.slab)}
        layoutSize={layoutSize}
      />
    </div>
  );
};

export default ClustersSlab;
