import { checkLoginStatus } from 'auth/actions';
import { bind } from 'bind-decorator';
import { LocationDescriptor } from 'history';
import update from 'immutability-helper';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import { MAX_PATHWAY_ITEMS, PATHWAY_COVER_IMAGE_URL, ROUTES } from 'global/constants';
import { CreateItemStep } from 'items/components/CreateItemModal';
import { TagEditor } from 'search/components/TagEditor';
import { detailUrlForEntity } from 'library/utils';
import { getLoggedInStatus, getUserFullName, getUsername, getUserPermissions } from 'auth/selectors';
import { Card, FauxCard } from '../Card';
import { ItemsApi, OrganizationsApi } from 'global/api';
import { RootState } from 'global/state';
import { getStore } from 'global/store';
import { getItemTypeMeta, ItemType } from 'items/models';
import {
    APIPermissions,
    Author,
    ChildItem,
    ItemCreationTypeEnum,
    ItemMetadata,
    ItemMetadataBackgroundKnowledgeEnum,
    ItemMetadataLicenseEnum,
    ItemMetadataUpdate,
    ItemMetadataUpdateBackgroundKnowledgeEnum,
    ItemMetadataUpdateLicenseEnum,
    ItemMetadataUpdateTypeEnum,
    ItemResponse,
    ItemUserAttributes,
    LearningObjective,
    PathwayDetail,
    PathwayUpdate,
    ResourceRelationship,
} from 'labxchange-client';
import messages from 'library/displayMessages';
import { getEmptyItemMetadata } from 'library/models';
import {
    AuthorsField,
    Button,
    ConfirmDuplicatesModal,
    DuplicateModalItem,
    HtmlTextBox,
    Icon,
    Modal,
    ModalConfirmation,
    RedirectToLogin,
    ReorderableList,
    ReorderableListItem,
    Spinner,
    StandardPageLayout,
    UnderConstruction,
} from 'ui/components';
import { ImageOptions } from 'assets/images/pathway-cover-images';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import {
    AgreementsField,
    BackgroundKnowledgeField,
    FeaturedImageField,
    LanguageField,
    LicenseField,
    PublicToggleField,
    SubjectAreaField,
} from '../ItemMetadataEditor';
import { LearningObjectiveEditor } from 'library/components/LearningObjectiveEditor';
import { PathwayChildDeleteModal } from '../PathwayChildDeleteModal';
import { PathwayChildEditModal, PathwayChildUpdateProps } from '../PathwayChildEditModal';
import { PathwayChildClipVideoModal, PathwayChildClipVideoProps } from '../PathwayChildClipVideoModal';
import { PathwayChildPreviewModal } from '../PathwayChildPreviewModal';
import { PathwayCoverUpdateModal } from '../PathwayEditorWidget';
import { InputText } from '@edx/paragon';
import { ContentPickerModal, Tab } from 'library/components/ContentPickerModal';
import { UI_IS_SM} from 'ui/breakpoints';
import { intl} from 'i18n';
import { ResourcesSection } from '../../../ui/components/Resources/ResourcesSection';

const wrapperClasses = 'col-md-12 col-lg-10 offset-lg-1';

export enum ModalType {
    None = 0,
    Preview,
    Edit,
    Delete,
    ErrorPublicWithPrivateAssets,
    SelectCoverImage,
    ConfirmDeletePathway,
    PickContent,
    ConfirmDuplicates,
    PublishExit,
    SavingLoading,
    ClipVideo,
}

interface MatchProps {
    pathwayKey: string;  // obtained from the url
}

interface Props extends RouteComponentProps<MatchProps> {
}

interface ReduxStateProps {
    isLoggedIn: boolean;
    loggedInUsername: string;
    loggedInUserFullName: string;
    userPermissions?: APIPermissions;
}

interface State {
    loading: boolean;
    loadingRetries: number;
    pathwayMetadata?: ItemMetadata;
    pathwayUserAttributes?: ItemUserAttributes;
    pathwayDetails?: PathwayDetail;
    modalWindowToggle: boolean;  // for the cover image modal
    redirectTo?: LocationDescriptor;
    showPublicToggle: boolean;
    newFeaturedImage?: File;
    agreementsChecked: boolean;
    showErrors: boolean;
    saveButtonDisabled: boolean;
    unsavedChanges: boolean;
    leaveAndSaveLocation?: LocationDescriptor;
    infoBoxExpanded: boolean;
    modalType: ModalType;
    modalChildId?: number;
    createItemStep: CreateItemStep;
    createItemSelectedItemType?: ItemType;
    contentPickerModalTab: Tab;
    isMobileView: boolean;
    duplicates: DuplicateModalItem[];
    selectedItems: Set<string>;
    forceUpdateAfterAddDuplicates: boolean;
    // Used in organizations, if can publish this item to public library
    canPublishOrgItemsToPublicLibrary: boolean;
    isOrganizationItem: boolean;
}

/**
 * The PathwayEditPage allows editing of a piece of content,
 * by handling the API calls required for loading and saving.
 */
export class PathwayEditPageInternal extends React.PureComponent<Props & ReduxStateProps, State> {
    private mediaQuery = UI_IS_SM;

    constructor(props: Props & ReduxStateProps) {
        super(props);
        this.state = {
            loading: true,
            loadingRetries: 2,
            modalWindowToggle: false,
            pathwayMetadata: undefined,
            showPublicToggle: false,
            agreementsChecked: false,
            showErrors: false,
            saveButtonDisabled: false,
            unsavedChanges: false,
            infoBoxExpanded: false,
            modalType: ModalType.None,
            createItemStep: CreateItemStep.SelectType,
            createItemSelectedItemType: undefined,
            contentPickerModalTab: Tab.Library,
            isMobileView: false,
            duplicates: [],
            selectedItems: new Set(),
            forceUpdateAfterAddDuplicates: false,
            canPublishOrgItemsToPublicLibrary: false,
            isOrganizationItem: false,
        };

        // If there are unsaved changes, ask the user to confirm leaving the page.
        // Note that some browsers ignore this; it's not 100% reliable.
        window.addEventListener('beforeunload', this.onLeavePage);
    }

    @bind public onLeavePage(e: any): string|undefined {
        if (this.state.unsavedChanges) {
            // Prevent the unload.
            e.preventDefault();
            e.returnValue = '';

            // Attempt to show a custom message. This is non-standard, and may
            // or may not be displayed. Many browsers will only display their
            // standard confirm leave message.
            return intl.formatMessage(messages.unsavedChangesLeaveConfirm);
        }
        return;
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQuery.matches});
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
        this.setState({ showErrors: false, redirectTo: undefined });
        // Create an empty template if called from pathway/new
        if (this.isNewPathway()) {
            const pathwayMetadata = getEmptyItemMetadata(
                ItemType.Pathway, new Date(),
            );

            this.setState({
                loading: false,
                pathwayMetadata,
                pathwayDetails: {
                    items: [],
                } as PathwayDetail,
                showPublicToggle: this.shouldShowPublicToggle(),
            });

            // If we're creating the pathway for an organization, set the "content source"
            if (this.props.location.state && this.props.location.state.orgSlug) {
                this.loadContentSource(this.props.location.state.orgSlug);
                this.setState({
                    canPublishOrgItemsToPublicLibrary: await this.loadOrgPermissions(this.props.location.state.orgSlug),
                    isOrganizationItem: true,
                });
            }

            return;
        }
        this.loadPathway();
    }

    private async loadPathway() {
        const id = this.props.match.params.pathwayKey;
        // Otherwise, try to get pathway data from the api
        try {
            const [pathwayMetadata, pathwayDetails] = await Promise.all([
                ItemsApi.read({id}),
                ItemsApi.pathway({id}),
            ]);
            if (pathwayMetadata.userAttributes.canEditObject) {
                const sourceOrg = pathwayMetadata.metadata.source?.sourceOrganizations && pathwayMetadata.metadata.source?.sourceOrganizations[0].organization;
                this.setState({
                    loading: false,
                    pathwayDetails,
                    pathwayUserAttributes: pathwayMetadata.userAttributes,
                    pathwayMetadata: pathwayMetadata.metadata,
                    showPublicToggle: this.shouldShowPublicToggle(),
                    canPublishOrgItemsToPublicLibrary: await this.loadOrgPermissions(sourceOrg && sourceOrg.slug),
                    isOrganizationItem: (sourceOrg && sourceOrg.slug) !== undefined,
                });
            } else {
                // They don't have permission; Redirect to the "view" mode and display an error message.
                this.setState({
                    loading: false,
                    pathwayUserAttributes: undefined,
                    pathwayMetadata: undefined,
                    redirectTo: ROUTES.Library.PATHWAY_SLUG(this.props.match.params.pathwayKey),
                });
                showErrorMessage('You do not have permission to edit this pathway.');
                return;
            }
        } catch (err) {
            const loadingRetries = this.state.loadingRetries;
            if (!loadingRetries) {
                showErrorMessage('Could not fetch pathway data.', {exception: err});
                this.setState({
                    loading: false,
                    pathwayUserAttributes: undefined,
                    pathwayMetadata: undefined,
                });
            } else {
                // Try again
                this.setState({
                    loadingRetries: loadingRetries-1,
                }, this.loadPathway);
            }
        }
    }

    public componentDidUpdate() {
        this.setState({showPublicToggle: this.shouldShowPublicToggle()});
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo}/>;
        }
        if (!this.props.isLoggedIn) {
            return <StandardPageLayout><RedirectToLogin/></StandardPageLayout>;
        }
        if (this.state.pathwayMetadata === undefined
            || this.state.pathwayDetails === undefined) {
            if (this.state.loading) {
                return (
                    <StandardPageLayout headerFeature={this.renderErrorHeader()}>
                        <p><WrappedMessage message={uiMessages.uiLoading}/></p>
                    </StandardPageLayout>
                );
            } else {
                // We weren't able to load the metadata about the content
                return (
                    <StandardPageLayout headerFeature={this.renderErrorHeader()}>
                        <h1><WrappedMessage message={uiMessages.uiError}/></h1>
                        <p><WrappedMessage message={messages.pathwayContentError}/></p>
                    </StandardPageLayout>
                );
            }
        }

        const isPublic = Boolean(this.state.pathwayMetadata.isPublic);
        const showErrors = this.state.showErrors;

        const metadataWrapperClasses = `extra-meta-wrapper ${wrapperClasses}`;

        return (
            <StandardPageLayout
                pageTitle={messages.pathwayEditPageTitle}
                pageTitleValues={{name: this.state.pathwayMetadata.title}}
                headerFeature={this.renderHeader()}
                headerBackgroundUrl={
                    this.state.pathwayDetails.image
                        ? PATHWAY_COVER_IMAGE_URL(this.state.pathwayDetails.image)
                        : ''
                }
            >
                <div className='pathway-wrapper row'>
                    {this.state.modalWindowToggle
                    ? <PathwayCoverUpdateModal
                            images={ImageOptions}
                            defaultValue={this.state.pathwayMetadata.imageUrl}
                            onUpdate={this.updateImageUrl}
                            onClose={this.closeModal}
                      />
                    : null}

                    <div className='info-box-wrapper col-12'>
                        <div className='info-box white-box' data-expanded={this.state.infoBoxExpanded}>
                            <div className='pathway-info pathway-info-edit'>
                                {this.renderPathwayForm()}
                            </div>
                            <div className='author-info'>
                            </div>
                        </div>
                    </div>

                    {this.renderCardsList()}

                    <div className={metadataWrapperClasses}>
                        <AuthorsField
                            authors={this.state.pathwayMetadata.authors}
                            onUpdate={this.onAuthorsChange}
                            currentUser={{
                                username: this.props.loggedInUsername,
                                fullName: this.props.loggedInUserFullName,
                            }}
                            isNew={this.isNewPathway()}
                        />
                    </div>

                    {this.state.isMobileView ?
                        <>
                            <div className={metadataWrapperClasses}>
                                <TagEditor
                                    required={isPublic}
                                    showErrors={showErrors}
                                    onChange={this.updateFreeFormTags}
                                    tags={this.state.pathwayMetadata.freeFormTags}
                                />
                            </div>
                            <div className={metadataWrapperClasses}>
                                <FeaturedImageField
                                    required={isPublic}
                                    showErrors={showErrors}
                                    defaultValue={this.state.pathwayMetadata.imageUrl}
                                    onUpdate={this.updateFeaturedImage}
                                />
                            </div>
                        </> :
                        <div className={`tags-featured-image-container ${metadataWrapperClasses}`}>
                            <div className='tags-editor-container side-meta-wrapper'>
                                <TagEditor
                                    required={isPublic}
                                    showErrors={showErrors}
                                    onChange={this.updateFreeFormTags}
                                    tags={this.state.pathwayMetadata.freeFormTags}
                                />
                            </div>
                            <div className='featured-image-container side-meta-wrapper'>
                                <FeaturedImageField
                                    required={isPublic}
                                    showErrors={showErrors}
                                    defaultValue={this.state.pathwayMetadata.imageUrl}
                                    onUpdate={this.updateFeaturedImage}
                                />
                            </div>
                        </div>
                    }

                    <div className={metadataWrapperClasses}>
                        <LearningObjectiveEditor
                            learningObjectives={this.state.pathwayMetadata.learningObjectives}
                            onChange={this.updateLearningObjectives}
                        />
                    </div>
                    {this.props.userPermissions?.library.canCreateLinkItem &&
                      <ResourcesSection
                        item={this.state.pathwayMetadata}
                        updateResources={this.onUpdateResources}
                      />
                    }

                    <div className={metadataWrapperClasses}>
                        {(this.state.showPublicToggle || this.state.canPublishOrgItemsToPublicLibrary)
                        ?
                            <PublicToggleField
                                isPublic={Boolean(this.state.pathwayMetadata.isPublic)}
                                onUpdate={this.updatePublic}
                                showNote={false}
                                titleMessage={messages.pathwayPublicTitle}
                                descriptionMessage={messages.pathwayPublicText}
                                disabled={this.state.isOrganizationItem && !this.state.canPublishOrgItemsToPublicLibrary}
                            />
                        : null}
                    </div>

                    <div className={metadataWrapperClasses}>
                        <AgreementsField
                            isChecked={this.state.agreementsChecked}
                            showErrors={showErrors}
                            onUpdate={this.onAgreementsCheckedUpdate}
                        />
                    </div>

                    {this.renderSaveEditDeleteButtons()}

                    {this.renderModals()}
                </div>
            </StandardPageLayout>
        );
    }

    /* Helper functions and render functions below this line */

    private renderCardsList() {
        const itemsList: ReorderableListItem[] = this.state.pathwayDetails!.items.map((child, index) => {
            const item = child.item.metadata;
            const detailUrl = detailUrlForEntity({
                id: item.id,
                type: item.type,
            });
            return {
                key: child.item.metadata.id,
                html: (
                    <Card
                        {...child.item}
                        notes={child.notes}
                        pathwayMetadata={this.state.pathwayMetadata}
                        miniMobileMode={true}
                        onClickPreview={() => this.onOpenModal(index, ModalType.Preview)}
                        onClickEdit={() => this.onOpenModal(index, ModalType.Edit)}
                        onClickDelete={() => this.onOpenModal(index, ModalType.Delete)}
                        detailUrl={detailUrl}
                        showMenuButton={true}
                        openDetailUrlInNewTab={true}
                        showPathwayItemsMenu={true}
                        isEditModalOpen={this.state.modalChildId === index && this.state.modalType === ModalType.Edit}
                        isClipped={child.videoStartTime !== undefined && child.videoStopTime !== undefined}
                        videoStartTime={child.videoStartTime}
                        videoStopTime={child.videoStopTime}
                        hasNotes={child.notes ? true : false}
                        onClickClipVideo={() => this.onOpenModal(index, ModalType.ClipVideo)}
                    />
                ),
            };
        });
        // Then add the placeholder cards for new content:
        for (let i = 0; i < Math.max(1, 3 - this.state.pathwayDetails!.items.length); i++) {
            const isRequired = i+this.state.pathwayDetails!.items.length < 2;
            const isError = isRequired && this.state.showErrors;
            itemsList.push({
                key: `faux${i}`,
                isPlaceholder: true,
                html: (
                    <FauxCard
                        isError={isError}
                        onClick={() => this.setState({modalType: ModalType.PickContent})}
                        miniMobileMode={true}
                        isRequired={isRequired}
                    >
                        <span>
                            <Icon name='plus-small' zoom='26' />
                            {this.state.pathwayDetails!.items.length
                            ? <WrappedMessage message={messages.pathwayAddNextCard} />
                            : <WrappedMessage message={messages.pathwayAddNewCard} />
                            }{isRequired ? '*' : ''}
                        </span>
                    </FauxCard>
                ),
            });
        }
        return <div className={`pathway-cards-list-editor ${wrapperClasses}`}>
            <ReorderableList items={itemsList} onMove={this.moveItem} />
        </div>;
    }

    private renderModals() {
        switch (this.state.modalType) {

            case ModalType.Preview:
                if (this.state.modalChildId === undefined) { return; }
                return (
                    <PathwayChildPreviewModal
                        child={this.state.pathwayDetails!.items[this.state.modalChildId]}
                        onClose={this.onCloseModal}
                    />
                );

            case ModalType.ClipVideo:
                if (this.state.modalChildId === undefined) { return; }
                return (
                    <PathwayChildClipVideoModal
                        child={this.state.pathwayDetails!.items[this.state.modalChildId]}
                        onClose={this.onCloseModal}
                        onUpdate={this.clipVideo}
                    />
                );

            case ModalType.Edit:
                if (this.state.modalChildId === undefined) { return; }
                return (
                    <PathwayChildEditModal
                        child={this.state.pathwayDetails!.items[this.state.modalChildId]}
                        onClose={this.onCloseModal}
                        onUpdate={this.updateItem}
                    />
                );

            case ModalType.Delete:
                if (this.state.modalChildId === undefined) { return; }
                return (
                    <PathwayChildDeleteModal
                        child={this.state.pathwayDetails!.items[this.state.modalChildId]}
                        onDelete={this.removeItem}
                        onClose={this.onCloseModal}
                    />
                );

            case ModalType.ErrorPublicWithPrivateAssets:
                return (
                    <Modal
                        showBackButton={true}
                        onRequestClose={this.onCloseModal}
                        content={
                            <>
                                <div className='warning-icon'></div>
                                <div className='title-text'>
                                    <WrappedMessage
                                        message={messages.pathwayErrorMakePublicWithPrivateAssetsModalTitle}
                                    />
                                </div>
                                <div className='body-text'>
                                    <WrappedMessage
                                        message={messages.pathwayErrorMakePublicWithPrivateAssetsModalBody}
                                    />
                                </div>
                            </>
                        }
                    />
                );

            case ModalType.ConfirmDeletePathway:
                return (
                    <ModalConfirmation
                        onCanceled={this.onCloseModal}
                        onConfirmed={() => {
                            this.setState({modalType: ModalType.None});
                            this.deletePathway();
                        }}
                        title={messages.pathwayDeleteConfirm}
                        body={messages.pathwayDeleteBody}
                        titleValues={{title: this.state.pathwayMetadata!.title}}
                        confirmButtonText={uiMessages.uiConfirmationButton}
                        cancelButtonText={uiMessages.uiCancelButton}
                    />
                );

            case ModalType.PickContent:
                return (
                    <ContentPickerModal
                        tab={this.state.contentPickerModalTab}
                        firstSelectedItems={this.state.selectedItems}
                        onTabChange={(tab) => {this.setState({ contentPickerModalTab: tab });}}
                        onClose={this.onCloseModal}
                        onItemsAdd={this.addNewItems}
                        excludeTypes={[ItemType.Pathway, ItemType.Book, ItemType.Cluster, ItemType.Link]}
                        multipleSelect={true}
                        showCreateItemTab={true}
                        onCreateItem={this.onCreateItem}
                        createItemStep={this.state.createItemStep}
                        onCreateItemStepChange={this.onCreateItemStepChange}
                        createItemSelectedItemType={this.state.createItemSelectedItemType}
                        onCreateItemSelectedItemTypeChange={this.onCreateItemSelectedItemTypeChange}
                        onSelectionChanged={this.onSelectionChanged}
                    />
                );

            case ModalType.ConfirmDuplicates:
                return (
                    <ConfirmDuplicatesModal
                        items={this.state.duplicates}
                        description={messages.duplicatesConfirmDescription}
                        onConfirm={this.addDuplicates}
                        onCancel={this.resetDuplicates}
                        onUpdate={(items) => {this.setState({duplicates: items});}}
                    />
                );

            case ModalType.PublishExit:
                let typeMessage = 'asset';
                if (this.state.createItemSelectedItemType) {
                    typeMessage = intl.formatMessage(
                        getItemTypeMeta(this.state.createItemSelectedItemType).name
                    ).toLowerCase();
                }
                return (
                    <Modal
                        className='modal-publish-exit'
                        showBackButton={false}
                        onRequestClose={this.onCancelSaveAndAddNew}
                        content={
                            <>
                                <div className='warning-icon'></div>
                                <div className='title-text'>
                                    <WrappedMessage
                                        message={messages.leavePublishModalTitlePrivate}
                                    />
                                </div>
                                <div className='body-text'>
                                    <WrappedMessage
                                        message={this.state.selectedItems.size === 0
                                                    ? messages.leavePublishModalInfo
                                                    : messages.leavePublishWithSelectedItemsModalInfo}
                                        values={{
                                            assetType: typeMessage,
                                        }}
                                    />
                                    {this.isNewPathway() &&
                                        <AgreementsField
                                            isChecked={this.state.agreementsChecked}
                                            showErrors={this.state.showErrors}
                                            onUpdate={this.onAgreementsCheckedUpdate}
                                        />
                                    }
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <Button
                                    btnStyle='outline'
                                    label={messages.leavePublishModalBackToEditButton}
                                    onClick={this.onCancelSaveAndAddNew}
                                />
                                <Button
                                    btnStyle='primary'
                                    onClick={this.forceSaveNew}
                                    disabled={!this.state.agreementsChecked && this.isNewPathway()}
                                    label={messages.leavePublishModalConfirmButton}
                                />
                            </>
                        }
                    />
                );
            case ModalType.SavingLoading:
                return (
                    <Modal
                        className='text-center edit-page-saving-modal'
                        onRequestClose={() => {/* do nothing */}}
                        size='normal'
                        showTopBar={false}
                        content={
                            <div>
                                <div className='lx-spinner center'><Spinner size='96px'/></div>
                                <div className='content'>
                                    <WrappedMessage message={messages.savingModalMessage} />
                                </div>
                            </div>
                        }
                    />
                );
            default: return null;
        }
    }

    private renderSaveEditDeleteButtons() {
        const buttonDisabled = this.state.saveButtonDisabled;
        return (
            <div className={`button-wrapper ${wrapperClasses}`}>
                <button
                    className='lx-btn btn-link pathway-delete-button'
                    onClick={() => this.setState({modalType: ModalType.ConfirmDeletePathway})}>
                    <Icon name='trashcan' zoom='1em'/>
                    <WrappedMessage message={messages.pathwayDelete}/>
                </button>
                <UnderConstruction style={{marginLeft: 'auto'}}>
                    <button type='button'
                        className='lx-btn btn-outline-primary draft-button hide-on-tablet-and-below'
                        onClick={this.saveDraft}
                    >
                        <WrappedMessage message={messages.pathwaySave}/>
                    </button>
                </UnderConstruction>
                <Button
                    btnStyle='outline'
                    onClick={this.onPublishAndContinue}
                    label={messages.pathwaySaveContinue}
                    disabled={buttonDisabled}
                    aria-disabled={buttonDisabled}
                />
                <Button btnStyle='primary'
                    onClick={this.publishPathway}
                    disabled={buttonDisabled}
                    aria-disabled={buttonDisabled}
                    data-testid='publish-button'
                    hasLoader={true}
                    label={this.state.pathwayMetadata?.isPublic
                        ? messages.pathwayPublishPublicly
                        : messages.pathwayPublishPrivately}
                />
            </div>
        );
    }

    private renderPathwayForm() {
        const isPublic = Boolean(this.state.pathwayMetadata?.isPublic);
        const showErrors = this.state.showErrors;
        const titleIsError = this.state.showErrors && !this.state.pathwayMetadata!.title;

        const pathwayMetadata = this.state.pathwayMetadata!;

        return (
            <div className='pathway-edit'>
                <InputText
                    name='pathway_title'
                    inline={false}
                    value={pathwayMetadata.title}
                    label={<WrappedMessage message={messages.pathwayTitle} />}
                    placeholder={intl.formatMessage(messages.pathwayTitlePlaceholder)}
                    className={`pathway-title form-control lx-input ${titleIsError ? 'lx-input-error' : ''}`}
                    onChange={this.updateTitle}
                />
                <HtmlTextBox
                    label={<WrappedMessage message={messages.pathwayDescription} />}
                    required={isPublic}
                    showErrors={showErrors}
                    placeholder={intl.formatMessage(messages.pathwayDescriptionPlaceholder)}
                    onChange={this.updateDescription}
                    defaultValue={pathwayMetadata.description.trim()}
                    editorStyle={HtmlTextBox.EditorStyle.Medium}
                />
                <ul className='metadata-edit list-unstyled mb-0'>
                    <li className='subject-area'>
                        <SubjectAreaField
                            required={isPublic}
                            showErrors={showErrors}
                            onUpdate={this.updateSubjectTags}
                            selectedSubjectTags={pathwayMetadata.subjectTags}
                        />
                    </li>
                    <li>
                        <LanguageField
                            value={pathwayMetadata.language}
                            onUpdate={this.onUpdateLanguage}
                        />
                    </li>
                    <li>
                        <BackgroundKnowledgeField
                            required={isPublic}
                            showErrors={showErrors}
                            value={pathwayMetadata.backgroundKnowledge}
                            onChange={this.updateBackgroundKnowledge}
                        />
                    </li>
                    <li>
                        <LicenseField
                            value={pathwayMetadata.license}
                            onChange={this.updateLicense}
                            contentSource={this.state.pathwayMetadata!.source}
                        />
                    </li>
                </ul>
            </div>
        );
    }

    @bind private onOpenModal(modalChildId: number, modalType: ModalType) {
        this.setState({modalType, modalChildId});
    }

    @bind private onCloseModal() {
        this.setState({
            modalType: ModalType.None,
            modalChildId: undefined,
            createItemStep: CreateItemStep.SelectType,
            createItemSelectedItemType: undefined,
            contentPickerModalTab: Tab.Library,
            selectedItems: new Set(),
        });
    }

    // This is called when the user clicks 'Back to edit' on the 'publish
    // pathway privately' modal to confirm navigating away to create a new item
    // that will be added to this pathway. This cancels the operation and
    // returns to the plain pathway edit view.
    @bind private onCancelSaveAndAddNew() {
        this.setState({
            modalType: ModalType.PickContent,
            createItemStep: CreateItemStep.SelectType,
        });
    }

    // This is called when the user confirms they want to save the pathway and
    // navigate away to create a new item to add to the pathway. It force saves
    // by setting to private and filling in the title if missing if not
    // currently safe to publish.
    @bind private forceSaveNew() {
        // Here we need to force save the pathway. If it's not ready/valid to
        // be saved, tweak the data to make sure it can be saved.
        this.setState({
            modalType: ModalType.SavingLoading,
        });

        /// If there is selected items in the modal, then we need to add them to the
        /// pathway. This aproach allows to await until the last item. The las added item
        /// calls forceSaveNew again with selectedItems as empty.
        if (this.state.selectedItems.size !== 0) {
            this.addNewItems(this.state.selectedItems, true);
            return;
        }

        const title = this.state.pathwayMetadata!.title || 'Pathway ' + new Date().toISOString();
        const isPublic = this.okayToPublish() ? this.state.pathwayMetadata!.isPublic : false;
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {
                isPublic: {$set: isPublic},
                title: {$set: title},
            }),
            unsavedChanges: true,
        }, async () => {
            const id = await this.doPublishPathway(true);
            this.setState({
                pathwayMetadata: update(this.state.pathwayMetadata!, {id: {$set: id || ''}}),
                selectedItems: new Set(),
                modalType: ModalType.SavingLoading,
            }, () => {
                // Cool hack to ensure the url to the pathway edit page (in
                // case we just saved a new pathway).
                const url = ROUTES.Library.PATHWAY_EDIT_SLUG(this.state.pathwayMetadata!.id);
                window.history.replaceState(null, '', url);

                this.buildAndDoCreateItemRedirect();
            });

        });
    }

    @bind private onCreateItemStepChange(createItemStep: CreateItemStep) {
        this.setState({ createItemStep });
    }

    @bind private onCreateItemSelectedItemTypeChange(createItemSelectedItemType: ItemType) {
        this.setState({ createItemSelectedItemType });
    }

    private async loadOrgPermissions(orgSlug?: string) {
        if (orgSlug === undefined) {
            return false;
        }
        const orgResponse = await OrganizationsApi.read({id: orgSlug!});
        return orgResponse.permissions.canPublishContentToPublicLibraryObject;
    }

    private buildAndDoCreateItemRedirect() {
        let location = this.state.leaveAndSaveLocation;
        if (location === undefined) {
            return;
        }

        // This is when the user selects a new item to add, and will be
        // redirected to the edit page for that item (location).

        // see for typing spec:
        // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/54ab8fc24cbf263e7608d2bc56cad6283bc2aa8d/types/history/index.d.ts
        // Pull out the existing search params (this is to preserve any
        // existing params).
        let search;
        if (typeof(location) === 'string') {
            search = new URLSearchParams(new URL(location, window.location.origin).search);
        } else {
            search = new URLSearchParams(location.search);
        }

        // Add search params so we can track which pathway we came from.
        if (this.state.pathwayMetadata !== undefined) {
            search.append('add-to-id', this.state.pathwayMetadata.id);
            search.append('add-to-type', this.state.pathwayMetadata.type);
        }

        // Update the location with the added search params.
        if (typeof(location) === 'string') {
            const url = new URL(location, window.location.origin);
            location = url.pathname + '?' + search.toString();
        } else {
            location.search = search.toString();
        }

        this.setState({
            redirectTo: location,
        });
    }

    @bind private onCreateItem(location: LocationDescriptor, itemType?: ItemType) {
        this.setState({
            leaveAndSaveLocation: location,
            createItemSelectedItemType: itemType ?? this.state.createItemSelectedItemType,
        }, () => {
            // We want to save the location to state
            // and open the confirm modal for force save and
            // exit. Also, if there are no unsaved changes and it's an existing
            // pathway, we can navigate away directly.
            if (!this.state.unsavedChanges && !this.isNewPathway()) {
                this.buildAndDoCreateItemRedirect();
            } else {
                this.setState({
                    modalType: ModalType.PublishExit,
                });
            }
        });
    }

    @bind private async addNewItems(ids: Set<string>, forceUpdate = false) {
        this.setState({
            modalType: forceUpdate ? ModalType.SavingLoading : ModalType.None,
            selectedItems: new Set(),
        });
        const duplicates: DuplicateModalItem[] = [];
        const itemsToAdd: string[] = []; // not duplicated
        let currentItems = new Map<string, DuplicateModalItem>();
        if (this.state.pathwayDetails) {
            currentItems = new Map<string, DuplicateModalItem>((this.state.pathwayDetails.items.map((item) =>
                [item.item.metadata.id!, {...item, id: item.item.metadata.id!}]
            )));
        }

        /// Before to add items we need to know if there are duplicates
        for (const id of ids) {
            const duplicateItem = currentItems.get(id);
            if (duplicateItem) {
                duplicates.push(duplicateItem);
            }
            else {
                itemsToAdd.push(id);
            }
        }

        for (let i = 0; i < itemsToAdd.length; i++) {
            /// Force update if there aren't duplicates and is the last item
            /// If there are duplicates, then we need to call the force update
            /// after saving the last duplicate.
            const forceUpdateLastId = forceUpdate && duplicates.length === 0 && i === itemsToAdd.length - 1;
            try {
                await this.addItem(itemsToAdd[i], forceUpdateLastId);
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.pathwayCardItemFetchError}/>, {exception: err});
            }
        }

        if (duplicates.length > 0) {
            this.setState({
                modalType: ModalType.ConfirmDuplicates,
                duplicates,
                forceUpdateAfterAddDuplicates: forceUpdate,
            });
        }
    }

    @bind private resetDuplicates() {
        if (this.state.forceUpdateAfterAddDuplicates) {
            this.forceSaveNew();
        }

        this.setState({
            duplicates: [],
            modalType: this.state.forceUpdateAfterAddDuplicates ? ModalType.SavingLoading : ModalType.None,
            forceUpdateAfterAddDuplicates: false,
        });
    }

    @bind private async addDuplicates() {
        const forceUpdate = this.state.forceUpdateAfterAddDuplicates;
        if (forceUpdate) {
            this.setState({
                modalType: ModalType.SavingLoading,
            });
        }

        for (let i = 0; i < this.state.duplicates.length; i++) {
            try {
                const forceUpdateLastId = forceUpdate && i === this.state.duplicates.length - 1;
                await this.addItem(this.state.duplicates[i].id, forceUpdateLastId);
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.pathwayCardItemFetchError}/>, {exception: err});
            }
        }
        this.setState({
            forceUpdateAfterAddDuplicates: false,
        }, () => this.resetDuplicates());
    }

    private renderErrorHeader() {
        return (
            <div className='pathway-page-header-content container pt-md-5'>
            </div>
        );
    }

    private renderHeader() {
        const headingMessage = this.isNewPathway() ? messages.pathwayCreateHeading : messages.pathwayUpdateHeading;
        return (
            <div className='pathway-page-header-content extended container pt-md-5'>
                <h1 className='sr-only'><WrappedMessage message={headingMessage}/></h1>
                <UnderConstruction style={{display: 'inline'}}>
                    <button className='lx-btn btn-outline-secondary preview-button'>
                        <WrappedMessage message={messages.pathwayPreview}/>
                    </button>
                </UnderConstruction>
                <button
                    className='btn edit-image-button btn-outline-secondary'
                    onClick={this.toggleModalWindow}
                >
                    <Icon name='device-camera'/>
                    <WrappedMessage message={messages.pathwayImageEdit}/>
                </button>
            </div>
        );
    }

    // Returns true when creating a new pathway, false when editing an existing one.
    private isNewPathway() {
        return !this.props.match.params.pathwayKey;
    }

    @bind private toggleModalWindow() {
        this.setState({modalWindowToggle: !this.state.modalWindowToggle});
    }

    @bind private closeModal() {
        this.setState({modalWindowToggle: false});
    }

    @bind private updateFreeFormTags(data: string[]) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {freeFormTags: {$set: data.slice()}}),
            unsavedChanges: true,
        });
    }

    @bind private updateSubjectTags(data: ReadonlySet<string>) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {subjectTags: {$set: Array.from(data)}}),
            unsavedChanges: true,
        });
    }

    @bind private updateImageUrl(data: string) {
        this.setState({
            pathwayDetails: update(this.state.pathwayDetails, {image: {$set: data}}),
            unsavedChanges: true,
        });
    }

    @bind private updateFeaturedImage(imageFile?: File) {
        this.setState({
            newFeaturedImage: imageFile,
            unsavedChanges: true,
        });
    }

    @bind private updateTitle(data: string) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {title: {$set: data}}),
            unsavedChanges: true,
        });
    }

    @bind private updateDescription(data: string) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {description: {$set: data}}),
            unsavedChanges: true,
        });
    }

    @bind private onUpdateLanguage(language: string) {
        if (this.state.pathwayMetadata === undefined) {
            return;
        }

        this.setState({
            pathwayMetadata: {
                ...this.state.pathwayMetadata,
                language,
            }
        });
    }

    @bind private onSelectionChanged(itemIds: Set<string>) {
        this.setState({
            selectedItems: itemIds,
        });
    }

    @bind private updateBackgroundKnowledge(value: ItemMetadataBackgroundKnowledgeEnum) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {backgroundKnowledge: {$set: value}}),
            unsavedChanges: true,
        });
    }

    @bind private updateLicense(value: ItemMetadataLicenseEnum) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {license: {$set: value}}),
            unsavedChanges: true,
        });
    }

    @bind private updateLearningObjectives(learningObjectives: LearningObjective[]) {
        if (this.state.pathwayMetadata) {
            this.setState({
                pathwayMetadata: {
                    ...this.state.pathwayMetadata,
                    learningObjectives,
                },
                unsavedChanges: true,
            });
        }
    }

    @bind private updatePublic(isPublic: boolean) {
        const hasPrivateAssets = this.state.pathwayDetails!.items.some((value: ChildItem) => (
            !value.item.metadata.isPublic
        ));

        if (isPublic && hasPrivateAssets) {
            this.setState({
                modalType: ModalType.ErrorPublicWithPrivateAssets,
                pathwayMetadata: update(this.state.pathwayMetadata!, {isPublic: {$set: false}}),
            });
        } else {
            this.setState({
                pathwayMetadata: update(this.state.pathwayMetadata!, {isPublic: {$set: isPublic}}),
                unsavedChanges: true,
            });
        }
    }

    @bind private moveItem(oldIdx: number, newIdx: number) {
        if (this.state.pathwayDetails === undefined) { return; }
        const child = this.state.pathwayDetails.items[oldIdx];
        this.setState({
            pathwayDetails: update(
                this.state.pathwayDetails,
                {items: {$splice: [[oldIdx, 1], [newIdx, 0, child]]}}),
            unsavedChanges: true,
        });
    }

    @bind private removeItem() {
        const idx = this.state.modalChildId;
        if (idx !== undefined) {
            this.setState({
                pathwayDetails: update(
                    this.state.pathwayDetails,
                    {items: {$splice: [[idx, 1]]}}),
                unsavedChanges: true,
            });
        }
    }

    @bind private updateItem(newData: PathwayChildUpdateProps) {
        const idx = this.state.modalChildId;
        if (idx !== undefined) {
            this.setState({
                pathwayDetails: update(
                    this.state.pathwayDetails,
                    {items: {[idx]: {$merge: newData}}}),
                unsavedChanges: true,
            });
        }
    }

    @bind private clipVideo(newData: PathwayChildClipVideoProps) {
        const idx = this.state.modalChildId;
        if (idx !== undefined) {
            this.setState({
                pathwayDetails: update(
                    this.state.pathwayDetails,
                    {items: {[idx]: {$merge: newData}}}),
                unsavedChanges: true,
            });
        }
    }

    /**
     * Adds an item to the pathway
     *
     * The force update is used when the user wants to create a new item
     * and is needed to add all items before leave to the creation page
     */
    @bind private async addItem(id: string, forceUpdate = false) {
        if (this.state.pathwayDetails === undefined) { return; }

        // Check if we're going to exceed the items limit for pathways
        if (this.state.pathwayDetails.items.length >= MAX_PATHWAY_ITEMS) {
            showErrorMessage(<WrappedMessage
                message={messages.cannotAddMoreItemsToPathway}
                values={{ maxItems: MAX_PATHWAY_ITEMS }}
            />);
            return;
        }

        const itemData = await ItemsApi.read({id});
        const newChildren = this.state.pathwayDetails.items.slice();
        newChildren.push({item: itemData, notes: ''});
        this.setState({
            pathwayDetails: update(
                this.state.pathwayDetails,
                {items: {$set: newChildren}}),
            unsavedChanges: true,
        }, () => {
            if (forceUpdate) {
                this.forceSaveNew();
            }
        });
    }

    /**
     * Returns true if if all required fields are set depending on the current
     * state.
     */
    @bind private okayToPublish(): boolean {
        const pathwayDetails = this.state.pathwayDetails;
        const pathwayMetadata = this.state.pathwayMetadata;
        if (pathwayMetadata === undefined || pathwayDetails === undefined) {
            return false;
        }

        if (!this.state.agreementsChecked) {
            return false;
        }

        const isPublic = Boolean(pathwayMetadata.isPublic);
        const hasTitle = pathwayMetadata.title.length > 0;
        const hasDescript = !isPublic || pathwayMetadata.description!.length > 0;
        const hasSubject = !isPublic || pathwayMetadata.subjectTags!.length > 0;
        const backgroundKnowledge = pathwayMetadata.backgroundKnowledge!;
        const hasBackground =  !isPublic || (Object.values(
            ItemMetadataBackgroundKnowledgeEnum).includes(backgroundKnowledge) &&
            (backgroundKnowledge !== ItemMetadataBackgroundKnowledgeEnum.Empty));
        const hasTags = !isPublic || pathwayMetadata.freeFormTags!.length > 0;
        const hasContent = !isPublic || pathwayDetails.items.length > 1; // min 2 content items
        const hasFeaturedImage = !isPublic || this.state.newFeaturedImage || pathwayMetadata.imageUrl;

        if (hasTitle && hasTags && hasDescript && hasSubject && hasBackground && hasContent && hasFeaturedImage) {
            return true;
        } else {
            return false;
        }
    }

    @bind private saveDraft() {
        // TODO: Currently doesn't do anything.
    }

    /**
     * Delete the pathway and redirect to the dashboard.
     */
    @bind private async deletePathway() {
        const pathwayMetadata = this.state.pathwayMetadata;
        if (pathwayMetadata === undefined) {
            throw new Error('Cannot deletePathway() when metadata not loaded');
        }
        const pathwayId = this.props.match.params.pathwayKey;
        if (pathwayId) {
            // If editing an existing pathway, delete it:
            try {
                await ItemsApi._delete({id: pathwayId});
            } catch (err) {
                showErrorMessage(<>Unable to delete pathway. You might not have permission.</>, {exception: err});
                return;
            }
        }
        this.setState({redirectTo: ROUTES.Dashboard.HOME});
    }

    @bind private onAgreementsCheckedUpdate(checked: boolean) {
        this.setState({
            agreementsChecked: checked,
            unsavedChanges: true,
        });
    }

    /**
     * Perform the save and publish work. This is used by the 'publish' and
     * 'save and continue' actions.
     */
    private async doPublishPathway(force: boolean = false): Promise<string|undefined> {
        const newData = this.state.pathwayMetadata;
        if (newData === undefined || this.state.pathwayDetails === undefined) {
            throw new Error('Cannot publishPathway() when metadata not loaded');
        }

        if (!this.okayToPublish() && !force) {
            this.setState({
                showErrors: true,
            });
            return;
        }

        let pathwayId = this.props.match.params.pathwayKey;
        if (!this.state.unsavedChanges && pathwayId) { return pathwayId; }

        this.setState({ saveButtonDisabled: true });
        let result: ItemResponse;
        const data: ItemMetadataUpdate = {
            title: newData.title,
            authors: newData.authors.filter((x: Author) => (x.username || x.fullName)),
            backgroundKnowledge: newData.backgroundKnowledge as unknown as ItemMetadataUpdateBackgroundKnowledgeEnum,
            description: newData.description,
            language: newData.language,
            // Must filter learningObjectives, because the editor can have partially completed items.
            learningObjectives: newData.learningObjectives.filter(x => x.description),
            subjectTags: newData.subjectTags,
            freeFormTags: newData.freeFormTags,
            license: newData.license as unknown as ItemMetadataUpdateLicenseEnum,
            type: ItemMetadataUpdateTypeEnum.Pathway,
            isPublic: newData.isPublic,
            sendPiiMetadata: false,
            notifyUpdate: false,
            resources: newData.resources,
        };
        const childrenData = this.state.pathwayDetails!.items.map((child) => {
            const {item, ...child_metadata} = child;
            return {id: child.item.metadata.id, ...child_metadata};
        });
        if (!pathwayId) {
            // Create a new pathway:
            try {
                const createResult = await ItemsApi.create({data: {
                    type: ItemCreationTypeEnum.Pathway,
                    title: data.title || 'New Pathway',
                    sourceId: newData.source ? parseInt(newData.source.id, 10) : undefined,
                }});
                pathwayId = createResult.metadata.id;
            } catch (err) {
                showErrorMessage('Unable to create pathway.', {exception: err});
                this.setState({ saveButtonDisabled: false });
                throw err;
            }
        }
        // Update pathway details
        const pathwayNewData: PathwayUpdate = {
            items: childrenData,
            image: this.state.pathwayDetails.image,
        };
        try {
            await ItemsApi.pathwayUpdate({id: pathwayId, data: pathwayNewData});
            // Update featured image
            if (this.state.newFeaturedImage) {
                await ItemsApi.imageCreate({id: pathwayId, image: this.state.newFeaturedImage});
            }
            // And update all the other fields:
            result = await ItemsApi.partialUpdate({id: pathwayId, data});
        } catch (err) {
            let errorMessage = err.statusText;
            if (err.status === 400) {
                errorMessage = (await err.json())[0];
            }
            showErrorMessage(`Some changes could not be saved. The error is "${errorMessage}"`, {exception: err});
            this.setState({ saveButtonDisabled: false });
            throw err;
        }

        // successfully saved, tidy up
        this.setState({
            saveButtonDisabled: false,
            unsavedChanges: false,
        });
        checkLoginStatus(getStore().dispatch);
        return result.metadata.id as string;
    }

    /**
     * Called on 'save and continue editing' action.
     *
     * This should save the pathway (same as publish) with the current data,
     * and then reopen the edit page for the pathway. If this was a new
     * pathway, the url will change.
     */
    @bind private async onPublishAndContinue(): Promise<boolean> {
        const id = await this.doPublishPathway();
        // We only get an id if it saved successfully.
        if (id) {
            // If it saved successfully, and we're currently editing a new pathway,
            // then redirect to the edit page for the newly saved pathway.
            if (this.isNewPathway()) {
                this.setState({
                    pathwayMetadata: update(this.state.pathwayMetadata!, {id: {$set: id || ''}}),
                });
                // directly replace in history because we don't want to get
                // wound up in recursive redirects, etc. This also plays nice
                // with history back/forward.
                window.history.replaceState(null, '', ROUTES.Library.PATHWAY_EDIT_SLUG(id));
            }
            return true;
        }
        return false;
    }

    /**
     * Publish the pathway and redirect to the newly published pathway.
     *
     * "Publish" means to save in the database. It is not automatically public.
     */
    @bind private async publishPathway() {
        const id = await this.doPublishPathway();
        if (id) {
            this.setState({redirectTo: ROUTES.Library.PATHWAY_SLUG(id)});
        }
    }

    private shouldShowPublicToggle(): boolean {
        const userPermissions = this.props.userPermissions;

        if (this.isNewPathway() || this.state.pathwayUserAttributes === undefined) {
            if (userPermissions && userPermissions.library.canMakeItemPublic) {
                return true;
            }
        } else {
            return this.state.pathwayUserAttributes.showPublicToggle;
        }
        return false;
    }

    @bind private onAuthorsChange(authors: Author[]) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!, {authors: {$set: Array.from(authors)}}),
            unsavedChanges: true,
        });
    }

    @bind private onUpdateResources(resources: ResourceRelationship[]) {
        this.setState({
            pathwayMetadata: update(this.state.pathwayMetadata!,
                {resources: {$set: Array.from(resources)}},
            )
        });
    }

    /**
     * If we're creating a pathway for an organization, we need to set the
     * "content source" field to the default ContentSource of the organization.
     */
    private async loadContentSource(orgId: string) {
        let contentSources;
        try {
            contentSources = await OrganizationsApi.contentSources({id: orgId});
            if (contentSources.length === 0) { throw new Error('No content sources returned - shouldn\'t happen.'); }
        } catch (err) {
            showErrorMessage(
                'Unable to link this pathway to the selected organization. ' +
                'It will be saved to your educator dashboard instead.',
                {exception: err},
            );
            return;
        }
        let contentSource;
        // Need to find the "main" content source which is the one that has no title set
        // (or, might have a title that matches the org name, though blank title is better)
        if (contentSources.length > 1) {
            for (const cs of contentSources) {
                const sourceOrg = cs.sourceOrganizations && cs.sourceOrganizations[0].organization;
                if (sourceOrg) {
                    if (cs.title === '') {
                        contentSource = cs;
                        break;
                    } else if (cs.title === sourceOrg.name && contentSource === undefined) {
                        contentSource = cs;
                    }
                }
            }
        }
        if (contentSource === undefined) {
            contentSource = contentSources[0]; // Just pick the first one.
        }
        this.setState({pathwayMetadata: update(this.state.pathwayMetadata!,
            {source: {$set: contentSource}},
        )});
    }
}

export const PathwayEditPage = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        loggedInUserFullName: getUserFullName(state),
        userPermissions: getUserPermissions(state),
    }),
)(PathwayEditPageInternal);
