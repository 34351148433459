import { defineMessages } from 'react-intl';

const messages = defineMessages({
    canceledByMenteeMessageMentor: {
        id:'canceledByMenteeMessageMentor',
        defaultMessage: `{fullName} has canceled the request.`,
        description: 'Message to mentor when a mentorship is canceled by mentee.',
    },
    canceledByMenteeMessageMentee: {
        id:'canceledByMenteeMessageMentee',
        defaultMessage: `You canceled this mentorship request.`,
        description: 'Message to mentee when a mentorship is canceled by mentee.',
    },
    acceptedMessageMentor: {
        id:'acceptedMessageMentor',
        defaultMessage: 'You accepted this mentorship request',
        description: 'Message to mentor when a mentorship is accepted.',
    },
    acceptedMessageMentee: {
        id:'acceptedMessageMentee',
        defaultMessage: '{fullName} is now your mentor. Write a message to say hello!',
        description: 'Message to mentee when a mentorship is accepted.',
    },
    refusedMessageMentee: {
        id:'refusedMessageMentee',
        defaultMessage: '{fullName} will not be able to mentor you at the moment.',
        description: 'Message to mentee when a mentorship is refused.',
    },
    refusedMessageMentor: {
        id:'refusedMessageMentor',
        defaultMessage: `You cancelled this mentorship request`,
        description: 'Message to mentor when a mentorship is refused.',
    },
    stoppedByMenteeMessageMentor: {
        id:'stoppedByMenteeMessageMentor',
        defaultMessage: `{fullName} withdrew to be your mentee. Reason: ”{reason}”`,
        description: 'Message to mentor when a mentorship is stopped by mentee.',
    },
    stoppedByMentorMessageMentee: {
        id:'stoppedByMentorMessageMentee',
        defaultMessage: `{fullName} withdrew to be your mentor. Reason: ”{reason}”`,
        description: 'Message to mentor when a mentorship is stopped by mentoee.',
    },
    stoppedByMenteeMessageMentee: {
        id:'stoppedByMenteeMessageMentee',
        defaultMessage: `You withdrew from {fullName}'s mentorship. Reason: ”{reason}”.`,
        description: 'Message to mentee when a mentorship is stopped by mentee.',
    },
    stoppedByMentorMessageMentor: {
        id:'stoppedByMentorMessageMentor',
        defaultMessage: `You withdrew from {fullName}'s mentorship. Reason: ”{reason}”.`,
        description: 'Message to mentor when a mentorship is stopped by mentor.',
    },
});

export default messages;
