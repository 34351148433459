import { bind } from 'bind-decorator';
import classNames from 'classnames';
import { MentorshipsApi } from 'global/api';
import { Mentorship, MentorshipStatusEnum } from 'labxchange-client';
import * as React from 'react';
import { Modal } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface Props {
    mentorship: Mentorship;
    onClose: () => void;
    onSuccess: (mentorshio: Mentorship) => void;
    onError: () => void;
    byMentor: boolean;
}

interface State {
    loading: boolean;
    reason?: string;
}

export class StopMentorshipModal extends React.PureComponent<Props, State> {
    public formRef: React.RefObject<HTMLFormElement>;

    constructor(props: Props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: false,
            reason: undefined,
        };
    }

    public render() {
        return (
            <Modal
                className='mentorship-cancel-modal'
                onRequestClose={this.props.onClose}
                content={
                    <>
                        <div className='warning-icon'></div>
                        <div className='title-text'>
                            {this.props.byMentor ?
                            <WrappedMessage message={messages.cancelByMentorTitle}
                                            values={{fullName: this.props.mentorship.mentee.fullName}}/>
                            :
                            <WrappedMessage message={messages.cancelByMenteeTitle}
                                            values={{fullName: this.props.mentorship.mentor.fullName}}/>
                            }
                        </div>
                        <form id='stop-mentorship-form' onSubmit={this.onSubmit} ref={this.formRef}>
                            <div className={classNames('form-group', 'message')}>
                                <label>
                                    {this.props.byMentor ?
                                    <WrappedMessage message={messages.messageByMentorLabel}
                                                    values={{fullName: this.props.mentorship.mentee.fullName}}/>
                                    :
                                    <WrappedMessage message={messages.messageByMenteeLabel}
                                                    values={{fullName: this.props.mentorship.mentor.fullName}}/>
                                    }
                                </label>
                                <textarea className={classNames(
                                                'form-control',
                                                {'is-invalid': this.state.reason === ''})}
                                        rows={8}
                                        onBlur={this.handleReasonBlur}>
                                </textarea>
                                <div className='invalid-feedback'>
                                    <WrappedMessage message={messages.requiredFieldErrorMessage}/>
                                </div>
                            </div>
                        </form>
                    </>
                }
                footer={
                    <>
                        <button className={classNames('btn', 'btn-link')} onClick={this.props.onClose}>
                            <WrappedMessage message={messages.cancelButton}/>
                        </button>
                        <button type='submit' form='stop-mentorship-form'
                                className={classNames('btn', 'primary-button')}
                                disabled={this.state.loading} aria-disabled={this.state.loading}
                        >
                        {this.props.byMentor ?
                            <WrappedMessage message={messages.submitByMentorButton}/>
                            :
                            <WrappedMessage message={messages.submitByMenteeButton}/>
                        }
                        </button>
                    </>
                }
            />
        );
    }

    @bind private handleReasonBlur(event: React.FormEvent<HTMLTextAreaElement>) {
        this.setState({reason: event.currentTarget.value});
    }

    private setFormErrors() {
        if (this.formRef.current) {
            this.formRef.current.checkValidity();
        }
    }

    private isValid(): boolean {
        if (this.state.reason === undefined || !this.state.reason) {
            this.setState({reason: ''});
            this.setFormErrors();
            return false;
        }
        return true;
    }

    @bind private async onSubmit(event: React.FormEvent<HTMLFormElement>) {
        this.setState({loading: true});
        event.preventDefault();
        if (!this.isValid()) {
            this.setState({loading: false});
            return;
        }
        let status = MentorshipStatusEnum.StoppedByMentee;

        if (this.props.byMentor) {
            status = MentorshipStatusEnum.StoppedByMentor;
        }
        try {
            const mentorship = await MentorshipsApi.partialUpdate({
                id: this.props.mentorship.id,
                data: {status, stop_reason: this.state.reason},
            });

            this.setState({loading: false});

            if (mentorship.status === status) {
                this.props.onSuccess(mentorship);
            } else {
                this.setFormErrors();
                this.props.onError();
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
            this.setState({loading: false});
            this.setFormErrors();
            this.props.onError();
        }
    }
}
