import { defineMessages } from 'react-intl';

const messages = defineMessages({
    languageSelectLabel: {
        id:'languageSelectLabel',
        defaultMessage: 'Language',
        description: 'Label for the language select dropdown',
    },
    languageSelectPrompt: {
        id:'languageSelectPrompt',
        defaultMessage: '- Select language -',
        description: 'Prompt text for the language select dropdown',
    },
    languageSelectTitle: {
        id: 'languageSelectTitle',
        defaultMessage: 'Select language',
        description: 'Modal text for title'
    },
    languageSelectConfirm: {
        id: 'languageSelectConfirm',
        defaultMessage: 'Set language',
        description: 'Modal text for confirm'
    },
    languageSelectCancel: {
        id: 'languageSelectCancel',
        defaultMessage: 'close',
        description: 'Modal text for cancel'
    },
    changeLanguage: {
        id: 'changeLanguage',
        defaultMessage: 'change',
        description: 'Text for account settings change button'
    },
    changeLanguageIcon: {
        id: 'changeLanguageIcon',
        defaultMessage: 'Change language',
        description: 'Text for language switch button'
    }
});

export default messages;
