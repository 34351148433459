import { ItemsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import update from 'immutability-helper';
import { ItemMetadata, XBlockOLX } from 'labxchange-client';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { StandardPageLayout } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';

interface Props {
    readonly itemMetadata: ItemMetadata;
    // The OLX file(s) that contain the source for this item's XBlock(s)
    originalItemOlx: ReadonlyArray<XBlockOLX>;
}

interface State {
    updatedOLX: ReadonlyArray<XBlockOLX>;
    doneEditing: boolean;
}

/**
 * A page for editing an item's OLX.
 * This is only meant for use by admins and developers, not for end users.
 */
export class ItemEditOlxPage extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            updatedOLX: this.props.originalItemOlx,
            doneEditing: false,
        };
    }

    public render() {
        if (this.state.doneEditing) {
            return <Redirect to={ROUTES.Library.ITEM_SLUG(this.props.itemMetadata.id)} />;
        }

        return <StandardPageLayout
            otherClassName={'item-edit-olx-page'}
            headerFeature={<h1>OLX Editor: {this.props.itemMetadata.title}</h1>}
        >
            {
                this.state.updatedOLX.map((entry, idx) => (
                    <div key={entry.blockId}>
                        <h2>{entry.blockId}</h2>
                        <textarea
                            className={`olx`}
                            value={entry.blockOlx}
                            onChange={(ev) => { this.handleOlxChanged(idx, ev.target.value); }}
                        />
                    </div>
                ))
            }
            <button className='btn btn-primary' onClick={this.handleSave}>Save OLX</button>
        </StandardPageLayout>;
    }

    private handleOlxChanged(index: number, newOlx: string) {
        this.setState({
            updatedOLX: update(this.state.updatedOLX, {
                [index]: {blockOlx: {$set: newOlx}},
            }),
        });
    }

    private handleSave = async () => {
        try {
            await ItemsApi.blocksOlxPartialUpdate({
                id: this.props.itemMetadata.id,
                data: this.state.updatedOLX.slice(),
            });
            this.setState({doneEditing: true});
        } catch (err) {
            showErrorMessage(<>Unable to save OLX - check for errors</>, {exception: err});
        }
    }
}

interface WrapperProps extends RouteComponentProps<MatchProps> {
}

interface MatchProps {
    itemKey: string;
}

interface WrapperState {
    itemMetadata?: ItemMetadata;
    itemOlx?: XBlockOLX[];
}

/**
 * Wrapper for the item OLX Editor page.
 * Loads necessary data from the LabXchange UI and then renders the edit page.
 */
export class ItemEditOlxPageWrapper extends React.PureComponent<WrapperProps, WrapperState> {

    constructor(props: WrapperProps) {
        super(props);
        this.state = {
        };
    }

    public render() {
        if (this.state.itemMetadata && this.state.itemOlx) {
            return <ItemEditOlxPage
                itemMetadata={this.state.itemMetadata}
                originalItemOlx={this.state.itemOlx}
            />;
        } else {
            return <StandardPageLayout headerFeature={<h1>OLX Editor</h1>}>
                <p>Loading OLX Editor...</p>
            </StandardPageLayout>;
        }
    }

    public async componentDidMount() {
        try {
            // Load the item metadata and the OLX from the API
            const [itemResponse, itemOlx] = await Promise.all([
                ItemsApi.read({id: this.props.match.params.itemKey}),
                ItemsApi.blocksOlxRead({id: this.props.match.params.itemKey}),
            ]);
            this.setState({
                itemMetadata: itemResponse.metadata,
                itemOlx,
            });
        } catch (err) {
            showErrorMessage(<>Unable to load OLX</>, {exception: err});
            return;
        }
    }
}
