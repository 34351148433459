import { getCookie } from 'utils';
import moment from 'moment-shortformat';
import { createIntl, createIntlCache } from 'react-intl';

import aRaEmessages from './compiled-translations/displayMessages.ar.json';
import deMessages from './compiled-translations/displayMessages.de.json';
import es419Messages from './compiled-translations/displayMessages.es.json';
import frMessages from './compiled-translations/displayMessages.fr.json';
import itMessages from './compiled-translations/displayMessages.it.json';
import jaMessages from './compiled-translations/displayMessages.ja.json';
import koMessages from './compiled-translations/displayMessages.ko.json';
import nlMessages from './compiled-translations/displayMessages.nl.json';
import ptBrMessages from './compiled-translations/displayMessages.pt.json';
import trTrMessages from './compiled-translations/displayMessages.tr.json';
import zhHansMessages from './compiled-translations/displayMessages.zh.json';
import plMessages from './compiled-translations/displayMessages.pl.json';
import zuMessages from './compiled-translations/displayMessages.zu.json';
import ukMessages from './compiled-translations/displayMessages.uk.json';
import viMessages from './compiled-translations/displayMessages.vi.json';
import azMessages from './compiled-translations/displayMessages.az.json';
import roMessages from './compiled-translations/displayMessages.ro.json';
import kaMessages from './compiled-translations/displayMessages.ka.json';
// import ruMessages from './compiled-translations/displayMessages.ru.json';


export const LANGUAGE_COOKIE_NAME = 'labxchange_language';

// This is the canonical list of language codes (and associated data)
// that we use on the frontend for locales.
// NOTE: this should be kept in sync with LANGUAGES in backend/labxchange/settings/base.py
export const UILanguages = {
    'en': {
        label: 'English',
        nativeName: 'English',
        messages: null,
    },
    'ar-ae': {
        label: 'Arabic',
        nativeName: 'العربية',
        messages: aRaEmessages,
    },
    'zh-hans': {
        label: 'Chinese (Simplified)',
        nativeName: '中文 (简体)',
        messages: zhHansMessages,
    },
    'nl': {
        label: 'Dutch',
        nativeName: 'Nederlands',
        messages: nlMessages,
    },
    'fr': {
        label: 'French',
        nativeName: 'Français',
        messages: frMessages,
    },
    'de': {
        label: 'German',
        nativeName: 'Deutsch',
        messages: deMessages,
    },
    'it': {
        label: 'Italian',
        nativeName: 'Italiano',
        messages: itMessages,
    },
    'ja': {
        label: 'Japanese',
        nativeName: '日本語',
        messages: jaMessages,
    },
    'ka': {
        label: 'Georgian',
        nativeName: 'ქართული',
        messages: kaMessages,
    },
    'ko': {
        label: 'Korean',
        nativeName: '한국어',
        messages: koMessages,
    },
    'pt-br': {
        label: 'Portuguese (Brazil)',
        nativeName: 'Português',
        messages: ptBrMessages,
    },
    'es-419': {
        label: 'Spanish (Latin America)',
        nativeName: 'Español',
        messages: es419Messages,
    },
    'tr-tr': {
        label: 'Turkish',
        nativeName: 'Türkçe',
        messages: trTrMessages,
    },
    'pl': {
        label: 'Polish',
        nativeName: 'Polski',
        messages: plMessages,
    },
    'zu': {
        label: 'Zulu',
        nativeName: 'isiZulu',
        messages: zuMessages,
    },
    'uk': {
        label: 'Ukrainian',
        nativeName: 'Yкраїнська',
        messages: ukMessages,
    },
    'vi': {
        label: 'Vietnamese',
        nativeName: 'Tiếng Việt',
        messages: viMessages,
    },
    'az': {
        label: 'Azerbaijani',
        nativeName: 'Azərbaycan',
        messages: azMessages,
    },
    'ro': {
        label: 'Romanian',
        nativeName: 'limba română',
        messages: roMessages,
    },
    /// Uncomment this and the imports after you have enough ru translations
    /// Don't forget to uncomment the values in 'global/locales-constants'
    /*
    'ru': {
        label: 'Russian',
        nativeName: 'Pусский',
        messages: ruMessages,
    },
    */
};

export interface UILanguage {
    key: keyof typeof UILanguages;
    label: string;
    nativeName: string;
    messages: any;
}

export const DEFAULT_UI_LANGUAGE: UILanguage = {
    key: 'en',
    label: 'English',
    nativeName: 'English',
    messages: null,
};

export const UILangCodes = Object.keys(UILanguages) as (keyof typeof UILanguages)[];

export const setLanguageCookie = (value: string) => {
    const languageCookie = `${LANGUAGE_COOKIE_NAME}=${value}; ` +
        `path=/; domain=${window.location.hostname}`;
    document.cookie = languageCookie;
};

/**
 * Extract general language code from UILangCodes dialects
 * example: ar-ae -> ar.
 * This is used in the heuristics to select the closest supported language based on the browser language.
 */
function getMainLanguageCode(code: string) {
    // Replace underscore with dash (en_US -> en-US, ar_ae -> ar-ae)
    const languageCode = code.replace('_', '-');
    return languageCode.split('-')[0].toLowerCase();
}

/**
 * This tries to get a supported language code, given a list of random language codes.
 * Used as a heuristic to pick a language based on the browser language.
 * Can also be used in other places when you have an unknown/untrusted language code,
 * and you need to get the 'closest' supported language if possible.
 */
export function parseSupportedLanguageCode(languageCodes: readonly string[]): (keyof typeof UILanguages)|null {
    for (const code of languageCodes) {
        if (UILangCodes.includes(code as any)) {
            return code as keyof typeof UILanguages;
        }
        for (const supportedLanguage of UILangCodes) {
            if (getMainLanguageCode(code) === getMainLanguageCode(supportedLanguage)) {
                return supportedLanguage;
            }
        }
    }
    return null;
}

/**
 * Find supported browser preferred language
 */
function getBrowserPreferredLanguage() {
    // Get first supported language from browsers prefrered
    const browserLanguage = parseSupportedLanguageCode(navigator.languages);
    // Try to use browser UI languages if list of preferred language is empty
    const browserUILanguage = parseSupportedLanguageCode([navigator.language]);
    return browserLanguage || browserUILanguage;
}

/**
 * This returns the language to be used for the current locale.
 * Takes into account the browser language and the user's saved locale.
 */
export const getSavedLanguage = () => {
    // Language set using the selector (and saved in the cookie) has priority over browser language.
    const lng = getCookie(LANGUAGE_COOKIE_NAME) || getBrowserPreferredLanguage();
    // If the language cookie isn't set, set it to the browser's default language.
    // This ensures that the backend uses the user language during registration
    // and while retrieving messages/data.
    if (!getCookie(LANGUAGE_COOKIE_NAME) && lng) {
        setLanguageCookie(lng);
    }
    return lng ? lng.toLocaleLowerCase() : DEFAULT_UI_LANGUAGE.key;
};

/**
 * This is for getting the native name of a maybe supported language.
 * Only use this if there's a possibility that the languageCode isn't one we support.
 */
export function getLanguageNativeName(languageCode: string): string {
    if (UILangCodes.includes(languageCode as any)) {
        return UILanguages[languageCode as keyof typeof UILanguages].nativeName;
    }
    return 'Unknown';  // TODO: i18n for this string
}

export function handleTranslationError(err: any): void{
    // TODO: setup NewRelic monitoring of frontend
    const allowedErrors = ['MISSING_TRANSLATION', 'FORMAT_ERROR'];
    if (allowedErrors.includes(err.code)) {
      return;
    }
    /// Here we use console.error() instead throw() because
    /// with throw() if there is an error the page gets stuck on loading

    // tslint:disable-next-line: no-console
    console.error(err);
}

/**
 * Sets <HTML> element classes to switch font
 * stack based on active language.
 */
export const selectFontStackForLanguage = () => {
    const language = getSavedLanguage();

    // Options from `src/assets/scss/_fonts.scss`
    let languageClass: 'font-face-ar' | 'font-face-cs' | 'font-face-ea' | 'font-face-vi' | 'font-face-az' | '';

    switch (language) {
        case 'ar-ae':
            languageClass = 'font-face-ar';
            break;
        case 'uk':
            languageClass = 'font-face-cs';
            break;
        case 'vi':
            languageClass = 'font-face-vi';
            break;
        case 'zh-hans':
        case 'ja':
        case 'ko':
            languageClass = 'font-face-ea';
            break;
        case 'az':
            languageClass = 'font-face-az';
            break;
        default:
            languageClass = '';
    }

    // Set class of the main <HTML> element to the language
    // class, so that <language-selector> body applies fonts
    // globally.
    document.documentElement.className = languageClass;
};

const getLocale: () => UILanguage = () => {
    const preferredLocale = getSavedLanguage();

    for (const key of UILangCodes) {
        if (preferredLocale.startsWith(key) || preferredLocale === key) {
            const lang = UILanguages[key];
            return {
                ...lang,
                key,
            };
        }
    }
    return DEFAULT_UI_LANGUAGE;
};

export const localeInfo: UILanguage = getLocale();
export const locale: keyof typeof UILanguages = localeInfo.key;
export const localeMessages: any = localeInfo.messages;

moment.locale(locale);

// Use this intl object wherever you need i18n in a situation where you can't use WrappedMessage or FormattedMessage.
// (eg. outside of react components, as a child to `<option>`, etc.)
export const intl = createIntl(
    {
        locale,
        messages: localeMessages,
        onError: handleTranslationError,
    },
    createIntlCache()
);
