// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomItemCreate
 */
export interface ClassroomItemCreate {
    /**
     * 
     * @type {string}
     * @memberof ClassroomItemCreate
     */
    classroom: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomItemCreate
     */
    item: string;
}

export function ClassroomItemCreateFromJSON(json: any): ClassroomItemCreate {
    return {
        'classroom': json['classroom'],
        'item': json['item'],
    };
}

export function ClassroomItemCreateToJSON(value?: ClassroomItemCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'classroom': value.classroom,
        'item': value.item,
    };
}


