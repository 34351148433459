import bind from 'bind-decorator';
import * as React from 'react';

/** Props used by both <CheckBoxOnly> and <CheckBox> */
interface CheckBoxProps {
    /**
     * Is this checkbox checked?
     * NULL is accepted and means that the checkbox is in an "indeterminate" state.
     */
    checked: boolean|null;
    disabled: boolean;
    /**
     * Handle a change event coming from this control.
     * This handler will not be called if something
     * else on the page causes the 'checked' prop to
     * change.
     * Note that users can never set a checkbox to the
     * "interminate" state by clicking on it, so this
     * event only ever sets it to checked or unchecked.
     */
    onClick: (newValue: boolean) => void;
    otherProps: React.HTMLProps<HTMLInputElement>;
    children?: React.ReactNode;
    /**
     * The label for this checkbox. Used only for screen readers.
     * Only needed if the children don't act as the label.
     */
    altLabel?: string;
    isError: boolean;
}

/**
 * An accessible checkbox component that looks like a hexagon,
 * and supports checked, unchecked, indeterminate, and disabled
 * states.
 * Any child elements passed as props will be rendered as the label.
 * If no label is passed in as child element(s), the 'altLabel'
 * property should be set.
 */
export class CheckBox extends React.PureComponent<CheckBoxProps> {

    public static defaultProps = {
        disabled: false,
        otherProps: {},
        showDescription: true,
        isError: false,
    };

    public render() {
        return <div
            role='checkbox'
            aria-checked={
                this.props.checked === null ? 'mixed' :
                this.props.checked === true ? 'true' :
                'false'
            }
            aria-disabled={this.props.disabled}
            className={
                `lx-check-box ` +
                `${!this.props.children ? 'checkbox-no-label ' : ''}` +
                `${this.props.isError ? 'lx-check-box-error ' : ''}`}
            onClick={this.handleClick}
            onKeyDown={this.onKeyDown}
            tabIndex={0}
            {...(this.props.altLabel ? {'aria-label': this.props.altLabel} : {})}
            {...this.props.otherProps}
        >
            <span className='checkbox-icon'></span>
            {this.props.children}
        </div>;
    }

    @bind private handleClick(): void {
        if (this.props.disabled) {
            return;
        }
        const newValue: boolean = !this.props.checked; // Note that NULL (indeterminate) becomes True
        this.props.onClick(newValue);
    }

    @bind private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === ' ') {
            event.preventDefault();
            this.handleClick();
        }
    }
}
