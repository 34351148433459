import { RefObject } from 'react';

/**
 * Fires callback when a click is detected outside component.
 *
 * @param callback Function that is called when a click
 *                 outside the component this is placed on
 *                 is detected.
 */
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void,
): void {
    document.addEventListener('mouseup', event => {
        const el = ref?.current;

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) {
            return;
        }

        // Call callback if click outside was detected.
        handler();
    });
}
