// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerOutlookFact
 */
export interface CareerOutlookFact {
    /**
     * HTML formatted text describing the demand fact details.
     * @type {string}
     * @memberof CareerOutlookFact
     */
    details: string;
    /**
     * HTML formatted text describing the demand fact source text.
     * @type {string}
     * @memberof CareerOutlookFact
     */
    sourceText: string;
    /**
     * Image for demand fact card.
     * @type {string}
     * @memberof CareerOutlookFact
     */
    readonly factImage?: string;
}

export function CareerOutlookFactFromJSON(json: any): CareerOutlookFact {
    return {
        'details': json['details'],
        'sourceText': json['source_text'],
        'factImage': !exists(json, 'fact_image') ? undefined : json['fact_image'],
    };
}

export function CareerOutlookFactToJSON(value?: CareerOutlookFact): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'details': value.details,
        'source_text': value.sourceText,
    };
}


