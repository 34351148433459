import * as React from 'react';

import { Organization } from 'labxchange-client';
import { Icon } from 'ui/components';
import { intl } from 'i18n';
import messages from './displayMessages';
import { AnalyticsApi } from 'global/api';
import { AnalyticsEventEventTypeEnum } from 'labxchange-client';

interface Props {
    organization: Organization;
    showAll?: boolean;
    showFacebook?: boolean;
    showTwitter?: boolean;
    showInstagram?: boolean;
    showLinkedin?: boolean;
    showAcademia?: boolean;
    showPinterest?: boolean;
    showYoutube?: boolean;
    showWebsite?: boolean;
}

export class OrgSocialLinks extends React.PureComponent<Props> {

    public render() {
        const props = this.props;
        const profile = props.organization;
        const showAll = props.showAll;
        return <div className='org-social-links'>
            { (showAll || props.showFacebook) && profile.facebookUrl &&
                <a title={intl.formatMessage(messages.facebookIcon)} className='icon rounded-circle'
                    href={profile.facebookUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='facebook'/>
                </a>
            }
            { (showAll || props.showTwitter) && profile.twitterUrl &&
                <a title={intl.formatMessage(messages.twitterIcon)} className='icon rounded-circle'
                    href={profile.twitterUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='twitter'/>
                </a>
            }
            { (showAll || props.showInstagram) && profile.instagramUrl &&
                <a title={intl.formatMessage(messages.instagramIcon)} className='icon rounded-circle'
                    href={profile.instagramUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='instagram'/>
                </a>
            }
            { (showAll || props.showLinkedin) && profile.linkedinUrl &&
                <a title={intl.formatMessage(messages.linkedinIcon)} className='icon rounded-circle'
                    href={profile.linkedinUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='linkedin'/>
                </a>
            }
            { (showAll || props.showAcademia) && profile.academiaUrl &&
                <a title={intl.formatMessage(messages.academiaIcon)} className='icon rounded-circle'
                    href={profile.academiaUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='academia'/>
                </a>
            }
            { (showAll || props.showPinterest) && profile.pinterestUrl &&
                <a title={intl.formatMessage(messages.pinterestIcon)} className='icon rounded-circle'
                    href={profile.pinterestUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='pinterest'/>
                </a>
            }
            { (showAll || props.showYoutube) && profile.youtubeUrl &&
                <a title={intl.formatMessage(messages.youtubeIcon)} className='icon rounded-circle'
                    href={profile.youtubeUrl} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitSocialMedia(profile.slug)}
                >
                    <Icon name='youtube'/>
                </a>
            }
            { (showAll || props.showWebsite) && profile.url &&
                <a title={intl.formatMessage(messages.websiteIcon)} className='icon rounded-circle'
                    href={profile.url} target='_blank' rel='noopener noreferrer'
                    onClick={() => this.onVisitWebsite(profile.slug)}
                >
                    <Icon name='website-link'/>
                </a>
            }
        </div>;
    }

    private onVisitWebsite(slug: string) {
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.OrganizationWebsiteClicked,
                orgSlug: slug,
                data: {
                    from: 'organization_page',
                    pathname: window.location.pathname,
                },
            },
        });
    }

    private onVisitSocialMedia(slug: string) {
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.OrganizationSocialMediaClicked,
                orgSlug: slug,
                data: {
                    from: 'organization_page',
                    pathname: window.location.pathname,
                },
            },
        });
    }

}
