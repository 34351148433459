import * as React from 'react';

type MoveOption = 'left'|'up'|'down'|'right';

interface Props {
    onMoveClick: (option: MoveOption) => void;
    onZoomClick: (direction: 'up'|'down') => void;
    onZoomChange: (event: any) => void;
    zoomValue: number;
}

const moveOptions: MoveOption[] = [
    'left',
    'up',
    'down',
    'right',
];

const zoomOptions = [
    {value: 0.25, label: '25%'},
    {value: 0.5, label: '50%'},
    {value: 0.75, label: '75%'},
    {value: 1, label: '100%'},
    {value: 1.25,label: '125%'},
    {value: 1.5, label: '150%'},
    {value: 1.75, label: '175%'},
    {value: 2, label: '200%'},
];

export const ZoomControls: React.FC<Props> = ({onMoveClick, onZoomClick, onZoomChange, zoomValue}) => (
    <div className='theater-zoom-controls'>
        <div className='move-controls-box controls-box'>
            {moveOptions.map(option => (
                <button
                    className={`theater-control-${option}`}
                    key={option}
                    onClick={() => onMoveClick(option)}
                >
                    <i className={`fa fa-angle-${option}`}/>
                </button>
            ))}
        </div>
        <div className='zoom-controls-box controls-box'>
            <button
                className='theater-control-left'
                onClick={() => onZoomClick('down')}
            >
                <div className='zoom-icon'>
                    <span className='zoom-icon-inner'/>
                </div>
            </button>
            <div className='theater-zoom-dropdown'>
                <select
                    onChange={onZoomChange}
                    onBlur={onZoomChange}
                    value={zoomValue}
                >
                    {zoomOptions.map((option, i) => (
                      <option value={option.value} key={i}> {option.label} </option>
                    ))}
                </select>
            </div>
            <button
                className='theater-control-right'
                onClick={() => onZoomClick('up')}
            >
                <div className='zoom-icon zoom-icon-plus'>
                    <span className='zoom-icon-inner'/>
                </div>
            </button>
        </div>
    </div>
);
