import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { EVENT_NAMES } from 'tracking/constants';
import { analyticsInstance } from 'tracking';

interface DynamicContentProps {
    topics: { name: string; url?: string }[];
    browseTopicTitle?: string;
    browseTopicLink?: string;
    subject: string;
    level: string;
}

const DynamicContentComponent: React.FC<DynamicContentProps> = ({browseTopicTitle, browseTopicLink, topics, subject, level}) => {

    return (
        <div>

            {topics.map((topic, index) => {
                if (topic.url === undefined || topic.url === '#') {
                    return (
                        <div title='Coming soon' className='w-inline-block'>
                            <div className='topic-text comming-soon-topic'>
                            {topic.name}</div>
                        </div>
                    );
                } else {
                    return (
                        <NavLink
                            key={index}
                            to={topic.url}
                            className='w-inline-block'
                            onClick={() => {
                                analyticsInstance.track(EVENT_NAMES.SubjectsPageTopicLinkClicked, {
                                    subject_text: subject,
                                    level_text: level,
                                    topic_text: topic.name,
                                    topic_link: topic.url,
                                    url: window.location.toString(),
                                });
                            }}
                        >
                            <div className='topic topic-text'>{topic.name}</div>
                        </NavLink>
                    );
                }})}
            {browseTopicLink && browseTopicTitle &&
            <NavLink
                to={browseTopicLink}
                className='button w-inline-block button-margin'
                data-testid='all-topics-button'
                onClick={() => {
                    analyticsInstance.track(EVENT_NAMES.SubjectsPageTopicAllClicked, {
                        subject_text: subject,
                        level_text: level,
                        'topics': topics,
                        'button_text': browseTopicTitle,
                        'button_link': browseTopicLink,
                        url: window.location.toString(),
                    });
                }}
            >
                <div className='button-text'>{browseTopicTitle}</div>
            </NavLink>
            }
        </div>
    );
};

export default DynamicContentComponent;
