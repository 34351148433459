/**
 * Components to show the preview and sidebar for composite assets like assignment and case study.
 */
import classNames from 'classnames';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import {
    ContainerOne,
    LinkButton,
} from 'elements';
import { NextButton } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import {BlockScoreState} from './models';
import { getItemTypeMeta, ItemType } from 'items/models';
import { intl } from 'i18n';

const toUptoTwoDecimalPlaces = (num: number) => {
    return (Math.round(num * 100) / 100).toString();
};

interface BlockOverviewProps {
    iconUrl: string;
    startButtonTitleDescriptor: MessageDescriptor;
    onStartButtonClick: () => void;
    outlineItems: BlockOverviewOutlineItem[];
    score?: BlockScoreState;
    theme: 'assignment'|'case-study';
}

/**
 * BlockOverview consists of a header with a start button and an outline of the asset contents.
 */
export const BlockOverview: React.FunctionComponent<BlockOverviewProps> = (props) => {
    return (
        <div className={'block-overview'}>
            <ContainerOne>
                <BlockOverviewHeader
                    iconUrl={props.iconUrl}
                    buttonTitleDescriptor={props.startButtonTitleDescriptor}
                    onButtonClick={props.onStartButtonClick}
                />
                <BlockOverviewOutline
                    items={props.outlineItems}
                    showItemScores={true}
                    theme={props.theme}
                    itemTheme='single-line'
                />
                { props.score &&
                    <div className={'block-overview-total-score'}>
                        <div className={'block-overview-total-score-inner'}>
                            <div className={'block-overview-total-score-label'}>
                                <WrappedMessage message={messages.blockOverviewTotalPointsLabel}/>
                            </div>
                            <div className={'block-overview-total-score-score'}>
                                <span>{toUptoTwoDecimalPlaces(props.score.earned)}</span>
                                <span>/</span>
                                <span>{toUptoTwoDecimalPlaces(props.score.possible)}</span>
                            </div>
                        </div>
                    </div>
                }
            </ContainerOne>
        </div>
    );
};

interface BlockOverviewSidebarProps {
    itemType: ItemType;
    iconUrl?: string; /// If is undefined, then it doesn't show the icon container
    backButtonTitleDescriptor: MessageDescriptor;
    onBackButtonClick: () => void;
    onItemClick: (index: number) => void;
    outlineItems: BlockOverviewOutlineItem[];
    score?: BlockScoreState;
    theme: 'assignment'|'case-study';
}

/**
 * BlockOverviewSidebar shows the content outline in the detailed asset view.
 */
export const BlockOverviewSidebar: React.FunctionComponent<BlockOverviewSidebarProps> = (props) => {
    return (
        <div className='block-overview-sidebar'>
            {props.iconUrl !== undefined &&
                <div className='block-overview-sidebar-icon-container'>
                    <img className='block-overview-sidebar-icon' src={props.iconUrl} alt='' />
                    <div className='block-overview-sidebar-asset-type'>
                        <div className='block-overview-sidebar-asset-type-name'>
                            <WrappedMessage message={getItemTypeMeta(props.itemType).name} />
                        </div>
                        {props.score &&
                            <div className='block-overview-sidebar-total-score'>
                                <WrappedMessage message={messages.blockOverviewPossiblePointsLabel} values={{
                                    earned: toUptoTwoDecimalPlaces(props.score.earned),
                                    possible: toUptoTwoDecimalPlaces(props.score.possible),
                                }}/>
                            </div>
                        }
                    </div>
                </div>
            }
            <BlockOverviewOutline
                items={props.outlineItems}
                showItemScores={true}
                onItemClick={props.onItemClick}
                theme={props.theme}
                itemTheme='double-line'
            />
            <div className={'block-overview-back'}>
                <LinkButton
                    title={'< ' + intl.formatMessage(props.backButtonTitleDescriptor)}
                    onClick={props.onBackButtonClick}
                />
            </div>
        </div>
    );
};

interface BlockOverviewHeaderProps {
    iconUrl: string;
    buttonTitleDescriptor: MessageDescriptor;
    onButtonClick: () => void;
}

/**
 * BlockOverviewHeader has the icon and a start button and shows on the top of the preview outline.
 */
const BlockOverviewHeader: React.FunctionComponent<BlockOverviewHeaderProps> = (props) => {
    return (
        <>
            <div className={'block-overview-header'}>
                <img className={'block-overview-header-icon'} src={props.iconUrl} alt='' />
                <NextButton
                    title={intl.formatMessage(props.buttonTitleDescriptor)}
                    onClick={(e) => { props.onButtonClick(); }}/>
            </div>
            <div className={'block-overview-header-narrow'}>
                <NextButton
                    title={intl.formatMessage(props.buttonTitleDescriptor)}
                    onClick={(e) => { props.onButtonClick(); }}/>
            </div>
        </>
    );
};

export interface BlockOverviewOutlineItem {
    id: string;
    title: string;
    selected: boolean;
    score?: BlockScoreState;
}

interface BlockOverviewOutlineProps {
    items: BlockOverviewOutlineItem[];
    showItemScores: boolean;
    onItemClick?: (index: number) => void;
    theme: 'assignment'|'case-study';
    itemTheme: 'single-line'|'double-line';
}

/**
 * BlockOverviewOutline lists all the child blocks of the composite asset.
 */
const BlockOverviewOutline: React.FunctionComponent<BlockOverviewOutlineProps> = (props) => {
    return (
        <div className={'block-overview-outline'}>
            <ol dir='auto'>
                {props.items.map((item, index) => {
                    return (
                        <li key={index.toString()} className={`theme-${props.itemTheme}`}>
                            <BlockOverviewOutlineItemComponent
                                item={item}
                                itemIndex={index}
                                showItemScores={props.showItemScores}
                                onItemClick={props.onItemClick}
                                theme={props.theme}
                                itemTheme={props.itemTheme}
                                />
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

interface BlockOverviewOutlineItemProps {
    item: BlockOverviewOutlineItem;
    itemIndex: number;
    showItemScores: boolean;
    onItemClick?: (index: number) => void;
    theme: 'assignment'|'case-study';
    itemTheme: 'single-line'|'double-line';
}

/**
 * BlockOverviewOutlineItemComponent is a list item in BlockOverviewOutline.
 */
export const BlockOverviewOutlineItemComponent: React.FunctionComponent<BlockOverviewOutlineItemProps> = (props) => {
    return (
        <>
            <div className='block-overview-outline-item-row'>
                <span className={classNames(
                    'block-overview-outline-item-selection-marker',
                    { 'block-overview-outline-item-selection-marker-enabled':  props.item.selected },
                )} />
                <button
                    className={`block-overview-outline-item-title-${props.theme}`}
                    disabled={ props.onItemClick === undefined }
                    onClick={() => {
                        if (props.onItemClick) { props.onItemClick(props.itemIndex); }
                    }}
                >
                    {(props.itemIndex + 1).toString()}.&nbsp;{props.item.title}
                </button>
            </div>
            {props.showItemScores && props.item.score &&
                <div className={'block-overview-outline-item-score'}>
                    <span>{toUptoTwoDecimalPlaces(props.item.score.earned)}</span>
                    <span>/</span>
                    <span>{toUptoTwoDecimalPlaces(props.item.score.possible)}</span>
                </div>
            }
        </>
    );
};

interface BlockOverviewBackProps {
    onBackToOverviewButtonClick: () => void;
    onPreviousItemClick?: () => void;
    previousItemIndex?: number;
    previousItem?: BlockOverviewOutlineItem;
}

/**
 * BlockOverviewBack shows a link to either the overview of the previous item.
 */
export const BlockOverviewBack: React.FunctionComponent<BlockOverviewBackProps> = (props) => {
    let backBtn;
    if (props.previousItem && props.onPreviousItemClick) {
        const previousItemTitle = '< ' + props.previousItem.title;
        backBtn = <LinkButton title={previousItemTitle} onClick={props.onPreviousItemClick} />;
    } else {
        const title = '< ' + intl.formatMessage(messages.blockOverviewBackLabel);
        backBtn = (
            <LinkButton title={title} onClick={props.onBackToOverviewButtonClick} />
        );
    }

    return (
            <div className={'block-overview-back-bar px-3'}>
                {backBtn}
                {props.previousItem && props.previousItem.score &&
                    <div className={'block-overview-outline-item-score'}>
                        <span>{toUptoTwoDecimalPlaces(props.previousItem.score.earned)}</span>
                        <span>/</span>
                        <span>{toUptoTwoDecimalPlaces(props.previousItem.score.possible)}</span>
                    </div>
                }
            </div>
    );
};
