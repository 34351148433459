/*
 * Functions used by the ClassroomProgress report pages.
 */

import moment from 'moment-shortformat';
import * as React from 'react';
import { Link } from 'react-router-dom';

import {Icon} from 'elements/components/Icons';
import {getItemTypeMeta} from 'items/models';
import {ItemProgress} from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';

export function renderRelativeDate(date: Date): React.ReactNode {
    return moment(date).fromNow();
}

export function renderItemPostedDateShort(date: Date): React.ReactNode {
    return moment(date).format('MMM DD');
}

export function renderItemPostedDateFull(date: Date): React.ReactNode {
    return moment(date).format('MMMM DD, YYYY');
}

export function renderItemTitle(itemProgress: ItemProgress): React.ReactNode {
    const itemTypeMeta = getItemTypeMeta(itemProgress.type as any);

    return (
        <span className='classroom-progress-item-title'>
            <Icon name={itemTypeMeta.icon} zoom='15'/>
            <Link to={detailUrlForEntity({id: itemProgress.itemId, type: itemProgress.type})}>
                {itemProgress.title}
            </Link>
        </span>
    );
}
