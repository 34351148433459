import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';

interface Props {
    labelDescriptor: MessageDescriptor;
    value: string;
}

export const Option: React.FunctionComponent<Props> = (props) => {
    return (
        <option value={props.value}>{intl.formatMessage(props.labelDescriptor)}</option>
    );
};
