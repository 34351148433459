import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import Select, { ValueType } from 'react-select';
import { intl } from 'i18n';

import {
  CurriculumModules,
  CurriculumSubject,
  ModuleHierarchy
} from 'labxchange-client';
import { CurriculumsApi } from 'global/api';

import { Icon, showErrorMessage, Spinner } from 'ui/components';
import { SelectDropdownOption } from '../../types';
import { createSubjectGradeOptions, renderModules } from '../../utils';
import messages from '../../displayMessages';
import { ROUTES } from 'global/constants';
import { isKeyboardEnterEvent } from 'global/utils';
import { useLayoutSize, WrappedMessage } from 'utils';

interface TopicPageHeaderProps {
  topicTitle: string;
  loading: boolean;
  breadCrumbs?: string[];
}

export const TopicPageHeader: React.FC<TopicPageHeaderProps> = ({
  loading, topicTitle,
}) => {
  const history = useHistory();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const curriculum = pathSegments[pathSegments.length - 4];
  const subject = pathSegments[pathSegments.length - 3];
  const grade = pathSegments[pathSegments.length - 2];
  const moduleSlug = pathSegments[pathSegments.length - 1];

  const [showSidebar, setShowSidebar] = React.useState(history.location.state?.from === ROUTES.CurriculumsPage);
  const [sidebarLoading, setSidebarLoading] = React.useState<boolean>(false);
  const [selectedSubject, setSelectedSubject] = React.useState<string>(subject || '');
  const [selectedGrade, setSelectedGrade] = React.useState<string>(grade || '');
  const [curriculumModules, setCurriculumModules] = React.useState<ModuleHierarchy[]>([]);
  const [curriculumSubjects, setCurriculumSubjects] = React.useState<CurriculumSubject[]>([]);
  const [curriculumName, setCurriculumName] = React.useState<string>('');
  const [curriculumCountry, setCurriculumCountry] = React.useState<string>('');
  // Used to prevent null default values from being set on the dropdowns
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
  const [marginTop, setMarginTop] = React.useState(0);
  const isMobile = ['small'].includes(useLayoutSize());

  React.useEffect(() => {
    const fetchData = async () => {
      setSidebarLoading(true);

      if (curriculum && subject && grade) {
        try {
          const response: CurriculumModules = await CurriculumsApi.read({
            id: curriculum,
            subject: selectedSubject,
            grade: selectedGrade,
          });
          setCurriculumModules(response?.modules || []);
          setCurriculumSubjects(response?.subjects || []);
          setCurriculumName(response?.name || 'Curriculum');
          setCurriculumCountry(response?.country || '');

          if (firstLoad) {
            setFirstLoad(false);
          }
        } catch (error) {
          showErrorMessage(intl.formatMessage(messages.curriculumTopicPageSidebarError));
        }
      }

      setSidebarLoading(false);
    };

    fetchData();
  } , [curriculum, selectedGrade, selectedSubject]);

  // Navbar has different behavior when scrolling up or down
  // so the sidebar margin is handled accordingly
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY === 0) {
        setMarginTop(0);
      } else if (scrollY > lastScrollY) {
        setMarginTop(-68);
      } else {
        setMarginTop(32);
      }
      lastScrollY = scrollY;
    };

    let lastScrollY = window.scrollY;
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Ensure scrolling is enabled when the component unmounts
  React.useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubjectValue = (selectedOption as SelectDropdownOption).value;
      setSelectedSubject(selectedSubjectValue);

      const availableGradeValue = curriculumSubjects.find((s) => s.slug === selectedSubjectValue)?.grades[0].slug;
      setSelectedGrade(availableGradeValue as string);
    }
  };

  const onGradeChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedGradeValue = (selectedOption as SelectDropdownOption).value;
      setSelectedGrade(selectedGradeValue);
    }
  };

  const openSidebar = () => {
    document.body.style.overflow = 'hidden';
    setShowSidebar(true);
  };

  const closeSidebar = () => {
    document.body.style.overflow = 'auto';
    setShowSidebar(false);
  };

  const {
    subjectOptArray: subjectOptions,
    gradeOptArray: gradeOptions
  } = createSubjectGradeOptions(curriculumSubjects, selectedSubject);
  const subjectDefaultValue = subjectOptions.find(option => option.value === selectedSubject);
  const gradeDefaultValue = gradeOptions.find(option => option.value === selectedGrade);

  const renderSidebar = () => {
    return (
      <div
        className={classNames('curriculum-sidebar', { 'show': showSidebar })}
        style={{ marginTop: `${marginTop}px` }}
      >
        <div className='sidebar-content'>
          <div className='sidebar-header'>
            <div className='curriculum-info'>
              <span className='curriculum-title'>{curriculumName}</span>
              <span className='curriculum-subheading'>{curriculumCountry}</span>
            </div>
            <div className='close-btn-wrapper'>
              <button
                tabIndex={0}
                className='close-button'
                aria-label='Close sidebar'
                onClick={closeSidebar}
                data-testid='close-sidebar'
              >
                <Icon name={isMobile ? 'x' : 'double-arrow-left'} zoom='20' />
              </button>
            </div>
          </div>
          <div className='curriculum-dropdowns'>
            <Select
              tabIndex='0'
              className='select-dropdown'
              classNamePrefix='select'
              options={subjectOptions}
              onChange={onSubjectChange}
              value={subjectDefaultValue}
              isSearchable={false}
            />
            <Select
              tabIndex='0'
              className='select-dropdown'
              classNamePrefix='select'
              options={gradeOptions}
              onChange={onGradeChange}
              value={gradeDefaultValue}
              isSearchable={false}
            />
          </div>
          <div className='modules-list'>
            {sidebarLoading ? <div className='spinner-wrapper'> <Spinner /></div> :
              renderModules(
                curriculumModules, curriculum, selectedSubject,
                selectedGrade, 1, moduleSlug, closeSidebar
              )
            }
          </div>
        </div>
        <div
          role='button'
          aria-label='Close sidebar'
          tabIndex={0}
          className='sidebar-backdrop'
          onClick={closeSidebar}
          onKeyDown={(e) => isKeyboardEnterEvent(e) && closeSidebar()}
          data-testid='sidebar-backdrop'
        />
      </div>
    );
  };

  return (
    <>
      {!firstLoad && renderSidebar()}
      <header className='curriculum-topic-header'>
          {sidebarLoading ? <Skeleton className='sidebar-button no-cursor' width={36} height={36} /> :
            <button
              tabIndex={0}
              className='sidebar-button'
              aria-label='Open sidebar'
              onClick={openSidebar}
              onKeyDown={(e) => isKeyboardEnterEvent(e) && openSidebar()}
              data-testid='open-sidebar'
            >
              <WrappedMessage message={messages.curriculumTopicPageSidebarOpenBtnText} />
              <Icon name='double-arrow-right' zoom='20' />
            </button>
            }
          <div className='topic-title-wrapper container'>
            {loading ? <Skeleton width={280} height={60} /> :
              <h2 className='topic-title'>
                {topicTitle}
              </h2>
            }
          </div>
      </header>
    </>
  );
};

export default TopicPageHeader;
