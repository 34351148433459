// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationsObjectPermissions
 */
export interface OrganizationsObjectPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsObjectPermissions
     */
    canViewOrganizationDashboardObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsObjectPermissions
     */
    canEditOrganizationProfileObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsObjectPermissions
     */
    canEditMembersOfOrganizationObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsObjectPermissions
     */
    canEditContentOfOrganizationObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsObjectPermissions
     */
    canPublishContentToPublicLibraryObject: boolean;
}

export function OrganizationsObjectPermissionsFromJSON(json: any): OrganizationsObjectPermissions {
    return {
        'canViewOrganizationDashboardObject': json['can_view_organization_dashboard_object'],
        'canEditOrganizationProfileObject': json['can_edit_organization_profile_object'],
        'canEditMembersOfOrganizationObject': json['can_edit_members_of_organization_object'],
        'canEditContentOfOrganizationObject': json['can_edit_content_of_organization_object'],
        'canPublishContentToPublicLibraryObject': json['can_publish_content_to_public_library_object'],
    };
}

export function OrganizationsObjectPermissionsToJSON(value?: OrganizationsObjectPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_view_organization_dashboard_object': value.canViewOrganizationDashboardObject,
        'can_edit_organization_profile_object': value.canEditOrganizationProfileObject,
        'can_edit_members_of_organization_object': value.canEditMembersOfOrganizationObject,
        'can_edit_content_of_organization_object': value.canEditContentOfOrganizationObject,
        'can_publish_content_to_public_library_object': value.canPublishContentToPublicLibraryObject,
    };
}


