import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleUserProfileSettings',
        defaultMessage: 'Account settings',
        description: 'HTML title for the Account settings page.',
    },
    AccountSettingsTitle: {
        id:'AccountSettingsTitle',
        defaultMessage: 'Account Settings',
        description: 'Account settings title',
    },
    BasicAccountInfoTitle: {
        id:'BasicAccountInfoTitle',
        defaultMessage: 'Basic account information',
        description: 'Title for basic account information section',
    },
    UsernameTitle: {
        id:'UsernameTitle',
        defaultMessage: 'Username  (The name that identifies you on LabXchange. You cannot change your username.)',
        description: 'Title for username section',
    },
    dateOfBirthTitle: {
        id:'dateOfBirthTitle',
        defaultMessage: 'Date of birth',
        description: 'label for the user\'s date of birth on the account settings page',
    },
    FullnameTitle: {
        id:'FullnameTitle',
        defaultMessage: 'Full Name',
        description: 'Title for full name section',
    },
    EmailTitle: {
        id:'EmailTitle',
        defaultMessage: 'Email',
        description: 'Title for email address section',
    },
    CountryTitle: {
        id:'CountryTitle',
        defaultMessage: 'Country',
        description: 'Title for country section',
    },
    TimezoneTitle: {
        id:'TimezoneTitle',
        defaultMessage: 'Time Zone',
        description: 'Title for time zone section',
    },
    LanguageSwitcherTitle: {
        id:'languageSwitcherTitle',
        defaultMessage: 'Set Language',
        description: 'Title for Set Language section',
    },
    currentLanguage: {
        id:'currentLanguage',
        defaultMessage: 'Current Language',
        description: 'Text for Set Language section',
    },
    ResetPasswordTitle: {
        id:'ResetPasswordTitle',
        defaultMessage: 'Reset edX/LabXchange password',
        description: 'Title for reset password section',
    },
    notificationSettingsTitle: {
        id:'notificationSettingsTitle',
        defaultMessage: 'Notification settings',
        description: 'Title for email notification section',
    },
    DeleteAccountTitle: {
        id:'DeleteAccountTitle',
        defaultMessage: 'Delete account',
        description: 'Title for delete account section',
    },
    DeleteAccountSideBarText: {
        id:'DeleteAccountSideBarText',
        defaultMessage: 'Delete account',
        description: 'Text to display on the sidebar menu',
    },
    userAccountNotFound: {
        id:'userAccountNotFound',
        defaultMessage: 'could not find user account',
        description: 'Error message when user account is not found',
    },
    profileLink: {
        id:'profileLink',
        defaultMessage: 'Please visit My profile instead.',
        description: 'Link to profile page at the end of deleteMessageLine1.',
    },
    deleteMessageLine1: {
        id:'deleteMessageLine1',
        defaultMessage: 'Are you trying to switch to the Educator role? ',
        description: 'Delete message line 1',
    },
    deleteMessageLine2: {
        id:'deleteMessageLine2',
        defaultMessage: `If you are trying to delete your LabXchange account,
            you can do that here. However, please note that deletion of your
            account and personal data is permanent and cannot be undone. LabXchange
            will not be able to recover your account or any account data.`,
        description: 'Delete message line 2',
    },
    deleteMessageLine3: {
        id:'deleteMessageLine3',
        defaultMessage: `If you would also like to delete your edX account,
                         please visit edX.org. Please note, however, that if
                         you delete your edX account before deleting your
                         LabXchange account, you will not be able to log into
                         LabXchange to delete your account. We recommend that
                         you first delete your LabXchange account, and then
                         your edX account.`,
       description: 'Delete message line 3',
    },
    deleteMessageLine4: {
        id:'deleteMessageLine4',
        defaultMessage: 'Want to change your email, name, or password instead?',
        description: 'Delete message line 5',
    },
    deleteButton: {
        id:'deleteButton',
        defaultMessage: 'Delete account',
        description: 'Text for delete account button',
    },
    deleteWarningText1: {
        id:'deleteWarningText1',
        defaultMessage: `Warning: Account deletion is permanent. Please read
                         the above carefully before proceeding. This is an
                         irreversible action, and you will no longer be able to
                         use the same email on LabXchange.`,
        description: 'First warning text',
    },
    deleteWarningText2 : {
        id:'deleteWarningText2',
        defaultMessage: `Before proceeding, please unlink all social media accounts.`,
        description: 'Second warning text',
    },
    deleteAccountModalTitleFirst: {
        id:'deleteAccountModalTitleFirst',
        defaultMessage: 'Are you sure?',
        description: 'Title text for first account deletion modal.',
    },
    deleteAccountModalConfirmationFirst: {
        id:'deleteAccountModalConfirmationFirst',
        defaultMessage: `Deleting your LabXchange account is permanent and
                         irreversible.  You will no longer be able to log into
                         LabXchange.`,
        description: 'Confirmation text for first account deletion modal.',
    },
    deleteAccountModalConfirmButtonFirst: {
        id:'deleteAccountModalConfirmButtonFirst',
        defaultMessage: 'Yes, delete my account',
        description: 'Confirm button text for first account deletion modal.',
    },
    deleteAccountModalTitleFinal: {
        id:'deleteAccountModalTitleFinal',
        defaultMessage: 'Note',
        description: 'Title text for final account deletion modal.',
    },
    deleteAccountModalConfirmationFinal: {
        id:'deleteAccountModalConfirmationFinal',
        defaultMessage: `Deleting your LabXchange account will not delete your
                         edX account. If you would also like to delete your edX
                         account, please visit edX.org after deleting your
                         LabXchange account. If you delete your edX account
                         before deleting your LabXchange account, you will not
                         be able to log into LabXchange to delete your account.`,
        description: 'Confirmation text for final account deletion modal.',
    },
    deleteAccountModalConfirmButtonFinal: {
        id:'deleteAccountModalConfirmButtonFinal',
        defaultMessage: 'I acknowledge that I must visit edX.org to delete my edX account',
        description: 'Confirm button text for final account deletion modal.',
    },
    updateButton: {
        id:'updateInformationButton',
        defaultMessage: 'Update information',
        description: 'Text for update button',
    },
    resetPasswordSubmitButton: {
        id: 'resetPasswordSubmitButton',
        defaultMessage: 'Reset password',
        description: 'Text for the reset password submit button',
    },
    resetPasswordSubmitTitle: {
        id: 'resetPasswordSubmitTitle',
        defaultMessage: 'Are you sure you want to reset your password?',
        description: 'Title for the reset password confirmation modal',
    },
    resetPasswordSubmitBody: {
        id: 'resetPasswordSubmitBody',
        defaultMessage: 'Reset your password for {email}.',
        description: 'Text for the reset password confirmation modal',
    },
    passwordResetMessage: {
        id:'passwordResetMessage',
        defaultMessage: `We have e-mailed you instructions for setting your password
                         to the e-mail address you submitted. You should be receiving it shortly.`,
        description: 'Message text to display when password is reset',
    },
    ariaEditDateOfBirthButton: {
        id:'ariaEditDateOfBirthButton',
        defaultMessage: 'Edit your date of birth',
        description: 'Accessible label for "edit user date of birth" button.',
    },
    // for NotificationSettings.tsx
    notificationSettingsGetFailed: {
        id:'notificationSettingsGetFailed',
        defaultMessage: 'Failed to load notification settings.',
        description: 'Message shown when a network request to load user notification settings failed.',
    },
    notificationSettingsUpdateFailed: {
        id:'notificationSettingsUpdateFailed',
        defaultMessage: 'Failed to update notification settings.',
        description: 'Message shown when a network request to update user notification settings failed.',
    },
    notificationSettingsNotification: {
        id:'notificationSettingsNotification',
        defaultMessage: 'Notification',
        description: 'Label for checkbox to toggle push/app notifications for a notification setting.',
    },
    notificationSettingsEmail: {
        id:'notificationSettingsEmail',
        defaultMessage: 'Email',
        description: 'Label for checkbox to toggle email notifications for a notification setting.',
    },
    notificationSettingsNewMessages: {
        id:'notificationSettingsNewMessages',
        defaultMessage: 'Receive notifications about new messages?',
        description: 'Title for new messages notification settings',
    },
    notificationSettingsClasses: {
        id:'notificationSettingsClasses',
        defaultMessage: 'Receive notifications about your classes?',
        description: 'Title for classes notification settings',
    },
    notificationSettingsClassDiscussions: {
        id:'notificationSettingsClassDiscussions',
        defaultMessage: 'Receive notifications about your class discussions?',
        description: 'Title for class discussions notification settings',
    },
    notificationSettingsContentUpdate: {
        id:'notificationSettingsContentUpdate',
        defaultMessage: 'Receive notifications about your content?',
        description: 'Title for content notification settings',
    },
    notificationSettingsDiscussionForum: {
        id:'notificationSettingsDiscussionForum',
        defaultMessage: 'Receive notifications about the discussion forum?',
        description: 'Title for the discussion forum notification settings',
    },
    notificationSettingsMentorship: {
        id:'notificationSettingsMentorship',
        defaultMessage: 'Receive notifications about mentorship?',
        description: 'Title for mentorship notification settings',
    },
    notificationSettingsProductInterviews: {
        id:'notificationSettingsProductInterviews',
        defaultMessage: 'Receive notifications to help us improve LabXchange?',
        description: 'Title for product interviews notification settings',
    },
    notLoggedInSettingsAlertTitle: {
        id: 'notLoggedInSettingsAlertTitle',
        defaultMessage: 'Log in to view your account settings',
        description: 'Title of the alert when the user tries to enter to his settings page without being logged in',
    },
    notLoggedInSettingsAlertBody: {
        id: 'notLoggedInSettingsAlertBody',
        defaultMessage: 'You must log in to view your account settings. You can log in or sign up by clicking on the buttons below.',
        description: 'Body of the alert when the user tries to enter to his settings page without being logged in',
    },
    newsletterSettingUpdateInformation: {
        id: 'newsletterSettingUpdateInformation',
        defaultMessage: 'Update your subscription settings using the links provided at the bottom of each newsletter.',
        description: 'Message to show user to subscribe to newsletter for receiving updates',
    },
    newsletterSettingUpdateInformationHeading: {
        id: 'newsletterSettingUpdateInformationHeading',
        defaultMessage: 'Newsletter Settings',
        description: 'Message to show user to subscribe to newsletter for receiving updates',
    },
    notificationMediumNotify: {
        id: 'notificationMediumNotify',
        defaultMessage: 'Receive in-app notifications',
        description: 'Message to tell users that they will be receiving the in-app notifications',
    },
    notificationMediumEmail: {
        id: 'notificationMediumEmail',
        defaultMessage: 'Receive email notifications',
        description: 'Message to tell user that they will be receiving the email notifications',
    }
});

export default messages;
