import { bind } from 'bind-decorator';
import * as React from 'react';

import messages from 'notifications/displayMessages';
import { Notification } from 'labxchange-client';
import { RouteComponentProps, withRouter } from 'react-router';
import { KebabMenu, KebabMenuItem } from 'ui/components';
import { isKeyboardEnterEvent } from 'global/utils';

export interface NotificationItemProps extends RouteComponentProps<{}> {
  notification: Readonly<Notification>;
  markRead: (notificationId: string) => void;
  markUnread: (notificationId: string) => void;
}

export class NotificationItemInternal extends React.Component<
  NotificationItemProps,
  {}
> {
  public render() {
    const { notification } = this.props;
    return (
      <div
        tabIndex={0}
        role='button'
        aria-label='notification-alert-item'
        className={`notification-alert-item ${notification.unread ? 'unread' : ''}`}
        onClick={this.onClickButtonLabel}
        onKeyDown={(e) => isKeyboardEnterEvent(e) && this.onClickButtonLabel(e)}
        data-testid='notification-alert-item'
      >
        <div className='notification-icon'>
          <img alt='Notification Icon' src={notification.content.largeIconUrl} />
        </div>
        <div className='notification-details'>
          <div className='title'>{notification.content.title}</div>
          <div className='description'>{notification.content.text}</div>
          <div className='footer'>
            <span className='footer-link'>{notification.content.buttonLabel}</span>
          </div>
        </div>
        <div className='notification-menu'>
          <KebabMenu>
            {this.props.notification.unread ? (
              <KebabMenuItem
                iconName={'check'}
                message={messages.kebabMarkRead}
                disabled={false}
                onClick={this.markReadInPlace}
              />
            ) : (
              <KebabMenuItem
                iconName={'email'}
                message={messages.kebabMarkUnread}
                disabled={false}
                onClick={this.markUnreadInPlace}
              />
            )}
          </KebabMenu>
        </div>
      </div>
    );
  }

  @bind private markReadInPlace() {
    if (this.props.notification.id) {
      this.props.markRead(this.props.notification.id);
    }
  }

  @bind private markUnreadInPlace() {
    if (this.props.notification.id) {
      this.props.markUnread(this.props.notification.id);
    }
  }

  @bind private onClickButtonLabel(event: React.MouseEvent | React.KeyboardEvent) {
    event.preventDefault();
    if (this.props.notification.id) {
      this.props.markRead(this.props.notification.id);
      const targetUrl = this.props.notification.content.targetUrl;
      if (targetUrl) {
        if (targetUrl.startsWith('/')) {
          this.props.history.push(this.props.notification.content.targetUrl);
        } else {
          window.open(targetUrl, '_blank');
        }
      }
    }
  }
}

// Product interviews notifications use this component
export const NotificationAlertItem = withRouter(NotificationItemInternal);
