// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CurriculumDetail,
    CurriculumDetailFromJSON,
    CurriculumDetailToJSON,
    CurriculumModules,
    CurriculumModulesFromJSON,
    CurriculumModulesToJSON,
    CurriculumsList,
    CurriculumsListFromJSON,
    CurriculumsListToJSON,
    PaginatedCurriculumTaggingData,
    PaginatedCurriculumTaggingDataFromJSON,
    PaginatedCurriculumTaggingDataToJSON,
} from '../models';

export interface GetCurriculumSubjectTaggingDataRequest {
    curriculum: string;
    subject: string;
    page?: number;
    pageSize?: number;
    sort?: string;
}

export interface GetDetailRequest {
    id: string;
}

export interface ReadRequest {
    id: string;
    subject?: string;
    grade?: string;
}

/**
 * no description
 */
export class CurriculumsApi extends runtime.BaseAPI {

    /**
     * Return the tagging data for a given curriculum and subject. Supports pagination.
     */
    async getCurriculumSubjectTaggingDataRaw(requestParameters: GetCurriculumSubjectTaggingDataRequest): Promise<runtime.ApiResponse<PaginatedCurriculumTaggingData>> {
        if (requestParameters.curriculum === null || requestParameters.curriculum === undefined) {
            throw new runtime.RequiredError('curriculum','Required parameter requestParameters.curriculum was null or undefined when calling getCurriculumSubjectTaggingData.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling getCurriculumSubjectTaggingData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.curriculum !== undefined) {
            queryParameters['curriculum'] = requestParameters.curriculum;
        }

        if (requestParameters.subject !== undefined) {
            queryParameters['subject'] = requestParameters.subject;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/curriculums/get_curriculum_subject_tagging_data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCurriculumTaggingDataFromJSON(jsonValue));
    }

    /**
     * Return the tagging data for a given curriculum and subject. Supports pagination.
     */
    async getCurriculumSubjectTaggingData(requestParameters: GetCurriculumSubjectTaggingDataRequest): Promise<PaginatedCurriculumTaggingData> {
        const response = await this.getCurriculumSubjectTaggingDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return details of curriculum with all subjects and grades
     */
    async getDetailRaw(requestParameters: GetDetailRequest): Promise<runtime.ApiResponse<CurriculumDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDetail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/curriculums/{id}/get_detail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CurriculumDetailFromJSON(jsonValue));
    }

    /**
     * Return details of curriculum with all subjects and grades
     */
    async getDetail(requestParameters: GetDetailRequest): Promise<CurriculumDetail> {
        const response = await this.getDetailRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return list of all available curriculums
     */
    async listRaw(): Promise<runtime.ApiResponse<Array<CurriculumsList>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/curriculums`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurriculumsListFromJSON));
    }

    /**
     * Return list of all available curriculums
     */
    async list(): Promise<Array<CurriculumsList>> {
        const response = await this.listRaw();
        return await response.value();
    }

    /**
     * Return curriculum detail
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<CurriculumModules>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.subject !== undefined) {
            queryParameters['subject'] = requestParameters.subject;
        }

        if (requestParameters.grade !== undefined) {
            queryParameters['grade'] = requestParameters.grade;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/curriculums/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CurriculumModulesFromJSON(jsonValue));
    }

    /**
     * Return curriculum detail
     */
    async read(requestParameters: ReadRequest): Promise<CurriculumModules> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

}
