import '@contently/videojs-annotation-comments/build/css/annotations.css';
import { getChannelDetails, getVideoDetails } from 'items/youTubeApi';
import { ROUTES } from 'global/constants';

import { bind } from 'bind-decorator';
import { ContainerOne, Theatre } from 'elements';
import { XBlocksApi } from 'global/api';
import {
    AnnotatedVideoAnnotation,
    AnnotatedVideoDataState,
    ItemMetadata,
    VideoDataState
} from 'labxchange-client';
import { VideoPlayerStyle } from 'library/components/VideoPlayer';
import * as React from 'react';
import * as UI from 'ui/components';
import { UI_IS_MD } from 'ui/breakpoints';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { toggleHelpCenterVisibility, WrappedMessage } from 'utils';
import videojs from 'video.js';
import messages from '../../../displayMessages';
import { BlockPreview } from '../BlockPreview';
import { VideoBlock } from '../BlockVideo';
import { BlockProps } from '../models';
import { createAssetViewedEventForItem } from '../utils';
import { Annotation } from './Annotation';
import { AnnotationQuestionSidebar } from './AnnotationQuestionSidebar';
import { AnnotationsSidebar } from './AnnotationsSidebar';
import { AnnotationsList } from './AnnotationsList';
import { Tab, MobileTabbedPage } from './MobileTabbedPage';
import { TagsFilter } from './TagsFilter';
import { getTags } from './utils';
import { Transcript } from 'library/components/Transcript';

export enum SidebarStatus {
    Hidden = 0,
    Visible = 1,
}

export enum SidebarPanelStatus {
    Hidden = 0,
    Question = 1,
}

interface MobileModalProps {
    size: 'normal' | 'large' | 'max-9/16' | 'max';
    modalExtraClassName: string;
    showTopBar: boolean;
    contentPadding: 0 | 16 | 32;
    onRequestClose(): void;
    content: JSX.Element;
}

interface Props {
    itemMetadata: ItemMetadata;
    username?: string;
}

interface State {
    isLoading: boolean;
    showBlockView: boolean;
    blockState?: AnnotatedVideoDataState;
    videoBlockState?: VideoDataState;
    player?: videojs.Player;
    videoAuthorUrl?: string;
    videoAuthorLogoUrl?: string;
    videoAuthorName?: string;
    sidebarStatus: SidebarStatus;
    sidebarPanelStatus: SidebarPanelStatus;
    sidebarPanelLastStatus: SidebarPanelStatus;
    sidebarPanelAnnotation?: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[];
    isMediumView: boolean;
    actualTab: Tab;
    selectedTags: string[];
    toggleIsChecked: boolean;
    showMobileModal: boolean;
    mobileModalProps: MobileModalProps;
    annotationListRef?: React.RefObject<AnnotationsList>;
}

export class AnnotatedVideoBlock extends React.PureComponent<Props & BlockProps, State> {

    private mediaQueryMedium = UI_IS_MD;

    /// Refs used in mobile view
    private verticalListRef: React.RefObject<AnnotationsSidebar>;
    private horizontalListRef: React.RefObject<AnnotationsSidebar>;

    constructor(props: BlockProps) {
        super(props);
        this.state = {
            isLoading: true,
            showBlockView: false,
            sidebarStatus: SidebarStatus.Visible,
            sidebarPanelStatus: SidebarPanelStatus.Hidden,
            sidebarPanelLastStatus: SidebarPanelStatus.Hidden,
            isMediumView: false,
            actualTab: Tab.VideoPlayer,
            selectedTags: [],
            toggleIsChecked: true,
            showMobileModal: false,
            mobileModalProps: {
                size: 'max-9/16',
                modalExtraClassName: 'annotated-video-theater-mobile-modal',
                showTopBar: false,
                contentPadding: 0,
                onRequestClose: () => this.changeMobileModalState(false),
                content: (<div></div>),
            }
        };
        this.horizontalListRef = React.createRef();
        this.verticalListRef = React.createRef();
    }

    public componentDidMount() {
        this.parseQueryParams();
        this.fetchAnnotatedVideoXBlockData();
        this.mediaQueryMedium.addListener(this.setIsMediumView);
        this.setIsMediumView();
    }

    private parseQueryParams() {
        const queryParams = new URLSearchParams(window.location.search);
        const isFullScreen = queryParams.get('fullscreen') === 'true';
        if (isFullScreen) {
            this.setState({ showBlockView: true });
            toggleHelpCenterVisibility('hide');
        }
    }

    public componentWillUnmount() {
        this.mediaQueryMedium.removeListener(this.setIsMediumView);
    }

    @bind private setIsMediumView() {
        this.setState({isMediumView: this.mediaQueryMedium.matches});
    }

    /**
     * Fetch video data to render player
     */
    private async fetchAnnotatedVideoXBlockData() {
        let result;
        let videoDetails;
        let videoAuthorName;
        let videoAuthorUrl;
        let videoAuthorLogoUrl;
        try {
            result = await XBlocksApi.annotatedVideoDataAndState({id: this.props.itemMetadata.id});
            if (result.videoYoutubeId && result.videoYoutubeId !== '') {
                videoDetails = await getVideoDetails(result.videoYoutubeId);
                if (videoDetails && videoDetails.items) {
                    const videoSnippet = videoDetails.items[0].snippet;
                    videoAuthorName = videoSnippet.channelTitle;
                    videoAuthorUrl = `https://youtube.com/channel/${videoSnippet.channelId}`;
                    const channelDetails = await getChannelDetails(videoSnippet.channelId);
                    if (channelDetails && channelDetails.items) {
                        videoAuthorLogoUrl = channelDetails.items[0].snippet.thumbnails.medium.url;
                    }
                }
            }
            const videoData = await XBlocksApi.videoDataAndState({id: result.video.id});
            this.setState({
                blockState: result,
                videoBlockState: videoData,
                isLoading: false,
            });
        } catch (error) {
            showErrorMessage(<WrappedMessage message={messages.errorLoadingVideo} />, {
                exception: error,
            });
            return;
        }
        this.setState({
            blockState: result,
            isLoading: false,
            videoAuthorName,
            videoAuthorUrl,
            videoAuthorLogoUrl,
        });
    }

    public render() {
        if (!this.state.blockState) { return null; }
        const annotations = this.state.blockState && (this.state.blockState.annotations || []);
        const ownerDetails = this.getOwnerDetails();
        return <>
            <ContainerOne>
                <BlockPreview
                    previewImageUrl={this.getPoster()}
                    button={
                        <UI.Button
                            btnStyle='primary'
                            label={messages.blockAnnotatedVideoStartLabel}
                            onClick={this.onShow}
                        />
                    }
                />
                { this.state.showBlockView ? !this.state.isMediumView ?
                    this.renderWebTheatre(annotations, ownerDetails) :
                    this.renderMobileTheatre(annotations, ownerDetails)
                : null
                }
            </ContainerOne>
            { this.state.videoBlockState ?
            <Transcript
                itemMetadata={this.props.itemMetadata}
                transcriptVideoID={this.state.blockState.video.id}
                videoBlockState={this.state.videoBlockState}
                currentTime={0}
                setVideoTimePosition={this.setVideoTimePosition}
            /> : null }
            </>;
    }

    @bind private renderWebTheatre(annotations: AnnotatedVideoAnnotation[], ownerDetails: any) {
        if (this.state.blockState === undefined) { return undefined; }
        return (
            <Theatre
                title={this.props.itemMetadata.title}
                onRequestClose={this.onClose}
            >
                <div className='annotated-video-theater'>
                    {this.renderVideoBlock()}
                    {this.renderSidebarPanel()}
                    <AnnotationsSidebar
                        title={this.props.itemMetadata.title}
                        ownerName={ownerDetails.name}
                        ownerUrl={ownerDetails.url}
                        ownerOrgId={ownerDetails.orgId}
                        videoUrl={this.state.blockState.videoYoutubeId}
                        videoAuthorName={this.state.videoAuthorName}
                        videoAuthorUrl={this.state.videoAuthorUrl}
                        videoAuthorLogoUrl={this.state.videoAuthorLogoUrl}
                        annotations={annotations}
                        player={this.state.player}
                        onOpenQuestion={this.onOpenQuestion}
                        onToggleSidebar={this.onToggleSidebar}
                        loggedInUsername={this.props.username}
                    />
                </div>
            </Theatre>
        );
    }

    @bind private renderMobileTheatre(annotations: AnnotatedVideoAnnotation[], ownerDetails: any) {
        if (this.state.blockState === undefined) { return undefined; }
        return (
            <Theatre
                title={this.props.itemMetadata.title}
                onRequestClose={this.onClose}
            >
                <div className='annotated-video-theater annotated-video-theater-mobile'>
                    <MobileTabbedPage
                      onTabChange={this.onTabChange}
                      onToggleChange={this.onToggleChange}
                      actualTab={this.state.actualTab}
                    >
                        <div className={`annotated-video-mobile-player
                            ${this.tabsBarIsShowed() ? 'with-tags-bar' : ''}
                            ${this.state.actualTab !== Tab.VideoPlayer ? 'hidden' : ''}`}>
                            {this.renderVideoBlock()}
                            <div className={`annotated-video-horizontal-sidebar
                                ${!this.state.toggleIsChecked ? 'hidden' : ''}`}>
                                <AnnotationsSidebar
                                    ref={this.horizontalListRef}
                                    title={this.props.itemMetadata.title}
                                    ownerName={ownerDetails.name}
                                    ownerUrl={ownerDetails.url}
                                    ownerOrgId={ownerDetails.orgId}
                                    videoUrl={this.state.blockState.videoYoutubeId}
                                    videoAuthorName={this.state.videoAuthorName}
                                    videoAuthorUrl={this.state.videoAuthorUrl}
                                    videoAuthorLogoUrl={this.state.videoAuthorLogoUrl}
                                    annotations={annotations}
                                    player={this.state.player}
                                    onOpenQuestion={this.onOpenQuestionInMobile}
                                    onToggleSidebar={this.onToggleSidebar}
                                    isMobileView={true}
                                    selectedTags={this.state.selectedTags}
                                    onUpdateTags={this.onUpdateTags}
                                    isHorizontalList={true}
                                    onOpenAnnotationMore={this.onOpenReadMoreModal}
                                    loggedInUsername={this.props.username}
                                />
                            </div>
                        </div>
                        <div className={`annotated-video-mobile-sidebar
                            ${this.state.actualTab !== Tab.AnnotationsList ? 'hidden' : ''}`}>
                            <AnnotationsSidebar
                                ref={this.verticalListRef}
                                title={this.props.itemMetadata.title}
                                ownerName={ownerDetails.name}
                                ownerUrl={ownerDetails.url}
                                ownerOrgId={ownerDetails.orgId}
                                videoUrl={this.state.blockState.videoYoutubeId}
                                videoAuthorName={this.state.videoAuthorName}
                                videoAuthorUrl={this.state.videoAuthorUrl}
                                videoAuthorLogoUrl={this.state.videoAuthorLogoUrl}
                                annotations={annotations}
                                player={this.state.player}
                                onOpenQuestion={this.onOpenQuestionInMobile}
                                onToggleSidebar={this.onToggleSidebar}
                                isMobileView={true}
                                selectedTags={this.state.selectedTags}
                                onUpdateTags={this.onUpdateTags}
                                onStartAnnotationList={this.onStartAnnotationMobileList}
                                onAnnotationWatch={() => this.onTabChange(Tab.VideoPlayer)}
                                loggedInUsername={this.props.username}
                            />
                        </div>
                        <div className={`annotated-video-mobile-filter
                            ${this.state.actualTab !== Tab.AnnotationFilter ? 'hidden' : ''}`}>
                            <TagsFilter
                                show={true}
                                tags={getTags(annotations)}
                                selectedTags={this.state.selectedTags}
                                onSelectTags={this.onSelectTags}
                                onClose={() => null}
                                isMobileView={true}
                            />
                        </div>
                    </MobileTabbedPage>
                    { this.state.showMobileModal &&
                        <UI.Modal
                            size={this.state.mobileModalProps.size}
                            modalExtraClassName={this.state.mobileModalProps.modalExtraClassName}
                            showTopBar={this.state.mobileModalProps.showTopBar}
                            contentPadding={this.state.mobileModalProps.contentPadding}
                            onRequestClose={this.state.mobileModalProps.onRequestClose}
                            content={this.state.mobileModalProps.content}
                        />
                    }
                </div>
            </Theatre>
        );
    }

    private getOwnerDetails() {
        const item = this.props.itemMetadata;
        let name: string | undefined;
        let url: string | undefined;
        let orgId: string | undefined;
        if (item.source) {
            const sourceOrg = item.source.sourceOrganizations && item.source.sourceOrganizations[0].organization;
            if (sourceOrg) {
                name = sourceOrg.name;
                url = sourceOrg.url;
                orgId = sourceOrg.id;
            }
        } else if (item.authors.length > 0) {
            const author = item.authors[0];
            if (author.username) {
                name = author.fullName;
                url = ROUTES.Users.PROFILE_SLUG(author.username);
            }
        }

        return {name, url, orgId};
    }

    @bind private renderVideoBlock() {
        if (this.state.blockState === undefined) { return undefined; }
        return (
            <VideoBlock
                blockData={this.props.blockData}
                isPathwayChild={this.props.isPathwayChild}
                onExpandBlock={this.props.onExpandBlock}
                itemMetadata={this.state.blockState.video}
                playingHandler={this.playingHandler}
                onPlayerReady={this.onPlayerReady}
                videoPlayerStyle={VideoPlayerStyle.AnnotatedVideo}
                videoDataAndState={this.state.videoBlockState}
                showCustomControlsOnVimeo={true}
            />
        );
    }

    @bind private changeMobileModalState(showMobileModal: boolean) {
        this.setState({showMobileModal});
    }

    @bind private onTabChange(actualTab: Tab) {
        this.setState({actualTab});
    }

    @bind private onToggleChange(toggleIsChecked: boolean) {
        this.setState({toggleIsChecked});
    }

    @bind private tabsBarIsShowed() {
        return this.state.selectedTags.length !== 0;
    }

    @bind private onToggleSidebar() {
        if (this.state.sidebarStatus === SidebarStatus.Visible) {
            this.setState({
                sidebarStatus: SidebarStatus.Hidden,
                sidebarPanelStatus: SidebarPanelStatus.Hidden,
            });
        } else {
            this.setState({
                sidebarStatus: SidebarStatus.Visible,
                sidebarPanelStatus: this.state.sidebarPanelLastStatus,
            });
        }
    }

    @bind private onPlayerReady(player: videojs.Player) {
        this.setState({player});
    }

    @bind private onShow() {
        this.setState({showBlockView: true});
        this.updateUrlForFullScreen();
        createAssetViewedEventForItem(this.props.itemMetadata.id);
    }

    private updateUrlForFullScreen() {
        const url = new URL(window.location.href);
        url.searchParams.set('fullscreen', 'true');
        window.history.replaceState({}, '', url.toString());
        toggleHelpCenterVisibility('hide');
    }

    @bind private onClose() {
        this.setState({showBlockView: false, player: undefined});
        toggleHelpCenterVisibility('show');
    }

    @bind public timeUpdateHandler(videoPosition: number): boolean {
        return true;
    }

    @bind public playingHandler(): boolean {
        this.setState({
            sidebarPanelLastStatus: this.state.sidebarPanelStatus,
            sidebarPanelStatus: SidebarPanelStatus.Hidden});
        if (this.state.showBlockView) {
            return true;
        } else {
            this.setState({showBlockView: true});
            return false;
        }
    }

    @bind private renderSidebarPanel() {
        switch (this.state.sidebarPanelStatus) {
            case (SidebarPanelStatus.Hidden): return <></>;
            case (SidebarPanelStatus.Question): return (
                <div className='annotated-video-sidebar-panel'>
                    <AnnotationQuestionSidebar
                        annotation={this.state.sidebarPanelAnnotation!}
                        onCloseQuestion={this.onCloseQuestion}
                    />
                </div>);
        }
    }

    @bind private onCloseQuestion() {
        this.setState({
            sidebarPanelLastStatus: this.state.sidebarPanelStatus,
            sidebarPanelStatus: SidebarPanelStatus.Hidden,
            sidebarPanelAnnotation: undefined,
        });
        if (this.state.player) { this.state.player.play(); }
    }

    @bind private onOpenQuestion(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]) {
        this.setState({
            sidebarPanelAnnotation: annotation,
            sidebarPanelStatus: SidebarPanelStatus.Question,
            sidebarPanelLastStatus: SidebarPanelStatus.Question,
        });
    }

    @bind private onOpenQuestionInMobile(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]) {
        this.setState({
            mobileModalProps: {
                size: 'max-9/16',
                modalExtraClassName: 'annotated-video-theater-mobile-modal',
                showTopBar: true,
                contentPadding: 16,
                onRequestClose: () => this.changeMobileModalState(false),
                content: (
                    <div className='annotated-video-mobile-question-modal'>
                        <AnnotationQuestionSidebar
                            annotation={annotation}
                            onCloseQuestion={this.onCloseQuestionInMobile}
                        />
                    </div>
                ),
            },
            showMobileModal: true,
        });
    }

    @bind private onCloseQuestionInMobile() {
        this.changeMobileModalState(false);
        if (this.state.player) { this.state.player.play(); }
    }

    @bind private onCloseReadMoreModal() {
        this.state.player?.play();
        this.changeMobileModalState(false);
    }

    @bind private onOpenReadMoreModal(annotation: AnnotatedVideoAnnotation) {
        const ownerDetails = this.getOwnerDetails();
        this.setState({
            mobileModalProps: {
                size: 'max-9/16',
                modalExtraClassName: 'annotated-video-theater-mobile-modal',
                showTopBar: false,
                contentPadding: 0,
                onRequestClose: () => this.onCloseReadMoreModal(),
                content: (
                    <ul className='annotated-video-mobile-annotation-modal'>
                        <Annotation
                            ownerName={ownerDetails.name}
                            ownerUrl={ownerDetails.url}
                            annotation={annotation}
                            onCloseInModal={() => this.onCloseReadMoreModal()}
                            onWatch={() => this.onOpenWatchInModal(annotation)}
                            onSelectTag={() => null}
                            loggedInUsername={this.props.username}
                            questionStartOrderMap={{}}
                        />
                    </ul>
                ),
            },
            showMobileModal: true,
        });
    }

    @bind private onStartAnnotationMobileList(annotationListRef: React.RefObject<AnnotationsList>) {
        this.setState({annotationListRef});
    }

    @bind private onOpenWatchInModal(annotation: AnnotatedVideoAnnotation) {
        this.changeMobileModalState(false);
        if (this.state.annotationListRef !== undefined && this.state.annotationListRef.current !== null) {
            this.state.annotationListRef.current.onSelectAnnotation(annotation);
        }
    }

    @bind private onUpdateTags(tags: []) {
        this.setState({selectedTags: tags});
    }

    private getPoster() {
        if (this.isYoutubeSource()) {
            return `https://img.youtube.com/vi/${this.state.blockState?.videoYoutubeId}/hqdefault.jpg`;
        }
        if (this.isVimeoSource()) {
          return `https://vumbnail.com/${this.getVimeoId()}.jpg`;
        }
        return this.props.itemMetadata.imageUrl;
    }

    private isYoutubeSource() {
        if (this.state.blockState === undefined) {
            return false;
        }
        if (this.state.blockState.videoYoutubeId !== undefined && this.state.blockState.videoYoutubeId !== '') {
            return true;
        }
        return false;
    }

    private isVimeoSource() {
        if (this.state.blockState === undefined) {
            return false;
        }
        if (this.state.blockState.videoYoutubeId !== undefined && this.state.blockState.videoYoutubeId !== '') {
            return false;
        }
        if (this.state.videoBlockState === undefined) {
            return false;
        }
        return this.state.videoBlockState.encodedVideos.fallback.url.split('/')[2] === 'vimeo.com';
    }

    private getVimeoId() {
        if (this.state.videoBlockState === undefined) {
            return '';
        }
        return this.state.videoBlockState.encodedVideos.fallback.url.split('/')[3];
    }

    @bind private onSelectTags(tags: string[]) {
        this.setState({
            selectedTags: tags,
            actualTab: Tab.AnnotationsList,
        });

        /// Used this approach because in some cases the lists were not updated when changing the tags
        if (this.horizontalListRef.current !== null) {
            this.horizontalListRef.current.updateTags(tags);
        }
        if (this.verticalListRef.current !== null) {
            this.verticalListRef.current.updateTags(tags);
        }
    }

    @bind private setVideoTimePosition() {
        // No-op : annotated video transcripts don't stay in sync with video.
    }
}
