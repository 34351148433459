import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id:'titleDuplicateItemsModal',
        defaultMessage: 'Duplicate Items',
        description: 'Title of modal confirming adding duplicate items.',
    },
    cancel: {
        id:'cancel',
        defaultMessage: 'Cancel',
        description: 'Label on button to cancel adding duplicate items.',
    },
    add: {
        id:'add',
        defaultMessage: 'Add again',
        description: 'Label on button to add all listed duplicate items anyway.',
    },
    originallyAdded: {
        id:'originallyAdded',
        defaultMessage: 'Originally added {date}',
        description: 'Message to note when the item was originally added to the classroom.',
    },
    remove: {
        id:'remove',
        defaultMessage: 'Remove item',
        description: 'Screenreader message for the trashcan button next to each duplicate icon. Clicking this button removes the item from the list of duplicates that will be added on clicking "Add anyway"',
    },
});

export default messages;
