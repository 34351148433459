import bind from 'bind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { isMouseOrEnterEvent } from 'global/utils';
import { UI_IS_SM } from 'ui/breakpoints';
import { Icon } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { intl } from 'i18n';
import messages from '../displayMessages';

export interface TestimonialProps {
    quote: string;
    attribution: string;
    img: string;
    visible?: boolean;
    isMobileView?: boolean;
}

interface Props {
    firstIdx?: number;
    testimonials: TestimonialProps[];
}

interface State {
    currentIdx: number;
    isMobileView: boolean;
}

export class TestimonialSection extends React.PureComponent<Props, State> {

    public static defaultProps = {
        firstIdx: 0,
    };

    private mediaQuery = UI_IS_SM;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentIdx: this.props.firstIdx || 0,
            isMobileView: false,
        };
    }

    public componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
    }

    public render() {
        return (
            <div className='testimonial-wrapper'>
                <h2 className='sr-only'>
                    {intl.formatMessage(messages.testimonialsSliderHeading)}
                </h2>
                {/* hidden on medium and smaller screens */}
                <button className='lx-btn previous-button d-none d-md-block'
                        title={intl.formatMessage(uiMessages.uiPrevious)}
                        onKeyDown={this.goToPrev}
                        onClick={this.goToPrev}
                        aria-label={intl.formatMessage(messages.testimonialScrollPrevText)}>
                  <div className='rounded-circle'>
                      <Icon name='chevron-left' />
                  </div>
                </button>
                {this.renderTestimonials()}
                {/* hidden on medium and smaller screens */}
                <button className='lx-btn next-button d-none d-md-block'
                        title={intl.formatMessage(uiMessages.uiNext)}
                        onKeyDown={this.goToNext}
                        onClick={this.goToNext}
                        aria-label={intl.formatMessage(messages.testimonialScrollNextText, {index: this.state.currentIdx + 1, count: this.props.testimonials.length})}>
                    <div className='rounded-circle'>
                        <Icon name='chevron-right'/>
                    </div>
                </button>
                {/* visible on medium and smaller screens */}
                <button className='next-dots unstyled d-md-none'
                    title={intl.formatMessage(uiMessages.uiNext)}
                    onKeyDown={this.goToNext}
                    onClick={this.goToNext}>{
                        this.props.testimonials.map((testimonial, idx) => {
                            return (
                                <div key={idx} className={classNames('dot rounded-circle',
                                    this.state.currentIdx === idx ? 'selected' : null)}/>
                            );
                        })
                    }
                </button>
            </div>
        );
    }

    private renderTestimonials() {
        return (
            <div className='testimonial-list'>{
                this.props.testimonials.map((testimonial, idx) => {
                    return (
                        <Testimonial key={idx}
                            visible={this.state.currentIdx === idx}
                            isMobileView={this.state.isMobileView}
                            testimonialNumber={idx + 1}
                            totalTestimonials={this.props.testimonials.length}
                            {...testimonial}/>
                    );
                })
            }</div>
        );
    }

    @bind private setIsMobileView(): void {
        this.setState({isMobileView: this.mediaQuery.matches});
    }

    @bind private goToPrev(event: React.MouseEvent<any>|React.KeyboardEvent<any>) {
        // Go to the previous index, wrapping around as needed.
        const numItems = this.props.testimonials.length;
        const decrIdx = this.state.currentIdx - 1;
        const currentIdx = decrIdx < 0 ? numItems - 1 : decrIdx;
        if (isMouseOrEnterEvent(event)) {
            this.setState({currentIdx});
        }
    }

    @bind private goToNext(event: React.MouseEvent<any>|React.KeyboardEvent<any>) {
        // Go to the next index, wrapping around as needed.
        const numItems = this.props.testimonials.length;
        const incrIdx = this.state.currentIdx + 1;
        const currentIdx = incrIdx >= numItems ? 0 : incrIdx;
        if (isMouseOrEnterEvent(event)) {
            this.setState({currentIdx});
        }
    }
}

interface TestimonialComponentProps extends TestimonialProps {
    testimonialNumber: number;
    totalTestimonials: number;
}

const Testimonial: React.FunctionComponent<TestimonialComponentProps> = (props) => (
    <div className={classNames('testimonial', props.visible ? 'visible' : 'invisible')}>
        <h3 className='sr-only'>
            {intl.formatMessage(messages.testimonialNumberText, {
                testimonialNumber: props.testimonialNumber,
                totalTestimonials: props.totalTestimonials,
            })}
        </h3>
        <div className='headshot'>
            <div className='img'>
                <img src={props.img} alt={props.attribution} className='rounded-circle'/>
            </div>
            {props.isMobileView ? (
                <div className='attribution'>{props.attribution}</div>
            ) : null}
        </div>
        <div className='quote-wrapper'>
            <div className='quote'>{props.quote}</div>
            {props.isMobileView ? null : (
                <div className='attribution'>{props.attribution}</div>
            )}
        </div>
    </div>
);
