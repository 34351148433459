import bind from 'bind-decorator';
import { ModerationRequestContentTypeEnum } from 'labxchange-client';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { IconSymbol, KebabMenuItem, showSuccessMessage } from 'ui/components';
import { WrappedMessage } from 'utils';
import { ReportSubmitModal } from '../ReportModals';
import messages from '../../displayMessages';
import uiMessages from 'ui/components/displayMessages';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';

enum ModalType {
    None = 0,
    Submit,
}

interface Props {
    asKebab: boolean;
    itemId: string;
    itemTitle: string;
    itemType: ModerationRequestContentTypeEnum;
    extraContext?: {};
    label: MessageDescriptor;
    onClose(): void;
    onOpen(): void;
    icon?: IconSymbol;
}

interface State {
    modalType: ModalType;
}

export class ReportButton extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalType: ModalType.None,
        };
    }

    public render() {
        let button: JSX.Element;
        if (this.props.asKebab) {
            button = <KebabMenuItem
                disabled={false}
                iconName={this.props.icon || 'report'}
                message={this.props.label}
                onClick={() => this.onOpenModal(ModalType.Submit)}
            />;
        } else {
            button = (
                <div className='report-button'>
                    <button
                        className='btn report-button-experiment-style'
                        onClick={() => {
                            this.onOpenModal(ModalType.Submit);
                            analyticsInstance.track(EVENT_NAMES.ReportButtonClicked, { url: window.location.toString(), actionText:'Report', itemType: this.props.itemType });
                        }}
                    >
                        <WrappedMessage message={this.props.label}/>
                    </button>
                </div>
            );
        }
        return (
            <>
                {button}
                {this.renderModals()}
            </>
        );
    }

    private renderModals() {
        switch (this.state.modalType) {
            case ModalType.Submit:
                return <ReportSubmitModal
                    itemId={this.props.itemId}
                    extraContext={this.props.extraContext || {}}
                    itemTitle={this.props.itemTitle}
                    itemType={this.props.itemType}
                    onSuccess={this.onSuccess}
                    onClose={this.onCloseModal}
                    onError={this.onCloseModal}
                />;
            default: return null;
        }
    }

    @bind private onSuccess() {
        this.onCloseModal();
        showSuccessMessage(<WrappedMessage message={messages.modalSuccessDescription} />, {
            title: <WrappedMessage message={messages.modalSuccessTitle} />,
            confirmText: uiMessages.uiConfirmationButton,
        });
    }

    @bind private onOpenModal(modalType: ModalType) {
        this.setState({modalType});
        this.props.onOpen();
    }

    @bind private onCloseModal() {
        this.setState({modalType: ModalType.None});
        this.props.onClose();
    }
}
