// main org profile page wrapper
import bind from 'bind-decorator';
import { OrganizationsApi, SearchApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { ItemType } from 'items/models';
import {
    AppliedFilter,
    AppliedFilterFilterOnEnum,
    ItemResponse,
    OrganizationResponse,
    SearchRequestOrderingEnum,
    SearchResults,
} from 'labxchange-client';
import { Card } from 'library/components/Card';
import { CardsListBottomBar } from 'library/components/CardsListBottomBar';
import { detailUrlForEntity } from 'library/utils';
import { AlternativeOrgProfilePage, OrganizationDetails, OrgProfileHeader } from 'org/components';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getCurrentPageFromString, getPaginationSizeFromString } from 'search/utils';
import {
    PaginationSizeOptions,
    ResultsCount,
    SortOptions,
    StandardPageLayout,
} from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { replicateElement, WrappedMessage } from 'utils';
import { ItemCounts } from '../utils';
import { SORT_OPTIONS_RELEVANCE } from '../../../global/locales-constants';
import { intl, locale } from 'i18n';
import messages from './displayMessages';
import { skeletonItem, skeletonOrg } from 'skeletons';

/*
 * To add a new alternative profile page for an organization you need to add a new entry
 * to the OrganizationDetails declaration below.
 */
const alternativeOrganizations: { [id: string]: OrganizationDetails } = {
    'AmgenFoundation': {
        descriptionText: 'The Amgen Foundation, the main philanthropic arm of ' +
                         'Amgen, inspires the next generation of innovators by ' +
                         'funding evidence-based science education programs, ' +
                         'reaching millions of students and teachers around the ' +
                         'world. The Amgen Foundation believes that science literacy ' +
                         'is as fundamental to success in life as reading and writing. ' +
                         'To that end, the Foundation has contributed over $270 ' +
                         'million to advancing science education programming globally, ' +
                         'working to reimagine how students introduce, experience and ' +
                         'access science learning.',
        headerBackgroundUrl: '/assets/images/org-page-amgen-header.jpg',
        headersPartners: [
            {
                altText: 'LabXchange logo',
                logoUrl: '/assets/images/labxchange-logo-trim.svg',
            },
            {
                altText: 'AMGEN Biotech Experience logo',
                logoUrl: '/assets/images/partners/abe.svg',
            },
            {
                altText: 'AMGEN Scholars Program logo',
                logoUrl: '/assets/images/partners/amgen-scholars.svg',
            },
            {
                altText: 'Khan Academy logo',
                logoUrl: '/assets/images/partners/khan-academy.svg',
            },
        ],
        programs: [
            {
                title: 'Free, High-Quality Science Learning for All',
                description: 'LabXchange accelerates science learning by facilitating ' +
                             'discovery and authentic experiences with the scientific ' +
                             'process. LabXchange is purpose-built to drive more inclusion ' +
                             'in the scientific process, and strives to make a career in ' +
                             'science accessible to anyone, anywhere, through top-quality ' +
                             'learning resources, virtual lab simulations, and social ' +
                             'features centered on learning.',
                // https://www.youtube.com/watch?v=ZO0rOz8ysJE
                youtubeVideoId: 'ZO0rOz8ysJE',
                logoUrl: '/assets/images/labxchange-logo-trim.svg',
                logoAltText: 'LabXchange logo',
                firstButtonText: 'Profile',
                firstButtonUrl: 'https://www.labxchange.org/org/labxchange',
                secondButtonText: 'About us',
                secondButtonUrl: 'https://about.labxchange.org/',
            },
            {
                title: 'Hands-On Biotechnology in Secondary School',
                description: 'The Amgen Biotech Experience (ABE) provides teachers with ' +
                             'professional development, equipment, and hands-on curricula ' +
                             'to transform biology education in secondary schools worldwide. ' +
                             'Through the ABE labs, students gain hands-on experience in ' +
                             'producing a functional protein from genetically modified bacteria.',
                // https://www.youtube.com/watch?v=8pzo-LzrgvY
                youtubeVideoId: '8pzo-LzrgvY',
                logoUrl: '/assets/images/partners/abe.svg',
                logoAltText: 'Amgen Biotech Experience logo',
                firstButtonText: 'ABE on LabXchange',
                firstButtonUrl: 'https://abe.labxchange.org/',
                secondButtonText: 'Website',
                secondButtonUrl: 'https://www.amgenbiotechexperience.com/',
                secondButtonNewTab: true,
            },
            {
                title: 'Undergraduate Research Experiences',
                description: 'The Amgen Scholars Program brings undergraduates from across ' +
                             'the globe to participate in cutting-edge research opportunities ' +
                             'at one of 25 world-class institutions through the U.S., Europe, ' +
                             'Asia, Australia, and Canada. Scholars undertake a research project ' +
                             'under top faculty, whilst participating as a cohort in seminars, ' +
                             'networking events, and an annual symposium.',
                // https://www.youtube.com/watch?v=XLtPW00XWXI
                youtubeVideoId: 'XLtPW00XWXI',
                logoUrl: '/assets/images/partners/amgen-scholars.svg',
                logoAltText: 'AMGEN Scholars Program logo',
                firstButtonText: 'ASP on LabXchange',
                firstButtonUrl: 'https://www.labxchange.org/org/AmgenScholarsProgram',
                secondButtonText: 'Website',
                secondButtonUrl: 'https://amgenscholars.com/',
                secondButtonNewTab: true,
            },
            {
                title: 'Free, World-Class Education for Anyone',
                description: 'Khan Academy is a personalized learning resource for all ages. ' +
                             'The Amgen Foundation is the exclusive sponsor of Khan Academy’s ' +
                             'biology resources, which include high school biology and Advanced ' +
                             'Placement® (AP) Biology. Khan Academy offers practice exercises, ' +
                             'instructional videos, and a personalized learning dashboard that ' +
                             'empower learners to study at their own pace in and outside of the classroom.',
                // https://www.youtube.com/watch?v=JC82Il2cjqA
                youtubeVideoId: 'JC82Il2cjqA',
                logoUrl: '/assets/images/partners/khan-academy.png',
                logoAltText: 'Khan Academy logo',
                firstButtonText: 'Khan Academy on LabXchange',
                firstButtonUrl: 'https://www.labxchange.org/org/KhanAcademy',
                secondButtonText: 'Website',
                secondButtonUrl: 'https://www.khanacademy.org/',
                secondButtonNewTab: true,
            },
        ],
        staffInvolvementText: 'The Amgen Foundation supports and celebrates staff members’ ' +
                              'contributions to building a better tomorrow. Amgen staff are ' +
                              'helping to build a diverse library of content that supports all ' +
                              'learners in exploring and engaging with science, and in particular, ' +
                              'the vast industry of biotechnology.',
        staffInvolvementSecondText: 'Recent content collaborations with Amgen scientists:',
        cardIds: [
            'lb:LabXchange:14a9a19e:lx_simulation:1',
            'lb:LabXchange:0e7474ee:lx_simulation:1',
            'lb:LabXchange:cc67d362:lx_simulation:1',
            'lb:LabXchange:96c9a8ad:video:1',
            'lb:LabXchange:2a3e3967:video:1',
            'lb:LabXchange:cb0d5fe7:video:1',
            'lb:LabXchange:a1520d4f:lx_simulation:1',
            'lb:LabXchange:6cf003a1:lx_simulation:1',
        ],
        staffEngagementTag: 'ALXCollab',
        footerText: 'Stay Connected',
    },
};
const devstackCardIds = [
    'lb:LabXchange:1fb8b9d6:lx_simulation:2',
    'lb:LabXchange:ab134bb3:lx_narrative:1',
    'lb:LabXchange:demo3:lx_document:1',
    'lb:LabXchange:2eb8b9d5:problem:1',
];

type ContentTypeParam = 'cluster'|'pathway'|'content'|undefined;

interface MatchProps {
    slug: string;
    contentType: ContentTypeParam;
}

interface Props extends RouteComponentProps<MatchProps> {
}

interface State {
    loadingOrgData: boolean;
    orgData?: OrganizationResponse;
    loadingSearchData: boolean;
    results: ItemResponse[];
    searchResults?: SearchResults;
    showMore: boolean;
    currentPage: number;
    refresh: boolean;
}

const PAGINATION_OPTIONS = [
    // Multiples of 4 work best for this page
    { label: '8', value: 8 },
    { label: '24', value: 24 },
    { label: '48', value: 48 },
];
const DEFAULT_PAGE_SIZE = PAGINATION_OPTIONS[0].value;

export class OrgProfilePage extends React.PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentPage: getCurrentPageFromString(this.params.get('page')),
            loadingOrgData: true,
            loadingSearchData: true,
            showMore: false,
            refresh: false,
            results: replicateElement(skeletonItem, 8),
        };
    }

    public async componentDidMount() {
        this.fetchOrgProfile();
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        const loadingAnotherOrganization = this.orgSlug !== prevProps.match.params.slug;
        const switchedContentTab = this.contentType !== prevProps.match.params.contentType;
        const changedPageGETparameter = this.props.location.search !== prevProps.location.search;
        const standardPaginationCalled = prevState.currentPage !== getCurrentPageFromString(this.params.get('page'));

        if (loadingAnotherOrganization) {
            this.loadOrganization();
        } else if (switchedContentTab || changedPageGETparameter) {
            this.switchContentTab();
        } else if (!this.state.showMore && standardPaginationCalled) {
            // Excluding infinite scroll behavior, it doesn't affect `?page` param
            this.setState({ currentPage: getCurrentPageFromString(this.params.get('page'))});
        }
    }

    public render() {
        // profile loaded

        const orgSlug = this.orgSlug;
        const orgProfile = this.state.orgData ? this.state.orgData.org : skeletonOrg;
        if (this.isAlternativeOrganization()) {
            const details = alternativeOrganizations[orgSlug];
            // Overwrite defined cardIds with the devstack ones if it is not production
            if (process.env.NODE_ENV !== 'production') { details.cardIds = devstackCardIds; }
            return <AlternativeOrgProfilePage organizationId={orgSlug} details={details}/>;
        }

        const numResults = this.state.searchResults ? this.state.searchResults.count : 0;
        const pageCount = Math.max(1, Math.ceil(numResults / this.currentPaginationSize));

        return (
            <StandardPageLayout
                headerBackgroundUrl={orgProfile.coverImageUrl}
                pageTitle={messages.pageTitle}
                pageTitleValues={{name: orgProfile.name || orgProfile.slug}}
                headerFeature={<OrgProfileHeader
                    profile={orgProfile}
                    canViewDashboard={this.state.orgData ? this.state.orgData.permissions.canViewOrganizationDashboardObject: false}
                    itemCounts={this.itemCounts}
                    showSkeleton={this.state.loadingOrgData}
                />}

            >
                {!this.state.loadingSearchData && <div className='org-profile-pagination-header'>
                    <span className='results-count'>
                        <ResultsCount totalCount={numResults}/>
                    </span>
                    <div className='lxc-hide-mobile'>
                        <PaginationSizeOptions
                            name={intl.formatMessage(messages.paginationSizeLabel)}
                            value={this.currentPaginationSize}
                            onChange={this.onPaginationSizeSelect}
                            options={PAGINATION_OPTIONS}
                        />
                    </div>
                    <SortOptions
                        name={intl.formatMessage(messages.sortByLabel)}
                        value={this.currentOrdering}
                        onChange={this.onSortChange}
                        options={[
                            { label: SORT_OPTIONS_RELEVANCE[locale], value: SearchRequestOrderingEnum.Relevance },
                        ]}
                    />
                </div>}
                <div className='org-assets-list-wrapper'>
                    {!this.state.loadingSearchData && this.state.searchResults === undefined && <p>Error: Unable to load search results</p>}
                    <ul className='list-unstyled cards-list'>
                        {this.state.results.map((item: ItemResponse,  index) => (
                            <li key={index}>
                                <Card
                                    metadata={item.metadata}
                                    mobileViewMode={true}
                                    detailUrl={detailUrlForEntity(item.metadata)}
                                    userAttributes={item.userAttributes}
                                    showSkeleton={this.state.loadingSearchData}
                                />
                            </li>
                        ))}
                    </ul>
                    {!this.state.loadingSearchData && <CardsListBottomBar
                        isMobileView={false}
                        currentPage={this.state.currentPage}
                        onPageJump={this.onPageSelect}
                        onPageNavigate={this.onPageSelect}
                        onSizeSelect={this.onPaginationSizeSelect}
                        loadMore={this.loadMore}
                        pageCount={pageCount}
                        paginationSize={this.currentPaginationSize}
                    />}
                </div>
            </StandardPageLayout>
        );

    }

    private get params() {
        const params = new URLSearchParams(this.props.location.search);
        return params;
    }

    private get orgSlug(): string {
        return this.props.match.params.slug;
    }

    private get contentType(): ContentTypeParam {
        return this.props.match.params.contentType;
    }

    private get currentPaginationSize(): number {
        const params = new URLSearchParams(this.props.location.search);
        return getPaginationSizeFromString(params.get('pagination_size'), DEFAULT_PAGE_SIZE, PAGINATION_OPTIONS);
    }

    private get currentOrdering(): SearchRequestOrderingEnum {
        const params = new URLSearchParams(this.props.location.search);
        switch (params.get('order')) {
            case SearchRequestOrderingEnum.Relevance:
            default:
                return SearchRequestOrderingEnum.Relevance;
        }
    }

    @bind private onSortChange(sortOrder: string) {
        this.updateURLParams('order', sortOrder);
    }

    @bind private onPageSelect(page: number) {
        this.updateURLParams('page', page.toString());
    }

    @bind private onPaginationSizeSelect(paginationSize: number) {
        this.updateURLParams('pagination_size', paginationSize.toString());
    }

    @bind private loadMore() {
        this.setState({
            showMore: true,
            currentPage: this.state.currentPage + 1,
        }, this.fetchSearchResults);
    }

    private updateURLParams(param: 'order'|'page'|'pagination_size', value: string) {
        const params = new URLSearchParams(this.props.location.search);
        params.set(param, value);
        this.props.history.push(
            ROUTES.Organizations.PROFILE_SLUG(this.props.match.params.slug, this.props.match.params.contentType) +
            `?${params.toString()}`,
        );
    }

    private loadOrganization() {
        this.setState({
            orgData: undefined,
            loadingOrgData: true,
            searchResults: undefined,
            loadingSearchData: true,
        });
        this.fetchOrgProfile();
    }

    private switchContentTab() {
        this.setState({
            loadingSearchData: true,
            results: replicateElement(skeletonItem, 8),
            currentPage: this.props.location.search ? getCurrentPageFromString(this.params.get('page')) : 1,
        }, this.fetchSearchResults);
    }

    private async fetchOrgProfile() {
        const orgSlug = this.props.match.params.slug;
        let orgResponse: OrganizationResponse|undefined;
        try {
            orgResponse = await OrganizationsApi.retrievePartner({id: orgSlug});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadFailed}/>, {exception: err});
            // this.state.orgData will also get set to undefined below, showing another error message.
        }
        if (orgSlug === this.orgSlug) { // Don't overwrite newer results, if the org changed during 'await'
            this.setState({orgData: orgResponse, loadingOrgData: false}, async () => {
                await this.fetchSearchResults();
            });
        }
    }

    private isAlternativeOrganization() {
        return this.orgSlug in alternativeOrganizations;
    }

    /**
     * Search for all public content where the "source" is linked to this organization.
     */
    private async fetchSearchResults() {
        const orgId = this.state.orgData?.org.id;
        if (orgId === undefined) {
            return;
        }

        // All search params are captured in this string:
        const fetchedFor = this.props.location.search;
        const searchRequest = {
            filters: [
                {filterOn: AppliedFilterFilterOnEnum.Partner, filterValues: [orgId]},
            ],
            exclude: [] as AppliedFilter[],
            currentPage: this.state.currentPage,
            paginationSize: this.currentPaginationSize,
            ordering: this.currentOrdering,
        };
        if (this.contentType === 'cluster') {
            searchRequest.filters.push(
                {filterOn: AppliedFilterFilterOnEnum.ItemType, filterValues: [ItemType.Cluster]},
            );
        } else if (this.contentType === 'pathway') {
            searchRequest.filters.push(
                {filterOn: AppliedFilterFilterOnEnum.ItemType, filterValues: [ItemType.Pathway]},
            );
        } else if (this.contentType === 'content') {
            // Filter on all types except pathway and cluster.
            // We can't use .exclude because it excludes results from aggregations as well
            // and we need to know how many pathways/clusters the org has.
            searchRequest.filters.push(
                {
                    filterOn: AppliedFilterFilterOnEnum.ItemType,
                    filterValues: Object.values(ItemType).filter(
                        (it) => it !== ItemType.Pathway && it !== ItemType.Cluster,
                    ),
                },
            );
        }
        let searchResults: SearchResults|undefined;
        try {
            searchResults = await SearchApi.librarySearch({data: searchRequest});
        } catch (err) {
            searchResults = undefined; // undefined results + loadingSearchData false will show an error message.
        }

        let results = (searchResults && searchResults.results) || [];
        if (this.state.showMore && this.state.searchResults && searchResults) {
            results = this.state.results.concat(searchResults.results);
        }
        if (this.props.location.search === fetchedFor) { // Avoid overwriting newer results
            this.setState({results, searchResults, loadingSearchData: false});
        }
    }

    /**
     * Using the search results (specifically its aggregation data),
     * determine how many items of each category (cluster, pathway, content, all)
     * the organization has.
     *
     * Content mean non-pathway, non-cluster
     */
    private get itemCounts(): ItemCounts {
        if (this.state.searchResults === undefined) {
            return {all: 0, cluster: 0, pathway: 0, content: 0};
        }
        const buckets: {key: ItemType, doc_count: number}[] = (
            this.state.searchResults.aggregations.ItemType.buckets
        );

        const clusterItemsBucket = buckets.find((item) => item.key === ItemType.Cluster);
        const pathwayItemsBucket = buckets.find((item) => item.key === ItemType.Pathway);
        const contentItemsBuckets = buckets.filter(
            (item) => item.key !== ItemType.Pathway && item.key !== ItemType.Cluster,
        );
        const cluster = clusterItemsBucket ? clusterItemsBucket.doc_count : 0;
        const pathway = pathwayItemsBucket ? pathwayItemsBucket.doc_count : 0;
        const content = contentItemsBuckets.reduce((num, item) => num + item.doc_count, 0);
        const all = content + cluster + pathway;
        return {all, cluster, pathway, content};
    }
}
