// Pathway cover image options.
// These are all hardcoded urls because we have a fixed set of images to choose
// from (images in this directory).

export interface ImageOption {
    value: string;
    label: string;
}

const imageOptions: ImageOption[] = [
    {
        value: 'abstract.jpg',
        label: 'Abstract 1',
    },
    {
        value: 'abstract_2.jpg',
        label: 'Abstract 2',
    },
    {
        value: 'abstract_3.jpg',
        label: 'Abstract 3',
    },
    {
        value: 'bacteria_1.jpg',
        label: 'Bacteria 1',
    },
    {
        value: 'bacteria_3.jpg',
        label: 'Bacteria 3',
    },
    {
        value: 'bacteria_blue.jpg',
        label: 'Bacteria Blue',
    },
    {
        value: 'bacteria_red.jpg',
        label: 'Bacteria Red',
    },
    {
        value: 'blood.jpg',
        label: 'Blood 1',
    },
    {
        value: 'cell_-_mitosis_1.jpg',
        label: 'Cell Mitosis1',
    },
    {
        value: 'cells_1.jpg',
        label: 'Cells 1',
    },
    {
        value: 'cells_10.jpg',
        label: 'Cells 10',
    },
    {
        value: 'cells_2.jpg',
        label: 'Cells 2',
    },
    {
        value: 'cells_4.jpg',
        label: 'Cells 4',
    },
    {
        value: 'cells_5.jpg',
        label: 'Cells 5',
    },
    {
        value: 'cells_6.jpg',
        label: 'Cells 6',
    },
    {
        value: 'cells_7.jpg',
        label: 'Cells 7',
    },
    {
        value: 'cells_8.jpg',
        label: 'Cells 8',
    },
    {
        value: 'cells_9.jpg',
        label: 'Cells 9',
    },
    {
        value: 'cells_blue_2.jpg',
        label: 'Cells Blue 2',
    },
    {
        value: 'cells_blue.jpg',
        label: 'Cells Blue',
    },
    {
        value: 'cells_red_2.jpg',
        label: 'Cells Red 2',
    },
    {
        value: 'cells_red.jpg',
        label: 'Cells Red',
    },
    {
        value: 'dna_1.jpg',
        label: 'DNA 1',
    },
    {
        value: 'dna_2.jpg',
        label: 'DNA 2',
    },
    {
        value: 'dna_3.jpg',
        label: 'DNA 3',
    },
    {
        value: 'dna_4.jpg',
        label: 'DNA 4',
    },
    {
        value: 'dna_5.jpg',
        label: 'DNA 5',
    },
    {
        value: 'enzyme_4.jpg',
        label: 'Enzyme 4',
    },
    {
        value: 'evolution_-_fossil.jpg',
        label: 'Evolution Fossil',
    },
    {
        value: 'evolution_-_vertebrate_1.jpg',
        label: 'Evolution Vertebrate 1',
    },
    {
        value: 'evolution_-_vertebrate_2.jpg',
        label: 'Evolution Vertebrate 2',
    },
    {
        value: 'evolution_1.jpg',
        label: 'Evolution 1',
    },
    {
        value: 'evolution_2.jpg',
        label: 'Evolution 2',
    },
    {
        value: 'lab_equipment.jpg',
        label: 'Lab Equipment',
    },
    {
        value: 'lab_equipment_1.jpg',
        label: 'Lab Equipment 1',
    },
    {
        value: 'lab_equipment_2.jpg',
        label: 'Lab Equipment 2',
    },
    {
        value: 'life_-_bees.jpg',
        label: 'Life Bees',
    },
    {
        value: 'life_-_leaves_copy_2.jpg',
        label: 'Life Leaves Copy 2',
    },
    {
        value: 'life_-_leaves_copy.jpg',
        label: 'Life Leaves Copy',
    },
    {
        value: 'life_-_leaves.jpg',
        label: 'Life Leaves',
    },
    {
        value: 'life.jpg',
        label: 'Life',
    },
    {
        value: 'molecules.jpg',
        label: 'Molecules',
    },
    {
        value: 'plasmid.jpg',
        label: 'Plasmid',
    },
    {
        value: 'programing_1.jpg',
        label: 'Programing 1',
    },
    {
        value: 'virus_1.jpg',
        label: 'Virus 1',
    },
    {
        value: 'virus_2.jpg',
        label: 'Virus 2',
    },
    {
        value: 'virus.jpg',
        label: 'Virus',
    },
    {
        value: 'background-blue-desktop.png',
        label: 'Background Blue Desktop',
    },
    {
        value: 'bacteria_2.jpg',
        label: 'Bacteria 2',
    },
    {
        value: 'bacteria_4.jpg',
        label: 'Bacteria 4',
    },
    {
        value: 'blood2.jpg',
        label: 'Blood 2',
    },
    {
        value: 'cell_-_neuron_1.jpg',
        label: 'Cell Neuron 1',
    },
    {
        value: 'cell_-_neuron_2.jpg',
        label: 'Cell Neuron 2',
    },
    {
        value: 'cells_3.jpg',
        label: 'Cells 3',
    },
    {
        value: 'cells.jpg',
        label: 'Cells',
    },
    {
        value: 'enzym_5.jpg',
        label: 'Enzyme 5',
    },
    {
        value: 'enzym_6.jpg',
        label: 'Enzyme 6',
    },
    {
        value: 'enzyme_1.jpg',
        label: 'Enzyme 1',
    },
    {
        value: 'enzyme_2.jpg',
        label: 'Enzyme 2',
    },
    {
        value: 'enzyme_3.jpg',
        label: 'Enzyme 3',
    },
    {
        value: 'gel_electrophoresis_1.jpg',
        label: 'Gel Electrophoresis 1',
    },
    {
        value: 'gel_electrophoresis_2.jpg',
        label: 'Gel Electrophoresis 2',
    },
    {
        value: 'gel_electrophoresis_3.jpg',
        label: 'Gel Electrophoresis 3',
    },
    {
        value: 'gel_electrophoresis_4.jpg',
        label: 'Gel Electrophoresis 4',
    },
    {
        value: 'gel_electrophoresis_5.jpg',
        label: 'Gel Electrophoresis 5',
    },
    {
        value: 'gel_electrophoresis_6.jpg',
        label: 'Gel Electrophoresis 6',
    },
    {
        value: 'genetic_engineering_1.jpg',
        label: 'Genetic Engineering 1',
    },
    {
        value: 'genetic_engineering_2.jpg',
        label: 'Genetic Engineering 2',
    },
    {
        value: 'genetic_engineering_3.jpg',
        label: 'Genetic Engineering 3',
    },
    {
        value: 'genetic_engineering_4.jpg',
        label: 'Genetic Engineering 4',
    },
    {
        value: 'lab_equipment_-_chemicals.jpg',
        label: 'Lab Equipment Chemicals',
    },
    {
        value: 'lab_equipment_-_micropipette_1.jpg',
        label: 'Lab Equipment Micropipette',
    },
    {
        value: 'lab_equipment_-_micropipette_2.jpg',
        label: 'Lab Equipment Micropipette',
    },
    {
        value: 'lab_equipment_1.jpg',
        label: 'Lab Equipment 1',
    },
    {
        value: 'programing_2.jpg',
        label: 'Programing 2',
    },
    {
        value: 'protein_1.jpg',
        label: 'Protein 1',
    },
];

export const ImageOptions = imageOptions.sort((a, b) => (
    (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.value > b.value) ? 1 : -1) : -1
));
