
import * as React from 'react';

import { ItemType } from 'items/models';
import { WrappedMessage, useLayoutSize } from 'utils';

import messages from '../../../explore/displayMessages';
import ContentTypeCard from './ContentTypeCard';
import { ContentType } from './types';
import Skeleton from 'react-loading-skeleton';

const defaultContentTypes: ContentType[] = [
  {
    name: 'Simulations',
    image: '/assets/images/content-types/simulations.svg',
    itemType: ItemType.Simulation,
    url: `/library?t=ItemType%3A${ItemType.Simulation}`,
  },
  {
    name: 'Interactives',
    image: '/assets/images/content-types/interactives.svg',
    itemType: ItemType.Interactive,
    url: `/library?t=ItemType%3A${ItemType.Interactive}`,
  },
  {
    name: 'Pathways',
    image: '/assets/images/content-types/pathways.svg',
    itemType: ItemType.Pathway,
    url: `/library?t=ItemType%3A${ItemType.Pathway}`,
  },
  {
    name: 'Case studies',
    image: '/assets/images/content-types/case_studies.svg',
    itemType: ItemType.CaseStudy,
    url: `/library?t=ItemType%3A${ItemType.CaseStudy}`,
  },
  {
    name: 'Clusters',
    image: '/assets/images/content-types/clusters.svg',
    itemType: ItemType.Cluster,
    url: `/library?t=ItemType%3A${ItemType.Cluster}`,
  },
  {
    name: 'Videos',
    image: '/assets/images/content-types/videos.svg',
    itemType: ItemType.Video,
    url: `/library?t=ItemType%3A${ItemType.Video}`,
  },
  {
    name: 'Question sets',
    image: '/assets/images/content-types/question_sets.svg',
    itemType: ItemType.Question,
    url: `/library?t=ItemType%3A${ItemType.Assignment} `,
  },
  {
    name: 'Teaching guides',
    image: '/assets/images/content-types/teaching_guides.svg',
    itemType: ItemType.TeachingGuide,
    url: `/library?t=ItemType%3A${ItemType.TeachingGuide}`,
  },
  {
    name: 'Annotated videos',
    image: '/assets/images/content-types/annotated_videos.svg',
    itemType: ItemType.AnnotatedVideo,
    url: `/library?t=ItemType%3A${ItemType.AnnotatedVideo}`,
  }
];

interface ContentTypesSectionProps {
  contentTypes?: ContentType[];
  loading?: boolean;
}

export const ContentTypesSection: React.FC<ContentTypesSectionProps> = (props) => {
  const contentTypes = props.contentTypes?.length ? props.contentTypes : defaultContentTypes;
  const layoutSize = useLayoutSize();
  const isMobile = layoutSize === 'mobile' || layoutSize === 'small';

  return (
    props.loading ?
      <div className='content-section'>
        <Skeleton width={isMobile ? 300 : 400} height={50} />
          <div className='content-type-cards-list'>
            <div className='content-type-cards-list'>
              {Array.from({ length: 9 }, (_, index) => (
                <div className='content-type-card'>
                  <Skeleton width={51} height={51}/>
                  <Skeleton width={106} height={24} />
                </div>
              ))}
          </div>
        </div>
      </div>
    :
    <div className='content-section'>
      <div className='content-section-header'>
          <h1 className='content-heading'>
              <WrappedMessage message={messages.exploreByContentType} />
          </h1>
      </div>
      <div className='content-type-cards-list'>
        {contentTypes.map((contentType) => (
          <ContentTypeCard
            key={contentType.name}
            name={contentType.name}
            image={contentType.image}
            contentType={contentType.itemType}
            url={contentType.url}
            showNewTag={contentType.itemType === ItemType.AnnotatedVideo}
          />
        ))}
      </div>
    </div>
  );
};

export default ContentTypesSection;
