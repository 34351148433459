import { getDefaultState } from '../../models';
import * as ActionTypes from '../actions/action-types';

const MutliSelectAnswersReducer = function (state = getDefaultState().multiSelectAnswers, action: any) {
    switch (action.type) {
        case ActionTypes.MULTI_SELECT_ANSWERS_ADD_NEW:
            const len = state.multiSelectAnswersList.length;
            // 65 is charcode for 'A'
            const nextId = String.fromCharCode(65 + len);
            const emptyAnswer = {
                id: nextId, title: '', correct: false, selectedFeedback: "", unselectedFeedback: "", answer: ""
            };
            return {
                ...state,
                multiSelectAnswersList: state.multiSelectAnswersList.concat([emptyAnswer])
            };
        case ActionTypes.MULTI_SELECT_ANSWERS_REMOVE:
            const newMultiSelectAnswersList = state.multiSelectAnswersList.filter((multi: any) => multi.id !== action.id);
            return {
                ...state,
                multiSelectAnswersList: newMultiSelectAnswersList,
            };
        case ActionTypes.MULTI_SELECT_ANSWERS_CHANGED:
            let numberOfCorrects = 0
            for (const multi of state.multiSelectAnswersList) {
                if (multi.correct) numberOfCorrects += 1;
            }
            return {
                ...state,
                multiSelectAnswersList: state.multiSelectAnswersList.map((multi: any) => {
                    if (multi.id === action.id) {
                        return {
                            id: action.id,
                            title: action.title,
                            correct: (numberOfCorrects === 1 && multi.correct) ? true : action.correct,
                            selectedFeedback: action.selectedFeedback,
                            unselectedFeedback: action.unselectedFeedback,
                            answer: action.answer
                        }
                    } else {
                        return multi;
                    }
                })
            };
        // GeneralFeedback
        case ActionTypes.GENERAL_FEEDBACK_CHANGE:
            let generalFeedbackList = [{ ...action }];
            state.generalFeedbackList.forEach((el: any) => {
                if (el.id !== action.id) {
                    generalFeedbackList.push(el);
                }
            });
            return {
                ...state,
                generalFeedbackList: generalFeedbackList
            };
        default:
            return state;
    }
};

export default MutliSelectAnswersReducer;
