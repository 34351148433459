/**
 * Generates a data URL of format 'data:${mimeType};base64,${base64Data}' from an image file.
 *
 * @param {File} imageFile - The image file to generate the data URL from.
 * @returns {Promise<string | null>} A promise that resolves with the generated data URL
 *                                     or empty string "" if the input file is invalid.
 */
export function generateDataUrlFromImage(imageFile: File) {
    return new Promise((resolve, reject) => {
        if (imageFile) {
            const reader = new FileReader();
            reader.readAsBinaryString(imageFile);
            reader.onload = () => {
                const base64Data = btoa(reader.result as string);
                const mimeType = imageFile.type;
                const dataUrl = `data:${mimeType};base64,${base64Data}`;
                resolve(dataUrl);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        } else {
            resolve('');
        }
    });
}
