import { Action } from 'redux';

export enum Types {
    SHOW_MODAL = 'SHOW_MODAL',
    DISMISS_MODAL = 'DISMISS_MODAL'
}

export interface ShowModal extends Action {
    readonly type: Types.SHOW_MODAL;
}

export interface DismissModal extends Action {
    readonly type: Types.DISMISS_MODAL;
}

export type ActionTypes = ShowModal | DismissModal;
