import React from 'react';

interface StepperProps {
    currentStep: number;
    totalSteps: number;
}

const Stepper: React.FC<StepperProps> = ({ currentStep, totalSteps }) => {
    const stepsArray = Array.from({ length: totalSteps }, (_, index) => index + 1);

    return (
        <div className='stepper' data-testid='stepper'>
            {stepsArray.map((step) => (
                <div
                    key={step}
                    className={`step ${step <= currentStep ? 'active' : ''}`}
                >
                    <div className='line'></div>
                </div>
            ))}
        </div>
    );
};

export default Stepper;
