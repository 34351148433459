import defaultClassroomImage from 'assets/images/default-classroom.png';
import * as React from 'react';
import { Icon } from 'ui/components';
import { CardDetails, CardDetailsStats, CardMedia, CardMediaOverlayText, CardStandard } from 'ui/components/Card/Cards';
import { KebabMenu, KebabMenuItem } from 'ui/components/KebabMenu';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface EducatorDashboardActiveClassroomCardProps extends KebabMenuProps {
    id: string;
    title: string;
    text: string;
    code: React.ReactNode;
    itemCount?: number;
    membershipsCount?: number;
    imageUrl?: string;
    hideMenu?: boolean;
    isSelected?: boolean;
    onClick: () => void;
    showSkeleton?: boolean;
}

interface KebabMenuProps {
    onCopy?: () => void;
    onShare?: () => void;
    onArchive?: () => void;
    onDelete?: () => void;
    onSelect?(): void;
}

export const EducatorDashboardActiveClassroomCard: React.FC<EducatorDashboardActiveClassroomCardProps> = (
    { id, title, text, code, itemCount, membershipsCount, imageUrl, hideMenu, isSelected, showSkeleton,
        onClick, onCopy, onShare, onArchive, onDelete, onSelect }) => (
        <CardStandard
            additionalStyling={
                `educator-dashboard-card-component ` +
                `${isSelected ? 'selected' : ''}`
            }
            media={
                <CardMedia
                    mediaUrl={imageUrl || defaultClassroomImage}
                    overlays={
                        [
                            <CardMediaOverlayText
                                message={
                                    <WrappedMessage
                                        message={messages.classCodeOverlayText}
                                        values={{ code }}
                                    />
                                }
                                additionalStyling='media-overlay-position-bottom-left'
                                key='classCodeCardMediaOverlay'
                            />,
                        ]
                    }
                    showSkeleton={showSkeleton}
                />
            }
            details={
                <CardDetails
                    title={title}
                    text={text}
                    showSkeleton={showSkeleton}
                />
            }
            barBottom={
                <>
                    <CardDetailsStats
                        text={
                            <>
                                <WrappedMessage
                                    message={messages.cardMetadataLearnerCount}
                                    values={{ learnerCount: membershipsCount }}
                                />
                                <div> ● </div>
                                <WrappedMessage
                                    message={messages.cardMetadataAssignedItemsCount}
                                    values={{ itemCount }}
                                />
                            </>
                        }
                        showSkeleton={showSkeleton}
                    />

                    {onSelect &&
                        <button
                            className={
                                `unstyled select-classroom-card ` +
                                `${isSelected ? 'select-classroom-card-selected' : ''}`}
                            onClick={
                                (e) => {
                                    e.stopPropagation();
                                    onSelect();
                                }}>
                            {isSelected
                                ? <>Selected</>
                                : <><Icon name='plus'/>Select</>}
                        </button>
                    }
                </>
            }
            showBarBottomSeparator={false}
            showBarTopSeparator={false}
            onClick={onClick}
            showSkeleton={showSkeleton}
        >
            {!showSkeleton ? !hideMenu ?
                <KebabMenu buttonId={`classroom-card-menu-${id}`}>
                    <KebabMenuItem
                        iconName='diff'
                        message={messages.copyMenuText}
                        onClick={onCopy}
                        disabled={false}
                    />
                    <KebabMenuItem
                        iconName='link-external'
                        message={messages.shareMenuText}
                        onClick={onShare}
                        disabled={true /* TODO: implement and enable */}
                    />
                    <KebabMenuItem
                        iconName='archive'
                        message={messages.archiveMenuText}
                        onClick={onArchive}
                        disabled={false}
                    />
                    <KebabMenuItem
                        iconName='trashcan'
                        message={messages.deleteMenuText}
                        onClick={onDelete}
                        disabled={false}
                    />
                </KebabMenu>
            : null : null}
        </CardStandard>

    );
