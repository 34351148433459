import { defineMessages } from 'react-intl';

const messages = defineMessages({
    errorCreate: {
        id:'errorCreate',
        defaultMessage: 'Unable to create your report. Please try again later.',
        description: 'Error while creating report.',
    },
    modalSuccessTitle: {
        id:'modalSuccessTitle',
        defaultMessage: 'Report submitted',
        description: 'Title for success report modal.',
    },
    modalSuccessDescription: {
        id:'modalSuccessDescription',
        defaultMessage: `This content will be reviewed as soon as possible.
         You may receive an email from the site admins if they require further clarification about your report.`,
        description: 'Description for success report modal.',
    },
    modalTitle: {
        id:'modalTitle',
        defaultMessage: 'Do you want to report "{title}"?',
        description: 'Title for report modal.',
    },
    modalDescription: {
        id:'modalDescription',
        defaultMessage: `Your report will be reviewed as soon as possible.
         By reporting this content, you consent to receive email from the site admins at
         the address associated with your LabXchange account.
         You will be contacted if further information is required.`,
        description: 'Body for report modal.',
    },
    modalSubmitButton: {
        id:'modalSubmitButton',
        defaultMessage: 'Submit report',
        description: 'Submit button label for report modal.',
    },
    modalReasonLabel: {
        id:'modalReasonLabel',
        defaultMessage: 'Reason',
        description: 'Reason select label for report modal.',
    },
    modalDescriptionLabel: {
        id:'modalDescriptionLabel',
        defaultMessage: 'Description',
        description: 'Description input label for report modal.',
    },
    modalDescriptionPlaceholder: {
        id:'modalDescriptionPlaceholder',
        defaultMessage: 'Describe your reason for flagging this content',
        description: 'Description input placeholder for report modal.',
    },
    reasonDefault: {
        id:'reasonDefault',
        defaultMessage: '- Select -',
        description: 'Default reason for report modal.',
    },
    reasonAccessiblity: {
        id:'reasonAccessiblity',
        defaultMessage: 'Accessibility issue',
        description: 'Accessiblity issue reason for report modal.',
    },
    reasonOffensive: {
        id:'reasonOffensive',
        defaultMessage: 'Offensive / Inappropriate content',
        description: 'Offensive content reason for report modal.',
    },
    reasonUnavailable: {
        id:'reasonUnavailable',
        defaultMessage: 'Resource unavailable / Corrupted',
        description: 'Resource unavailable reason for report modal.',
    },
    reasonQualityIssue: {
        id:'reasonQualityIssue',
        defaultMessage: 'Quality issue / Incorrect information',
        description: 'Quality issue reason for report modal.',
    },
    reasonCopyright: {
        id:'reasonCopyright',
        defaultMessage: 'Copyright violation',
        description: 'Copyright violation reason for report modal.',
    },
    reasonOther: {
        id:'reasonOther',
        defaultMessage: 'Other',
        description: 'Other reason for report modal.',
    },
});

export default messages;
