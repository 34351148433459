// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Organization,
    OrganizationFromJSON,
    OrganizationToJSON,
    OrganizationsObjectPermissions,
    OrganizationsObjectPermissionsFromJSON,
    OrganizationsObjectPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationResponse
     */
    org: Organization;
    /**
     * 
     * @type {OrganizationsObjectPermissions}
     * @memberof OrganizationResponse
     */
    permissions: OrganizationsObjectPermissions;
}

export function OrganizationResponseFromJSON(json: any): OrganizationResponse {
    return {
        'org': OrganizationFromJSON(json['org']),
        'permissions': OrganizationsObjectPermissionsFromJSON(json['permissions']),
    };
}

export function OrganizationResponseToJSON(value?: OrganizationResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'org': OrganizationToJSON(value.org),
        'permissions': OrganizationsObjectPermissionsToJSON(value.permissions),
    };
}


