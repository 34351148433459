// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    AnnotatedVideoAnnotation,
    AnnotatedVideoAnnotationFromJSON,
    AnnotatedVideoAnnotationToJSON,
    ItemMetadata,
    ItemMetadataFromJSON,
    ItemMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnnotatedVideoDataState
 */
export interface AnnotatedVideoDataState {
    /**
     * 
     * @type {ItemMetadata}
     * @memberof AnnotatedVideoDataState
     */
    video: ItemMetadata;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoDataState
     */
    videoYoutubeId: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoDataState
     */
    videoPoster: string;
    /**
     * 
     * @type {Array<AnnotatedVideoAnnotation>}
     * @memberof AnnotatedVideoDataState
     */
    annotations: Array<AnnotatedVideoAnnotation>;
}

export function AnnotatedVideoDataStateFromJSON(json: any): AnnotatedVideoDataState {
    return {
        'video': ItemMetadataFromJSON(json['video']),
        'videoYoutubeId': json['video_youtube_id'],
        'videoPoster': json['video_poster'],
        'annotations': (json['annotations'] as Array<any>).map(AnnotatedVideoAnnotationFromJSON),
    };
}

export function AnnotatedVideoDataStateToJSON(value?: AnnotatedVideoDataState): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'video': ItemMetadataToJSON(value.video),
        'video_youtube_id': value.videoYoutubeId,
        'video_poster': value.videoPoster,
        'annotations': (value.annotations as Array<any>).map(AnnotatedVideoAnnotationToJSON),
    };
}


