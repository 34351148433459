import { getStore } from 'global/store';
import {
    DismissModal,
    ShowModal, Types
} from './actions';

export function showLoginModal() {
    getStore().dispatch<ShowModal>({type: Types.SHOW_MODAL});
}

export function dismissModal() {
    getStore().dispatch<DismissModal>({type: Types.DISMISS_MODAL});
}
