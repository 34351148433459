import { bind } from 'bind-decorator';
import * as React from 'react';
import { ItemSection, TextBox } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { AudioEditorState, SpecificEditorProps, AudioTranscripts } from './models';
import { AudioTranscriptEditor } from './AudioTranscriptEditor';
import { intl } from 'i18n';

type AudioBlockEditorProps = SpecificEditorProps<AudioEditorState>;

/**
 * Editor UI for Audio components (lx_audio)
 */
export class AudioBlockEditor extends React.PureComponent<AudioBlockEditorProps, {}> {

    constructor(props: AudioBlockEditorProps) {
        super(props);
    }

    public render() {
        const editorState = this.props.editorState;
        if (editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return <>
                <ItemSection
                    sectionName='audio-embed'
                    extraClasses='audio-embed'>
                    <div className='logos'>
                        <div className='soundcloud-logo' />
                        <div className='stitcher-logo' />
                    </div>
                    <p>
                        <WrappedMessage message={messages.audioEditorEmbedCodeInstructions} />
                        <a href='https://labxchange.zendesk.com/hc/en-us/articles/360046382974'
                           target='_blank' rel='noopener noreferrer'
                        >
                            <WrappedMessage message={messages.audioEditorEmbedCodeInstructionsLinkName} />
                        </a>
                    </p>
                    <TextBox
                        label={<WrappedMessage message={messages.audioEditorEmbedCodeLabel} />}
                        required={true}
                        showErrors={this.props.showErrors}
                        value={editorState.embedCode}
                        placeholder={intl.formatMessage(messages.audioEditorEmbedCodePlaceholder)}
                        onChange={this.onEmbedCodeChange}
                    />
                </ItemSection>
                <ItemSection
                    title={intl.formatMessage(uiMessages.transcriptEditorTranscriptsSectionLabel)}
                    sectionName='transcript'
                    extraClasses='transcripts'
                >
                    <AudioTranscriptEditor
                        transcripts={editorState.audioTranscripts}
                        onChange={this.onTranscriptsChange}
                    />
                </ItemSection>
            </>;
        }
    }

    @bind private onTranscriptsChange(audioTranscripts: AudioTranscripts) {
        if (this.props.editorState === undefined) { return; }
        this.props.onEditorStateChanged(
            {...this.props.editorState, audioTranscripts},
        );
    }

    @bind private onEmbedCodeChange(embedCode: string) {
        if (this.props.editorState === undefined) { return; }
        this.props.onEditorStateChanged(
            {...this.props.editorState, embedCode},
        );
    }
}
