import { bind } from 'bind-decorator';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { intl } from 'i18n';

import MultiSelectItem from './MultiSelectItem';


const messages = defineMessages({
    title: {
        id: 'MultiSelectAnswers.title',
        defaultMessage: 'Answers*'
    },
    description: {
        id: 'MultiSelectAnswers.description',
        defaultMessage: 'Enter the answers below and select whether an answer is correct or incorrect. Remember, you can have more than one correct answer.'
    },
    btnAddNewAnswer: {
        id: 'MultiSelectAnswers.btnAddNewAnswer',
        defaultMessage: '+ Add answer'
    }
})


export default class MultiSelectAnswers extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            focusLastAnswer: false,
        };
    }

    @bind private moveFocusToNewlyAddedAnswer() {
        this.setState({
            focusLastAnswer: true
        });
    }

    componentDidUpdate(prevProps: any) {
        const { answersList } = this.props;
        if (answersList.length === prevProps.answersList.length + 1) {
            this.moveFocusToNewlyAddedAnswer(); 
        }
    }

    render() {
        const { formatMessage } = intl;
        return (
            <fieldset className='lxc-answers-wrapper'>
                <legend className='lxc-answers-title'>
                    {formatMessage(messages.title)}
                </legend>
                <div className='lxc-answers-description'>
                    {formatMessage(messages.description)}
                </div>
                <div className='lxc-answers-list lxc-answers-list_multi'>
                    {
                        this.props.answersList.map((answer: any, i: number) => {
                            return <MultiSelectItem 
                                        key={i} {...answer}
                                        multiSelectChangeAnswer={this.props.multiSelectChangeAnswer}
                                        multiSelectRemoveAnswer={this.props.multiSelectRemoveAnswer}
                                        answersList={this.props.answersList}
                                        focusAnswer={i===this.props.answersList.length-1 ? this.state.focusLastAnswer:false}
                                    />
                        })
                    }
                    <div className='lxc-answers-another-option'>
                        <button className='lxc-answers-another-option-btn' type='button' onClick={this.props.multiSelectAddAnswer}>
                            {
                                this.props.answersList.length ? (
                                    <>+ Add <span className='lxc-hide-mobile'>another</span> answer</>
                                ) : formatMessage(messages.btnAddNewAnswer)
                            }
                        </button>
                    </div>
                </div>
            </fieldset>
        );
    }
}
