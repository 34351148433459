import { bind } from 'bind-decorator';
import { isKeyboardEvent } from 'global/utils';
import { ItemProgress, ItemProgressTypeEnum } from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import moment from 'moment-shortformat';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { AssignedItemProgress } from './AssignedItemProgress';
import { Icon } from 'ui/components';
import { ItemIcon } from 'library/components';
import { KebabMenu, KebabMenuItem } from 'ui/components/KebabMenu';
import { showWarningMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import Skeleton from 'react-loading-skeleton';

interface AssignedItemRowProps {
    itemProgress: ItemProgress;
    unassignItem?: (item: ItemProgress) => void;
    markAsIncomplete?: (item: ItemProgress) => void;
    showSkeleton?: boolean;
}

interface AssignedItemRowState {
    folded: boolean;
}

/**
 * Display an assigned item in a progress report.
 * The assigned item is a pathway, assignment, or other item assigned to a user
 * by a classroom educator or by a mentor.
 */
export class AssignedItemRow extends React.PureComponent<AssignedItemRowProps, AssignedItemRowState> {

    constructor(props: AssignedItemRowProps) {
        super(props);
        this.state = {
            folded: true,
        };
    }

    public render() {
        const itemProgress = this.props.itemProgress;
        const titleUrl = detailUrlForEntity({
            id: itemProgress.itemId,
            type: itemProgress.type,
        });

        const statusIcon = this.getItemProgressStatus();
        return (
            <div className='assignment'
                 tabIndex={0}
                 role='button'
                 onClick={this.onToggleAssignedItem}
                 onKeyPress={this.onToggleAssignedItem}
                 aria-expanded={!this.state.folded}
            >
                <div className={`parent-assignment ${!this.props.showSkeleton ? statusIcon : ''}`}>
                    <div className={`status-icon ${statusIcon} ${this.props.showSkeleton ? 'skeleton' : ''}`} />
                    <div className='parent-assignment-description'>
                        {this.props.showSkeleton ?  <Skeleton /> : <>
                            <div className='icon-type'>
                                <ItemIcon itemType={itemProgress.type as any}/>
                            </div>
                            <NavLink to={titleUrl} className='title'>
                                {itemProgress.title}
                            </NavLink>
                        </>}
                    </div>
                    <div className='dates'>
                        {this.props.showSkeleton ? <Skeleton /> : <div className='lxc-hide-mobile'>
                            <WrappedMessage message={messages.assignedAt} /> {moment(itemProgress.assignedDate).format('YY.MM.DD')}
                            &nbsp;-&nbsp;
                            <AssignedItemProgress itemProgress={itemProgress}/>
                        </div>}
                    </div>
                    {itemProgress.children && itemProgress.children.length > 0 ?
                        <div className={`caret caret-${this.state.folded ? 'down' : 'up'}`}></div>
                        :
                        <div className='caret'></div>
                    }
                    <KebabMenu darkKebab={true}>
                        {this.props.unassignItem ?
                            <KebabMenuItem
                                iconName='trashcan'
                                message={messages.unassignContent}
                                onClick={this.unassignContentConfirm}
                                disabled={false}
                            />
                        : null}
                        {this.props.markAsIncomplete && itemProgress.completion ? <KebabMenuItem
                            iconName='redo'
                            message={messages.markAsIncomplete}
                            onClick={() => this.props.markAsIncomplete && this.props.markAsIncomplete(itemProgress)}
                            disabled={false}
                        /> : null}
                    </KebabMenu>
                </div>
                {this.renderChildItems()}
            </div>
        );

    }

    @bind private unassignContentConfirm() {
        const { itemProgress } = this.props;
        const confirmationMessage = <WrappedMessage
            message={messages.unassignContentConfirmModal}
            values={{ contentTitle: itemProgress.title }}
        />;
        showWarningMessage('', {
            title: confirmationMessage,
            confirmText: messages.unassignContent,
            onConfirm: () => this.props.unassignItem && this.props.unassignItem(itemProgress),
        });
    }

    private getItemProgressStatus() {
        const itemProgress = this.props.itemProgress;
        let statusIcon = itemProgress.completion === 1 ? 'success' : 'in-progress';
        // mark completed failed items
        const { completion } = itemProgress;
        let { weightedEarned, weightedPossible } = itemProgress;
        if (weightedEarned === undefined) {
            weightedEarned = 0;
        }
        if (weightedPossible === undefined) {
            weightedPossible = 0;
        }
        // assume 0 is failing score if the scorable xblock has been completed 100%
        if (completion === 1 && weightedEarned === 0 && weightedPossible > 0) {
            statusIcon = 'failure';
        }
        return statusIcon;
    }

    /** If this item is a pathway or assignment, show a breakdown of its child items. */
    private renderChildItems() {
        if (this.props.itemProgress.children?.length === 0 || this.state.folded) { return; }
        const assignedItemChildRows = this.props.itemProgress.children?.map(
            (itemProgress, index) => {
                const { completion, weightedEarned, weightedPossible } = itemProgress;
                const itemIsCompleted = completion === 1;
                // Default status is `in progress`
                let status = 'in-progress';
                let statusIcon = null;
                // Mark completed items
                if (itemIsCompleted) {
                    status = 'success';
                    statusIcon = <Icon name='check' />;
                }
                // assume 0 is failing score if the scorable xblock has been completed 100%
                if (itemIsCompleted && weightedEarned === 0 && weightedPossible > 0) {
                    status = 'failure';
                    statusIcon = <Icon name='x' />;
                }
                return (
                    <div key={`${index}-${this.props.itemProgress.id}`} className={`assessment ${status}`}>
                        <div className={`status-icon ${status}`}>
                            {statusIcon}
                        </div>
                        <div className='assessment-title'>
                            <NavLink to={detailUrlForEntity({id: itemProgress.itemId, type: itemProgress.type})}>
                                {itemProgress.title}
                            </NavLink>
                        </div>
                        {weightedPossible > 0 ? (
                            <>
                                <span>&nbsp;-&nbsp;</span>
                                <div className='assessment-score'>{weightedEarned}/{weightedPossible}</div>
                            </>
                        ) : null}
                    </div>
                );
            },
        );
        return (
            <div className='assessments'>
                {assignedItemChildRows}
            </div>
        );
    }

    @bind private onToggleAssignedItem(event: React.MouseEvent | React.KeyboardEvent) {
        if (isKeyboardEvent(event)) {
            if (event.key !== 'Enter') { return; }
        }
        if (this.props.itemProgress.children?.length === 0) { return; }
        event.preventDefault();
        this.setState({folded: !this.state.folded});
    }
}

interface AssignedItemsReportProps {
    itemsProgress: ItemProgress[];
    unassignItem?: (item: ItemProgress) => void;
    markAsIncomplete?: (item: ItemProgress) => void;
    showSkeleton?: boolean;
}

interface AssignedItemsReportState {
    folded: boolean;
}

/** A list of assigned items, shown in a grade/progress report. */
export class AssignedItemsReport extends React.PureComponent<AssignedItemsReportProps, AssignedItemsReportState> {

    constructor(props: AssignedItemsReportProps) {
        super(props);
        this.state = {
            folded: false,
        };
    }

    public render() {
        const assignedItemRows = this.props.itemsProgress.map(
            (itemProgress) => {
                return (
                    <AssignedItemRow
                        key={itemProgress.id}
                        itemProgress={itemProgress}
                        unassignItem={this.props.unassignItem}
                        showSkeleton={this.props.showSkeleton}
                        markAsIncomplete={this.props.markAsIncomplete}
                    />
                );
            },
        );
        return (
            <div className='assignments'>{this.props.showSkeleton? this.renderSkeletonRows() : assignedItemRows}</div>
        );
    }

    public renderSkeletonRows() {
        return [...Array(8)].map(() => {
            return <AssignedItemRow
                itemProgress={{
                    id: '',
                    itemId: '',
                    type: ItemProgressTypeEnum.Unknown,
                    title: '',
                    assignedDate: new Date(),
                }}
                showSkeleton={true}
            />;
        });
    }
}
