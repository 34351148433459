// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SubjectArea
 */
export interface SubjectArea {
    /**
     * 
     * @type {string}
     * @memberof SubjectArea
     */
    name: string;
    /**
     * Translation for subject area name
     * @type {string}
     * @memberof SubjectArea
     */
    areaName?: string;
}

export function SubjectAreaFromJSON(json: any): SubjectArea {
    return {
        'name': json['name'],
        'areaName': !exists(json, 'area_name') ? undefined : json['area_name'],
    };
}

export function SubjectAreaToJSON(value?: SubjectArea): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'area_name': value.areaName,
    };
}


