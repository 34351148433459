// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Organization,
    OrganizationFromJSON,
    OrganizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationMembership
 */
export interface OrganizationMembership {
    /**
     * 
     * @type {number}
     * @memberof OrganizationMembership
     */
    readonly id?: number;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationMembership
     */
    organization: Organization;
    /**
     * Designates that this user has permissions to administrater this organization
     * @type {boolean}
     * @memberof OrganizationMembership
     */
    isAdmin?: boolean;
    /**
     * Designates that this user has permissions to upload content to this organization
     * @type {boolean}
     * @memberof OrganizationMembership
     */
    isContentUploader?: boolean;
    /**
     * Designates that this user has permissions to view analytics of this organization
     * @type {boolean}
     * @memberof OrganizationMembership
     */
    isAnalyticsViewer?: boolean;
    /**
     * Designates that this user has permissions to publish content to the public library
     * @type {boolean}
     * @memberof OrganizationMembership
     */
    isContentPublisher?: boolean;
}

export function OrganizationMembershipFromJSON(json: any): OrganizationMembership {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organization': OrganizationFromJSON(json['organization']),
        'isAdmin': !exists(json, 'is_admin') ? undefined : json['is_admin'],
        'isContentUploader': !exists(json, 'is_content_uploader') ? undefined : json['is_content_uploader'],
        'isAnalyticsViewer': !exists(json, 'is_analytics_viewer') ? undefined : json['is_analytics_viewer'],
        'isContentPublisher': !exists(json, 'is_content_publisher') ? undefined : json['is_content_publisher'],
    };
}

export function OrganizationMembershipToJSON(value?: OrganizationMembership): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'organization': OrganizationToJSON(value.organization),
        'is_admin': value.isAdmin,
        'is_content_uploader': value.isContentUploader,
        'is_analytics_viewer': value.isAnalyticsViewer,
        'is_content_publisher': value.isContentPublisher,
    };
}


