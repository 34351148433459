import { defineMessages } from 'react-intl';

const messages = defineMessages({
    ariaMonthLabel: {
        id:'ariaMonthLabel',
        defaultMessage: 'Date month.',
        description: 'Aria label for month.',
    },
    ariaDayLabel: {
        id:'ariaDayLabel',
        defaultMessage: 'Date day.',
        description: 'Aria label for day.',
    },
    ariaYearLabel: {
        id:'ariaYearLabel',
        defaultMessage: 'Date year.',
        description: 'Aria label for year.',
    },
    defaultMonth: {
        id:'defaultMonth',
        defaultMessage: 'MM',
        description: 'Default date month option.',
    },
    defaultDay: {
        id:'defaultDay',
        defaultMessage: 'DD',
        description: 'Default date day option.',
    },
    defaultYear: {
        id:'defaultYear',
        defaultMessage: 'YYYY',
        description: 'Default date year option.',
    },
});

export default messages;
