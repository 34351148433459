import messages from 'library/displayMessages';
import * as React from 'react';
import { ItemSection } from 'ui/components';
import { WrappedMessage } from 'utils';
import { TagsEditor } from 'ui/components/TagsEditor';

interface TagEditorProps {
    tags: ReadonlyArray<string>;
    onChange?: (tags: string[]) => void;
    showErrors: boolean;
    required: boolean;
}

export class TagEditor extends React.Component<TagEditorProps> {

    public render() {
        const isError = this.props.required && this.props.tags.length === 0 && this.props.showErrors;
        return (
            <div className='tags-editor-common-wrapper'>
                <ItemSection
                    isMandatory={this.props.required}
                    sectionName='tag-editor'
                    title={<WrappedMessage message={messages.pathwayTagsEditor} />}
                >
                    <TagsEditor
                        placeholder={messages.pathwayTagsPlaceholder}
                        label={messages.pathwayTagsAddLabel}
                        tags={this.props.tags}
                        showErrors={isError}
                        required={this.props.required}
                        onChange={this.props.onChange}
                    />
                </ItemSection>
            </div>
        );
    }

}
