// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClusterItem,
    ClusterItemFromJSON,
    ClusterItemToJSON,
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
    OrganizationSummary,
    OrganizationSummaryFromJSON,
    OrganizationSummaryToJSON,
    Tour,
    TourFromJSON,
    TourToJSON,
} from './';

/**
 * 
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    type: ClusterTypeEnum;
    /**
     * 
     * @type {Array<Tour>}
     * @memberof Cluster
     */
    tours: Array<Tour>;
    /**
     * 
     * @type {Array<ClusterItem>}
     * @memberof Cluster
     */
    items: Array<ClusterItem>;
    /**
     * 
     * @type {ItemResponse}
     * @memberof Cluster
     */
    metadata: ItemResponse;
    /**
     * 
     * @type {Array<OrganizationSummary>}
     * @memberof Cluster
     */
    sources: Array<OrganizationSummary>;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    readonly coverImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    readonly funderBackgroundImageUrl?: string;
}

export function ClusterFromJSON(json: any): Cluster {
    return {
        'id': json['id'],
        'type': json['type'],
        'tours': (json['tours'] as Array<any>).map(TourFromJSON),
        'items': (json['items'] as Array<any>).map(ClusterItemFromJSON),
        'metadata': ItemResponseFromJSON(json['metadata']),
        'sources': (json['sources'] as Array<any>).map(OrganizationSummaryFromJSON),
        'coverImageUrl': !exists(json, 'cover_image_url') ? undefined : json['cover_image_url'],
        'funderBackgroundImageUrl': !exists(json, 'funder_background_image_url') ? undefined : json['funder_background_image_url'],
    };
}

export function ClusterToJSON(value?: Cluster): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'type': value.type,
        'tours': (value.tours as Array<any>).map(TourToJSON),
        'items': (value.items as Array<any>).map(ClusterItemToJSON),
        'metadata': ItemResponseToJSON(value.metadata),
        'sources': (value.sources as Array<any>).map(OrganizationSummaryToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum ClusterTypeEnum {
    Cluster = 'cluster'
}


