import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';
import {
    Icon,
    ScreenReaderIntl,
} from 'ui/components';
import messages from '../displayMessages';

interface Props {
    onSubmit: (keywords: string) => void;
    searchMessage: MessageDescriptor;
    searchButtonLabel?: MessageDescriptor;
}

export const SearchBar = (props: Props) => {
    const searchMsg = intl.formatMessage(props.searchMessage);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onSubmit = () => {
        if (inputRef.current){
            props.onSubmit(inputRef.current.value);
            inputRef.current.value = '';
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <div className='lx-search-box input-group'>
            <input type='text'
                    className='form-control'
                    placeholder={searchMsg}
                    aria-label={searchMsg}
                    onKeyDown={onKeyDown}
                    ref={inputRef}/>
            <button onClick={onSubmit} className='lx-search-box-icon-container'>
                <Icon name='search' fill='#FFFFFF' zoom='1.3em' />
                <ScreenReaderIntl message={props.searchButtonLabel ?? messages.searchButtonLabel} />
            </button>
        </div>
    );
};
