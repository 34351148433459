// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Conversation,
    ConversationFromJSON,
    ConversationToJSON,
    ConversationStatus,
    ConversationStatusFromJSON,
    ConversationStatusToJSON,
    DiscussionAuthor,
    DiscussionAuthorFromJSON,
    DiscussionAuthorToJSON,
    DiscussionsMessage,
    DiscussionsMessageFromJSON,
    DiscussionsMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConversationWithMessages
 */
export interface ConversationWithMessages {
    /**
     * 
     * @type {Conversation}
     * @memberof ConversationWithMessages
     */
    conversation: Conversation;
    /**
     * 
     * @type {Array<DiscussionsMessage>}
     * @memberof ConversationWithMessages
     */
    messages: Array<DiscussionsMessage>;
    /**
     * 
     * @type {Array<DiscussionAuthor>}
     * @memberof ConversationWithMessages
     */
    participants: Array<DiscussionAuthor>;
    /**
     * 
     * @type {ConversationStatus}
     * @memberof ConversationWithMessages
     */
    status: ConversationStatus;
}

export function ConversationWithMessagesFromJSON(json: any): ConversationWithMessages {
    return {
        'conversation': ConversationFromJSON(json['conversation']),
        'messages': (json['messages'] as Array<any>).map(DiscussionsMessageFromJSON),
        'participants': (json['participants'] as Array<any>).map(DiscussionAuthorFromJSON),
        'status': ConversationStatusFromJSON(json['status']),
    };
}

export function ConversationWithMessagesToJSON(value?: ConversationWithMessages): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'conversation': ConversationToJSON(value.conversation),
        'messages': (value.messages as Array<any>).map(DiscussionsMessageToJSON),
        'participants': (value.participants as Array<any>).map(DiscussionAuthorToJSON),
        'status': ConversationStatusToJSON(value.status),
    };
}


