import { getUsername, getUserPermissions } from 'auth/selectors';
import update from 'immutability-helper';

import bind from 'bind-decorator';
import { Thread } from 'discussions/components/Thread';
import { ThreadComposer } from 'discussions/components/ThreadComposer';
import { ClassroomsApi, DiscussionsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import {
    APIPermissions,
    ClassroomDetail,
    DiscussionSubCategory,
    ThreadPost as ThreadPostElement,
    ThreadWithPosts
} from 'labxchange-client';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Icon, ModalConfirmation, Tag } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { ThreadShareLikeFollowButtons } from 'discussions/components/ThreadShareLikeFollowButtons';
import { LocationDescriptor } from 'history';
import { EmptyDashboardPage } from 'library/components/EmptyDashboardPage';

enum ModalType {
    None,
    DeletePost,
    DeleteThread,
}

interface ReduxStateProps {
    loggedInUsername?: string;
    userPermissions?: APIPermissions;
}

interface Props {
    classroomId?: string;
    subCategoryId?: string;
    threadId: string;
    postId?: string;
    hideFirstPost?: boolean;
    reversePosts?: boolean;
    hideShareLikeButtons?: boolean;
    onShare?(): void;
    isAssetDiscussion?: boolean;
    thread?: ThreadWithPosts;
}

interface State {
    thread?: ThreadWithPosts;
    classroom?: ClassroomDetail;
    subCategory?: DiscussionSubCategory;
    isComposing: boolean;
    isEditing: boolean;
    sending: boolean;
    parentNode?: HTMLElement;
    modalType: ModalType;
    actionPostId?: number;
    isFollowButtonHovered?: boolean;
    redirectTo?: LocationDescriptor;
}

export class ClassroomDiscussionThreadInternal extends React.PureComponent<
    Props & ReduxStateProps, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isComposing: false,
            isEditing: false,
            sending: false,
            modalType: ModalType.None,
        };
    }

    public static defaultProps = {
        hideFirstPost: false,
        hideShareLikeButtons: false,
        reversePosts: false,
    };

    public async componentDidMount() {
        const parentNode = ReactDOM.findDOMNode(this);
        if (parentNode && parentNode instanceof HTMLElement) {
            this.setState({ parentNode });
        }

        if (this.props.classroomId) {
            try {
                const classroom = await ClassroomsApi.read({ id: this.props.classroomId });
                this.setState({ classroom });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorLoadingDiscussion} />, { exception: err });
            }
        } else if (this.props.subCategoryId) {
            try {
                const subCategory = await DiscussionsApi.subcategoriesRead({ id: this.props.subCategoryId });
                this.setState({ subCategory });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorLoadingDiscussion} />, { exception: err });
            }
        }
        this.setState({ redirectTo: undefined });
        await this.loadData();
    }

    private emptyThread() {
        if (this.state.thread) {
            return this.state.thread.posts.length < 2;
        } else {
            return true;
        }
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }
        if (this.props.loggedInUsername === undefined) { return null; }
        const isEditingTopic = this.isEditingTopic();
        let defaultTitle = '';
        if (isEditingTopic && this.state.thread) {
            defaultTitle = this.state.thread.thread.title;
        }

        const liked = this.state.thread?.thread.userAttributes?.liked;
        const followed = this.state.thread?.thread.userAttributes?.followed;

        let followButtonMessage = messages.followThread;
        if (followed) {
            if (this.state.isFollowButtonHovered) {
                followButtonMessage = messages.unfollowingThread;
            } else { followButtonMessage = messages.unfollowThread; }
        }

        return (
            <div className='subcategory-discussion-thread'>
                {this.props.classroomId ? (
                    <div className='subcategory-discussion-thread-heading'>
                        <div className='container discussion-heading-container'>
                            <h1 className='sr-only'>Classroom discussion thread</h1>
                            <Link
                                to={ROUTES.Classrooms.CLASSROOM_DISCUSSION_SLUG(this.props.classroomId)}>
                                <Icon name='chevron-left' />
                                Back to threads
                            </Link>
                        </div>
                    </div>
                ) : null}
                <div className='subcategory-discussion-thread-content'>
                    {this.props.isAssetDiscussion && this.state.thread ?
                        <div className={'discussion-box-actions'}>
                            <ThreadShareLikeFollowButtons
                                onLike={!this.emptyThread() ? this.onLike : undefined}
                                liked={liked}
                                onFollow={this.onFollow}
                                followed={followed}
                                outline={false}
                                enableHovering={false}
                                iconZoom={'x1.5'}
                            />
                            {!this.emptyThread() &&
                                <button
                                    className='btn btn-primary btn-reply'
                                    data-testid='reply-thread'
                                    onClick={() => this.onClickNewPost()}>
                                    <Icon name='reply' zoom='x1' />
                                    <WrappedMessage message={messages.postReply} />
                                </button>
                            }
                        </div>
                        : null}
                    <div className='subcategory-discussion-thread-content-thread'>
                        {this.state.thread === undefined
                            ? ''
                            : ((this.emptyThread() && this.props.isAssetDiscussion) ?
                                <EmptyDashboardPage title={messages.emptyDiscussionTitle} buttons={
                                    <button
                                        className='btn btn-primary btn-reply'
                                        data-testid='reply-thread'
                                        onClick={() => this.onClickNewPost()}>
                                        <WrappedMessage message={messages.emptyDiscussionAction} />
                                    </button>
                                } /> :
                                <Thread
                                    thread={this.state.thread}
                                    getPostPermalink={this.getPostPermalink}
                                    onReply={this.onClickNewPost}
                                    onEdit={this.onClickEditPost}
                                    onDelete={this.onClickDeletePost}
                                    canEdit={this.canEdit}
                                    canDelete={this.canDelete}
                                    loggedInUsername={this.props.loggedInUsername}
                                    anchoredPost={this.props.postId || undefined}
                                    hideFirstPost={this.props.hideFirstPost}
                                    reversePosts={this.props.reversePosts}
                                />
                            )
                        }
                    </div>
                    {this.props.isAssetDiscussion ? null :
                        <div className='subcategory-discussion-thread-content-sidebar'>
                            <div className='classroom-sidebar-box-wrapper'>
                                <div className='classroom-sidebar-box'>
                                    {this.state.thread === undefined
                                        ? ''
                                        :
                                        <>
                                            <div className='classroom-sidebar-box-icon rounded-circle'></div>
                                            <div className='classroom-sidebar-box-name'>
                                                {this.state.thread.thread.title}
                                            </div>
                                            <button
                                                className={`btn btn-primary btn-follow ${followed ? 'following' : ''}`}
                                                data-testid='follow-thread'
                                                onClick={this.onFollow}
                                                onMouseEnter={() => this.setState({ isFollowButtonHovered: true })}
                                                onMouseLeave={() => this.setState({ isFollowButtonHovered: false })}
                                            >
                                                <WrappedMessage message={followButtonMessage} />
                                            </button>
                                        </>
                                    }
                                </div>
                                {this.state.thread && !this.props.hideShareLikeButtons ?
                                    <ThreadShareLikeFollowButtons
                                        liked={this.state.thread.thread.userAttributes?.liked}
                                        onLike={this.onLike}
                                        onShare={this.props.onShare}
                                    /> : null
                                }

                                {this.props.loggedInUsername ? (
                                    <>
                                        <button
                                            className='btn btn-primary btn-reply'
                                            data-testid='reply-thread'
                                            onClick={() => this.onClickNewPost()}>
                                            <Icon name='reply' zoom='x1' />
                                            <WrappedMessage message={messages.postThreadReply} />
                                        </button>
                                    </>) : null}
                                <div className='thread-tags'>
                                    {this.state.thread && this.state.thread.thread.freeFormTags?.map((tag, index) => (
                                        <Tag key={index}>
                                            {tag}
                                        </Tag>
                                    ))}
                                </div>
                            </div>
                        </div>}
                </div>
                {(this.state.isComposing || this.state.isEditing) &&
                    <ThreadComposer
                        close={this.closeComposeDrawer}
                        open={true}
                        parentNode={this.state.parentNode}
                        sending={this.state.sending}
                        disableTitle={(!(this.state.isEditing && isEditingTopic))}
                        onSubmit={this.state.isComposing ? this.submitPost : this.updatePost}
                        defaultTitle={defaultTitle}
                        tags={this.state.thread?.thread.freeFormTags}
                        hideTags={!!this.state.classroom}
                        disableNewTags={!this.props.userPermissions?.profile.canAddTagsToThreads}
                        defaultContent={
                            (this.state.isEditing && this.state.actionPostId)
                                ? this.getPostContent(this.state.actionPostId)
                                : ''
                        }
                    />
                }
                {this.renderModals()}
            </div>
        );
    }

    @bind private async onFollow() {
        try {
            if (this.props.classroomId) {
                const thread = await ClassroomsApi.classroomThreadsFollow({
                    id: this.props.threadId,
                    data: { classroom: this.props.classroomId }
                });
                this.setState({ thread: update(this.state.thread, { thread: { $set: thread } }) });
            } else if (this.props.subCategoryId) {
                const thread = await DiscussionsApi.threadsFollow({
                    id: this.props.threadId,
                });
                this.setState({ thread: update(this.state.thread, { thread: { $set: thread } }) });
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorLikeThread} />, { exception: err });
        }
    }

    @bind private async onLike() {
        try {
            if (this.props.classroomId) {
                const thread = await ClassroomsApi.classroomThreadsLike({
                    id: this.props.threadId,
                    data: { classroom: this.props.classroomId }
                });
                this.setState({ thread: update(this.state.thread, { thread: { $set: thread } }) });
            } else if (this.props.subCategoryId) {
                const thread = await DiscussionsApi.threadsLike({
                    id: this.props.threadId,
                });
                this.setState({ thread: update(this.state.thread, { thread: { $set: thread } }) });
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorLikeThread} />, { exception: err });
        }
    }

    @bind private onClickNewPost(postId?: number) {
        this.setState({ isComposing: !this.state.isComposing, actionPostId: postId });
    }

    @bind private onClickEditPost(postId: number) {
        this.setState({ isEditing: true, actionPostId: postId });
    }

    @bind private async onClickDeletePost(postId: number, isTopic: boolean) {
        if (isTopic) {
            this.setState({ modalType: ModalType.DeleteThread });
        } else {
            this.setState({ modalType: ModalType.DeletePost, actionPostId: postId });
        }
    }

    @bind private getPostContent(postId: number) {
        if (this.state.thread === undefined) { return ''; }
        const post = this.state.thread.posts.find((p) =>
            p.id === postId,
        );
        if (post === undefined) { return ''; }
        return post.html;
    }

    @bind private closeComposeDrawer() {
        this.setState({ isComposing: false, isEditing: false });
    }

    @bind private async deleteThread() {
        if (this.props.classroomId) {
            try {
                await ClassroomsApi.classroomThreadsDelete({
                    id: this.props.threadId,
                    data: {
                        classroom: this.props.classroomId,
                    },
                });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorDeleteClassroomThread} />, { exception: err });
            }
        } else if (this.props.subCategoryId) {
            try {
                await DiscussionsApi.threadsDelete({
                    id: this.props.threadId,
                });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorDeleteThread} />, { exception: err });
            }
        }
        this.setState({
            modalType: ModalType.None,
            redirectTo: this.props.classroomId ?
                ROUTES.Classrooms.CLASSROOM_DISCUSSION_SLUG(this.props.classroomId)
                : this.props.subCategoryId ? ROUTES.Community.SUBCATEGORY_ROUTE_SLUG(this.props.subCategoryId)
                    : ROUTES.Explore.HOME,
        });
    }

    @bind private async deletePost() {

        if (this.state.actionPostId === undefined) {
            return;
        }
        try {
            if (this.props.classroomId) {
                await ClassroomsApi.classroomThreadsPostDelete({
                    id: this.props.threadId,
                    data: {
                        classroom: this.props.classroomId,
                        post: this.state.actionPostId.toString(),
                    },
                });
            }
            else if (this.props.subCategoryId) {
                await DiscussionsApi.threadsPostDelete({
                    id: this.props.threadId,
                    data: {
                        post: this.state.actionPostId.toString(),
                    },
                });
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorDeleteThreadPost} />, { exception: err });
        }

        this.setState({ actionPostId: undefined, modalType: ModalType.None });
        await this.loadData();
    }

    @bind private async updatePost(title: string, content: string, tags?: string[]) {
        if (this.state.actionPostId === undefined) { return; }
        this.setState({ sending: true });

        try {
            if (this.props.classroomId) {
                if (this.isEditingTopic()) {
                    await ClassroomsApi.classroomThreadsPartialUpdate({
                        id: this.props.threadId,
                        data: {
                            classroom: this.props.classroomId,
                            title,
                        },
                    });
                }
                await ClassroomsApi.classroomThreadsPostPartialUpdate({
                    id: this.props.threadId,
                    data: {
                        post: this.state.actionPostId.toString(),
                        classroom: this.props.classroomId,
                        content,
                    },
                });
            } else if (this.props.subCategoryId) {
                if (this.isEditingTopic()) {
                    await DiscussionsApi.threadsPartialUpdate({
                        id: this.props.threadId,
                        data: {
                            title,
                            freeFormTags: tags,
                        }
                    });
                }
                await DiscussionsApi.threadsPostPartialUpdate({
                    id: this.props.threadId,
                    data: {
                        post: this.state.actionPostId.toString(),
                        content,
                    },
                });
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorUpdateThreadPost} />, { exception: err });
        }


        this.setState({ sending: false, actionPostId: undefined });
        await this.loadData();
    }

    @bind private async submitPost(title: string, content: string) {
        this.setState({ sending: true });

        try {
            if (this.props.classroomId) {
                await ClassroomsApi.classroomThreadsPostCreate({
                    id: this.props.threadId,
                    data: {
                        classroom: this.props.classroomId,
                        thread: this.props.threadId,
                        replyTo: this.state.actionPostId,
                        content,
                    },
                });
            } else if (this.props.subCategoryId) {
                await DiscussionsApi.threadsPostCreate({
                    id: this.props.threadId,
                    data: {
                        thread: this.props.threadId,
                        replyTo: this.state.actionPostId,
                        content,
                    },
                });
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorCreateThreadPost} />, { exception: err });
        }

        this.setState({ sending: false, actionPostId: undefined });
        await this.loadData(true);
    }

    @bind private getPostPermalink(postId: number) {
        if (this.state.thread === undefined) { return ''; }

        if (this.props.classroomId) {
            return ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD_POST_SLUG(
                this.props.classroomId, this.state.thread.thread.id, postId,
            );
        } else {
            return '#';
        }
    }

    @bind private canDelete(post: ThreadPostElement, isTopic: boolean): boolean {
        if (this.state.thread) {
            if (this.props.classroomId) {
                return (this.props.userPermissions
                    && this.props.userPermissions.classrooms.canDeleteClassDiscussions) || false;
            }
            if (isTopic) {
                return (this.state.thread.thread.permissions
                    && this.state.thread.thread.permissions.canDeleteThreadObject) || false;
            }
        }
        return !!post.permissions?.canDeletePostObject;
    }

    @bind private canEdit(): boolean {
        return ((this.props.userPermissions
            && this.props.userPermissions.classrooms.canEditClassDiscussions)) || false;
    }

    private renderModals() {
        switch (this.state.modalType) {
            case ModalType.DeleteThread:
                return (
                    <ModalConfirmation
                        title={messages.deleteThreadModalTitle}
                        confirmClassNames='btn-warning'
                        confirmButtonText={messages.deleteThreadPostModalConfirm}
                        cancelButtonText={messages.deleteThreadPostModalCancel}
                        onCanceled={() => this.setState({ modalType: ModalType.None })}
                        onConfirmed={this.deleteThread}
                        body={messages.deleteThreadModalBody} />
                );
            case ModalType.DeletePost:
                return (
                    <ModalConfirmation
                        title={messages.deleteThreadPostModalTitle}
                        confirmClassNames='btn-warning'
                        confirmButtonText={messages.deleteThreadPostModalConfirm}
                        cancelButtonText={messages.deleteThreadPostModalCancel}
                        onCanceled={() => this.setState({ modalType: ModalType.None, actionPostId: undefined })}
                        onConfirmed={this.deletePost}
                        body={messages.deleteThreadPostModalBody} />
                );
            default: return null;
        }
    }

    private async loadData(force: boolean = false) {
        try {
            let thread;
            if ((this.props.thread !== undefined) && !force) {
                thread = this.props.thread;
            } else if (this.props.classroomId) {
                thread = await ClassroomsApi.classroomThreadsRead(
                    {
                        id: this.props.threadId,
                        classroomId: this.props.classroomId,
                    });
            } else if (this.props.subCategoryId) {
                thread = await DiscussionsApi.threadsRead({
                    id: this.props.threadId,
                });
            }
            this.setState({ thread });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorLoadingDiscussion} />, { exception: err });
        }
    }

    private isEditingTopic() {
        if (this.state.actionPostId !== undefined && this.state.thread !== undefined) {
            if (this.state.actionPostId === this.state.thread.posts[0].id) {
                return true;
            }
        }
        return false;
    }
}

export const ClassroomDiscussionThread = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        loggedInUsername: getUsername(state),
        userPermissions: getUserPermissions(state),
    }),
)(ClassroomDiscussionThreadInternal);
