// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * Get demand card.
 * @export
 * @interface CareerOverviewCard
 */
export interface CareerOverviewCard {
    /**
     * 
     * @type {number}
     * @memberof CareerOverviewCard
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof CareerOverviewCard
     */
    career?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerOverviewCard
     */
    readonly cardLabel?: string;
    /**
     * Overview card type.
     * @type {string}
     * @memberof CareerOverviewCard
     */
    cardType: CareerOverviewCardCardTypeEnum;
    /**
     * Overview card description.
     * @type {string}
     * @memberof CareerOverviewCard
     */
    description: string;
    /**
     * Demand card trajectory. Select only for demand card.
     * @type {string}
     * @memberof CareerOverviewCard
     */
    demandTrajectory?: CareerOverviewCardDemandTrajectoryEnum;
}

export function CareerOverviewCardFromJSON(json: any): CareerOverviewCard {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'career': !exists(json, 'career') ? undefined : json['career'],
        'cardLabel': !exists(json, 'card_label') ? undefined : json['card_label'],
        'cardType': json['card_type'],
        'description': json['description'],
        'demandTrajectory': !exists(json, 'demand_trajectory') ? undefined : json['demand_trajectory'],
    };
}

export function CareerOverviewCardToJSON(value?: CareerOverviewCard): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'career': value.career,
        'card_type': value.cardType,
        'description': value.description,
        'demand_trajectory': value.demandTrajectory,
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerOverviewCardCardTypeEnum {
    Duties = 'duties',
    Education = 'education',
    Outlook = 'outlook',
    RelatedCareers = 'related-careers',
    Salary = 'salary',
    Skills = 'skills'
}
/**
* @export
* @enum {string}
*/
export enum CareerOverviewCardDemandTrajectoryEnum {
    Decline = 'decline',
    RapidDecline = 'rapid-decline',
    Growth = 'growth',
    RapidGrowth = 'rapid-growth',
    Stable = 'stable'
}


