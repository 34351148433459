// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AccountDetails
 */
export interface AccountDetails {
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    timezone?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountDetails
     */
    dateOfBirth?: Date;
}

export function AccountDetailsFromJSON(json: any): AccountDetails {
    return {
        'userName': json['user_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : new Date(json['date_of_birth']),
    };
}

export function AccountDetailsToJSON(value?: AccountDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'user_name': value.userName,
        'full_name': value.fullName,
        'email': value.email,
        'country': value.country,
        'timezone': value.timezone,
        'date_of_birth': value.dateOfBirth === undefined ? undefined : value.dateOfBirth.toISOString().substr(0,10),
    };
}


