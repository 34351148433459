import { defineMessages } from 'react-intl';

const messages = defineMessages({
    loginRequired: {
        id:'loginRequired',
        defaultMessage: 'You need to be logged in to see this.',
        description: 'Message shown if a user tries to do something without being logged in.',
    },
});

export default messages;
