import * as React from 'react';
import * as LoginActions from 'auth/actions';

import { intl } from 'i18n';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import {
    BlockProps,
} from './models';
import { XBlocksApi } from 'global/api';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { AlertBox, Spinner } from 'ui/components';
import { QuestionOptionResponseBlock } from './QuestionOptionResponseBlock';
import { QuestionStringResponseBlock } from './QuestionStringResponseBlock';
import { QuestionChoiceResponseBlock } from './QuestionChoiceResponseBlock';
import {
    QuestionData, ViewData, ChoiceResponse, OptionResponse, StringResponse,
} from './question-block-shared';

interface State {
    data: ViewData<QuestionData>|null;
}

export class QuestionBlock extends React.PureComponent<BlockProps, State> {
    constructor(props: BlockProps) {
        super(props);
        this.state = {
            data: null,
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    private async loadData() {
        try {
            // Load the XBlock edit data from the API, if available:
            const dataRaw = await XBlocksApi.studentViewUserState({
                id: this.props.itemMetadata.id,
            });
            // NOTE: parsing as json here is needed because of GenericObjectSerializer.
            const data = JSON.parse(dataRaw);
            this.setState({
                data: data as ViewData<QuestionData>,
            });
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorLoadingQuestion} />,
                {exception: err}
            );
        }
    }

    private onUpdateData(data: ViewData<QuestionData>) {
        this.setState({ data });
    }

    public render() {
        if (this.state.data === null) {
            return <Spinner />;
        }

        const alertAnonymous = (
            (this.props.username && this.props.username !== '') ? null :
            <AlertBox
                className='anonymous-user-warning'
                type='warning'
                bodyMessage={messages.anonymousUserWarning}
                bodyMessageValues={{
                    signIn: <button className='button-link' onClick={LoginActions.redirectToLogin} >{intl.formatMessage(messages.anonymousUserSignInText)}</button>,
                    signUp: <button className='button-link' onClick={LoginActions.redirectToLxSignUp}>{intl.formatMessage(messages.anonymousUserSignUpText)}</button>,
                }}
                showCloseIcon={false}
            />
        );
        const questionBlock = this.renderQuestionBlock();

        return (
            <>
                {alertAnonymous}
                {questionBlock}
            </>
        );
    }

    public renderQuestionBlock() {
        switch (this.state.data!.questionData.type) {
            case 'optionresponse': {
                return (
                    <QuestionOptionResponseBlock
                        itemId={this.props.itemMetadata.id}
                        data={this.state.data as ViewData<OptionResponse>}
                        onUpdateData={(x) => this.onUpdateData(x)}
                    />
                );
            }
            case 'stringresponse': {
                return (
                    <QuestionStringResponseBlock
                        itemId={this.props.itemMetadata.id}
                        data={this.state.data as ViewData<StringResponse>}
                        onUpdateData={(x) => this.onUpdateData(x)}
                    />
                );
            }
            case 'choiceresponse': {
                return (
                    <QuestionChoiceResponseBlock
                        itemId={this.props.itemMetadata.id}
                        data={this.state.data as ViewData<ChoiceResponse>}
                        onUpdateData={(x) => this.onUpdateData(x)}
                    />
                );
            }
        }
        return null;
    }
}
