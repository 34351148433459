import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';

import { CreateItemModalContent, CreateItemStep } from 'items/components/CreateItemModal';
import { LibraryContentList } from 'library/components/LibraryContentList';
import { Button, Icon, Modal, SearchBar } from 'ui/components';

import { ItemType } from 'items/models';
import { APIPermissions, ItemMetadata, ItemMetadataTypeEnum } from 'labxchange-client';
import { connect } from 'react-redux';
import { RootState } from 'global/state';
import { getUserPermissions } from 'auth/selectors';


export enum Tab {
    Library,
    OwnContent,
    OwnOrg,
    Favorites,
    AddContent,
}

interface ReduxStateProps {
    userPermissions?: APIPermissions;
}

interface State {
    tab: Tab;
    keywords?: string;
    searchParams?: string;
    selectedItems: Set<string>;
}

interface Props {
    /// Used on the first render to fill
    firstSelectedItems?: Set<string>;

    onClose: () => void;
    onItemAdd?: (item: ItemMetadata) => void;
    onItemsAdd?: (itemIds: Set<string>) => void;
    multipleSelect?: boolean;
    /** If enabled exclude these item types from the result */
    excludeTypes?: ItemType[];

    // if `tab` is provided, the tab shown will be controlled by the props.
    // Otherwise, the tab will be controlled by the component in internal
    // state.
    tab?: Tab;
    onTabChange?: (tab: Tab) => void;

    showCreateItemTab?: boolean;
    onCreateItem?: (location: LocationDescriptor, itemType?: ItemType) => void;
    createItemStep?: CreateItemStep;
    onCreateItemStepChange?: (step: CreateItemStep) => void;
    createItemSelectedItemType?: ItemType;
    onCreateItemSelectedItemTypeChange?: (itemType: ItemType) => void;
    onSelectionChanged?: (itemIds: Set<string>) => void;
    // whether the add new content should replace the whole modal when not on first step
    noAddContentReplaceModal?: boolean;
}

export class ContentPickerModalInternal extends React.PureComponent<Props & ReduxStateProps, State> {

    searchBoxRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);
        this.state = {
            tab: Tab.Library,
            selectedItems: new Set(),
        };
        this.searchBoxRef = React.createRef<HTMLDivElement>();
    }

    componentDidMount() {
        if (this.props.firstSelectedItems) {
            this.setState({
                selectedItems: this.props.firstSelectedItems,
            });
        }
    }

    public render() {
        const searchParams = new URLSearchParams(this.state.searchParams);
        if (this.state.keywords) {
            searchParams.set('q', this.state.keywords);
        }

        const tab = this.props.tab || this.state.tab;

        let tabContent;

        const confirmedExcludeTypes = this.props.excludeTypes;
        if(!this.props.userPermissions?.library.canCreateLinkItem) {
            confirmedExcludeTypes?.push(ItemMetadataTypeEnum.Link);
        }

        const baseProps = {
            isModalView: true,
            searchParams: searchParams.toString(),
            onSearchParamsChanged: this.onSearchParamsChanged,
            displayAddButton: true,
            openCardDetailUrlsInNewTab: true,
            onItemAdd: this.onItemAdd,
            onSelectionChanged: this.onSelectionChanged,
            multipleSelect: this.props.multipleSelect,
            selectedItems: this.state.selectedItems,
            excludeTypes: confirmedExcludeTypes,
            showCompletion: false,
        };

        let searchBoxContent = null;
        if (tab !== Tab.AddContent) {
            let searchMessage;
            switch (tab) {
                case Tab.OwnContent: {
                    searchMessage = messages.ownContentSearchBoxPlaceholder;
                    break;
                }
                case Tab.OwnOrg: {
                    searchMessage = messages.orgContentSearchBoxPlaceholder;
                    break;
                }
                case Tab.Favorites: {
                    searchMessage = messages.favoritesSearchBoxPlaceholder;
                    break;
                }
                default: {
                    searchMessage = messages.searchBoxPlaceholder;
                    break;
                }
            }

            // ContentPickerSearchbox does not have scrollIntoView() method,
            // so surround it in a div element that does
            searchBoxContent = (<div ref={this.searchBoxRef}>
                <SearchBar
                    onSubmit={this.setSearchKeywords}
                    searchMessage={searchMessage}
                />
            </div>);
        }

        switch (tab) {
            case (Tab.Library): {
                tabContent = (<LibraryContentList {...baseProps} scrollToRef={this.searchBoxRef}/>);
                break;
            }
            case (Tab.OwnContent): {
                tabContent = (<LibraryContentList {...baseProps} scrollToRef={this.searchBoxRef} searchOnlyOwnedByUser={true} />);
                break;
            }
            case (Tab.OwnOrg): {
                tabContent = (<LibraryContentList {...baseProps} scrollToRef={this.searchBoxRef} searchOnlyInUserOrg={true} />);
                break;
            }
            case (Tab.Favorites): {
                tabContent = (<LibraryContentList {...baseProps} scrollToRef={this.searchBoxRef} searchOnlyFavoritedByUser={true} />);
                break;
            }
            case (Tab.AddContent): {
                tabContent = (<CreateItemModalContent
                    onCloseAndRedirect={this.onCreateItem}
                    excludeTypes={this.props.excludeTypes}
                    step={this.props.createItemStep || CreateItemStep.SelectType}
                    onStepChange={this.onCreateItemStepChange}
                    selectedItemType={this.props.createItemSelectedItemType}
                    onSelectedItemTypeChange={this.onCreateItemSelectedItemTypeChange}
                />);
                break;
            }
        }

        if (
            tab === Tab.AddContent
            && this.props.createItemStep !== CreateItemStep.SelectType
            && !this.props.noAddContentReplaceModal
        ) {
            return (
                <Modal
                    size='large'
                    showTopBar={true}
                    showBackButton={true}
                    onRequestBack={() => this.onCreateItemStepChange(CreateItemStep.SelectType)}
                    onRequestClose={this.props.onClose}
                    title={<WrappedMessage message={messages.createItemButtonLabel} />}
                    contentScrolls={true}
                    content={tabContent}
                    ariaLabel={messages.createItemButtonLabel}
                />
            );
        }

        return (
            <Modal
                onRequestClose={this.props.onClose}
                size='large'
                className='content-picker-modal'
                contentPadding={0}
                ariaLabel={messages.createItemButtonLabel}
                content={
                    <div className='content-picker-body'>
                        <div className='content-picker-tabs d-flex justify-content-start'>
                            {this.props.userPermissions?.library.canViewPublicContent &&
                                <button className={`nav-item text-header unstyled ${tab === Tab.Library ? 'active' : ''}`}
                                    onClick={() => this.switchTab(Tab.Library)}
                                >
                                    <Icon className='content-picker-tab-icon' name='library' zoom='25'/>
                                    <WrappedMessage message={messages.tabLabelLibrary} />
                                </button>
                            }
                            {this.props.userPermissions?.library.canViewOwnContent &&
                                <button
                                    className={`nav-item text-header unstyled ${tab === Tab.OwnContent ? 'active' : ''}`}
                                    data-testid='my-content-tab'
                                    onClick={() => this.switchTab(Tab.OwnContent)}
                                >
                                    <Icon className='content-picker-tab-icon' name='lock' zoom='25'/>
                                    <div className='content-picker-tab-text'>
                                        <WrappedMessage message={messages.tabLabelYourContent} />
                                    </div>
                                    <div className='content-picker-tab-text-mobile'>
                                        <WrappedMessage message={messages.tabLabelYourContentMobile} />
                                    </div>
                                </button>
                            }
                            {this.props.userPermissions?.library.canViewOrganizationContent &&
                                <button
                                    className={`nav-item text-header unstyled ${tab === Tab.OwnOrg ? 'active' : ''}`}
                                    onClick={() => this.switchTab(Tab.OwnOrg)}
                                >
                                    <Icon className='content-picker-tab-icon' name='organization' zoom='25'/>
                                    <WrappedMessage message={messages.tabLabelYourOrg} />
                                </button>
                            }
                            {this.props.userPermissions?.library.canViewFavoriteContent &&
                                <button
                                    className={`nav-item text-header unstyled ${tab === Tab.Favorites ? 'active' : ''}`}
                                    data-testid='favorites-tab'
                                    onClick={() => this.switchTab(Tab.Favorites)}
                                >
                                    <Icon className='content-picker-tab-icon' name='star' zoom='25'/>
                                    <div className='content-picker-tab-text'>
                                        <WrappedMessage message={messages.tabLabelFavorites} />
                                    </div>
                                    <div className='content-picker-tab-text-mobile'>
                                        <WrappedMessage message={messages.tabLabelFavoritesMobile} />
                                    </div>
                                </button>
                            }
                            {this.props.showCreateItemTab &&
                                <button
                                    className={`nav-item text-header unstyled ${tab === Tab.AddContent ? 'active' : ''}`}
                                    data-testid='add-new-content'
                                    onClick={() => this.switchTab(Tab.AddContent)}
                                >
                                    <Icon className='content-picker-tab-icon' name='plus' zoom='25'/>
                                    <div className='content-picker-tab-text'>
                                        <WrappedMessage message={messages.tabLabelAddContent} />
                                    </div>
                                    <div className='content-picker-tab-text-mobile'>
                                        <WrappedMessage message={messages.tabLabelAddContentMobile} />
                                    </div>
                                </button>
                            }
                            <button onClick={this.props.onClose} className='close-button'>
                                <span className='close-label'>
                                    <WrappedMessage message={messages.closeButtonLabel} />
                                </span>
                                <span className='close-icon'>
                                    <Icon name='x' zoom='14' />
                                </span>
                            </button>
                        </div>
                        <div className='content-picker-tab-content'>
                            {searchBoxContent}
                            <div className='library-content-list-container'>
                                {tabContent}
                            </div>
                        </div>
                    </div>
                }
                footer={(this.props.multipleSelect && this.state.selectedItems.size > 0)
                    ?
                    <div className={`${tab !== Tab.AddContent ? 'content-picker-footer' : 'content-picker-footer-only-close'}`}>
                        <Button
                            btnStyle='outline'
                            label={messages.closeButtonLabel}
                            onClick={this.props.onClose}
                        />
                        {(tab !== Tab.AddContent)
                        ?
                        <Button
                            onClick={this.onSubmit}
                            label={messages.addMultipleButton}
                            data-testid='save-content'
                            btnStyle='primary'
                            labelValues={{count: this.state.selectedItems.size}}
                            disabled={this.state.selectedItems.size === 0}
                        />
                        : undefined
                        }
                    </div>
                    : undefined
                }
            />
        );
    }

    @bind private onCreateItemStepChange(step: CreateItemStep) {
        if (this.props.onCreateItemStepChange) {
            this.props.onCreateItemStepChange(step);
        }
    }

    @bind private onCreateItemSelectedItemTypeChange(itemType: ItemType) {
        if (this.props.onCreateItemSelectedItemTypeChange) {
            this.props.onCreateItemSelectedItemTypeChange(itemType);
        }
    }

    @bind private onCreateItem(location: LocationDescriptor, itemType?: ItemType) {
        if (this.props.onCreateItem) {
            this.props.onCreateItem(location, itemType);
        }
    }

    @bind private switchTab(tab: Tab) {
        this.setState({ tab });
        if (this.props.onTabChange) {
            this.props.onTabChange(tab);
        }
    }

    @bind private onItemAdd(item: ItemMetadata) {
        if (this.props.onItemAdd) {
            this.props.onItemAdd(item);
        }
    }

    @bind private onSelectionChanged(itemIds: Set<string>) {
        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(itemIds);
        }
        this.setState({
            selectedItems: itemIds,
        });
    }

    /**
     * Called when the 'Add items' button clicked. Submits the stored selected
     * items state to parent.
     */
    @bind private onSubmit() {
        if (this.props.onItemsAdd) {
            this.props.onItemsAdd(this.state.selectedItems);
        }
        // clear selected items on submit.
        this.setState({
            selectedItems: new Set(),
        });
    }

    @bind private onSearchParamsChanged(searchParams: URLSearchParams) {
        this.setState({
            searchParams: searchParams.toString(),
            keywords: searchParams.get('q') || undefined,
        });
    }

    @bind private setSearchKeywords(keywords: string) {
        this.setState({keywords});
    }
}

export const ContentPickerModal = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
    }),
)(ContentPickerModalInternal);
