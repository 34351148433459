import { defineMessages } from 'react-intl';

const messages = defineMessages({
    itemAssignLabel: {
        id:'itemAssignLabel',
        defaultMessage: 'Add or assign to:',
        description: 'Title for the assign actions',
    },
    itemAssignToPathwayLabel: {
        id:'itemAssignToPathwayLabel',
        defaultMessage: 'Pathway',
        description: 'Label for the pathway assign action',
    },
    itemConvertAnnotatedVideoLabel: {
        id:'itemConvertAnnotatedVideoLabel',
        defaultMessage: 'Annotate Video',
        description: 'Label for creating annotated video',
    },
    itemAssignToPathwayLargeLabel: {
        id:'itemAssignToPathwayLargeLabel',
        defaultMessage: 'Add to pathway',
        description: 'Large label for the pathway assign action button',
    },
    itemAssignToClassroomLabel: {
        id:'itemAssignToClassroomLabel',
        defaultMessage: 'Class',
        description: 'Label for the classroom assign action',
    },
    itemAddToClassroomLabel: {
        id:'itemAddToClassroomLabel',
        defaultMessage: 'Add to class',
        description: 'Label for the classroom assign action',
    },
    itemAssignToClassroomLargeLabel: {
        id:'itemAssignToClassroomLargeLabel',
        defaultMessage: 'Assign to class',
        description: 'Large label for the class assign action button',
    },
    itemAssignToMentorshipLabel: {
        id:'itemAssignToMentorshipLabel',
        defaultMessage: 'Mentee',
        description: 'Label for the mentee assign action',
    },
    itemAssignToMentorshipLargeLabel: {
        id:'itemAssignToMentorshipLargeLabel',
        defaultMessage: 'Assign to mentee',
        description: 'Large label for the mentorship assign action button',
    },
    failedToAddItem: {
        id:'failedToAddItem',
        defaultMessage: 'An error occured while adding selected items. Please try again later.',
        description: 'Error message when adding selected items failed',
    },
    // Assign to Pathway modal
    itemAssignToPathwayModalTitle: {
        id:'itemAssignToPathwayModalTitle',
        defaultMessage: 'Add “{itemTitle}” to draft pathway',
        description: 'Assign item to a pathway modal title',
    },
    itemAssignToPathwayModalSubmitButton: {
        id:'itemAssignToPathwayModalSubmitButton',
        defaultMessage: 'Assign to pathway ({counter})',
        description: 'Assign item to a pathway modal submit button',
    },
    itemAssignToPathwayModalSuccessText: {
        id:'itemAssignToPathwayModalSuccessText',
        defaultMessage: 'This asset has been assigned to the selected {count, plural, one {pathway} other {pathways}}.',
        description: 'Assign item to a pathway modal success text',
    },
    itemAssignToPathwayPublicError: {
        id:'itemAssignToPathwayPublicError',
        defaultMessage: 'You can\'t add this private content to this public pathway.',
        description: 'Error message when user is trying the assign a private asset to a public pathway.',
    },
    // Assign to Classroom modal
    itemAssignToClassroomModalTitle: {
        id:'itemAssignToClassroomModalTitle',
        defaultMessage: 'Add “{itemTitle}” to class',
        description: 'Assign item to a class modal title',
    },
    itemAssignToClassroomModalSubmitButton: {
        id:'itemAssignToClassroomModalSubmitButton',
        defaultMessage: 'Assign to class ({counter})',
        description: 'Assign item to a class modal submit button',
    },
    itemAssignToClassroomModalSuccessText: {
        id:'itemAssignToClassroomModalSuccessText',
        defaultMessage: 'This asset has been assigned to the selected {count, plural, one {classroom} other {classrooms}}.',
        description: 'Assign item to a classroom modal success text',
    },
    itemAssignToClassroomModalAdditionalText: {
        id:'itemAssignToClassroomModalAdditionalText',
        defaultMessage: 'Note that the following {count, plural, one {classroom} other {classrooms}} already had this asset assigned:',
        description: 'When assigning an asset to a class, an alert informs the user that the asset is already assigned to the class.',
    },
    itemAssignToClassroomModalSearchText: {
        id: 'itemAssignToClassroomModalSearchText',
        defaultMessage: 'Search classes',
        description: 'Assign item to a classroom modal search box text',
    },
    // Assign to Mentee modal
    itemAssignToMenteeModalTitle: {
        id:'itemAssignToMenteeModalTitle',
        defaultMessage: 'Assign “{itemTitle}” to mentee',
        description: 'Assign item to a mentee modal title',
    },
    itemAssignToMenteeModalSubmitButton: {
        id:'itemAssignToMenteeModalSubmitButton',
        defaultMessage: 'Assign to mentee ({counter})',
        description: 'Assign item to a mentee modal submit button',
    },
    itemAssignToMenteeModalSuccessText: {
        id:'itemAssignToMenteeModalSuccessText',
        defaultMessage: 'This asset has been assigned to the selected {count, plural, one {mentee} other {mentees}}.',
        description: 'Assign item to a mentee modal success text',
    },
    itemAssignToMenteeModalAdditionalText: {
        id:'itemAssignToMenteeModalAdditionalText',
        defaultMessage: 'Note that the following {count, plural, one {mentee} other {mentees}} already had this asset assigned:',
        description: 'Assign item to a mentee modal additional text',
    },
    // Create Annotated Video
    createAnnotatedVideoModalButtonText: {
        id:'createAnnotatedVideoModalButtonText',
        defaultMessage: 'Yes',
        description: 'Confirmation for converting video to annotated video',
    },
    createAnnotatedVideoModalHeading: {
        id:'createAnnotatedVideoModalHeading',
        defaultMessage: 'ANNOTATED VIDEO',
        description: 'Heading in annotated video modal',
    },
    createAnnotatedVideoModalText: {
        id:'createAnnotatedVideoModalText',
        defaultMessage: 'Do you want to annotate {videoName}?',
        description: 'Confirmation modal text',
    },
    createAnnotatedVideoModalError: {
        id:'createAnnotatedVideoModalError',
        defaultMessage: 'Video type not supported',
        description: 'Confirmation modal video type error',
    },
    showingResultsItems: {
        id:'showingResultsItems',
        defaultMessage: 'Showing {count} results',
        description: 'Text to indicate how many items there are.',
    },
    sortBy: {
        id:'sortBy',
        defaultMessage: 'Sort by:',
        description: 'Label for the sort by select',
    },
    atoZ: {
        id:'atoZ',
        defaultMessage: 'A to Z',
        description: 'Label on sort by A to Z option.',
    },
    ztoA: {
        id:'ztoA',
        defaultMessage: 'Z to A',
        description: 'Label on sort by Z to A option.',
    },
    recentlyAdded: {
        id:'recentlyAdded',
        defaultMessage: 'Recently added',
        description: 'Label on sort by recently added option.',
    },
});

export default messages;
