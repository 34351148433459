import { defineMessages } from 'react-intl';

const messages = defineMessages({
    tabMyContent: {
        id:'tabMyContent',
        defaultMessage: 'My content',
        description: 'Title for Content tab',
    },
    tabFavorites: {
        id:'tabFavorites',
        defaultMessage: 'Favorites',
        description: 'Title for Favorites tab',
    },
    tabClasses: {
        id:'tabClasses',
        defaultMessage: 'Classes I\'m in',
        description: 'Tab for classes the user is enrolled in. (Name to be decided)',
    },
    tabMyClasses: {
        id: 'tabMyClasses',
        defaultMessage: 'Classes I teach',
        description: 'Tab for classes created by the user. (Name to be decided)'
    },
    tabProgress: {
        id:'tabProgress',
        defaultMessage: 'Progress',
        description: 'Title for Progress tab',
    },
    tabMessages: {
        id: 'tabMessages',
        defaultMessage: 'Messages',
        description: 'Title for Messages tab'
    },
    dashboardTitle: {
        id: 'dashboardTitle',
        defaultMessage: 'Dashboard',
        description: 'Title message for dashboard page',
    },
    myContentPageTitle: {
        id: 'myContentPageTitle',
        defaultMessage: 'My content - Dashboard',
        description: 'HTML title for the classes page on dashboard',
    },
    favoritesPageTitle: {
        id: 'favoritesPageTitle',
        defaultMessage: 'My Favorites - Dashboard',
        description: 'HTML title for the favorites page on dashboard',
    },
    classroomsPageTitle: {
        id: 'classroomsPageTitle',
        defaultMessage: 'Classrooms - Dashboard',
        description: 'HTML title for the classrooms page on dashboard (educator)',
    },
    myClassroomsPageTitle: {
         id: 'myClassroomsPageTitle',
         defaultMessage: 'My classrooms - Dashboard',
         description: 'HTML title for the classrooms page on dashboard (enrollment)',
    },
    myMessagesPageTitle: {
        id: 'myMessagesPageTitle',
        defaultMessage: 'My Messages - Dashboard',
        description: 'HTML title for the messages page on dashboard',
    },
    notLoggedInDashboardAlertTile: {
        id: 'notLoggedInDashboardAlertTile',
        defaultMessage: 'Log in to view dashboard',
        description: 'Title of the alert page when the user tries to go to dashboard without being logged in',
    },
    notLoggedInDashboardAlertBody: {
        id: 'notLoggedInDashboardAlertBody',
        defaultMessage: 'You can\'t view your dashboard without logging into LabXchange. You can log in or sign up by clicking the buttons below.',
        description: 'Body of the alert page when the user tries to go to dashboard without being logged in',
    },
    notLoggedInCommunityAlertTitle: {
        id: 'notLoggedInCommunityAlertTitle',
        defaultMessage: 'Log in to view discussion',
        description: 'Title of the alert page when the user tries to go to community without being logged in',
    },
    notLoggedInCommunityAlertBody: {
        id: 'notLoggedInCommunityAlertBody',
        defaultMessage: 'You must have a LabXchange account to access our community content. Please log in or sign up to access this content.',
        description: 'Body of the alert page when the user tries to go to community without being logged in',
    },
    notLoggedInMentorsAlertTitle: {
        id: 'notLoggedInMentorsAlertTitle',
        defaultMessage: 'Log in to view your mentoring dashboard',
        description: 'Title of the alert page when the user tries to go to the mentoring dashboard without being logged in',
    },
    notLoggedInMentorsAlertBody: {
        id: 'notLoggedInMentorsAlertBody',
        defaultMessage: 'You must have a LabXchange account to access our community content. Please log in or sign up to access this content.',
        description: 'Body of the alert page when the user tries to go to the mentoring dashboard without being logged in'
    },
    notEducatorClassroomAlertTitle: {
        id: 'notLearnerClassroomAlertTitle',
        defaultMessage: 'You don\'t have access to this page.',
        description: 'Title of the alert page when the user tries to go to the \'Classes I teach\' page without being a educator',
    },
    notEducatorClassroomAlertBody: {
        id: 'notLearnerClassroomAlertBody',
        defaultMessage: 'You may need to log in with different credentials to access this page.',
        description: 'Body of the alert page when the user tries to go to the \'Classes I teach\' page without being a educator',
    },
});


export default messages;
