import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { bind } from 'bind-decorator';

import { ItemMetadata } from 'labxchange-client';
import { getItemAndBlockData } from 'library/utils';
import { VideoBlock } from 'library/components/Block/BlockVideo';
import { BlockData, getEmptyBlockData, LoadingState } from 'library/components/Block/models';
import { skeletonItemMetadata } from 'skeletons';
import { intl } from 'i18n';


interface VideoProps {
    videoId: string;
    videoImageUrl: string;
    videoImageTitle: MessageDescriptor;
}

interface VideoState {
    blockData: BlockData;
    itemMetadata: ItemMetadata;
    loading: boolean;
    unauthorized: boolean;
    errStatus?: number;
}

export class PromoVideo extends React.PureComponent<VideoProps, VideoState> {

    constructor(props: VideoProps) {
        super(props);
        this.state = {
            blockData: getEmptyBlockData(),
            itemMetadata: skeletonItemMetadata,
            loading: true,
            unauthorized: false,
            errStatus: 404,
        };
    }

    public componentDidMount() {
        this.loadItemMetadata(this.props.videoId);
    }

    public render() {
        return this.state.loading
            ? <img src={this.props.videoImageUrl} alt={intl.formatMessage(this.props.videoImageTitle)} />
            : <VideoBlock blockData={this.state.blockData}
                     itemMetadata={this.state.itemMetadata}
                     onExpandBlock={this.onExpandBlock}
                     hideTranscripts={true}
               />;
    }

    /**
     * The XBlock on this page has requested to take up the full page width
     * (or not).
     * @param expand Whether or not to render the XBlock in full width
     */
    @bind private onExpandBlock(expand: boolean) {
        // do nothing
    }

    private async loadItemMetadata(itemId: string) {
        const response = await getItemAndBlockData(itemId);
        if (response.blockData.loadingState === LoadingState.READY) {
            this.setState({
                blockData: response.blockData,
                itemMetadata: response.itemData.metadata,
                loading: false,
            });
        } else {
            this.setState({
                errStatus: response.statusCode,
                unauthorized: response.statusCode === 401,
            });
        }
    }
}
