// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceRelationship
 */
export interface ResourceRelationship {
    /**
     * 
     * @type {number}
     * @memberof ResourceRelationship
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceRelationship
     */
    targetItemId: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRelationship
     */
    readonly targetItemTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRelationship
     */
    readonly targetItemType?: ResourceRelationshipTargetItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceRelationship
     */
    resourceCategory: ResourceRelationshipResourceCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceRelationship
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceRelationship
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceRelationship
     */
    readonly targetItemRelId?: string;
}

export function ResourceRelationshipFromJSON(json: any): ResourceRelationship {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'targetItemId': json['target_item_id'],
        'targetItemTitle': !exists(json, 'target_item_title') ? undefined : json['target_item_title'],
        'targetItemType': !exists(json, 'target_item_type') ? undefined : json['target_item_type'],
        'resourceCategory': json['resource_category'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'isPrivate': !exists(json, 'is_private') ? undefined : json['is_private'],
        'targetItemRelId': !exists(json, 'target_item_rel_id') ? undefined : json['target_item_rel_id'],
    };
}

export function ResourceRelationshipToJSON(value?: ResourceRelationship): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'target_item_id': value.targetItemId,
        'resource_category': value.resourceCategory,
        'title': value.title,
        'is_private': value.isPrivate,
    };
}

/**
* @export
* @enum {string}
*/
export enum ResourceRelationshipTargetItemTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}
/**
* @export
* @enum {string}
*/
export enum ResourceRelationshipResourceCategoryEnum {
    Learner = 'learner',
    Educator = 'educator',
    ExtensionTask = 'extension_task'
}


