import bind from 'bind-decorator';
import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';

import { UI_IS_MD, UI_IS_SM } from 'ui/breakpoints';
import {
    ExpandableText,
    ConfirmDuplicatesModal,
    DuplicateModalItem,
} from 'ui/components/';
import { CardFauxAdd } from 'ui/components/Card/Cards';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { KebabMenu, KebabMenuItem } from 'ui/components/KebabMenu';

import {
    ClassroomItemsList,
    ClassroomMembershipBox,
 } from 'classrooms/components';

import { ContentPickerModal } from 'library/components/ContentPickerModal';

import { ItemType } from 'items/models';
import {
    ClassroomDetail,
    ClassroomItem,
    ClassroomItemStatusEnum,
    ClassroomItemUpdateFromJSON,
    ClassroomMembership,
} from 'labxchange-client';

import {HeadingFour} from 'elements/components/Text';
import { ClassroomsApi } from 'global/api';
import Skeleton from 'react-loading-skeleton';

interface Props {
    classroom?: ClassroomDetail;
    showSkeleton?: boolean;
    reloadData(): void;
}

enum DeviceClass {
    mobile = 0,
    medium = 1,
    wide = 2,
}

interface State {
    showContentPickerModal: boolean;
    deviceClass: DeviceClass;
    showConfirmDuplicatesModal: boolean;
    duplicates: DuplicateModalItem[];
    addQueue: string[];  // ids of items to add
}

/**
 * ClassroomContentEducator is the Educator-perspective view of the content tab on a ClassroomPage.
 *
 * It is comprised of a ContentPicker for selecting content which can be posted / shared,
 * ClassroomItemsLists updating posted/shared state,
 * and ClassroomMembershipBox to display currently enrolled learners, along with actions to take
 * (message the classroom, or add learners)
 */
export class ClassroomContentEducator extends React.PureComponent<Props, State> {

    private mediaQueryMobile = UI_IS_SM;
    private mediaQueryMedium = UI_IS_MD;

    constructor(props: any) {
        super(props);

        this.state = {
            showContentPickerModal: false,
            deviceClass: DeviceClass.wide,
            showConfirmDuplicatesModal: false,
            duplicates: [],
            addQueue: [],
        };
    }

    public async componentDidMount() {
        this.mediaQueryMobile.addListener(this.setDeviceClass);
        this.mediaQueryMedium.addListener(this.setDeviceClass);
        this.setDeviceClass();
    }

    public componentWillUnmount() {
        this.mediaQueryMobile.removeListener(this.setDeviceClass);
        this.mediaQueryMedium.removeListener(this.setDeviceClass);
    }

    public render() {
        const classroomItems = this.props.classroom?.items || [];

        const itemsUnposted = classroomItems.filter((items) => items.status === 'unposted');
        // Show recently updated unposted classroom items at the bottom.
        itemsUnposted.sort((a, b) => {
          if (a.updated > b.updated) return 1;
          if (b.updated > a.updated) return -1;
          return 0;
        });

        const itemsPosted = classroomItems.filter((items) => items.status === 'posted');
        // Show recently posted items at the bottom
        itemsPosted.sort((a, b) => b.order - a.order);

        const members: ClassroomMembership[] = this.props.classroom?.members ? this.props.classroom.members : [];

        return (
            <div className='classroom-content-educator'>
                <div className='classroom-content-main'>
                    <div className='classroom-description'>
                        <h1>
                            {this.props.showSkeleton ?
                                <Skeleton /> :
                                <WrappedMessage message={messages.classDescriptionHeading} />
                            }
                        </h1>
                        <p>
                            <ExpandableText
                                text={this.props.classroom?.description}
                                limit={312}
                                showSkeleton={this.props.showSkeleton}
                            />
                        </p>
                    </div>

                    { this.state.showConfirmDuplicatesModal &&
                        <ConfirmDuplicatesModal
                            items={this.state.duplicates}
                            description={messages.duplicatesInClassConfirmDescription}
                            onConfirm={this.addDuplicates}
                            onCancel={this.onlyAddQueue}
                            onUpdate={(items) => {this.setState({ duplicates: items });}}
                        />
                    }

                    {
                        this.state.showContentPickerModal ?
                            <ContentPickerModal
                                onClose={this.onContentPickerClose}
                                onItemsAdd={this.onAddNewItems}
                                excludeTypes={[ItemType.Book, ItemType.Cluster]}
                                multipleSelect={true}
                            /> : null
                    }

                    {this.props.showSkeleton ? null :
                        this.state.deviceClass === DeviceClass.mobile &&
                        <ClassroomMembershipBox members={members} />
                    }

                    {this.props.showSkeleton ? null :
                        this.state.deviceClass === DeviceClass.medium &&
                        <div className='classroom-learners-score-container'>
                            <ClassroomMembershipBox members={members} />
                        </div>
                    }

                    <ClassroomItemsList
                        classroomId={this.props.classroom? this.props.classroom.id : ''}
                        classroomName={this.props.classroom? this.props.classroom.name : ''}
                        items={itemsPosted}
                        canReorder={true}
                        renderKebabMenu={(item) => this.renderKebabMenu(item, true)}
                        showPostedDate={true}
                        showSkeleton={this.props.showSkeleton}
                        showCompletion={false}
                    />

                    {itemsUnposted.length > 0 ?
                        <div className='classroom-items-unposted-container'>
                            <div className='classroom-items-list-header row'>
                                <HeadingFour level={2}>
                                    <WrappedMessage message={messages.unpostedContent} />
                                </HeadingFour>
                            </div>
                            <ClassroomItemsList
                                classroomId={this.props.classroom? this.props.classroom.id : ''}
                                classroomName={this.props.classroom? this.props.classroom.name : ''}
                                items={itemsUnposted}
                                onAddItem={this.onClassroomItemClicked}
                                renderKebabMenu={(item) => this.renderKebabMenu(item, false)}
                                showSkeleton={this.props.showSkeleton}
                                showCompletion={false}
                            />
                        </div> : null
                    }

                    <CardFauxAdd
                        onClick={this.onAddContentClicked}
                        message={messages.addContentButtonLabel}
                    />
                </div>

                {this.state.deviceClass === DeviceClass.wide &&
                    <div className='side'>
                        {this.props.showSkeleton ? null : <ClassroomMembershipBox members={members}>
                            {/* {
                            members.length > 0 ?
                                <button className='primary-button' type='button'
                                    id='classroom-content-educator-message-class'>
                                    <Icon name='comment-discussion' />
                                    <WrappedMessage message={messages.messageClassButtonLabel} />
                                </button>
                                :
                                <button className='primary-button' type='button'
                                    id='classroom-content-educator-add-members'>
                                    <WrappedMessage message={messages.addLearnersButtonLabel} />
                                </button>
                        } */}
                        </ClassroomMembershipBox>}
                    </div>
                }
            </div>
        );
    }

    @bind public renderKebabMenu(classroomItem: ClassroomItem, posted: boolean) {
        if (!this.props.showSkeleton) {
            return (
                <KebabMenu
                    buttonId={`classroom-item-card-menu-${classroomItem.id}`}
                    buttonAdditionalStyling={'kebab-button'}
                >
                    {posted &&
                    <KebabMenuItem
                        iconName='reply'
                        message={messages.actionUnpost}
                        disabled={false}
                        onClick={() => {
                            this.onItemUnpost(classroomItem);
                        }}
                    />
                    }
                    <KebabMenuItem
                        iconName='trashcan'
                        message={messages.actionRemove}
                        disabled={false}
                        onClick={() => {
                            this.onItemRemove(classroomItem);
                        }}
                    />
                </KebabMenu>
            );
        }
        return null;
    }

    @bind private async onAddNewItems(ids: Set<string>) {
        this.setState({showContentPickerModal: false});

        const existingItems = new Map((this.props.classroom?.items || []).map((x) => [
            x.item.metadata.id, x
        ]));

        const dups = [];
        const newIds = [];
        for (const id of ids) {
            const dup = existingItems.get(id);
            if (dup) {
                dups.push(dup);
            } else {
                newIds.push(id);
            }
        }

        // This logic gets messy because we need to call addItems and refresh parent data once,
        // otherwise intermediate steps may be lost when the parent reloads.
        // Here, we check for duplicates.  If there are none, we immediately add the items and the process is done.
        // If there are, we save all the items (dups and new items) to state, and show the dups confirm modal.
        // The dups confirm modal will call addDuplicates (which adds duplicates and new items) on confirm,
        // or addQueue on reject (which only adds the new items).
        // This ensures that the duplicates confirmation steps do not get skipped when data is reloaded.
        if (dups.length > 0) {
            this.setState({
                showConfirmDuplicatesModal: true,
                duplicates: dups,
                addQueue: newIds,
            });
        } else {
            this.addItems(newIds);
        }
    }

    @bind private resetQueue() {
        this.setState({
            duplicates: [],
            addQueue: [],
            showConfirmDuplicatesModal: false,
        });
    }

    @bind private async addDuplicates() {
        this.addItems(this.state.duplicates.map(x => x.item.metadata.id).concat(this.state.addQueue));
        this.resetQueue();
    }

    @bind private async onlyAddQueue() {
        this.addItems(this.state.addQueue);
        this.resetQueue();
    }

    private async addItems(items: string[]) {
        if (this.props.classroom) {
            const data = {
                classroom: this.props.classroom.id,
                items,
            };

            try {
                await ClassroomsApi.classroomItemsCreateMany({data});
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.failedToAddItem}/>, {
                    exception: err,
                    details: `Unable to add items (${items}) to Classroom (${this.props.classroom.id})`,
                });
            }

            // Refresh the UI so it the state is synced with the server, and added items are displayed.
            // Note that after calling this, the parent may destroy and recreate this component.
            this.props.reloadData();
        }
    }

    @bind private async onClassroomItemClicked(item: ClassroomItem) {
        this.updateItemStatus(item, ClassroomItemStatusEnum.Posted);
    }

    @bind private async onItemUnpost(item: ClassroomItem) {
        this.updateItemStatus(item, ClassroomItemStatusEnum.Unposted);
    }

    @bind private async onItemRemove(item: ClassroomItem) {
        try {
            await ClassroomsApi.classroomItemsDelete({ id: item.id! });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.failedToUpdateItem}/>, {
                exception: err,
                details: `Unable to delete ClassroomItem ${`item.id`}`,
            });
        }
        this.props.reloadData();
    }

    @bind private onAddContentClicked() {
        this.setState({showContentPickerModal: true});
    }

    @bind private onContentPickerClose() {
        this.setState({showContentPickerModal: false});
    }

    @bind private setDeviceClass(): void {
        if (this.mediaQueryMobile.matches) {
            this.setState({deviceClass: DeviceClass.mobile});
        } else if (this.mediaQueryMedium.matches) {
            this.setState({deviceClass: DeviceClass.medium});
        } else {
            this.setState({deviceClass: DeviceClass.wide});
        }
    }

    private async updateItemStatus(item: ClassroomItem, status: ClassroomItemStatusEnum) {
        try {
            const data = ClassroomItemUpdateFromJSON({ status });
            await ClassroomsApi.classroomItemsPartialUpdate({ id: item.id!, data });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.failedToUpdateItem}/>, {
                exception: err,
                details: `Unable to update status of ClassroomItem ${`item.id`}`,
            });
        }
        this.props.reloadData();
    }
}
