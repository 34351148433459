import * as LoginActions from 'auth/actions';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    StandardPageLayout,
    Button
} from 'ui/components';

import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

interface Props extends RouteComponentProps<{}>{
    title: string;
    body: string;
    primaryButtonText?: MessageDescriptor;
    primaryButtonOnClick?: () => void;
    secondaryButtonText?: MessageDescriptor;
    secondaryButtonOnClick?: () => void;
    image: string;
    headerFeature?: React.ReactNode;
    footerFeature?: React.ReactNode;
    /// [false] if you want to put the registration gate in the header
    /// [true] if you want to put the registration gate in the body
    contentInBody?: boolean;
    hideLayout?:boolean;
}

export class RegistrationGateInternal extends React.PureComponent<Props> {

    public render() {
      // Log that user encountered the registration gate
      analyticsInstance.track(EVENT_NAMES.RegistrationGateVisited, { url: window.location.toString() });
        return (
            <div className='library-registration-gate-wrapper'>
                {this.props.hideLayout ? this.renderRegistrationGate() :
                    <StandardPageLayout
                        headerFeature={
                            <div className='testtt'>
                                {this.props.headerFeature}
                                {this.props.contentInBody !== true && this.renderRegistrationGate()}
                            </div>
                        }
                        headerFeatureContainer={(this.props.contentInBody !== true)}
                        footerFeature={this.props.footerFeature}
                    >
                        {this.props.contentInBody === true && this.renderRegistrationGate()}
                    </StandardPageLayout>
                }
            </div>
        );
    }

    private renderRegistrationGate() {
        let containerClassName = 'container alert-container';
        if (this.props.contentInBody === true) {
            containerClassName += ' alert-body';
        }
        return (
            <div className={containerClassName}>
                 <img src={this.props.image} alt='' />
                 <div>
                    <h1>{this.props.title}</h1>
                    <h2>{this.props.body}</h2>
                 </div>
                 <div className='alert-buttons'>
                    {this.props.secondaryButtonText ? <Button
                        btnStyle='outline'
                        label={this.props.secondaryButtonText}
                        onClick={this.props.secondaryButtonOnClick || this.handleLogin}
                    />: null}
                    {this.props.primaryButtonText ? <Button
                        btnStyle='primary'
                        label={this.props.primaryButtonText}
                        onClick={this.props.primaryButtonOnClick || this.handleSignup}
                    />: null}
                 </div>
            </div>
        );
    }

    private handleLogin = () => {
        analyticsInstance.track(EVENT_NAMES.RegistrationGateLoginClicked, { url: window.location.toString() });
        LoginActions.redirectToLogin();
    }

    private handleSignup = () => {
        analyticsInstance.track(EVENT_NAMES.RegistrationGateSignupClicked, { url: window.location.toString() });
        LoginActions.redirectToLxSignUp();
    }
}

export const RegistrationGate = connect<Props>(
    null, null,
)(withRouter(RegistrationGateInternal));
