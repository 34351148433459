import React from 'react';

import { ModuleSlab, SectionSlab } from 'labxchange-client';
import { PathwaysCarousel } from 'home/components/TeachPage';

import { convertSlabToFeaturedItems } from './utils';

interface PathwaysSlabProps {
  heading: string;
  slab: SectionSlab | ModuleSlab;
}

export const PathwaysSlab: React.FC<PathwaysSlabProps> = (props) => {
  return (
    <div className='pathways-container section pathway-section scroll-section'>
      <h1 className='slab-title'>{props.heading}</h1>
      <PathwaysCarousel pathways={convertSlabToFeaturedItems(props.slab)} />
    </div>
  );
};

export default PathwaysSlab;
