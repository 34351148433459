// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemMetadata,
    ItemMetadataFromJSON,
    ItemMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExploreHeader
 */
export interface ExploreHeader {
    /**
     * 
     * @type {string}
     * @memberof ExploreHeader
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ExploreHeader
     */
    emphasizeWord: string;
    /**
     * 
     * @type {string}
     * @memberof ExploreHeader
     */
    buttonText: string;
    /**
     * 
     * @type {string}
     * @memberof ExploreHeader
     */
    buttonLink: string;
    /**
     * 
     * @type {Array<ItemMetadata>}
     * @memberof ExploreHeader
     */
    items: Array<ItemMetadata>;
}

export function ExploreHeaderFromJSON(json: any): ExploreHeader {
    return {
        'description': json['description'],
        'emphasizeWord': json['emphasize_word'],
        'buttonText': json['button_text'],
        'buttonLink': json['button_link'],
        'items': (json['items'] as Array<any>).map(ItemMetadataFromJSON),
    };
}

export function ExploreHeaderToJSON(value?: ExploreHeader): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'description': value.description,
        'emphasize_word': value.emphasizeWord,
        'button_text': value.buttonText,
        'button_link': value.buttonLink,
        'items': (value.items as Array<any>).map(ItemMetadataToJSON),
    };
}


