import React, { useState, useEffect } from 'react';

interface CareerHeaderProps {
    careerName: string;
    navLinks: { name: string; href: string }[];
    onNavClick: (href: string) => void;
}

export const CareerHeader: React.FC<CareerHeaderProps> = ({ careerName, navLinks, onNavClick }) => {
    const [selectedLink, setSelectedLink] = useState<number>(0);
    const [scrollY, setScrollY] = useState<number>(window.scrollY);
    const [showHeader, setShowHeader] = useState<boolean>(true);
    const scrollThreshold = 100;

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        // Show the header if scrolling up or if near the top
        if (currentScrollY < scrollY || currentScrollY < scrollThreshold) {
            setShowHeader(true);
        } else if (currentScrollY > scrollY) {
            // Hide the header only when scrolling down
            setShowHeader(false);
        }

        // Update the last scroll position
        setScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollY]);

    const handleClick = (href: string, index: number) => {
        setSelectedLink(index);
        onNavClick(href);
    };

    return (
        <header className={`career-header ${showHeader ? 'visible' : 'hidden'}`}>
            <div className='header-content-container'>
                <h1 className='header-content-container__title'>{careerName}</h1>
                <nav className='header-content-container__nav'>
                    <ul className='header-content-container__nav-list'>
                        {navLinks.map((link, index) => (
                            <li key={index} className='header-content-container__nav-item'>
                                <a
                                    href={link.href}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClick(link.href, index);
                                    }}
                                    className={`header-content-container__nav-link ${selectedLink === index ? 'header-content-container__nav-link-active' : ''}`}
                                >
                                    {link.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default CareerHeader;
