// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    LearnerReportUser,
    LearnerReportUserFromJSON,
    LearnerReportUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface LearnerReportMembership
 */
export interface LearnerReportMembership {
    /**
     * 
     * @type {LearnerReportUser}
     * @memberof LearnerReportMembership
     */
    user: LearnerReportUser;
    /**
     * 
     * @type {string}
     * @memberof LearnerReportMembership
     */
    status?: LearnerReportMembershipStatusEnum;
}

export function LearnerReportMembershipFromJSON(json: any): LearnerReportMembership {
    return {
        'user': LearnerReportUserFromJSON(json['user']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function LearnerReportMembershipToJSON(value?: LearnerReportMembership): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'user': LearnerReportUserToJSON(value.user),
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum LearnerReportMembershipStatusEnum {
    Requested = 'requested',
    Rejected = 'rejected',
    Joined = 'joined',
    Left = 'left'
}


