import { Store } from 'redux';
// RootState is imported as a type only - do not call any code from this module:
import { RootState } from './state';

// Global redux store
// To avoid circular imports, this is set at runtime in main.tsx
// Do not call getStore() at import time, as it may not be initialized yet.
let store: Store<RootState>;
export const setStore = (value: Store<RootState>) => { store = value; };
export const getStore = () => store;
