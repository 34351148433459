// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomEducator
 */
export interface ClassroomEducator {
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducator
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducator
     */
    readonly fullName?: string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomEducator
     */
    type?: number;
}

export function ClassroomEducatorFromJSON(json: any): ClassroomEducator {
    return {
        'username': json['username'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ClassroomEducatorToJSON(value?: ClassroomEducator): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'type': value.type,
    };
}


