import * as React from 'react';
import { Button, Icon } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface Props {
    videoUrl: string;
    channelUrl: string;
    channelName: string;
    channelLogoUrl: string;
}

export class AnnotatedVideoAttribution extends React.PureComponent<Props> {
    public render() {
        return <div className='annotated-video-attribution'>
            <div className='annotated-video-attribution-card'>
                <img src={this.props.channelLogoUrl} alt='Channel logo'/>
                <div>
                    <WrappedMessage message={messages.annotatedVideoAttribution1Text} />&nbsp;
                    <a
                        href={this.props.channelUrl}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {this.props.channelName}
                    </a>
                    <WrappedMessage message={messages.annotatedVideoAttribution2Text} />&nbsp;
                    <a
                        href={this.props.channelUrl}
                        target='_blank'
                        rel='noopener noreferrer'>
                        YouTube
                    </a>
                    <Icon name='link-external'/>
                </div>
                <Button
                    label={messages.annotatedVideoAttributionWatchOnYoutubeText}
                    btnStyle='outline'
                    icon='link-external'
                    iconPosition='left'
                    newWindow={true}
                    href={this.props.channelUrl}
                />
            </div>
            <div className='annotated-video-attribution-link'>
                <WrappedMessage message={messages.videoByText}/>&nbsp;
                <a
                    href={this.props.channelUrl}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {this.props.channelName}.
                </a>
                <Icon name='link-external'/>
            </div>
        </div>;
    }
}
