import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';

import { NavLink, NavLinkProps } from 'react-router-dom';
import { IconSymbol } from 'ui/components';
import { WrappedMessage } from 'utils';
import Skeleton from 'react-loading-skeleton';
import messages from '../displayMessages';

export interface NavTabsProps {
    children: React.ReactNode;
    // When the user clicks any link, do we want to scroll the page back to this tab component?
    scrollUpOnNavigate?: boolean;
    showSkeleton?: boolean;
}
export interface NavTabsLinkProps extends NavLinkProps {
    icon?: IconSymbol;
    message: MessageDescriptor;
    values?: any; // Values to pass into the <WrappedMessage message={message} />, if any
    showSkeleton?: boolean;
}

/**
 * Navigation tabs:
 *
 * Blue link tabs on a white background, inside a bootstrap .container.
 * Each link has an icon that's only visible on mobile.
 * If there are more links than fit on the screen, the user can scroll horizontally to see them all.
 *
 * Used on the Dashboards and Organization Profile pages.
 *
 * Design: https://app.zeplin.io/project/5db872c9eceabf5701126d0f/screen/5dc98fa9f0255e072334da02
 * (Note: the org profile page shows no margin between the tabs, but we are going with
 * the design shown on the learner/educator dashboards, which does - they should be the
 * same.)
 */
export const NavTabs: React.FunctionComponent<NavTabsProps> = ({
    children,
    scrollUpOnNavigate = false,
    showSkeleton = false
}) => {
    return <div className={`dashboard-nav-tabs${scrollUpOnNavigate ? ' lx-scroll-top-on-navigate' : ''}${showSkeleton ? ' skeleton' : ''}`}>
        {/* Change this to className='container-md' when we upgrade Bootstrap to 4.4. */}
        {/* We need to use container-md[-full] not container-sm - see the learner dashboard on tablet size for why */}
        <nav className='container container-md-full' aria-label={intl.formatMessage(messages.navigationMenuAriaLabel)}>
            {children}
        </nav>
    </div>;
};

export const NavTabsLink: React.FunctionComponent<NavTabsLinkProps> = ({
    icon,
    message,
    values,
    showSkeleton = false,
    ...navLinkProps
}) => {
    return <NavLink {...navLinkProps}>
        { showSkeleton ?
            <Skeleton /> :
            // icon && <Icon name={icon} zoom='22' />
            <WrappedMessage message={message} values={values} />
        }
    </NavLink>;
};
