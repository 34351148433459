import { defineMessages } from 'react-intl';

const messages = defineMessages({
    leaveClassTitle: {
        id:'leaveClassTitle',
        defaultMessage: 'You are about to leave this class',
        description: 'Title text for Leave Modal, which presents when a user attempts to leave a classroom from a Classroom Page',
    },
    leaveClassText: {
        id:'leaveClassText',
        defaultMessage: 'Your progress, discussion threads, and replies will not be lost. Your educator will be notified that you have left. If you wish to rejoin this class, you will need to reenter the class code.',
        description: 'Body text for Leave Modal, which presents when a user attempts to leave a classroom from a Classroom Page',
    },
    leaveClassLeaveButtonText: {
        id:'leaveClassLeaveButtonText',
        defaultMessage: 'Leave class',
        description: 'Leave button text for Leave Modal, which presents when a user attempts to leave a classroom from a Classroom Page',
    },
    leaveClassCancelButtonText: {
        id:'leaveClassCancelButtonText',
        defaultMessage: 'Back to class',
        description: 'Cancel button text for Leave Modal, which presents when a user attempts to leave a classroom from a Classroom Page',
    },
});

export default messages;
