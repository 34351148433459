// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Mentorship,
    MentorshipFromJSON,
    MentorshipToJSON,
    MentorshipApplyByMentee,
    MentorshipApplyByMenteeFromJSON,
    MentorshipApplyByMenteeToJSON,
    MentorshipCreate,
    MentorshipCreateFromJSON,
    MentorshipCreateToJSON,
    MentorshipItem,
    MentorshipItemFromJSON,
    MentorshipItemToJSON,
    MentorshipItemCreate,
    MentorshipItemCreateFromJSON,
    MentorshipItemCreateToJSON,
    MentorshipItemsResponse,
    MentorshipItemsResponseFromJSON,
    MentorshipItemsResponseToJSON,
} from '../models';

export interface ApplyByMenteeRequest {
    data: MentorshipApplyByMentee;
}

export interface ItemsRequest {
    id: string;
    currentPage?: number;
}

export interface ListRequest {
    page?: number;
    status?: string;
    source?: string;
    target?: string;
    initiator?: string;
    mentor?: string;
    mentee?: string;
    search?: string;
}

export interface MarkIncompleteRequest {
    id: string;
    data: any;
}

export interface MentorshipItemsCreateRequest {
    data: MentorshipItemCreate;
}

export interface MentorshipItemsDeleteRequest {
    id: string;
}

export interface MentorshipItemsListRequest {
    page?: number;
    mentorshipId?: string;
}

export interface MentorshipItemsReadRequest {
    id: string;
}

export interface PartialUpdateRequest {
    id: string;
    data: any;
}

export interface ReadRequest {
    id: string;
}

export interface UpdateRequest {
    id: string;
    data: MentorshipCreate;
}

/**
 * no description
 */
export class MentorshipsApi extends runtime.BaseAPI {

    /**
     * Note that this whole request is wrapped in transaction.atomic, so if something fails, the mentorship objects won't be updated.
     * API endpoint for a user to apply for mentorship as a mentee.
     */
    async applyByMenteeRaw(requestParameters: ApplyByMenteeRequest): Promise<runtime.ApiResponse<Mentorship>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling applyByMentee.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/apply_by_mentee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MentorshipApplyByMenteeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipFromJSON(jsonValue));
    }

    /**
     * Note that this whole request is wrapped in transaction.atomic, so if something fails, the mentorship objects won't be updated.
     * API endpoint for a user to apply for mentorship as a mentee.
     */
    async applyByMentee(requestParameters: ApplyByMenteeRequest): Promise<Mentorship> {
        const response = await this.applyByMenteeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Action that return mentorship items
     */
    async itemsRaw(requestParameters: ItemsRequest): Promise<runtime.ApiResponse<MentorshipItemsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling items.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipItemsResponseFromJSON(jsonValue));
    }

    /**
     * Action that return mentorship items
     */
    async items(requestParameters: ItemsRequest): Promise<MentorshipItemsResponse> {
        const response = await this.itemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all Mentorship for the currently logged in User
     */
    async listRaw(requestParameters: ListRequest): Promise<runtime.ApiResponse<Array<Mentorship>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        if (requestParameters.initiator !== undefined) {
            queryParameters['initiator'] = requestParameters.initiator;
        }

        if (requestParameters.mentor !== undefined) {
            queryParameters['mentor'] = requestParameters.mentor;
        }

        if (requestParameters.mentee !== undefined) {
            queryParameters['mentee'] = requestParameters.mentee;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MentorshipFromJSON));
    }

    /**
     * List all Mentorship for the currently logged in User
     */
    async list(requestParameters: ListRequest): Promise<Array<Mentorship>> {
        const response = await this.listRaw(requestParameters);
        return await response.value();
    }

    /**
     * Mark metnorship item as incompleted.
     */
    async markIncompleteRaw(requestParameters: MarkIncompleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncomplete.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling markIncomplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/{id}/mark_incomplete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Mark metnorship item as incompleted.
     */
    async markIncomplete(requestParameters: MarkIncompleteRequest): Promise<any> {
        const response = await this.markIncompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create MentorshipItem, which represents an association between an ItemMetadata and a Mentorship.
     */
    async mentorshipItemsCreateRaw(requestParameters: MentorshipItemsCreateRequest): Promise<runtime.ApiResponse<MentorshipItemCreate>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling mentorshipItemsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/mentorship-items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MentorshipItemCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipItemCreateFromJSON(jsonValue));
    }

    /**
     * Create MentorshipItem, which represents an association between an ItemMetadata and a Mentorship.
     */
    async mentorshipItemsCreate(requestParameters: MentorshipItemsCreateRequest): Promise<MentorshipItemCreate> {
        const response = await this.mentorshipItemsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unassign content: Delete MentorshipItem
     */
    async mentorshipItemsDeleteRaw(requestParameters: MentorshipItemsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mentorshipItemsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/mentorship-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unassign content: Delete MentorshipItem
     */
    async mentorshipItemsDelete(requestParameters: MentorshipItemsDeleteRequest): Promise<void> {
        await this.mentorshipItemsDeleteRaw(requestParameters);
    }

    /**
     * List MentorshipItems for a Mentorship
     */
    async mentorshipItemsListRaw(requestParameters: MentorshipItemsListRequest): Promise<runtime.ApiResponse<Array<MentorshipItem>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.mentorshipId !== undefined) {
            queryParameters['mentorship_id'] = requestParameters.mentorshipId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/mentorship-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MentorshipItemFromJSON));
    }

    /**
     * List MentorshipItems for a Mentorship
     */
    async mentorshipItemsList(requestParameters: MentorshipItemsListRequest): Promise<Array<MentorshipItem>> {
        const response = await this.mentorshipItemsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for Mentorship Content API
     */
    async mentorshipItemsReadRaw(requestParameters: MentorshipItemsReadRequest): Promise<runtime.ApiResponse<MentorshipItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mentorshipItemsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/mentorship-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipItemFromJSON(jsonValue));
    }

    /**
     * Viewset for Mentorship Content API
     */
    async mentorshipItemsRead(requestParameters: MentorshipItemsReadRequest): Promise<MentorshipItem> {
        const response = await this.mentorshipItemsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for managing Mentorships.
     */
    async partialUpdateRaw(requestParameters: PartialUpdateRequest): Promise<runtime.ApiResponse<Mentorship>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipFromJSON(jsonValue));
    }

    /**
     * Viewset for managing Mentorships.
     */
    async partialUpdate(requestParameters: PartialUpdateRequest): Promise<Mentorship> {
        const response = await this.partialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for managing Mentorships.
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<Mentorship>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipFromJSON(jsonValue));
    }

    /**
     * Viewset for managing Mentorships.
     */
    async read(requestParameters: ReadRequest): Promise<Mentorship> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for managing Mentorships.
     */
    async updateRaw(requestParameters: UpdateRequest): Promise<runtime.ApiResponse<MentorshipCreate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling update.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling update.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/mentorships/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MentorshipCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MentorshipCreateFromJSON(jsonValue));
    }

    /**
     * Viewset for managing Mentorships.
     */
    async update(requestParameters: UpdateRequest): Promise<MentorshipCreate> {
        const response = await this.updateRaw(requestParameters);
        return await response.value();
    }

}
