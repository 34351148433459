// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ImageUpload
 */
export interface ImageUpload {
    /**
     * 
     * @type {string}
     * @memberof ImageUpload
     */
    newImageUrl: string;
}

export function ImageUploadFromJSON(json: any): ImageUpload {
    return {
        'newImageUrl': json['new_image_url'],
    };
}

export function ImageUploadToJSON(value?: ImageUpload): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'new_image_url': value.newImageUrl,
    };
}


