import { defineMessages } from 'react-intl';

const messages = defineMessages({
    messageModalTitle: {
        id:'messageModalTitle',
        defaultMessage: 'Message {fullName}',
        description: 'Title for message modal.',
    },
    messageTextAreaPlaceholder: {
        id:'messageTextAreaPlaceholder',
        defaultMessage: 'Enter your message to {fullName}',
        description: 'Placeholder for message text area.',
    },
    messageTextAreaLabel: {
        id:'messageTextAreaLabel',
        defaultMessage: 'Message content',
        description: 'Label for message text area.',
    },
    messageInfoBoxText: {
        id:'messageInfoBoxText',
        defaultMessage: `{fullName} must accept your invitation before you can send them additional messages.`,
        description: 'Text from info box for message modal.',
    },
    messageSubmitButton: {
        id:'messageSubmitButton',
        defaultMessage: 'Send message',
        description: 'Compose submit button text.',
    },
    errorSenderDirectMessagesDisabled: {
        id:'errorSenderDirectMessagesDisabled',
        defaultMessage: 'You cannot message {name} because you have disabled direct messaging.',
        description: 'Modal title when user tried to send message when sender messaging is disabled.',
    },
    errorReceiverDirectMessagesDisabled: {
        id:'errorReceiverDirectMessagesDisabled',
        defaultMessage: 'You cannot message {name} because they have disabled direct messaging.',
        description: 'Modal title when user tried to send message when receiver messaging is disabled.',
    },
    errorCheckingIfCanMessage: {
        id:'errorCheckingIfCanMessage',
        defaultMessage: 'Error initiating message with user.',
        description: 'Label for button to click to send a direct message to the user.',
    },
    failedSendingWithReason: {
        id:'failedSendingWithReason',
        defaultMessage: 'This message could not be delivered. {reason}.',
        description: 'Failure message when sending message with a reason.',
    },
});

export default messages;
