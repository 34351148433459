import { defineMessages } from 'react-intl';

const messages = defineMessages({
    missingContentDescription: {
        id:'missingContentDescription',
        defaultMessage: 'Oops! Not the reaction we were expecting!',
        description: 'Description for the missing library item page',
    },
    backToHomeButton: {
        id:'backToHomeButton',
        defaultMessage: 'Back to home',
        description: 'Button which points to the homepage',
    }
});

export default messages;
