import { defineMessages } from 'react-intl';

const messages = defineMessages({
    filterClear: {
        id:'filterClear',
        defaultMessage: 'Clear all',
        description: 'Label for the button that clears search keywords and selected facets.',
    },
    filterClose: {
        id:'filterClose',
        defaultMessage: 'Close',
        description: 'Click to close filter options.',
    },
    filterHeading: {
        id:'filterHeading',
        defaultMessage: 'Refine by',
        description: 'Heading text for the search Facet filter bar.',
    },
    taxonomyFilterLabel: {
        id:'taxonomyFilterLabel',
        defaultMessage: 'Taxonomy Filter',
        description: 'Label for the taxonomy filter widget.',
    },
    taxonomyHeading: {
        id:'taxonomyHeading',
        defaultMessage: '{name}',
        description: 'Title of a group of facets to filter on. Used when no facets from this group are selected.',
    },
    taxonomyHeadingWithCount: {
        id:'taxonomyHeadingWithCount',
        defaultMessage: '{name} [{count, number}]',
        description: 'Title of a group of facets to filter on, showing a number (>0) of selected facets.',
    },
    taxonomySearch: {
        id:'taxonomySearch',
        defaultMessage: 'Search by {name}',
        description: 'Placeholder text displayed in the search box for a group of facets.',
    },
    taxonomyHeadingCount: {
        id:'taxonomyHeadingCount',
        defaultMessage: '[{count, number}]',
        description: 'Shows a number (>0) of selected facets.',
    },
    taxonomySubjectArea: {
        id:'taxonomySubjectArea',
        defaultMessage: 'Subject Area',
        description: 'Heading for subject area search facet.',
    },
    taxonomySubjectAreaSearch: {
        id:'taxonomySubjectAreaSearch',
        defaultMessage: 'Search by Subject Area',
        description: 'Heading for subject area search facet filter input.',
    },
    taxonomyContentType: {
        id:'taxonomyContentType',
        defaultMessage: 'Content Type',
        description: 'Heading for content type search facet.',
    },
    taxonomyContentTypeSearch: {
        id:'taxonomyContentTypeSearch',
        defaultMessage: 'Search by Content Type',
        description: 'Heading for content type search facet filter input.',
    },
    taxonomyCountry: {
        id: 'taxonomyCountry',
        defaultMessage: 'Country',
        description: 'Heading for country search facet.',
    },
    taxonomyCountrySearch: {
        id: 'taxonomyCountrySearch',
        defaultMessage: 'Search by Country',
        description: 'Heading for country search facet filter input.',
    },
    taxonomyBackgroundKnowledge: {
        id:'taxonomyBackgroundKnowledge',
        defaultMessage: 'Background Knowledge',
        description: 'Heading for Background Knowledge search facet.',
    },
    taxonomyBackgroundKnowledgeSearch: {
        id:'taxonomyBackgroundKnowledgeSearch',
        defaultMessage: 'Search by Background Knowledge',
        description: 'Heading for Background Knowledge search facet filter input.',
    },
    taxonomyVideoDuration: {
        id:'taxonomyVideoDuration',
        defaultMessage: 'Video Duration',
        description: 'Heading for Video Duration search facet.',
    },
    taxonomyVideoDurationSearch: {
        id:'taxonomyVideoDurationSearch',
        defaultMessage: 'Search by Video Duration',
        description: 'Heading for Video Duration search facet filter input.',
    },
    taxonomySource: {
        id:'taxonomyContentSource',
        defaultMessage: 'Source',
        description: 'Heading for Source search facet.',
    },
    taxonomySourceSearch: {
        id:'taxonomyContentSourceSearch',
        defaultMessage: 'Search by Source',
        description: 'Heading for Source search facet filter input.',
    },
    taxonomyPopularTags: {
        id:'taxonomyPopularTags',
        defaultMessage: 'Popular Tags',
        description: 'Heading for Popular Tags search facet.',
    },
    taxonomyPopularTagsSearch: {
        id:'taxonomyPopularTagsSearch',
        defaultMessage: 'Search by Popular Tags',
        description: 'Heading for Popular Tags search facet filter input.',
    },
    taxonomyRole: {
        id:'taxonomyRole',
        defaultMessage: 'Role',
        description: 'Heading for Role search facet.',
    },
    taxonomyRoleSearch: {
        id:'taxonomyRoleSearch',
        defaultMessage: 'Search by Role',
        description: 'Heading for Role search facet filter input.',
    },
    taxonomyInstitution: {
        id:'taxonomyInstitution',
        defaultMessage: 'Institution',
        description: 'Heading for Institution search facet.',
    },
    taxonomyInstitutionSearch: {
        id:'taxonomyInstitutionSearch',
        defaultMessage: 'Search by Institution',
        description: 'Heading for Institution search facet filter input.',
    },
    taxonomyInterests: {
        id:'taxonomyInterests',
        defaultMessage: 'Interests',
        description: 'Heading for Interests search facet.',
    },
    taxonomyInterestsSearch: {
        id:'taxonomyInterestsSearch',
        defaultMessage: 'Search by Interests',
        description: 'Heading for Interests search facet filter input.',
    },
    taxonomyAreaOfStudy: {
        id:'taxonomyAreaOfStudy',
        defaultMessage: 'Area of Study',
        description: 'Heading for Area of Study search facet.',
    },
    taxonomyAreaOfStudySearch: {
        id:'taxonomyAreaOfStudySearch',
        defaultMessage: 'Search by Area of Study',
        description: 'Heading for Area of Study search facet filter input.',
    },
    taxonomyLocation: {
        id:'taxonomyLocation',
        defaultMessage: 'Location',
        description: 'Heading for Location search facet.',
    },
    taxonomyLocationSearch: {
        id:'taxonomyLocationSearch',
        defaultMessage: 'Search by Location',
        description: 'Heading for Location search facet filter input.',
    },
    taxonomyForum: {
        id:'taxonomyForum',
        defaultMessage: 'Forum',
        description: 'Heading for Forum search facet.',
    },
    taxonomyLanguage: {
        id:'taxonomyLanguage',
        defaultMessage: 'Language',
        description: 'Heading for Language search facet.',
    },
    taxonomyForumSearch: {
        id:'taxonomyForumSearch',
        defaultMessage: 'Search by Forum',
        description: 'Heading for Forum search facet filter input.',
    },
    taxonomyLanguageSearch: {
        id:'taxonomyLanguageSearch',
        defaultMessage: 'Search by Language',
        description: 'Heading for Language search facet filter input.',
    },
    taxonomyOrganizationType: {
        id: 'taxonomyOrgType',
        defaultMessage: 'Organization Type',
        description: 'Heading for Organization Type search facet.',
    },
    taxonomyOrganizationTypeSearch: {
        id: 'taxonomyOrgTypeS',
        defaultMessage: 'Search by Organization Type',
        description: 'Heading for Organization Type search facet filter input.',
    },
    toggleFilters: {
        id:'toggleFilters',
        defaultMessage: 'Toggle filter options',
        description: 'Label for the button that toggles the filters in the library.',
    },
    searchTabContent: {
        id:'searchTabContent',
        defaultMessage: 'Content',
        description: 'Label for the Content search tab in the header',
    },
    searchTabDiscussion: {
        id:'searchTabDiscussion',
        defaultMessage: 'Discussion',
        description: 'Label for the Discussion search tab in the header',
    },
    searchTabLibrary: {
        id:'searchTabLibrary',
        defaultMessage: 'Library',
        description: 'Label for the Library search tab in the header',
    },
    searchTabPeople: {
        id:'searchTabPeople',
        defaultMessage: 'People',
        description: 'Label for the People search tab in the header',
    },
    searchTabOrganizations: {
        id: 'searchTabOrganizations',
        defaultMessage: 'Sources',
        description: 'Label for the Organizations search tab in the header',
    },
    loadingTags: {
        id: 'loading',
        defaultMessage: 'Loading...',
        description: 'Loading tags list for creation form',
    },
    loadingTagsError: {
        id: 'loading',
        defaultMessage: 'Loading...',
        description: 'Loading tags list for creation form',
    },
    tagItemWithCount: {
        id: 'tagItemWithCount',
        defaultMessage: '{name}\u00A0({count, number})', // \u00A0 is the non-breaking space character.
        description: 'Display the tag name with the number of entities related to that tag.',
    },
    'Chemistry': {
        id: 'Chemistry',
        defaultMessage: 'Chemistry',
        description: 'Chemistry subject'
    },
    'Biological Sciences': {
        id: 'Biological Sciences',
        defaultMessage: 'Biological Sciences',
        description: 'Biological Sciences subject',
    },
    'Astrobiology': {
        id: 'Astrobiology',
        defaultMessage: 'Astrobiology',
        description: 'Astrobiology subject'
    },
    'Biochemistry': {
        id: 'Biochemistry',
        defaultMessage: 'Biochemistry',
        description: 'Biochemistry subject'
    },
    'Bioengineering': {
        id: 'Bioengineering',
        defaultMessage: 'Bioengineering',
        description: 'Bioengineering subject'
    },
    'Biophysics': {
        id: 'Biophysics',
        defaultMessage: 'Biophysics',
        description: 'Biophysics subject'
    },
    'Biotechnology': {
        id: 'Biotechnology',
        defaultMessage: 'Biotechnology',
        description: 'Biotechnology subject'
    },
    'Cancer': {
        id: 'Cancer',
        defaultMessage: 'Cancer',
        description: 'Cancer subject'
    },
    'Cell Biology': {
        id: 'Cell Biology',
        defaultMessage: 'Cell Biology',
        description: 'Cell Biology subject'
    },
    'Computational Biology': {
        id: 'Computational Biology',
        defaultMessage: 'Computational Biology',
        description: 'Computational Biolo subjectgy'
    },
    'Developmental Biology': {
        id: 'Developmental Biology',
        defaultMessage: 'Developmental Biology',
        description: 'Developmental Biology subject'
    },
    'Drug Development': {
        id: 'Drug Development',
        defaultMessage: 'Drug Development',
        description: 'Drug Development subject'
    },
    'Ecology': {
        id: 'Ecology',
        defaultMessage: 'Ecology',
        description: 'Ecology subject'
    },
    'Evolution': {
        id: 'Evolution',
        defaultMessage: 'Evolution',
        description: 'Evolution subject'
    },
    'Genetics': {
        id: 'Genetics',
        defaultMessage: 'Genetics',
        description: 'Genetics subject'
    },
    'Immunology': {
        id: 'Immunology',
        defaultMessage: 'Immunology',
        description: 'Immunology subject'
    },
    'Lab Safety & Etiquette': {
        id: 'Lab Safety & Etiquette',
        defaultMessage: 'Lab Safety & Etiquette',
        description: 'Lab Safety & Etiquette subject'
    },
    'Marine Biology': {
        id: 'Marine Biology',
        defaultMessage: 'Marine Biology',
        description: 'Marine Biology subject'
    },
    'Microbiology': {
        id: 'Microbiology',
        defaultMessage: 'Microbiology',
        description: 'Microbiology subject'
    },
    'Molecular Biology': {
        id: 'Molecular Biology',
        defaultMessage: 'Molecular Biology',
        description: 'Molecular Biology subject'
    },
    'Neuroscience': {
        id: 'Neuroscience',
        defaultMessage: 'Neuroscience',
        description: 'Neuroscience subject'
    },
    'Physiology': {
        id: 'Physiology',
        defaultMessage: 'Physiology',
        description: 'Physiology subject'
    },
    'Plant Sciences': {
        id: 'Plant Sciences',
        defaultMessage: 'Plant Sciences',
        description: 'Plant Sciences subject'
    },
    'Stem Cells': {
        id: 'Stem Cells',
        defaultMessage: 'Stem Cells',
        description: 'Stem Cells subject'
    },
    'Structural Biology': {
        id: 'Structural Biology',
        defaultMessage: 'Structural Biology',
        description: 'Structural Biology subject'
    },
    'Synthetic Biology': {
        id: 'Synthetic Biology',
        defaultMessage: 'Synthetic Biology',
        description: 'Synthetic Biology subject'
    },
    'Systems Biology': {
        id: 'Systems Biology',
        defaultMessage: 'Systems Biology',
        description: 'Systems Biology subject'
    },
    'Techniques (Biology)': {
        id: 'Techniques (Biology)',
        defaultMessage: 'Techniques (Biology)',
        description: 'Techniques (Biology) subject'
    },
    'Virology': {
        id: 'Virology',
        defaultMessage: 'Virology',
        description: 'Virology subject'
    },
    'Zoology': {
        id: 'Zoology',
        defaultMessage: 'Zoology',
        description: 'Zoology subject'
    },
    'Global Health': {
        id: 'Global Health',
        defaultMessage: 'Global Health',
        description: 'Global Health subject'
    },
    'Climate Change in Health': {
        id: 'Climate Change in Health',
        defaultMessage: 'Climate Change in Health',
        description: 'Climate Change in Health subject'
    },
    'Communicable Disease': {
        id: 'Communicable Disease',
        defaultMessage: 'Communicable Disease',
        description: 'Communicable Disease subject'
    },
    'Demographic Shifts': {
        id: 'Demographic Shifts',
        defaultMessage: 'Demographic Shifts',
        description: 'Demographic Shifts subject'
    },
    'Health Systems': {
        id: 'Health Systems',
        defaultMessage: 'Health Systems',
        description: 'Health Systems subject'
    },
    'Maternal & Child Health': {
        id: 'Maternal & Child Health',
        defaultMessage: 'Maternal & Child Health',
        description: 'Maternal & Child Health subject'
    },
    'Mental Health': {
        id: 'Mental Health',
        defaultMessage: 'Mental Health',
        description: 'Mental Health subject'
    },
    'Noncommunicable Disease': {
        id: 'Noncommunicable Disease',
        defaultMessage: 'Noncommunicable Disease',
        description: 'Noncommunicable Disease subject'
    },
    'Sexual & Reproductive Health': {
        id: 'Sexual & Reproductive Health',
        defaultMessage: 'Sexual & Reproductive Health',
        description: 'Sexual & Reproductive Health subject'
    },
    'Substance Abuse': {
        id: 'Substance Abuse',
        defaultMessage: 'Substance Abuse',
        description: 'Substance Abuse subject'
    },
    'Health Science': {
        id: 'Health Science',
        defaultMessage: 'Health Science',
        description: 'Health Science subject'
    },
    'Anatomy': {
        id: 'Anatomy',
        defaultMessage: 'Anatomy',
        description: 'Anatomy subject'
    },
    'Diseases': {
        id: 'Diseases',
        defaultMessage: 'Diseases',
        description: 'Diseases subject'
    },
    'Epidemiology': {
        id: 'Epidemiology',
        defaultMessage: 'Epidemiology',
        description: 'Epidemiology subject'
    },
    'Health Care': {
        id: 'Health Care',
        defaultMessage: 'Health Care',
        description: 'Health Care subject'
    },
    'Medical Research': {
        id: 'Medical Research',
        defaultMessage: 'Medical Research',
        description: 'Medical Research subject'
    },
    'Medical Specialties': {
        id: 'Medical Specialties',
        defaultMessage: 'Medical Specialties',
        description: 'Medical Specialties subject'
    },
    'Pharmacology': {
        id: 'Pharmacology',
        defaultMessage: 'Pharmacology',
        description: 'Pharmacology subject'
    },
    'Prepare For Careers': {
        id: 'Prepare For Careers',
        defaultMessage: 'Prepare For Careers',
        description: 'Prepare For Careers subject'
    },
    'Prepare For College': {
        id: 'Prepare For College',
        defaultMessage: 'Prepare For College',
        description: 'Prepare For College subject'
    },
    'Financial Aid': {
        id: 'Financial Aid',
        defaultMessage: 'Financial Aid',
        description: 'Financial Aid subject'
    },
    'First Generation Students': {
        id: 'First Generation Students',
        defaultMessage: 'First Generation Students',
        description: 'First Generation Students subject'
    },
    'Transition to College': {
        id: 'Transition to College',
        defaultMessage: 'Transition to College',
        description: 'Transition to College subject'
    },
    'Your First Year': {
        id: 'Your First Year',
        defaultMessage: 'Your First Year',
        description: 'Your First Year subject'
    },
    'Prepare for Careers': {
        id: 'Prepare for Careers',
        defaultMessage: 'Prepare for Careers',
        description: 'Prepare for Collge subject'
    },
    'Prepare for College': {
        id: 'Prepare for College',
        defaultMessage: 'Prepare for College',
        description: 'Prepare for Collge subject'
    },
    'Prepare for Graduate School': {
        id: 'Prepare for Graduate School',
        defaultMessage: 'Prepare for Graduate School',
        description: 'Prepare for Graduate School subject'
    },
    'Professional Development': {
        id: 'Professional Development',
        defaultMessage: 'Professional Development',
        description: 'Professional Development subject'
    },
    'Science & Society': {
        id: 'Science & Society',
        defaultMessage: 'Science & Society',
        description: 'Science & Society subject'
    },
    'Bioethics': {
        id: 'Bioethics',
        defaultMessage: 'Bioethics',
        description: 'Bioethics subject'
    },
    'Scientific Communication': {
        id: 'Scientific Communication',
        defaultMessage: 'Scientific Communication',
        description: 'Scientific Communication subject'
    },
    'History of Science': {
        id: 'historyOfScience',
        defaultMessage: 'History of Science',
        description: 'History of Science subject'
    },
    'Math': {
        id: 'math',
        defaultMessage: 'Math',
        description: 'Math subject'
    },
    'Diversity, Inclusion and Belonging': {
        id: 'diversityInclusionAndBelonging',
        defaultMessage: 'Diversity, Inclusion and Belonging',
        description: 'Diversity, Inclusion and Belonging subject'
    },
    'Voices in Science': {
        id: 'voicesInScienceSubject',
        defaultMessage: 'Voices in Science',
        description: 'Voices in Science subject'
    },
    'Scientific Process': {
        id: 'scientificProcessSubject',
        defaultMessage: 'Scientific Process',
        description: 'Scientific Process subject'
    },
    'Data Analysis': {
        id: 'Data Analysis',
        defaultMessage: 'Data Analysis',
        description: 'Data Analysis subject'
    },
    'Ethics': {
        id: 'Ethics',
        defaultMessage: 'Ethics',
        description: 'Ethics subject'
    },
    'Experimental Design': {
        id: 'Experimental Design',
        defaultMessage: 'Experimental Design',
        description: 'Experimental Design subject'
    },
    'Scientific Method': {
        id: 'Scientific Method',
        defaultMessage: 'Scientific Method',
        description: 'Scientific Method subject'
    },
    'Online Learning': {
        id: 'Online Learning',
        defaultMessage: 'Online Learning',
        description: 'Online Learning subject'
    },
    'Physics': {
        id: 'Physics',
        defaultMessage: 'Physics',
        description: 'Physics subject'
    },
    'None': {
        id: 'backgroundKnowledgeNone',
        defaultMessage: 'None',
        description: 'Background Knowledge (None)'
    },
    'Some': {
        id: 'backgroundKnowledgeSome',
        defaultMessage: 'Some',
        description: 'Background Knowledge (Some)'
    },
    'Extensive': {
        id: 'backgroundKnowledgeExtensive',
        defaultMessage: 'Extensive',
        description: 'Extensive'
    },
    'Annotated video (New)': {
        id:'itemTypeAnnotatedVideo',
        defaultMessage: 'Annotated Video (New)',
        description: 'Display name of annotated video item type.',
    },
    'Question Set': {
        id:'itemTypeAssignment',
        defaultMessage: 'Question Set',
        description: 'Display name of assignment item type.',
    },
    'Question': {
        id:'itemTypeAssessment',
        defaultMessage: 'Question',
        description: 'Display name of assessment item type.',
    },
    'Audio': {
        id:'itemTypeAudio',
        defaultMessage: 'Audio',
        description: 'Audio item type.',
    },
    'Textbook': {
        id:'itemTypeBook',
        defaultMessage: 'Textbook',
        description: 'Display name of book item type.',
    },
    'Case Study': {
        id:'itemTypeCaseStudy',
        defaultMessage: 'Case Study',
        description: 'Display name of case study item type.',
    },
    'Cluster': {
        id:'simpleItemTypeCluster',
        defaultMessage: 'Cluster',
        description: 'Display name of cluster item type.',
    },
    'Document': {
        id:'itemTypeDocument',
        defaultMessage: 'Document',
        description: 'Display name of document item type.',
    },
    'Image': {
        id:'itemTypeImage',
        defaultMessage: 'Image',
        description: 'Display name of Image item type.',
    },
    'Interactive': {
        id:'itemTypeInteractive',
        defaultMessage: 'Interactive',
        description: 'Display name of interactive item type.',
    },
    'Narrative': {
        id:'itemTypeNarrative',
        defaultMessage: 'Narrative',
        description: 'Display name of narrative item type.',
    },
    'Pathway': {
        id:'simpleItemTypePathway',
        defaultMessage: 'Pathway',
        description: 'Display name of pathway item type.',
    },
    'Simulation': {
        id:'itemTypeSimulation',
        defaultMessage: 'Simulation',
        description: 'Simulation item type.',
    },
    'Teaching Guide': {
        id:'itemTypeTeachingGuide',
        defaultMessage: 'Teaching Guide',
        description: 'Display name of the teaching guide item type.',
    },
    'Text': {
        id:'itemTypeText',
        defaultMessage: 'Text',
        description: 'Display name of text item type.',
    },
    'Video': {
        id:'itemTypeVideo',
        defaultMessage: 'Video',
        description: 'Display name of video item type.',
    },
    'Unknown': {
        id:'itemTypeUnknown',
        defaultMessage: 'Unknown',
        description: 'Unknown item type.',
    },
    'Annotated video (New) (Plural)': {
        id:'itemTypeAnnotatedVideoPlural',
        defaultMessage: 'Annotated Videos (Beta)',
        description: 'Display name of annotated video item type in plural.',
    },
    'Question Set (Plural)': {
        id:'itemTypeAssignmentPlural',
        defaultMessage: 'Question Sets',
        description: 'Display name of assignment item type in plural.',
    },
    'Question (Plural)': {
        id:'itemTypeAssessmentPlural',
        defaultMessage: 'Questions',
        description: 'Display name of assessment item type in plural.',
    },
    'Audio (Plural)': {
        id:'itemTypeAudioPlural',
        defaultMessage: 'Audios',
        description: 'Audio item type in plural.',
    },
    'Textbook (Plural)': {
        id:'itemTypeBookPlural',
        defaultMessage: 'Textbooks',
        description: 'Display name of book item type in plural.',
    },
    'Case Study (Plural)': {
        id:'itemTypeCaseStudyPlural',
        defaultMessage: 'Case Studies',
        description: 'Display name of case study item type in plural.',
    },
    'Cluster (Plural)': {
        id:'simpleItemTypeClusterPlural',
        defaultMessage: 'Cluster',
        description: 'Display name of cluster item type in plural.',
    },
    'Document (Plural)': {
        id:'itemTypeDocumentPlural',
        defaultMessage: 'Documents',
        description: 'Display name of document item type in plural.',
    },
    'Image (Plural)': {
        id:'itemTypeImagePlural',
        defaultMessage: 'Images',
        description: 'Display name of Image item type in plural.',
    },
    'Interactive (Plural)': {
        id:'itemTypeInteractivePlural',
        defaultMessage: 'Interactives',
        description: 'Display name of interactive item type in plural.',
    },
    'Narrative (Plural)': {
        id:'itemTypeNarrativePlural',
        defaultMessage: 'Narratives',
        description: 'Display name of narrative item type in plural.',
    },
    'Pathway (Plural)': {
        id:'simpleItemTypePathwayPlural',
        defaultMessage: 'Pathways',
        description: 'Display name of pathway item type in plural.',
    },
    'Simulation (Plural)': {
        id:'itemTypeSimulationPlural',
        defaultMessage: 'Simulations',
        description: 'Simulation item type in plural.',
    },
    'Teaching Guide (Plural)': {
        id:'itemTypeTeachingGuidePlural',
        defaultMessage: 'Teaching Guides',
        description: 'Display name of the teaching guide item type in plural.',
    },
    'Text (Plural)': {
        id:'itemTypeTextPlural',
        defaultMessage: 'Texts',
        description: 'Display name of text item type in plural.',
    },
    'Video (Plural)': {
        id:'itemTypeVideoPlural',
        defaultMessage: 'Videos',
        description: 'Display name of video item type in plural.',
    },
    'Unknown (Plural)': {
        id:'itemTypeUnknownPlural',
        defaultMessage: 'Unknowns',
        description: 'Unknown item type in plural.',
    },
});

export default messages;
