import { intl } from 'i18n';
import { ProfileRoleEnum } from 'labxchange-client';
import * as React from 'react';
import { AuthPageLayout, Button, SelectionButton } from 'ui/components';
import messages from '../displayMessages';
import { RouteComponentProps } from 'react-router-dom';
import useAddQueryParam, { ProgressPercentageEnum } from '../../utils';

interface Props extends Partial<RouteComponentProps> {
    onDone?: (role: ProfileRoleEnum) => void;
    onSkip?: () => void;
    completeOnboarding: () => void;
}

export const SelectRoleView: React.FunctionComponent<Props> = (props) => {

    const [role, setRole] = React.useState<ProfileRoleEnum | undefined>(undefined);

    const onUpdateRole = (updatedRole: ProfileRoleEnum) => {
        setRole(updatedRole);
    };

    useAddQueryParam('step', 'SelectRole');

    const onDone = () => {
        if (props.onDone && role) {
            props.onDone(role);
        }
    };

    return (
        <AuthPageLayout
            imageUrl='/assets/images/auth-layouts/roles.svg'
            title={intl.formatMessage(messages.lxAuthSelectRoleViewTitle)}
            progressPercentage={ProgressPercentageEnum.RoleSelection}
        >
            <div className='select-role-container'>
                <h5>{intl.formatMessage(messages.lxAuthSelectRoleViewQuestion)}</h5>
                <div className='description'>
                    {intl.formatMessage(messages.lxAuthSelectRoleViewDescription)}
                </div>
                <div className='selectors-container'>
                    <SelectionButton
                        className='first learner'
                        label={messages.lxAuthSelectRoleViewLearnerLabel}
                        icon={role === ProfileRoleEnum.Learner ? 'teacher-hat-white' : 'teacher-hat'}
                        selected={role === ProfileRoleEnum.Learner}
                        onClick={() => onUpdateRole(ProfileRoleEnum.Learner)}
                    />
                    <SelectionButton
                        className='educator'
                        label={messages.lxAuthSelectRoleViewEducatorLabel}
                        icon={role === ProfileRoleEnum.Educator ? 'apple-educator-white' : 'apple-educator'}
                        selected={role === ProfileRoleEnum.Educator}
                        onClick={() => onUpdateRole(ProfileRoleEnum.Educator)}
                    />
                </div>
                <div className='buttons-container'>
                    <Button
                        className='first role-done-btn'
                        label={messages.lxAuthDoneLabel}
                        btnStyle='primary'
                        onClick={onDone}
                        disabled={role === undefined}
                    />
                </div>
            </div>
        </AuthPageLayout>
    );
};
