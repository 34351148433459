import React, { useState } from 'react';
import CollapsibleComponent from '../../../CareerCollapsible/CollapsibleComponent';
import { CareerCheckBox } from 'career-explorer/components/CareerCheckBox';

interface CheckListItemProps {
    checklistItem: any;
}

const CheckListItem: React.FC<CheckListItemProps> = ({checklistItem}) => {
    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

    const toggleCheckbox = (newValue: boolean) => {
        setCheckboxChecked(newValue);
    };

    return (
        <div className='checklist-item'>
            <CareerCheckBox checked={checkboxChecked} onClick={toggleCheckbox}>
                {checklistItem}
            </CareerCheckBox>
        </div>
    );
};

interface GuideProps {
    guideDetails: any;
}

const Guide: React.FC<GuideProps> = ({guideDetails}) => {

    const renderSubjects = (heading: string, subjectCategories: any) => {
        return (
            <div className='guide'>
                <div className='heading'>{heading}</div>
                <div className='collapsibles'>
                {subjectCategories && subjectCategories.map((subject: any, i: number) => (
                    <CollapsibleComponent key={i} title={subject.title} description={subject.description}>
                        <div className='children'>
                            {subject.subjects && subject.subjects.map((item: any, j: number) => (
                                    <CollapsibleComponent key={j} title={item.title} description={item.description}>
                                        {item.checklist &&
                                            <div className='checklist'>
                                                {item.checklist.map((checklistItem: string, k: number) => (
                                                    <CheckListItem key={k} checklistItem={checklistItem} />
                                                ))}
                                            </div>
                                        }
                                    </CollapsibleComponent>
                            ))}
                        </div>
                    </CollapsibleComponent>
                ))}
                </div>
            </div>
        );
    };

    return (
        <>
            {renderSubjects('Required', guideDetails.requiredSubjects)}
            {renderSubjects('Optional', guideDetails.optionalSubjects)}
        </>
    );
};

export default Guide;
