// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AppliedFilter
 */
export interface AppliedFilter {
    /**
     * 
     * @type {string}
     * @memberof AppliedFilter
     */
    filterOn: AppliedFilterFilterOnEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppliedFilter
     */
    filterValues: Array<string>;
}

export function AppliedFilterFromJSON(json: any): AppliedFilter {
    return {
        'filterOn': json['filter_on'],
        'filterValues': json['filter_values'],
    };
}

export function AppliedFilterToJSON(value?: AppliedFilter): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filter_on': value.filterOn,
        'filter_values': value.filterValues,
    };
}

/**
* @export
* @enum {string}
*/
export enum AppliedFilterFilterOnEnum {
    SubjectArea = 'SubjectArea',
    ItemType = 'ItemType',
    BackgroundKnowledge = 'BackgroundKnowledge',
    Duration = 'Duration',
    Partner = 'Partner',
    Language = 'Language',
    Tag = 'Tag',
    Containing = 'Containing',
    References = 'References'
}


