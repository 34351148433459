import * as React from 'react';

interface Props {
    children: React.ReactNode;
    mainClassName?: string;
}

export class EmbeddedPageLayout extends React.Component<Props> {
    // Component for displaying page inside an iframe without headers and footers
    public render() {
        return <div className={this.props.mainClassName}>{this.props.children}</div>;
    }
}
