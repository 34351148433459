import bind from 'bind-decorator';
import * as React from 'react';

import { connect } from 'react-redux';
import { RootState } from '../../../global/state';
import { getUserPermissions } from '../../../auth/selectors';

import { Icon } from 'elements';
import { ResultsCount, SortOptions, Tag } from 'ui/components';
import { WrappedMessage, showFilterTabsUi, showSearchBarInHero } from 'utils';
import messages from '../../displayMessages';
import Skeleton from 'react-loading-skeleton';
import { intl } from 'i18n';
import { ContentTypes, singularToPluralMapping } from 'search/components/Taxonomy';
import { TagData } from 'search/components/Tag';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { APIPermissions } from 'labxchange-client';
import classNames from 'classnames';

interface CardsListTopBarProps {
    isMobileView: boolean;
    isTabletView: boolean;
    isLargeView: boolean;
    filterShown: boolean;
    onClear: () => void;
    /** Event to call when a keyword filter (entered by the user in the search box) is removed */
    onClearFilter: (query: string) => void;
    /** Event to call when a tag filter (e.g. a specific Subject Area) is removed */
    onClearTag: (tag: string) => void;
    onSortSelect: (sortOrder: string) => void;
    /** In mobile view only, this event indicates the user wants to hide/show the list of tag filter options */
    onToggleFilter: () => void;
    resultCount: number;
    /** Set of keyword search terms/filters that are applied */
    searchQueries: ReadonlySet<string>;
    sortOrder: string;
    sortOrderOptions: { label: string, value: string}[];
    /** Set of tag filters (e.g. a specific subject area) that are applied */
    tags: ReadonlyArray<{id: string, name: string}>;
    /** Allow filter buttons to be shown or not */
    allowFiltering?: boolean;
    showSkeleton?: boolean;
    contentFilters?: ReadonlyArray<TagData>;
    /** Event when the user toggles a content filter */
    onToggleTagSelect?: (selectedTagIdSet: ReadonlySet<string>) => void;
    /** Set of all tag IDs in the current tag hierarchy that the user has selected. */
    selectedTagIds: ReadonlySet<string>;
}

interface CardsListTopBarState {
    appliedContentFilter: string;
    showAllContentFilters: boolean;
    disableShowAllContentFilters: boolean;
}

interface CardsListTopBarReduxState {
    userPermissions?: APIPermissions;
}

/**
 * The CardsListTopBar displays the number of pages in a paginated list of Cards.
 *
 * It also displays option dropdowns for number of pages and sort order.
 */
class CardsListTopBarInternal extends React.Component<CardsListTopBarProps & CardsListTopBarReduxState, CardsListTopBarState> {

    constructor(props: CardsListTopBarProps) {
        super(props);
        this.state = {
            appliedContentFilter: ContentTypes.All,
            showAllContentFilters: false,
            disableShowAllContentFilters: false
        };
    }

    componentDidUpdate(prevProps: CardsListTopBarProps) {
        if (prevProps.tags !== this.props.tags) {
            this.updateContentFilterState();
        }
    }

    updateContentFilterState() {
        const tagIds = this.props.tags.map(tag => tag.id);
        const selectedContentFilter = Object.values(ContentTypes).find(contentType =>
          tagIds.includes(contentType)
        );

        this.setState({
          appliedContentFilter: selectedContentFilter ? selectedContentFilter : ContentTypes.All
        });
    }

    public render() {

        const sortOptions = <SortOptions
            name='cards_sort_options'
            value={this.props.sortOrder}
            options={this.props.sortOrderOptions}
            onChange={this.props.onSortSelect}
            isMobileView={this.props.isMobileView}
        />;

        const filters = (this.props.searchQueries.size > 0 || this.props.tags.length > 0) ? (
            <div className='cards-list-top-bar-row-2'>
                {this.props.showSkeleton ?
                    <Skeleton />
                    : <>
                    { !showSearchBarInHero() &&
                        Array.from(this.props.searchQueries).map(((keyword) =>
                            <span className='keyword' key={keyword}>
                                Keyword: {keyword}
                                <button className={`${'btn'} ${'btn-link'} ${'clear-button'}`}
                                        onClick={() => {this.removeFilter(keyword); }}>
                                    <span className='sr-only'>
                                        <WrappedMessage message={messages.removeSearchCriteria} />
                                    </span>
                                    <Icon name='x' zoom='0.8em'/>
                                </button>
                            </span>
                        ))
                    }

                    {
                        this.props.tags.map(((tag) =>
                            <Tag key={tag.id} onClick={() => {this.removeTag(tag.id); }}>
                                {tag.name}
                                <span className='sr-only'>
                                    <WrappedMessage message={messages.removeSearchCriteria} />
                                </span>
                            </Tag>
                        ))
                    }

                    { (!showSearchBarInHero() || this.props.tags.length > 0) &&
                        <button className='btn clear-all' onClick={this.onClear}>
                            <WrappedMessage message={messages.filterClear}/>
                        </button>
                    }
                </>}
            </div>
        ) : undefined;

        const { contentFilters, isTabletView, isMobileView, isLargeView } = this.props;
        const { appliedContentFilter, showAllContentFilters, disableShowAllContentFilters } = this.state;
        const renderContentFilters = contentFilters ? (
            <div className='content-filters'>
              {Object.values(ContentTypes).map((contentType, index) => {
                const contentFilter =
                    contentFilters &&
                    contentFilters.find((filter) => filter.id === contentType);
                const tagId = contentFilter ? contentFilter.id : contentType === ContentTypes.All ? 'All' : '';
                const isSelected = appliedContentFilter === tagId;
                // Based on tablet and desktop view to show a different number of buttons
                // However, when creating a pathway, we need to display 6 as there is a lot of space available.
                const isPathwayNew = /^\/library\/pathway\/new$/.test(window.location.pathname);
                let numberOfButtonToShow = (isTabletView || isLargeView) && !isPathwayNew ? 4 : 6;
                // disable toggle button if selected filter is from second or third row
                const disableToggleButton = numberOfButtonToShow < index ? true : false;
                numberOfButtonToShow = showAllContentFilters? (Object.keys(ContentTypes).length - 1): numberOfButtonToShow;

                return (
                    <React.Fragment key={contentType}>
                    {index === 1 && <div className='vertical-divider'></div>}

                        {(index <= numberOfButtonToShow ||
                            (index > numberOfButtonToShow && (showAllContentFilters || isMobileView))) && (
                            <>
                                {(contentType === ContentTypes.Link && !this.props.userPermissions?.library.canCreateLinkItem)? <> </> :
                                    <button
                                        className={classNames('btn-content-filter', {'btn-selected' : isSelected, 'btn-inactive' : !tagId && contentType !== ContentTypes.All})}
                                        key={contentType}
                                        id={tagId}
                                        disabled={!tagId}
                                        onClick={() => {
                                            if (tagId) {
                                                this.toggleContentFilter(tagId, this.isTagSelected(tagId), disableToggleButton);
                                            }
                                        }}
                                    >
                                        {singularToPluralMapping[contentType]}
                                        {contentType === ContentTypes.AnnnotatedVideo &&
                                          <span className='new-tag'>
                                            <WrappedMessage message={messages.newTage}/>
                                          </span>
                                        }
                                    </button>
                                }
                            </>
                        )}

                    {index === numberOfButtonToShow && !isMobileView && (
                        <button className={`btn-toggle ${showAllContentFilters ? 'is-open' : ''}`}
                            aria-label={intl.formatMessage(messages.contentFiltersToggle)}
                            disabled={disableShowAllContentFilters}
                            onClick={this.toggleShowAllContentFilters}
                        >
                        <Icon
                            name={showAllContentFilters ? 'chevron-up-tabs' : 'chevron-down-tabs'}
                            className='icon-toggle'
                        />
                        </button>
                    )}
                    </React.Fragment>
                );
                })}
            </div>
          ) : null;

        const isExclusiveTabletView = !this.props.isMobileView && this.props.isTabletView;
        const isDesktopView = !this.props.isMobileView && !this.props.isTabletView;
        // Show results: on desktop and in modals when the filters are hidden.
        const showResultsCount = isDesktopView || (this.props.isTabletView && !this.props.filterShown);

        const showFilterTabs = showFilterTabsUi();
        const searchQuery = this.props.searchQueries.size > 0 ? Array.from(this.props.searchQueries)[0] : '';
        const clearQueryButton = showFilterTabs && searchQuery ?
            <button className='btn btn-link clear-query'
                    onClick={() => {this.removeFilter(searchQuery);}}
                    >
                <span className='sr-only'>
                    <WrappedMessage message={messages.clearSearchCriteria} />
                </span>
                <WrappedMessage message={messages.queryClear}/>
            </button>
            : null;
        return (
            <div className='cards-list-top-bar'>
                {showFilterTabs && renderContentFilters}
                    { (isDesktopView || this.props.isTabletView) && this.props.showSkeleton ? <Skeleton />
                      : showResultsCount && showFilterTabs &&
                      <div className='cards-list-top-bar-row-1 d-flex hide-on-mobile'>
                            <div className='result-count mr-auto'>
                                <ResultsCount totalCount={this.props.resultCount} searchQuery={searchQuery}/>
                                {clearQueryButton}
                            </div>
                        </div>
                    }
                <div className='cards-list-top-bar-row-1 d-flex'>
                    {this.props.showSkeleton ? <Skeleton /> : showFilterTabs ?
                        <div className='tabs-title-parent mr-auto hide-on-mobile'>
                            {this.state.appliedContentFilter === 'All' ?
                                <WrappedMessage
                                    message={messages.allContentTypes}
                                /> :
                                singularToPluralMapping[this.state.appliedContentFilter as ContentTypes]}
                        </div>
                        :
                        <div className='cards-list-top-bar-row-1 d-flex hide-on-mobile'>
                            <div className='result-count mr-auto'>
                                <ResultsCount totalCount={this.props.resultCount} searchQuery={searchQuery}/>
                            </div>
                        </div>
                    }
                    <div className={`result-controls${this.props.isMobileView ? 'result-controls-mobile' : ''}`}>
                        {this.props.showSkeleton ? <Skeleton /> : <>
                            <div className='options-container'>{ sortOptions }</div>
                        </>}
                    </div>
                    {
                        (!this.props.showSkeleton && (this.props.isMobileView || this.props.isTabletView)) &&
                        this.props.allowFiltering &&
                        <>
                            <div className='options-container'>
                                { !showSearchBarInHero() &&
                                    <button
                                    className={`btn filter-toggle ${isExclusiveTabletView ? ' is-tablet-view' : ''}`}
                                    onClick={this.onToggleFilter}
                                    title={intl.formatMessage(messages.filterToggle)}
                                    >
                                        <Icon name='settings' zoom='1.4em' />
                                        {isExclusiveTabletView &&
                                            <span><WrappedMessage message={messages.filterToggle} /></span>
                                        }
                                    </button>
                                }
                            </div>
                        </>
                    }
                    { this.props.isMobileView &&
                        <div className='result-count mr-auto'>
                            <ResultsCount totalCount={this.props.resultCount} searchQuery={searchQuery}/>
                            {clearQueryButton}
                        </div>
                    }
                </div>
                { !showFilterTabs && filters }
            </div>
        );
    }

    @bind private onToggleFilter(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        this.props.onToggleFilter();
    }

    @bind private onClear(event: React.MouseEvent<HTMLButtonElement>): void {
        event.preventDefault();
        this.props.onClear();
    }

    @bind private removeFilter(keyword: string) {
        this.props.onClearFilter(keyword);
    }

    @bind private removeTag(tagId: string) {
        this.props.onClearTag(tagId);
    }

    @bind private toggleContentFilter(tagId: string, alreadySelected: boolean, disableToggleButton: boolean) {
        const selectedTagIdSet = new Set(this.props.selectedTagIds);
        const { appliedContentFilter } = this.state;

        if (tagId === ContentTypes.All) {
          if (appliedContentFilter === ContentTypes.All) {
            return;
          } else {
            selectedTagIdSet.delete(appliedContentFilter);
            this.resetState();
          }
        } else if (alreadySelected) {
          selectedTagIdSet.delete(tagId);
          this.resetState();
        } else {
          selectedTagIdSet.add(tagId);
          if (this.isTagSelected(appliedContentFilter)) {
            selectedTagIdSet.delete(appliedContentFilter);
          }

          this.setState({
            appliedContentFilter: tagId,
            disableShowAllContentFilters: disableToggleButton
          });
        }

        if (this.props.onToggleTagSelect) {
          this.props.onToggleTagSelect(selectedTagIdSet);
        }
    }

    @bind private resetState(){
          this.setState({
            disableShowAllContentFilters: false,
            appliedContentFilter: ContentTypes.All,
          });
    }

    @bind private isTagSelected(tagId: string) {
        return this.props.selectedTagIds.has(tagId);
    }

    @bind private toggleShowAllContentFilters() {
        this.setState((prevState) => ({
            showAllContentFilters: !prevState.showAllContentFilters
        }), () => {
            this.createToggleMoreFilterTabsClickedEvent(this.state.showAllContentFilters);
        });
    }

    @bind private createToggleMoreFilterTabsClickedEvent(showMore: boolean) {
        analyticsInstance.track(
            EVENT_NAMES.ToggleMoreFilterTabsClicked,
            {
                show_more: showMore,
                url: window.location.toString(),
            }
      );
    }
}

export const CardsListTopBar = connect<CardsListTopBarReduxState, RootState> (
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
    }),
)(CardsListTopBarInternal);
