import { defineMessages } from 'react-intl';

const messages = defineMessages({
    communityTitle: {
        id:'communityTitle',
        defaultMessage: 'Community',
        description: 'Title for the Community page.',
    },
    discussionsTab: {
        id: 'discussionsTab',
        defaultMessage: 'Discussion',
        description: 'Discussions page tab',
    },
    menteesTitle: {
        id: 'dashboardMenteesTitle',
        defaultMessage: 'Mentees',
        description: 'Mentees page tab',
    },
    mentorsTab: {
        id: 'mentorsTab',
        defaultMessage: 'Mentors',
        description: 'Mentors page tab',
    },
});

export default messages;
