import { createAnalyticsEventWithDelay } from 'tracking/utils';
import { AnalyticsEventEventTypeEnum, ItemMetadataTypeEnum } from 'labxchange-client';
import { XBlockType } from '../BlockEditor/models';
import { AssignmentBlock } from './BlockAssignment';
import { CaseStudyBlock, TeachingGuideBlock } from './BlockCaseStudy';
import { DocumentBlock } from './BlockDocument';
import { SimulationBlock } from './BlockSimulation';
import { VideoBlock } from './BlockVideo';
import { AudioBlock } from './BlockAudio';
import { AnnotatedVideoBlock } from './BlockAnnotatedVideo';
import { ImageBlock } from './BlockImage';
import { QuestionBlock } from './QuestionBlock';
import { AnalyticsApi } from 'global/api';
import { RichBlock } from './RichBlock';
import { NarrativeBlock } from './BlockNarrative';

export const blockTypeFromUsageKey = (usageKey: string): XBlockType => {

    if (!usageKey) {
        return XBlockType.Other;
    }
    let blockTypePart: string;

    if (usageKey.startsWith('lb:')) {
        // Get the 'lx_image' part of the ID (e.g. 'lb:LabXchange:466906f5:lx_image:1')
        blockTypePart = usageKey.split(':')[3];
    } else if (usageKey.startsWith('lx-pb:')) {
        // Get the 'lx_image' part of the ID (e.g. 'lx-pb:00000000-e4fe-47af-8ff6-123456789000:lx_image:c4f6e73d')
        blockTypePart = usageKey.split(':')[2];
    } else if(usageKey.startsWith('lx')) {
        // pathway, clusters are not valid block types and has different usage key structure
        // hence we need to check it differently for navigation between resource and asset
        blockTypePart = usageKey.split('-')[1].split(':')[0];
    } else {
        return XBlockType.Other;
    }
    // Looking up a TypeScript string enum value by value is a bit tricky...
    const blockTypeKeys = Object.keys(XBlockType) as (keyof typeof XBlockType)[];
    const foundKey = blockTypeKeys.find((typeKey) => XBlockType[typeKey] === blockTypePart);
    // Return the XBlockType if it's a known type, otherwise return XBlockType.Other
    return (foundKey && XBlockType[foundKey]) || XBlockType.Other;
};

/**
 * Special UI component for XBlock display.
 */
export const componentForType = (itemType: ItemMetadataTypeEnum): any => {
    switch (itemType) {
        case ItemMetadataTypeEnum.AnnotatedVideo:
            return AnnotatedVideoBlock;
        case ItemMetadataTypeEnum.Assignment:
            return AssignmentBlock;
        case ItemMetadataTypeEnum.Assessment:
            return QuestionBlock;
        case ItemMetadataTypeEnum.Audio:
            return AudioBlock;
        case ItemMetadataTypeEnum.CaseStudy:
            return CaseStudyBlock;
        case ItemMetadataTypeEnum.Document:
            return DocumentBlock;
        case ItemMetadataTypeEnum.Link:
            return DocumentBlock;
        case ItemMetadataTypeEnum.LxText:
            return RichBlock;
        case ItemMetadataTypeEnum.Image:
            return ImageBlock;
        case ItemMetadataTypeEnum.Interactive:
            return SimulationBlock;
        case ItemMetadataTypeEnum.LxVideo:
            return VideoBlock;
        case ItemMetadataTypeEnum.Narrative:
            return NarrativeBlock;
        case ItemMetadataTypeEnum.Question:
            return QuestionBlock;
        case ItemMetadataTypeEnum.Simulation:
            return SimulationBlock;
        case ItemMetadataTypeEnum.TeachingGuide:
            return TeachingGuideBlock;
        case ItemMetadataTypeEnum.Text:
            return RichBlock;
        case ItemMetadataTypeEnum.Video:
            return VideoBlock;
        default:
            /// Unknown
            return RichBlock;
    }
};


/**
 * Return whether the AssetView event should be triggered on display.
 * For some block types it is triggered on further actions.
 */
export const shouldAutoTrackAssetView = (usageKey: string): boolean => {
    const blockType = blockTypeFromUsageKey(usageKey);
    switch (blockType) {
        case XBlockType.Document: // Triggered when Show document is clicked.
            return false;
        case XBlockType.Interactive: // Triggered when Start interactive is clicked.
            return false;
        case XBlockType.Simulation: // Triggered when Start simulation is clicked.
            return false;
        case XBlockType.Video: // Triggered when video starts playing.
            return false;
        case XBlockType.LXVideo: // Triggered when video starts playing.
            return false;
        default:
            return true;
    }
};

export const createAssetViewedEventForItem = (usageKey: string, delay=true) => {
    if (delay) {
        createAnalyticsEventWithDelay({
            data: {
                eventType: AnalyticsEventEventTypeEnum.AssetViewed,
                objectId: usageKey,
            },
        });
    } else {
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.AssetViewed,
                objectId: usageKey,
            },
        });
    }
};
