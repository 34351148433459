// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    MentorshipItem,
    MentorshipItemFromJSON,
    MentorshipItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MentorshipItemsResponse
 */
export interface MentorshipItemsResponse {
    /**
     * 
     * @type {Array<MentorshipItem>}
     * @memberof MentorshipItemsResponse
     */
    readonly items?: Array<MentorshipItem>;
    /**
     * 
     * @type {number}
     * @memberof MentorshipItemsResponse
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof MentorshipItemsResponse
     */
    page: number;
}

export function MentorshipItemsResponseFromJSON(json: any): MentorshipItemsResponse {
    return {
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<any>).map(MentorshipItemFromJSON),
        'count': json['count'],
        'page': json['page'],
    };
}

export function MentorshipItemsResponseToJSON(value?: MentorshipItemsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'page': value.page,
    };
}


