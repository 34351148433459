import { bind } from 'bind-decorator';
import * as React from 'react';

import { MessageDescriptor } from 'react-intl';
import { Icon, IconSymbol } from 'elements';
import { NotificationsApi } from 'global/api';
import { CheckBox } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { NotificationSettings } from 'labxchange-client';
import { intl } from '../../../i18n';

type SettingUpdate = { [K in keyof NotificationSettings]: boolean; };

interface SettingData {
    icon: IconSymbol;
    notifyKey: keyof NotificationSettings;
    emailKey: keyof NotificationSettings;
    message: MessageDescriptor;
}

const settingsData: SettingData[] = [
    {
        notifyKey: 'notifyNewMessages',
        emailKey: 'notifyNewMessagesEmail',
        message: messages.notificationSettingsNewMessages,
        icon: 'email',
    },
    {
        notifyKey: 'notifyClasses',
        emailKey: 'notifyClassesEmail',
        message: messages.notificationSettingsClasses,
        icon: 'classroom',
    },
    {
        notifyKey: 'notifyClassDiscussions',
        emailKey: 'notifyClassDiscussionsEmail',
        message: messages.notificationSettingsClassDiscussions,
        icon: 'discussion',
    },
    {
        notifyKey: 'notifyContentUpdate',
        emailKey: 'notifyContentUpdateEmail',
        message: messages.notificationSettingsContentUpdate,
        icon: 'document',
    },
    {
        notifyKey: 'notifyDiscussionForum',
        emailKey: 'notifyDiscussionForumEmail',
        message: messages.notificationSettingsDiscussionForum,
        icon: 'discussions',
    },
    {
        notifyKey: 'notifyMentorship',
        emailKey: 'notifyMentorshipEmail',
        message: messages.notificationSettingsMentorship,
        icon: 'mentorship',
    },
    {
        notifyKey: 'notifyProductInterviews',
        emailKey: 'notifyProductInterviewsEmail',
        message: messages.notificationSettingsProductInterviews,
        icon: 'arrow-up-tailed',
    },
];

export class NotificationSettingsComponent extends React.Component<{}, NotificationSettings> {

    public constructor(props: {}) {
        super(props);
        this.state = {
            notifyNewMessages: true,
            notifyNewMessagesEmail: true,
            notifyClasses: true,
            notifyClassesEmail: true,
            notifyClassDiscussions: true,
            notifyClassDiscussionsEmail: true,
            notifyContentUpdate: true,
            notifyContentUpdateEmail: true,
            notifyDiscussionForum: true,
            notifyDiscussionForumEmail: true,
            notifyMentorship: true,
            notifyMentorshipEmail: true,
            notifyProductInterviews: true,
            notifyProductInterviewsEmail: true,
        };
    }

    public async componentDidMount() {
        let ns: NotificationSettings;
        try {
            ns = await NotificationsApi.getSettings();
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.notificationSettingsGetFailed}/>, {exception: err});
            return;
        }
        this.setState(ns);
    }

    public render() {
        const settings = settingsData.map(this.renderSetting);
        return (
            <div className='notification-settings'>
                {settings}
                <div>
                    <Icon name={'email'} fill='#333333' />
                    <span className='notification-description'>
                        <WrappedMessage message={messages.newsletterSettingUpdateInformationHeading} />
                    </span>
                    <hr />
                    <WrappedMessage message={messages.newsletterSettingUpdateInformation} />
                </div>
            </div>
        );
    }

    @bind private renderSetting(data: SettingData, index: number): React.ReactNode {
        return (
            <div key={index} role='group' aria-labelledby={`notification-description-${index}`}>
                <Icon name={data.icon} fill='#333333' />
                <span className='notification-description' id={`notification-description-${index}`}>
                    <WrappedMessage message={data.message} />
                </span>
                <hr />
                <CheckBox
                    checked={this.state[data.notifyKey]}
                    onClick={() => this.toggleSetting(data.notifyKey)}
                    altLabel={intl.formatMessage(messages.notificationMediumNotify)}
                >
                    <WrappedMessage message={messages.notificationSettingsNotification} />
                </CheckBox>
                <CheckBox
                    checked={this.state[data.emailKey]}
                    onClick={() => this.toggleSetting(data.emailKey)}
                    altLabel={intl.formatMessage(messages.notificationMediumEmail)}
                >
                    <WrappedMessage message={messages.notificationSettingsEmail} />
                </CheckBox>
            </div>
        );
    }

    @bind private async toggleSetting(key: keyof NotificationSettings) {
        // Optimistically update the local state,
        // but save the old setting so we can revert if it failed.
        const old = this.state[key];
        this.setState({ [key]: !old } as SettingUpdate);
        try {
            await NotificationsApi.updateSettings({ data: { [key]: !old } as SettingUpdate});
        } catch (err) {
            // There was an error; assume setting failed to update and revert so state is correct.
            this.setState({ [key]: old } as SettingUpdate);
            showErrorMessage(<WrappedMessage message={messages.notificationSettingsUpdateFailed}/>, {exception: err});
        }
    }
}
