import { defineMessages } from 'react-intl';

const messages = defineMessages({
    userCardAboutTitleAndInstitution: {
        id: 'userCardAboutTitleAndInstitution',
        defaultMessage: '{title} at {institution}',
        description: 'Text to be shown in profile card',
    },
    userCardAboutTitle: {
        id: 'userCardAboutTitle',
        defaultMessage: '{title}',
        description: 'Text to be shown in profile card',
    },
    userCardAboutInstitution: {
        id: 'userCardAboutInstitution',
        defaultMessage: '{institution}',
        description: 'Text to be shown in profile card',
    },
    userCardAboutCountry: {
        id: 'userCardAboutCountry',
        defaultMessage: '{country}',
        description: 'Text to be shown in profile card',
    },
    userCardSelectButtonLabel: {
        id: 'userCardSelectButtonLabel',
        defaultMessage: 'Select',
        description: 'Label for button to select a user card.',
    },
    userCardSelectedButtonLabel: {
        id: 'userCardSelectedButtonLabel',
        defaultMessage: 'Selected',
        description: 'Label for button to unselect selected user card.',
    },
    removeButton: {
        id:'removeButton',
        defaultMessage: 'Remove',
        description: 'Remove label.',
    },
});

export default messages;
