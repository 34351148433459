import * as BannerActions from '../../actions';
import * as React from 'react';
import { Banner } from 'labxchange-client';
import { BannerItem } from '../BannerItem';
import { connect } from 'react-redux';
import { getVisibleBanners } from '../../selectors';
import { LXThunkUnwrap } from 'global/types';
import { RootState } from 'global/state';
import { PolicyAndTermsUpdatesBannerId } from '../../actions';

interface StateProps {
    banners?: ReadonlyArray<Banner>;
}

interface ActionProps {
    dismiss: typeof BannerActions.dismissBanner;
}

interface Props extends StateProps, LXThunkUnwrap<ActionProps> {
    sticky?: boolean;
}

export class BannerListInternal extends React.PureComponent<Props> {
    public render() {
        const {banners, dismiss, sticky} = this.props;

        if (banners && banners.length) {
            return (
                <ul className={`banner-list list-unstyled ${sticky ? 'banner-list-sticky' : ''}`}>
                {banners.map((banner) => (
                    <li key={banner.id}>
                        <BannerItem banner={banner} dismiss={dismiss} className={banner.id === PolicyAndTermsUpdatesBannerId ? 'banner-policy-update' : ''} />
                    </li>
                ))}
                </ul>
            );
        } else {
            return null;
        }
    }
}

class BannerListContainer extends React.PureComponent<Props> {
    public render() {
        return (
            <BannerListInternal
                banners={this.props.banners}
                dismiss={this.props.dismiss}
                sticky={this.props.sticky}
            />
        );
    }
}

export const BannerList = connect<StateProps, ActionProps, {}, RootState>(
    (state: RootState) => ({
        banners: getVisibleBanners(state),
    }), {
        dismiss: BannerActions.dismissBanner,
    },
)(BannerListContainer);
