import { ItemType } from 'items/models';
import { ItemMetadata, ItemMetadataBackgroundKnowledgeEnum, ItemMetadataLicenseEnum } from 'labxchange-client';
import { locale } from 'i18n';

export interface Resource {
    kind: string;
    data: string;
    mimetype: string;
}

export interface Fragment {
    content: string;
    json_init_args: null;
    js_init_version: null;
    js_init_fn: null;
    resources: Resource[];
}

export function getEmptyItemMetadata(type: ItemType, uploadedDate: Date): ItemMetadata {
    return {
        authors: [{
            fullName: '',
            username: '',
        }],
        backgroundKnowledge: ItemMetadataBackgroundKnowledgeEnum.Empty,
        clonedFrom: '',
        description: '',
        freeFormTags: [],
        funders: [],
        id: '',
        imageUrl: '',
        itemCount: 0,
        language: locale,
        learningObjectives: [],
        license: ItemMetadataLicenseEnum.LX1,
        references: '',
        stats: {
            clones: 0,
            favorites: 0,
            remixes: 0,
            publicRemixes: 0,
            views: 0,
        },
        subjectTags: [],
        title: '',
        sendPiiMetadata: false,
        resources: [],
        uploadedDate,
        type,
    };
}
