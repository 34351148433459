// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompletedPathwayResponse,
    CompletedPathwayResponseFromJSON,
    CompletedPathwayResponseToJSON,
    TotalScoreResponse,
    TotalScoreResponseFromJSON,
    TotalScoreResponseToJSON,
} from '../models';

export interface ProgressCompletedPathwaysRequest {
    type?: string;
    page?: number;
    paginationSize?: number;
}

export interface ProgressTotalScoreRequest {
    page?: number;
    paginationSize?: number;
}

/**
 * no description
 */
export class LearningApi extends runtime.BaseAPI {

    /**
     * Return completed pathways
     */
    async progressCompletedPathwaysRaw(requestParameters: ProgressCompletedPathwaysRequest): Promise<runtime.ApiResponse<CompletedPathwayResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.paginationSize !== undefined) {
            queryParameters['pagination_size'] = requestParameters.paginationSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/learning/progress/completed_pathways`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompletedPathwayResponseFromJSON(jsonValue));
    }

    /**
     * Return completed pathways
     */
    async progressCompletedPathways(requestParameters: ProgressCompletedPathwaysRequest): Promise<CompletedPathwayResponse> {
        const response = await this.progressCompletedPathwaysRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return total score items
     */
    async progressTotalScoreRaw(requestParameters: ProgressTotalScoreRequest): Promise<runtime.ApiResponse<TotalScoreResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.paginationSize !== undefined) {
            queryParameters['pagination_size'] = requestParameters.paginationSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/learning/progress/total_score`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TotalScoreResponseFromJSON(jsonValue));
    }

    /**
     * Return total score items
     */
    async progressTotalScore(requestParameters: ProgressTotalScoreRequest): Promise<TotalScoreResponse> {
        const response = await this.progressTotalScoreRaw(requestParameters);
        return await response.value();
    }

}
