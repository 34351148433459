import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleLearnerMentorsDashboard',
        defaultMessage: 'Mentors',
        description: 'Title of the learner dashboard page that lists all learner\'s mentors.',
    },
    mentorsDescription: {
        id:'mentorsDescription',
        defaultMessage: 'Check in with your mentors here.',
        description: 'Description text of mentors dashboard.',
    },
    removeButton: {
        id:'removeButton',
        defaultMessage: 'Remove',
        description: 'Remove label.',
    },
    messageButton: {
        id:'messageButton',
        defaultMessage: 'Message',
        description: 'Send a message button.',
    },
    addNewMentorButton: {
        id:'addNewMentorButton',
        defaultMessage: 'Search for a mentor',
        description: 'Search a mentor button label.',
    },
    pastMentorsSection: {
        id:'pastMentorsSection',
        defaultMessage: 'Past mentors',
        description: 'Past mentors section.',
    },
    assignmentsButton: {
        id:'assignmentsButton',
        defaultMessage: 'Question Sets',
        description: 'Assignments button label.',
    },
    onFailedToStop: {
        id:'onFailedToStop',
        defaultMessage: 'Error while sending your stopped request. Please try again later.',
        description: 'Error message when trying to send mentorship stopped request.',
    },
    workAssignedByModal: {
        id:'workAssignedByModal',
        defaultMessage: 'Work assigned by {fullName}',
        description: 'Assignments modal title.',
    },
    emptyMessage: {
        id:'emptyMessage',
        defaultMessage: 'Your mentor has not assigned you any content yet.',
        description: 'Empty message when not items are assigned to mentorship.',
    },
    errorLoading: {
        id:'errorLoading',
        defaultMessage: 'Unable to load the list of mentors.',
        description: '',
    },
    reRequestMentorship: {
        id:'reRequestMentorship',
        defaultMessage: 'Re-request mentorship',
        description: 'Label on button to re-request mentorship with a user.',
    },
    mentorshipReRequested: {
        id:'mentorshipReRequested',
        defaultMessage: 'Mentorship re-request sent',
        description: 'Label on pending mentorship when re-requested',
    },
    emptyMentorsDashboardTitle: {
        id:'emptyMentorsDashboardTitle',
        defaultMessage: 'You don’t have a mentor yet.',
        description: 'Title for the empty content mentors dashboard page.',
    },
    emptyMentorsDashboardSecondaryText: {
        id:'emptyMentorsDashboardSecondaryText',
        defaultMessage: 'You can search LabXchange and find a mentor who may help you achieve your goals.',
        description: 'Secondary text for the empty mentors dashboard page.',
    },
    emptyMentorsDashboardAction: {
        id:'emptyMentorsDashboardAction',
        defaultMessage: 'Search for a mentor',
        description: 'Action button for the empty mentors dashboard page.',
    },
});

export default messages;
