// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SearchSuggestion
 */
export interface SearchSuggestion {
    /**
     * 
     * @type {number}
     * @memberof SearchSuggestion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SearchSuggestion
     */
    query: string;
}

export function SearchSuggestionFromJSON(json: any): SearchSuggestion {
    return {
        'id': json['id'],
        'query': json['query'],
    };
}

export function SearchSuggestionToJSON(value?: SearchSuggestion): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'query': value.query,
    };
}


