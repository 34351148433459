import bind from 'bind-decorator';
import * as React from 'react';
import { Button, ButtonProps } from 'ui/components';

interface Props extends ButtonProps {
    /** The file content type */
    contentType: string;
    /** Async callback to get file content,
     * return empty to not trigger the download
     */
    getValue(): Promise<string|undefined>;
    /** Callback that return the filename */
    getFileName(): string;
}

/***
 *
 * Button that download a file
 * Should only be used for small file because it use inlined data.
 */
export class DownloadButton extends React.PureComponent<Props> {
    public render() {
        return (
            <Button
                {...this.props}
                onClick={this.onClick}
            />
        );
    }

    @bind private async onClick() {
        const value = await this.props.getValue();
        if (!value) { return; }
        const exportElement = document.createElement('a');
        exportElement.setAttribute(
            'href',
            `data:${this.props.contentType};charset=utf-8,` + encodeURIComponent(value),
        );
        exportElement.setAttribute('download', this.props.getFileName());
        exportElement.style.display = 'none';
        document.body.appendChild(exportElement);
        exportElement.click();
        document.body.removeChild(exportElement);
    }
}
