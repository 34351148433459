import * as React from 'react';

import {
    Button,
    KebabMenu,
    ShareModal,
} from 'ui/components';
import { FavoriteButton } from 'ui/components/FavoriteButton';
import { Cluster, ModerationRequestContentTypeEnum } from 'labxchange-client';
import { ReportButton } from 'moderation/components';
import libraryMessages from 'library/displayMessages';
import { detailUrlForEntity } from 'library/utils';

interface ItemActionsProps {
    clusterKey: string;
    cluster: Cluster;
}

interface ItemActionsState {
    keepKebabExpanded: boolean;
    shareModalVisible: boolean;
}

export class ClusterActionButtons extends React.PureComponent<ItemActionsProps, ItemActionsState> {

    constructor(props: ItemActionsProps) {
        super(props);
        this.state = {
            keepKebabExpanded: false,
            shareModalVisible: false,
        };
    }

    public render() {
        const { clusterKey, cluster } = this.props;
        return (
            <div className='buttons'>
                <FavoriteButton
                    itemId={clusterKey}
                    isFavorite={cluster.metadata.userAttributes.isFavorite}
                    showLabel={true}
                    btnStyle='small'
                    titleAddFavorite={libraryMessages.actionFavorite}
                    itemType='cluster'
                />
                <Button
                    btnStyle='unstyled'
                    icon='share'
                    onClick={() => {this.setState({shareModalVisible: true});}}
                    label={libraryMessages.actionShare}
                />
                <KebabMenu
                    keepExpanded={this.state.keepKebabExpanded}
                    darkKebab={true}
                    icon='kebab-horizontal'
                >
                    <ReportButton
                        asKebab={true}
                        itemId={cluster.metadata.metadata.id}
                        itemTitle={cluster.metadata.metadata.title}
                        itemType={ModerationRequestContentTypeEnum.LibraryItemmetadata}
                        label={libraryMessages.actionReport}
                        onOpen={() => this.setState({keepKebabExpanded: true})}
                        onClose={() => this.setState({keepKebabExpanded: false})}
                    />
                </KebabMenu>

                {this.state.shareModalVisible &&
                    <ShareModal
                        getItemUrl={() => detailUrlForEntity(cluster.metadata.metadata)}
                        itemTitle={cluster.metadata.metadata.title}
                        itemDescription={cluster.metadata.metadata.description}
                        itemImageUrl={cluster.metadata.metadata.imageUrl}
                        onClose={() => { this.setState({shareModalVisible: false}); }}
                    />
                }
            </div>
        );
    }
}
