import bind from 'bind-decorator';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { RootState } from 'global/state';
import { HtmlTextBox } from 'ui/components';
import { intl } from 'i18n';

import MultiAdvancedSettings from '../components/AdvancedSettings/MultiAdvancedSettings';
import MultiSelectAnswers from '../components/UnitEditorWidget/MultiSelectAnswers';

import * as actionTypes from '../store/actions/action-types';


const messages = defineMessages({
    title: {
        id: 'MultiSelectContainer.title',
        defaultMessage: 'Question*'
    },
    description: {
        id: 'MultiSelectContainer.description',
        defaultMessage: 'Create a question that has more than one answer.'
    }
});


export class MultiSelectContainer extends React.Component<any, any> {

    render() {
        const { formatMessage } = intl;
        const answersList = this.prepareAnswers();
        return (
            <div className='lxc-unit-editor-wrapper'>
                <fieldset className='lxc-answers-wrapper'>
                    <legend className='lxc-answers-title'>
                        {formatMessage(messages.title)}
                    </legend>
                    <div className='lxc-answers-description'>
                        {formatMessage(messages.description)}
                    </div>
                    <HtmlTextBox
                        label='Question Editor'
                        hideLabel={true}
                        defaultValue={this.props.editorContent}
                        onChange={this.onEditorContentChange}
                        editorStyle={HtmlTextBox.EditorStyle.Default}
                        extraClassName='question-body-editor'
                    />
                </fieldset>
                <MultiSelectAnswers
                    answersList={answersList}
                    multiSelectAddAnswer={this.props.multiSelectAddAnswer}
                    multiSelectRemoveAnswer={this.props.multiSelectRemoveAnswer}
                    multiSelectChangeAnswer={this.props.multiSelectChangeAnswer}

                />
                <MultiAdvancedSettings
                    answersList={this.props.answersList}
                    hints={this.props.hints}

                    generalFeedbackList={this.props.generalFeedbackList}
                    generalFeedbackChange={this.props.generalFeedbackChange}

                    hintAdd={this.props.hintAdd}
                    hintRemove={this.props.hintRemove}
                    hintChange={this.props.hintChange}

                    scorringSelectedPointOption={this.props.scorringSelectedPointOption}
                    scorringselectedAttemptsOption={this.props.scorringselectedAttemptsOption}
                    scorringattemptsOptions={this.props.scorringattemptsOptions}
                    scorringPointsOptions={this.props.scorringPointsOptions}
                    scorringAttemptsChange={this.props.scorringAttemptsChange}
                    scorringPointsChange={this.props.scorringPointsChange}
                />
            </div>
        )
    }

    @bind private onEditorContentChange(content: string) {
        this.props.multiEditorContentChange(content);
    }

    @bind private prepareAnswers() {
        const answersList = this.props.answersList;
        if (!answersList.some((element: any) => element.id == 'A')) {
            answersList.push({
                id: 'A',
                correct: true,
                title: "",
                selectedFeedback: "",
                unselectedFeedback: "",
                answer: "",
            });
        }
        if (!answersList.some((element: any) => element.id == 'B')) {
            answersList.push({
                id: 'B',
                correct: false,
                title: "",
                selectedFeedback: "",
                unselectedFeedback: "",
                answer: "",
            });
        }
        return answersList;
    }
};

const mapStateToProps = (store: RootState) => {
    return {
        // editor content
        editorContent: store.assessmentEditorState.multiSelectEditor.content,
        // multi answers
        answersList: store.assessmentEditorState.multiSelectAnswers.multiSelectAnswersList,
        // general feedback
        generalFeedbackList: store.assessmentEditorState.multiSelectAnswers.generalFeedbackList,
        // hints
        hints: store.assessmentEditorState.hintSettings.hints,
        // scorring
        scorringSelectedPointOption: store.assessmentEditorState.scorringSettings.selectedPointOption,
        scorringselectedAttemptsOption: store.assessmentEditorState.scorringSettings.selectedAttemptsOption,
        scorringattemptsOptions: store.assessmentEditorState.scorringSettings.attemptsOptions,
        scorringPointsOptions: store.assessmentEditorState.scorringSettings.pointsOptions
    }
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        // editor
        multiEditorContentChange: (content: any) => {
            return dispatch({ type: actionTypes.MULTI_EDITOR_CONTENT_CHANGE, content: content });
        },
        // multiSelect answers
        multiSelectAddAnswer: (event: any) => {
            return dispatch({ type: actionTypes.MULTI_SELECT_ANSWERS_ADD_NEW });
        },
        multiSelectRemoveAnswer: (id: any) => {
            return dispatch({ type: actionTypes.MULTI_SELECT_ANSWERS_REMOVE, id: id });
        },
        multiSelectChangeAnswer: (event: any) => {
            return dispatch({ type: actionTypes.MULTI_SELECT_ANSWERS_CHANGED, ...event });
        },
        // generalFeedback
        generalFeedbackChange: (data: any) => {
            return dispatch({ type: actionTypes.GENERAL_FEEDBACK_CHANGE, ...data });
        },
        // Hints
        hintAdd: (event: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_ADD });
        },
        hintRemove: (id: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_REMOVE, id: id });
        },
        hintChange: (data: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_CHANGED, ...data });
        },
        // scorring settings
        scorringAttemptsChange: (value: any) => {
            return dispatch({ type: actionTypes.SCORRING_TEMPTS_CHANGED, ...value })
        },
        scorringPointsChange: (value: any) => {
            return dispatch({ type: actionTypes.SCORRING_POINTS_CHANGED, ...value })
        }


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectContainer);
