import { defineMessages } from 'react-intl';

const messages = defineMessages({
    stepProgressText: {
        id:'stepProgressText',
        defaultMessage: 'Step {step} of {numSteps}.',
        description: 'Step progression as text.',
    },
    selectRoleTitle: {
        id:'selectRoleTitle',
        defaultMessage: 'Select a role',
        description: 'User role picker title.',
    },
    learnerTitleRole: {
        id:'learnerTitleRole',
        defaultMessage: 'I am a learner',
        description: 'Title for learner role.',
    },
    learnerDescriptionRole: {
        id:'learnerDescriptionRole',
        defaultMessage: 'I want to learn about science.',
        description: 'Description for learner role.',
    },
    learnerRegisterAsButtonLabel: {
        id:'learnerRegisterAsButtonLabel',
        defaultMessage: 'Register as a learner',
        description: 'Learner role confirmation button.',
    },
    educatorTitleRole: {
        id:'educatorTitleRole',
        defaultMessage: 'I am an educator',
        description: 'Title for educator role.',
    },
    educatorDescriptionRole: {
        id:'educatorDescriptionRole',
        defaultMessage: 'I want to create content and share content to learners.',
        description: 'Description for educator role.',
    },
    educatorRegisterAsButtonLabel: {
        id:'educatorRegisterAsButtonLabel',
        defaultMessage: 'Register as an educator',
        description: 'Educator role confirmation button.',
    },
    skipRoleRegistrationButton: {
        id:'skipRoleRegistrationButton',
        defaultMessage: 'Skip',
        description: 'Skip role selection button.',
    },
    schoolNameLabel: {
        id:'schoolNameLabel',
        defaultMessage: 'School name',
        description: 'School name input label.',
    },
    schoolNamePlaceholder: {
        id:'schoolNamePlaceholder',
        defaultMessage: 'Enter school name',
        description: 'School name input placeholder.',
    },
    institutionLabel: {
        id:'institutionLabel',
        defaultMessage: 'Institution',
        description: 'Institution input label.',
    },
    institutionPlaceholder: {
        id:'institutionPlaceholder',
        defaultMessage: 'Enter institution name',
        description: 'Institution name input placeholder.',
    },
    jobTitleLabel: {
        id:'jobTitleLabel',
        defaultMessage: 'Job title',
        description: 'Job title input label.',
    },
    jobTitlePlaceholder: {
        id:'jobTitlePlaceholder',
        defaultMessage: 'Enter job title',
        description: 'Job title name input placeholder.',
    },
    schoolTypeLabel: {
        id:'schoolTypeLabel',
        defaultMessage: 'What type of school do you attend?',
        description: 'School type input label.',
    },
    schoolTypePlaceholder: {
        id:'schoolTypePlaceholder',
        defaultMessage: 'Select a school type',
        description: 'School type input placeholder.',
    },
    highSchoolTypeName: {
        id:'highSchoolTypeName',
        defaultMessage: 'High school',
        description: 'High school type option label.',
    },
    middleSchoolTypeName: {
        id:'middleSchoolTypeName',
        defaultMessage: 'Middle school',
        description: 'Middle school type option label.',
    },
    otherSchoolTypeName: {
        id:'otherSchoolTypeName',
        defaultMessage: 'Other',
        description: 'Other school type option label.',
    },
    classCodeLabel: {
        id:'classCodeLabel',
        defaultMessage: 'Class code',
        description: 'Class code input label.',
    },
    classCodePlaceholder: {
        id:'classCodePlaceholder',
        defaultMessage: 'Enter class code',
        description: 'Class code input placeholder.',
    },
    profileDetailsTitle: {
        id:'profileDetailsTitle',
        defaultMessage: 'Profile details',
        description: 'Profile details title for user registration form.',
    },
    optionalText: {
        id:'optionalText',
        defaultMessage: 'optional',
        description: 'Optional text.',
    },
    errorSavingProfileChanges: {
        id:'errorSavingProfileChanges',
        defaultMessage: 'The change to your profile could not be saved. Please try again later.',
        description: 'Error message shown when the system cannot make changes to a user\'s profile.',
    },
    yourEducationTitle: {
        id:'yourEducationTitle',
        defaultMessage: 'Your education',
        description: 'Title for user education information form.',
    },
    currentSchoolInformationTitle: {
        id:'currentSchoolInformationTitle',
        defaultMessage: 'Current school information',
        description: 'Title for current school information form.',
    },
    signUpBirthDateQuestion: {
        id:'signUpBirthDateQuestion',
        defaultMessage: 'Are you 13 or older?',
        description: 'Age question on signup page.',
    },
    signUpBirthDateDescription: {
        id:'signUpBirthDateDescription',
        defaultMessage: 'You have to be 13 or above to sign up for LabXchange.',
        description: 'Description text about which age user should have to user LabXchange.',
    },
    signupBirthDateLabel: {
        id:'signupBirthDateLabel',
        defaultMessage: 'Date of birth',
        description: 'Signup date of birth input label.',
    },
    invalidClassCodeModalTitle: {
        id:'invalidClassCodeModalTitle',
        defaultMessage: 'Invalid class code',
        description: 'Invalid class code modal title.',
    },
    invalidClassCodeModalDescription: {
        id:'invalidClassCodeModalDescription',
        defaultMessage: 'The class code you entered is incorrect. Make sure you enter the code correctly, or contact your educator.',
        description: 'Invalid class code modal description.',
    },
    cancelButtonLabel: {
        id:'cancelButtonLabel',
        defaultMessage: 'Cancel',
        description: 'Cancel button label.',
    },
    tryAgainButtonLabel: {
        id:'tryAgainButtonLabel',
        defaultMessage: 'Try again',
        description: 'Try again button label.',
    },
    nextButtonLabel: {
        id:'nextButtonLabel',
        defaultMessage: 'Next',
        description: 'Next button label.',
    },
    finishButtonLabel: {
        id:'finishButtonLabel',
        defaultMessage: 'Finish',
        description: 'Finish onboarding button label.',
    },
    timeToExploreTitle: {
        id:'timeToExploreTitle',
        defaultMessage: 'Time to explore',
        description: 'Title for modal that redirect user to explore page.',
    },
    timeToExploreDescription: {
        id:'timeToExploreDescription',
        defaultMessage: 'You need to be 13 or older to create an account. But don\'t worry ! You can still explore all the content in the LabXchange library.',
        description: 'Text that explain user below 13 years old can only see explore page.',
    },
    embargoCantRegisterTitle: {
        id:'embargoCantRegisterTitle',
        defaultMessage: 'You can\'t register for an account',
        description: 'Title for dialog message that explains user cannot create account from embargoed region',
    },
    embargoCantRegisterDescription: {
        id:'embargoCantRegisterDescription',
        defaultMessage: 'Our system indicates that you are trying to register for an account from a country or region in which it is not currently available.',
        description: 'Text that explains user cannot create account from embargoed region',
    },
    sdnCantRegisterTitle: {
        id:'sdnCantRegisterTitle',
        defaultMessage: 'You can\'t register for an account',
        description: 'Title for dialog message that explains user cannot create account because of a SDN check hit',
    },
    sdnCantRegisterDescription: {
        id:'sdnCantRegisterDescription',
        defaultMessage: 'Unfortunately, your account profile appears to match one or more records on a U.S. Treasury Department sanctions list. This means we cannot provide you with services and must suspend your learner account. If you have questions regarding clearing a match, please contact {mailtoLink} for information about options for clearing a match. Your account will be suspended until this matter is resolved satisfactorily.',
        description: 'Text that explains user cannot create account because of a SDN check hit',
    },
    checkItOutButtonLabel: {
        id:'checkItOutButtonLabel',
        defaultMessage: 'Check it out',
        description: 'Label for check it out button that send user to explore page.',
    },
    errorMessageUnableToUpdateUserProfile: {
        id:'errorMessageUnableToUpdateUserProfile',
        defaultMessage: 'Unable to update your profile.',
        description: 'Error message when an error with backend happen while updating user profile.',
    },
    errorMessageUnableToGetUserProfile: {
        id:'errorMessageUnableToGetUserProfile',
        defaultMessage: 'Unable to get your profile.',
        description: 'Error message when an error with backend happen while getting user profile.',
    },
    errorMessageUnableToJoinClassroom: {
        id:'errorMessageUnableToJoinClassroom',
        defaultMessage: 'Class code is not working.',
        description: 'Error message when class code is not working while trying to join classroom during onboarding.',
    },
    defaultSelectOption: {
        id:'defaultSelectOption',
        defaultMessage: 'Select',
        description: 'Default select option.',
    },
    highestQualificationLabel: {
        id:'highestQualificationLabel',
        defaultMessage: 'Highest level of education completed',
        description: 'Highest qualification select label for user registration form.',
    },
    highestQualificationDoctorateOption: {
        id:'highestQualificationDoctorateOption',
        defaultMessage: 'Doctorate',
        description: 'Doctorate highest qualification select option.',
    },
    highestQualificationMasterOption: {
        id:'highestQualificationMasterOption',
        defaultMessage: 'Master’s or professional degree',
        description: 'Master highest qualification select option.',
    },
    highestQualificationBachelorOption: {
        id:'highestQualificationBachelorOption',
        defaultMessage: 'Bachelor’s degree',
        description: 'Bachelor highest qualification select option.',
    },
    highestQualificationAssociateOption: {
        id:'highestQualificationAssociateOption',
        defaultMessage: 'Associate degree',
        description: 'Associate highest qualification select option.',
    },
    highestQualificationSecondaryOption: {
        id:'highestQualificationSecondaryOption',
        defaultMessage: 'Secondary/high school',
        description: 'Secondary/high school highest qualification select option.',
    },
    highestQualificationJuniorSecondaryOption: {
        id:'highestQualificationJuniorSecondaryOption',
        defaultMessage: 'Junior secondary/junior high/middle school',
        description: 'Junior secondary/junior high/middle school highest qualification select option.',
    },
    highestQualificationElementaryOption: {
        id:'highestQualificationElementaryOption',
        defaultMessage: 'Elementary/primary school',
        description: 'Elementary/primary school highest qualification select option.',
    },
    highestQualificationNoneOption: {
        id:'highestQualificationNoneOption',
        defaultMessage: 'No formal education',
        description: 'No formal education qualification select option.',
    },
    highestQualificationOtherOption: {
        id:'highestQualificationOtherOption',
        defaultMessage: 'Other education',
        description: 'Other education qualification select option.',
    },
    uploadImageText: {
        id:'uploadImageText',
        defaultMessage: 'Upload an image',
        description: 'Text for uploading image option',
    },
    changeImageText: {
        id:'changeImageText',
        defaultMessage: 'Change image',
        description: 'Text for changing image option',
    },
    removeImageText: {
        id:'removeImageText',
        defaultMessage: 'Remove',
        description: 'Text for removing image option',
    },
    updateProfileButtonLabel: {
        id:'updateProfileButtonLabel',
        defaultMessage: 'Update profile',
        description: 'Text for update profile button',
    },
});

export default messages;
