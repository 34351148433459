// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserFavorite
 */
export interface UserFavorite {
    /**
     * 
     * @type {string}
     * @memberof UserFavorite
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFavorite
     */
    item: string;
    /**
     * 
     * @type {Date}
     * @memberof UserFavorite
     */
    readonly created?: Date;
}

export function UserFavoriteFromJSON(json: any): UserFavorite {
    return {
        'user': !exists(json, 'user') ? undefined : json['user'],
        'item': json['item'],
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
    };
}

export function UserFavoriteToJSON(value?: UserFavorite): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'item': value.item,
    };
}


