// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ItemUserAttributes
 */
export interface ItemUserAttributes {
    /**
     * 
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    isFavorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    canEditObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    showPublicToggle: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    isItemOwner: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    canViewAssetDiscussion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    canSendPii?: boolean;
    /**
     * Check if user has submitted feedback for the asset
     * @type {boolean}
     * @memberof ItemUserAttributes
     */
    readonly hasUserSubmittedFeedback?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ItemUserAttributes
     */
    weightedEarned?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemUserAttributes
     */
    weightedPossible?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemUserAttributes
     */
    completion?: number;
    /**
     * 
     * @type {Date}
     * @memberof ItemUserAttributes
     */
    completionDate?: Date;
}

export function ItemUserAttributesFromJSON(json: any): ItemUserAttributes {
    return {
        'isFavorite': json['is_favorite'],
        'canEditObject': json['can_edit_object'],
        'showPublicToggle': json['show_public_toggle'],
        'isItemOwner': json['is_item_owner'],
        'canViewAssetDiscussion': !exists(json, 'can_view_asset_discussion') ? undefined : json['can_view_asset_discussion'],
        'canSendPii': !exists(json, 'can_send_pii') ? undefined : json['can_send_pii'],
        'hasUserSubmittedFeedback': !exists(json, 'has_user_submitted_feedback') ? undefined : json['has_user_submitted_feedback'],
        'weightedEarned': !exists(json, 'weighted_earned') ? undefined : json['weighted_earned'],
        'weightedPossible': !exists(json, 'weighted_possible') ? undefined : json['weighted_possible'],
        'completion': !exists(json, 'completion') ? undefined : json['completion'],
        'completionDate': !exists(json, 'completion_date') ? undefined : new Date(json['completion_date']),
    };
}

export function ItemUserAttributesToJSON(value?: ItemUserAttributes): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'is_favorite': value.isFavorite,
        'can_edit_object': value.canEditObject,
        'show_public_toggle': value.showPublicToggle,
        'is_item_owner': value.isItemOwner,
        'can_view_asset_discussion': value.canViewAssetDiscussion,
        'can_send_pii': value.canSendPii,
        'weighted_earned': value.weightedEarned,
        'weighted_possible': value.weightedPossible,
        'completion': value.completion,
        'completion_date': value.completionDate === undefined ? undefined : value.completionDate.toISOString(),
    };
}


