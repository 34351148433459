import update from 'immutability-helper';

import { ActionTypes, Types } from './actions';

export interface UserState {
    ownAvatarLastChangeTime: number;
}

export function userReducer(
    state: Readonly<UserState> = {ownAvatarLastChangeTime: 0},
    action: ActionTypes,
): Readonly<UserState> {
    switch (action.type) {
        case Types.RELOAD_OWN_AVATAR_IMAGE:
            return update(state, {ownAvatarLastChangeTime: {$set: new Date().getTime()}});
        default:
            return state;
    }
}
