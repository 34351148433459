// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CurriculumTaggingDataItem,
    CurriculumTaggingDataItemFromJSON,
    CurriculumTaggingDataItemToJSON,
    ModuleHierarchy,
    ModuleHierarchyFromJSON,
    ModuleHierarchyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CurriculumTaggingData
 */
export interface CurriculumTaggingData {
    /**
     * 
     * @type {Array<CurriculumTaggingDataItem>}
     * @memberof CurriculumTaggingData
     */
    items: Array<CurriculumTaggingDataItem>;
    /**
     * 
     * @type {Array<ModuleHierarchy>}
     * @memberof CurriculumTaggingData
     */
    modules: Array<ModuleHierarchy>;
}

export function CurriculumTaggingDataFromJSON(json: any): CurriculumTaggingData {
    return {
        'items': (json['items'] as Array<any>).map(CurriculumTaggingDataItemFromJSON),
        'modules': (json['modules'] as Array<any>).map(ModuleHierarchyFromJSON),
    };
}

export function CurriculumTaggingDataToJSON(value?: CurriculumTaggingData): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'items': (value.items as Array<any>).map(CurriculumTaggingDataItemToJSON),
        'modules': (value.modules as Array<any>).map(ModuleHierarchyToJSON),
    };
}


