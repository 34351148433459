import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface TopicPageHeaderProps {
  topicTitle: string;
  loading: boolean;
}

export const TopicPageHeader: React.FC<TopicPageHeaderProps> = (props) => {
  return (
    <header className='topic-header'>
        <div className='topic-title-wrapper container'>
          {props.loading ? <Skeleton width={280} height={60} /> :
            <h2 className='topic-title'>
              {props.topicTitle}
            </h2>
          }
        </div>
    </header>
  );
};

export default TopicPageHeader;
