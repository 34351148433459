import * as React from 'react';
import {
    OrganizationSummaryOrganizationTypeEnum as OrganizationTypeEnum, OrganizationSummary
} from 'labxchange-client';
import { NavLink } from 'react-router-dom';

import {ROUTES} from 'global/constants';


interface Props {
    sources?: OrganizationSummary[];
}

export class ClusterSources extends React.PureComponent<Props> {
    public render() {
        const { sources } = this.props;
        if (!sources) {
            return null;
        }
        const sourcesElements = sources.map((sourceOrg: OrganizationSummary) => {

            const imageComponent = (
                <img alt={`${sourceOrg.name} logo`} src={sourceOrg.largeLogoUrl} />
            );

            if (sourceOrg.organizationType === OrganizationTypeEnum.SPONSOR ||
                sourceOrg.organizationType === OrganizationTypeEnum.PARTNER
            ) {
                return (
                    <NavLink
                        key={`source-${sourceOrg.slug}`}
                        to={ROUTES.Organizations.PROFILE_SLUG(sourceOrg.slug)}
                        className='source-image'
                    >
                        {imageComponent}
                    </NavLink>
                );
            }

            return (
                <a
                    key={`source-${sourceOrg.slug}`}
                    href={sourceOrg.url ? sourceOrg.url : '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='source-image'
                >
                    {imageComponent}
                </a>
            );
        });

        return (
            <div className='row cluster-sources'>
                {sourcesElements}
            </div>
        );
    }
}
