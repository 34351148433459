import {
    areAssessmentEditorRequiredFieldsComplete,
} from 'assessment-editor';
import { LocationDescriptor } from 'history';
import { ItemType } from 'items/models';
import { AnnotatedVideoAnnotation, Author, ItemMetadata, ItemResponse } from 'labxchange-client';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';
import messages from './displayMessages';

/**
 * Defines the various XBlock types that can be edited.
 * The enum value must be the LMS's xblock type ID.
 *
 * "Other" is for any type that we do not (yet) support editing.
 */
export enum XBlockType {
    AnnotatedVideo = 'lx_annotated_video',
    Assignment = 'lx_assignment',
    Audio = 'lx_audio',
    CaseStudy = 'lx_case_study',
    Document = 'lx_document',
    DragAndDrop = 'drag-and-drop-v2',
    Html = 'html',
    Image = 'lx_image',
    Interactive = 'lx_simulation',
    Narrative = 'lx_narrative',
    LXVideo = 'lx_video',
    Problem = 'problem',
    Simulation = 'lx_simulation',
    TeachingGuide = 'lx_teaching_guide',
    Video = 'video',
    Question = 'lx_question',
    Link = 'link',
    Pathway = 'pathway',
    Cluster = 'cluster',
    Other = 'other',
}

/** The data required to hold the state when editing an assignment XBlock */
export interface AssignmentEditorState {
    blockType: XBlockType.Assignment;
    assessments: {
        // ID of this item. Once the assignment has been saved, these IDs will change to be specific to this assignment.
        id: string,
        metadata: ItemMetadata,
        graded: boolean,
        max_attempts: number|null,
        weight: number|null,
    }[];
}

/** The data required to hold the state when editing an image XBlock */
export interface ImageEditorState {
    blockType: XBlockType.Image;
    imageFile?: File;
    imageUrl: string;
    // The original image URL, when we started editing. We need this to know
    // if the image has been changed. (Sometimes the "image_url" field on the
    // server has a value of "/static/img.png" but gets sent to us as
    // "https://cdn.example.com/assets/block_id/img.png", and we want to avoid
    // overwriting the "image_url" field with that expanded URL - it should
    // stay as "/static/img.png". To do that without making things complex, we
    // just use this additional field to know when the URL has been changed.)
    originalImageUrl: string;
    altText: string;
    captionText: string;
    citationText: string;
    extendedDescText: string;
}

/** The data required to hold the state when editing a text XBlock */
export interface TextEditorState {
    blockType: XBlockType.Html;
    data: string;
}

export interface AssessmentEditorState {
    blockType: XBlockType.Problem;
    olx: string;
    isEditable: boolean; // Does the visual assessment editor support this problem?
}

export interface AudioTranscript {
    type: 'inlinehtml';
    content: string;
}

export interface AudioTranscripts {
    [language: string]: AudioTranscript;
}

export interface AudioEditorState {
    blockType: XBlockType.Audio;
    embedCode: string;
    audioTranscripts: AudioTranscripts;
}

export interface QuestionEditorState {
    blockType: XBlockType.Question;
    olx: string;
    isEditable: boolean;
}

export interface CaseStudyInlineTextAsset {
    type: 'inline';
    inlinehtml: string;
}

export interface CaseStudyBlockAsset {
    type: 'block';
    item: ItemResponse;
    embed: boolean;
}

export type CaseStudyAsset = CaseStudyInlineTextAsset|CaseStudyBlockAsset;

export interface CaseStudySection {
    title: string;
    children: CaseStudyAsset[];

    expanded: boolean;
}

export interface CaseStudyEditorState {
    blockType: XBlockType.CaseStudy;
    sections: CaseStudySection[];
    attachments: ItemResponse[];
}

export interface TeachingGuideEditorState {
    blockType: XBlockType.TeachingGuide;
    sections: CaseStudySection[];
    attachments: ItemResponse[];
}

/** The data required to hold the state when editing a narrative XBlock */
export interface NarrativeEditorState {
    blockType: XBlockType.Narrative;
    narrative: string;
    keyPoints: string;
}

export interface DocumentEditorState {
    blockType: XBlockType.Document;
    documentFile?: File;
    documentUrl: string;
    originalDocumentUrl: string;
    citationText?: string;
}

export interface VideoEditorState {
    blockType: XBlockType.Video;
    youTubeId?: string;
    html5Sources: string[];
    transcripts: {
        [language: string]: File | string;
    };
    transcripts_urls: {
        [language: string]: string | undefined;
    };
}

export interface LXVideoEditorState {
    blockType: XBlockType.LXVideo;
    youTubeId?: string;
    html5Sources: string[];
    transcripts: {
        [language: string]: File | string;
    };
    transcripts_urls: {
        [language: string]: string | undefined;
    };
}

export interface AnnotatedVideoEditorState {
    blockType: XBlockType.AnnotatedVideo;
    youTubeId?: string;
    html5Sources: string[];
    transcripts: {
        [language: string]: File | string;
    };
    transcripts_urls: {
        [language: string]: string | undefined;
    };
    item?: ItemMetadata;
    annotations?: AnnotatedVideoAnnotation[];
    addedAnnotations?: AnnotatedVideoAnnotation[];
    updatedAnnotations?: AnnotatedVideoAnnotation[];
    deletedAnnotations?: AnnotatedVideoAnnotation[];
}

export interface LinkEditorState {
    blockType: XBlockType.Link;
    documentUrl: string;
}

/** OtherEditorState: represents an uneditable/unsupported XBlock */
export interface OtherEditorState {
    blockType: XBlockType.Other;
}

export type EditorState = Readonly<
    |AnnotatedVideoEditorState
    |AssessmentEditorState
    |AssignmentEditorState
    |AudioEditorState
    |CaseStudyEditorState
    |DocumentEditorState
    |ImageEditorState
    |LXVideoEditorState
    |NarrativeEditorState
    |TeachingGuideEditorState
    |TextEditorState
    |VideoEditorState
    |QuestionEditorState
    |LinkEditorState
    |OtherEditorState
>;

export interface NestedCreateOptions {
    sectionId?: number;
    embed?: boolean;
}

// The properties passed to the editor for a specific XBlock type, like Image
export interface SpecificEditorProps<StateSubclass> {
    xblockId?: string;
    /**
     * The state of the editor. This should be considered private/internal,
     * but the parent component should update this whenever the OnEditorStateChanged
     * event is called with a new state.
     * Leave this as undefined when first initializing this component.
     */
    editorState?: Readonly<StateSubclass>;
    /** An event emitted whenever the user has interacted with this XBlock Editor */
    onEditorStateChanged: (newState: EditorState) => void;
    showErrors: boolean;
    showWarnings: boolean;
    onNestedCreate?: (location: LocationDescriptor, options: NestedCreateOptions) => void;
    saveItem(callback?: (isSaving: boolean) => void, onRequiredFields?: (items: MessageDescriptor[]) => void): void;
    onForceSave(callback?: (item?: ItemMetadata) => void): void;
    onUpdateAuthors?(authors: Author[]): void;
    loggedInUsername?: string;
    itemMetadata?: ItemMetadata;
}

/**
 * Given an XBlock type, return the default editor state that represents a new/blank
 * instance of that XBlock.
 * @param blockType The XBlock type the user wants to create
 */
export function newXBlockEditorState(blockType: XBlockType, defaultValues: {}): EditorState {
    switch (blockType) {
        case XBlockType.AnnotatedVideo:
            return {
                blockType: XBlockType.AnnotatedVideo,
                youTubeId: undefined,
                html5Sources: [],
                transcripts: {},
                transcripts_urls: {},
                item: undefined,
                annotations: [],
                ...defaultValues,
            };
        case XBlockType.Assignment:
            return {
                blockType: XBlockType.Assignment,
                assessments: [],
                ...defaultValues,
            };
        case XBlockType.Image:
            return {
                blockType: XBlockType.Image,
                imageFile: undefined,
                imageUrl: '',
                originalImageUrl: '',
                altText: '',
                citationText: '',
                captionText: '',
                extendedDescText: '',
                ...defaultValues,
            };
        case XBlockType.Html:
            return {
                blockType: XBlockType.Html,
                data: '',
                ...defaultValues,
            };
        case XBlockType.Document:
            return {
                blockType: XBlockType.Document,
                documentFile: undefined,
                documentUrl: '',
                originalDocumentUrl: '',
                citationText: '',
            };
        case XBlockType.Narrative:
            return {
                blockType: XBlockType.Narrative,
                narrative: intl.formatMessage(messages.narrativeDefaultContent),
                keyPoints: '',
                ...defaultValues,
            };
        case XBlockType.Problem:
            return {
                blockType: XBlockType.Problem,
                olx: '',
                isEditable: true,
                ...defaultValues,
            };
        case XBlockType.Video:
            return {
                blockType: XBlockType.Video,
                youTubeId: undefined,
                html5Sources: [],
                transcripts: {},
                transcripts_urls: {},
                ...defaultValues,
            };
        case XBlockType.LXVideo:
            return {
                blockType: XBlockType.LXVideo,
                youTubeId: undefined,
                html5Sources: [],
                transcripts: {},
                transcripts_urls: {},
                ...defaultValues,
            };
        case XBlockType.Audio:
            return {
                blockType: XBlockType.Audio,
                embedCode: '',
                audioTranscripts: {},
                ...defaultValues,
            };
        case XBlockType.CaseStudy:
            return {
                blockType: XBlockType.CaseStudy,
                sections: [
                    {title: intl.formatMessage(messages.caseStudySummaryTitle), expanded: true, children: []},
                    {title: intl.formatMessage(messages.caseStudyIntroTitle), expanded: false, children: []},
                    {title: intl.formatMessage(messages.caseStudyResultsTitle), expanded: false, children: []},
                    {title: intl.formatMessage(messages.caseStudyDiscussionTitle), expanded: false, children: []},
                    {title: intl.formatMessage(messages.caseStudyMaterialsTitle), expanded: false, children: []},
                    {title: intl.formatMessage(messages.caseStudyAcknowledgementsTitle), expanded: false, children: []},
                    {title: intl.formatMessage(messages.caseStudyReferencesTitle), expanded: false, children: []},
                    {title: intl.formatMessage(messages.caseStudyCitationTitle), expanded: false, children: []},
                ],
                attachments: [],
                ...defaultValues,
            };
        case XBlockType.TeachingGuide:
            return {
                blockType: XBlockType.TeachingGuide,
                sections: [
                    {title: '', expanded: true, children: []},
                ],
                attachments: [],
                ...defaultValues,
            };
        case XBlockType.Question:
            return {
                blockType: XBlockType.Question,
                olx: '',
                isEditable: true,
                ...defaultValues,
            };
        case XBlockType.Link:
            return {
                blockType: XBlockType.Link,
                documentUrl: ''
            };
        default:
            return {blockType: XBlockType.Other};
    }
}

/**
 * Given an Item Type (like "Assignment"), get the default underlying
 * XBlock type (like "unit")
 */
export function newXBlockTypeForItemType(itemType: ItemType) {
    switch (itemType) {
        case ItemType.AnnotatedVideo: return XBlockType.AnnotatedVideo;
        case ItemType.Assignment: return XBlockType.Assignment;
        case ItemType.Assessment: return XBlockType.Problem;
        case ItemType.Audio: return XBlockType.Audio;
        case ItemType.Document: return XBlockType.Document;
        case ItemType.Image: return XBlockType.Image;
        case ItemType.Text: return XBlockType.Html;
        case ItemType.Narrative: return XBlockType.Narrative;
        case ItemType.LxVideo: return XBlockType.LXVideo;
        case ItemType.Video: return XBlockType.Video;
        case ItemType.CaseStudy: return XBlockType.CaseStudy;
        case ItemType.TeachingGuide: return XBlockType.TeachingGuide;
        case ItemType.Question: return XBlockType.Question;
        case ItemType.Link: return XBlockType.Link;
        default: return XBlockType.Other;
    }
}

/**
 * Given an XBlock editor state, return TRUE if it's valid/complete and
 * ready to be saved, or false if the user has yet to fill in some
 * required fields.
 * @param editorState The XBlock editor state.
 * @param isPublic If some fields depends on if is public or not
 */
export function areRequiredFieldsComplete(editorState: EditorState, isPublic = false): boolean {
    switch (editorState.blockType) {
        case XBlockType.AnnotatedVideo:
            return Boolean(
                // A video ID & a transcription are required
                (editorState.youTubeId || editorState.html5Sources.length > 0)
                 && !isPublic || (Object.keys(editorState.transcripts).length > 0),
            );
        case XBlockType.Assignment:
            return Boolean(editorState.assessments.length >= 2);
        case XBlockType.Image:
            return Boolean(
                // alt text is required
                editorState.altText &&
                // An image is required
                (editorState.imageFile || editorState.imageUrl) &&
                // Citation is required
                editorState.citationText,
            );
        case XBlockType.Html:
            return Boolean(editorState.data);
        case XBlockType.Document:
            return Boolean(
                // A document is required
                (editorState.documentFile || editorState.documentUrl),
            );
        case XBlockType.Narrative:
            return Boolean(
                // All contents are required
                (editorState.narrative && editorState.keyPoints),
            );
        case XBlockType.Problem:
            // Note: this does not take editorState, because it pulls the
            // assessment editor state from redux.
            return areAssessmentEditorRequiredFieldsComplete();
        case XBlockType.Video || XBlockType.LXVideo:
            return Boolean(
                // A video ID & a transcription are required
                (editorState.youTubeId || editorState.html5Sources.length > 0)
                && !isPublic || (Object.keys(editorState.transcripts).length > 0),
            );
        case XBlockType.Audio:
            return Boolean(editorState.embedCode);
        case XBlockType.CaseStudy:
            return true;
        case XBlockType.TeachingGuide:
            return true;
        case XBlockType.Link:
            return editorState.documentUrl !== '';
        default:
            return true;
    }
}
