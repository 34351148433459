// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserMentorshipProfile
 */
export interface UserMentorshipProfile {
    /**
     * 
     * @type {boolean}
     * @memberof UserMentorshipProfile
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMentorshipProfile
     */
    message?: string;
}

export function UserMentorshipProfileFromJSON(json: any): UserMentorshipProfile {
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function UserMentorshipProfileToJSON(value?: UserMentorshipProfile): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled': value.enabled,
        'message': value.message,
    };
}


