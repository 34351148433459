// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserAward
 */
export interface UserAward {
    /**
     * 
     * @type {string}
     * @memberof UserAward
     */
    awardTitle: string;
    /**
     * 
     * @type {string}
     * @memberof UserAward
     */
    awardedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAward
     */
    awardYear?: number;
}

export function UserAwardFromJSON(json: any): UserAward {
    return {
        'awardTitle': json['award_title'],
        'awardedBy': !exists(json, 'awarded_by') ? undefined : json['awarded_by'],
        'awardYear': !exists(json, 'award_year') ? undefined : json['award_year'],
    };
}

export function UserAwardToJSON(value?: UserAward): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'award_title': value.awardTitle,
        'awarded_by': value.awardedBy,
        'award_year': value.awardYear,
    };
}


