import { checkLoginStatus } from 'auth/actions';
import { bind } from 'bind-decorator';
import classNames from 'classnames';
import { MentorshipsApi } from 'global/api';
import { getStore } from 'global/store';
import { MentorshipStatusEnum } from 'labxchange-client';
import * as React from 'react';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface Props {
    embed: any;
    type: string;
}

interface State {
    readOnly: boolean;
}

export class MentorshipEmbedCreated extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            readOnly: ![
                MentorshipStatusEnum.Pending,
                MentorshipStatusEnum.ReRequested,
            ].includes(this.props.embed.mentorship.status),
        };
    }

    public render() {
        const embed = this.props.embed;
        if (this.props.type === 'pending.mentee' || this.state.readOnly) {
            return (
                <div className='mentorship-embed-created'>
                    <h3><WrappedMessage message={messages.readOnlyTitle} /></h3>
                    <div>
                        <div className='label'>
                            <WrappedMessage message={messages.readOnlyNeedHelpLabel}
                                values={{ fullName: embed.mentorship.mentee.full_name }}
                            />
                        </div>
                        <div>{embed.mentorship.pending_subject}</div>
                    </div>
                    <div>
                        <div className='label'>
                            <WrappedMessage message={messages.readOnlyNeedMentorLabel}
                                values={{ fullName: embed.mentorship.mentee.full_name }}
                            />
                        </div>
                        <div>{embed.mentorship.pending_reason}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='mentorship-embed-created'>
                    <p>
                        <WrappedMessage
                            message={messages.announce}
                            values={{ fullName: embed.mentorship.mentee.full_name }} />
                    </p>
                    <div>
                        <div className='label'>
                            <WrappedMessage message={messages.readOnlyNeedHelpLabel}
                                values={{ fullName: embed.mentorship.mentee.full_name }}
                            />
                        </div>
                        <div>{embed.mentorship.pending_subject}</div>
                    </div>
                    <div>
                        <div className='label'>
                            <WrappedMessage message={messages.readOnlyNeedMentorLabel}
                                values={{ fullName: embed.mentorship.mentee.full_name }}
                            />
                        </div>
                        <div>{embed.mentorship.pending_reason}</div>
                    </div>
                    <br />
                    <p>
                        <WrappedMessage
                            message={messages.isAgree}
                            values={{ fullName: embed.mentorship.mentee.full_name }}
                        />
                    </p>
                    <p>
                        <button className={classNames('unstyled', 'false-link')} onClick={
                            (e) => {
                                this.onUpdateStatus(
                                    e, embed.mentorship.id, MentorshipStatusEnum.Accepted);
                            }}>
                            <WrappedMessage
                                message={messages.acceptLink}
                                values={{ fullName: embed.mentorship.mentee.full_name }}
                            />
                        </button>
                        <br />
                        <WrappedMessage message={messages.orConjonction} /><br />
                        <button className={classNames('unstyled', 'false-link')} onClick={
                            (e) => {
                                this.onUpdateStatus(
                                    e, embed.mentorship.id, MentorshipStatusEnum.Refused);
                            }}>
                            <WrappedMessage
                                message={messages.refuseLink}
                                values={{ fullName: embed.mentorship.mentee.full_name }}
                            />
                        </button>
                    </p>
                    <p>
                        <WrappedMessage
                            message={messages.letsChat}
                            values={{ fullName: embed.mentorship.mentee.full_name }}
                        />
                    </p>
                </div>
            );
        }
    }

    @bind private async onUpdateStatus(
        event: React.MouseEvent<HTMLButtonElement>, mentorshipId: string, status: string,
    ) {
        event.preventDefault();
        try {
            const mentorship = await MentorshipsApi.partialUpdate({
                id: mentorshipId, data: { status },
            });
            if (mentorship.status !== MentorshipStatusEnum.Pending) {
                this.setState({ readOnly: true });
                checkLoginStatus(getStore().dispatch);
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
    }
}
