import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { showErrorMessage, Icon } from 'ui/components';
import {
    StringResponse, ViewData, Hints, Submit, Feedback, noMoreSubmissions, SpecificFeedback,
} from './question-block-shared';
import { XBlocksApi } from 'global/api';
import { intl } from 'i18n';
import { RichText } from 'elements';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';

interface Props {
    itemId: string;
    data: ViewData<StringResponse>;
    onUpdateData: (data: ViewData<StringResponse>) => void;
}

interface State {
    submitting: boolean;
}

export class QuestionStringResponseBlock extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    private async onSubmit() {
        this.setState({ submitting: true });

        const response = this.props.data.questionData.studentAnswer.response ?? '';

        let data: ViewData<StringResponse>;
        try {
            const res = await XBlocksApi.submitQuestionAnswer({
                id: this.props.itemId,
                data: {
                    response,
                },
            });
            // parse because GenericObjectSerializer
            data = JSON.parse(res);
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorSubmittingAnswer} />,
                {exception: err}
            );
            this.setState({ submitting: false });
            return;
        }

        this.props.onUpdateData(data);
        this.setState({ submitting: false });
    }

    public render() {
        const data = this.props.data;
        return (
            <div className='question-content'>
                {this.renderQuestion()}
                <Feedback
                    max={data.maxAttempts}
                    used={data.studentAttempts}
                    correct={data.correct}
                    answer={data.questionData.answer}
                />
                <Hints hints={data.hints} />
                <Submit
                    hidden={noMoreSubmissions(data)}
                    disabled={this.state.submitting || !data.questionData.studentAnswer.response}
                    maxAttempts={data.maxAttempts}
                    usedAttempts={data.studentAttempts}
                    onSubmit={() => this.onSubmit()}
                />
            </div>
        );
    }

    private renderQuestion(): React.ReactNode {
        const data = this.props.data;

        let extraClass = '';
        let icon = null;
        if (data.correct === true) {
            extraClass = 'correct';
            icon = <Icon fill='white' zoom='24px' name='check' />;
        }
        else if (data.correct === false) {
            extraClass = 'incorrect';
            icon = <Icon fill='white' zoom='24px' name='x' />;
        }

        const id = `${this.props.itemId}-question-form`;
        return (
            <div className='question-question'>
                <label className='question-question' htmlFor={id}>
                    <span>
                        <RichText
                            innerHtml={data.questionData.question}
                            sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                            renderMath={true}
                            renderCode={true}
                        />
                    </span>
                </label>
                <div className='string-input-group' dir='auto'>
                    <input
                        id={id}
                        dir='auto'
                        className={`string-input ${extraClass}`}
                        type='text'
                        disabled={noMoreSubmissions(data)}
                        value={data.questionData.studentAnswer.response ?? ''}
                        onChange={(e) => this.onChange(e.target.value)}
                        placeholder={intl.formatMessage(messages.stringAnswerPlaceholder)}
                    />
                    <div className={`input-icon ${extraClass}`}>{icon}</div>
                </div>
                <SpecificFeedback correct={data.correct} comment={data.questionData.comment} />
            </div>
        );
    }

    private onChange(response: string) {
        this.props.onUpdateData({
            ...this.props.data,
            questionData: {
                ...this.props.data.questionData,
                studentAnswer: { response },
            },
        });
    }
}
