// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentSource,
    ContentSourceFromJSON,
    ContentSourceToJSON,
    Organization,
    OrganizationFromJSON,
    OrganizationToJSON,
    OrganizationMembership,
    OrganizationMembershipFromJSON,
    OrganizationMembershipToJSON,
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseToJSON,
} from '../models';

export interface ContentSourcesRequest {
    id: string;
}

export interface ReadRequest {
    id: string;
}

export interface RetrieveByIdRequest {
    id: string;
}

export interface RetrievePartnerRequest {
    id: string;
}

/**
 * no description
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     * Get the content sources linked to this organization. Guaranteed to return at least one content source, if the organization is valid.
     */
    async contentSourcesRaw(requestParameters: ContentSourcesRequest): Promise<runtime.ApiResponse<Array<ContentSource>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling contentSources.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organizations/{id}/content_sources`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContentSourceFromJSON));
    }

    /**
     * Get the content sources linked to this organization. Guaranteed to return at least one content source, if the organization is valid.
     */
    async contentSources(requestParameters: ContentSourcesRequest): Promise<Array<ContentSource>> {
        const response = await this.contentSourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     * List partners
     */
    async listPartnersRaw(): Promise<runtime.ApiResponse<Array<Organization>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organizations/list_partners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationFromJSON));
    }

    /**
     * List partners
     */
    async listPartners(): Promise<Array<Organization>> {
        const response = await this.listPartnersRaw();
        return await response.value();
    }

    /**
     * List the organizations that the current user is a member of
     */
    async organizationMembershipsListRaw(): Promise<runtime.ApiResponse<Array<OrganizationMembership>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organizations/organization-memberships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationMembershipFromJSON));
    }

    /**
     * List the organizations that the current user is a member of
     */
    async organizationMembershipsList(): Promise<Array<OrganizationMembership>> {
        const response = await this.organizationMembershipsListRaw();
        return await response.value();
    }

    /**
     * Retrieve a single organization by slug
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a single organization by slug
     */
    async read(requestParameters: ReadRequest): Promise<OrganizationResponse> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a single organization by id
     */
    async retrieveByIdRaw(requestParameters: RetrieveByIdRequest): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organizations/{id}/retrieve_by_id`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a single organization by id
     */
    async retrieveById(requestParameters: RetrieveByIdRequest): Promise<OrganizationResponse> {
        const response = await this.retrieveByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a partner-type organization (only partner organizations have profile pages on LabXchange)
     */
    async retrievePartnerRaw(requestParameters: RetrievePartnerRequest): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrievePartner.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organizations/{id}/retrieve_partner`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a partner-type organization (only partner organizations have profile pages on LabXchange)
     */
    async retrievePartner(requestParameters: RetrievePartnerRequest): Promise<OrganizationResponse> {
        const response = await this.retrievePartnerRaw(requestParameters);
        return await response.value();
    }

}
