import { bind } from 'bind-decorator';
import * as React from 'react';

import messages from './displayMessages';

import iconSuccess from 'assets/images/icon-success.svg';
import { Button } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';

import { ClassroomsApi } from 'global/api';
import {
    ClassroomMembership,
    ClassroomMembershipStatusEnum,
    ClassroomMembershipUpdateFromJSON,
} from 'labxchange-client';

interface State {
    displayedMembershipStatus: ClassroomMembershipStatusEnum;
}

interface Props {
    membership: ClassroomMembership;
    onComplete: () => void;
}

/**
 * On the educator's "Learners" tab of any class, for any enrollment requests listed
 * at the top of the learners list, this panel is at the bottom of each card and allows
 * the educator to accept or reject the request, along with undo functionality.
 */
export class ClassroomMembershipRequestPrompt extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        if (props.membership.status) {
            this.state = {
                displayedMembershipStatus: props.membership.status,
            };
        }
    }

    public renderContent() {
        const fullName = this.props.membership.user ? this.props.membership.user.fullName : '';

        switch (this.state.displayedMembershipStatus) {
            case ClassroomMembershipStatusEnum.Joined:
                return (
                    <div className='content-container approved'>
                        <img src={iconSuccess} className='request-status-icon' alt='' />
                        <WrappedMessage
                            message={messages.classroomMembershipRequestAcceptedText}
                            values={{ fullName }}
                        />&nbsp;
                        <Button
                            btnStyle='link'
                            size='sm'
                            onClick={() => this.props.onComplete()}
                            label={messages.classroomMembershipRequestButtonCancel}
                        />
                    </div>
                );
            case ClassroomMembershipStatusEnum.Rejected:
                return (
                    <div className='content-container rejected'>
                        <img src={iconSuccess} className='request-status-icon' alt='' />
                        <WrappedMessage
                            message={messages.classroomMembershipRequestRejectedText}
                            values={{ fullName }}
                        />&nbsp;
                        <Button
                            btnStyle='link'
                            size='sm'
                            onClick={() => this.updateStatus(ClassroomMembershipStatusEnum.Requested)}
                            label={messages.classroomMembershipRequestButtonUndo}
                        />
                    </div>
                );
            case ClassroomMembershipStatusEnum.Requested:
                // The normal case: a learner has requested to join this class, and we display
                // Yes and No buttons to accept or reject the request.
                return (
                    <div className='content-container requested'>
                        <div className='prompt-text'>
                            <WrappedMessage
                                message={messages.classroomMembershipRequestRequestedText}
                                values={{ fullName }}
                            />
                        </div>
                        <Button
                            btnStyle='outline'
                            onClick={() => { this.updateStatus(ClassroomMembershipStatusEnum.Rejected); }}
                            data-testid={`membership-reject-${fullName}`}
                            label={messages.classroomMembershipRequestButtonNo}
                        />
                        <Button
                            btnStyle='primary'
                            onClick={() => { this.updateStatus(ClassroomMembershipStatusEnum.Joined); }}
                            data-testid={`membership-accept-${fullName}`}
                            label={messages.classroomMembershipRequestButtonYes}
                        />
                    </div>
                );
                default: return null;
        }
    }

    public render() {
        return (
            <div className='classroom-membership-request-prompt'>
                {this.renderContent()}
            </div>
        );
    }

    @bind private async updateStatus(status: ClassroomMembershipStatusEnum) {
        this.setState({ displayedMembershipStatus: status });
        try {
            const data = ClassroomMembershipUpdateFromJSON({ status });
            await ClassroomsApi.classroomMembershipsPartialUpdate({
                id: this.props.membership.id!, data,
            });
        } catch (err) {
            this.setState({ displayedMembershipStatus: ClassroomMembershipStatusEnum.Requested});
            showErrorMessage(<WrappedMessage message={messages.errorUpdatingClassroomMembershipRequest}/>, {
                exception: err,
                details: 'Unable to update ClassroomMembership status.',
            });
        }
    }
}
