import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { assessmentEditorStateReducer } from 'assessment-editor/store/reducers';
import { bannerReducer } from 'banners/reducers';
import { loginStateReducer } from 'auth/reducers';
import { classroomsReducer } from 'classrooms/reducers';
import { libraryReducer } from 'library/reducers';
import { notificationReducer } from 'notifications/reducers';
import { globalMessagesReducer } from 'ui/components/GlobalMessageReporter/reducer';
import { uiStateReducer } from 'ui/reducers';
import { userReducer } from 'user/reducers';
import { RootState } from './state';
import { globalLoginModalReducer } from 'auth/components/LxLoginView/reducers';

export type { RootState };

export const createRootReducer = (history: History) => combineReducers({
    assessmentEditorState: assessmentEditorStateReducer,
    bannerState: bannerReducer,
    classrooms: classroomsReducer,
    globalMessages: globalMessagesReducer,
    libraryState: libraryReducer,
    loginState: loginStateReducer,
    notificationState: notificationReducer,
    router: connectRouter(history),
    ui: uiStateReducer,
    user: userReducer,
    loginModalState: globalLoginModalReducer,
});
