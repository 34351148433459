import { Modal } from 'elements/components/Modals';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import elementsMessages from 'elements/displayMessages';
import { LoginPageContent } from './LoginPageContent';

export interface LoginModalProps {
    children: React.ReactElement;
    title: MessageDescriptor;
    subtitle: MessageDescriptor;
    ariaLabel: MessageDescriptor;
    showSignUpButton?: boolean;
    showBackButton?: boolean;
    termsAndPolicyMessage: MessageDescriptor;
    onSignUpButtonClick?: () => void;
    onRequestClose: () => void;
    onRequestBack?: () => void;
    onClickTermsOfService?: () => void;
    onClickPrivacyPolicy?: () => void;
}

export const LoginModalComponent: React.FunctionComponent<LoginModalProps> = (props) => {
    return (
        <Modal
            modalExtraClassName='login-modal'
            size='normal'
            topBarStyle='white'
            showTopBar={true}
            showBackButton={props.showBackButton}
            backButtonText={elementsMessages.elementsModalBackText}
            contentScrolls={false}
            contentPadding={0}
            onRequestClose={props.onRequestClose}
            onRequestBack={props.onRequestBack}
            content={
                <LoginPageContent
                    title={props.title}
                    subtitle={props.subtitle}
                    termsAndPolicyMessage={props.termsAndPolicyMessage}
                    children={props.children}
                    onSignUpButtonClick={props.onSignUpButtonClick}
                />
            }
            ariaLabel={props.ariaLabel}
        />
    );
};
