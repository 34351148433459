import React, { useState } from 'react';
import { Icon } from 'elements';
import { Button } from '../Buttons';
import { MessageDescriptor } from 'react-intl';
import { AutoCompleteTextInput, ChoiceOption, TextInput } from '../TextInput';
import { WrappedMessage } from 'utils';
import { CalendarDate } from '../DateInput';
import messages  from './displayMessages';
import './styles.scss';
import { localeInfo } from 'i18n';
import { ProfileRoleEnum } from 'labxchange-client/models/Profile';
import { countries } from 'typed-countries';
import { countryFilterName } from 'search/utils';

// This will be used to format the output from CalendarDate,
// so much specify the UTC timezone.
const dateFormatter = new Intl.DateTimeFormat(localeInfo.key, {month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC'});

// Remapping country names to a list of ChoiceOptions to fit
// in out autocomplete field. Can be moved to a utils file
// if it becomes useful elsewhere.
const countryChoices: ChoiceOption[] = countries.map((country) => {
    return {
        value: country.iso,
        displayName: country.name,
    };
});


interface FieldDisplayViewProps {
    label: MessageDescriptor;
    note?: MessageDescriptor;
    editable: boolean;
    value?: string;
    testId?: string;
    onClick: () => void;
}

const FieldDisplayView = (props: FieldDisplayViewProps) => {
    // Display disabled version of field
    if (props.editable) {
        return (
            <div className='text-field-form-container'>
                <div className='text-field-form'>
                    <small>
                        <WrappedMessage message={props.label} />
                    </small>
                    <div className='value-display' data-testid={props.testId ?? ''}>
                        <div className={props.editable ? 'value-disabled' : ''}>
                            {props.value || '--'}
                        </div>
                    </div>
                </div>
                {props.note &&
                    <small className='text-field-note'>
                        <WrappedMessage message={props.note} />
                    </small>
                }
            </div>
        );
    }

    // Display editable version of field
    return (
        <div className='text-field-form-container'>
            <div className='text-field-form'>
                <button className='unstyled lx-field-button' onClick={props.onClick}>
                    <small>
                        <WrappedMessage message={props.label} />
                    </small>
                    <div className='value-display' data-testid={props.testId ?? ''}>
                        <div className={props.editable ? 'value-disabled' : ''}>
                            {props.value || '--'}
                        </div>
                        <Icon className='icon' name='pencil' zoom='16'/>
                    </div>
                </button>
            </div>
            {props.note &&
                <small className='text-field-note'>
                    <WrappedMessage message={props.note} />
                </small>
            }
        </div>
    );
};

interface FieldEditViewProps {
    children: React.ReactNode;
    disableSave: boolean;
    onClose: () => void;
    onSave: () => void;
    fullWidth?: boolean;
}

const FieldEditView = (props: FieldEditViewProps) => {
    return (
        <div className='text-field-form-container'>
            <div className='text-field-form'>
                <div className='value-edit'>
                    <div className={props.fullWidth ? 'input-container full-width' : 'input-container'}>
                        {props.children}
                    </div>
                    <Button
                        label={messages.buttonSave}
                        btnStyle='primary'
                        disabled={props.disableSave}
                        onClick={props.onSave}
                    />
                    <Button
                        label={messages.buttonCancel}
                        btnStyle='link'
                        onClick={props.onClose}
                    />
                </div>
            </div>
        </div>
    );
};


interface TextFieldEditFormProps {
    label: MessageDescriptor;
    disabled: boolean;
    value?: string;
    fullWidth?: boolean;
    testId?: string;
    onSave?: (value: string) => void;
}


export const TextFieldEditForm = (props: TextFieldEditFormProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState('');

    const openEditMode = () => {
        // If value from props comes empty, set it as empty string
        setValue(props.value || '');
        setIsEditing(true);
    };

    // Show editing fields and Save/Cancel buttons
    if (isEditing) {
        return (
            <FieldEditView
                disableSave={false}
                onClose={() => setIsEditing(false)}
                onSave={() => {
                    setIsEditing(false);
                    if (props.onSave) {
                        props.onSave(value);
                    }
                }}
                fullWidth={props.fullWidth}
            >
                <TextInput
                    inputKey='input'
                    placeholder={props.label}
                    value={value}
                    onChangeValue={(newValue) => setValue(newValue)}
                    type='text'
                />
            </FieldEditView>
        );
    }

    return (
        <FieldDisplayView
            label={props.label}
            editable={props.disabled}
            value={props.value}
            onClick={openEditMode}
            testId={props.testId}
        />
    );
};

interface CountryFieldEditFormProps {
    label: MessageDescriptor;
    placeholder?: MessageDescriptor;
    bottomText?: MessageDescriptor;
    error?: MessageDescriptor;
    disabled: boolean;
    value?: string;
    onSave?: (value: string) => void;
}


export const CountryFieldEditForm = (props: CountryFieldEditFormProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [disableSave, setDisableSave] = useState(!!props.value);
    const [value, setValue] = useState<ChoiceOption|undefined>();

    let displayValue = '--';
    if (props.value) {
        displayValue = countryFilterName(props.value);
    }

    const openEditMode = () => {
        const countryObj = countryChoices.find((c: any) => c.value === props.value);
        setValue(countryObj);
        setIsEditing(true);
    };

    // Show editing fields and Save/Cancel buttons
    if (isEditing) {
        return (
            <FieldEditView
                disableSave={disableSave}
                onClose={() => setIsEditing(false)}
                onSave={() => {
                    setIsEditing(false);
                    if (props.onSave && value) {
                        props.onSave(value.value);
                    }
                }}
            >
                <AutoCompleteTextInput
                    key='input'
                    placeholder={props.label}
                    value={value}
                    onChangeValue={(newValue) => {
                        setValue(newValue);
                        setDisableSave(newValue === undefined);
                    }}
                    choices={countryChoices}
                />
            </FieldEditView>
        );
    }

    return (
        <FieldDisplayView
            label={props.label}
            editable={props.disabled}
            value={displayValue}
            onClick={openEditMode}
        />
    );
};

interface DateOfBirthFieldEditFormProps {
    label: MessageDescriptor;
    placeholder?: MessageDescriptor;
    bottomText?: MessageDescriptor;
    disabled: boolean;
    value?: CalendarDate;
    userRole?: ProfileRoleEnum;
    onSave?: (value: CalendarDate) => void;
}

export const DateOfBirthFieldEditForm = (props: DateOfBirthFieldEditFormProps) => {
    const role = props.userRole || ProfileRoleEnum.Educator;
    const [error, setError] = useState<MessageDescriptor|undefined>();
    const [disableSave, setDisableSave] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState('');

    let displayValue = '--';
    if (props.value) {
        displayValue = dateFormatter.format(props.value.toJsDate());
    }

    let ageNotice = messages.minimumAgeNotice;
    if (props.userRole === ProfileRoleEnum.Educator) {
        ageNotice = messages.minimumEducatorAgeNotice;
    }

    const setNewDate = (newValue: string) => {
        setValue(newValue);
        const newDate = new Date(newValue);
        // Only do error checking if there's a valid value.
        // Also, disable saving if year is too old.
        if (!isNaN(newDate.getTime()) && newDate.getFullYear() > 1900) {
            // Clear invalid date
            setDisableSave(false);

            // Calculate age
            const today = new Date();
            const age = today.getFullYear() - newDate.getFullYear();

            // Display error messages depending on date
            if ((age < 18 && role === ProfileRoleEnum.Educator) || age < 13) {
                setError(ageNotice);
            } else {
                setError(undefined);
            }
        } else {
            setDisableSave(true);
        }
    };

    const openEditMode = () => {
        // If value from props comes empty, set it as empty string
        if (props.value) {
            setValue(props.value.isoString);
        }
        setIsEditing(true);
    };

    // Show editing fields and Save/Cancel buttons
    if (isEditing) {
        return (
            <FieldEditView
                disableSave={disableSave || !!error}
                onClose={() => setIsEditing(false)}
                onSave={() => {
                    setIsEditing(false);
                    if (!error && props.onSave && value) {
                        props.onSave(CalendarDate.fromIsoString(value));
                    }
                }}
            >
                <TextInput
                    inputKey='date'
                    type='date'
                    placeholder={props.label}
                    value={value || ''}
                    onChangeValue={setNewDate}
                    invalid={!!error}
                />
                <small className={!!error ? 'error' : ''}>
                    <WrappedMessage message={error || ageNotice} />
                </small>
            </FieldEditView>
        );
    }

    return (
        <FieldDisplayView
            label={props.label}
            editable={props.disabled}
            value={displayValue}
            onClick={openEditMode}
            note={ageNotice}
        />
    );
};