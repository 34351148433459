import { defineMessages } from 'react-intl';

const messages = defineMessages({
    elementsModalCloseText: {
        id:'elementsModalCloseText',
        defaultMessage: 'Close',
        description: 'Text for the modal dialog close button',
    },
    elementsModalBackText: {
        id:'elementsModalBackText',
        defaultMessage: 'Back',
        description: 'Text for the modal dialog back button',
    },
    elementsModalBackSRText: {
        id:'elementsModalBackSRText',
        defaultMessage: 'Return back to other sign-up options',
        description: 'Text for screen readers for the back button',
    },
    topBarButtonText: {
        id:'topBarButtonText',
        defaultMessage: 'Back to Overview',
        description: 'Text for the back button',
    },
});

export default messages;
