// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface VideoTranscripts
 */
export interface VideoTranscripts {
    /**
     * 
     * @type {string}
     * @memberof VideoTranscripts
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof VideoTranscripts
     */
    transcript: string;
}

export function VideoTranscriptsFromJSON(json: any): VideoTranscripts {
    return {
        'languageCode': json['language_code'],
        'transcript': json['transcript'],
    };
}

export function VideoTranscriptsToJSON(value?: VideoTranscripts): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'language_code': value.languageCode,
        'transcript': value.transcript,
    };
}


