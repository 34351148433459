// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ItemCloneForTranslation
 */
export interface ItemCloneForTranslation {
    /**
     * 
     * @type {string}
     * @memberof ItemCloneForTranslation
     */
    language: string;
}

export function ItemCloneForTranslationFromJSON(json: any): ItemCloneForTranslation {
    return {
        'language': json['language'],
    };
}

export function ItemCloneForTranslationToJSON(value?: ItemCloneForTranslation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'language': value.language,
    };
}


