import * as React from 'react';
import { useState } from 'react';
import EditResourceModal from './EditResourceModal';
import classNames from 'classnames';
import { intl } from '../../../i18n';
import messages from '../../../library/displayMessages';
import { messages as resourceMessages } from './displayMessages';
import { Icon } from '../../../elements';
import { WrappedMessage, resourceTypes } from '../../../utils';
import { Link } from 'react-router-dom';
import { detailUrlForEntity } from '../../../library/utils';
import { ItemMetadata, ResourceRelationship } from '../../../labxchange-client';

interface ResourcesSectionProps {
    item: ItemMetadata;
    updateResources: (resources: ResourceRelationship[]) => void;
}

export const ResourcesSection: React.FC<ResourcesSectionProps> = ({item, updateResources}) => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [editItemIndex, setEditItemIndex] = useState<number>();
    const [resourceToEdit, setResourceToEdit] = useState<ResourceRelationship>();

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setResourceToEdit(undefined);
        setEditItemIndex(undefined);
    };

    const handleEditPopUp = (index: number) => {
        if(item.resources){
            setResourceToEdit(item.resources[index]);
            setEditItemIndex(index);
        }
        handleOpenPopup();
    };

    const handleDelete = (indexToDelete: number) => {
        const updatedItems = item.resources;
        if(updatedItems){
            updatedItems.splice(indexToDelete, 1);
            updateResources(updatedItems);
        }
    };

    return (
        <div className={classNames('extra-meta-wrapper', {'col-md-12 col-lg-10 offset-lg-1': item.type === 'pathway'})}>
            <div className='resources-container rounded mb-3'>
                <h2 className='resources-heading-section'>Resources</h2>
                <div className='resources-list-section'>
                    {item.resources?.map((resource, resourceIndex) => (
                        <div className='resource-item' key={resourceIndex}>
                            <div className='item-info-section'>
                                <span> {resource.title} </span> |
                                <span> {resourceTypes.find(type => type.value === resource.resourceCategory)?.name || ''}</span>
                            </div>
                            <div className='item-actions-section'>
                                {resource.targetItemType &&
                                  <Link
                                    className='unstyled tooltip-button'
                                    to={detailUrlForEntity({id: resource.targetItemId, type: resource.targetItemType})}
                                    aria-label={intl.formatMessage(messages.ariaViewButton)}
                                  >
                                    <Icon name='eye-navy' zoom='18'/>
                                  </Link>
                                }
                                <button
                                    className='unstyled tooltip-button'
                                    onClick={() => handleEditPopUp(resourceIndex)}
                                    aria-label={intl.formatMessage(messages.libraryCardMenuEditTitle)}
                                >
                                    <Icon name='pencil' zoom='18'/>
                                </button>
                                <button
                                    className='unstyled tooltip-button'
                                    onClick={()=>handleDelete(resourceIndex)}
                                    aria-label={intl.formatMessage(messages.actionDelete)}
                                >
                                    <Icon name='trashcan' zoom='18'/>
                                </button>
                            </div>
                        </div>
                    ))}

                    <button className='add-new-button' onClick={handleOpenPopup}>
                        <WrappedMessage message={resourceMessages.addNewActionLabel} />
                    </button>
                </div>
            </div>
            {isPopupOpen &&
              <EditResourceModal
                onClose={handleClosePopup}
                updateResources={updateResources}
                resourceToEdit={resourceToEdit}
                resourcesList={item.resources}
                editIndex={editItemIndex}
              />}
        </div>
    );
};
