// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionSubCategory,
    DiscussionSubCategoryFromJSON,
    DiscussionSubCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiscussionCategory
 */
export interface DiscussionCategory {
    /**
     * 
     * @type {string}
     * @memberof DiscussionCategory
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionCategory
     */
    name: string;
    /**
     * Icon to be displayed alongside the category in the discussions page.  Must be either SVG or PNG.
     * @type {string}
     * @memberof DiscussionCategory
     */
    readonly icon?: string;
    /**
     * Verbose description of the discussion category.
     * @type {string}
     * @memberof DiscussionCategory
     */
    description?: string;
    /**
     * 
     * @type {Array<DiscussionSubCategory>}
     * @memberof DiscussionCategory
     */
    subcategories: Array<DiscussionSubCategory>;
    /**
     * 
     * @type {number}
     * @memberof DiscussionCategory
     */
    threadCount: number;
    /**
     * 
     * @type {number}
     * @memberof DiscussionCategory
     */
    postCount: number;
    /**
     * 
     * @type {number}
     * @memberof DiscussionCategory
     */
    viewCount: number;
}

export function DiscussionCategoryFromJSON(json: any): DiscussionCategory {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'subcategories': (json['subcategories'] as Array<any>).map(DiscussionSubCategoryFromJSON),
        'threadCount': json['thread_count'],
        'postCount': json['post_count'],
        'viewCount': json['view_count'],
    };
}

export function DiscussionCategoryToJSON(value?: DiscussionCategory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'subcategories': (value.subcategories as Array<any>).map(DiscussionSubCategoryToJSON),
        'thread_count': value.threadCount,
        'post_count': value.postCount,
        'view_count': value.viewCount,
    };
}


