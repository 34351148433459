// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadPostCreate
 */
export interface ThreadPostCreate {
    /**
     * 
     * @type {string}
     * @memberof ThreadPostCreate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadPostCreate
     */
    thread: string;
    /**
     * 
     * @type {number}
     * @memberof ThreadPostCreate
     */
    replyTo?: number;
}

export function ThreadPostCreateFromJSON(json: any): ThreadPostCreate {
    return {
        'content': json['content'],
        'thread': json['thread'],
        'replyTo': !exists(json, 'reply_to') ? undefined : json['reply_to'],
    };
}

export function ThreadPostCreateToJSON(value?: ThreadPostCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
        'thread': value.thread,
        'reply_to': value.replyTo,
    };
}


