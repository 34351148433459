import bind from 'bind-decorator';
import * as React from 'react';

import { Icon } from 'elements/components/Icons';
import { SearchApi } from 'global/api';
import { Author, PeopleAppliedFilter, PeopleSearchRequestData } from 'labxchange-client';
import Autosuggest from 'react-autosuggest';
import { MessageDescriptor } from 'react-intl';
import { ItemSection } from 'ui/components/';
import { UserAvatar } from 'user/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';
import { InputText } from '@edx/paragon';

interface AuthorsFieldProps {
    onUpdate: (authors: Author[]) => void;
    authors: Author[];
    currentUser?: Author;
    isNew: boolean;
    titleMessage?:  MessageDescriptor;
    searchFilters?: PeopleAppliedFilter[];
    addAnotherLabel?: MessageDescriptor;
    avoidSuggestCurrentUser?: boolean;
    canEditOriginalAuthors?: boolean;
}

interface AuthorsFieldState {
    // ok to have one set of suggestions because only one input can be active
    suggestions: Author[];
    authorAdded: boolean;
}

export class AuthorsField extends React.PureComponent<
    AuthorsFieldProps, AuthorsFieldState
> {

    constructor(props: AuthorsFieldProps) {
        super(props);

        this.state = {
            suggestions: [],
            authorAdded: true,
        };
    }

    private static isEmptyAuthor(author: Author) {
        return author.fullName === '';
    }

    public render() {
        let authors = this.props.authors;
        if (authors.length === 0) {
            authors = authors.concat([AuthorsField.emptyAuthor()]);
        }

        if (
            this.props.authors.every(AuthorsField.isEmptyAuthor)
            && this.props.currentUser
            && this.props.isNew
            && this.state.authorAdded
        ) {
            this.addAuthor(Object.assign(this.props.currentUser), true);
            this.setState({
                authorAdded: false,
            });
        }

        const authorsElems = authors.map((author: Author, index: number) => {
            const inputProps = {
                key: index,
                type: 'text',
                id: `author-field-${index}`,
                placeholder: intl.formatMessage(messages.inputPlaceholder),
                // Fall back to username if there is a username and no full name is set.
                // Otherwise show the full name.
                value: (!author.fullName && author.username) ? author.username : author.fullName,
                onChange: this.onUpdateItem(index),
            };
            const isOriginalAuthor = author.originalAuthor === true && this.props.canEditOriginalAuthors !== true;
            return (
                <div className='row' key={index}>
                    <div className='left-box'>
                        <UserAvatar
                            username={author.username}
                            diameter='36px'
                        />
                    </div>
                    <div className='centre-box'>
                        <label htmlFor={isOriginalAuthor ? `original-author-${index}` : `author-field-${index}`}
                               className='sr-only'>
                            <WrappedMessage message={messages.authorFieldLabel}/>
                        </label>
                        {
                            isOriginalAuthor ?
                                <InputText
                                    name='original_author_text_input'
                                    value={inputProps.value}
                                    readOnly={true}
                                    className='original-author lx-input'
                                    id={`original-author-${index}`}
                                />
                                :
                                <Autosuggest
                                    suggestions={this.state.suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    onSuggestionSelected={this.onSuggestionSelected(index)}
                                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    renderInputComponent={this.renderInputComponent}
                                    inputProps={inputProps}
                                    id={`autosuggest-${index}`}
                                />
                        }
                    </div>
                    <div className='right-box'>
                        {
                             (!author.originalAuthor || this.props.canEditOriginalAuthors) &&
                             <button
                                onClick={this.onDeleteItem(index)}
                                className='btn btn-link'
                                title={intl.formatMessage(messages.deleteButtonLabel)}
                                type='button'
                            >
                                <Icon name='trashcan' />
                            </button>
                        }

                    </div>
                </div>
            );
        });

        return (
            <div className='item-authors-field'>
                <ItemSection
                    title={<WrappedMessage message={this.props.titleMessage? this.props.titleMessage : messages.sectionTitle} />}
                    sectionName='item-authors-field-section'
                >
                    <div className='item-authors-field-content'>
                        <div className='row'>
                            <div className='left-box'></div>
                            <div className='centre-box'>
                                <span className='subtitle'><WrappedMessage message={messages.subtitle} /></span>
                            </div>
                            <div className='right-box'></div>
                        </div>
                        {authorsElems}
                        <div className='row'>
                            <div className='left-box'>
                                {authors.length < 2 &&
                                    <UserAvatar diameter='36px'/>
                                }
                            </div>
                            <div className='centre-box'>
                                <button
                                    className='authors-add-button'
                                    onClick={this.onAddItem}
                                    type='button'
                                >
                                    <Icon name='plus-small' zoom='18' />
                                    <WrappedMessage message={this.props.addAnotherLabel? this.props.addAnotherLabel : messages.addAnother} />
                                </button>
                            </div>
                            <div className='right-box'></div>
                        </div>
                    </div>
                </ItemSection>
            </div>
        );
    }

    @bind private onDeleteItem(index: number) {
        return (() => {
            if (this.props.authors.length > 1) {
                this.onUpdate(this.props.authors.filter((_, i) => (i !== index)));
            } else {
                // we always want one in the list; this way we can still delete
                // the last one, but with the effect of clearing the field
                // without removing it.
                this.onUpdate([AuthorsField.emptyAuthor()]);
            }
        });
    }

    @bind private onUpdateItem(index: number) {
        return ((e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.target.value;
            let authors = this.props.authors;
            if (authors.length === 0) {
                authors = authors.concat([AuthorsField.emptyAuthor()]);
            }
            this.onUpdate(authors.map(
                (author, i) => {
                    if (i === index) {
                        return {
                            fullName: name,
                        };
                    } else {
                        return author;
                    }
                },
            ));
        });
    }

    @bind private onSuggestionSelected(index: number) {
        return ((event: React.FormEvent, params: Autosuggest.SuggestionSelectedEventData<Author>) => {
            let authors = this.props.authors;
            if (authors.length === 0) {
                authors = authors.concat([AuthorsField.emptyAuthor()]);
            }
            this.onUpdate(authors.map(
                (author, i) => {
                    if (i === index) {
                        return params.suggestion;
                    } else {
                        return author;
                    }
                },
            ));
        });
    }

    @bind private onUpdate(authors: Author[]) {
        this.props.onUpdate(authors);
    }

    @bind private onAddItem() {
        this.addAuthor(AuthorsField.emptyAuthor());
    }

    private addAuthor(author: Author, first= false) {
        if (first) {
            this.onUpdate([author].concat(this.props.authors));
        } else {
            this.onUpdate(this.props.authors.concat([author]));
        }
    }

    @bind private onSuggestionsClearRequested(): void {
        this.setState({
            suggestions: [],
        });
    }

    @bind private async onSuggestionsFetchRequested(params: Autosuggest.SuggestionsFetchRequestedParams) {
        this.setState({
            suggestions: await this.getSuggestions(params.value),
        });
    }

    private async getSuggestions(text: string): Promise<Author[]> {
        const searchRequest: PeopleSearchRequestData = {
            filters: this.props.searchFilters? this.props.searchFilters : [],
            wildcardNames: text.toLowerCase(),
        };
        const response = await SearchApi.peopleSearch({data: searchRequest});
        const existingUsernames = this.props.authors.filter((x) => x.username).map((x) => x.username!.toLowerCase());
        if (this.props.avoidSuggestCurrentUser && this.props.currentUser) {
            existingUsernames.push(this.props.currentUser.username ? this.props.currentUser.username : '');
        }

        // map to our Author type and filter out any users that have already
        // been entered in the form.
        return response.results.map((x) => ({
            username: x.username,
            fullName: x.fullName,
        })).filter((x) => !existingUsernames.includes(x.username.toLowerCase()));
    }

    @bind private renderInputComponent(inputProps: any): React.ReactNode {
        return (
            <>
                <input {...inputProps} />
                <Icon className='input-search-icon' name='search' />
            </>
        );
    }

    @bind private getSuggestionValue(author: Author): string {
        return author.fullName;
    }

    @bind private renderSuggestion(author: Author, params: Autosuggest.RenderSuggestionParams): React.ReactNode {
        const fullName = highlight(author.fullName, params.query);
        const username = author.username ?
            <span className='username'>@{highlight(author.username, params.query)}</span>
            : null;
        return (
            <span>
                <UserAvatar
                    username={author.username}
                    diameter='32px'
                />
                <span className='autosuggest-author-name'>
                    {fullName}
                    {this.props.currentUser?.username === author.username? <WrappedMessage message={messages.meText}/> : ' '}
                    {username}
                </span>
            </span>
        );
    }

    @bind private shouldRenderSuggestions(value: string): boolean {
        return value ? value.trim().length >= 2 : false;
    }

    private static emptyAuthor(): Author {
        return {
            fullName: '',
        };
    }

}

function highlight(text: string, query: string): React.ReactNode {
    const start = text.toLowerCase().indexOf(query.toLowerCase());
    const end = start + query.length;

    if (start === -1) {
        return <span>{text}</span>;
    }

    return (
        <>
            <span>{text.substring(0, start)}</span>
            <span className='highlight'>{text.substring(start, end)}</span>
            <span>{text.substring(end)}</span>
        </>
    );
}
