
import { intl } from 'i18n';
import * as React from 'react';
import messages from './displayMessages';
import { WrappedMessage, useLayoutSize } from 'utils';
import { Link } from 'react-router-dom';
import { ROUTES } from 'global/constants';
import Skeleton from 'react-loading-skeleton';

export const SourcesContainer: React.FC = () => {

    return (
        <>
        <div className='sources-graph'>
            <img
                alt={intl.formatMessage(messages.sourceImageAltText)}
                src='/assets/images/sources-graph.png'
            />
        </div>
        <div className='sources-description'>
            <h1><WrappedMessage message={messages.stemSourcesHeading} /></h1>
            <p><WrappedMessage message={messages.stemSourcesDescription} /></p>
            <Link className='btn-view-all' to={ROUTES.Organizations.HOME}>
                <span><WrappedMessage message={messages.textViewAllSources} /></span>
            </Link>
        </div>
        </>
    );
};

export const SourcesContainerSkeleton: React.FC = () => {
    const layoutSize = useLayoutSize();
    const isMobile = layoutSize === 'mobile' || layoutSize === 'small';

    return (
        <>
        <div className='sources-graph'>
            <Skeleton width={isMobile? 390 : 400} height={318} />
        </div>
        <div className='sources-description'>
            <Skeleton width={isMobile ? 380 : 530} height={84} />
            <Skeleton width={isMobile ? 380 : 530} height={62} />
            <Skeleton width={218} height={52} />
        </div>
        </>
    );
};
