import bind from 'bind-decorator';
import { Modal } from 'elements';
import { clipboardCopy } from 'global/utils';
import * as React from 'react';
import { ScreenReaderIntl, showSuccessMessage } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';

export interface ShareModalProps {
    onClose: () => void;
    getItemUrl(): string;
    itemTitle: string;
    itemDescription: string;
    itemImageUrl?: string;
}

enum Tab {
    Share,
    Embed,
}

interface State {
    activeTab: Tab;
    isCopySuccess: boolean;
}

/**
 * https://app.zeplin.io/project/5dd40f309990df4083eda452/screen/5e96fc2427d3fb527bc036a1
 * Share/embed content modal. Give this an ItemMetadata and render it, and don't
 * worry about anything else. It will call props.onClose when the user does
 * something that signals that the action is over and the modal should no
 * longer be displayed.
 */
export class ShareModal extends React.PureComponent<ShareModalProps, State> {

    constructor(props: ShareModalProps) {
        super(props);
        this.state = {
            activeTab: Tab.Share,
            isCopySuccess: false,
        };
    }

    public render() {
        return (
            <Modal
                className='content-share-modal'
                content={
                    <>
                        {this.renderTabBar()}
                        {this.renderContent()}
                    </>
                }
                onRequestClose={this.props.onClose}
            />
        );
    }

    private renderTabBar() {
        const activeTab = this.state.activeTab;

        // TODO: make this dynamic once support for embed added
        // TODO: remove embedExists parameter altogether once embed support added
        const embedDisabled = true;
        const embedExists = false;

        return (
            <nav>
                <button
                    className={`nav-item ${activeTab === Tab.Share ? 'active' : ''}`}
                    onClick={() => { this.setState({ activeTab: Tab.Share }); }}
                >
                    <WrappedMessage message={messages.shareTabTitle} />
                </button>
                {embedExists &&
                <button
                    aria-disabled={embedDisabled}
                    tabIndex={embedDisabled ? -1 : undefined}
                    className={
                        `nav-item ${activeTab === Tab.Embed ? 'active' : ''} ${embedDisabled ? 'disabled' : ''}`
                    }
                    onClick={() => (embedDisabled || this.setState({ activeTab: Tab.Embed }))}
                >
                    <WrappedMessage message={messages.embedTabTitle} />
                </button>
                }
            </nav>
        );
    }

    private renderContent() {
        switch (this.state.activeTab) {
            case Tab.Share: return this.renderShareContent();
            case Tab.Embed: return this.renderEmbedContent();
        }
    }

    private renderShareContent() {
        const itemTitle = this.props.itemTitle;
        const itemDescription = this.props.itemDescription;
        const itemImageUrl = this.props.itemImageUrl || '';

        const url = this.getAssetUrl();
        // sometimes image urls are absolute; we need to check and turn it into
        // an absolute url if it isn't.
        const imageUrl = /^(?:[a-z]+:)?\/\//i.test(itemImageUrl)
            ? itemImageUrl
            : window.location.origin + itemImageUrl;

        const pinterestParams = new URLSearchParams();
        pinterestParams.set('url', url);
        pinterestParams.set('media', imageUrl);
        pinterestParams.set('description', `${itemTitle}\n${itemDescription}`);
        const pinterestUrl = 'https://pinterest.com/pin/create/button/?' + pinterestParams.toString();

        const facebookParams = new URLSearchParams();
        facebookParams.set('u', url);
        const facebookUrl = 'https://www.facebook.com/sharer.php?' + facebookParams.toString();

        const twitterParams = new URLSearchParams();
        twitterParams.set('url', url);
        twitterParams.set('via', 'LabXchange');
        twitterParams.set('text', itemTitle);
        const twitterUrl = 'https://twitter.com/intent/tweet?' + twitterParams.toString();

        const classroomParams = new URLSearchParams();
        classroomParams.set('url', url);
        const classroomUrl = 'https://classroom.google.com/share?' + classroomParams.toString();

        return (
            <div className='buttons'>
                <a
                    onClick={this.props.onClose}
                    href={facebookUrl}
                    role='button'
                    target='_blank' rel='noreferrer noopener'
                    title={intl.formatMessage(messages.facebookShare)}
                    className='btn facebook-share-btn'
                >
                    <ScreenReaderIntl message={messages.facebookShare} />
                </a>
                <a
                    onClick={this.props.onClose}
                    href={twitterUrl}
                    role='button'
                    target='_blank' rel='noreferrer noopener'
                    title={intl.formatMessage(messages.twitterShare)}
                    className='btn twitter-share-btn'
                >
                    <ScreenReaderIntl message={messages.twitterShare} />
                </a>
                <a
                    onClick={this.props.onClose}
                    href={pinterestUrl}
                    role='button'
                    target='_blank' rel='noreferrer noopener'
                    title={intl.formatMessage(messages.pinterestShare)}
                    className='btn pinterest-share-btn'
                >
                    <ScreenReaderIntl message={messages.pinterestShare} />
                </a>
                <a
                    onClick={this.props.onClose}
                    href={classroomUrl}
                    role='button'
                    target='_blank' rel='noreferrer noopener'
                    title={intl.formatMessage(messages.classroomShare)}
                    className='btn classroom-share-btn'
                >
                    <ScreenReaderIntl message={messages.classroomShare} />
                </a>
                <button
                    onClick={this.onCopyLink}
                    title={intl.formatMessage(messages.copyLink)}
                    aria-label={intl.formatMessage(messages.copyLink)}
                    className='btn link-share-btn'
                />
            </div>
        );
    }

    private renderEmbedContent() {
        // TODO: implement this once sharing embedded content enabled.
        return null;
    }

    @bind private onCopyLink() {
        clipboardCopy(this.getAssetUrl());
        this.props.onClose();
        showSuccessMessage(<WrappedMessage message={messages.successfulCopyDescription} />, {
            title: <WrappedMessage message={messages.successfulCopyTitle} />,
        });
    }

    private getAssetUrl(): string {
        const url = window.location.origin + this.props.getItemUrl();
        return url;
    }
}
