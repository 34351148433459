import bind from 'bind-decorator';
import update from 'immutability-helper';
import moment from 'moment-shortformat';
import * as React from 'react';
import { APIPermissions, DiscussionCategory, DiscussionSubCategory } from 'labxchange-client';
import {DiscussionsApi} from 'global/api';
import ReactDOM from 'react-dom';
import { LocationDescriptor } from 'history';
import messages from 'discussions/displayMessages';
import { RouteComponentProps } from 'react-router';
import { showErrorMessage } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from '../../../utils';
import {ThreadCardList} from '../ThreadCardList';
import {ROUTES} from '../../../global/constants';
import {ThreadComposer} from '../ThreadComposer';
import { ThreadPinStatusEnum } from '../../models';
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'global/state';
import { getUserPermissions } from 'auth/selectors';
import { CommunityLayout } from 'dashboard';
import { intl } from 'i18n';

interface SubCategoryProps extends React.PropsWithChildren {
    subcategory: DiscussionSubCategory;
    onCreateThreadClick: (subcategoryID: string) => void;
    onPinThreadClick: (subcategory: DiscussionSubCategory, threadId: number, pinned: boolean) => void;
}

interface SubCategoryState {
    redirectTo?: LocationDescriptor;
    isComposing: boolean;
}

interface ReduxStateProps {
    userPermissions?: APIPermissions;
}

export class SubCategoryComponent extends React.PureComponent<SubCategoryProps, SubCategoryState> {

    constructor(props: SubCategoryProps) {
        super(props);

        this.state = {
            isComposing: false,
        };
    }

    public render() {
        const subcategory = this.props.subcategory;
        const threads = this.props.subcategory.threadSet || [];

        return (
            <div className='discussion-subcategory-container'>
                <div className='discussions-subcategory-header'>
                    <div className='subcategory-description'>
                        <div className='subcategory-icon'>
                            {subcategory.icon && <img src={subcategory.icon} alt='' />}
                        </div>
                        <div className='subcategory-subject'>
                            <Link
                            to={ROUTES.Community.SUBCATEGORY_ROUTE_SLUG(subcategory.id)}>
                            <div className='subcategory-name'>{subcategory.name}</div>
                            <div className='subcategory-description'>{subcategory.description}</div>
                            </Link>
                        </div>
                    </div>
                    <div className='subcategory-thread-button'>
                        <button
                            className='btn btn-primary'
                            onClick={this.onClickNewThread}
                            aria-label={intl.formatMessage(messages.discussionsAddNewThreadAria)}
                        >
                            <WrappedMessage message={messages.discussionsAddNewThread} />
                        </button>
                    </div>
                </div>
                <div className='discussions-subcategory-content'>
                <ThreadCardList
                    threads={threads}
                    getThreadLink={this.getThreadLink}
                    onPin={this.onClickPinThread}
                />
                </div>
                <div className='discussions-subcategory-bottom'>
                    <div className='subcategory-stats'>
                        <div className='stats-container'>
                            <div className='stats-header'>
                                <WrappedMessage message={messages.discussionsLastThread} />
                            </div>
                            <div className='stats-content'>{moment(subcategory.latestThread).short()}</div>
                        </div>
                        <div className='stats-container'>
                            <div className='stats-header'>
                                <WrappedMessage message={messages.discussionsLastReply} />

                            </div>
                            <div className='stats-content'>{moment(subcategory.latestReply).short()}</div>
                        </div>
                        <div className='stats-container'>
                            <div className='stats-header'>
                                <WrappedMessage message={messages.discussionsViews} />
                            </div>
                            <div className='stats-content'>{subcategory.viewCount}</div>
                        </div>
                    </div>
                    <div className='subcategory-all-threads'>
                        <Link
                            to={ROUTES.Community.SUBCATEGORY_ROUTE_SLUG(subcategory.id)}>
                            <WrappedMessage message={messages.discussionsAllThreads} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    @bind private getThreadLink(threadId: number) {
        return ROUTES.Community.SUBCATEGORY_THREAD_ROUTE_SLUG(this.props.subcategory.id, threadId.toString());
    }

    @bind private onClickNewThread() {
        this.props.onCreateThreadClick(this.props.subcategory.id);
    }

    @bind private onClickPinThread(threadId: number, pinned: boolean) {
        this.props.onPinThreadClick(this.props.subcategory, threadId, pinned);
    }
}


interface InternalState {
    isComposing: boolean;
    parentNode?: HTMLElement;
    sending: boolean;
    selectedSubCategory?: string;
    selectedSubCategoryData?: DiscussionSubCategory;
    redirectTo?: LocationDescriptor;
}

interface InternalProps {
    category?: DiscussionCategory;
    loading: boolean;
    onPinThreadClick: (subcategory: DiscussionSubCategory, threadId: number, pinned: boolean) => void;
}

class DiscussionCategoryPageInternal extends React.PureComponent<InternalProps & ReduxStateProps, InternalState> {
    constructor(props: InternalProps) {
        super(props);

        this.state = {
            sending: false,
            isComposing: false,
        };
    }

    public async componentDidMount() {
        const parentNode = ReactDOM.findDOMNode(this)!.parentNode;
        if (parentNode && parentNode instanceof HTMLElement) {
            this.setState({parentNode});
        }
    }

    private getSubCategoryFromCategory(category: DiscussionCategory, subCategoryID: string): DiscussionSubCategory|undefined {
        return category.subcategories.find(subCategory => subCategory.id === subCategoryID);
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push={true} to={this.state.redirectTo}/>;
        }
        if (this.props.loading) {
            return (
                <CommunityLayout pageTitle={uiMessages.uiLoading}>
                    <WrappedMessage message={uiMessages.uiLoading} />
                </CommunityLayout>
            );
        } else {
            const category = this.props.category;
            const subcategories = category? category.subcategories : [];

            return (
                <CommunityLayout
                    pageTitle={messages.discussionsCategoryPageTitle}
                    pageTitleValues={{name: category ? category.name : ''}}
                >
                    <div className='discussions-header'>
                        <Link to={ROUTES.Community.DISCUSSIONS}>
                            Discussion forum
                        </Link>
                        {category ?
                            <>
                                <span aria-hidden='true'>&nbsp;&gt;{' '}</span>
                                <span>{category.name}</span>
                            </> :
                            null
                        }
                    </div>
                    <div className='discussions-category'>
                        {(subcategories as DiscussionSubCategory[]).map((subcategory) => {
                            return <SubCategoryComponent
                                key={subcategory.id}
                                subcategory={subcategory}
                                onCreateThreadClick={this.onCreateThreadClick}
                                onPinThreadClick={this.props.onPinThreadClick}
                            >
                            </SubCategoryComponent>;
                        })}
                    </div>
                    {this.props.category && this.state.selectedSubCategory &&
                        <ThreadComposer
                            close={this.closeComposeDrawer}
                            open={this.state.isComposing}
                            parentNode={this.state.parentNode}
                            sending={this.state.sending}
                            onSubmit={this.submitThread}
                            tags={this.getSubCategoryFromCategory(this.props.category, this.state.selectedSubCategory)!.predefinedTags}
                            disableNewTags={!this.props.userPermissions?.profile.canAddTagsToThreads}
                        />
                    }
                </CommunityLayout>
            );
        }
    }

    @bind private onCreateThreadClick(subcategoryID: string) {
        this.setState({
            selectedSubCategory: subcategoryID,
            isComposing: !this.state.isComposing,
        });
    }

    @bind private closeComposeDrawer() {
        this.setState({isComposing: false});
    }

    @bind private async submitThread(title: string, content: string, tags?: string[]) {
        this.setState({sending: true});

        const selectedSubCategory = this.state.selectedSubCategory? this.state.selectedSubCategory : '';
        try {
            const thread = await DiscussionsApi.threadsCreate({
                data: {
                    subCategory: selectedSubCategory,
                    title,
                    content,
                    freeFormTags: tags,
                },
            });
            this.setState({
                sending: false,
                redirectTo: ROUTES.Community.SUBCATEGORY_THREAD_ROUTE_SLUG(selectedSubCategory, thread.id.toString()),
            });
            return;
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorCreatingThread} />, {exception: err});
            this.setState({sending: false});
        }
    }
}

interface MatchProps {
    category: string;
    topic?: string;
}

interface Props extends RouteComponentProps<MatchProps> {
}

interface State {
    category?: DiscussionCategory;
    loading: boolean;
}

class DiscussionCategoryPageHandler extends React.PureComponent<Props & ReduxStateProps, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            <DiscussionCategoryPageInternal
                category={this.state.category}
                loading={this.state.loading}
                onPinThreadClick={this.onPinThreadClick}
                {...this.props}
            />
        );
    }

    private async loadData() {
        this.setState({loading: true});

        try {
            const categoryId = this.props.match.params.category;
            const category = await DiscussionsApi.categoriesRead({id: categoryId});
            this.setState({category});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadCategoryFailed}/>, {exception: err});
        }

        this.setState({loading: false});
    }

    @bind private async onPinThreadClick(updatedSubcategory: DiscussionSubCategory, threadId: number, pinned: boolean) {
        if (this.state.category === undefined || updatedSubcategory.threadSet?.length === 0) { return; }

        try {
            const subcategories = await Promise.all((this.state.category?.subcategories)?.map(async (subcategory) => {
                if (subcategory.id === updatedSubcategory.id) {
                    let threads = subcategory.threadSet || [];

                    const pinStatus = pinned ? ThreadPinStatusEnum.Pinned : ThreadPinStatusEnum.Default;
                    const updatedThread = await DiscussionsApi.threadsPartialUpdate(
                        {
                            id: threadId.toString(), data: {
                                pinStatus,
                            },
                        },
                    );

                    threads = (threads).map(thread => thread.id === updatedThread.id ? updatedThread : thread);
                    return update(updatedSubcategory, {
                        threadSet: {
                            $set: threads
                        }
                    });
                }

                return subcategory;
            }));

            const category = {...this.state.category, subcategories: subcategories || []};

            this.setState(
                update(this.state, {
                    category: {$set: category}
                })
            );
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorUpdatingThread} />, {exception: err});
        }
    }
}


export const DiscussionCategoryPage = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
    }),
)(DiscussionCategoryPageHandler);
