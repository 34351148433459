import React from 'react';
import { NavLink } from 'react-router-dom';
import { intl } from '../../../../../i18n';
import messages from '../../../../displayMessages';
import { Icon } from '../../../../../elements';
import { CareerSuggestedAssets } from 'labxchange-client';

import { detailUrlForEntity } from '../../../../../library/utils';
import { WrappedMessage } from '../../../../../utils';

export const ChallengeCard = ({ suggestedAsset }: { suggestedAsset: CareerSuggestedAssets }) => {

    const suggestedAssetInfo = suggestedAsset.items?.[0].metadata;
    const labXLogo = '/assets/images/career-explorer/labxchange-asset-logo.svg';
    const defaultAssetImage = '/assets/images/career-explorer/challenge-card.svg';

    return (
        <>
            {suggestedAssetInfo && (
                <div className='challenge-card'>
                    <img
                        data-testid='assetImage'
                        alt={intl.formatMessage(messages.altTextForImages)}
                        src={suggestedAssetInfo.imageUrl || defaultAssetImage}
                    />
                    <div className='challenge-card__content'>
                        <h3>{suggestedAssetInfo.title}</h3>
                        <p>{suggestedAssetInfo.description}</p>
                        <NavLink
                            to={detailUrlForEntity(suggestedAssetInfo)}
                            className='challenge-card__button'
                        >
                            <div>
                                <img data-testid='labXlogo' alt={intl.formatMessage(messages.altTextForImages)} src={labXLogo} className='logo'/>
                                <WrappedMessage message={messages.careerChallengeCardCTAText}/>
                            </div>

                            <div data-testid='openExternalIcon'>
                                <Icon name='link-external' fill='#00AEEF' zoom='1em'></Icon>
                            </div>
                        </NavLink>
                    </div>
                </div>
            )}
        </>
    );
};
