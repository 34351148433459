// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyClasses: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyClassesEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyClassDiscussions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyClassDiscussionsEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyMentorship: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyMentorshipEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyNewMessages: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyNewMessagesEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyDiscussionForum: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyDiscussionForumEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyContentUpdate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyContentUpdateEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyProductInterviews: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifyProductInterviewsEmail: boolean;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return {
        'notifyClasses': json['notify_classes'],
        'notifyClassesEmail': json['notify_classes_email'],
        'notifyClassDiscussions': json['notify_class_discussions'],
        'notifyClassDiscussionsEmail': json['notify_class_discussions_email'],
        'notifyMentorship': json['notify_mentorship'],
        'notifyMentorshipEmail': json['notify_mentorship_email'],
        'notifyNewMessages': json['notify_new_messages'],
        'notifyNewMessagesEmail': json['notify_new_messages_email'],
        'notifyDiscussionForum': json['notify_discussion_forum'],
        'notifyDiscussionForumEmail': json['notify_discussion_forum_email'],
        'notifyContentUpdate': json['notify_content_update'],
        'notifyContentUpdateEmail': json['notify_content_update_email'],
        'notifyProductInterviews': json['notify_product_interviews'],
        'notifyProductInterviewsEmail': json['notify_product_interviews_email'],
    };
}

export function NotificationSettingsToJSON(value?: NotificationSettings): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'notify_classes': value.notifyClasses,
        'notify_classes_email': value.notifyClassesEmail,
        'notify_class_discussions': value.notifyClassDiscussions,
        'notify_class_discussions_email': value.notifyClassDiscussionsEmail,
        'notify_mentorship': value.notifyMentorship,
        'notify_mentorship_email': value.notifyMentorshipEmail,
        'notify_new_messages': value.notifyNewMessages,
        'notify_new_messages_email': value.notifyNewMessagesEmail,
        'notify_discussion_forum': value.notifyDiscussionForum,
        'notify_discussion_forum_email': value.notifyDiscussionForumEmail,
        'notify_content_update': value.notifyContentUpdate,
        'notify_content_update_email': value.notifyContentUpdateEmail,
        'notify_product_interviews': value.notifyProductInterviews,
        'notify_product_interviews_email': value.notifyProductInterviewsEmail,
    };
}


