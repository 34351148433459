// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserEducation
 */
export interface UserEducation {
    /**
     * 
     * @type {string}
     * @memberof UserEducation
     */
    qualification: string;
    /**
     * 
     * @type {string}
     * @memberof UserEducation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEducation
     */
    school?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEducation
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEducation
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof UserEducation
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEducation
     */
    startYear?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEducation
     */
    endMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEducation
     */
    endYear?: number;
}

export function UserEducationFromJSON(json: any): UserEducation {
    return {
        'qualification': json['qualification'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'school': !exists(json, 'school') ? undefined : json['school'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'startMonth': !exists(json, 'start_month') ? undefined : json['start_month'],
        'startYear': !exists(json, 'start_year') ? undefined : json['start_year'],
        'endMonth': !exists(json, 'end_month') ? undefined : json['end_month'],
        'endYear': !exists(json, 'end_year') ? undefined : json['end_year'],
    };
}

export function UserEducationToJSON(value?: UserEducation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'qualification': value.qualification,
        'description': value.description,
        'school': value.school,
        'country': value.country,
        'state': value.state,
        'start_month': value.startMonth,
        'start_year': value.startYear,
        'end_month': value.endMonth,
        'end_year': value.endYear,
    };
}


