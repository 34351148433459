// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomMembershipAcceptAll
 */
export interface ClassroomMembershipAcceptAll {
    /**
     * 
     * @type {string}
     * @memberof ClassroomMembershipAcceptAll
     */
    classroom: string;
}

export function ClassroomMembershipAcceptAllFromJSON(json: any): ClassroomMembershipAcceptAll {
    return {
        'classroom': json['classroom'],
    };
}

export function ClassroomMembershipAcceptAllToJSON(value?: ClassroomMembershipAcceptAll): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'classroom': value.classroom,
    };
}


