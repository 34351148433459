import bind from 'bind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import { Button, showErrorMessage } from 'ui/components';

import {
    ClassroomsDashboardContent,
    ExistingClassroom,
    ClassroomModals,
    ModalType,
} from 'classrooms/components';

import { ClassroomsApi } from 'global/api';
import { Classroom } from 'labxchange-client';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { EmptyDashboardPage } from 'library/components/EmptyDashboardPage';

interface Props extends RouteComponentProps<{}> { }

interface State {
    modalType: ModalType;
    loading: boolean;
    classrooms: Classroom[];
    selectedClassroom?: ExistingClassroom;       // tracks selected classroom for Modals
}

class EducatorClassroomsDashboardInternal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            modalType: ModalType.None,
            loading: false,
            selectedClassroom: undefined,
            classrooms: [],
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    @bind public async loadData() {
        this.setState({ loading: true });
        try {
            const classrooms = await ClassroomsApi.listAsEducator({});
            this.setState({ classrooms });
        } catch (err) {
            showErrorMessage('Could not load classrooms.', {exception: err});
        }
        this.setState({ loading: false });
    }

    public render() {
        if (!this.state.loading && this.state.classrooms.length === 0) {
            return this.renderEmptyPage();
        }
        return (
            <div className='educator-dashboard-page'>
                {this.renderModals()}
                <h1 className='sr-only'>
                    <WrappedMessage message={messages.pageTitle}/>
                </h1>
                <ClassroomsDashboardContent
                    isEducator={true}
                    activeClassrooms={
                        this.state.classrooms.filter((c) =>
                            c.archived === false && c.id !== undefined) as ExistingClassroom[]
                    }
                    inactiveClassrooms={
                        this.state.classrooms.filter((c) =>
                            c.archived === true && c.id !== undefined) as ExistingClassroom[]
                    }
                    onFauxCardClick={this.onAddClassroomClick}
                    onUnarchiveClick={this.onUnarchiveClick}
                    onCopyClick={this.onCopyClick}
                    onArchiveClick={this.onArchiveClick}
                    onDeleteClick={this.onDeleteClick}
                    showSkeleton={this.state.loading}
                />
            </div>
        );
    }

    @bind private onArchiveClick(classroom: ExistingClassroom) {
        this.setState({
            modalType: ModalType.Archive,
            selectedClassroom: classroom,
        });
    }

    @bind private onDeleteClick(classroom: ExistingClassroom) {
        this.setState({
            modalType: ModalType.Delete,
            selectedClassroom: classroom,
        });
    }

    @bind private onCopyClick(classroom: ExistingClassroom) {
        this.setState({
            modalType: ModalType.Copy,
            selectedClassroom: classroom,
        });
    }

    @bind private onUnarchiveClick(classroom: ExistingClassroom) {
        this.setState({
            modalType: ModalType.Unarchive,
            selectedClassroom: classroom,
        });
    }

    @bind private onAddClassroomClick() {
        this.setState({ modalType: ModalType.NewClassroom });
    }

    @bind private renderEmptyPage() {
        return (
            <div className='educator-dashboard-page'>
                {this.renderModals()}
                <EmptyDashboardPage
                    title={messages.emptyClassroomDashboardTitle}
                    secondaryText={messages.emptyClassroomDashboardSecondaryText}
                    buttons={
                        <Button
                            onClick={this.onAddClassroomClick}
                            className='btn primary-button add-link'
                            btnStyle='primary'
                            label={messages.emptyClassroomDashboardAction}
                        />
                    }
                />
            </div>
        );
    }

    private renderModals() {
        return (
            <ClassroomModals
                modalType={this.state.modalType}
                classroom={this.state.selectedClassroom}
                loading={this.state.loading}
                onShouldReloadData={this.loadData}
                onChangeModal={(modalType) => this.setState({ modalType })}

            />
        );
    }
}

export const EducatorClassroomsDashboard = withRouter(EducatorClassroomsDashboardInternal);
