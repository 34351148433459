import { bind } from 'bind-decorator';
import * as React from 'react';

import { DocumentField, ThinItemSection } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import { DocumentEditorState, SpecificEditorProps } from './models';

type Props = SpecificEditorProps<DocumentEditorState>;

/**
 * Editor UI for Document components (lx_document)
 */
export class DocumentBlockEditor extends React.PureComponent<Props> {

    public render() {
        const editorState = this.props.editorState;
        if (editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return (
                <ThinItemSection>
                    <DocumentField
                        required={true}
                        showErrors={this.props.showErrors}
                        currentUrl={editorState.documentUrl || ''}
                        currentFile={editorState.documentFile}
                        allowUrlInput={false}
                        onChange={this.onDocumentFileChange}
                    />
                </ThinItemSection>
            );
        }
    }

    /**
     * The user has dragged a new file onto the dropzone or entered a URL
     *
     * Note that for now we only store the file data/URL locally in the
     * editorState; it doesn't get uploaded until the user saves the whole
     * form.
     */
    @bind private onDocumentFileChange(newFile: File|undefined, newUrl: string) {
        const editorState: DocumentEditorState = this.props.editorState!;
        let documentFile;
        let documentUrl;

        if (newFile) {
            documentFile = newFile;
            documentUrl = '';
        } else {
            documentFile = undefined;
            documentUrl = newUrl; // May be '' if the url was cleared/deleted.
        }

        const newEditorState = {
            ...editorState,
            documentFile,
            documentUrl,
        };
        this.props.onEditorStateChanged(newEditorState);
    }

}
