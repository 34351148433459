import bind from 'bind-decorator';
import { ROUTES } from 'global/constants';
import { LibraryContentList } from 'library/components/LibraryContentList';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import { WrappedMessage, showSearchBarInHero, showExperiment } from 'utils';
import messages from './displayMessages';
import { localeInfo } from 'i18n';
import { LibrarySearchHeader } from '../LibrarySearchHeader';
import { NavTabs } from 'search/components/NavTabs';
import { detailUrlForEntity } from 'library/utils';
import { AppliedFilterFilterOnEnum, ItemMetadata } from 'labxchange-client';
import { ItemType } from '../../../items/models';
import { getAppliedSearchFilters } from 'search/utils';

const LIBRARY_CARD_EXP = 'show_new_library_card';

interface LibraryPageProps extends RouteComponentProps<{}> {}

interface LibraryPageState {
    searchParams: string[];
    showFilterModal: boolean;
}

/**
 * The LibraryPage is the main view of content available in LabXchange.
 *
 * It is comprised of a LibraryContentList which provides all of the searching and filtering functionality
 * for Library Content
 */
export class LibraryPage extends React.PureComponent<LibraryPageProps, LibraryPageState> {

    constructor(props: LibraryPageProps) {
        super(props);

        this.state = {
            searchParams: [`Language:${localeInfo.key}`],
            showFilterModal: false,
        };
    }

    private onToggleFilter = () => {
        this.setState(prevState => ({
            showFilterModal: !prevState.showFilterModal
        }));
    }

    private onClickViewItem = (item: ItemMetadata | undefined) => {
        if (item === undefined) return;
        this.props.history.push(detailUrlForEntity(item));
    }

    private onClickLaunchItem = (item: ItemMetadata | undefined) => {
        if (item === undefined) return;
        const itemUrl = detailUrlForEntity(item);
        const itemLaunchUrl = itemUrl ? `${itemUrl}?fullscreen=true` : '#';
        this.props.history.push(itemLaunchUrl);
    }

    private getExcludedItems = (searchParams: URLSearchParams) => {
        const filters = Array.from(getAppliedSearchFilters(new Set(searchParams.getAll('t'))));
        if (filters.some(f => f.filterOn === AppliedFilterFilterOnEnum.References)) {
            return [];
        }

        return [ItemType.Link];
    }

    public render() {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.sort(); // stabilize to avoid false positives with changes
        const header = showSearchBarInHero() ? (
            <LibrarySearchHeader
              {...this.props}
              searchTitleUrl={ROUTES.Library.SEARCH_TITLE}
              searchUrl={ROUTES.Library.HOME}
              searchParams={searchParams}
              onToggleFilter={this.onToggleFilter}
            />
        ) :  <NavTabs {...this.props} />;
        return (
            <StandardPageLayout pageTitle={messages.pageTitle} headerFeature={header}
                                searchParams={this.state.searchParams}>
                <div className='library-page'>
                    <h1 className='sr-only'>
                        <WrappedMessage message={messages.pageTitle}/>
                    </h1>
                    <LibraryContentList
                        onSearchParamsChanged={this.onSearchParamsChanged}
                        searchParams={searchParams.toString()}
                        isModalView={false}
                        displayAddButton={false}
                        openCardDetailUrlsInNewTab={false}
                        isPublicContentOnly={true}
                        disableDefaultFilters={searchParams.toString() !== ''}
                        showFilterModal={this.state.showFilterModal}
                        showLibraryActions={showExperiment(LIBRARY_CARD_EXP)}
                        onClickViewItem={this.onClickViewItem}
                        onClickLaunchItem={this.onClickLaunchItem}
                        excludeTypes={this.getExcludedItems(searchParams)}
                    />
                </div>
            </StandardPageLayout>
        );
    }

    @bind private onSearchParamsChanged(newParams: URLSearchParams) {
        const newUrl = `${ROUTES.Library.HOME}?${newParams.toString()}`;
        this.props.history.push(newUrl);
        this.setState({searchParams: newParams.getAll('t')});
    }
}
