import { intl } from 'i18n';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import messages from '../displayMessages';
import { Button } from 'ui/components';
import { TermsAndPolicyComponent } from './TermsAndPolicyComponent';

export interface LoginPageContentProps {
    children: React.ReactElement;
    title: MessageDescriptor;
    subtitle: MessageDescriptor;
    termsAndPolicyMessage: MessageDescriptor;
    onSignUpButtonClick?: () => void;
}

export const LoginPageContent: React.FunctionComponent<LoginPageContentProps> = (props) => {
    return (
        <div className='login-modal-content log-in-full-view'>
            <h2>{intl.formatMessage(props.title)}</h2>
            <div className='subtitle'>
                {intl.formatMessage(props.subtitle)}
            </div>
            <div className='content'>
                {props.children}
            </div>
            <TermsAndPolicyComponent
                type='sign-in'
                clickOnMessage={props.termsAndPolicyMessage}
            />
            <div className='sign-up-container'>
                <div className='sign-up-text'>
                    {intl.formatMessage(messages.lxAuthSignInSignUpMessage)}
                </div>
                <Button
                    className='sign-up-button'
                    btnStyle='link'
                    label={messages.lxAuthSignUpLavel}
                    onClick={props.onSignUpButtonClick}
                />
            </div>
        </div>
    );
};
