import { defineMessages } from 'react-intl';

const messages = defineMessages({
    selectDefaultOption: {
        id:'selectDefaultOption',
        defaultMessage: '- Select -',
        description: 'Default option for select inputs',
    },
    helpSubjectQuestion: {
        id:'helpSubjectQuestion',
        defaultMessage: 'What subject do you need help with?',
        description: 'Question about which subject mentee need help.',
    },
    helpQuestion: {
        id:'helpQuestion',
        defaultMessage: 'What kind of advice are you looking for?',
        description: 'Question about what mentee need help.',
    },
    mentorDescriptionTitle: {
        id:'mentorDescriptionTitle',
        defaultMessage: 'What is mentorship?',
        description: 'Mentorship explanation title.',
    },
    mentorDescriptionText: {
        id:'mentorDescriptionText',
        defaultMessage: `On LabXchange, mentorship is a way for you to get advice and guidance on your studies,
         research, and career. It is not intended as a way to get homework help or academic tutoring.
         Please answer the questions above to help your potential mentor decide if they are a good fit.
         You will be notified of their decision.`,
        description: 'Mentorship explanation description.',
    },
    mentorRequestReasonLabel: {
        id:'mentorRequestReasonLabel',
        defaultMessage: `Please write a message to {fullName} explaining your request for mentorship.`,
        description: 'Mentorship request explanation',
    },
    cancelButton: {
        id:'cancelRequestButton',
        defaultMessage: 'Cancel request',
        description: 'Cancel button',
    },
    sendApplicationButton: {
        id:'sendApplicationButton',
        defaultMessage: 'Request mentorship',
        description: 'Request mentorship button',
    },
    consentCheckboxLabel: {
        id:'consentCheckboxLabel',
        defaultMessage: 'I consent to receive messages from {fullName}.',
        description: 'Consent checkbox label.',
    },
    academicsHelp: {
        id:'academicsHelp',
        defaultMessage: 'Academics',
        description: 'Academics help',
    },
    teachingHelp: {
        id:'teachingHelp',
        defaultMessage: 'Teaching',
        description: 'Teaching help',
    },
    researchHelp: {
        id:'researchHelp',
        defaultMessage: 'Research',
        description: 'Research help',
    },
    careerHelp: {
        id:'careerHelp',
        defaultMessage: 'Career',
        description: 'Career help',
    },
    otherHelp: {
        id:'otherHelp',
        defaultMessage: 'Other',
        description: 'Other help',
    },
    biologicalSciencesHelp: {
        id:'biologicalSciencesHelp',
        defaultMessage: 'Biological Sciences',
        description: 'Biological sciences help',
    },
    chemistryHelp: {
        id:'chemistryHelp',
        defaultMessage: 'Chemistry',
        description: 'Chemistry help',
    },
    healthScienceHelp: {
        id:'healthScienceHelp',
        defaultMessage: 'Health Science',
        description: 'Health Science help',
    },
    globalHealthHelp: {
        id:'globalHealthHelp',
        defaultMessage: 'Global Health',
        description: 'Global Health help',
    },
    scienceSocietyHelp: {
        id:'scienceSocietyHelp',
        defaultMessage: 'Science & Society',
        description: 'Science & Society help',
    },
    scientificProcessHelp: {
        id:'scientificProcessHelp',
        defaultMessage: 'Scientific Process',
        description: 'Scientific Process help',
    },
    prepareCollegeHelp: {
        id:'prepareCollegeHelp',
        defaultMessage: 'Prepare for College',
        description: 'Prepare for College help',
    },
    prepareGradSchoolHelp: {
        id:'prepareGradSchoolHelp',
        defaultMessage: 'Prepare for Graduate School',
        description: 'Prepare for Graduate School help',
    },
    prepareCareersHelp: {
        id:'prepareCareersHelp',
        defaultMessage: 'Prepare for Careers',
        description: 'Prepare for Careers help',
    },
    professionalDevelopmentHelp: {
        id:'professionalDevelopmentHelp',
        defaultMessage: 'Professional Development',
        description: 'Professional Development help',
    },
});

export default messages;
