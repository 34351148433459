import bind from 'bind-decorator';
import { ClassroomsApi } from 'global/api';
import {getItemTypeMeta} from 'items/models';
import {
    ClassroomDetail,
    ItemProgress,
    LearnerReport
} from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UI_IS_SM } from 'ui/breakpoints';
import { showErrorMessage } from 'ui/components';
import { AssignedItemCompletion } from 'ui/components/AssignedItemsReport';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { ProgressMetric } from './ProgressMetric';
import { renderItemPostedDateFull, renderItemTitle } from './utils';

interface Props {
    classroom: ClassroomDetail;
}

interface State {
    learnerReport?: LearnerReport;
    loading: boolean;
    isMobileView: boolean;
}

export class ClassroomProgressLearner extends React.PureComponent<Props, State> {
    private mediaQuery = UI_IS_SM;  // This matches Bootstrap's definition of small (sm) breakpoint.

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            isMobileView: false,
        };
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
        await this.fetchLearnerReport();
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    public async fetchLearnerReport() {
        try {
            const learnerReport = await ClassroomsApi.learnerProgress(
                { id: this.props.classroom.id },
            );
            this.setState({ learnerReport, loading: false });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorClassroomReport}/>, {
                exception: err,
            });
        }
    }

    public renderEmptyScreen() {
        return (
            <div className='classroom-progress-learner'>
                <div className='empty-message'>
                    <WrappedMessage message={messages.emptyClassroomMessage} />
                </div>
            </div>
        );
    }

    public render() {
        if (this.state.loading) { return null; }
        if (this.state.learnerReport === undefined) { return null; }
        if (this.state.learnerReport.items.length === 0) {
            return this.renderEmptyScreen();
        }
        return (
            <div className='classroom-progress-learner'>
                <div className='summary'>
                    <ProgressMetric
                        label={messages.classScoreTitle} value={this.state.learnerReport?.totalScore}
                        helpText={messages.learnerClassScoreDescription}
                        className='class-score'
                    />
                    <ProgressMetric
                        label={messages.classProgressTitle}
                        value={Math.round(100 * (this.state.learnerReport?.totalCompletion || 0))}
                        helpText={messages.learnerClassProgressDescription}
                        className='class-progress'
                    />
                </div>
                {this.renderItems()}
            </div>
        );
    }

    private getAssignments() {
        if (this.state.learnerReport === undefined) { return (null); }

        let assignments: JSX.Element[];
        if (!this.state.isMobileView) {
            assignments = this.state.learnerReport.items.map(
                (itemProgress) => {
                    return (
                        <tr key={itemProgress.id} className='assignment'>
                            <th scope='row'>{renderItemPostedDateFull(itemProgress.assignedDate)}</th>
                            <td>
                                <AssignedItemCompletion
                                    completion={itemProgress.completion}
                                    completionDate={itemProgress.completionDate}
                                    showDate={true}
                                />
                            </td>
                            <td>{this.renderAssignedItems(itemProgress)}</td>
                            <td>{this.renderItemTypeInfo(itemProgress)}</td>
                            <td>{this.renderItemScore(itemProgress)}</td>
                        </tr>
                    );
                },
            );
        } else {
            assignments = this.state.learnerReport.items.map(
                (itemProgress) => {
                    return (
                        <tr key={itemProgress.id} className='assignment'>
                            <th scope='row'>
                                <Link className='mobile-row'
                                      to={detailUrlForEntity({id: itemProgress.itemId, type: itemProgress.type})}>
                                    <span className='created-date'>
                                        {renderItemPostedDateFull(itemProgress.assignedDate)}
                                    </span>
                                    <div className='assignment-title'>
                                        <span>{itemProgress.title}</span>
                                        <div className='caret'></div>
                                    </div>
                                    {this.renderItemScore(itemProgress, true)}
                                </Link>
                            </th>
                        </tr>
                    );
                },
            );
        }
        return assignments;
    }

    private renderItems() {
        if (this.state.loading) { return (null); }
        const assignments = this.getAssignments();
        return (
            <div className='table details'>
                <table>
                    <thead>
                        {!this.state.isMobileView ?
                        <tr>
                            <th scope='col' className='classroom-progress-learner-assigned'>
                                <WrappedMessage message={messages.assignedHeader} />
                            </th>
                            <th scope='col' className='classroom-progress-learner-completion'>
                                <WrappedMessage message={messages.completedHeader} />
                            </th>
                            <th scope='col'>
                                <WrappedMessage message={messages.nameHeader} />
                            </th>
                            <th scope='col' className='classroom-progress-learner-content-type'>
                                <WrappedMessage message={messages.contentTypeHeader} />
                            </th>
                            <th scope='col' className='classroom-progress-learner-score'>
                                <WrappedMessage message={messages.totalScoreHeader} />
                            </th>
                        </tr>
                        :
                        <tr>
                            <th scope='col'>
                                <WrappedMessage message={messages.assignedWorkHeader} />
                            </th>
                        </tr>
                        }
                    </thead>
                    <tbody>
                        {assignments}
                    </tbody>
                </table>
                </div>
        );
    }

    @bind private renderAssignedItems(itemProgress: ItemProgress) {
        const assessments = itemProgress.children?.map(
            (childItemProgress) => {
                const itemId = childItemProgress.id;
                return (
                    <li key={itemId} className='name'>
                        {renderItemTitle(childItemProgress)}
                        <span>{this.renderItemScore(childItemProgress)}</span>
                    </li>
                );
            },
        );
        return (
            <div>
                <span className='name'>
                    {renderItemTitle(itemProgress)}
                </span>
                <ul>{assessments}</ul>
            </div>
        );
    }

    @bind private renderItemScore(itemProgress: ItemProgress, useLabel = false): React.ReactNode {
        const earned = itemProgress.weightedEarned;
        const possible = itemProgress.weightedPossible;

        if (itemProgress.completion !== undefined) {
            // If there is a score to display, show it
            if (earned || possible) {
                return <WrappedMessage
                    message={useLabel ? messages.scoreWithLabel : messages.score}
                    values={{earned, possible}} />;
            }
            // If the item is complete, show that
            if (itemProgress.completionDate !== undefined) {
                return (
                    <span className='complete'>
                        <WrappedMessage message={messages.complete} />
                    </span>
                );
            }
            // Otherwise, show incomplete.
            return (
                <span className='incomplete'>
                    <WrappedMessage message={messages.notStarted} />
                </span>
            );
        }

        // Do not render scores for incompletable items.
        return <WrappedMessage message={useLabel ? messages.incompletableWithLabel : messages.incompletable} />;
    }

    @bind private renderItemTypeInfo(itemProgress: ItemProgress): React.ReactNode {
        const message = getItemTypeMeta(itemProgress.type as any).name;
        return (
            <div className='media-info'>
                <span><WrappedMessage message={message} values={{count: itemProgress.itemCount}}/></span>
            </div>
        );
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQuery.matches});
    }
}
