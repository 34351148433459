// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AddToAssignment
 */
export interface AddToAssignment {
    /**
     * 
     * @type {string}
     * @memberof AddToAssignment
     */
    assessmentId: string;
}

export function AddToAssignmentFromJSON(json: any): AddToAssignment {
    return {
        'assessmentId': json['assessment_id'],
    };
}

export function AddToAssignmentToJSON(value?: AddToAssignment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'assessment_id': value.assessmentId,
    };
}


