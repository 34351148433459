import {bind} from 'bind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { intl } from 'i18n';

import { getLoggedInStatus, getUsername, getUserPermissions } from 'auth/selectors';
import { ItemsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { ItemType } from 'items/models';
import {
    APIPermissions,
    ChildItem,
    ItemMetadata,
    ItemUserAttributes,
    RelatedItemMetadata,
    PathwayDetail,
} from 'labxchange-client';
import { ItemPageBody } from 'library/components/ItemPage/ItemPageBody';
import messages from 'library/displayMessages';
import { detailUrlForEntity, getPageMainClass, readNextItemInBookFor } from 'library/utils';
import { connect } from 'react-redux';
import { StandardPageLayout } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage, showLoginRequiredMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { getItemAndBlockData, getPathwayChild, getPathwayChildIndex } from '../../utils';
import { BlockData, getEmptyBlockData, LoadingState } from '../Block/models';
import { InvalidContent } from '../InvalidContent';
import { LibraryBreadcrumb } from '../Breadcrumb';

interface MatchProps {
    itemKey: string;
    pathwayKey: string;
    relId?: string;
}

interface GlobalStateProps {
    isLoggedIn: boolean;
    loggedInUsername?: string;
    userPermissions?: APIPermissions;
}

interface Props extends GlobalStateProps, RouteComponentProps<MatchProps> {
}

interface State {
    contentLoading: boolean;
    blockData: BlockData;
    contentMetadata?: ItemMetadata;
    contentUserAttributes?: ItemUserAttributes;
    // Does the XBlock request to be rendered with full width?
    expandBlock: boolean;
    pathwayLoading: boolean;
    pathwayMetadata?: ItemMetadata;
    pathwayChildren?: ChildItem[];
    next?: RelatedItemMetadata;
    errStatus?: number;
}

/**
 * The PathwayItemPage is the main view of a particular piece of content,
 * when viewed as part of a Pathway.
 *
 * When viewed outside a pathway, the ItemPage should be used instead.
 */
export class PathwayItemPageInternal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            blockData: getEmptyBlockData(),
            contentLoading: true,
            expandBlock: false,
            pathwayLoading: true,
        };
    }

    public componentDidMount() {
        this.loadFullContent(this.props.match.params.pathwayKey, this.props.match.params.itemKey, this.props.match.params.relId);
    }

    public async componentDidUpdate(prevProps: Props) {
        const itemKey = this.props.match.params.itemKey;
        const relId = this.props.match.params.relId;
        if (itemKey !== prevProps.match.params.itemKey || relId !== prevProps.match.params.relId) {
            this.partialPathwayRead();
            this.loadContentMetadata(itemKey, relId);
        }
    }

    private async loadFullContent(pathwayKey: string, itemKey: string|null = null, relId?: string) {
        this.setState({
            pathwayLoading: true,
            contentLoading: true,
        });
        try {
            // These requests mus be done in parallel for performance reasons.
            const [pathwayDetailsResponse, pathwayItemResponse, blockDataResponse ] = await Promise.all([
                ItemsApi.pathway({id: pathwayKey}),
                ItemsApi.read({id: pathwayKey}),
                getItemAndBlockData(itemKey!),
            ]);

            this.setState({
                pathwayChildren: pathwayDetailsResponse.items,
                pathwayLoading: false,
                pathwayMetadata: pathwayItemResponse.metadata,
            });
            // If the item is part of a book and we've originated at a book
            // index, then populate 'next' which is the next item in the book.
            if (pathwayItemResponse.metadata.book) {
                const source = new URLSearchParams(this.props.location.search).get('source') || undefined;
                if (source !== undefined && source.startsWith(ROUTES.Library.BOOK_SLUG(''))) {
                    const next = await readNextItemInBookFor(pathwayKey);
                    this.setState({ next });
                }
            }

            if (blockDataResponse.blockData.loadingState === LoadingState.READY) {
                this.setState({
                    contentLoading: false,
                    blockData: blockDataResponse.blockData,
                    contentMetadata: {...blockDataResponse.itemData.metadata, relId},
                    contentUserAttributes: blockDataResponse.itemData.userAttributes,
                });
            } else {
                this.setState({
                    contentLoading: false,
                    blockData: getEmptyBlockData(),
                    contentMetadata: undefined,
                    contentUserAttributes: undefined,
                });
            }
        } catch (err) {
            if (err.status) {
                this.setState({errStatus: err.status});
            } else {
                this.setState({errStatus: 500});
            }
            if (!this.props.isLoggedIn) {
                // Perhaps this is a private pathway. Prompt the user to log in.
                showLoginRequiredMessage();
            }
            this.setState({
                pathwayLoading: false,
                pathwayMetadata: undefined,
            });
        }
    }

    public renderHeader(content: React.ReactNode) {

        // When expandBlock is true, there is no source box so there is space to expand the title.
        const h1Classes = this.state.expandBlock ? 'col-12' : 'col-12 col-md-8 col-lg-9';

        return (
        <div className='pathway-item-page-header-content container py-4 pb-md-2'>
            <div className='row'>
                <div className={h1Classes}>
                    <LibraryBreadcrumb
                        book={this.state.pathwayMetadata && this.state.pathwayMetadata.book}
                        itemType={ItemType.Pathway}
                        itemTitle={this.state.pathwayMetadata?.title}
                        itemId={this.state.pathwayMetadata?.id}
                    />
                    {content}
                </div>
            </div>
        </div>);
    }

    public render() {
        if (this.state.pathwayMetadata === undefined || this.state.pathwayChildren === undefined) {
            if (this.state.pathwayLoading) {
                return (
                    <StandardPageLayout
                        headerFeature={
                            this.renderHeader(<h1 className='my-2'>{intl.formatMessage(uiMessages.uiLoading)}</h1>)
                        }
                    >
                        <p>{intl.formatMessage(uiMessages.uiLoading)}</p>
                    </StandardPageLayout>
                );
            } else {
                // We weren't able to load the metadata about the pathway.
                return (
                    <InvalidContent statusCode={this.state.errStatus} />
                );
            }
        }

        const pathway = this.state.pathwayMetadata;
        let itemIndex = 0;
        let contentTitle;

        if (this.state.contentMetadata === undefined) {
            if (this.state.contentLoading) {
                contentTitle = <WrappedMessage message={uiMessages.uiLoading}/>;
            } else {
                return <InvalidContent statusCode={this.state.errStatus} />;
            }
        } else {
            contentTitle = this.state.contentMetadata.title;
            const contentId = this.state.contentMetadata.id;
            const relId = this.props.match.params.relId;
            itemIndex = getPathwayChildIndex(this.state.pathwayChildren, contentId, relId);
        }

        const totalItems = this.state.pathwayChildren.length;
        // The progress bar is continuous by default, but should be made out of discrete items:
        // - on desktop, if there are less than 40 items;
        // - on tablets, if there are less than 25 items;
        const discreteOnDesktop = totalItems < 40;
        const discreteOnTablet = totalItems < 25;
        const progressBarClasses = classNames(
            'pathway-progress-bar',
            {
                'discrete-on-desktop': discreteOnDesktop,
                'discrete-on-tablet': discreteOnTablet,
            },
        );

        const pageHeading = <>{itemIndex + 1}. {contentTitle} <span>{itemIndex + 1}/{totalItems}</span></>;

        const headerContent = <>
            <h1 className='my-2' dir='auto'>{pageHeading}</h1>
            <div className={progressBarClasses}>
                {this.state.pathwayChildren.map((child, idx) => {
                    const current = idx === itemIndex;
                    const visited = (child.item.userAttributes.completion === 1) || current;

                    const className = classNames({ current, visited });
                    return (
                        <NavLink
                            className={className}
                            aria-label={child.item.metadata.title}
                            key={child.item.metadata.id}
                            to={detailUrlForEntity(child.item.metadata, pathway.id)}
                        />
                    );
                })}
            </div>
        </>;

        if ((this.state.contentMetadata === undefined) ||
            (this.state.contentUserAttributes === undefined)) {
            return (
                <StandardPageLayout headerFeature={this.renderHeader(headerContent)}>
                    <p>{contentTitle}</p>
                </StandardPageLayout>
            );
        } else {
            return (
                <StandardPageLayout
                    containerPaddingEnabled={this.state.expandBlock === false}
                    headerContainerPaddingEnabled={this.state.expandBlock === false}
                    mainClassName={getPageMainClass(this.state.expandBlock, this.state.contentMetadata?.type)}
                    pageTitle={messages.pathwayItemPageTitle}
                    pageTitleValues={{index: String(itemIndex + 1), name: this.state.contentMetadata.title}}
                    headerFeature={this.renderHeader(headerContent)}
                >
                    <ItemPageBody
                        next={this.state.next}
                        blockData={{...this.state.blockData, ...this.blockDataOverrides}}
                        contentMetadata={this.state.contentMetadata}
                        contentUserAttributes={this.state.contentUserAttributes}
                        expandBlock={this.state.expandBlock}
                        onExpandBlock={this.onExpandBlock}
                        pathwayMetadata={pathway}
                        pathwayChildren={this.state.pathwayChildren}
                        isUserLoggedIn={this.props.isLoggedIn}
                        screenReaderHeading={pageHeading}
                        loggedInUsername={this.props.loggedInUsername}
                        userPermissions={this.props.userPermissions}
                    />
                </StandardPageLayout>
            );
        }
    }

    private partialPathwayRead() {
        // Load full pathway to properly render process indicator
        return ItemsApi.pathway({id: this.props.match.params.pathwayKey})
            .then((pathwayDetails: PathwayDetail) => this.setState({pathwayChildren: pathwayDetails.items}))
            .catch((error) => showErrorMessage('Could not fetch pathway data.', {exception: error}));
    }

    private async loadContentMetadata(itemKey: string, relId?: string) {
        this.setState({
            contentLoading: true,
            contentMetadata: undefined,
            contentUserAttributes: undefined,
        });
        const response = await getItemAndBlockData(itemKey);
        if (response.blockData.loadingState === LoadingState.READY) {
            this.setState({
                contentLoading: false,
                blockData: response.blockData,
                contentMetadata: {...response.itemData.metadata, relId},
                contentUserAttributes: response.itemData.userAttributes,
            });
        } else {
            this.setState({
                contentLoading: false,
                blockData: getEmptyBlockData(),
                contentMetadata: undefined,
                contentUserAttributes: undefined,
                errStatus: response.statusCode,
            });
        }
    }

    /**
     * Get any pathway-specific block overrides for the current item.
     * This is not stored in state because the current item may change,
     * and it's easier to check this every time it's needed.
     */
    private get blockDataOverrides(): Partial<BlockData> {
        const itemKey = this.props.match.params.itemKey;
        const relId = this.props.match.params.relId;
        // Check for pathway-specific video start/stop timestamp overrides:
        const pathwayChild = getPathwayChild(this.state.pathwayChildren!, itemKey, relId);
        if (pathwayChild && pathwayChild.videoStartTime !== undefined && pathwayChild.videoStopTime !== undefined) {
            return {
                videoPlaybackWindow: [pathwayChild.videoStartTime, pathwayChild.videoStopTime],
            };
        }
        return {};
    }

    /**
     * The XBlock on this page has requested to take up the full page width
     * (or not).
     * @param expand Whether or not to render the XBlock in full width
     */
    @bind private onExpandBlock(expand: boolean) {
        this.setState(
            { expandBlock: expand },
        );
    }
}

export const PathwayItemPage = connect<GlobalStateProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        userPermissions: getUserPermissions(state),
    }),
)(PathwayItemPageInternal);
