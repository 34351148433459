// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomObjectPermissions
 */
export interface ClassroomObjectPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomObjectPermissions
     */
    canEditClassObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomObjectPermissions
     */
    canDeleteClassObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomObjectPermissions
     */
    canPinThreadObject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomObjectPermissions
     */
    canModifyEducatorsObject: boolean;
}

export function ClassroomObjectPermissionsFromJSON(json: any): ClassroomObjectPermissions {
    return {
        'canEditClassObject': json['can_edit_class_object'],
        'canDeleteClassObject': json['can_delete_class_object'],
        'canPinThreadObject': json['can_pin_thread_object'],
        'canModifyEducatorsObject': json['can_modify_educators_object'],
    };
}

export function ClassroomObjectPermissionsToJSON(value?: ClassroomObjectPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_edit_class_object': value.canEditClassObject,
        'can_delete_class_object': value.canDeleteClassObject,
        'can_pin_thread_object': value.canPinThreadObject,
        'can_modify_educators_object': value.canModifyEducatorsObject,
    };
}


