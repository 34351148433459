import * as React from 'react';
import { Link } from 'react-router-dom';

import { WrappedMessage } from 'utils';
import { intl } from 'i18n';

import messages from '../displayMessages';


export interface PartnerProps {
    name: string;
    img: string;
    url: string;
}

interface Props {
    partners: PartnerProps[];
    partnersUrl?: string;
}

export const PartnerSection: React.FunctionComponent<Props> = ({partners, partnersUrl}) => (

    <div className='partner-wrapper'>
        <p className='partner-description'>
            <WrappedMessage message={messages.partnerLogoStripDescription} values={{ count: <b>175</b> }} />
        </p>
        <div className='partner-list'>{partners.map((partner, idx) => {
            return (
                <Partner key={idx} {...partner}/>
            );
        })}</div>
        {partnersUrl ? (
            <Link
                to={partnersUrl}
                className='see-all btn btn-outline-primary new-home-page'
                aria-label={intl.formatMessage(messages.collaboratorAriaLabel)}
            >
                <WrappedMessage message={messages.buttonSeeAllPartners}/>
            </Link>
            ) : null
        }
    </div>
);

const Partner: React.FunctionComponent<PartnerProps> = ({name, img, url}) => (
    <Link to={url} className='partner'>
        <img src={img} alt={name}/>
    </Link>
);
