import { bind } from 'bind-decorator';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import autosize from "autosize";
import { doAutoSize } from "../../Utils";
import {
    HtmlTextBox,
    ToggleTextSwitch,
    ToggleSwitch
} from 'ui/components';
import { intl } from 'i18n';
import { messages as componentMessages } from '../displayMessages';


const messages = defineMessages({
    title: {
        id: 'MultiSelectItem.title',
        defaultMessage: 'Answer item'
    },
    correct: {
        id: 'MultiSelectItem.placeholder.correct',
        defaultMessage: 'Add the correct answer'
    },
    incorrect: {
        id: 'MultiSelectItem.placeholder.incorrect',
        defaultMessage: 'Add an incorrect answer'
    },
    selectedFeedback: {
        id: 'MultiSelectItem.selectedFeedback',
        defaultMessage: 'Selected feedback (specific)'
    },
    unselectedFeedback: {
        id: 'MultiSelectItem.unselectedFeedback',
        defaultMessage: 'Unselected feedback (specific)'
    },
    selectedFeedbackFieldPlaceholder: {
        id: 'MultiSelectItem.selectedFeedbackFieldPlaceholder',
        defaultMessage: 'Enter feedback for when the choice is selected'
    },
    unselectedFeedbackFieldPlaceholder: {
        id: 'MultiSelectItem.unselectedFeedbackFieldPlaceholder',
        defaultMessage: 'Enter feedback for when the choice is unselected'
    },
    buttonRemoveAreaLabel: {
        id: 'MultiSelectItem.buttonRemoveAreaLabel',
        defaultMessage: 'Remove answer item'
    }
});

export default class MultiSelectItem extends React.PureComponent<any, any> {

    private answerTextarea: any;
    private selectedFeedbackTextarea: any;

    constructor(props: any) {
        super(props);

        this.state = {
            openFeedback: this.props.selectedFeedback,
            // Hide unselected feedback:
            // https://app.asana.com/0/1202822149778018/1203232161649928/f
            disableUnselectedFeedback: true,
        };
    }

    componentDidMount() {
        autosize(this.answerTextarea);
        autosize(this.selectedFeedbackTextarea);
    }

    @bind private showHideFeedback() {
        this.setState({
            openFeedback: !this.state.openFeedback
        }, () => {
            doAutoSize();
        });
    }

    @bind private toggleChange(correct: boolean) {
        this.propsChanged({
            ...this.props,
            correct,
        })
    }

    @bind private selectedFeedbackChanged(text: string) {
        this.propsChanged({
            ...this.props,
            selectedFeedback: text
        });
    }

    @bind private unselectedFeedbackChanged(event: any) {
        this.propsChanged({
            ...this.props,
            unselectedFeedback: event.target!.value
        });
    }

    @bind private titleChanged(title: string) {
        this.propsChanged({ ...this.props, title });
    }

    private propsChanged(props: any) {
        this.props.multiSelectChangeAnswer(props);
    }

    @bind private removeAnswer() {
        this.props.multiSelectRemoveAnswer(this.props.id);
    }

    render() {
        const { formatMessage } = intl;
        const additionalСlass = this.state.openFeedback ? 'lxc-answers-feedback_open' : '';
        const additionalСlassContainer = this.state.openFeedback ? 'lxc-answers-feedback-container_open' : '';
        const placeholderText = this.props.correct ? formatMessage(messages.correct) : formatMessage(messages.incorrect);

        const showErrorButton = this.props.answersList && this.props.answersList.length > 2
            && this.props.id !== 'A' && this.props.id !== 'B';

        return (
            <div key={this.props.id} className='lxc-answers-option'>
                <ToggleTextSwitch
                    isChecked={this.props.correct}
                    onToggle={this.toggleChange}
                    extraClass='lxc-answers-toggle'
                />
                <div className='lxc-answers-field-wrapper'>
                    <div className={`lxc-answers-field-block ${this.state.openFeedback ? 'lxc-answers-field-block_open' : ''}`}>
                        <div className={`lxc-answers-item-wrapper ${showErrorButton ? 'lxc-answers-item-wrapper-with-delete' : ''}`}>
                            <label className='lxc-sr' htmlFor={`answer-multi${this.props.id}`}>{placeholderText}</label>
                            <HtmlTextBox
                                label={placeholderText}
                                hideLabel={true}
                                defaultValue={this.props.title}
                                onChange={(text: string) => this.titleChanged(text)}
                                editorStyle={HtmlTextBox.EditorStyle.AssesmentEditor}
                                ref={c => (this.answerTextarea = c)}
                                options={{ forced_root_block: false, body_class: 'wysiwyg-select-body' }}
                                extraClassName="wysiwyg-select"
                                labelJustify='flex-end'
                                auto_focus={this.props.focusAnswer}
                            />
                        </div>
                    </div>
                    <div className={`lxc-answers-feedback ${additionalСlass} ${showErrorButton ? 'lxc-answers-feedback-with-delete' : ''}`}>
                        <div className='lxc-answers-feedback-toggle'>
                            <ToggleSwitch
                                isChecked={this.state.openFeedback}
                                onToggleSwitchSelect={this.showHideFeedback}
                                displayText={formatMessage(componentMessages.feedbackToggleLabel)}
                            />
                        </div>
                        <div className={`lxc-answers-feedback-container ${additionalСlassContainer}`}>
                            <HtmlTextBox
                                label={formatMessage(messages.selectedFeedback)}
                                defaultValue={this.props.selectedFeedback}
                                onChange={(text: string) => this.selectedFeedbackChanged(text)}
                                editorStyle={HtmlTextBox.EditorStyle.AssesmentEditor}
                                ref={c => (this.selectedFeedbackTextarea = c)}
                                options={{ forced_root_block: false, body_class: 'wysiwyg-select-body' }}
                                extraClassName="wysiwyg-select"
                            />
                            {!this.state.disableUnselectedFeedback
                                ?
                                <>
                                    <label className='lxc-answers-feedback-title' htmlFor={`feedback-unselected${this.props.id}`}>
                                        {formatMessage(messages.unselectedFeedback)}
                                    </label>
                                    <textarea rows={1} className='lxc-answers-feedback-field'
                                        id={`feedback-unselected${this.props.id}`} value={this.props.unselectedFeedback}
                                        placeholder={formatMessage(messages.unselectedFeedbackFieldPlaceholder)}
                                        onChange={this.unselectedFeedbackChanged} />
                                </>
                                : null}

                        </div>
                    </div>
                </div>
                {
                    showErrorButton ? (
                        <button className='lxc-answers-remove-btn' type='button' aria-label={formatMessage(messages.buttonRemoveAreaLabel)} onClick={this.removeAnswer} />
                    ) : null
                }
            </div>
        );
    }
}
