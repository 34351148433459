import { LxConfig } from 'global/constants';
import { DEFAULT_UI_LANGUAGE, parseSupportedLanguageCode } from 'i18n';
import { Author, ItemMetadataLicenseEnum } from 'labxchange-client';
import { VTTtoSRT } from 'library/components/Transcript/utils';

const VIMEO_API_URL = 'https://api.vimeo.com/';

function vimeoApiUrl(endpoint: string, params: {}) {
    const query = new URLSearchParams({...params}).toString();
    return `${VIMEO_API_URL}/${endpoint}?${query}`;
}

export function getVimeoIdFromUrl(url: string) {
    const match = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/([^&]+)/);
    return match && match[1];
}

export async function getVideoDetails(videoId: string) {
    const endpoint = `videos/${videoId}`;
    const headers = {
        'Authorization': `bearer ${LxConfig.VimeoApiKey}`,
    };

    const response = await fetch(vimeoApiUrl(endpoint, {}), {
        headers,
    });
    const json = await response.json();

    if (response.status !== 200) {
        const message = 'Vimeo API video details request failed';
        throw new Error(message);
    }
    return json;
}

export async function getTranscriptsRequest(videoId: string) {
    const endpoint = `videos/${videoId}/texttracks`;
    const headers = {
        'Authorization': `bearer ${LxConfig.VimeoApiKey}`,
    };

    const response = await fetch(vimeoApiUrl(endpoint, {}), {
        headers,
    });
    const json = await response.json();

    if (response.status !== 200) {
        const message = 'Vimeo API video transcripts request failed';
        throw new Error(message);
    }
    return json;
}

export async function getTranscripts(videoId: string) {
    const transcripts = {} as any;

    let json;
    try {
        json = await getTranscriptsRequest(videoId);
    }
    catch (error) {
        return {};
    }
    if (json.data) {
        for (const data of json.data) {
            if (data.link && data.language) {
                try {
                    const response = await fetch(data.link);
                    const blob = await response.blob();
                    const language = data.language.toLowerCase();
                    const transcriptFile = new File(
                        [VTTtoSRT(await blob.text())],
                        'transcript-' + language + '.srt', {
                        type: 'text/plain;charset=utf-8',
                    });
                    transcripts[language] = transcriptFile;
                }
                catch (error) {
                    continue;
                }
            }
        }
    }
    return transcripts;
}

export async function getVimeoParams(videoUrl: string, videoId: string, author?: Author) {
    const videoDetails = await getVideoDetails(videoId);

    let language = DEFAULT_UI_LANGUAGE.key;
    let originalLanguage;
    const defaultLanguage = videoDetails.language;
    if (defaultLanguage) {
        const parsedLanguage = parseSupportedLanguageCode([defaultLanguage]);
        if (parsedLanguage) {
            language = parsedLanguage;
        } else {
            originalLanguage = defaultLanguage;
        }
    }

    const authors = [];
    if (author) {
        authors.push(author);
    }
    authors.push({
        fullName: videoDetails.user.name,
        originalAuthor: true,
    });

    const tags = [];
    if (videoDetails.tags) {
        for (const tag of videoDetails.tags) {
            tags.push(tag.name);
        }
    }

    let videoPicture;
    const pictures = {} as any;
    if (videoDetails.pictures) {
        for (const size of videoDetails.pictures.sizes) {
            pictures[size.width] = size.link;
        }
        /// Trying different sizes
        videoPicture = pictures[960] || pictures[1280] || pictures[1920] || pictures[640];
    }

    const params = {
        title: videoDetails.name,
        description: videoDetails.description,
        language,
        originalLanguage,
        freeFormTags: tags,
        authors,
        license: videoDetails.license === 'creativeCommons' ?
            ItemMetadataLicenseEnum.CCBY40 :
            ItemMetadataLicenseEnum.LX1,
        imageUrl: videoPicture,
        blockEditorDefaults: {
            html5Sources: [videoUrl],
            transcripts: await getTranscripts(videoId),
        },
    };
    return params;
}
