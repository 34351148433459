import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { DropdownLinkProps, DropdownList } from 'ui/components/Dropdown';
import { intl } from 'i18n';

interface DashboardDropdownProps {
    title: MessageDescriptor;
    titleValues?: {};
    links: DropdownLinkProps[];
}

export class DashboardDropdown extends React.PureComponent<DashboardDropdownProps> {

    public render() {
        return (
            <div className='dashboard-header container'>
                <DropdownList
                    label={intl.formatMessage(this.props.title, this.props.titleValues || {})}
                    id='dashboard-dropdown'
                    className='dashboard-dropdown'
                    links={this.props.links}
                />
            </div>
        );
    }
}
