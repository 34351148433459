const biologicalTechnicianNodes = [
    {
        id: '1',
        position: { x: 100, y: 100 },
        data: {
            label: 'High school',
            nodeName: 'high-school',
            nodeType: 'typical',
            description: 'Excited about this career? This is where your career journey begins.',
            completionLabel: 'I’ve finished high school',
            dataRows: [
                {title: 'Years of study', subTitle: '5 years', iconName: 'calendar-clock'},
                {title: 'Required credits to complete', subTitle: '22 to 24', iconName: 'hash'},
                {title: 'Subjects', subTitle: 'Science, Technology and Mathematics focused', iconName: 'career-subjects'},
            ],
            guide: {
                overview: 'For a full list of requirements, check out the high school guide.',
                details: {
                    description: 'Here’s a list of requirements along with recommended extras to help you prepare for college. Consider AP classes if available, or look into dual enrolment options.',
                    requiredSubjects: [
                        {
                            title: 'Core subjects',
                            description: 'You’ll need to take at least one class in each core subject.',
                            subjects: [
                                {
                                    title: 'Science',
                                    description: 'Research, lab techniques, and biology will build the skills needed for this role’s core duties.',
                                    checklist: [
                                        'AP Biology',
                                        'AP Chemistry',
                                    ],
                                },
                                {
                                    title: 'Mathematics',
                                    description: 'Calculations and measurements will prepare you for experiments and scientific research.',
                                    checklist: [
                                        'Algebra',
                                        'AP Calculas',
                                    ],
                                },
                                {
                                    title: 'English',
                                    description: 'Presenting, research, and writing will prepare you for technical reports and sharing your findings.',
                                    checklist: [
                                        'AP English Language and Composition',
                                        'Communication',
                                        'Technical writing',
                                    ],
                                },
                                {
                                    title: 'Social Science',
                                    description: 'Transferable skills like teamwork and communication are essential for collaborating in lab settings.',
                                    checklist: [
                                        'AP Psychology',
                                        'AP World History',
                                        'Geography',
                                        'Modern world studies',
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Electives',
                            description: 'You’ll need to take as many as needed to reach the required total credits.',
                            subjects: [
                                {
                                    title: 'Science',
                                    description: 'Enhance your skills in research, lab techniques, and biology.',
                                    checklist: [
                                        'AP Environmental science',
                                        'AP Physics: Algebra-Based',
                                    ],
                                },
                                {
                                    title: 'Computer Science',
                                    description: 'Essential skills for data analysis and documenting experiments.',
                                    checklist: [
                                        'AP Computer Science',
                                        'Electronics',
                                    ],
                                },
                                {
                                    title: 'Vocational',
                                    description: 'Hands on practical skills to prepare you for various lab assays.',
                                    checklist: [
                                        'Lab techniques',
                                        'Microbiology lab methods',
                                    ],
                                },
                                {
                                    title: 'Capstone',
                                    description: 'Build skills in research, analysis, collaboration, and presenting.',
                                    checklist: [
                                        'AP Research',
                                        'AP Seminar',
                                    ],
                                },
                            ],
                        },
                    ],
                    optionalSubjects: [
                        {
                            title: 'Extracurricular Activities',
                            description: 'Helps you develop teamwork and hands-on experience, which are valued by colleges and future employers.',
                            subjects: [
                                {
                                    title: 'Clubs',
                                    description: 'Gain practical experience and build essential skills.',
                                    checklist: [
                                        'Science Club',
                                        'Science Olympaid',
                                    ],
                                },
                                {
                                    title: 'Fairs',
                                    description: 'Showcase skills, share research and connect with field experts.',
                                    checklist: [
                                        'Science Fairs',
                                    ],
                                },
                                {
                                    title: 'Volunteering',
                                    description: 'Gives you experience with tools and techniques.',
                                    checklist: [
                                        'Technical workshops',
                                        'Local labs',
                                        'Environmental organizations',
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Career readiness',
                            description: 'It’s never too early to start planning your future.',
                            subjects: [
                                {
                                    title: 'Internships',
                                    description: 'Helps you develop essential skills through real-world exposure.',
                                    checklist: [
                                        'Summer internships',
                                        'Job shadowing',
                                    ],
                                },
                                {
                                    title: 'Interests',
                                    description: 'Helps you narrow down your options and find a career that is right for you.',
                                    checklist: [
                                        'Explore careers related to your interests',
                                        'List your interests',
                                    ],
                                },
                                {
                                    title: 'Career Comparison',
                                    description: 'Helps you identify and compare what is most important to you.',
                                    checklist: [
                                        'Research industries',
                                        'List what is important to you',
                                        'Explore various careers',
                                        'Try out career challenges',
                                        'Save careers to your shortlist',
                                        'Compare careers',
                                    ],
                                },
                                {
                                    title: 'Skills Development ',
                                    description: 'While you will learn many skills in school, you can boost your learning with our FREE focused skill lessons on LabXchange.',
                                    checklist: [
                                        'Complete the skills lessons for your chosen career',
                                    ],
                                },
                                {
                                    title: 'College Preparation',
                                    description: 'Early preparation increases your chances of getting into the program that best fits your goals.',
                                    checklist: [
                                        'Search for colleges that offer Associate and Bachelor Degree programs',
                                    ],
                                },
                            ],
                        },
                    ],
                }
            }
        },
    },
    {
        id: '2',
        position: { x: 107, y: 200 },
        data: {
            label: 'Associate',
            nodeName: 'associate',
            nodeType: 'typical',
            description: 'Generally more affordable than a bachelor’s degree but offers fewer options, and many employers prefer bachelor\'s degrees. Transitioning to a bachelor\'s degree may be possible. Chat to the colleges admission office to find out more.',
            completionLabel: 'I\'ve finished an associate degree',
            dataRows: [
                {title: 'Years of study', subTitle: '2 years', iconName: 'calendar-clock'},
                {title: 'Required credits to complete', subTitle: '60', iconName: 'hash'},
                {title: 'Subjects', subTitle: 'Biological Sciences or Natural Resources', iconName: 'career-subjects'},
            ],
            guide: {
                overview: 'For a full list of requirements, check out the associate degree guide.',
                details: {
                    description: 'Here’s a list of requirements along with recommended extras to help you prepare for your career.',
                    requiredSubjects: [
                        {
                            title: 'Core subjects',
                            description: 'You’ll need to take at least one course in each core subject.',
                            subjects: [
                                {
                                    title: 'Science',
                                    description: 'Research, lab techniques, and biology will deepen the skills needed for this role’s core duties.',
                                    checklist: [
                                        'General biology I & II',
                                        'General Chemistry I & II',
                                    ],
                                },
                                {
                                    title: 'Mathematics',
                                    description: 'Calculation and data analysis will prepare you for experiments and scientific research.',
                                    checklist: [
                                        'Calculus',
                                        'Statistics',
                                    ],
                                },
                                {
                                    title: 'English',
                                    description: 'Presenting, research, and writing will prepare you for technical reports and sharing your findings.',
                                    checklist: [
                                        'English composition I & II',
                                    ],
                                },
                                {
                                    title: 'Social Science',
                                    description: 'Transferable skills like teamwork and communication are essential for collaborating in lab settings.',
                                    checklist: [
                                        'Public Speaking',
                                        'Psychology',
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Electives',
                            description: 'You’ll need to take as many as needed to reach the required total credits.',
                            subjects: [
                                {
                                    title: 'Science',
                                    description: 'Research, lab techniques, and biology will deepen the skills needed for this role’s core duties.',
                                    checklist: [
                                        'Lab Techniques ',
                                    ],
                                },
                            ],
                        },
                    ],
                    optionalSubjects: [
                        {
                            title: 'Extracurricular Activities',
                            description: 'Helps you develop teamwork and experience, making you more attractive to employers.',
                            subjects: [
                                {
                                    title: 'Clubs',
                                    description: 'Gain practical experience and build essential skills.',
                                    checklist: [
                                        'Join a science or STEM club',
                                    ],
                                },
                                {
                                    title: 'Volunteering',
                                    description: 'Gives you experience with tools and techniques.',
                                    checklist: [
                                        'Biology labs',
                                        'Environmental organizations',
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Career readiness',
                            description: 'It’s never too early to start planning your future.',
                            subjects: [
                                {
                                    title: 'Internships and Work Experience',
                                    description: 'Helps you develop essential skills through real-world exposure.',
                                    checklist: [
                                        'Summer internships',
                                        'Job shadowing',
                                        'Part-time lab assistant',
                                    ],
                                },
                                {
                                    title: 'Training Sessions',
                                    description: 'Learning experiences that help you develop practical skills.',
                                    checklist: [
                                        'Attend industry workshops',
                                    ],
                                },
                                {
                                    title: 'Skills Development ',
                                    description: 'While you will learn many skills in school, you can boost your learning with our FREE focused skill lessons on LabXchange.',
                                    checklist: [
                                        'Continue learning the skills lessons for your chosen career',
                                    ],
                                },
                            ],
                        },
                    ],
                }
            }
        },
    },
    {
        id: '3',
        position: { x: 106, y: 300 },
        data: {
            label: 'Internship',
            nodeName: 'internship',
            nodeType: 'typical',
            description: 'Internships offer hands-on experience and boosts your resume. While some programs include them, consider pursuing extra internships during summer breaks for additional experience.',
            completionLabel: 'I\'ve finished an internship',
            dataRows: [
                {title: 'Duration', subTitle: 'Short term to a year', iconName: 'calendar-clock'},
            ],
            guide: {
                overview: 'For a full list of requirements, check out the associate degree guide.',
                details: {
                    description: 'Here’s a list of required internships as well as recommended extras to prepare for your career. ',
                    requiredSubjects: [
                        {
                            title: 'Degree requirement',
                            description: 'Some degrees require you to do an internship for credits, typically in a research lab, university, or industry like pharmaceuticals, agriculture or biotechnology. Your college will guide you through the process.',
                        },
                        {
                            title: 'Post Studies',
                            description: 'With Associates degrees, a full-year internship may be required before landing an entry-level jon. Some internships are paid, while others aren’t. and prior experience may waive the need for additional internships.',
                        },
                    ],
                    optionalSubjects: [
                        {
                            title: 'Career readiness',
                            description: 'Here are additional methods to prepare you for your career.',
                            subjects: [
                                {
                                    title: 'Internships and Work Experience',
                                    description: 'Internship opportunities allow you to gain valuable work experience, build your network, and explore career paths while applying your academic knowledge.',
                                    checklist: [
                                        'Summer internships',
                                        'Job shadowing',
                                        'Part-time lab assistant',
                                    ],
                                },
                            ],
                        },
                    ],
                }
            }
        },
    },
    {
        id: '4',
        position: { x: 180, y: 250 },
        data: {
            label: 'Bachelor\'s',
            nodeName: 'bachelors',
            nodeType: 'alternative',
            description: 'A Bachelor\'s degree with a focus on lab-intensive coursework is recommended for this career.',
            completionLabel: 'I\'ve finished a bachelor\'s degree',
            dataRows: [
                {title: 'Years of study', subTitle: '4 years', iconName: 'calendar-clock'},
                {title: 'Required credits to complete', subTitle: '120', iconName: 'hash'},
                {title: 'Subjects', subTitle: 'Biology, Biotechnology, Ecology, Microbiology, Molecular Biology, or Biochemistry', iconName: 'career-subjects'},
            ],
            guide: {
                overview: 'For a full list of requirements, check out the associate degree guide.',
                details: {
                    description: 'Here’s a list of requirements as well as recommended extras to prepare for your career.',
                    requiredSubjects: [
                        {
                            title: 'Core subjects',
                            description: 'You’ll need to take at least one class in each core subject and these typically offer 3 to 4 credits each.',
                            subjects: [
                                {
                                    title: 'Science',
                                    description: 'Research, lab techniques, and biology will deepen the skills needed for this role’s core duties.',
                                    checklist: [
                                        'General Biology',
                                        'General Chemistry',
                                        'Lab Techniques',
                                        'Physics I'
                                    ],
                                },
                                {
                                    title: 'Mathematics',
                                    description: 'Calculation and data analysis will prepare you for experiments and scientific research.',
                                    checklist: [
                                        'Calculus',
                                        'Statistics',
                                    ],
                                },
                                {
                                    title: 'English',
                                    description: 'Writing, research, and presenting will prepare you for technical reports and sharing your findings effectively.',
                                    checklist: [
                                        'Writing in Biology',
                                        'English composition',
                                    ],
                                },
                                {
                                    title: 'Career Preparation',
                                    description: 'In your fourth year, you’ll take  career preparation focused courses.',
                                    checklist: [
                                        'Resume Writing',
                                        'Job Search',
                                        'Professional Writing',
                                    ],
                                },
                                {
                                    title: 'Thesis',
                                    description: 'Showcase your research, problem-solving abilities, and deep understanding of your field.',
                                    checklist: [
                                        'Capstone Project',
                                        'Senior Thesis',
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Electives',
                            description: 'You’ll need to take as many as needed to reach the required total credits.',
                            subjects: [
                                {
                                    title: 'Science',
                                    description: 'Enhance your skills in research, lab techniques, and biology.',
                                    checklist: [
                                        'Environmental Policy',
                                        'Environment Science',
                                        'Microbiology',
                                    ],
                                },
                                {
                                    title: 'Social Science',
                                    description: 'Transferable skills like teamwork and communication are essential for collaborating in lab settings.',
                                    checklist: [
                                        'Psychology',
                                        'Public Speaking',
                                    ],
                                },
                            ],
                        },
                    ],
                    optionalSubjects: [
                        {
                            title: 'Extracurricular Activities',
                            description: 'Helps you build teamwork and experience, making you more attractive to employers.',
                            subjects: [
                                {
                                    title: 'Clubs',
                                    description: 'Gain practical experience and build essential skills.',
                                    checklist: [
                                        'Join a science club',
                                    ],
                                },
                                {
                                    title: 'Volunteering',
                                    description: 'Gives you experience with tools and techniques.',
                                    checklist: [
                                        'Biology labs',
                                        'Environmental organizations',
                                    ],
                                },
                            ],
                        },
                        {
                            title: 'Career readiness',
                            description: 'It’s never too early to start planning your future.',
                            subjects: [
                                {
                                    title: 'Internships and Work Experience',
                                    description: 'Helps you develop essential skills through real-world exposure.',
                                    checklist: [
                                        'Summer internships',
                                        'Job shadowing',
                                        'Part-time lab assistant',
                                    ],
                                },
                                {
                                    title: 'Training Sessions',
                                    description: 'Learning experiences that help you develop practical skills.',
                                    checklist: [
                                        'Attend industry workshops',
                                    ],
                                },
                                {
                                    title: 'Skills Development ',
                                    description: 'You can boost your learnings with our FREE focused skill lessons on LabXchange.',
                                    checklist: [
                                        'Complete the skill lessons on the skills map',
                                    ],
                                },
                            ],
                        },
                    ],
                }
            }
        },
    },
    {
        id: '5',
        position: { x: 89, y: 400 },
        data: {
            label: 'Entry-level jobs',
            nodeName: 'entry-level-jobs',
            nodeType: 'typical',
            description: 'After earning your bachelor’s or associate degree and completing an internship, you’ll be ready to begin your career as a biological technician.',
            completionLabel: 'I\'ve finished an entry-level job',
            dataRows: [
                {title: 'Years of experience', subTitle: '0 to 2 years', iconName: 'calendar-clock'},
            ],
            otherCareers: {
                description: 'This path will also open doors to 5 other careers.',
                careers: [
                    {title: 'Environmental Science and Protection Technician', url: '#'},
                    {title: 'Food Science Technician', url: '#'},
                    {title: 'Greenhouse Technician', url: '#'},
                    {title: 'Veterinary Technologists and Technicians', url: '#'},
                    {title: 'Forensic Science Technician', url: '#'},
                ]
            }
        },
    },
];

const processEngineertNodes = [
    {
        'id': '1',
        'position': { 'x': 100, 'y': 100 },
        'data': {
            'label': 'High school',
            'nodeName': 'high-school',
            'nodeType': 'typical',
            'description': 'Excited about a career in process engineering? This is where your journey begins.',
            'completionLabel': 'I’ve finished high school',
            'dataRows': [
                { 'title': 'Years of study', 'subTitle': '5 years', 'iconName': 'calendar-clock' },
                { 'title': 'Required credits to complete', 'subTitle': '22 to 24', 'iconName': 'hash' },
                { 'title': 'Subjects', 'subTitle': 'Science, technology, and mathematics focused', 'iconName': 'career-subjects' }
            ],
            'guide': {
                'overview': 'For a full list of requirements, check out the high school guide.',
                'details': {
                    'description': 'Take courses such as AP Physics, AP Chemistry, and AP Calculus to prepare for engineering studies. Consider extracurriculars like robotics and science clubs to develop hands-on skills.',
                    'requiredSubjects': [
                        {
                            'title': 'Core subjects',
                            'description': 'At least one class in each core subject is required.',
                            'subjects': [
                                { 'title': 'Science', 'description': 'Physics and chemistry are essential for understanding engineering concepts.', 'checklist': ['AP Physics', 'AP Chemistry'] },
                                { 'title': 'Mathematics', 'description': 'Mathematics prepares you for problem-solving in engineering.', 'checklist': ['Algebra', 'AP Calculus'] },
                                { 'title': 'English', 'description': 'Strong communication skills are crucial for reports and presentations.', 'checklist': ['AP English Language and Composition', 'Technical writing'] }
                            ]
                        },
                        {
                            'title': 'Electives',
                            'description': 'Additional courses to round out your credits.',
                            'subjects': [
                                { 'title': 'Computer Science', 'description': 'Essential skills for data analysis and systems management.', 'checklist': ['AP Computer Science', 'Robotics'] },
                                { 'title': 'Vocational', 'description': 'Hands-on experience in technical fields.', 'checklist': ['Engineering Drawing', 'CAD'] }
                            ]
                        }
                    ],
                    'optionalSubjects': [
                        {
                            'title': 'Extracurricular Activities',
                            'description': 'Gain teamwork and technical experience through clubs and volunteering.',
                            'subjects': [
                                { 'title': 'Clubs', 'description': 'Science and engineering clubs provide practical experience.', 'checklist': ['Robotics Club', 'Science Olympiad'] },
                                { 'title': 'Volunteering', 'description': 'Build practical knowledge in technical fields.', 'checklist': ['Technical workshops', 'Local labs'] }
                            ]
                        }
                    ]
                }
            }
        }
    },
    {
        'id': '2',
        'position': { 'x': 22, 'y': 150 },
        'data': {
            'label': 'Associate',
            'nodeName': 'associate',
            'nodeType': 'alternative',
            'description': 'An associate degree provides foundational knowledge in engineering and opens up entry-level roles.',
            'completionLabel': 'I’ve finished an associate degree',
            'dataRows': [
                { 'title': 'Years of study', 'subTitle': '2 years', 'iconName': 'calendar-clock' },
                { 'title': 'Required credits to complete', 'subTitle': '60', 'iconName': 'hash' },
                { 'title': 'Subjects', 'subTitle': 'Science and engineering principles', 'iconName': 'career-subjects' }
            ],
            'guide': {
                'overview': 'For a full list of requirements, check out the associate degree guide.',
                'details': {
                    'description': 'Build technical skills and gain hands-on experience through labs and projects.',
                    'requiredSubjects': [
                        {
                            'title': 'Core subjects',
                            'description': 'Science, mathematics, and engineering principles are the focus.',
                            'subjects': [
                                { 'title': 'Science', 'description': 'General chemistry and physics form the basis of engineering knowledge.', 'checklist': ['General Chemistry 1', 'Physics 1'] },
                                { 'title': 'Mathematics', 'description': 'Strengthen your analytical skills with advanced math.', 'checklist': ['Pre-calculus', 'Calculus 1 & 2'] }
                            ]
                        },
                        {
                            'title': 'Electives',
                            'description': 'Specialize further with electives in engineering and technical subjects.',
                            'subjects': [
                                { 'title': 'Engineering', 'description': 'Focus on engineering mechanics and CAD.', 'checklist': ['Engineering Mechanics: Statics & Dynamics', 'CAD'] }
                            ]
                        }
                    ],
                    'optionalSubjects': [
                        {
                            'title': 'Extracurricular Activities',
                            'description': 'Internships and workshops provide practical skills.',
                            'subjects': [
                                { 'title': 'Internships', 'description': 'Gain real-world experience through internships.', 'checklist': ['Summer internships', 'Job shadowing'] }
                            ]
                        }
                    ]
                }
            }
        }
    },
    {
        'id': '3',
        'position': { 'x': 100, 'y': 200 },
        'data': {
            'label': 'Bachelor\'s',
            'nodeName': 'bachelors',
            'nodeType': 'typical',
            'description': 'A bachelor’s degree is essential for most process engineering positions and provides advanced skills.',
            'completionLabel': 'I’ve finished a bachelor’s degree',
            'dataRows': [
                { 'title': 'Years of study', 'subTitle': '4 years', 'iconName': 'calendar-clock' },
                { 'title': 'Required credits to complete', 'subTitle': '120', 'iconName': 'hash' }
            ],
            'guide': {
                'overview': 'Bachelor’s programs provide in-depth knowledge and practical experience through labs and internships.',
                'details': {
                    'description': 'Complete core courses and specialize in areas like process optimization and systems control.',
                    'requiredSubjects': [
                        {
                            'title': 'Core subjects',
                            'description': 'Focus on key engineering principles and advanced mathematics.',
                            'subjects': [
                                { 'title': 'Science', 'description': 'Deepen your understanding of process control and design.', 'checklist': ['General Chemistry 1 & 2', 'Heat Transfer'] },
                                { 'title': 'Mathematics', 'description': 'Focus on calculus and statistics for engineering applications.', 'checklist': ['Calculus 1 & 2', 'Statistics'] }
                            ]
                        }
                    ],
                    'optionalSubjects': [
                        {
                            'title': 'Specialization Electives',
                            'description': 'Tailor your studies with electives in areas like sustainable engineering and automation.',
                            'subjects': [
                                { 'title': 'Advanced Process Control', 'description': 'Learn to optimize production systems.', 'checklist': ['Process Simulation', 'Sustainable Process Engineering'] }
                            ]
                        }
                    ]
                }
            }
        }
    },
    {
        'id': '4',
        'position': { 'x': 100, 'y': 300 },
        'data': {
            'label': 'Internship',
            'nodeName': 'internship',
            'nodeType': 'typical',
            'description': 'An internship provides real-world exposure and practical experience in process engineering.',
            'completionLabel': 'I’ve finished an internship',
            'dataRows': [
                { 'title': 'Duration', 'subTitle': 'Short term to a year', 'iconName': 'calendar-clock' },
                { 'title': 'Focus', 'subTitle': 'Gaining hands-on experience in a lab or industry', 'iconName': 'career-subjects' }
            ],
            'guide': {
                'overview': 'Internships are a great way to apply classroom knowledge in real-world settings.',
                'details': {
                    'description': 'Most degrees require internships for credits. You’ll work in industries like pharmaceuticals, manufacturing, or biotech.',
                    'requiredSubjects': [
                        {
                            'title': 'Internships',
                            'description': 'Valuable work experience to prepare you for your career.',
                            'subjects': [
                                { 'title': 'Summer Internships', 'description': 'Work in a research lab or chemical plant.', 'checklist': ['Biotech Lab', 'Manufacturing Plant'] }
                            ]
                        }
                    ]
                }
            }
        }
    },
    {
        'id': '5',
        'position': { 'x': 180, 'y': 250 },
        'data': {
            'label': 'Master\'s',
            'nodeName': 'masters',
            'nodeType': 'alternative',
            'description': 'A master’s degree allows for specialization and higher-level roles in process engineering.',
            'completionLabel': 'I’ve finished a master’s degree',
            'dataRows': [
                { 'title': 'Years of study', 'subTitle': '2 years', 'iconName': 'calendar-clock' },
                { 'title': 'Required credits to complete', 'subTitle': '30-36', 'iconName': 'hash' }
            ],
            'guide': {
                'overview': 'Master’s programs focus on advanced process control, optimization, and system design.',
                'details': {
                    'description': 'Complete advanced coursework, research projects, and internships in fields like sustainable engineering or automation.',
                    'requiredSubjects': [
                        {
                            'title': 'Core subjects',
                            'description': 'Specialize in process control, optimization, and system design.',
                            'subjects': [
                                { 'title': 'Advanced Process Design', 'description': 'Focus on optimization and process improvement.', 'checklist': ['Optimization of Chemical Processes'] }
                            ]
                        },
                        {
                            'title': 'Specialization Electives',
                            'description': 'Deepen your expertise with electives in areas like renewable energy and bioprocess engineering.',
                            'subjects': [
                                { 'title': 'Renewable Energy Systems', 'description': 'Sustainable engineering practices.', 'checklist': ['Bioprocess Engineering', 'Energy and Environmental Systems Modeling'] }
                            ]
                        }
                    ]
                }
            }
        }
    },
    {
        'id': '6',
        'position': { 'x': 120, 'y': 400 },
        'data': {
            'label': 'Entry-level Jobs',
            'nodeName': 'entry-level-jobs',
            'nodeType': 'typical',
            'description': 'After completing your degree and internship, you’ll be ready to start your career as a process engineer.',
            'completionLabel': 'I’ve started my career',
            'dataRows': [
                { 'title': 'Job roles', 'subTitle': 'Process Engineer, Manufacturing Engineer', 'iconName': 'career-subjects' },
                { 'title': 'Industries', 'subTitle': 'Pharmaceuticals, manufacturing, renewable energy', 'iconName': 'career-industry' }
            ],
            'guide': {
                'overview': 'Process engineers play a key role in optimizing production systems across various industries.',
                'details': {
                    'description': 'You’ll work in industries ranging from pharmaceuticals to renewable energy, solving complex challenges in process control and optimization.',
                    'requiredSubjects': [
                        {
                            'title': 'Core skills',
                            'description': 'Problem-solving, process optimization, and system design are key for entry-level roles.',
                            'subjects': [
                                { 'title': 'Process Optimization', 'description': 'Design and refine systems for efficiency.', 'checklist': ['CAD', 'Process Automation'] }
                            ]
                        }
                    ]
                }
            }
        }
    },
    {
        'id': '7',
        'position': { 'x': 130, 'y': 500 },
        'data': {
            'label': 'Mid-Level Jobs',
            'nodeName': 'mid-level-jobs',
            'nodeType': 'typical',
            'description': 'After gaining experience, you can advance to mid-level positions in process engineering, focusing on larger projects and leadership roles.',
            'completionLabel': 'I’ve advanced to a mid-level position',
            'dataRows': [
                { 'title': 'Years of experience', 'subTitle': '2 to 5 years', 'iconName': 'calendar-clock' },
                { 'title': 'Job roles', 'subTitle': 'Senior Process Engineer, Project Manager', 'iconName': 'career-subjects' }
            ],
            'guide': {
                'overview': 'Mid-level roles involve managing complex projects, optimizing large-scale systems, and leading teams.',
                'details': {
                    'description': 'These roles require a deep understanding of process engineering and leadership skills, allowing you to take on more responsibilities and larger-scale projects.',
                    'requiredSubjects': [
                        {
                            'title': 'Core skills',
                            'description': 'Leadership, project management, and advanced process control are essential at this level.',
                            'subjects': [
                                { 'title': 'Project Management', 'description': 'Lead teams and manage projects to optimize processes.', 'checklist': ['Lean Manufacturing', 'Six Sigma'] }
                            ]
                        }
                    ]
                }
            }
        }
    }
];

const biologicalTechnicianEdges = [
    {
        id: '1-2',
        source: '1',
        target: '2',
        style: { stroke: '#80D2C9' },
    },
    {
        id: '1-4',
        source: '1',
        target: '4',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '2-3',
        source: '2',
        target: '3',
        style: { stroke: '#80D2C9' },
    },
    {
        id: '2-4',
        source: '2',
        target: '4',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '3-5',
        source: '3',
        target: '5',
        style: { stroke: '#80D2C9' },
    },
    {
        id: '4-3',
        source: '4',
        target: '3',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '4-5',
        source: '4',
        target: '5',
        style: { stroke: '#CCD5EC' },
    },
];

const processEngineertEdges = [
    {
        id: '1-2',
        source: '1',
        target: '2',
        style: { stroke: '#80D2C9' },
    },
    {
        id: '2-3',
        source: '2',
        target: '3',
        style: { stroke: '#80D2C9' },
    },
    {
        id: '3-4',
        source: '3',
        target: '4',
        style: { stroke: '#80D2C9' },
    },
    {
        id: '1-5',
        source: '1',
        target: '5',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '5-6',
        source: '5',
        target: '6',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '2-6',
        source: '2',
        target: '6',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '5-2',
        source: '5',
        target: '2',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '6-3',
        source: '6',
        target: '3',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '2-7',
        source: '2',
        target: '7',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '7-8',
        source: '7',
        target: '8',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '7-3',
        source: '7',
        target: '3',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '7-4',
        source: '7',
        target: '4',
        style: { stroke: '#CCD5EC' },
    },
    {
        id: '8-4',
        source: '8',
        target: '4',
        style: { stroke: '#CCD5EC' },
    },
];

type MAPPING_TYPE = {
    [key: string]: any;
};

const addClassToNodes = (nodes: any) => {
    return nodes.map((node: any) => ({...node, className: node.data.nodeType}));
};

export const CAREERS_NODES_MAPPING: MAPPING_TYPE = {
    'process-engineer': addClassToNodes(processEngineertNodes),
    'biological-technician': addClassToNodes(biologicalTechnicianNodes),
    'data-analyst': [],
    botanist: [],
};

export const CAREERS_EDGES_MAPPING: MAPPING_TYPE = {
    'process-engineer': processEngineertEdges,
    'biological-technician': biologicalTechnicianEdges,
    'data-analyst': [],
    botanist: [],
};
