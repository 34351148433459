import bind from 'bind-decorator';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { intl } from 'i18n';

import { Thread } from 'labxchange-client';

import { ThreadPinStatusEnum, getThreadIconName } from 'discussions/models';
import { PlainText } from 'elements/components/RichText';
import { Icon, KebabMenu, KebabMenuItem } from 'ui/components';
import messages from 'discussions/displayMessages';


interface StatsProps {
    likeCount?: number;
    postCount?: number;
    viewCount?: number;
    participantCount?: number;
}

export class ThreadCardStats extends React.PureComponent<StatsProps> {
    public render() {
        return (
            <div className='thread-card-stats'>

                {this.props.likeCount !== undefined ?
                    <div
                        className='thread-card-counter'
                        aria-label={intl.formatMessage(messages.threadStatsLikesAria)}
                    >
                        <Icon name='heart' zoom='1.2em'/>
                        {this.props.likeCount}
                    </div>
                : null}
                {this.props.postCount !== undefined ?
                    <div
                        className='thread-card-counter'
                        aria-label={intl.formatMessage(messages.threadStatsPostsAria)}
                    >
                        <Icon name='comment-discussion' zoom='1.2em'/>
                        {this.props.postCount}
                    </div>
                : null}
                {this.props.viewCount !== undefined ?
                    <div
                        className='thread-card-counter'
                        aria-label={intl.formatMessage(messages.threadStatsViewsAria)}
                    >
                        <Icon name='eye' zoom='1.2em'/>
                        {this.props.viewCount}
                    </div>
                : null}
                {this.props.participantCount !== undefined ?
                    <div
                        className='thread-card-counter'
                        aria-label={intl.formatMessage(messages.threadStatsParticipantsAria)}
                    >
                        <Icon name='person' zoom='1.2em'/>
                        {this.props.participantCount}
                    </div>
                : null}
            </div>
        );
    }
}

export interface ThreadCardProps {
    thread: Thread;
    threadLink: string;
    onPin?(threadId: number, pinned: boolean): void;
    onDelete?(threadId: number): void;
}

interface State {
    closeActionsMenu: boolean;
}

export class ThreadCard extends React.PureComponent<ThreadCardProps, State> {
    constructor(props: ThreadCardProps) {
        super(props);
        this.state = {
            closeActionsMenu: false,
        };
    }

    public render() {
        const thread = this.props.thread;
        let classNames = 'thread-card';
        if (thread.pinStatus !== ThreadPinStatusEnum.Default) {
            classNames += ' thread-card-pinned';
        }
        return (
            <NavLink
                className={`unstyled ${classNames}`}
                to={this.props.threadLink}
            >
                {this.renderStatus()}
                <div className='thread-card-heading'>
                    <div className='thread-card-heading-title'>{thread.title}</div>
                    <div className='thread-card-heading-latest-reply'>
                        <strong>Latest reply</strong>
                        : <PlainText innerHtml={thread.latestReply} />
                    </div>
                </div>
                <ThreadCardStats
                    likeCount={thread.likeCount}
                    postCount={thread.postCount}
                    viewCount={thread.viewCount}
                    participantCount={thread.participantCount}
                />
                {this.renderActions()}
            </NavLink>
        );
    }

    private renderStatus() {
        const iconName = getThreadIconName(this.props.thread);
        return <div className='thread-card-status'>
            {iconName ? <Icon name={iconName} zoom='1.2em'/> : null}
        </div>;
    }

    private renderActions() {
        const thread = this.props.thread;
        const actions = [];

        if (thread.permissions?.canPinThreadObject && this.props.onPin) {
            switch (thread.pinStatus) {
                case ThreadPinStatusEnum.Default: {
                    actions.push(<KebabMenuItem
                        key='pin'
                        iconName='pin'
                        message={messages.actionPin}
                        onClick={this.onPin}
                        disabled={false}
                    />);
                    break;
                }
                case ThreadPinStatusEnum.Pinned: {
                    actions.push(<KebabMenuItem
                        key='unpin'
                        iconName='pin'
                        iconOutline={true}
                        message={messages.actionUnpin}
                        onClick={this.onUnpin}
                        disabled={false}
                    />);
                    break;
                }
            }
        }

        if (this.props.onDelete && thread.permissions?.canDeleteThreadObject) {
            actions.push(<KebabMenuItem
                key='trashcan'
                iconName='trashcan'
                message={messages.discussionsPostDelete}
                disabled={false}
                onClick={this.onDelete}
            />);
        }

        if (actions.length <= 0) { return null; }
        return <div className='thread-card-actions'>
            <KebabMenu forceClose={this.state.closeActionsMenu}>
                {actions}
            </KebabMenu>
        </div>;
    }

    @bind private onPin() {
        if (this.props.onPin) {
            this.props.onPin(this.props.thread.id, true);
        }
        this.closeActionsMenu();
    }

    @bind private onUnpin() {
        if (this.props.onPin) {
            this.props.onPin(this.props.thread.id, false);
        }
        this.closeActionsMenu();
    }

    @bind private onDelete() {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.thread.id);
        }
        this.closeActionsMenu();
    }

    @bind private closeActionsMenu(){
        this.setState({closeActionsMenu: true}, () => {
            this.setState({closeActionsMenu: false});
        });
    }
}
