import { ClassroomsApi } from 'global/api';
import { LXThunkAction } from 'global/types';
import { Classroom } from 'labxchange-client';
import { Action } from 'redux';

export enum Types {
    FETCH_CLASSROOMS_REQUEST = 'FETCH_CLASSROOMS_REQUEST',
    FETCH_CLASSROOMS_SUCCESS = 'FETCH_CLASSROOMS_SUCCESS',
    FETCH_CLASSROOMS_FAILURE = 'FETCH_CLASSROOMS_FAILURE',
}

export interface FetchClassroomsRequest extends Action {
    readonly type: Types.FETCH_CLASSROOMS_REQUEST;
}

export interface FetchClassroomsSuccess extends Action {
    readonly type: Types.FETCH_CLASSROOMS_SUCCESS;
    readonly classrooms: Classroom[];
}

export interface FetchClassroomsFailure extends Action {
    readonly type: Types.FETCH_CLASSROOMS_FAILURE;
    readonly err: any;
}

export type ActionTypes =
    | FetchClassroomsRequest
    | FetchClassroomsSuccess
    | FetchClassroomsFailure;

export const fetchClassrooms = (): LXThunkAction<void> =>
    async (dispatch) => {

        dispatch({
            type: Types.FETCH_CLASSROOMS_REQUEST,
        });

        try {
            const classrooms = await ClassroomsApi.listAsEducator({});

            dispatch({
                type: Types.FETCH_CLASSROOMS_SUCCESS,
                classrooms,
            });
        } catch (err) {
            dispatch({
                type: Types.FETCH_CLASSROOMS_FAILURE,
                err,
            });
        }
    };
