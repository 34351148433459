// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ItemProgress
 */
export interface ItemProgress {
    /**
     * 
     * @type {string}
     * @memberof ItemProgress
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemProgress
     */
    itemId: string;
    /**
     * 
     * @type {string}
     * @memberof ItemProgress
     */
    type: ItemProgressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemProgress
     */
    title: string;
    /**
     * Number of child items of this item.
     * @type {number}
     * @memberof ItemProgress
     */
    itemCount?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof ItemProgress
     */
    readonly children?: Array<any>;
    /**
     * 
     * @type {Date}
     * @memberof ItemProgress
     */
    assignedDate: Date;
    /**
     * 
     * @type {number}
     * @memberof ItemProgress
     */
    weightedEarned?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemProgress
     */
    weightedPossible?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemProgress
     */
    completion?: number;
    /**
     * 
     * @type {Date}
     * @memberof ItemProgress
     */
    completionDate?: Date;
}

export function ItemProgressFromJSON(json: any): ItemProgress {
    return {
        'id': json['id'],
        'itemId': json['item_id'],
        'type': json['type'],
        'title': json['title'],
        'itemCount': !exists(json, 'item_count') ? undefined : json['item_count'],
        'children': !exists(json, 'children') ? undefined : json['children'],
        'assignedDate': new Date(json['assigned_date']),
        'weightedEarned': !exists(json, 'weighted_earned') ? undefined : json['weighted_earned'],
        'weightedPossible': !exists(json, 'weighted_possible') ? undefined : json['weighted_possible'],
        'completion': !exists(json, 'completion') ? undefined : json['completion'],
        'completionDate': !exists(json, 'completion_date') ? undefined : new Date(json['completion_date']),
    };
}

export function ItemProgressToJSON(value?: ItemProgress): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'item_id': value.itemId,
        'type': value.type,
        'title': value.title,
        'item_count': value.itemCount,
        'assigned_date': value.assignedDate.toISOString(),
        'weighted_earned': value.weightedEarned,
        'weighted_possible': value.weightedPossible,
        'completion': value.completion,
        'completion_date': value.completionDate === undefined ? undefined : value.completionDate.toISOString(),
    };
}

/**
* @export
* @enum {string}
*/
export enum ItemProgressTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}


