import * as React from 'react';
import { defineMessages } from 'react-intl';

import AnswerTypeSetting from './AnswerTypeSetting';
import HintSetting from './HintSetting';
import ScorringSetting from './ScoringSetting';
import { doAutoSize } from "../../Utils";
import { intl } from 'i18n';

const messages = defineMessages({
    title: {
        id: 'SingleAdvansedSettings.title',
        defaultMessage: 'Advanced settings'
    },
    closeSettings: {
        id: 'SingleAdvansedSettings.closeSettings',
        defaultMessage: 'Hide advanced options'
    },
    showSettings: {
        id: 'SingleAdvansedSettings.showSettings',
        defaultMessage: 'Show advanced options'
    }
})

export default class SingleAdvancedSettings extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            advancedSettingsOpenned: false
        };
    }

    switchAdvancedSettings() {
        this.setState({
            advancedSettingsOpenned: !this.state.advancedSettingsOpenned
        }, () => {
            doAutoSize();
        });
    }

    render() {
        return (
            <div className='lxc-advanced-settings-wrapper'>
                <div className={`lxc-advanced-settings ${this.state.advancedSettingsOpenned ? 'lxc-advanced-settings_open' : ''}`}>
                    <div className='lxc-advanced-settings-title'>
                        {intl.formatMessage(messages.title)}
                    </div>
                    <AnswerTypeSetting
                        answerTypeSelectedOption={this.props.answerTypeSelectedOption}
                        answerTypeOptions={this.props.answerTypeOptions}
                        answerTypeChange={this.props.answerTypeChange}
                    />
                    <HintSetting
                        hints={this.props.hints}
                        hintAdd={this.props.hintAdd}
                        hintRemove={this.props.hintRemove}
                        hintChange={this.props.hintChange}
                    />
                    <ScorringSetting
                        scorringSelectedPointOption={this.props.scorringSelectedPointOption}
                        scorringselectedAttemptsOption={this.props.scorringselectedAttemptsOption}
                        scorringattemptsOptions={this.props.scorringattemptsOptions}
                        scorringPointsOptions={this.props.scorringPointsOptions}
                        scorringAttemptsChange={this.props.scorringAttemptsChange}
                        scorringPointsChange={this.props.scorringPointsChange}
                    />
                </div>
                <button type='button' className='lxc-show-advanced-settings' onClick={this.switchAdvancedSettings.bind(this)}>
                    {this.state.advancedSettingsOpenned
                        ? intl.formatMessage(messages.closeSettings)
                        : intl.formatMessage(messages.showSettings)}
                    <span className='lxc-show-advanced-settings-icon'></span>
                </button>
            </div>
        );
    }
}
