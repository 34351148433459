import { intl } from 'i18n';
import * as React from 'react';
import { AuthPageLayout, Button, TextInput } from 'ui/components';
import messages from '../displayMessages';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import useAddQueryParam, { ProgressPercentageEnum } from '../../utils';

interface Props extends React.PropsWithChildren {
    onEnterClassCode: (code: string) => void;
    onSkip?: () => void;
    invalidClassCode: boolean;
    onInvalidClassCodeChange: (invalid: boolean) => void;
    completeOnboarding: () => void;
}

export const JoinClassView: React.FunctionComponent<Props> = (props) => {

    React.useEffect(() => {
        analyticsInstance.track(EVENT_NAMES.OnboardingJoinClassViewed, { url: window.location.toString() });
    });

    const [classCode, setClassCode] = React.useState('');
    useAddQueryParam('step', 'JoinClass');

    const onChangeValue = (value: string) => {
        setClassCode(value);
        if (props.invalidClassCode) {
            props.onInvalidClassCodeChange(false);
        }
    };

    const onClick = () => {
        if (classCode)
            props.onEnterClassCode(classCode);
    };
    return (
        <AuthPageLayout
            imageUrl='/assets/images/auth-layouts/roles.svg'
            title={intl.formatMessage(messages.lxAuthJoinAClassViewTitle)}
            onClickLogo={props.completeOnboarding}
            progressPercentage={ProgressPercentageEnum.JoinAClass}
        >

            <div className='join-class-container'>
                <h5>{intl.formatMessage(messages.lxAuthJoinAClassViewSubHeading)}</h5>
                <div className='description'>
                    {intl.formatMessage(messages.lxAuthJoinAClassViewDescription)}
                </div>

                <TextInput
                    className='class-code'
                    inputKey='class-code'
                    value={classCode}
                    onChangeValue={onChangeValue}
                    type='text'
                    placeholder={messages.lxAuthClassCodeTextPlaceholder}
                    invalid={props.invalidClassCode}
                    feedbackMessage={props.invalidClassCode ? messages.lxAuthInavlidClassCode : messages.lxAuthClassCodeHelpText}
                />
                <div className='buttons-container'>
                    <Button
                        className='skip-button'
                        label={messages.lxAuthSkipClassCode}
                        btnStyle='link'
                        onClick={props.onSkip}
                    />
                    <Button
                        className='first role-done-btn'
                        label={messages.lxAuthRequestToJoinLabel}
                        btnStyle='primary'
                        disabled={classCode.length !== 6 }
                        onClick={onClick}
                    />
                </div>
            </div>
        </AuthPageLayout>
    );
};
