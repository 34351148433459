import * as React from 'react';
import { defineMessages } from 'react-intl';
import { HtmlTextBox } from 'ui/components';
import { intl } from 'i18n';

const messages = defineMessages({
    placeholder: {
        id: 'HintSettingItem.placeholder',
        defaultMessage: 'Add a hint'
    },
    ariaLabel: {
        id: 'HintSettingItem.ariaLabel',
        defaultMessage: 'Remove hint item'
    }
});

export default class HintSettingItem extends React.Component<any, any> {

    private hintTextarea: any;

    constructor(props: any) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidMount() {
        this.setState({ ...this.props });
    }

    private change(value: string) {
        this.setState({
            value: value
        }, () => this.props.hintChange(this.state));
    }

    remove(event: any) {
        this.props.hintRemove(this.props.id);
    }

    render() {
        const { formatMessage } = intl;

        return (
            <div className='lxc-advanced-hint-wrapper'>
                <div className='lxc-advanced-hint-block'>
                    <HtmlTextBox
                        label={formatMessage(messages.placeholder)}
                        defaultValue={this.props.value}
                        onChange={(text: string) => this.change(text)}
                        editorStyle={HtmlTextBox.EditorStyle.AssesmentEditor}
                        ref={c => (this.hintTextarea = c)}
                        options={{ forced_root_block: false, body_class: 'wysiwyg-select-body' }}
                        extraClassName="wysiwyg-select"
                    />
                    <button className='lxc-answers-remove-btn' type='button' aria-label={formatMessage(messages.ariaLabel)} onClick={this.remove.bind(this)} />
                </div>
            </div>
        )
    }
}
