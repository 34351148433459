import { ROUTES } from 'global/constants';
import * as React from 'react';
import { CheckBox } from 'ui/components/CheckBox';
import { WrappedMessage } from 'utils';
import messages from '../displayMessages';
import footerMessages from 'ui/components/Footer/displayMessages';

interface Props {
    isChecked: boolean;
    onClick: (newEvent: boolean) => void;
    showErrors: boolean;
}

export const CopyrightAcknowledgement: React.FC<Props> = (props) => {
    const isError = !props.isChecked && props.showErrors;

    return (
        <div className='copyright-agreement-field'>
        <CheckBox isError={isError} checked={props.isChecked} onClick={props.onClick}>
            <WrappedMessage message={messages.uiCopyrightAgreement} values={{
                termsOfService: (
                    <a href={ROUTES.General.TOS} target='_blank' rel='noopener noreferrer'>
                        <WrappedMessage message={footerMessages.footerTermsOfService}/>
                    </a>
                ),
                privacyPolicy: (
                    <a href={ROUTES.General.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer'>
                        <WrappedMessage message={footerMessages.footerPrivacyPolicy}/>
                    </a>
                ),
            }}/>
        </CheckBox>
        </div>
    );
};
