import * as React from 'react';
import { showErrorMessage } from 'ui/components';
import { Redirect } from 'react-router';
import messages from '../displayMessages';
import { WrappedMessage } from 'utils';
import { showLoginModal } from './dispatch';
import { ROUTES } from 'global/constants';

export class LXLoginView extends React.PureComponent<{}> {
    /*
     * I used this view to show the login modal when the ENABLE_LX_LOGIN
     * var is on debug. Only is used to show some errors on login or sign up.
     */

    public render() {
        const errorParam = this.getErrorParam();
        const isFullPageView = window.location.pathname === ROUTES.General.LOG_IN;

        if (errorParam) {
            if (errorParam === 'social_sign_up') {
                showErrorMessage(<WrappedMessage message={messages.lxAuthAccountAlreadyCreatedMsg} />);
            }
            else if (errorParam === 'login_cancelled') {
                showErrorMessage(<WrappedMessage message={messages.lxAuthSocialLoginCanceled} />);
            }
        }

        showLoginModal();
        if(!isFullPageView){
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        } else
            return <Redirect to={ROUTES.General.LOG_IN} push={true} />;
    }

    private getErrorParam() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('error');
    }
}
