import { defineMessages } from 'react-intl';

const messages = defineMessages({
    membershipBoxHeading: {
        id:'membershipBoxHeading',
        defaultMessage: 'Learners ({learnerCount})',
        description: 'Text for heading in learners box on classrooms content tab for educators, class tab for learners',
    },
});

export default messages;
