// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    SectionSlab,
    SectionSlabFromJSON,
    SectionSlabToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExploreSection
 */
export interface ExploreSection {
    /**
     * 
     * @type {string}
     * @memberof ExploreSection
     */
    title: string;
    /**
     * A unique human-readable name, e.g., 'biology-middle-cells'
     * @type {string}
     * @memberof ExploreSection
     */
    slug: string;
    /**
     * 
     * @type {number}
     * @memberof ExploreSection
     */
    order: number;
    /**
     * 
     * @type {Array<SectionSlab>}
     * @memberof ExploreSection
     */
    readonly sectionSlabs?: Array<SectionSlab>;
}

export function ExploreSectionFromJSON(json: any): ExploreSection {
    return {
        'title': json['title'],
        'slug': json['slug'],
        'order': json['order'],
        'sectionSlabs': !exists(json, 'section_slabs') ? undefined : (json['section_slabs'] as Array<any>).map(SectionSlabFromJSON),
    };
}

export function ExploreSectionToJSON(value?: ExploreSection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'slug': value.slug,
        'order': value.order,
    };
}


