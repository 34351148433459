import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import { InitiativesApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { StandardPageLayout, Spinner } from 'ui/components';

import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

type Props = RouteComponentProps<{key: string}>;

interface State {
    url?: string;
}

/**
 * Initiative redirect page.
 * This pulls an initiative from the backend and redirects to it.
 */
export class InitiativePage extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            url: undefined,
        };
    }

    async componentDidMount() {
        let data;
        const initiativeKey = this.props.match.params.key;
        try {
            data = await InitiativesApi.read({ id: initiativeKey });
        } catch(err) {
            this.setState({ url: ROUTES.All.HOME });
            analyticsInstance.track(EVENT_NAMES.InitiativeRedirectFailure, {
                initiative_key: initiativeKey,
                url: window.location.toString(),
            });
            return;
        }

        const redirectUrl = data.redirectUrl;

        this.setState({ url: redirectUrl });
        analyticsInstance.track(EVENT_NAMES.InitiativeRedirectSuccess, {
            initiative_key: initiativeKey,
            redirect_url: redirectUrl,
            url: window.location.toString(),
        });
    }

    render() {
        const url = this.state.url;
        if (url !== undefined) {
            // redirect manually if absolute URL
            // react-router does not support redirecting toabsolute URLs out of the box
            if (/^https?:\/\//.test(url)) {
                window.location.href = url;
                return;
            }
            return <Redirect to={url} />;
        }

        return (
            <StandardPageLayout>
                <Spinner />
            </StandardPageLayout>
        );
    }
}

