import * as React from 'react';

import { Funder } from 'labxchange-client';
import { WrappedMessage } from 'utils';
import messages from 'clusters/displayMessages';

interface Props {
    funders: Funder[];
    funderBackgroundImageUrl?: string;
}

export class Funders extends React.PureComponent<Props> {

    public render() {
        const { funders, funderBackgroundImageUrl } = this.props;
        if (funders.length === 0) {
            return null;
        }
        return (
            <div
                className='funders'
                style={{backgroundImage: `url(${funderBackgroundImageUrl})`}}
            >
                <p className='funders-title'>
                    <WrappedMessage message={messages.clusterPageFunders} />
                </p>
                <div className={`flex ${funders.length === 1 && 'single'}`}>
                    {funders.map((funder: Funder, index: number) => (
                        <img
                            className='funder-item'
                            src={funder.largeLogoUrl} alt={funder.name}
                            key={`funder-item-${index}`}
                        />
                    ))}
                </div>
            </div>
        );
    }

}
