// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClassroomItemOrder,
    ClassroomItemOrderFromJSON,
    ClassroomItemOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomItemOrderRequest
 */
export interface ClassroomItemOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassroomItemOrderRequest
     */
    classroom: string;
    /**
     * 
     * @type {Array<ClassroomItemOrder>}
     * @memberof ClassroomItemOrderRequest
     */
    items: Array<ClassroomItemOrder>;
}

export function ClassroomItemOrderRequestFromJSON(json: any): ClassroomItemOrderRequest {
    return {
        'classroom': json['classroom'],
        'items': (json['items'] as Array<any>).map(ClassroomItemOrderFromJSON),
    };
}

export function ClassroomItemOrderRequestToJSON(value?: ClassroomItemOrderRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'classroom': value.classroom,
        'items': (value.items as Array<any>).map(ClassroomItemOrderToJSON),
    };
}


