import {ItemProgress, MentorshipItem} from 'labxchange-client';

export const mentorshipItemsToItemsProgress = (mentorshipItems: MentorshipItem[]): ItemProgress[] => {
    return mentorshipItems.map((mentorshipItem) => {
        return {
            id: mentorshipItem.id,
            itemId: mentorshipItem.item.metadata.id,
            type: mentorshipItem.item.metadata.type as any,
            title: mentorshipItem.item.metadata.title,
            itemCount: mentorshipItem.item.metadata.itemCount,
            assignedDate: mentorshipItem.created,
            weightedEarned: mentorshipItem.item.userAttributes.weightedEarned,
            weightedPossible: mentorshipItem.item.userAttributes.weightedPossible,
            completion: mentorshipItem.item.userAttributes.completion,
            completionDate: mentorshipItem.item.userAttributes.completionDate,
            children: mentorshipItem.children ? mentorshipItem.children : []
        };
    });
};
