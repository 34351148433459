import update from 'immutability-helper';

import * as LoginActions from 'auth/actions';
import * as ClassroomActions from 'classrooms/actions';
import {ClassroomsModel} from './models';

export const initialState: Readonly<ClassroomsModel> = {
    unarchived: [],
    archived: [],
    loading: false,
};

/**
 * Reducer that keeps a cache of classrooms, handles basic fetch list
 * Drops them in two separate buckets: unarchived, and archived
 * Clears state when user is not authenticated
 */

export function classroomsReducer(
    state = initialState,
    action: ClassroomActions.ActionTypes | LoginActions.ActionTypes,
): ClassroomsModel {
    switch (action.type) {
        case ClassroomActions.Types.FETCH_CLASSROOMS_REQUEST:
            return update(state, {loading: {$set: true}});

        case ClassroomActions.Types.FETCH_CLASSROOMS_FAILURE:
            return update(state, {loading: {$set: false}});

        case ClassroomActions.Types.FETCH_CLASSROOMS_SUCCESS:
            return update(state, {
                unarchived: {$set: action.classrooms.filter((c) => c.archived === false)},
                archived: {$set: action.classrooms.filter((c) => c.archived === true)},
                loading: {$set: false},
            });

        case LoginActions.Types.LOGIN_FAILURE:
        case LoginActions.Types.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}
