import { defineMessages } from 'react-intl';

const messages = defineMessages({
    userProfileMentorship: {
        id:'userProfileMentorship',
        defaultMessage: 'Mentorship',
        description: 'Mentorship user profile section for other user.',
    },
    userProfileMentorshipApplyButton: {
        id:'userProfileMentorshipApplyButton',
        defaultMessage: 'Apply for mentorship',
        description: 'Apply for mentorship button.',
    },
    userProfileMentorshipAcceptButton: {
        id:'userProfileMentorshipAcceptButton',
        defaultMessage: 'Yes, I will mentor {fullName}',
        description: 'Accept mentorship button.',
    },
    userProfileMentorshipCancelButton: {
        id:'userProfileMentorshipCancelButton',
        defaultMessage: 'Cancel mentorship',
        description: 'Cancel mentorship button.',
    },
    userProfileMentorshipRefuseButton: {
        id:'userProfileMentorshipRefuseButton',
        defaultMessage: 'No, I decline to mentor {fullName}',
        description: 'Refuse mentorship button.',
    },
    applySuccessTitle: {
        id:'applySuccessTitle',
        defaultMessage: 'Applied for mentorship',
        description: 'Apply success title.',
    },
    applySuccessMessage: {
        id:'applySuccessMessage',
        defaultMessage: ' will review your application and let you know if they can mentor you.',
        description: 'Apply success message.',
    },
    applySuccessBackToProfileButton: {
        id:'applySuccessBackToProfileButton',
        defaultMessage: 'Back to {fullName}\'s profile',
        description: 'Apply success back to profile button.',
    },
    onFailedToApply: {
        id:'onFailedToApply',
        defaultMessage: 'There was an error while sending your request for mentorship. Please try again later.',
        description: 'Error message when trying to send mentorship apply.',
    },
    confirmButtonText: {
        id:'confirmButtonText',
        defaultMessage: 'Confirm',
        description: 'Text for confirm button',
    },
    cancelMentorshipRequestTitle: {
        id:'cancelMentorshipRequestTitle',
        defaultMessage: 'Are you sure you want to cancel the mentorship request?',
        description: 'Cancel mentorship request modal title',
    },
});

export default messages;
