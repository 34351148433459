import { bind } from 'bind-decorator';
import { getItemTypeMeta, ItemType } from 'items/models';
import { AnnotatedVideoAnnotation } from 'labxchange-client';
import * as React from 'react';
import * as UI from 'ui/components';
import { WrappedMessage } from 'utils';
import videojs from 'video.js';

import { AnnotationsList, AnnotationsListSkeleton } from './AnnotationsList';
import messages from './displayMessages';
import { TagsFilter } from './TagsFilter';
import { getTags } from './utils';
import { intl } from 'i18n';

interface State {
    isExtended: boolean;
    showTags: boolean;
    selectedTags: string[];
    editable: boolean;
    isEditingAnnotation: boolean;
}

interface Props {
    isSaving?: boolean;
    isLoading?: boolean;
    editable?: boolean;
    title?: string;
    annotations: AnnotatedVideoAnnotation[];
    player?: videojs.Player;
    ownerName?: string;
    ownerUrl?: string;
    ownerOrgId?: string;
    videoUrl?: string;
    videoAuthorName?: string;
    videoAuthorUrl?: string;
    videoAuthorLogoUrl?: string;
    onOpenQuestion?(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]): void;
    onToggleSidebar(): void;
    onSaveAnnotation?(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined): void;
    onDeleteAnnotation?(annotation: AnnotatedVideoAnnotation): void;
    onSave?(): void;
    onStartAnnotationForm?(annotation?: AnnotatedVideoAnnotation): void;
    onStartQuestionForm?(questionTime?: number): void;
    onStartAnnotationEdition?(startTimer: React.RefObject<UI.TimerInput>, endTimer: React.RefObject<UI.TimerInput>): void;
    onStartQuestionEdition?(startTimer: React.RefObject<UI.TimerInput>): void;
    onChangeAnnotationTimes?(isLeft: boolean, newTime: number): void;
    onChangeQuestionTime?(newTime: number): void;
    deletePaddles?():void;
    deleteQuestionPaddle?():void;
    isMobileView?: boolean;
    selectedTags?: string[];
    onUpdateTags?(selectedTags: string[]): void;
    isHorizontalList?: boolean;
    onOpenAnnotationMore?(annotation: AnnotatedVideoAnnotation): void;
    /// This gets a reference of the annotation list in this component
    onStartAnnotationList?(annotationListRef: React.RefObject<AnnotationsList>): void;
    /// Adds extra functionality to 'Watch clip' button in all annotations
    onAnnotationWatch?(): void;
    loggedInUsername?: string;
    toggleEditMode?(): void;
    hideQuestionSideBar?(): void;
}


export class AnnotationsSidebar extends React.PureComponent<Props, State> {

    private annotationListRef: React.RefObject<AnnotationsList>;

    constructor(props: Props) {
        super(props);
        this.state = {
            isExtended: true,
            showTags: false,
            selectedTags: [],
            editable: this.props.editable || false,
            isEditingAnnotation: false,
        };
        this.annotationListRef = React.createRef();
    }

    private getItemType() {
        return getItemTypeMeta(ItemType.AnnotatedVideo).name;
    }

    public componentDidMount() {
         if (this.props.onStartAnnotationList) { this.props.onStartAnnotationList(this.annotationListRef); }
    }

    public componentDidUpdate() {
        if (this.props.selectedTags !== undefined) {
            this.setState({selectedTags: this.props.selectedTags});
        }
    }

    @bind private updateEditable() {
        this.setState({editable: !this.state.editable});
        if(this.props.toggleEditMode) {
            this.props.toggleEditMode();
        }
    }

    public render() {
        const editable = this.props.editable;
        const tags = getTags(this.props.annotations);
        return (
            <div tabIndex={-1} className={`annotated-video-sidebar
                ${this.state.editable ? 'annotated-video-sidebar-edit' : ''}
                ${!this.state.isExtended ? 'annotated-video-sidebar-hidden' : ''}
                ${this.props.isLoading ? 'annotated-video-sidebar-loading' : ''}
                ${this.props.isMobileView ? 'annotated-video-sidebar-mobile' : ''}
            `}>
                {!this.props.isMobileView && <div
                  className='annotated-video-sidebar-header' role='banner'>
                    <button
                        aria-label='Annotations icon'
                        className={
                            `annotated-video-sidebar-header-icon unstyled
                            ${this.state.isExtended
                                ? ''
                                : 'annotated-video-sidebar-header-icon-collapse'}
                            `
                        }
                        onClick={this.toggleExtended}
                        tabIndex={0}>
                    </button>
                    <div className={`annotated-video-sidebar-header-box ${!this.state.isExtended ? 'hidden' : ''}`}>
                        <div
                            className='annotated-video-sidebar-header-box-type'
                            tabIndex={-1} aria-label='Annotations section heading.'>
                            <WrappedMessage message={this.getItemType()}/>
                        </div>
                        <div className='annotated-video-sidebar-header-box-title'>
                            {this.props.title}
                        </div>
                    </div>
                    {editable
                    ? (this.state.isExtended && <UI.ToggleSwitch
                          componentStyle='yellow'
                          isChecked={this.state.editable}
                          displayText={intl.formatMessage(messages.editToggleLabel)}
                          onToggleSwitchSelect={() => this.updateEditable()}
                      />
                    )
                    : ( tags.length && <div className={
                        `annotated-video-sidebar-header-search
                        ${!this.state.isExtended ? 'hidden' : ''}
                        ${this.state.showTags
                            ? 'annotated-video-sidebar-header-search-active'
                            : ''}
                        `}>
                        <button aria-label='search' onClick={this.toggleFilterTags} className='unstyled'>
                            <UI.Icon name='search'/>
                        </button>
                    </div>)
                    }
                </div>}
                <div tabIndex={-1} className={
                          `annotated-video-sidebar-body ${this.props.isHorizontalList ? 'horizontal-list' : ''} ${!this.state.isExtended ? 'hidden' : ''} `}>
                    {(this.state.selectedTags.length > 0 && !this.state.showTags) &&
                        <div className={`annotated-video-sidebar-body-selected-tags
                                        ${this.props.isHorizontalList ? 'horizontal-list' : ''}`}>
                            <ul>
                                {this.state.selectedTags.map((tag, index) =>
                                    <li
                                        key={index}
                                        className='annotated-video-tags-filter-tag'>
                                        <button className='unstyled'
                                        onClick={() => this.toggleTag(tag)}>
                                            #{tag}
                                        </button>
                                    </li>
                                )}
                            </ul>
                            <button
                                aria-label={intl.formatMessage(messages.clearAllFilteredTagsButtonLabel)}
                                className='unstyled'
                                onClick={this.clearTags}>
                                <UI.Icon name='x' zoom='x0.75'/>
                            </button>
                        </div>
                    }
                    {!this.props.player
                        ? !this.state.showTags ? <AnnotationsListSkeleton/> : null
                        : <AnnotationsList
                            ref={this.annotationListRef}
                            editable={this.state.editable}
                            show={!this.state.showTags}
                            player={this.props.player}
                            annotations={this.props.annotations}
                            ownerName={this.props.ownerName}
                            ownerUrl={this.props.ownerUrl}
                            ownerOrgId={this.props.ownerOrgId}
                            videoUrl={this.props.videoUrl}
                            videoAuthorName={this.props.videoAuthorName}
                            videoAuthorUrl={this.props.videoAuthorUrl}
                            videoAuthorLogoUrl={this.props.videoAuthorLogoUrl}
                            onSelectAnnotation={this.onSelectAnnotation}
                            selectedTags={this.state.selectedTags}
                            onSelectTag={this.toggleTag}
                            onOpenQuestion={this.onOpenQuestion}
                            onSaveAnnotation={this.props.onSaveAnnotation}
                            onDeleteAnnotation={this.props.onDeleteAnnotation}
                            isSaving={this.props.isSaving}
                            onStartAnnotationForm={this.props.onStartAnnotationForm}
                            onStartQuestionForm={this.props.onStartQuestionForm}
                            onStartAnnotationEdition={this.props.onStartAnnotationEdition}
                            onStartQuestionEdition={this.props.onStartQuestionEdition}
                            onChangeAnnotationTimes={this.props.onChangeAnnotationTimes}
                            onChangeQuestionTime={this.props.onChangeQuestionTime}
                            deletePaddles={this.props.deletePaddles}
                            deleteQuestionPaddle={this.props.deleteQuestionPaddle}
                            isHorizontalList={this.props.isHorizontalList}
                            onOpenAnnotationMore={this.props.onOpenAnnotationMore}
                            onChangeEditState={this.onChangeEditAnnotationState}
                            onWatch={this.props.onAnnotationWatch}
                            loggedInUsername={this.props.loggedInUsername}
                            sideBarIsClosed={!this.state.isExtended}
                        />
                    }
                    {this.state.editable &&
                        <div className={`annotated-video-sidebar-footer ${this.state.isEditingAnnotation ? 'without-button' : ''}`}>
                            {!this.state.isEditingAnnotation &&
                              <>
                                <a
                                  href='https://labxchange.zendesk.com/hc/en-us/requests/new'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='give-feedback-button'
                                >
                                  <WrappedMessage message={messages.giveFeedbackButtonLabel} />
                                </a>

                                <UI.Button
                                  className='save-annotated-video-button'
                                  btnStyle='primary'
                                  label={!this.props.isSaving ? messages.saveAnnotationsButtonLabel : messages.savingAnnotationsButtonLabel}
                                  onClick={this.props.onSave}
                                  disabled={this.props.isSaving}
                                  hasLoader={this.props.isSaving}
                                />
                              </>
                            }
                        </div>
                    }
                    {!this.props.isMobileView &&
                      <TagsFilter
                          show={this.state.showTags}
                          tags={tags}
                          selectedTags={this.state.selectedTags}
                          onSelectTags={this.onSelectTags}
                          onClose={this.toggleFilterTags}
                      />
                    }
                </div>
            </div>
        );
    }

    @bind private toggleTag(tag: string) {
        let selectedTags = [...this.state.selectedTags];
        const index = selectedTags.indexOf(tag);
        if (index > -1) {
            selectedTags.splice(index, 1);
        } else {
            selectedTags = this.state.selectedTags.concat([tag]);
        }
        this.setState({selectedTags});
        if (this.props.onUpdateTags) { this.props.onUpdateTags(selectedTags); }
    }

    @bind private onSelectTags(tags: string[]) {
        this.setState({showTags: false}, () => {
            this.setState({selectedTags: tags});
        });
    }

    @bind private toggleFilterTags() {
        this.setState({showTags: !this.state.showTags});
    }

    @bind private onOpenQuestion(annotation: AnnotatedVideoAnnotation) {
        if (this.props.player) { this.props.player.pause(); }
        if (this.props.onOpenQuestion) { this.props.onOpenQuestion(annotation); }
    }

    @bind private onSelectAnnotation(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]) {
        this.setState({isExtended: true});
        if (this.props.player) {
            if (Array.isArray(annotation)) {
                this.props.player.pause();
                this.setState({isExtended: true});
                if (this.props.onOpenQuestion) {
                    this.props.onOpenQuestion(annotation);
                }
            } else {
                if (annotation.question) {
                    this.props.player.pause();
                    if (this.props.onOpenQuestion) {
                        this.props.onOpenQuestion(annotation);
                    }
                }
            }
        }
    }

    @bind private onChangeEditAnnotationState(editState: boolean) {
        if(editState && this.props.hideQuestionSideBar)
        {
            this.props.hideQuestionSideBar();
        }
        this.setState({isEditingAnnotation: editState});
    }

    @bind private toggleExtended() {
        this.props.onToggleSidebar();
        this.setState({isExtended: !this.state.isExtended});
    }

    @bind private clearTags() {
        this.setState({selectedTags: []});
        if (this.props.onUpdateTags) { this.props.onUpdateTags([]); }
    }

    @bind public updateTags(selectedTags: string[]) {
       this.setState({selectedTags});

       /// Used this function because in some cases the lists were not updated when changing the tags
       this.forceUpdate();
       if (this.annotationListRef.current !== null) {
          this.annotationListRef.current.updateTags(selectedTags);
       }
    }
}
