import { Lottie } from '@crello/react-lottie';
import * as React from 'react';

import animationData from './Spinner.json';
import blackAnimationData from './BlackSpinner.json';

/**
 * An animated loading spinner
 * The animation was built using After Effects and this uses
 * Airbnb's "lottie-web" code to render it (via react-lottie).
 */
export const Spinner: React.FunctionComponent<{size?: string, black?: boolean}> = ({size = '80px', black = false}) => {

    return <Lottie
        height={size}
        width={size}
        config={{
            animationData: black? blackAnimationData : animationData,
            loop: true,
            renderer: 'svg',
        }}
    />;
};
