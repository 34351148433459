// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    LearningObjective,
    LearningObjectiveFromJSON,
    LearningObjectiveToJSON,
} from './';

/**
 * 
 * @export
 * @interface PathwayItemUpdate
 */
export interface PathwayItemUpdate {
    /**
     * 
     * @type {string}
     * @memberof PathwayItemUpdate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayItemUpdate
     */
    relId?: string;
    /**
     * 
     * @type {string}
     * @memberof PathwayItemUpdate
     */
    notes?: string;
    /**
     * 
     * @type {Array<LearningObjective>}
     * @memberof PathwayItemUpdate
     */
    learningObjectives?: Array<LearningObjective>;
    /**
     * 
     * @type {number}
     * @memberof PathwayItemUpdate
     */
    videoStartTime?: number;
    /**
     * 
     * @type {number}
     * @memberof PathwayItemUpdate
     */
    videoStopTime?: number;
}

export function PathwayItemUpdateFromJSON(json: any): PathwayItemUpdate {
    return {
        'id': json['id'],
        'relId': !exists(json, 'rel_id') ? undefined : json['rel_id'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'learningObjectives': !exists(json, 'learning_objectives') ? undefined : (json['learning_objectives'] as Array<any>).map(LearningObjectiveFromJSON),
        'videoStartTime': !exists(json, 'video_start_time') ? undefined : json['video_start_time'],
        'videoStopTime': !exists(json, 'video_stop_time') ? undefined : json['video_stop_time'],
    };
}

export function PathwayItemUpdateToJSON(value?: PathwayItemUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'rel_id': value.relId,
        'notes': value.notes,
        'learning_objectives': value.learningObjectives === undefined ? undefined : (value.learningObjectives as Array<any>).map(LearningObjectiveToJSON),
        'video_start_time': value.videoStartTime,
        'video_stop_time': value.videoStopTime,
    };
}


