// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionAuthor,
    DiscussionAuthorFromJSON,
    DiscussionAuthorToJSON,
    ThreadPostPermissions,
    ThreadPostPermissionsFromJSON,
    ThreadPostPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ThreadPost
 */
export interface ThreadPost {
    /**
     * 
     * @type {number}
     * @memberof ThreadPost
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ThreadPost
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadPost
     */
    username: string;
    /**
     * 
     * @type {DiscussionAuthor}
     * @memberof ThreadPost
     */
    author: DiscussionAuthor;
    /**
     * 
     * @type {string}
     * @memberof ThreadPost
     */
    html: string;
    /**
     * 
     * @type {number}
     * @memberof ThreadPost
     */
    replyTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ThreadPost
     */
    replyCount: number;
    /**
     * 
     * @type {Date}
     * @memberof ThreadPost
     */
    created: Date;
    /**
     * 
     * @type {ThreadPostPermissions}
     * @memberof ThreadPost
     */
    permissions?: ThreadPostPermissions;
}

export function ThreadPostFromJSON(json: any): ThreadPost {
    return {
        'id': json['id'],
        'name': json['name'],
        'username': json['username'],
        'author': DiscussionAuthorFromJSON(json['author']),
        'html': json['html'],
        'replyTo': !exists(json, 'reply_to') ? undefined : json['reply_to'],
        'replyCount': json['reply_count'],
        'created': new Date(json['created']),
        'permissions': !exists(json, 'permissions') ? undefined : ThreadPostPermissionsFromJSON(json['permissions']),
    };
}

export function ThreadPostToJSON(value?: ThreadPost): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'username': value.username,
        'author': DiscussionAuthorToJSON(value.author),
        'html': value.html,
        'reply_to': value.replyTo,
        'reply_count': value.replyCount,
        'created': value.created.toISOString(),
        'permissions': ThreadPostPermissionsToJSON(value.permissions),
    };
}


