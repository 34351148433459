// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssetFeedbackRequest,
    AssetFeedbackRequestFromJSON,
    AssetFeedbackRequestToJSON,
    AssetFeedbackResponse,
    AssetFeedbackResponseFromJSON,
    AssetFeedbackResponseToJSON,
} from '../models';

export interface FeedbacksCreateRequest {
    data: AssetFeedbackRequest;
}

export interface FeedbacksGetFeedbackRequest {
    curriculumSlug: string;
    assetId: string;
    userEmail: string;
}

/**
 * no description
 */
export class AssetFeedbacksApi extends runtime.BaseAPI {

    /**
     * Create a new feedback
     */
    async feedbacksCreateRaw(requestParameters: FeedbacksCreateRequest): Promise<runtime.ApiResponse<AssetFeedbackResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling feedbacksCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/asset_feedbacks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssetFeedbackRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFeedbackResponseFromJSON(jsonValue));
    }

    /**
     * Create a new feedback
     */
    async feedbacksCreate(requestParameters: FeedbacksCreateRequest): Promise<AssetFeedbackResponse> {
        const response = await this.feedbacksCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve feedback by query parameters
     */
    async feedbacksGetFeedbackRaw(requestParameters: FeedbacksGetFeedbackRequest): Promise<runtime.ApiResponse<AssetFeedbackResponse>> {
        if (requestParameters.curriculumSlug === null || requestParameters.curriculumSlug === undefined) {
            throw new runtime.RequiredError('curriculumSlug','Required parameter requestParameters.curriculumSlug was null or undefined when calling feedbacksGetFeedback.');
        }

        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling feedbacksGetFeedback.');
        }

        if (requestParameters.userEmail === null || requestParameters.userEmail === undefined) {
            throw new runtime.RequiredError('userEmail','Required parameter requestParameters.userEmail was null or undefined when calling feedbacksGetFeedback.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.curriculumSlug !== undefined) {
            queryParameters['curriculum_slug'] = requestParameters.curriculumSlug;
        }

        if (requestParameters.assetId !== undefined) {
            queryParameters['asset_id'] = requestParameters.assetId;
        }

        if (requestParameters.userEmail !== undefined) {
            queryParameters['user_email'] = requestParameters.userEmail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/asset_feedbacks/get_feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetFeedbackResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve feedback by query parameters
     */
    async feedbacksGetFeedback(requestParameters: FeedbacksGetFeedbackRequest): Promise<AssetFeedbackResponse> {
        const response = await this.feedbacksGetFeedbackRaw(requestParameters);
        return await response.value();
    }

}
