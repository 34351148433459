import React from 'react';
import { WrappedMessage } from '../../../../utils';
import messages from '../../../displayMessages';
import { CareerOverviewCard, CareerOverviewCardCardTypeEnum } from 'labxchange-client';
import { Icon } from 'elements';
import PulseCard from 'career-explorer/components/PulseCard';
import { useColor } from 'career-explorer/ThemeProvider';
import { intl } from 'i18n';
import { getCardIcon, getCardIconColor } from 'career-explorer/utils';

export const CARD_LABELS = {
    'duties': intl.formatMessage(messages.careerOverviewDutyCardLabel),
    'education': intl.formatMessage(messages.careerOverviewEducationCardLabel),
    'outlook': intl.formatMessage(messages.careerOverviewDemandCardLabel),
    'related-careers': intl.formatMessage(messages.careerOverviewRelatedCareersCardLabel),
    'skills': intl.formatMessage(messages.careerOverviewSkillsCardLabel),
    'salary': intl.formatMessage(messages.careerOverviewSalaryCardLabel),
};

interface BasicsPillProps  {
    careerCard: CareerOverviewCard;
}

export const BasicPill: React.FC<BasicsPillProps> = ({careerCard}) => {
    const { setSelectedColor } = useColor();

    const handleNodeClick = () => {
        setSelectedColor({backgroundColor: '#E2F5F3', iconColor: '#33B7A7'});

        if (careerCard.cardType) {
            const targetElement = document.getElementById(careerCard.cardType);
            if (targetElement) {
                setTimeout(() => {
                    targetElement.scrollIntoView({behavior: 'smooth'});
                }, 700);
            }
        }
    };

    const handleReadMore = () => {
        handleNodeClick();
    };

    return (
        <PulseCard onClick={handleNodeClick}>
            <div className='pill'>
                <div className='pill-content'>
                    <div className='pill-info'>
                        <div className='pill-title'>
                            <div className='pill-info-subtitle'>{CARD_LABELS[careerCard.cardType]}</div>
                            <h4 className='pill-info-title'>{careerCard.cardLabel}</h4>
                        </div>
                        <div className={`pill-icon ${careerCard.demandTrajectory}`}>
                            <Icon
                                name={getCardIcon(careerCard.cardType, careerCard.demandTrajectory)}
                                fill={getCardIconColor(careerCard.demandTrajectory)}
                                zoom='28px'
                            />
                        </div>
                    </div>
                    <div className='pill-info-description'>
                        {careerCard.description}&nbsp;
                        <button onClick={handleReadMore} className='read-more-button'>
                            <WrappedMessage message={messages.readMoreBasicSummary}/>
                        </button>
                    </div>
                </div>
            </div>
        </PulseCard>
    );
};

interface CareerOverviewProps {
    overviewCards: CareerOverviewCard[];
}

const CareerOverviewSection: React.FC<CareerOverviewProps> = ({overviewCards}) => {
    const cardOrder = [
        CareerOverviewCardCardTypeEnum.Duties,
        CareerOverviewCardCardTypeEnum.Skills,
        CareerOverviewCardCardTypeEnum.Education,
        CareerOverviewCardCardTypeEnum.Salary,
        CareerOverviewCardCardTypeEnum.Outlook,
        CareerOverviewCardCardTypeEnum.RelatedCareers,
    ];

    return (
        <div className='career-overview-section'>
            <h2>
                <WrappedMessage message={messages.careerOverviewSectionHeading} />
            </h2>
            <div className='career-overview-container'>
                {overviewCards.sort((a, b) => cardOrder.indexOf(a.cardType) - cardOrder.indexOf(b.cardType)).map((card) => (
                    <BasicPill key={card.id} careerCard={card}/>
                ))}
            </div>
        </div>
    );
};

export default CareerOverviewSection;
