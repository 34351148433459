import React, { useState } from 'react';
import { Icon } from 'elements';
import classNames from 'classnames';
import { useLayoutSize } from 'utils';

interface CareerTagProps {
    onClick?: (tag: React.ReactNode, addTag: boolean) => void;
    children?: React.ReactNode;
    disableIcon?: boolean;
    ariaDescribedBy?: string;
    active?: boolean;
    cssClass?: string;
}

const CareerTag: React.FC<CareerTagProps> = (props) => {
    const isMobileOrTablet = ['small', 'mobile', 'medium'].includes(useLayoutSize());
    const [isPulsing, setIsPulsing] = useState(false);

    const handleTagClick = () => {
        setIsPulsing(true);
        let addTag = false;
        let pulseTime = 0;

        if (!props.onClick) {
            return;
        }

        if (!props.active) {
            addTag = true;
            pulseTime = 1000;
        }

        return setTimeout(() => {
            setIsPulsing(false);
            if (props.onClick) props.onClick(props.children, addTag);
        }, pulseTime);
    };

    return (
        <button
            className={classNames(`career-tag ${props.cssClass}`, {
                'active': props.active,
                'mobile': isMobileOrTablet,
                })
            }
            onClick={handleTagClick}
            type='button'
            aria-describedby={props.ariaDescribedBy}
        >
            {props.children}
            {(props.active && !props.disableIcon) ? <Icon name='x' zoom='1em'/> : null}
            {isPulsing && !props.active &&
                <>
                    <div className='pulse'/>
                    <Icon className='pulse-icon' name='check' fill='#ffffff' zoom='20px' />
                </>
            }
        </button>
    );
};

export default CareerTag;
