// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    PathwayItemUpdate,
    PathwayItemUpdateFromJSON,
    PathwayItemUpdateToJSON,
} from './';

/**
 * 
 * @export
 * @interface PathwayUpdate
 */
export interface PathwayUpdate {
    /**
     * 
     * @type {string}
     * @memberof PathwayUpdate
     */
    image?: string;
    /**
     * 
     * @type {Array<PathwayItemUpdate>}
     * @memberof PathwayUpdate
     */
    items?: Array<PathwayItemUpdate>;
}

export function PathwayUpdateFromJSON(json: any): PathwayUpdate {
    return {
        'image': !exists(json, 'image') ? undefined : json['image'],
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<any>).map(PathwayItemUpdateFromJSON),
    };
}

export function PathwayUpdateToJSON(value?: PathwayUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'image': value.image,
        'items': value.items === undefined ? undefined : (value.items as Array<any>).map(PathwayItemUpdateToJSON),
    };
}


