// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    RelatedCareer,
    RelatedCareerFromJSON,
    RelatedCareerToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelatedCareerSection
 */
export interface RelatedCareerSection {
    /**
     * Get related careers.
     * @type {Array<RelatedCareer>}
     * @memberof RelatedCareerSection
     */
    readonly relatedCareers?: Array<RelatedCareer>;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareerSection
     */
    sectionTitle: string;
}

export function RelatedCareerSectionFromJSON(json: any): RelatedCareerSection {
    return {
        'relatedCareers': !exists(json, 'related_careers') ? undefined : (json['related_careers'] as Array<any>).map(RelatedCareerFromJSON),
        'sectionTitle': json['section_title'],
    };
}

export function RelatedCareerSectionToJSON(value?: RelatedCareerSection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'section_title': value.sectionTitle,
    };
}


