// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ConversationListCount
 */
export interface ConversationListCount {
    /**
     * 
     * @type {number}
     * @memberof ConversationListCount
     */
    all: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationListCount
     */
    unread: number;
}

export function ConversationListCountFromJSON(json: any): ConversationListCount {
    return {
        'all': json['all'],
        'unread': json['unread'],
    };
}

export function ConversationListCountToJSON(value?: ConversationListCount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'all': value.all,
        'unread': value.unread,
    };
}


