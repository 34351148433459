
import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { showErrorMessage } from 'ui/components';
import { XBlocksApi } from 'global/api';
import { BlockProps } from './models';
import { AudioTranscript } from 'library/components/Transcript/AudioTranscript';
import { SandboxedIFrame } from 'elements/components/SandboxedIFrame';


interface AudioBlockData {
    display_name: string;
    embed_code: string;
    options: {lang: string, language: {name: string, native_name: string}}[];
    transcripts: {[key: string]: {content: string, type: string}};
    user_state: {current_language: string};
}

interface TranscriptData {
    availableLanguages: {kind: string, code: string, languageNative: string}[];
    queriedTranscripts: Map<string, string>;
    defaultLanguage: string;
}

export interface AudioBlockState {
    transcriptData?: TranscriptData;
    embedCode?: string;
}

export class AudioBlock extends React.PureComponent<BlockProps, AudioBlockState> {

    constructor(props: BlockProps) {
        super(props);
        this.state = {
            transcriptData: undefined
        };
    }

    async componentDidMount() {
        try {
            const audioBlockData = await this.getXblockData();
            const embedCode = audioBlockData.embed_code;
            const transcriptData = this.generateTranscriptData(audioBlockData);
            this.setState({transcriptData, embedCode});
        } catch(error) {
            showErrorMessage(<WrappedMessage message={messages.errorLoadingAudio} />, {
                exception: error,
            });
            return;
        }
    }

    public render() {
        return (
            <div className='audio-block-student-view unfolded'>
                <div className='audio-block-embed-code-student-view'>
                    { this.state.embedCode &&
                        <SandboxedIFrame
                            title={this.props.itemMetadata.title}
                            scrolling='no'
                            {...this.extractIframeProps()}
                        />
                    }
                </div>
                {this.state.transcriptData &&
                    <AudioTranscript
                        itemMetadata={this.props.itemMetadata}
                        audioTranscripts={this.state.transcriptData}
                    />
                }
            </div>
        );
    }

    private async getXblockData() {
        const dataRaw = await XBlocksApi.studentViewUserState({ id: this.props.itemMetadata.id, });
        const data = JSON.parse(dataRaw);
        return data;
    }

    private generateTranscriptData(audioBlockData: AudioBlockData) {
        const languageData = audioBlockData.options;
        const transcripts = audioBlockData.transcripts;
        const defaultLanguage = audioBlockData.user_state.current_language;

        const availableLanguages = languageData.map((language: any) => {
            return {
                kind: 'captions',
                code: language.lang,
                languageNative: language.language.name,
                src: language.url,
            };
        });

        const queriedTranscripts = new Map();
        Object.keys(transcripts).forEach((languageCode) => {
            queriedTranscripts.set(languageCode, transcripts[languageCode].content);
        });

        return {
            availableLanguages, queriedTranscripts, defaultLanguage
        };
    }

    private extractIframeProps() {
        const props = {} as any;
        if (this.state.embedCode) {
            const embedCodeHTML = new DOMParser().parseFromString(this.state.embedCode, 'text/html');
            const iframeObject = embedCodeHTML.getElementsByTagName('iframe')[0];

            // If iframe exists, extract and populate props
            if (iframeObject) {
                if (iframeObject.hasAttribute('width')) {
                    props.width = iframeObject.getAttribute('width');
                } else {
                    props.width = '300px';
                }

                if (iframeObject.hasAttribute('src')) {
                    props.src = iframeObject.getAttribute('src');
                }
            }
        }
        return props;
    }
}
