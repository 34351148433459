import React from 'react';
import PulseCard from '../PulseCard';
import { CareerCheckBox } from '../CareerCheckBox';

interface StepperProps {
    title: string;
    description: string;
    checked: boolean;
    toggleCheckbox: (checked: boolean) => void;
}

const CheckboxCard: React.FC<StepperProps> = ({ title, description, checked, toggleCheckbox }) => {
    return (
        <PulseCard>
            <div className='checkbox-card' onClick={() => toggleCheckbox(!checked)} >
                <div className='card-content-section'>
                    <div className='content-title'>
                        {title}
                    </div>
                    <div className='content-description'>
                        {description}
                    </div>
                </div>
                <CareerCheckBox checked={checked} onClick={() => toggleCheckbox(!checked)}/>
            </div>
        </PulseCard>
    );
};

export default CheckboxCard;
