// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface MentorshipGeneralPermissions
 */
export interface MentorshipGeneralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof MentorshipGeneralPermissions
     */
    canBeMentee: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MentorshipGeneralPermissions
     */
    canBeMentor: boolean;
}

export function MentorshipGeneralPermissionsFromJSON(json: any): MentorshipGeneralPermissions {
    return {
        'canBeMentee': json['can_be_mentee'],
        'canBeMentor': json['can_be_mentor'],
    };
}

export function MentorshipGeneralPermissionsToJSON(value?: MentorshipGeneralPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_be_mentee': value.canBeMentee,
        'can_be_mentor': value.canBeMentor,
    };
}


