// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomThreadDelete
 */
export interface ClassroomThreadDelete {
    /**
     * 
     * @type {string}
     * @memberof ClassroomThreadDelete
     */
    classroom: string;
}

export function ClassroomThreadDeleteFromJSON(json: any): ClassroomThreadDelete {
    return {
        'classroom': json['classroom'],
    };
}

export function ClassroomThreadDeleteToJSON(value?: ClassroomThreadDelete): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'classroom': value.classroom,
    };
}


