import bind from 'bind-decorator';
import * as React from 'react';

export interface ImagePickerData {
    image: File|null;
    imagePreviewUrl: string;
}

interface Props extends React.PropsWithChildren {
    id: string;
    onFileChanged: (fileInfo: ImagePickerData) => void;
}

/**
 * Wraps an element such as a button, so that clicking on it will open a file
 * dialog and allow the user to upload an image file.
 *
 * You probably want to use <ImageField> instead, which also supports drag-and-drop.
 */
export class ImageFilePicker extends React.PureComponent<Props, {}> {

    public render() {
        return (
            <div>
                <div className='image-file-picker-form'>
                    <input type='file' id={this.props.id} className='image-file-picker'
                        aria-label='Image File Selector' tabIndex={0}
                        accept='image/png, image/jpeg' onChange={this.onFileChange} required
                    />
                    <label htmlFor={this.props.id}>
                        {this.props.children}
                    </label>
                </div>
            </div>
        );
    }

    @bind private onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if (e.target.files === null) {
            return;
        }

        const reader = new FileReader();
        const image = e.target.files[0];

        reader.onloadend = () => {
            this.props.onFileChanged({
                image,
                imagePreviewUrl: reader.result as string,
            });
        };
        reader.readAsDataURL(image);
    }
}
