import React from 'react';
import messages from './displayMessages';

import { WrappedMessage } from 'utils';

export interface CareerCheckBoxProps {
    checked: boolean|null;
    disabled?: boolean;
    onClick: (newValue: boolean) => void;
    otherProps?: React.HTMLProps<HTMLInputElement>;
    children?: React.ReactNode;
    altLabel?: string;
}

export const CareerCheckBox: React.FC<CareerCheckBoxProps> = (props, {disabled=false, otherProps={}}) =>  {

    const handleClick = () => {
        if (props.disabled) {
            return;
        }
        const newValue: boolean = !props.checked;
        props.onClick(newValue);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleClick();
        }
    };

    return (
        <div
            role='checkbox'
            aria-checked={
                props.checked === null ? 'mixed' :
                props.checked === true ? 'true' :
                'false'
            }
            aria-disabled={disabled}
            className={`career-checkbox ` + `${!props.children ? 'checkbox-no-label ' : ''}`}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
            {...(props.altLabel ? {'aria-label': props.altLabel} : {})}
            {...otherProps}
        >
            <span className='checkbox-icon'></span>
            <span className='checkbox-tooltip'>
                {props.checked ?
                    <WrappedMessage message={messages.checkboxCheckedTooltipLabel}/>
                    : <WrappedMessage message={messages.checkboxUnCheckedTooltipLabel} />
                }
            </span>
            {props.children}
        </div>
    );
};