import * as React from 'react';
import {UILangCodes, UILanguages, DEFAULT_UI_LANGUAGE} from '../../../i18n';

interface LanguageSelectionOptions {
    currentLanguage: string;
    onSetLanguage: (language: keyof typeof UILanguages) => any;
    original?: string;
}

export const LanguageSelector = ({currentLanguage, onSetLanguage, original}: LanguageSelectionOptions) => {
    if (!UILangCodes.includes(currentLanguage as any)) {
        currentLanguage = DEFAULT_UI_LANGUAGE.key;
    }
    const rows = [];
    for (let i = 0; i < UILangCodes.length; i += 2) {
        const buttons = [i, i + 1].map((index) => {
            if (UILangCodes.length <= index) {
                return [];
            }
            const key = UILangCodes[index];
            const lang = UILanguages[key];
            const isSelected = (currentLanguage === key);
            const isOriginal = (original === key);
            return <button
                key={key}
                onClick={() => onSetLanguage(key)}
                className={
                    'btn' +
                    `${isSelected ? ' language-selected ' : ''}` +
                    `${isOriginal ? ' language-original ' : ''}`
                }
            >
                {lang.nativeName}
            </button>;
        });
        rows.push(<div key={i} className='translation-selector-row'>
            {buttons}
        </div>);
    }
    return <div className='translations'>{rows}</div>;
};
