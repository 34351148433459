import * as React from 'react';
import classNames from 'classnames';

import { Icon } from 'elements';

interface FilterButtonsProps {
    btnList: string[];
    btnSelected: string;
    onButtonClick: (subject: string) => void;
    moreTopicsLink?: React.ReactNode;
}

export const FilterButtons: React.FC<FilterButtonsProps> = (props) => {
    const { btnList, btnSelected, onButtonClick, moreTopicsLink } = props;
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [isAtStart, setIsAtStart] = React.useState(true);
    const [isAtEnd, setIsAtEnd] = React.useState(false);
    const [hideChevrons, setHideChevrons] = React.useState(false);
    const [isDragging, setIsDragging] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const [scrollLeft, setScrollLeft] = React.useState(0);
    const [dragDistance, setDragDistance] = React.useState(0);
    const hideLeftChevron = hideChevrons || isAtStart;
    const hideRightChevron = hideChevrons || isAtEnd;

    React.useEffect(() => {
        const updateStates = () => {
            // Update the chevron visibility
            if (scrollContainerRef.current) {
                const container = scrollContainerRef.current as HTMLElement;
                const containerWidth = container.offsetWidth;
                const totalButtonsWidth = Array.from(container.children)
                                               .reduce((total, child) => {
                                                   const htmlChild = child as HTMLElement;
                                                   return total + htmlChild.offsetWidth;
                                               }, 0);
                setHideChevrons(totalButtonsWidth <= containerWidth);
            }

            // Check scroll position
            if (scrollContainerRef.current) {
                const { scrollLeft: currentScrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
                setIsAtStart(currentScrollLeft === 0);
                setIsAtEnd(currentScrollLeft + clientWidth === scrollWidth);
            }
        };

        updateStates();

        const scrollContainer = scrollContainerRef.current;
        scrollContainer?.addEventListener('scroll', updateStates, { passive: true });
        window.addEventListener('resize', updateStates);

        return () => {
            scrollContainer?.removeEventListener('scroll', updateStates);
            window.removeEventListener('resize', updateStates);
        };
    }, [btnList, scrollContainerRef.current]);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setDragDistance(0);
        if (scrollContainerRef.current) {
            setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
            setScrollLeft(scrollContainerRef.current.scrollLeft);
        }
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        e.preventDefault();
        if (scrollContainerRef.current) {
            const x = e.pageX - scrollContainerRef.current.offsetLeft;
            const walk = x - startX;
            setDragDistance(prevDistance => prevDistance + Math.abs(walk));
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    const onMouseLeave = () => {
        setIsDragging(false);
    };

    const handleRightScroll = () => {
        if (scrollContainerRef.current) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft + 250;
            scrollContainerRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
        }
    };

    const handleLeftScroll = () => {
        if (scrollContainerRef.current) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft - 250;
            scrollContainerRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
        }
    };

    const onClickFilterButton = (subject: string) => {
        if (dragDistance < 10) {
            onButtonClick(subject);
        }
    };

    return (
        <div className='filter-buttons'>
            {!hideLeftChevron && (
                <button className='filter-scroll-button' onClick={handleLeftScroll}>
                    <Icon name='chevron-left-thin' />
                </button>
            )}
            <div
                className={classNames('filter-buttons-wrapper', {
                  'no-chevron': hideChevrons,
                  'no-chevron-left': isAtStart,
                  'no-chevron-right': isAtEnd,
                })}
            >
                <div
                    className='filter-buttons-container'
                    role='toolbar'
                    ref={scrollContainerRef}
                    onMouseDown={onMouseDown}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUp}
                    onMouseLeave={onMouseLeave}
                >
                    {btnList.map(btn => (
                        <button
                            key={btn}
                            className={`btn-filter ${btnSelected === btn ? 'btn-selected' : ''}`}
                            onClick={() => onClickFilterButton(btn)}
                        >
                        <span>{btn.replace('_', ' ')}</span>
                        </button>
                    ))}
                    {moreTopicsLink}
                </div>
            </div>
            {!hideRightChevron && (
                <button className='filter-scroll-button' onClick={handleRightScroll}>
                    <Icon name='chevron-right-thin' />
                </button>
            )}
        </div>
    );
};
