// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Thread,
    ThreadFromJSON,
    ThreadToJSON,
    ThreadPost,
    ThreadPostFromJSON,
    ThreadPostToJSON,
} from './';

/**
 * 
 * @export
 * @interface ThreadWithPosts
 */
export interface ThreadWithPosts {
    /**
     * 
     * @type {Thread}
     * @memberof ThreadWithPosts
     */
    thread: Thread;
    /**
     * 
     * @type {Array<ThreadPost>}
     * @memberof ThreadWithPosts
     */
    posts: Array<ThreadPost>;
}

export function ThreadWithPostsFromJSON(json: any): ThreadWithPosts {
    return {
        'thread': ThreadFromJSON(json['thread']),
        'posts': (json['posts'] as Array<any>).map(ThreadPostFromJSON),
    };
}

export function ThreadWithPostsToJSON(value?: ThreadWithPosts): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'thread': ThreadToJSON(value.thread),
        'posts': (value.posts as Array<any>).map(ThreadPostToJSON),
    };
}


