import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import bind from 'bind-decorator';
import update from 'immutability-helper';
import { LocationDescriptor } from 'history';

import { ClassroomDetail, ThreadList } from 'labxchange-client';
import { ThreadCardList } from 'discussions/components/ThreadCardList';
import { ThreadComposer } from 'discussions/components/ThreadComposer';
import { ClassroomsApi } from 'global/api';
import { LxConfig, PAGINATION_OPTIONS, ROUTES } from 'global/constants';
import { getCurrentPageFromString, getPaginationSizeFromString } from 'search/utils';
import { JumpTo, Pagination, PaginationSizeOptions, SortOptions } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import discussionMessages from 'discussions/displayMessages';
import { ThreadPinStatusEnum } from 'discussions/models';
import { intl } from 'i18n';

interface Props extends RouteComponentProps<{}> {
    classroom: ClassroomDetail;
}

interface State {
    loading: boolean;
    sending: boolean;
    isComposing: boolean;
    currentPage: number;
    paginationSize: number;
    ordering: string;
    parentNode?: HTMLElement;
    threads?: ThreadList;
    redirectTo?: LocationDescriptor;
}

export class ClassroomDiscussionInternal extends React.PureComponent<Props, State> {
    private loadDataTimer = 0;

    constructor(props: Props) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);

        this.state = {
            loading: true,
            sending: false,
            isComposing: false,
            ordering: params.get('ordering') || 'recently',
            currentPage: getCurrentPageFromString(params.get('page')),
            paginationSize: getPaginationSizeFromString(params.get('size')),
        };
    }

    public async componentDidMount() {
        const parentNode = findDOMNode(this)!.parentNode;
        if (parentNode && parentNode instanceof HTMLElement) {
            this.setState({parentNode});
        }

        await this.loadDataInterval();
    }

    public componentWillUnmount() {
        if (this.loadDataTimer > 0) {
            window.clearTimeout(this.loadDataTimer);
        }
    }

    @bind public async loadDataInterval() {
        /* Load data periodically, but instead of using a fixed interval, wait for server response before scheduling
         * the next request. */
        if (this.loadDataTimer) {
            window.clearTimeout(this.loadDataTimer);
        }

        await this.loadData();

        this.loadDataTimer = window.setTimeout(this.loadDataInterval, LxConfig.DiscussionPollDefaultInterval);
    }

    public async loadData() {
        this.setState({loading: true});
        try {
            const threads = await ClassroomsApi.classroomThreadsList({
                classroomId: this.props.classroom.id,
                currentPage: this.state.currentPage,
                ordering: this.state.ordering,
                paginationSize: this.state.paginationSize,
            });
            this.setState({loading: false, threads});
        } catch (err) {
            if (err.status !== 404) {
                showErrorMessage(<WrappedMessage message={discussionMessages.loadFailed} />);
                throw err;
            }
            this.setState({loading: false, threads: undefined});
        }
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push={true} to={this.state.redirectTo}/>;
        }
        const pageCount = Math.ceil(
            (this.state.threads ? this.state.threads.count : 0) / this.state.paginationSize);
        return (
            <div className='subcategory-discussion'>
                <div className='subcategory-discussion-header'>
                    <button data-testid='new-thread' className='btn btn-primary' onClick={this.onClickNewThread}>
                        <WrappedMessage
                            message={discussionMessages.discussionsAddNewThread}
                        />
                    </button>
                    {this.state.threads !== undefined
                    ?
                        <div className='subcategory-discussion-header-actions'>
                            <span className='subcategory-discussion-header-count'>
                                <WrappedMessage
                                    message={discussionMessages.showingResultCounter}
                                    values={{count: this.state.threads.count}}
                                />
                            </span>
                            <PaginationSizeOptions
                                name={intl.formatMessage(discussionMessages.paginationSizeLabel)}
                                value={this.state.paginationSize}
                                onChange={this.onPaginationSizeSelect}
                                options={PAGINATION_OPTIONS}
                            />
                            <SortOptions
                                name={intl.formatMessage(discussionMessages.sortByLabel)}
                                value={this.state.ordering}
                                onChange={this.onOrderingSelect}
                                options={[
                                    {
                                        label: intl.formatMessage(
                                            discussionMessages.recentlySort,
                                        ),
                                        value: 'recently',
                                    }, {
                                        label: intl.formatMessage(
                                            discussionMessages.popularitySort,
                                        ),
                                        value: 'popularity',
                                    },
                                ]}
                            />
                        </div>
                    : null }
                </div>
                {this.state.threads !== undefined
                ?
                    <div className='subcategory-discussion-content'>
                        <ThreadCardList
                            threads={this.state.threads.results}
                            getThreadLink={this.getThreadLink}
                            onPin={
                                this.props.classroom.permissions?.canPinThreadObject
                                ? this.onPinThread
                                : undefined}
                            onDelete={this.onDelete}
                        />
                        <div className='subcategory-discussion-pagination'>
                            <JumpTo
                                pageCount={pageCount}
                                currentPage={this.state.currentPage}
                                onPageSelect={this.onPageSelect}
                            />
                            <Pagination
                                pageCount={pageCount}
                                currentPage={this.state.currentPage}
                                onPageSelect={this.onPageSelect}
                            />
                        </div>
                    </div>
                : null}
                <ThreadComposer
                    close={this.closeComposeDrawer}
                    open={this.state.isComposing}
                    parentNode={this.state.parentNode}
                    sending={this.state.sending}
                    onSubmit={this.submitThread}
                    hideTags={true}
                />
            </div>
        );
    }

    @bind private async onDelete(threadId: number) {
        this.setState({loading: true});
        try {
            await ClassroomsApi.classroomThreadsDelete({
                id: threadId.toString(),
                data: {classroom: this.props.classroom.id},
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={discussionMessages.errorDeletingThread} />, {exception: err});
        }
        await this.loadData();
        this.setState({loading: false});
    }

    @bind private getThreadLink(threadId: number) {
        return ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD_SLUG(
            this.props.classroom.id, threadId,
        );
    }

    @bind private async onPinThread(threadId: number, pinned: boolean) {
        if (this.state.threads === undefined) { return; }
        try {
            const pinStatus = pinned ? ThreadPinStatusEnum.Pinned : ThreadPinStatusEnum.Default;
            const updatedThread = await ClassroomsApi.classroomThreadsPartialUpdate(
                {
                    id: threadId.toString(), data: {
                        classroom: this.props.classroom.id,
                        pinStatus,
                    },
                },
            );
            const threads = (this.state.threads?.results).map((thread) => {
                if (thread.id === updatedThread.id) {
                    return updatedThread;
                }
                return thread;
            });
            this.setState({
                threads: update(
                    this.state.threads,
                    {results: {$set: threads}}
                ),
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={discussionMessages.errorUpdatingThread} />, {exception: err});
        }
    }

    @bind private onClickNewThread() {
        this.setState({isComposing: !this.state.isComposing});
    }

    @bind private closeComposeDrawer() {
        this.setState({isComposing: false});
    }

    @bind private async submitThread(title: string, content: string) {
        this.setState({sending: true});

        try {
            const thread = await ClassroomsApi.classroomThreadsCreate({
                data: {
                    classroom: this.props.classroom.id,
                    subCategory: this.props.classroom.discussion,
                    title,
                    content,
                },
            });
            this.setState({
                sending: false,
                redirectTo: ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD_SLUG(
                    this.props.classroom.id, thread.id,
            )});
            return;
        } catch (err) {
            showErrorMessage(<WrappedMessage message={discussionMessages.errorCreatingThread} />, {exception: err});
            this.setState({sending: false});
        }

        await this.loadData();
    }

    @bind private onPageSelect(page: number) {
        this.setState({currentPage: page}, () => {
            const params = new URLSearchParams();
            params.set('page', this.state.currentPage.toString());
            this.updateURLParams(params);
        });
    }

    @bind private onOrderingSelect(ordering: string) {
        this.setState({ordering}, () => {
            const params = new URLSearchParams();
            params.set('ordering', this.state.ordering.toString());
            this.updateURLParams(params);
        });
    }

    @bind private onPaginationSizeSelect(paginationSize: number) {
        this.setState({paginationSize}, () => {
            const params = new URLSearchParams();
            params.set('pagination_size', this.state.paginationSize.toString());
            this.updateURLParams(params);
        });
    }

    private updateURLParams(newParams: URLSearchParams) {
        this.props.history.push(
            ROUTES.Classrooms.CLASSROOM_DISCUSSION_SLUG(this.props.classroom.id) +
            `?${newParams.toString()}`,
        );
        this.loadData();
    }
}

export const ClassroomDiscussion = withRouter(ClassroomDiscussionInternal);
