import { defineMessages } from 'react-intl';

const messages = defineMessages({
    classPageTitle: {
        id:'classPageTitle',
        defaultMessage: 'Class {name}',
        description: 'HTML title for the class page',
    },
    classDiscussionPageTitle: {
        id:'classDiscussionPageTitle',
        defaultMessage: 'Class discussion - {name}',
        description: 'HTML title for the class discussion page',
    },
    classLearnersPageTitle: {
        id:'classLearnersPageTitle',
        defaultMessage: 'Class learners - {name}',
        description: 'HTML title for the class learners page',
    },
    classProgressPageTitle: {
        id:'classProgressPageTitle',
        defaultMessage: 'Class progress - {name}',
        description: 'HTML title for the class progress page',
    },
    classCopiedSuccessParagraph1: {
        id:'classCopiedSuccessParagraph1',
        defaultMessage: `This class has been copied, using the same name, description, and settings. You may now invite
        learners and assign content.`,
        description: 'First paragraph of the copied success modal.',
    },
    classCopiedSuccessParagraph2: {
        id:'classCopiedSuccessParagraph2',
        defaultMessage: `All of the content assigned to the original class has been copied, but remains unposted until you
        assign it. Co-educators, learners, progress, and discussion threads have not been copied from the original class.`,
        description: 'Second paragraph of the copied success modal.',
    },
    classCopiedSuccessButtonLabel: {
        id:'classCopiedSuccessButtonLabel',
        defaultMessage: 'Go to class',
        description: 'Laben on the copied success modal.',
    },
});

export default messages;
