import { bind } from 'bind-decorator';
import * as React from 'react';
import { Button, Icon } from 'ui/components';
import { intl } from 'i18n';

import messages from './displayMessages';

interface Props {
    tags: string[];
    selectedTags: string[];
    onClose(): void;
    onSelectTags(tags: string[]): void;
    show?: boolean;
    isMobileView?: boolean;
}

interface State {
    unselected: string[];
    selectedTags: string[];
}

export class TagsFilter extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            unselected: [],
            selectedTags: this.props.selectedTags,
        };
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.selectedTags !== this.props.selectedTags) {
            this.setState({selectedTags: this.props.selectedTags});
        }
    }

    public render() {
        const tags = this.props.tags.map((tag, index) =>
            <li
                key={index}
                className={
                    `annotated-video-tags-filter-tag
                    ${this.state.selectedTags.includes(tag)
                        ? 'annotated-video-tags-filter-tag-selected' : ''}`
                }>
                <button className='unstyled' onClick={() => this.toggleTag(tag)}>
                    #{tag}
                </button>
            </li>
        );
        return <div className={`annotated-video-tags-filter
            ${!this.props.show ? 'annotated-video-tags-filter-hidden' : ''}`}>
            <div className='annotated-video-tags-filter-header'>
                <span>View tags</span>
                {!this.props.isMobileView &&
                    <button
                        aria-label={intl.formatMessage(messages.closeTagsFilterViewButtonLabel)}
                        className='unstyled' onClick={this.props.onClose}>
                        <Icon name='x' zoom='x0.75'/>
                    </button>
                }
            </div>
            <ul>{tags}</ul>
            <div className='annotated-video-tags-filter-footer'>
                <Button
                    btnStyle='primary'
                    onClick={this.onSelectTags}
                    label={messages.filterTagsButtonLabel}
                />
            </div>
        </div>;
    }

    @bind private onSelectTags() {
        this.props.onSelectTags(this.state.selectedTags);
    }

    @bind private toggleTag(tag: string) {
        let selectedTags = this.state.selectedTags;
        const index = selectedTags.indexOf(tag);
        if (index > -1) {
            selectedTags.splice(index, 1);
            this.forceUpdate();
        } else {
            selectedTags = this.state.selectedTags.concat([tag]);
        }
        this.setState({selectedTags});
    }
}
