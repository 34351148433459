import * as React from 'react';
import { LearningObjective } from 'labxchange-client';

interface LearningObjectivesProps {
    learningObjectives: LearningObjective[];
}

export default class LearningObjectives extends React.PureComponent<LearningObjectivesProps> {
    render() {
        return (
            <ol>{this.renderLearningObjectives()}</ol>
        );
    }

    private renderLearningObjectives() {
        return this.props.learningObjectives.map((objective) => {
            return (
                <li key={objective.id}>
                    {this.buildDescription(objective)}
                </li>
            );
        });
    }

    private buildDescription(objective: LearningObjective) {
        const description = objective.description;
        let newDescription = description.charAt(0).toUpperCase() + description.slice(1);
        if (description[description.length - 1] !== '.') {
            newDescription += '.';
        }
        return newDescription;
    }
}
