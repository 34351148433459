import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { ClusterDynamic } from './ClusterDynamic';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../../global/constants';

interface ClusterMap {
    [slug: string]: string;
}

export const staticToDynamicCluster:ClusterMap = {
    'remote-learning-with-labxchange': 'lx-cluster:lxc-remote-learning-with-labxchange',
    'fairopportunityx': 'lx-cluster:lxc-fox',
    'crispr-hiv': 'lx-cluster:lxc-crispr-hiv',
    'abe': 'lx-cluster:abe',
};

interface MatchProps {
    clusterKey: string;
}

interface Props extends RouteComponentProps<MatchProps> {}

export class ClustersPage extends React.PureComponent<Props, {}> {

    public render() {
        const clusterKey = this.props.match.params.clusterKey;
        const clusterSlug = staticToDynamicCluster[clusterKey];
        if (clusterSlug) {
            // Ensures backwards compatibility with existing static cluster page URLs.
            return <Redirect exact strict to={ROUTES.Library.CLUSTER_SLUG(clusterSlug)}/>;
        } else {
            // Rendering dynamic cluster
            return <ClusterDynamic />;
        }
    }
}
