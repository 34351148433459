// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AnnotatedVideoQuestionAnswer
 */
export interface AnnotatedVideoQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoQuestionAnswer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoQuestionAnswer
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnotatedVideoQuestionAnswer
     */
    correct: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoQuestionAnswer
     */
    feedback?: string;
}

export function AnnotatedVideoQuestionAnswerFromJSON(json: any): AnnotatedVideoQuestionAnswer {
    return {
        'id': json['id'],
        'text': json['text'],
        'correct': json['correct'],
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
    };
}

export function AnnotatedVideoQuestionAnswerToJSON(value?: AnnotatedVideoQuestionAnswer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'text': value.text,
        'correct': value.correct,
        'feedback': value.feedback,
    };
}


