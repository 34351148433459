import * as React from 'react';
import { bind } from 'bind-decorator';
import moment from 'moment-shortformat';
import { generateID } from 'global/utils';

interface State {
    hoursValue: number;
    minutesValue: number;
    secondsValue: number;
}

export interface TimerInputProps {
    style?: 'default' | 'invalid';
    value?: number;
    onChange(
        hours: number,
        minutes: number,
        seconds: number,
        total: number): void;
}

export class TimerInput extends React.PureComponent<TimerInputProps, State> {
    private id: string;

    constructor(props: TimerInputProps) {
        super(props);
        const duration = moment.duration(this.props.value || 0, 'seconds');
        this.id = generateID();
        this.state = {
            hoursValue: duration.hours() || 0,
            minutesValue: duration.minutes() || 0,
            secondsValue: duration.seconds() || 0,
        };
    }

    public render() {
        return <div className={`
            lx-timer-input
            ${this.props.style === 'invalid' ? 'lx-timer-input-invalid': ''}`}>
            <button onClick={this.onClickMinusButton}>-</button>
            <div className='fields'>
                <div className='field'>
                    <label htmlFor={`hours-${this.id}`} aria-hidden={true}>HH</label>
                    <input
                        id={`hours-${this.id}`}
                        aria-label='Hours input field'
                        type='number'
                        value={this.padValue(this.state.hoursValue || 0)}
                        onKeyDown={this.onBlurHours}
                        onChange={this.onBlurHours}
                        onBlur={this.onBlurHours}/>
                </div>
                <span>:</span>
                <div className='field'>
                    <label htmlFor={`minutes-${this.id}`}aria-hidden={true}>MM</label>
                    <input
                        id={`minutes-${this.id}`}
                        aria-label='Minutes input field'
                        type='number'
                        value={this.padValue(this.state.minutesValue || 0)}
                        onKeyDown={this.onBlurMinutes}
                        onChange={this.onBlurMinutes}
                        onBlur={this.onBlurMinutes}/>
                </div>
                <span>:</span>
                <div className='field'>
                    <label htmlFor={`seconds-${this.id}`} aria-hidden={true}>SS</label>
                    <input
                        id={`seconds-${this.id}`}
                        aria-label='Seconds input field'
                        type='number'
                        value={this.padValue(this.state.secondsValue || 0)}
                        onKeyDown={this.onBlurSeconds}
                        onChange={this.onBlurSeconds}
                        onBlur={this.onBlurSeconds}/>
                </div>
            </div>
            <button onClick={this.onClickPlusButton}>+</button>
        </div>;
    }

    @bind private onBlurHours(event: React.SyntheticEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.setState(
            {hoursValue: this.cleanValue(event.currentTarget.value, 100)},
            () => this.checkValues());
    }

    @bind private onBlurMinutes(event: React.SyntheticEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.setState(
            {minutesValue: this.cleanValue(event.currentTarget.value, 60)},
            () => this.checkValues());
    }

    @bind private onBlurSeconds(event: React.SyntheticEvent<HTMLInputElement>) {
        event.stopPropagation();
        this.setState(
            {secondsValue: this.cleanValue(event.currentTarget.value, 60)},
            () => this.checkValues());
    }

    @bind private cleanValue(value: string, max: number) {
        let cleanedValue = parseInt(value.slice(-2).replace(/\D/g, ''), 10);
        if (cleanedValue >= max) {
            cleanedValue = max - 1;
        } else if (cleanedValue < 0) {
            cleanedValue = 0;
        }
        return cleanedValue;
    }

    @bind private padValue(value: number) {
        return value.toString().padStart(2, '0');
    }

    @bind private checkValues() {
        let hoursValue = this.state.hoursValue;
        let minutesValue = this.state.minutesValue;
        let secondsValue = this.state.secondsValue;

        if (secondsValue >= 60) {
            secondsValue = 0;
            minutesValue++;
        }

        if (secondsValue < 0) {
            secondsValue = 0;
            minutesValue--;
            if (minutesValue === 0) {
                secondsValue = 59;
            }
        }

        if (minutesValue >= 60) {
            minutesValue = 0;
            hoursValue++;
        }

        if (minutesValue < 0) {
            minutesValue = 0;
            hoursValue--;
            if (hoursValue === 0) {
                minutesValue = 59;
                secondsValue = 59;
            }
        }

        if (hoursValue < 0) {
            hoursValue = 0;
        }

        if (hoursValue > 99) {
            hoursValue = 99;
        }

        this.setState({hoursValue, minutesValue, secondsValue}, () => {
            this.props.onChange(
                this.state.hoursValue,
                this.state.minutesValue,
                this.state.secondsValue,
                (
                    this.state.secondsValue
                    +(this.state.minutesValue * 60)
                    + (this.state.hoursValue * 60 * 60)
                )
            );
        });
    }

    @bind private onClickPlusButton() {
        this.setState({secondsValue: this.state.secondsValue + 1}, () => this.checkValues());
    }

    @bind private onClickMinusButton() {
        this.setState({secondsValue: this.state.secondsValue - 1}, () => this.checkValues());
    }
}
