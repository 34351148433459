// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface PeopleAppliedFilter
 */
export interface PeopleAppliedFilter {
    /**
     * 
     * @type {string}
     * @memberof PeopleAppliedFilter
     */
    filterOn: PeopleAppliedFilterFilterOnEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PeopleAppliedFilter
     */
    filterValues: Array<string>;
}

export function PeopleAppliedFilterFromJSON(json: any): PeopleAppliedFilter {
    return {
        'filterOn': json['filter_on'],
        'filterValues': json['filter_values'],
    };
}

export function PeopleAppliedFilterToJSON(value?: PeopleAppliedFilter): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filter_on': value.filterOn,
        'filter_values': value.filterValues,
    };
}

/**
* @export
* @enum {string}
*/
export enum PeopleAppliedFilterFilterOnEnum {
    AreaOfStudy = 'AreaOfStudy',
    Country = 'Country',
    Institution = 'Institution',
    Role = 'Role',
    Subjects = 'Subjects'
}


