import { intl } from 'i18n';
import * as React from 'react';
import messages from '../displayMessages';

export interface NewsletterProps {
    onCheck: (value: boolean) => void;
    checked?: boolean;
}

export const NewsletterCheck: React.FunctionComponent<NewsletterProps> = (props) => {
    const [checked, setChecked] = React.useState(false);
    React.useEffect(() => {
        setChecked(props.checked || false);
    }, []);
    return (
        <div className='newsletter-check-container'>
            <input
                className='newsletter-check'
                type='checkbox'
                id='newsletter-check'
                value={1}
                checked={checked}
                onChange={(event) => {
                    setChecked(!checked);
                    props.onCheck(event.target.checked);
                }}
            />
            <label
                className='newsletter-msg'
                htmlFor='newsletter-check'
            >
                {intl.formatMessage(messages.lxAuthSignUpNewsletterMessage)}
            </label>
        </div>
    );
};
