// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface MentorshipScore
 */
export interface MentorshipScore {
    /**
     * 
     * @type {number}
     * @memberof MentorshipScore
     */
    weighedEarned: number;
    /**
     * 
     * @type {number}
     * @memberof MentorshipScore
     */
    weighedPossible: number;
}

export function MentorshipScoreFromJSON(json: any): MentorshipScore {
    return {
        'weighedEarned': json['weighed_earned'],
        'weighedPossible': json['weighed_possible'],
    };
}

export function MentorshipScoreToJSON(value?: MentorshipScore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'weighed_earned': value.weighedEarned,
        'weighed_possible': value.weighedPossible,
    };
}


