import * as React from 'react';
import { defineMessages } from 'react-intl';
import { doAutoSize } from "../../Utils";
import autosize from "autosize";
import {
    HtmlTextBox,
    ToggleTextSwitch,
    ToggleSwitch
} from 'ui/components';
import { intl } from 'i18n';
import { messages as componentMessages } from '../displayMessages';

const messages = defineMessages({
    title: {
        id: 'SingleSelectItem.title',
        defaultMessage: 'Answer item'
    },
    correct: {
        id: 'SingleSelectItem.placeholder.correct',
        defaultMessage: 'Add the correct answer'
    },
    incorrect: {
        id: 'SingleSelectItem.placeholder.incorrect',
        defaultMessage: 'Add an incorrect answer'
    },
    feedbackTitle: {
        id: 'SingleSelectItem.feedbackTitle',
        defaultMessage: 'Specific feedback'
    },
    feedbackPlaceholder: {
        id: 'SingleSelectItem.feedbackPlaceholder',
        defaultMessage: 'Enter feedback for when the choice is selected'
    },
    removeBtnAreaLabel: {
        id: 'SingleSelectItem.removeBtnAreaLabel',
        defaultMessage: 'Remove answer item'
    }
});

export default class SingleSelectItem extends React.PureComponent<any, any> {

    private answerTextarea: any;
    private feedbackTextarea: any;

    constructor(props: any) {
        super(props);
        this.answerChanged = this.answerChanged.bind(this);
        this.openFeedbackButtonClick = this.openFeedbackButtonClick.bind(this);
        this.removeAnswer = this.removeAnswer.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.feedbackChange = this.feedbackChange.bind(this);
        this.titleChange = this.titleChange.bind(this);

        this.state = {
            openFeedback: this.props.feedback,
        }
    }

    componentDidMount() {
        autosize(this.answerTextarea);
        autosize(this.feedbackTextarea)
    }

    answerChanged(changed: any) {
        let { id, title, correct, feedback } = this.props;
        this.props.singleSelectChangeAnswer({
            id: id,
            title: title,
            correct: correct,
            feedback: feedback, ...changed
        });
    }

    removeAnswer() {
        this.props.singleSelectRemoveAnswer(this.props.id);
    }

    openFeedbackButtonClick() {
        this.setState({
            openFeedback: !this.state.openFeedback
        }, () => {
            doAutoSize();
        });
    }

    checkBoxChange(correct: boolean) {
        this.answerChanged({ correct });
    }

    feedbackChange(value: string) {
        this.answerChanged({ feedback: value });
    }

    titleChange(value: string) {
        this.answerChanged({ title: value });
    }

    render() {
        const { formatMessage } = intl;
        const additionalСlass = this.state.openFeedback ? 'lxc-answers-feedback_open' : '';
        const additionalСlassContainer = this.state.openFeedback ? 'lxc-answers-feedback-container_open' : '';
        const additionalBlockСlass = this.state.openFeedback ? 'lxc-answers-field-block_open' : '';
        const placeholderText = this.props.correct ? formatMessage(messages.correct) : formatMessage(messages.incorrect);

        const showErrorButton = this.props.answersList && this.props.answersList.length > 2
            && this.props.id !== 0 && this.props.id !== 1;

        return (
            <div key={this.props.id} className='lxc-answers-option'>
                <ToggleTextSwitch
                    isChecked={this.props.correct}
                    onToggle={this.checkBoxChange}
                    extraClass='lxc-answers-toggle'
                />
                <div className='lxc-answers-field-wrapper'>
                    <div className={`lxc-answers-field-block ${additionalBlockСlass}`}>
                        <div className={`lxc-answers-item-wrapper ${showErrorButton ? 'lxc-answers-item-wrapper-with-delete' : ''}`}>
                            <label className='lxc-sr' htmlFor={`answer-single${this.props.id}`}>{placeholderText}</label>
                            <HtmlTextBox
                                label={placeholderText}
                                hideLabel={true}
                                defaultValue={this.props.title}
                                onChange={(text) => this.titleChange(text)}
                                editorStyle={HtmlTextBox.EditorStyle.AssesmentEditor}
                                ref={c => (this.answerTextarea = c)}
                                options={{ forced_root_block: false, body_class: 'wysiwyg-select-body' }}
                                extraClassName="wysiwyg-select"
                                labelJustify='flex-end'
                            />
                        </div>

                    </div>
                    <div className={`lxc-answers-feedback ${additionalСlass} ${showErrorButton ? 'lxc-answers-feedback-with-delete' : ''}`}>
                        <div className='lxc-answers-feedback-toggle'>
                            <ToggleSwitch
                                isChecked={this.state.openFeedback}
                                onToggleSwitchSelect={this.openFeedbackButtonClick}
                                displayText={formatMessage(componentMessages.feedbackToggleLabel)}
                            />
                        </div>
                        <div className={`lxc-answers-feedback-container ${additionalСlassContainer}`}>
                            <HtmlTextBox
                                label={formatMessage(messages.feedbackTitle)}
                                defaultValue={this.props.feedback}
                                onChange={(text) => this.feedbackChange(text)}
                                editorStyle={HtmlTextBox.EditorStyle.AssesmentEditor}
                                ref={c => (this.feedbackTextarea = c)}
                                options={{ forced_root_block: false, body_class: 'wysiwyg-select-body' }}
                                extraClassName="wysiwyg-select"
                            />
                        </div>
                    </div>
                </div>
                {
                    showErrorButton ? (
                        <button className='lxc-answers-remove-btn' type='button' aria-label={formatMessage(messages.removeBtnAreaLabel)} onClick={this.removeAnswer} />
                    ) : null
                }
            </div>
        );
    }
}
