// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomItemUpdate
 */
export interface ClassroomItemUpdate {
    /**
     * 
     * @type {string}
     * @memberof ClassroomItemUpdate
     */
    status?: ClassroomItemUpdateStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ClassroomItemUpdate
     */
    order: number;
}

export function ClassroomItemUpdateFromJSON(json: any): ClassroomItemUpdate {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'order': json['order'],
    };
}

export function ClassroomItemUpdateToJSON(value?: ClassroomItemUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'order': value.order,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClassroomItemUpdateStatusEnum {
    Unposted = 'unposted',
    Posted = 'posted'
}


