import * as React from 'react';
import { useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import Helmet from 'react-helmet';
import { intl } from 'i18n';
import { Button } from '../Buttons';
import { LabXChangeLogo } from '../LabXChangeLogo';
import messages from 'elements/displayMessages';
import uiMessages from 'ui/components/displayMessages';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'global/constants';
import ProgressBar from 'auth/components/ProgressBar/ProgressBar';

export interface AuthPageLayoutProps {
    pageTitle?: MessageDescriptor;
    className?: string;
    children: React.ReactNode;
    imageUrl: string;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    enableBackButton?: boolean;
    onClickBackButton?: () => void;
    onClickLogo?: () => void;  // It redirects to the Home by default
    progressPercentage?: number;
}

export const AuthPageLayout: React.FunctionComponent<AuthPageLayoutProps> = (props) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    const pageTitle = () => {
        if (props.pageTitle) {
            const title = intl.formatMessage(props.pageTitle);
            return intl.formatMessage(uiMessages.pageTitlePlaceholder, {title});
        }
        return intl.formatMessage(uiMessages.defaultPageTitle);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        // We want to invoke this only if the user has pressed the space or enter key
        // while navigating through the site using the tab key.
        if (e.key === 'Enter' || e.key === ' ') {
            props.onClickLogo?.();
        }
    };

    return (
        <main className={`layout-wrapper auth-layout ${props.className}`}>
            <Helmet>
                <title>{pageTitle()}</title>
            </Helmet>
            <div className='image-container'>
                {props.onClickLogo &&
                    <div
                        role='button'
                        tabIndex={0}
                        className='logo-with-click'
                        onClick={props.onClickLogo}
                        onKeyDown={(e) => handleKeyDown(e)}
                    >
                        <LabXChangeLogo width={142} height={50} type='trim' theme={isMobile ? 'dark': 'light'} />
                    </div>
                }
                {props.onClickLogo === undefined &&
                    <NavLink
                        className='lx-logo-link'
                        to={ROUTES.All.HOME}
                    >
                        <LabXChangeLogo width={142} height={50} type='trim' theme={isMobile ? 'dark': 'light'} />
                    </NavLink>
                }
                <img
                    className='image'
                    src={props.imageUrl}
                    alt=''
                />
            </div>
            <div className='content-container'>
                {props.enableBackButton &&
                    <Button
                        className='back-button'
                        btnStyle='link'
                        size='sm'
                        icon='chevron-left'
                        iconFill='#0063C3'
                        label={messages.elementsModalBackText}
                        onClick={props.onClickBackButton}
                    >
                        <span className='sr-only'>{intl.formatMessage(messages.elementsModalBackSRText)}</span>
                    </Button>
                }
                {props.progressPercentage && <ProgressBar percentage={props.progressPercentage} />}
                <div className='content'>
                    {props.title && <h1 className='heading-style-2'>{props.title}</h1>}
                    {props.subtitle &&
                        <div className='subtitle'>
                            {props.subtitle}
                        </div>
                    }
                    <div className='children'>
                        {props.children}
                    </div>
                </div>
            </div>
        </main>
    );
};
