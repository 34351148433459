// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionsMessageEmbed,
    DiscussionsMessageEmbedFromJSON,
    DiscussionsMessageEmbedToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiscussionsMessage
 */
export interface DiscussionsMessage {
    /**
     * 
     * @type {number}
     * @memberof DiscussionsMessage
     */
    readonly id?: number;
    /**
     * 
     * @type {Date}
     * @memberof DiscussionsMessage
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof DiscussionsMessage
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionsMessage
     */
    content: string;
    /**
     * 
     * @type {DiscussionsMessageEmbed}
     * @memberof DiscussionsMessage
     */
    embed?: DiscussionsMessageEmbed;
}

export function DiscussionsMessageFromJSON(json: any): DiscussionsMessage {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': new Date(json['created']),
        'author': json['author'],
        'content': json['content'],
        'embed': !exists(json, 'embed') ? undefined : DiscussionsMessageEmbedFromJSON(json['embed']),
    };
}

export function DiscussionsMessageToJSON(value?: DiscussionsMessage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'created': value.created.toISOString(),
        'author': value.author,
        'content': value.content,
        'embed': DiscussionsMessageEmbedToJSON(value.embed),
    };
}


