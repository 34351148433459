import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { MessageDescriptor } from 'react-intl';

import { ROUTES } from 'global/constants';
import { TabbedPageLayout } from 'dashboard';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface Props {
    children: React.ReactNode;
    pageTitle?: MessageDescriptor;
    pageTitleValues?: {[key: string]: string};
    searchUrl?: string;
}

class CommunityLayoutInternal extends React.PureComponent<Props & RouteComponentProps> {
    public render() {
        return (
            <TabbedPageLayout
                pageTitle={this.props.pageTitle}
                pageTitleValues={this.props.pageTitleValues}
                searchUrl={this.props.searchUrl}
                header={
                    <div className='inactive-dashboard-dropdown container'>
                        <h1><WrappedMessage message={messages.communityTitle} /></h1>
                    </div>
                }
                tabs={[
                    {to: ROUTES.Community.DISCUSSIONS, message: messages.discussionsTab, exact: false, onlyEducator: false},
                    {to: ROUTES.Community.MENTEES, message: messages.menteesTitle, exact: true, onlyEducator: true},
                    {to: ROUTES.Community.MENTORS, message: messages.mentorsTab, exact: true, onlyEducator: false},
                ]}
            >
                {this.props.children}
            </TabbedPageLayout>
        );
    }
}

export const CommunityLayout = withRouter(CommunityLayoutInternal);
