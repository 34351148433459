// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ProfilesGeneralPermissions
 */
export interface ProfilesGeneralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ProfilesGeneralPermissions
     */
    canAccessEducatorDashboard: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfilesGeneralPermissions
     */
    canSendDirectMessages: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfilesGeneralPermissions
     */
    canAddTagsToThreads: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfilesGeneralPermissions
     */
    canSubmitUsersnapFeedback: boolean;
}

export function ProfilesGeneralPermissionsFromJSON(json: any): ProfilesGeneralPermissions {
    return {
        'canAccessEducatorDashboard': json['can_access_educator_dashboard'],
        'canSendDirectMessages': json['can_send_direct_messages'],
        'canAddTagsToThreads': json['can_add_tags_to_threads'],
        'canSubmitUsersnapFeedback': json['can_submit_usersnap_feedback'],
    };
}

export function ProfilesGeneralPermissionsToJSON(value?: ProfilesGeneralPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_access_educator_dashboard': value.canAccessEducatorDashboard,
        'can_send_direct_messages': value.canSendDirectMessages,
        'can_add_tags_to_threads': value.canAddTagsToThreads,
        'can_submit_usersnap_feedback': value.canSubmitUsersnapFeedback,
    };
}


