import * as React from 'react';
import { defineMessages } from 'react-intl';
import { intl } from 'i18n';
import { HtmlTextBox } from 'ui/components';
import autosize from "autosize";

const messages = defineMessages({
    correctLabel: {
        id: 'GroupFeedbackItem.correctLabel',
        defaultMessage: 'Correct feedback'
    },
    incorrectLabel: {
        id: 'GroupFeedbackItem.incorrectLabel',
        defaultMessage: 'Incorrect feedback'
    },
    placeholder: {
        id: 'GroupFeedbackItem.placeholder',
        defaultMessage: 'Enter feedback text'
    },
    correctNote: {
        id: 'GroupFeedbackItem.correctNote',
        defaultMessage: 'This will only be display if the user gets the question correct.'
    },
    incorrectNote: {
        id: 'GroupFeedbackItem.incorrectNote',
        defaultMessage: 'This will only be display if the user gets the question incorrect.'
    },
});

export default class GeneralFeedbackItem extends React.Component<any, any> {

    private answerTextarea: any;

    constructor(props: any) {
        super(props);
        this.feedbackChangeHandler = this.feedbackChangeHandler.bind(this);
    }

    feedbackChangeHandler(text: string) {
        this.props.generalFeedbackChange({
            feedback: text,
            correct: this.props.correct,
            id: this.props.id
        })
    }

    componentDidMount() {
        autosize(this.answerTextarea);
    }

    render() {
        return (
            <fieldset className='lxc-advanced-settings-feedback-wrapper'>
                <HtmlTextBox
                    label={intl.formatMessage(this.props.correct ? messages.correctLabel : messages.incorrectLabel)}
                    defaultValue={this.props.feedback}
                    onChange={(text) => this.feedbackChangeHandler(text)}
                    editorStyle={HtmlTextBox.EditorStyle.AssesmentEditor}
                    ref={c => (this.answerTextarea = c)}
                    options={{ forced_root_block: false, body_class: 'wysiwyg-select-body' }}
                    extraClassName="wysiwyg-select"
                />
                <div className='lxc-advanced-settings-note'>
                    {intl.formatMessage(this.props.correct ? messages.correctNote : messages.incorrectNote)}
                </div>
            </fieldset>
        )
    }
}
