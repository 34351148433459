// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface MentorshipCreate
 */
export interface MentorshipCreate {
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    mentor: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    mentee: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    readonly initiator?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    status?: MentorshipCreateStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof MentorshipCreate
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MentorshipCreate
     */
    readonly updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    stopReason?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    pendingReason?: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipCreate
     */
    pendingSubject?: string;
}

export function MentorshipCreateFromJSON(json: any): MentorshipCreate {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mentor': json['mentor'],
        'mentee': json['mentee'],
        'initiator': !exists(json, 'initiator') ? undefined : json['initiator'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
        'updated': !exists(json, 'updated') ? undefined : new Date(json['updated']),
        'stopReason': !exists(json, 'stop_reason') ? undefined : json['stop_reason'],
        'pendingReason': !exists(json, 'pending_reason') ? undefined : json['pending_reason'],
        'pendingSubject': !exists(json, 'pending_subject') ? undefined : json['pending_subject'],
    };
}

export function MentorshipCreateToJSON(value?: MentorshipCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'mentor': value.mentor,
        'mentee': value.mentee,
        'status': value.status,
        'stop_reason': value.stopReason,
        'pending_reason': value.pendingReason,
        'pending_subject': value.pendingSubject,
    };
}

/**
* @export
* @enum {string}
*/
export enum MentorshipCreateStatusEnum {
    Pending = 'pending',
    ReRequested = 're-requested',
    Accepted = 'accepted',
    Refused = 'refused',
    Canceled = 'canceled',
    StoppedByMentor = 'stopped-by-mentor',
    StoppedByMentee = 'stopped-by-mentee'
}


