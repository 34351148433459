// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionsMessage,
    DiscussionsMessageFromJSON,
    DiscussionsMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiscussionsMessageCreateResponse
 */
export interface DiscussionsMessageCreateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DiscussionsMessageCreateResponse
     */
    created: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiscussionsMessageCreateResponse
     */
    conversation: string;
    /**
     * 
     * @type {DiscussionsMessage}
     * @memberof DiscussionsMessageCreateResponse
     */
    message: DiscussionsMessage;
}

export function DiscussionsMessageCreateResponseFromJSON(json: any): DiscussionsMessageCreateResponse {
    return {
        'created': json['created'],
        'conversation': json['conversation'],
        'message': DiscussionsMessageFromJSON(json['message']),
    };
}

export function DiscussionsMessageCreateResponseToJSON(value?: DiscussionsMessageCreateResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'created': value.created,
        'conversation': value.conversation,
        'message': DiscussionsMessageToJSON(value.message),
    };
}


