// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModuleSlab
 */
export interface ModuleSlab {
    /**
     * 
     * @type {string}
     * @memberof ModuleSlab
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleSlab
     */
    displayType: ModuleSlabDisplayTypeEnum;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof ModuleSlab
     */
    items: Array<ItemResponse>;
}

export function ModuleSlabFromJSON(json: any): ModuleSlab {
    return {
        'title': json['title'],
        'displayType': json['display_type'],
        'items': (json['items'] as Array<any>).map(ItemResponseFromJSON),
    };
}

export function ModuleSlabToJSON(value?: ModuleSlab): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'display_type': value.displayType,
        'items': (value.items as Array<any>).map(ItemResponseToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum ModuleSlabDisplayTypeEnum {
    Interactives = 'interactives',
    Pathways = 'pathways',
    SimpleCards = 'simple_cards',
    Clusters = 'clusters',
    FeatureCard = 'feature_card',
    List = 'list'
}


