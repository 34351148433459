import { defineMessages } from 'react-intl';

const messages = defineMessages({
    createItemButtonLabel: {
        id:'createItemButtonLabel',
        defaultMessage: 'Add your own content',
        description: 'Label of button for adding content.',
    },
    createItemErrorMessage: {
        id:'createItemErrorMessage',
        defaultMessage: 'Creating content failed with error "{error}".',
        description: 'Message when creating an asset fails.',
    },
    itemTypeAnnotatedVideoBeta: {
        id:'itemTypeAnnotatedVideoBeta',
        defaultMessage: 'Annotated video',
        description: 'Display name of annotated video (New) item type.',
    },
    itemTypeGoogleDoc: {
        id:'itemTypeGoogleDoc',
        defaultMessage: 'Google Doc',
        description: 'Display name of Google doc item type.',
    },
    itemTypeAssignment: {
        id:'itemTypeAssignment',
        defaultMessage: 'Question Set',
        description: 'Display name of assignment item type.',
    },
    itemTypeAssessment: {
        id:'itemTypeAssessment',
        defaultMessage: 'Question',
        description: 'Display name of assessment item type.',
    },
    itemTypeQuestionNew: {
        id:'itemTypeQuestionNew',
        defaultMessage: 'Question (Beta)',
        description: 'Display name of new question item type while in private beta.',
    },
    itemTypeAssessmentTemplateMultiSelect: {
        id:'itemTypeAssessmentTemplateMultiSelect',
        defaultMessage: 'Multi Select',
        description: 'Display name for multi select template for assessment item type.',
    },
    itemTypeAssessmentTemplateShortAnswer: {
        id:'itemTypeAssessmentTemplateShortAnswer',
        defaultMessage: 'Short Answer',
        description: 'Display name for short answer template for assessment item type.',
    },
    itemTypeAssessmentTemplateSingleSelect: {
        id:'itemTypeAssessmentTemplateSingleSelect',
        defaultMessage: 'Single Select',
        description: 'Display name for single select template for assessment item type.',
    },
    itemTypeAudio: {
        id:'itemTypeAudio',
        defaultMessage: 'Audio',
        description: 'Audio item type.',
    },
    itemTypeBook: {
        id:'itemTypeBook',
        defaultMessage: 'Textbook',
        description: 'Display name of book item type.',
    },
    itemTypeCaseStudy: {
        id:'itemTypeCaseStudy',
        defaultMessage: 'Case Study',
        description: 'Display name of case study item type.',
    },
    itemTypeCluster: {
        id:'itemTypeCluster',
        defaultMessage: 'Cluster {count, plural, =0 {} one { - {count, number} item} other { - {count, number} items}}',
        description: 'Display name of cluster item type with item count info.',
    },
    simpleItemTypeCluster: {
        id:'simpleItemTypeCluster',
        defaultMessage: 'Cluster',
        description: 'Display name of cluster item type.',
    },
    itemTypeDocument: {
        id:'itemTypeDocument',
        defaultMessage: 'Document',
        description: 'Display name of document item type.',
    },
    itemTypeImage: {
        id:'itemTypeImage',
        defaultMessage: 'Image',
        description: 'Display name of Image item type.',
    },
    itemTypeInteractive: {
        id:'itemTypeInteractive',
        defaultMessage: 'Interactive',
        description: 'Display name of interactive item type.',
    },
    itemTypeNarrative: {
        id:'itemTypeNarrative',
        defaultMessage: 'Narrative',
        description: 'Display name of narrative item type.',
    },
    itemTypePathway: {
        id:'itemTypePathway',
        defaultMessage: 'Pathway {count, plural, =0 {} one { - {count, number} item} other { - {count, number} items}}',
        description: 'Display name of pathway item type with item count info.',
    },
    simpleItemTypePathway: {
        id:'simpleItemTypePathway',
        defaultMessage: 'Pathway',
        description: 'Display name of pathway item type.',
    },
    itemTypeSimulation: {
        id:'itemTypeSimulation',
        defaultMessage: 'Simulation',
        description: 'Simulation item type.',
    },
    itemTypeTeachingGuide: {
        id:'itemTypeTeachingGuide',
        defaultMessage: 'Teaching Guide',
        description: 'Display name of the teaching guide item type.',
    },
    itemTypeText: {
        id:'itemTypeText',
        defaultMessage: 'Text',
        description: 'Display name of text item type.',
    },
    itemTypeHtmlText: {
        id:'itemTypeHtmlText',
        defaultMessage: 'Text (Beta)',
        description: 'Display name of html text item type.',
    },
    itemTypeBlankPageText: {
        id:'itemTypeBlankPageText',
        defaultMessage: 'Blank page',
        description: 'Display name of blank page text item type.',
    },
    itemTypeExperimentText: {
        id:'itemTypeExperimentText',
        defaultMessage: 'Experiment',
        description: 'Display name of experiment text item type.',
    },
    itemTypeScientificPaperText: {
        id:'itemTypeScientificPaperText',
        defaultMessage: 'Scientific paper',
        description: 'Display name of scientific paper text item type.',
    },
    itemTypeReviewText: {
        id:'itemTypeReviewText',
        defaultMessage: 'Review',
        description: 'Display name of review text item type.',
    },
    itemTypeUnknown: {
        id:'itemTypeUnknown',
        defaultMessage: 'Unknown',
        description: 'Unknown item type.',
    },
    itemTypeVideo: {
        id:'itemTypeVideo',
        defaultMessage: 'Video',
        description: 'Display name of video item type.',
    },
    itemTypeLXVideo: {
        id:'itemTypeLXVideo',
        defaultMessage: 'Video (Beta)',
        description: 'Display name of new video item type.',
    },
    itemTypeAboutAnnotatedVideo: {
        id:'itemTypeAboutAnnotatedVideo',
        defaultMessage: 'About this annotated video',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutGoogleDoc: {
        id:'itemTypeAboutGoogleDoc',
        defaultMessage: 'About this Google Document',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutAssignment: {
        id:'itemTypeAboutAssignment',
        defaultMessage: 'About this question set',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutAssessment: {
        id:'itemTypeAboutAssessment',
        defaultMessage: 'About this question',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutAudio: {
        id:'itemTypeAboutAudio',
        defaultMessage: 'About this audio',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutBook: {
        id:'itemTypeAboutBook',
        defaultMessage: 'About this book',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutCaseStudy: {
        id:'itemTypeAboutCaseStudy',
        defaultMessage: 'About this case study',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutCluster: {
        id:'itemTypeAboutCluster',
        defaultMessage: 'About this cluster',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutDocument: {
        id:'itemTypeAboutDocument',
        defaultMessage: 'About this document',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutInteractive: {
        id:'itemTypeAboutInteractive',
        defaultMessage: 'About this interactive',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutImage: {
        id:'itemTypeAboutImage',
        defaultMessage: 'About this image',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutNarrative: {
        id:'itemTypeAboutNarrative',
        defaultMessage: 'About this narrative',
        description: 'Heading above the description of this item type',
    },
    itemTypeStoryInScience: {
        id:'itemTypeStoryInScience',
        defaultMessage: 'Story in Science',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutPathway: {
        id:'itemTypeAboutPathway',
        defaultMessage: 'About this pathway',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutSimulation: {
        id:'itemTypeAboutSimulation',
        defaultMessage: 'About this simulation',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutTeachingGuide: {
        id:'itemTypeAboutTeachingGuide',
        defaultMessage: 'About this teaching guide',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutText: {
        id:'itemTypeAboutText',
        defaultMessage: 'About this text',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutBlankPageText: {
        id:'itemTypeAboutBlankPageText',
        defaultMessage: 'About this blank page text',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutExperimentText: {
        id:'itemTypeAboutExperimentText',
        defaultMessage: 'About this experiment text',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutScientificPaperText: {
        id:'itemTypeAboutScientificPaperText',
        defaultMessage: 'About this scientific paper text',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutReviewText: {
        id:'itemTypeAboutReviewText',
        defaultMessage: 'About this review text',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutUnknown: {
        id:'itemTypeAboutUnknown',
        defaultMessage: 'About this content',
        description: 'Heading above the description of this item type',
    },
    itemTypeAboutVideo: {
        id:'itemTypeAboutVideo',
        defaultMessage: 'About this video',
        description: 'Heading above the description of this item type',
    },
    videoImportInstructions: {
        id:'videoImportInstructions',
        defaultMessage: 'To add a YouTube video, enter the URL in the field below. To add a video from Vimeo and other supported services, view our {guidelinesLink}.',
        description: 'Short instructions for importing a video from YouTube or other supported service',
    },
    videoImportOnlyYoutubeInstructions: {
        id:'videoImportOnlyYoutubeInstructions',
        defaultMessage: 'Enter the Youtube URL in the field below. See also our {guidelinesLink}.',
        description: 'Short instructions for importing a video from YouTube service',
    },
    videoGuidelinesLinkLabel: {
        id:'videoGuidelinesLinkLabel',
        defaultMessage: 'guidelines',
        description: 'Label for the link to the article with video import guidelines.',
    },
    videoImportFieldLabel: {
        id:'videoImportFieldLabel',
        defaultMessage: 'URL',
        description: 'Label for the video import URL field',
    },
    videoImportFieldPlaceholder: {
        id:'videoImportFieldPlaceholder',
        defaultMessage: 'Enter a video URL',
        description: 'Placeholder for the video import URL field',
    },
    videoImportButtonLabel: {
        id:'videoImportButtonLabel',
        defaultMessage: 'Import video',
        description: 'Label for the video import button',
    },
    youTubeVideoImportFailureMessage: {
        id:'youTubeVideoImportFailureMessage',
        defaultMessage: 'This YouTube video could not be added. Please double check the URL and try again.',
        description: 'Description for the YouTube video import failure message',
    },

    browserDoesntSupportEmbeddedVideos: {
        id: 'browserDoesntSupportEmbeddedVideos',
        defaultMessage: 'Sorry, your browser doesn\'t support embedded videos',
        description: 'Message if browser doesn\'t support embedded videos',
    }
});

export default messages;
