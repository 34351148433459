import { PeopleAppliedFilterFilterOnEnum } from 'labxchange-client';
import { MutableTagData } from 'search/components/Tag';
import { TaxonomyCategory, TaxonomyData } from 'search/components/Taxonomy';
import { countryFilterName, AggregationResults, buildTags } from 'search/utils';

/**
 * Generate a URL that will display a search page, filtered with the given keywords/tags.
 *
 * @param baseUrl - intended to be of the form ROUTES.<page>.HOME
 * @param keywords - items to be prefixed with `q=` in the url
 * @param tagIds - items to be prefixed with `t=` in the url
 */

export const PeopleSearchFilter = PeopleAppliedFilterFilterOnEnum;
export type PeopleSearchFilter = PeopleAppliedFilterFilterOnEnum;

/**
 * Given a role value (e.g. 'learner'), return the title-cased name of that role.
 * This should return a <WrappedMessage>, but TagData/<Taxonomy> doesn't support
 * that yet.
 */
function roleFilterName(role: string): string {
    return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
}

/**
 * When we submit a search request to the people search API, it returns data about the
 * available filter options ("aggregations") that we can use to refine the search.
 * This method converts that aggregation data from the ElasticSearch result format
 * to the 'TaxonomyData'/'TagData' format used by our <FilterBar> component to display
 * the checkboxes of options that users can toggle on and off.
 *
 * @param aggregationsData The aggregation data returned by ElasticSearch
 */
export function buildFilterOptionsFromSearchData(aggregationsData: AggregationResults): TaxonomyData[] {

    // Subject filter is a special case because it has two levels of tags:
    const subjectTags: MutableTagData[] = [];
    for (const {key, doc_count} of aggregationsData[PeopleSearchFilter.Subjects].buckets!) {
        // key is either a subject/child key like "Biological Sciences:Genetics"
        // or a subject area / parent key like "Biological Sciences"
        const tagId = `${PeopleSearchFilter.Subjects}:${key}`;
        const isChild = key.indexOf(':') !== -1; // Is this a child [subject] or a parent [subject area]?
        if (isChild) {
            const name = key.split(':')[1];
            const parentTagName = key.split(':')[0];
            const parentTagId = PeopleSearchFilter.Subjects + ':' + parentTagName;
            let parentTag = subjectTags.find((tag) => tag.id === parentTagId);
            if (parentTag === undefined) {
                // TODO: Load correct numEntities for parent tag from elasticsearch
                parentTag = {id: parentTagId, name: parentTagName, childTags: [], numEntities: undefined};
                subjectTags.push(parentTag);
            }
            parentTag.childTags.push({id: tagId, name, numEntities: doc_count});
        } else {
            // If this parent tag wasn't already added by a child tag, add it now:
            if (subjectTags.find((tag) => tag.id === tagId) === undefined) {
                subjectTags.push({id: tagId, name: key, numEntities: doc_count, childTags: []});
            }
        }
    }
    const interestsFilter: TaxonomyData = {
        id: PeopleSearchFilter.Subjects,
        category: TaxonomyCategory.Interests,
        tags: subjectTags,
    };

    // The rest are fairly consistent:

    const roleFilter: TaxonomyData = buildTags(aggregationsData, PeopleSearchFilter.Role, TaxonomyCategory.Role, roleFilterName);
    const institutionFilter = buildTags(aggregationsData, PeopleSearchFilter.Institution, TaxonomyCategory.Institution);
    const areaOfStudyFilter = buildTags(aggregationsData, PeopleSearchFilter.AreaOfStudy, TaxonomyCategory.AreaOfStudy);
    const locationFilter = buildTags(aggregationsData, PeopleSearchFilter.Country, TaxonomyCategory.Location, countryFilterName);

    const filters = [
        roleFilter,
        institutionFilter,
    ];
    filters.push(interestsFilter);
    filters.push(areaOfStudyFilter);
    filters.push(
        locationFilter,
    );
    return filters;
}
