import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleEducatorContentDashboard',
        defaultMessage: 'Content',
        description: 'Title of the educator dashboard page that lists all of educator\'s content.',
    },
    emptyContentLibraryTitle: {
        id:'emptyContentLibraryTitle',
        defaultMessage: 'You haven\'t created any content yet.',
        description: 'Title for the empty content library dashboard page.',
    },
    emptyContentLibrarySecondaryText: {
        id:'emptyContentLibrarySecondaryText',
        defaultMessage: 'Create learning content that you can share privately with others.',
        description: 'Secondary text for the empty content library learners dashboard page.',

    },
    emptyContentLibraryAction: {
        id:'emptyContentLibraryAction',
        defaultMessage: 'Create content',
        description: 'Action button for the empty content library dashboard page.',
    },
    emptyContentLibrarySecondaryTextEducator: {
        id:'emptyContentLibrarySecondaryTextEducator',
        defaultMessage: 'Create learning content that you can share privately with learners.',
        description: 'Secondary text for the empty content library educators dashboard page.',
    },
});

export default messages;
