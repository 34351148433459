// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface LearnerReportUser
 */
export interface LearnerReportUser {
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof LearnerReportUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LearnerReportUser
     */
    fullName: string;
}

export function LearnerReportUserFromJSON(json: any): LearnerReportUser {
    return {
        'username': json['username'],
        'fullName': json['full_name'],
    };
}

export function LearnerReportUserToJSON(value?: LearnerReportUser): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'full_name': value.fullName,
    };
}


