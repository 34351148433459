import { defineMessages } from 'react-intl';

const messages = defineMessages({
    annotationEditorTitle: {
        id:'annotationEditorTitle',
        defaultMessage: 'Create an annotation',
        description: 'Title for the annotation editor',
    },
    annotationEditorQuestionTitle: {
        id:'annotationEditorQuestionTitle',
        defaultMessage: 'Create a question',
        description: 'Title for the annotation question editor',
    },
    annotationEditorSaveButton: {
        id:'annotationEditorSaveButton',
        defaultMessage: 'Save annotation',
        description: 'Label for the save annotation editor button',
    },
    annotationEditorSaveQuestionButton: {
        id:'annotationEditorSaveQuestionButton',
        defaultMessage: 'Save question',
        description: 'Label for the save annotation with question editor button',
    },
    annotationEditorDeleteButton: {
        id:'annotationEditorDeleteButton',
        defaultMessage: 'Delete',
        description: 'Label for the delete annotation editor button',
    },
    blockAnnotatedVideoEditLabel: {
        id:'blockAnnotatedVideoEditLabel',
        defaultMessage: 'Edit annotated video',
        description: 'Label for button to edit annotated video.',
    },
    annotationEditorStartInputLabel: {
        id:'annotationEditorStartInputLabel',
        defaultMessage: 'Starting position',
        description: 'Label for the start annotated video annotation input.',
    },
    annotationEditorEndInputLabel: {
        id:'annotationEditorEndInputLabel',
        defaultMessage: 'Ending position',
        description: 'Label for the end annotated video annotation input.',
    },
    annotationQuestionSetOrderLabel: {
        id:'annotationQuestionSetOrderLabel',
        defaultMessage: 'Question set order',
        description: 'Label for the question set order.',
    },
    annotationEditorTitleInputLabel: {
        id:'annotationEditorTitleInputLabel',
        defaultMessage: 'Annotation title',
        description: 'Label for the title annotated video annotation input.',
    },
    annotationEditorQuestionTitleInputLabel: {
        id:'annotationEditorQuestionTitleInputLabel',
        defaultMessage: 'Question title',
        description: 'Label for the question title annotated video annotation input.',
    },
    annotationEditorImageAltInputLabel: {
        id:'annotationEditorAltImageInputLabel',
        defaultMessage: 'Alt text (required if there is an image)',
        description: 'Label for the alt text annotated video annotation input.',
    },
    annotationEditorImageAltInputPlaceholder: {
        id:'annotationEditorImageAltInputPlaceholder',
        defaultMessage: 'Describe the image',
        description: 'Placeholder for the alt text annotated video annotation input.',
    },
    annotationEditorQuestionAddAnswerButtonLabel: {
        id:'annotationEditorQuestionAddAnswerButtonLabel',
        defaultMessage: 'Add answer',
        description: 'Label for the add answer button for annotated video problem.',
    },
    annotationEditorQuestionInputLabel: {
        id:'annotationEditorQuestionInputLabel',
        defaultMessage: 'Question',
        description: 'Label for the annotated video question input.',
    },
    annotationEditorQuestionRemoveButtonLabel: {
        id:'annotationEditorQuestionRemoveButtonLabel',
        defaultMessage: 'Remove annotation',
        description: 'Label for the remove answer question checkbox.',
    },
    annotationEditorQuestionAnswerPlaceholder: {
        id:'annotationEditorQuestionAnswerPlaceholder',
        defaultMessage: 'Enter answer',
        description: 'Placeholder for the answer input.',
    },
    annotationEditorQuestionAnswerAddFeedbackLink: {
        id:'annotationEditorQuestionAnswerAddFeedbackLink',
        defaultMessage: '+ Add Feedback',
        description: 'Link to add feedback.',
    },
    annotationEditorQuestionAnswerFeedbackLabel: {
        id:'annotationEditorQuestionAnswerFeedbackLabel',
        defaultMessage: 'Feedback',
        description: 'Feedback label',
    },
    annotationEditorQuestionFeedbackPlaceholder: {
        id:'annotationEditorQuestionFeedbackPlaceholder',
        defaultMessage: 'Enter feedback (optional)',
        description: 'Placeholder for the answer input.',
    },
    annotationEditorDescriptionInputLabel: {
        id:'annotationEditorDescriptionInputLabel',
        defaultMessage: 'Description',
        description: 'Label for the description annotated video annotation input.',
    },
    annotationEditorLinkInputLabel: {
        id:'annotationEditorLinkInputLabel',
        defaultMessage: 'Learn more button (optional)',
        description: 'Label for the link annotated video annotation input.',
    },
    annotationEditorTitleInputPlaceholder: {
        id:'annotationEditorTitleInputPlaceholder',
        defaultMessage: 'Enter title',
        description: 'Placeholder for the title annotated video annotation input.',
    },
    annotationEditorDescriptionInputPlaceholder: {
        id:'annotationEditorDescriptionInputPlaceholder',
        defaultMessage: 'Enter your description',
        description: 'Placeholder for the description annotated video annotation input.',
    },
    annotationEditorLinkInputPlaceholder: {
        id:'annotationEditorLinkInputPlaceholder',
        defaultMessage: 'Enter external link',
        description: 'Placeholder for the link annotated video annotation input.',
    },
    annotationEditorTagsInputPlaceholder: {
        id:'annotationEditorTagsInputPlaceholder',
        defaultMessage: 'Enter tags',
        description: 'Placeholder for the tags annotated video annotation input.',
    },
    annotationEditorTagsSectionName: {
        id:'annotationEditorTagsSectionName',
        defaultMessage: 'Tags',
        description: 'Section name for the annotated video annotation tags section.',
    },
    annotationEditorTagsHelpText: {
        id:'annotationEditorTagsHelpText',
        defaultMessage: 'Add tags to help other users find your content. Separate the tags with commas.',
        description: 'Help text for the annotated video annotation tags section.',
    },
    annotationEditorAnswersSectionName: {
        id:'annotationEditorAnswersSectionName',
        defaultMessage: 'Answers',
        description: 'Section name for the annotated video annotation answers section.',
    },
});

export default messages;
