import { defineMessages } from 'react-intl';

const messages = defineMessages({
    sortOptionsLabel: {
        id:'sortOptionsLabel',
        defaultMessage: 'Attribute to sort by',
        description: 'Label for sorting options.',
    },
    sortOptionsText: {
        id:'sortOptionsText',
        defaultMessage: 'Sort by {options}',
        description: 'Text that shows current sorting setting',
    },
});

export default messages;
