export const choiceResponseTemplate = `<problem>
  <choiceresponse>
    <label></label>
    <checkboxgroup>
    </checkboxgroup>
  </choiceresponse>
</problem>`;

export const multichoiceResponseTemplate = `<problem>
  <multiplechoiceresponse>
    <label></label>
    <choicegroup>
    </choicegroup>
  </multiplechoiceresponse>
</problem>`;

export const stringResponseTemplate = `<problem>
  <stringresponse>
    <label></label>
  </stringresponse>
</problem>`;

export const newChoiceResponseTemplate = `<lx_question>
  <choiceresponse>
    <label></label>
    <checkboxgroup>
    </checkboxgroup>
  </choiceresponse>
</lx_question>`;

export const newMultichoiceResponseTemplate = `<lx_question>
  <multiplechoiceresponse>
    <label></label>
    <choicegroup>
    </choicegroup>
  </multiplechoiceresponse>
</lx_question>`;

export const newStringResponseTemplate = `<lx_question>
  <stringresponse>
    <label></label>
  </stringresponse>
</lx_question>`;
