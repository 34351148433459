import * as React from 'react';
import { bind } from 'bind-decorator';

import { getLoggedInStatus } from 'auth/selectors';
import { UsersApi } from 'global/api';
import { getStore } from 'global/store';
import { locale, setLanguageCookie } from 'i18n';
import messages from './displayMessages';
import { WrappedMessage } from 'utils';
import { Button, Modal} from 'ui/components';
import { LanguageSelector } from './LanguageSelector';

interface Props {
    onClose: () => void;
}

interface State {
    selectedLanguage: string;
}

export class LanguageSwitcherModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedLanguage: locale,
        };
    }

    render() {
        return (
            <Modal
                className='modal-close-header-action'
                onRequestClose={this.props.onClose}
                closeButtonText={messages.languageSelectCancel}
                size='normal'
                title={<WrappedMessage message={messages.languageSelectTitle} />}
                showTopBar={true}
                content={
                    <div className='translation-selector-modal'>
                        <LanguageSelector
                            currentLanguage={this.state.selectedLanguage}
                            onSetLanguage={(translation) => this.setState({selectedLanguage: translation})}
                        />
                        <Button
                            btnStyle='primary'
                            fullWidth={true}
                            label={messages.languageSelectConfirm}
                            onClick={this.onConfirm}
                        />
                    </div>
                }
            />
        );
    }

    @bind private async onConfirm() {
        const { selectedLanguage } = this.state;
        const loggedIn = getLoggedInStatus(getStore().getState());
        if (loggedIn) {
            await UsersApi.changeLanguage({data: {preferredLanguage: selectedLanguage}});
        }
        setLanguageCookie(selectedLanguage);
        window.location.reload();
    }
}
