import { ItemMetadata } from 'labxchange-client';
import { LocationDescriptor } from 'history';
import bind from 'bind-decorator';
import { ROUTES } from 'global/constants';
import * as React from 'react';
import { Button, Modal } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { Redirect } from 'react-router';
import { getYouTubeIdFromUrl, getYoutubeParams } from 'items/youTubeApi';
import { XBlocksApi } from 'global/api';
import { HeadingOne } from 'elements/components/Text';
import { WrappedMessage } from 'utils';
import { getTranscriptFilesFromVideoState } from '../../utils';
import { ItemType } from 'items/models';

import messages from './displayMessages';

interface Props {
    itemMetadata: ItemMetadata;
    onClose(): void;
}

interface State {
    redirectTo?: LocationDescriptor;
    isValidVideo: boolean;
}

export default class CeateAnnotatedVideoModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: undefined,
            isValidVideo: true,
        };
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }
        return (
            <Modal
                size='normal'
                onRequestClose={this.props.onClose}
                showTopBar={true}
                className='item-assign-modal item-assign-annotated-video-modal'
                content={
                    <div className='lx-modal-body'>
                        <div className='col-12'>
                            <img src='/assets/images/annotated-video/annotate-video-logo.png' alt=''/>
                        </div>
                        <div className='col-2' /><div className='col-10'>
                            <HeadingOne level={1} title={messages.createAnnotatedVideoModalHeading.defaultMessage} />
                        </div>
                        <div className='col-12'>
                            <p>
                                {this.state.isValidVideo ?
                                <WrappedMessage
                                message={messages.createAnnotatedVideoModalText}
                                values={{videoName: this.props.itemMetadata.title}}/> : <WrappedMessage
                                message={messages.createAnnotatedVideoModalError}
                                values={{videoName: this.props.itemMetadata.title}}/> }
                            </p>
                        </div>
                        <div>
                        <Button
                            btnStyle='link'
                            onClick={this.props.onClose}
                            label={uiMessages.uiCancelButton}/>
                        <Button
                            btnStyle='primary'
                            onClick={this.onClick}
                            label={messages.createAnnotatedVideoModalButtonText}
                            disabled={!this.state.isValidVideo}
                            className='confirm-annotated-video-creation'
                            />
                        </div>
                    </div>
                }
            />
        );
    }

    @bind private async onClick() {
        try {
            const result = await XBlocksApi.videoDataAndState({id: this.props.itemMetadata.id});
            let params;

            const newTranscriptState = await getTranscriptFilesFromVideoState(
                this.props.itemMetadata.id,
                result,
            );
            if (result.encodedVideos.youtube !== undefined) {
                const youtubeUrl = result.encodedVideos.youtube.url;
                const youTubeVideoId = getYouTubeIdFromUrl(youtubeUrl) || '';
                params = await getYoutubeParams(youTubeVideoId) || {};
                params.blockEditorDefaults.transcripts = newTranscriptState;
            }
            else {
                const vimeoUrl = result.encodedVideos.fallback.url;
                /// TODO is missing get the param details of vimeo videos
                params = {
                    blockEditorDefaults: {
                        youTubeId: '',
                        transcripts: newTranscriptState,
                        annotations: [],
                        html5Sources: [vimeoUrl],
                    }
                };
            }

            const location = {
                pathname: ROUTES.Library.ITEM_NEW_SLUG(ItemType.AnnotatedVideo, ''),
                state: params,
            };
            this.setState({
                redirectTo: location,
            });
        } catch {
            this.setState({
                isValidVideo: false
            });
        }
    }
}
