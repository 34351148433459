import bind from 'bind-decorator';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Truncate from 'react-truncate';

import { AnalyticsApi, SearchApi } from 'global/api';
import { ORG_SORT_OPTIONS, ROUTES } from 'global/constants';
import {
    AnalyticsEventEventTypeEnum,
    OrgSearchRequestOrderingEnum,
    OrgAppliedFilterFilterOnEnum,
    OrganizationResult,
    OrgAppliedFilter,
    OrgSearchRequest,
    OrganizationResultOrganizationTypeEnum,
} from 'labxchange-client';
import libraryMessages from 'library/displayMessages';

import { TaxonomyData, TaxonomyCategory } from 'search/components/Taxonomy';
import {
    AggregationResults,
    buildTags,
    countryFilterName,
    eqSet,
    getAppliedSearchFilters,
    getCurrentPageFromString,
    getPaginationSizeFromString,
    getSortOrderingFromString,
    languagesFilter,
    sanitizeSearchParams,
} from 'search/utils';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { ResponsiveFilteredSearch } from '../ResponsiveFilteredSearch/ResponsiveFilteredSearch';
import messages from './displayMessages';
import { Icon, Button } from 'ui/components';
import { RecommendedItemsContainer } from 'elements';

interface OrgCardProps {
    org: OrganizationResult;
    onVisitProfile?(): void;
}

export const OrgCard: React.FC<OrgCardProps> = ({ org, onVisitProfile }) => {
    let button;
    let target;
    if (org.organizationType === OrganizationResultOrganizationTypeEnum.PARTNER) {
        target = ROUTES.Organizations.PROFILE_SLUG(org.slug);
        button = (
            <Button
                href={target}
                label={messages.viewProfile}
                onClick={onVisitProfile}
            />
        );
    } else {
        target = `${ROUTES.Library.HOME}?t=Partner%3A${org.id}`;
        button = (
            <Button
                href={target}
                label={messages.viewInLibrary}
                btnStyle='outline'
            />
        );
    }

    // bullet point separated stats where the count is at least one
    const stats = [
        [org.clusters, <WrappedMessage message={messages.clustersCount} values={{count: org.clusters }} />],
        [org.pathways, <WrappedMessage message={messages.pathwaysCount} values={{count: org.pathways }} />],
        [org.contentItems, <WrappedMessage message={messages.contentCount} values={{count: org.contentItems }} />],
    ].filter(x => x[0] > 0).map((x, i) => [i > 0 && <> &bull; </>, x[1]]);

    let logo;
    if (org.largeLogoUrl) {
        logo = (
            <div className='org-card-logo'>
                <img src={org.largeLogoUrl} alt='' />
            </div>
        );
    } else {
        logo = (
            <div className='org-card-logo placeholder'>
                <Icon name='skyscraper' zoom='50' fill='#FFF' />
            </div>
        );
    }

    return (
        <NavLink to={target} tabIndex={-1} className='org-card'>
            {logo}
            <div className='org-card-content'>
                <div className='heading'>
                    {org.organizationType === OrganizationResultOrganizationTypeEnum.PARTNER ?
                        <>
                            <div className='icon-title-wrap'>
                                <Icon className='icon' name='collaborator-badge' fill='#003E6B' />
                                <div className='title-wrap'>
                                  <Truncate className='title'>{org.name}</Truncate>
                                </div>
                            </div>
                            <div className='collab'><WrappedMessage message={messages.collaborator} /></div>
                        </>
                        : <Truncate className='title'>{org.name}</Truncate>
                    }
                </div>
                <div className='stats'>
                    <Truncate>{stats}</Truncate>
                </div>
            </div>
            <div className='org-card-btn-wrap'>
                {button}
            </div>
        </NavLink>
    );
};


export const OrgCardSkeleton: React.FC<{}> = () => {
    return (
        <div className='org-card'>
            <div className='org-card-logo skel'>
                <Skeleton width='100%' height='100%' />
            </div>
            <div className='org-card-content'>
                <div className='heading'>
                    <div className='title'>
                        <Skeleton width='70%' />
                    </div>
                    <Skeleton width='40%' height='12px' />
                </div>
                <div className='stats'>
                    <Skeleton height='16px' />
                </div>
            </div>
            <div className='org-card-btn-wrap'>
                <Skeleton />
            </div>
        </div>
    );
};

interface Props {
    searchParams: string;
    onSearchParamsChanged: (newParams: URLSearchParams) => void;
    isModalView: boolean;
    showFilterModal: boolean;
}

interface State {
    orgs: OrganizationResult[];
    availableSearchFilters: ReadonlyArray<TaxonomyData>;
    resultCount: number;
    currentPage: number;
    paginationSize: number;
    sortOrder: OrgSearchRequestOrderingEnum;
    appliedTagIds: ReadonlySet<string>;
    searchKeywords: ReadonlySet<string>;
    loadingNewResults: boolean;
    // How many times we have refreshed the search results. Used to avoid displaying old results.
    refreshCount: number;
    showMore: boolean;
    // Variable used to display recommendations if search results returns empty.
    recommendedOrgs: OrganizationResult[];
}

export class OrganizationSearch extends React.PureComponent<Props, State> {

    private node: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.node = React.createRef<HTMLDivElement>();

        const allowedFilters = new Set(Object.keys(OrgAppliedFilterFilterOnEnum));
        const params = sanitizeSearchParams(props.searchParams || '', allowedFilters);

        this.state = {
            orgs: [],
            currentPage: getCurrentPageFromString(params.get('page')),
            paginationSize: getPaginationSizeFromString(params.get('size')),
            resultCount: 0,
            sortOrder: getSortOrderingFromString(
                params.get('order'),
                OrgSearchRequestOrderingEnum.Relevance,
                ORG_SORT_OPTIONS
            ),
            availableSearchFilters: [],
            appliedTagIds: new Set(params.getAll('t')),
            searchKeywords: new Set(params.getAll('q')),
            loadingNewResults: false,
            refreshCount: 0,
            showMore: false,
            recommendedOrgs: [],
        };
    }

    public componentDidMount() {
        this.refreshSearchResults();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.searchParams !== this.props.searchParams) {
            // Search params have been updated from parent; spread out to state.
            const params = new URLSearchParams(this.props.searchParams);
            this.setState({
                currentPage: getCurrentPageFromString(params.get('page')),
                paginationSize: getPaginationSizeFromString(params.get('size')),
                sortOrder: getSortOrderingFromString(
                    params.get('order'),
                    OrgSearchRequestOrderingEnum.Relevance,
                    ORG_SORT_OPTIONS
                ),
                appliedTagIds: new Set(params.getAll('t')),
                searchKeywords: new Set(params.getAll('q')),
            });
        } else if (
            !eqSet(prevState.appliedTagIds, this.state.appliedTagIds) ||
            prevState.currentPage !== this.state.currentPage ||
            prevState.paginationSize !== this.state.paginationSize ||
            prevState.sortOrder !== this.state.sortOrder ||
            !eqSet(prevState.searchKeywords, this.state.searchKeywords)
        ) {
            this.refreshSearchResults();
            if (this.node.current) {
                const rect = this.node.current.getBoundingClientRect();
                if (rect.top < 0) {
                    this.node.current.scrollIntoView();
                }
            }
        }
    }

    public render() {
        let content;
        if (this.state.loadingNewResults) {
            content = Array(12).fill(
                <OrgCardSkeleton />
            );
        } else {
            content = this.state.orgs.map((org) => (
                <OrgCard key={org.name} org={org} onVisitProfile={() => this.onVisitProfile(org.slug)}/>
            ));
        }

        // Only render recommended orgs if the variable is populated
        const renderRecommended = this.state.recommendedOrgs.length !== 0;
        let recommendedOrgsContent;
        if (renderRecommended) {
            recommendedOrgsContent = this.state.recommendedOrgs.map((org) => (
                <OrgCard key={org.name} org={org} onVisitProfile={() => this.onVisitProfile(org.slug)}/>
            ));
        }

        return (
            <ResponsiveFilteredSearch
                allowFiltering={true}
                appliedFilterIds={this.state.appliedTagIds}
                availableSearchFilters={this.state.availableSearchFilters}
                currentPage={this.state.currentPage}
                onFiltersChanged={this.onFiltersChanged}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
                onSortChange={this.onSortChange}
                paginationSize={this.state.paginationSize}
                resultCount={this.state.resultCount}
                searchKeywords={this.state.searchKeywords}
                showSearchForTaxonomies={[]}
                sortOrder={this.state.sortOrder}
                sortOrderOptions={ORG_SORT_OPTIONS}
                loadingNewResults={this.state.loadingNewResults}
                loadMore={this.loadMore}
                maxPagesDisplayed={60}
                showFilterModal={this.props.showFilterModal}
            >
                <div className='org-cards-list-container'>
                    {content}
                </div>
                { renderRecommended &&
                    <RecommendedItemsContainer>
                        {recommendedOrgsContent}
                    </RecommendedItemsContainer>
                }
            </ResponsiveFilteredSearch>
        );
    }

    @bind private onParamsChanged() {
        const params = new URLSearchParams();
        this.state.appliedTagIds.forEach((tagID)  => { params.append('t', tagID); });
        this.state.searchKeywords.forEach((kw)  => { params.append('q', kw); });
        params.set('page', this.state.currentPage.toString());
        params.set('size', this.state.paginationSize.toString());
        params.set('order', this.state.sortOrder);
        this.props.onSearchParamsChanged(params);
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.OrganizationSearchPerformed,
                data: {
                    url: window.location.href,
                    pathname: window.location.pathname,
                },
            },
        });
    }

    @bind private onSizeChange(paginationSize: number) {
        this.setState({
            paginationSize,
        }, this.onParamsChanged);
    }

    @bind private onSortChange(sortOrder: string) {
        this.setState({
            sortOrder: sortOrder as OrgSearchRequestOrderingEnum,
            currentPage: 1,
        }, this.onParamsChanged);
    }

    @bind private loadMore() {
        this.setState({
            showMore: true,
            currentPage: this.state.currentPage + 1
        });
    }

    @bind private onPageChange(newPage: number) {
        if (newPage === this.state.currentPage) { return; }

        this.setState({currentPage: newPage}, this.onParamsChanged);

        // record this page change event using the analytics API:
        let eventType: AnalyticsEventEventTypeEnum;
        if (newPage === this.state.currentPage + 1) {
            eventType = AnalyticsEventEventTypeEnum.OrganizationSearchNextPageVisited;
        } else if (newPage === this.state.currentPage - 1) {
            eventType = AnalyticsEventEventTypeEnum.OrganizationSearchPreviousPageVisited;
        } else {
            eventType = AnalyticsEventEventTypeEnum.OrganizationSearchPageNumberVisited;
        }
        AnalyticsApi.create({data: {
            eventType, data: {url: window.location.href, pathname: window.location.pathname},
        }});
    }

    @bind private onFiltersChanged(newFilters: ReadonlySet<string>, searchKeywords: ReadonlySet<string>): void {
        this.setState({
            currentPage: 1,
            appliedTagIds: newFilters,
            orgs: [],
            searchKeywords,
        }, this.onParamsChanged);
    }

    private async refreshSearchResults() {
        const thisRefreshCount = this.state.refreshCount + 1; // Used to track which response matches which request
        this.setState({refreshCount: this.state.refreshCount + 1});
        const filters = Array.from(getAppliedSearchFilters(this.state.appliedTagIds)) as OrgAppliedFilter[];
        const searchRequest: OrgSearchRequest = {
            filters,
            keywords: Array.from(this.state.searchKeywords).join(' '),
            currentPage: this.state.currentPage,
            paginationSize: this.state.paginationSize,
            ordering: this.state.sortOrder,
        };

        this.setState({loadingNewResults: true});
        let responseData;
        try {
            responseData = await SearchApi.organizationSearch({data: searchRequest});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={libraryMessages.searchError} />, {exception: err});
            this.setState({
                orgs: [],
                resultCount: 0,
                loadingNewResults: false,
            });
            return;
        }

        // Now, the filters or pagination may have changed while the results were loading.
        // If so, we need to just discard these results and wait for the new ones.
        if (thisRefreshCount !== this.state.refreshCount) {
            return;
        }

        let orgs: OrganizationResult[] = responseData.results;
        if (this.state.showMore) {
            orgs = [...this.state.orgs].concat(orgs);
        }

        const newState: Partial<State> = {
            orgs,
            resultCount: responseData.count,
            availableSearchFilters: buildFilterOptionsFromSearchData(responseData.aggregations),
            loadingNewResults: false,
            recommendedOrgs: [],
        };

        // If no responses are found, fetch 12 most popular organizations
        // and display them as recommended items.
        if (responseData.count === 0) {
            let recommendationResponseData;
            try {
                recommendationResponseData = await SearchApi.organizationSearch({data: {
                    ordering: OrgSearchRequestOrderingEnum.Popularity,
                    paginationSize: 12,
                }});
                newState.recommendedOrgs = recommendationResponseData.results;
            } catch (err) {
                // Skip this exception silently
            }
        }

        this.setState(newState as State, () => {
            // NOW we can sanitize the page number, because we have the
            // paginationSize and the resultCount set.
            const lastPage = Math.ceil(this.state.resultCount / this.state.paginationSize);
            if (this.state.currentPage > lastPage && lastPage > 0) {
                this.onPageChange(lastPage);
            }
        });
    }

    private onVisitProfile(slug: string) {
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.OrganizationLabXchangeProfileVisited,
                orgSlug: slug,
                data: {
                    from: 'source_page',
                    pathname: window.location.pathname,
                },
            },
        });
    }
}

// The OrganizationType filter is temporarily disabled (see commented code below),
// while the LX team decide its future.

// const nameForOrgType = (key: string) => {
//     if (key === 'collaborator') {
//         return intl.formatMessage(libraryMessages.orgTypeCollaborator);
//     } else {
//         return intl.formatMessage(libraryMessages.orgTypeContentSource);
//     }
// };

const buildFilterOptionsFromSearchData = (data: AggregationResults) => ([
    // buildTags(data, OrgAppliedFilterFilterOnEnum.OrganizationType, TaxonomyCategory.OrganizationType, nameForOrgType),
    buildTags(data, OrgAppliedFilterFilterOnEnum.Country, TaxonomyCategory.Country, countryFilterName),
    languagesFilter,
]);
