import { bind } from 'bind-decorator';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { createAnalyticsEventWithDelay } from 'tracking/utils';
import { getItemTypeMeta } from 'items/models';
import {
    AnalyticsEventEventTypeEnum,
    ItemMetadata,
    RelatedItemMetadata,
    RelatedItemMetadataTypeEnum,
} from 'labxchange-client';
import messages from 'library/displayMessages';
import { detailUrlForEntity } from 'library/utils';
import { Button, Icon } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';

interface Props {
    pathwayMetadata: ItemMetadata;
    pathwayItems: ItemMetadata[];
    currentItemIndex: number;
    next?: RelatedItemMetadata;
}

interface State {
    selectedItemIndex: number;
}

/**
 * The "Preview Next Box" is shown at the bottom right of the item page
 * when viewing an item that is part of a pathway. It shows a thumbnail
 * of the next item in the pathway (and a carousel of thumbnails of all
 * the other items in the pathway).
 */
export class PreviewNextBox extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        const itemCount = props.pathwayItems.length;
        const selectedItemIndex = Math.min(props.currentItemIndex + 1, itemCount - 1);
        this.state = {selectedItemIndex};
    }

    public renderButtons() {
        const pathway = this.props.pathwayMetadata;
        const index = this.props.currentItemIndex;
        const nextItem = this.props.pathwayItems[index + 1];
        let showNext = true;

        // Next button will either go to the next asset in the pathway, or if a
        // book exists, the next asset in the book, or show a 'complete' button
        // instead if last asset in book.
        let nextRoute;
        if (nextItem) {
            nextRoute = detailUrlForEntity(nextItem, pathway.id);
        } else if (pathway.book && this.props.next) {
            nextRoute = detailUrlForEntity(this.props.next);
            // if we're going to a textbook next, then we're at the last item
            // in the book, so show 'complete' instead of 'next'.
            if (this.props.next.type === RelatedItemMetadataTypeEnum.Book) {
                showNext = false;
            }
        } else {
            showNext = false;
            nextRoute = detailUrlForEntity(pathway);
        }

        // Back button will either go to the previous asset in the pathway, or
        // jump back to the pathway page.
        let backRoute;
        if (index === 0) {
            backRoute = detailUrlForEntity(pathway);
        } else {
            backRoute = detailUrlForEntity(this.props.pathwayItems[index - 1], pathway.id);
        }

        // TODO: do we want an analytics event for onClickBackButton too?
        return (
            <div className='back-next-buttons'>
                <Button
                    className='back-button'
                    btnStyle='outline'
                    label={messages.pathwayBack}
                    icon='chevron-left'
                    href={backRoute}
                />
                {showNext ?
                    <Button
                        btnStyle='primary'
                        label={messages.pathwayStartNext}
                        icon='chevron-right'
                        iconPosition='right'
                        href={nextRoute}
                        onClick={this.onClickNextButton}
                    />
                :
                    <Button
                        btnStyle='primary'
                        className='pathway-complete'
                        label={messages.pathwayComplete}
                        href={nextRoute}
                    />
                }
            </div>
        );
    }

    public render() {
        const pathway = this.props.pathwayMetadata;
        const pathwayItems = this.props.pathwayItems;
        const selectedItemIndex = this.state.selectedItemIndex;
        const selectedItem = pathwayItems[selectedItemIndex];
        const backgroundUrl = selectedItem.imageUrl || '/assets/images/default-featured.png';

        return (
            <div className='pathway-preview col-12 col-md-4 col-lg-12'>
                <h2><WrappedMessage message={messages.pathwayPreviewNext}/></h2>

                <div className='pathway-item-image'
                     style={{backgroundImage: `url('${backgroundUrl}')`}}>

                    <button className={`lx-btn previous-button ${selectedItemIndex > 1 ? '' : 'invisible'}`}
                        disabled={selectedItemIndex <= 0}
                        onClick={this.goToPrevious}>
                        <Icon name='chevron-left' />
                        <WrappedMessage message={uiMessages.uiPrevious} />
                    </button>

                    <NavLink
                        to={detailUrlForEntity(selectedItem, pathway.id)}
                        className='item-title'
                    >
                        <Icon name={getItemTypeMeta(selectedItem.type).icon} />
                        {selectedItemIndex + 1}. {selectedItem.title}
                    </NavLink>

                    <button className='lx-btn next-button'
                            disabled={selectedItemIndex >= pathwayItems.length - 1}
                            onClick={this.goToNext}>
                        <Icon name='chevron-right'/>
                        <WrappedMessage message={uiMessages.uiNext}/>
                    </button>

                    {pathwayItems.length <= 15 ?
                        <div className='progress-dots'>
                            {pathwayItems.map((item, itemIdx) => (
                                <span key={item.id} data-selected={itemIdx === selectedItemIndex}/>
                            ))}
                        </div>
                        : null
                    }
                </div>

                {this.renderButtons()}
            </div>
        );
    }

    @bind private goToPrevious(event: React.MouseEvent<HTMLButtonElement>) {
        const selectedItemIndex = this.state.selectedItemIndex;
        if (selectedItemIndex > 0) {
            this.setState({selectedItemIndex: selectedItemIndex - 1});
        }
    }

    @bind private goToNext(event: React.MouseEvent<HTMLButtonElement>) {
        const selectedItemIndex = this.state.selectedItemIndex;
        if (selectedItemIndex < this.props.pathwayItems.length - 1) {
            this.setState({selectedItemIndex: selectedItemIndex + 1});
        }
    }

    @bind private onClickNextButton() {
        createAnalyticsEventWithDelay({
            data: {
                eventType: AnalyticsEventEventTypeEnum.PathwayAssetNextClicked,
                objectId: this.props.pathwayMetadata.id,
            },
        });
    }
}
