import { DiscussionsAppliedFilterFilterOnEnum } from 'labxchange-client';
import { MutableTagData} from 'search/components/Tag';
import { TaxonomyCategory, TaxonomyData } from 'search/components/Taxonomy';
import { AggregationResults, buildTags } from 'search/utils';

/**
 * Generate a URL that will display a search page, filtered with the given keywords/tags.
 *
 * @param baseUrl - intended to be of the form ROUTES.<page>.HOME
 * @param keywords - items to be prefixed with `q=` in the url
 * @param tagIds - items to be prefixed with `t=` in the url
 */

export const DiscussionsSearchFilter = DiscussionsAppliedFilterFilterOnEnum;
export type DiscussionsSearchFilter = DiscussionsAppliedFilterFilterOnEnum;

interface BucketItemType {
    name?: AggregationResultsType;
    sub_category?: AggregationResultsType;
    key: string;
    doc_count: number;
}

interface AggregationResultsType {
    doc_count_error_upper_bound?: number;
    sum_other_doc_count?: number;
    buckets?: BucketItemType[];
}

interface DiscussionAggregationResults {
    [key: string]: AggregationResultsType;
}

export function buildFilterOptionsFromSearchData(aggregationsData: DiscussionAggregationResults): TaxonomyData[] {
    // Category and SubCategory filter is a special case because it has two levels of tags:
    const categoryTags: MutableTagData[] = [];

    const getCategoryName = (key: string, nameAggregation?: AggregationResultsType) => {
        if (!nameAggregation) {
            return key;
        }
        return nameAggregation.buckets![0].key;
    };
    for (const {key, doc_count, name, sub_category} of aggregationsData[DiscussionsSearchFilter.Category].buckets!) {
        const categoryName = getCategoryName(key, name);
        const tag: MutableTagData = {id: `${DiscussionsSearchFilter.Category}:${key}`, name: categoryName, childTags: [], numEntities: doc_count};
        if (categoryName !== 'Assets') {
            for (const subCategoryBucket of sub_category?.buckets!) {
                tag.childTags.push({id: `${DiscussionsSearchFilter.SubCategory}:${subCategoryBucket.key}`, name: getCategoryName(subCategoryBucket.key, subCategoryBucket.name), childTags: [], numEntities: subCategoryBucket.doc_count});
            }
        }
        categoryTags.push(tag);
    }

    // Category Aggregation contains the name of the category inside the first bucket
    const categoryFilter: TaxonomyData = {
        id: DiscussionsSearchFilter.Category,
        category: TaxonomyCategory.Forum,
        tags: categoryTags,
    };

    const tagFilter = buildTags(aggregationsData as AggregationResults, DiscussionsSearchFilter.Tag, TaxonomyCategory.PopularTags);

    return [
        categoryFilter,
        tagFilter,
    ];
}
