import { defineMessages } from 'react-intl';

const messages = defineMessages({
    buttonSave: {
        id: 'buttonSave',
        defaultMessage: 'Save',
    },
    buttonCancel: {
        id: 'buttonCancel',
        defaultMessage: 'Cancel',
    },
    minimumAgeNotice: {
        id:'minimumAgeNotice',
        defaultMessage: 'You must be over 13 years old to register for LabXchange.',
        description: 'Noticed displayed below the age selection field indicading the age restriction to be a LabXchange user.',
    },
    minimumEducatorAgeNotice: {
        id:'minimumEducatorAgeNotice',
        defaultMessage: 'You must be over 18 years old to have a educator profile.',
        description: 'Noticed displayed below the age selection field indicading the age restriction to be a LabXchange educator.',
    },
});

export default messages;
