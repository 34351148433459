// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Conversation,
    ConversationFromJSON,
    ConversationToJSON,
    ConversationInfo,
    ConversationInfoFromJSON,
    ConversationInfoToJSON,
    ConversationListCount,
    ConversationListCountFromJSON,
    ConversationListCountToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConversationList
 */
export interface ConversationList {
    /**
     * 
     * @type {ConversationListCount}
     * @memberof ConversationList
     */
    count: ConversationListCount;
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof ConversationList
     */
    conversations: Array<Conversation>;
    /**
     * 
     * @type {Array<ConversationInfo>}
     * @memberof ConversationList
     */
    conversationsInfos: Array<ConversationInfo>;
}

export function ConversationListFromJSON(json: any): ConversationList {
    return {
        'count': ConversationListCountFromJSON(json['count']),
        'conversations': (json['conversations'] as Array<any>).map(ConversationFromJSON),
        'conversationsInfos': (json['conversations_infos'] as Array<any>).map(ConversationInfoFromJSON),
    };
}

export function ConversationListToJSON(value?: ConversationList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': ConversationListCountToJSON(value.count),
        'conversations': (value.conversations as Array<any>).map(ConversationToJSON),
        'conversations_infos': (value.conversationsInfos as Array<any>).map(ConversationInfoToJSON),
    };
}


