import { defineMessages } from 'react-intl';

const messages = defineMessages({
    orgProfileNotFound: {
        id:'orgProfileNotFound',
        defaultMessage: 'The organization profile could not be found.',
        description: 'Text when the organization profile is not found',
    },
    pageTitle: {
        id:'pageTitleOrgProfile',
        defaultMessage: 'Organization {name}',
        description: 'HTML title for the organization profile page',
    },
    loadFailed: {
        id:'loadFailed',
        defaultMessage: 'The organization profile could not be found.',
        description: 'Message displayed when a background request on the organization profile page fails.',
    },
    paginationSizeLabel: {
        id:'paginationSizeLabel',
        defaultMessage: 'Show',
        description: 'Help text for the pagination size label.',
    },
    sortByLabel: {
        id:'sortByLabel',
        defaultMessage: 'Sort by',
        description: 'Help text for the sortable threads.',
    },
});

export default messages;
