import { bind } from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'global/state';
import { LXThunkUnwrap } from 'global/types';
import { fetchSubjectTags } from 'library/actions';
import { GlobalLibraryState } from 'library/reducers';
import { MutableTagData, TagData } from 'search/components/Tag';
import { Taxonomy, TaxonomyCategory } from 'search/components/Taxonomy';

interface OwnProps {
    /** The list of selected tags like "Biological Sciences:Biotechnology" */
    selectedSubjectTags: ReadonlySet<string>;
    onSelectedTagsChanged: (newTags: ReadonlySet<string>) => void;
    titleSubjectsAmount?: number;
    preventLoading?: boolean;
}
interface ActionProps {
    fetchSubjectTags: typeof fetchSubjectTags;
}

interface FlatSubject {
    [index: string]: string;
}

interface StateProps {
    allSubjectTags: TagData[];
    flatObjects: FlatSubject;
    allSubjectTagsLoadingStatus: GlobalLibraryState['subjectTagsStatus'];
}
interface Props extends OwnProps, LXThunkUnwrap<ActionProps>, StateProps {}

interface State {
    isOpen: boolean;
}

interface State {
    isOpen: boolean;
}

/**
 * The Subject Tags Select Widget renders a tree view for selecting subject [area] tags
 */
class _SubjectTagsSelect extends React.PureComponent<Props, State> { // tslint:disable-line: class-name

    private dropdownRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {isOpen: false};
        this.dropdownRef = React.createRef();
    }

    componentDidMount() {
        if (!this.props.preventLoading) {
            this.loadSubjectTags();
        }
    }

    public componentWillMount() {
        /* Add event listener to window, not document, so that it can be
         * prevented via React.Event.stopPropagation().  See:
         * https://github.com/facebook/react/issues/4335#issuecomment-421705171
         */
        window.addEventListener('mousedown', this.handleClick);
        window.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClick);
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    public render() {
        return (
            <Taxonomy
                id='Subject Tags'
                // TODO: don't override title; it's not i18n
                category={TaxonomyCategory.SubjectArea} // Hidden because we override 'title' below
                isOpen={this.state.isOpen}
                isSelected={false}
                className='subject-area-widget'
                title={this.getSubjectAreaInputTitle()}
                displayCount={false}
                onToggleShow={this.onToggle}
                onToggleTagSelect={this.onSelectedTagsChanged}
                tags={this.tagsListWithStatusMessage}
                selectedTagIds={this.props.selectedSubjectTags}
                nodeRef={this.dropdownRef}
            />
        );
    }

    private getSubjectAreaInputTitle(): string {
        const tags = Array.from(this.props.selectedSubjectTags).slice();
        tags.sort();
        if (tags.length === 0) {
            return '---';
        }

        // Display one subject by default
        const titleSubjectsAmount = this.props.titleSubjectsAmount || 1;
        const visibleTags: string[] = (tags as string[]).slice(0, titleSubjectsAmount).map((tag: string) =>
            tag.split(':', 2).slice(-1)[0]
        );

        const firstTagNameTranslation = visibleTags.map(item => this.props.flatObjects[item] || item);
        const visibleLabelInputs: string = firstTagNameTranslation.join(', ');

        const restTagsAmount = tags.length - titleSubjectsAmount;
        if (restTagsAmount > 0) {
            return `${visibleLabelInputs} +${restTagsAmount}`;
        }
        return visibleLabelInputs;
    }

    @bind private onToggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    // Close the dropdown if user clicks anywhere outside of the dropdown panel.
    @bind private handleClick(event: MouseEvent) {
        if (this.state.isOpen) {
            const element = event.target as HTMLElement;
            if (!this.dropdownRef.current || this.dropdownRef.current.contains(element)) {
                return;
            }
            this.setState({isOpen: false});
        }
    }

    @bind private handleKeyUp(event: KeyboardEvent) {
        if (this.state.isOpen) {
            switch (event.key) {
            case 'Escape':
            case 'Esc':
                this.setState({isOpen: false});
                break;
            case 'Tab':
                if (this.dropdownRef.current && document.activeElement &&
                    !this.dropdownRef.current.contains(document.activeElement)) {
                    this.setState({isOpen: false});
                }
                break;
            }
        }
    }

    private loadSubjectTags() {
        // Load subject tags list on demand, the first time the widget is opened
        if (this.props.allSubjectTagsLoadingStatus === 'not-loaded') {
            this.props.fetchSubjectTags();
        }
    }

    private get tagsListWithStatusMessage(): TagData[] {
        if (this.props.allSubjectTagsLoadingStatus === 'error') {
            return [{id: 'error', name: 'Error loading subject areas'}];
        } else if (this.props.allSubjectTagsLoadingStatus === 'loading') {
            return [{id: 'error', name: 'Loading...'}];
        } else {
            return this.props.allSubjectTags;
        }
    }

    @bind private onSelectedTagsChanged(newTags: ReadonlySet<string>) {
        // Only allow changing tags if the list of all tags loaded successfully.
        if (this.props.allSubjectTagsLoadingStatus === 'loaded') {
            this.props.onSelectedTagsChanged(newTags);
        }
    }
}

/**
 * The Subject Tags Select Widget renders a tree view for selecting subject [area] tags
 */
export const SubjectTagsSelect = connect<StateProps, ActionProps, OwnProps, RootState>(
    (state: RootState, ownProps: OwnProps): StateProps => {
        // Convert from the global list of possible subject tag strings like
        // "Biological Sciences:Biochemistry" to the TagData[] format used by
        // our <Taxonomy> UI widget:
        const tagData: MutableTagData[] = [];
        let flatObjects: FlatSubject = {};
        for (const { name, subjectId } of state.libraryState.subjectTags) {
            const [ subjectArea, subjectTag ] = subjectId!.split(':', 2);
            const key = subjectTag || subjectArea;
            // Translation objects: {[key: subject id]: [val: subject translation]
            flatObjects = {
                [key]: name || subjectTag,
                ...flatObjects,
            };

            // Has the subject area been added already?
            let subjectAreaEntry = tagData.find((sa) => sa.id === subjectArea);
            if (subjectAreaEntry === undefined && subjectArea !== 'Other') {
                tagData.push(subjectAreaEntry = {
                    id: subjectArea, // e.g. "Biological Sciences"
                    name: subjectArea, // e.g. "Biological Sciences"
                    childTags: [],
                });
            }
            if (subjectTag) {
                subjectAreaEntry?.childTags.push({
                    id: subjectId!, // e.g. "Biological Sciences:Biochemistry"
                    name: subjectTag, // e.g. "Biochemistry"
                });
            }
        }

        // Apply translations
        const result: MutableTagData[] = [];
        for (const item of tagData) {
            const resultItem = {...item};
            const itemTranslation = flatObjects[resultItem.id];
            if (itemTranslation) {
                resultItem.name = itemTranslation;
            }

            const childTags = [];
            for (const child of resultItem.childTags) {
                const subjectTagID = child.id!.split(':', 2)[1];
                const childTranslation = flatObjects[subjectTagID];
                if (childTranslation) {
                    child.name = childTranslation;
                }
                childTags.push(child);
            }
            resultItem.childTags = childTags;
            result.push(resultItem);
        }
        return {
            flatObjects,
            allSubjectTags: result,
            allSubjectTagsLoadingStatus: state.libraryState.subjectTagsStatus,
        };
    }, {
        fetchSubjectTags,
    },
)(_SubjectTagsSelect);
