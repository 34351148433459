// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemProgress,
    ItemProgressFromJSON,
    ItemProgressToJSON,
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomItem
 */
export interface ClassroomItem {
    /**
     * 
     * @type {string}
     * @memberof ClassroomItem
     */
    id: string;
    /**
     * 
     * @type {ItemResponse}
     * @memberof ClassroomItem
     */
    item: ItemResponse;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomItem
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomItem
     */
    updated: Date;
    /**
     * 
     * @type {Array<ItemProgress>}
     * @memberof ClassroomItem
     */
    children: Array<ItemProgress>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomItem
     */
    classroom: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomItem
     */
    status: ClassroomItemStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ClassroomItem
     */
    order: number;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomItem
     */
    postedDate: Date;
}

export function ClassroomItemFromJSON(json: any): ClassroomItem {
    return {
        'id': json['id'],
        'item': ItemResponseFromJSON(json['item']),
        'created': new Date(json['created']),
        'updated': new Date(json['updated']),
        'children': (json['children'] as Array<any>).map(ItemProgressFromJSON),
        'classroom': json['classroom'],
        'status': json['status'],
        'order': json['order'],
        'postedDate': new Date(json['posted_date']),
    };
}

export function ClassroomItemToJSON(value?: ClassroomItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'item': ItemResponseToJSON(value.item),
        'created': value.created.toISOString(),
        'updated': value.updated.toISOString(),
        'children': (value.children as Array<any>).map(ItemProgressToJSON),
        'classroom': value.classroom,
        'status': value.status,
        'order': value.order,
        'posted_date': value.postedDate.toISOString(),
    };
}

/**
* @export
* @enum {string}
*/
export enum ClassroomItemStatusEnum {
    Unposted = 'unposted',
    Posted = 'posted'
}


