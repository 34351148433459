import { defineMessages } from 'react-intl';

const messages = defineMessages({
    composePlaceholder: {
        id:'composePlaceholder',
        defaultMessage: 'Message {username}',
        description: 'Placeholder for the compose text area.',
    },
    composeCancelMessage: {
        id:'composeCancelMessage',
        defaultMessage: 'Cancel',
        description: 'Compose cancel link text.',
    },
    composeSendMessage: {
        id:'composeSendMessage',
        defaultMessage: 'Send message',
        description: 'Compose submit button text.',
    },
});

export default messages;
