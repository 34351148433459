// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Ability,
    AbilityFromJSON,
    AbilityToJSON,
    Skill,
    SkillFromJSON,
    SkillToJSON,
} from './';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {Array<Skill>}
     * @memberof Task
     */
    skills: Array<Skill>;
    /**
     * 
     * @type {Array<Ability>}
     * @memberof Task
     */
    abilities: Array<Ability>;
    /**
     * The long name of the task.
     * @type {string}
     * @memberof Task
     */
    longName: string;
    /**
     * Description of the task.
     * @type {string}
     * @memberof Task
     */
    description: string;
    /**
     * The duty associated with this task.
     * @type {number}
     * @memberof Task
     */
    duty: number;
}

export function TaskFromJSON(json: any): Task {
    return {
        'skills': (json['skills'] as Array<any>).map(SkillFromJSON),
        'abilities': (json['abilities'] as Array<any>).map(AbilityFromJSON),
        'longName': json['long_name'],
        'description': json['description'],
        'duty': json['duty'],
    };
}

export function TaskToJSON(value?: Task): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'skills': (value.skills as Array<any>).map(SkillToJSON),
        'abilities': (value.abilities as Array<any>).map(AbilityToJSON),
        'long_name': value.longName,
        'description': value.description,
        'duty': value.duty,
    };
}


