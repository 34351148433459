import React from 'react';
import Select, { ValueType} from 'react-select';
import { Option } from './types';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { PopularSearchQueryContainer, PopularSearchQueryContainerSkeleton } from './PopularSearchQueryContainer';
import { getLoggedInStatus } from '../../../auth/selectors';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Icon } from 'elements';
import Skeleton from 'react-loading-skeleton';


interface UserHeaderProps {
  selectedSubject: string;
  onSubjectSelection: (selectedOption: ValueType<Option>) => void;
  layoutSize: string;
  loading?: boolean;
}

const subjectOptions : Option[] = [
  {
    value: 'biology',
    label: 'Biology'
  },
  {
    value: 'physics',
    label: 'Physics'
  },
  {
    value: 'chemistry',
    label: 'Chemistry'
  },
  {
    value: 'allSubjects',
    label: (
      <span>
        All subjects <Icon name='arrow-link-tilted' />
      </span>
    )
  }
];

const UserHeader: React.FC<UserHeaderProps> = (props) => {

  const renderLoggedOutSkeleton = () => {
    return (
      <div className='content-section'>
        <Skeleton width={40} height={48} />
        <Skeleton width={200} height={48} />
      </div>
    );
  };

  const renderLoggedInSkeleton = () => {
    if (!isMobile) {
      return (
        <div className='content-section skeleton-center-placement'>
            <PopularSearchQueryContainerSkeleton />
        </div>
      );
    }
    return renderLoggedOutSkeleton();
  };

  const showSearchView = useSelector(getLoggedInStatus);
  const isMobile = props.layoutSize === 'mobile' || props.layoutSize === 'small' || props.layoutSize === 'medium';

  return (
    <header className={classNames('user-header', {'search-placement': showSearchView && !isMobile})}>
    {(showSearchView && !isMobile) ? (
      props.loading ? renderLoggedInSkeleton() : (
          <PopularSearchQueryContainer
              selectedSubject={props.selectedSubject}
              isHeroSection
              subjectOptions={subjectOptions}
              onSubjectSelection={props.onSubjectSelection}
          />
      )
    ) : ( props.loading ? renderLoggedOutSkeleton() :
      <div className='content-section'>
        <h1>{<WrappedMessage message={messages.teachTextForHeader}/>}</h1>
        <Select
          className='subject-select'
          classNamePrefix='subject'
          options={subjectOptions}
          onChange={props.onSubjectSelection}
          defaultValue={subjectOptions[0]}
          isSearchable={false}
        />
      </div>
    )}
  </header>

  );
};

export default UserHeader;
