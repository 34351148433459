import { bind } from 'bind-decorator';
import { MentorshipsApi } from 'global/api';
import { Mentorship, MentorshipStatusEnum, Profile } from 'labxchange-client';
import * as React from 'react';
import { Button } from 'ui/components';
import { CheckBox, Modal } from 'ui/components';
import { WrappedMessage } from 'utils';
import { UserAvatar } from '../../../user/components/UserAvatar';
import messages from './displayMessages';
import { intl } from 'i18n';

interface Props {
    profile: Profile;
    loggedInUsername: string;
    onClose: () => void;
    onSuccess: (mentorship: Mentorship) => void;
    onError: () => void;
}

interface State {
    loading: boolean;
    consentChecked: boolean;
    helpValue: string;
    helpSubjectValue: string;
    requestReasonDescription: string;
}

export class ApplyByMenteeModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            helpValue: '',
            helpSubjectValue: '',
            consentChecked: false,
            requestReasonDescription: '',
        };
    }

    public render() {
        return (
            <Modal
                className='apply-by-mentee-modal'
                onRequestClose={this.props.onClose}
                content={
                    <>
                        <div className='mentor-description'>
                            <div className='avatar'>
                                <UserAvatar username={this.props.profile.username}/></div>
                            <div>
                                <div className='name'>{this.props.profile.fullName}</div>
                                <div className='message'>
                                    {this.props.profile.mentorship?.message}
                                </div>
                            </div>
                        </div>
                        <label>
                            <WrappedMessage message={messages.helpSubjectQuestion}/>
                            <select
                                className='lx-select form-control'
                                onBlur={this.handleHelpSubjectChange}
                                onChange={this.handleHelpSubjectChange}
                                required aria-required={true}
                            >
                                <option value=''>
                                    {intl.formatMessage(messages.selectDefaultOption)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.biologicalSciencesHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.chemistryHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.healthScienceHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.globalHealthHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.scienceSocietyHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.scientificProcessHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.prepareCollegeHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.prepareGradSchoolHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.prepareCareersHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.professionalDevelopmentHelp)}
                                </option>
                                <option>
                                    {intl.formatMessage(messages.otherHelp)}
                                </option>
                            </select>
                        </label>
                        <label>
                            <WrappedMessage message={messages.helpQuestion}/>
                            <select
                                className='lx-select form-control'
                                onBlur={this.handleHelpChange}
                                onChange={this.handleHelpChange}
                                required aria-required={true}
                            >
                                <option value=''>
                                    {intl.formatMessage(messages.selectDefaultOption)}
                                </option>
                                <option>{intl.formatMessage(messages.academicsHelp)}</option>
                                <option>{intl.formatMessage(messages.teachingHelp)}</option>
                                <option>{intl.formatMessage(messages.researchHelp)}</option>
                                <option>{intl.formatMessage(messages.careerHelp)}</option>
                                <option>{intl.formatMessage(messages.otherHelp)}</option>
                            </select>
                        </label>
                        <label>
                            <WrappedMessage
                                message={messages.mentorRequestReasonLabel}
                                values={{ fullName: this.props.profile.fullName }}
                            />
                            <textarea
                                className='form-control'
                                onChange={this.onRequestReasonChange}
                            />
                        </label>
                        <CheckBox
                            checked={this.state.consentChecked}
                            onClick={(value: boolean) => this.setState({ consentChecked: value })}>
                            <WrappedMessage
                                message={messages.consentCheckboxLabel}
                                values={{ fullName: this.props.profile.fullName }}
                            />
                        </CheckBox>
                        <div className='mentorship-description'>
                            <h2><WrappedMessage message={messages.mentorDescriptionTitle}/></h2>
                            <p><WrappedMessage message={messages.mentorDescriptionText}/></p>
                        </div>
                    </>
                }
                footer={
                    <>
                        <Button
                            btnStyle='link'
                            onClick={this.props.onClose}
                            label={messages.cancelButton}
                        />
                        <Button
                            btnStyle='primary'
                            disabled={this.state.loading || !this.isValid()}
                            label={messages.sendApplicationButton}
                            onClick={this.onRequestMentorship}
                        />
                    </>
                }
            />
        );
    }

    @bind private onRequestReasonChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ requestReasonDescription: e.target.value });
    }

    @bind private handleHelpChange(event: React.FormEvent<HTMLSelectElement>) {
        this.setState({ helpValue: event.currentTarget.value });
    }

    @bind private handleHelpSubjectChange(event: React.FormEvent<HTMLSelectElement>) {
        this.setState({ helpSubjectValue: event.currentTarget.value });
    }

    private isValid(): boolean {
        return Boolean(
            this.state.helpSubjectValue &&
            this.state.helpValue &&
            this.state.consentChecked &&
            this.state.requestReasonDescription
        );
    }

    @bind private async onRequestMentorship() {
        this.setState({ loading: true });

        try {
            const mentorship = await MentorshipsApi.applyByMentee({data: {
                mentor: this.props.profile.username,
                pendingReason: this.state.helpValue,
                pendingSubject: this.state.helpSubjectValue,
                reasonDescription: this.state.requestReasonDescription,
            }});

            this.setState({ loading: false });

            if (
                [
                    MentorshipStatusEnum.Pending,
                    MentorshipStatusEnum.ReRequested,
                ].includes(mentorship.status)
            ) {
                this.props.onSuccess(mentorship);
            } else {
                this.props.onError();
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
            this.setState({ loading: false });
            this.props.onError();
        }
    }
}
