import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { intl } from 'i18n';

import { Icon, CollapsibleWithChevron } from 'elements';
import { ROUTES } from 'global/constants';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import {
    Button,
    LabXChangeLogo,
    LanguageSwitcherModal,
} from 'ui/components';
import messages from '../displayMessages';
import { EVENT_NAMES } from '../../../tracking/constants';
import { analyticsInstance } from '../../../tracking';
import { ProfileRoleEnum } from 'labxchange-client';

interface MobileSidebarProps {
    visible: boolean;
    username: string;
    name: string;
    notificationCount: number;
    onLogIn: () => void;
    onLogOut: () => void;
    onSignup: () => void;
    onHideSidebar: () => void;
    userRole?: null | string;
}

interface State {
    showLanguageSwitcher: boolean;
}

export class MobileSidebar extends React.PureComponent<MobileSidebarProps, State> {
    constructor(props: MobileSidebarProps) {
        super(props);
        this.state = {
            showLanguageSwitcher: false,
        };
    }

    public async componentDidUpdate(prevProps: MobileSidebarProps) {
        // if we're transitioning from closed to open, we need to focus the first element in the sidebar for a11y
        if (!prevProps.visible && this.props.visible) {
            document.querySelector<HTMLElement>('.sidebar-container a')?.focus();
        }
    }

    public render() {
        if (!this.props.visible) {
            return null;
        }

        return (
            <nav className='sidebar-container'>
                {this.state.showLanguageSwitcher &&
                    <LanguageSwitcherModal onClose={() => this.setState({ showLanguageSwitcher: false })} />
                }
                <div className='sidebar'>
                    {this.props.username !== ''
                        ? this.renderLoggedInSidebar()
                        : this.renderLoggedOutSidebar()
                    }
                </div>
                <div role='button' className='sidebar-backdrop' tabIndex={0}
                    onClick={this.props.onHideSidebar}
                    onKeyPress={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                            this.props.onHideSidebar();
                        }
                    }}
                />
            </nav>
        );
    }

    private renderLoggedInSidebar() {
        const isEducator = this.props.userRole === ProfileRoleEnum.Educator;
        return (
            <>
                <div className='d-flex flex-row user-welcome'>
                    <div className='left-box'>
                        <NavLink
                            to={ROUTES.Users.PROFILE_EDIT_SLUG(this.props.username)}
                            onClick={() => {
                                analyticsInstance.track(
                                    EVENT_NAMES.MyProfileHeaderAvatarClicked,
                                    {
                                        icon_text: messages.uiMyProfile.defaultMessage,
                                        url: window.location.toString()
                                    }
                                );
                                this.props.onHideSidebar();
                            }}
                        >
                            <UserAvatar username={this.props.username} />
                        </NavLink>
                    </div>
                    <div className='flex-column right-box'>
                        <div className='welcome-msg'>
                            <WrappedMessage message={messages.uiSidebarLoginMessage} />
                        </div>
                        <div className='user-name'>
                            {this.props.name}
                        </div>
                        <NavLink
                            to={ROUTES.Users.PROFILE_EDIT_SLUG(this.props.username)}
                            className='profile-link'
                            onClick={() => {
                                analyticsInstance.track(
                                    EVENT_NAMES.MyProfileHeaderButtonClicked,
                                    {
                                        button_text: messages.uiMyProfile.defaultMessage,
                                        url: window.location.toString()
                                    }
                                );
                                this.props.onHideSidebar();
                            }}
                        >
                            <WrappedMessage message={messages.uiMyProfile}/> <Icon name='chevron-right'/>
                        </NavLink>
                    </div>
                </div>
                <NavLink
                    className='navlink'
                    to={ROUTES.Subjects}
                    onClick={() => {analyticsInstance.track(EVENT_NAMES.SubjectsPageHeaderButtonClicked, { button_text: messages.uiNavSubjects.defaultMessage, url: window.location.toString() }); this.props.onHideSidebar();}}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='subjects-bold' />
                        <WrappedMessage message={messages.uiNavSubjects} />
                    </div>
                </NavLink>
                <NavLink
                    className='navlink'
                    to={ROUTES.Library.HOME}
                    onClick={() => {
                        analyticsInstance.track(
                            EVENT_NAMES.LibraryPageHeaderButtonClicked,
                            {
                                button_text: messages.uiNavLibrary.defaultMessage,
                                url: window.location.toString()
                            }
                        );
                        this.props.onHideSidebar();
                    }}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='library-bold' outline />
                        <WrappedMessage message={messages.uiNavLibrary}/>
                    </div>
                </NavLink>
                <hr />
                <NavLink
                    className='navlink'
                    to={isEducator ? ROUTES.Dashboard.EDUCATOR_CLASSROOMS : ROUTES.Dashboard.LEARNER_CLASSROOMS}
                    onClick={() => {
                        analyticsInstance.track(
                            EVENT_NAMES.MyClassesHeaderButtonClicked,
                            {
                                button_text: messages.uiNavMyClasses.defaultMessage,
                                url: window.location.toString()
                            }
                        );
                        this.props.onHideSidebar();
                    }}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='classroom-bold'/>
                        <WrappedMessage message={messages.uiNavMyClasses}/>
                    </div>
                </NavLink>
                <NavLink
                    className='navlink'
                    to={ROUTES.Dashboard.HOME}
                    onClick={() => {
                        analyticsInstance.track(
                            EVENT_NAMES.MyContentHeaderButtonClicked,
                            {
                                button_text: messages.uiNavMyContent.defaultMessage,
                                url: window.location.toString()
                            }
                        );
                        this.props.onHideSidebar();
                    }}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='folder'/>
                        <WrappedMessage message={messages.uiNavMyContent}/>
                    </div>
                </NavLink>
                <NavLink
                    className='navlink'
                    to={ROUTES.Dashboard.HOME}
                    onClick={() => {
                        analyticsInstance.track(
                            EVENT_NAMES.CreateHeaderButtonClicked,
                            {
                                button_text: messages.uiNavCreateContent.defaultMessage,
                                url: window.location.toString()
                            }
                        );
                        this.props.onHideSidebar();
                    }}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='plus-alt'/>
                        <WrappedMessage message={messages.uiNavCreateContent}/>
                    </div>
                </NavLink>
                <NavLink
                    className='navlink'
                    to={ROUTES.Personal.NOTIFICATIONS}
                    onClick={() => {
                        analyticsInstance.track(
                            EVENT_NAMES.NotificationsHeaderButtonClicked,
                            {
                                button_text: messages.uiMyNotifications.defaultMessage,
                                url: window.location.toString()
                            }
                        );
                        this.props.onHideSidebar();
                    }}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='bell-alt' outline />
                        <WrappedMessage message={messages.uiMyNotifications}/>
                        {this.props.notificationCount > 0 &&
                            <span className='noti-badge'>
                                {this.props.notificationCount}
                            </span>
                        }
                    </div>
                </NavLink>
                <hr />
                <NavLink
                    to='#'
                    className='navlink'
                    onClick={() => {
                        analyticsInstance.track(
                            EVENT_NAMES.LogoutHeaderButtonClicked,
                            {
                                button_text: messages.uiLogOut.defaultMessage,
                                url: window.location.toString()
                            },
                            { sendImmediately: true }
                        );
                        this.props.onLogOut();
                    }}
                >
                    <div className='navlink-items'>
                        <Icon zoom='22' name='logout-alt' />
                        <WrappedMessage message={messages.uiLogOut} />
                    </div>
                </NavLink>
            </>
        );
    }

    private renderLoggedOutSidebar() {
        return <>
            <LabXChangeLogo width={120} type='trim' theme='dark' />
            <NavLink
                className='navlink bottom-border'
                onClick={() => {analyticsInstance.track(EVENT_NAMES.SubjectsPageHeaderButtonClicked, { button_text: messages.uiNavSubjects.defaultMessage, url: window.location.toString() }); this.props.onHideSidebar();}}
                to={ROUTES.Subjects}
            >
                <div className='navlink-items'>
                    <Icon zoom='22' name='subjects-bold' />
                    <WrappedMessage message={messages.uiNavSubjects} />
                </div>
            </NavLink>
            <NavLink
                className='navlink bottom-border'
                onClick={() => {
                    analyticsInstance.track(
                        EVENT_NAMES.LibraryPageHeaderButtonClicked,
                        {
                            button_text: messages.uiNavLibrary.defaultMessage,
                            url: window.location.toString()
                        }
                    );
                    this.props.onHideSidebar();
                }}
                to={ROUTES.Library.HOME}
            >
                <div className='navlink-items'>
                    <Icon zoom='22' name='library-bold' outline />
                    <WrappedMessage message={messages.uiNavLibrary}/>
                </div>
            </NavLink>
            <div className='nav-dropdown bottom-border'>
                <div className='nav-dropdown-items'>
                    <CollapsibleWithChevron
                        key='educators-menu'
                        title={intl.formatMessage(messages.uiNavEducatorsDropdownLabel)}
                        headerIcon='classroom-bold'
                        headerIconZoom='22'
                        defaultOpen={false}
                        chevronIconZoom='1em'
                        onExpand={() => {
                            analyticsInstance.track(
                                EVENT_NAMES.EducatorsHeaderDropdownExpanded,
                                {
                                    button_text: messages.uiNavEducatorsDropdownLabel.defaultMessage,
                                    url: window.location.toString()
                                }
                            );
                        }}
                    >
                        <div className='dropdown-links'>
                            <NavLink
                                className='dropdown-link'
                                to={ROUTES.Teach}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    analyticsInstance.track(
                                        EVENT_NAMES.EducatorsHeaderDropdownExploreClicked,
                                        {
                                            button_text: messages.uiNavEducatorsDropdownExploreContent.defaultMessage,
                                            url: window.location.toString()
                                        }
                                    );
                                    this.props.onHideSidebar();
                                }}
                            >
                                {intl.formatMessage(messages.uiNavEducatorsDropdownExploreContent)}
                            </NavLink>
                            <NavLink
                                className='dropdown-link'
                                to={ROUTES.Dashboard.HOME}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    analyticsInstance.track(
                                        EVENT_NAMES.EducatorsHeaderDropdownCreateClassClicked,
                                        {
                                            button_text: messages.uiNavEducatorsDropdownCreateClass.defaultMessage,
                                            url: window.location.toString()
                                        }
                                    );
                                    this.props.onHideSidebar();
                                }}
                            >
                                {intl.formatMessage(messages.uiNavEducatorsDropdownCreateClass)}
                            </NavLink>
                        </div>
                        <NavLink
                            to={ROUTES.Dashboard.HOME}
                            onClick={() => {
                                analyticsInstance.track(
                                    EVENT_NAMES.EducatorsHeaderDropdownCreateContentClicked,
                                    {
                                        button_text: messages.uiNavEducatorsDropdownCreateContent.defaultMessage,
                                        url: window.location.toString()
                                    }
                                );
                                this.props.onHideSidebar();
                            }}
                        >
                            <Button
                                className='nav-dropdown-button'
                                btnStyle='outline'
                                label={messages.uiNavEducatorsDropdownCreateContent}
                                icon='plus'
                                iconPosition='right'
                                iconZoom='12'
                            />
                        </NavLink>
                    </CollapsibleWithChevron>
                </div>
            </div>
            <div className='nav-dropdown'>
                <div className='nav-dropdown-items'>
                    <CollapsibleWithChevron
                        key='learners-menu'
                        title={intl.formatMessage(messages.uiNavLearnersDropdownLabel)}
                        headerIcon='teacher-hat-bold'
                        headerIconZoom='22'
                        defaultOpen={false}
                        chevronIconZoom='1em'
                        onExpand={() => {
                            analyticsInstance.track(
                                EVENT_NAMES.LearnersHeaderDropdownExpanded,
                                {
                                    button_text: messages.uiNavLearnersDropdownLabel.defaultMessage,
                                    url: window.location.toString()
                                }
                            );
                        }}
                    >
                        <div className='dropdown-links'>
                            <NavLink
                                className='dropdown-link'
                                to={ROUTES.Dashboard.HOME}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    analyticsInstance.track(
                                        EVENT_NAMES.LearnersHeaderDropdownCreateContentClicked,
                                        {
                                            button_text: messages.uiNavLearnersDropdownCreateContent.defaultMessage,
                                            url: window.location.toString()
                                        }
                                    );
                                    this.props.onHideSidebar();
                                }}
                            >
                                {intl.formatMessage(messages.uiNavLearnersDropdownCreateContent)}
                            </NavLink>
                        </div>
                        <NavLink
                            to={ROUTES.Dashboard.LEARNER_CLASSROOMS}
                            onClick={() => {
                                analyticsInstance.track(
                                    EVENT_NAMES.LearnersHeaderDropdownJoinClassClicked,
                                    {
                                        button_text: messages.uiNavLearnersDropdownJoinAClass.defaultMessage,
                                        url: window.location.toString()
                                    }
                                );
                                this.props.onHideSidebar();
                            }}
                        >
                            <Button
                                className='nav-dropdown-button'
                                btnStyle='outline'
                                label={messages.uiNavLearnersDropdownJoinAClass}
                            />
                        </NavLink>
                    </CollapsibleWithChevron>
                </div>
            </div>
            <Button
                className='nav-button login-button'
                btnStyle='outline'
                onClick={() => {
                    analyticsInstance.track(EVENT_NAMES.SignInButtonClicked, { url: window.location.toString() });
                    this.props.onHideSidebar();
                    this.props.onLogIn();
                }}
                label={messages.uiSignIn}
            />
            <Button
                className='nav-button sign-up-button'
                btnStyle='primary'
                onClick={() => {
                    analyticsInstance.track(EVENT_NAMES.SignUpButtonClicked, { url: window.location.toString() });
                    this.props.onHideSidebar();
                    this.props.onSignup();
                }}
                label={messages.uiSignUp}
            />
        </>;
    }
}
