// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    SourceOrganization,
    SourceOrganizationFromJSON,
    SourceOrganizationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContentSource
 */
export interface ContentSource {
    /**
     * 
     * @type {string}
     * @memberof ContentSource
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSource
     */
    title: string;
    /**
     * 
     * @type {Array<SourceOrganization>}
     * @memberof ContentSource
     */
    sourceOrganizations: Array<SourceOrganization>;
    /**
     * 
     * @type {string}
     * @memberof ContentSource
     */
    customDescription: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSource
     */
    infoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSource
     */
    infoButtonText: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContentSource
     */
    showEnrollAction: boolean;
}

export function ContentSourceFromJSON(json: any): ContentSource {
    return {
        'id': json['id'],
        'title': json['title'],
        'sourceOrganizations': (json['source_organizations'] as Array<any>).map(SourceOrganizationFromJSON),
        'customDescription': json['custom_description'],
        'infoUrl': json['info_url'],
        'infoButtonText': json['info_button_text'],
        'showEnrollAction': json['show_enroll_action'],
    };
}

export function ContentSourceToJSON(value?: ContentSource): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'source_organizations': (value.sourceOrganizations as Array<any>).map(SourceOrganizationToJSON),
        'custom_description': value.customDescription,
        'info_url': value.infoUrl,
        'info_button_text': value.infoButtonText,
        'show_enroll_action': value.showEnrollAction,
    };
}


