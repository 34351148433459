import bind from 'bind-decorator';
import {
    ClassroomContentEducator,
    ClassroomContentLearner,
    ClassroomDiscussion,
    ClassroomDiscussionThread,
    ClassroomLearnersEducator,
    ClassroomPageHeaderEducator,
    ClassroomPageHeaderLearner,
    ClassroomProgressEducator,
    ClassroomProgressLearner,
    ClassroomModals,
    ModalType,
} from 'classrooms/components';
import { ClassroomsApi } from 'global/api';
import { getLoggedInStatus } from 'auth/selectors';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { ClassroomDetail } from 'labxchange-client';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
    showErrorMessage,
    showSuccessMessage,
    Spinner,
    StandardPageLayout,
} from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface ReduxStateProps {
    isLoggedIn: boolean;
}

interface MatchProps {
    classroomKey: string;
    thread?: string;
    post?: string;
}

interface Props extends RouteComponentProps<MatchProps> {}

interface State {
    loading: boolean;
    classroom?: ClassroomDetail;
    learnerViewEnabled: boolean;
    modalType: ModalType;
}

export class ClassroomPageInternal extends React.PureComponent<Props & ReduxStateProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            modalType: ModalType.None,
            loading: true,
            learnerViewEnabled: false,
        };
    }

    public async componentDidMount() {
        // If the user is not logged in, construct a phony classroom
        // (so the page skeleton is rendered properly) and show the sign-up modal.
        if (!this.props.isLoggedIn) {
            this.setState({
                classroom: {
                    id: this.props.match.params.classroomKey,
                    name: '',
                    description: '',
                    subject: '',
                    itemsCount: 0,
                    membershipsCount: 0,
                    maxStudents: 0,
                    imageUrl: '',
                    archived: false,
                    created: new Date(),
                    isMember: false,
                    requestedToJoin: false,
                    discussion: '',
                },
                modalType: ModalType.Login,
            });
            return;
        }

        const classroom = await this.loadData();
        if (classroom === undefined) { return; }

        const permissions = classroom.permissions;
        const isEducator = (permissions && permissions.canEditClassObject) || false;
        this.checkPermissions(isEducator);
        this.setState({loading: false});

        const params = new URLSearchParams(this.props.location.search);
        if (params.get('copiedFrom')) {
            showSuccessMessage(
                <>
                    <p><WrappedMessage message={messages.classCopiedSuccessParagraph1} /></p>
                    <p><WrappedMessage message={messages.classCopiedSuccessParagraph2} /></p>
                </>,
                {confirmText: messages.classCopiedSuccessButtonLabel}
            );
        }

        // Check if the user has seen the welcome modal before, using the Web Storage API
        const classroomId = this.props.match.params.classroomKey;
        if (localStorage.getItem(`firstTimeViewingClassroom-${classroomId}`) === 'firstTime' &&
            classroom.isMember) {
            this.setState({ modalType: ModalType.Welcome });
        }
    }

    public async componentDidUpdate(prevProps: Props) {
        // TODO: check this logic
        if (this.props.location.pathname !== prevProps.location.pathname && this.state.learnerViewEnabled) {
            this.setState({learnerViewEnabled: false});
        }
    }

    public async loadData() {
        this.setState({ loading: true });
        try {
            const id = this.props.match.params.classroomKey;
            const classroom = await ClassroomsApi.read({id});
            this.setState({
                loading: false,
                classroom,
            });
            return classroom;
        } catch (err) {
            showErrorMessage('Could not load classroom.', {exception: err});
            this.setState({loading: false });
            return;
        }
    }

    public render() {
        const classroom = this.state.classroom;

        let element = null;
        if (classroom === undefined) {
            switch (this.props.match.path) {
                case ROUTES.Classrooms.CLASSROOM: {
                    element = <ClassroomContentLearner
                            classroom={this.state.classroom!}
                            // reloadData={() => null}
                            showSkeleton={true}
                        />;
                    break;
                }
                default: {
                    if (this.state.loading) {
                        element = <Spinner />;
                    }
                }
            }
            return (
                <StandardPageLayout headerFeature={<div className='classroom-page-empty-header'></div>}>
                    {element}
                </StandardPageLayout>
            );
        }

        const permissions = classroom.permissions;
        const isEducator = Boolean(permissions && permissions.canEditClassObject && !this.state.learnerViewEnabled);

        return (
            <StandardPageLayout
                pageTitle={this.pageTitle()}
                pageTitleValues={{name: classroom.name}}
                headerFeature={isEducator ?
                    <ClassroomPageHeaderEducator
                        classroomId={classroom.id}
                        classroomName={classroom.name}
                        classroomJoinCode={classroom.joinCode}
                        onClassSettings={this.onClassSettings}
                        learnerViewEnabled={this.state.learnerViewEnabled}
                        onToggleLearnerView={this.onToggleLearnerView}
                    />
                    :
                    <ClassroomPageHeaderLearner
                        classroom={this.state.classroom!}
                        onJoinButtonPressed={this.onJoinButtonPressed}
                        onLeaveButtonPressed={this.onLeaveButtonPressed}
                        learnerViewEnabled={this.state.learnerViewEnabled}
                        onToggleLearnerView={this.onToggleLearnerView}
                    />
                }
                headerBackgroundUrl={classroom.imageUrl}
                backgroundClassrooms={true}
            >
                <ClassroomModals
                    classroom={classroom}
                    loading={this.state.loading}
                    onShouldReloadData={() => this.loadData()}
                    modalType={this.state.modalType}
                    onChangeModal={(modalType) => this.setState({ modalType })}
                />
                {this.renderElement(isEducator)}
            </StandardPageLayout>
        );
    }

    private pageTitle() {
        switch (this.props.match.path) {
            // TODO: check if this actually matches
            case ROUTES.Classrooms.CLASSROOM: {
                return messages.classPageTitle;
            }
            case ROUTES.Classrooms.CLASSROOM_PROGRESS: {
                return messages.classProgressPageTitle;
            }
            case ROUTES.Classrooms.CLASSROOM_LEARNERS: {
                return messages.classLearnersPageTitle;
            }
            case ROUTES.Classrooms.CLASSROOM_DISCUSSION:
            case ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD: {
                return messages.classDiscussionPageTitle;
            }
            default: return undefined;
        }
    }

    private renderElement(isEducator: boolean) {
        switch (this.props.match.path) {
            case ROUTES.Classrooms.CLASSROOM: {
                return this.renderContent(isEducator);
            }
            case ROUTES.Classrooms.CLASSROOM_PROGRESS: {
                return this.renderProgress(isEducator);
            }
            case ROUTES.Classrooms.CLASSROOM_LEARNERS: {
                return this.renderLearners();
            }
            case ROUTES.Classrooms.CLASSROOM_DISCUSSION: {
                return this.renderDiscussion();
            }
            case ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD:
            case ROUTES.Classrooms.CLASSROOM_DISCUSSION_THREAD_POST: {
                return this.renderDiscussionThread();
            }
            default: return null;
        }
    }

/**
 * Checks the membership status for the requesting user
 * If they are not a member, they should first request to join
 * We redirect them to the educator dashboard and show a "need to join" modal
 * If they have already requested to join, we display a "your request has been sent" modal
 */
    private checkPermissions(isEducator: boolean) {
        const classroom = this.state.classroom;

        if (isEducator || classroom === undefined) {
            return;
        } else if (!classroom.isMember && !classroom.requestedToJoin) {
            this.setState({ modalType: ModalType.NeedJoinCode });
        } else if (!classroom.isMember && classroom.requestedToJoin) {
            this.setState({ modalType: ModalType.RequestSent });
        }
    }

    private renderProgress(isEducator: boolean) {
        if (this.state.classroom === undefined) { return null; }
        if (isEducator) {
            return <ClassroomProgressEducator classroom={this.state.classroom} />;
        } else {
            return <ClassroomProgressLearner classroom={this.state.classroom} />;
        }
    }

    private renderLearners() {
        if (this.state.classroom === undefined) { return null; }
        return (
            <ClassroomLearnersEducator
                classroom={this.state.classroom}
                reloadData={() => this.loadData()}
            />
        );
    }

    private renderDiscussion() {
        if (this.state.classroom === undefined) { return null; }
        return (
            <ClassroomDiscussion classroom={this.state.classroom}/>
        );
    }

    private renderDiscussionThread() {
        if (
            this.state.classroom === undefined
            || this.props.match.params.thread === undefined
        ) { return null; }

        return (
            <ClassroomDiscussionThread
                classroomId={this.state.classroom.id}
                threadId={this.props.match.params.thread}
                postId={this.props.match.params.post}
            />
        );
    }

    private renderContent(isEducator: boolean) {
        if (isEducator && this.state.learnerViewEnabled === false) {
            return (
                <ClassroomContentEducator
                    classroom={this.state.classroom}
                    reloadData={() => this.loadData()}
                    showSkeleton={this.state.loading}
                />
            );
        } else {
            return (
                <ClassroomContentLearner
                    classroom={this.state.classroom}
                    showSkeleton={this.state.loading}
                />
            );
        }
    }

    @bind private onClassSettings() {
        this.setState({ modalType: ModalType.Update });
    }

    // Educator View: toggle learner view
    @bind private async onToggleLearnerView(setFlag: boolean) {
        if (setFlag) {
            await this.loadData();
        }
        this.setState({ learnerViewEnabled: setFlag });
    }

    @bind private onJoinButtonPressed() {
        this.setState({ modalType: ModalType.JoinClassroom });
    }

    @bind private onLeaveButtonPressed() {
        this.setState({ modalType: ModalType.LeaveClassroom });
    }
}

export const ClassroomPage = connect<ReduxStateProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(withRouter(ClassroomPageInternal));
