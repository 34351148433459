// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClassroomEducator,
    ClassroomEducatorFromJSON,
    ClassroomEducatorToJSON,
    ClassroomObjectPermissions,
    ClassroomObjectPermissionsFromJSON,
    ClassroomObjectPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomEducatorList
 */
export interface ClassroomEducatorList {
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducatorList
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducatorList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducatorList
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducatorList
     */
    subject: string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomEducatorList
     */
    maxStudents: number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducatorList
     */
    readonly imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomEducatorList
     */
    readonly joinCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomEducatorList
     */
    archived: boolean;
    /**
     * 
     * @type {ClassroomObjectPermissions}
     * @memberof ClassroomEducatorList
     */
    permissions?: ClassroomObjectPermissions;
    /**
     * 
     * @type {Array<ClassroomEducator>}
     * @memberof ClassroomEducatorList
     */
    educators?: Array<ClassroomEducator>;
    /**
     * 
     * @type {number}
     * @memberof ClassroomEducatorList
     */
    readonly membershipsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassroomEducatorList
     */
    readonly itemsCount?: number;
}

export function ClassroomEducatorListFromJSON(json: any): ClassroomEducatorList {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'subject': json['subject'],
        'maxStudents': json['max_students'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'joinCode': !exists(json, 'join_code') ? undefined : json['join_code'],
        'archived': json['archived'],
        'permissions': !exists(json, 'permissions') ? undefined : ClassroomObjectPermissionsFromJSON(json['permissions']),
        'educators': !exists(json, 'educators') ? undefined : (json['educators'] as Array<any>).map(ClassroomEducatorFromJSON),
        'membershipsCount': !exists(json, 'memberships_count') ? undefined : json['memberships_count'],
        'itemsCount': !exists(json, 'items_count') ? undefined : json['items_count'],
    };
}

export function ClassroomEducatorListToJSON(value?: ClassroomEducatorList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'subject': value.subject,
        'max_students': value.maxStudents,
        'archived': value.archived,
        'permissions': ClassroomObjectPermissionsToJSON(value.permissions),
        'educators': value.educators === undefined ? undefined : (value.educators as Array<any>).map(ClassroomEducatorToJSON),
    };
}


