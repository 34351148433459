import { ItemMetadata, ItemUserAttributes } from 'labxchange-client';
import { Card } from 'library/components/Card';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';

interface Props {
    metadata: ItemMetadata;
    userAttributes: ItemUserAttributes;
}

export class PathwayClone extends React.PureComponent<Props> {
    public render() {
        return (
            <div  className='extra-meta-wrapper col-md-12 col-lg-10 offset-lg-1'>
                <div className='unit-clone-content'>
                    <h2>
                        <WrappedMessage message={messages.pathwayCloneSectionTitle}/>
                    </h2>
                    <Card
                        metadata={this.props.metadata}
                        userAttributes={this.props.userAttributes}
                        showMenuButton={false}
                        detailUrl={detailUrlForEntity(this.props.metadata)}
                    />
                </div>
            </div>
        );
    }
}
