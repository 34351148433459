import bind from 'bind-decorator';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Highlight, TruncatedHighlight } from 'ui/components/Highlight';

interface ClipHeightProps {
    lineHeight: number;
    text: string;
    ellipsis?: string | React.ReactNode;
    highlightedKeywords?: ReadonlySet<string>;
}

interface ClipHeightState {
    height: number;
    width: number;
}

/**
 * Renders a chunk of text that is truncated neatly.
 * Nasty bunch of javascript hacks.
 */
export class ClipHeight extends React.PureComponent<ClipHeightProps, ClipHeightState> {

    constructor(props: ClipHeightProps) {
        super(props);
        this.state = {
            height: 0,
            width: 0,
        };
    }

    public render() {
        const lines = Math.floor(this.state.height / this.props.lineHeight);
        const truncateProps = {
            lines,
            trimWhitespace: true,
            width: this.state.width,
            ellipsis: this.props.ellipsis || '…',
            keywords: this.props.highlightedKeywords
        };

        // TODO: Hack this
        return (
            <>
                <TruncatedHighlight {...truncateProps}>{this.props.text}</TruncatedHighlight>
                <ReactResizeDetector handleWidth handleHeight refreshMode='throttle' onResize={this.onResize}/>
            </>
        );
    }

    @bind private onResize(width: number, height: number): void {
        if (height !== 0 && width !== 0) {
            this.setState({width, height});
        }
    }

}

interface ClipLinesProps {
    lines: '0'|'1'|'2'|'3'|'4';
    text: string;
    highlightedKeywords?: ReadonlySet<string>;
}

/**
 * Renders a chunk of text that is truncated after a specific number of lines.
 * Nicer than the javascript hacks, but still uses non-standard css.
 */
// TODO: Hack this
export const ClipLines: React.FC<ClipLinesProps> = ({ lines, text, highlightedKeywords }) => (
    <div className={`clip-lines clip-lines-${lines}`}>
        { <Highlight keywords={highlightedKeywords}>{text}</Highlight> }
    </div>
);
