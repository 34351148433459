import * as React from 'react';
import Truncate, {TruncateProps} from 'react-truncate';
import { highlighter } from './highlighter';

export interface HighlightProps {
    keywords?: ReadonlySet<string>;
    children?: string;
}

/**
 * Highlight simple text given as a child.
 */
export class Highlight extends React.PureComponent<HighlightProps> {
    public render() {
        const keywords = this.props.keywords !== undefined ? [...this.props.keywords] : [];
        return this.props.children ? highlighter(this.props.children, keywords) : null;
    }
}

export interface TruncatedHighlightProps extends TruncateProps {
    keywords?: ReadonlySet<string>;
}

/**
 * Truncate and highlight a text given as a child.
 */
export class TruncatedHighlight extends Truncate {
    /**
     * This function is a modified version of the extended Truncate component's
     * renderLine function. We added the keyword highlighting but nothing else changed.
     */
    public renderLine?(line: string | JSX.Element, i: number, arr: string[]): JSX.Element | JSX.Element[] {
        let element;
        const keywordSet = (this.props as TruncatedHighlightProps).keywords;
        const keywords = keywordSet !== undefined ? [...keywordSet] : [];

        if (typeof line === 'string') {
            element = highlighter(line, keywords);
        } else {
            const text = line.props.children[0];
            const ellipsis = line.props.children[1];

            element = {...line, props: {
                ...line.props,
                children: [highlighter(text, keywords), ellipsis]
            }};
        }

        const br = <br key={i + 'br'} />;
        element = <span key={i}>{element}</span>;

        if (i === arr.length - 1) {
            return element;
        }

        if (line) {
            return [element, br];
        }

        return br;
    }
}
