import React, { useState } from 'react';
import {Icon, IconSymbol} from '../../../elements';

export enum CollapsibleSize {
    small = 'small',
    large = 'large',
}

interface CollapsibleComponentProps {
    title: string;
    description?: string;
    children?: React.ReactNode;
    headerIcon?: IconSymbol;
    size?: CollapsibleSize;
}

const CollapsibleComponent: React.FC<CollapsibleComponentProps> = ({
  title,
  description,
  children,
  headerIcon,
  size = CollapsibleSize.small,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`collapsible-component collapsible-component--${size} ${isExpanded ? 'expanded' : ''}`}>
            <div className='collapsible-component-header' onClick={toggleExpand}>
                <div>
                    {headerIcon && <Icon name={headerIcon} fill={'#7188C7'} zoom='1.25em' className='collapsible-icon'/>}
                    <span className={`collapsible-component-heading collapsible-component-heading--${size}`}>{title}</span>
                </div>

                <button className='toggle-button'>
                    {isExpanded ?  <Icon name='chevron-up' fill={'#002F50'} zoom='1.25em' />
                        : <Icon name='chevron-down' fill={'#002F50'} zoom='1.25em' />
                    }
                </button>
            </div>
            {isExpanded && (
                <div className='expanded-content'>
                    {description &&
                      <p>{description}</p>
                    }
                    {children}
                </div>
            )}
        </div>
    );
};

export default CollapsibleComponent;
