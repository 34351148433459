import bind from 'bind-decorator';
import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import * as React from 'react';

import { NavLink } from 'react-router-dom';

interface DropdownProps extends React.PropsWithChildren {
    id: string;
    label: React.ReactNode;
    className?: string;
    title?: string;
    onExpanded?: (expanded: boolean) => void;
    expandOnHover?: boolean;
}

interface DropdownState {
    expanded: boolean;
    currentItem: number;
}

export class Dropdown extends React.Component<DropdownProps, DropdownState> {
    private node: React.RefObject<HTMLDivElement>;
    private button: React.RefObject<HTMLButtonElement>;

    constructor(props: DropdownProps) {
        super(props);
        this.state = {
            expanded: false,
            currentItem: 1,
        };

        this.node = React.createRef<HTMLDivElement>();
        this.button = React.createRef<HTMLButtonElement>();
    }

    public componentDidMount() {
        /* Add event listener to window, not document, so that it can be
         * prevented via React.Event.stopPropagation().  See:
         * https://github.com/facebook/react/issues/4335#issuecomment-421705171
         * Need to also add the third arg (useCapture: true) to make it work alongside react-dropdown.
         */
        window.addEventListener('mousedown', this.handleClick, true);
        window.addEventListener('keyup', this.handleKeyUp, true);
        if (this.props.expandOnHover) {
            this.node.current?.addEventListener('mouseenter', this.handleMouseEnter);
            this.node.current?.addEventListener('mouseleave', this.handleMouseLeave);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClick, true);
        window.removeEventListener('keyup', this.handleKeyUp, true);
        if (this.props.expandOnHover) {
            this.node.current?.removeEventListener('mouseenter', this.handleMouseEnter);
            this.node.current?.removeEventListener('mouseleave', this.handleMouseLeave);
        }
    }

    public render() {
        return (
            <div className={classNames('dropdown', this.props.className)} ref={this.node}>
                <button id={this.props.id}
                        className='dropdown-toggle btn btn-primary text-header'
                        aria-haspopup='true'
                        aria-label='dropdown'
                        aria-expanded={this.state.expanded}
                        title={this.props.title}
                        onClick={this.toggleDropdown}
                        ref={this.button}
                >
                    {this.props.label}
                </button>
                <div
                    role='menu'
                    tabIndex={-1}
                    className={classNames(
                        'dropdown-menu',
                        {show: this.state.expanded},
                    )}
                    aria-labelledby={this.props.id}
                    onKeyPress={this.handlePanelKeypress}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }

    @bind private handleMouseEnter() {
        this.setExpanded(true);
    }

    @bind private handleMouseLeave() {
        this.setExpanded(false);
    }

    // Close the dropdown if user clicks anywhere outside of the dropdown panel.
    @bind private handleClick(event: MouseEvent) {
        if (this.state.expanded) {
            const element = event.target as HTMLElement;
            if (!this.node.current || this.node.current.contains(element)) {
                return;
            }
            this.setExpanded(false);
        }
    }

    @bind private handleKeyUp(event: KeyboardEvent) {
        if (this.state.expanded === true) {
            switch (event.key) {
            case 'Escape':
            case 'Esc':
                this.setExpanded(false);
                if (this.button.current) {
                    this.button.current.focus();
                }
                break;
            case 'Tab':
                if (this.node.current && document.activeElement &&
                    !this.node.current.contains(document.activeElement)) {
                    this.setExpanded(false);
                }
                break;
            }
        }
    }

    @bind private handlePanelKeypress(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            this.toggleDropdown();
        }
    }

    @bind private toggleDropdown() {
        this.setExpanded(!this.state.expanded);
    }

    private setExpanded(expanded: boolean) {
        this.setState({expanded});
        if (this.props.onExpanded) {
            this.props.onExpanded(expanded);
        }
    }

}

export interface DropdownLinkProps {
    key: string;
    to: LocationDescriptor;
    content: React.ReactNode;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
    disableActive?: boolean;
}

interface DropdownListProps {
    id: string;
    label: React.ReactNode;
    links: DropdownLinkProps[];
    className?: string;
    onExpandedChange?: (expanded: boolean) => void;
    expandOnHover?: boolean;
}

interface DropdownListState {
    currentIdx: number;
}

export class DropdownList extends React.PureComponent<DropdownListProps, DropdownListState> {

    private listRef: React.RefObject<HTMLUListElement>;

    constructor(props: DropdownListProps) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            currentIdx: -1,
        };
    }

    public componentDidMount() {
        /* Add event listener to window, not document, so that it can be
         * prevented via React.Event.stopPropagation().  See:
         * https://github.com/facebook/react/issues/4335#issuecomment-421705171
         */
        window.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }


    public render() {
        return (
            <Dropdown
                id={this.props.id}
                label={this.props.label}
                className={this.props.className}
                onExpanded={this.onExpanded}
                expandOnHover={this.props.expandOnHover}
            >
                <ul ref={this.listRef} className='dropdown-list'>
                    {this.props.links.map((props) => (
                        <li key={props.key}>
                            <NavLink className={classNames('dropdown-item', props.className)}
                                     activeClassName={props.disableActive ? '' : 'active'} role='menuitem' tabIndex={-1} exact
                                     to={props.to} onClick={props.onClick}>
                                {props.content}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </Dropdown>
        );
    }

    @bind private onExpanded(expanded: boolean) {
        if (this.props.onExpandedChange) {
            this.props.onExpandedChange(expanded);
        }
        this.setState({currentIdx: -1});
    }

    @bind private handleKeyUp(event: KeyboardEvent) {
        const ul = this.listRef.current;
        if (!ul) {
            return;
        }

        const currentIdx = this.state.currentIdx;
        const linkCount = this.props.links.length;
        let nextIdx = null;

        switch (event.key) {
        case 'ArrowDown':
        case 'Down':
            nextIdx = currentIdx + 1 > linkCount - 1 ? 0 : currentIdx + 1;
            break;
        case 'ArrowUp':
        case 'Up':
            nextIdx = currentIdx <= 0 ? linkCount - 1 : currentIdx - 1;
            break;
        }
        if (nextIdx !== null) {
            event.preventDefault();
            this.setState({currentIdx: nextIdx});
            const elem = ul.querySelectorAll('li')[nextIdx].querySelector('a');
            elem!.focus();
        }
    }
}
