import { bind } from 'bind-decorator';
import { AnnotatedVideoAnnotation } from 'labxchange-client';
import moment from 'moment-shortformat';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as UI from 'ui/components';

import { AnnotationEditor } from 'library/components/BlockEditor/AnnotatedVideoBlockEditor';
import messages from './displayMessages';
import { Icon } from 'ui/components';
import elementsMessages from 'elements/displayMessages';
import { intl } from 'i18n';
import { RichText } from 'elements/components/RichText';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';

export interface Props {
    annotation: AnnotatedVideoAnnotation;
    index?: number;
    ownerName?:string;
    ownerUrl?:string;
    /// Used if the owner is an organization
    ownerOrgId?: string;
    videoAuthor?: string;
    videoUrl?: string;
    selected?: boolean;
    onWatch?(): void;
    onOpenQuestion?(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]): void;
    onSelectTag?(tag: string): void;
    editable?: boolean;
    showEditor?: boolean;
    onEdit?(index: number): void;
    onSave?(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined,
        withQuestion: boolean): void;
    onDelete?(annotation: AnnotatedVideoAnnotation, withQuestion?: boolean): void;
    /// Function to send the annotation and the editor ref to get the annotation times and update the editor state
    onStartAnnotationForm?(annotation?: AnnotatedVideoAnnotation, editorRef?: React.RefObject<AnnotationEditor>): void;
    onStartQuestionForm?(questionTime?: number, editorRef?: React.RefObject<AnnotationEditor>): void;
    /// Function used in the AnnotationEditor component
    onStartAnnotationEdition?(startTimer: React.RefObject<UI.TimerInput>, endTimer: React.RefObject<UI.TimerInput>): void;
    onStartQuestionEdition?(startTimer: React.RefObject<UI.TimerInput>): void;
    onChangeAnnotationTimes?(isLeft: boolean, newTime: number): void;
    onChangeQuestionTime?(newTime: number): void;
    isHorizontalBlock?: boolean;
    onOpenMore?(annotation: AnnotatedVideoAnnotation): void;
    /// Used when the annotation is in a modal. It shows close button
    onCloseInModal?(): void;
    isTheFirst?: boolean;
    isTheLast?: boolean;
    isTagsBarShowed?: boolean;
    loggedInUsername?: string;
    questionStartOrderMap: {[start: string]: number};
    canEditAnnotation?: boolean;
    authorIsTheLoggedUser?(authorUrl: string): boolean;
    pausePlayer?(): void;
}

interface State {
    showEditor: boolean;
    showReadMore: boolean;
}

export class Annotation extends React.PureComponent<Props, State> {
    private editorRef: React.RefObject<AnnotationEditor>;
    private cardDescriptionRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            showEditor: this.props.showEditor || false,
            showReadMore: false,
        };
        this.editorRef = React.createRef();
        this.cardDescriptionRef = React.createRef();
    }

    private withQuestion() {
        return (this.props.annotation.question
        && Object.keys(this.props.annotation.question).length > 0) || false;
    }

    private formatDuration(duration: number): string {
        const d = moment.duration(duration, 'seconds');
        return (
            `${d.hours().toString().padStart(2, '0')}:` +
            `${d.minutes().toString().padStart(2, '0')}:` +
            `${d.seconds().toString().padStart(2, '0')}`
        );
    }

    public componentDidMount() {
        this.controlVisibilityOfReadMoreButton();
    }

    private controlVisibilityOfReadMoreButton() {
        if (!this.props.isHorizontalBlock) {
            // No readMore Button for large screen users.
            // ReadMore  button will only show for Mobile and Tablet screens.
            return;
        }

        if (this.props.annotation.imageUrl) {
            // Always show readMore if annotation contains an image.
            this.setState({ showReadMore: true });
            return;
        }

        const container = this.cardDescriptionRef.current;
        if (container) {
            // Show readMore if description is clamped i.e the content description exceeds the card's capacity.
            const isTextClamped = container.scrollHeight > container.clientHeight;
            if (isTextClamped) {
                this.setState({ showReadMore: true });
            }
        }
    }

    public render () {
        const annotation = this.props.annotation;
        const hasImage = annotation.imageUrl;
        const onOpenQuestion = this.props.onOpenQuestion;
        const withQuestion = this.withQuestion();
        const question = annotation.question;
        const onSelectTag = this.props.onSelectTag;
        let authorName = annotation.authorName ?? '';
        let authorUrl = annotation.authorUrl ?? '';
        let authorOrgId = annotation.authorOrgId ?? '';
        let isUserLoggedIn = false;

        /// If the authorName is empty then show the name of the annotated video owner
        if (authorName === '') {
            authorName = this.props.ownerName ?? '';
        }
        if (authorUrl === '') {
            authorUrl = this.props.ownerUrl ?? '';
        }
        if (authorOrgId === '') {
            authorOrgId = this.props.ownerOrgId ?? '';
        }

        if (this.props.authorIsTheLoggedUser && this.props.authorIsTheLoggedUser(authorUrl)) {
            isUserLoggedIn = true;
        }

        /// If the authorUrl is the same of the logged url
        if (isUserLoggedIn) {
            authorName = intl.formatMessage(messages.annotationAuthorMadeByYou);
        }

        if (this.state.showEditor) {
            if (this.props.onEdit && this.props.index !== undefined) {
                this.props.onEdit(this.props.index);
            }
            if(withQuestion) {
                if (this.props.onStartQuestionForm) {
                    this.props.onStartQuestionForm(annotation.start, this.editorRef);
                }
            } else {
                if (this.props.onStartAnnotationForm) {
                    this.props.onStartAnnotationForm(annotation, this.editorRef);
                }
            }

            return <AnnotationEditor
                annotation={annotation}
                ref={this.editorRef}
                withQuestion={withQuestion}
                onDelete={this.onDelete}
                onSave={this.onSave}
                onChangeAnnotationTimes={this.props.onChangeAnnotationTimes}
                onChangeQuestionTime={this.props.onChangeQuestionTime}
                onStartForm={this.props.onStartAnnotationEdition}
                onStartQuestionEdition={this.props.onStartQuestionEdition}
                questionStartOrderMap={this.props.questionStartOrderMap}
            />;
        }

        return <li data-id={`annotation-${annotation.id}`} className={
              `annotated-video-annotation
              ${this.props.selected ? 'annotated-video-annotation-selected' : ''}
              ${this.props.isHorizontalBlock ? 'horizontal-block' : ''}
              ${this.props.isTheFirst ? 'first-annotation' : ''}
              ${this.props.isTheLast ? 'last-annotation' : ''}
              ${(this.props.isHorizontalBlock && this.props.isTagsBarShowed) ? 'with-tags-bar' : ''}
            `}>
            {this.props.editable && (this.props.canEditAnnotation ?? false) &&
                <UI.Button
                    label={messages.editAnnotationButtonLabel}
                    icon='pencil'
                    iconOnly={true}
                    onClick={this.onEdit}
                    className='annotated-video-annotation-edit-button'/>
            }
            {this.props.editable && !(this.props.canEditAnnotation ?? true) &&
                <UI.Button
                    label={messages.deleteAnnotationButtonLabel}
                    icon='trashcan-small'
                    iconOnly={true}
                    onClick={() => { this.onDelete(this.props.annotation); }}
                    className='annotated-video-annotation-edit-button'/>
            }
            {this.props.onCloseInModal &&
                <UI.Button
                    btnStyle='link'
                    label={elementsMessages.elementsModalCloseText}
                    icon='x'
                    iconPosition='right'
                    onClick={this.props.onCloseInModal}
                    className='annotated-video-annotation-close-button'/>
            }
            {hasImage &&
                <img
                    className={`annotated-video-annotation-image ${this.props.isHorizontalBlock ? 'hidden' : ''}`}
                    src={annotation.imageUrl}
                    alt={annotation.imageAlt}
                />
            }
            <div className='annotated-video-annotation-range'>
               {this.formatDuration(annotation.start)}{annotation.end ? ' - ' + this.formatDuration(annotation.end) : ''}
            </div>
            <div className={`annotated-video-annotation-title ${this.props.isHorizontalBlock ? 'horizontal-block' : ''}`}>
                <RichText
                    innerHtml={annotation.title}
                    sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                    renderMath={true}
                />
            </div>
            <div ref={this.cardDescriptionRef} className={`annotated-video-annotation-description ${this.props.isHorizontalBlock ? 'horizontal-block' : ''}`}>
                <RichText
                    innerHtml={annotation.description ?? ''}
                    sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                    renderMath={true}
                />
            </div>
            <div className={`annotated-video-annotation-buttons ${this.props.isHorizontalBlock ? 'horizontal-block' : ''}`}>
                {this.props.isHorizontalBlock && !withQuestion && this.state.showReadMore &&
                    <UI.Button
                        btnStyle='outline'
                        label={messages.readMoreAnnotationButtonText}
                        onClick={this.onOpenReadMore}
                    />
                }
                {!withQuestion &&
                    <UI.Button
                        btnStyle='outline'
                        label={messages.watchClipButtonLabel}
                        onClick={this.props.onWatch}
                    />
                }
                {annotation.link && !this.props.isHorizontalBlock &&
                    <UI.Button
                        btnStyle='outline'
                        icon='link-external'
                        iconPosition='left'
                        href={annotation.link}
                        label={messages.learnMoreButtonLabel}
                        newWindow={true}
                        onClick={this.onLearnMore}
                    />
                }
                {(onOpenQuestion && withQuestion && question) ?
                    <UI.Button
                        btnStyle='outline'
                        label={messages.openProblemButtonSingularLabel}
                        onClick={() => onOpenQuestion(annotation)}
                    /> : null
                }
            </div>
            {onSelectTag && annotation.tags && !this.props.isHorizontalBlock && this.props.isTagsBarShowed &&
                <ul className='annotated-video-annotation-tags'>
                    {annotation.tags.map((tag, index) =>
                        <li key={index}>
                            <button className='unstyled' onClick={() => this.onSelectTag(tag)}>
                                #{tag}
                            </button>
                        </li>
                    )}
                </ul>
            }
            {!this.props.isHorizontalBlock && !isUserLoggedIn &&
                <div className='annotated-video-annotation-attribution'>
                    <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={authorUrl}>
                        {authorName}
                        <Icon name='link-external'/>
                    </a>
                </div>
            }

            {!this.props.isHorizontalBlock && isUserLoggedIn &&
                <div className='annotated-video-annotation-attribution own-content'>
                    {authorName}
                </div>
            }
        </li>;
    }

    @bind private onSelectTag(tag: string) {
        if (this.props.editable) { return; }
        if (!this.props.onSelectTag) { return; }
        this.props.onSelectTag(tag);
    }

    @bind private onDelete(annotation?: AnnotatedVideoAnnotation) {
        if (this.props.onDelete && annotation) { this.props.onDelete(annotation, this.withQuestion()); }
        this.setState({showEditor: false});
    }

    @bind private onSave(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined) {
        if (this.props.onSave) { this.props.onSave(annotation, imageFile, this.withQuestion()); }
        this.setState({showEditor: false});
    }

    @bind private onEdit() {
        this.setState({showEditor: true});
    }

    @bind private onOpenReadMore() {
        if(this.props.pausePlayer) {
            this.props.pausePlayer();
        }
        if (this.props.onOpenMore) {
            this.props.onOpenMore(this.props.annotation);
        }
    }

    @bind private onLearnMore() {
        if(this.props.pausePlayer) {
            this.props.pausePlayer();
        }
    }
}

export const AnnotationSkeleton = () => <li className='annotated-video-annotation'>
    <div className='annotated-video-annotation-range'><Skeleton/></div>
    <div className='annotated-video-annotation-title'><Skeleton/></div>
    <div className='annotated-video-annotation-description'><Skeleton/></div>
    <div className='annotated-video-annotation-tags'><Skeleton/></div>
</li>;
