/**
 * Helper methods to make it simple to dispatch errors, warnings, and success messages
 * from anywhere, even if not in a connect()ed Redux component.
 */

import { redirectToLogin } from 'auth/actions';
import { getStore } from 'global/store';
import uiMessages from 'ui/components/displayMessages';
import {
    showErrorMessage as realShowErrorMessage,
    showSuccessMessage as realShowSuccessMessage,
    showWarningMessage as realShowWarningMessage,
} from './actions';
import { intl } from 'i18n';

export function showErrorMessage(
    message: Parameters<typeof realShowErrorMessage>[0],
    args: Parameters<typeof realShowErrorMessage>[1] = {},
) {
    const store = getStore();
    if (store) store.dispatch(realShowErrorMessage(message, args));
}

export function showWarningMessage(
    message: Parameters<typeof realShowWarningMessage>[0],
    args: Parameters<typeof realShowWarningMessage>[1] = {},
) {
    const store = getStore();
    if (store) store.dispatch(realShowWarningMessage(message, args));
}

export function showSuccessMessage(
    message: Parameters<typeof realShowSuccessMessage>[0],
    args: Parameters<typeof realShowSuccessMessage>[1] = {},
) {
    const store = getStore();
    if (store) store.dispatch(realShowSuccessMessage(message, args));
}

export function showLoginRequiredMessage() {
    showWarningMessage(
        intl.formatMessage(uiMessages.uiAccountRequiredMessage),
        {
            title: intl.formatMessage(uiMessages.loginClassTitle),
            onConfirm: () => { redirectToLogin(); },
            confirmText: uiMessages.uiSignUp,
        },
    );
}

export function showLockedResourcesMessage() {
    showWarningMessage(
        intl.formatMessage(uiMessages.educatorResourcesFreeAccountCreateMessage),
        {
            title: intl.formatMessage(uiMessages.educatorResourcesFreeAccountCreateHeading),
            onConfirm: () => { redirectToLogin(); },
            confirmText: uiMessages.uiSignUp,
        },
    );
}
