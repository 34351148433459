import { countries } from 'typed-countries';

import { PAGINATION_OPTIONS, PAGINATION_SIZE } from 'global/constants';
import { UILanguages, UILangCodes } from 'i18n';
import { TaxonomyData, TaxonomyCategory } from './components/Taxonomy/types';
import { AppliedFilterFilterOnEnum } from 'labxchange-client';

/**
 * Generate a URL that will display a search page, filtered with the given keywords/tags.
 *
 * @param baseUrl - intended to be of the form ROUTES.<page>.HOME
 * @param keywords - items to be prefixed with `q=` in the url
 * @param tagIds - items to be prefixed with `t=` in the url
 */
export function buildSearchURL(
    baseUrl: string,
    keywords: ReadonlySet<string>,
    tagIds: ReadonlySet<string>,
): string {
    const [path, queryString] = baseUrl.split('?', 2);
    const query = new URLSearchParams(queryString);
    query.delete('q');
    query.delete('t');
    keywords.forEach((kw)  => { query.append('q', kw); });
    tagIds.forEach((tagID)  => { query.append('t', tagID); });
    return `${path}?${query}`;
}

/**
 * Helper to remove invalid search parameters from a given query string.
 *
 * This is so invalid parameters aren't re-saved to the url on change;
 * ie. invalid, changed, or outdated parameters aren't perpetuated in shared urls.
 */
export function sanitizeSearchParams(
    params: URLSearchParams|string,
    allowedFilters: Set<string>,
): URLSearchParams {
    const origParams = new URLSearchParams(params);
    const newParams = new URLSearchParams();
    origParams.forEach((v, k) => {
        if (k === 't') {
            const filterName = v.split(':')[0];
            if (allowedFilters.has(filterName)) {
                newParams.append(k, v);
            }
        } else if (['q', 'page', 'size', 'order'].includes(k)) {
            newParams.set(k, v);
        }
    });
    return newParams;
}

/**
 * Helper to pull a sanitized page number from a string.
 */
export function getCurrentPageFromString(maybePage?: string | null): number {
    const page = parseInt(maybePage || '1', 10) || 1;
    if (page < 1) {
        return 1;
    } else {
        return page;
    }
}

/**
 * Helper to pull a sanitized pagination size from a maybe string.
 */
export function getPaginationSizeFromString(
    maybeSize?: string | null,
    defaultSize: number = PAGINATION_SIZE,
    sizeOptions: {value: number}[] = PAGINATION_OPTIONS,
): number {
    const size = parseInt(maybeSize || defaultSize.toString(), 10) || defaultSize;
    // sanitize to ensure it's one of the available pagination options
    if (!sizeOptions.map((x) => x.value).includes(size)) {
        return defaultSize;
    } else {
        return size;
    }
}

/**
 * Helper to pull a sanitized search ordering from a maybe string
 */
export function getSortOrderingFromString<T>(
    s: null | undefined | any,
    defaultOrder: T,
    orderOptions: {value: T}[],
): T {
    if (!s) {
        return defaultOrder;
    }

    if (orderOptions.map(x => x.value).includes(s)) {
        return s as T;
    } else {
        return defaultOrder;
    }
}

/**
 * Compares two sets for equality. We need this because === only checks if the
 * two sets are the same object.
 */
export function eqSet<T>(set1: ReadonlySet<T>, set2: ReadonlySet<T>): boolean {
    if (set1.size !== set2.size) {
       return false;
    }
    for (const v of set1) {
       if (!set2.has(v)) {
          return false;
       }
    }
    return true;
}

export enum SearchType {
    Library,
    People,
}

export interface SearchDetailsState {
    currentPage: number;
    paginationSize: number;
    resultCount: number;
    sortOrder: string;
}

/**
 * Given a country code (e.g. 'AU'), return the full name of that country.
 * This should return a <WrappedMessage>, but TagData/<Taxonomy> doesn't support
 * that yet.
 */
export function countryFilterName(countryCode: string): string {
    const countryObj = countries.find((c: any) => c.iso === countryCode);
    return countryObj ? countryObj.name : countryCode;
}

interface AggregationBucket {
    key: string;
    doc_count: number;
    translated_name?: string;
    extra_text?: string;
}

export interface AggregationResults {
    [key: string]: {
        doc_count_error_upper_bound?: number;
        sum_other_doc_count?: number;
        buckets?: AggregationBucket[];
        [otherKey: string]: any;
    };
}

export const languagesFilter: TaxonomyData = {
    id: AppliedFilterFilterOnEnum.Language,
    category: TaxonomyCategory.Language,
    tags: UILangCodes.map(code => ({
        id: `${AppliedFilterFilterOnEnum.Language}:${code}`,
        name: UILanguages[code].nativeName,
    }))
};

interface SearchFilter {
    filterOn: string;
    filterValues: string[];
}

export function getAppliedSearchFilters(appliedTagIds: ReadonlySet<string>): ReadonlySet<SearchFilter> {
    // Group by filter type, e.g. if filtering 'Role' (PeopleSearchFilter.Role)
    // for the values 'learner' and 'mentor', set
    // valuesByFilterType['Role'] = ['learner', 'mentor']
    const valuesByFilterType: {[x: string]: string[]} = {};
    for (const filterCode of appliedTagIds) {
        const filterType = filterCode.substr(0, filterCode.indexOf(':'));
        const filterValue = filterCode.substr(filterCode.indexOf(':') + 1);
        if (!valuesByFilterType[filterType]) {
            valuesByFilterType[filterType] = [];
        }
        valuesByFilterType[filterType].push(filterValue);
    }
    return new Set(Object.entries(valuesByFilterType).map(([filterOn, filterValues]) => ({
        filterOn,
        filterValues,
    })));
}

// Helper function with the common code for building TaxonomyData in the case where there are no child
// filters (a single level of filter options only)
export const buildTags = (
    aggregationsData: AggregationResults,
    filterType: string,
    category: TaxonomyCategory,
    nameForBucket: (name: string) => string = (n) => n,
): TaxonomyData => ({
    id: filterType,
    category,
    tags: (aggregationsData[filterType]?.buckets || [])
        .filter(({key}) => Boolean(key))
        .map(({key, doc_count, translated_name, extra_text}) => ({
            // All the IDs of the selected filter options are combined into one set, so we need to namespace them:
            id: filterType + ':' + key,
            name: nameForBucket(key),
            numEntities: doc_count,
            translatedName: translated_name,
            extraText: extra_text,
        })),
});
