import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import classNames from 'classnames';

interface VideoPlayerProps {
    youtubeUrl: string;
}

const YouTubeURLRegex = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ youtubeUrl }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // @ts-ignore
    useEffect(() => {
        if (videoRef.current && YouTubeURLRegex.test(youtubeUrl)) {
            const player = videojs(videoRef.current, {
                controls: true,
                sources: [{ src: youtubeUrl, type: 'video/youtube' }],
            });

            const handlePlay = () => setIsPlaying(true);
            const handlePause = () => setIsPlaying(false);

            player.on('play', handlePlay);
            player.on('pause', handlePause);

            return () => {
                player.off('play', handlePlay);
                player.off('pause', handlePause);
                player.dispose();
            };
        }
    }, [youtubeUrl]);

    return (
        <div data-vjs-player>
            <video
                ref={videoRef}
                className={classNames('video-js vjs-lx-skin', {'video-playing' : isPlaying })}
                playsInline
                data-testid='video-element'
            />
        </div>
    );
};

export default VideoPlayer;
