import React, {useEffect, useState} from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import { intl } from 'i18n';
import messages from './displayMessages';
import CheckboxCard from '../CheckboxCard/CheckboxCard';


export const DriversOptions = [
    {
        title: 'Building & creating',
        description: 'Bring new ideas to life by constructing new products, services, systems or experiences.'
    },
    {
        title: 'Creating change',
        description: 'Drive social justice, advocate for policy changes, or lead community initiatives.'
    },
    {
        title: 'Helping people or animals',
        description: 'Improve wellbeing or offer support through personal interaction and care to others.'
    },
    {
        title: 'Influencing & Inspiring',
        description: 'Drive technological progress and solve complex problems.'
    },
    {
        title: 'Learning & mastering',
        description: 'Become an expert by gaining new knowledge, or perfecting a skill.'
    },
    {
        title: 'Solving tough problems',
        description: 'Provide innovative solution with analytical thinking and creativity.'
    },
    {
        title: 'I don’t know yet',
        description: 'We’ll show you all careers so you can figure out what drives and inspires you.'
    },
];
export const ImpactOptions = [
    {
        title: 'Education & learning',
        description: 'Contribute to improving educational programs or technology.'
    },
    {
        title: 'Health & wellbeing',
        description: 'Contribute to improving Health and well-being of people.'
    },
    {
        title: 'Humanitarian aid & relief',
        description: 'Aid in crisis response, disaster relief, and humanitarian efforts.'
    },
    {
        title: 'Innovation & technology',
        description: 'Drive technological progress and solve complex problems.'
    },
    {
        title: 'Social impact',
        description: 'Help underserved communities and provide essential services.'
    },
    {
        title: 'Sustainability',
        description: 'Promote sustainability, conservation, or climate change mitigation.'
    },
    {
        title: 'I don’t know yet',
        description: 'We’ll show you all careers so you can figure out how you want to make an impact.'
    },
];


export interface DriverStepProps {
    currentStep: number;
    onButtonClick: (nextStep: number) => void;
    isDriverScreen?: boolean;
}

export const DriverAndImpactStep: React.FC<DriverStepProps> = ({currentStep, onButtonClick, isDriverScreen}) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const renderOptions = isDriverScreen ? DriversOptions : ImpactOptions;
    const title = isDriverScreen ? intl.formatMessage(messages.careerDriverOnboardingStepTitle)
        : intl.formatMessage(messages.careerImpactOnboardingStepTitle);
    const description = isDriverScreen ? intl.formatMessage(messages.careerDriverOnboardingStepDescription)
        : intl.formatMessage(messages.careerImpactOnboardingStepDescription);

    const toggleOption = (option: string) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter(selected => selected !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    useEffect(()=> {
        setSelectedOptions([]);
    }, [isDriverScreen]);

    return (
        <OnboardingPageLayout
            title={title}
            description={description}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedOptions.length !== 0}
        >
            {renderOptions.map((driver, i) =>
                <CheckboxCard
                    title={driver.title}
                    description={driver.description}
                    checked={selectedOptions.includes(driver.title)}
                    toggleCheckbox={() => toggleOption(driver.title)}
                />
            )}
        </OnboardingPageLayout>
    );
};

export default DriverAndImpactStep;
