import { ROUTES } from 'global/constants';
import { Author } from 'labxchange-client';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { UserAvatar } from 'user/components/UserAvatar';

interface AuthorsListProps {
    authors: Author[];
}

export class AuthorsList extends React.PureComponent<AuthorsListProps> {
    public render() {
        if (this.props.authors.length === 0) { return null; }
        return (
            <div className='authors-list'>
                {
                    this.props.authors.map((author, idx) => (
                        <div className='credit' key={idx}>
                            <UserAvatar
                                username={author.username} fullName={author.fullName} // Full name for non-users.
                            />
                            <div>
                                {author.username
                                ?
                                    <NavLink
                                        className='author-name'
                                        to={ROUTES.Users.PROFILE_SLUG(author.username)}>
                                        {author.fullName}
                                    </NavLink>
                                : <span className='author-name'>{author.fullName}</span>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}
