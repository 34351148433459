import { LocationDescriptor } from 'history';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { CreateItemModal } from 'items/components/CreateItemModal';
import { OrganizationResponse } from 'labxchange-client';
import { LibraryContentList } from 'library/components/LibraryContentList';

interface Props extends RouteComponentProps<{}> {
    organizationData: OrganizationResponse;
}

export const OrganizationContentDashboard = withRouter(({
    organizationData,
    ...props
}: Props) => {
    // Handler for creating new content as this organization:
    const canCreateContent = organizationData.permissions.canEditContentOfOrganizationObject;
    const orgId = organizationData.org.id;
    const orgSlug = organizationData.org.slug;
    const [showCreateContentModal, setShowCreateContentModal] = React.useState(false);
    const clickedCreateContent = React.useCallback(() => { setShowCreateContentModal(true); }, []);
    const cancelledCreateContent = React.useCallback(() => { setShowCreateContentModal(false); }, []);
    // If the user has clicked "Add New Content" and then selected a content type, this callback
    // will move to the new item page, and also set the org ID in the query string.
    const pushCreateContentPage = React.useCallback((newUrl: LocationDescriptor<{}>) => {
        const extraState = { orgSlug };
        if (typeof newUrl === 'string') {
            props.history.push({ pathname: newUrl, state: extraState});
        } else {
            newUrl.state = {...(newUrl.state || {}), ...extraState};
            props.history.push(newUrl);
        }
    }, [orgSlug]);

    return (
        <div className='my-content-page'>
            <LibraryContentList
                cardsStyle='portrait'
                isModalView={false}
                displayAddButton={false}
                showCreateContentButton={canCreateContent}
                onCreateContent={clickedCreateContent}
                openCardDetailUrlsInNewTab={false}
                searchOnlyOwnedByOrganization={orgId}
                disableDefaultFilters={true}
            />
            {showCreateContentModal && (
                <CreateItemModal
                    onRequestClose={cancelledCreateContent}
                    onCloseAndRedirect={pushCreateContentPage}
                />
            )}
        </div>
    );
});
