import * as React from 'react';

import { ContainerOne } from 'ui/components/Containers/Containers';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';
export { ContainerOne };
export * from './CollapsibleWithChevron';


/**
 * A container for content which is centered on large screens with the standard width.
 * On medium and small screens the content takes up the full width of the window.
 * This should only be used as a direct child of StandardPageLayout.
 */
export const OutermostCenteredContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <div className='outermost-centered-container'>
            {props.children}
        </div>
    );
};

interface ContainerWithTitleOutsideProps extends React.PropsWithChildren {
    title: React.ReactNode;
    theme?: undefined | 'navy';
}

/**
 * A container with rounder corners and border with a title placed above it.
 */
export const ContainerWithTitleOutside: React.FunctionComponent<ContainerWithTitleOutsideProps> = (props) => {

    return (
        <div className={
            'container-with-title-outside' +
            (props.theme ? ` container-with-title-outside-${props.theme}` : '')
        }>
            <div className='container-with-title-outside-title'>
                {props.title}
            </div>
            <ContainerOne theme={props.theme}>
                {props.children}
            </ContainerOne>
        </div>
    );
};

/**
 * A separator for use in ContainerOne.
 */
export const ContainerOneSeparator: React.FunctionComponent = (props) => {
    return (
        <div className='container-one-separator'></div>
    );
};

/**
 * A container for two child elements where the first child takes up content width and the
 * second child expands to fill the remaining space.
 * On small screens the second child shows below the first.
 */
export const OneContentWidthSecondFlexibleWidthElementsInRowContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <div className='one-content-width-second-flexible-width-elements-container'>
            <div className='row m-n2 m-md-n3'>
                {React.Children.map(props.children, (child, i) => {
                    const className = i === 0 ? 'p-2 p-md-3 col-sm-auto' : 'p-2 p-md-3 col';
                    return (
                        <div className={className}>
                            {child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

/**
 * A container for two child elements where both take half the width on medium and large screens.
 * On small screens the second element is shown below the first.
 */
export const TwoEqualWidthElementsInRowContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <div className='two-equal-width-elements-in-row-container'>
            <div className='row m-n2 m-md-n3'>
                {React.Children.map(props.children, (child, i) => {
                    return (
                        <div className='col-12 col-md-6 p-2 p-md-3'>
                            {child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

/**
 * A container to show elements in a column with standard padding and margins.
 */
export const ElementsInColumnContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <div className='elements-in-column-container'>
            <div className='row mx-0 my-n2 m-md-n3'>
                {React.Children.map(props.children, (child, i) => {
                    return (
                        <div className='col-12 px-0 py-2 p-md-3'>
                            {child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

/**
 * A container to align an element to the right on medium and large screens.
 * On small screens the element is shown centered and expanded.
 */
export const AlignElementRightContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <div className='align-element-right-container'>
            {props.children}
        </div>
    );
};

/**
 * A ordered list container with standard styles applied to it.
 */
export const OrderedListContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <ol className='ordered-list-container'>
            {props.children}
        </ol>
    );
};

/**
 * A container used on Library and Organization search to display
 * recommended results when the query comes in empty.
 */
export const RecommendedItemsContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    return (
        <div className='container py-3 recommended-items-container'>
            <h4 className='mb-3'>
                <WrappedMessage message={messages.recommendedResources} />
            </h4>
            {props.children}
        </div>
    );
};
