import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import { RedirectsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { StandardPageLayout, Spinner } from 'ui/components';

type Props = RouteComponentProps<{key: string}>;

interface State {
    url?: string;
}

/**
 * Dynamic redirect page.
 * This pulls a redirect from the backend and redirects.
 */
export class RedirectPage extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            url: undefined,
        };
    }

    async componentDidMount() {
        let data;
        try {
            data = await RedirectsApi.read({ id: this.props.match.params.key });
        } catch(err) {
            this.setState({ url: ROUTES.All.HOME });
            return;
        }
        this.setState({ url: data.url });
    }

    render() {
        const url = this.state.url;
        if (url !== undefined) {
            return <Redirect to={url} />;
        }

        return (
            <StandardPageLayout>
                <Spinner />
            </StandardPageLayout>
        );
    }
}

