import React from 'react';
import { EdgeProps } from 'reactflow';

const CustomEdge: React.FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, style = {} }) => {
  const edgePath = `M${sourceX},${sourceY} L${targetX},${targetY}`;

  return (
    <g className='react-flow__edge'>
      <path
        id={id}
        style={{ ...style, strokeWidth: 4 }}
        className='react-flow__edge-path'
        d={edgePath}
      />
    </g>
  );
};

export default CustomEdge;
