import { UILanguages } from 'i18n';
type SortOptionLocale  = {
    [k in keyof typeof UILanguages]: string;
};


// TODO: convert these to standard i18n message descriptors,
// so we can translate these in transifex instead.
export const SORT_OPTIONS_RELEVANCE: SortOptionLocale = {
    'en': 'Relevance',
    'nl': 'Relevantie',
    'de': 'Relevanz',
    'it': 'Rilevanza',
    'ja': '関連性',
    'fr': 'Pertinence',
    'ko': '관련성',
    'tr-tr': 'Alaka düzeyi',
    'ar-ae': 'ملاءمة',
    'es-419': 'Relevancia',
    'pt-br': 'Relevância',
    'zh-hans': '关联',
    'pl': 'Trafność',
    'zu': 'Ukufaneleka',
    'uk': 'Релевантність',
    'vi': 'sự liên quan',
    'az': 'Uyğunluq',
    'ro': 'Relevanţă',
    'ka': 'შესაბამისობა',
    // 'ru': 'Актуальность',
};

export const SORT_OPTIONS_LAST_ADDED: SortOptionLocale = {
    'en': 'Last Added',
    'nl': 'Laatst toegevoegd',
    'de': 'Zuletzt hinzugefügt',
    'it': 'Ultimo aggiunto',
    'ja': '最終追加',
    'fr': 'Dernier ajouté',
    'ko': '마지막 추가',
    'tr-tr': 'En son eklenmiş',
    'ar-ae': 'آخر يضاف',
    'es-419': 'Última incorporacion',
    'pt-br': 'Último adicionado',
    'zh-hans': '最后添加',
    'pl': 'Ostatnio dodane',
    'zu': 'Igcine ukufakwa',
    'uk': 'Останній доданий',
    'vi': 'thêm lần cuối',
    'az': 'Ən Son Əlavə Edilən',
    'ro': 'Ultima adăugată',
    'ka': 'ბოლოს დამატებულია',
    // 'ru': 'Последнее добавление',
};

export const SORT_OPTIONS_MOST_VIEWED: SortOptionLocale = {
    'en': 'Most Viewed',
    'nl': 'Meest bekeken',
    'de': 'Am häufigsten gesehen',
    'it': 'I più visti',
    'ja': '最も見られました',
    'fr': 'Le plus regardé',
    'ko': '많이 본',
    'tr-tr': 'En çok görüntülenen',
    'ar-ae': 'الأكثر مشاهدة',
    'es-419': 'Mas visto',
    'pt-br': 'Mais visto',
    'zh-hans': '最受关注',
    'pl': 'Najczęściej oglądane',
    'zu': 'Ebonwe Kakhulu',
    'uk': 'З найбільшою кількістю переглядів',
    'vi': 'Xem nhiều nhất',
    'az': 'Ən Çox Baxılan',
    'ro': 'Cele mai văzute',
    'ka': 'ყველაზე ნახული',
    // 'ru': 'Наиболее просматриваемые',
};

export const SORT_OPTIONS_MOST_FAVORITED: SortOptionLocale = {
    'en': 'Most Favorited',
    'nl': 'Meest favoriet',
    'de': 'Am beliebtesten',
    'it': 'I più preferiti',
    'ja': '最もお気に入り',
    'fr': 'Les plus favoris',
    'ko': '가장 좋아하는',
    'tr-tr': 'En Çok Beğenilen',
    'ar-ae': 'الأكثر تفضيلاً',
    'es-419': 'Más favorecido',
    'pt-br': 'Mais favorito',
    'zh-hans': '最喜欢的',
    'pl': 'Najbardziej ulubione',
    'zu': 'Athandwe Kakhulu',
    'uk': 'З найбільшою кількістю додавань у обране',
    'vi': 'yêu thích nhất',
    'az': 'Ən Çox Bəyənilən',
    'ro': 'Cele mai preferate',
    'ka': 'ყველაზე რჩეული',
    // 'ru': 'Самые любимые',
};

export const SORT_OPTIONS_MOST_REMIXED: SortOptionLocale = {
    'en': 'Most Remixed',
    'nl': 'Meest geremixed',
    'de': 'Am meisten remixt',
    'it': 'La maggior parte dei remix',
    'ja': 'ほとんどのリミックス',
    'fr': 'Le plus remixé',
    'ko': '대부분의 리믹스',
    'tr-tr': 'En Çok Yeniden Düzenlenen',
    'ar-ae': 'الأكثر ريمكسًا',
    'es-419': 'Más remezclado',
    'pt-br': 'Mais Remixado',
    'zh-hans': '最多混音',
    'pl': 'Najczęściej remiksowane',
    'zu': 'Exutshwe Kaningi',
    'uk': 'З найбільшою кількістю реміксів',
    'vi': 'Được phối lại nhiều nhất',
    'az': 'Ən Çox Remiks Edilən',
    'ro': 'Cele mai remixate',
    'ka': 'ყველაზე რემიქსი',
    // 'ru': 'Самый ремикс',
};

export const SORT_OPTIONS_MOST_RECENT: SortOptionLocale = {
    'en': 'Most Recent',
    'nl': 'Meest recente',
    'de': 'Neueste',
    'it': 'Piu recente',
    'ja': '最も最近の',
    'fr': 'Le plus récent',
    'ko': '가장 최근',
    'tr-tr': 'En Yeniler',
    'ar-ae': 'الأحدث',
    'es-419': 'Más reciente',
    'pt-br': 'Mais recente',
    'zh-hans': '最近的',
    'pl': 'Najnowszy',
    'zu': 'Eyakamuva',
    'uk': 'Найновіші',
    'vi': 'Gần đây nhất',
    'az': 'Ən Sonuncu',
    'ro': 'Cel mai recent',
    'ka': 'Უახლესი',
    // 'ru': 'Самые последние',
};

export const SORT_OPTIONS_MOST_LIKED: SortOptionLocale = {
    'en': 'Most Liked',
    'nl': 'Meest leuk gevonden',
    'de': 'Am meisten gemocht',
    'it': 'I più apprezzati',
    'ja': '最も好きな',
    'fr': 'Le plus aimé',
    'ko': '가장 좋아하는',
    'tr-tr': 'En çok sevilen',
    'ar-ae': 'الأكثر إعجابا',
    'es-419': 'El mas gustado',
    'pt-br': 'O mais adorado',
    'zh-hans': '最喜欢',
    'pl': 'Najbardziej lubiane',
    'zu': 'Ethandwe Kakhulu',
    'uk': 'З найбільшою кількістю вподобань',
    'vi': 'Thích nhất',
    'az': 'Ən Çox Bəyənilən',
    'ro': 'Cele mai apreciate',
    'ka': 'ყველაზე მოწონებული',
    // 'ru': 'Самые популярные',
};

export const SORT_OPTIONS_RECENTLY_JOINED: SortOptionLocale = {
    'en': 'Recently Joined',
    'nl': 'Onlangs toegetreden',
    'de': 'Vor kurzem beigetreten',
    'it': 'Iscritto di recente',
    'ja': '最近参加した',
    'fr': 'A récemment rejoint',
    'ko': '최근 참여한',
    'tr-tr': 'Yakın zamanda katıldı',
    'ar-ae': 'انضم مؤخرا',
    'es-419': 'Recientemente Unidos',
    'pt-br': 'Ingressou recentemente',
    'zh-hans': '近期加入',
    'pl': 'Niedawno dołączył',
    'zu': 'Usanda Kujoyina',
    'uk': 'Нещодавно приєдналися',
    'vi': 'Đã tham gia gần đây',
    'az': 'Ən Son Qoşulan',
    'ro': 'S-a alăturat recent',
    'ka': 'ახლახან შეუერთდა',
    // 'ru': 'Недавно присоединился',
};
