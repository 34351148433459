import { defineMessages } from 'react-intl';

const messages = defineMessages({
    classScoreTitle: {
        id:'classScoreTitle',
        defaultMessage: 'Your class score',
        description: 'Class score title.',
    },
    educatorClassScoreDescription: {
        id:'educatorClassScoreDescription',
        defaultMessage: 'This is the average of all your learners\' question set scores. Your learners\' scores are the total scores from their completed question sets.',
        description: 'Description of the class score metric shown on the educator progress page.',
    },
    learnerClassScoreDescription: {
        id:'learnerClassScoreDescription',
        defaultMessage: 'This is your total score on all the question sets you have completed so far.',
        description: 'Description of the class score metric shown on the learner progress page.',
    },
    classScoreValue: {
        id:'classScoreValue',
        defaultMessage: '{value}%',
        description: 'Class score value.',
    },
    classProgressTitle: {
        id:'classProgressTitle',
        defaultMessage: 'Your class progress',
        description: 'Class progress title.',
    },
    educatorClassProgressDescription: {
        id:'educatorClassProgressDescription',
        defaultMessage: 'This shows how much of the content you have assigned has been viewed or completed by your learners.',
        description: 'Description of the class progress metric shown on the educator progress page.',
    },
    educatorClassProgressUpdateButton: {
        id:'educatorClassProgressUpdateButton',
        defaultMessage: 'Update report',
        description: 'Label for button for re-calculating the class progress report.',
    },
    educatorClassProgressUpdateDateLabel: {
        id:'educatorClassProgressUpdateDateLabel',
        defaultMessage: 'Last updated {updatedDate}',
        description: 'Label for date when the class progress report was last updated.',
    },
    educatorClassProgressDownloadButton: {
        id:'educatorClassProgressDownloadButton',
        defaultMessage: 'Download report',
        description: 'Download report button.',
    },
    learnerClassProgressDescription: {
        id:'learnerClassProgressDescription',
        defaultMessage: 'This percentage shows how much of the assigned content you have viewed or completed.',
        description: 'Description of the class progress metric shown on the educator progress page.',
    },
    classProgressValue: {
        id:'classProgressValue',
        defaultMessage: '{value}% complete',
        description: 'Class progress value, with label.',
    },
    assessmentScoreTitle: {
        id:'assessmentScoreTitle',
        defaultMessage: 'Question score',
        description: 'Assessment score title.',
    },
    simulationScoreTitle: {
        id:'simulationScoreTitle',
        defaultMessage: 'Simulation score',
        description: 'Simulation score title.',
    },
    notStarted: {
        id:'notStarted',
        defaultMessage: 'Not started',
        description: 'Marks an item the user has not started.',
    },
    complete: {
        id:'complete',
        defaultMessage: 'Complete',
        description: 'Marks an item the user has completed.',
    },
    incompletable: {
        id:'incompletable',
        defaultMessage: '-',
        description: 'Marks an item that cannot be completed.',
    },
    incompletableWithLabel: {
        id:'incompletableWithLabel',
        defaultMessage: 'Score: -',
        description: 'Marks an item that cannot be completed, with a label',
    },
    score: {
        id:'score',
        defaultMessage: '{earned}/{possible}',
        description: 'Represents an item score (points earned divided by points possible).',
    },
    scorePercentage: {
        id:'scorePercentage',
        defaultMessage: '{score}% Correct',
        description: 'Represents an item score as a fraction.',
    },
    scoreWithLabel: {
        id:'scoreWithLabel',
        defaultMessage: 'Score: {earned}/{possible}',
        description: 'Represents an item score (points earned divided by points possible), with a label.',
    },
    assignedHeader: {
        id:'assignedHeader',
        defaultMessage: 'Assigned',
        description: 'Assigned header.',
    },
    assignmentSortTitle: {
        id:'assignmentSortTitle',
        defaultMessage: 'Sort by {assignment} progress',
        description: 'Help text for the sortable Assignment columns in the reports table.',
    },
    completedHeader: {
        id:'completedHeader',
        defaultMessage: 'Completed',
        description: 'Completed header.',
    },
    nameHeader: {
        id:'nameHeader',
        defaultMessage: 'Name',
        description: 'Name header.',
    },
    nameSortTitle: {
        id:'nameSortTitle',
        defaultMessage: 'Sort by name',
        description: 'Help text for the sortable Name column in the reports table.',
    },
    contentTypeHeader: {
        id:'contentTypeHeader',
        defaultMessage: 'Content type',
        description: 'Content type header.',
    },
    progressHeader: {
        id:'progressHeader',
        defaultMessage: 'Overall Progress',
        description: 'Progress column header.',
    },
    progressSortTitle: {
        id:'progressSortTitle',
        defaultMessage: 'Sort by progress',
        description: 'Help text for the sortable Progress column in the reports table.',
    },
    scoreHeader: {
        id:'scoreHeader',
        defaultMessage: 'Overall Score',
        description: 'Score column header.',
    },
    scoreSortTitle: {
        id:'scoreSortTitle',
        defaultMessage: 'Sort by score',
        description: 'Help text for the sortable Score column in the reports table.',
    },
    totalScoreHeader: {
        id:'totalScoreHeader',
        defaultMessage: 'Total score',
        description: 'Total score header.',
    },
    assignedWorkHeader: {
        id:'assignedWorkHeader',
        defaultMessage: 'Assigned work',
        description: 'Assigned work header.',
    },
    emptyClassroomMessage: {
        id:'emptyClassroomMessage',
        defaultMessage: 'Your progress will be visible once the class has content.',
        description: 'Empty screen message for classroom without any assigned items.',
    },
    emptyClassroomReportLabel: {
        id:'emptyClassroomReportLabel',
        defaultMessage: '-',
        description: 'Default label for classroom report rows for class without any members.',
    },
    errorClassroomReport: {
        id:'errorClassroomReport',
        defaultMessage: 'Error fetching classroom report data.',
        description: 'Error message shown to users on failure to fetch classroom report data.',
    },
});

export default messages;
