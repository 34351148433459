// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClassroomEducator,
    ClassroomEducatorFromJSON,
    ClassroomEducatorToJSON,
    ClassroomMembership,
    ClassroomMembershipFromJSON,
    ClassroomMembershipToJSON,
    ClassroomObjectPermissions,
    ClassroomObjectPermissionsFromJSON,
    ClassroomObjectPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomUpdate
 */
export interface ClassroomUpdate {
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    subject?: string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomUpdate
     */
    maxStudents?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    readonly imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomUpdate
     */
    readonly joinCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomUpdate
     */
    archived?: boolean;
    /**
     * 
     * @type {ClassroomObjectPermissions}
     * @memberof ClassroomUpdate
     */
    permissions?: ClassroomObjectPermissions;
    /**
     * 
     * @type {Array<ClassroomMembership>}
     * @memberof ClassroomUpdate
     */
    readonly members?: Array<ClassroomMembership>;
    /**
     * 
     * @type {Array<ClassroomEducator>}
     * @memberof ClassroomUpdate
     */
    educators?: Array<ClassroomEducator>;
    /**
     * 
     * @type {number}
     * @memberof ClassroomUpdate
     */
    readonly membershipsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassroomUpdate
     */
    readonly itemsCount?: number;
}

export function ClassroomUpdateFromJSON(json: any): ClassroomUpdate {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'maxStudents': !exists(json, 'max_students') ? undefined : json['max_students'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'joinCode': !exists(json, 'join_code') ? undefined : json['join_code'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'permissions': !exists(json, 'permissions') ? undefined : ClassroomObjectPermissionsFromJSON(json['permissions']),
        'members': !exists(json, 'members') ? undefined : (json['members'] as Array<any>).map(ClassroomMembershipFromJSON),
        'educators': !exists(json, 'educators') ? undefined : (json['educators'] as Array<any>).map(ClassroomEducatorFromJSON),
        'membershipsCount': !exists(json, 'memberships_count') ? undefined : json['memberships_count'],
        'itemsCount': !exists(json, 'items_count') ? undefined : json['items_count'],
    };
}

export function ClassroomUpdateToJSON(value?: ClassroomUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'subject': value.subject,
        'max_students': value.maxStudents,
        'archived': value.archived,
        'permissions': ClassroomObjectPermissionsToJSON(value.permissions),
        'educators': value.educators === undefined ? undefined : (value.educators as Array<any>).map(ClassroomEducatorToJSON),
    };
}


