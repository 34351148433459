// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemProgress,
    ItemProgressFromJSON,
    ItemProgressToJSON,
    LearnerReportMembership,
    LearnerReportMembershipFromJSON,
    LearnerReportMembershipToJSON,
} from './';

/**
 * 
 * @export
 * @interface LearnerReport
 */
export interface LearnerReport {
    /**
     * 
     * @type {Array<ItemProgress>}
     * @memberof LearnerReport
     */
    items: Array<ItemProgress>;
    /**
     * 
     * @type {LearnerReportMembership}
     * @memberof LearnerReport
     */
    membership?: LearnerReportMembership;
    /**
     * 
     * @type {number}
     * @memberof LearnerReport
     */
    readonly totalScore?: number;
    /**
     * 
     * @type {number}
     * @memberof LearnerReport
     */
    readonly totalCompletion?: number;
}

export function LearnerReportFromJSON(json: any): LearnerReport {
    return {
        'items': (json['items'] as Array<any>).map(ItemProgressFromJSON),
        'membership': !exists(json, 'membership') ? undefined : LearnerReportMembershipFromJSON(json['membership']),
        'totalScore': !exists(json, 'total_score') ? undefined : json['total_score'],
        'totalCompletion': !exists(json, 'total_completion') ? undefined : json['total_completion'],
    };
}

export function LearnerReportToJSON(value?: LearnerReport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'items': (value.items as Array<any>).map(ItemProgressToJSON),
        'membership': LearnerReportMembershipToJSON(value.membership),
    };
}


