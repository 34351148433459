import bind from 'bind-decorator';
import * as React from 'react';
import { intl } from 'i18n';
import displatMessages from '../displayMessages';

interface ToggleSwitchProps {
    componentStyle?: 'default' | 'yellow';
    isChecked: boolean;
    displayText: string;
    ariaText?: string;
    labelPosition?: 'right' | 'left';
    hideLabel?: boolean;
    onToggleSwitchSelect: (setFlag: boolean) => void;
    disabled?: boolean;
}

interface State {
    isFocused: boolean;
}

export class ToggleSwitch extends React.PureComponent<ToggleSwitchProps, State> {

    constructor(props: ToggleSwitchProps) {
        super(props);
        this.state = {
            isFocused: false,
        };
    }

    public render() {
        const style = this.props.componentStyle || 'default';
        const disabled = Boolean(this.props.disabled);
        const labelPosition = this.props.labelPosition !== undefined ? this.props.labelPosition : 'right';
        return(
            <div className={`
                switch-container
                ${style !== 'default' ? 'switch-container-' + style : ''}`}>
                {!this.props.hideLabel && labelPosition === 'left' &&
                <span className='switch-text left'>{this.props.displayText}</span>
                }
                <label className={this.state.isFocused ? 'focus' : ''}>
                    <input
                        checked={this.props.isChecked}
                        disabled={disabled}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        aria-label={this.props.ariaText || this.props.displayText}
                        className='switch' type='checkbox'
                        aria-checked={this.props.isChecked}
                        role='switch'
                    />
                    <div>
                        <div></div>
                    </div>
                </label>
                {!this.props.hideLabel && labelPosition === 'right' &&
                <span className='switch-text right'>{this.props.displayText}</span>
                }
                <div className='toggleStatus visually-hidden' aria-live='assertive'>
                    {intl.formatMessage(displatMessages.uiToggleStatus, {
                        type: this.props.displayText,
                        status: this.props.isChecked ?
                            intl.formatMessage(displatMessages.feedbackToggleOnState) :
                            intl.formatMessage(displatMessages.feedbackToggleOffState)
                    })}
                </div>
            </div>
        );
    }

    @bind private handleChange() {
        this.props.onToggleSwitchSelect(!this.props.isChecked);
    }

    @bind private handleFocus() {
        this.setState({ isFocused: true });
    }

    @bind private handleBlur() {
        this.setState({ isFocused: false });
    }
}
