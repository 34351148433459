import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from 'elements/components/Icons';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { FileFieldProps } from './models';
import { intl } from 'i18n';

interface ImageFieldProps {
    keepGeneratedUrl?: boolean;
    maxImageSize?: number; /// Use this to avoid large images. On MiB
    onMaxImageSize?(): void;
}

/**
 * A widget that allows the user to specify an image, by dragging-and-dropping
 * or clicking or entering a URL.
 *
 * Matches the design at
 * https://app.zeplin.io/project/5d8d3ae41e40c461ec49a80f/screen/5d8e125f9289ba61a4d8f30c
 */
export const ImageField: React.FunctionComponent<ImageFieldProps & FileFieldProps> = ({
    currentFile,
    currentUrl,
    allowUpload = true,
    allowUrlInput = false,
    onChange,
    required,
    showErrors,
    keepGeneratedUrl = false,
    maxImageSize,
    onMaxImageSize,
    label = <WrappedMessage message={messages.defaultImageLabel} />,
}) => {
    const id = uniqueId();

    // Is there currently an image set?
    const hasImage = Boolean(currentFile || currentUrl);
    // And what is its filename?
    const currentFileName = (
        currentFile ? currentFile.name :
        currentUrl ? currentUrl.split('/').pop() :
        ''
    );

    const isError = required && showErrors && !hasImage;
    const fullLabel = label ? <>{label}{required ? '*' : ''}</> : null;

    // If the image is a File object (not uploaded to server yet), generate a URL for it:
    const [generatedFileUrl, setGeneratedFileUrl] = React.useState('');
    React.useEffect(() => {
        if (currentFile) {
            // Verify the size of the image
            // It occurs because in edx-platform a verification is made that generates the error
            // https://github.com/edx/edx-platform/blob/master/openedx/core/djangoapps/content_libraries/views.py#L726
            if (maxImageSize && currentFile.size > maxImageSize * 1024 * 1024) {
                if (onMaxImageSize) { onMaxImageSize(); }
                setGeneratedFileUrl('');
                onChange(undefined, '', '');
                return;
            }
            const newGeneratedUrl = URL.createObjectURL(currentFile);
            setGeneratedFileUrl(newGeneratedUrl);
            onChange(currentFile, currentUrl, newGeneratedUrl);
            if (keepGeneratedUrl) { return; }
            // Specify how to clean up after this effect, to avoid a memory leak for the image URLs:
            return function cleanup() { URL.revokeObjectURL(newGeneratedUrl); };
        } else {
            setGeneratedFileUrl('');
            onChange(currentFile, currentUrl, '');
            return;
        }
    }, [currentFile]); // Only re-run this effect when currentFile changes

    // If the user is entering in a new URL (allowUrlInput is true), this state variable
    // will store the new URL:
    const [newUrl, setNewUrl] = React.useState('');

    // When the "Delete" button gets clicked:
    const onDeleteClick = () => { onChange(/* newFile: */ undefined, /* newUrl: */ '', generatedFileUrl); };

    // Our handler for drag-and-drop / file uploads.
    // It's wrapped in useCallback as an optimization - see https://reactjs.org/docs/hooks-reference.html#usecallback
    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        onChange(acceptedFiles[0], /* newUrl: */ '', generatedFileUrl);
    }, [onChange]);

    // Build the dropzone. We set 'noClick' if there is already an image,
    // so that clicking on the image won't open the file upload dialog
    // (users can click on the "Change" button to do that).
    // Note that due to the rules of React hooks, we must always call this
    // useDropzone() method every time, even it allowUpload is false.
    const dropZone = useDropzone({onDrop, accept: 'image/*', multiple: false, noClick: hasImage});
    const dropZoneRootProps = allowUpload ? dropZone.getRootProps() : {};
    const fileInputElement = allowUpload ? <input {...dropZone.getInputProps()} /> : null;
    const isDragActive = allowUpload && dropZone.isDragActive;

    return <>
        {fullLabel && <p id={`${id}Label`} className={'lx-file-field-label'}>{fullLabel}</p>}
            <div
                className={`lx-file-field ${isError ? 'lx-file-field-error' : ''}`}
                id={id}
                aria-labelledby={`${id}Label`}
            >
            {hasImage ?
                // There is currently an image set:
                <>
                    {/* Show the currently selected image, which may also be a dropzone to change the image. */}
                    <div
                        className={`lx-file-field-img-wrapper ${isDragActive ? 'drag-active' : ''}`}
                        {...dropZoneRootProps}
                    >
                        {fileInputElement}
                        <img
                            src={currentFile ? generatedFileUrl : currentUrl}
                            alt={intl.formatMessage(messages.currentImageAlt)}
                        />
                    </div>
                    {/* Toolbar to allow changing the image */}
                    <div className='lx-file-field-current-img-toolbar'>
                        {/* Show the filename of the current image in the bottom left */}
                        <span className='filename' aria-hidden={true}>{currentFileName}</span>
                        {/* Change button: If uploads are allowed, this opens the file upload popup */}
                        {allowUpload &&
                            <button
                                className='btn btn-link btn-small'
                                onClick={() => {
                                    if (dropZone.inputRef.current) { dropZone.inputRef.current.click(); }
                                }}
                            >
                                <Icon name='arrow-up' />
                                <WrappedMessage message={messages.changeImage}/>
                            </button>
                        }
                        {/* Delete button */}
                        <button className='btn btn-link btn-small' onClick={onDeleteClick}>
                            <Icon name='trashcan' />
                            <WrappedMessage message={messages.deleteImage}/>
                        </button>
                    </div>
                </>
            : // Else if there is currently no image set:
                <>
                    {/* Display the dropzone, if allowed */}
                    {allowUpload &&
                        <div
                            className={`lx-file-field-dropzone ${isDragActive ? 'drag-active' : ''}`}
                            {...dropZoneRootProps}
                        >
                            {fileInputElement}
                            <Icon name='plus' zoom='35' />
                            <p className='main'><WrappedMessage message={messages.uploadAnImage} /></p>
                            <p><WrappedMessage message={messages.uploadImageInstructions} /></p>
                            {maxImageSize &&
                                <p>
                                    <WrappedMessage
                                        message={messages.maxImageSizeHelpMessage}
                                        values={{maxImageSizeNumber: maxImageSize}}
                                    />
                                </p>
                            }
                        </div>
                    }
                    {/* "----Or----" divider between upload and URL field, if both are enabled */}
                    {
                        (allowUpload && allowUrlInput) && <p className='lx-file-field-or'>
                            <span><WrappedMessage message={messages.or} /></span>
                        </p>
                    }
                    {/* Display the "Enter URL" component, if allowed */}
                    {allowUrlInput &&
                        <div className='input-group lx-file-field-url-wrapper' role='group'>
                            <input
                                className='form-control'
                                placeholder={intl.formatMessage(messages.enterImageUrl)}
                                value={newUrl}
                                onChange={(event) => setNewUrl(event.target.value)}
                            />
                            <div className='input-group-append'>
                                <button
                                    className='btn btn-primary'
                                    disabled={!newUrl /* Disable if no URL has been entered */}
                                    onClick={() => onChange(/* newFile: */ undefined, newUrl, generatedFileUrl)}
                                >
                                    <WrappedMessage message={messages.saveUrl}/>
                                </button>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    </>;
};

let lastId = 0;
/** Helper function to generate a unique HTML ID. */
function uniqueId(): string { return `ImageField${++lastId}`; }
