import {getDefaultState} from '../../models';
import {
    SHORT_ANSWERS_ADD_CORRECT,
    SHORT_ANSWERS_ADD_INCORRECT,
    SHORT_ANSWERS_REMOVE,
    SHORT_ANSWERS_CHANGED,
} from '../actions/action-types';
const defaultOption = {id:1, value: 'text', label: 'text'};

const ShortAnswerReducer = function(state = getDefaultState().shortAnswers, action: any) {

    const shortAnswersList: any[] = state.shortAnswersList;
    switch (action.type) {
        case SHORT_ANSWERS_ADD_CORRECT:
            let emptyCorrectAnswer = {
                id: Math.random(), value: '', currentType: defaultOption, correct: true
            };
            return {
                ...state,
                shortAnswersList: shortAnswersList.concat([emptyCorrectAnswer])
            };
        case SHORT_ANSWERS_ADD_INCORRECT:
            let emptyIncorrectAnswer = {
                id: Math.random(), value: '', currentType: defaultOption, correct: false
            };
            return {
                ...state,
                shortAnswersList: shortAnswersList.concat([emptyIncorrectAnswer])
            };
        case SHORT_ANSWERS_REMOVE:
            return {
                ...state,
                shortAnswersList: shortAnswersList.filter((single: any) => single.id !== action.id)
            };
        case SHORT_ANSWERS_CHANGED:
            return {
                ...state,
                shortAnswersList: shortAnswersList.map((single: any) => {
                    if (single.id === action.id) {
                        return {
                            id: action.id,
                            value: action.value,
                            currentType: action.currentType,
                            correct: action.correct,
                            feedback: action.feedback
                        };
                    } else {
                        return single;
                    }
                })
            };
        default:
            return state;
    }
};

export default ShortAnswerReducer;
