import * as React from 'react';
import {Helmet} from 'react-helmet';
import {intl, locale} from '../../../i18n';
import Favicon32 from 'assets/images/favicon-32x32.png';
import Favicon192 from 'assets/images/favicon-192x192.png';
import messages from '../displayMessages';
import {bind} from 'bind-decorator';
import {announce} from 'oaf-side-effects';

interface HelmetComponentState {
  canonicalUrl: string;
}

const srNavigationAnnouncementsDivId = 'navigation-announcements';
export const srNavigationAnnouncementsDiv = (
  <div id={srNavigationAnnouncementsDivId} className='sr-only' aria-live='polite' aria-atomic='true'/>
);

export class HelmetComponent extends React.PureComponent<{}, HelmetComponentState> {
  private lastTitle: string | null = null;

  constructor(props: {}) {
    super(props);
    this.state = {
      canonicalUrl: ''
    };
  }

  public render() {
    return (
      <Helmet onChangeClientState={this.onChangeClientState}>
        <html lang={locale}/>
        <link rel='icon' type='image/png' sizes='32x32' href={Favicon32}/>
        <link rel='icon' type='image/png' sizes='192x192' href={Favicon192}/>
        <title>{this.defaultPageTitle()}</title>
        <link rel='canonical' href={this.state.canonicalUrl}/>
      </Helmet>
    );
  }

  private defaultPageTitle() {
    return intl.formatMessage(messages.defaultPageTitle);
  }

  @bind public onChangeClientState(newState: { title: string }) {
    const newTitle = newState.title;
    if (newTitle !== this.lastTitle && newTitle !== this.defaultPageTitle()) {
      const announcement = intl.formatMessage(
        messages.srPageNavigationAnnouncement, {pageTitle: newTitle},
      );
      announce(announcement, srNavigationAnnouncementsDivId);
    }
    this.lastTitle = newTitle;
    const newCanonicalUrl = `${window.location.origin}${window.location.pathname}`;
    if (this.state.canonicalUrl !== newCanonicalUrl) {
      this.setState({canonicalUrl: newCanonicalUrl});
    }
  }
}
