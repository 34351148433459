// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ConversationInfo
 */
export interface ConversationInfo {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationInfo
     */
    hasBlockedYou: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationInfo
     */
    isBlocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    status: ConversationInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ConversationInfo
     */
    username: string;
}

export function ConversationInfoFromJSON(json: any): ConversationInfo {
    return {
        'hasBlockedYou': json['has_blocked_you'],
        'isBlocked': json['is_blocked'],
        'status': json['status'],
        'username': json['username'],
    };
}

export function ConversationInfoToJSON(value?: ConversationInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'has_blocked_you': value.hasBlockedYou,
        'is_blocked': value.isBlocked,
        'status': value.status,
        'username': value.username,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConversationInfoStatusEnum {
    None = 'none',
    Normal = 'normal',
    PendingYou = 'pending_you',
    PendingThem = 'pending_them'
}


