import bind from 'bind-decorator';
import { ItemsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { ItemType } from 'items/models';
import { ItemMetadata } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LanguageSelector, ModalConfirmation } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';

interface Props extends RouteComponentProps<{}> {
    metadata: ItemMetadata;
    onClose: () => void;
    onConfirm: () => void;
}

interface State {
    disabled: boolean;
    cloneLanguage: string;
}

class CreateTranslationModalInternal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            disabled: false,
            cloneLanguage: 'en',
        };
    }

    public render() {
        return (
            <ModalConfirmation
                title={messages.actionCreateTranslationTitle}
                confirmButtonText={messages.actionCreateTranslationConfirm}
                cancelButtonText={messages.actionCreateTranslationCancel}
                onCanceled={this.props.onClose}
                onConfirmed={this.onConfirm}
                disabled={this.state.disabled}
            >
                <LanguageSelector
                    original={this.props.metadata.language}
                    currentLanguage={this.state.cloneLanguage}
                    onSetLanguage={(language: string) => {this.setState({cloneLanguage: language});}}
                />
            </ModalConfirmation>
        );
    }

    @bind private async onConfirm() {
        this.setState({disabled: true});
        try {
            const item = await ItemsApi.cloneForTranslation({
                    id: this.props.metadata.id,
                    data: {
                        language: this.state.cloneLanguage,
                    },
            });
            if (item.metadata.type === ItemType.Pathway) {
                this.props.history.push(ROUTES.Library.PATHWAY_EDIT_SLUG(item.metadata.id));
                return;
            }
            this.props.history.push(ROUTES.Library.ITEM_EDIT_SLUG(item.metadata.id));
            this.props.onConfirm();
            return;
        } catch(error) {
            let errorMessage = error.statusText;
            if (error.status === 400) {
                errorMessage = (await error.json())[0];
            }
            showErrorMessage(
                <WrappedMessage message={messages.actionCreateTranslationError} values={{
                    error_message: errorMessage,
                }}/>
            );
            this.props.onClose();
        }
    }
}

export const CreateTranslationModal = withRouter(CreateTranslationModalInternal);
