// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadPostDelete
 */
export interface ThreadPostDelete {
    /**
     * 
     * @type {string}
     * @memberof ThreadPostDelete
     */
    post: string;
}

export function ThreadPostDeleteFromJSON(json: any): ThreadPostDelete {
    return {
        'post': json['post'],
    };
}

export function ThreadPostDeleteToJSON(value?: ThreadPostDelete): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'post': value.post,
    };
}


