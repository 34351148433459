import { bind } from 'bind-decorator';
import * as React from 'react';
import { Spinner, TimestampInput, timestampStringFromNumber } from 'ui/components';
import { VideoBlock } from '../Block/BlockVideo';
import { WrappedMessage } from 'utils';
import messages from 'library/displayMessages';
import { BlockData, getEmptyBlockData, LoadingState} from 'library/components/Block/models';
import videojs from 'video.js';
import { ChildItem } from 'labxchange-client';
import { getItemAndBlockData } from 'library/utils';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { VideoPlayer } from '../VideoPlayer';
import { UI_IS_SM } from 'ui/breakpoints';

interface Props {
    pathwayChild: ChildItem;
    minClippedLength: number;
    onTimeUpdate?: (startTime: number, endTime: number) => void;
    onShowControls?: (videoDuration: number) => void;
}

interface State {
    videoStartTimestamp: number;
    videoStopTimestamp: number;
    blockData: BlockData;
    player?: videojs.Player;
    showControls: boolean;
    videoLength?: number;
    clippedLength?: number;
    videoPlayerRef?: React.RefObject<VideoPlayer>;
    isMobileView: boolean;
    disableLeftOfStart: boolean;
    disableRightOfStart: boolean;
    disableLeftOfEnd: boolean;
    disableRightOfEnd: boolean;
}

export class ClipVideo extends React.PureComponent<Props,State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            videoStartTimestamp: this.props.pathwayChild.videoStartTime || 0,
            videoStopTimestamp: this.props.pathwayChild.videoStopTime || this.props.pathwayChild.item.metadata.duration || 10,
            blockData: getEmptyBlockData(),
            isMobileView: false,
            disableLeftOfStart: true,
            disableRightOfStart: true,
            disableLeftOfEnd: true,
            disableRightOfEnd: true,
            showControls: false,
        };
    }

    public componentDidMount() {
        this.loadVideoBlockData();
        window.addEventListener('resize', this.setLayout);
        window.addEventListener('resize', this.setLayout);
        this.setLayout();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.setLayout);
        window.removeEventListener('resize', this.setLayout);
    }

    public render() {
        const clippedLengthString = timestampStringFromNumber(this.state.clippedLength ?? 0);

        return (
            <>
                {this.state.player === undefined &&
                    <div className='spinner-wrapper'><Spinner /></div>
                }

                {this.state.blockData.loadingState === LoadingState.READY &&
                    <div className={`video-block${this.state.player === undefined ? ' hidden' : ''}`}>
                        <VideoBlock
                            blockData={this.state.blockData}
                            itemMetadata={this.props.pathwayChild.item.metadata}
                            onExpandBlock={() => {/* do nothing */}}
                            onPlayerReady={this.onPlayerReady}
                            playingHandler={this.playingHandler}
                            onComponentReady={this.onComponentReady}
                            showPlayerRangePaddles={true}
                            onChangeRangePaddleTime={this.onChangeRangePaddleTime}
                        />
                    </div>
                }
                <div className={`controls-wrapper${this.state.player === undefined ? ' hidden' : ''}`}>
                    <div className='controls'>
                        <div className='timestamp-input-wrapper'>
                            {/* Override the video start time for this pathway */}
                            <label htmlFor='videoStartTime'>
                                <WrappedMessage message={messages.pathwayChildVideoStartTime}/>
                            </label>
                            <TimestampInput
                                id='videoStartTime'
                                timeStamp={this.state.showControls ? this.state.videoStartTimestamp : 0}
                                onTimeStampChange={(newStart) => this.updateVideoStartTimestamp(newStart, true)}
                                interval={10}
                                disableLeft={this.state.showControls ? this.state.disableLeftOfStart : true}
                                disableRight={this.state.showControls ? this.state.disableRightOfStart : true}
                            />
                        </div>
                        {!this.state.isMobileView && <div className='video-length-wrapper'>
                            <WrappedMessage message={messages.metadataVideoDuration}/>
                            <div className='video-length'>
                                {this.state.showControls ? clippedLengthString : '__:__'}
                            </div>
                        </div>}
                        <div className='timestamp-input-wrapper'>
                            {/* Override the video start time for this pathway */}
                            <label htmlFor='videoStopTime'>
                                <WrappedMessage message={messages.pathwayChildVideoEndTime}/>
                            </label>
                            <TimestampInput
                                id='videoStopTime'
                                timeStamp={this.state.showControls ? this.state.videoStopTimestamp : 0}
                                onTimeStampChange={(newStop) => this.updateVideoStopTimestamp(newStop, true)}
                                interval={10}
                                disableLeft={this.state.showControls ? this.state.disableLeftOfEnd: true}
                                disableRight={this.state.showControls ? this.state.disableRightOfEnd: true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    @bind private setLayout() {
        if (!this.state.isMobileView && UI_IS_SM.matches) {
            this.state.videoPlayerRef?.current?.deleteRangePaddlesInPlayer();
        }
        else if (this.state.isMobileView && !UI_IS_SM.matches) {
            this.state.videoPlayerRef?.current?.showRangePaddlesInPlayer(
                this.state.videoStartTimestamp,
                this.state.videoStopTimestamp,
            );
        }
        this.setState({
            isMobileView: UI_IS_SM.matches,
        });
    }


    @bind private updateVideoStartTimestamp(newStart: number, updatePaddle: boolean) {
        if (newStart < 0) {
            newStart = 0;
        }
        let newVideoLength = this.state.videoStopTimestamp - newStart;
        if (newVideoLength < this.props.minClippedLength) {
            newStart = this.state.videoStopTimestamp - this.props.minClippedLength;
            newVideoLength = this.props.minClippedLength;
        }
        this.setState({
            videoStartTimestamp: newStart,
            clippedLength: newVideoLength,
            disableLeftOfStart: newStart === 0,
            disableRightOfStart: newVideoLength === this.props.minClippedLength,
            disableLeftOfEnd: newVideoLength === this.props.minClippedLength,
        });
        if (updatePaddle) {
            this.state.videoPlayerRef?.current?.changePaddlePosition(true, newStart);
            this.state.player?.currentTime(newStart);
        }
        if (this.props.onTimeUpdate){
            this.props.onTimeUpdate(newStart, this.state.videoStopTimestamp);
        }
        return newStart;
    }

    @bind private updateVideoStopTimestamp(newStop: number, updatePaddle: boolean) {
        if (newStop > this.state.videoLength!) {
            newStop = this.state.videoLength!;
        }
        let newVideoLength = newStop - this.state.videoStartTimestamp;
        if (newVideoLength < this.props.minClippedLength) {
            newStop = this.state.videoStartTimestamp + this.props.minClippedLength;
            newVideoLength = this.props.minClippedLength;
        }
        this.setState({
            videoStopTimestamp: newStop,
            clippedLength: newVideoLength,
            disableLeftOfEnd: newVideoLength === this.props.minClippedLength,
            disableRightOfStart: newVideoLength === this.props.minClippedLength,
            disableRightOfEnd: newStop === this.state.videoLength,
        });
        if (updatePaddle) {
            this.state.videoPlayerRef?.current?.changePaddlePosition(false, newStop);
            this.state.player?.currentTime(newStop);
        }
        if (this.props.onTimeUpdate){
            this.props.onTimeUpdate(this.state.videoStartTimestamp, newStop);
        }
        return newStop;
    }

    @bind private async onPlayerReady(player: videojs.Player) {
        this.setState({player});
    }

    @bind private onComponentReady(videoPlayerRef: React.RefObject<VideoPlayer>) {
        this.setState({videoPlayerRef});
    }

    @bind private async loadVideoBlockData() {
        const response = await getItemAndBlockData(this.props.pathwayChild.item.metadata.id);
        if (response.blockData.loadingState === LoadingState.READY) {
            this.setState({
                blockData: response.blockData,
            });
        }
        else {
            showErrorMessage(<WrappedMessage message={messages.errorLoadingDiscussion} />);
        }
    }

    @bind private playingHandler() {
        if (this.state.videoLength === undefined && this.state.player) {
            const duration = Math.trunc(this.state.player.duration());
            let clippedLength = duration;
            if (this.props.pathwayChild.videoStartTime && this.props.pathwayChild.videoStopTime) {
                clippedLength = this.props.pathwayChild.videoStopTime -
                                this.props.pathwayChild.videoStartTime;
            }
            this.setState({
                videoLength: duration,
                videoStopTimestamp: this.props.pathwayChild.videoStopTime ?? duration,
                clippedLength,
                disableLeftOfStart: (this.props.pathwayChild.videoStartTime !== undefined && this.props.pathwayChild.videoStartTime === 0) ||
                                        this.props.pathwayChild.videoStartTime === undefined,
                disableRightOfStart: clippedLength === this.props.minClippedLength,
                disableLeftOfEnd: clippedLength === this.props.minClippedLength,
                disableRightOfEnd: (this.props.pathwayChild.videoStopTime !== undefined && this.props.pathwayChild.videoStopTime === duration) ||
                                this.props.pathwayChild.videoStopTime === undefined,
                showControls: true,
            });
            if (!this.state.isMobileView) {
                this.state.videoPlayerRef?.current?.showRangePaddlesInPlayer(
                    this.props.pathwayChild.videoStartTime ?? 0,
                    this.props.pathwayChild.videoStopTime ?? duration,
                );
            }
            if (this.props.onTimeUpdate) {
                this.props.onTimeUpdate(this.state.videoStartTimestamp, duration);
            }
            if (this.props.onShowControls) {
                this.props.onShowControls(duration);
            }
            this.state.player.currentTime(this.state.videoStartTimestamp);
        }
        return true;
    }

    @bind private onChangeRangePaddleTime(isLeft: boolean, newTime: number) {
        newTime = Math.trunc(newTime);
        if (isLeft) {
            newTime = this.updateVideoStartTimestamp(newTime, false);
        }
        else {
            newTime = this.updateVideoStopTimestamp(newTime, false);
        }
        return newTime;
    }

}