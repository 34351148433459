import bind from 'bind-decorator';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { RootState } from 'global/state';
import { HtmlTextBox } from 'ui/components';
import { intl } from 'i18n';
import * as actionTypes from '../store/actions/action-types';
import ShortAnswers from '../components/UnitEditorWidget/ShortAnswers';
import ShortAdvancedSettings from '../components/AdvancedSettings/ShortAdvancedSettings';
import { ShortAnswerType, SingleSelectTypes } from '../models';

const messages = defineMessages({
    title: {
        id: 'ShortAnswerContainer.title',
        defaultMessage: 'Question*'
    },
    description: {
        id: 'ShortAnswerContainer.description',
        defaultMessage: 'Create a question that only has one answer.'
    }
});
class ShortAnswerContainer extends React.Component<any, any> {

    render() {
        return (
            <div className='lxc-unit-editor-wrapper'>
                <fieldset className='lxc-answers-wrapper'>
                    <legend className='lxc-answers-title'>
                        {intl.formatMessage(messages.title)}
                    </legend>
                    <div className='lxc-answers-description'>
                        {intl.formatMessage(messages.description)}
                    </div>
                    <HtmlTextBox
                        label='Question Editor'
                        hideLabel={true}
                        defaultValue={this.props.editorContent}
                        onChange={this.onEditorContentChange}
                        editorStyle={HtmlTextBox.EditorStyle.Default}
                    />
                </fieldset>
                <ShortAnswers
                    shortAnswersList={this.props.shortAnswersList}
                    typeOptions={this.props.typeOptions}
                    shortAnswersAddCorrectAnswer={this.props.shortAnswersAddCorrectAnswer}
                    shortAnswersAddIncorrectAnswer={this.props.shortAnswersAddIncorrectAnswer}
                    shortAnswersRemoveAnswer={this.props.shortAnswersRemoveAnswer}
                    shortAnswersChangeAnswer={this.props.shortAnswersChangeAnswer}
                />
                <ShortAdvancedSettings

                    groupFeedbackContent={this.props.groupFeedbackContent}

                    hints={this.props.hints}

                    groupFeedbackchange={this.props.groupFeedbackchange}

                    hintAdd={this.props.hintAdd}
                    hintRemove={this.props.hintRemove}
                    hintChange={this.props.hintChange}

                    answerTypeSelectedOption={this.props.answerTypeSelectedOption}
                    answerTypeOptions={this.props.answerTypeOptions}
                    answerTypeChange={this.props.answerTypeChange}

                    scorringSelectedPointOption={this.props.scorringSelectedPointOption}
                    scorringselectedAttemptsOption={this.props.scorringselectedAttemptsOption}
                    scorringattemptsOptions={this.props.scorringattemptsOptions}
                    scorringPointsOptions={this.props.scorringPointsOptions}
                    scorringAttemptsChange={this.props.scorringAttemptsChange}
                    scorringPointsChange={this.props.scorringPointsChange}

                />
            </div>
        );
    }

    @bind private onEditorContentChange(content: string) {
        this.props.shortAnswerEditorContentChange(content);
    }
}

const mapStateToProps = (store: RootState) => {
    return {
        shortAnswersList: store.assessmentEditorState.shortAnswers.shortAnswersList,
        typeOptions: ShortAnswerType,
        // editor content
        editorContent: store.assessmentEditorState.shortAnswerEditor.content,
        // general feedback
        generalFeedbackData: store.assessmentEditorState.multiSelectAnswers.generalFeedbackList,
        // hints
        hints: store.assessmentEditorState.hintSettings.hints,
        // answer type
        answerTypeSelectedOption: store.assessmentEditorState.singleSelectAnswers.selectedType,
        answerTypeOptions: SingleSelectTypes,
        // scorring
        scorringSelectedPointOption: store.assessmentEditorState.scorringSettings.selectedPointOption,
        scorringselectedAttemptsOption: store.assessmentEditorState.scorringSettings.selectedAttemptsOption,
        scorringattemptsOptions: store.assessmentEditorState.scorringSettings.attemptsOptions,
        scorringPointsOptions: store.assessmentEditorState.scorringSettings.pointsOptions
    }
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        // editor
        shortAnswerEditorContentChange: (content: any) => {
            return dispatch({ type: actionTypes.SHORT_ANSWER_EDITOR_CONTENT_CHANGE, content: content });
        },
        shortAnswersAddCorrectAnswer: (event: any) => {
            return dispatch({ type: actionTypes.SHORT_ANSWERS_ADD_CORRECT });
        },
        shortAnswersAddIncorrectAnswer: (event: any) => {
            return dispatch({ type: actionTypes.SHORT_ANSWERS_ADD_INCORRECT });
        },
        shortAnswersRemoveAnswer: (id: any) => {
            return dispatch({ type: actionTypes.SHORT_ANSWERS_REMOVE, id: id });
        },
        shortAnswersChangeAnswer: (data: any) => {
            return dispatch({ type: actionTypes.SHORT_ANSWERS_CHANGED, ...data });
        },
        // Hints
        hintAdd: (event: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_ADD });
        },
        hintRemove: (id: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_REMOVE, id: id });
        },
        hintChange: (data: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_CHANGED, ...data });
        },
        // answerType setting
        answerTypeChange: (value: any) => {
            return dispatch({ type: actionTypes.ANSWER_TYPE_SETTING_CHANGED, selectedType: value })
        },
        // scorring settings
        scorringAttemptsChange: (value: any) => {
            return dispatch({ type: actionTypes.SCORRING_TEMPTS_CHANGED, ...value })
        },
        scorringPointsChange: (value: any) => {
            return dispatch({ type: actionTypes.SCORRING_POINTS_CHANGED, ...value })
        }
    }
};

export default connect<any, any, RootState>(mapStateToProps, mapDispatchToProps)(ShortAnswerContainer);
