import { defineMessages } from 'react-intl';

const messages = defineMessages({
    sectionTitle: {
        id:'sectionTitle',
        defaultMessage: 'Individual authors',
        description: 'Individual authors section title.',
    },
    addAnother: {
        id:'addAnother',
        defaultMessage: 'Add another author',
        description: 'Label for the button to add a new individual author.',
    },
    inputPlaceholder: {
        id:'inputPlaceholder',
        defaultMessage: 'Enter name',
        description: 'Placeholder text in the input fields for entering a name in the Individual authors name field.',
    },
    subtitle: {
        id:'subtitle',
        defaultMessage: 'Name',
        description: 'Small title over the first in the list of input boxes.',
    },
    authorFieldLabel: {
        id:'authorFieldLabel',
        defaultMessage: 'Owner name',
        description: 'Accessibility label for each owner field input.',
    },
    deleteButtonLabel: {
        id:'deleteButtonLabel',
        defaultMessage: 'Remove owner',
        description: 'Accessibility label for the remove owner trashcan icon button',
    },
    meText: {
        id:'meText',
        defaultMessage: ' (me) ',
        description: 'Text to identify current logged in user',
    },
});

export default messages;
