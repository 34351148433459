import { bind } from 'bind-decorator';
import { MentorshipsApi } from 'global/api';
import { Mentorship, MentorshipStatusEnum, Profile, ProfileRoleEnum } from 'labxchange-client';
import * as React from 'react';
import { Button, Modal, ModalConfirmation } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { UserAvatar } from '../../../user/components/UserAvatar';
import { UserProfileSection } from '../../../user/components/UserProfileSection';
import { ApplyByMenteeModal } from '../ApplyByMenteeModal';
import messages from './displayMessages';

interface State {
    mentorship?: Mentorship;
    profileMentorships: Mentorship[];
    loadingMentorships: boolean;
    shouldShowApplyModal: boolean;
    shouldShowApplySuccessModal: boolean;
    shouldShowCancelModal: boolean;
}

interface Props {
    profile: Profile;
    loggedInUsername?: string;
}

export class MentorshipProfile extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            profileMentorships: [],
            loadingMentorships: true,
            shouldShowApplyModal: false,
            shouldShowApplySuccessModal: false,
            shouldShowCancelModal: false,
        };
    }

    public async componentDidMount() {
        try {
            await this.fetchMentorships();
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
    }

    public render() {
        if (!this.props.profile.mentorship!.enabled &&
            (this.state.mentorship === undefined || this.state.mentorship!.status !== MentorshipStatusEnum.Pending
        )) { return null; }
        if (this.props.profile.role !== ProfileRoleEnum.Educator) { return null; }
        return (
            <UserProfileSection title={messages.userProfileMentorship} sectionName='mentorship-program'>
                {this.renderSectionContent()}
            </UserProfileSection>
        );
    }

    private renderSectionContent() {
        return (
            <div className='mentorship-profile'>
                {this.props.profile.mentorship!.enabled ?
                    <>
                    <div className='user-profile-mentorship-text'>
                        {this.props.profile.mentorship!.message}
                    </div>
                    {this.renderMenteesList()}
                    </>
                : null}
                <div className='mentorship-profile-buttons'>{this.renderActionButtons()}</div>
                {this.renderModals()}
            </div>
        );
    }

    private renderActionButtons() {
        if (this.props.loggedInUsername === '' || this.state.loadingMentorships) {
            return (null);
        }
        if (this.state.mentorship !== undefined) {
            if (this.state.mentorship.status === MentorshipStatusEnum.Pending) {
                return (
                    <Button
                        btnStyle='primary'
                        label={messages.userProfileMentorshipCancelButton}
                        labelValues={{ fullName: this.props.profile.fullName }}
                        onClick={this.onOpenCancelModal}/>
                );
            }
        } else if (this.props.profile.mentorship!.enabled) {
            return (
                <Button
                    btnStyle='normal'
                    icon='person'
                    label={messages.userProfileMentorshipApplyButton}
                    labelValues={{ fullName: this.props.profile.fullName }}
                    onClick={this.onOpenApplyMentorshipModal}/>
            );
        }
        return (undefined);
    }

    private renderModals() {
        if (this.state.shouldShowApplyModal) {
            return (
                <ApplyByMenteeModal
                    profile={this.props.profile}
                    loggedInUsername={this.props.loggedInUsername!}
                    onSuccess={this.onSuccessApply}
                    onError={this.onErrorApply}
                    onClose={this.onCloseApplyMentorshipModal}
                />
            );
        } else if (this.state.shouldShowApplySuccessModal) {
            return (
                <Modal
                    onRequestClose={this.onCloseSuccessMentorshipModal}
                    content={
                        <div className='mentorship-success-modal'>
                            <div className='success-icon'></div>
                            <div className='title-text'>
                                <WrappedMessage message={messages.applySuccessTitle} />
                            </div>
                            <div className='message'>
                                <strong>{this.props.profile.fullName}</strong>
                                <WrappedMessage message={messages.applySuccessMessage} />
                            </div>
                        </div>
                    }
                    footer={
                        <Button
                            btnStyle='primary'
                            onClick={this.onCloseSuccessMentorshipModal}
                            label={messages.applySuccessBackToProfileButton}
                            labelValues={{ fullName: this.props.profile.fullName }}/>
                    }
                />
            );
        } else if (this.state.shouldShowCancelModal) {
            return (
                <ModalConfirmation
                    onCanceled={this.onCloseCancelModal}
                    onConfirmed={this.handleCancelMentorship}
                    title={messages.cancelMentorshipRequestTitle}
                    confirmButtonText={uiMessages.uiConfirmationButton}
                    cancelButtonText={uiMessages.uiCancelButton}
                />
            );
        }
        return (null);
    }

    @bind private onOpenApplyMentorshipModal() {
        this.setState({ shouldShowApplyModal: true });
    }

    @bind private onCloseApplyMentorshipModal() {
        this.setState({ shouldShowApplyModal: false });
    }

    @bind private onCloseSuccessMentorshipModal() {
        this.setState({ shouldShowApplySuccessModal: false });
    }

    @bind private onOpenCancelModal() {
        this.setState({ shouldShowCancelModal: true });
    }

    @bind private onCloseCancelModal() {
        this.setState({ shouldShowCancelModal: false });
    }

    @bind private onSuccessApply(mentorship: Mentorship) {
        this.setState({
            mentorship,
            shouldShowApplyModal: false,
            shouldShowApplySuccessModal: true,
        });
    }

    @bind private onErrorApply() {
        this.setState({shouldShowApplyModal: false});
        showErrorMessage(<WrappedMessage message={messages.onFailedToApply}/>, {
            confirmText: messages.confirmButtonText,
        });
    }

    @bind private async handleCancelMentorship() {
        try {
            const mentorship = await MentorshipsApi.partialUpdate({
                id: this.state.mentorship!.id, data: { status: MentorshipStatusEnum.Canceled },
            });
            if (mentorship.status === MentorshipStatusEnum.Canceled) {
                this.setState({ mentorship });
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
        this.setState({ shouldShowCancelModal: false });
    }

    private renderMenteesList() {
        const mentorships = this.state.profileMentorships;

        if (mentorships.length === 0) {
            return (null);
        }

        const mentorshipsElements = mentorships.map((mentorship) => {
            return (
                <div key={mentorship.id}
                    className='mentee-list-icon'>
                    <UserAvatar username={mentorship.mentee.username}/>
                </div>
            );
        });

        return (
            <div className='mentees'>
                <div className='mentees-list'>{mentorshipsElements}</div>
            </div>
        );
    }

    private async fetchMentorships() {
        // Retrieve user profile mentorships as mentor
        try {
            const profileMentorships = await MentorshipsApi.list(
                { status: MentorshipStatusEnum.Accepted, mentor: this.props.profile.username },
            );
            this.setState({ profileMentorships });
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }

        try {
            let mentorships = await MentorshipsApi.list({
                target: this.props.loggedInUsername, source: this.props.profile.username,
            });
            if (mentorships.length === 0) {
                mentorships = await MentorshipsApi.list({
                    source: this.props.loggedInUsername, target: this.props.profile.username,
                });
            }
            if (mentorships.length > 0) {
                this.setState({ mentorship: mentorships[0] });
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
        this.setState({ loadingMentorships: false });
    }
}
