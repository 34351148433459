import * as React from 'react';
import { ContainerOne } from 'elements/components/Containers';
import { sanitizeConfigOptions, sanitizeUnsafeHTML } from '@labxchange/util-sanitization';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { WrappedMessage } from 'utils';
import messages from 'library/displayMessages';
import { Icon } from 'elements';

interface ReferencesBoxProps {
  references: string;
}

interface ReferencesBoxState {
  isCollapsed: boolean;
}

export class ReferencesBox extends React.PureComponent<ReferencesBoxProps, ReferencesBoxState> {
  constructor(props: ReferencesBoxProps) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  }

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.toggleCollapse();
    }
  }

  handleSpacebarScroll = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      // Prevent scrolling when expanding references with the spacebar.
      event.preventDefault();
    }
  }

  render() {
    const { references } = this.props;
    const { isCollapsed } = this.state;

    if (!references) {
      return null;
    }

    return (
      /* The <div> element has a child elements that allow keyboard interaction */
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <ContainerOne>
        <div className='references-box'>
          <div
            className={`references-header ${isCollapsed ? 'collapsed' : ''}`}
            onClick={this.toggleCollapse}
            onKeyUp={this.handleKeyPress}
            onKeyDown={this.handleSpacebarScroll}
            role='button'
            tabIndex={0}
            aria-expanded={!isCollapsed}
            aria-controls='references-content'
          >
            <WrappedMessage message={messages.referencesHeading}/>
            <Icon
              className='collapse-icon'
              name={isCollapsed ? 'chevron-up' : 'chevron-down'}
            />
          </div>
          {!isCollapsed && (
            <div
              className='references-content'
              id='references-content'
            >
              <HTMLEllipsis
                className='references-html'
                unsafeHTML={sanitizeUnsafeHTML(references, sanitizeConfigOptions.UnsafeHTMLReferences)}
                maxLine={100000}
              />
            </div>
          )}
        </div>
      </ContainerOne>
    );
  }
}
