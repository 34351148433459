// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Edition
 */
export interface Edition {
    /**
     * 
     * @type {string}
     * @memberof Edition
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Edition
     */
    editionType: EditionEditionTypeEnum;
    /**
     * The ISBN-10 of the book.
     * @type {string}
     * @memberof Edition
     */
    isbn10?: string;
    /**
     * The ISBN-13 of the book.
     * @type {string}
     * @memberof Edition
     */
    isbn13?: string;
}

export function EditionFromJSON(json: any): Edition {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'editionType': json['edition_type'],
        'isbn10': !exists(json, 'isbn_10') ? undefined : json['isbn_10'],
        'isbn13': !exists(json, 'isbn_13') ? undefined : json['isbn_13'],
    };
}

export function EditionToJSON(value?: Edition): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'edition_type': value.editionType,
        'isbn_10': value.isbn10,
        'isbn_13': value.isbn13,
    };
}

/**
* @export
* @enum {string}
*/
export enum EditionEditionTypeEnum {
    Digital = 'digital',
    Ibooks = 'ibooks',
    Print = 'print'
}


