import { defineMessages } from 'react-intl';

const messages = defineMessages({
    defaultDocumentLabel: {
        id:'defaultDocumentLabel',
        defaultMessage: 'Document',
        description: 'Label for the document upload form field',
    },
    changeDocument: {
        id:'changeDocument',
        defaultMessage: 'Change',
        description: 'Button to change the current document',
    },
    deleteDocument: {
        id:'deleteDocument',
        defaultMessage: 'Delete',
        description: 'Button to delete the current document',
    },
    enterDocumentUrl: {
        id:'enterDocumentUrl',
        defaultMessage: 'Enter document URL',
        description: 'Text describing that a user can enter the URL to an document',
    },
    uploadADocument: {
        id:'uploadADocument',
        defaultMessage: 'Upload a document',
        description: 'Instruction heading',
    },
    uploadDocumentInstructions: {
        id:'uploadDocumentInstructions',
        defaultMessage: 'Drag and drop a document from your desktop, or click here to upload one.',
        description: 'Instructions for how to upload a document',
    },
    uploadDocumentRequirements: {
        id:'uploadDocumentRequirements',
        defaultMessage: 'Requirements: Must be a pdf smaller than 10MB.',
        description: 'Requirements for uploading a document',
    },
    defaultImageLabel: {
        id:'defaultImageLabel',
        defaultMessage: 'Image',
        description: 'Label for the image upload form field',
    },
    currentImageAlt: {
        id:'currentImageAlt',
        defaultMessage: 'The current image',
        description: 'Label for the current image in an image upload form field',
    },
    changeImage: {
        id:'changeImage',
        defaultMessage: 'Change',
        description: 'Button to change the current image',
    },
    deleteImage: {
        id:'deleteImage',
        defaultMessage: 'Delete',
        description: 'Button to delete the current image',
    },
    enterImageUrl: {
        id:'enterImageUrl',
        defaultMessage: 'Enter image URL',
        description: 'Text describing that a user can enter the URL to an image',
    },
    uploadAnImage: {
        id:'uploadAnImage',
        defaultMessage: 'Upload an image',
        description: 'Instruction heading',
    },
    uploadImageInstructions: {
        id:'uploadImageInstructions',
        defaultMessage: `Add an image that previews what this asset is about.
         Drag and drop an image from your desktop, or click here to select an image to upload.`,
        description: 'Instructions for how to upload an image',
    },
    saveUrl: {
        id:'saveUrl',
        defaultMessage: 'Save',
        description: '"Save" button text that user can click after entering a URL',
    },
    or: {
        id:'or',
        defaultMessage: 'Or',
        description: 'The word "Or", shown between two options for file upload',
    },
    maxImageSizeHelpMessage: {
        id: 'maxImageSizeHelpMessage',
        defaultMessage: 'Max size: {maxImageSizeNumber} MB',
        description: 'The help message that appears in the upload image button when there is a max image size.',
    },
});

export default messages;
