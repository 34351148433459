// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Subject,
    SubjectFromJSON,
    SubjectToJSON,
    SubjectArea,
    SubjectAreaFromJSON,
    SubjectAreaToJSON,
} from '../models';

/**
 * no description
 */
export class LibraryApi extends runtime.BaseAPI {

    /**
     * If the total public item count is available in the cache, we just return it from there, otherwise fetch fresh count from OpenSearch. The cache is set to expire every 24 hours.
     * Get total public items count from the library.
     */
    async getPublicItemsCountRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/library/library_count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * If the total public item count is available in the cache, we just return it from there, otherwise fetch fresh count from OpenSearch. The cache is set to expire every 24 hours.
     * Get total public items count from the library.
     */
    async getPublicItemsCount(): Promise<any> {
        const response = await this.getPublicItemsCountRaw();
        return await response.value();
    }

    /**
     * Get the list of subject areas that items can be tagged with.
     */
    async getSubjectAreasRaw(): Promise<runtime.ApiResponse<Array<SubjectArea>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/library/subject_areas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectAreaFromJSON));
    }

    /**
     * Get the list of subject areas that items can be tagged with.
     */
    async getSubjectAreas(): Promise<Array<SubjectArea>> {
        const response = await this.getSubjectAreasRaw();
        return await response.value();
    }

    /**
     * Get the list of subject tags that items can be tagged with.
     */
    async getSubjectTagsRaw(): Promise<runtime.ApiResponse<Array<Subject>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/library/subject_tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectFromJSON));
    }

    /**
     * Get the list of subject tags that items can be tagged with.
     */
    async getSubjectTags(): Promise<Array<Subject>> {
        const response = await this.getSubjectTagsRaw();
        return await response.value();
    }

}
