// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    MentorshipItem,
    MentorshipItemFromJSON,
    MentorshipItemToJSON,
    MentorshipScore,
    MentorshipScoreFromJSON,
    MentorshipScoreToJSON,
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface Mentorship
 */
export interface Mentorship {
    /**
     * 
     * @type {string}
     * @memberof Mentorship
     */
    id: string;
    /**
     * 
     * @type {Profile}
     * @memberof Mentorship
     */
    mentor: Profile;
    /**
     * 
     * @type {Profile}
     * @memberof Mentorship
     */
    mentee: Profile;
    /**
     * 
     * @type {string}
     * @memberof Mentorship
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof Mentorship
     */
    target: string;
    /**
     * 
     * @type {string}
     * @memberof Mentorship
     */
    initiator: MentorshipInitiatorEnum;
    /**
     * 
     * @type {string}
     * @memberof Mentorship
     */
    status: MentorshipStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Mentorship
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Mentorship
     */
    updated: Date;
    /**
     * 
     * @type {Array<MentorshipItem>}
     * @memberof Mentorship
     */
    readonly items?: Array<MentorshipItem>;
    /**
     * 
     * @type {MentorshipScore}
     * @memberof Mentorship
     */
    completion: MentorshipScore;
}

export function MentorshipFromJSON(json: any): Mentorship {
    return {
        'id': json['id'],
        'mentor': ProfileFromJSON(json['mentor']),
        'mentee': ProfileFromJSON(json['mentee']),
        'source': json['source'],
        'target': json['target'],
        'initiator': json['initiator'],
        'status': json['status'],
        'created': new Date(json['created']),
        'updated': new Date(json['updated']),
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<any>).map(MentorshipItemFromJSON),
        'completion': MentorshipScoreFromJSON(json['completion']),
    };
}

export function MentorshipToJSON(value?: Mentorship): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'mentor': ProfileToJSON(value.mentor),
        'mentee': ProfileToJSON(value.mentee),
        'source': value.source,
        'target': value.target,
        'initiator': value.initiator,
        'status': value.status,
        'created': value.created.toISOString(),
        'updated': value.updated.toISOString(),
        'completion': MentorshipScoreToJSON(value.completion),
    };
}

/**
* @export
* @enum {string}
*/
export enum MentorshipInitiatorEnum {
    MentorIsInitiator = 'mentor_is_initiator',
    MenteeIsInitiator = 'mentee_is_initiator'
}
/**
* @export
* @enum {string}
*/
export enum MentorshipStatusEnum {
    Pending = 'pending',
    ReRequested = 're-requested',
    Accepted = 'accepted',
    Refused = 'refused',
    Canceled = 'canceled',
    StoppedByMentor = 'stopped-by-mentor',
    StoppedByMentee = 'stopped-by-mentee'
}


