import { bind } from 'bind-decorator';
import { Badge, Icon } from 'elements';
import { LocationDescriptor } from 'history';
import { locale, intl } from 'i18n';
import { ItemType, getItemTypeMeta } from 'items/models';
import {
    APIPermissions,
    ChildItem,
    ItemMetadata,
    ItemMetadataTypeEnum,
    ItemUserAttributes,
    ModerationRequestContentTypeEnum,
    RelatedItemMetadata
} from 'labxchange-client';
import messages from 'library/displayMessages';
import { detailUrlForEntity, getPathwayChild, getPathwayChildIndex } from 'library/utils';
import * as React from 'react';
import { Redirect } from 'react-router';
import { UI_IS_MD } from 'ui/breakpoints';
import {
    Button,
    BackToButton,
    ExpandableText,
    ModalConfirmation,
    ShareModal,
    AlertBox,
} from 'ui/components';
import { WrappedMessage, convertTimeZone } from 'utils';
import { Tag } from 'ui/components';

import { Block } from '../';
import { BlockData } from '../Block/models';
import { ContentSourceInfoBox } from '../ContentSourceInfoBox';
import { ReferencesBox } from './ReferencesBox';
import { CreateTranslationModal } from '../CreateTranslationModal';
import { ItemActions } from '../ItemActions';
import { ItemAssign } from '../ItemAssign';
import { ItemMetadataTable } from '../ItemMetadataTable';
import { AuthorsList } from './AuthorsList';
import { FundersList } from './FundersList';
import { PreviewNextBox } from './PreviewNextBox';
import { LxConfig } from 'global/constants';
import { ROUTES } from 'global/constants';
import {ReportButton} from '../../../moderation/components';
import { ResourcesListing } from '../ResourcesSection';
import { history } from 'global/history';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';


const dateFormatter = new Intl.DateTimeFormat(locale, {month: 'long', day: 'numeric', year: 'numeric'});

interface Props {
    blockData: BlockData;
    contentMetadata: ItemMetadata;
    contentUserAttributes: ItemUserAttributes;
    userPermissions?: APIPermissions;
    // Does the XBlock request to be rendered with full width?
    expandBlock: boolean;
    onExpandBlock: (expand: boolean) => void;
    markAsIncomplete?(): void;
    pathwayMetadata?: ItemMetadata;
    pathwayChildren?: ChildItem[];
    isUserLoggedIn?: boolean;
    loggedInUsername?: string;
    screenReaderHeading: React.ReactNode;
    next?: RelatedItemMetadata;
    classroomName?: string;
    onCloneAnnotatedVideo?(): void;
}

interface State {
    shareModalVisible: boolean;
    createTranslationModalVisible: boolean;
    isMediumView: boolean;
    redirectTo?: LocationDescriptor;
    cloneAnnotatedVideoModelVisible: boolean;
}

/**
 * The ItemPageBody displays the actual content of the ItemPage,
 * such as metadata, SideBar, and
 * the Block component containing the unit itself.
 */
export class ItemPageBody extends React.PureComponent<Props, State> {

    private mediaQueryMedium = UI_IS_MD;

    constructor(props: Props) {
        super(props);
        this.state = {
            shareModalVisible: false,
            createTranslationModalVisible: false,
            isMediumView: this.mediaQueryMedium.matches,
            cloneAnnotatedVideoModelVisible: false,
        };
    }

    public componentDidMount() {
        this.mediaQueryMedium.addListener(this.setIsMediumView);
    }

    public componentWillUnmount() {
        this.mediaQueryMedium.removeListener(this.setIsMediumView);
    }

    @bind private setIsMediumView() {
        this.setState({isMediumView: this.mediaQueryMedium.matches});
    }

    @bind private onChangeCloneModalState(visible: boolean) {
        this.setState({cloneAnnotatedVideoModelVisible: visible});
    }

    public renderPathwayPreviewBox() {
        const pathwayChildren = this.props.pathwayChildren;
        if (pathwayChildren === undefined || this.props.pathwayMetadata === undefined) {
            return null;
        }

        const childIndex = getPathwayChildIndex(pathwayChildren, this.props.contentMetadata.id, this.props.contentMetadata.relId);

        return <PreviewNextBox
            next={this.props.next}
            pathwayMetadata={this.props.pathwayMetadata}
            pathwayItems={pathwayChildren.map((child) => child.item.metadata)}
            currentItemIndex={childIndex}
        />;
    }

    private isVideo(itemId: string) {
        return this.props.contentMetadata.type === ItemMetadataTypeEnum.Video;
    }

    @bind private onClickTag(tag: string) {
        const target = `${ROUTES.Library.HOME}?t=Tag%3A${tag}`;
        this.setState({redirectTo: target});
    }

    @bind private getContentDates() {
        const datesData = [];
        const { publishedAt, uploadedDate, isPublic } = this.props.contentMetadata;
        const showPublishedDate = isPublic && publishedAt;
        if ( showPublishedDate) {
            // Case 1 -> Public asset with published date
            datesData.push({
                'title': messages.contentPublishedOnDate,
                'value': this.props.contentMetadata.publishedAt
            });
            if (this.props.contentMetadata.updatedAt) {
                datesData.push({
                    'title': messages.contentUpdatedAtDate,
                    'value': this.props.contentMetadata.updatedAt
                });
            }
        } else {
            // Case 2 -> Public asset with no published date or a Private asset
            if (uploadedDate)
            {
                datesData.push({
                    'title': messages.contentUploadedOnDate,
                    'value': uploadedDate
                });
            }

            if (this.props.contentMetadata.updatedAt) {
                datesData.push({
                    'title': messages.contentUpdatedAtDate,
                    'value': this.props.contentMetadata.updatedAt
                });
            } else if (uploadedDate) {
                datesData.push({
                    'title': messages.contentUpdatedAtDate,
                    'value': uploadedDate
                });
            }
        }
        return datesData;
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }
        const item = {
            description: '',
            languageInfo: {
                language: 'en',
                subtitles: [],
            },
            stats: {
                clones: 0,
                favorites: 0,
                remixes: 0,
                views: 0,
            },
            subjectTags: {
                children: [],
                main: 'Unknown',
            },
            ...this.props.contentMetadata,
        };

        const pathway = this.props.pathwayMetadata;
        const pathwayChild = getPathwayChild(this.props.pathwayChildren!, this.props.contentMetadata.id, this.props.contentMetadata.relId);

        const canCreateTranslation = !this.props.pathwayMetadata;
        const pageBodyClassName = this.props.expandBlock ? 'page-body col-12' : 'page-body col-12 col-lg-9';
        let toBeUpdated = this.props.contentMetadata.toBeUpdated;
        if (toBeUpdated) {
            /// This date is stored in UTC on the database
            /// but in the frontend it's showed in the local time of the user.
            /// Only the date is shown, so it can show different days for different users.
            /// For that reason I convert this date to UTC-0 to show the exact day
            /// that is in the database to all users.
            toBeUpdated = convertTimeZone(toBeUpdated, 'Africa/Abidjan');
        }

        const contentDates = this.getContentDates();

        return (
            <div className='item-page-wrapper row'>
                {this.state.shareModalVisible &&
                    <ShareModal
                        getItemUrl={() => detailUrlForEntity(this.props.contentMetadata, pathway?.id)}
                        itemTitle={this.props.contentMetadata.title}
                        itemDescription={this.props.contentMetadata.description}
                        itemImageUrl={this.props.contentMetadata.imageUrl}
                        onClose={() => { this.setState({shareModalVisible: false}); }}
                    />
                }
                {this.state.createTranslationModalVisible &&
                    <CreateTranslationModal
                        metadata={this.props.contentMetadata}
                        onClose={() => { this.setState({createTranslationModalVisible: false}); }}
                        onConfirm={() => { this.setState({createTranslationModalVisible: false}); }}
                    />
                }
                {this.state.cloneAnnotatedVideoModelVisible && this.props.onCloneAnnotatedVideo &&
                    <ModalConfirmation
                        title={messages.copyAnnotatedVideoTitle}
                        confirmButtonText={messages.copyAnnotatedVideoConfirm}
                        cancelButtonText={messages.copyAnnotatedVideoCancel}
                        onCanceled={() => { this.onChangeCloneModalState(false); }}
                        onConfirmed={this.props.onCloneAnnotatedVideo}
                        iconName='copy-icon'
                    >
                        <WrappedMessage
                            message={messages.copyAnnotatedVideoModalContent}
                        />
                    </ModalConfirmation>
                }
                <div className={pageBodyClassName} dir='auto'>
                    <div className='row'>
                        <h1 className='sr-only'>{this.props.screenReaderHeading}</h1>

                        { !this.props.expandBlock && (
                            <React.Fragment>

                        <div className='about col-12 col-md-8 col-lg-12'>
                            <div className='subheading-and-actions' dir='auto'>
                                <h2>
                                    <WrappedMessage message={getItemTypeMeta(this.props.contentMetadata.type).aboutTitle}/>
                                </h2>
                                {this.props.contentMetadata.isPublic === false
                                ? <Badge iconName='lock' text={messages.privateIconText}/>
                                : null}
                                <div className='actions'>
                                    <ItemActions
                                        itemMetadata={this.props.contentMetadata}
                                        userAttributes={this.props.contentUserAttributes}
                                        userPermissions={this.props.userPermissions}
                                        loggedInUsername={this.props.loggedInUsername}
                                        isUserLoggedIn={this.props.isUserLoggedIn}
                                        onShare={() => {
                                            this.setState({shareModalVisible: true});
                                            analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
                                                url: window.location.toString(),
                                                actionText:'share',
                                                itemType: this.props.contentMetadata.type
                                            });
                                        }}
                                        markAsIncomplete={this.props.markAsIncomplete}
                                        forceDisplayMarkAsIncomplete={false}
                                        onCreateTranslation={
                                            canCreateTranslation
                                            ? () => this.setState({createTranslationModalVisible: true})
                                            : undefined
                                        }
                                    />
                                </div>
                            </div>
                            <div className='description' dir='auto'>
                                <ExpandableText text={this.props.contentMetadata.description} lines={2} html={true} />
                            </div>
                            { this.props.contentMetadata.uploadedDate &&
                            <p className='content-dates'>
                                {contentDates.map((obj, index) => (
                                    <React.Fragment key={index}>
                                        <small>
                                            <WrappedMessage
                                            message={obj.title}
                                            values={{ date: dateFormatter.format(obj.value) }}
                                            />
                                        </small>
                                        {index < contentDates.length - 1 && <span>&nbsp;|&nbsp;</span>}
                                    </React.Fragment>
                                ))}
                            </p>
                            }
                            { toBeUpdated &&
                                <AlertBox
                                    type='warning'
                                    bodyMessage={messages.updateWarningBodyMessage}
                                    bodyMessageValues={{
                                        itemType: intl.formatMessage(getItemTypeMeta(this.props.contentMetadata.type).name),
                                        date: intl.formatDate(toBeUpdated, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: '2-digit',
                                        })
                                    }}
                                    link={LxConfig.DeprecatedItemAlertUrl}
                                    linkMessage={messages.updateWarningLinkMessage}
                                />
                            }
                        </div>

                        {item.source ?
                            <ContentSourceInfoBox
                                className='col-12 col-md-4 col-lg-12' itemId={item.id} {...item.source}
                            /> :
                            null
                        }

                        {this.state.isMediumView && <div className='backtobutton-wrapper col-12'>
                            <BackToButton/>
                        </div>}

                        { pathwayChild && pathwayChild.notes &&
                            <div className='item-notes col-12'>
                                <div className='rounded'>
                                    {pathway ? <>
                                        {pathway.source?.sourceOrganizations && pathway.source?.sourceOrganizations[0].organization.largeLogoUrl ?
                                            <div className='avatar rounded-circle'>
                                                <img src={pathway.source.sourceOrganizations[0].organization.largeLogoUrl} alt='' />
                                            </div> : <Icon name={'content-note'} />}
                                        <div>
                                        {pathway.source ? <a className='provider-name' href={pathway.source.sourceOrganizations[0].organization.url}>
                                                {pathway.source.sourceOrganizations[0].organization.name}
                                            </a> : null}
                                            <p><ExpandableText text={pathwayChild.notes} limit={300}/></p>
                                        </div>
                                    </> : null}
                                </div>
                            </div>
                        }

                            </React.Fragment>
                        )}

                        { item.id &&
                        <div className='content col-12'>
                            <Block
                                blockData={this.props.blockData}
                                itemMetadata={this.props.contentMetadata}
                                onExpandBlock={this.props.onExpandBlock}
                                isPathwayChild={this.props.pathwayMetadata !== undefined}
                                pathwayMetadata={this.props.pathwayMetadata}
                                pathwayItems={this.props.pathwayChildren?.map((child) => child.item.metadata)}
                                currentItemIndex={getPathwayChildIndex(this.props.pathwayChildren!, this.props.contentMetadata.id, this.props.contentMetadata.relId)}
                                classroomName={this.props.contentMetadata.sendPiiMetadata ? this.props.classroomName : ''}
                                username={this.props.loggedInUsername}
                            />
                        </div>
                        }

                        {!this.props.expandBlock && (
                            <React.Fragment>

                                <div className='d-lg-none col-12'>
                                    {this.props.isUserLoggedIn && this.props.loggedInUsername &&
                                      <ItemAssign
                                        itemMetadata={item}
                                        loggedInUsername={this.props.loggedInUsername}
                                        toClassroom={this.props.userPermissions?.library.canAssignAssetToClassroom}
                                        toMentorship={this.props.userPermissions?.library.canAssignAssetToMentorship}
                                        toPathway={this.props.userPermissions?.library.canAssignAssetToPathway}
                                        createAnnotatedVideo={this.props.userPermissions?.library.canCreateAnnotatedVideoItem && this.isVideo(this.props.contentMetadata.id)}
                                      />
                                    }
                                </div>

                                {this.state.isMediumView && <div className='metadata col-12'>
                                  <ItemMetadataTable item={item}/>
                                </div>}
                                {(this.state.isMediumView && item.resources) && <div className='metadata col-12'>
                                  <ResourcesListing
                                    resources={item.resources}
                                    assetId={item.id}
                                    assetType={item.type}
                                    assetName={item.title}
                                  />
                                </div>}

                                <div className='col-12'>
                                    <ReferencesBox references={item.references}/>
                                </div>

                                <div className='col-12'>
                                    <FundersList funders={item.funders}/>
                                </div>

                                <div className='col-12'>
                                    <AuthorsList authors={item.authors}/>
                                </div>

                                {this.renderPathwayPreviewBox()}

                                {this.props.contentMetadata.freeFormTags &&
                                  <div className='col-12'>
                                      {this.props.contentMetadata.freeFormTags.map((tag, index) => (
                                          <Tag
                                              key={index}
                                              onClick={() => this.onClickTag(tag)}
                                              hoverType='color'
                                              disableIcon={true}
                                          >
                                              {tag}
                                          </Tag>
                                      ))}
                                  </div>
                                }

                                <ReportButton
                                    itemId={this.props.contentMetadata.id}
                                    itemTitle={this.props.contentMetadata.title}
                                    itemType={ModerationRequestContentTypeEnum.LibraryItemmetadata}
                                    label={messages.actionReport}
                                    asKebab={false}
                                    onClose={() => null}
                                    onOpen={() => null}
                                />

                            </React.Fragment>
                        )}
                    </div>
                </div>

                { (!this.state.isMediumView && !this.props.expandBlock) &&
                <div className='page-sidebar d-none d-lg-block col-lg-3'>
                    <div className='row page-sidebar-row'>
                        {item.source ?
                            <ContentSourceInfoBox
                                className='col-12 col-md-4 col-lg-12' itemId={item.id} {...item.source} /> :
                            null
                        }

                        {this.props.isUserLoggedIn && this.props.loggedInUsername &&
                            <div className='col-12'>
                                <ItemAssign
                                    itemMetadata={item}
                                    loggedInUsername={this.props.loggedInUsername}
                                    toClassroom={this.props.userPermissions?.library.canAssignAssetToClassroom}
                                    toMentorship={this.props.userPermissions?.library.canAssignAssetToMentorship}
                                    toPathway={this.props.userPermissions?.library.canAssignAssetToPathway}
                                    createAnnotatedVideo={this.props.userPermissions?.library.canCreateAnnotatedVideoItem && this.isVideo(this.props.contentMetadata.id)}
                                />
                            </div>
                        }

                        {this.props.userPermissions?.library.canCreateAnnotatedVideoItem &&
                            this.props.contentMetadata.type === ItemType.AnnotatedVideo &&
                            <div className='col-12'>
                                <Button
                                    className='annotated-video-clone-button'
                                    icon='clone'
                                    label={messages.copyAnnotatedVideoButtonLabel}
                                    onClick={() => { this.onChangeCloneModalState(true); }}
                                />
                            </div>
                        }

                        {this.props.userPermissions?.library.canMakeItemPublic && <div className='col-12'>
                               <Button
                                className='item-referennces-button'
                                icon='arrow-right'
                                label={messages.showItemReferencesButtonLabel}
                                onClick={() => {history.push(`${ROUTES.Library.HOME}?t=References:${this.props.contentMetadata.id}`);}}
                            />
                        </div>}

                        <div className='sticky'>
                            {this.renderPathwayPreviewBox()}

                            <div className='backtobutton-wrapper col-12'>
                                <BackToButton/>
                            </div>
                        </div>
                        {item.resources  &&
                          <ResourcesListing
                            resources={item.resources}
                            assetId={item.id}
                            assetType={item.type}
                            assetName={item.title}
                            isSideBarView
                          />
                        }
                        <div className='metadata col-12'>
                            <ItemMetadataTable item={item}/>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
