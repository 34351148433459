import bind from 'bind-decorator';
import { ClassroomsApi } from 'global/api';
import { isKeyboardEnterEvent } from 'global/utils';
import {ClassroomDetail, ClassroomReport, ItemProgress, LearnerReport} from 'labxchange-client';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import ReactTable, { Column } from 'react-table';
import { UI_IS_XS } from 'ui/breakpoints';
import { Button, DownloadButton, JumpTo, Pagination, Spinner } from 'ui/components';
import { AssignedItemCompletion, AssignedItemProgress } from 'ui/components/AssignedItemsReport';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { ProgressMetric } from './ProgressMetric';
import {
    renderRelativeDate,
    renderItemPostedDateShort,
    renderItemTitle
} from './utils';
import { intl } from 'i18n';

interface ClassroomProgressProps {
    classroom: ClassroomDetail;
}

interface ClassroomProgressState {
    classroomReport?: ClassroomReport;
    loading: boolean;
    isMobileView: boolean;
}

export class ClassroomProgressEducator extends React.PureComponent<ClassroomProgressProps, ClassroomProgressState> {
    private mediaQuery = UI_IS_XS;  // This matches Bootstrap's definition of x-small (xs) breakpoint.

    constructor(props: ClassroomProgressProps) {
        super(props);
        this.state = {
            loading: true,
            isMobileView: false,
        };
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
        await this.fetchClassroomReport(true);
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    public async fetchClassroomReport(cached: boolean) {
        const parameters = {
            id: this.props.classroom.id,
            cached: cached ? cached : undefined,
        };
        try {
            const classroomReport = await ClassroomsApi.classProgress(parameters);
            this.setState({ classroomReport, loading: false });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorClassroomReport}/>, {
                exception: err,
            });
        }
    }

    public render() {
        if (this.state.loading || this.state.classroomReport === undefined) {
            return <Spinner />;
        }

        const learnerReports = this.state.classroomReport.learnerReports;
        const enableDownloadButton = learnerReports.length > 0 && learnerReports[0].membership;

        return (
            <div className='classroom-progress-educator'>
                <div className='summary'>
                    <ProgressMetric
                        label={messages.classScoreTitle}
                        value={this.state.classroomReport?.totalScore}
                        helpText={messages.educatorClassScoreDescription}
                        className='class-score'
                    />
                    <ProgressMetric
                        label={messages.classProgressTitle}
                        value={this.state.classroomReport?.totalCompletion}
                        valueText={messages.classProgressValue}
                        helpText={messages.educatorClassProgressDescription}
                        className='class-progress'
                    />
                </div>
                <div className='class-actions'>
                    <DownloadButton
                        icon='desktop-download'
                        label={messages.educatorClassProgressDownloadButton}
                        contentType='text/csv'
                        getFileName={() => `export-${this.props.classroom.id}.csv`}
                        getValue={this.getReport}
                        disabled={!enableDownloadButton}
                    />
                    <Button
                        btnStyle='primary'
                        icon='sync'
                        label={messages.educatorClassProgressUpdateButton}
                        secondaryLabel={messages.educatorClassProgressUpdateDateLabel}
                        secondaryLabelValues={{
                            updatedDate: this.state.classroomReport?.updatedDate ? renderRelativeDate(
                                this.state.classroomReport.updatedDate
                            ) : null
                        }}
                        disabled={this.state.loading}
                        onClick={() => this.fetchClassroomReport(false)}
                    />
                </div>
                <ClassroomProgressTable
                    learnerReports={this.state.classroomReport.learnerReports}
                    isMobileView={this.state.isMobileView}
                />
            </div>
        );
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQuery.matches});
    }

    @bind private async getReport() {
        try {
            const classProgress = await ClassroomsApi.classProgress(
                { id: this.props.classroom.id, csvExport: true},
            );
            return classProgress.csvExport;
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorClassroomReport}/>, {
                exception: err,
            });
        }
        return;
    }
}

interface PaginationProps {
    page: number;
    pages: number;
    onPageChange: (page: number) => void;
}

class ClassroomProgressTablePagination extends React.PureComponent<PaginationProps> {

    // ReactTable uses page = 0, 1, ..., but LX Pagination components use currentPage = 1, 2, ...

    public render() {
        const currentPage = this.props.page + 1;
        return (
            <div className='pagination d-flex justify-content-between mb-3'>
                <JumpTo
                    pageCount={this.props.pages}
                    currentPage={currentPage}
                    onPageSelect={this.onPageSelect}
                />
                <Pagination
                    pageCount={this.props.pages}
                    currentPage={currentPage}
                    onPageSelect={this.onPageSelect}
                />
            </div>
        );
    }

    @bind private onPageSelect(currentPage: number) {
        const page = currentPage - 1;
        this.props.onPageChange(page);
    }
}

interface ClassroomProgressTableProps {
    learnerReports: LearnerReport[];
    isMobileView: boolean;
    pageSize: number;
}

class ClassroomProgressTable extends React.PureComponent<ClassroomProgressTableProps> {

    private readonly tableDivRef: React.RefObject<HTMLDivElement>;

    public static defaultProps = {
        pageSize: 500,
    };

    constructor(props: ClassroomProgressTableProps) {
        super(props);
        this.tableDivRef = React.createRef();
    }

    componentDidMount() {
        if (!this.props.isMobileView) {
            if (this.tableDivRef && this.tableDivRef.current && this.tableDivRef.current.scrollTo) {
                // Scroll to right end so that the overall score and progress elements are visible.
                this.tableDivRef.current.scrollTo({left: this.tableDivRef.current.offsetWidth});
            }
        }
    }

    public render() {
        const headers = this.getHeaders();
        const rows = this.props.learnerReports;
        const minRows = Math.min(this.props.pageSize, rows.length);
        const showPagination = (rows.length > this.props.pageSize);
        return <ReactTable
                columns={headers} data={rows} minRows={minRows}
                pageSize={this.props.pageSize} showPagination={showPagination}
                PaginationComponent={ClassroomProgressTablePagination}
                TableComponent={({ children, className, ...rest }: { children: React.ReactNode; className: string; }) => (

                    <div ref={this.tableDivRef} className='rt-table' role='grid'
                      {...rest}
                    >
                      {children}
                    </div>
                )}
                multiSort={false}
                defaultSorted={[{
                  id: 'membershipName',
                  desc: false
                }]}
        />;
    }

    private getHeaders(): Column[] {
        const firstReport = this.props.learnerReports[0];
        const nameHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage message={messages.nameHeader}/>,
                messages.nameSortTitle,
            ),
            Cell: this.renderName,
            accessor: (report: LearnerReport) => report.membership?.user.fullName,
            id: 'membershipName',
            className: 'sticky sortable',
            headerClassName: 'sticky sortable',
            width: (this.props.isMobileView ? undefined : 216),
            sortable: true,
            resizable: true,
        };
        const progressHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage message={messages.progressHeader}/>,
                messages.progressSortTitle,
            ),
            Cell: this.renderLearnerClassProgress,
            accessor: (report: LearnerReport) => report.totalCompletion,
            id: 'progress',
            minWidth: 200,
            sortable: true,
            resizable: false,
            className: 'sortable classroom-progress-progress-column',
            headerClassName: 'sortable',
        };
        const scoreHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage message={messages.scoreHeader}/>,
                messages.scoreSortTitle,
            ),
            Cell: this.renderLearnerClassScore,
            accessor: (report: LearnerReport) => report.totalScore,
            id: 'score',
            minWidth: 180,
            sortable: true,
            resizable: false,
            className: 'sortable classroom-progress-score-column',
            headerClassName: 'sortable',
        };
        let assignmentHeaders: Column[] = [];
        if (!this.props.isMobileView) {
            assignmentHeaders = firstReport.items.map((assignment, index) => {
                return {
                    Header: this.renderItemHeader(assignment),
                    Cell: this.renderItemProgress(index),
                    minWidth: 240,
                    id: `assignment${index}`,
                    headerClassName: 'sortable',
                    sortable: false,
                    resizable: true,
                };
            });
        }
        return [nameHeader].concat(assignmentHeaders, [progressHeader, scoreHeader]);
    }

    private sortableHeader(label: React.ReactNode,
                           sortTitle: MessageDescriptor,
                           sortTitleValues?: Record<string, string>,
        ): React.ReactNode {
        const iconClasses = `sort-icons ${this.props.isMobileView ? 'is-mobile' : ''}`;
        return (
            <div className='sortable-column' title={intl.formatMessage(sortTitle, sortTitleValues)}
                role='button' tabIndex={0} onKeyDown={this.sortColumn}>
                <div className='sort-label'>{label}</div>
                <div className={iconClasses}></div>
            </div>
        );
    }

    private renderItemHeader(itemProgress: ItemProgress): React.ReactNode {
        return (
            <div>
                <div className='assignment-date'><small>
                    {renderItemPostedDateShort(itemProgress.assignedDate)}</small>
                </div>
                <div className='assignment-title'>{renderItemTitle(itemProgress)}</div>
            </div>
        );
    }

    private renderName({value}: {value: string|undefined}): React.ReactNode {
        return value ? <div>{value}</div> : <WrappedMessage message={messages.emptyClassroomReportLabel} />;
    }

    private renderLearnerClassProgress({value}: {value: number|undefined}): React.ReactNode {
        if (value !== undefined) {
            return <AssignedItemCompletion
                completion={value}
            />;
        }
        return <WrappedMessage message={messages.emptyClassroomReportLabel} />;
    }

    private renderLearnerClassScore({value}: {value: number|undefined}): React.ReactNode {
        if (value !== undefined) {
            const score = Math.round(value);
            return (
                <WrappedMessage
                    message={messages.scorePercentage}
                    values={{score}}
                />
            );
        }
        return <WrappedMessage message={messages.emptyClassroomReportLabel} />;
    }

    private renderItemProgress(index: number): ({original}: {original: LearnerReport}) => React.ReactNode {
        return ({original}: {original: LearnerReport}): React.ReactNode => {
            if (original.membership) {
                const itemProgress: ItemProgress = original.items[index];
                return <AssignedItemProgress itemProgress={itemProgress} />;
            }
            return <WrappedMessage message={messages.emptyClassroomReportLabel} />;
        };
    }

    @bind private sortColumn(event: React.KeyboardEvent<HTMLDivElement>) {
        // Adds keyboard control of sortable columns by passing keyboard events on labels
        // through to the parent elements for action by ReactTable.
        if (isKeyboardEnterEvent(event) && event.currentTarget.parentElement) {
            event.currentTarget.parentElement.click();
        }
    }

}
