import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { MessageDescriptor } from 'react-intl';

import { getUserFullName, getUsername, getUserPermissions } from 'auth/selectors';
import { Icon, IconSymbol } from 'elements';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { APIPermissions } from 'labxchange-client';
import uiMessages from 'ui/components/displayMessages';
import { UI_IS_SM } from 'ui/breakpoints';
import { WrappedMessage } from 'utils';
import {
    getItemTypeMeta,
    getItemTypeMetaSubTypes,
    ItemSubTypeMeta,
    ItemType,
    itemTypesUserCanCreate,
} from '../../models';
import { VideoImportModal } from './VideoImportModal';

const itemTypesWithPreEditorStep: ItemType[] = [
    ItemType.AnnotatedVideo,
    ItemType.LxVideo,
    ItemType.Video,
];

export enum CreateItemStep {
    SelectType,
    SelectSubType,
    PreEditor,
}

interface ReduxStateProps {
    userPermissions?: APIPermissions;
    userUsername?: string;
    userFullName?: string;
}

interface Props {
    onCloseAndRedirect: (location: LocationDescriptor, itemType?: ItemType) => void;
    step: CreateItemStep;
    onStepChange: (step: CreateItemStep) => void;
    excludeTypes?: ItemType[];
    selectedItemType?: ItemType;
    onSelectedItemTypeChange: (itemType: ItemType) => void;
}

interface State {
    isMobileView: boolean;
}

class CreateItemModalContentInternal extends React.PureComponent<Props & ReduxStateProps, State> {
    private mediaQueryMobile = UI_IS_SM;
    private disabledItemForMobile = ['annotated_video'];

    constructor(props: Props) {
        super(props);
        this.state = {
            isMobileView: false
        };
    }

    public componentDidMount() {
        this.mediaQueryMobile.addListener(this.setIsMobileView);
        this.setIsMobileView();
    }

    public componentWillUnmount() {
        this.mediaQueryMobile.removeListener(this.setIsMobileView);
    }

    public render() {
        if (this.props.userPermissions === undefined) {
            // This can happen if the educator dashboard is the first page the user visits and
            // the modal is opened immediately.
            return <WrappedMessage message={uiMessages.uiLoading} />;
        } else if (this.props.step === CreateItemStep.PreEditor) {
            return this.renderPreEditorStep();
        } else if (this.props.step === CreateItemStep.SelectSubType) {
            // Create modal content for subtypes
            const itemMetaSubTypes = getItemTypeMetaSubTypes(this.props.selectedItemType!);
            return (
                <ul className='create-item-modal-list'>
                    {itemMetaSubTypes.map((itemSubType) => {
                        return (
                            <li key={itemSubType.slug}>
                                <CreateItemButton
                                    titleDescriptor={itemSubType.name}
                                    buttonIcon={itemSubType.icon}
                                    buttonImagePath={itemSubType.imageFile}
                                    hasImage={itemSubType.imageFile ? true : false}
                                    onSelect={() => {
                                        this.onCreateItemSelect(itemSubType.type, itemSubType);
                                    }}
                                />
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            // Create modal content for first level types
            // Which types the current user can create are defined by the backend in
            // backend/labxchange/apps/library/permissions.py:LibraryPermissions
            // Edit that file to hide/show certain asset types.
            let itemTypes = itemTypesUserCanCreate(this.props.userPermissions.library);
            if(this.state.isMobileView) {
                itemTypes = itemTypes.filter(item => !this.disabledItemForMobile.includes(item));
            }
            if (this.props.excludeTypes !== undefined) {
                itemTypes = itemTypes.filter((x) => !this.props.excludeTypes!.includes(x));
            }
            return (
                <ul className='create-item-modal-list'>
                    {itemTypes.map((itemType) => {
                        const meta = getItemTypeMeta(itemType);
                        return (
                            <li key={itemType} data-testid={`create-item-${itemType}`}>
                                <CreateItemButton
                                    titleDescriptor={meta.name}
                                    buttonIcon={meta.icon}
                                    buttonImagePath={meta.imageFile}
                                    hasImage={meta.imageFile == null ? false : true}
                                    onSelect={() => { this.onCreateItemSelect(itemType); }}
                                />
                            </li>
                        );
                    })}
                </ul>
            );
        }
    }

    private renderPreEditorStep() {
        const itemType = this.props.selectedItemType;
        switch (itemType) {
            case ItemType.AnnotatedVideo:
                return <VideoImportModal
                    itemType={ItemType.AnnotatedVideo}
                    author={
                        this.props.userUsername && this.props.userFullName
                        ? {username: this.props.userUsername, fullName: this.props.userFullName}
                        : undefined
                    }
                    onCloseAndRedirect={this.props.onCloseAndRedirect} />;
            case ItemType.Video:
                return <VideoImportModal
                    itemType={ItemType.Video}
                    onCloseAndRedirect={this.props.onCloseAndRedirect} />;
            case ItemType.LxVideo:
                return <VideoImportModal
                    itemType={ItemType.LxVideo}
                    onCloseAndRedirect={this.props.onCloseAndRedirect} />;
            default:
                throw new Error(`Pre-editor step for item type ${itemType} is not defined`);
        }
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQueryMobile.matches});
    }

    @bind private async onCreateItemSelect(itemType: ItemType, itemSubTypeMeta?: ItemSubTypeMeta) {
        if (this.props.step === CreateItemStep.SelectType) {
            const template = getItemTypeMetaSubTypes(itemType);
            if (template.length > 0) {
                this.props.onStepChange(CreateItemStep.SelectSubType);
                this.props.onSelectedItemTypeChange(itemType);
                return;
            }
            if (itemTypesWithPreEditorStep.includes(itemType)) {
                this.props.onStepChange(CreateItemStep.PreEditor);
                this.props.onSelectedItemTypeChange(itemType);
                return;
            }
        }
        if (itemType === ItemType.Pathway) {
            this.props.onCloseAndRedirect(ROUTES.Library.PATHWAY_NEW, itemType);
            return;
        } else {
            this.props.onCloseAndRedirect(ROUTES.Library.ITEM_NEW_SLUG(
                itemType, itemSubTypeMeta !== undefined ? itemSubTypeMeta.slug : ''),
                itemType,
            );
        }
    }

}

export const CreateItemModalContent = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
        userUsername: getUsername(state),
        userFullName: getUserFullName(state),
    }),
)(CreateItemModalContentInternal);

interface CreateItemButtonProps {
    titleDescriptor: MessageDescriptor;
    buttonIcon: IconSymbol;
    buttonImagePath?: string | null;
    hasImage: boolean;
    onSelect?: () => void;
}

const CreateItemButton: React.FunctionComponent<CreateItemButtonProps> = (props) => {
    return (
        <div className='create-item-button'>
            <button onClick={props.onSelect}>
                {props.hasImage
                ? <img src={`/assets/images/tiles/${props.buttonImagePath}`}
                    alt=''
                    height='123px'
                    width='123px'
                    style={{margin: 'auto'}}/>
                : <Icon name={props.buttonIcon} zoom='123px' />
                }
                <p><WrappedMessage message={props.titleDescriptor} values={{count: 0}} /></p>
            </button>
        </div>
    );
};
