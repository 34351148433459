import {getDefaultState} from '../../models';
import {MULTI_EDITOR_CONTENT_CHANGE} from '../actions/action-types';

const MultiSelectEditorReducer = function(state = getDefaultState().multiSelectEditor, action: any) {
    switch(action.type) {
        case MULTI_EDITOR_CONTENT_CHANGE:
            return Object.assign({}, state, {content: action.content});
        default:
            return state;
    }
};

export default MultiSelectEditorReducer;
