import React from 'react';
import CareerOutlookFact from './CareerOutlookFact';
import { Icon, IconSymbol } from 'elements';
import { CareerOutlook } from 'labxchange-client';

import messages from '../../../../career-explorer/displayMessages';
import { WrappedMessage } from 'utils';
import { MessageDescriptor } from 'react-intl';
import classNames from 'classnames';
import { getCardIcon, getCardIconColor } from 'career-explorer/utils';

interface CareerOutlookCardProps {
  title: MessageDescriptor;
  subTitle: number | string;
  iconName: IconSymbol;
  iconColor: string;
  iconClass?: string;
}

export const CareerOutlookCard: React.FC<CareerOutlookCardProps> = ({
  title, subTitle, iconName, iconColor, iconClass
}) => {
  return (
    <div className='outlook-card'>
      <div className={classNames('outlook-card-icon', iconClass)}>
        <Icon name={iconName} fill={iconColor} zoom='2em'/>
      </div>
      <div className='outlook-card-content'>
        <div className='outlook-card-title'>
          <WrappedMessage message={title} />
        </div>
        <div className='outlook-card-subtitle'>{subTitle}</div>
      </div>
    </div>
  );
};


interface CareerOutlookProps {
  outlook: CareerOutlook;
  careerTitle: string;
}

const CareerOutlookSection: React.FC<CareerOutlookProps> = ({ outlook, careerTitle }) => {
  return (
    <div className='career-outlook-container'>
      <div className='title-container'>
        <h2 className='title'>
          <WrappedMessage message={messages.outlookSectionHeading} />
        </h2>
      </div>
      <div className='cards-container'>
        {outlook.demand &&
          <CareerOutlookCard
            title={messages.outlookDemandTitle}
            subTitle={outlook.demand?.cardLabel}
            iconName={getCardIcon(outlook.demand.cardType, outlook.demand.demandTrajectory)}
            iconColor={getCardIconColor(outlook.demand.demandTrajectory)}
            iconClass={outlook.demand.demandTrajectory}
          />
        }
        {outlook.jobOpenings !== undefined && outlook.jobOpenings > 0 &&
          <CareerOutlookCard
            title={messages.outlookJobOpeningsTitle}
            subTitle={outlook.jobOpenings}
            iconName='jobs'
            iconColor={getCardIconColor()}
          />
        }
      </div>
      <div className='quote'>
        <span><WrappedMessage message={messages.outlookQouteTextPart1} /></span>&nbsp;
        <span className='highlight'>
          <WrappedMessage message={messages.outlookQouteTextPart2} values={{ careerTitle }} />
        </span>
      </div>
      {outlook.facts &&
        <div className='fact-cards'>
        {outlook.facts.map((fact, index) => (
            <CareerOutlookFact key={index} cardNumber={index + 1} fact={fact} />
        ))}
      </div>
      }
    </div>
  );
};

export default CareerOutlookSection;
