import React, { useState } from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import { intl } from 'i18n';
import CareerRadioButton from 'career-explorer/components/CareerRadioButton/CareerRadioButton';
import messages from './displayMessages';


export const EducationQuestions = [
    'I won’t finish high school',
    'I want to start working immediately',
    'I want to go to vocational school',
    'I want to get an associate degree',
    'I want to get a bachelor’s degree',
    'I want to get a graduate degree',
    'I want to get a professional degree',
    'I don’t know yet',
];

export interface EducationStepProps {
    currentStep: number;
    onButtonClick: (nextStep: number) => void;
}

export const EducationStep: React.FC<EducationStepProps> = ({currentStep, onButtonClick}) => {
    const [selectedId, setSelectedId] = useState(0);

    const onRadioSelect = (newValue: number) => {
        setSelectedId(newValue);
    };

    return (
        <OnboardingPageLayout
            title={intl.formatMessage(messages.careerEducationOnboardingStepTitle)}
            description={intl.formatMessage(messages.careerEducationOnboardingStepDescription)}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedId !== 0}
        >
            {EducationQuestions.map((question, i) =>
                <CareerRadioButton key={i} id={i+1} selected={selectedId === (i + 1)} onClick={onRadioSelect}>
                    {question}
                </CareerRadioButton>
            )}
        </OnboardingPageLayout>
    );
};

export default EducationStep;
