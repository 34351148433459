import { getLoggedInStatus,} from 'auth/selectors';
import bind from 'bind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { intl } from 'i18n';
import { PeopleSearch } from 'library/components/PeopleSearch';

import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import uiMessages from 'ui/components/displayMessages';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { WrappedMessage, showSearchBarInHero } from 'utils';
import messages from './displayMessages';
import { LibrarySearchHeader } from '../LibrarySearchHeader';
import { NavTabs } from 'search/components/NavTabs';

interface ReduxStateProps {
    isLoggedIn?: boolean;
}

interface PeoplePageProps extends RouteComponentProps<{}> {}

interface PeoplePageState {
    searchParams: string[];
    showFilterModal: boolean;
}

/**
 * The PeoplePage is the main view of profiles registered in LabXchange.
 *
 * It is comprised of a PeopleSearch component which provides all of the
 * searching and filtering functionality for User Profiles.
 */
export class PeoplePageInternal extends React.PureComponent<PeoplePageProps & ReduxStateProps, PeoplePageState> {

    constructor(props: PeoplePageProps) {
        super(props);

        this.state = {
            searchParams: [],
            showFilterModal: false,
        };
    }

    private onToggleFilter = () => {
        this.setState(prevState => ({
            showFilterModal: !prevState.showFilterModal
        }));
    }

    public render() {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.sort(); // stabilize to avoid false positives with changes
        const header = showSearchBarInHero() ? (
            <LibrarySearchHeader
              {...this.props}
              searchTitleUrl={ROUTES.Library.SEARCH_TITLE}
              searchUrl={ROUTES.Library.HOME}
              onToggleFilter={this.onToggleFilter}
              searchParams={searchParams}
            />
        ) :  <NavTabs {...this.props} />;

        /// If is not loggedIn, then show the registration gate page
        if (!this.props.isLoggedIn) {
          return <RegistrationGate
              title={intl.formatMessage(messages.notLoggedPeopleAlertTitle)}
              body={intl.formatMessage(messages.notLoggedPeopleAlertBody)}
              primaryButtonText={uiMessages.uiSignUp}
              secondaryButtonText={uiMessages.uiSignIn}
              image='/assets/images/access.svg'
              headerFeature={header}
              contentInBody={true}
          />;
        }

        return (
            <StandardPageLayout pageTitle={messages.pageTitle} headerFeature={header} searchUrl={ROUTES.People.HOME}
                                searchParams={this.state.searchParams}>
                <div className='people-page'>
                    <h1 className='sr-only'>
                        <WrappedMessage message={messages.pageTitle}/>
                    </h1>
                    <PeopleSearch
                        onSearchParamsChanged={this.onSearchParamsChanged}
                        searchParams={searchParams.toString()}
                        isModalView={false}
                        showFilterModal={this.state.showFilterModal}
                    />
                </div>
            </StandardPageLayout>
        );
    }

    @bind private onSearchParamsChanged(newParams: URLSearchParams) {
        const newUrl = `${ROUTES.People.HOME}?${newParams.toString()}`;
        this.props.history.push(newUrl);
        this.setState({searchParams: newParams.getAll('t')});
    }
}

export const PeoplePage = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(PeoplePageInternal);
