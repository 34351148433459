import { ProblemBlockEditor } from 'assessment-editor';
import { LocationDescriptor } from 'history';
import { ItemsApi } from 'global/api';
import * as React from 'react';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import { blockTypeFromUsageKey } from '../Block/utils';
import { AssignmentBlockEditor } from './AssignmentBlockEditor';
import messages from './displayMessages';
import { AudioBlockEditor } from './AudioBlockEditor';
import {
    CaseStudyBlockEditor,
    TeachingGuideBlockEditor,
} from './CaseStudyBlockEditor';
import { DocumentBlockEditor } from './DocumentBlockEditor';
import { ImageBlockEditor } from './ImageBlockEditor';
import { EditorState, NestedCreateOptions, newXBlockEditorState, XBlockType } from './models';
import { NarrativeBlockEditor } from './NarrativeBlockEditor';
import { editApiResponseToEditorState } from './serializers';
import { TextBlockEditor } from './TextBlockEditor';
import { VideoBlockEditor } from './VideoBlockEditor';
import { AnnotatedVideoBlockEditor } from './AnnotatedVideoBlockEditor';
import { Author, ItemMetadata } from 'labxchange-client';
import { MessageDescriptor } from 'react-intl';
import DocLinkEditor from './DocLinkEditor';

export type { EditorState };

interface Props {
    xblockId?: string; // Will be undefined when editing a new component.
    /**
     * The type of XBlock being edited (e.g. "video", "unit", "html")
     */
    xblockType: XBlockType;
    /**
     * The state of the editor. This should be considered private/internal,
     * but the parent component should update this whenever the OnEditorStateChanged
     * event is called with a new state.
     * Leave this as undefined when first initializing this component.
     */
    editorState?: EditorState;
    /** An event emitted whenever the user has interacted with this XBlock Editor */
    onEditorStateChanged: (newState: EditorState) => void;
    // No children
    children?: never;
    showErrors: boolean;
    showWarnings: boolean;
    onNestedCreate?: (location: LocationDescriptor, options: NestedCreateOptions) => void;
    // Callback to save item
    saveItem(callback?: (isSaving: boolean) => void, onRequiredFields?: (items: MessageDescriptor[]) => void): void;
    onForceSave(): void;
    onUpdateAuthors?(authors: Author[]): void;
    loggedInUsername?: string;
    itemMetadata?: ItemMetadata;
}

/**
 * Base class for a component that edits the actual content of an XBlock.
 * Does not interact with any APIs - just gets state passed in and emits
 * change events to its parent.
 */
class BlockEditor extends React.PureComponent<Props> {
    public render() {
        // Show the editor:
        const xblockType = this.props.xblockType;
        const sharedProps = {
            xblockId: this.props.xblockId,
            editorState: this.props.editorState as any,
            onEditorStateChanged: this.props.onEditorStateChanged,
            showErrors: this.props.showErrors,
            showWarnings: this.props.showWarnings,
            onNestedCreate: this.props.onNestedCreate,
            saveItem: this.props.saveItem,
            onForceSave: this.props.onForceSave,
            loggedInUsername: this.props.loggedInUsername,
            itemMetadata: this.props.itemMetadata,
            onUpdateAuthors: this.props.onUpdateAuthors,
        };

        if (sharedProps.editorState && sharedProps.editorState.blockType !== XBlockType.Other) {
            if (sharedProps.editorState.blockType !== xblockType) {
                return <p>Error: block type mismatch.</p>; // Shouldn't ever happen; just a safeguard.
            }
        }
        switch (this.props.editorState ? this.props.editorState.blockType : xblockType) {
            case XBlockType.Assignment:
                return <AssignmentBlockEditor {...sharedProps} />;
            case XBlockType.Document:
                return <DocumentBlockEditor {...sharedProps} />;
            case XBlockType.Image:
                return <ImageBlockEditor {...sharedProps} />;
            case XBlockType.Html:
                return <TextBlockEditor {...sharedProps} />;
            case XBlockType.Narrative:
                return <NarrativeBlockEditor {...sharedProps} />;
            case XBlockType.Problem:
                return <ProblemBlockEditor {...sharedProps} />;
            case XBlockType.Video:
                return <VideoBlockEditor {...sharedProps} />;
            case XBlockType.LXVideo:
                return <VideoBlockEditor {...sharedProps} />;
            case XBlockType.AnnotatedVideo:
                return <AnnotatedVideoBlockEditor {...sharedProps} />;
            case XBlockType.Link:
                return <DocLinkEditor {...sharedProps} />;
            case XBlockType.Audio:
                return <AudioBlockEditor {...sharedProps} />;
            case XBlockType.CaseStudy:
                return <CaseStudyBlockEditor {...sharedProps} />;
            case XBlockType.TeachingGuide:
                return <TeachingGuideBlockEditor {...sharedProps} />;
            case XBlockType.Question:
                return <ProblemBlockEditor {...sharedProps} />;
            default:
                return <div className='container-one rounded alert'>
                    <p><WrappedMessage message={messages.cannotEditThatType} /></p>
                </div>;
        }
    }
}

interface NewBlockProps {
    /**
     * The type of XBlock being created (e.g. "video", "unit", "html")
     */
    xblockType: XBlockType;
    defaultValues: {};
    loggedInUsername?: string;
}

/**
 * A component for editing the actual content of a new XBlock, such as a video
 * XBlock, that hasn't yet been saved to Blockstore.
 */
export class NewBlockEditor extends React.PureComponent<NewBlockProps&Props> {
    public render() {
        if (this.props.editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return <BlockEditor {...this.props} />;
        }
    }

    public componentDidMount() {
        if (this.props.editorState === undefined) {
            const newEditorState = newXBlockEditorState(this.props.xblockType, this.props.defaultValues);
            this.props.onEditorStateChanged(newEditorState);
        }
    }
}

interface ExistingBlockProps {
    /**
     * Item ID. The "main" XBlock ID for this item.
     */
    itemId: string;
    /**
     * Library Block ID.
     * The ID of the XBlock we're editing, e.g. 'lb:LabXchange:466906f5:lx_image:1'
     * This is usually the same as the item ID, except in the case of an item
     * that is comprised of multiple XBlocks.
     */
    xblockId: string;
    loggedInUsername?: string;
}

/**
 * A component for editing the actual content of an XBlock, such as a video XBlock.
 */
export class ExistingBlockEditor extends React.PureComponent<ExistingBlockProps&Omit<Props, 'xblockType'>> {

    public render() {
        if (this.props.editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return <BlockEditor xblockId={this.props.xblockId} xblockType={this.xblockType()} {...this.props} />;
        }
    }

    public async componentDidMount() {
        try {
            // Load the XBlock edit data from the API, if available:
            const dataRaw = await ItemsApi.editXblock({id: this.props.itemId, blockKey: this.props.xblockId});
            // NOTE, XXX: parsing as json here is needed because of GenericObjectSerializer.
            const data = JSON.parse(dataRaw);
            // Load data from backend, then:
            const newEditorState = editApiResponseToEditorState(this.xblockType(), data);
            this.props.onEditorStateChanged(newEditorState);
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
            this.props.onEditorStateChanged({blockType: XBlockType.Other});
        }
    }

    /**
     * Detect what type of XBlock we're editing, based on its ID.
     */
    xblockType(): XBlockType {
        return blockTypeFromUsageKey(this.props.xblockId);
    }
}
