// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
    UserFavorite,
    UserFavoriteFromJSON,
    UserFavoriteToJSON,
} from '../models';

export interface DeleteRequest {
    item: string;
}

export interface CreateRequest {
    data: UserFavorite;
}

export interface ListRequest {
    page?: number;
}

export interface ReadRequest {
    item: string;
}

/**
 * no description
 */
export class FavoritesApi extends runtime.BaseAPI {

    /**
     * Ensures that the delete endpoint returns valid JSON.
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<UserFavorite>> {
        if (requestParameters.item === null || requestParameters.item === undefined) {
            throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling _delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/favorites/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFavoriteFromJSON(jsonValue));
    }

    /**
     * Ensures that the delete endpoint returns valid JSON.
     */
    async _delete(requestParameters: DeleteRequest): Promise<UserFavorite> {
        const response = await this._deleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for UserFavorites API
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<UserFavorite>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling create.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/favorites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserFavoriteToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFavoriteFromJSON(jsonValue));
    }

    /**
     * Viewset for UserFavorites API
     */
    async create(requestParameters: CreateRequest): Promise<UserFavorite> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

    /**
     * List of favorite items for a user.
     */
    async listRaw(requestParameters: ListRequest): Promise<runtime.ApiResponse<Array<ItemResponse>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/favorites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ItemResponseFromJSON));
    }

    /**
     * List of favorite items for a user.
     */
    async list(requestParameters: ListRequest): Promise<Array<ItemResponse>> {
        const response = await this.listRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve the user+item favorite, if present.
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<UserFavorite>> {
        if (requestParameters.item === null || requestParameters.item === undefined) {
            throw new runtime.RequiredError('item','Required parameter requestParameters.item was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/favorites/{item}`.replace(`{${"item"}}`, encodeURIComponent(String(requestParameters.item))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFavoriteFromJSON(jsonValue));
    }

    /**
     * Retrieve the user+item favorite, if present.
     */
    async read(requestParameters: ReadRequest): Promise<UserFavorite> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

}
