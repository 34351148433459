import { v4 as uuidv4 } from 'uuid';

import { Event } from 'tracking/Event';
import { CreateRequest } from 'labxchange-client/apis/AnalyticsApi';

import { AnalyticsApi } from 'global/api';


/***
 * Create an event with delay if other requests need to be prioritized.
 * @param requestedParams
 */
export function createAnalyticsEventWithDelay(requestedParams: CreateRequest) {
    setTimeout(() => {
        AnalyticsApi.create(requestedParams);
    }, 2500);
}

/***
 * Create an event with delay if other requests need to be prioritized.
 * This is the `v2` version which stores data on the data warehouse.
 * @param requestedParams
 */
 export function createAnalyticsV2EventWithDelay(eventData: Event) {
    setTimeout(() => {
        AnalyticsApi.createEventCreate({
            data: {
                ...eventData,
                sentAt: new Date(),
            },
        });
    }, 2500);
 }

/***
 * Get or create UUID for a given key
 *
 * @params keyName: string
 * @returns string
 */
 export function getOrCreateUUID(keyName: string): string {
    let uuid = localStorage.getItem(keyName);
    if (uuid === null || uuid === '') {
        uuid = uuidv4();
        localStorage.setItem(keyName, uuid);
    }
    return uuid;
}

/***
 * Retrieves a session UUID, creating and
 * storing one if it doesn't exist.
 *
 * @returns string
 */
 export function getOrCreateSessionId(): string {
    return getOrCreateUUID('analytics_session_uuid');
}
