// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ItemTranslation
 */
export interface ItemTranslation {
    /**
     * 
     * @type {string}
     * @memberof ItemTranslation
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof ItemTranslation
     */
    item: string;
    /**
     * 
     * @type {string}
     * @memberof ItemTranslation
     */
    type: ItemTranslationTypeEnum;
}

export function ItemTranslationFromJSON(json: any): ItemTranslation {
    return {
        'language': json['language'],
        'item': json['item'],
        'type': json['type'],
    };
}

export function ItemTranslationToJSON(value?: ItemTranslation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'language': value.language,
        'item': value.item,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum ItemTranslationTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}


