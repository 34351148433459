import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { StandardPageLayout } from 'ui/components';
import {ColorProvider} from '../../ThemeProvider';

export interface CareerStandardLayoutProps {
  mainClassName?: string;
  pageTitle?: MessageDescriptor;
  children?: React.ReactNode;
  headerComponent?: React.ReactNode;
  headerContainerPaddingEnabled?: boolean;

}

export const CareerStandardLayout: React.FC<CareerStandardLayoutProps>  = ({
  mainClassName,
  pageTitle,
  children,
  headerComponent,
  headerContainerPaddingEnabled=false
}) => {
  return (
      <StandardPageLayout
          mainClassName={mainClassName}
          pageTitle={pageTitle}
          headerComponent={headerComponent}
          headerContainerPaddingEnabled={headerContainerPaddingEnabled}
          footerComponent={<></>}
      >
        {children}
      </StandardPageLayout>
  );
};

export default CareerStandardLayout;
