import { AuthProviderEnum, getProviderIcon, providerToString } from 'auth/utils';
import { intl } from 'i18n';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { SSOButton } from 'ui/components';
import messages from '../displayMessages';

interface Props {
    providers: AuthProviderEnum[];
    onClickProvider?: (provider: AuthProviderEnum) => void;
    topMessage?: MessageDescriptor;
    checkValue?: boolean;
    bottomMessage?: MessageDescriptor;
    onCheck?: (value: boolean) => void;
}

export const SSOComponent: React.FunctionComponent<Props> = (props) => {
    return (
        <div className='sso-component'>
            {props.topMessage &&
                <div className='sso-component-top-msg'>
                    {intl.formatMessage(props.topMessage)}
                </div>
            }
            {props.providers.map((provider) =>
                <SSOButton
                    icon={getProviderIcon(provider)}
                    label={messages.lxAuthProviderButtonText}
                    labelValues={{ provider: providerToString(provider) }}
                    onClick={() => { if (props.onClickProvider) props.onClickProvider(provider); }}
                    data-testid={provider}
                />
            )}
        </div>
    );
};
