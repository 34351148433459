import bind from 'bind-decorator';
import { ItemResponse } from 'labxchange-client';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { ItemIcon } from 'library/components';
import { Modal } from 'elements';
import { Button } from 'ui/components';
import { WrappedMessage } from 'utils';

import messages from './displayMessages';
import { locale } from 'i18n';

export interface DuplicateModalItem {
    id: string;
    item: ItemResponse;
    updated?: Date;
}

interface Props {
    items: DuplicateModalItem[];
    description: MessageDescriptor;
    onConfirm: () => void;
    onCancel: () => void;
    onUpdate: (items: DuplicateModalItem[]) => void;
}

export class ConfirmDuplicatesModal extends React.PureComponent<Props, {}> {

    public render() {

        const items = this.props.items.map(duplicateItem => {
            const item = duplicateItem.item.metadata;
            return (
                <div className='item'>
                    <ItemIcon className='item-icon' itemType={item.type} zoom='16' />
                    <div className='text'>
                        <div className='title'>{item.title}</div>
                        {duplicateItem.updated &&
                            <div className='meta'>
                                <WrappedMessage
                                    message={messages.originallyAdded}
                                    values={{date: duplicateItem.updated.toLocaleDateString(locale)}}
                                />
                            </div>
                        }
                    </div>
                    <Button
                        btnStyle='link'
                        size='sm'
                        icon='trashcan'
                        iconOnly={true}
                        label={messages.remove}
                        onClick={() => this.remove(duplicateItem.id)}
                    />
                </div>
            );
        });

        return (
            <Modal
                onRequestClose={this.props.onCancel}
                className='modal-confirm-duplicates'
                content={
                    <>
                        <div className='warning-icon'></div>
                        <div className='title-text'>
                            <WrappedMessage message={messages.title} />
                        </div>

                        <div className='description'>
                            <WrappedMessage message={this.props.description} />
                        </div>

                        <div className='items-box'>
                            {items}
                        </div>
                    </>
                }
                footer={
                    <>
                        <Button
                            btnStyle='outline'
                            label={messages.cancel}
                            onClick={this.props.onCancel} />
                        <Button
                            btnStyle='primary'
                            label={messages.add}
                            onClick={this.props.onConfirm} />
                    </>
                }
            />
        );
    }

    @bind private remove(id: string) {
        const items = this.props.items.filter(x => x.id !== id);
        if (items.length) {
            this.props.onUpdate(items);
        } else {
            // If we removed the last one, the modal is now useless; cancel.
            this.props.onCancel();
        }
    }
}
