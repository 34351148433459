import { defineMessages } from 'react-intl';

const messages = defineMessages({
    classroomModalCreateButton: {
        id:'classroomModalCreateButton',
        defaultMessage: 'Create class',
        description: 'label for create class button in Modal used for creating a classroom from Educator Dashboard',
    },
    classroomModalUpdateButton: {
        id:'classroomModalUpdateButton',
        defaultMessage: 'Update',
        description: 'label for update class button in Modal used for editing a classroom from Classroom Page',
    },
    classroomModalCreateHeader: {
        id:'classroomModalCreateHeader',
        defaultMessage: 'Create class',
        description: 'Text for title header in Modal used for creating a classroom from Educator Dashboard',
    },
    classroomModalUpdateHeader: {
        id:'classroomModalUpdateHeader',
        defaultMessage: 'Class settings',
        description: 'Text for title header in Modal used for updating a classroom from Classroom Page',
    },
    classroomModalUpdateArchiveButton: {
        id:'classroomModalUpdateArchiveButton',
        defaultMessage: 'Archive',
        description: 'label for archive button in Modal used for updating a classroom from Classroom Page',
    },
    classroomModalUpdateUnarchiveButton: {
        id:'classroomModalUpdateUnarchiveButton',
        defaultMessage: 'Unarchive',
        description: 'label for unarchive button in Modal used for updating a classroom from Classroom Page',
    },
    classroomModalDeleteButton: {
        id:'classroomModalDeleteButton',
        defaultMessage: 'Delete',
        description: 'label for delete button in Modal used for deleting a classroom from Classroom Page',
    },
    classroomModalName: {
        id:'classroomModalName',
        defaultMessage: 'Class name',
        description: 'Label for class name textfield in Modal used for creating or updating a classroom',
    },
    classroomModalNamePlaceholder: {
        id:'classroomModalNamePlaceholder',
        defaultMessage: 'Class name',
        description: 'placeholder for class name textfield in Modal used for creating or updating a classroom',
    },
    classroomModalSubject: {
        id:'classroomModalSubject',
        defaultMessage: 'Subject',
        description: 'Label for subject list selection in Modal used for creating or updating a classroom',
    },
    classroomModalMaxStudents: {
        id:'classroomModalMaxStudents',
        defaultMessage: 'Maximum number of learners',
        description: 'Label for max students list selection in Modal used for creating or updating a classroom',
    },
    classroomModalDescription: {
        id:'classroomModalDescription',
        defaultMessage: 'Description',
        description: 'Description textfield in Modal used for creating or updating a classroom',
    },
    classroomModalDescriptionPlaceholder: {
        id:'classroomModalDescriptionPlaceholder',
        defaultMessage: 'Description',
        description: 'placeholder text for textfield in Modal used for creating or updating a classroom',
    },
    classroomModalImageUpload: {
        id:'classroomModalImageUpload',
        defaultMessage: 'Featured image',
        description: 'Image Upload / File picker in Classroom Create / Update Modal',
    },
    classroomModalInvalidName: {
        id:'classroomModalInvalidName',
        defaultMessage: 'Please enter a classroom name.',
        description: 'Label validation error message for name field when creating or updating a classroom',
    },
    classroomModalInvalidSubject: {
        id:'classroomModalInvalidSubject',
        defaultMessage: 'Please enter a subject.',
        description: 'Label validation error message for subject field when creating or updating a classroom',
    },
    classroomModalInvalidMaxStudents: {
        id:'classroomModalInvalidMaxStudents',
        defaultMessage: 'Please select a max number of students.',
        description: 'Label validation error message for max students field when creating or updating a classroom',
    },
    classroomModalInvalidDescription: {
        id:'classroomModalInvalidDescription',
        defaultMessage: 'Please enter a description.',
        description: 'Label validation error message for description field when creating or updating a classroom',
    },
    classroomModalEducatorsTitle: {
        id:'classroomModalEducatorsTitle',
        defaultMessage: 'Co educators',
        description: 'Label for the title of the co-educators field',
    },
    classroomModalCreateError: {
        id:'classroomModalCreateError',
        defaultMessage: 'Error creating a classroom. Please try again later.',
        description: 'Error message when creating a new classroom.',
    },
    classroomModalUpdateError: {
        id:'classroomModalUpdateError',
        defaultMessage: 'Error updating a classroom. Please try again later.',
        description: 'Error message when updating a classroom.',
    },
    classroomModalAddEducatorLabel: {
        id:'classroomModalAddEducatorLabel',
        defaultMessage: 'Add another co educator',
        description: 'Button label for adding a co-educator.',
    },
    classroomModalUploadPreviewLabel: {
        id: 'classroomModalUploadPreviewLabel',
        defaultMessage: 'Upload preview',
        description: 'Button label for upload a preview of the feature image.',
    },
    learnerViewToggleAria: {
        id:'learnerViewToggleAria',
        defaultMessage: 'View this page as a learner',
        description: 'Aria label for the learner view toggle in the educator view of the classroom page.',
    },
});

export default messages;
