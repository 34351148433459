import { bind } from 'bind-decorator';
import { ChildItem } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { Modal } from 'ui/components';
import { WrappedMessage } from 'utils';

export interface PathwayChildUpdateProps {
    notes: string;
}

interface Props {
    child: ChildItem;
    onClose: () => void;
    onUpdate: (props: PathwayChildUpdateProps) => void;
}

interface State {
    notes: string;
}

/** Modal for Pathway asset edition */
export class PathwayChildEditModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            notes: this.props.child.notes || '',
        };
    }

    public render() {

        return (
            <Modal
                onRequestClose={this.props.onClose}
                size='large' showTopBar={true}
                title={
                    <WrappedMessage message={messages.itemEdit} />
                }
                className='pathway-child-edit-modal'
                content={
                    <div className='row'>
                        <div className='col-12'>
                            <div className='item-notes'>
                                <label htmlFor='item-notes-input'>
                                    <WrappedMessage message={messages.pathwayChildNotesLabel}/>
                                </label>
                                <textarea
                                    id='item-notes-input'
                                    value={this.state.notes}
                                    onChange={this.updateNotes}
                                    rows={6}
                                />
                            </div>
                        </div>
                    </div>
                }
                footer={
                    <button className='primary-button' onClick={this.updateChild}>
                        <WrappedMessage message={messages.pathwayChildUpdate}/>
                    </button>
                }
            />
        );
    }

    @bind private updateNotes(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({notes: event.target.value});
    }

    @bind private updateChild() {
        this.props.onUpdate({ notes: this.state.notes });
        this.props.onClose();
    }

}
