import { defineMessages } from 'react-intl';

const messages = defineMessages({
    logoAltText: {
        id:'logoAltText',
        defaultMessage: '{name} logo',
        description: 'Alt text of main logo for the organization owning the current page\'s content. Shown full size in the header.',
    },
    uiNavTabAll: {
        id:'uiNavTabAll',
        defaultMessage: 'All ({count})',
        description: 'Title of the all items tab',
    },
    uiNavTabCluster: {
        id:'uiNavTabCluster',
        defaultMessage: 'Cluster ({count})',
        description: 'Title of the cluster items tab',
    },
    uiNavTabPathway: {
        id:'uiNavTabPathway',
        defaultMessage: 'Pathway ({count})',
        description: 'Title of the pathway items tab',
    },
    uiNavTabContent: {
        id:'uiNavTabOrgContent',
        defaultMessage: 'Content ({count})',
        description: 'Title of the content items tab',
    },
    uiNavTabDashboard: {
        id:'uiNavTabDashboard',
        defaultMessage: 'Private Dashboard',
        description: `Link to the organization's dashboard`,
    },
});

export default messages;
