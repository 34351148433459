import * as React from 'react';
import { intl } from 'i18n';

import { Icon, RichText } from 'elements';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';
import { useLayoutSize } from 'utils';
import messages from '../../displayMessages';

interface TopicPageHeaderProps {
  learningObjectives: string[];
  heading: string;
}

export const LearningObjectives: React.FC<TopicPageHeaderProps> = (props) => {
  const isMobile = ['small'].includes(useLayoutSize());
  const [expanded, setExpanded] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = React.useState(false);
  const itemCount = props.learningObjectives.length;

  const toggleExpanded = () => setExpanded(!expanded);

  React.useEffect(() => {
    if (contentRef.current) {
      // Ensure that show more button is shown only if the content is larger than 5 lines
      const collapsedHeight = 5 * parseFloat(getComputedStyle(contentRef.current).lineHeight);
      if (contentRef.current.scrollHeight > collapsedHeight) {
        setShowButton(true);
      }
    }
  }, [props.learningObjectives]);

  return (
    <div className='learning-objectives-wrapper section'>
      <div className='learning-objectives-content'>
        <div className='header'>
          <div className='learning-objective-title'>
            <Icon
                name='learning-objective'
                className='learning-objective-icon'
            />
            <h3 className='font-xs-lt'>
              {props.heading + (itemCount > 1 ? 's' : '')}
            </h3>
          </div>
          <span className='learning-objs-count-tag'>
            {intl.formatMessage(isMobile ?
              messages.learningObjectivesCountOnly :
              messages.learningObjectivesTotalCount,
              { count: itemCount }
            )}
          </span>
        </div>

        <div
          ref={contentRef}
          className={`learning-objectives-list ${expanded ? 'expanded' : 'collapsed'}`}
        >
          {props.learningObjectives.length > 1 ?
            <ol>{props.learningObjectives.map((lo, index) =>
              <li key={index}>
                <RichText
                    innerHtml={lo}
                  sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                />
              </li>
            )}
            </ol>
            :
            <RichText
                innerHtml={props.learningObjectives[0]}
              sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
            />
          }
        </div>
        {showButton && (
          <button className='toggle-button' onClick={toggleExpanded}>
            {expanded ?
              intl.formatMessage(messages.learningObjectivesShowLess) :
              intl.formatMessage(messages.learningObjectivesShowMore)
            }
          </button>
        )}
      </div>
    </div>
  );
};

export default LearningObjectives;
