// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Transcripts,
    TranscriptsFromJSON,
    TranscriptsToJSON,
} from './';

/**
 * 
 * @export
 * @interface VideoDataState
 */
export interface VideoDataState {
    /**
     * 
     * @type {Array<Transcripts>}
     * @memberof VideoDataState
     */
    transcripts: Array<Transcripts>;
    /**
     * 
     * @type {any}
     * @memberof VideoDataState
     */
    encodedVideos: any;
    /**
     * 
     * @type {number}
     * @memberof VideoDataState
     */
    savedVideoPosition: number;
    /**
     * 
     * @type {number}
     * @memberof VideoDataState
     */
    speed: number;
}

export function VideoDataStateFromJSON(json: any): VideoDataState {
    return {
        'transcripts': (json['transcripts'] as Array<any>).map(TranscriptsFromJSON),
        'encodedVideos': (json['encoded_videos']),
        'savedVideoPosition': json['saved_video_position'],
        'speed': json['speed'],
    };
}

export function VideoDataStateToJSON(value?: VideoDataState): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'transcripts': (value.transcripts as Array<any>).map(TranscriptsToJSON),
        'encoded_videos': (value.encodedVideos),
        'saved_video_position': value.savedVideoPosition,
        'speed': value.speed,
    };
}


