// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UserSocialLink
 */
export interface UserSocialLink {
    /**
     * 
     * @type {string}
     * @memberof UserSocialLink
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof UserSocialLink
     */
    socialLink: string;
}

export function UserSocialLinkFromJSON(json: any): UserSocialLink {
    return {
        'platform': json['platform'],
        'socialLink': json['social_link'],
    };
}

export function UserSocialLinkToJSON(value?: UserSocialLink): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'platform': value.platform,
        'social_link': value.socialLink,
    };
}


