import React from 'react';

export type ViewType = 'career' | 'category' | 'other';

export interface ViewToggleProps {
    view: ViewType;
    onViewChange: (view: ViewType) => void;
}

const ViewToggle: React.FC<ViewToggleProps> = ({ view, onViewChange }) => {
    return (
        <div className='view-toggle'>
            <button
                className={`view-toggle__button ${view === 'career' ? 'view-toggle__button--active' : ''}`}
                onClick={() => onViewChange('career')}
                aria-pressed={view === 'career'}
            >
                <span className='view-toggle__icon'>📇</span>
                Career
            </button>
            <button
                className={`view-toggle__button ${view === 'category' ? 'view-toggle__button--active' : ''}`}
                onClick={() => onViewChange('category')}
                aria-pressed={view === 'category'}
            >
                <span className='view-toggle__icon'>📋</span>
                Categories
            </button>
        </div>
    );
};

export default ViewToggle;
