// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemMetadata,
    ItemMetadataFromJSON,
    ItemMetadataToJSON,
    ItemUserAttributes,
    ItemUserAttributesFromJSON,
    ItemUserAttributesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     * 
     * @type {ItemMetadata}
     * @memberof ItemResponse
     */
    metadata: ItemMetadata;
    /**
     * 
     * @type {ItemUserAttributes}
     * @memberof ItemResponse
     */
    userAttributes: ItemUserAttributes;
}

export function ItemResponseFromJSON(json: any): ItemResponse {
    return {
        'metadata': ItemMetadataFromJSON(json['metadata']),
        'userAttributes': ItemUserAttributesFromJSON(json['user_attributes']),
    };
}

export function ItemResponseToJSON(value?: ItemResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'metadata': ItemMetadataToJSON(value.metadata),
        'user_attributes': ItemUserAttributesToJSON(value.userAttributes),
    };
}


