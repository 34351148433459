import { LocationDescriptor } from 'history';
import { ItemProgress, Profile } from 'labxchange-client';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, Pagination } from 'ui/components';
import { AssignedItemsReport } from '../AssignedItemsReport';
import { PAGINATION_SIZE } from 'global/constants';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface AssignedItemsReportModalProps {
    user: Profile;
    completion: {weighedEarned?: number, weighedPossible?: number};
    itemsProgress: ItemProgress[];
    onClose: () => void;
    onClickRemove: () => void;
    onNavigationChange?: (page: number) => void;
    navigation?: {
        pageCount: number;
        currentPage: number;
    };
    unassignItem?: (item: ItemProgress) => void;
    markAsIncomplete?: (item: ItemProgress) => void;
}

interface AssignedItemsReportModalState {
    redirectTo?: LocationDescriptor;
}

export class AssignedItemsReportModal extends React.PureComponent<AssignedItemsReportModalProps, AssignedItemsReportModalState> {

    constructor(props: AssignedItemsReportModalProps) {
        super(props);
        this.state = {};
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push={true} to={this.state.redirectTo}/>;
        }
        const user = this.props.user;
        return (
            <Modal
                className='assignment-list-modal'
                onRequestClose={this.props.onClose}
                size='large'
                showTopBar={true}
                title={
                    <WrappedMessage
                        message={messages.menteeProgressReportModalHeader}
                        values={{ fullName: user.fullName }}
                    />
                }
                content={
                    <div className='modal-body'>
                        <AssignedItemsReport
                            itemsProgress={this.props.itemsProgress}
                            unassignItem={this.props.unassignItem}
                            markAsIncomplete={this.props.markAsIncomplete}
                        />
                    </div>
                }
                footer={
                    <div className='modal-navigation'>
                        {this.props.navigation && <Pagination
                            pageCount={Math.ceil((this.props.navigation.pageCount ? this.props.navigation.pageCount : 0) / PAGINATION_SIZE)}
                            currentPage={this.props.navigation.currentPage}
                            onPageSelect={this.props.onNavigationChange!}
                        />}
                    </div>
                }
            />
        );
    }
}
