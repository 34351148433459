// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerMentor
 */
export interface CareerMentor {
    /**
     * 
     * @type {string}
     * @memberof CareerMentor
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CareerMentor
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CareerMentor
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CareerMentor
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerMentor
     */
    publicAvatarUrl?: string;
}

export function CareerMentorFromJSON(json: any): CareerMentor {
    return {
        'username': json['username'],
        'email': json['email'],
        'fullName': json['full_name'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'publicAvatarUrl': !exists(json, 'public_avatar_url') ? undefined : json['public_avatar_url'],
    };
}

export function CareerMentorToJSON(value?: CareerMentor): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'email': value.email,
        'full_name': value.fullName,
        'job_title': value.jobTitle,
        'public_avatar_url': value.publicAvatarUrl,
    };
}


