// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExploreSection,
    ExploreSectionFromJSON,
    ExploreSectionToJSON,
} from '../models';

export interface SectionsReadRequest {
    id: string;
}

/**
 * no description
 */
export class ExploreSectionsApi extends runtime.BaseAPI {

    /**
     * Return section and slabs with items
     */
    async sectionsReadRaw(requestParameters: SectionsReadRequest): Promise<runtime.ApiResponse<ExploreSection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sectionsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/explore_sections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExploreSectionFromJSON(jsonValue));
    }

    /**
     * Return section and slabs with items
     */
    async sectionsRead(requestParameters: SectionsReadRequest): Promise<ExploreSection> {
        const response = await this.sectionsReadRaw(requestParameters);
        return await response.value();
    }

}
