import * as React from 'react';
import { Action } from 'redux';
import { MessageDescriptor } from 'react-intl';

export enum Types {
    /**
     * Show a new message. If a message is already shown, the new
     * message won't be seen until after all existing messages are dismissed.
     */
    SHOW_MESSAGE = 'SHOW_MESSAGE',
    /** Dismiss the currently visible message, if any. */
    DISMISS_MESSAGE = 'DISMISS_MESSAGE',
}

export const enum MessageType {
    Success,
    Warning,
    Error,
}

export interface ShowMessage extends Action {
    readonly type: Types.SHOW_MESSAGE;
    /** What type of message this is */
    readonly messageType: MessageType;
    /** Title of the message popup. Leave undefined for the default title. */
    readonly title?: React.ReactNode;
    /** Append component to the modal body. */
    readonly bodyHtmlAppend?: React.ReactNode;
    /** The message to display. */
    readonly message: React.ReactNode;
    /** Optionally make this a prompt with an OK and cancel button, and attach this event to the OK button: */
    readonly onConfirm?: () => void;
    // Optionally pass a function that will be called if the modal is closed (but not onConfirm)
    readonly onClose?: () => void;
    readonly confirmText?: MessageDescriptor;
    readonly cancelText?: MessageDescriptor;
}

export interface DismissMessage extends Action {
    readonly type: Types.DISMISS_MESSAGE;
}

export type ActionTypes = ShowMessage | DismissMessage;

/**
 * Show the specified error message and log it to the error console
 * message: The message to display. Does not support HTML.
 * title: Optional title.
 * exception: If an exception was thrown, pass it here to report the details in the browser's console.
 * details: Any additional debugging information you wish to log to the browser's console.
 */
interface ErrorMessageArgs {
    title?: React.ReactNode;
    exception?: Error;
    details?: any;
    confirmText?: MessageDescriptor;
    cancelText?: MessageDescriptor;
    onClose?: () => void;
}

export function showErrorMessage(
    message: React.ReactNode,
    {title, exception, details, confirmText, cancelText, onClose}: ErrorMessageArgs = {},
): ShowMessage {
    // tslint:disable: no-console
    console.groupCollapsed('Error Details');
    console.log('Error message: ', message);
    if (details) {
        console.log(details);
    }
    if (exception) {
        console.error(exception);
        // If this was an HTTP 400 error, print the details to make debugging easier
        if ('status' in exception && (exception as any).status === 400 && !(exception as any).bodyUsed) {
            (exception as any).text().then((bodyText: string) => console.log(bodyText));
        }
    } else {
        console.trace('Stack trace');
    }
    console.groupEnd();
    return {
        type: Types.SHOW_MESSAGE,
        messageType: MessageType.Error,
        title,
        message,
        confirmText,
        cancelText,
        onClose,
    };
}

interface WarningMessageArgs {
    title?: React.ReactNode;
    onConfirm?: () => void;
    confirmText?: MessageDescriptor;
    cancelText?: MessageDescriptor;
    bodyHtmlAppend?: React.ReactNode;
}

/**
 * Show the specified warning message
 */
export function showWarningMessage(
    message: React.ReactNode,
    {title, onConfirm, bodyHtmlAppend, confirmText, cancelText}: WarningMessageArgs = {},
): ShowMessage {
    return {
        type: Types.SHOW_MESSAGE,
        messageType: MessageType.Warning,
        title,
        message,
        bodyHtmlAppend,
        onConfirm,
        confirmText,
        cancelText,
    };
}

interface SuccessMessageArgs {
    title?: React.ReactNode;
    confirmText?: MessageDescriptor;
    onClose?: () => void;
}

/**
 * Show the specified success message
 */
export function showSuccessMessage(
    message: React.ReactNode,
    {title, confirmText, onClose}: SuccessMessageArgs = {}
): ShowMessage {
    return {
        type: Types.SHOW_MESSAGE,
        messageType: MessageType.Success,
        title,
        message,
        confirmText,
        onClose,
    };
}

/** Dismiss the currently visible message, if any. */
export function dismissMessage(): DismissMessage {
    return {type: Types.DISMISS_MESSAGE};
}
