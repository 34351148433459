import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation, NavLink } from 'react-router-dom';

import { CareerCategory, CareerSubcategory } from 'labxchange-client';
import { CareersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { isKeyboardEnterEvent } from '../../../global/utils';
import Banner from '../Banner/Banner';

import {Icon, Spinner} from 'ui/components';
import { useColor } from '../../ThemeProvider';
import {CareerStandardLayout} from '../CareerStandardLayout';
import messages from '../../displayMessages';


export const SubCategoryHome: React.FC = () => {
    const {category: categoryId} = useParams<{ category: string }>();
    const location = useLocation<{ categoryData: CareerCategory }>();
    const categoryData = location.state?.categoryData;

    const [loading, setLoading] = useState<boolean>(true);
    const [subCategories, subSubCategories] = useState<CareerSubcategory[]>([]);
    const [categoryInfo, setCategoryInfo] = useState<CareerCategory>(categoryData);

    const history = useHistory();

    const { selectedColor } = useColor();

    const fetchCategories = async () => {
        try {
            setLoading(true);
            const response = await CareersApi.subcategoriesList({category: Number(categoryId)});
            if (response.length) {
                subSubCategories(response);
                setCategoryInfo(response[0].category);
            }
        } catch (error) {
            subSubCategories([]);
            // tslint:disable-next-line:no-console
            console.error('Error fetching subCategories', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, [categoryId]);

    const onClickCard = (subcategory: CareerSubcategory) => {
        history.push({
            pathname: ROUTES.CareerExplorer.CAREER_LIST(Number(categoryId), Number(subcategory.id)),
            state: {categoryData, subcategoryData: subcategory},
        });
    };

    if (loading) {
        return <Spinner/>;
    }

    return (
        <CareerStandardLayout
            mainClassName='career-home-page'
            pageTitle={messages.careerDefaultPageTitle}
            headerComponent={
                <Banner
                    title={categoryInfo.categoryName}
                    description={categoryInfo.definition}
                    icon={categoryInfo.icon}
                />
            }
        >
                {!loading &&
                  <div className='subcategory-home'>
                    <div className='subcategory-list'>
                        {subCategories.map((category) => (
                            <div
                                key={category.id}
                                tabIndex={0}
                                role='button'
                                className={`subcategory-list__card ${selectedColor?.class}`}
                                onClick={() => onClickCard(category)}
                                onKeyDown={(e) => isKeyboardEnterEvent(e) && onClickCard(category)}
                            >
                                <div className='subcategory-list__card__name'>
                                    {category.subcategoryName}
                                </div>
                                <div className='subcategory-list__card__icon'>
                                    <Icon name='arrow-forward' fill={'#002F50'} />
                                </div>
                            </div>
                        ))}
                    </div>
                  </div>
                }
        </CareerStandardLayout>

    );
};

export default SubCategoryHome;
