import bind from 'bind-decorator';
import * as React from 'react';

import {
    ItemMetadata,
} from 'labxchange-client';
import { ItemsApi } from 'global/api';
import { KebabMenuItem, ModalConfirmation } from 'ui/components';
import {
    showErrorMessage,
    showSuccessMessage,
} from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import uiMessages from 'ui/components/displayMessages';

interface Props {
    item: ItemMetadata;
    onClose: () => void;
    onOpen: () => void;
    onDelete?: () => void;
}

interface State {
    showConfirmModal: boolean;
}

// This item is designed to be in a KebabMenu.
export class DeleteButton extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showConfirmModal: false,
        };
    }

    public render() {
        return (
            <>
                <KebabMenuItem
                    iconName='trashcan'
                    disabled={false}
                    message={messages.actionDelete}
                    onClick={this.onOpenConfirmModal}
                />
                {this.state.showConfirmModal &&
                    (this.props.item.isPublic ?
                        showErrorMessage(<WrappedMessage message={messages.deleteItemBlocked} />, {
                            onClose: this.onCloseConfirmModal,
                            exception: new Error('Cannot delete public asset'),
                        })
                        :
                        <ModalConfirmation
                            onCanceled={this.onCloseConfirmModal}
                            onConfirmed={this.onDelete}
                            title={messages.deleteItemConfirm}
                            titleValues={{title: this.props.item.title}}
                            body={messages.deleteItemWarn}
                            confirmButtonText={uiMessages.uiConfirmationButton}
                            cancelButtonText={uiMessages.uiCancelButton}
                        />
                    )
                }
            </>
        );
    }

    @bind private onCloseConfirmModal() {
        this.setState({ showConfirmModal: false });
        this.props.onClose();
    }

    @bind private onOpenConfirmModal() {
        this.setState({ showConfirmModal: true });
        this.props.onOpen();
    }

    @bind private async onDelete() {
        const item = this.props.item;
        if (item.id) {
            try {
                await ItemsApi._delete({ id: item.id });
                showSuccessMessage(<WrappedMessage message={messages.deleteItemSuccess} />);
                if (this.props.onDelete) {
                    this.props.onDelete();
                }
            } catch (err) {
                this.setState({ showConfirmModal: false });
                showErrorMessage(
                    <WrappedMessage message={messages.deleteItemErrorMessage} />,
                    {
                        exception: err,
                        confirmText: uiMessages.uiOk,
                    },
                );
            }
        }

        this.onCloseConfirmModal();
    }

}
