import moment from 'moment-shortformat';
import * as React from 'react';

import { ConversationInfo, Conversation, DiscussionAuthor, ConversationInfoStatusEnum } from 'labxchange-client';
import { Hexagon } from 'ui/components';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface MessageTopicItemProps {
    selected: boolean;
    user?: Readonly<DiscussionAuthor>;
    selectConversation?: (conversationId: string) => void;
    conversation: Readonly<Conversation>;
    info?: ConversationInfo;
}

export class MessageTopicItem extends React.Component<MessageTopicItemProps> {
    public render() {
        const { selected, conversation, user } = this.props;
        const unread = (conversation.unread || 0) > 0;
        const newPosts = conversation.unread || 0;

        return (
            <button
                role='listitem'
                tabIndex={0}
                className={`message-topic-item list-group-item list-group-item-action ` +
                `${unread ? 'unread' : ''} ${selected ? 'selected' : ''}`}
                onClick={this.selectConversation.bind(this, conversation.id)}
                aria-selected={selected}
            >
                <div className='message-topic-item-left'>
                    {user &&
                        <UserAvatar
                            username={user.username}
                            diameter='54px'
                        />
                    }
                    {newPosts > 0 &&
                        <Hexagon className='badge font-weight-normal m-0 p-0'
                            width={17} height={20} text={newPosts.toString()} />
                    }
                </div>
                <div className='message-topic-item-details'>
                    <div className='message-topic-item-details-top'>
                        <div className='message-topic-item-name'>
                            <span>{user && user.fullName}</span>
                        </div>
                        <div className='message-topic-item-date'>
                            <span>{moment(conversation.updated).calendar(undefined, {
                                sameDay: '[Today]',
                                lastDay: '[Yesterday]',
                                lastWeek: '[Last] dddd',
                                sameElse: 'DD/MM/YYYY',
                            })}</span>
                        </div>
                    </div>
                    {this.renderTitleOrLabel()}
                </div>
            </button>
        );
    }

    /**
     * renders either the title of the message as a preview, or a notice if the
     * conversation is blocked, pending, etc.
     */
    private renderTitleOrLabel(): React.ReactNode {
        const { info, conversation } = this.props;

        if (info !== undefined) {
            if (info.isBlocked) {
                return (
                    <div className='message-topic-item-title blocked-notice'>
                        <WrappedMessage message={messages.blocked} />
                    </div>
                );
            } else if (info.hasBlockedYou) {
                return (
                    <div className='message-topic-item-title pending-notice'>
                        <WrappedMessage message={messages.blockedYou} />
                    </div>
                );
            } else if (info.status === ConversationInfoStatusEnum.PendingYou) {
                return (
                    <div className='message-topic-item-title pending-notice'>
                        <WrappedMessage message={messages.pendingYou} />
                    </div>
                );
            } else if (info.status === ConversationInfoStatusEnum.PendingThem) {
                return (
                    <div className='message-topic-item-title pending-notice'>
                        <WrappedMessage message={messages.pendingThem} />
                    </div>
                );
            }
        }

        return (
            <div className='message-topic-item-title'>
                {conversation.latestReply}
            </div>
        );
    }

    private selectConversation = (conversationId: string, e: React.MouseEvent) => {
        if (this.props.selectConversation) {
            this.props.selectConversation(conversationId);
        }
    }
}
