import bind from 'bind-decorator';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import messages from './displayMessages';
import { Button, ToggleSwitch } from 'ui/components';

export enum Tab {
    VideoPlayer,
    AnnotationsList,
    AnnotationFilter,
}

export interface TabLink {
    to: string;
    message: MessageDescriptor;
    exact: boolean;
}

interface Props {
    children: React.ReactNode;
    onTabChange: (actualTab: Tab) => void;
    onToggleChange: (toggleIsChecked: boolean) => void;
    actualTab: Tab;
}

interface State {
    toggleIsChecked: boolean;
    actualTab: Tab;
}

export class MobileTabbedPage extends React.Component<Props, State> {

     constructor(props: Props) {
        super(props);
        this.state = {
          toggleIsChecked: true,
          actualTab: this.props.actualTab,
        };
    }

    public componentDidUpdate() {
        if (this.props.actualTab !== this.state.actualTab) {
            this.setState({actualTab: this.props.actualTab});
        }
    }

    public render() {
        return (
            <div className='annotated-video-mobile'>
                <div className='annotated-video-mobile-tabs'>
                    <Button
                        className={`annotated-video-mobile-tab ${this.state.actualTab === Tab.VideoPlayer ? 'active' : ''}`}
                        btnStyle='ghost'
                        icon='device-camera-video'
                        iconOnly={true}
                        label={messages.watchClipButtonLabel}
                        onClick={() => this.onClickTab(Tab.VideoPlayer)}
                        iconZoom='20'
                    />
                    <Button
                        className={`annotated-video-mobile-tab ${this.state.actualTab === Tab.AnnotationsList ? 'active' : ''}`}
                        btnStyle='ghost'
                        icon='list-unordered'
                        iconOnly={true}
                        label={messages.watchClipButtonLabel}
                        onClick={() => this.onClickTab(Tab.AnnotationsList)}
                        iconZoom='20'
                    />
                    <Button
                        className={`annotated-video-mobile-tab ${this.state.actualTab === Tab.AnnotationFilter ? 'active' : ''}`}
                        btnStyle='ghost'
                        icon='search'
                        iconOnly={true}
                        label={messages.watchClipButtonLabel}
                        onClick={() => this.onClickTab(Tab.AnnotationFilter)}
                        iconZoom='20'
                    />
                    <div className='annotated-video-mobile-toggle'>
                        <ToggleSwitch
                            componentStyle='yellow'
                            isChecked={this.state.toggleIsChecked}
                            onToggleSwitchSelect={this.onToggleSwitchSelect}
                            displayText=''
                            hideLabel={true}
                        />
                    </div>
                </div>
                {this.props.children}
            </div>
        );
    }

    @bind private onToggleSwitchSelect(toggleIsChecked: boolean) {
        this.setState({toggleIsChecked});
        this.props.onToggleChange(toggleIsChecked);
    }

    @bind private onClickTab(actualTab: Tab) {
        this.setState({actualTab});
        this.props.onTabChange(actualTab);
    }
}
