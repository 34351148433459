import * as React from 'react';
import { defineMessages } from 'react-intl';

import GeneralFeedback from './GeneralFeedback';
import HintSetting from './HintSetting';
import ScorringSetting from './ScoringSetting';
import { doAutoSize } from "../../Utils";
import { intl } from 'i18n';

const messages = defineMessages({
    title: {
        id: 'MultiAdvansedSettings.title',
        defaultMessage: 'Advanced settings'
    },
    closeSettings: {
        id: 'MultiAdvansedSettings.closeSettings',
        defaultMessage: 'Hide advanced options'
    },
    showSettings: {
        id: 'MultiAdvansedSettings.showSettings',
        defaultMessage: 'Show advanced options'
    }
})

export default class MultiAdvancedSettings extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            advancedSettingsOpenned: true,
        };
    }

    switchAdvancedSettings() {
        this.setState({
            advancedSettingsOpenned: !this.state.advancedSettingsOpenned
        }, () => {
            doAutoSize();
        });
    }

    render() {
        return (
            <div className='lxc-advanced-settings-wrapper'>
                <div className={`lxc-advanced-settings ${this.state.advancedSettingsOpenned ? 'lxc-advanced-settings_open' : ''}`}>
                    <div className='lxc-advanced-settings-title'>
                        {intl.formatMessage(messages.title)}
                    </div>
                    <GeneralFeedback
                        generalFeedbackList={this.props.generalFeedbackList}
                        generalFeedbackChange={this.props.generalFeedbackChange}
                    />
                    <HintSetting {...this.props} />
                    <ScorringSetting {...this.props} />
                </div>
                <button type='button' className='lxc-show-advanced-settings' onClick={this.switchAdvancedSettings.bind(this)}>
                    {this.state.advancedSettingsOpenned
                        ? intl.formatMessage(messages.closeSettings)
                        : intl.formatMessage(messages.showSettings)}
                    <span className='lxc-show-advanced-settings-icon'></span>
                </button>
            </div>
        );
    }
}
