// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationContent
 */
export interface NotificationContent {
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    iconName: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    largeIconUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    targetUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    buttonLabel: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    profileIconUrl: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    profileUrlUsername: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationContent
     */
    iconColor: NotificationContentIconColorEnum;
}

export function NotificationContentFromJSON(json: any): NotificationContent {
    return {
        'title': json['title'],
        'text': json['text'],
        'iconName': json['icon_name'],
        'largeIconUrl': json['large_icon_url'],
        'targetUrl': json['target_url'],
        'buttonLabel': json['button_label'],
        'profileIconUrl': json['profile_icon_url'],
        'profileUrlUsername': json['profile_url_username'],
        'iconColor': json['icon_color'],
    };
}

export function NotificationContentToJSON(value?: NotificationContent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'text': value.text,
        'icon_name': value.iconName,
        'large_icon_url': value.largeIconUrl,
        'target_url': value.targetUrl,
        'button_label': value.buttonLabel,
        'profile_icon_url': value.profileIconUrl,
        'profile_url_username': value.profileUrlUsername,
        'icon_color': value.iconColor,
    };
}

/**
* @export
* @enum {string}
*/
export enum NotificationContentIconColorEnum {
    Blue = 'blue',
    Orange = 'orange',
    Green = 'green',
    Red = 'red'
}


