import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleCollaboratorsPage',
        defaultMessage: 'LabXchange Collaborators',
        description: 'The title of the page.',
    },
    pageSubTitle: {
        id:'pageSubTitleCollaboratorsPage',
        defaultMessage: 'LabXchange convenes a community that believes in the transformative power of education.',
        description: 'The subtitle of the page.',
    },
    loadFailed: {
        id:'loadCollaboratorsPageFailed',
        defaultMessage: 'Collaborators page failed to load.',
        description: 'Message displayed when the background request on the collaborators page fails.',
    },
    sponsors: {
        id:'sponsors',
        defaultMessage: 'Sponsor',
        description: 'Text for the sponsor heading.',
    },
    sponsorsSub: {
        id:'sponsorsSub',
        defaultMessage: 'Our sponsor\'s commitment to excellence in science education makes LabXchange possible.',
        description: 'Text for the sponsor subheading.',
    },
    strategicAllies: {
        id:'strategicAllies',
        defaultMessage: 'Strategic Allies',
        description: 'Text for the strategic allies heading.',
    },
    strategicAlliesSub: {
        id:'strategicAlliesSub',
        defaultMessage: 'We join with mission-driven organizations to harness technology and mobilize networks.',
        description: 'Text for the strategic allies subheading.',
    },
    collaborators: {
        id:'collaborators',
        defaultMessage: 'Content collaborators',
        description: 'Text for the sponsor heading.',
    },
    collaboratorsSub: {
        id:'collaboratorsSub',
        defaultMessage: 'These organizations create the exceptional digital learning resources that you find on LabXchange.',
        description: 'Text for the sponsor subheading.',
    },
    seeProfile: {
        id:'seeProfile',
        defaultMessage: 'See profile',
        description: 'Text for the link to the organization profile.',
    },
    viewWebsite: {
        id:'viewWebsite',
        defaultMessage: 'View website',
        description: 'Text for the link to the organization website.',
    },
    collaborateWithUs: {
        id:'collaborateWithUs',
        defaultMessage: 'Collaborate with us',
        description: 'Text for the link to the email address for potential collaborators to contact us on.',
    },
});

export default messages;
