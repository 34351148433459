import * as React from 'react';

import { SearchRequestOrderingEnum } from 'labxchange-client';
import { FilterBar, FilterBarProps } from 'search/components/FilterBar';
import { TaxonomyData } from 'search/components/Taxonomy';
import { UI_IS_MD, UI_IS_SM, UI_IS_LG } from 'ui/breakpoints';
import { CardsListBottomBar } from '../CardsListBottomBar';
import { CardsListTopBar } from '../CardsListTopBar';
import { SORT_OPTIONS_RELEVANCE } from '../../../global/locales-constants';
import { locale } from 'i18n';
import { TagData, nameForTag } from 'search/components/Tag';
import { NoResultsWidget } from './NoResultsWidget';
import messages from './displayMessages';
import { WrappedMessage } from 'utils';

interface Props {
    /** Show and allow results filtering */
    allowFiltering?: boolean;
    /** Result cards components */
    children?: React.ReactNode;
    /** Are we in a modal (hides filters by default and forces mobile/tablet view mode only) */
    isModalView?: boolean;
    /** Are (new/updated) results being fetched from the server? */
    loadingNewResults: boolean;
    /** Whether to show the filters and pagination when loading results? */
    onlyShowLoaderWhenLoading?: boolean;
    /**
     * List of all the tag taxonomies that can be used to filter the search (Subject Area, Content Type, etc.)
     * This comes from ElasticSeach, so it reflects only the subset of filter options that are actually in use.
     */
    availableSearchFilters: ReadonlyArray<TaxonomyData>;
    contentFilters?: ReadonlyArray<TagData>;
    /** IDs of the search filters that are currently applied */
    appliedFilterIds: ReadonlySet<string>;
    searchKeywords: ReadonlySet<string>;
    /** Number of matches that were returned from the search */
    resultCount: number;
    // Pagination of search results
    currentPage: number;
    paginationSize: number;
    maxPagesDisplayed: number;
    sortOrder: string;
    sortOrderOptions?: { label: string, value: string}[];
    // Which of the filter options in the filter bar should have their own search box to filter them
    showSearchForTaxonomies: FilterBarProps['showSearchForTaxonomies'];
    // Events:
    /** The user has used the pagination controls to go to a different page of results */
    onPageChange: (currentPage: number) => void;
    /** The user has applied a new set of filters to the search, or changed the keyword */
    onFiltersChanged: (newFilters: ReadonlySet<string>, searchKeywords: ReadonlySet<string>) => void;
    /** The user has changed the pagination size */
    onSizeChange: (paginationSize: number) => void;
    /** The user has changed the sort order */
    onSortChange: (sortOrder: string) => void;
    /** The user has clicked on 'Show more results' button from mobile view */
    loadMore?: () => void;
    showFilterModal?: boolean;
}

/**
 * A responsive, full-width component (put in a .container) that has:
 * - on desktop, a filter bar on the left 1/4 and result cards on the right 3/4
 * - on tablet, a hidden filter modal that is shown when the user clicks a "Filters" button
 * - on phones, a minimal UI at the top with an icon to bring up the filters modal
 */
export const ResponsiveFilteredSearch: React.FunctionComponent<Props> = ({
    isModalView = false,
    ...props
}) => {

    // Layout mode: we detect the device size, but also sometimes we force
    // tablet mode when this UI is shown in a modal on desktop.
    const [layoutMode, setLayoutMode] = React.useState<'desktop'|'mobile'|'tablet'|'large'>('desktop');

    React.useEffect(() => {
        const updateLayoutMode = () => {
            setLayoutMode(
                UI_IS_SM.matches ? 'mobile' :
                UI_IS_MD.matches ? 'tablet' :
                UI_IS_LG.matches ? 'large' :
                isModalView ? 'tablet' : // On desktop but forced to use modal layout
                'desktop',
            );
        };
        UI_IS_SM.addListener(updateLayoutMode);
        UI_IS_MD.addListener(updateLayoutMode);
        UI_IS_LG.addListener(updateLayoutMode);
        updateLayoutMode();
        return () => {
            // Cleanup:
            UI_IS_SM.removeListener(updateLayoutMode);
            UI_IS_MD.removeListener(updateLayoutMode);
            UI_IS_LG.addListener(updateLayoutMode);
        };
    });

    // On tablet/mobile layouts, did the user click on the button to show the filters?
    const [isFilterModalVisible, setIsFilterModalVisible] = React.useState(false);

    React.useEffect(() => {
        const value = props.showFilterModal ? true : false;
        setIsFilterModalVisible(value);
    },[props.showFilterModal]);

    const filterVisible = isFilterModalVisible || layoutMode === 'desktop' || layoutMode === 'large';
    // Count how many pages of results there are
    const pageCount = Math.min(Math.max(1, Math.ceil(props.resultCount / props.paginationSize)), props.maxPagesDisplayed);

    // Handler for clearing all applied filters and tags
    const doFiltersAndTagsClear = () => {
        props.onFiltersChanged(new Set(), new Set());
    };
    // Handler for clearing a single applied keyword
    const onClearKeywordFilter = (keyword: string) => {
        const newKeywords = new Set([...props.searchKeywords].filter((kw) => kw !== keyword));
        props.onFiltersChanged(props.appliedFilterIds, newKeywords);
    };
    // Handler for clearing a single applied filter
    const onClearFilter = (filterId: string) => {
        const newFilterIds = new Set([...props.appliedFilterIds].filter((f) => f !== filterId));
        props.onFiltersChanged(newFilterIds, props.searchKeywords);
    };

    // Default to only including 'Relevance'. This is used for many search types,
    // so it's expected that the parent component will supply a sane list of
    // order options for the component type.
    const sortOrderOptions = props.sortOrderOptions || [
        { label: SORT_OPTIONS_RELEVANCE[locale], value: SearchRequestOrderingEnum.Relevance },
    ];

    return <div
        className={`
            responsive-filtered-search
            ${filterVisible ? 'show-filter' : ''}
        `}
    >
        {props.loadingNewResults &&
            <span className='sr-only' aria-live='polite'>
                <WrappedMessage message={messages.resultsLoadingSRMessage} />
            </span>
        }
        <FilterBar
            showCloseButton={isFilterModalVisible && layoutMode !== 'desktop'}
            onCloseButtonClick={() => { setIsFilterModalVisible(false); }}
            onTagsClear={doFiltersAndTagsClear}
            showTagsClear={props.appliedFilterIds.size > 0}
            selectedTagIds={props.appliedFilterIds}
            taxonomies={props.availableSearchFilters}
            onToggleTagSelect={(newFilters) => { props.onFiltersChanged(newFilters, props.searchKeywords); }}
            initiallyExpandedTaxonomy={''}
            showSearchForTaxonomies={props.showSearchForTaxonomies}
            showSkeleton={props.loadingNewResults}
        />
        <div className={`responsive-filtered-search-results-pane${props.loadingNewResults ? ' loading-results' : ''}`}>
            <CardsListTopBar
                isMobileView={layoutMode === 'mobile'}
                isTabletView={layoutMode === 'tablet'}
                isLargeView={layoutMode === 'large'}
                allowFiltering={props.allowFiltering}
                filterShown={filterVisible}
                searchQueries={props.searchKeywords}
                onClear={doFiltersAndTagsClear}
                // The following is confusing because old code calls keywords "filters" and filters "tags"
                onClearFilter={onClearKeywordFilter}
                onClearTag={onClearFilter}
                onToggleFilter={() => {
                    setIsFilterModalVisible(!isFilterModalVisible);
                }}
                onSortSelect={props.onSortChange}
                resultCount={props.resultCount}
                sortOrder={props.sortOrder}
                sortOrderOptions={sortOrderOptions}
                tags={appliedTagsWithNames(props.appliedFilterIds, props.availableSearchFilters)}
                showSkeleton={props.loadingNewResults}
                contentFilters={props.contentFilters}
                selectedTagIds={props.appliedFilterIds}
                onToggleTagSelect={(newFilters) => { props.onFiltersChanged(newFilters, props.searchKeywords); }}
            />
            { (props.resultCount === 0 && !props.loadingNewResults) &&
                <NoResultsWidget />
            }
            <div className='responsive-filtered-search-results'>
                {props.children}
            </div>
            {pageCount > 1 &&
                <CardsListBottomBar
                    isMobileView={layoutMode === 'mobile'}
                    currentPage={props.currentPage}
                    onPageJump={props.onPageChange}
                    onPageNavigate={props.onPageChange}
                    onSizeSelect={props.onSizeChange}
                    loadMore={props.loadMore!}
                    pageCount={pageCount}
                    paginationSize={props.paginationSize}
                />
            }
        </div>

        {!props.loadingNewResults && (
            <span className='sr-only' aria-live='polite'>
                <WrappedMessage message={messages.loadingDoneSRMessage} />
            </span>
        )}
    </div>;
};

/**
 * Get a list of all the filters currently applied, including each filter's
 * ID and a human-friendly name. This is used to populate the filter bar
 * shown above the search result cards.
 * The easiest way to get the human-friendly names is to look them up
 * from the data in availableSearchFilters
 */
function appliedTagsWithNames(
    appliedFilterIds: ReadonlySet<string>,
    availableSearchFilters: ReadonlyArray<TaxonomyData>,
): {id: string, name: string}[] {

    return Array.from(appliedFilterIds).map((tagId) => {
        for (const filter of availableSearchFilters) {
            for (const filterOption of filter.tags) {
                if (filterOption.id === tagId) {
                    return {id: tagId, name: nameForTag(filterOption, filter.category)};
                }
                // And for subject tags we have to check child tags too:
                for (const childFilterOption of filterOption.childTags || []) {
                    if (childFilterOption.id === tagId) {
                        return {id: tagId, name: nameForTag(childFilterOption, filter.category)};
                    }
                }
            }
        }
        // Default case:
        return {id: tagId, name: tagId};
    });
}
