import React, { useState } from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import { intl } from 'i18n';
import messages from './displayMessages';
import CareerTag from '../CareerTag';
import { Icon } from 'elements';
import { WrappedMessage } from 'utils';
import { isKeyboardEnterEvent } from 'global/utils';


export const Interests = [
    'Computers',
    'Drawing',
    'Electronics',
    'Gaming',
    'Robotics',
    'Sports',
    'Cars',
    'Art & Drawings',
];

export interface InterestsInputFieldProps {
    onSubmit: (value: string) => void;
}

export const InterestsInputField: React.FC<InterestsInputFieldProps> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleSubmit = () => {
        if (inputRef.current && inputRef.current.value) {
            props.onSubmit(inputRef.current.value);
            inputRef.current.value = '';
        }
    };

    return (
        <div className='interests-input-field'>
            <input
                type='text'
                id='interests-input'
                className='input-field-element'
                placeholder={intl.formatMessage(messages.careerInterestsInputPlaceholder)}
                onKeyDown={(e) => isKeyboardEnterEvent(e) && handleSubmit()}
                ref={inputRef}
            />
            <div className='instructions'>
                <Icon name='info' zoom='16px' fill='#316387' />
                <span className='instructions-label'>
                    <WrappedMessage message={messages.careerInterestsInputInstructions} />
                </span>
            </div>
        </div>
    );
};

export interface InterestsStepProps {
    currentStep: number;
    onButtonClick: (nextStep: number) => void;
}

export const InterestsStep: React.FC<InterestsStepProps> = ({currentStep, onButtonClick}) => {
    const [selectedTags, setSelectedTags] = useState<React.ReactNode[]>([]);

    const onTagClick = (tag: React.ReactNode, addTag: boolean) => {
        if (addTag) {
            return setSelectedTags([...selectedTags, tag]);
        }

        return setSelectedTags([...selectedTags.filter((interest) => interest !== tag)]);
    };

    const onSubmit = (value: string) => {
        const tags = value.split(',').map(tag => tag.trim()).filter(tag => tag.trim());
        return setSelectedTags([...selectedTags, ...tags]);
    };

    const getInterests = () => {
        let interests = Interests;
        if (selectedTags) {
            interests = [...interests.filter((inerest, i) => selectedTags.indexOf(inerest) < 0)];
        }

        return interests.filter((interest, i) => i < 6);
    };

    return (
        <OnboardingPageLayout
            title={intl.formatMessage(messages.careerInterestsOnboardingStepTitle)}
            description={intl.formatMessage(messages.careerInterestsOnboardingStepDescription)}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedTags.length > 0}
        >
            <div className='career-interests-step'>
                <div className='career-interests'>
                    {getInterests().map((interest, i) =>
                        <CareerTag key={i} onClick={onTagClick} cssClass='career-interests__interest-button-style'>
                            {interest}
                        </CareerTag>
                    )}
                </div>
                <InterestsInputField onSubmit={onSubmit} />
                {selectedTags.length > 0 &&
                    <div className='career-interests selected'>
                        {selectedTags.map((interest, i) =>
                            <CareerTag key={i} active={true} onClick={onTagClick}>
                                {interest}
                            </CareerTag>
                        )}
                    </div>
                }
            </div>
        </OnboardingPageLayout>
    );
};

export default InterestsStep;
