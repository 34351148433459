import { defineMessages } from 'react-intl';

const messages = defineMessages({
    apploginError: {
        id:'apploginError',
        defaultMessage: '{loginError}',
        description: 'Error when the user is not able to login',
    },
    partnerLogoStripDescription: {
        id:'partnerLogoStripDescription',
        defaultMessage: 'We offer educational resources from over {count} leading organizations.',
        description: 'Description text for the partner logo strip',
    },
    buttonSeeAllPartners: {
        id:'buttonSeeAllPartners',
        defaultMessage: 'See all',
        description: 'Text used for the "See all partners" link/button',
    },
    howItWorksHeading: {
        id:'homeHowItWorksHeading',
        defaultMessage: 'How it works',
        description: 'Heading for the "How it works" section of the home page.'
    },
    howItWorksExploreHeading: {
        id:'homeHowItWorksExploreHeading',
        defaultMessage: 'Explore',
        description: 'Heading for the "Explore" hexagon in the "How it works" section of the home page.'
    },
    howItWorksExploreText: {
        id:'homeHowItWorksExploreText',
        defaultMessage: 'Discover high-quality science content in the LabXchange library, for free.',
        description: 'Text for the "Explore" hexagon in the "How it works" section of the home page.'
    },
    howItWorksCreateHeading: {
        id:'homeHowItWorksCreateHeading',
        defaultMessage: 'Create',
        description: 'Heading for the "Create" hexagon in the "How it works" section of the home page.'
    },
    howItWorksCreateText: {
        id:'homeHowItWorksCreateText',
        defaultMessage: 'Sign up to customize a learning journey that meets your unique needs.',
        description: 'Text for the "Create" hexagon in the "How it works" section of the home page.'
    },
    howItWorksShareHeading: {
        id:'homeHowItWorksShareHeading',
        defaultMessage: 'Share',
        description: 'Heading for the "Share" hexagon in the "How it works" section of the home page.'
    },
    howItWorksShareText: {
        id:'homeHowItWorksShareText',
        defaultMessage: 'Engage with a community of educators, learners, and mentors.',
        description: 'Text for the "Share" hexagon in the "How it works" section of the home page.'
    },
    heroHeading: {
        id:'homeHeroHeading',
        defaultMessage: 'Welcome to the world\'s science classroom',
        description: 'Heading displayed in the "hero" bar at the top of the home page.',
    },
    heroText: {
        id:'homeHeroText',
        defaultMessage: 'Experience science as it should be:  a journey of bold thinking, deep imagination, and supportive community.',
        description: 'Text displayed in the "hero" bar at the top of the home page.',
    },
    heroInteractiveHeading: {
        id:'homeHeroInteractiveHeading',
        defaultMessage: 'Dive into a world of interactive learning',
        description: 'Heading displayed in the "hero" bar at the top of the home page.',
    },
    heroInteractiveText: {
        id:'homeHeroInteractiveText',
        defaultMessage: 'Experience what science should be: exploration through bold thinking, collaboration, and endless imagination.',
        description: 'Text displayed in the "hero" bar at the top of the home page.',
    },
    heroInteractiveEducatorsButton: {
        id:'heroInteractiveEducatorsButton',
        defaultMessage: 'Educators',
        description: 'Text displayed in the "Educators" button shown on the "hero" bar at the top of the home page.',
    },
    heroInteractiveLearnersButton: {
        id:'homeheroInteractiveLearnersButton',
        defaultMessage: 'Learners',
        description: 'Text displayed in the "Learners" button shown on the "hero" bar at the top of the home page.',
    },
    heroInteractiveCardLabelSimulation: {
        id:'heroInteractiveCardLabelSimulation',
        defaultMessage: 'Simulation',
        description: 'Simulation label displayed on simulation cards in the hero section.',
    },
    heroInteractiveCardLabelInteractive: {
        id:'heroInteractiveCardLabelInteractive',
        defaultMessage: 'Interactive',
        description: 'Interactive label displayed on simulation cards in the hero section.',
    },
    heroSignUpButton: {
        id:'homeHeroSignUpButton',
        defaultMessage: 'Join for free',
        description: 'Text displayed in the "sign up" button shown on the "hero" bar at the top of the home page.',
    },
    heroExploreButton: {
        id:'homeHeroExploreButton',
        defaultMessage: 'Explore',
        description: 'Text displayed in the "explore" button shown on the "hero" bar at the top of the home page.',
    },
    exploreHeading: {
        id:'exploreHeading',
        defaultMessage: 'Start exploring now!',
        description: 'Heading for the Explore section.',
    },
    featuredItemsHeading: {
        id:'featuredItemsHeading',
        defaultMessage: 'Featured Content',
        description: 'Heading for the Featured Content section.',
    },
    featuredLearningItemsHeading: {
        id:'featuredLearningItemsHeading',
        defaultMessage: 'Learn',
        description: 'Heading for the Featured Content section.',
    },
    featuredTeachingItemsHeading: {
        id:'featuredTeachingItemsHeading',
        defaultMessage: 'Teach',
        description: 'Heading for the Featured Content section.',
    },
    featuredResearchItemsHeading: {
        id:'featuredResearchItemsHeading',
        defaultMessage: 'Research',
        description: 'Heading for the Featured Content section.',
    },
    featuredClustersItemsHeading: {
        id:'featuredClustersItemsHeading',
        defaultMessage: 'Clusters',
        description: 'Heading for the clusters content section.',
    },
    featuredSimulationsItemsHeading: {
        id:'featuredSimulationsItemsHeading',
        defaultMessage: 'Simulations',
        description: 'Heading for the simulation content section.',
    },
    featuredPathwaysItemsHeading: {
        id:'featuredPathwaysItemsHeading',
        defaultMessage: 'Pathways',
        description: 'Heading for the pathways content section.',
    },
    featuredInterativesItemsHeading: {
        id:'featuredInterativesItemsHeading',
        defaultMessage: 'Interactives',
        description: 'Heading for the intractives content section.',
    },
    sponsorHeading: {
        id:'sponsorHeading',
        defaultMessage: 'Our Sponsor',
        description: 'Heading for the Sponsor section.',
    },
    sponsorAltTag: {
        id:'sponsorAltTag',
        defaultMessage: 'Amgen Foundation',
        description: 'Alt text for the Amgen logo in the Sponsor section.',
    },
    sponsorText1: {
        id:'sponsorText1',
        defaultMessage: `LabXchange is sponsored by the Amgen
            Foundation, which seeks to advance excellence in science education
            to inspire the next generation of innovators.`,
        description: 'Text shown in Sponsor section.',
    },
    sponsorText2: {
        id:'sponsorText2',
        defaultMessage: `The Foundation, established in 1991, is an integral
            component of Amgen’s commitment to dramatically improving people’s
            lives. To date, the foundation has contributed nearly 450+ million
            dollars to local, regional, and international nonprofit
            organizations.`,
        description: 'Text shown in Sponsor section.',
    },
    interactiveSponsorButtonLabel: {
        id:'interactiveSponsorButtonLabel',
        defaultMessage: 'Amgen Foundation page',
        description: 'Button label for Amgen foundation sponsor section',
    },
    joinUsHeading: {
        id:'joinUsHeading',
        defaultMessage: 'Join the LabXchange Community',
        description: 'Heading for the Join Us section.',
    },
    promoVideoHeading: {
        id:'homePromoVideoHeading',
        defaultMessage: 'Start your journey in Science',
        description: 'Heading shown on the home page next to the promo video.'
    },
    promoVideoIsLoading: {
        id:'homePromoVideoIsLoading',
        defaultMessage: 'Video is loading',
        description: 'Text visible to screenreaders which is shown while the promo video is loading.',
    },
    promoVideoText: {
        id:'homePromoVideoText',
        defaultMessage: `Whether you're an educator, learner or science professional, it can be difficult to find the
time, resources, and support you need to reach your potential.
{linebreak}
Discover how LabXchange can help you
overcome these hurdles. We'll introduce you to the platform through the story of an educator.`,
        description: 'Text shown on the home page next to the promo video.',
    },
    roleHeading: {
        id:'roleHeading',
        defaultMessage: 'I\'m a {role}',
        description: 'Role selection prefix text.',
    },
    learnerRoleHeading: {
        id:'learnerRoleHeading',
        defaultMessage: 'learner',
        description: 'Learner role selection text.',
    },
    teacherRoleHeading: {
        id:'teacherRoleHeading',
        defaultMessage: 'teacher',
        description: 'Teacher role selection text.',
    },
    learnHeadingText :{
        id:'learnHeadingText',
        defaultMessage: 'Learn with labxchange',
        description: 'Learner heading text.',
    },
    learnValueText :{
        id:'learnValueText',
        defaultMessage: 'Whether it’s for school, university, or a career, LabXchange lets you learn in the way that works best for you. Explore thousands of free learning resources, save your favorites, and create a customized learning experience tailored to your specific goals.',
        description: 'Learner value text.',
    },
    teachHeadingText :{
        id:'teachHeadingText',
        defaultMessage: 'Teach with labxchange',
        description: 'Teach heading text.',
    },
    teachValueText :{
        id:'teachValueText',
        defaultMessage: 'Explore thousands of high-quality, ready-to-use teaching resources and find the perfect materials for your next lesson. Created at Harvard University with support from the Amgen Foundation, LabXchange makes science education accessible to everyone, everywhere—for free.',
        description: 'Teach value text.',
    },
    moreSubjectsText :{
        id:'moreSubjectsText',
        defaultMessage: 'More Subjects',
        description: 'More subjects button text.',
    },
    fetchInteractiveAssetsError :{
        id:'fetchInteractiveAssetsError',
        defaultMessage: 'Unable to load interactive assets.',
        description: 'Error when explore API fails.',
    },
    teachPageTitle :{
        id:'teachPageTitle',
        defaultMessage: 'Teach',
        description: 'Teach page title.',
    },
    testimonialsSliderHeading: {
        id:'testimonialsSliderHeading',
        defaultMessage: 'Testimonials slider',
        description: 'Testimonials slider heading.',
    },
    testimonialNumberText: {
        id:'testimonialNumberText',
        defaultMessage: 'Testimonial {testimonialNumber} of {totalTestimonials}',
        description: 'Testimonial number text.',
    },
    collaboratorAriaLabel :{
        id:'collaboratorAriaLabel',
        defaultMessage: 'See all sources',
        description: 'Expand button to see all collaborators',
    },
    testimonialScrollNextText :{
        id:'testimonialScrollNextText',
        defaultMessage: 'See testimonial {index} of {count}',
        description: 'Text for next button on testimonial carousel.',
    },
    testimonialScrollPrevText :{
        id:'testimonialScrollPrevText',
        defaultMessage: 'See previous testimonial',
        description: 'Text for prev button on testimonial carousel.',
    },
});

export default messages;
