import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ValueType } from 'react-select';

import {
  ExploreContent,
  ExploreSection,
  SectionSlab,
  SectionSlabAssetTypeEnum
} from 'labxchange-client';
import { StandardPageLayout, showErrorMessage } from 'ui/components';
import { ExploreContentsApi } from 'global/api';
import { history } from 'global/history';
import { ROUTES } from 'global/constants';
import { WrappedMessage } from 'utils';

import messages from '../../../displayMessages';
import ClassicCardsSlab from '../ClassicCardsSlab';
import PathwaysSlab from '../PathwaysSlab';
import InteractiveCardsSlab from '../InteractiveCardsSlab';
import ClustersSlab from '../ClustersSlab';
import FeatureCardSlab from './FeatureCardSlab';
import SkeletonSlabs from '../SkeletonSlabs';
import ContentPageHeader from './ContentPageHeader';
import { SelectDropdownOption } from '../types';
import { getSubjectOptionValue, getContentTypeOptionValue } from '../utils';

export const ContentPage: FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const subjectOpt = getSubjectOptionValue(pathSegments[pathSegments.length - 2]);
  const contentTypeOpt = getContentTypeOptionValue(pathSegments[pathSegments.length - 1], subjectOpt?.value);

  // Redirect to home page if the url is not valid
  if (!contentTypeOpt || !subjectOpt) {
    history.replace(ROUTES.Library.HOME);
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>(subjectOpt?.value || '');
  const [content, setContent] = useState<string>(contentTypeOpt?.value || '');
  const [sections, setSections] = useState<ExploreSection[]>([]);
  const [sectionSlabs, setSectionSlabs] = useState<SectionSlab[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (subject) {
        setLoading(true);
        try {
          const response: ExploreContent = await ExploreContentsApi.contentsRead({
            id: subject
          });
          const respSections = response?.exploreSections || [];
          setSections(respSections);
          if (content) {
            const matchedSection = respSections.find((s) => s.slug.includes(content));
            setSectionSlabs(matchedSection?.sectionSlabs || []);
          }
        } catch (error) {
          setSections([]);
          setSectionSlabs([]);
          showErrorMessage(
            <WrappedMessage message={messages.contentPageError} />
          );
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [subject]);

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubject = (selectedOption as SelectDropdownOption).value;
      setSubject(selectedSubject);
      history.replace(`/subject/${selectedSubject.replace('-content', '')}/${content}`);
    }
  };

  const onContentChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedContent = (selectedOption as SelectDropdownOption).value;
      const section = sections.find((s) => s.slug.includes(selectedContent));
      setContent(selectedContent);
      setSectionSlabs(section?.sectionSlabs || []);
      history.replace(`/subject/${subject.replace('-content', '')}/${selectedContent}`);
    }
  };

  const renderSlabs = () => {
    return (
      sectionSlabs
      .sort((a, b) => a.order - b.order)
      .map((slab: SectionSlab, index: number) => {
        if (slab.assetType === SectionSlabAssetTypeEnum.Interactives) {
          return (
            <InteractiveCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.Pathways) {
          return (
            <PathwaysSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.SimpleCards) {
          return (
            <ClassicCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.Clusters) {
          return (
            <ClustersSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.FeatureCard) {
          return (
            <FeatureCardSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        }
        return null;
      })
    );
  };

  const renderSkeleton = () => {
    return <SkeletonSlabs count={4} />;
  };

  return (
    <StandardPageLayout
        backgroundStyle='light-header'
        mainClassName='content-page'
        headerFeature={
          <ContentPageHeader
            loading={loading}
            onSubjectChange={onSubjectChange}
            onContentChange={onContentChange}
            subjectDefaultValue={subjectOpt}
            contentDefaultValue={contentTypeOpt}
            subject={subject}
          />
        }
    >
      {(loading || !sectionSlabs) ? renderSkeleton() : renderSlabs()}
    </StandardPageLayout>
  );
};
