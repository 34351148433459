import * as React from 'react';

import {RouteComponentProps, withRouter} from 'react-router';
import {StandardPageLayout} from 'ui/components';
import messages from 'notifications/displayMessages';
import {WrappedMessage} from 'utils';
import {NotificationList} from '../NotificationList';
import * as NotificationActions from '../../actions';
import { getNotificationList } from '../../selectors';


import {Notification} from 'labxchange-client';
import {connect} from 'react-redux';
import {RootState} from 'global/state';
import {LXThunkUnwrap} from 'global/types';


interface StateProps {
    notifications?: ReadonlyArray<Notification>;
}

interface ActionProps {
    markAllRead: typeof NotificationActions.markAllNotificationsRead;
    markRead: typeof NotificationActions.markNotificationRead;
    markUnread: typeof NotificationActions.markNotificationUnread;
}

interface Props extends StateProps, LXThunkUnwrap<ActionProps>, RouteComponentProps {
}


export class NotificationPageInternal extends React.Component<Props> {

    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.notificationsTitle}
                headerFeature={
                    <div className={'message-page-header-content container'}>
                        <h1><WrappedMessage message={messages.notificationsTitle} /></h1>
                    </div>
                }
            >
                <div className='notification-page'>
                    <NotificationList
                        markAllRead={this.props.markAllRead}
                        markRead={this.props.markRead}
                        markUnread={this.props.markUnread}
                        notifications={this.props.notifications}
                        hideDayLabels={false}
                    />
                </div>
            </StandardPageLayout>
        );
    }
}

export const NotificationPage = connect<StateProps, ActionProps, {}, RootState>(
    (state: RootState) => ({
        notifications: getNotificationList(state, 250),
    }), {
        markAllRead: NotificationActions.markAllNotificationsRead,
        markRead: NotificationActions.markNotificationRead,
        markUnread: NotificationActions.markNotificationUnread,
    },
)(withRouter(NotificationPageInternal));
