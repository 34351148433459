// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface MentorshipApplyByMentee
 */
export interface MentorshipApplyByMentee {
    /**
     * 
     * @type {string}
     * @memberof MentorshipApplyByMentee
     */
    mentor: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipApplyByMentee
     */
    pendingReason: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipApplyByMentee
     */
    pendingSubject: string;
    /**
     * 
     * @type {string}
     * @memberof MentorshipApplyByMentee
     */
    reasonDescription?: string;
}

export function MentorshipApplyByMenteeFromJSON(json: any): MentorshipApplyByMentee {
    return {
        'mentor': json['mentor'],
        'pendingReason': json['pending_reason'],
        'pendingSubject': json['pending_subject'],
        'reasonDescription': !exists(json, 'reason_description') ? undefined : json['reason_description'],
    };
}

export function MentorshipApplyByMenteeToJSON(value?: MentorshipApplyByMentee): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'mentor': value.mentor,
        'pending_reason': value.pendingReason,
        'pending_subject': value.pendingSubject,
        'reason_description': value.reasonDescription,
    };
}


