// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    readonly subjectId?: string;
}

export function SubjectFromJSON(json: any): Subject {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'subjectId': !exists(json, 'subject_id') ? undefined : json['subject_id'],
    };
}

export function SubjectToJSON(value?: Subject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
    };
}


