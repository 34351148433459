import * as React from 'react';
import * as BannerActions from 'banners/actions';
import bind from 'bind-decorator';
import messages from '../displayMessages';
import { AlertBox, AuthPageLayout, Button, showErrorMessage, showSuccessMessage } from 'ui/components';
import { connect } from 'react-redux';
import { getHasVerifiedEmail, getLoggedInStatus, getUserEmail } from 'auth/selectors';
import { intl } from 'i18n';
import { ProgressPercentageEnum, insertQueryParam, isLxLoginActive } from 'auth/utils';
import { RootState } from 'global/state';
import { ROUTES } from 'global/constants';
import { UsersApi } from 'global/api';
import { WrappedMessage } from 'utils';
import { history } from 'global/history';
import { LXThunkUnwrap } from 'global/types';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { RouteComponentProps } from 'react-router-dom';


enum StatusParam {
    Sending = 'sending', /// Use this param to send the email on render this page
    Success = 'success',
    Error = 'error',
}

interface State {
    resendCount: number;
    resendCounter?: any;
    firstSent: boolean; /// Used with StatusParam.Sending
    alertClassName: string;
}

interface ReduxStateProps {
    isLoggedIn: boolean;
    hasVerifiedEmail: boolean;
    userEmail: string;
}

interface ActionProps {
    dismissBanner: typeof BannerActions.dismissBanner;
}

interface ExternalProps {
    redirect?: boolean;
    countdownOnPageLoad?: boolean;
    onSuccess?: () => void;
    onSkip?: () => void;
    completeOnboarding: () => void;
}

type Props  = ExternalProps & ReduxStateProps & LXThunkUnwrap<ActionProps> & Partial<RouteComponentProps>;

export class ActivateAccountInternal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            resendCount: 0,
            firstSent: false,
            alertClassName: 'select-role-success-alert',
        };
    }

    public componentDidMount() {
        if(this.props.history)
            insertQueryParam(this.props.history, 'step', 'Activate');
        setTimeout(this.onCloseAlert, 4000);
        this.startCounter();
        // track activation success or failure
        if (this.hasParam(StatusParam.Success)) {
            analyticsInstance.track(EVENT_NAMES.AccountActivationSuccess, { url: window.location.toString() });
        } else if (this.hasParam(StatusParam.Error)) {
            analyticsInstance.track(EVENT_NAMES.AccountActivationInvalidLink, { url: window.location.toString() });
        }

        if (this.hasParam(StatusParam.Sending) && !this.state.firstSent) {
            // Used to send the email on first render
            this.setState({ firstSent: true }, this.resendEmail);
        } else if (this.hasParam(StatusParam.Success)) {
            // Used when the user opens a valid email activation link
            showSuccessMessage(
                <WrappedMessage message={messages.lxAuthEmailConfimationSuccess} />,
                { confirmText: messages.lxAuthEmailConfimationSuccessButtonLabel }
            );
            if (!this.props.isLoggedIn) {
                history.push(ROUTES.General.SIGN_IN);
            } else {
                history.push(ROUTES.Explore.HOME);
            }
        } else if (this.hasParam(StatusParam.Error) ) {
            // Used when the user opens an invalid email activation link
            if(!this.props.hasVerifiedEmail){
                if(!this.props.isLoggedIn){
                    showErrorMessage(<WrappedMessage message={messages.lxAuthInvalidConfirmation} />);
                    history.push(ROUTES.Explore.HOME);
                }else {
                    showErrorMessage(<WrappedMessage message={messages.lxAuthLoggedOUtInvalidConfirmation} />);
                }
            }
            else {
                history.push(ROUTES.Explore.HOME);
            }
        }
    }

    public render() {
        const { userEmail }  = this.props;
        const emailOpenLink = userEmail.includes('@gmail') ? `https://mail.google.com/mail/u/${userEmail}/#search/from%3A(no-reply@labxchange.org)+in%3Aanywhere+newer_than%3A1h` : undefined;
        if (!this.props.isLoggedIn || !isLxLoginActive() || this.props.hasVerifiedEmail) {
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
            if (this.props.redirect) {
                history.push(ROUTES.Explore.HOME);
            }
        }
        return (
            <AuthPageLayout
                imageUrl='/assets/images/auth-layouts/email-verification.svg'
                title={intl.formatMessage(messages.lxAuthActivateAccountTitle)}
                onClickLogo={this.props.completeOnboarding}
                onClickBackButton={this.handleBackButton}
                enableBackButton={window.location.pathname !== ROUTES.General.ONBOARDING}
                progressPercentage={ProgressPercentageEnum.EmailActivation}
            >
                {!this.state.firstSent &&
                  <AlertBox
                    className={this.state.alertClassName}
                    type='success'
                    bodyMessage={messages.lxAuthAccountCreatedMsg}
                    showCloseIcon={true}
                    onClose={this.onCloseAlert}
                  />
                }
                <div className='help-text'>
                    <WrappedMessage
                        message={messages.lxAuthActivateAccountLabelText}
                        values={{link: <a target='_blank' rel='noopener noreferrer' href='https://labxchange.zendesk.com/hc/en-us/articles/360056398994-Activate-Your-Account'>
                                <WrappedMessage message={messages.lxAuthActivateAccountLabelLink}/></a>}}
                    />
                </div>

                <div className='auth-activate-account'>
                    <span className='description'>
                        <span className='email-message'>
                            <WrappedMessage message={messages.lxAuthActivateAccountEmailMsg} values={{ email: this.props.userEmail}} />
                        </span>

                        <br />
                       <span>
                            <WrappedMessage message={messages.lxAuthActivateAccountMsg} />
                       </span>
                    </span>
                    <div className='activation-buttons'>
                        {emailOpenLink &&
                            <Button
                                className='gmail-inbox-button'
                                label={messages.lxAuthCheckInbox}
                                icon='gmail'
                                iconPosition='left'
                                onClick={() => this.handleCheckYourInboxClick(emailOpenLink)}
                            />
                        }
                        {
                            this.state.resendCount > 0
                                ? <Button
                                    disabled={true}
                                    className='resend-button'
                                    size='sm'
                                    label={messages.lxAuthActivateAccountResendEmailWithCountButton}
                                    labelValues={{ count: this.state.resendCount }}
                                    onClick={this.resendEmail} />
                                : <Button size='sm' className='resend-button active' label={messages.lxAuthActivateAccountResendEmailButton} onClick={this.resendEmail} />
                        }
                        <Button
                            className='skip-button'
                            label={messages.lxAuthSkipActivateEmailButton}
                            btnStyle='link'
                            onClick={this.onSkip}
                        />
                    </div>
                </div>
            </AuthPageLayout>
        );
    }

    @bind private handleCheckYourInboxClick(inboxLink: string) {
        analyticsInstance.track(EVENT_NAMES.OnboardingCheckYourInboxClicked, { url: window.location.toString() });
        window.open(inboxLink, '_blank');
    }

    @bind private onSkip() {
        analyticsInstance.track(EVENT_NAMES.AccountActivationSkipped, { url: window.location.toString() });
        if (this.props.onSkip) {
            this.props.onSkip();
        }
        else {
            /// We redirect to Home and dismiss the activate account banner
            this.props.dismissBanner({ id: BannerActions.ActivateEmailBannerId, content: ''});
            history.push(ROUTES.All.HOME);
        }
    }

    private resetResendCounter() {
        if (this.state.resendCounter) { clearInterval(this.state.resendCounter); }
        this.setState({ resendCounter: undefined, resendCount: 0 });
    }

    private startCounter() {
        this.setState({ resendCount: 60 }, () => {
            const interval = setInterval(() => {
                if (this.state.resendCount > 0) {
                    this.setState({ resendCount: this.state.resendCount - 1 });
                } else {
                    this.resetResendCounter();
                }
            }, 1000);
            this.setState({ resendCounter: interval });
        });
    }

    @bind private async resendEmail() {
        analyticsInstance.track(EVENT_NAMES.AccountActivationResendEmail, { url: window.location.toString() });
        try {
            await UsersApi.resendEmailConfirmation();
            showSuccessMessage(<WrappedMessage message={messages.lxAuthEmailConfirmationSended} />);
        }
        catch (error) {
            if (error.status !== 429) {
                showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToSendEmailConfirmation} />);
                // Do nothing  if error === 429 as email is recently sent and user is being displayed message in background about waiting.
            }
        }
        this.resetResendCounter();
        this.startCounter();
    }

    private hasParam(param: string) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.has(param);
    }

    @bind private handleBackButton () {
        history.push(ROUTES.All.HOME);
    }

    @bind private onCloseAlert() {
        this.setState({ alertClassName: 'select-role-success-alert out' });
    }
}
export const ActivateAccount = connect<ReduxStateProps, ActionProps, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        hasVerifiedEmail: getHasVerifiedEmail(state),
        userEmail: getUserEmail(state),
    }), {
        dismissBanner: BannerActions.dismissBanner,
    },
)(ActivateAccountInternal);
