import { defineMessages } from 'react-intl';

const messages = defineMessages({
    backToDashboard: {
        id:'backToDashboard',
        defaultMessage: 'Back to learner dashboard',
        description: 'Text on button for navigating back to learner dashboard (mentors tab) from an item page.',
    },
    backToEducatorDashboard: {
        id:'backToEducatorDashboard',
        defaultMessage: 'Back to educator dashboard',
        description: 'Text on button for navigating back to educator dashboard (mentees tab) from an item page.',
    },
    backToClass: {
        id:'backToClass',
        defaultMessage: 'Back to class',
        description: 'Text on button for navigating back to classroom page from an item page.',
    },
    backToBook: {
        id:'backToBook',
        defaultMessage: 'Back to textbook',
        description: 'Text on button for navigating back to textbook home page from an item page.',
    },
    backToCluster: {
        id: 'backToCluster',
        defaultMessage: 'Back to cluster',
        description: 'Text on button for navigating back to cluster home page from an item page.',
    },
});

export default messages;
