import { getLoggedInStatus } from 'auth/selectors';
import { bind } from 'bind-decorator';
import { AnalyticsApi, ItemsApi, XBlocksApi } from 'global/api';
import { RootState } from 'global/state';
import {
    AnalyticsEventEventTypeEnum, SaveUserStateVideo, VideoDataState
} from 'labxchange-client';
import { createAssetViewedEventForItem } from 'library/components/Block/utils';
import { VideoPlayer, VideoPlayerStyle } from 'library/components/VideoPlayer';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { showErrorMessage } from 'ui/components';
import { WrappedMessage } from 'utils';
import videojs from 'video.js';
import messages from '../../displayMessages';
import { BlockProps } from './models';

export interface VideoBlockState {
    blockState?: VideoDataState;
    isLoading: boolean;
    videoViewed: boolean;
}

export interface VideoBlockProps {
    playingHandler?(): boolean;
    timeUpdateHandler?(videoPosition: number): boolean;
    onPlayerReady?(player: videojs.Player): void;
    onComponentReady?(componentRef: React.RefObject<VideoPlayer>): void;
    videoPlayerStyle?: VideoPlayerStyle;
    youtubeUrl?: string;
    hideTranscripts?: boolean;
    showPlayerRangePaddles?: boolean;
    showQuestionPaddle?: boolean;
    onChangeRangePaddleTime?(isLeft:boolean, newTime: number): number;
    videoDataAndState?: VideoDataState;
    showCustomControlsOnVimeo?: boolean;
    resumeVideoFromSavedPosition?: boolean;
}

// Describe EncodedVideo interface
interface EncodedVideo {
    youtube?: {
        url?: string;
    };
    fallback?: {
        url?: string;
    };
}

const VimeoURLRegex = /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
const YouTubeURLRegex = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;

function getVideoType(url: string) {
    if (YouTubeURLRegex.test(url)) {
        return 'video/youtube';
    }
    if (VimeoURLRegex.test(url)) {
        return 'video/vimeo';
    }
    return 'application/x-mpegURL';
}

function getVideoURL(encodedVideos: EncodedVideo) {
    let src = '';
    if (encodedVideos.youtube) {
        src = encodedVideos.youtube.url || '';
    } else if (encodedVideos.fallback) {
        src = encodedVideos.fallback.url || '';
    }
    return src;
}

interface ReduxStateProps {
    isLoggedIn: boolean;
}

class VideoBlockInternal extends React.PureComponent<
    VideoBlockProps & BlockProps & ReduxStateProps,
    VideoBlockState> {
    private videoPlayer: React.RefObject<VideoPlayer>;

    constructor(props: VideoBlockProps & BlockProps & ReduxStateProps) {
        super(props);
        this.state = {
            isLoading: true,
            videoViewed: false,
        };
        this.videoPlayer = React.createRef();
    }

    componentDidMount() {
        this.fetchVideoXBlockData();
        if (this.props.onComponentReady) { this.props.onComponentReady(this.videoPlayer); }
    }

    public render() {
        let nextUrl = '';
        if (this.props.isPathwayChild && this.props.pathwayItems && this.props.pathwayMetadata && this.props.currentItemIndex !== undefined) {
            const index = this.props.currentItemIndex;
            nextUrl = detailUrlForEntity(this.props.pathwayItems[index + 1], this.props.pathwayMetadata.id);
        }
        if (this.state.isLoading) { return null; }
        const playerTranscript = (this.state.blockState
            && this.state.blockState.transcripts) || [];
        const playbackRate = (this.state.blockState && this.state.blockState.speed) || 1;
        const savedVideoPosition = (this.state.blockState
            && this.state.blockState.savedVideoPosition) || 0;
        return <VideoPlayer
            ref={this.videoPlayer}
            style={this.props.videoPlayerStyle}
            itemMetadata={this.props.itemMetadata}
            sources={this.getSources()}
            playerTranscripts={playerTranscript}
            playbackRate={playbackRate}
            savedVideoPosition={savedVideoPosition}
            resumeVideoFromSavedPosition={this.props.resumeVideoFromSavedPosition}
            timeUpdateHandler={this.saveVideoPosition}
            rateChangeHandler={this.videoSpeedChanged}
            pauseHandler={this.pauseEvent}
            endedHandler={this.publishCompletion}
            playingHandler={this.playingEvent}
            videoPlaybackWindow={this.props.blockData.videoPlaybackWindow}
            nextUrl={nextUrl}
            onPlayerReader={this.props.onPlayerReady}
            hideTranscripts={this.props.hideTranscripts}
            showRangePaddles={this.props.showPlayerRangePaddles}
            showQuestionPaddle={this.props.showQuestionPaddle}
            onChangeRangePaddleTime={this.props.onChangeRangePaddleTime}
            videoBlockState={this.state.blockState}
            showCustomControlsOnVimeo={this.props.showCustomControlsOnVimeo}
        />;
    }

    /**
     * Fetch video data to render player
     */
    private async fetchVideoXBlockData() {
        if (!this.props.itemMetadata.id) { this.setState({isLoading: false}); return; }
        let result;
        if (this.props.videoDataAndState) {
            result = this.props.videoDataAndState;
        } else {
            try {
                result = await XBlocksApi.videoDataAndState({id: this.props.itemMetadata.id});
            } catch (error) {
                showErrorMessage(<WrappedMessage message={messages.errorLoadingVideo} />, {
                    exception: error,
                });
                return;
            }
        }
        this.setState({ blockState: result, isLoading: false });
    }

    /**
     * Xblock API request to save user state
     */
    private xBlocksApiSaveUserState(userState: SaveUserStateVideo) {
        if (!this.props.itemMetadata.id) { return; }
        XBlocksApi.saveUserVideoState({ id: this.props.itemMetadata.id, data: userState});
    }

    private doAnalytics() {
        return this.props.itemMetadata.id;
    }

    @bind public playingEvent() {
        const doAnalytics = this.doAnalytics();
        if (this.props.playingHandler) {
            const result = this.props.playingHandler();
            if (!result) { return; }
        }
        if (doAnalytics && this.state.videoViewed === false) {
            createAssetViewedEventForItem(this.props.itemMetadata.id);
            this.setState({
                videoViewed: true,
            });
        }
        if (doAnalytics) {
            AnalyticsApi.create({
                data: {
                    eventType: AnalyticsEventEventTypeEnum.VideoAssetPlayed,
                    objectId: this.props.itemMetadata.id,
                },
            });
        }
    }

    @bind pauseEvent(videoPosition: number) {
        if (this.doAnalytics()) {
            AnalyticsApi.create({
                data: {
                    eventType: AnalyticsEventEventTypeEnum.VideoAssetPaused,
                    objectId: this.props.itemMetadata.id
                }
            });
        }
        this.saveVideoPosition(videoPosition);
    }

    @bind public saveVideoPosition(videoPosition: number) {
        if (this.props.timeUpdateHandler) {
            const result = this.props.timeUpdateHandler(videoPosition);
            if (!result) { return; }
        }
        this.xBlocksApiSaveUserState({ savedVideoPosition: videoPosition });
    }

    @bind public videoSpeedChanged(videoSpeed: number) {
        this.xBlocksApiSaveUserState({ speed: videoSpeed });
    }

    @bind private publishCompletion() {
        if (this.props.isLoggedIn) {
            ItemsApi.markComplete({id: this.props.itemMetadata.id});
        }
    }

    @bind public getSources() {
        if (!this.state.blockState) {
            if (this.props.youtubeUrl) {
                return [{
                    src: this.props.youtubeUrl,
                    type: getVideoType(this.props.youtubeUrl),
                }];
            }
            return [];
        }
        const videoURL = getVideoURL(this.state.blockState.encodedVideos);
        const videoType = getVideoType(videoURL);
        return  [{ src: videoURL, type: videoType }];
    }
}

export const VideoBlock = connect<ReduxStateProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(VideoBlockInternal);
