// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomsGeneralPermissions
 */
export interface ClassroomsGeneralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomsGeneralPermissions
     */
    canCreateClass: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomsGeneralPermissions
     */
    canJoinClass: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomsGeneralPermissions
     */
    canEditClassDiscussions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomsGeneralPermissions
     */
    canDeleteClassDiscussions: boolean;
}

export function ClassroomsGeneralPermissionsFromJSON(json: any): ClassroomsGeneralPermissions {
    return {
        'canCreateClass': json['can_create_class'],
        'canJoinClass': json['can_join_class'],
        'canEditClassDiscussions': json['can_edit_class_discussions'],
        'canDeleteClassDiscussions': json['can_delete_class_discussions'],
    };
}

export function ClassroomsGeneralPermissionsToJSON(value?: ClassroomsGeneralPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_create_class': value.canCreateClass,
        'can_join_class': value.canJoinClass,
        'can_edit_class_discussions': value.canEditClassDiscussions,
        'can_delete_class_discussions': value.canDeleteClassDiscussions,
    };
}


