import { ThreadPost as ThreadPostElement, ThreadWithPosts, Thread as ThreadElement } from 'labxchange-client';
import * as React from 'react';
import { ThreadPost } from '../ThreadPost';

export interface ThreadProps {
    thread: ThreadWithPosts;
    loggedInUsername: string;
    anchoredPost?: string;
    hideFirstPost?: boolean;
    getPostPermalink(postId: number): string;
    onReply(postId: number): void;
    onEdit(postId: number): void;
    onDelete(postId: number, isTopic: boolean): void;
    canEdit(): boolean;
    canDelete(post: ThreadPostElement, isTopic: boolean): boolean;
    reversePosts?: boolean;
}

export class Thread extends React.PureComponent<ThreadProps> {
    public static defaultProps = {
        hideFirstPost: false,
        reversePosts: false,
    };

    public componentDidUpdate() {
        this.scrollToPost();
    }

    public render() {
        const thread = this.props.thread;
        const replies: {[id: number]: ThreadPostElement[]} = {};
        thread.posts.map((post: ThreadPostElement) => {
            if (post.replyTo) {
                if (replies[post.replyTo] === undefined) {
                    replies[post.replyTo] = [];
                }
                replies[post.replyTo].push(post);
            }
            return null;
        });
        const canEdit = this.props.canEdit();

        const [headLXPost, ...tailLXPosts] = thread.posts;
        const headPost = this.getThreadPost(thread.thread, headLXPost, replies, canEdit, true);
        const tailPosts = tailLXPosts.map((post: ThreadPostElement) => {
            return this.getThreadPost(thread.thread, post, replies, canEdit, false);
        });
        const posts = [headPost];
        if (this.props.reversePosts) {
            tailPosts.reverse();
        }

        posts.push(...tailPosts);
        return (<>{posts}</>);
    }

    private getThreadPost(
        thread: ThreadElement,
        post: ThreadPostElement,
        replies: {[id: number]: ThreadPostElement[]},
        canEdit: boolean,
        isTopic: boolean) {
        if (post.replyTo !== undefined) { return null; }

        const active = this.props.anchoredPost !== undefined && (
            this.props.anchoredPost === post.id.toString()
        );
        return (
            <ThreadPost
                key={`thread-post-${post.id}`}
                post={post}
                thread={thread}
                active={active}
                replies={replies[post.id]}
                isOriginalPost={isTopic}
                onReply={this.props.onReply}
                onEdit={this.props.onEdit}
                onDelete={this.props.onDelete}
                canEdit={canEdit}
                canDelete={this.props.canDelete(post, isTopic)}
                loggedInUsername={this.props.loggedInUsername!}
                    permalink={this.props.getPostPermalink(post.id)}
                    hideFirstPost={this.props.hideFirstPost}
                />
            );
    }

    private scrollToPost() {
        if (this.props.anchoredPost === undefined) { return; }
        const id = 'post-' + this.props.anchoredPost;
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }
}
