import {bind} from 'bind-decorator';
import * as React from 'react';

import {ContainerOne} from 'elements';
import messages from '../../displayMessages';
import {BlockPreview} from './BlockPreview';
import {BlockProps} from './models';
import {createAssetViewedEventForItem} from './utils';
import { Button, Icon} from 'ui/components';
import {ItemMetadataTypeEnum} from '../../../labxchange-client';

export class DocumentBlock extends React.PureComponent<BlockProps, {}> {
    public render() {
        return (
            <ContainerOne>
                <BlockPreview
                    previewImageUrl={this.props.itemMetadata.imageUrl}
                    button={
                        <>
                            {this.props.itemMetadata.type === ItemMetadataTypeEnum.Link ?
                              <Icon name='file-google-upload' className='icon-section' />
                                :  <Icon name='file-pdf-upload' className='icon-section' />
                            }
                            <Button
                                btnStyle='primary'
                                label={this.props.itemMetadata.type === ItemMetadataTypeEnum.Link ? messages.openGoogleDocLinkText : messages.blockDocumentShowLabel}
                                onClick={this.onShow}
                                icon='link-external'
                                iconPosition='left'
                            />
                        </>
                    }
                />
            </ContainerOne>
        );
    }

    @bind private async onShow() {
        if (this.props.blockData.studentViewData?.document_url) {
            // Show the pdf in a new tab; it's too slow and resource intensive to try to embed a pdfviewer.
            window.open(this.props.blockData.studentViewData.document_url, '_blank', 'noopener,noreferrer');
            createAssetViewedEventForItem(this.props.itemMetadata.id);
        }
    }
}
