// Add/edit resource modal
import * as React from 'react';
import { CardFauxAdd } from '../Card/Cards';
import { messages } from './displayMessages';
import {
    ItemMetadata,
    ResourceRelationship,
    ResourceRelationshipResourceCategoryEnum,
    ResourceRelationshipTargetItemTypeEnum
} from '../../../labxchange-client';
import { ContentPickerModal } from '../../../library/components';
import { Icon } from '../../../elements';
import { resourceTypes, WrappedMessage } from '../../../utils';

interface EditResourceProps {
    onClose: () => void;
    updateResources:  (resources: ResourceRelationship[]) => void;
    resourceToEdit?: ResourceRelationship;
    resourcesList?: ResourceRelationship[];
    editIndex?: number;
}

const EditResourceModal: React.FC<EditResourceProps> = ({ onClose, updateResources, resourceToEdit, resourcesList, editIndex }) => {

    const [showAssetPicker, setShowAssetPicker] = React.useState(false);

    const [isEditing, setIsEditing] = React.useState(false);

    const emptyResource: ResourceRelationship = {
        resourceCategory: ResourceRelationshipResourceCategoryEnum.Learner,
        targetItemId: '',
        isPrivate: false,
        title: '',
        targetItemTitle: '',
        targetItemType: ResourceRelationshipTargetItemTypeEnum.Link
    };

    const [newItem, setNewItem] = React.useState<ResourceRelationship>(emptyResource);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value, type } = e.target;
        let inputValue;
        if (type === 'checkbox') {
            inputValue = (e.target as HTMLInputElement).checked;
        } else {
            inputValue = value;
        }
        setNewItem({ ...newItem, [name]: inputValue });
    };

    const onSelectResource = async (item: ItemMetadata) => {
        // @ts-ignore
        setNewItem({ ...newItem, targetItemId: item.id, targetItemType: item.type, targetItemTitle: item.title });
        setShowAssetPicker(false);
    };

    const AddResources = async () => {
        if(newItem) {
            const updatedItems = resourcesList ?? [];
            if(editIndex !== undefined){
                updatedItems[editIndex] = newItem;
            } else {
               updatedItems.push(newItem);
            }
            updateResources(updatedItems);
            onClose();

        }
    };

    React.useEffect(() => {
        if(resourceToEdit){
             setIsEditing(true);
             setNewItem({...resourceToEdit});
        }
    }, [resourceToEdit]);

    return (
        <div className='popup-overlay'>
            <div className='resources-container rounded mb-3 popup-content'>
                <div className='popup-heading'>
                    <span>
                        {isEditing ? newItem.targetItemTitle : <WrappedMessage message={messages.modalResourceHeading} />}
                    </span>
                    <button onClick={onClose}>
                        <WrappedMessage message={messages.closeResourceModalActionText} />
                    </button>
                </div>

                <div className='p-3'>
                    <h2> {isEditing ?
                        <WrappedMessage message={messages.editResourcesHeading}/>
                        : <WrappedMessage message={messages.addNewResourcesHeading}/>
                    }</h2>
                    {newItem.targetItemTitle ?
                        <div className='selected-resource-info'>
                            <span>
                                  {newItem.targetItemTitle}
                            </span>
                            <span>
                                   <button
                                       className='unstyled tooltip-button'
                                       onClick={() => setNewItem({...newItem, targetItemId: '', targetItemType: ResourceRelationshipTargetItemTypeEnum.Link, targetItemTitle: ''})}
                                   >
                                        <Icon name='pencil' zoom='18' />
                                   </button>
                            </span>

                        </div> :
                        <div className='mb-4'>
                            <CardFauxAdd
                                message={isEditing ? messages.editResourceTitle :messages.attachResourceTitle}
                                onClick={() => setShowAssetPicker(true)}
                            />
                        </div>
                    }

                    <div>
                        <div className='form-group'>
                            <label htmlFor='resourceCategory' className='label'>
                                <WrappedMessage
                                    message={messages.resourceCategoryLabel}
                                />
                            </label>
                            <select
                                name='resourceCategory'
                                value={newItem?.resourceCategory}
                                onBlur={handleChange}
                                onChange={handleChange}
                                className='item'
                                id='resourceCategory'
                            >
                                <>
                                    {resourceTypes.map(resourceType => (
                                        <option value={resourceType.value} key={resourceType.name}>
                                            {resourceType.name}
                                        </option>
                                    ))}
                                </>
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className='form-group'>
                            <label htmlFor='title'>
                                <WrappedMessage
                                    message={messages.overrideTitleLabel}
                                />
                            </label>
                            <input
                                id='title'
                                type='text'
                                name='title'
                                value={newItem.title}
                                onChange={handleChange}
                                className='item'
                            />
                        </div>
                    </div>
                    <div>
                        <div className='form-group'>
                            <label htmlFor='isPrivate'>
                                <WrappedMessage
                                    message={messages.isEducatorOnlyLabel}
                                />
                            </label>
                            <input
                                id='isPrivate'
                                type='checkbox'
                                name='isPrivate'
                                checked={Boolean(newItem.isPrivate)}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className='popup-footer'>
                    <button onClick={AddResources} className='lx-btn-primary' disabled={!newItem.targetItemId || !newItem.title}>
                        <WrappedMessage message={messages.saveResourceButtonText} />
                    </button>
                </div>

                {showAssetPicker &&
                  <ContentPickerModal
                    onClose={() => setShowAssetPicker(false)}
                    multipleSelect={false}
                    onItemAdd={(item) => onSelectResource(item)}
                  />
                }
            </div>
        </div>

    );
};

export default EditResourceModal;