// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Ability
 */
export interface Ability {
    /**
     * 
     * @type {number}
     * @memberof Ability
     */
    readonly id?: number;
    /**
     * The title of the ability.
     * @type {string}
     * @memberof Ability
     */
    title: string;
    /**
     * Description of the ability.
     * @type {string}
     * @memberof Ability
     */
    description: string;
}

export function AbilityFromJSON(json: any): Ability {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': json['description'],
    };
}

export function AbilityToJSON(value?: Ability): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
    };
}


