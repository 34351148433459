import { bind } from 'bind-decorator';
import moment from 'moment-timezone';
import * as React from 'react';

import caseStudyColoredIconUrl from 'assets/images/case-study/case-study-colored.svg';
import teachingGuideColoredIconUrl from 'assets/images/teaching-guide/teaching-guide-colored.svg';

import {
    ContainerOne,
    CopyLinkButton,
} from 'elements';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import {
    BlockOverview,
    BlockOverviewOutlineItem,
    BlockOverviewSidebar,
} from './BlockOverview';
import { CaseStudyLikeContentView } from './CaseStudyLikeContentView';
import {
    BlockProps,
} from './models';
import { ItemsApi } from 'global/api';
import { editApiResponseToEditorState } from 'library/components/BlockEditor/serializers';
import {
    CaseStudyEditorState,
    CaseStudySection,
    TeachingGuideEditorState,
    XBlockType,
} from 'library/components/BlockEditor/models';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { ItemType } from 'items/models';
import { createAssetViewedEventForItem } from './utils';
import { intl } from 'i18n';

interface BlockState {
    showOverview: boolean;
    selectedSectionIndex: number;
    editorState?: Readonly<CaseStudyEditorState|TeachingGuideEditorState>;
}

export enum Variant {
    CaseStudy,
    TeachingGuide,
}

const getColoredIconUrl = (variant: Variant): string => {
    switch (variant) {
        case Variant.CaseStudy: return caseStudyColoredIconUrl;
        case Variant.TeachingGuide: return teachingGuideColoredIconUrl;
    }
};

export class CaseStudyBlock extends React.PureComponent<BlockProps, BlockState> {
    variant: Variant;
    private contentRef: React.RefObject<HTMLDivElement>;
    private markCompleteTimer: ReturnType<typeof window.setTimeout>|null;

    constructor(props: BlockProps) {
        super(props);
        this.contentRef = React.createRef();
        this.state = {
            showOverview: true,
            selectedSectionIndex: 0,
        };
        this.variant = Variant.CaseStudy;
        this.markCompleteTimer = null;
    }

    public componentDidMount() {
        this.loadData();
        window.addEventListener('scroll', this.onScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
        if (this.markCompleteTimer !== null) {
            window.clearTimeout(this.markCompleteTimer);
        }
    }

    private async loadData() {
        try {
            // Load the XBlock edit data from the API, if available:
            const dataRaw = await ItemsApi.editXblock({
                id: this.props.itemMetadata.id,
                blockKey: this.props.itemMetadata.id
            });
            // NOTE, XXX: parsing as json here is needed because of GenericObjectSerializer.
            const data = JSON.parse(dataRaw);
            // Load data from backend, then:
            let variant: XBlockType;
            if (this.variant === Variant.CaseStudy) {
                variant = XBlockType.CaseStudy;
            } else {
                variant = XBlockType.TeachingGuide;
            }
            const editorState = editApiResponseToEditorState(variant, data) as CaseStudyEditorState;
            this.setState({
                editorState,
            });
        } catch (err) {
            let errorMsg;
            if (this.variant === Variant.CaseStudy) {
                errorMsg = messages.errorLoadingCaseStudy;
            } else {
                errorMsg = messages.errorLoadingTeachingGuide;
            }
            showErrorMessage(
                <WrappedMessage message={errorMsg} />,
                {exception: err}
            );
        }
    }

    public render() {
        if (this.state.showOverview) {
            return this.renderOverview();
        } else {
            return this.renderContent();
        }
    }

    private renderOverview() {
        let startLabel;
        if (this.variant === Variant.CaseStudy) {
            startLabel = messages.blockCaseStudyStartLabel;
        } else {
            startLabel = messages.blockTeachingGuideStartLabel;
        }
        return (
            <div>
                <BlockOverview
                    iconUrl={getColoredIconUrl(this.variant)}
                    startButtonTitleDescriptor={startLabel}
                    onStartButtonClick={this.onStart}
                    outlineItems={this.outlineItems()}
                    theme='case-study'
                />
                <div className='case-study-reference-container'>
                    <ContainerOne>
                        <div className='case-study-reference-header'>Reference</div>
                        <div className='case-study-reference-content'>
                            <p>{this.fullReference()}
                                <CopyLinkButton
                                    title={intl.formatMessage(messages.blockCaseStudyCopyFullReferenceLabel)}
                                    value={this.fullReference()}
                                />
                            </p>
                            <p>{this.inTextReference()}
                                <CopyLinkButton
                                    title={intl.formatMessage(messages.blockCaseStudyCopyInTextReferenceLabel)}
                                    value={this.inTextReference()}
                                />
                            </p>
                        </div>
                    </ContainerOne>
                </div>
            </div>
        );
    }

    private renderContent() {
        let variant;
        if (this.variant === Variant.CaseStudy) {
            variant = ItemType.CaseStudy;
        } else {
            variant = ItemType.TeachingGuide;
        }
        return (
            <div className={`block-expanded-container block-${this.props.itemMetadata.type}-expanded`}>
                <div className='block-expanded-sidebar block-case-study d-none d-md-block col-lg-3'>
                    <BlockOverviewSidebar
                        itemType={variant}
                        backButtonTitleDescriptor={messages.blockOverviewBackLabel}
                        onBackButtonClick={this.onBack}
                        onItemClick={this.onItemClick}
                        outlineItems={this.outlineItems()}
                        theme='case-study'
                    />
                </div>
                <div ref={this.contentRef} className='block-expanded-content col-12 col-md-9'>
                    {this.state.editorState &&
                        <CaseStudyLikeContentView
                            editorState={this.state.editorState}
                        />
                    }
                </div>
            </div>
        );
    }

    private outlineItems(): BlockOverviewOutlineItem[] {
        const studentViewData = this.props.blockData.studentViewData;
        if (studentViewData.sections) {
            return (studentViewData.sections as CaseStudySection[]).map((section, index) => {
                return {
                    id: index.toString(),
                    title: section.title,
                    selected: (this.state.showOverview === false && this.state.selectedSectionIndex === index),
                };
            });
        }
        return [];
    }

    private fullReference() {

        const itemMetadata = this.props.itemMetadata;
        let reference: string = '';

        const formattedNames: string[] = [];
        itemMetadata.authors.forEach((author) => {
            const components = author.fullName.trim().split(' ');
            if (components.length === 1) {
                formattedNames.push(components[components.length - 1] + '.');
            } else if (components.length > 1) {
                // LastName, F. (where F is first character of first name).
                formattedNames.push(components[components.length - 1] + ', ' + components[0].substring(0, 1) + '.');
            }
        });

        reference = formattedNames.join(', ');

        if (itemMetadata.uploadedDate) {
            reference = reference + ' ' + itemMetadata.uploadedDate.getFullYear() + '.';
        }

        if (itemMetadata.title) {
            reference = reference + ' ' + itemMetadata.title + '.';
        }

        reference = reference + ' Available at: ' + window.location.href;
        reference = reference + ' [Accessed ' + moment().format('D MMM. YYYY') + ']';

        return reference;
    }

    private inTextReference() {

        const itemMetadata = this.props.itemMetadata;
        let authorName = '';

        const author = this.props.itemMetadata.authors[0];
        if (author) {
            const components = author.fullName.trim().split(' ');
            if (components.length === 1) {
                authorName = components[components.length - 1];
            } else if (components.length > 1) {
                // LastName, F (where F is first character of first name).
                authorName = components[components.length - 1] + ', ' + components[0].substring(0, 1);
            }
        }

        let uploadedDate = '';
        if (itemMetadata.uploadedDate) {
            uploadedDate = itemMetadata.uploadedDate.getFullYear().toString();
        }
        return `(${authorName} et al, ${uploadedDate})`;
    }

    @bind private async onStart() {
        this.setState({
            showOverview: false,
        });
        this.props.onExpandBlock(true);
        window.scrollTo(0, 0);
        createAssetViewedEventForItem(this.props.itemMetadata.id, false);

        // mark as completed 30 seconds after started, if still on page
        this.markCompleteTimer = setTimeout(() => {
            ItemsApi.markComplete({id: this.props.itemMetadata.id});
        }, 30_000);
    }

    @bind private async onBack() {
        window.scrollTo(0, 0);
        this.setState({
            showOverview: true,
        });
        this.props.onExpandBlock(false);
    }

    // Handler to update the sidebar highlighted header as you scroll.
    @bind private onScroll() {
        if (this.state.showOverview || !this.contentRef.current) {
            return;
        }
        const contentEl = this.contentRef.current;
        const sectionElements = contentEl.querySelectorAll('.section');
        if (sectionElements.length > 0) {
            let index = 0;
            for (let i = sectionElements.length - 1; i > 0; i--) {
                const element = sectionElements[i];
                const elementRect = element.getBoundingClientRect();
                if (elementRect.top <= 42) {  // 42 == section heading top margin
                    index = i;
                    break;
                }
            }
            this.setState({
                selectedSectionIndex: index,
            });
        }

    }

    @bind private async onItemClick(index: number) {
        if (this.state.showOverview || !this.contentRef.current) {
            return;
        }

        const el = this.contentRef.current.querySelector(`.section:nth-child(${index + 1})`);
        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
    }
}


export class TeachingGuideBlock extends CaseStudyBlock {
    constructor(props: BlockProps) {
        super(props);
        this.variant = Variant.TeachingGuide;
    }
}
