import { defineMessages } from 'react-intl';

const messages = defineMessages({
    organizationDashboard: {
        id:'organizationDashboard',
        defaultMessage: '{organizationName} Dashboard ',
        description: 'Title for an organization dashboard home page.',
    },
    uiNavTabContent: {
        id:'uiNavTabContent',
        defaultMessage: 'Content',
        description: 'Title for Content tab',
    },
    uiNavTabClasses: {
        id:'uiNavTabClasses',
        defaultMessage: 'Classes',
        description: 'Title for Classes tab',
    },
    uiNavTabPathway: {
        id:'uiNavTabMyPathway',
        defaultMessage: 'My Pathways',
        description: 'Title for Pathways tab',
    },
    uiNavTabProgress: {
        id:'uiNavTabProgress',
        defaultMessage: 'Progress',
        description: 'Title for Progress tab',
    },
    uiNavTabBadges: {
        id:'uiNavTabBadges',
        defaultMessage: 'Badges',
        description: 'Title for Badges tab',
    },
    uiNavTabMentors: {
        id:'uiNavTabMentors',
        defaultMessage: 'Mentors',
        description: 'Title for Mentors tab',
    },
    uiNavDropdownDashboardLink: {
        id:'uiNavDropdownDashboardLink',
        defaultMessage: 'Dashboard ',
        description: 'Title for Dashboard home page.',
    },
    uiNavTabMyContent: {
        id:'uiNavTabMyContent',
        defaultMessage: 'My content',
        description: 'Title for Content tab',
    },
    uiNavTabOrganizationContent: {
        id:'uiNavTabOrganizationContent',
        defaultMessage: '{organizationName} Content',
        description: 'Title for Organization content tab',
    },
    uiNavTabOrganizationPeople: {
        id:'uiNavTabOrganizationPeople',
        defaultMessage: 'People',
        description: 'Title for Organization people tab',
    },
    uiNavTabOrganizationProfileSettings: {
        id:'uiNavTabOrganizationProfileSettings',
        defaultMessage: 'Profile Settings',
        description: 'Title for Organization profile settings tab',
    },
    uiNavTabOrganizationProfile: {
        id:'uiNavTabOrganizationProfile',
        defaultMessage: 'Public Profile',
        description: `Link from an organization's dashboard to the org's public profile page.`,
    },
    uiNavTabOrganizationSources: {
        id:'uiNavTabOrganizationSources',
        defaultMessage: 'Sources',
        description: 'Title for Organization content sources tab',
    },
    uiNavTabFavorites: {
        id:'uiNavTabFavorites',
        defaultMessage: 'Favorites',
        description: 'Title for Favorites tab',
    },
    uiNavTabMentees: {
        id:'uiNavTabMentees',
        defaultMessage: 'Mentees',
        description: 'Title for Mentees tab',
    },
});

export default messages;
