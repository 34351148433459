import bind from 'bind-decorator';
import * as React from 'react';

import { intl } from 'i18n';

import messages from './displayMessages';

export interface LanguageSelectProps {
    value: string;
    onUpdate: (language: string) => void;
    disabledLanguages?: string[];
    prompt?: boolean;
}

/**
 * This selector selects from the huge list of languages.
 * Currently only used for the transcript editor.
 */
export class TranscriptLanguageSelector extends React.PureComponent<LanguageSelectProps> {

    public render() {
        const disabledLanguages = this.props.disabledLanguages || [];

        const languages = Object.keys(languageNames).map((lang) => {
            return (
                <option key={lang} value={lang} disabled={disabledLanguages.includes(lang)}>
                    {languageNames[lang]}
                </option>
            );
        });

        if (this.props.prompt) {
            languages.unshift(
                <option key='prompt' value='' disabled>
                    {intl.formatMessage(messages.languageSelectPrompt)}
                </option>
            );
        }

        return (
            <select
                key='prompt'
                className='form-control lx-select'
                value={this.props.value}
                onBlur={this.onUpdate}
                onChange={this.onUpdate}
                aria-label={intl.formatMessage(messages.languageSelectLabel)}>
                {languages}
            </select>
        );
    }

    @bind private onUpdate(evt: React.ChangeEvent<HTMLSelectElement>) {
        this.props.onUpdate(evt.currentTarget.value);
    }
}

/**
 * English name for each language
 * @author Phil Teare
 * using wikipedia data
 *
 * These codes need to also be present in edx-platform settings.ALL_LANGUAGES, otherwise transcripts will not display.
 */
const languageNames: {[key: string]: string } = {
    en: 'English',
    aa: 'Afar',
    ab: 'Abkhaz',
    ae: 'Avestan',
    af: 'Afrikaans',
    ak: 'Akan',
    am: 'Amharic',
    an: 'Aragonese',
    ar: 'Arabic',
    as: 'Assamese',
    av: 'Avaric',
    ay: 'Aymara',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    be: 'Belarusian',
    bm: 'Bambara',
    bn: 'Bengali',
    bh: 'Bihari',
    bi: 'Bislama',
    bs: 'Bosnian',
    br: 'Breton',
    bg: 'Bulgarian',
    my: 'Burmese',
    ca: 'Catalan; Valencian',
    ch: 'Chamorro',
    ce: 'Chechen',
    ny: 'Chichewa; Chewa; Nyanja',
    zh: 'Chinese',
    // This is purposefully zh_HANS, because that is what edx-platform uses.
    'zh_HANS': 'Chinese (Simplified)',
    cv: 'Chuvash',
    kw: 'Cornish',
    co: 'Corsican',
    cr: 'Cree',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    dv: 'Divehi; Dhivehi; Maldivian;',
    ee: 'Ewe',
    eo: 'Esperanto',
    et: 'Estonian',
    eu: 'Basque',
    fo: 'Faroese',
    fj: 'Fijian',
    fi: 'Finnish',
    fr: 'French',
    ff: 'Fula; Fulah; Pulaar; Pular',
    gl: 'Galician',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek, Modern',
    gn: 'Guaraní',
    gu: 'Gujarati',
    ht: 'Haitian; Haitian Creole',
    ha: 'Hausa',
    he: 'Hebrew (modern)',
    hz: 'Herero',
    hi: 'Hindi',
    ho: 'Hiri Motu',
    hu: 'Hungarian',
    hy: 'Armenian',
    ia: 'Interlingua',
    id: 'Indonesian',
    ie: 'Interlingue',
    ga: 'Irish',
    ig: 'Igbo',
    ik: 'Inupiaq',
    io: 'Ido',
    is: 'Icelandic',
    it: 'Italian',
    iu: 'Inuktitut',
    ja: 'Japanese',
    jv: 'Javanese',
    kl: 'Kalaallisut, Greenlandic',
    kn: 'Kannada',
    kr: 'Kanuri',
    ks: 'Kashmiri',
    kk: 'Kazakh',
    km: 'Khmer',
    ki: 'Kikuyu, Gikuyu',
    rw: 'Kinyarwanda',
    ky: 'Kirghiz, Kyrgyz',
    kv: 'Komi',
    kg: 'Kongo',
    ko: 'Korean',
    ku: 'Kurdish',
    kj: 'Kwanyama, Kuanyama',
    la: 'Latin',
    lb: 'Luxembourgish, Letzeburgesch',
    lg: 'Luganda',
    li: 'Limburgish, Limburgan, Limburger',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    lv: 'Latvian',
    gv: 'Manx',
    mk: 'Macedonian',
    mg: 'Malagasy',
    ms: 'Malay',
    ml: 'Malayalam',
    mt: 'Maltese',
    mi: 'Māori',
    mr: 'Marathi (Marāṭhī)',
    mh: 'Marshallese',
    mn: 'Mongolian',
    na: 'Nauru',
    nl: 'Dutch',
    nv: 'Navajo, Navaho',
    nb: 'Norwegian Bokmål',
    nd: 'North Ndebele',
    ne: 'Nepali',
    ng: 'Ndonga',
    nn: 'Norwegian Nynorsk',
    no: 'Norwegian',
    ii: 'Nuosu',
    nr: 'South Ndebele',
    oc: 'Occitan',
    oj: 'Ojibwe, Ojibwa',
    cu: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    om: 'Oromo',
    or: 'Oriya',
    os: 'Ossetian, Ossetic',
    pa: 'Panjabi, Punjabi',
    pi: 'Pāli',
    fa: 'Persian',
    pl: 'Polish',
    ps: 'Pashto, Pushto',
    pt: 'Portuguese',
    qu: 'Quechua',
    rm: 'Romansh',
    rn: 'Kirundi',
    ro: 'Romanian, Moldavian, Moldovan',
    ru: 'Russian',
    sa: 'Sanskrit (Saṁskṛta)',
    sc: 'Sardinian',
    sd: 'Sindhi',
    se: 'Northern Sami',
    sm: 'Samoan',
    sg: 'Sango',
    sq: 'Albanian',
    sr: 'Serbian',
    gd: 'Scottish Gaelic; Gaelic',
    sn: 'Shona',
    si: 'Sinhala, Sinhalese',
    sk: 'Slovak',
    sl: 'Slovene',
    so: 'Somali',
    st: 'Southern Sotho',
    es: 'Spanish',
    su: 'Sundanese',
    sw: 'Swahili',
    ss: 'Swati',
    sv: 'Swedish',
    ta: 'Tamil',
    te: 'Telugu',
    tg: 'Tajik',
    th: 'Thai',
    ti: 'Tigrinya',
    bo: 'Tibetan Standard, Tibetan, Central',
    tk: 'Turkmen',
    tl: 'Tagalog',
    tn: 'Tswana',
    to: 'Tonga (Tonga Islands)',
    tr: 'Turkish',
    ts: 'Tsonga',
    tt: 'Tatar',
    tw: 'Twi',
    ty: 'Tahitian',
    ug: 'Uighur, Uyghur',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    ve: 'Venda',
    vi: 'Vietnamese',
    vo: 'Volapük',
    wa: 'Walloon',
    cy: 'Welsh',
    wo: 'Wolof',
    fy: 'Western Frisian',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang, Chuang',
    zu: 'Zulu',
};

export const transcriptLanguageNames = languageNames;

export const getTranscriptLanguage = (languageCode: string): string => {
    return transcriptLanguageNames[languageCode] || languageCode;
};
