import { Collapsible as ParagonCollapsible } from '@edx/paragon';
import * as React from 'react';
import classNames from 'classnames';

const openMarker = (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='10' viewBox='0 0 8 10' fill='none'>
        <path d='M8 5L0.500004 9.33012L0.500004 0.669873L8 5Z' fill='#333333'/>
    </svg>
);

const closeMarker = (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'>
        <path d='M5 8L0.669877 0.500003L9.33013 0.500004L5 8Z' fill='#333333'/>
    </svg>
);

interface CollapsibleProps extends React.PropsWithChildren {
    title: string | React.ReactNode;
    defaultOpen: boolean;
    isCollapsible: boolean;
    isOpen?: boolean;
    onToggle?: (open: boolean) => void;
    onOpen?: () => void;
    onClose?: () => void;
    showMarkers?: boolean;
    closeMarker?: React.JSX.Element;
    openMarker?: React.JSX.Element;
}

interface CollapsibleState {
    isExpanded: boolean;
}

export class Collapsible extends React.PureComponent<CollapsibleProps, CollapsibleState> {
    constructor(props: CollapsibleProps) {
        super(props);
        this.state = {
            isExpanded: false,
        };
    }

    public static defaultProps = {
        defaultOpen: false,
        isCollapsible: true,
    };

    private onExpand = () => {
        this.setState({ isExpanded: true });
    }

    private onCollapse = () => {
        this.setState({ isExpanded: false });
    }

    public render() {
        return (
            <ParagonCollapsible.Advanced
                className='collapsible'
                defaultOpen={this.props.defaultOpen}
                open={this.isOpen()}
                onToggle={this.props.onToggle}
                onOpen={() => {
                    if (this.props.onOpen) {
                        this.props.onOpen();
                    }
                    this.onExpand();
                }}
                onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                    this.onCollapse();
                }}>

                {this.renderTrigger()}

                <ParagonCollapsible.Body className='collapsible-body' transitionWrapper={<></>}>
                    {this.props.children}
                </ParagonCollapsible.Body>

            </ParagonCollapsible.Advanced>
        );
    }

    private renderTrigger() {
        if (!this.props.isCollapsible) {
            return this.props.title;
        }

        return (
            <ParagonCollapsible.Trigger
                className={classNames('collapsible-trigger', { 'with-markers': this.props.showMarkers })}
            >
                {this.props.showMarkers && (this.state.isExpanded ?
                    <span className='collapsible-marker'>{this.props.closeMarker || closeMarker}</span>
                    : <span className='collapsible-marker'>{this.props.openMarker || openMarker}</span>
                )}
                <span
                    className={classNames('collapsible-title', { 'with-margin': this.props.showMarkers })}>
                    {this.props.title}
                </span>
            </ParagonCollapsible.Trigger>
        );
    }

    private isOpen() {
        return !this.props.isCollapsible || this.props.isOpen;
    }
}
