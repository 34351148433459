import { getItemTypeMeta, ItemType } from 'items/models';
import { AppliedFilterFilterOnEnum } from 'labxchange-client';
import { getBackgroundKnowledgeLabel } from 'library/displayMessages';
import { MutableTagData, TagData } from 'search/components/Tag';
import { TaxonomyData, TaxonomyCategory } from 'search/components/Taxonomy';
import { AggregationResults, buildTags, languagesFilter } from 'search/utils';

/**
 * Utility functions related to searching the library.
 */

export const LibrarySearchFilter = AppliedFilterFilterOnEnum;
export type LibrarySearchFilter = AppliedFilterFilterOnEnum;

/**
 * Given a item type value (e.g. 'pathway'), return the name of that item type.
 * This should return a <WrappedMessage>, but TagData/<Taxonomy> doesn't support
 * that yet.
 */
function itemTypeFilterName(itemType: string): string {
    const itemTypeMeta = getItemTypeMeta(itemType as ItemType);
    let name = itemTypeMeta.name.defaultMessage as string;
    // Some types have a name like "Pathway - {count, number}":
    if (name.includes(' {count,')) {
        name = name.split(' {count,')[0];
    }
    return name;
}

interface CustomMutableTagData extends MutableTagData {
    translatedName?: string;
}

/**
 * When we submit a search request to the library search API, it returns data about the
 * available filter options ("aggregations") that we can use to refine the search.
 * This method converts that aggregation data from the ElasticSearch result format
 * to the 'TaxonomyData'/'TagData' format used by our <FilterBar> component to display
 * the checkboxes of options that users can toggle on and off.
 *
 * @param aggregationsData The aggregation data returned by ElasticSearch
 */
export function buildFilterOptionsFromSearchData(aggregationsData: AggregationResults): TaxonomyData[] {

    // Subject Area filter is a special case because it has two levels of tags:
    const subjectAreaTags: CustomMutableTagData[] = [];
    for (const {key, doc_count, translated_name } of aggregationsData[LibrarySearchFilter.SubjectArea].buckets!) {
        // key is either a subject/child key like "Biological Sciences:Genetics"
        // or a subject area / parent key like "Biological Sciences"
        const tagId = `${LibrarySearchFilter.SubjectArea}:${key}`;
        const isChild = key.indexOf(':') !== -1; // Is this a child [subject] or a parent [subject area]?
        if (isChild) {
            const name = key.split(':')[1];
            const parentTagName = key.split(':')[0];
            const parentTagId = LibrarySearchFilter.SubjectArea + ':' + parentTagName;
            let parentTag = subjectAreaTags.find((tag) => tag.id === parentTagId);
            if (parentTag === undefined) {
                // TODO: Load correct numEntities for parent tag from elasticsearch
                parentTag = {
                    id: parentTagId,
                    name: parentTagName,
                    childTags: [],
                    numEntities: undefined,
                    translatedName: translated_name,
                };
                subjectAreaTags.push(parentTag);
            }
            parentTag.childTags.push({
                id: tagId,
                name,
                numEntities: doc_count,
                translatedName: translated_name,
            });
        } else {
            // If this parent tag wasn't already added by a child tag, add it now:
            if (subjectAreaTags.find((tag) => tag.id === tagId) === undefined) {
                subjectAreaTags.push({
                    id: tagId,
                    name: key,
                    numEntities: doc_count,
                    childTags: [],
                    translatedName: translated_name,
                });
            }
        }
    }
    const subjectAreaFilter: TaxonomyData = {
        id: LibrarySearchFilter.SubjectArea,
        category: TaxonomyCategory.SubjectArea,
        tags: subjectAreaTags,
    };

    // The rest are fairly consistent:

    const backgroundKnowledgeFilter = buildTags(
        aggregationsData,
        LibrarySearchFilter.BackgroundKnowledge,
        TaxonomyCategory.BackgroundKnowledge,
        (code) => getBackgroundKnowledgeLabel(code).defaultMessage,
    );
    const durationFilter = buildTags(aggregationsData, LibrarySearchFilter.Duration, TaxonomyCategory.VideoDuration,
        (durationKey) => {
            // Get a friendly label for the duration range options:
            const ranges: any = {'*-300.0': '< 5 min', '300.0-600.0': '5-10 min', '600.0-*': '> 10 min'};
            return ranges[durationKey] || durationKey;
        },
    );
    const partnersFilter = buildTags(aggregationsData, LibrarySearchFilter.Partner, TaxonomyCategory.ContentSource);

    const freeFormTagsFilter = buildTags(aggregationsData, LibrarySearchFilter.Tag, TaxonomyCategory.PopularTags);

    const filters = [
        subjectAreaFilter,
        backgroundKnowledgeFilter,
        // Only include the video duration filter if there are some videos in the result.
        ...(durationFilter.tags.length > 0 ? [durationFilter] : []),
        partnersFilter,
        freeFormTagsFilter,
        languagesFilter,
      ];

      return filters;
}

export function buildContentFilters(aggregationsData: AggregationResults): ReadonlyArray<TagData> {
    const contentFilter =  buildTags(aggregationsData, LibrarySearchFilter.ItemType, TaxonomyCategory.ContentType, itemTypeFilterName);
    return contentFilter.tags;
}
