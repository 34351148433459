import * as React from 'react';

interface Props {
    percentage: string;
}

export class ProgressBar extends React.PureComponent<Props> {
    public render() {
        return <div className='progress-bar-component'>
            <div className='progress-bar-container'>
                <div className='progress-bar' style={{width: this.props.percentage}} />
            </div>
            <div className='progress-bar-text'>
                {this.props.percentage}
            </div>
        </div>;
    }
}
