// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionAuthor,
    DiscussionAuthorFromJSON,
    DiscussionAuthorToJSON,
} from './';

/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    latestReply: string;
    /**
     * 
     * @type {DiscussionAuthor}
     * @memberof Conversation
     */
    author: DiscussionAuthor;
    /**
     * 
     * @type {DiscussionAuthor}
     * @memberof Conversation
     */
    recipient: DiscussionAuthor;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    latestReplyUpdated?: Date;
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    unread: number;
}

export function ConversationFromJSON(json: any): Conversation {
    return {
        'id': json['id'],
        'created': new Date(json['created']),
        'updated': new Date(json['updated']),
        'latestReply': json['latest_reply'],
        'author': DiscussionAuthorFromJSON(json['author']),
        'recipient': DiscussionAuthorFromJSON(json['recipient']),
        'latestReplyUpdated': !exists(json, 'latest_reply_updated') ? undefined : new Date(json['latest_reply_updated']),
        'unread': json['unread'],
    };
}

export function ConversationToJSON(value?: Conversation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'created': value.created.toISOString(),
        'updated': value.updated.toISOString(),
        'latest_reply': value.latestReply,
        'author': DiscussionAuthorToJSON(value.author),
        'recipient': DiscussionAuthorToJSON(value.recipient),
        'latest_reply_updated': value.latestReplyUpdated === undefined ? undefined : value.latestReplyUpdated.toISOString(),
        'unread': value.unread,
    };
}


