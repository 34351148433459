import * as React from 'react';
import { Button, ButtonProps } from 'ui/components';

interface Props extends ButtonProps {
    selected?: boolean;
}

export const SelectionButton: React.FunctionComponent<Props> = (props) => {
    const className = `auth-button selection-button
                        ${props.className ? props.className : ''}
                        ${props.selected ? 'selected' : ''}`;
    return (
        <Button
            {...props}
            className={className}
            btnStyle='primary'
        />
    );
};
