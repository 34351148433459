// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemProgress,
    ItemProgressFromJSON,
    ItemProgressToJSON,
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomAssignmentResponse
 */
export interface ClassroomAssignmentResponse {
    /**
     * 
     * @type {string}
     * @memberof ClassroomAssignmentResponse
     */
    id: string;
    /**
     * 
     * @type {ItemResponse}
     * @memberof ClassroomAssignmentResponse
     */
    item: ItemResponse;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomAssignmentResponse
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomAssignmentResponse
     */
    updated: Date;
    /**
     * 
     * @type {Array<ItemProgress>}
     * @memberof ClassroomAssignmentResponse
     */
    children: Array<ItemProgress>;
    /**
     * 
     * @type {string}
     * @memberof ClassroomAssignmentResponse
     */
    mentorship: string;
}

export function ClassroomAssignmentResponseFromJSON(json: any): ClassroomAssignmentResponse {
    return {
        'id': json['id'],
        'item': ItemResponseFromJSON(json['item']),
        'created': new Date(json['created']),
        'updated': new Date(json['updated']),
        'children': (json['children'] as Array<any>).map(ItemProgressFromJSON),
        'mentorship': json['mentorship'],
    };
}

export function ClassroomAssignmentResponseToJSON(value?: ClassroomAssignmentResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'item': ItemResponseToJSON(value.item),
        'created': value.created.toISOString(),
        'updated': value.updated.toISOString(),
        'children': (value.children as Array<any>).map(ItemProgressToJSON),
        'mentorship': value.mentorship,
    };
}


