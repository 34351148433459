import {getDefaultState} from '../../models';
import {SINGLE_EDITOR_CONTENT_CHANGE} from '../actions/action-types';

const SingleSelectEditorReducer = function(state = getDefaultState().singleSelectEditor, action: any) {
    switch(action.type) {
        case SINGLE_EDITOR_CONTENT_CHANGE:
            return Object.assign({}, state, {content: action.content});
        default:
            return state;
    }
};

export default SingleSelectEditorReducer;
