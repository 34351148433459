import { getItemTypeMeta, ItemType } from 'items/models';
import * as React from 'react';
import { Icon, IconProps } from 'ui/components';
import { ResourceRelationshipTargetItemTypeEnum } from '../../../labxchange-client';

interface Props extends IconProps {
    itemType: ItemType | ResourceRelationshipTargetItemTypeEnum;
}

type ItemIconProps = Omit<Props, 'name'>;

export class ItemIcon extends React.PureComponent<ItemIconProps> {
    public render() {
        const {itemType, ...iconProps} = this.props;
        return <Icon
            name={getItemTypeMeta(itemType).icon}
            {...iconProps}
        />;
    }
}
