import * as React from 'react';
import { WrappedMessage } from 'utils';
import { MessageDescriptor } from 'react-intl';

interface Props {
    title: MessageDescriptor;
    secondaryText?: MessageDescriptor;
    buttons?: React.ReactNode;
}

export class EmptyDashboardPage extends React.PureComponent<Props, {}> {

    public render() {
        const { title, secondaryText, buttons } = this.props;
        return (
            <div className='dashboard-empty-section'>
                <h1 className='title'>
                    <WrappedMessage message={title} />
                </h1>
                {secondaryText ? <div className='secondary-text'>
                    <WrappedMessage message={secondaryText} />
                </div> : null }
                <div className='buttons'>
                    {buttons ? buttons : null}
                </div>
            </div>
        );
    }
}
