// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    AnnotatedVideoQuestionAnswer,
    AnnotatedVideoQuestionAnswerFromJSON,
    AnnotatedVideoQuestionAnswerToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnnotatedVideoQuestion
 */
export interface AnnotatedVideoQuestion {
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoQuestion
     */
    type?: AnnotatedVideoQuestionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnotatedVideoQuestion
     */
    question: string;
    /**
     * 
     * @type {Array<AnnotatedVideoQuestionAnswer>}
     * @memberof AnnotatedVideoQuestion
     */
    answers: Array<AnnotatedVideoQuestionAnswer>;
}

export function AnnotatedVideoQuestionFromJSON(json: any): AnnotatedVideoQuestion {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'question': json['question'],
        'answers': (json['answers'] as Array<any>).map(AnnotatedVideoQuestionAnswerFromJSON),
    };
}

export function AnnotatedVideoQuestionToJSON(value?: AnnotatedVideoQuestion): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'question': value.question,
        'answers': (value.answers as Array<any>).map(AnnotatedVideoQuestionAnswerToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum AnnotatedVideoQuestionTypeEnum {
    Select = 'select'
}


