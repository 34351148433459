// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomLeaveRequest
 */
export interface ClassroomLeaveRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassroomLeaveRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomLeaveRequest
     */
    feedback?: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomLeaveRequest
     */
    user?: string;
}

export function ClassroomLeaveRequestFromJSON(json: any): ClassroomLeaveRequest {
    return {
        'id': json['id'],
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function ClassroomLeaveRequestToJSON(value?: ClassroomLeaveRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'feedback': value.feedback,
        'user': value.user,
    };
}


