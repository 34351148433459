import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    ItemResponse,
    OrganizationSummary,
    OrganizationSummaryOrganizationTypeEnum
} from 'labxchange-client';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import { Button } from 'ui/components';
import { ROUTES } from 'global/constants';
import { detailUrlForEntity } from 'library/utils';
import { history } from 'global/history';
import { sanitizeUnsafeHTML, sanitizeConfigOptions } from '@labxchange/util-sanitization';

import messages from '../../../displayMessages';

export interface FeatureCardProps {
    item: ItemResponse;
}

export const FeatureCard: React.FC<FeatureCardProps> = (props) => {
    const item = props.item.metadata;
    const itemLink = detailUrlForEntity(item);

    const defaultImage = '/assets/images/default-featured.png';
    const cardImageUrl = item.imageUrl ?? defaultImage;
    const assetId = item.id ?? '';
    const assetIdOnS3 = assetId.replace(/:/g, '-');
    const gifUrl = `https://media.labxchange.org/interactive_gifs/${assetIdOnS3}.gif`;
    const gifImageUrl = `https://media.labxchange.org/interactive_gifs/${assetIdOnS3}.jpg`;

    const [imageUrl, setImageUrl] = useState(gifImageUrl);
    const [backgroundImage, setBackgroundImage] = useState(`url(${imageUrl})`);

    useEffect(() => {
        const image = new Image();
        image.src = gifImageUrl;
        image.onload = () => {
          setImageUrl(gifImageUrl);
        };

        image.onerror = () => {
          setImageUrl(cardImageUrl);
          setBackgroundImage(`url(${cardImageUrl})`);
        };
    }, [gifImageUrl, cardImageUrl]);

    const handleMouseEnter = () => {
        const gif = new Image();
        gif.src = gifUrl;
        gif.onload = () => {
            setBackgroundImage(`url(${gifUrl})`);
        };
    };

    const handleMouseLeave = () => {
        setBackgroundImage(`url(${imageUrl})`);
    };

    let sourceOrg = null;
    if (item.source && item.source.sourceOrganizations) {
        sourceOrg = item.source.sourceOrganizations[0].organization;
    }

    const providerLink = (content: any, org: OrganizationSummary) => {
        let orgIsPartner = false;
        let orgUrl = '#';

        if (
            org.organizationType ===
                OrganizationSummaryOrganizationTypeEnum.SPONSOR ||
            org.organizationType ===
                OrganizationSummaryOrganizationTypeEnum.PARTNER
        ) {
            orgUrl = ROUTES.Organizations.PROFILE_SLUG(org.slug);
            orgIsPartner = true;
        } else if (org.url) {
            orgUrl = org.url;
            orgIsPartner = false;
        }

        if (orgIsPartner) {
            return (
                <Link to={orgUrl} className='item-source-provider-url'>
                    {content}
                </Link>
            );
        } else {
            return (
                <a
                    href={orgUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='item-source-provider-url'
                >
                    {content}
                </a>
            );
        }
    };

    return (
        <div className='feature-card'>
            <Link
                to={itemLink}
                className='left-section'
                style={{ backgroundImage }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className='left-section-content'>
                    <div className='item-title'>
                        <h3>{item.title}</h3>
                    </div>
                    {/* Disabling linter for next line because the div is used to stop event propagation */}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div className='provider-info' onClick={(e) => e.stopPropagation()}>
                        {sourceOrg ?
                            providerLink(
                                <>
                                    <img
                                        className='item-org-avatar rounded-circle'
                                        src={sourceOrg.logoUrl}
                                        style={{backgroundColor: sourceOrg.brandColor}}
                                        alt=''
                                    />
                                    <h4>{sourceOrg.name}</h4>
                                </>,
                                sourceOrg
                            )
                            : item.authors.length > 0 && item.authors[0].username ?
                                <div className='item-user-avatar'>
                                    <UserAvatar
                                        username={item.authors[0].username}
                                        diameter='36px'
                                    />
                                    <h4>{item.authors[0].fullName}</h4>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </Link>
            <div className='right-section'>
                <div className='right-section-content'>
                    <div className='item-heading'>
                        <span><WrappedMessage message={messages.simulationCardHeadingTag}/></span>
                        <h4><WrappedMessage message={messages.simulationCardHeadingText}/></h4>
                    </div>
                    <p>{sanitizeUnsafeHTML(item.description, sanitizeConfigOptions.StripHTML)}</p>
                </div>
                <div className='action-buttons'>
                    <Button
                        className='overview-button'
                        btnStyle='primary'
                        label={messages.simulationCardOverviewButton}
                        onClick={() => history.push(itemLink)}
                    />
                    <Button
                        className='launch-button'
                        btnStyle='primary'
                        label={messages.simulationCardLaunchButton}
                        icon='polygon-right-curved'
                        iconPosition='right'
                        onClick={() => history.push(itemLink + '?fullscreen=true')}
                    />
                </div>
            </div>
        </div>
    );
};

export default FeatureCard;
