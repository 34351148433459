import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface ProgressMetricProps {
    label: MessageDescriptor;
    value?: number;
    valueText: MessageDescriptor;
    helpText?: MessageDescriptor;
    className?: string;
}

export class ProgressMetric extends React.PureComponent<ProgressMetricProps> {
    public static defaultProps = {
        valueText: messages.classScoreValue,
    };

    public render() {
        return (
            <div className={`progress-metric ${this.props.className}`}>
                <div className='state-badge'></div>
                <div className='state-details'>
                    <div className='state-title'>
                        <WrappedMessage message={this.props.label} />
                    </div>
                    <div className='state-score'>
                    {this.props.value !== undefined ?
                        <span>
                            <WrappedMessage message={this.props.valueText} values={{value: this.props.value}} />
                        </span>
                    :
                        <span>
                            <WrappedMessage message={messages.incompletable} />
                        </span>
                    }
                    </div>
                </div>
                {this.props.helpText ?
                    <div className='state-help'>
                        <small><WrappedMessage message={this.props.helpText} /></small>
                    </div>
                 : null}
            </div>
        );
    }
}
