import * as React from 'react';
import GeneralFeedbackItem from './GeneralFeedbackItem';
import { defineMessages } from 'react-intl';
import { intl } from 'i18n';

const messages = defineMessages({
    title: {
        id: 'GeneralFeedback.title',
        defaultMessage: 'GENERAL feedback'
    },
    description: {
        id: 'GeneralFeedback.description',
        defaultMessage: 'Feedback will appear when a student submits correct or incorrect answers.'
    },
    btn: {
        id: 'GeneralFeedback.btn',
        defaultMessage: '+ Add general feedback'
    },
    note: {
        id: 'GeneralFeedback.note',
        defaultMessage: 'Note: General feedback overrides specific feedback.'
    },
})

export default class GeneralFeedback extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.getFeedback = this.getFeedback.bind(this)
    }

    getFeedback(key: number, correct: boolean) {
        let feedback = this.props.generalFeedbackList.find((feedback: any) => feedback.correct === correct);
        if (feedback === undefined) { feedback = { correct: correct, feedback: "" } }
        return <GeneralFeedbackItem key={key} id={Math.random()} generalFeedbackChange={this.props.generalFeedbackChange} {...feedback} />
    }

    render() {
        const { formatMessage } = intl;
        return (
            <div className='lxc-advanced-settings-block'>
                <div className='lxc-advanced-settings-block-title'>
                    {formatMessage(messages.title)}
                </div>
                <div className='lxc-advanced-settings-block-description'>
                    {formatMessage(messages.description)}
                </div>

                <div className='lxc-advanced-settings-form'>
                    {this.getFeedback(0, true)}
                    {this.getFeedback(1, false)}
                    <div className='lxc-advanced-settings-note'>
                        {formatMessage(messages.note)}
                    </div>
                </div>

            </div>
        )
    }
};
