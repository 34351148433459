import { bind } from 'bind-decorator';
import { ChildItem } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { ModalConfirmation } from 'ui/components';

interface Props {
    child: ChildItem;
    onDelete: () => void;
    onClose: () => void;
}

/** Modal for Pathway asset deletion */
export class PathwayChildDeleteModal extends React.PureComponent<Props> {

    public render() {
        return (
            <ModalConfirmation
                onCanceled={this.props.onClose}
                onConfirmed={this.onDelete}
                title={messages.itemDelete}
                titleValues={{title: this.props.child.item.metadata.title}}
                confirmButtonText={messages.libraryCardMenuDeleteTitle}
                cancelButtonText={messages.itemCancel}
            />
        );
    }

    @bind private onDelete() {
        this.props.onDelete();
        this.props.onClose();
    }
}
