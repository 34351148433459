import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { ItemStats as ItemStatsInterface } from 'labxchange-client';
import messages from 'library/displayMessages';
import { WrappedMessage } from 'utils';

/**
 * The ItemStats component displays stats in a Card.
 */
export class ItemStats extends React.PureComponent<ItemStatsInterface> {

    public render() {
        const nodes = [];
        if (this.props.favorites) {
            nodes.push(this.renderItemStat('favorites', this.props.favorites, messages.itemStatsFavouriteCount));
        }
        if (this.props.views) {
            nodes.push(this.renderItemStat('views', this.props.views, messages.itemStatsViewCount));
        }
        if (this.props.remixes) {
            nodes.push(this.renderItemStat('remixes', this.props.remixes, messages.itemStatsRemixCount));
        }
        if (this.props.clones) {
            nodes.push(this.renderItemStat('clones', this.props.clones, messages.itemStatsClonesCount));
        }

        return <ul className='item-stats list-unstyled'>{nodes}</ul>;
    }

    private renderItemStat(key: string, count: number, message: MessageDescriptor): React.ReactNode {
        return <li key={key}><WrappedMessage message={message} values={{count}}/></li>;
    }

}
