import * as React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'global/constants';
import { SearchBox } from 'search/components/SearchBox';
import { WrappedMessage } from 'utils';
import  messages from './displayMessages';
import { Icon } from 'elements';
import Select, { ValueType } from 'react-select';
import { Option } from './types';
import { useState } from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { localeInfo } from 'i18n';

interface PopularSearchQueryContainerProps {
    selectedSubject: string;
    isHeroSection?: boolean;
    onSubjectSelection? : (selectedOption: ValueType<Option>) => void;
    subjectOptions: Option[];
}

type PopularQueries = {
    [key: string]: string[];
};

const popularQueries: PopularQueries = {
    'biology': ['Cell structure', 'The double helix', 'Inheritance'],
    'physics': ['Momentum', 'Energy transfer', 'Gravity'],
    'chemistry': ['Macromolecules', 'Stoichiometry', 'Reactions']
};

const popularQueriesHeroSection: PopularQueries = {
    'biology': ['The human body', 'Cell structure', 'Photosynthesis' ,'DNA'],
    'chemistry': [ 'Atom', 'Chemical reactions', 'Periodic table'],
    'physics': ['Sound', 'Magnetic Fields', 'Forces & Motion']
};

const subjectQueries: any = {
    biology: '&t=SubjectArea%3ABiological+Sciences',
    physics: '&t=SubjectArea%3APhysics',
    chemistry: '&t=SubjectArea%3AChemistry'
};

const subjectQueriesForSearch: any = {
    biology: ['SubjectArea:Biological Sciences'],
    physics: ['SubjectArea:Physics'],
    chemistry: ['SubjectArea:Chemistry'],
};


export const PopularSearchQueryContainer: React.FunctionComponent<PopularSearchQueryContainerProps> = (props) => {
    const [selectedSubjectForSearch, setSelectedSubjectForSearch] = useState (props.subjectOptions[0]);

    const subjectChange = (selectedOption: ValueType<Option>) =>{
        if (selectedOption && 'value' in selectedOption) {
            setSelectedSubjectForSearch(selectedOption);
        }
    };
    return (
        <>
            <div className='library-search-container'>
                {props.isHeroSection ?
                    <>
                        <h1>
                            {<WrappedMessage
                                message={messages.teachPageHeroImageText}
                                values={{teaching: <span style={{color:'#FAAC48'}}>
                                        <WrappedMessage message={messages.teachText}/></span>}}
                            />}
                        </h1>
                        <div className='logged-in-search-version'>
                            <Select
                                className='subject-select'
                                classNamePrefix='subject'
                                options={props.subjectOptions?.slice(0,3)}
                                onChange={subjectChange}
                                defaultValue={selectedSubjectForSearch}
                                isSearchable={false}
                            />
                            <span className='separator'/>
                            <SearchBox
                                darkBackground={false}
                                searchUrl={ROUTES.Library.HOME}
                                searchParams={subjectQueriesForSearch[selectedSubjectForSearch.value]}
                                showAlwaysFullSearchBar
                            />
                        </div>
                        {localeInfo.key === 'en' && <div className={classNames('popular-queries', {'in-hero-section': props.isHeroSection})}>
                            {
                                popularQueriesHeroSection[selectedSubjectForSearch.value].map(query =>
                                <Link key={query} className='search-link' to={`${ROUTES.Library.HOME}?q=${query}${subjectQueries[selectedSubjectForSearch.value]}`}>{query}
                                    <Icon name='search'/>
                                </Link>
                            )}
                        </div>}
                    </>
                    :
                    <>
                        <h1><WrappedMessage message={messages.searchContainerHeading}/></h1>
                        <SearchBox
                            darkBackground={false}
                            searchUrl={ROUTES.Library.HOME}
                            searchTitleUrl={ROUTES.Library.SEARCH_TITLE}
                            showAlwaysFullSearchBar
                        />
                        {localeInfo.key === 'en' && <div className='popular-queries'>
                            <span><WrappedMessage message={messages.popularQuriesTryText}/></span>
                            {popularQueries[props.selectedSubject].map(query =>
                                <Link className='search-link' to={`${ROUTES.Library.HOME}?q=${query}`}>{query}
                                    <Icon name='search'/>
                                </Link>
                            )}
                        </div>
                        }
                    </>
                }
            </div>
        </>
    );
};

export const PopularSearchQueryContainerSkeleton = () => {
    return (
        <div className='library-search-container'>
            <Skeleton width={476} height={36} />
            <div className='logged-in-search-version'>
                <Skeleton width={650} height={48}/>
            </div>
            <div className='popular-queries in-hero-section'>
                {Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton key={index} className='btn-filter btn-filter-skeleton btn-search-query-skeleton' width={150} height={36} />
                ))}
            </div>
        </div>
    );
};
