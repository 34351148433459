// This is used for features that aren't ready; we want to show them in the UI
// to help people understand what's coming and keep the layout consistent, but
// show it as clearly disabled and disallow clicking on it.

import * as React from 'react';

export interface UnderConstructionProps {
    children: React.ReactNode;
    style?: object;
}

export class UnderConstruction extends React.PureComponent<UnderConstructionProps> {

    public static defaultProps = {
        styles: {},
    };

    public render() {
        return (
            <div className='under-construction' style={this.props.style} aria-disabled='true'>
                {this.props.children}
            </div>
        );
    }
}
