import React from 'react';

interface ProgressBarProps {
    percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps>  = ({ percentage }) => (
  <div className='onboarding-progress-bar-container'>
    <div className='bar' style={{ width: `${percentage}%` }}></div>
  </div>
);

export default ProgressBar;
