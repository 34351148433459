// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Book,
    BookFromJSON,
    BookToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    previous?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    count: number;
    /**
     * 
     * @type {Array<Book>}
     * @memberof InlineResponse200
     */
    results: Array<Book>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return {
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(BookFromJSON),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'next': value.next,
        'previous': value.previous,
        'count': value.count,
        'results': (value.results as Array<any>).map(BookToJSON),
    };
}


