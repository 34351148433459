import * as LoginActions from 'auth/actions';
import * as React from 'react';
import bind from 'bind-decorator';
import messages from '../displayMessages';
import { AuthPageLayout, Button, SSOButton } from 'ui/components';
import { AuthProviderEnum, getProviderIcon, providerToString } from 'auth/utils';
import { connect } from 'react-redux';
import { getCookie } from 'utils';
import { getLoggedInStatus } from 'auth/selectors';
import { history } from 'global/history';
import { EVENT_NAMES } from 'tracking/constants';
import { analyticsInstance } from 'tracking';
import { intl } from 'i18n';
import { Redirect } from 'react-router-dom';
import { RootState } from 'global/state';
import { ROUTES } from 'global/constants';
import { TermsAndPolicyComponent } from '../elements/TermsAndPolicyComponent';

interface Props {
    isLoggedIn: boolean;
    loginUser: typeof LoginActions.login;
    email?: string;
    provider?: AuthProviderEnum;
}


@connect((state: RootState) => ({
    isLoggedIn: getLoggedInStatus(state),
}), {
    loginUser: LoginActions.login,
})
export class SSOLogin extends React.PureComponent<Props> {

    public render() {
        const email = history.location.state?.email || this.getEmailCookie() || this.props.email || ' ';
        const providerParam: string | null = history.location.state?.provider || this.props.provider || this.getProvider();

        if (this.props.isLoggedIn || !providerParam) {
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }

        const provider: AuthProviderEnum = providerParam as AuthProviderEnum;

        return (
            <AuthPageLayout
                className='sso-login-page'
                imageUrl='/assets/images/auth-layouts/scientists.svg'
                title={intl.formatMessage(messages.lxAuthHelloAgainLabel)}
                subtitle={intl.formatMessage(messages.lxAuthAccountCreatedWithOtherMethod, { email: <b>{email}</b>, provider: providerToString(provider) })}
            >
                <div className='sso-button-container'>
                    <SSOButton
                        icon={getProviderIcon(provider)}
                        label={messages.lxAuthProviderButtonText}
                        labelValues={{ provider: providerToString(provider) }}
                        onClick={() => this.onClickProvider(provider)}
                    />
                </div>
                <TermsAndPolicyComponent
                    type='sign-in'
                    clickOnMessage={messages.lxAuthProviderButtonText}
                    clickOnMessageValues={{ provider: providerToString(provider) }}
                />
                <div className='help-desk-container'>
                    <Button
                        className='help-desk-button'
                        btnStyle='link'
                        label={messages.lxAuthHelpDeskLabel}
                        onClick={this.onClickHelpDesk}
                    />
                </div>
            </AuthPageLayout>
        );
    }

    @bind private onClickProvider(provider: AuthProviderEnum) {
        analyticsInstance.track(
            EVENT_NAMES.ProviderSignInRedirectClicked,
            { provider: provider.toLowerCase(), url: window.location.toString() },
            { sendImmediately: true },
        );

        if (provider === undefined) {
            return;
        }
        if (provider === AuthProviderEnum.Edx) {
            /// Login with edx. This calls the old auth flow.
            this.props.loginUser();
        }
        else {
            LoginActions.loginWithProvider(provider);
        }
    }

    private onClickHelpDesk() {
        analyticsInstance.track(EVENT_NAMES.SignInHelpdeskClicked, { url: window.location.toString() }, { sendImmediately: true });
        window.location.href = 'https://labxchange.zendesk.com/hc/en-us/articles/360035736354-Creating-An-Account';
    }

    private getEmailCookie() {
        return getCookie('sso_auth_user_email')?.replace(/\"/gi, '');
    }

    private getProvider() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('provider');
    }
}