import * as React from 'react';
import bind from 'bind-decorator';
import messages from '../displayMessages';
import { AuthPageLayout, Button, showErrorMessage, ShowPasswordButton, showSuccessMessage, TextInput } from 'ui/components';
import { connect } from 'react-redux';
import { getLoggedInStatus, getUserEmail } from 'auth/selectors';
import { history } from 'global/history';
import { intl } from 'i18n';
import { isLxLoginActive, validatePassword } from 'auth/utils';
import { LxConfig, ROUTES } from '../../../global/constants';
import { Redirect } from 'react-router';
import { RootState } from 'global/state';
import { WrappedMessage } from 'utils';

interface Props {
    isLoggedIn: boolean;
    email: string;
}

interface State {
    password: string;
    confirmPassword: string;
    showPassword?: boolean;
    showConfirmPassword?: boolean;
    invalidPassword: boolean;
    submitted: boolean;
}

@connect((state: RootState) => ({
    isLoggedIn: getLoggedInStatus(state),
    email: getUserEmail(state),
}), {})
export class ResetPasswordView extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            submitted: false,
            invalidPassword: false,
        };
    }

    private isValid(): boolean {
        if (!this.state.password) { return false; }
        if (this.state.password !== this.state.confirmPassword) { return false; }
        if (this.state.submitted) { return false; }
        if (!validatePassword(this.state.password)) { return false; }
        return true;
    }

    public render() {
        const errorParam = this.getErrorParam();
        if (errorParam) {
            if (errorParam === 'authenticated') {
                showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordAuthenticatedError} />);
            }
            else if (errorParam === 'token-fail') {
                showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordTokenError} />);
            }
            else {
                showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordError} />);
            }
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }

        if (!isLxLoginActive()) {
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }

        if (this.getUidb36() === null) {
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }

        const subtitle = this.getSubtitleMessage();

        return (
            <AuthPageLayout
                className='reset-password-content'
                imageUrl='/assets/images/auth-layouts/reset-password.svg'
                title={intl.formatMessage(messages.lxAuthResetPasswordTitle)}
                subtitle={
                    <>
                        {intl.formatMessage(subtitle)}
                        <strong>{this.props.email ? this.props.email : ''}</strong>
                    </>
                }>
                <div className='reset-password-form'>
                    <form onSubmit={this.onSubmit} method='POST'>
                        <TextInput
                            className='password-input'
                            value={this.state.password}
                            inputKey='reset-password'
                            autoComplete='new-password'
                            onChangeValue={this.onChangePassword}
                            type={this.state.showPassword ? 'text' : 'password'}
                            placeholder={messages.lxAuthPasswordInputPlaceholder}
                            feedbackMessage={messages.lxAuthPasswordInputFeedback}
                            invalid={this.state.invalidPassword}
                            onFocusOut={this.onPasswordFocusOut}
                            inputAccessory={
                                <ShowPasswordButton
                                    active={this.state.showPassword || false}
                                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                />
                            }
                        />
                        <br />
                        <TextInput
                            className='password-input'
                            value={this.state.confirmPassword}
                            inputKey='reset-password-confirm'
                            autoComplete='new-password'
                            placeholder={messages.lxAuthResetPasswordConfirmInputPlaceholder}
                            onChangeValue={(confirmPassword) => this.setState({ confirmPassword })}
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            inputAccessory={
                                <ShowPasswordButton
                                    active={this.state.showConfirmPassword || false}
                                    onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}
                                />
                            }
                            barAccessory={this.getConfirmPasswordAccessory()}
                        />
                        <br />
                        <Button
                            btnStyle='primary'
                            fullWidth={true}
                            label={messages.lxAuthResetPasswordSubmitButton}
                            disabled={!this.isValid()}
                            onClick={this.onSubmit}
                        />
                    </form>
                </div>
            </AuthPageLayout >
        );
    }

    private getSubtitleMessage() {
        if (this.props.email) {
            return messages.lxAuthResetPasswordSubtitleWithEmail;
        }
        return messages.lxAuthResetPasswordSubtitle;
    }

    private getConfirmPasswordAccessory() {
        let className = 'confirm-password-accessory feedback-message';
        if (!this.state.password || !this.state.confirmPassword || (this.state.password === this.state.confirmPassword)) { className += ' invisible'; }
        return <span className={className}>{intl.formatMessage(messages.lxAuthResetConfirmPasswordNoMatchAccessory)}</span>;
    }

    @bind private onChangePassword(password: string) {
        this.setState({ password, invalidPassword: false });
    }

    @bind private onPasswordFocusOut(password: string) {
        this.setState({ password, invalidPassword: !validatePassword(password)});
    }

    @bind private async onSubmit() {
        /// Build data for request
        const formData = new FormData();
        formData.append('password1', this.state.password);
        formData.append('password2', this.state.confirmPassword);

        let response;
        try {
            /// We add `credentials: 'include'` to send the session.
            /// See the view for more information about the token saved on the session.
            response = await fetch(`${LxConfig.BackendBaseUrl}/accounts/password/reset/key/${this.getUidb36()}-set-password/`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
        }
        catch {
            showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordError} />);
            return;
        }

        if (response.status === 200) {
            showSuccessMessage(<WrappedMessage message={messages.lxAuthResetPasswordSuccess}/>,
                {
                    onClose: () => {
                        history.push(ROUTES.General.LOG_IN);
                    },
                },);
        }
        else if (response.status === 401) {
            showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordTokenError} />);
        }
        else if (response.status === 400) {
            /// Password validation errors
            const errorMessage = (await response.json()).error_message;
            if (errorMessage) {
                showErrorMessage(errorMessage);
            }
            else {
                showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordInvalidPassword} />);
            }
        }
        else {
            showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordError} />);
        }
    }

    private getErrorParam() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('error');
    }

    private getUidb36() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('uidb36');
    }

}