// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadPermissions
 */
export interface ThreadPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ThreadPermissions
     */
    canPostToThreadObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadPermissions
     */
    canPinThreadObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadPermissions
     */
    canDeleteThreadObject?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadPermissions
     */
    canReplyThreadObject?: boolean;
}

export function ThreadPermissionsFromJSON(json: any): ThreadPermissions {
    return {
        'canPostToThreadObject': !exists(json, 'can_post_to_thread_object') ? undefined : json['can_post_to_thread_object'],
        'canPinThreadObject': !exists(json, 'can_pin_thread_object') ? undefined : json['can_pin_thread_object'],
        'canDeleteThreadObject': !exists(json, 'can_delete_thread_object') ? undefined : json['can_delete_thread_object'],
        'canReplyThreadObject': !exists(json, 'can_reply_thread_object') ? undefined : json['can_reply_thread_object'],
    };
}

export function ThreadPermissionsToJSON(value?: ThreadPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_post_to_thread_object': value.canPostToThreadObject,
        'can_pin_thread_object': value.canPinThreadObject,
        'can_delete_thread_object': value.canDeleteThreadObject,
        'can_reply_thread_object': value.canReplyThreadObject,
    };
}


