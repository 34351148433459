// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ExploreGeneralPermissions
 */
export interface ExploreGeneralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ExploreGeneralPermissions
     */
    canUserTagContent: boolean;
}

export function ExploreGeneralPermissionsFromJSON(json: any): ExploreGeneralPermissions {
    return {
        'canUserTagContent': json['can_user_tag_content'],
    };
}

export function ExploreGeneralPermissionsToJSON(value?: ExploreGeneralPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_user_tag_content': value.canUserTagContent,
    };
}


