// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomThreadPostUpdate
 */
export interface ClassroomThreadPostUpdate {
    /**
     * 
     * @type {string}
     * @memberof ClassroomThreadPostUpdate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomThreadPostUpdate
     */
    post: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomThreadPostUpdate
     */
    classroom: string;
}

export function ClassroomThreadPostUpdateFromJSON(json: any): ClassroomThreadPostUpdate {
    return {
        'content': json['content'],
        'post': json['post'],
        'classroom': json['classroom'],
    };
}

export function ClassroomThreadPostUpdateToJSON(value?: ClassroomThreadPostUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
        'post': value.post,
        'classroom': value.classroom,
    };
}


