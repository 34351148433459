import { defineMessages } from 'react-intl';

const messages = defineMessages({
    menteeProgressReportModalHeader: {
        id:'menteeProgressReportModalHeader',
        defaultMessage: '{fullName}\'s Progress',
        description: 'Mentee progress report modal header title.'
    },
    messageButton: {
        id:'messageButton',
        defaultMessage: 'Message',
        description: 'Send a message button.',
    },
    removeButton: {
        id:'removeButton',
        defaultMessage: 'Remove',
        description: 'Remove label.',
    },
    completedWork: {
        id:'completedWork',
        defaultMessage: 'Completed work',
        description: 'Label for hexagon completion wich represent completed work.',
    },
});

export default messages;
