// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TaggedContentRequest,
    TaggedContentRequestFromJSON,
    TaggedContentRequestToJSON,
    TaggedContentResponse,
    TaggedContentResponseFromJSON,
    TaggedContentResponseToJSON,
} from '../models';

export interface ContentsCreateRequest {
    data: TaggedContentRequest;
}

export interface ContentsGetTaggedContentRequest {
    userEmail: string;
    curriculumSlug: string;
    assetId: string;
    subjectSlug?: string;
}

/**
 * no description
 */
export class TaggedContentsApi extends runtime.BaseAPI {

    /**
     * Create a new tagged content instance
     */
    async contentsCreateRaw(requestParameters: ContentsCreateRequest): Promise<runtime.ApiResponse<TaggedContentResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling contentsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/tagged_contents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaggedContentRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaggedContentResponseFromJSON(jsonValue));
    }

    /**
     * Create a new tagged content instance
     */
    async contentsCreate(requestParameters: ContentsCreateRequest): Promise<TaggedContentResponse> {
        const response = await this.contentsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve tagged content by user_email, curriculum_slug, and asset_id.
     */
    async contentsGetTaggedContentRaw(requestParameters: ContentsGetTaggedContentRequest): Promise<runtime.ApiResponse<TaggedContentResponse>> {
        if (requestParameters.userEmail === null || requestParameters.userEmail === undefined) {
            throw new runtime.RequiredError('userEmail','Required parameter requestParameters.userEmail was null or undefined when calling contentsGetTaggedContent.');
        }

        if (requestParameters.curriculumSlug === null || requestParameters.curriculumSlug === undefined) {
            throw new runtime.RequiredError('curriculumSlug','Required parameter requestParameters.curriculumSlug was null or undefined when calling contentsGetTaggedContent.');
        }

        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling contentsGetTaggedContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userEmail !== undefined) {
            queryParameters['user_email'] = requestParameters.userEmail;
        }

        if (requestParameters.curriculumSlug !== undefined) {
            queryParameters['curriculum_slug'] = requestParameters.curriculumSlug;
        }

        if (requestParameters.subjectSlug !== undefined) {
            queryParameters['subject_slug'] = requestParameters.subjectSlug;
        }

        if (requestParameters.assetId !== undefined) {
            queryParameters['asset_id'] = requestParameters.assetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/tagged_contents/get_tagged_content`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaggedContentResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve tagged content by user_email, curriculum_slug, and asset_id.
     */
    async contentsGetTaggedContent(requestParameters: ContentsGetTaggedContentRequest): Promise<TaggedContentResponse> {
        const response = await this.contentsGetTaggedContentRaw(requestParameters);
        return await response.value();
    }

}
