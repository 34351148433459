// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileLanguage
 */
export interface ProfileLanguage {
    /**
     * 
     * @type {string}
     * @memberof ProfileLanguage
     */
    preferredLanguage: string;
}

export function ProfileLanguageFromJSON(json: any): ProfileLanguage {
    return {
        'preferredLanguage': json['preferred_language'],
    };
}

export function ProfileLanguageToJSON(value?: ProfileLanguage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'preferred_language': value.preferredLanguage,
    };
}


