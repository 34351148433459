import * as LoginActions from 'auth/actions';
import { getLoggedInStatus, getUsername, getUserPermissions } from 'auth/selectors';
import bind from 'bind-decorator';
import { OrganizationDashboardHeader } from 'dashboard';
import { OrganizationsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { APIPermissions, OrganizationMembership, OrganizationResponse } from 'labxchange-client';
import { OrganizationContentDashboard } from 'library/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { UI_IS_SM } from 'ui/breakpoints';
import { showErrorMessage, Spinner } from 'ui/components';
import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import { WrappedMessage } from 'utils';
import libraryMessages from '../displayMessages';
import { OrganizationPeopleDashboard } from '../OrganizationPeopleDashboard/OrganizationPeopleDashboard';
import { OrganizationProfileDashboard } from '../OrganizationProfileDashboard/OrganizationProfileDashboard';
import { OrganizationSourcesDashboard } from '../OrganizationSourcesDashboard/OrganizationSourcesDashboard';
import messages from './displayMessages';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { intl } from 'i18n';
import uiMessages from 'ui/components/displayMessages';

interface MatchProps {
    slug: string;
}

interface ReduxStateProps {
    loggedInUsername: string;
    isLoggedin?: boolean;
    userPermissions?: APIPermissions;
}

interface Props extends RouteComponentProps<MatchProps> { }

interface State {
    isMobileView: boolean;
    // All the organizations that this user is explictly a member of:
    myOrganizations: OrganizationMembership[];
    // The organization being viewed. If the user is an admin they may not be a member.
    organization?: OrganizationResponse;
    unauthorized: boolean;
}

export class OrganizationDashboardPageInternal extends React.PureComponent<Props & ReduxStateProps, State> {

    private mediaQuery = UI_IS_SM;  // This matches Bootstrap's definition of small (sm) breakpoint.

    constructor(props: Props & ReduxStateProps) {
        super(props);
        this.state = {
            isMobileView: false,
            myOrganizations: [],
            unauthorized: false,
        };
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
        this.loadOrganization();
        let myOrganizations;
        try {
          myOrganizations = await OrganizationsApi.organizationMembershipsList();
          await this.verifyPermissions();
        } catch (error) {
          this.setState({unauthorized: true});
          return;
        }
        this.setState({myOrganizations});
    }

    public async componentDidUpdate(prevProps: Props & ReduxStateProps) {
        if (this.props.match.params.slug !== prevProps.match.params.slug
              || this.props.isLoggedin !== prevProps.isLoggedin) {
            await this.loadOrganization();
            let myOrganizations;
            try {
              myOrganizations = await OrganizationsApi.organizationMembershipsList();
              await this.verifyPermissions();
            } catch (error) {
              this.setState({unauthorized: true});
              return;
            }
            this.setState({myOrganizations});
        }
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    /// This is used to verify if the user can permission to see the organization dashboard
    public async verifyPermissions() {
        if (this.state.organization !== undefined) {
            await OrganizationsApi.contentSources({id: this.state.organization.org.slug});
        }
    }

    public render() {
        if (this.state.organization === undefined) {
            return <StandardPageLayout><Spinner /></StandardPageLayout>;
        }
        if (this.state.unauthorized) {
            /// If need login, then show the registration gate page
            return <RegistrationGate
              title={intl.formatMessage(messages.notLoggedInOrgDashboardTitle)}
              body={intl.formatMessage(messages.notLoggedInOrgDashboardBody)}
              primaryButtonText={!this.props.isLoggedin ? uiMessages.uiSignUp : undefined}
              secondaryButtonText={!this.props.isLoggedin ? uiMessages.uiSignIn : uiMessages.uiLogOut}
              secondaryButtonOnClick={!this.props.isLoggedin ? undefined : this.handleLogOut}
              image='/assets/images/access.svg'
            />;
        }
        return (
            <StandardPageLayout
                pageTitle={this.pageTitle()}
                headerFeature={
                    <OrganizationDashboardHeader
                        userPermissions={this.props.userPermissions}
                        organization={this.state.organization}
                        links={
                            this.state.myOrganizations
                            .filter(organization => organization.organization.slug !== this.props.match.params.slug)
                            .map((organization) => ({
                                key: organization.organization.slug,
                                content: <WrappedMessage
                                    message={libraryMessages.organizationDashboard}
                                    values={{organizationName: organization.organization.name}}
                                />,
                                to: ROUTES.Dashboard.ORGANIZATION_CONTENT_SLUG(organization.organization.slug),
                            }))
                        }
                    />
                }>
                {this.renderContent()}
            </StandardPageLayout>
        );
    }

    private async loadOrganization() {
        const response = await OrganizationsApi.read({id: this.props.match.params.slug});
        if (response?.org.slug === this.props.match.params.slug) {// Check in case slug has changed
            this.setState({organization: response});
        } else {
            showErrorMessage('Unable to load that organization.');
        }
    }

    private pageTitle() {
        switch (this.props.match.path) {
            case ROUTES.Dashboard.ORGANIZATION_CONTENT: {
                return messages.contentPageTitle;
            }
            default: {
                return undefined;
            }
        }
    }

    private renderContent() {
        if (this.state.organization === undefined) {
            return null;
        }
        const orgData: OrganizationResponse = this.state.organization;
        // We use render={} below to avoid loading each dashboard tab before it's needed,
        // while still being able to pass in the 'organizationData' instead of reloading
        // it for each tab.
        return <Switch>
            <Route exact path={ROUTES.Dashboard.ORGANIZATION_CONTENT} render={() => (
                <OrganizationContentDashboard organizationData={orgData}/>
            )}/>
            <Route exact path={ROUTES.Dashboard.ORGANIZATION_PROFILE_ADMIN} render={() => (
                <OrganizationProfileDashboard organizationData={orgData}/>
            )}/>
            <Route exact path={ROUTES.Dashboard.ORGANIZATION_PEOPLE_ADMIN} render={() => (
                <OrganizationPeopleDashboard organizationData={orgData}/>
            )}/>
            <Route exact path={ROUTES.Dashboard.ORGANIZATION_SOURCES_ADMIN} render={() => (
                <OrganizationSourcesDashboard organizationData={orgData}/>
            )}/>
        </Switch>;
    }

    @bind private setIsMobileView(): void {
        this.setState({ isMobileView: this.mediaQuery.matches });
    }

    private handleLogOut() {
        LoginActions.logout();
    }
}

export const OrganizationDashboardPage = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        isLoggedin: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
        userPermissions: getUserPermissions(state),
    }),
)(OrganizationDashboardPageInternal);
