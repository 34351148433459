import { bind } from 'bind-decorator';
import update from 'immutability-helper';
import { AnnotatedVideoQuestion, AnnotatedVideoQuestionAnswer } from 'labxchange-client';
import * as React from 'react';
import * as UI from 'ui/components';
import { CardFauxAdd } from 'ui/components/Card/Cards';
import { intl } from 'i18n';
import classNames from 'classnames';

import messages from './displayMessages';

interface Props {
    question?: AnnotatedVideoQuestion;
    onChange(question: AnnotatedVideoQuestion): void;
    showQuestionError?: boolean;
    showAnswersError?: boolean;
}
interface State {
    questionValue: string;
    answersValue: AnnotatedVideoQuestionAnswer[];
}

export class ProblemEditor extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const question = this.props.question;
        this.state = {
            questionValue: (question && question.question) || '',
            answersValue: question?.answers || this.getDefaultAnswers(),
        };
    }

    private getDefaultAnswers() {
        return [
            {id: '', text: '', correct: true, feedback: ''},
            {id: '', text: '', correct: false, feedback: ''},
            {id: '', text: '', correct: false, feedback: ''},
        ];
    }

    private renderAnswers() {
        const answers = this.state.answersValue;
        return <ol className='annotated-video-problem-editor-answers'>
            {answers.map((answer, index) =>
                <AnswerEditor
                    key={index}
                    index={index}
                    text={answer.text}
                    correct={answer.correct}
                    feedback={answer.feedback}
                    onDelete={() => this.onDeleteAnswer(index)}
                    onChange={
                        (text, correct, feedback) =>
                        this.updateAnswer(index, text, correct, feedback)}/>
            )}
        </ol>;
    }

    @bind private onAddAnswer() {
        this.setState({
            answersValue: update(
                this.state.answersValue,
                {$push: [{id: '', text: '', correct: false, feedback: ''}]}
            )
        });
    }

    @bind private onQuestionChange(value: string) {
        this.setState({questionValue: value}, () => this.onChange());
    }

    @bind private onChange() {
        const question = this.props.question || {} as AnnotatedVideoQuestion;
        question.question = this.state.questionValue;
        question.answers = this.state.answersValue;
        this.props.onChange(question);
    }

    @bind private onDeleteAnswer(index: number) {
        const answers = [...this.state.answersValue];
        answers.splice(index, 1, );
        this.setState({answersValue: answers}, () => this.onChange());
    }

    @bind private updateAnswer(
        index: number, text: string, correct: boolean, feedback: string) {
        const answers = [...this.state.answersValue];
        const answer = answers[index];
        answer.text = text;
        answer.correct = correct;
        answer.feedback = feedback;
        answers.splice(index, 1, answer);
        this.setState({answersValue: answers}, () => this.onChange());
    }

    public render() {
        return <div className='annotated-video-problem-editor'>
            <div className='form-group' data-testid='question-input'>
                <UI.HtmlTextBox
                    showErrors={this.props.showQuestionError}
                    required={true}
                    label={intl.formatMessage(messages.annotationEditorQuestionInputLabel)}
                    placeholder={intl.formatMessage(messages.annotationEditorDescriptionInputPlaceholder)}
                    defaultValue={this.state.questionValue}
                    editorStyle={UI.HtmlTextBox.EditorStyle.Simple}
                    onChange={this.onQuestionChange}
                />
            </div>
            <UI.ItemSection
                extraClasses={
                    this.props.showAnswersError
                    ? 'item-section-error' : ''}
                isMandatory={true}
                sectionName={intl.formatMessage(messages.annotationEditorAnswersSectionName)}>
                {this.renderAnswers()}
                <CardFauxAdd
                    additionalStyling='add-answer'
                    message={messages.annotationEditorQuestionAddAnswerButtonLabel}
                    onClick={this.onAddAnswer}/>
            </UI.ItemSection>
        </div>;
    }
}

interface AnswerEditorProps {
    index: number;
    text?: string;
    correct?: boolean;
    feedback?: string;
    onChange(text: string, correct: boolean, feedback: string): void;
    onDelete(): void;
    showErrors?: boolean;
}

interface AnswerEditorState {
    showFeedback: boolean;
}

class AnswerEditor extends React.PureComponent<AnswerEditorProps, AnswerEditorState> {
    constructor(props: AnswerEditorProps) {
        super(props);
        this.state = {
            showFeedback: false,
        };
    }

    public render() {
        return <>
        {this.props.index!==0 && <hr className='divider' />}
        <li className='annotated-video-problem-editor-answer'>
            <input
                type='checkbox'
                aria-hidden={false}
                defaultChecked={this.props.correct}
                onBlur={this.onChangeCorrectInput}
                onChange={this.onChangeCorrectInput}/>
            <UI.ToggleTextSwitch
                isChecked={this.props.correct ?? false}
                onToggle={this.onChangeCorrect}
                extraClass='annotated-video-problem-editor-answer-correct-slider'
            />
            <div className={classNames(
                        'answer-input',
                        'form-group',
                        {'lx-input-error': this.props.showErrors},
                    )}
                    data-testid={`answer-text-${this.props.index}`}>
                <div className='answer-div flex-div'>
                    <UI.HtmlTextBox
                        label={''}
                        hideLabel={true}
                        hideAccessiblityLink={true}
                        placeholder={intl.formatMessage(messages.annotationEditorQuestionAnswerPlaceholder)}
                        defaultValue={this.props.text}
                        editorStyle={UI.HtmlTextBox.EditorStyle.OnlyScripts}
                        onChange={(value) => this.onChangeText(value)}
                    />
                    <UI.Button
                        label={messages.annotationEditorQuestionRemoveButtonLabel}
                        btnStyle='unstyled'
                        icon='trashcan'
                        iconOnly={true}
                        onClick={this.props.onDelete}/>
                </div>

                <div className='feedback-div flex-div'>
                    {!this.state.showFeedback ? (
                        <div className='add-feedback-button'>
                            <UI.Button
                                label={messages.annotationEditorQuestionAnswerAddFeedbackLink}
                                onClick={this.toggleFeedback}
                                btnStyle='link'
                            />
                        </div>
                    ) : (
                        <>
                            <UI.HtmlTextBox
                                label={intl.formatMessage(messages.annotationEditorQuestionAnswerFeedbackLabel)}
                                hideAccessiblityLink={true}
                                placeholder={intl.formatMessage(messages.annotationEditorQuestionAnswerFeedbackLabel)}
                                defaultValue={this.props.feedback}
                                editorStyle={UI.HtmlTextBox.EditorStyle.OnlyScripts}
                                onChange={(value) => this.onChangeFeedback(value)}
                            />
                            <UI.Button
                                label={messages.annotationEditorQuestionRemoveButtonLabel}
                                btnStyle='unstyled'
                                icon='trashcan'
                                iconOnly={true}
                                onClick={this.toggleFeedback}
                            />
                        </>
                    )}
                </div>
            </div>
        </li>
        </>;
    }

    @bind private toggleFeedback() {
        this.setState((prevState) => ({
            showFeedback: !prevState.showFeedback
        }));
    }

    @bind private onChangeCorrect(correct: boolean) {
        this.props.onChange(this.props.text || '', correct, this.props.feedback || '');
    }

    @bind private onChangeText(answerTextValue: string) {
        this.props.onChange(answerTextValue, this.props.correct || false, this.props.feedback || '');
    }

    @bind private onChangeCorrectInput(event: React.SyntheticEvent<HTMLInputElement>) {
        this.props.onChange(this.props.text || '', !this.props.correct, this.props.feedback || '');
    }

    @bind private onChangeFeedback(answerFeedbackValue: string) {
        this.props.onChange(this.props.text || '', this.props.correct || false, answerFeedbackValue);
    }
}
