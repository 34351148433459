import * as React from 'react';
import { Button } from 'ui/components';
import { MessageDescriptor } from 'react-intl';
import { IconSymbol } from 'elements';

interface Props {
    icon: IconSymbol;
    label: MessageDescriptor;
    labelValues?: any;
    onClick?: () => void;
    ['data-testid']?: string;
}

export const SSOButton: React.FunctionComponent<Props> = (props) => {
    return (
        <Button
            className='auth-button sso-button'
            btnStyle='primary'
            label={props.label}
            labelValues={props.labelValues}
            icon={props.icon}
            onClick={props.onClick}
            size='sm'
            data-testid={props['data-testid']}
        />
    );
};
