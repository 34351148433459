// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CurriculumSubject,
    CurriculumSubjectFromJSON,
    CurriculumSubjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface CurriculumDetail
 */
export interface CurriculumDetail {
    /**
     * 
     * @type {number}
     * @memberof CurriculumDetail
     */
    readonly id?: number;
    /**
     * 
     * @type {Array<CurriculumSubject>}
     * @memberof CurriculumDetail
     */
    subjects: Array<CurriculumSubject>;
    /**
     * 
     * @type {string}
     * @memberof CurriculumDetail
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumDetail
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumDetail
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurriculumDetail
     */
    isPublic?: boolean;
    /**
     * Please provide learning objective title as singular i.e. `Core concept` instead of `Core Concepts`The pluralization will be handled on the frontend based on the number of learning objectivesthat needs to be shown under the heading
     * @type {string}
     * @memberof CurriculumDetail
     */
    learningObjectivesHeading?: string;
}

export function CurriculumDetailFromJSON(json: any): CurriculumDetail {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subjects': (json['subjects'] as Array<any>).map(CurriculumSubjectFromJSON),
        'country': json['country'],
        'slug': json['slug'],
        'name': json['name'],
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'learningObjectivesHeading': !exists(json, 'learning_objectives_heading') ? undefined : json['learning_objectives_heading'],
    };
}

export function CurriculumDetailToJSON(value?: CurriculumDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'subjects': (value.subjects as Array<any>).map(CurriculumSubjectToJSON),
        'country': value.country,
        'slug': value.slug,
        'name': value.name,
        'is_public': value.isPublic,
        'learning_objectives_heading': value.learningObjectivesHeading,
    };
}


