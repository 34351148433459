import { SectionSlab, FeaturedItems, ModuleSlab } from 'labxchange-client';
import { ItemType } from 'items/models';

import { SelectDropdownOption } from './types';
import { biologyWithTopicLinks, Topic } from './SubjectsPage';
import { ROUTES } from 'global/constants';

export const convertSlabToFeaturedItems = (slab: SectionSlab | ModuleSlab): FeaturedItems => {
  return {
    name: '',
    description: '',
    items: slab.items || [],
    seeMoreLink: '',
  };
};

export const generateSubjectDropdownOptions = (subject: string) => [
  {
    value: `${subject}-middle`,
    label: 'Middle School'
  },
  {
    value: `${subject}-high`,
    label: 'High School'
  },
  {
    value: `${subject}-advanced`,
    label: 'Advanced'
  }
];

interface SlugToTopicsMap {
  [key: string]: Topic[];
}

export const slugToTopicsMap: SlugToTopicsMap = {
  'biology-middle': biologyWithTopicLinks.data.MIDDLE_SCHOOL.topics.filter(topic => topic.url !== '#'),
  'biology-high': biologyWithTopicLinks.data.HIGH_SCHOOL.topics.filter(topic => topic.url !== '#'),
  'biology-advanced': biologyWithTopicLinks.data.ADVANCED.topics.filter(topic => topic.url !== '#' && topic.name.startsWith('AP:'))
};

export enum Subjects {
  Biology = 'biology',
  Chemistry = 'chemistry',
  Physics = 'physics',
  EarthSpace = 'earth-space',
}

export const contentSubjectsOptions: SelectDropdownOption[] = [
  {
    value: `${Subjects.Biology}-content`,
    label: 'Biology'
  },
  {
    value: `${Subjects.Chemistry}-content`,
    label: 'Chemistry'
  },
  {
    value: `${Subjects.Physics}-content`,
    label: 'Physics'
  },
];

export enum ContentPageTypes {
  Simulations = 'simulations',
  Interactives = 'interactives',
  Pathways = 'pathways',
  CaseStudies = 'case-studies',
  Clusters = 'clusters',
  Videos = 'videos',
  QuestionSets = 'question-sets',
  TeachingGuides = 'teaching-guides',
  AnnotatedVideos = 'annotated-videos',
}

export const getContentTypesOptions = (subject?: string): SelectDropdownOption[] => {
  const options: SelectDropdownOption[] = [
    {
      value: ContentPageTypes.Simulations,
      label: 'Simulations',
    },
    {
      value: ContentPageTypes.Interactives,
      label: 'Interactives',
    }
  ];

  if (subject?.includes(Subjects.Biology) || subject?.includes(Subjects.Chemistry)) {
    options.push({
      value: ContentPageTypes.AnnotatedVideos,
      label: 'Annotated Videos',
    });
  }

  return options;
};

export const getSubjectOptionValue = (subjectSlug: string): SelectDropdownOption | undefined => {
  return contentSubjectsOptions.find(option => option.value.includes(subjectSlug));
};

export const getContentTypeOptionValue = (contentTypeSlug: string, subject?: string): SelectDropdownOption | undefined => {
  return getContentTypesOptions(subject).find(option => option.value === contentTypeSlug);
};

export const getSubjectContentTypes = (subject: string) => {
  return [
      {
          name: 'Simulations',
          image: '/assets/images/content-types/simulations.svg',
          itemType: ItemType.Simulation,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.Simulation}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : ROUTES.Subject.CONTENT_SLUG(subject, ContentPageTypes.Simulations),
        },
        {
          name: 'Interactives',
          image: '/assets/images/content-types/interactives.svg',
          itemType: ItemType.Interactive,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.Interactive}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : ROUTES.Subject.CONTENT_SLUG(subject, ContentPageTypes.Interactives),
        },
        {
          name: 'Pathways',
          image: '/assets/images/content-types/pathways.svg',
          itemType: ItemType.Pathway,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.Pathway}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : `/library?t=ItemType%3A${ItemType.Pathway}`,
        },
        {
          name: 'Case studies',
          image: '/assets/images/content-types/case_studies.svg',
          itemType: ItemType.CaseStudy,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.CaseStudy}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : `/library?t=ItemType%3A${ItemType.CaseStudy}`,
        },
        {
          name: 'Clusters',
          image: '/assets/images/content-types/clusters.svg',
          itemType: ItemType.Cluster,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.Cluster}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : `/library?t=ItemType%3A${ItemType.Cluster}`,
        },
        {
          name: 'Videos',
          image: '/assets/images/content-types/videos.svg',
          itemType: ItemType.Video,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.Video}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : `/library?t=ItemType%3A${ItemType.Video}`,
        },
        {
          name: 'Question sets',
          image: '/assets/images/content-types/question_sets.svg',
          itemType: ItemType.Question,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.Question}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : `/library?t=ItemType%3A${ItemType.Assignment}`,
        },
        {
          name: 'Teaching guides',
          image: '/assets/images/content-types/teaching_guides.svg',
          itemType: ItemType.TeachingGuide,
          url: subject === 'ap-biology' ? `/library?t=ItemType%3A${ItemType.TeachingGuide}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : `/library?t=ItemType%3A${ItemType.TeachingGuide}`,
        },
        {
          name: 'Annotated videos',
          image: '/assets/images/content-types/annotated_videos.svg',
          itemType: ItemType.AnnotatedVideo,
          url: subject === 'ap-biology'? `/library?t=ItemType%3A${ItemType.AnnotatedVideo}&t=SubjectArea%3ABiological+Sciences&t=Tag%3AAP%20Biology` : ROUTES.Subject.CONTENT_SLUG(subject, ContentPageTypes.AnnotatedVideos),
        }
  ];
};
