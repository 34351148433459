import React from 'react';

import { SectionSlab } from 'labxchange-client';

import { convertSlabToFeaturedItems } from '../utils';
import { FeatureCard } from './FeatureCard';

interface FeatureSlabProps {
  heading: string;
  slab: SectionSlab;
}

export const FeatureCardSlab: React.FC<FeatureSlabProps> = (props) => {
  const slab = convertSlabToFeaturedItems(props.slab);
  if (slab && slab.items) {
    return (
      <div className='feature-card-container container'>
        <h1 className='slab-title'>{props.heading}</h1>
        <FeatureCard item={slab.items[0]} />
      </div>
    );
  }

  return null;
};

export default FeatureCardSlab;
