import * as React from 'react';
import { ROUTES } from 'global/constants';
import { Icon, NavTabs, NavTabsLink } from 'ui/components';
import messages from 'search/displayMessages';
import { RouteComponentProps } from 'react-router';
import { SearchBox } from 'search/components/SearchBox';
import { showSearchBarInHero } from 'utils';
import { intl } from 'i18n';

interface Props extends RouteComponentProps<{}> {
    searchUrl: string;
    searchTitleUrl: string;
    searchParams: URLSearchParams;
    onToggleFilter: () => void;
}

export const LibrarySearchHeader: React.FC<Props> = (props) => {
    const searchParams = props.searchParams.getAll('t');
    // there is always one search query
    const searchTerm = props.searchParams.getAll('q')[0];

    return <div className='search-header'>
        {/* We will hide the search bar from the header on the library,
        people, and sources pages, and show it instead in the hero section. */}
        {showSearchBarInHero() && <div className='search-bar-container'>
            <form className='search-bar form-inline mx-2'>
                <SearchBox
                    searchUrl={props.searchUrl}
                    searchTitleUrl={props.searchTitleUrl}
                    darkBackground={false}
                    searchParams={searchParams}
                    searchTerm={searchTerm}
                />
            </form>
            <button
                className='btn filter-toggle'
                onClick={props.onToggleFilter}
                title={intl.formatMessage(messages.toggleFilters)}
            >
                <Icon name='filter'/>
            </button>
        </div>}

        <NavTabs>
        <NavTabsLink
            to={`${ROUTES.Library.HOME}${props.location.search}`} exact
            message={messages.searchTabContent}
        />
        <NavTabsLink
            to={`${ROUTES.People.HOME}${props.location.search}`}
            message={messages.searchTabPeople}
        />
        <NavTabsLink
            to={`${ROUTES.Organizations.HOME}${props.location.search}`}
            message={messages.searchTabOrganizations}
        />
        </NavTabs>
    </div>;
};
