// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadCreate
 */
export interface ThreadCreate {
    /**
     * 
     * @type {string}
     * @memberof ThreadCreate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadCreate
     */
    subCategory: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadCreate
     */
    content: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThreadCreate
     */
    freeFormTags?: Array<string>;
}

export function ThreadCreateFromJSON(json: any): ThreadCreate {
    return {
        'title': json['title'],
        'subCategory': json['sub_category'],
        'content': json['content'],
        'freeFormTags': !exists(json, 'free_form_tags') ? undefined : json['free_form_tags'],
    };
}

export function ThreadCreateToJSON(value?: ThreadCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'sub_category': value.subCategory,
        'content': value.content,
        'free_form_tags': value.freeFormTags,
    };
}


