import update from 'immutability-helper';

import * as UIActions from './actions';
import { UIStateModel } from './models';

/**
 * Reducer that maintains the state of the user interface
 */
export function uiStateReducer(
    state: Readonly<UIStateModel> = new UIStateModel(),
    action: UIActions.ActionTypes,
): UIStateModel {
    switch (action.type) {
        case UIActions.Types.HIDE_SIDEBAR:
            return update(state, {sidebarVisible: {$set: false}});
        case UIActions.Types.SHOW_SIDEBAR:
            return update(state, {sidebarVisible: {$set: true}});
        default:
            return state;
    }
}
