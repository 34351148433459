import { authLocalStorageManager } from 'auth/utils';
import bind from 'bind-decorator';
import * as React from 'react';
import { CalendarDate, DateInput } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from '../displayMessages';

interface SignupBirthdateProps {
    minimumAge?: number;
    onCancel: () => void;
    onSuccess: () => void;
    onFailure: () => void;
}

interface SignupBirthdateState {
    date?: CalendarDate;
}

export class SignupBirthdate extends React.PureComponent<SignupBirthdateProps, SignupBirthdateState> {

    constructor(props: SignupBirthdateProps) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <div className='signup-birthdate'>
                <div className='logo'></div>
                <h1><WrappedMessage message={messages.signUpBirthDateQuestion}/></h1>
                <div><WrappedMessage message={messages.signUpBirthDateDescription}/></div>
                <form>
                    <DateInput
                        label={messages.signupBirthDateLabel}
                        onChange={(date) => {
                            this.setState({date});
                        }}
                    />
                    <button
                        className='cancel-button btn btn-outline-primary'
                        onClick={() => this.props.onCancel()}>
                        <WrappedMessage message={messages.cancelButtonLabel}/>
                    </button>
                    <button
                        type='submit'
                        onClick={this.onSubmit}
                        disabled={this.isSubmitDisabled()}
                        aria-disabled={this.isSubmitDisabled()}
                        className='btn btn-primary'>
                        <WrappedMessage message={messages.nextButtonLabel}/>
                    </button>
                </form>
            </div>
        );
    }

    private isSubmitDisabled() {
        if (this.state.date === undefined) {
            return true;
        }
        return false;
    }

    @bind private onSubmit(event: React.BaseSyntheticEvent) {
        event.preventDefault();
        if (this.state.date === undefined) { return; }
        const minimumAge = this.props.minimumAge || 13;
        if (this.state.date.ageInYears >= minimumAge) {
            authLocalStorageManager.userBirthdate = this.state.date;
            this.props.onSuccess();
            return;
        }
        this.props.onFailure();
    }
}
