import { Action } from 'redux';

import { LibraryApi } from 'global/api';
import { Subject, PathwayDetail } from 'labxchange-client';
import { LXThunkAction, LXThunkDispatch } from 'global/types';
import { getStore } from 'global/store';
export enum Types {
    FETCHING_SUBJECT_AREAS = 'FETCHING_SUBJECT_AREAS',
    FETCHED_SUBJECT_AREAS = 'FETCHED_SUBJECT_AREAS',
    FETCHED_LIBRARY_COUNT = 'FETCHED_LIBRARY_COUNT',
    LOAD_CURRENT_PATHWAY = 'LOAD_CURRENT_PATHWAY',
    CLOSE_CONGRATULATIONS_MODAL = 'CLOSE_CONGRATULATIONS_MODAL',
}

export interface FetchingSubjectTags extends Action {
    readonly type: Types.FETCHING_SUBJECT_AREAS;
}
export interface FetchedSubjectTags extends Action {
    readonly type: Types.FETCHED_SUBJECT_AREAS;
    readonly success: boolean;
    readonly subjectTags: Subject[];
}

export interface FetchedLibraryCount extends Action {
    readonly type: Types.FETCHED_LIBRARY_COUNT;
    readonly totalPublicAssetCount: string;
}

export interface SetCurrentPathway extends Action {
    readonly type: Types.LOAD_CURRENT_PATHWAY;
    readonly id: string;
    readonly pathway: PathwayDetail;
}

export interface CloseCongratulationModal extends Action {
    readonly type: Types.CLOSE_CONGRATULATIONS_MODAL;
}

export type ActionTypes = FetchingSubjectTags|
    FetchedSubjectTags|
    SetCurrentPathway|
    CloseCongratulationModal|
    FetchedLibraryCount;

export const fetchSubjectTags = (): LXThunkAction<void> => {
    return async (dispatch: LXThunkDispatch) => {
        dispatch({type: Types.FETCHING_SUBJECT_AREAS});
        try {
            const subjectTags = await LibraryApi.getSubjectTags();
            dispatch({
                type: Types.FETCHED_SUBJECT_AREAS,
                success: true,
                subjectTags,
            });
        } catch {
            dispatch({
                type: Types.FETCHED_SUBJECT_AREAS,
                success: false,
                subjectTags: [],
            });
        }
    };
};

export const fetchTotalPublicAssetCount = (): LXThunkAction<void> => async (dispatch, getState) => {
    try {
        const totalPublicAssetCount = await LibraryApi.getPublicItemsCount();
        dispatch({
            type: Types.FETCHED_LIBRARY_COUNT,
            totalPublicAssetCount: Number(totalPublicAssetCount)?.toLocaleString(),  // add thousand separator
        });
    } catch (err) {
        dispatch({
            type: Types.FETCHED_LIBRARY_COUNT,
            totalPublicAssetCount: '',
        });
    }
};

export async function fetchTotalPublicAssetCountNow() {
    await getStore().dispatch(fetchTotalPublicAssetCount() as any);
}

export const setCurrentPathway = (id: string, pathway: PathwayDetail): LXThunkAction<void> => {
    return async (dispatch: LXThunkDispatch) => {
        dispatch({type: Types.LOAD_CURRENT_PATHWAY, id, pathway});
    };
};

export const closeCongratulationModal = (): LXThunkAction<void> => {
    return async(dispatch: LXThunkDispatch) => {
        dispatch({type: Types.CLOSE_CONGRATULATIONS_MODAL});
    };
};
