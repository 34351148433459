import { ROUTES } from 'global/constants';
import {
    DiscussionAuthor,
    DiscussionsMessage,
} from 'labxchange-client';
import { MentorshipEmbedCreated } from 'mentorship/components/MentorshipEmbedCreated';
import { MentorshipEmbedResponded } from 'mentorship/components/MentorshipEmbedResponded';
import moment from 'moment-shortformat';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { UserAvatar } from 'user/components/UserAvatar';

interface MessagePostSeparatorProps {
    created?: Date;
}

export const MessagePostSeparator: React.FunctionComponent<MessagePostSeparatorProps> = ({ created }) => (
    <div className='message-post-separator'>
        <hr />
        <span>
            {created
                ? moment(created).calendar(undefined, {
                    sameDay: '[Today]',
                    lastDay: '[Yesterday]',
                    lastWeek: '[Last] dddd',
                    sameElse: 'DD/MM/YYYY',
                })
                : ''
            }
        </span>
        <hr />
    </div>
);

interface MessagePostItemProps {
    message: Readonly<DiscussionsMessage>;
    sameDay?: boolean;
    sameUser?: boolean;
    user?: Readonly<DiscussionAuthor>;
    loggedInUsername: string;
}

export class MessagePostItem extends React.Component<MessagePostItemProps> {
    public render() {
        const { message, sameDay, sameUser, user } = this.props;
        const yours = user && (user.username === this.props.loggedInUsername);
        return (
            <>
                {!sameDay && <MessagePostSeparator created={message.created} />}
                <div className={`message-post-item list-group-item ${yours ? 'mine' : ''}`} role='group'>
                    <div className='message-post-avatar'>
                        {user && !sameUser &&
                            <NavLink
                                to={ROUTES.Users.PROFILE_SLUG(user.username)}
                                aria-hidden='true'
                                tabIndex={-1}
                            >
                                <UserAvatar username={user.username} diameter='30px' />
                            </NavLink>}
                    </div>
                    <div className='message-post-details'>
                        {user && !sameUser &&
                            <div className='message-post-name'>
                                <a href={ROUTES.Users.PROFILE_SLUG(user.username)}  tabIndex={-1}>{user.fullName}</a>
                            </div>
                        }
                        {!message.embed ?
                            <div
                                aria-label={message.content}
                                className='message-post-content'
                                dangerouslySetInnerHTML={{ __html: message.content }}
                                tabIndex={-1}
                            />
                            : this.renderEmbed()}
                    </div>
                    <div className='message-post-padder' />
                </div>
            </>
        );
    }

    private renderEmbed() {
        try {
            const message = this.props.message;
            if (message.embed) {
                const embed = message.embed.data;
                const app = embed.type.substr(0, embed.type.indexOf('.'));
                const type = embed.type.substr(embed.type.indexOf('.') + 1);
                switch (app) {
                    case 'mentorship': {
                        if (type === 'pending.mentor' || type === 'pending.mentee') {
                            return (<MentorshipEmbedCreated type={type} embed={embed} />);
                        } else {
                            return (
                                <MentorshipEmbedResponded type={type} embed={embed} />
                            );
                        }
                    }
                }
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }
        return null;
    }
}
