import * as React from 'react';
import { Modal } from 'elements/components/Modals';
import { Spinner } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from 'library/displayMessages';

interface Props {
    onClose: () => void;
}

export class SavingAnnotatedVideoModal extends React.PureComponent<Props> {
    render() {
        return (
            <>
                <Modal
                    className='text-center saving-annotated-video-modal'
                    modalExtraClassName='saving-annotated-video-modal-overlay'
                    onRequestClose={this.props.onClose}
                    size='normal'
                    showTopBar={true}
                    content={
                        <div>
                            <div className='lx-spinner'><Spinner size='96px'/></div>
                            <h1><WrappedMessage message={messages.savingAnnotatedVideoModalTitle} /></h1>
                            <div className='content'>
                                <WrappedMessage message={messages.savingAnnotatedVideoModalText} />
                            </div>
                        </div>
                    }
                />
            </>
        );
    }
}
