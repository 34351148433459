// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadUpdate
 */
export interface ThreadUpdate {
    /**
     * 
     * @type {string}
     * @memberof ThreadUpdate
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof ThreadUpdate
     */
    pinStatus?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ThreadUpdate
     */
    freeFormTags?: Array<string>;
}

export function ThreadUpdateFromJSON(json: any): ThreadUpdate {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        'pinStatus': !exists(json, 'pin_status') ? undefined : json['pin_status'],
        'freeFormTags': !exists(json, 'free_form_tags') ? undefined : json['free_form_tags'],
    };
}

export function ThreadUpdateToJSON(value?: ThreadUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'pin_status': value.pinStatus,
        'free_form_tags': value.freeFormTags,
    };
}


