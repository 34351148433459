import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Redirect } from 'react-router';

import { CreateItemModal } from 'items/components/CreateItemModal';
import { LibraryContentList } from 'library/components/LibraryContentList';
import { Button } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { DASHBOARD_SORT_OPTIONS } from 'global/constants';
import { SearchRequestOrderingEnum } from 'labxchange-client';
import { EmptyDashboardPage } from 'library/components/EmptyDashboardPage';

interface State {
    showCreateContentModal: boolean;
    redirectTo?: LocationDescriptor;
}

interface Props {
    isEducator?: boolean;
}

export class EducatorContentDashboard extends React.PureComponent<Props, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            showCreateContentModal: false,
        };
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }
        return (
            <div className='my-content-page'>
                <h1 className='sr-only'>
                    <WrappedMessage message={messages.pageTitle}/>
                </h1>
                <LibraryContentList
                    cardsStyle='portrait'
                    isModalView={false}
                    displayAddButton={false}
                    openCardDetailUrlsInNewTab={false}
                    searchOnlyOwnedByUser={true}
                    showCreateContentButton={true}
                    onCreateContent={this.onCreateContent}
                    isPublicContentOnly={false}
                    sortOrderOptions={DASHBOARD_SORT_OPTIONS}
                    defaultSortOrder={SearchRequestOrderingEnum.LastAdded}
                    placeholderView={this.renderEmptyPage()}
                    disableDefaultFilters={true}
                    showCompletion={false}
                    isKebabMenuVisible={true}
                />
                {
                    this.state.showCreateContentModal ? (
                        <CreateItemModal
                            onRequestClose={this.onRequestCloseCreateContentModal}
                            onCloseAndRedirect={this.onCloseAndRedirect}
                        ></CreateItemModal>
                    ) : null
                }
            </div>
        );
    }

    @bind private onCreateContent() {
        this.setState({
            showCreateContentModal: true,
        });
    }

    @bind private onRequestCloseCreateContentModal() {
        this.setState({
            showCreateContentModal: false,
        });
    }

    @bind private onCloseAndRedirect(location: LocationDescriptor) {
        this.setState({
            redirectTo: location,
        });
    }

    private renderEmptyPage() {
        return <EmptyDashboardPage
            title={messages.emptyContentLibraryTitle}
            secondaryText={this.emptyPagesecondaryText()}
            buttons={this.emptyPageActionButtons()}
        />;
    }

    private emptyPagesecondaryText() {
        if (this.props.isEducator) {
            return messages.emptyContentLibrarySecondaryTextEducator;
        }
        return messages.emptyContentLibrarySecondaryText;
    }

    private emptyPageActionButtons() {
        return (
            <Button
                onClick={this.onCreateContent}
                btnStyle='primary'
                className='add-link'
                label={messages.emptyContentLibraryAction}
            ></Button>
        );
    }
}
