import React from 'react';
import messages from './displayMessages';
import { WrappedMessage, useLayoutSize } from 'utils';
import { FilterButtons } from './FilterButtons';
import { Link } from 'react-router-dom';
import { ROUTES } from 'global/constants';
import { CardsList } from './CardsList';
import { convertSlabToFeaturedItems } from 'subjects/components/page/utils';
import { SectionSlab } from 'labxchange-client';
import { CardSkeleton } from './CardDetail';
import Skeleton from 'react-loading-skeleton';
import { SectionSlabsApi } from 'global/api';

interface TopicsContainerProps {
    isUserLoggedIn: boolean;
    selectedSubject: string;
    subjectTopics: string[];
    interactiveSlabs: SectionSlab[];
    onSubjectTabSelection: (selectedOption: string) => void;
}

export const TopicsContainer:  React.FC<TopicsContainerProps> = (props) => {
    const {isUserLoggedIn, selectedSubject, subjectTopics, interactiveSlabs, onSubjectTabSelection} = props;
    const layoutSize = useLayoutSize();
    const isMobile = layoutSize === 'mobile' || layoutSize === 'small';
    const [selectedTopic, setSelectedTopic] = React.useState(subjectTopics[0]);
    const [currentTopicSlab, setCurrentTopicSlab] = React.useState<SectionSlab | undefined>(undefined);

    React.useEffect(() => {
        fetchSlab();
    }, [selectedTopic]);

    const fetchSlab = async () => {
        setCurrentTopicSlab(undefined);

        const topicSlab = interactiveSlabs.find(slab => slab.title === selectedTopic);
        if (topicSlab) {
            const response: SectionSlab = await SectionSlabsApi.slabsRead({ id: topicSlab.slug });
            if (response) {
                setCurrentTopicSlab(response);
            }
        }
    };

    const moreTopicsLink = (
        <Link className='underline-link mobile-row interactive-sec-more' to={ROUTES.Subjects}>
          <WrappedMessage
            message={messages.moreTopicsText}
            values={{ subject: selectedSubject.charAt(0).toUpperCase() + selectedSubject.slice(1).toLowerCase()}}
          />
        </Link>
    );

    const onTopicSelection = (topic: string) => {
        setSelectedTopic(topic);
    };

    return (
        <div className='cards-container section scroll-section'>
        {(isUserLoggedIn && !isMobile )?
            <>
                <h1><WrappedMessage message={messages.subjectFilterInteractiveHeading} /></h1>
                <div className='topic-selection'>
                    <FilterButtons
                        btnList={['Biology', 'Physics', 'Chemistry']}
                        btnSelected={selectedSubject.charAt(0).toUpperCase() + selectedSubject.slice(1)}
                        onButtonClick={onSubjectTabSelection}
                    />
                </div>
            </> :
            <>
                <div className='interactive-sec-heading'>
                    <h1><WrappedMessage message={messages.interactiveCardsHeading} /></h1>
                    {!isMobile && moreTopicsLink}
                </div>
                <div className='topic-selection'>
                    <FilterButtons
                        btnList={subjectTopics}
                        btnSelected={selectedTopic}
                        onButtonClick={onTopicSelection}
                        moreTopicsLink={isMobile && moreTopicsLink}
                    />
                </div>
            </>
        }
        <div className='list-cards'>
            <CardsList cards={currentTopicSlab && convertSlabToFeaturedItems(currentTopicSlab)} layoutSize={layoutSize} />
        </div>
    </div>
    );
};


export const TopicsContainerSkeleton = () => {
    const layoutSize = useLayoutSize();
    const isMobile = layoutSize === 'mobile' || layoutSize === 'small';

    const cardsSkeleton = Array.from({ length: 4 }).map((_, index) => (
        <li key={index}>
          <CardSkeleton />
        </li>
    ));

    const topicsSkeleton = (
        <div className='filter-buttons-container' role='toolbar'>
            {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton key={index} className='btn-filter btn-filter-skeleton' width={95} />
            ))};
        </div>
    );

    return (
        <div className='cards-container section scroll-section'>
            <div className='interactive-sec-heading'>
                <Skeleton width={isMobile ? 300 : 480} height={42} />
                {!isMobile &&
                    <a className='underline-link mobile-row interactive-sec-more' href='/subjects'>
                        <Skeleton width={80} height={16} />
                    </a>
                }

                </div>
                <div className='topic-selection'>
                    <div className='filter-buttons'>
                        <div className='filter-buttons-wrapper no-chevron-left'>
                           {topicsSkeleton}
                        </div>
                    </div>
                </div>
            <div className='list-cards'>
                <ul className='cards-ul list-unstyled'>
                    {cardsSkeleton}
                </ul>
            </div>
        </div>
    );
};
