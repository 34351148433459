import {defineMessages} from 'react-intl';

const messages = defineMessages({

    lxAuthSubjectSelectPageHeading: {
        id: 'lxAuthSubjectSelectPageHeading',
        defaultMessage: 'Last Step',
        description: 'Heading for subject select',
    },
    lxAuthSelectLevelPageHeading: {
        id: 'lxAuthSelectSubjectPageHeading',
        defaultMessage: 'Which grade level(s) do you teach?',
        description: 'Heading for subject select',
    },
    lxAuthSelectSubjects: {
        id: 'lxAuthSelectSubjects',
        defaultMessage: 'Which subject(s)?',
        description: 'Heading for subject select',
    },
    lxAuthSelectLevelSelectMessage: {
        id: 'lxAuthSelectLevelSelectMessage',
        defaultMessage: 'Select one or more',
        description: 'Heading for subject select',
    },
    subjectSelectButtonMid: {
        id: 'subjectSelectButtonMid',
        defaultMessage: 'Middle school',
        description: 'Heading for subject select',
    },
    subjectSelectButtonHigh: {
        id: 'subjectSelectButtonHigh',
        defaultMessage: 'High school',
        description: 'Heading for subject select',
    },
    subjectSelectButtonAdvance: {
        id: 'subjectSelectButtonAdvance',
        defaultMessage: 'Advanced',
        description: 'Heading for subject select',
    },
    subjectSelectButtonOther: {
        id: 'subjectSelectButtonOther',
        defaultMessage: 'Other',
        description: 'Heading for subject select',
    },
    subjectAddError: {
        id: 'subjectAddError',
        defaultMessage: 'An error occurred while saving educator\'s  preferences',
        description: 'Error message on saving subject preference',
    },
});

export default messages;
