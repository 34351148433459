import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define a context and a hook to provide easy access
const ColorContext = createContext<any | null>(null);

export const useColor = () => useContext(ColorContext);

export interface ColorScheme {
    class: string;
    color: string;
    iconColorFill: string;
}

const colorValues = {
    purpleIcon: '#7188C7',
    purpleBackground:'#E1E5F3',
    pinkIcon: '#E57295',
    pinkBackground:'#F9E1E8',
    tealIcon: '#80D2C9',
    tealBackground:'#CEEEEA',
    yellowIcon: '#FCC885',
    yellowBackground:'#FEEBD3',
};

export const colorClasses : ColorScheme[] = [
    { class: 'bluish-purple-background', color: colorValues.pinkBackground, iconColorFill: colorValues.purpleIcon  },
    { class: 'pinkish-red-background', color: colorValues.pinkBackground, iconColorFill: colorValues.pinkIcon },
    { class: 'teal-background', color: colorValues.tealBackground, iconColorFill: colorValues.tealIcon },
    { class: 'yellow-background', color: colorValues.yellowBackground, iconColorFill: colorValues.yellowIcon },
];

export const ColorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedColor, setSelectedColor] = useState<ColorScheme>(colorClasses[0]);

    return (
        <ColorContext.Provider value={{ selectedColor, setSelectedColor }}>
            {children}
        </ColorContext.Provider>
    );
};

export const colorCalculator = (index:number) => {
    // Determine the current row based on the index
    const rowIndex = Math.floor(index / 4); // Assuming 4 elements per row

    // Check if the current row is odd or even
    const isEvenRow = rowIndex % 2 === 1;

    // Determine the color index based on whether the row is even or odd
    let colorIndex = 0;
    if (isEvenRow) {
        // In even rows, swap color 1st with 2nd, and 3rd with 4th
        const positionInRow = index % 4; // position within the current row (0, 1, 2, 3)
        if (positionInRow === 0) {
            colorIndex = 1;  // 1st element should use 2nd color
        } else if (positionInRow === 1) {
            colorIndex = 0;  // 2nd element should use 1st color
        } else if (positionInRow === 2) {
            colorIndex = 3;  // 3rd element should use 4th color
        } else if (positionInRow === 3) {
            colorIndex = 2;  // 4th element should use 3rd color
        }
    } else {
        // For odd rows, use the normal order
        colorIndex = index % 4;
    }

    return colorClasses[colorIndex];
};
