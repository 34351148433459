import { bind } from 'bind-decorator';
import update from 'immutability-helper';
import { LearningObjective } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { Icon, ItemSection, ScreenReader } from 'ui/components';
import { WrappedMessage } from 'utils';
import { FauxCard } from '../Card';
import { intl } from 'i18n';

interface Props {
    learningObjectives: LearningObjective[];
    onChange: (learningObjectives: LearningObjective[]) => void;
}

export class LearningObjectiveEditor extends React.PureComponent<Props, {}> {
    private inputRef: React.MutableRefObject<HTMLInputElement | null>;

    constructor(props: Props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidUpdate() {
        this.inputRef.current?.focus();
    }

    public render() {
        const title = (
            <div className='learning-objectives-title'>
                <WrappedMessage message={messages.metadataLearningObjectives}/>
            </div>);

        return (
            <ItemSection
                sectionName='learning-objectives'
                extraClasses='learning-objectives'
                title={title}
            >
                <ol>
                    {this.props.learningObjectives.map(this.renderLearningObjective)}
                    <li className='objective'>
                        <FauxCard
                            onClick={this.displayNewLearningObjectives}
                            miniMobileMode={true}
                            ariaLabel={intl.formatMessage(
                                messages.metadataLearningObjectiveListItem, {number: this.props.learningObjectives.length + 1}
                            )}
                        >
                            <span>
                                <Icon name='plus-small' zoom='16' />
                                <WrappedMessage message={messages.pathwayAddNewLearningObjective} />
                            </span>
                        </FauxCard>
                    </li>
                </ol>
            </ItemSection>
        );
    }

    @bind private renderLearningObjective(learningObjective: LearningObjective, index: number) {
        const itemNumber = index + 1;

        return (
            <li className='objective' key={index}>
                <div className='form-group form-inline'>
                    <input
                        ref={this.inputRef}
                        className='form-control'
                        aria-label={intl.formatMessage(messages.metadataLearningObjectiveListItem, {number: itemNumber})}
                        placeholder={intl.formatMessage(messages.pathwayDefaultLearningObjectiveDescription)}
                        value={learningObjective.description}
                        onChange={(event) => {
                            this.updateLearningObjectiveDescription(
                                index,
                                event.currentTarget.value,
                            );
                        }}
                        onFocus={this.focusLearningObjective}
                    />
                </div>
                <span>
                    <button onClick={() => this.deleteLearningObjective(index)}>
                        <Icon name='trashcan' zoom='15' />
                        <ScreenReader>
                            <WrappedMessage
                                message={messages.metadataDeleteLearningObjective}
                                values={{number: itemNumber}}
                            />
                        </ScreenReader>
                    </button>
                </span>
            </li>
        );
    }

    @bind private updateLearningObjectiveDescription(index: number, description: string) {
        this.props.onChange(
            update(this.props.learningObjectives, {
                [index]: {description: { $set: description }}
            })
        );
    }

    @bind private focusLearningObjective(event: React.FocusEvent<HTMLInputElement>) {
        this.inputRef.current = event.target;
    }

    @bind private deleteLearningObjective(index: number) {
        this.props.onChange(
            this.props.learningObjectives.filter((_, i) => i !== index)
        );
    }

    @bind private displayNewLearningObjectives(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.props.onChange(
             this.props.learningObjectives.concat([{
                 description: '',
             }]),
        );
    }
}
