import { defineMessages } from 'react-intl';

const messages = defineMessages({
    checkboxUnCheckedTooltipLabel: {
        id:'checkboxUnCheckedTooltipLabel',
        defaultMessage: 'You haven’t completed this',
        description: 'Checkbox un-checked tooltip label',
    },
    checkboxCheckedTooltipLabel: {
        id:'checkboxCheckedTooltipLabel',
        defaultMessage: 'You’ve completed this',
        description: 'Checkbox checked tooltip label',
    },
});

export default messages;
