// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SearchModeParameters
 */
export interface SearchModeParameters {
    /**
     * 
     * @type {string}
     * @memberof SearchModeParameters
     */
    organization?: string;
}

export function SearchModeParametersFromJSON(json: any): SearchModeParameters {
    return {
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
    };
}

export function SearchModeParametersToJSON(value?: SearchModeParameters): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'organization': value.organization,
    };
}


