import { bind } from 'bind-decorator';
import { ItemResponse } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { WrappedMessage } from 'utils';
import { CardsList } from './CardsList';

interface Props {
    similarItems: ItemResponse[];
    moreItemsBySource: ItemResponse[];
}

interface State {
    selectedSimilarItemsTab: boolean;
}

export class RecommendedContentWidget extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedSimilarItemsTab: true,
        };
    }

    public render() {
        return (
            <aside className='recommended-content-widget container-fluid'>
                <div className='content-widget-tabs d-flex justify-content-start'>
                    {this.props.similarItems !== undefined && this.props.similarItems.length > 0
                    ?   <button onClick={this.selectSimilarItemsTab}
                            className={this.state.selectedSimilarItemsTab ? 'selected' : undefined}>
                            <WrappedMessage message={messages.similarItemsTab} />
                        </button>
                    : null}
                    {this.props.moreItemsBySource !== undefined && this.props.moreItemsBySource.length > 2
                    ?   <button onClick={this.selectMoreItemsBySourceTab}
                            className={!this.state.selectedSimilarItemsTab ? 'selected' : undefined}>
                            <WrappedMessage message={messages.moreItemsBySourceTab} />
                        </button>
                    : null}
                </div>
                {this.renderContent()}
            </aside>
        );
    }

    @bind private selectSimilarItemsTab(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({selectedSimilarItemsTab: true});
    }

    @bind private selectMoreItemsBySourceTab(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({selectedSimilarItemsTab: false});
    }

    private renderContent() {
        switch (this.state.selectedSimilarItemsTab) {
            case true:
                return (
                    <CardsList cards={this.props.similarItems}/>
                );
            case false:
                return (
                    <CardsList cards={this.props.moreItemsBySource}/>
                );
            default: return null;
        }
    }
}
