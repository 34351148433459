import {bind} from 'bind-decorator';
import * as React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {getLoggedInStatus, getUsername} from 'auth/selectors';
import {MentorshipsApi} from 'global/api';
import {ROUTES} from 'global/constants';
import {RootState} from 'global/state';
import { Mentorship, MentorshipStatusEnum, Profile } from 'labxchange-client';
import { actionCanMessage, MessageModalState, RenderModals } from 'messages/components';
import {UI_IS_SM} from 'ui/breakpoints';
import {Button, KebabMenu, KebabMenuItem, Modal,} from 'ui/components';
import {showErrorMessage, showLoginRequiredMessage} from 'ui/components/GlobalMessageReporter/dispatch';
import {UserCard, UserCardButton} from 'user/components/UserCard';
import {WrappedMessage} from 'utils';
import {AssignedItemsReport} from '../../../ui/components/AssignedItemsReport';
import {UserAvatar} from '../../../user/components/UserAvatar';
import {StopMentorshipModal} from '../StopMentorshipModal';
import {mentorshipItemsToItemsProgress} from '../../util';
import messages from './displayMessages';
import {ApplyByMenteeModal} from 'mentorship/components/ApplyByMenteeModal';
import libraryMessages from 'library/displayMessages';
import {EmptyDashboardPage} from 'library/components/EmptyDashboardPage';
import Skeleton from 'react-loading-skeleton';
import {skeletonMentorship} from 'skeletons';

enum LocalModalType {
    None = 0,
    StopMentorship,
    ShowAssignments,
    ApplyMentee,
}

interface LocalModalTypeInterface {
    kind: 'local';
    modalType: LocalModalType;
}

interface MessageModalType {
    kind: 'message';
    modalType: MessageModalState;
}

type ModalType = LocalModalTypeInterface | MessageModalType;

interface State {
    activeMentorships: Mentorship[];
    stoppedMentorships: Mentorship[];
    modalMentorship?: Mentorship;
    modalUserProfile?: Profile;
    modalType: ModalType;
    isMobileView: boolean;
    dataFetchedOnce: boolean;
}

interface ReduxStateProps {
    isLoggedIn: boolean;
    loggedInUsername: string;
}
interface Props extends ReduxStateProps {
    history: any;
}

export class LearnerMentorsDashboardInternal extends React.PureComponent<Props, State> {
    private mediaQuery = UI_IS_SM;  // This matches Bootstrap's definition of small (sm) breakpoint.

    constructor(props: Props) {
        super(props);
        this.state = {
            activeMentorships: [skeletonMentorship],
            stoppedMentorships: [],
            isMobileView: false,
            modalType: {kind: 'local', modalType: LocalModalType.None},
            dataFetchedOnce: false,
        };
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
        await this.fetchMentorships();
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    public render() {
        const searchMentorLink = `${ROUTES.People.HOME}?t=Role:mentor`;
        if (!this.props.isLoggedIn || (this.state.activeMentorships.length === 0 && this.state.stoppedMentorships.length === 0)) {
            return (
                <div className='learner-mentors-dashboard-wrapper'>
                    <EmptyDashboardPage
                        title={messages.emptyMentorsDashboardTitle}
                        secondaryText={messages.emptyMentorsDashboardSecondaryText}
                        buttons={
                            <NavLink
                                to={searchMentorLink}
                                onClick={this.promptLoginBeforeAddMentor}
                                className='btn primary-button add-new-mentor'
                            >
                                <WrappedMessage message={messages.emptyMentorsDashboardAction} />
                            </NavLink>
                        }
                    />
                </div>
            );
        }
        return (
            <div className='learner-mentors-dashboard-wrapper learner-mentors-dashboard'>
                <h1 className='sr-only'>
                    <WrappedMessage message={messages.pageTitle}/>
                </h1>
                <div className='description-text'><WrappedMessage message={messages.mentorsDescription}/></div>
                {this.renderMentorshipsList(this.state.activeMentorships)}
                <NavLink to={searchMentorLink} onClick={this.promptLoginBeforeAddMentor}
                      className='add-new-mentor'>
                        + <WrappedMessage message={messages.addNewMentorButton}/>
                </NavLink>
                { this.state.stoppedMentorships.length > 0 &&
                    <>
                        <h4 className='past-mentors-title'>
                            <WrappedMessage message={messages.pastMentorsSection}/>
                        </h4>
                        {this.renderMentorshipsList(this.state.stoppedMentorships, true)}
                    </>
                }
                {this.renderModals()}
            </div>
        );
    }

    private renderModals() {
        switch (this.state.modalType.kind) {
            case 'local': {
                switch (this.state.modalType.modalType) {
                    case LocalModalType.StopMentorship: {

                        if (!this.state.modalMentorship) { return; }

                        return (
                            <StopMentorshipModal
                                byMentor={false}
                                mentorship={this.state.modalMentorship}
                                onSuccess={this.onSuccessModal}
                                onError={this.onErrorModal}
                                onClose={this.onCloseModal} />
                        );
                    }

                    case LocalModalType.ShowAssignments: {
                        if (!this.state.modalMentorship) { return; }

                        return (
                            <Modal
                                onRequestClose={this.onCloseModal}
                                size='large'
                                className='mentorship-assignments-modal'
                                content={
                                    <>
                                        <div className='mentorship-assignments-modal-title'>
                                            <WrappedMessage
                                                message={messages.workAssignedByModal}
                                                values={{
                                                    fullName: this.state.modalMentorship.mentor.fullName,
                                                }}/>
                                        </div>
                                        {this.renderAssignments(this.state.modalMentorship)}
                                    </>
                                }
                            />
                        );
                    }

                    case LocalModalType.ApplyMentee: {
                        if (!this.state.modalMentorship) { return; }

                        return (
                            <ApplyByMenteeModal
                                onClose={this.onCloseModal}
                                onSuccess={this.onSuccessModal}
                                onError={() => {/* TODO: handle error */}}
                                profile={this.state.modalMentorship.mentor}
                                loggedInUsername={this.props.loggedInUsername}
                            />
                        );
                    }

                    default: {
                        return;
                    }
                }
            }

            case 'message': {
                // It's an external message modal type, so we defer to the
                // Messages modals
                const userProfile = this.state.modalUserProfile;
                if (userProfile === undefined) { return; }
                const onClose = this.onCloseModal;
                const history = this.props.history;
                const ownUsername = this.props.loggedInUsername;
                const modalType = this.state.modalType.modalType;

                return RenderModals({
                    userProfile,
                    onClose,
                    history,
                    ownUsername,
                    modalType,
                });
            }
        }
    }

    @bind private onCloseModal() {
        this.setState({
            modalMentorship: undefined,
            modalUserProfile: undefined,
            modalType: {kind: 'local', modalType: LocalModalType.None},
        });
    }

    @bind private async onSuccessModal(mentorship: Mentorship) {
        await this.fetchMentorships();
        this.onCloseModal();
    }

    @bind private onErrorModal() {
        this.onCloseModal();
        showErrorMessage(<WrappedMessage message={messages.onFailedToStop}/>);
    }

    private renderMentorshipsList(mentorships: Mentorship[], pastMentors: boolean = false) {
        if (mentorships.length === 0) { return(null); }

        const mentorshipCards = mentorships.map((mentorship) => {
            const mentor = mentorship.mentor;

            let mentorDesc;
            if (mentor.jobTitle && mentor.institution) {
                mentorDesc = <WrappedMessage message={libraryMessages.profileCardJobTitleWithCompany}
                             values={{jobTitle: mentor.jobTitle, company: mentor.institution }} />;
            } else if (mentor.jobTitle) {
                mentorDesc = <WrappedMessage message={libraryMessages.profileCardJobTitle}
                                 values={{jobTitle: mentor.jobTitle}}/>;
            } else if (mentor.institution) {
                mentorDesc = <WrappedMessage message={libraryMessages.profileCardCompany}
                                     values={{company: mentor.institution}} />;
            }

            let actionContent;
            if (pastMentors && mentorship.status !== MentorshipStatusEnum.ReRequested) {
                actionContent = (
                    <Button
                        className='message-button'
                        label={messages.reRequestMentorship}
                        onClick={() => this.setState({
                            modalType: {kind: 'local', modalType: LocalModalType.ApplyMentee},
                            modalMentorship: mentorship,
                        })}
                    />
                );
            } else if (pastMentors && mentorship.status === MentorshipStatusEnum.ReRequested) {
                actionContent = (
                    <WrappedMessage message={messages.mentorshipReRequested} />
                );
            } else {
                actionContent = (
                    <Button
                        className='message-button'
                        onClick={() => this.onClickMessageMentor(mentorship.mentor)}
                        icon='mail'
                        label={messages.messageButton}
                    />
                );
            }

            if (!this.state.isMobileView) {
                return (
                    <div key={mentorship.id}
                        className={`mentor-card ${pastMentors ? 'past-mentor-card' : ''}`}>
                        <div className='profile'>
                            {this.state.dataFetchedOnce && !pastMentors &&
                                <KebabMenu darkKebab={true} >
                                    <KebabMenuItem
                                        iconName='dash'
                                        message={messages.removeButton}
                                        onClick={() => this.setState({
                                        modalType: {kind: 'local', modalType: LocalModalType.StopMentorship},
                                        modalMentorship: mentorship,
                                    })}
                                        disabled={false}
                                    />
                                </KebabMenu>
                            }
                            <NavLink to={ROUTES.Users.PROFILE_SLUG(mentor.username)} className='avatar' aria-label={mentor.username}>
                                <UserAvatar username={mentor.username} showSkeleton={!this.state.dataFetchedOnce} diameter={'70px'}/>
                            </NavLink>
                            <div className='user-info'>
                                <h2>{!this.state.dataFetchedOnce ? <Skeleton /> :
                                    <NavLink to={ROUTES.Users.PROFILE_SLUG(mentor.username)} className='fullname'>
                                        {mentor.fullName}
                                    </NavLink>
                                }</h2>
                                <div className='bio'>
                                    {!this.state.dataFetchedOnce ? <Skeleton /> : mentorDesc}
                                </div>
                            </div>
                            {!this.state.dataFetchedOnce ? <div className='skeleton-button'><Skeleton /></div> : actionContent}
                        </div>
                        {this.renderAssignments(mentorship)}
                    </div>
                );
            } else {
                return (
                    <UserCard
                        key={mentorship.id}
                        menu={pastMentors ? null : this.renderMenu(mentorship)}
                        profile={mentorship.mentee}
                        accessory={
                            !pastMentors &&
                            <>
                                <UserCardButton
                                    onClick={() => this.onClickMessageMentor(mentorship.mentor)}
                                    iconName='mail'
                                    label={messages.messageButton}
                                />
                                 <UserCardButton
                                    onClick={() => {
                                        this.setState({
                                            modalMentorship: mentorship,
                                            modalType: {kind: 'local', modalType: LocalModalType.ShowAssignments},
                                        });
                                    }}
                                    iconName='plus'
                                    label={messages.assignmentsButton}
                                />
                            </>
                        }
                        extraClasses={['user-card', pastMentors ? 'past-user-card' : undefined]}
                    />
                );
            }
            });
        return (
            <div className='mentors-list'>
                {mentorshipCards}
            </div>
        );
    }

    private renderMenu(mentorship: Mentorship) {
        return (
            <KebabMenu
                darkKebab={true}
            >
                <KebabMenuItem
                    iconName='trashcan'
                    disabled={false}
                    message={messages.removeButton}
                    onClick={() => { this.onRemove(mentorship); }}
                />
            </KebabMenu>
        );
    }

    private renderAssignments(mentorship: Mentorship) {
        if (this.state.dataFetchedOnce && (mentorship.items === undefined || mentorship.items.length === 0)) {
            return (
                <div className='empty-message'>
                    <WrappedMessage message={messages.emptyMessage}/>
                </div>
            );
        }

        return <AssignedItemsReport
            itemsProgress={mentorshipItemsToItemsProgress(mentorship.items? mentorship.items : [])}
            showSkeleton={!this.state.dataFetchedOnce}
        />;
    }


    private async fetchMentorships() {
        if (!this.props.isLoggedIn) {
            // The user is not logged in, so just show an empty list so they know what
            // sort of functionality they'll get if they log in.
            this.setState({activeMentorships: [], stoppedMentorships: []});
            return;
        }

        let mentorships = [];
        try {
            mentorships = await MentorshipsApi.list({
                mentee: this.props.loggedInUsername,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorLoading}/>, {exception: err});
            return;
        }

        const activeMentorships = mentorships.filter(x => x.status === MentorshipStatusEnum.Accepted);
        const stoppedMentorships = mentorships.filter(
            x => [
                MentorshipStatusEnum.StoppedByMentor,
                MentorshipStatusEnum.StoppedByMentee,
                MentorshipStatusEnum.ReRequested,
            ].includes(x.status)
        );
        // TODO: display pending mentorships here somewhere?
        this.setState({activeMentorships, stoppedMentorships, dataFetchedOnce: true});
    }

    @bind private async onRemove(mentorship: Mentorship) {
        this.setState({
            modalType: {kind: 'local', modalType: LocalModalType.StopMentorship},
            modalMentorship: mentorship,
        });
    }

    @bind private onClickMessageMentor(mentor: Profile) {
        this.setState({
            modalUserProfile: mentor,
        }, async () => {
            const newModalState = await actionCanMessage(
                this.state.modalUserProfile!.username, this.props.history,
            );
            this.setState({
                modalType: {kind: 'message', modalType: newModalState},
            });
        });
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQuery.matches});
    }

    @bind private promptLoginBeforeAddMentor(event: React.MouseEvent) {
        if (!this.props.isLoggedIn) {
            // If the user is not logged in, tell them to log in first:
            event.preventDefault();
            showLoginRequiredMessage();
        }
    }
}

export const LearnerMentorsDashboard = connect<ReduxStateProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
    }),
)(LearnerMentorsDashboardInternal);
