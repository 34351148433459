import * as React from 'react';

import logoFull from 'assets/images/harvard-logo.svg';
import messages from '../displayMessages';
import { intl } from 'i18n';

interface HarvardUniversityLogoProps {
    width?: number;
    height?: number;
}

export const HarvardUniversityLogo: React.FC<HarvardUniversityLogoProps> = () => {
    const title = intl.formatMessage(messages.uiHarvardOnlineLogo);
    return <img src={logoFull} alt={title} title={title} />;
};
