import {defineMessages} from 'react-intl';

const messages = defineMessages({
    acceptCookiePolicy: {
        id:'acceptCookiePolicy',
        defaultMessage: 'Accept',
        description: 'Button text to accept cookie policy',
    },
    lxAuthActivateEmailBannerContent: {
        id: 'lxAuthActivateEmailBannerContent',
        defaultMessage: 'You still need to activate your account. {link}.',
        description: 'Content of the banner that appears when the user needs to activate his account',
    },
    lxAuthActivateEmailBannerLinkLabel: {
        id: 'lxAuthActivateEmailBannerLinkLabel',
        defaultMessage: 'Activate it now',
        description: 'Link label of the activate email banner',
    },
    policyAndTermsUpdateBannerContent: {
        id: 'policyAndTermsUpdateBannerContent',
        defaultMessage: 'LabXchange has updated its {policyLink}. By continuing to use our services, you acknowledge these updates.',
        description: 'Content of the banner that appears when terms and policies update',
    },
    policyLinkLabel: {
        id: 'policyLinkLabel',
        defaultMessage: 'Privacy Notice',
        description: 'Link label of the policies updates banner',
    },
    acceptTermsButton: {
        id: 'acceptTermsButton',
        defaultMessage: 'Accept',
        description: 'Text for button shown on a banner to accept changes to legal terms and policies',
    },
    termsLinkLabel: {
        id: 'termsLinkLabel',
        defaultMessage: 'Terms of Service',
        description: 'Link label of the terms updates banner',
    },
    consentModalTitle: {
        id: 'consentModalTitle',
        defaultMessage: 'We Value Your Privacy',
        description: 'Title of the consent modal',
    },
    consentModalDescription: {
        id: 'consentModalDescription',
        defaultMessage:
            'We use cookies to enhance your experience, analyze site usage, and assist in our marketing efforts. By clicking "Accept All", you agree to the storing of cookies on your device. You can manage your cookie preferences or withdraw consent by other available options.',
        description: 'Description in the consent modal',
    },
    consentModalAcceptAllBtn: {
        id: 'consentModalAcceptAllBtn',
        defaultMessage: 'Accept All',
        description: 'Label for the Accept All button in the consent modal',
    },
    consentModalAcceptNecessaryBtn: {
        id: 'consentModalAcceptNecessaryBtn',
        defaultMessage: 'Strictly Necessary',
        description: 'Label for the Strictly Necessary button',
    },
    consentModalShowPreferencesBtn: {
        id: 'consentModalShowPreferencesBtn',
        defaultMessage: 'Manage Preferences',
        description: 'Label for the Manage Preferences button',
    },
    consentModalFooter: {
        id: 'consentModalFooter',
        defaultMessage: 'Privacy Notice',
        description: 'Footer content with a link to the Privacy Policy',
    },
    preferencesModalTitle: {
        id: 'preferencesModalTitle',
        defaultMessage: 'Manage Cookie Preferences',
        description: 'Title of the cookie preferences modal',
    },
    preferencesModalAcceptAllBtn: {
        id: 'preferencesModalAcceptAllBtn',
        defaultMessage: 'Accept All',
        description: 'Label for the Accept All button in preferences modal',
    },
    preferencesModalAcceptNecessaryBtn: {
        id: 'preferencesModalAcceptNecessaryBtn',
        defaultMessage: 'Strictly Necessary',
        description: 'Label for the Strictly Necessary button',
    },
    preferencesModalSavePreferencesBtn: {
        id: 'preferencesModalSavePreferencesBtn',
        defaultMessage: 'Save Preferences',
        description: 'Label for the Save Preferences button',
    },
    preferencesModalCloseIconLabel: {
        id: 'preferencesModalCloseIconLabel',
        defaultMessage: 'Close Modal',
        description: 'Label for the close icon in preferences modal',
    },
    preferencesModalSectionAboutCookiesTitle: {
        id: 'preferencesModalSectionAboutCookiesTitle',
        defaultMessage: 'About Cookies',
        description: 'Title for the About Cookies section',
    },
    preferencesModalSectionAboutCookiesDescription: {
        id: 'preferencesModalSectionAboutCookiesDescription',
        defaultMessage:
            'Cookies are small text files used to store information on your device. They help us improve site functionality and deliver personalized content. You can adjust your preferences below.',
        description: 'Description for the About Cookies section',
    },
    preferencesModalSectionStrictlyNecessaryTitle: {
        id: 'preferencesModalSectionStrictlyNecessaryTitle',
        defaultMessage: 'Strictly Necessary Cookies',
        description: 'Title for the Strictly Necessary Cookies section',
    },
    preferencesModalSectionStrictlyNecessaryDescription: {
        id: 'preferencesModalSectionStrictlyNecessaryDescription',
        defaultMessage:
            'These cookies are essential for the website to function and cannot be switched off in our systems.',
        description: 'Description for the Strictly Necessary Cookies section',
    },
    preferencesModalSectionAnalyticsTitle: {
        id: 'preferencesModalSectionAnalyticsTitle',
        defaultMessage: 'Analytics Cookies',
        description: 'Title for the Analytics Cookies section',
    },
    preferencesModalSectionAnalyticsDescription: {
        id: 'preferencesModalSectionAnalyticsDescription',
        defaultMessage:
            'These cookies help us understand how visitors interact with our website.',
        description: 'Description for the Analytics Cookies section',
    },
    preferencesModalSectionPerformanceTitle: {
        id: 'preferencesModalSectionPerformanceTitle',
        defaultMessage: 'Performance Cookies',
        description: 'Title for the Performance Cookies section',
    },
    preferencesModalSectionPerformanceDescription: {
        id: 'preferencesModalSectionPerformanceDescription',
        defaultMessage:
            'These cookies enhance site performance and user experience by remembering preferences and settings.',
        description: 'Description for the Performance Cookies section',
    },
    preferencesModalSectionMarketingTitle: {
        id: 'preferencesModalSectionMarketingTitle',
        defaultMessage: 'Marketing Cookies',
        description: 'Title for the Marketing Cookies section',
    },
    preferencesModalSectionMarketingDescription: {
        id: 'preferencesModalSectionMarketingDescription',
        defaultMessage:
            'These cookies are used to help us measure the effectiveness of our marketing campaigns.',
        description: 'Description for the Marketing Cookies section',
    },
    preferencesModalSectionMoreInfoTitle: {
        id: 'preferencesModalSectionMoreInfoTitle',
        defaultMessage: 'More Information',
        description: 'Title for the More Information section',
    },
    preferencesModalSectionMoreInfoDescription: {
        id: 'preferencesModalSectionMoreInfoDescription',
        defaultMessage:
            'For more details about our cookie usage, please view {privacyPolicyLink} and {cookiePolicyLink}.',
        description: 'Description for the More Information section',
    },
    cookiePolicyLinkText: {
        id: 'cookiePolicyLinkText',
        defaultMessage: `Cookies and Similar Technologies`,
        description: 'Cookies and Similar Technologies Policy link text',
    }
});

export default messages;
