import * as LoginActions from 'auth/actions';
import * as React from 'react';
import bind from 'bind-decorator';
import messages from '../displayMessages';
import { AuthPageLayout, showErrorMessage } from 'ui/components';
import { AuthProviderEnum, validatePassword } from 'auth/utils';
import { connect } from 'react-redux';
import { getCookie, WrappedMessage } from 'utils';
import { getLoggedInStatus } from 'auth/selectors';
import { history } from 'global/history';
import { intl } from 'i18n';
import { LoginPasswordComponent } from '../elements/LoginPasswordComponent';
import { RootState } from 'global/state';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

interface Props {
    isLoggedIn: boolean;
    login: typeof LoginActions.login;
    email?: string;
    provider?: AuthProviderEnum;
}

interface State {
    password: string;
    showPassword: boolean;
    invalidPassword: boolean;
    enableButton: boolean;
}

@connect((state: RootState) => ({
    isLoggedIn: getLoggedInStatus(state),
}), {
    login: LoginActions.login,
})
export class PasswordLoginView extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            password: '',
            showPassword: false,
            invalidPassword: false,
            enableButton: false,
        };
    }

    public render() {
        const email = this.getEmail();

        return (
            <AuthPageLayout
                className='password-login-page'
                imageUrl='/assets/images/auth-layouts/scientists.svg'
                title={intl.formatMessage(messages.lxAuthSignInModalTitle)}
                subtitle={intl.formatMessage(messages.lxAuthAccountAlreadyCreatedMsg)}
            >
                <LoginPasswordComponent
                    inputKey='password-login-page-input'
                    email={email}
                    password={this.state.password}
                    showPassword={this.state.showPassword}
                    invalidPassword={this.state.invalidPassword}
                    disableButton={!this.state.enableButton}
                    onUpdatePassword={this.onUpdatePassword}
                    onUpdateShowPassword={this.onShowPassword}
                    onClickResetPassword={() => LoginActions.redirectToResetPasswordPage(email)}
                    onSubmit={this.onSubmitEmailLogin}
                />
            </AuthPageLayout>
        );
    }

    @bind private onUpdatePassword(password: string) {
        this.setState({
            password,
            enableButton: validatePassword(password),
            invalidPassword: false,
        });
    }

    @bind private onShowPassword(showPassword: boolean) {
        this.setState({ showPassword });
    }

    @bind private getEmail() {
        return history.location.state?.email || this.getEmailCookie() || this.props.email || '';
    }

    @bind private async onSubmitEmailLogin() {
        analyticsInstance.track(EVENT_NAMES.PasswordAuthenticationSubmission, { url: window.location.toString() });
        this.setState({ enableButton: false });

        const response = await LoginActions.loginWithEmail(
            this.getEmail(),
            this.state.password,
        );

        if (response === undefined) {
            return;
        }

        if (response.status === 200) {
            /// After the `labxchange_sessionid` cookie is set,
            /// we redirect to `/oauth2/authorize` to complete the login
            this.props.login();
        }
        else if (response.status === 429) {
            /// "Too many failed login attempts. Try again later."
            const errorMessage = (await response.json()).error_message;
            showErrorMessage(errorMessage);
        }
        else if (response.status === 401) {
            this.setState({ invalidPassword: true });
        }
        else {
            showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToLogin} />);
        }

    }

    private getEmailCookie() {
        return getCookie('password_auth_user_email')?.replace(/\"/gi, '');
    }
}
