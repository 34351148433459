// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecommendedItems
 */
export interface RecommendedItems {
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof RecommendedItems
     */
    similarItems?: Array<ItemResponse>;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof RecommendedItems
     */
    moreByAuthor?: Array<ItemResponse>;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof RecommendedItems
     */
    moreBySource?: Array<ItemResponse>;
}

export function RecommendedItemsFromJSON(json: any): RecommendedItems {
    return {
        'similarItems': !exists(json, 'similar_items') ? undefined : (json['similar_items'] as Array<any>).map(ItemResponseFromJSON),
        'moreByAuthor': !exists(json, 'more_by_author') ? undefined : (json['more_by_author'] as Array<any>).map(ItemResponseFromJSON),
        'moreBySource': !exists(json, 'more_by_source') ? undefined : (json['more_by_source'] as Array<any>).map(ItemResponseFromJSON),
    };
}

export function RecommendedItemsToJSON(value?: RecommendedItems): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'similar_items': value.similarItems === undefined ? undefined : (value.similarItems as Array<any>).map(ItemResponseToJSON),
        'more_by_author': value.moreByAuthor === undefined ? undefined : (value.moreByAuthor as Array<any>).map(ItemResponseToJSON),
        'more_by_source': value.moreBySource === undefined ? undefined : (value.moreBySource as Array<any>).map(ItemResponseToJSON),
    };
}


