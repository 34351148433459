import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import classNames from 'classnames';
import { intl } from 'i18n';

import messages from '../displayMessages';
import { Button, Icon, IconSymbol} from 'ui/components';


interface KebabMenuProps extends React.PropsWithChildren {
    button?: React.ReactNode;
    buttonId?: string;
    buttonAdditionalStyling?: string;
    // keepExanded will keep kebab menu opened when user clicked outside of it
    // It allow to have a KebabMenuItem which open a modal for example.
    keepExpanded?: boolean;
    forceClose?: boolean;
    darkKebab?: boolean; // if true, render kebab in navy-2
    icon?: IconSymbol;
    className?: string;
    onExpanded?: (expanded: boolean) => void;
}

interface KebabMenuState {
    expanded: boolean;
    currentIdx: number;
}

export class KebabMenu extends React.Component<KebabMenuProps, KebabMenuState> {

    private node: React.RefObject<HTMLDivElement>;
    private listRef: React.RefObject<HTMLUListElement>;

    constructor(props: KebabMenuProps) {
        super(props);
        this.node = React.createRef<HTMLDivElement>();
        this.listRef = React.createRef();
        this.state = {
            expanded: false,
            currentIdx: -1,
        };
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
        window.addEventListener('keyup', this.handleKeyUp);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    public componentDidUpdate(prevProps: KebabMenuProps) {
        if (this.props.forceClose && prevProps.forceClose !== this.props.forceClose) {
            this.setState({expanded: !this.props.forceClose});
        }
        if (this.props.onExpanded) {
            this.props.onExpanded(this.state.expanded);
        }
    }

    public handleClick = (event: MouseEvent) => {
        const element = event.target as HTMLElement;
        if (!this.node.current || this.node.current.contains(element) || this.props.keepExpanded) {
            return;
        }

        if (this.state.expanded) {
            this.toggleExpanded();
        }
    }

    public handleKeyUp = (event: KeyboardEvent) => {
        const ul = this.listRef.current;
        if (!ul) {
            return;
        }

        const currentIdx = this.state.currentIdx;
        const childrenCount = React.Children.count(this.props.children);
        let nextIdx = null;

        switch (event.key) {
        case 'ArrowDown':
        case 'Down':
            nextIdx = currentIdx + 1 > childrenCount - 1 ? 0 : currentIdx + 1;
            break;
        case 'ArrowUp':
        case 'Up':
            nextIdx = currentIdx <= 0 ? childrenCount - 1 : currentIdx - 1;
            break;
        case 'Escape':
            this.toggleExpanded();
            return;
        }
        if (nextIdx !== null) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({currentIdx: nextIdx});
            const elem = ul.querySelectorAll('li')[nextIdx].querySelector('button');
            elem!.focus();
        }
    }

    public toggleExpanded() {
        this.setState(
            { expanded: !this.state.expanded },
            () => {
                if (this.props.onExpanded) {
                    this.props.onExpanded(this.state.expanded);
                }
            }
        );
    }

    public render() {
        if (
            Array.isArray(this.props.children)
            && this.props.children.filter((x: any) => x).length === 0
        ) {
            return null;
        }
        const id = this.props.buttonId || uniqueId();
        let hasOnlyDisabledChildren: boolean = true;
        React.Children.forEach(this.props.children, (child) => {
            if (!React.isValidElement(child)) {
                return;
            }
            if (!child.props.disabled) {
                hasOnlyDisabledChildren = false;
            }
        });
        const icon: IconSymbol = this.props.icon || 'kebab-vertical';
        return (
            <div className={`dropdown ${this.props.className? this.props.className : ''}`} ref={this.node} draggable={false}>
                <button id={id}
                    aria-haspopup='true'
                    aria-label={intl.formatMessage(messages.kebabMenuArialLabel)}
                    aria-expanded={this.state.expanded}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.toggleExpanded();
                    }}
                    onKeyPress={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.toggleExpanded();
                    }}
                    className={classNames('kebab-menu-button', {
                        [`${this.props.buttonAdditionalStyling}`]: !!this.props.buttonAdditionalStyling,
                        'dark-kebab': this.props.darkKebab,
                    })}
                >
                    {this.props.button ? this.props.button : <Icon name={icon} />}
                </button>
                {this.state.expanded && !hasOnlyDisabledChildren ?
                    <div
                        role='menu'
                        tabIndex={-1}
                        className='kebab-menu-content'
                        aria-labelledby={id}
                        onClick={(e) => {
                            // kebab menu content box can float over other
                            // clickable elements, so catch click events here
                            // so they don't pass through and accidentally
                            // trigger something underneath.
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onKeyPress={() => {/* placeholder */}}
                    >
                        <ul ref={this.listRef}>
                            {this.props.children}
                        </ul>
                    </div>
                    : null
                }
            </div>
        );
    }
}

export interface KebabMenuItemProps extends React.PropsWithChildren {
    iconName: IconSymbol;
    iconOutline?: boolean;
    message: MessageDescriptor;
    onClick?: () => void;
    disabled: boolean;
    href?: string;
    className?: string;
}

export const KebabMenuItem: React.FC<KebabMenuItemProps> = (
    { iconName, iconOutline, message, onClick, disabled, href, ...props }) => (
        <li
            // hide disabled menu items
            className={classNames(disabled ? 'hidden' : 'kebab-menu-item')}
        >
            <Button
                btnStyle='unstyled'
                disabled={disabled}
                onClick={onClick}
                href={href}
                icon={iconName}
                iconOutline={iconOutline}
                label={message}
                {...props}
            />
        </li>
    );

export interface KebabMenuItemWrapperProps {
    disabled?: boolean;
}


let lastId = 0;
/** Helper function to generate a unique HTML ID. */
function uniqueId(): string { return `KebabMenu${++lastId}`; }
