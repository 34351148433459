import { defineMessages } from 'react-intl';

const messages = defineMessages({
    loginTitle:  {
        id:'loginTitle',
        defaultMessage: 'Create your account',
        description: 'Create your account page title.',
    },
    loginSubTitle:  {
        id:'loginSubTitle',
        defaultMessage: 'Learning without limits',
        description: 'Create your account page sub-title.',
    },
    loginBoxTitle:  {
        id:'loginBoxTitle',
        defaultMessage: 'What does this mean?',
        description: 'Create your account page box title.',
    },
    loginBoxParagraphOne: {
        id:'loginBoxParagraphOne',
        defaultMessage: 'We want to make it easy for you to continue your learning journey.  With one account for both sites, you can learn anytime, anywhere - for free.',
        description: 'First paragraph of information about your account on login page.',
    },
    loginBoxParagraphTwo: {
        id:'loginBoxParagraphTwo',
        defaultMessage: 'If you already have an edX account, you can use it to log in on the next screen.',
        description: 'Second paragraph of information about your account on login page.',
    },
    loginCreateAnAccount:  {
        id:'loginCreateAnAccount',
        defaultMessage: 'Create an account',
        description: 'Create your account page box label.',
    },
    getStartedButtonLabel: {
        id:'getStartedButtonLabel',
        defaultMessage: 'Get started',
        description: 'Label get started button which redirect user to edX login view.',
    },
    pageTitle: {
        id:'pageTitleLoginView',
        defaultMessage: 'Sign in',
        description: 'HTML title for the sign-in page.',
    },
    accountIsEdxAccount: {
        id:'accountIsEdxAccount',
        defaultMessage: 'Your LabXchange account is also an {edxLink} account',
        description: 'Message explaining that your labxchange account is linked to edx.org. edxLink is a link to edx.org, with the text edX.org.',
    },
});

export default messages;
