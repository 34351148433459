import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Organization } from 'labxchange-client';
import { OrgSocialLinks, OrganizationHeaderPartner } from 'org/components';
import messages from '../OrgProfileHeader/displayMessages';
import { intl } from 'i18n';
import { RichText } from 'elements/components/RichText';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';

interface Props {
    profile: Organization;
    partners: OrganizationHeaderPartner[];
    showSkeleton?: boolean;
}

export class AlternativeOrgProfileHeader extends React.PureComponent<Props> {
    public render() {
        const profile = this.props.profile;
        const headersPartnersComponents = this.props.partners.map((partner, index) =>
            this.props.showSkeleton ?
                <Skeleton className='alternative-header-partners-logo' /> :
                <div className='alternative-header-partners-logo'>
                    <img key={index} src={partner.logoUrl} alt={partner.altText}></img>
                </div>
        );
        const altText = intl.formatMessage(messages.logoAltText, {name: profile.name});
        return <>
            {this.props.showSkeleton ?
                <Skeleton className='alternative-org-profile-header' /> :
                <div className='alternative-org-profile-header'>
                    <h1 aria-label={profile.name}>
                        <img className='logo' src={profile.profilePageLogo} alt={altText}/>
                    </h1>
                    {profile.description &&
                        <div className={`description ${profile.darkTheme ? 'dark' : 'light'}`}>
                            <RichText
                                innerHtml={profile.description}
                                sanitizeConfig={sanitizeConfigOptions.UnsafeLightHTML}
                            />
                        </div>
                    }
                    <OrgSocialLinks
                        organization={profile}
                        showAll={false}
                        showWebsite={true}
                        showTwitter={true}
                        showLinkedin={true}
                    />
                </div>
            }
            <div className='alternative-header-partners-logos'>{headersPartnersComponents}</div>
        </>;
    }
}
