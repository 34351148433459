import { defineMessages } from 'react-intl';

const messages = defineMessages({
    dynamicClustersPageTitle: {
        id:'dynamicClustersPageTitle',
        defaultMessage: '{title} - Cluster',
        description: 'Page title for a dynamic Cluster page.',
    },
    loadingPathwayTranslationsError: {
        id:'loadingPathwayTranslationsError',
        defaultMessage: 'Something went wrong while loading available translations. Please try again later.',
        description: 'Error message when backend failed to return available translations.',
    },
    clusterHexIconAlt: {
        id:'clusterHexIconAlt',
        defaultMessage: 'Icon for {name}',
        description: 'Alt text for icon images on hex buttons',
    },
    clusterPageFunders: {
        id:'clusterPageFunders',
        defaultMessage: 'Created with support from',
        description: 'Title which shows above a list of logos of funders of an asset.',
    },
    clusterLanguageChange: {
        id:'clusterLanguageChange',
        defaultMessage: 'change',
        description: 'Text for language switcher button',
    },
    clusterShowDetails: {
        id:'clusterShowDetails',
        defaultMessage: 'Show details',
        description: 'Text for button on mobile which toggle meta block vision',
    },
    clusterHideDetails: {
        id:'clusterHideDetails',
        defaultMessage: 'Hide details',
        description: 'Text for button on mobile which toggle meta block vision',
    },
    toursHeader: {
        id:'toursHeader',
        defaultMessage: 'Tours',
        description: 'Title text for the tours section',
    },
    clusterOrderOne: {
        id: 'clusterOrderOne',
        defaultMessage: 'one',
        description: 'Label of the element in the position one in a tour of a cluster',
    },
    clusterOrderTwo: {
        id: 'clusterOrderTwo',
        defaultMessage: 'two',
        description: 'Label of the element in the position two in a tour of a cluster',
    },
    clusterOrderThree: {
        id: 'clusterOrderThree',
        defaultMessage: 'three',
        description: 'Label of the element in the position three in a tour of a cluster',
    },
    clusterOrderFour: {
        id: 'clusterOrderFour',
        defaultMessage: 'four',
        description: 'Label of the element in the position four in a tour of a cluster',
    },
    clusterOrderFive: {
        id: 'clusterOrderFive',
        defaultMessage: 'five',
        description: 'Label of the element in the position five in a tour of a cluster',
    },
    clusterOrderSix: {
        id: 'clusterOrderSix',
        defaultMessage: 'six',
        description: 'Label of the element in the position six in a tour of a cluster',
    },
    clusterOrderSeven: {
        id: 'clusterOrderSeven',
        defaultMessage: 'seven',
        description: 'Label of the element in the position seven in a tour of a cluster',
    },
    clusterOrderEight: {
        id: 'clusterOrderEight',
        defaultMessage: 'eight',
        description: 'Label of the element in the position eight in a tour of a cluster',
    },
    clusterOrderNine: {
        id: 'clusterOrderNine',
        defaultMessage: 'nine',
        description: 'Label of the element in the position nine in a tour of a cluster',
    },
    clusterOrderTen: {
        id: 'clusterOrderTen',
        defaultMessage: 'ten',
        description: 'Label of the element in the position ten in a tour of a cluster',
    },
    clusterOrderEleven: {
        id: 'clusterOrderEleven',
        defaultMessage: 'eleven',
        description: 'Label of the element in the position eleven in a tour of a cluster',
    },
    clusterOrderTwelve: {
        id: 'clusterOrderTwelve',
        defaultMessage: 'twelve',
        description: 'Label of the element in the position twelve in a tour of a cluster',
    },
    clusterOrderThirteen: {
        id: 'clusterOrderThirteen',
        defaultMessage: 'thirteen',
        description: 'Label of the element in the position thirteen in a tour of a cluster',
    },
    clusterOrderFourteen: {
        id: 'clusterOrderFourteen',
        defaultMessage: 'fourteen',
        description: 'Label of the element in the position fourteen in a tour of a cluster',
    },
    clusterOrderFifteen: {
        id: 'clusterOrderFifteen',
        defaultMessage: 'fifteen',
        description: 'Label of the element in the position fifteen in a tour of a cluster',
    },
    clusterOrderSixteen: {
        id: 'clusterOrderSixteen',
        defaultMessage: 'sixteen',
        description: 'Label of the element in the position sixteen in a tour of a cluster',
    },
    clusterOrderSeventeen: {
        id: 'clusterOrderSeventeen',
        defaultMessage: 'seventeen',
        description: 'Label of the element in the position seventeen in a tour of a cluster',
    },
    clusterOrderEighteen: {
        id: 'clusterOrderEighteen',
        defaultMessage: 'eighteen',
        description: 'Label of the element in the position eighteen in a tour of a cluster',
    },
    clusterOrderNineteen: {
        id: 'clusterOrderNineteen',
        defaultMessage: 'nineteen',
        description: 'Label of the element in the position nineteen in a tour of a cluster',
    },
    clusterOrderTwenty: {
        id: 'clusterOrderTwenty',
        defaultMessage: 'twenty',
        description: 'Label of the element in the position twenty in a tour of a cluster',
    },
});

export default messages;
