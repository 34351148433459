import { defineMessages } from 'react-intl';

const messages = defineMessages({
    failedToReOrderItem: {
        id:'failedToReOrderItem',
        defaultMessage: 'Unable to re-order item.',
        description: 'Error message when server failed to update item order.',
    },
});

export default messages;
