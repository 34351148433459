import * as React from 'react';

import defaultClassroomImage from 'assets/images/default-classroom.png';
import {
    CardBarUser,
    CardDetails,
    CardMedia,
    CardMediaOverlayText,
    CardStandard,
} from 'ui/components/Card/Cards';

import messages from './displayMessages';

import { WrappedMessage } from 'utils';
import classNames from 'classnames';
import { Icon } from 'elements/components/Icons';

interface LearnerDashboardActiveClassroomCardProps {
    title: string;
    text: string;
    code: React.ReactNode;
    imageUrl?: string;
    educatorName?: string;
    educatorUsername: string;
    onClick: () => void;
    showSkeleton?: boolean;
    joinRequestPending?: boolean;
}

const barBottomRequestPending = <div className='bar-bottom-request-pending'>
    <Icon name='clock-request-pending' />
    <WrappedMessage message={messages.cardRequestPending} />
</div>;

export const LearnerDashboardActiveClassroomCard: React.FC<LearnerDashboardActiveClassroomCardProps> = (
    { title, text, imageUrl, showSkeleton,code, educatorName, educatorUsername, joinRequestPending, onClick }) => (
        <CardStandard
            media={
                <CardMedia
                    mediaUrl={imageUrl || defaultClassroomImage}
                    overlays={
                        [
                            <CardMediaOverlayText
                                message={
                                    <WrappedMessage
                                        message={messages.classCodeOverlayText}
                                        values={{ code }}
                                    />
                                }
                                additionalStyling='media-overlay-position-bottom-left'
                                key='classCodeCardMediaOverlay'
                            />,
                        ]
                    }
                    showSkeleton={showSkeleton}
                />
            }
            details={
                <CardDetails
                    title={title}
                    text={text}
                    showSkeleton={showSkeleton}
                />
            }
            barBottom={joinRequestPending ? barBottomRequestPending :
                <CardBarUser
                    username={educatorUsername}
                    text={educatorName ? educatorName : ''}
                    onClick={onClick}
                    showSkeleton={showSkeleton}
                />
            }
            showBarBottomSeparator={false}
            showBarTopSeparator={false}
            onClick={joinRequestPending ? undefined : onClick}
            additionalStyling={classNames('learner-dashboard-card-component', {'request-pending' : joinRequestPending })}
            showSkeleton={showSkeleton}
        />
    );
