// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ConversationStatus
 */
export interface ConversationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationStatus
     */
    allowed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationStatus
     */
    reason: ConversationStatusReasonEnum;
}

export function ConversationStatusFromJSON(json: any): ConversationStatus {
    return {
        'allowed': json['allowed'],
        'reason': json['reason'],
    };
}

export function ConversationStatusToJSON(value?: ConversationStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'allowed': value.allowed,
        'reason': value.reason,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConversationStatusReasonEnum {
    SenderBlockedReceiver = 'sender blocked receiver',
    ReceiverBlockedSender = 'receiver blocked sender',
    SenderMessagesDisabled = 'sender messages disabled',
    ReceiverMessagesDisabled = 'receiver messages disabled',
    TopicPendingSenderInitiated = 'topic pending sender initiated',
    TopicPendingReceiverInitiated = 'topic pending receiver initiated',
    CannotMessageOneself = 'cannot message oneself',
    TopicExists = 'topic exists',
    MentorMenteeRelationship = 'mentor mentee relationship',
    InSameClass = 'in same class',
    NewTopicOk = 'new topic ok'
}


