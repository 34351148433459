import * as React from 'react';

export interface TextOneProps extends React.PropsWithChildren {
    expandedLineHeight?: boolean;
}

export const TextOne: React.FunctionComponent<TextOneProps> = (props) => {
    return (
        <p className={
            `text-one ` +
            `${props.expandedLineHeight ? 'text-one-expanded-line-height' : ''}`
        }>
            {props.children}
        </p>
    );
};
