// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface LibraryGeneralPermissions
 */
export interface LibraryGeneralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateItems: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateAnnotatedVideoItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateAssignmentItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateAssessmentItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateAudioItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateCaseStudyItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateClusterItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateDocumentItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateImageItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateInteractiveItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateNarrativeItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreatePathwayItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateSimulationItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateLinkItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateTeachingGuideItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateTextItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateVideoItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateLxVideoItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCreateQuestionItem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canCloneForTranslation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canMakeItemPublic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canAssignAssetToClassroom: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canAssignAssetToPathway: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canAssignAssetToMentorship: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canViewPublicContent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canViewOwnContent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canViewOrganizationContent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canViewFavoriteContent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canSendPii: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LibraryGeneralPermissions
     */
    canEditItemOriginalAuthors: boolean;
}

export function LibraryGeneralPermissionsFromJSON(json: any): LibraryGeneralPermissions {
    return {
        'canCreateItems': json['can_create_items'],
        'canCreateAnnotatedVideoItem': json['can_create_annotated_video_item'],
        'canCreateAssignmentItem': json['can_create_assignment_item'],
        'canCreateAssessmentItem': json['can_create_assessment_item'],
        'canCreateAudioItem': json['can_create_audio_item'],
        'canCreateCaseStudyItem': json['can_create_case_study_item'],
        'canCreateClusterItem': json['can_create_cluster_item'],
        'canCreateDocumentItem': json['can_create_document_item'],
        'canCreateImageItem': json['can_create_image_item'],
        'canCreateInteractiveItem': json['can_create_interactive_item'],
        'canCreateNarrativeItem': json['can_create_narrative_item'],
        'canCreatePathwayItem': json['can_create_pathway_item'],
        'canCreateSimulationItem': json['can_create_simulation_item'],
        'canCreateLinkItem': json['can_create_link_item'],
        'canCreateTeachingGuideItem': json['can_create_teaching_guide_item'],
        'canCreateTextItem': json['can_create_text_item'],
        'canCreateVideoItem': json['can_create_video_item'],
        'canCreateLxVideoItem': json['can_create_lx_video_item'],
        'canCreateQuestionItem': json['can_create_question_item'],
        'canCloneForTranslation': json['can_clone_for_translation'],
        'canMakeItemPublic': json['can_make_item_public'],
        'canAssignAssetToClassroom': json['can_assign_asset_to_classroom'],
        'canAssignAssetToPathway': json['can_assign_asset_to_pathway'],
        'canAssignAssetToMentorship': json['can_assign_asset_to_mentorship'],
        'canViewPublicContent': json['can_view_public_content'],
        'canViewOwnContent': json['can_view_own_content'],
        'canViewOrganizationContent': json['can_view_organization_content'],
        'canViewFavoriteContent': json['can_view_favorite_content'],
        'canSendPii': json['can_send_pii'],
        'canEditItemOriginalAuthors': json['can_edit_item_original_authors'],
    };
}

export function LibraryGeneralPermissionsToJSON(value?: LibraryGeneralPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_create_items': value.canCreateItems,
        'can_create_annotated_video_item': value.canCreateAnnotatedVideoItem,
        'can_create_assignment_item': value.canCreateAssignmentItem,
        'can_create_assessment_item': value.canCreateAssessmentItem,
        'can_create_audio_item': value.canCreateAudioItem,
        'can_create_case_study_item': value.canCreateCaseStudyItem,
        'can_create_cluster_item': value.canCreateClusterItem,
        'can_create_document_item': value.canCreateDocumentItem,
        'can_create_image_item': value.canCreateImageItem,
        'can_create_interactive_item': value.canCreateInteractiveItem,
        'can_create_narrative_item': value.canCreateNarrativeItem,
        'can_create_pathway_item': value.canCreatePathwayItem,
        'can_create_simulation_item': value.canCreateSimulationItem,
        'can_create_link_item': value.canCreateLinkItem,
        'can_create_teaching_guide_item': value.canCreateTeachingGuideItem,
        'can_create_text_item': value.canCreateTextItem,
        'can_create_video_item': value.canCreateVideoItem,
        'can_create_lx_video_item': value.canCreateLxVideoItem,
        'can_create_question_item': value.canCreateQuestionItem,
        'can_clone_for_translation': value.canCloneForTranslation,
        'can_make_item_public': value.canMakeItemPublic,
        'can_assign_asset_to_classroom': value.canAssignAssetToClassroom,
        'can_assign_asset_to_pathway': value.canAssignAssetToPathway,
        'can_assign_asset_to_mentorship': value.canAssignAssetToMentorship,
        'can_view_public_content': value.canViewPublicContent,
        'can_view_own_content': value.canViewOwnContent,
        'can_view_organization_content': value.canViewOrganizationContent,
        'can_view_favorite_content': value.canViewFavoriteContent,
        'can_send_pii': value.canSendPii,
        'can_edit_item_original_authors': value.canEditItemOriginalAuthors,
    };
}


