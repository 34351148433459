import bind from 'bind-decorator';
import { MentorshipsApi } from 'global/api';
import { intl } from 'i18n';
import { ItemMetadata, Mentorship, MentorshipStatusEnum } from 'labxchange-client';
import { default as libraryMessages } from 'library/displayMessages';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    Button,
    JumpTo,
    Modal,
    Pagination,
    PaginationSizeOptions,
    SearchBar,
    Spinner,
    Tag
} from 'ui/components';
import { CardAddButtonAccessory } from 'ui/components/Card/Cards';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage, showSuccessMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { UserCard } from 'user/components/UserCard';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

const PAGINATION_SIZE_OPTIONS = [
    { label: '20', value: 20 },
    { label: '40', value: 40 },
    { label: '60', value: 60 },
];

enum SortOrder {
    AtoZ = 'atoz',
    ZtoA = 'ztoa',
}

interface Props {
    loggedInUsername: string;
    itemMetadata: ItemMetadata;
    onClose(): void;
}

interface State {
    mentorships: Mentorship[];
    selectedMentorships: Mentorship[];
    loading: boolean;
    sending: boolean;
    paginationSize: number;
    currentPage: number;
    sortOrder: SortOrder;
    keywords?: string;
}

export default class AssignToMentorshipModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            mentorships: [],
            loading: true,
            sending: false,
            selectedMentorships: [],
            paginationSize: 20,
            currentPage: 1,
            sortOrder: SortOrder.AtoZ,
        };
    }

    public async componentDidMount() {
        await this.loadResults();
    }

    public render() {
        const activeTotalPages = Math.ceil(this.state.mentorships.length / this.state.paginationSize);
        const mentorships = this.filterResult();

        return (
            <Modal
                size='large'
                onRequestClose={this.props.onClose}
                showTopBar={true}
                className='item-assign-modal item-assign-mentorship-modal'
                title={
                    <WrappedMessage
                        message={messages.itemAssignToMenteeModalTitle}
                        values={{itemTitle: this.props.itemMetadata.title}}
                    />
                }
                content={
                    <div className='item-assign-mentorship-modal-content'>
                        <SearchBar
                            onSubmit={this.search}
                            searchMessage={messages.itemAssignToClassroomModalSearchText}
                        />
                        { this.state.keywords &&
                            <Tag
                                onClick={this.onTagRemove}
                            >
                                {this.state.keywords}
                            </Tag>
                        }
                        <div className='item-assign-mentorship-modal-controls'>
                            <div className='results-count'>
                                {this.state.loading? <Skeleton /> :
                                    <WrappedMessage message={messages.showingResultsItems}
                                        values={{ count: mentorships.length }}
                                />}
                            </div>
                            {this.state.loading ? <div className='control-skeleton'><Skeleton /></div> : <>
                                {activeTotalPages > 1 &&
                                    <PaginationSizeOptions
                                        name='mentorship-modal-content-pagination-size-options'
                                        value={this.state.paginationSize}
                                        options={PAGINATION_SIZE_OPTIONS}
                                        // eslint-disable-next-line jsx-a11y/no-onchange
                                        onChange={this.onChangeSize}
                                        hidePerPageSuffix={true}
                                    />
                                }
                                <div className='sort-control-wrapper'>
                                    <label htmlFor='sort-select' >
                                        <WrappedMessage message={messages.sortBy} />
                                    </label>
                                    <select // eslint-disable-line jsx-a11y/no-onchange
                                        id='sort-select'
                                        className='lx-select'
                                        onChange={(e) => this.setState({ sortOrder: e.currentTarget.value as unknown as SortOrder })}
                                    >
                                        <option value={SortOrder.AtoZ}>
                                            {intl.formatMessage(messages.atoZ)}
                                        </option>
                                        <option value={SortOrder.ZtoA}>
                                            {intl.formatMessage(messages.ztoA)}
                                        </option>
                                    </select>
                                </div>
                            </>}
                        </div>
                        <div className={`item-assign-mentorship-modal-cards ${this.state.loading ? 'center' : ''}`}>
                            {this.state.loading
                                ? <Spinner />
                                : mentorships.map((mentorship) => {
                                    return <UserCard
                                        key={mentorship.id}
                                        profile={mentorship.mentee}
                                        isSelected={this.isSelected(mentorship)}
                                        accessory={<CardAddButtonAccessory
                                                        onClick={() => this.onItemAdd(mentorship)}
                                                        selected={this.isSelected(mentorship)}
                                                        slectedIconType={'check'}
                                                    />}
                                        showSkeleton={this.state.loading}
                                        clipName={true}
                                    />;
                                })
                            }
                        </div>
                        {activeTotalPages > 1 &&
                            <div className='pagination-controls'>
                                <JumpTo
                                    pageCount={activeTotalPages}
                                    currentPage={this.state.currentPage}
                                    onPageSelect={this.onChangePage}
                                />
                                <Pagination
                                    pageCount={activeTotalPages}
                                    currentPage={this.state.currentPage}
                                    onPageSelect={this.onChangePage}
                                />
                            </div>
                        }
                    </div>
                }
                footer={this.state.selectedMentorships.length !== 0 ?
                    <div className='modal-bottom-bar'>
                        <Button
                            disabled={this.state.sending}
                            onClick={this.props.onClose}
                            label={uiMessages.uiCancelButton}
                            btnStyle='link'/>
                        <Button
                            btnStyle='primary'
                            onClick={this.onUpdate}
                            label={messages.itemAssignToMenteeModalSubmitButton}
                            labelValues={{counter: this.state.selectedMentorships.length}}
                            disabled={this.state.sending || this.state.selectedMentorships.length === 0} />
                    </div>
                    : undefined
                }
            />
        );
    }

    @bind private async onUpdate() {
        this.setState({sending: true}, async () => {
            const existingItems: Mentorship[] = [];
            await Promise.all(this.state.selectedMentorships.map(async (mentorship) => {
                try {
                    let alreadyExists = false;
                    const mentorshipItems = await MentorshipsApi.mentorshipItemsList(
                        {mentorshipId: mentorship.id},
                    );
                    mentorshipItems.forEach((mentorshipItem) => {
                        if (mentorshipItem.item.metadata.id === this.props.itemMetadata.id) {
                            alreadyExists = true;
                        }
                    });
                    if (!alreadyExists) {
                        await MentorshipsApi.mentorshipItemsCreate({data: {
                            mentorship: mentorship.id,
                            item: this.props.itemMetadata.id,
                        }});
                    } else {
                        existingItems.push(mentorship);
                    }
                } catch (err) {
                    showErrorMessage(
                        <WrappedMessage message={messages.failedToAddItem} />,
                        {exception: err},
                    );
                }

            }));
            this.showSuccessModal(existingItems);
            this.setState({sending: false});
            this.props.onClose();
        });
    }

    private showSuccessModal(existingItems: Mentorship[]) {
        showSuccessMessage(<div className='item-assign-success-modal'>
            <WrappedMessage
                message={messages.itemAssignToMenteeModalSuccessText}
                values={{count: this.state.selectedMentorships.length}}
            />
            { existingItems.length ? <div className='item-assign-success-modal-notice'>
                <WrappedMessage
                    message={messages.itemAssignToMenteeModalAdditionalText}
                    values={{count: existingItems.length}}
                />
                <ul>
                    {existingItems.map((mentorship) => (
                        <li key={mentorship.id}>{mentorship.mentee.fullName}</li>
                    ))}
                </ul>
            </div> : null }
        </div>);
    }

    @bind private search(searchKeywords: string) {
        this.setState({
            loading: true,
            currentPage: 1,
            keywords: searchKeywords,
        }, () => this.loadResults());
    }

    @bind private onChangeSize(size: number) {
        this.setState({
            paginationSize: size,
            currentPage: 1,
        });
    }

    @bind private onChangePage(page: number) {
        this.setState({
            currentPage: page,
        });
    }

    @bind private onItemAdd(mentorship: Mentorship) {
        const selectedMentorships = this.state.selectedMentorships;
        const index = selectedMentorships.indexOf(mentorship);
        if (index > -1) {
            selectedMentorships.splice(index, 1);
        } else {
            selectedMentorships.push(mentorship);
        }
        this.setState({selectedMentorships}, () => this.forceUpdate());
    }

    @bind private isSelected(mentorship: Mentorship): boolean {
       return this.state.selectedMentorships.includes(mentorship);
    }

    @bind private filterResult() {
        let mentorships = this.state.mentorships;
        switch (this.state.sortOrder) {
            case SortOrder.AtoZ: {
                mentorships.sort((x, y) => x.mentee.fullName.localeCompare(y.mentee.fullName));
                break;
            }
            case SortOrder.ZtoA: {
                mentorships.sort((x, y) => -x.mentee.fullName.localeCompare(y.mentee.fullName));
                break;
            }
        }
        const start = this.state.paginationSize * (this.state.currentPage - 1);

        mentorships = mentorships.slice(start, start + this.state.paginationSize);
        return mentorships;
    }

    @bind private async loadResults() {
        try {
            const mentorships = await MentorshipsApi.list({
                mentor: this.props.loggedInUsername,
                status: MentorshipStatusEnum.Accepted,
                search: this.state.keywords ?? '',
            });
            this.setState({mentorships, loading: false});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={libraryMessages.searchError} />, {exception: err});
            this.setState({mentorships: [], loading: false});
        }
    }

    @bind private onTagRemove() {
        this.setState({
            keywords: undefined,
            loading: true,
        }, () => this.loadResults());
    }
}
