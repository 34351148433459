import bind from 'bind-decorator';
import { Icon } from 'elements';
import { ROUTES } from 'global/constants';
import * as React from 'react';

import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import { EVENT_NAMES } from 'tracking/constants';

import messages from '../displayMessages';
import { DropdownList } from '../Dropdown';
import { analyticsInstance } from '../../../tracking';

interface LoggedInMenuProps {
    onLogOut: () => void;
    username: string;
    className?: string;
}

export class LoggedInMenu extends React.Component<LoggedInMenuProps> {

    public render() {
        const avatar = <UserAvatar
            username={this.props.username}
        />;
        return (
                <DropdownList
                    id='menu-logged-in'
                    label={avatar}
                    className={`logged-in-menu-button ${this.props.className ? this.props.className : ''}`}
                    onExpandedChange={(expanded) => {
                        if (expanded) {
                            analyticsInstance.track(
                                EVENT_NAMES.ProfileDropdownHeaderExpanded,
                                {
                                    icon_title: messages.uiProfileDropdown.defaultMessage,
                                    url: window.location.toString()
                                }
                            );
                        }
                    }}
                    links={[
                        {
                            key: 'myprofile',
                            to: ROUTES.Users.PROFILE_EDIT_SLUG(this.props.username),
                            content: <>
                                <Icon zoom='16px' name='person' className='logged-in-menu-icon' />
                                <WrappedMessage message={messages.uiMyProfile}/>
                            </>,
                            onClick: () => {
                                analyticsInstance.track(
                                    EVENT_NAMES.ProfileDropdownMyProfileClicked,
                                    {
                                        icon_title: messages.uiMyProfile.defaultMessage,
                                        url: window.location.toString()
                                    }
                                );
                            }
                        },
                        {
                            key: 'settings',
                            to: ROUTES.Personal.PROFILE_SETTINGS,
                            content: <>
                                <Icon zoom='16px' name='settings2' className='logged-in-menu-icon' />
                                <WrappedMessage message={messages.uiSettings}/>
                            </>,
                            onClick: () => {
                                analyticsInstance.track(
                                    EVENT_NAMES.ProfileDropdownSettingsClicked,
                                    {
                                        icon_title: messages.uiSettings.defaultMessage,
                                        url: window.location.toString()
                                    }
                                );
                            }
                        },
                        {
                            key: 'logout',
                            to: '/logout',
                            content: <>
                                <Icon zoom='16px' name='key' className='logged-in-menu-icon' />
                                <WrappedMessage message={messages.uiLogOut}/>
                            </>,
                            onClick: (event) => {
                                analyticsInstance.track(
                                    EVENT_NAMES.ProfileDropdownLogoutClicked,
                                    {
                                        icon_title: messages.uiLogOut.defaultMessage,
                                        url: window.location.toString()
                                    },
                                    { sendImmediately: true }
                                );
                                this.onLogOut(event);
                            }
                        },
                    ]}
            />

        );
    }

    @bind private onLogOut(event: React.MouseEvent): void {
        event.preventDefault();
        this.props.onLogOut();
    }
}
