import * as React from 'react';
import { PAGINATION_OPTIONS } from 'global/constants';


import { Button, Pagination, PaginationSizeOptions } from 'ui/components';
import messages from 'ui/components/displayMessages';

interface CardsListBottomBarProps {
    isMobileView: boolean;
    currentPage: number;
    onPageJump: (pageNumber: number) => void;
    onPageNavigate: (pageNumber: number) => void;
    onSizeSelect: (paginationSize: number) => void;
    loadMore: () => void;
    pageCount: number;
    paginationSize: number;
}

/**
 * The CardsListBottomBar allows selecting a page in a paginated list of Cards.
 */
export class CardsListBottomBar extends React.Component<CardsListBottomBarProps> {

    public render() {
        const paginationSizeOptions = <PaginationSizeOptions
            name='cards_pagination_size_options'
            value={this.props.paginationSize}
            options={PAGINATION_OPTIONS}
            onChange={this.props.onSizeSelect}
        />;

        return (
            <>
                <div className='cards-list-bottom-bar d-flex justify-content-between mb-3'>
                    <div className='lxc-hide-mobile w-100 d-flex align-items-center'>
                        <Pagination
                            pageCount={this.props.pageCount}
                            currentPage={this.props.currentPage}
                            onPageSelect={this.props.onPageNavigate}
                        />
                        {!this.props.isMobileView &&
                            <div className='ml-auto pagination-size-options'>{ paginationSizeOptions }</div>
                        }
                    </div>
                </div>
                <div className='d-md-none'>
                    {this.nextPageExists &&
                        <>
                            <Button
                                label={messages.showMoreResults}
                                onClick={this.props.loadMore}
                                fullWidth={true}
                            />
                        </>
                    }
                </div>
            </>
        );
    }

    private get nextPageExists() {
        return this.props.pageCount >= this.props.currentPage + 1;
    }
}
