import moment from 'moment-shortformat';

/**
 * A calendar date (date without time)
 * The month and days are 1-indexed, so (2020, 1, 1) is January 1, 2020
 */
export class CalendarDate {
    /** Construct a CalendarDate from an ISO 8601 string like "2020-01-01" */
    public static fromIsoString(isoString: string) {
        const year = parseInt(isoString.substr(0, 4), 10);
        const month = parseInt(isoString.substr(5, 2), 10);
        const day = parseInt(isoString.substr(8, 2), 10);
        return new CalendarDate(year, month, day);
    }
    /**
     * Construct a CalendarDate from a JavaScript Date
     * This assumes that the date was constructed using UTC,
     * which is the case if you pass a date-only ISO 8601 string
     * ("2020-01-01") to Date() or Date.parse() but is NOT the
     * case if you pass a date-time string (e.g. "2011-10-10T14:48:00").
     * Beware!
     */
    public static fromJsDate(date: Date) {
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();
        return new CalendarDate(year, month, day);
    }

    private values: [number, number, number];

    constructor(year: number, month: number, day: number) {
        if (isNaN(year) || isNaN(month) || isNaN(day)) { throw new Error('CalendarDate param is not a number.'); }
        this.values = [year, month, day];
    }

    get year() { return this.values[0]; }
    get month() { return this.values[1]; }
    get day() { return this.values[2]; }
    /** ISO 8601 date string, e.g. "2020-01-01" */
    get isoString() {
        return `${this.year}-${this.month.toString().padStart(2, '0')}-${this.day.toString().padStart(2, '0')}`;
    }
    get ageInYears() {
        return moment().diff(moment(this.isoString), 'years');
    }
    /**
     * This returns a Date object for this date.
     * Note that it will be only correct when displayed in the UTC timezone.
     * This means if you are in a negative timezone, and naively print this date, the date will be off by one.
     *
     * See also notes in fromJsDate.
     */
    public toJsDate(): Date {
        return new Date(this.isoString);
    }
}
