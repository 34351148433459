import bind from 'bind-decorator';
import { AnalyticsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import {
    AnalyticsEventEventTypeEnum,
    ContentSource,
    OrganizationSummaryOrganizationTypeEnum,
    OrganizationSummary,
} from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { WrappedMessage } from 'utils';
import { Icon } from 'elements/components/Icons';
import { sanitizeUnsafeHTML, sanitizeConfigOptions } from '@labxchange/util-sanitization';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

interface LinkProps {
  href: string;
  label: string;
}

const Link: React.FC<LinkProps> = ({ href, label }) => (
  <a
    href={href}
    target='_blank'
    className={'item-source-provider-url'}
    rel='noopener noreferrer'
  >
    {label}
  </a>
);

interface LinkListProps {
  links: LinkProps[];
}

const OrgLinkList: React.FC<LinkListProps> = ({ links }) => {
  if (links.length === 0) {
    return null;
  } else if (links.length === 1) {
    return <Link href={links[0].href} label={links[0].label} />;
  } else {
    const formattedLinks = links.map((link, index) => {
      if (index === links.length - 1) {
        return <span key={index}> <WrappedMessage message={messages.contentSourceDescriptionPart} /> <Link href={link.href} label={link.label} /></span>;
      } else {
        return <span key={index}><Link href={link.href} label={link.label} />, </span>;
      }
    });

    return <>{formattedLinks}</>;
  }
};


interface Props extends ContentSource {
    itemId: string;
    className?: string;
}

/**
 * The box at the top right of a unit page that displays where content comes from.
 */
export class ContentSourceInfoBox extends React.PureComponent<Props, {}> {

    private pathParams = window.location.search;
    private pathname = window.location.pathname;

    /// Verifies if 'infoUrl' and 'orgUrl' has the same hostname
    public showProfile(infoUrlString: string, orgUrlString: string) {
      try {
        const infoUrl = new URL(infoUrlString);
        const orgUrl = new URL(orgUrlString);
        return infoUrl.hostname !== orgUrl.hostname;
      } catch (_) {
        return true;
      }
    }

    public getSourceOrganizationsText () {
      return this.props.sourceOrganizations.map((obj, index) => (
        {
          href: obj.customLink || this.getOrgUrlAndInfo(obj.organization).orgUrl,
          label: obj.organization.name

        }
      ));
    }

    public getOrgUrlAndInfo(org: OrganizationSummary) {
      const orgInfo = {
        orgUrl: '#',
        orgIsPartner: false,
        orgUrlIsExternal: false
      };
      if (
        org.organizationType ===
          OrganizationSummaryOrganizationTypeEnum.SPONSOR ||
        org.organizationType ===
          OrganizationSummaryOrganizationTypeEnum.PARTNER
      ) {
        orgInfo.orgUrl = ROUTES.Organizations.PROFILE_SLUG(org.slug);
        orgInfo.orgIsPartner = true;
      } else if (org.url) {
        orgInfo.orgUrl = org.url;
        orgInfo.orgUrlIsExternal = true;
      }
      return orgInfo;
    }

    public render() {
        let orgUrl = '#';
        let orgIsPartner = false;
        let orgUrlIsExternal = false;
        let sourceOrg = null;
        let showProfileButton = false;
        let orgLibraryUrl = '#';
        const infoUrl = this.props.infoUrl;
        let buttonCount = 0;

        if (this.props.sourceOrganizations.length === 1) {
          sourceOrg = this.props.sourceOrganizations[0].organization;

          const orgInfo = this.getOrgUrlAndInfo(sourceOrg);
          orgUrl = orgInfo.orgUrl;
          orgIsPartner = orgInfo.orgIsPartner;
          orgUrlIsExternal = orgInfo.orgUrlIsExternal;

          if (orgUrl !=='#') {
            showProfileButton = this.showProfile(infoUrl, orgUrl);
          }
          orgLibraryUrl = `${ROUTES.Library.HOME}?t=Partner:${sourceOrg.id}&page=1&size=24&order=relevance`;
        }

        const providerLink = (content: any) => {
          if (orgIsPartner) {
            return (
              <NavLink to={orgUrl} className={'item-source-provider-url'}>
                {content}
              </NavLink>
            );
          } else {
            return (
              <a
                href={orgUrl}
                target='_blank'
                rel='noopener noreferrer'
                className={'item-source-provider-url'}
              >
                {content}
              </a>
            );
          }
        };

        if (sourceOrg && infoUrl) {
          buttonCount++;
        }
        return (
            /* The <div> element has a child elements that allow keyboard interaction */
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            /* eslint-disable jsx-a11y/no-static-element-interactions */
            <div
              className={`source ${this.props.className || ''}`}
              onClick={this.onLinkClick}
            >
              <div className='source-content'>
                {sourceOrg &&
                  sourceOrg.largeLogoUrl &&
                  providerLink(
                    <img
                      className='mb-3'
                      src={sourceOrg.largeLogoUrl}
                      alt={sourceOrg.name}
                    />
                  )}

                {this.props.sourceOrganizations.length > 1 ? (
                  <div
                    className={`providers-logo-container count-${this.props.sourceOrganizations.length}`}
                  >
                    {this.props.sourceOrganizations.map((obj, index) => (
                      <div
                        key={obj.organization.id + index}
                        className='providers-logo'
                      >
                        <a
                          href={obj.customLink || this.getOrgUrlAndInfo(obj.organization).orgUrl}
                          target='_blank'
                          className={'item-source-provider-url'}
                          rel='noopener noreferrer'
                        >
                          <img src={obj.organization.logoUrl} alt={obj.organization.name} data-event-org={obj.organization.slug} />
                        </a>
                      </div>
                    ))}
                  </div>
                ) : null}

                {infoUrl && this.props.showEnrollAction && (
                  <a
                    href={infoUrl}
                    className='source-action primary-action nudging-button-primary mt-2 item-source-enroll-url'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Icon
                      name={'link-external'}
                      zoom={'x1.0'}
                      className={'external-link-icon'}
                    />
                    <WrappedMessage message={messages.contentSourceEnrollAction} />
                  </a>
                )}

                {sourceOrg && <p className='source-title'>{sourceOrg.name}</p>}

                <div className='source-text'>
                  {this.props.customDescription ? (
                    <HTMLEllipsis
                      className='custom-description'
                    unsafeHTML={sanitizeUnsafeHTML(this.props.customDescription, sanitizeConfigOptions.UnsafeLightHTML)}
                      maxLine={1000} // hopefully won't get description any longer than that...
                    />
                  ) : (
                    this.props.title ? (
                      <WrappedMessage
                        message={messages.contentSourceVerbose}
                        values={{
                          organizationNames: <OrgLinkList links={this.getSourceOrganizationsText()} />,
                          sourceName: (
                            <a
                              href={infoUrl}
                              target='_blank'
                              rel='noopener noreferrer'
                              className={'item-source-source-url'}
                            >
                              {this.props.title}
                            </a>
                          ),
                        }}
                      />
                    ) : (
                      <WrappedMessage
                        message={messages.contentSourceSimple}
                        values={{ organizationNames: <OrgLinkList links={this.getSourceOrganizationsText()} />}}
                      />
                    )
                  )}
                </div>
              </div>
              <div className='source-actions action-button-div'>
                {sourceOrg && infoUrl && (
                  <a
                    href={infoUrl}
                    className={`source-action item-source-info-url action-button right-border`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Icon
                      name={'link-external'}
                      zoom={'x1.0'}
                      className={'external-link-icon'}
                    />
                    {this.props.infoButtonText || (
                      <WrappedMessage
                        message={messages.contentSourceInfoButtonText}
                      />
                    )}
                  </a>
                )}

                {showProfileButton ? (
                  <a
                    href={orgUrl}
                    className={`source-action item-source-profile-url action-button${buttonCount === 1 ? ' left-border' : ''}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {orgUrlIsExternal ? (
                      <Icon
                        name={'link-external'}
                        zoom={'x1.0'}
                        className={'external-link-icon'}
                      />) : (<Icon
                      name={'badge'}
                      zoom={'x1.0'}
                    />
                    )}
                    <WrappedMessage message={messages.viewOwnerProfileButton} />
                  </a>
                ) : (
                  sourceOrg && (
                    <a
                      href={orgLibraryUrl}
                      className={`source-action item-source-library-url action-button${buttonCount === 1 ? ' left-border' : ''}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <WrappedMessage message={messages.libraryViewButtonLabel} />
                    </a>
                  )
                )}
              </div>
            </div>
        );
    }

    @bind private onLinkClick(event: React.MouseEvent) {
        const eventTargetClassNames = (event.target as HTMLElement).className.split(' ');
        const eventSourceOrgSlug = (event.target as HTMLElement).getAttribute('data-event-org') || '';
        let clickEventType;
        let orgEventType;
        if (eventTargetClassNames.includes('item-source-provider-url')) {
            clickEventType = AnalyticsEventEventTypeEnum.AssetSourceProviderLinkClicked;
            orgEventType = AnalyticsEventEventTypeEnum.OrganizationLabXchangeProfileVisited;
        } else if (eventTargetClassNames.includes('item-source-source-url')) {
            clickEventType = AnalyticsEventEventTypeEnum.AssetSourceSourceLinkClicked;
            orgEventType = AnalyticsEventEventTypeEnum.OrganizationWebsiteClicked;
        } else if (eventTargetClassNames.includes('item-source-info-url')) {
            clickEventType = AnalyticsEventEventTypeEnum.AssetSourceInfoLinkClicked;
            orgEventType = AnalyticsEventEventTypeEnum.OrganizationWebsiteClicked;
        } else if (eventTargetClassNames.includes('item-source-enroll-url')) {
            clickEventType = AnalyticsEventEventTypeEnum.AssetSourceEnrollLinkClicked;
        } else if (eventTargetClassNames.includes('item-source-profile-url')) {
            orgEventType = AnalyticsEventEventTypeEnum.OrganizationLabXchangeProfileVisited;
        }

        if (clickEventType) {
            AnalyticsApi.create({
                data: {
                    eventType: clickEventType,
                    objectId: this.props.id,
                    data: {
                        itemId: this.props.itemId,
                    },
                },
            });
        }
        if (orgEventType) {
            /// From a normal asset
            let fromValue = 'asset';
            if (this.pathParams.search('lx-book') >= 0) { /// From a textbook
                fromValue = 'textbook';
            }
            else if (this.pathname.search('lx-pathway') >= 0) { /// From a pathway
                fromValue = 'pathway';
            }

            AnalyticsApi.create({
                data: {
                    eventType: orgEventType,
                    orgSlug: eventSourceOrgSlug || this.props.provider.slug,
                    objectId: this.props.id,
                    data: {
                        from: fromValue,
                        pathname: this.pathname,
                    },
                },
            });
        }
    }

}
