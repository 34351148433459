// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface XBlockOLX
 */
export interface XBlockOLX {
    /**
     * 
     * @type {string}
     * @memberof XBlockOLX
     */
    blockId: string;
    /**
     * 
     * @type {string}
     * @memberof XBlockOLX
     */
    blockOlx: string;
}

export function XBlockOLXFromJSON(json: any): XBlockOLX {
    return {
        'blockId': json['block_id'],
        'blockOlx': json['block_olx'],
    };
}

export function XBlockOLXToJSON(value?: XBlockOLX): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'block_id': value.blockId,
        'block_olx': value.blockOlx,
    };
}


