import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { intl } from 'i18n';
import displatMessages from '../displayMessages';


interface Props {
    isChecked: boolean;
    leftMessage?: MessageDescriptor;
    rightMessage?: MessageDescriptor;
    onToggle?: (newValue: boolean) => void;
    extraClass?: string;
    label?: MessageDescriptor;
}

export const ToggleTextSwitch: React.FunctionComponent<Props> = (props) => {
    const leftLabel = intl.formatMessage(props.leftMessage ?? displatMessages.uiDefaultAnswerCorrectToggle);
    const rightLabel = intl.formatMessage(props.rightMessage ?? displatMessages.uiDefaultAnswerIncorrectToggle);
    const toggleLabel = intl.formatMessage(props.label ?? displatMessages.uiDefaultToggleLabel);

    return (
        <div className={`lx-toggle-text-switch-container ${props.extraClass ?? ''}`} role='group' aria-labelledby='toggleLabel'>
            <div id='toggleLabel' className='visually-hidden'>{toggleLabel}</div>
            <fieldset>
                <button
                    className={`unstyled ${props.isChecked ? 'active' : ''}`} aria-pressed={props.isChecked? 'true' : 'false'}
                    onClick={() => { if (props.onToggle) { props.onToggle(!props.isChecked); } }}>
                        {leftLabel}
                </button>
                <button
                    className={`unstyled ${props.isChecked ? '' : 'active'}`} aria-pressed={props.isChecked? 'false' : 'true'}
                    onClick={() => { if (props.onToggle) { props.onToggle(!props.isChecked); } }}>
                        {rightLabel}
                </button>
                <div aria-live='assertive' className='toggleStatus visually-hidden'>
                    {intl.formatMessage(displatMessages.uiToggleStatus, {type: toggleLabel, status: props.isChecked ? leftLabel: rightLabel})}
                </div>
            </fieldset>
        </div>
    );
};
