import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ROUTES } from 'global/constants';
import { EducatorContentDashboard, FavoritesDashboard } from 'library/components';
import { EducatorClassroomsDashboard, LearnerClassroomsDashboard } from 'classrooms/components';
import { TabLink, TabbedPageLayout } from '../TabbedPageLayout';
import messages from './displayMessages';
import { MessagePage } from '../../messages/components';

export const DashboardNavigationLinks: TabLink[] = [
    {
        to: ROUTES.Dashboard.HOME,
        message: messages.tabMyContent,
        exact: true,
        onlyEducator: false
    },
    {
        to: ROUTES.Dashboard.FAVORITES,
        message: messages.tabFavorites,
        exact: true,
        onlyEducator: false
    },
    {
        to: ROUTES.Dashboard.EDUCATOR_CLASSROOMS,
        message: messages.tabMyClasses,
        exact: true,
        onlyEducator: true
    },
    {
        to: ROUTES.Dashboard.LEARNER_CLASSROOMS,
        message: messages.tabClasses,
        exact: true,
        onlyEducator: false
    },
    {
        to: ROUTES.Messages.HOME,
        message: messages.tabMessages,
        exact: false,
        onlyEducator: false
    },
];

/**
 * Dashboard page container.
 * - Render available tabs for educator and learner
 */
export const DashboardPage: React.FC<RouteComponentProps> = (props) => {

    const currentFocus = React.useRef<HTMLElement | null>(null);

    React.useLayoutEffect(() => {
        const handleFocusIn = (event: FocusEvent) => {
            currentFocus.current = event.target as HTMLElement;
        };

        document.addEventListener('focusin', handleFocusIn);

        if (currentFocus.current) {
            currentFocus.current.focus();
        }

        return () => {
            document.removeEventListener('focusin', handleFocusIn);
        };
    }, [props.location.pathname]);

    const getTitle = () => {
        switch (props.match.path) {
            case ROUTES.Dashboard.HOME:
                return messages.myContentPageTitle;
            case ROUTES.Dashboard.FAVORITES:
                return messages.favoritesPageTitle;
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return messages.classroomsPageTitle;
            case ROUTES.Dashboard.LEARNER_CLASSROOMS:
                return messages.myClassroomsPageTitle;
            default:
                return undefined;
        }
    };

    const getContent = () => {
        switch (props.match.path) {
            case ROUTES.Dashboard.HOME:
                return <EducatorContentDashboard />;
            case ROUTES.Dashboard.FAVORITES:
                return <FavoritesDashboard />;
            case ROUTES.Dashboard.EDUCATOR_CLASSROOMS:
                return <EducatorClassroomsDashboard />;
            case ROUTES.Dashboard.LEARNER_CLASSROOMS:
                return <LearnerClassroomsDashboard />;
            case ROUTES.Messages.MESSAGE_NEW:
                return <MessagePage />;
            case ROUTES.Messages.MESSAGE:
                return <MessagePage />;
            case ROUTES.Messages.HOME:
                return <MessagePage />;
            default:
                return null;
        }
    };

    return (
        <TabbedPageLayout
            pageTitle={getTitle()}
            tabs={DashboardNavigationLinks}
        >
            <div >
                {getContent()}
            </div>
        </TabbedPageLayout>
    );
};
