import { defineMessages } from 'react-intl';

const messages = defineMessages({
    confirmationModalCancelButton: {
        id:'confirmationModalCancelButton',
        defaultMessage: 'Cancel',
        description: 'Button label for back button in modal which presents when an educator changes archive status from class settings',
    },
    confirmationModalArchiveConfirmButton: {
        id:'confirmationModalArchiveConfirmButton',
        defaultMessage: 'Archive class',
        description: 'Button label for archive class button in modal which presents when an educator is about to archive a class',
    },
    confirmationModalArchiveTitle: {
        id:'confirmationModalArchiveTitle',
        defaultMessage: 'Are you sure you want to archive this class?',
        description: 'Header text for modal which presents when an educator is about to archive a class',
    },
    confirmationModalArchiveBody: {
        id:'confirmationModalArchiveBody',
        defaultMessage: 'No progress or discussions will be lost when you archive this class. Your learners will be notified that you have archived this class. You can unarchive this class from your Educator Dashboard.',
        description: 'Body text for modal which presents when an educator is about to archive a class',
    },
    confirmationModalArchiveCancelButton: {
        id:'confirmationModalArchiveCancelButton',
        defaultMessage: 'Cancel',
        description: 'Button label for cancel button in modal which presents when an educator is about to archive a class',
    },
    confirmationModalUnarchiveConfirmButton: {
        id:'confirmationModalUnarchiveConfirmButton',
        defaultMessage: 'Unarchive class',
        description: 'Button label for un-aarchive class button in modal which presents when an educator is about to un-archive a class',
    },
    confirmationModalUnarchiveTitle: {
        id:'confirmationModalUnarchiveTitle',
        defaultMessage: 'Are you sure you want to unarchive this class?',
        description: 'Header text for modal which presents when an educator is about to archive a class',
    },
    confirmationModalUnarchiveBody: {
        id:'confirmationModalUnarchiveBody',
        defaultMessage: 'Discussions and progress will be restored. Your learners will be notified that you have unarchived this class.',
        description: 'Body text for modal which presents when an educator is about to unarchive a class',
    },
    confirmationModalDeleteTitle: {
        id:'confirmationModalDeleteTitle',
        defaultMessage: 'Are you sure you want to delete this class?',
        description: 'Header text for modal which presents when an educator is about to delete a class',
    },
    confirmationModalDeleteBody: {
        id:'confirmationModalDeleteBody',
        defaultMessage: 'Deleting a class is permanent and cannot be undone.',
        description: 'Body text for modal which presents when a class is about to be deleted',
    },
    confirmationModalDeleteConfirmButton: {
        id:'confirmationModalDeleteConfirmButton',
        defaultMessage: 'Delete class',
        description: 'Button label for delete class button in the modal which presents when an educator is about to archive a class',
    },
    confirmationModalDeleteCancelButton: {
        id:'confirmationModalDeleteCancelButton',
        defaultMessage: 'Cancel',
        description: 'Button label for cancel button in the modal which presents when an educator is about to delete a class',
    },
    failedToArchiveClass: {
        id:'failedToArchiveClass',
        defaultMessage: 'Unable to archive class',
        description: 'Error message for when the class cannot be archived',
    },
    failedToUnarchiveClass: {
        id:'failedToUnarchiveClass',
        defaultMessage: 'Unable to unarchive class',
        description: 'Error message for when the class cannot be unarchived',
    },
    failedToDeleteClass: {
        id:'failedToDeleteClass',
        defaultMessage: 'Unable to delete class',
        description: 'Error message for when the class cannot be deleted',
    },
    confirmationModalCopyTitle: {
        id:'confirmationModalCopyTitle',
        defaultMessage: 'Copy this class?',
        description: 'Header text for modal which presents when an educator is about to copy a class',
    },
    confirmationModalCopyBody: {
        id:'confirmationModalCopyBody',
        defaultMessage: `If you copy this class, all of the content assigned to the original class will be copied, but
        remains unposted until you assign it. Co-educators, learners, progress, and discussion threads will not be copied
        from the original class.`,
        description: 'Body text for modal which presents when an educator is about to copy a class',
    },
    confirmationModalCopyConfirmButton: {
        id:'confirmationModalCopyConfirmButton',
        defaultMessage: 'Copy class',
        description: 'Button label for copy class button in the modal which presents when an educator is about to copy a class',
    },
    confirmationModalCopyCancelButton: {
        id:'confirmationModalCopyCancelButton',
        defaultMessage: 'Cancel',
        description: 'Button label for cancel button in the modal which presents when an educator is about to copy a class',
    },
    classCodePlaceholderText: {
        id:'classCodePlaceholderText',
        defaultMessage: 'Enter class code',
        description: 'Placeholder text for class code input field on Join classroom Modal, which presents when a user attempts to join a classroom from a Learner Dashboard, or Classroom Page',
    },
    requestFailureTitle: {
        id:'requestFailureTitle',
        defaultMessage: 'Request Failed.',
        description: 'title text for request failed error modal',
    },
    requestFailureMessage: {
        id:'requestFailureMessage',
        defaultMessage: `We were unable to submit your request to join this class. Please check the code and try again.`,
        description: 'Body text for request failed error modal',
    },
    requestFailureClassFullTitle: {
        id:'requestFailureClassFullTitle',
        defaultMessage: 'This Class is Full',
        description: 'Title for class is full error modal',
    },
    requestFailureClassFullBody: {
        id:'requestFailureClassFullBody',
        defaultMessage: `This class has reached its enrollment limit, so you will not be able to enroll.
         Please contact the educator who gave you the class code.`,
        description: 'Body for class is full error modal',
    },
    requestFailureClassArchivedTitle: {
        id:'requestFailureClassArchivedTitle',
        defaultMessage: 'This Class is Archived',
        description: 'Title for class is archived error modal',
    },
    requestFailureClassArchivedBody: {
        id:'requestFailureClassArchivedBody',
        defaultMessage: 'The educator has archived this class. It is no longer possible to enroll in this class.',
        description: 'Body for class is archived error modal',
    },
    requestFailureEducatorShouldNotJoinAsLearnerTitle: {
        id:'requestFailureEducatorShouldNotJoinAsLearnerTitle',
        defaultMessage: 'Error',
        description: 'Title for educator should not join their own class as learner error modal',
    },
    requestFailureEducatorShouldNotJoinAsLearnerBody: {
        id:'requestFailureEducatorShouldNotJoinAsLearnerBody',
        defaultMessage: 'You cannot enroll as a learner in your own class.',
        description: 'Body for educator should not join their own class as learner error modal',
    },
    rejoinConfirmationTitle: {
        id:'rejoinConfirmationTitle',
        defaultMessage: 'Rejoin class?',
        description: 'Title text for confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    rejoinConfirmationText: {
        id:'rejoinConfirmationText',
        defaultMessage: 'The educator will need to accept your request to join this class again.',
        description: 'Body text for confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    rejoinConfirmationButton: {
        id:'rejoinConfirmationButton',
        defaultMessage: 'Confirm',
        description: 'label for confirm button in confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    rejoinCancelButton: {
        id:'rejoinCancelButton',
        defaultMessage: 'Cancel',
        description: 'label for cancel button in confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    archivedModalTitle: {
        id:'archivedModalTitle',
        defaultMessage: 'This class is archived',
        description: 'title text for modal when class is archived',
    },
    archivedModalBody: {
        id:'archivedModalBody',
        defaultMessage: 'The educator has archived this class. You can no longer join this class or view the content.',
        description: 'body text for archive modal',
    },
    welcomeClassTitle: {
        id:'welcomeClassTitle',
        defaultMessage: 'Welcome to {classroomName}',
        description: 'Title text for Welcome Class Modalm which presents when a user first joins a classroom',
    },
    welcomeIsTaughtByText: {
        id:'welcomeIsTaughtByText',
        defaultMessage: '{classroomName} is taught by {educatorName}',
        description: 'Body text for Welcome Class modal, which presents when a user first joins a classroom',
    },
    welcomeClassButtonText: {
        id:'welcomeClassButtonText',
        defaultMessage: 'Explore Class',
        description: 'Button text for Welcome Class Modal, which presents when a user first joins a classroom',
    },
    requestHasBeenSentTitle: {
        id:'requestHasBeenSentTitle',
        defaultMessage: 'Your Request Has Been Sent',
        description: 'Title text for Request Has Been Sent Modal, which presents when a user first requests to join a classroom',
    },
    requestHasBeenSentBody: {
        id:'requestHasBeenSentBody',
        defaultMessage: 'You\'ll receive a confirmation when your request has been accepted or declined.',
        description: 'Body text for Request Has Been Sent Modal, which presents when a user first requests to join a classroom',
    },
    requestHasBeenSentButton: {
        id:'requestHasBeenSentButton',
        defaultMessage: 'Done',
        description: 'Button text for Request Has Been Sent Modal, which presents when a user first requests to join a classroom',
    },
});

export default messages;
