import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ROUTES } from 'global/constants';
import { Organization } from 'labxchange-client';
import { NavTabs, NavTabsLink } from 'ui/components';
import { ItemCounts } from '../utils';
import messages from './displayMessages';
import { intl } from 'i18n';
import { OrgSocialLinks } from 'org/components';
import { RichText } from 'elements/components/RichText';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';


interface Props {
    profile: Organization;
    /** Does this user have permission to view the org's dashboard? (org member or system admin) */
    canViewDashboard: boolean;
    itemCounts: ItemCounts;
    showSkeleton?: boolean;
}

export class OrgProfileHeader extends React.PureComponent<Props> {

    public render() {
        const profile = this.props.profile;
        const itemCounts = this.props.itemCounts;
        const altText = intl.formatMessage(messages.logoAltText, {name: profile.name});

        return <>
            {this.props.showSkeleton ?
                <Skeleton className='org-profile-header'/> :
                <div className='org-profile-header'>
                    <h1 aria-label={profile.name}>
                        <img className='logo' src={profile.profilePageLogo} alt={altText}/>
                    </h1>
                    {profile.description &&
                        <div className={`description ${profile.darkTheme ? 'dark' : 'light'}`}>
                            <RichText
                                innerHtml={profile.description}
                                sanitizeConfig={sanitizeConfigOptions.UnsafeLightHTML}
                            />
                        </div>
                    }
                    <OrgSocialLinks showAll={true} organization={profile}/>
                </div>
            }
            <NavTabs scrollUpOnNavigate={true} showSkeleton={this.props.showSkeleton}>
                <NavTabsLink exact to={ROUTES.Organizations.PROFILE_SLUG(profile.slug)}
                    icon='library' message={messages.uiNavTabAll} values={{count: itemCounts.all}}
                             showSkeleton={this.props.showSkeleton}
                />
                <NavTabsLink exact to={ROUTES.Organizations.PROFILE_SLUG(profile.slug, 'cluster')}
                    icon='cluster' message={messages.uiNavTabCluster} values={{count: itemCounts.cluster}}
                             showSkeleton={this.props.showSkeleton}
                />
                <NavTabsLink exact to={ROUTES.Organizations.PROFILE_SLUG(profile.slug, 'pathway')}
                    icon='pathway' message={messages.uiNavTabPathway} values={{count: itemCounts.pathway}}
                             showSkeleton={this.props.showSkeleton}
                />
                <NavTabsLink exact to={ROUTES.Organizations.PROFILE_SLUG(profile.slug, 'content')}
                    icon='document' message={messages.uiNavTabContent} values={{count: itemCounts.content}}
                             showSkeleton={this.props.showSkeleton}

                />
                {this.props.canViewDashboard &&
                    <NavTabsLink exact to={ROUTES.Dashboard.ORGANIZATION_CONTENT_SLUG(this.props.profile.slug)}
                        icon='settings' message={messages.uiNavTabDashboard} showSkeleton={this.props.showSkeleton}
                    />
                }
            </NavTabs>
        </>;
    }
}
