import { bind } from 'bind-decorator';
import { AnnotatedVideoAnnotation } from 'labxchange-client';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as UI from 'ui/components';
import { WrappedMessage } from 'utils';
import { HeadingTwo } from 'elements';
import classNames from 'classnames';
import messages from './displayMessages';
import { PlainText, RichText } from 'elements/components/RichText';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';

interface UserAnswer {
    correct?: boolean;
    value?: string;
}

export interface Props {
    annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[];
    onCloseQuestion(): void;
}

interface State {
    totalQuestionsRemaining: number;
}

export class AnnotationQuestionSidebar extends React.PureComponent<Props, State> {

    // Reference to the first question, whether there's just one or a set of questions.
    // This reference will be utilized to set focus on the currently opened question.
    private firstQuestionRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            totalQuestionsRemaining: Array.isArray(this.props.annotation) ? this.props.annotation.length : 1,
        };
        this.firstQuestionRef = React.createRef();
    }

    public render () {
        if (Array.isArray(this.props.annotation)) {
            return  <>
                    <div className='annotated-video-annotation-question-sidebar'>
                        <div className='annotated-video-annotation-question-sidebar-content'>
                            <div className='question-label'>
                                <HeadingTwo
                                    level={2}
                                    titleDescriptor={messages.coupledQuestionsTitle}
                                />
                            </div>
                            {this.props.annotation.map((annotation, index) => {
                                return <AnnotationQuestion
                                    annotation={annotation}
                                    onCloseQuestion={this.closeQuestion}
                                    isCoupled={true}
                                    firstQuestionRef={index===0?this.firstQuestionRef:null}
                                    key={`question-${index}`}
                                />;
                            })}
                            <div className='annotated-video-annotation-question-close-button'>
                                <UI.Button
                                    btnStyle='outline'
                                    icon='x'
                                    onClick={this.props.onCloseQuestion}
                                    label={messages.endQuestionButtonLabel}
                                />
                            </div>
                        </div>
                    </div></>;
        } else {
            return  <>
                    <div className='annotated-video-annotation-question-sidebar'>
                        <div className='annotated-video-annotation-question-sidebar-content'>
                        {<AnnotationQuestion
                            annotation={this.props.annotation}
                            onCloseQuestion={this.props.onCloseQuestion}
                            isCoupled={false}
                            firstQuestionRef={this.firstQuestionRef}
                        />}
                        </div>
                    </div></>;
        }

    }

    @bind private closeQuestion() {
        let totalQuestionsRemaining = this.state.totalQuestionsRemaining;
        totalQuestionsRemaining -= 1;
        if (totalQuestionsRemaining === 0) {
            window.setTimeout(() => this.props.onCloseQuestion(), 1000);
        } else {
            this.setState({totalQuestionsRemaining});
        }
    }
}

interface AnnotationQuestionProps {
    annotation: AnnotatedVideoAnnotation;
    isCoupled: boolean;
    onCloseQuestion(): void;
    firstQuestionRef: React.RefObject<HTMLDivElement>| null;
}

interface AnnotationQuestionState {
    userAnswers: { [key: string]: UserAnswer};
    showResults: boolean;
    hasErrors: boolean;
    isAnsweredCorrectly: boolean;
    showFeedback: Set<string>;
    isMultiCorrect: boolean;
}

export class AnnotationQuestion extends React.PureComponent<AnnotationQuestionProps, AnnotationQuestionState> {

    private feedbackRef: React.RefObject<HTMLLIElement>;
    private resultAlert: React.RefObject<HTMLDivElement>;

    constructor(props: AnnotationQuestionProps) {
        super(props);
        this.state = {
            userAnswers: {},
            showResults: false,
            hasErrors: false,
            isAnsweredCorrectly: false,
            isMultiCorrect: this.getIsMultiCorrectQuestion(),
            showFeedback: new Set(),
        };
        this.feedbackRef = React.createRef();
        this.resultAlert = React.createRef();
    }

    public componentDidMount() {
        this.focusFirstQuestion();
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.annotation !== prevProps.annotation)
        {
            this.setState({showResults: false}, () => this.focusFirstQuestion());
        }
    }

    private focusFirstQuestion() {
        if(this.props.firstQuestionRef && this.props.firstQuestionRef.current) {
            this.props.firstQuestionRef.current.focus();
        }
    }


    public render () {
        const showResults = this.state.showResults;
        this.setState({
            isMultiCorrect: this.getIsMultiCorrectQuestion()
        });
        return (
            <>
            {this.props.isCoupled ? null : <div className='annotated-video-annotation-question-sidebar-title'>
                <div className='font-m-lt' >
                    <RichText
                        innerHtml={this.props.annotation.title}
                            sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                    />
                </div>

            </div>}
            <div className='annotated-video-annotation-question' tabIndex={-1}  ref={this.props.firstQuestionRef}>
                {!this.props.isCoupled ? null : <div className='inner-question-label'>{messages.coupledQuestionsInnerQuestionLabel.defaultMessage}</div>}
                <div className='annotated-video-annotation-question-title'>
                    <RichText
                        innerHtml={this.props.annotation.question!.question}
                            sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                    />
                </div>
                {this.props.annotation.imageUrl && <div><img
                    className='annotated-video-annotation-image annotation-question-image'
                    src={this.props.annotation.imageUrl}
                    alt={this.props.annotation.imageAlt}
                /></div>}
                <ol className='annotated-video-annotation-question-answers' role={this.state.isMultiCorrect ? 'group' : 'radiogroup'}>
                {this.props.annotation.question!.answers.map((answer, index) => {
                    const userAnswer = this.getUserAnswer(answer.id);
                    return (
                    <React.Fragment key={`${this.props.annotation.id}-ans-frag-${index}`}>
                    {/* eslint-disable jsx-a11y/click-events-have-key-events */
                    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                    <li
                        onClick={() => this.onClickAnswer(
                            answer.id,
                            !userAnswer.correct,
                            userAnswer.value || '')}
                        className={`answer ${userAnswer.correct ? 'answer-active ': ' '} ${this.state.isMultiCorrect ? 'multicorrect ': ' '}
                        ${this.state.showFeedback.has(answer.id) ? answer.correct ? 'correct' : 'incorrect' : ''}`}>
                        <button
                            className='unstyled'
                            aria-label={answer.text}
                            role={this.state.isMultiCorrect ? 'checkbox' : 'radio'}
                            aria-checked={userAnswer.correct ? 'true': 'false'}
                            onClick={() => this.onClickAnswer(
                                answer.id,
                                !userAnswer.correct,
                                userAnswer.value || '')}
                            >
                                    <PlainText innerHtml={answer.text} sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple} />
                        </button>
                    </li>
                    {this.state.showFeedback.has(answer.id) && answer.feedback ? (
                        <li
                            role='status'
                            ref={this.feedbackRef}
                            tabIndex={-1}
                            className={classNames(
                                'feedback',
                                answer.correct ? 'feedback-correct' : 'feedback-incorrect',
                            )}
                        >
                                    <PlainText innerHtml={answer.feedback} sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple} />
                        </li>)
                        : null
                    }
                    </React.Fragment>
                    );
                })}
                </ol>
                {showResults && this.renderResults()}
                <div className='annotated-video-annotation-question-submit-button'>
                    <UI.Button
                        btnStyle='primary'
                        onClick={this.onSubmit}
                        label={messages.submitQuestionButtonLabel}
                        disabled={this.state.isAnsweredCorrectly}
                    />
                </div>
            </div>
            {this.props.isCoupled ? null : <div className='annotated-video-annotation-question-close-button'>
                <UI.Button
                    btnStyle='outline'
                    icon='x'
                    onClick={this.props.onCloseQuestion}
                    label={messages.endQuestionButtonLabel}
                />
            </div>}
        </>
    );
    }

    @bind private getUserAnswer(id: string): UserAnswer {
        const userAnswer = this.state.userAnswers[id];
        if (userAnswer) { return userAnswer; }
        return {correct: false, value: ''};
    }

    @bind private onClickAnswer(id: string, correct: boolean, value: string): void {
        let userAnswers = {...this.state.userAnswers};
        if(this.state.isMultiCorrect){
            userAnswers[id] = {correct, value};
        } else {
            userAnswers = {};
            userAnswers[id] = {correct, value};
        }
        this.setState({userAnswers});
    }

    @bind private renderResults() {
        if (this.state.hasErrors) {
            if(this.state.isMultiCorrect) {
                return <><div className='answer-results-incorrect'>
                    <WrappedMessage message={messages.multiCorrectQuestionIncorrectResults}/>
                    </div></>;
            } else {
                return <div className='answer-results-incorrect' role='status' tabIndex={-1} ref={this.resultAlert}>
                <WrappedMessage message={messages.questionInCorrectResults}/>
            </div>;
            }
        }
        return <div className='answer-results-correct' role='status' tabIndex={-1} ref={this.resultAlert}>
            <WrappedMessage message={messages.questionCorrectResults}/>
        </div>;
    }

    @bind private hasErrors() {
        return this.props.annotation.question!.answers.some((answer) => {
            const userAnswer = this.getUserAnswer(answer.id);
            return userAnswer.correct !== answer.correct;
        });
    }

    @bind private onSubmit() {
        this.checkFeedback();
        const hasErrors = this.hasErrors();
        if (!hasErrors) {
            this.setState({isAnsweredCorrectly : true});
        }
        this.setState({hasErrors, showResults: true});
    }

    @bind private checkFeedback() {
        const showFeedback = new Set() as Set<string>;
        this.props.annotation.question!.answers.forEach((answer) => {
            const userAnswer = this.getUserAnswer(answer.id);
            if (userAnswer.correct) {
                showFeedback.add(answer.id);
            }
        });
        this.setState({showFeedback}, () => {
            if(this.feedbackRef && this.feedbackRef.current) {
                this.feedbackRef.current.focus();
            } else if(this.resultAlert && this.resultAlert.current) {
                this.resultAlert.current.focus();
            }
        });
    }

    private getIsMultiCorrectQuestion() {
        if (this.props.annotation.question?.answers) {
            return !(this.props.annotation.question?.answers.filter((x) => x.correct === true).length === 1);
        }
        return false;
    }
}

export const AnnotationProblemSidebarSkeleton = () => <div className='annotated-video-question-sidebar'>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
</div>;
