// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnnotatedVideoDataState,
    AnnotatedVideoDataStateFromJSON,
    AnnotatedVideoDataStateToJSON,
    SaveUserStateVideo,
    SaveUserStateVideoFromJSON,
    SaveUserStateVideoToJSON,
    VideoDataState,
    VideoDataStateFromJSON,
    VideoDataStateToJSON,
    VideoTranscript,
    VideoTranscriptFromJSON,
    VideoTranscriptToJSON,
    VideoTranscripts,
    VideoTranscriptsFromJSON,
    VideoTranscriptsToJSON,
} from '../models';

export interface AnnotatedVideoDataAndStateRequest {
    id: string;
}

export interface SaveUserVideoStateRequest {
    id: string;
    data: SaveUserStateVideo;
}

export interface StorageRequest {
    filename: string;
    id: string;
}

export interface StudentViewDataRequest {
    id: string;
}

export interface StudentViewUserStateRequest {
    id: string;
}

export interface SubmitQuestionAnswerRequest {
    id: string;
    data: any;
}

export interface TranscriptTranscriptDownloadSrtRequest {
    id: string;
}

export interface VideoDataAndStateRequest {
    id: string;
}

export interface VideoTranscriptJsonRequest {
    id: string;
    language: string;
}

export interface VideoTranscriptsSrtRequest {
    id: string;
}

export interface VideoVideoTranscriptRequest {
    id: string;
    language: string;
}

/**
 * no description
 */
export class XblocksApi extends runtime.BaseAPI {

    /**
     * API endpoint for rendering video player for annotated videos.
     */
    async annotatedVideoDataAndStateRaw(requestParameters: AnnotatedVideoDataAndStateRequest): Promise<runtime.ApiResponse<AnnotatedVideoDataState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling annotatedVideoDataAndState.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/annotated_video_student_view_data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnotatedVideoDataStateFromJSON(jsonValue));
    }

    /**
     * API endpoint for rendering video player for annotated videos.
     */
    async annotatedVideoDataAndState(requestParameters: AnnotatedVideoDataAndStateRequest): Promise<AnnotatedVideoDataState> {
        const response = await this.annotatedVideoDataAndStateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Form Data:     - saved_video_position [int] 70     - speed [float] 1.5
     * Store users video state: progress, speed
     */
    async saveUserVideoStateRaw(requestParameters: SaveUserVideoStateRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling saveUserVideoState.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling saveUserVideoState.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/save_user_video_state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveUserStateVideoToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Form Data:     - saved_video_position [int] 70     - speed [float] 1.5
     * Store users video state: progress, speed
     */
    async saveUserVideoState(requestParameters: SaveUserVideoStateRequest): Promise<any> {
        const response = await this.saveUserVideoStateRaw(requestParameters);
        return await response.value();
    }

    /**
     * URLs are stored in the OLX as /api/v1/xblocks/<block_id>/storage/<filename>-<hash><.ext>
     * Returns a redirect to the Django Storage URL for the given file (e.g. image or PDF file) used by an XBlock.
     */
    async storageRaw(requestParameters: StorageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling storage.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling storage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/storage/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * URLs are stored in the OLX as /api/v1/xblocks/<block_id>/storage/<filename>-<hash><.ext>
     * Returns a redirect to the Django Storage URL for the given file (e.g. image or PDF file) used by an XBlock.
     */
    async storage(requestParameters: StorageRequest): Promise<void> {
        await this.storageRaw(requestParameters);
    }

    /**
     * API method to get an XBlock student_view_data.
     */
    async studentViewDataRaw(requestParameters: StudentViewDataRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling studentViewData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/student_view_data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * API method to get an XBlock student_view_data.
     */
    async studentViewData(requestParameters: StudentViewDataRequest): Promise<any> {
        const response = await this.studentViewDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * API method to get an XBlock student_view_user_state.
     */
    async studentViewUserStateRaw(requestParameters: StudentViewUserStateRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling studentViewUserState.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/student_view_user_state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * API method to get an XBlock student_view_user_state.
     */
    async studentViewUserState(requestParameters: StudentViewUserStateRequest): Promise<any> {
        const response = await this.studentViewUserStateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Submit answer to question (lx_question block).
     */
    async submitQuestionAnswerRaw(requestParameters: SubmitQuestionAnswerRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitQuestionAnswer.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling submitQuestionAnswer.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/submit_question_answer`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Submit answer to question (lx_question block).
     */
    async submitQuestionAnswer(requestParameters: SubmitQuestionAnswerRequest): Promise<any> {
        const response = await this.submitQuestionAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Download the item's transcript for the given language.
     */
    async transcriptTranscriptDownloadSrtRaw(requestParameters: TranscriptTranscriptDownloadSrtRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transcriptTranscriptDownloadSrt.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/transcript/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download the item's transcript for the given language.
     */
    async transcriptTranscriptDownloadSrt(requestParameters: TranscriptTranscriptDownloadSrtRequest): Promise<void> {
        await this.transcriptTranscriptDownloadSrtRaw(requestParameters);
    }

    /**
     * API endpoint for rendering video player.
     */
    async videoDataAndStateRaw(requestParameters: VideoDataAndStateRequest): Promise<runtime.ApiResponse<VideoDataState>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling videoDataAndState.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/video_student_view_data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoDataStateFromJSON(jsonValue));
    }

    /**
     * API endpoint for rendering video player.
     */
    async videoDataAndState(requestParameters: VideoDataAndStateRequest): Promise<VideoDataState> {
        const response = await this.videoDataAndStateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Render Transcript widget on the frontend.
     * Video transcript json.
     */
    async videoTranscriptJsonRaw(requestParameters: VideoTranscriptJsonRequest): Promise<runtime.ApiResponse<Array<VideoTranscript>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling videoTranscriptJson.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling videoTranscriptJson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/video_transcript/{language}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VideoTranscriptFromJSON));
    }

    /**
     * Render Transcript widget on the frontend.
     * Video transcript json.
     */
    async videoTranscriptJson(requestParameters: VideoTranscriptJsonRequest): Promise<Array<VideoTranscript>> {
        const response = await this.videoTranscriptJsonRaw(requestParameters);
        return await response.value();
    }

    /**
     * All video transcripts in SRT format.
     */
    async videoTranscriptsSrtRaw(requestParameters: VideoTranscriptsSrtRequest): Promise<runtime.ApiResponse<Array<VideoTranscripts>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling videoTranscriptsSrt.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/video_transcripts/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VideoTranscriptsFromJSON));
    }

    /**
     * All video transcripts in SRT format.
     */
    async videoTranscriptsSrt(requestParameters: VideoTranscriptsSrtRequest): Promise<Array<VideoTranscripts>> {
        const response = await this.videoTranscriptsSrtRaw(requestParameters);
        return await response.value();
    }

    /**
     * Video.js support only VTT transcripts:
     * Parser transcript from SRT to VTT.
     */
    async videoVideoTranscriptRaw(requestParameters: VideoVideoTranscriptRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling videoVideoTranscript.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling videoVideoTranscript.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/xblocks/{id}/video/transcript/{language}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Video.js support only VTT transcripts:
     * Parser transcript from SRT to VTT.
     */
    async videoVideoTranscript(requestParameters: VideoVideoTranscriptRequest): Promise<void> {
        await this.videoVideoTranscriptRaw(requestParameters);
    }

}
