import { defineMessages } from 'react-intl';

const messages = defineMessages({
    facebookIcon: {
        id:'facebookIcon',
        defaultMessage: 'Facebook page',
        description: 'Description text for the Facebook icon link',
    },
    instagramIcon: {
        id:'instagramIcon',
        defaultMessage: 'Instagram page',
        description: 'Description text for the Instagram icon link',
    },
    linkedinIcon: {
        id:'linkedinIcon',
        defaultMessage: 'LinkedIn page',
        description: 'Description text for the LinkedIn icon link',
    },
    twitterIcon: {
        id:'twitterIcon',
        defaultMessage: 'Twitter page',
        description: 'Description text for the Twitter icon link',
    },
    academiaIcon: {
        id:'academiaIcon',
        defaultMessage: 'Academia page',
        description: 'Description text for the Academia icon link',
    },
    pinterestIcon: {
        id:'pinterestIcon',
        defaultMessage: 'Pinterest page',
        description: 'Description text for the Pinterest icon link',
    },
    youtubeIcon: {
        id:'youtubeIcon',
        defaultMessage: 'Youtube page',
        description: 'Description text for the Youtube icon link',
    },
    websiteIcon: {
        id:'websiteIcon',
        defaultMessage: 'External website',
        description: 'Description text for the external website icon link',
    },
});

export default messages;
