import type { CookieConsentConfig } from 'vanilla-cookieconsent';
import { UsersApi } from 'global/api';
import { intl } from 'i18n';
import messages from '../../displayMessages';

export const CONSENT_COOKIE_NAME = 'cookie_consent';

interface ConsentCategories {
    necessary: boolean;
    analytics: boolean;
    marketing: boolean;
    performance: boolean;
    [key: string]: boolean;
}

export const onCookieConsent = ({ cookie }: { cookie: any }) => {
    const consentDefaults: ConsentCategories = {
        necessary: true,
        analytics: false,
        marketing: false,
        performance: false,
    };

    const data = cookie.categories.reduce((finalConsent: ConsentCategories, category: string) => {
        finalConsent[category] = true;
        handleConsentGrant(category);
        return finalConsent;
    }, consentDefaults);
    Object.entries(data).forEach(([key, enabled]) => {
        if (!enabled) revokeConsent(key);
    });

    UsersApi.acceptCookieAgreement({ data }).then(() => 'saved');
};

const updateGtagConsent = (consentState: 'granted' | 'denied') => {
    if (typeof window.gtag === 'function') {
        window.gtag('set', 'url_passthrough', consentState === 'denied');
        window.gtag('consent', 'update', {
            analytics_storage: consentState,
        });
    }
};

const updateVWOConsent = (consent: 1 | 2 | 3) => {
    window.VWO = window.VWO || [];
    // tslint:disable-next-line:only-arrow-functions
    window.VWO.init = window.VWO.init || function (state: any) {
        window.VWO.consentState = state;
    };
    window.VWO.init(consent);
};

const updateDDConsent = (consent: 'granted' | 'not-granted') => {
    if (typeof window.DD_RUM?.setTrackingConsent === 'function') {
        window.DD_RUM.setTrackingConsent(consent);
    }
    else {
        // tslint:disable-next-line:no-console
        console.log('DD_RUM not found');
    }
};

const updateFBConsent = (consent: 'grant' | 'revoke') => {
    if (typeof window.fbq === 'function') {
        window.fbq('consent', consent);
    }
};

export const handleConsentGrant = (category: string) => {
    switch (category) {
        case 'analytics':
            updateGtagConsent('granted');
            updateVWOConsent(1);
            updateDDConsent('granted');
            break;
        case 'marketing':
            updateFBConsent('grant');
            break;
        default:
            // tslint:disable-next-line:no-console
            console.log(`Unknown category: ${category}`);
            break;
    }
};

export const revokeConsent = (category: string) => {
    switch (category) {
        case 'analytics':
            updateGtagConsent('denied');
            updateVWOConsent(3);
            updateDDConsent('not-granted');
            break;
        case 'marketing':
            updateFBConsent('revoke');
            break;
        default:
            // tslint:disable-next-line:no-console
            console.log(`Unknown category: ${category}`);
            break;
    }
};

export const cookieConsentConfig = (): CookieConsentConfig => {
    const { locale: lang, formatMessage } = intl;
    const showMarketingCategory = (window as any).showMarketingCategory;

    const categories = {
        necessary: { enabled: true, readOnly: true },
        analytics: { enabled: false },
        ...(showMarketingCategory && { marketing: { enabled: false } }),
    };
    const footerLink = `<a href="https://about.labxchange.org/privacy-notice" target="_blank">${formatMessage(messages.consentModalFooter)}</a>`;
    const cookiePolicyLink = `<a href="https://about.labxchange.org/cookies" target="_blank">${formatMessage(messages.cookiePolicyLinkText)}</a>`;

    const sections = [
        {
            title: formatMessage(messages.preferencesModalSectionAboutCookiesTitle),
            description: formatMessage(messages.preferencesModalSectionAboutCookiesDescription),
        },
        {
            title: formatMessage(messages.preferencesModalSectionStrictlyNecessaryTitle),
            description: formatMessage(messages.preferencesModalSectionStrictlyNecessaryDescription),
            linkedCategory: 'necessary',
        },
        {
            title: formatMessage(messages.preferencesModalSectionAnalyticsTitle),
            description: formatMessage(messages.preferencesModalSectionAnalyticsDescription),
            linkedCategory: 'analytics',
        },
        ...(showMarketingCategory
            ? [
                {
                    title: formatMessage(messages.preferencesModalSectionMarketingTitle),
                    description: formatMessage(messages.preferencesModalSectionMarketingDescription),
                    linkedCategory: 'marketing',
                },
            ]
            : []),
        {
            title: formatMessage(messages.preferencesModalSectionMoreInfoTitle),
            description: formatMessage(messages.preferencesModalSectionMoreInfoDescription, {
                privacyPolicyLink: footerLink,
                cookiePolicyLink
            }),
        },
    ];

    return {
        cookie: {
            name: CONSENT_COOKIE_NAME,
        },
        guiOptions: {
            consentModal: {
                layout: 'box inline',
                position: 'bottom right',
                equalWeightButtons: false,
                flipButtons: false,
            },
            preferencesModal: {
                layout: 'box',
                equalWeightButtons: true,
                flipButtons: false,
            },
        },
        onFirstConsent: onCookieConsent,
        onChange: onCookieConsent,
        categories,
        language: {
            default: lang,
            translations: {
                [lang]: {
                    consentModal: {
                        title: formatMessage(messages.consentModalTitle),
                        description: formatMessage(messages.consentModalDescription),
                        acceptAllBtn: formatMessage(messages.consentModalAcceptAllBtn),
                        acceptNecessaryBtn: formatMessage(messages.consentModalAcceptNecessaryBtn),
                        showPreferencesBtn: formatMessage(messages.consentModalShowPreferencesBtn),
                        footer: footerLink,
                    },
                    preferencesModal: {
                        title: formatMessage(messages.preferencesModalTitle),
                        acceptAllBtn: formatMessage(messages.preferencesModalAcceptAllBtn),
                        acceptNecessaryBtn: formatMessage(messages.preferencesModalAcceptNecessaryBtn),
                        savePreferencesBtn: formatMessage(messages.preferencesModalSavePreferencesBtn),
                        closeIconLabel: formatMessage(messages.preferencesModalCloseIconLabel),
                        sections,
                    },
                },
            },
        },
    };
};
