import { defineMessages } from 'react-intl';

const messages = defineMessages({
    paginationAriaLabel: {
        id:'paginationAriaLabel',
        defaultMessage: 'Pagination',
        description: 'Screen-reader text to label the nav region as pagination',
    },
    paginationCurrentPageLabel: {
        id:'paginationCurrentPageLabel',
        defaultMessage: 'Current Page',
        description: 'Label for current page.',
    },
    paginationGoLabel: {
        id:'paginationGoLabel',
        defaultMessage: 'Go',
        description: 'Label for jumping to page number x',
    },
    paginationJumptoLabel: {
        id:'paginationJumptoLabel',
        defaultMessage: 'Jump to',
        description: 'Label for inputing a page number',
    },
    paginationNextLabel: {
        id:'paginationNextLabel',
        defaultMessage: 'Next',
        description: 'Label for next page button.',
    },
    paginationPageLabel: {
        id:'paginationPageLabel',
        defaultMessage: 'Page',
        description: 'Label for page.',
    },
    paginationPageOfCountLabel: {
        id:'paginationPageOfCountLabel',
        defaultMessage: 'of',
        description: '',
    },
    paginationPreviousLabel: {
        id:'paginationPreviousLabel',
        defaultMessage: 'Previous',
        description: 'Label for previous page button',
    },
    paginationSizeOptionsLabel: {
        id:'paginationSizeOptionsLabel',
        defaultMessage: 'Number of results to show per page',
        description: 'Label for count per page option.',
    },
    paginationSizeOptionsText: {
        id:'paginationSizeOptionsText',
        defaultMessage: 'Results per page: {count_per_page}',
        description: 'Text displaying currently selected value for count per page.',
    },
    paginationSizeOptionsTextWithoutSuffix: {
        id:'paginationSizeOptionsTextWithoutSuffix',
        defaultMessage: 'Show {count_per_page}',
        description: 'Text displaying currently selected value for count per page without "per_page" suffix.',
    },
});

export default messages;
