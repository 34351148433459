import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import * as React from 'react';

import { Modal } from 'elements';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import type {
    ItemType,
} from '../../models';
import { CreateItemModalContent, CreateItemStep } from './CreateItemModalContent';

interface CreateItemModalProps {
    onRequestClose: () => void;
    onCloseAndRedirect: (location: LocationDescriptor) => void;
}

interface CreateItemModalState {
    step: CreateItemStep;
    selectedItemType?: ItemType;
}

export class CreateItemModal extends React.PureComponent<CreateItemModalProps, CreateItemModalState> {

    constructor(props: CreateItemModalProps) {
        super(props);
        this.state = {
            step: CreateItemStep.SelectType,
        };
    }

    public render() {
        return (
            <Modal
                size='large'
                showTopBar={true}
                showBackButton={this.state.step !== CreateItemStep.SelectType}
                title={
                    <WrappedMessage message={messages.createItemButtonLabel} />
                }
                ariaLabel={messages.createItemButtonLabel}
                contentScrolls={true}
                onRequestBack={this.onRequestBack}
                onRequestClose={this.props.onRequestClose}
                onAfterOpen={this.autoFocus}
                content={<CreateItemModalContent
                    onCloseAndRedirect={this.props.onCloseAndRedirect}
                    step={this.state.step}
                    onStepChange={this.onStepChange}
                    selectedItemType={this.state.selectedItemType}
                    onSelectedItemTypeChange={this.onSelectedItemTypeChange}
                />}
            />
        );
    }

    @bind private onStepChange(step: CreateItemStep) {
        this.setState({ step }, this.autoFocus);
    }

    private autoFocus() {
        const button = document.querySelector('.create-item-modal-list .create-item-button button');
        if (button !== null) {
            (button as HTMLElement).focus();
        }
    }

    @bind private onSelectedItemTypeChange(selectedItemType: ItemType) {
        this.setState({ selectedItemType });
    }

    @bind private onRequestBack() {
        this.onStepChange(CreateItemStep.SelectType);
    }
}
