// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Redirect,
    RedirectFromJSON,
    RedirectToJSON,
} from '../models';

export interface ReadRequest {
    id: string;
}

/**
 * no description
 */
export class RedirectsApi extends runtime.BaseAPI {

    /**
     * Get a single redirect by key.
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<Redirect>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/redirects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectFromJSON(jsonValue));
    }

    /**
     * Get a single redirect by key.
     */
    async read(requestParameters: ReadRequest): Promise<Redirect> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

}
