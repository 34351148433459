import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';

import { Modal } from 'elements';
import { RootState } from 'global/state';
import { WrappedMessage } from '../../../utils';
import messages from '../displayMessages';

import {
    dismissMessage, MessageType,
} from './actions';
import { Message } from './reducer';

/** Redux action creators that get bound to this component. */
interface DispatchProps {
    dismissMessage: typeof dismissMessage;
}

/** Props that come from the global redux state. */
interface ReduxStateProps {
    messages: ReadonlyArray<Message>;
}

interface Props extends ReduxStateProps, DispatchProps {
    store?: Store;
}

class GlobalMessageReporterComponent extends React.PureComponent<Props> {

    public componentDidMount() {
        (window as any).dismissMessage = this.props.dismissMessage;
    }

    public render() {
        if (this.props.messages.length === 0) {
            return null;
        }
        const message = this.props.messages[0];
        const title = (
            message.title ? message.title :
            message.type === MessageType.Error ? <WrappedMessage message={messages.uiError} /> :
            message.type === MessageType.Warning ? <WrappedMessage message={messages.uiWarning} /> :
            message.type === MessageType.Success ? <WrappedMessage message={messages.uiSuccess} /> :
            '');
        const imgClass = (message.type === MessageType.Success ? 'success-icon' : 'warning-icon');

        const confirmText = message.confirmText || messages.uiOk;
        const cancelText = message.cancelText || messages.uiCancelButton;
        return (
            <Modal
                className='global-alert-body'
                onRequestClose={this.onDismiss}
                content={
                    <div className='global-alert-body'>
                        <div className={imgClass}></div>
                        <div className='title-text'>{title}</div>
                        <div className='body-text'>{message.message}</div>
                        {message.bodyHtmlAppend ? message.bodyHtmlAppend : null}
                    </div>
                }
                footer={
                    <>
                        {message.onConfirm && // Show both a cancel button and an OK button if "onConfirm" is set.
                            <button className='btn btn-block cancel-button'
                                onClick={this.onDismiss}
                            >
                                <WrappedMessage message={cancelText} />
                            </button>
                        }
                        <button className='btn btn-block primary-button ok-button'
                            onClick={message.onConfirm ? this.onConfirm : this.onDismiss}
                        >
                            <WrappedMessage message={confirmText}/>
                        </button>
                    </>
                }
            />
        );
    }

    @bind private onDismiss() {
        if (this.props.messages.length) {
            const message = this.props.messages[0];
            if (message.onClose) {
                message.onClose();
            }
        }
        this.props.dismissMessage();
    }

    @bind private onConfirm() {
        if (this.props.messages.length) {
            const message = this.props.messages[0];
            if (message.onConfirm) {
                message.onConfirm();
            }
        }
        this.props.dismissMessage();
    }
}

/**
 * A component that lives near the top level of the hierarchy and is responsible
 * for displaying error messages, warnings, and success messages in modal popups
 * whenever needed by any other components.
 */
export const GlobalMessageReporter = connect<ReduxStateProps, DispatchProps, {/* OwnProps */}, RootState>(
    (state: RootState) => ({
        messages: state.globalMessages.messages,
    }),
    {
        dismissMessage,
    },
)(GlobalMessageReporterComponent);
