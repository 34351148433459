import * as React from 'react';
import bind from 'bind-decorator';
import { ItemMetadata, Transcripts, VideoDataState } from 'labxchange-client';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import './videojs-theme.css';

import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';
import 'videojs-youtube/dist/Youtube.min.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { WrappedMessage } from 'utils';
import messages from 'items/displayMessages';
import libraryMessages from 'library/displayMessages';
import { Transcript } from 'library/components/Transcript';
import { playerCaptions } from 'library/components/Transcript/utils';
import { Icon } from 'elements';
import { Button } from 'ui/components';
import ReactDOM from 'react-dom';

interface ModalProps extends React.PropsWithChildren {
    activate: boolean;
    onContinueWatching: () => void;
    onStartNext: () => void;
    startNextUrl: string;
}

class VideoModal extends React.PureComponent<ModalProps> {
    public render() {
        return <div className={this.props.activate ? 'video-modal' : 'video-modal-hidden'}>
            {this.props.activate && <>
                <div className='video-modal-background'>
                </div>
                <div className='video-modal-internal'>
                    <Icon name={'success'} />
                    <div className='video-modal-header'>
                        <WrappedMessage message={libraryMessages.finishedVideoHeader} />
                    </div>
                    <div className='video-modal-description'>
                        <WrappedMessage message={libraryMessages.finishedVideoDescription} />
                    </div>
                    <div className='buttons-row'>
                        <Button
                            className='continue-watching'
                            label={libraryMessages.finishedVideoContinueButton}
                            btnStyle='outline'
                            onClick={this.props.onContinueWatching}
                        />
                        {this.props.startNextUrl && <Button
                            className='start-next'
                            label={libraryMessages.finishedVideoNextButton}
                            btnStyle='primary'
                            href={this.props.startNextUrl}
                            onClick={this.props.onStartNext}
                            icon={'triangle-right'}
                            iconPosition={'left'}
                        />}
                    </div>
                </div>
            </>}
            {this.props.children}
        </div>;
    }
}

interface VideoSource {
    src: string;
    type: string;
}

export enum VideoPlayerStyle {
    Default = 'default',
    AnnotatedVideo = 'annotated-video',
}

interface VideoPlayerProps {
    style?: VideoPlayerStyle;
    itemMetadata: ItemMetadata;
    sources: VideoSource[];
    savedVideoPosition: number;
    resumeVideoFromSavedPosition?: boolean;
    playbackRate: number|null;
    playerTranscripts: Transcripts[];
    pauseHandler: (time: number) => void;
    timeUpdateHandler: (time: number) => void;
    rateChangeHandler: (rate: number) => void;
    endedHandler: () => void;
    playingHandler: () => void;
    videoPlaybackWindow?: [number, number];  // [start, stop] timestamps in seconds
    nextUrl: string;
    onPlayerReader?(player: videojs.Player): void;
    hideTranscripts?: boolean;
    showRangePaddles?: boolean;
    showQuestionPaddle?: boolean;
    onChangeRangePaddleTime?(isLeft:boolean, newTime: number): number;
    videoBlockState?: VideoDataState;
    showCustomControlsOnVimeo?: boolean;
}

// Support custom videojs-youtube variables
interface CustomVideoJsPlayerOptions extends VideoJsPlayerOptions {
    [index: string]: any;
}

interface VideoPlayerState {
    currentTime: number;
    reachedPlaybackEnd: boolean;
    leftRangePaddleFlag: boolean;
    rightRangePaddleFlag: boolean;
    questionPaddleFlag: boolean;
    leftRangePaddleTime: number;
    rightRangePaddleTime: number;
    questionPaddleTime: number;
    hasPlayedBefore: boolean;
}

interface PaddleProps {
    isLeft: boolean;
    onPaddleMouseUp(isLeft: boolean): void;
}

function Paddle (props:PaddleProps){
    React.useEffect(() => {
        const checkIfClickedOutside = () => {
          props.onPaddleMouseUp(props.isLeft);
        };
        document.addEventListener('mouseup', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mouseup', checkIfClickedOutside);
        };
    });

    return (
        <div
          className='lx-video-draggable-paddle'
        >
          <img src={`/assets/images/annotated-video/${props.isLeft ? 'left' : 'right'}-range-paddle.svg`} alt='' />
        </div>
    );
}

function QuestionPaddle (){
    return (
        <div
          className='lx-video-draggable-paddle'
        >
          <img src={`/assets/images/annotated-video/question-paddle.svg`} alt='' />
        </div>
    );
}


export class VideoPlayer extends React.Component<VideoPlayerProps, VideoPlayerState> {
    private videoNode: React.RefObject<HTMLVideoElement>;
    private player?: videojs.Player;
    private videoIframe?: HTMLIFrameElement;

    constructor(props: VideoPlayerProps) {
        super(props);
        this.state = {
            currentTime: 0,
            reachedPlaybackEnd: false,
            leftRangePaddleFlag: false,
            rightRangePaddleFlag: false,
            questionPaddleFlag: false,
            leftRangePaddleTime: 0,
            rightRangePaddleTime: 20,
            questionPaddleTime: 0,
            hasPlayedBefore: false,
        };

        this.videoNode = React.createRef();
    }

    componentDidMount() {
        if (this.videoNode.current) {
            this.player = videojs(
                this.videoNode.current,
                this.initializePlayer(),
                () => this.playerReady(),
            );
        }
    }

    componentWillUnmount() {
        if (this.player !== undefined) {
            this.player.dispose();
        }
    }

    render() {
        this.changeTranscriptsPosition();
        let className = '';
        switch (this.props.style) {
            case VideoPlayerStyle.Default: className = ''; break;
            case VideoPlayerStyle.AnnotatedVideo:
                className = 'video-player-with-annotations'; break;
            default: className = ''; break;
        }
        return (
            <>
                <VideoModal
                    activate={this.state.reachedPlaybackEnd}
                    onContinueWatching={this.modalContinueWatching}
                    onStartNext={this.modalStartNext}
                    startNextUrl={this.props.nextUrl}
                >
                    <div data-vjs-player>
                        <video // eslint-disable-line jsx-a11y/media-has-caption
                            ref={this.videoNode}
                            className={
                                `video-js vjs-theme-city vjs-lx-skin vjs-big-play-centered
                                ${className}`
                            }
                        >
                            {playerCaptions(this.props.playerTranscripts).map(item =>
                                <track
                                    key={`language-${item.languageCode}`}
                                    kind='captions'
                                    src={item.src}
                                    srcLang={item.languageCode}
                                    label={item.languageNative}
                                ></track>
                            )}
                            <WrappedMessage message={messages.browserDoesntSupportEmbeddedVideos} />
                        </video>

                    </div>
                </VideoModal>
                { !this.props.hideTranscripts && this.props.videoBlockState ?
                <Transcript
                    itemMetadata={this.props.itemMetadata}
                    videoBlockState={this.props.videoBlockState}
                    currentTime={this.state.currentTime}
                    setVideoTimePosition={this.setVideoTimePosition}
                /> : null }
            </>
        );
    }



    /**
     * Add the paddles to the video progress var in the indicated times
     */
    @bind public async showRangePaddlesInPlayer(leftTime: number, rightTime: number) {
        if(this.player !== undefined && this.props.showRangePaddles){
            const duration = this.player.duration();
            const root = document.getElementsByClassName('vjs-progress-holder')[0];
            const wrapperLeft = document.createElement('div');
            const wrapperRight = document.createElement('div');

            /// Creating the div wrappers fot the paddles
            wrapperLeft.id = 'lx-video-paddle-left';
            wrapperLeft.className = 'lx-video-draggable-paddle-wrapper left';
            wrapperRight.id = 'lx-video-paddle-right';
            wrapperRight.className = 'lx-video-draggable-paddle-wrapper right';
            wrapperLeft.style.width = `${(leftTime / duration) * 100}%`;
            wrapperRight.style.width = `${(rightTime / duration) * 100}%`;

            /// Adding wrappers. First is the right, because if the left is placed first, then the right covers the
            /// left and you cannot click the left. In the other case, the left never covers the right.
            root.appendChild(wrapperRight);
            root.appendChild(wrapperLeft);

            /// Adding the paddles
            await ReactDOM.render(<Paddle isLeft={false} onPaddleMouseUp={this.onPaddleMouseUp}/>, document.getElementsByClassName('lx-video-draggable-paddle-wrapper')[0]);
            await ReactDOM.render(<Paddle isLeft={true} onPaddleMouseUp={this.onPaddleMouseUp}/>, document.getElementsByClassName('lx-video-draggable-paddle-wrapper')[1]);

            /// Adding onmousedown events. First is the right
            const paddles = document.getElementsByClassName('lx-video-draggable-paddle');
            (paddles[0] as HTMLElement).onmousedown = this.onPaddleMouseDownRight;
            (paddles[1] as HTMLElement).onmousedown = this.onPaddleMouseDownLeft;

            this.setState({leftRangePaddleTime: leftTime});
            this.setState({rightRangePaddleTime: rightTime});
        }
    }

    @bind public async showQuestionPaddleInPlayer(time: number) {
        if(this.player !== undefined && this.props.showQuestionPaddle){
            const duration = this.player.duration();
            const root = document.getElementsByClassName('vjs-progress-holder')[0];
            const questionWrapper = document.createElement('div');

            /// Creating the div wrapper for the paddle
            questionWrapper.id = 'lx-video-question-paddle';
            questionWrapper.className = 'lx-video-draggable-paddle-wrapper question';
            questionWrapper.style.width = `${(time / duration) * 100}%`;


            root.appendChild(questionWrapper);

            /// Adding the paddles
            await ReactDOM.render(<QuestionPaddle />, document.getElementsByClassName('lx-video-draggable-paddle-wrapper')[0]);

            /// Adding onmousedown events. First is the right
            const paddles = document.getElementsByClassName('lx-video-draggable-paddle');
            (paddles[0] as HTMLElement).onmousedown = this.onPaddleMouseDownQuestion;

            this.setState({questionPaddleTime: time});
        }
    }

    /**
     * Delete the range paddles
     */
    @bind public deleteRangePaddlesInPlayer() {
        const leftWrapper = document.getElementById('lx-video-paddle-left');
        const rightWrapper = document.getElementById('lx-video-paddle-right');
        if (leftWrapper != null && leftWrapper.parentNode != null) {
            leftWrapper.parentNode.removeChild(leftWrapper);
        }
        if (rightWrapper != null && rightWrapper.parentNode != null) {
            rightWrapper.parentNode.removeChild(rightWrapper);
        }
        this.setState({
          leftRangePaddleTime: 0,
          rightRangePaddleTime: 3,
          leftRangePaddleFlag: false,
          rightRangePaddleFlag: false,
        });
    }

    @bind public deleteQuestionPaddleInPlayer() {
        const questionWrapper = document.getElementById('lx-video-question-paddle');
        if (questionWrapper != null && questionWrapper.parentNode != null) {
            questionWrapper.parentNode.removeChild(questionWrapper);
        }
        this.setState({
            questionPaddleTime: 0,
            questionPaddleFlag: false,
        });
    }

    @bind private onPaddleMouseUp(left: boolean){
        if (left) {
            this.setState({leftRangePaddleFlag: false});
        }
        else {
            this.setState({rightRangePaddleFlag: false});
        }
    }

    @bind private onPaddleMouseDownLeft(){
        this.setState({leftRangePaddleFlag: true});
    }

    @bind private onPaddleMouseDownRight(){
        this.setState({rightRangePaddleFlag: true});
    }

    @bind private onPaddleMouseDownQuestion(){
        this.setState({questionPaddleFlag: true});
    }

    @bind private modalContinueWatching() {
        if (this.player) {
            this.player.play();
        }
    }

    @bind private modalStartNext() {
        this.setState({reachedPlaybackEnd: false});
    }

    /**
     * The video controls overlap the transcripts, when the controls
     * appear it is necessary to change the position of the transcripts
     */
    private changeTranscriptsPosition() {
        const controlsCollection = document.getElementsByClassName('vjs-control-bar') as HTMLCollectionOf<HTMLElement>;
        const controls = controlsCollection[0];
        if (controls !== undefined) {
            /// Get and verify the opacity of video controls
            const style = window.getComputedStyle(controls);
            const opacity = Number(style.opacity) || 0;
            if (opacity > 0) {
                const trackDisplay = document.getElementsByClassName('vjs-text-track-cue') as HTMLCollectionOf<HTMLElement>;
                const track = trackDisplay[0];
                if (track !== undefined) {
                    /// When the video controls is visible, its size is 54px
                    /// so to keep 68px we need 14px more
                    track.style.setProperty('bottom', '14px', 'important');
                }
            }
        }
    }

    /**
     * Add event listeners on loaded player
     * https://docs.videojs.com/player#event:ratechange
     */
    private playerReady() {
        if (this.player !== undefined) {
            if (this.props.playbackRate && !this.isVimeoSource()) {
                this.player.playbackRate(this.props.playbackRate);
            }
            this.player.on('ratechange', this.rateChangeListener);
            this.player.on('pause', this.pauseListener);
            this.player.one('ended', this.endedListener);
            this.player.on('play', this.videoIsPlaying);
            this.player.one('playing', this.resumeVideoProgress);
            this.player.on('timeupdate', this.onTimeUpdate);
            if (this.props.onPlayerReader) {
                this.props.onPlayerReader(this.player);
            }
            if (this.isVimeoSource() && this.props.showCustomControlsOnVimeo) { this.updateVimeoPlayer(); }
            if (this.isHLSSource()) {
                this.player.qualityLevels();
                this.player.hlsQualitySelector({ displayCurrentQuality: true });
            }
            this.videoIframe = this.player.el().querySelector('iframe') as HTMLIFrameElement;
            if (this.videoIframe) {
                // Remove youtube video iframe from tab order till video is played.
                // Change will be reverted in videoIsPlaying handler.
                this.videoIframe.setAttribute('tabindex', '-1');
            }
        }
    }

    /**
     * Verify if there is a vimeo video on the sources
     */
    private isVimeoSource() {
      return this.props.sources.some((element) => element.type === 'video/vimeo');
    }

    /**
     * Verify if there is a HLS video on the sources
     */
    private isHLSSource() {
        return this.props.sources.some((element) => element.type === 'application/x-mpegURL');
      }

    /**
     * Updates the vimeo player with the lx controls
     */
    private async updateVimeoPlayer() {
      /// This deletes a class to allow use the lx controls (used with youtube too)
      /// The problem is that the video needs to be from a premium account to clear the vimeo controls
      const rootVideoPlayer = document.getElementsByClassName('vjs-lx-skin')[0];
      rootVideoPlayer.classList.remove('vjs-using-native-controls');
    }

    /**
     * Video.js config
     */
    private initializePlayer(): CustomVideoJsPlayerOptions {
        return {
            sources: this.props.sources,
            techOrder: ['youtube', 'vimeo', 'html5'],
            controls: true,
            preload: 'auto',
            autoplay: false,
            fluid: true,
            repeat: true,
            playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            controlBar: {
                volumePanel: {
                    inline: false,
                },
                children: [
                    'playToggle',
                    'progressControl',
                    'currentTimeDisplay',
                    'timeDivider',
                    'durationDisplay',
                    'playbackRateMenuButton',
                    'subtitlesButton',
                    'captionsButton',
                    'fullscreenToggle',
                ]
            },
            notSupportedMessage: '',
            youtube: {
                ytControls: 0,
                showinfo: 0,
                modestbranding: 1,
                cc_load_policy: 3,  // disable builtin youtube captions,
            },
            vimeo: {
              controls: false, /// Only for videos from a premium account
            },
        };
    }

    @bind private onTimeUpdate() {
        if (this.player !== undefined) {
            const currentTime = this.player.currentTime();

            // Pause the video if we just reached the window end.
            // Users can continue to play after that, and we should allow that.
            if (this.props.videoPlaybackWindow !== undefined) {
                const end = this.props.videoPlaybackWindow[1];
                if (currentTime >= end && this.state.currentTime < end) {
                    this.setState({reachedPlaybackEnd: true});
                    this.player.pause();
                }
            }

            // keep current time in sync, for use with transcripts
            this.setState({ currentTime });

            // Range paddles position change
            if (this.props.showRangePaddles !== undefined && this.props.showRangePaddles) {
                let isLeft;
                let newTime;
                if (this.state.leftRangePaddleFlag && this.state.leftRangePaddleTime !== currentTime) {
                    isLeft = true;
                    if (this.state.currentTime > this.state.rightRangePaddleTime) {
                        newTime = this.state.rightRangePaddleTime - 1;
                    }
                    else {
                        newTime = currentTime;
                    }
                }
                if (this.state.rightRangePaddleFlag && this.state.rightRangePaddleTime !== currentTime) {
                    isLeft = false;
                    if (this.state.currentTime < this.state.leftRangePaddleTime) {
                        newTime = this.state.leftRangePaddleTime + 1;
                    }
                    else {
                        newTime = currentTime;
                    }
                }
                if (isLeft !== undefined && newTime !== undefined) {
                    if (this.props.onChangeRangePaddleTime){
                        newTime = this.props.onChangeRangePaddleTime(isLeft, newTime);
                    }
                    this.changePaddlePosition(isLeft, newTime);
                }
            }

            if (this.state.questionPaddleFlag && this.props.showQuestionPaddle !== undefined && this.props.showQuestionPaddle) {
                this.changeQuestionPaddlePosition(currentTime);
                if (this.props.onChangeRangePaddleTime){
                    this.props.onChangeRangePaddleTime(true, currentTime);
                }

            }
        }
    }

    @bind public changePaddlePosition(isLeft: boolean, newTime: number){
        if (this.player !== undefined) {
            let wrapper;
            if (isLeft) {
                wrapper = document.getElementById('lx-video-paddle-left');
                this.setState({leftRangePaddleTime: newTime});
            }
            else {
                wrapper = document.getElementById('lx-video-paddle-right');
                this.setState({rightRangePaddleTime: newTime});
            }
            if(wrapper != null){
                const duration = this.player.duration();
                newTime = Math.min(newTime, duration);
                wrapper.style.width = `${(newTime / duration) * 100}%`;
            }
        }
    }

    @bind public changeQuestionPaddlePosition(newTime: number){
        if (this.player !== undefined) {
            const wrapper = document.getElementById('lx-video-question-paddle');
            this.setState({questionPaddleTime: newTime});

            if(wrapper != null){
               const duration = this.player.duration();
               newTime = Math.min(newTime, duration);
               wrapper.style.width = `${(newTime / duration) * 100}%`;
            }
        }
    }

    /**
     * Transcript widget: jump to specific timestamp.
     */
    @bind private setVideoTimePosition(time: number) {
        if (this.player !== undefined) {
            this.player.currentTime(time);
        }
        if (this.props.onChangeRangePaddleTime) {
            this.props.onChangeRangePaddleTime(true, time);
        }
    }

    @bind private videoIsPlaying() {
        this.setState({reachedPlaybackEnd: false});
        if (!this.state.hasPlayedBefore && this.player) {
            // The video is playing for the first time. To make the iframe naturally focusable,
            // remove the tabindex attribute.
            if (this.videoIframe) {
                this.videoIframe.removeAttribute('tabindex');
            }
            this.setState({hasPlayedBefore: true});
        }
        this.props.playingHandler();
    }

    /**
     * Resume video progress from saved video position
     */
    @bind private resumeVideoProgress() {
        if (this.player === undefined) {
            return;
        }

        if (this.props.resumeVideoFromSavedPosition && this.props.savedVideoPosition > 0) {
            this.player.currentTime(this.props.savedVideoPosition);
            return;
        }

        if (this.props.videoPlaybackWindow !== undefined) {
            const [start, end] = this.props.videoPlaybackWindow;
            const duration = this.player.duration();
            if (duration <= 0) {
                return;  // error; couldn't get a legit duration
            }

            // Cool hacks to inject the html elements into the videojs player
            const markerLeft = document.createElement('div');
            markerLeft.className = 'lx-video-marker left';
            markerLeft.style.width = `${(start / duration) * 100}%`;
            const markerRight = document.createElement('div');
            markerRight.className = 'lx-video-marker right';
            markerRight.style.left = `${(Math.min(end, duration) / duration) * 100}%`;
            const root = document.getElementsByClassName('vjs-progress-holder')[0];
            root.appendChild(markerLeft);
            root.appendChild(markerRight);
            this.player.currentTime(start);
        }
    }

    /**
     * Track video progress
     */
    @bind private pauseListener() {
        if (this.player !== undefined) {
            this.props.pauseHandler(this.player.currentTime());
        }
    }

    /**
     * Track video completion
     */
    @bind private endedListener() {
        if (this.player) {
            this.props.pauseHandler(this.player.currentTime());
            this.props.endedHandler();
        }
    }

    /**
     * Track changes in playback speed (playback rate)
     */
    @bind private rateChangeListener() {
        if (this.player?.hasStarted()) {
            this.props.rateChangeHandler(this.player.playbackRate());
        }
    }
}
