import { defineMessages } from 'react-intl';

const messages = defineMessages({
    placeholder: {
        id:'placeholder',
        defaultMessage: 'Search messages',
        description: 'Placeholder in the inbox user search box.',
    },
});

export default messages;
