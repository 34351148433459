// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionAuthor,
    DiscussionAuthorFromJSON,
    DiscussionAuthorToJSON,
    ThreadPermissions,
    ThreadPermissionsFromJSON,
    ThreadPermissionsToJSON,
    ThreadUserAttributes,
    ThreadUserAttributesFromJSON,
    ThreadUserAttributesToJSON,
} from './';

/**
 * 
 * @export
 * @interface Thread
 */
export interface Thread {
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    subCategory: string;
    /**
     * 
     * @type {DiscussionAuthor}
     * @memberof Thread
     */
    author: DiscussionAuthor;
    /**
     * 
     * @type {Date}
     * @memberof Thread
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Thread
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    latestReply: string;
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    pinStatus?: number;
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    postCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    likeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    viewCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Thread
     */
    participantCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly subCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    readonly categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    category?: string;
    /**
     * 
     * @type {ThreadPermissions}
     * @memberof Thread
     */
    permissions?: ThreadPermissions;
    /**
     * 
     * @type {ThreadUserAttributes}
     * @memberof Thread
     */
    userAttributes?: ThreadUserAttributes;
    /**
     * 
     * @type {Array<string>}
     * @memberof Thread
     */
    freeFormTags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Thread
     */
    itemMetadata?: string;
}

export function ThreadFromJSON(json: any): Thread {
    return {
        'id': json['id'],
        'title': json['title'],
        'subCategory': json['sub_category'],
        'author': DiscussionAuthorFromJSON(json['author']),
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
        'updated': !exists(json, 'updated') ? undefined : new Date(json['updated']),
        'latestReply': json['latest_reply'],
        'pinStatus': !exists(json, 'pin_status') ? undefined : json['pin_status'],
        'postCount': !exists(json, 'post_count') ? undefined : json['post_count'],
        'likeCount': !exists(json, 'like_count') ? undefined : json['like_count'],
        'viewCount': !exists(json, 'view_count') ? undefined : json['view_count'],
        'participantCount': !exists(json, 'participant_count') ? undefined : json['participant_count'],
        'subCategoryName': !exists(json, 'sub_category_name') ? undefined : json['sub_category_name'],
        'categoryName': !exists(json, 'category_name') ? undefined : json['category_name'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'permissions': !exists(json, 'permissions') ? undefined : ThreadPermissionsFromJSON(json['permissions']),
        'userAttributes': !exists(json, 'user_attributes') ? undefined : ThreadUserAttributesFromJSON(json['user_attributes']),
        'freeFormTags': !exists(json, 'free_form_tags') ? undefined : json['free_form_tags'],
        'itemMetadata': !exists(json, 'item_metadata') ? undefined : json['item_metadata'],
    };
}

export function ThreadToJSON(value?: Thread): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'sub_category': value.subCategory,
        'author': DiscussionAuthorToJSON(value.author),
        'created': value.created === undefined ? undefined : value.created.toISOString(),
        'updated': value.updated === undefined ? undefined : value.updated.toISOString(),
        'latest_reply': value.latestReply,
        'pin_status': value.pinStatus,
        'post_count': value.postCount,
        'like_count': value.likeCount,
        'view_count': value.viewCount,
        'participant_count': value.participantCount,
        'category': value.category,
        'permissions': ThreadPermissionsToJSON(value.permissions),
        'user_attributes': ThreadUserAttributesToJSON(value.userAttributes),
        'free_form_tags': value.freeFormTags,
        'item_metadata': value.itemMetadata,
    };
}


