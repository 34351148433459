import { Transcripts } from 'labxchange-client';
import { LxConfig } from 'global/constants';
import { getTranscriptLanguage } from 'ui/components/LanguageSelect/TranscriptLanguageSelector';


/**
 * Helper function to fetch the available transcript language for the video.
 * Returns an array with the language code and language name for all the transcripts provided.
 *
 * @param playerTranscripts: Array of Transcripts.
 */

export const availableTranscriptLanguages = (playerTranscripts: Transcripts[]): {code: string, languageNative: string}[] => {
    const captions = playerCaptions(playerTranscripts);
    return captions.map(caption => ({
        code: caption.languageCode,
        languageNative: caption.languageNative,
    }));
};


/**
 * Helper function to fetch the closed caption data for the video player.
 * It prepares the transcripts URL, language code and language name to be displayed on the player.
 *
 * @param playerTranscripts: Array of Transcripts that are to be shown on the player.
 */
export const playerCaptions = (playerTranscripts: Transcripts[]): {kind: string, src: string, languageCode: string, languageNative: string}[] => {
    return playerTranscripts.map(item => ({
        kind: 'captions',
        src: getTranscriptURL(item.transcript),
        languageCode: item.languageCode,
        languageNative: getTranscriptLanguage(item.languageCode),
    }));
};

export const  getTranscriptURL = (transcriptURI: string): string => {
    return `${LxConfig.BackendBaseUrl}${transcriptURI}`;
};

/**
 * Helper function to fetch the language code for the video if the transcript is present;
 * If the transcript for the default language of the video is not present, it fetches the first transcript
 * and sends its language code.
 * If there are no transcripts, function returns undefined
 *
 * @param transcripts: Array of Transcripts that are to be shown on the player.
 * @param assetLanguage: Language of the video as specified by the user
 */

export const getAvailableTranscript = (transcripts: Transcripts[] | undefined, assetLanguage: string) => {
    // Try to load default language transcript
    const assetLanguageTranscript = transcripts?.filter(item => item.languageCode === assetLanguage);
    if (assetLanguageTranscript && assetLanguageTranscript.length > 0) {
        return assetLanguageTranscript[0].languageCode;
    }

    // Load first available transcript
    if (transcripts && transcripts.length > 0) {
        return transcripts[0].languageCode;
    }
    return undefined;
};

/**
 * Converts a transcript from VTT format to SRT format
 *
 * @param vttTranscript
 */
export function VTTtoSRT(vttTranscript: string) {
    const lines = vttTranscript.split('\n');
    let result = '';
    for (let line of lines) {
        line = line.trim();
        if(line.match(/(\d{2}:\d{2})\.(\d{3}\s+)\-\-\>(\s+\d{2}:\d{2})\.(\d{3}\s*)/g) ||
            line.match(/(\d{2}:\d{2}:\d{2})\.(\d{3}\s+)\-\-\>(\s+\d{2}:\d{2}:\d{2})\.(\d{3}\s*)/g)) {
            const tokens = line.split('-->');
            const srtLine = tokens.map((item) => {
                item = item.replace('.',',');
                if(item.split(':').length < 3) {
                    item = '00:'+item.trim();
                }
                return item;
            }).join(' --> ');
            result += srtLine + '\n';
        }
        else if(line.match(/(WEBVTT\s*(FILE)?.*)/g)) {
            result += '';
        }
        else {
            result += line + '\n';
        }
    }
    return result;
}
