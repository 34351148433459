import bind from 'bind-decorator';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { HtmlTextBox } from 'ui/components';
import * as actionTypes from '../store/actions/action-types';
import { intl } from 'i18n';
import { RootState } from 'global/state';
import SingleSelectAnswers from '../components/UnitEditorWidget/SingleSelectAnswers';
import SingleAdvancedSettings from '../components/AdvancedSettings/SingleAdvancedSettings';
import { SingleSelectTypes } from '../models';

const messages = defineMessages({
    title: {
        id: 'SingleSelectContainer.title',
        defaultMessage: 'Question*'
    },
    description: {
        id: 'SingleSelectContainer.description',
        defaultMessage: 'Create a question that only has one answer.'
    }
});

export class SingleSelectContainer extends React.Component<any, any> {

    render() {
        const answersList = this.prepareAnswers();
        return (
            <div className='lxc-unit-editor-wrapper'>
                <fieldset className='lxc-answers-wrapper'>
                    <legend className='lxc-answers-title'>
                        {intl.formatMessage(messages.title)}
                    </legend>
                    <div className='lxc-answers-description'>
                        {intl.formatMessage(messages.description)}
                    </div>
                    <HtmlTextBox
                        label='Question Editor'
                        hideLabel={true}
                        defaultValue={this.props.editorContent}
                        onChange={this.onEditorContentChange}
                        editorStyle={HtmlTextBox.EditorStyle.Default}
                        extraClassName='question-body-editor'
                    />
                </fieldset>
                <SingleSelectAnswers
                    answersList={answersList}
                    singleSelectChangeAnswer={this.props.singleSelectChangeAnswer}
                    singleSelectRemoveAnswer={this.props.singleSelectRemoveAnswer}
                    singleSelectAddAnswer={this.props.singleSelectAddAnswer}
                />
                <SingleAdvancedSettings

                    groupFeedbackContent={this.props.groupFeedbackContent}

                    hints={this.props.hints}

                    generalFeedbackChange={this.props.generalFeedbackChange}

                    groupFeedbackchange={this.props.groupFeedbackchange}

                    hintAdd={this.props.hintAdd}
                    hintRemove={this.props.hintRemove}
                    hintChange={this.props.hintChange}

                    answerTypeSelectedOption={this.props.answerTypeSelectedOption}
                    answerTypeOptions={this.props.answerTypeOptions}
                    answerTypeChange={this.props.answerTypeChange}

                    scorringSelectedPointOption={this.props.scorringSelectedPointOption}
                    scorringselectedAttemptsOption={this.props.scorringselectedAttemptsOption}
                    scorringattemptsOptions={this.props.scorringattemptsOptions}
                    scorringPointsOptions={this.props.scorringPointsOptions}
                    scorringAttemptsChange={this.props.scorringAttemptsChange}
                    scorringPointsChange={this.props.scorringPointsChange}
                />
            </div>
        )
    }

    @bind private onEditorContentChange(content: string) {
        this.props.singleEditorContentChange(content);
    }

    @bind private prepareAnswers() {
        const answersList = this.props.answersList;
        if (!answersList.some((element: any) => element.id == 0)) {
            answersList.push({
                id: 0,
                correct: true,
                title: "",
                feedback: "",
            });
        }
        if (!answersList.some((element: any) => element.id == 1)) {
            answersList.push({
                id: 1,
                correct: false,
                title: "",
                feedback: "",
            });
        }
        return answersList;
    }
};

const mapStateToProps = (store: RootState) => {
    return {
        // editor content
        editorContent: store.assessmentEditorState.singleSelectEditor.content,
        // single answers
        answersList: store.assessmentEditorState.singleSelectAnswers.singleSelectAnswersList,
        // answer type
        answerTypeSelectedOption: store.assessmentEditorState.singleSelectAnswers.selectedType,
        answerTypeOptions: SingleSelectTypes,
        // general feedback
        generalFeedbackContent: store.assessmentEditorState.multiSelectAnswers.generalFeedbackList,
        // hints
        hints: store.assessmentEditorState.hintSettings.hints,
        // scorring
        scorringSelectedPointOption: store.assessmentEditorState.scorringSettings.selectedPointOption,
        scorringselectedAttemptsOption: store.assessmentEditorState.scorringSettings.selectedAttemptsOption,
        scorringattemptsOptions: store.assessmentEditorState.scorringSettings.attemptsOptions,
        scorringPointsOptions: store.assessmentEditorState.scorringSettings.pointsOptions
    }
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        // editor
        singleEditorContentChange: (content: any) => {
            return dispatch({ type: actionTypes.SINGLE_EDITOR_CONTENT_CHANGE, content: content });
        },
        // singleSelect answers
        singleSelectAddAnswer: (event: any) => {
            return dispatch({ type: actionTypes.SINGLE_SELECT_ANSWERS_ADD_NEW });
        },
        singleSelectRemoveAnswer: (id: any) => {
            return dispatch({ type: actionTypes.SINGLE_SELECT_ANSWERS_REMOVE, id: id });
        },
        singleSelectChangeAnswer: (event: any) => {
            return dispatch({ type: actionTypes.SINGLE_SELECT_ANSWERS_CHANGED, ...event });
        },
        // Hints
        hintAdd: (event: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_ADD });
        },
        hintRemove: (id: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_REMOVE, id: id });
        },
        hintChange: (data: any) => {
            return dispatch({ type: actionTypes.ADVANCED_SETTING_HINT_CHANGED, ...data });
        },
        // answerType setting
        answerTypeChange: (value: any) => {
            return dispatch({ type: actionTypes.ANSWER_TYPE_SETTING_CHANGED, selectedType: value })
        },
        // scorring settings
        scorringAttemptsChange: (value: any) => {
            return dispatch({ type: actionTypes.SCORRING_TEMPTS_CHANGED, ...value })
        },
        scorringPointsChange: (value: any) => {
            return dispatch({ type: actionTypes.SCORRING_POINTS_CHANGED, ...value })
        }
    }
};

export default connect<any, any, RootState>(mapStateToProps, mapDispatchToProps)(SingleSelectContainer);
