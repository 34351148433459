import { defineMessages } from 'react-intl';

const messages = defineMessages({
    composeLabel: {
        id:'composeLabel',
        defaultMessage: 'Compose thread',
        description: 'Discussion compose input label.',
    },
    composePost: {
        id:'composePost',
        defaultMessage: 'Post',
        description: 'Discussion compose post button text.',
    },
    characters: {
        id:'characters',
        defaultMessage: '{current}/{max} characters',
        description: 'message showing character count for input',
    },
    threadTagsPlaceholder: {
        id:'threadTagsPlaceholder',
        defaultMessage: 'Enter tags',
        description: 'Helper text for the tag editor box.',
    },
    threadTagsAdd: {
        id:'threadTagsAdd',
        defaultMessage: 'Add tags to help other users find your content.',
        description: 'Label for the thread tags area select box.',
    },
    maxTagsError: {
        id:'maxTagsError',
        defaultMessage: 'A thread can only contain a maximum of {max} tags',
        description: 'Error message when adding excessive tags to a thread',
    },
});

export default messages;
