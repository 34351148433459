import * as React from 'react';

import * as LoginActions from 'auth/actions';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { ROUTES } from 'global/constants';

interface ActionProps {
    loginError: typeof LoginActions.loginFailure;
}

interface Props extends ActionProps, RouteComponentProps<void> {

}

@connect(null, {
        loginError: LoginActions.loginFailure,
    },
)
export class LoginRedirect extends React.PureComponent<Props> {
    public componentDidMount() {
        // By the time we get here, getInitialLoginState() will have already
        // checked if the hash contains an auth token from the LMS, and saved
        // it into localStorage. If it's not there, then a login error ocurred.
        const token = localStorage.getItem('auth_token');
        if (token) {
            // No action needed... main.tsx will verify the token via checkLoginStatus()
        } else {
            this.props.loginError('Login Error');
        }
    }

    public render() {
        return <Redirect to={ROUTES.General.ONBOARDING}/>;
    }
}
