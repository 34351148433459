// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClassroomEducator,
    ClassroomEducatorFromJSON,
    ClassroomEducatorToJSON,
    ClassroomItem,
    ClassroomItemFromJSON,
    ClassroomItemToJSON,
    ClassroomMembership,
    ClassroomMembershipFromJSON,
    ClassroomMembershipToJSON,
    ClassroomObjectPermissions,
    ClassroomObjectPermissionsFromJSON,
    ClassroomObjectPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomDetail
 */
export interface ClassroomDetail {
    /**
     * 
     * @type {ClassroomObjectPermissions}
     * @memberof ClassroomDetail
     */
    permissions?: ClassroomObjectPermissions;
    /**
     * 
     * @type {Array<ClassroomEducator>}
     * @memberof ClassroomDetail
     */
    readonly educators?: Array<ClassroomEducator>;
    /**
     * 
     * @type {Array<ClassroomMembership>}
     * @memberof ClassroomDetail
     */
    readonly members?: Array<ClassroomMembership>;
    /**
     * 
     * @type {number}
     * @memberof ClassroomDetail
     */
    readonly membershipsCount?: number;
    /**
     * Serialize classroom items adding completion and stats
     * @type {Array<ClassroomItem>}
     * @memberof ClassroomDetail
     */
    readonly items?: Array<ClassroomItem>;
    /**
     * 
     * @type {number}
     * @memberof ClassroomDetail
     */
    readonly itemsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    subject: string;
    /**
     * 
     * @type {number}
     * @memberof ClassroomDetail
     */
    maxStudents: number;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    readonly joinCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomDetail
     */
    archived: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomDetail
     */
    created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomDetail
     */
    isMember: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassroomDetail
     */
    requestedToJoin: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClassroomDetail
     */
    discussion: string;
}

export function ClassroomDetailFromJSON(json: any): ClassroomDetail {
    return {
        'permissions': !exists(json, 'permissions') ? undefined : ClassroomObjectPermissionsFromJSON(json['permissions']),
        'educators': !exists(json, 'educators') ? undefined : (json['educators'] as Array<any>).map(ClassroomEducatorFromJSON),
        'members': !exists(json, 'members') ? undefined : (json['members'] as Array<any>).map(ClassroomMembershipFromJSON),
        'membershipsCount': !exists(json, 'memberships_count') ? undefined : json['memberships_count'],
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<any>).map(ClassroomItemFromJSON),
        'itemsCount': !exists(json, 'items_count') ? undefined : json['items_count'],
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'subject': json['subject'],
        'maxStudents': json['max_students'],
        'imageUrl': json['image_url'],
        'joinCode': !exists(json, 'join_code') ? undefined : json['join_code'],
        'archived': json['archived'],
        'created': new Date(json['created']),
        'isMember': json['is_member'],
        'requestedToJoin': json['requested_to_join'],
        'discussion': json['discussion'],
    };
}

export function ClassroomDetailToJSON(value?: ClassroomDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'permissions': ClassroomObjectPermissionsToJSON(value.permissions),
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'subject': value.subject,
        'max_students': value.maxStudents,
        'image_url': value.imageUrl,
        'archived': value.archived,
        'created': value.created.toISOString(),
        'is_member': value.isMember,
        'requested_to_join': value.requestedToJoin,
        'discussion': value.discussion,
    };
}


