import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';

interface SmallTabBarProps {
    children: React.ReactNode;
}

export class SmallTabBar extends React.PureComponent<SmallTabBarProps> {
    public render() {
        return (
            <div className='lx-small-tab-bar'>
                {this.props.children}
            </div>
        );
    }
}

interface SmallTabButtonProps {
    label: MessageDescriptor;
    labelValues?: any;
    active?: boolean;
    onClick: (event: React.MouseEvent) => void;
}

export class SmallTabButton extends React.PureComponent<SmallTabButtonProps> {
    public render() {
        return (
            <button className={
                `lx-small-tab-button ` +
                `${this.props.active ? 'lx-small-tab-button-active' : ''}`}
                onClick={this.props.onClick}>
                <WrappedMessage message={this.props.label} values={this.props.labelValues}/>
            </button>
        );
    }
}
