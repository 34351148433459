import { CareerOverviewCardCardTypeEnum, CareerOverviewCardDemandTrajectoryEnum } from 'labxchange-client';
import { useState, useRef, useCallback, useEffect } from 'react';

export enum CareerSectionsEnum {
    Overview = 'overview',
    CareerProgression = 'careerProgression',
    DayInLife = 'dayInLife',
    Requirements = 'requirements',
    WhatNext = 'whatNext',
    RelatedCareers = 'relatedCareers'
}

export const formatTime24to12 = (time: string) => {
    const [hours, minutes] = time.split(':');
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? 'pm' : 'am';
    const hours12 = ((hoursInt + 11) % 12 + 1);
    return `${hours12}:${minutes} ${suffix}`;
};

export enum CareerExplorerLogoType {
    Default = '/assets/images/career-explorer/career-explorer-logo-white.svg',
    Navy = '/assets/images/career-explorer/career-explorer-logo-navy.svg',
    White = '/assets/images/career-explorer/career-explorer-logo-white.svg',
}

function useFullscreen() {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [loading, setLoading] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);

    const toggleFullscreen = useCallback(() => {
        setLoading(true);
        if (!isFullscreen && elementRef.current) {
            elementRef.current?.requestFullscreen()
                .then(() => {
                    setIsFullscreen(true);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else if (isFullscreen) {
            document.exitFullscreen()
                .then(() => {
                    setIsFullscreen(false);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }, [isFullscreen]);

    // This is to handle 'Escape' key press to exit fullscreen
    useEffect(() => {
        const handleFullscreenChange = () => {
            const fullscreenElement = document.fullscreenElement;
            if (!fullscreenElement && isFullscreen) {
                toggleFullscreen();
                setIsFullscreen(!!fullscreenElement);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, [isFullscreen, toggleFullscreen, setIsFullscreen]);

    return { isFullscreen, loading, toggleFullscreen, elementRef };
}

export default useFullscreen;

export const getCardIcon = (
    cardType: CareerOverviewCardCardTypeEnum,
    demandTrajectory?: CareerOverviewCardDemandTrajectoryEnum | undefined,
) => {

    switch (cardType) {
        case CareerOverviewCardCardTypeEnum.Outlook:
            return demandTrajectory;
        default:
            return cardType;
    }
};

export const getCardIconColor = (
    demandTrajectory?: CareerOverviewCardDemandTrajectoryEnum | undefined,
) => {
    const iconColor = '#33B7A7';

    switch (demandTrajectory) {
        case CareerOverviewCardDemandTrajectoryEnum.Decline:
        case CareerOverviewCardDemandTrajectoryEnum.RapidDecline:
            return '#E57295';
        case CareerOverviewCardDemandTrajectoryEnum.Stable:
            return '#7188C7';
        default:
            return iconColor;
    }
};
