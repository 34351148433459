import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

import {getLoggedInStatus, getLoginCookieConsent} from 'auth/selectors';
import {getCookie} from 'utils';
import {cookieConsentConfig, CONSENT_COOKIE_NAME, onCookieConsent} from './CookieConsentConfig';

// Extend the Window interface to include custom properties
declare global {
    interface Window {
        VWO?: any;
        gtag?: any;
        fbq?: any;
        DD_RUM?: any;
    }
}

const CookieConsentBanner: React.FC = () => {
    const cookieConsent = useSelector(getLoginCookieConsent);
    const isLoggedIn = useSelector(getLoggedInStatus);

    useEffect(() => {
        CookieConsent.run(cookieConsentConfig());
    }, []);
    // If user take action before login - persist cookie consent state
    useEffect(() => {
        const cookieValue = getCookie(CONSENT_COOKIE_NAME);
        if (cookieValue) {
            const cookie = JSON.parse(decodeURIComponent(cookieValue));
            if(isLoggedIn && cookieConsent === undefined) {
              onCookieConsent({cookie});
            }
          }
    }, [isLoggedIn, cookieConsent]);

    return null;
};

export default CookieConsentBanner;
