import { defineMessages } from 'react-intl';

const messages = defineMessages({
    searchBoxPlaceholder: {
        id:'searchBoxPlaceholder',
        defaultMessage: 'Search public content',
        description: 'Placeholder text for search input field on Content Picker',
    },
    ownContentSearchBoxPlaceholder: {
        id:'ownContentSearchBoxPlaceholder',
        defaultMessage: 'Search your content',
        description: 'Placeholder text for search input field',
    },
    orgContentSearchBoxPlaceholder: {
        id:'orgContentSearchBoxPlaceholder',
        defaultMessage: 'Search organization\'s library',
        description: 'Placeholder text for search input field (organization content)',
    },
    favoritesSearchBoxPlaceholder: {
        id:'favoritesSearchBoxPlaceholder',
        defaultMessage: 'Search favorites',
        description: 'Placeholder text for search input field (organization content)',
    },
    tabLabelLibrary: {
        id:'tabLabelLibrary',
        defaultMessage: 'Library',
        description: 'Label for Library tab on Content Picker',
    },
    tabLabelYourContent: {
        id:'tabLabelYourContent',
        defaultMessage: 'Your Content',
        description: 'Label for your content tab on Content Picker',
    },
    tabLabelYourContentMobile: {
        id:'tabLabelYourContentMobile',
        defaultMessage: 'Private',
        description: 'Label for your content tab on Content Picker in a mobile view',
    },
    tabLabelYourOrg: {
        id:'tabLabelYourOrg',
        defaultMessage: 'Org. library',
        description: 'Label for your org library tab on Content Picker',
    },
    tabLabelFavorites: {
        id:'tabLabelFavorites',
        defaultMessage: 'Favorites',
        description: 'Label for your favorites tab on Content Picker',
    },
    tabLabelFavoritesMobile: {
        id:'tabLabelFavoritesMobile',
        defaultMessage: 'Favorite',
        description: 'Label for your favorites tab on Content Picker on a mobile view',
    },
    tabLabelAddContent: {
        id:'tabLabelAddContent',
        defaultMessage: '+ Add new content',
        description: 'Label for the add new content tab on Content Picker',
    },
    tabLabelAddContentMobile: {
        id:'tabLabelAddContentMobile',
        defaultMessage: 'Add',
        description: 'Label for the add new content tab on Content Picker in a mobile view',
    },
    addMultipleButton: {
        id:'addMultipleButton',
        defaultMessage: 'Add items ({count})',
        description: 'Label for the button to click to add all selected items',
    },
    tabAddNewContentBackButton: {
        id:'tabAddNewContentBackButton',
        defaultMessage: 'Back',
        description: 'Label for the button to click to return to the add new content main picker',
    },
    createItemButtonLabel: {
        id:'createItemButtonLabel',
        defaultMessage: 'Add your own content',
        description: 'Label of button for adding content.',
    },
    closeButtonLabel: {
        id: 'closeButtonLabel',
        defaultMessage: 'Close',
        description: 'Label of button for close a model.'
    }
});

export default messages;
