// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationCount
 */
export interface NotificationCount {
    /**
     * 
     * @type {number}
     * @memberof NotificationCount
     */
    all: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationCount
     */
    unread: number;
}

export function NotificationCountFromJSON(json: any): NotificationCount {
    return {
        'all': json['all'],
        'unread': json['unread'],
    };
}

export function NotificationCountToJSON(value?: NotificationCount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'all': value.all,
        'unread': value.unread,
    };
}


