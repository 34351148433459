import { defineMessages } from 'react-intl';

const messages = defineMessages({
    // TODO: remove unused messages
    pageTitle: {
        id:'pageTitleLearbersClassroomsDashboard',
        defaultMessage: 'Classes',
        description: 'Title of the learner dashboard page that lists all of learner\'s classes.',
    },
    pageDescription: {
        id:'pageDescription',
        defaultMessage: `Your current and past classes are listed below.
         If you have a class code, enter it here to join a new class.`,
        description: 'Description of the learner dashboard page that lists all of learner\'s classes.',
    },
    joinClassroomCardTitle: {
        id:'joinClassroomCardTitle',
        defaultMessage: 'Join a class',
        description: 'Label for join card button on Learner Dashboard',
    },
    inactiveClassesHeaderTitle: {
        id:'inactiveClassesHeaderTitle',
        defaultMessage: 'Past Classes',
        description: 'Label for past classes list header on Learner Dashboard',
    },
    rejoinConfirmationTitle: {
        id:'rejoinConfirmationTitle',
        defaultMessage: 'Rejoin class?',
        description: 'Title text for confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    rejoinConfirmationText: {
        id:'rejoinConfirmationText',
        defaultMessage: 'The educator will need to accept your request to join this class again.',
        description: 'Body text for confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    rejoinConfirmationButton: {
        id:'rejoinConfirmationButton',
        defaultMessage: 'Confirm',
        description: 'label for confirm button in confirmation modal which presents when a learner tries to rejoin a classroom',
    },
    requestFailureTitle: {
        id:'requestFailureTitle',
        defaultMessage: 'Request Failed.',
        description: 'title text for request failed error modal',
    },
    requestFailureMessage: {
        id:'requestFailureMessage',
        defaultMessage: `We were unable to submit your request to join this class. Please check the code and try again.`,
        description: 'Body text for request failed error modal',
    },
    showingResultCounter: {
        id:'showingResultCounter',
        defaultMessage: 'Showing {count, number} {count, plural, one {result} other {results}}',
        description: 'Number of results header.',
    },
    emtpyClassroomTitle: {
        id:'emtpyClassroomTitle',
        defaultMessage: 'You haven\'t joined a class yet.',
        description: 'Title for the empty classroom dashboard page.',
    },
    emptyClassroomSecondaryText: {
        id:'emptyClassroomSecondaryText',
        defaultMessage: 'In order to join a class, you will need an invitation from an educator.',
        description: 'Secondary text for the empty classroom dashboard page.',
    },
    emptyClassroomActionButton: {
        id:'emptyClassroomActionButton',
        defaultMessage: 'Join class',
        description: 'Action button text for the empty classroom dashboard page.'
    }
});

export default messages;
