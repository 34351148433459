// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    AppliedFilter,
    AppliedFilterFromJSON,
    AppliedFilterToJSON,
    SearchModeParameters,
    SearchModeParametersFromJSON,
    SearchModeParametersToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    mode?: SearchRequestModeEnum;
    /**
     * 
     * @type {SearchModeParameters}
     * @memberof SearchRequest
     */
    modeParameters?: SearchModeParameters;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    keywords?: string;
    /**
     * 
     * @type {Array<AppliedFilter>}
     * @memberof SearchRequest
     */
    filters?: Array<AppliedFilter>;
    /**
     * 
     * @type {Array<AppliedFilter>}
     * @memberof SearchRequest
     */
    exclude?: Array<AppliedFilter>;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    ordering?: SearchRequestOrderingEnum;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    paginationSize?: number;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return {
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'modeParameters': !exists(json, 'mode_parameters') ? undefined : SearchModeParametersFromJSON(json['mode_parameters']),
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'filters': !exists(json, 'filters') ? undefined : (json['filters'] as Array<any>).map(AppliedFilterFromJSON),
        'exclude': !exists(json, 'exclude') ? undefined : (json['exclude'] as Array<any>).map(AppliedFilterFromJSON),
        'ordering': !exists(json, 'ordering') ? undefined : json['ordering'],
        'currentPage': !exists(json, 'current_page') ? undefined : json['current_page'],
        'paginationSize': !exists(json, 'pagination_size') ? undefined : json['pagination_size'],
    };
}

export function SearchRequestToJSON(value?: SearchRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'mode': value.mode,
        'mode_parameters': SearchModeParametersToJSON(value.modeParameters),
        'keywords': value.keywords,
        'filters': value.filters === undefined ? undefined : (value.filters as Array<any>).map(AppliedFilterToJSON),
        'exclude': value.exclude === undefined ? undefined : (value.exclude as Array<any>).map(AppliedFilterToJSON),
        'ordering': value.ordering,
        'current_page': value.currentPage,
        'pagination_size': value.paginationSize,
    };
}

/**
* @export
* @enum {string}
*/
export enum SearchRequestModeEnum {
    Public = 'public',
    Owned = 'owned',
    OrganizationOwned = 'organization-owned',
    Favorites = 'favorites',
    OwnOrgs = 'own-orgs',
    AllUserAccessible = 'all-user-accessible'
}
/**
* @export
* @enum {string}
*/
export enum SearchRequestOrderingEnum {
    Relevance = 'relevance',
    LastAdded = 'last_added',
    MostViewed = 'most_viewed',
    MostFavorited = 'most_favorited',
    MostRemixed = 'most_remixed',
    Alphabetical = 'alphabetical'
}


