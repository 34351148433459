import bind from 'bind-decorator';
import * as React from 'react';

import { Icon } from 'elements';
import { intl } from 'i18n';

import messages from './displayMessages';

export class MessageSearchBox extends React.Component {
    public render() {
        const placeholder = intl.formatMessage(messages.placeholder);
        return (
            <div className='message-search-box input-group'>
                <input type='text'
                    className='message-search-box-input form-control border-right-0'
                    placeholder={placeholder}
                    aria-label={placeholder}
                    onKeyDown={this.onKeyDown}
                />
                <div className='message-search-box-icon input-group-append'>
                    <span className='message-search-box-icon-text input-group-text text-header'>
                        <Icon name='search' fill='#FFFFFF' zoom='1.3em'/>
                    </span>
                </div>
            </div>
        );
    }

    @bind private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
}
