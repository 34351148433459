// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface EmbargoStatus
 */
export interface EmbargoStatus {
    /**
     * 
     * @type {string}
     * @memberof EmbargoStatus
     */
    embargoedRegion?: string;
}

export function EmbargoStatusFromJSON(json: any): EmbargoStatus {
    return {
        'embargoedRegion': !exists(json, 'embargoed_region') ? undefined : json['embargoed_region'],
    };
}

export function EmbargoStatusToJSON(value?: EmbargoStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'embargoed_region': value.embargoedRegion,
    };
}


