import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pastClassesTitle: {
        id:'pastClassesTitle',
        defaultMessage: 'Past Classes',
        description: 'Label for past classes list header on Learner Dashboard',
    },
    archivedClassesTitle: {
        id:'archivedClassesTitle',
        defaultMessage: 'Archive',
        description: 'Header title for inactive classrooms list on Educator Dashboard',
    },
    addNewClassroomCardTitle: {
        id:'addNewClassroomCardTitle',
        defaultMessage: 'Create a new class',
        description: 'Text to click on for adding a new Classroom card in Educator Dashboard',
    },
    joinClassroomCardTitle: {
        id:'joinClassroomCardTitle',
        defaultMessage: 'Join a class',
        description: 'Label for join card button on Learner Dashboard',
    },
    sortBy: {
        id:'sortBy',
        defaultMessage: 'Sort by:',
        description: 'Label for the sort by select',
    },
    recentlyAdded: {
        id:'recentlyAdded',
        defaultMessage: 'Recently added',
        description: 'Label on sort by recently added option.',
    },
    atoZ: {
        id:'atoZ',
        defaultMessage: 'A to Z',
        description: 'Label on sort by A to Z option.',
    },
    ztoA: {
        id:'ztoA',
        defaultMessage: 'Z to A',
        description: 'Label on sort by Z to A option.',
    },
    showingResults: {
        id:'showingResults',
        defaultMessage: 'Showing {count} results',
        description: 'Text to indicate how many classroms there are.',
    },
});

export default messages;
