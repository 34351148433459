import { defineMessages } from 'react-intl';

const messages = defineMessages({
    blocked: {
        id:'blocked',
        defaultMessage: 'Blocked',
        description: 'Text for the label shown when the current user has blocked another user.',
    },
    blockedYou: {
        id:'blockedYou',
        defaultMessage: 'Conversation ended',
        description: 'Text for the label shown when the current user has been blocked by another user.',
    },
    pendingYou: {
        id:'pendingYou',
        defaultMessage: 'New message request',
        description: 'Text for the label shown when the current user has a new message request.',
    },
    pendingThem: {
        id:'pendingThem',
        defaultMessage: 'Message request pending',
        description: 'Text for the label shown when the current user is waiting for the other user to accept their message request.',
    },
});

export default messages;
