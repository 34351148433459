// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Thread,
    ThreadFromJSON,
    ThreadToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiscussionsSearchResults
 */
export interface DiscussionsSearchResults {
    /**
     * 
     * @type {number}
     * @memberof DiscussionsSearchResults
     */
    count: number;
    /**
     * 
     * @type {Array<Thread>}
     * @memberof DiscussionsSearchResults
     */
    results: Array<Thread>;
    /**
     * 
     * @type {any}
     * @memberof DiscussionsSearchResults
     */
    aggregations: any;
}

export function DiscussionsSearchResultsFromJSON(json: any): DiscussionsSearchResults {
    return {
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(ThreadFromJSON),
        'aggregations': (json['aggregations']),
    };
}

export function DiscussionsSearchResultsToJSON(value?: DiscussionsSearchResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(ThreadToJSON),
        'aggregations': (value.aggregations),
    };
}


