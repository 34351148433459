import React, {useState} from 'react';
import { CareerCheckBox } from '../../../CareerCheckBox';

interface CardItemProps {
   title: string;
   description: string;
   userPromptText: string;
   key: string;
}

const CardComponent: React.FC<CardItemProps> = ({title, description, userPromptText, key}) => {

    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
    const toggleCheckbox = (newValue: boolean) => {
        setCheckboxChecked(newValue);
    };

    return (
        <div className='card-container' key={key}>
            <div className='card-content'>
                <div className='title-section'>
                    <div className='title'>{title}</div>
                </div>
                <div className='description'>
                    {description}
                </div>
            </div>
            <div className='skills-section'>
                <div className='skill-container'>
                    <CareerCheckBox checked={checkboxChecked} onClick={() => toggleCheckbox(!checkboxChecked)}/>
                    <div className='skill-text'>
                        {userPromptText}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardComponent;
