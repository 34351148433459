import React, { ReactNode, useState, useEffect } from 'react';
import { WrappedMessage, useLayoutSize } from 'utils';
import { SectionSlab } from 'labxchange-client';
import messages from './displayMessages';

import { convertSlabToFeaturedItems } from 'subjects/components/page/utils';
import { CardsList } from './CardsList';
import { Link } from 'react-router-dom';
import { SectionSlabsApi } from 'global/api';


interface TeachPageState {
    heading: ReactNode;
    slug: string;
}

export const SingleSlabSection: React.FC<TeachPageState> = ({heading, slug}) => {
    const layoutSize = useLayoutSize();
    const [slabItems, setSlabItems] = useState<SectionSlab>();

    const fetchSingleSlabContent = async () => {
        const response: SectionSlab = await SectionSlabsApi.slabsRead({id: slug});
        if (response) {
            setSlabItems(response);
        }
    };

    useEffect(() => {
        fetchSingleSlabContent();
    }, [slug]);

    return (
        <div className='cards-container section scroll-section'>
            <div className='interactive-sec-heading'>
                <h1>{heading}</h1>
                {slabItems?.seeMoreLink &&
                  <Link
                    className='underline-link mobile-row interactive-sec-more'
                    to={slabItems.seeMoreLink}>
                    <WrappedMessage message={messages.seeMoreButton} />
                  </Link>
                }
            </div>
            {slabItems &&
              <div className='list-cards'>
                <CardsList
                  cards={convertSlabToFeaturedItems(slabItems)}
                  layoutSize={layoutSize}/>
              </div>
            }
        </div>
    );
};
