import bind from 'bind-decorator';
import * as React from 'react';

import { Modal } from 'ui/components';
import { WrappedMessage } from 'utils';
import { intl } from 'i18n';

import messages from './displayMessages';

interface Props {
    onClose: () => void;
    onJoinCodeEntered: (joinCode: string) => void;
    /*
    * This toggle is to let us re-purpose the Modal that lets users enter a join code,
    * to display to correct text for the case where they tried to enter a classroom they are not enrolled in
    */
    userAttemptedToViewClassroomBeforeEnrolled?: boolean;
}

interface State  {
    joinCode: string;
}

export class JoinClassroomModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            joinCode: '',
        };
    }

    public render() {
        let title;
        const body = messages.joinClassText;

        if (this.props.userAttemptedToViewClassroomBeforeEnrolled) {
            title = messages.joinClassPreviouslyViewedTitle;
        } else {
            title = messages.joinClassTitle;
        }
        return (
            <Modal
                className='modal-join-classroom'
                onRequestClose={this.props.onClose}
                content={
                    <>
                        <div className='title-icon'></div>
                        <div className='title-text'>
                            <WrappedMessage message={title} />
                        </div>
                        <div className='body-text'>
                            <WrappedMessage message={body} />
                        </div>
                        <div className='left-align'>
                            <label htmlFor='classroom-name'>
                                <WrappedMessage message={messages.classCodeInputTextLabel} />
                            </label>
                            <input
                                id='classroom-name'
                                className='form-control'
                                onChange={(e) => this.updateJoinCode(e.target.value)}
                                value={this.state.joinCode}
                                placeholder={intl.formatMessage(messages.classCodePlaceholderText)}
                                type='text'
                                autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                                autoComplete='on'
                            />
                            <button
                                className={'primary-button modal-join-classroom-button'}
                                onClick={this.onJoinButtonClicked}
                                disabled={!this.isCodeValid()}
                                aria-disabled={!this.isCodeValid()}
                            >
                                <WrappedMessage message={messages.joinClassButtonText} />
                            </button>
                        </div>
                    </>
                }
            />
        );
    }

    private isCodeValid() {
        return this.state.joinCode.length >= 5;
    }

    @bind private onJoinButtonClicked() {
        this.props.onJoinCodeEntered(this.state.joinCode);
    }

    @bind private updateJoinCode(joinCode: string) {
        this.setState({joinCode});
    }
}
