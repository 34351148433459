import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { MessageDescriptor } from 'react-intl';

import { Icon, IconSymbol } from 'elements';
import { WrappedMessage } from 'utils';
import { UserAvatar } from 'user/components';
import messages from '../displayMessages';
import { intl } from 'i18n';

interface CardStandardProps extends React.PropsWithChildren {
    barTop?: React.ReactElement<CardBarProps>;
    media?: React.ReactElement<CardMediaProps>;
    details: React.ReactElement<CardDetailsProps>;
    barBottom?: React.ReactElement<CardBarProps>;
    showBarTopSeparator: boolean;
    showBarBottomSeparator: boolean;
    onClick?: () => void;
    additionalStyling?: string;
    showSkeleton?: boolean;
}

// Media
interface CardMediaProps {
    mediaUrl?: string;
    overlays?: [React.ReactElement<CardMediaOverlayTextProps | CardMediaOverlayIconTextProps>];
    onClick?: (event: React.MouseEvent) => void;
    showSkeleton?: boolean;
}

interface CardMediaOverlayProps { additionalStyling: string; } // required for positioning
interface CardMediaOverlayTextProps extends CardMediaOverlayProps { message: React.ReactNode; }
interface CardMediaOverlayIconTextProps extends CardMediaOverlayProps { outofscope: string; }

// Details Primitives
interface CardDetailsProps {
    title: React.ReactNode;
    text?: string;
    onClick?: (event: React.MouseEvent) => void;
    showSkeleton?: boolean;
}
interface CardDetailsStatsProps {
    text?: string | React.ReactNode;
    showSkeleton?: boolean;
}

// Bars - the top and bottom sections of Cards
interface CardBarButtonProps {
    iconName: IconSymbol;
    text: string;
    onClick: (event: React.MouseEvent) => void;
}
interface CardBarUserProps {
    username: string;
    text: string;
    onClick?: () => void;
    showSkeleton?: boolean;
}
interface CardBarIconLabelProps {
    iconName: IconSymbol;
    text: string;
}
interface CardBarUsersListProps {
    usernames: string[];
}
type CardBarProps = CardBarButtonProps | CardBarUserProps | CardBarUsersListProps;

// Faux Card
interface CardFauxAddProps {
    message: MessageDescriptor;
    onClick: () => void;
    additionalStyling?: string;
    showSkeleton?: boolean;
}

// Standard card to build new Cards from
export const CardStandard: React.FunctionComponent<CardStandardProps> = (
    { barTop,
        media,
        details,
        barBottom,
        showBarTopSeparator,
        showBarBottomSeparator,
        onClick,
        additionalStyling,
        children,
        showSkeleton,
    }) => {

    const onKeyPress = (event: React.KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.key=== 'Enter' && onClick) {
            onClick();
        }
    };

    const onClickWrapper = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            onClick={onClickWrapper} role='button' tabIndex={0} onKeyPress={onKeyPress}
            className={classNames(
                'card-component',
                additionalStyling,
                {'skeleton': showSkeleton},
            )}
        >
            {children}
            {barTop}
            {showBarTopSeparator ? <div className='bar-seperator'/> : null}
            {media}
            {details}
            {showBarBottomSeparator ? <div className='bar-seperator'/> : null}
            {barBottom}
        </div>
    );
};

// Media + Overlays
export const CardMedia: React.FC<CardMediaProps> = (
    { mediaUrl, overlays , showSkeleton}) => (
        <div className='media'>
            {showSkeleton ? <div className='skeleton-container'><Skeleton/></div> : <>
                {overlays}
                {
                    mediaUrl ?
                        <img src={mediaUrl} alt=''/>
                        : null
                }
            </>
            }
        </div>
    );

export const CardMediaOverlayText: React.FC<CardMediaOverlayTextProps> = (
    { message, additionalStyling }) => (
        <div
            className={`media-overlay ${additionalStyling ? additionalStyling : ''}`}
        >
            {message}
        </div>
    );

// CardMediaOverlayLearnerProgress (SE-1095)

// CardMediaOverlayMediaType (out of scope)
// ^^ Represents the "media info" currently in Library Card
// it will be needed on Dashboards "details" tab, consider refactoring

// Details + Primitives
export const CardDetails: React.FC<CardDetailsProps> = (
    { title, text , showSkeleton }) => (
        <div className='details'>
            {showSkeleton ? <Skeleton count={3}/> : <>
                <h1>{title}</h1>
                <p>{text}</p>
            </>}
        </div>
    );

// Something like <ItemStats> (which is currently too tightly coupled
// to Library/AnnotatedItemMetadata) to be used for Classrooms in the Educator / Learner Dashboards
// This should take a complex object in the future, not some text string,
// the component handles formatting and presentation.
// It should just wrap another view, probably <ItemStats> or similar
export const CardDetailsStats: React.FC<CardDetailsStatsProps> = (
    {text, showSkeleton}) => {
    if (showSkeleton) {
        return <div className='details-stats skeleton-circles-container'>
            <Skeleton circle={true} count={4} />
        </div>;
    } else {
        return <div className='details-stats'>{text}</div>;
    }
};

// CardDetailsProgress (out of Scope)
// ^^ Represents progress status on Items such as "Complete", "In-Progress",
// Currently is appended below details in the designs

// Bars
export const CardBarButton: React.FC<CardBarButtonProps> = ({iconName, text, onClick}) => (
    <button className='bar-button' onClick={onClick}>
        <Icon name={iconName} className='bar-button-icon'/>{text}
    </button>
);
export const CardBarUser: React.FC<CardBarUserProps> = ({username, text, onClick, showSkeleton}) => {

    const onKeyPress = (event: React.KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.key=== 'Enter' && onClick) {
            onClick();
        }
    };

    const onClickWrapper = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (onClick) { onClick(); }
    };

    return (
        <div className='bar-user' onClick={onClickWrapper} role='button' tabIndex={0} onKeyPress={onKeyPress}>
            {showSkeleton ? <div className='skeleton-circles-container'>
                <Skeleton circle={true} count={4} />
            </div> : <>
                <UserAvatar username={username} diameter='32px'/>
                <div className='bar-user-name'>
                    {text}
                </div>
            </>}
         </div>
    );
};
export const CardBarIconLabel: React.FC<CardBarIconLabelProps> = ({iconName, text}) => (
    <div className='bar-label'>
        <Icon name={iconName} className='bar-label-icon'/>
        {text}
    </div>
);
export const CardBarUsersList: React.FC<CardBarUsersListProps> = (
    { usernames }) => (
        <div className='bar-users-list'>
            {
                usernames.slice(0, 3).map((username) => (
                    <UserAvatar
                        username={username}
                        key={username}
                    />
                ))
            }
            {
                usernames.length > 3 ?
                    <div className='bar-users-list-user-item rounded-circle'>
                        +{usernames.length - 3}
                    </div> : null
            }
        </div>
    );

// Dashboard Faux Card
export const CardFauxAdd: React.FC<CardFauxAddProps> = (
    { message, onClick, additionalStyling , showSkeleton}) => (
        <div className='card-component-faux-add-container'>
            <button
                className={`btn card-component-faux-add add-link join-class-link ${showSkeleton ? 'skeleton' : ''}
                    ${additionalStyling ? additionalStyling : ''}`}
                tabIndex={0}
                onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    onClick();
                }}
            >
                {showSkeleton ? null : <>
                    <Icon name='plus-small' zoom='64' />
                    <WrappedMessage message={message} />
                </>}
            </button>
        </div>
    );

// Card Accessories

interface CardAddButtonProps {
    onClick: () => void;
    selected?: boolean;
    slectedIconType?: 'dash' | 'check';
}

export const CardAddButtonAccessory: React.FC<CardAddButtonProps> =
    ({ onClick, selected, slectedIconType }) => {

        const icon: IconSymbol = selected ? (slectedIconType ?? 'dash') : 'plus';
        const message = selected ? messages.uiUnselectButton : messages.uiSelectButton;

        return (
        <div className='accessory-container'>
            <div className='plus-mobile-view'>
                    <button onClick={onClick}>
                        <Icon name={icon} zoom='12' />
                        <WrappedMessage message={message} />
                    </button>
                </div>
            <div className='plus-accessory'>
                <button onClick={onClick} title={intl.formatMessage(message)}>
                    <Icon
                        name={icon}
                        zoom='20'
                        className='plus-button'
                    />
                </button>
            </div>
        </div>
        );
    };
