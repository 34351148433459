import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitlePeoplePage',
        defaultMessage: 'People',
        description: 'Page title for the people page',
    },
    notLoggedPeopleAlertTitle: {
        id: 'notLoggedPeopleAlertTitle',
        defaultMessage: 'Log in to search LabXchange users',
        description: 'Title of the alert when the user tries to enter to the people page',
    },
    notLoggedPeopleAlertBody: {
        id: 'notLoggedPeopleAlertBody',
        defaultMessage: 'You must have a LabXchange account to search LabXchange user profiles. You can log in or sign up by clicking the buttons below.',
        description: 'Body of the alert when the user tries to enter to the people page',
    },
});

export default messages;
