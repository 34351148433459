import { bind } from 'bind-decorator';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { LocationDescriptor } from 'history';
import { ROUTES } from 'global/constants';
import { CreateItemStep } from 'items/components/CreateItemModal';
import { getItemTypeMeta, ItemType } from 'items/models';
import { ItemsApi } from 'global/api';
import { ContentPickerModal } from 'library/components/ContentPickerModal';
import * as UI from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { AssignmentEditorState, SpecificEditorProps } from './models';

type AssessmentOrItemEntry = AssignmentEditorState['assessments'][0];
interface AssessmentCardProps extends AssessmentOrItemEntry {
    onDeleteAssessment: (itemId: string) => void;
}

const ExcludeChildAssetTypes = Object.values(ItemType).filter(
    (it) => ![
        ItemType.Assessment,
        ItemType.Question,
        ItemType.Text,
    ].includes(it)
);

/** A card in the Assignment editor, representing an assessment (or other item) added to the assignment. */
const AssessmentCard: React.FunctionComponent<AssessmentCardProps> = ({id, onDeleteAssessment, ...props}) => {

    const callOnDeleteAssessment = React.useCallback(() => onDeleteAssessment(id), [id, onDeleteAssessment]);

    // Figure out the "Authored by: ..." text, showing authors and/or organization:
    const authors: React.ReactNode[] = [];
    for (const author of props.metadata.authors) {
        if (authors.length) {
            authors.push(', ');
        }
        // Fix: due to the fact that the API that provides this data has a generic schema,
        // some fields do not have the name transformed to camel case.
        if (author.fullName === undefined && (author as any).full_name) {
            author.fullName = (author as any).full_name;
        }
        authors.push(
            author.username ?
                <NavLink
                    key={author.username}
                    to={ROUTES.Users.PROFILE_SLUG(author.username)}
                >{author.fullName}</NavLink>
            :
                author.fullName,
        );
    }
    if (props.metadata.source) {
        if (authors.length) {
            authors.push(', ');
        }
        // Fix: due to the fact that the API that provides this data has a generic schema,
        // some fields do not have the name transformed to camel case.
        const source = props.metadata.source as any;
        authors.push(source.providerName || source.provider_name);
    }

    const assessmentTitle = <UI.ClipLines lines='2' text={props.metadata.title || 'Untitled'} />;

    return <div className='d-flex align-items-center'>
        <div className='assignment-assessment-card rounded'>
            <div className='title-panel'>
                <div className='item-type-label'>
                    <WrappedMessage message={getItemTypeMeta(props.metadata.type).name} />
                </div>
                <div className='item-title'>
                    <NavLink
                        to={ROUTES.Library.ITEM_SLUG(props.metadata.id)}
                        target='_blank'
                    >{assessmentTitle}</NavLink>
                </div>
                {authors.length > 0 &&
                    <div className='authors'>
                        <WrappedMessage
                            message={messages.assignmentEditorAuthoredBy}
                            values={{authors: <>{authors}</>}}/>
                    </div>
                }
            </div>
            <div className='points-panel'>
                <div className='assessment-stat'>
                    <span className='number-label'><WrappedMessage message={messages.assignmentEditorAttempts}/></span>
                    <span className='number-value'>{props.max_attempts === 0 ? '∞' : props.max_attempts || 'N/A'}</span>
                </div>
                <div className='assessment-stat'>
                    <span className='number-label'><WrappedMessage message={messages.assignmentEditorPoints}/></span>
                    <span className='number-value'>{props.weight || 'N/A'}</span>
                </div>
            </div>
        </div>
        <div className='assignment-assessment-buttons'>
            {/*
            This edit button is in the designs but we haven't implemented it yet:
            <UI.Button
                btnStyle='link' size='sm' icon='pencil' iconOnly={true} disabled={true}
                label={messages.assignmentEditorEditAssessment}
            />
            */}
            <UI.Button
                btnStyle='link' size='sm' icon='trashcan' iconOnly={true}
                onClick={callOnDeleteAssessment}
                label={messages.assignmentEditorDeleteAssessment}
            />
        </div>
    </div>;
};

/** A placeholder card in the Assignment editor, which is a clickable button to add a new assessment. */
const AddAssessmentCard: React.FunctionComponent<{
    onAddAssessment: () => void,
    required: boolean,
}> = ({onAddAssessment, required}) => {
    return <div className='d-flex align-items-center'>
        <button className='assignment-assessment-add-button rounded' onClick={onAddAssessment}>
            + <WrappedMessage message={messages.assignmentEditorAddAssessment}/>{required && '*'}
        </button>
        <div className='assignment-assessment-buttons'>{/* Placeholder for consistent spacing */}</div>
    </div>;
};

type Props = SpecificEditorProps<AssignmentEditorState>;

interface State {
    showAddAssessmentModal: boolean;

    // Track when new assessments are added to display a message about "Attempts/Points" not showing until save:
    newAssessmentsAdded: boolean;
}

/**
 * Editor UI for Assignment components (lx_assignment)
 */
export class AssignmentBlockEditor extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showAddAssessmentModal: false,
            newAssessmentsAdded: false,
        };
    }

    public render() {
        const editorState = this.props.editorState;

        // If we're loading just display a loading spinner
        if (editorState === undefined) {
            return <div className='p-4'><UI.Spinner /></div>;
        }

        // Sum the 'weight' values of each item
        const totalPoints = editorState.assessments.reduce((pts, item) => pts + (item.weight || 0), 0);

        // Create the "Add new assessment" buttons
        const addButtons: UI.ReorderableListItem[] = [];
        const numAddButtons = Math.max(1, 3 - editorState.assessments.length);
        for (let i = 0; i < numAddButtons; i++) {
            addButtons.push({
                key: i,
                html: <AddAssessmentCard
                    key={i}
                    onAddAssessment={() => { this.setState({ showAddAssessmentModal: true }); }}
                    required={numAddButtons > 1 && i < numAddButtons - 1}
                />,
                isPlaceholder: true,
            });
        }

        return <UI.ThinItemSection>
            <div className='section-label'><WrappedMessage message={messages.assignmentEditorAssessmentsHeading}/>*</div>
            <UI.ContainerOne>
                <div className='p-3'>
                    <UI.ReorderableList
                        items={[...editorState.assessments.map((item) => ({
                            key: item.metadata.id,
                            html: <AssessmentCard {...item} onDeleteAssessment={this.onDeleteAssessment} />,
                        })),
                        ...addButtons,
                        ]}
                        onMove={this.onMove}
                    />
                </div>
                {this.state.newAssessmentsAdded &&
                    <div className='m-3 alert alert-warning'>
                        {/* "Attempts" and "Points" values for newly added assessments will show after you save changes. */}
                        <WrappedMessage message={messages.assignmentEditorNewlyAdded}/>
                    </div>
                }
                <UI.ContainerInset justify='end'>
                    <WrappedMessage message={messages.assignmentEditorTotalPoints} values={{totalPoints}} />
                </UI.ContainerInset>
            </UI.ContainerOne>
            {
                this.state.showAddAssessmentModal &&
                    <ContentPickerModal
                        onClose={() => { this.setState({ showAddAssessmentModal: false }); }}
                        onItemsAdd={this.onAddNewItems}
                        // Filter to only allow selecting Assessment type items:
                        excludeTypes={ExcludeChildAssetTypes}
                        multipleSelect={true}
                        showCreateItemTab={true}
                        createItemStep={CreateItemStep.SelectSubType}
                        createItemSelectedItemType={ItemType.Assessment}
                        onCreateItem={this.onNestedCreate}
                        noAddContentReplaceModal={true}
                    />
            }
        </UI.ThinItemSection>;
    }

    @bind private onNestedCreate(location: LocationDescriptor) {
        this.setState({ showAddAssessmentModal: false });
        if (this.props.onNestedCreate) {
            this.props.onNestedCreate(location, {});
        }
    }

    // Event handler for re-ordering items:
    @bind private onMove(fromIndex: number, toIndex: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        const newAssessments = editorState.assessments.slice();

        const [assessment] = newAssessments.splice(fromIndex, 1); // Remove item from its old position
        newAssessments.splice(toIndex, 0, assessment); // Insert into new position
        this.props.onEditorStateChanged({...editorState, assessments: newAssessments});
    }

    // Event handler for deleting an item:
    @bind private onDeleteAssessment(itemId: string) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }

        const newAssessments = editorState.assessments.filter((entry) => entry.id !== itemId);
        this.props.onEditorStateChanged({...editorState, assessments: newAssessments});
    }

    // Event handler for adding a new item (after the user selects it from the modal):
    @bind private async onAddNewItems(assignmentIds: Set<string>) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }

        const existingItems = new Map((editorState.assessments || []).map((x: any) => [
            x.metadata.id, x
        ]));

        const duplicates = [];
        const newIds = [];
        for (const id of assignmentIds) {
            const duplicate = existingItems.get(id);
            if (duplicate) {
                duplicates.push(duplicate);
            } else {
                newIds.push(id);
            }
        }

        const newAssessments = [...editorState.assessments];
        for (const id of newIds) {
            const { metadata } = await ItemsApi.read({id});
            newAssessments.push({
                id,
                metadata,
                // TODO: call an API to get this information from the problem block's OLX?
                graded: true,
                max_attempts: null,
                weight: null,
            });
        }

        if (duplicates.length > 0) {
            UI.showErrorMessage(
                <WrappedMessage
                    message={messages.assignmentEditorAlreadyAdded}
                />
            );
        }

        this.props.onEditorStateChanged({...editorState, assessments: newAssessments});
        this.setState({
            newAssessmentsAdded: true,
            showAddAssessmentModal: false,
        });
    }
}
