import { defineMessages } from 'react-intl';

const messages = defineMessages({
    cancelByMenteeTitle: {
        id:'cancelByMenteeTitle',
        defaultMessage: 'Are you sure you want to remove {fullName} as a mentor?',
        description: 'Cancel mentorship by mentee modal title.',
    },
    cancelByMentorTitle: {
        id:'cancelByMentorTitle',
        defaultMessage: 'Are you sure you want to stop mentoring {fullName}?',
        description: 'Cancel mentorship by mentor modal title.',
    },
    messageByMenteeLabel: {
        id:'messageByMenteeLabel',
        defaultMessage: 'Provide {fullName} a reason for withdrawing your menteeship*.',
        description: 'Menteeship cancel message label.',
    },
    messageByMentorLabel: {
        id:'messageByMentorLabel',
        defaultMessage: 'Provide {fullName} a reason for withdrawing your mentorship.',
        description: 'Mentorship cancel message label.',
    },
    cancelButton: {
        id:'cancelButton',
        defaultMessage: 'Cancel',
        description: 'Cancel button',
    },
    submitByMenteeButton: {
        id:'submitByMenteeButton',
        defaultMessage: 'Withdraw menteeship',
        description: 'Withdraw menteeship button',
    },
    submitByMentorButton: {
        id:'submitByMentorButton',
        defaultMessage: 'Withdraw mentorship',
        description: 'Withdraw mentorship button',
    },
    requiredFieldErrorMessage: {
        id:'requiredFieldErrorMessage',
        defaultMessage: 'This field is required.',
        description: 'Required field error message.',
    },
});

export default messages;
