// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadUserAttributes
 */
export interface ThreadUserAttributes {
    /**
     * 
     * @type {boolean}
     * @memberof ThreadUserAttributes
     */
    liked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadUserAttributes
     */
    followed: boolean;
}

export function ThreadUserAttributesFromJSON(json: any): ThreadUserAttributes {
    return {
        'liked': json['liked'],
        'followed': json['followed'],
    };
}

export function ThreadUserAttributesToJSON(value?: ThreadUserAttributes): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'liked': value.liked,
        'followed': value.followed,
    };
}


