import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { WrappedMessage } from 'utils';

interface HeadingProps extends React.PropsWithChildren {
    level: 1 | 2 | 3 | 4 | 5 | 6;
    title?: string;
    titleDescriptor?: MessageDescriptor;
    titleDescriptorValues?: any;
}

export const HeadingOne: React.FunctionComponent<HeadingProps> = (props) => {
    return <Heading headingStyle='heading-style-1' {...props} />;
};

export const HeadingTwo: React.FunctionComponent<HeadingProps> = (props) => {
    return <Heading headingStyle='heading-style-2' {...props} />;
};

export const HeadingThree: React.FunctionComponent<HeadingProps> = (props) => {
    return <Heading headingStyle='heading-style-3' {...props} />;
};

export const HeadingFour: React.FunctionComponent<HeadingProps> = (props) => {
    return <Heading headingStyle='heading-style-4' {...props} />;
};

export const HeadingFive: React.FunctionComponent<HeadingProps> = (props) => {
    return <Heading headingStyle='heading-style-5' {...props} />;
};

export const HeadingSix: React.FunctionComponent<HeadingProps> = (props) => {
    return <Heading headingStyle='heading-style-6' {...props} />;
};

export interface HeadingInternalProps extends HeadingProps {
    headingStyle: 'heading-style-1' | 'heading-style-2' | 'heading-style-3' | 'heading-style-4' | 'heading-style-5' | 'heading-style-6';
}

/**
 * These components should be used for headings. They simplify the following:
 *
 * 1. Composing any combination of heading level and style without the need to add css classes or duplicate
 * styles.
 * 2. By default their bottom margin is set to zero (unlike the hx elements) so you don't need to reset it.
 * 3. You can pass a string, a descriptor (so reduces `<WrappedMessage />` noise) or children components.
 */
export const Heading: React.FunctionComponent<HeadingInternalProps> = (props) => {

    let headingContent: any;
    if (props.title) {
        headingContent = (
            <>{props.title}</>
        );
    } else if (props.titleDescriptor) {
        headingContent = (
            <WrappedMessage message={props.titleDescriptor} values={props.titleDescriptorValues}/>
        );
    } else if (props.children) {
        headingContent = props.children;
    }

    switch (props.level) {
        case 1:
            return <h1 className={props.headingStyle} children={headingContent}/>;
        case 2:
            return <h2 className={props.headingStyle} children={headingContent}/>;
        case 3:
            return <h3 className={props.headingStyle} children={headingContent}/>;
        case 4:
            return <h4 className={props.headingStyle} children={headingContent}/>;
        case 5:
            return <h5 className={props.headingStyle} children={headingContent}/>;
        case 6:
            return <h6 className={props.headingStyle} children={headingContent}/>;
    }
};
