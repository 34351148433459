import update from 'immutability-helper';
import { ActionTypes, Types } from './actions';

export interface LoginModalState {
    showModal: boolean;
}

export function globalLoginModalReducer(
    state: Readonly<LoginModalState> = {showModal: false},
    action: ActionTypes
): Readonly<LoginModalState> {
    switch (action.type) {
        case Types.SHOW_MODAL:
            return update(state, {showModal: {$set: true}});
        case Types.DISMISS_MODAL:
            return update(state, {showModal: {$set: false}});
        default:
            return state;
    }
}
