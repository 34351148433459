// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface DiscussionAuthor
 */
export interface DiscussionAuthor {
    /**
     * 
     * @type {string}
     * @memberof DiscussionAuthor
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionAuthor
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionAuthor
     */
    readonly jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionAuthor
     */
    readonly company?: string;
}

export function DiscussionAuthorFromJSON(json: any): DiscussionAuthor {
    return {
        'username': json['username'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'company': !exists(json, 'company') ? undefined : json['company'],
    };
}

export function DiscussionAuthorToJSON(value?: DiscussionAuthor): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
    };
}


