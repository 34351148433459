import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { CurriculumSubject, ModuleHierarchy } from 'labxchange-client';
import { Collapsible } from 'ui/components/Collapsible';
import { WrappedMessage } from 'utils';

import messages from './displayMessages';
import { SelectDropdownOption } from './types';

export const createSubjectGradeOptions = (
  subjects: CurriculumSubject[], selectedSubjectValue: string
) => {
  const subjectOptArray: SelectDropdownOption[] = [];
  const gradeOptArray: SelectDropdownOption[] = [];

  subjects.map((s) => {
    if (s.slug && s.name) {
      subjectOptArray.push({
        value: s.slug,
        label: s.name,
      });

      if (s.slug === selectedSubjectValue) {
        s.grades.map((g) => {
          if (g.slug && g.name) {
            gradeOptArray.push({
              value: g.slug,
              label: g.name,
            });
          }
        });
      }
    }
  });

  return { subjectOptArray, gradeOptArray };
};

export const renderModules = (
  modules: ModuleHierarchy[], curriculum: string, subject: string,
  grade: string, moduleLevel: number, activeModule?: string,
  callback?: () => void
) => {
  if (!modules || !modules.length) {
      return;
  }

  if (moduleLevel === 1) {
    return (
      <>
        {modules.map((module:any) => (
          <div key={module.slug} className='curriculum-module-container'>
            <h3 className='curriculum-module-heading'> {module.name} </h3>
            <ul key={module.slug} className='curriculum-module-list'>
              {renderModules(
                module.subModules || module.sub_modules, curriculum,
                subject, grade, moduleLevel + 1, activeModule, callback
              )}
            </ul>
          </div>
        ))}
      </>
    );
  }

  return (
      <>
        {modules.map((module:any) => (
          module.subModules?.length || module.sub_modules?.length ? (
            <li key={module.slug} className='curriculum-module-list'>
              <Collapsible title={module.name} showMarkers={true}>
                {/* render overview link if this is last level of module with more than 1 submodules */}
                {module.sub_modules.length > 1 && !(module.sub_modules[0].sub_modules.length) &&
                  <Link
                    onClick={callback}
                    to={`/c/${curriculum}/${subject}/${grade}/${module.slug}`}
                    className={classNames(
                      'curriculum-topic-link',
                      { active: module.slug === activeModule }
                    )}
                  >
                    <span className='curriculum-topic-link-text'>
                      <WrappedMessage message={messages.curriculumModuleOverviewLink} />
                    </span>
                  </Link>
                }
                <ul key={module.slug} className='curriculum-module-list'>
                  {renderModules(
                    module.subModules || module.sub_modules, curriculum,
                    subject, grade, moduleLevel + 1, activeModule, callback
                  )}
                </ul>
              </Collapsible>
            </li>
          ) : (
            <li key={module.slug} className='curriculum-module-item'>
              <Link
                onClick={callback}
                to={`/c/${curriculum}/${subject}/${grade}/${module.slug}`}
                className={classNames(
                  'curriculum-topic-link',
                  { active: module.slug === activeModule }
                )}
              >
                <span className='curriculum-topic-link-text'>{module.name}</span>
              </Link>
            </li>
          )
        ))}
      </>
    );
};
