import { getStore } from 'global/store';
import * as xmljs from 'xml-js';
import {
    GlobalProblemBlockEditorState,
    ProblemResponseType,
    SingleSelectTypeRadio,
} from './models';

type HTMLTransformer = (html: string) => Promise<string>;

export const olxFromAssessmentEditorState = async (transformer?: HTMLTransformer): Promise<string> => {
    const state = getStore().getState().assessmentEditorState;
    const olx = await serializeStateToOlx(state, transformer);
    return olx;
};

export const serializeStateToOlx = async (
    state: GlobalProblemBlockEditorState, transformer?: HTMLTransformer
): Promise<string> => {

    let responseElement: xmljs.Element | null = null;

    if (state.shortAnswers.shortAnswersList.length > 0) {
        responseElement = await stringResponseElementFromState(state, transformer);
    } else if (state.singleSelectAnswers.singleSelectAnswersList.length > 0) {
        if (state.singleSelectAnswers.selectedType.value === SingleSelectTypeRadio.value) {
            responseElement = await multipleChoiceResponseElementFromState(state, transformer);
        } else {
            responseElement = await optionResponseElementFromState(state, transformer);
        }
    } else if (state.multiSelectAnswers.multiSelectAnswersList.length > 0) {
        responseElement = await choiceResponseElementFromState(state, transformer);
    }

    const problemElementAttributes: { [index: string]: any } = {};
    if (state.scorringSettings.selectedAttemptsOption) {
        const maxAttempts = parseInt(state.scorringSettings.selectedAttemptsOption.value);
        if (!isNaN(maxAttempts)) {
            problemElementAttributes['max_attempts'] = maxAttempts.toString();
        }
    }
    if (state.scorringSettings.selectedPointOption) {
        const weight = parseInt(state.scorringSettings.selectedPointOption.value);
        if (!isNaN(weight)) {
            problemElementAttributes['weight'] = weight;
        }
    }

    const problemElementElements: xmljs.Element[] = [];

    if (responseElement) {
        problemElementElements.push(responseElement);
    }

    if (state.hintSettings.hints.length > 0) {
        const hintElements: xmljs.Element[] = [];
        for (const hint of state.hintSettings.hints) {
            const hintElement = await createTextElement('hint', {}, hint.value);
            hintElements.push(hintElement);
        }
        problemElementElements.push({
            name: 'demandhint',
            type: 'element',
            elements: hintElements,
        });
    }

    const problemElement = {
        name: 'olx',
        type: 'element',
        elements: [
            {
                name: state.blockType,
                type: 'element',
                attributes: problemElementAttributes,
                elements: problemElementElements,
            }
        ]
    };

    return serializeElementsToOlx(problemElement);
};

export const areAssessmentEditorRequiredFieldsComplete = (): boolean => {
    const state = getStore().getState().assessmentEditorState;

    switch (state.problemResponseType) {
        // "Multi Select" type assessment - a question and list of possible non-empty answers,
        // must have at least one correct answer, but can have multiple correct answers.
        case ProblemResponseType.ChoiceResponse: {
            return Boolean(
                state.multiSelectEditor.content
                && state.multiSelectAnswers.multiSelectAnswersList.filter(x => x.correct).length > 0
                && !state.multiSelectAnswers.multiSelectAnswersList.some(x => !x.title)
            );
        }

        // "Single Select" type assessment - a question and list of possible
        // non-empty answers. One and only one answer is correct. By default,
        // the labxchange frontend creates a MultipleChoiceResponse, but also
        // supports editing OptionResponse (they are basically the same).
        case ProblemResponseType.OptionResponse:
        case ProblemResponseType.MultipleChoiceResponse: {
            return Boolean(
                state.singleSelectEditor.content
                && state.singleSelectAnswers.singleSelectAnswersList.filter(x => x.correct).length === 1
                && !state.singleSelectAnswers.singleSelectAnswersList.some(x => !x.title)
            );
        }

        // "Short Answer" type assessment - a question and free form text
        // answer. There must be at least one correct text answer. All
        // potential answers must be non-empty.
        case ProblemResponseType.StringResponse: {
            return Boolean(
                state.shortAnswerEditor.content
                && state.shortAnswers.shortAnswersList.filter(x => x.correct).length > 0
                && !state.shortAnswers.shortAnswersList.some(x => x.value === '')
            );
        }

        // This shouldn't happen, but may be possible with custom olx? Either
        // way, if we don't know what it is, we leave it up to the user to
        // validate that all fields are set correctly.
        case ProblemResponseType.UnknownResponse:
        default: {
            return true;
        }
    }
};

const choiceResponseElementFromState = async (
    state: GlobalProblemBlockEditorState, transformer?: HTMLTransformer
): Promise<xmljs.Element> => {

    const choiceResponseAttributes: xmljs.Attributes = {};

    const choiceResponseElements: xmljs.Element[] = [];
    if (state.multiSelectEditor.content) {
        const labelElement = await createTextElement(
            'label', undefined, state.multiSelectEditor.content, transformer
        );
        choiceResponseElements.push(labelElement);
    }

    const checkboxGroupElements: xmljs.Element[] = [];
    choiceResponseElements.push({
        name: 'checkboxgroup',
        type: 'element',
        elements: checkboxGroupElements,
    });

    const multiSelectAnswersList = state.multiSelectAnswers.multiSelectAnswersList;
    if (multiSelectAnswersList && multiSelectAnswersList.length > 0) {

        for (const multiSelectAnswer of multiSelectAnswersList) {
            const choiceElement = await createTextElement(
                'choice',
                {
                    correct: multiSelectAnswer.correct ? 'true' : 'false',
                },
                multiSelectAnswer.title,
            );

            if (choiceElement.elements) {
                if (multiSelectAnswer.selectedFeedback) {
                    const hintElement = await createTextElement(
                        'choicehint',
                        {
                            selected: 'true',
                        },
                        multiSelectAnswer.selectedFeedback,
                    );
                    choiceElement.elements.push(hintElement);
                }
                if (multiSelectAnswer.unselectedFeedback) {
                    const hintElement = await createTextElement(
                        'choicehint',
                        {
                            selected: 'false',
                        },
                        multiSelectAnswer.unselectedFeedback,
                    );
                    choiceElement.elements.push(hintElement);
                }
            }
            checkboxGroupElements.push(choiceElement);
        }
    }

    const generalFeedbackList = state.multiSelectAnswers.generalFeedbackList;
    for (const generalFeedback of generalFeedbackList) {
        const hintElement = await createTextElement(
            'compoundhint',
            {
                correct: generalFeedback.correct ? 'true' : 'false',
            },
            generalFeedback.feedback,
        );
        checkboxGroupElements.push(hintElement);
    }

    return {
        name: ProblemResponseType.ChoiceResponse,
        type: 'element',
        attributes: choiceResponseAttributes,
        elements: choiceResponseElements,
    };
};

const multipleChoiceResponseElementFromState = async (
    state: GlobalProblemBlockEditorState, transformer?: HTMLTransformer
): Promise<xmljs.Element> => {

    const multipleChoiceResponseAttributes: xmljs.Attributes = {};

    const multipleChoiceResponseElements: xmljs.Element[] = [];
    if (state.singleSelectEditor.content) {
        const labelElement = await createTextElement(
            'label', undefined, state.singleSelectEditor.content, transformer
        );
        multipleChoiceResponseElements.push(labelElement);
    }

    const choiceGroupElements: xmljs.Element[] = [];
    multipleChoiceResponseElements.push({
        name: 'choicegroup',
        type: 'element',
        attributes: {
            type: 'MultipleChoice',
        },
        elements: choiceGroupElements,
    });

    const singleSelectAnswersList = state.singleSelectAnswers.singleSelectAnswersList;
    if (singleSelectAnswersList && singleSelectAnswersList.length > 0) {

        for (const singleSelectAnswer of singleSelectAnswersList) {
            const choiceElement = await createTextElement(
                'choice',
                {
                    correct: singleSelectAnswer.correct ? 'true' : 'false',
                    name: singleSelectAnswer.id,
                },
                singleSelectAnswer.title,
            );
            if (singleSelectAnswer.feedback && singleSelectAnswer.feedback.length > 0 && choiceElement.elements) {
                const hintElement = await createTextElement('choicehint', undefined, singleSelectAnswer.feedback);
                choiceElement.elements.push(hintElement);
            }
            choiceGroupElements.push(choiceElement);
        }
    }

    return {
        name: ProblemResponseType.MultipleChoiceResponse,
        type: 'element',
        attributes: multipleChoiceResponseAttributes,
        elements: multipleChoiceResponseElements,
    };
};


const optionResponseElementFromState = async (
    state: GlobalProblemBlockEditorState, transformer?: HTMLTransformer
): Promise<xmljs.Element> => {

    const optionResponseElements: xmljs.Element[] = [];
    if (state.singleSelectEditor.content) {
        const labelElement = await createTextElement(
            'label', undefined, state.singleSelectEditor.content, transformer
        );
        optionResponseElements.push(labelElement);
    }

    const optionInputElements: xmljs.Element[] = [];
    optionResponseElements.push({
        name: 'optioninput',
        type: 'element',
        elements: optionInputElements,
    });


    const singleSelectAnswersList = state.singleSelectAnswers.singleSelectAnswersList;
    if (singleSelectAnswersList && singleSelectAnswersList.length > 0) {

        for (const singleSelectAnswer of singleSelectAnswersList) {
            const optionElement = await createTextElement(
                'option',
                {
                    correct: singleSelectAnswer.correct ? 'true' : 'false',
                },
                singleSelectAnswer.title,
            );
            if (singleSelectAnswer.feedback && singleSelectAnswer.feedback.length > 0 && optionElement.elements) {
                const hintElement = await createTextElement('optionhint', undefined, singleSelectAnswer.feedback);
                optionElement.elements.push(hintElement);
            }
            optionInputElements.push(optionElement);
        }
    }

    return {
        name: ProblemResponseType.OptionResponse,
        type: 'element',
        elements: optionResponseElements,
    };
};

const stringResponseElementFromState = async (
    state: GlobalProblemBlockEditorState, transformer?: HTMLTransformer
): Promise<xmljs.Element> => {
    const stringResponseAttributes: xmljs.Attributes = {
        type: 'ci',
    };
    const stringResponseElements: xmljs.Element[] = [];
    if (state.shortAnswerEditor.content) {
        const labelElement = await createTextElement('label', undefined, state.shortAnswerEditor.content, transformer);
        stringResponseElements.push(labelElement);
    }
    if (state.shortAnswers.shortAnswersList && state.shortAnswers.shortAnswersList.length > 0) {
        const correctAnswers = state.shortAnswers.shortAnswersList.filter((answer: any) => answer.correct);
        const incorrectAnswers = state.shortAnswers.shortAnswersList.filter((answer: any) => !answer.correct);

        const firstShortAnswer = correctAnswers.shift();
        if (firstShortAnswer) {
            if (firstShortAnswer.value) {
                stringResponseAttributes.answer = firstShortAnswer.value;
            }
            if (firstShortAnswer.feedback) {
                const hintElement = await createTextElement('correcthint', undefined, firstShortAnswer.feedback)
                stringResponseElements.push(hintElement);
            }
        }
        for (const shortAnswer of correctAnswers) {
            const answerElement: xmljs.Element = {
                type: 'element',
                name: 'additional_answer',
                attributes: { answer: shortAnswer.value },
                elements: shortAnswer.feedback ? [{
                    type: 'element',
                    name: 'correcthint',
                    elements: [{
                        type: 'text',
                        text: shortAnswer.feedback,
                    }],
                }] : [],
            };
            stringResponseElements.push(answerElement);
        }
        for (const shortAnswer of incorrectAnswers) {
            const hintElement = await createTextElement(
                'stringequalhint', { answer: shortAnswer.value }, shortAnswer.feedback
            );
            stringResponseElements.push(hintElement);
        }
        const textlineElement = await createTextElement('textline', { size: '20' });
        stringResponseElements.push(textlineElement);
    }

    return {
        name: ProblemResponseType.StringResponse,
        type: 'element',
        attributes: stringResponseAttributes,
        elements: stringResponseElements,
    };
};

const createTextElement = async (
    name: string, attributes?: xmljs.Attributes, text?: string, transformer?: HTMLTransformer
) => {
    const element: xmljs.Element = {
        name: name,
        type: 'element',
    };

    if (attributes) {
        element.attributes = attributes;
    }
    if (text) {
        if (transformer) {
            text = await transformer(text);
        }
        element.elements = [{
            text: text,
            type: 'text',
        }];
    }
    return element;
};

const serializeElementsToOlx = (rootElement: xmljs.Element): string => {
    return xmljs.js2xml(rootElement, {
        spaces: 2,
    });
};
