import * as React from 'react';

import { ModalConfirmation } from 'ui/components';
import messages from './displayMessages';

interface Props {
    onDestructiveActionCanceled: () => void;
    onDestructiveActionConfirmed: () => void;
}

export class LeaveClassroomModal extends React.PureComponent<Props, {}> {

    public render() {
        return (
            <ModalConfirmation
                onCanceled={this.props.onDestructiveActionCanceled}
                onConfirmed={this.props.onDestructiveActionConfirmed}
                title={messages.leaveClassTitle}
                body={messages.leaveClassText}
                confirmButtonText={messages.leaveClassLeaveButtonText}
                cancelButtonText={messages.leaveClassCancelButtonText}
            />
        );
    }

}
