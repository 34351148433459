import {getDefaultState} from '../../models';
import {ADVANCED_SETTING_HINT_ADD, ADVANCED_SETTING_HINT_REMOVE, ADVANCED_SETTING_HINT_CHANGED} from '../actions/action-types';

const HintSettingReducer = function(state = getDefaultState().hintSettings, action: any) {
    switch(action.type) {
        case ADVANCED_SETTING_HINT_ADD:
            let newOne = {
                id: Math.random(),
                value: ""
            };
            return Object.assign({}, state, {hints: state.hints.concat([newOne])});
        case ADVANCED_SETTING_HINT_REMOVE:
            return Object.assign({}, state, {
                hints: (state.hints as any[]).filter(hint => hint.id !== action.id)
            });
        case ADVANCED_SETTING_HINT_CHANGED:
            return Object.assign({}, state, {
                hints: (state.hints as any[]).map(hint => {
                    if (hint.id === action.id) {
                        let {id, value} = action;
                        return {
                            id: id,
                            value: value
                        }
                    } else {
                        return hint;
                    }
                })
            });
        default:
            return state;
    }
};

export default HintSettingReducer;