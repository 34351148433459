import * as React from 'react';
import { WrappedMessage, showFilterTabsUi } from 'utils';
import messages from './displayMessages';

interface ResultsCountProps {
    totalCount: number;
    searchQuery?: string;
}

export class ResultsCount extends React.Component<ResultsCountProps> {

    public render() {
        return (
            <WrappedMessage
                message={showFilterTabsUi() ?
                    this.props.searchQuery ? messages.resultsCountTotalWithQuery : messages.resultsCountTotalNew
                    : messages.resultsCountTotal}
                values={{
                    totalCount: this.props.totalCount,
                    searchQuery: this.props.searchQuery,
                }}
            />
        );
    }
}
