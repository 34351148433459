import bind from 'bind-decorator';
import classNames from 'classnames';
import { DiscussionsMessageCreate, ConversationWithMessages, DiscussionAuthor } from 'labxchange-client';
import * as React from 'react';

import { WrappedMessage } from 'utils';
import { intl } from 'i18n';

import messages from './displayMessages';

export interface MessageComposeFormProps {
    recipient: DiscussionAuthor;
    sendMessage: (data: DiscussionsMessageCreate) => void;
    sending?: boolean;
    conversation?: ConversationWithMessages;
    disabled?: boolean;
}

export class MessageComposeForm extends React.Component<MessageComposeFormProps> {
    private inputRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: any) {
        super(props);
        this.inputRef = React.createRef();
    }

    public componentDidUpdate() {
        const inputEl = this.inputRef.current;
        if (inputEl) {
            inputEl.focus();
        }
    }

    public render() {
        const { recipient, sending } = this.props;

        const placeholder = intl.formatMessage(
            messages.composePlaceholder,
            { username: recipient ? recipient.fullName : '' }
        );
        return (
            <form className='message-compose-form'>
                <textarea
                    className='message-compose-form-input form-control'
                    placeholder={placeholder}
                    aria-label={placeholder}
                    ref={this.inputRef}
                    disabled={this.props.disabled}
                    aria-disabled={this.props.disabled}
                    required
                />
                <div className='message-compose-button-wrapper'>
                    <button
                        className='cancel-button'
                        onClick={this.onCancelClick}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={this.onSubmitClick}
                        disabled={this.props.disabled}
                        className={classNames(
                            'btn btn-light send-button',
                            { spinner: sending },
                        )}
                    >
                        <WrappedMessage message={messages.composeSendMessage} />
                    </button>
                </div>
            </form>
        );
    }

    @bind private async onSubmitClick(e: React.MouseEvent) {
        e.preventDefault();

        const input = this.inputRef.current;
        if (input && input.value && this.props.recipient) {
            input.disabled = true;
            let error = false;
            try {
                await this.props.sendMessage({
                    content: input.value,
                    recipient: this.props.recipient.username,
                    conversation: this.props.conversation?.conversation.id,
                });
            } catch (e) {
                error = true;
            }

            input.disabled = false;

            if (!error) {
                input.value = '';
            }
        }
    }

    @bind private onCancelClick(e: React.MouseEvent) {
        e.preventDefault();

        const inputEl = this.inputRef.current;
        if (inputEl) {
            inputEl.value = '';
        }
    }
}
