export const PROBLEM_BLOCK_EDITOR_RESET_STATE = 'PROBLEM_BLOCK_EDITOR_RESET_STATE';

export const MULTI_ADVANCED_SETTINGS_CHANGED = 'MULTI_ADVANCED_SETTINGS_CHANGED';

export const SHORT_ADVANCED_SETTINGS_CHANGED = 'SHORT_ADVANCED_SETTINGS_CHANGED';

export const SINGLE_ADVANCED_SETTINGS_CHANGED = 'SINGLE_ADVANCED_SETTINGS_CHANGED';

export const SINGLE_SELECT_ANSWERS_ADD_NEW = 'SINGLE_SELECT_ANSWERS_ADD_NEW';
export const SINGLE_SELECT_ANSWERS_REMOVE = 'SINGLE_SELECT_ANSWERS_REMOVE';
export const SINGLE_SELECT_ANSWERS_CHANGED = 'SINGLE_SELECT_ANSWERS_RADIO_CHANGED';

export const MULTI_SELECT_ANSWERS_ADD_NEW = 'MULTI_SELECT_ANSWERS_ADD_NEW';
export const MULTI_SELECT_ANSWERS_REMOVE = 'MULTI_SELECT_ANSWERS_REMOVE';
export const MULTI_SELECT_ANSWERS_CHANGED = 'MULTI_SELECT_ANSWERS_RADIO_CHANGED';

export const ANSWER_TYPE_SETTING_CHANGED = 'ANSWER_TYPE_SETTING_CHANGED';

export const ADVANCED_SETTING_HINT_ADD = 'ADVANCED_SETTING_HINT_ADD';
export const ADVANCED_SETTING_HINT_REMOVE = 'ADVANCED_SETTING_HINT_REMOVE';
export const ADVANCED_SETTING_HINT_CHANGED = 'ADVANCED_SETTING_HINT_CHANGED';

export const SCORRING_TEMPTS_CHANGED = 'SCORRING_TEMPTS_CHANGED';
export const SCORRING_POINTS_CHANGED = 'SCORRING_POINTS_CHANGED';

export const GENERAL_FEEDBACK_CHANGE = 'GENERAL_FEEDBACK_CHANGE';

export const SHORT_ANSWERS_ADD_CORRECT = 'SHORT_ANSWERS_ADD_CORRECT';
export const SHORT_ANSWERS_ADD_INCORRECT = 'SHORT_ANSWERS_ADD_INCORRECT';
export const SHORT_ANSWERS_REMOVE = 'SHORT_ANSWERS_REMOVE';
export const SHORT_ANSWERS_CHANGED = 'SHORT_ANSWERS_CHANGED';

export const MULTI_EDITOR_CONTENT_CHANGE = 'MULTI_EDITOR_CONTENT_CHANGE';
export const SINGLE_EDITOR_CONTENT_CHANGE = 'SINGLE_EDITOR_CONTENT_CHANGE';
export const SHORT_ANSWER_EDITOR_CONTENT_CHANGE = 'SHORT_ANSWER_EDITOR_CONTENT_CHANGE';
