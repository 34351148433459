import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleEducatorClassroomsDashboard',
        defaultMessage: 'Classes',
        description: 'Title of the educator dashboard page that lists all of educator\'s own classes.',
    },
    emptyClassroomDashboardTitle: {
        id:'emptyClassroomDashboardTitle',
        defaultMessage: 'You haven\'t created a class yet.',
        description: 'Title for the empty classroom dashboard page.',
    },
    emptyClassroomDashboardSecondaryText: {
        id:'emptyClassroomDashboardSecondaryText',
        defaultMessage: 'Create a class to share learning content privately with a group.',
        description: 'Secondary text for the empty classroom dashboard page.',
    },
    emptyClassroomDashboardAction: {
        id:'emptyClassroomDashboardAction',
        defaultMessage: 'Create class',
        description: 'Button text for the empty classroom dashboard page.',
    },
});

export default messages;
