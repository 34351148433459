import { defineMessages } from 'react-intl';

const messages = defineMessages({
    mailSubscribeEmail: {
        id:'mailSubscribeEmail',
        defaultMessage: 'Email',
        description: 'Text for the place holder in mail subscribe box.',
    },
    mailSubscribe: {
        id:'mailSubscribe',
        defaultMessage: 'Subscribe',
        description: 'Text for the subscribe button in mail subscribe box.',
    },
    footerStayConnectedInfo: {
        id:'footerStayConnectedInfo',
        defaultMessage: 'Sign up for our mailing list',
        description: 'Text for the footer stay connected group text.',
    },
});

export default messages;
