import { defineMessages } from 'react-intl';

const messages = defineMessages({
    discussionsHomePageTitle: {
        id:'discussionsHomePageTitle',
        defaultMessage: 'Discussions',
        description: 'HTML title for the discussions home page.',
    },
    discussionsHomeTitle: {
        id:'discussionsHomeTitle',
        defaultMessage: 'Discussion forum',
        description: 'Title for the Discussions Home breadcrumb.',
    },
    discussionsCategoryPageTitle: {
        id:'discussionsCategoryPageTitle',
        defaultMessage: '{name} - Discussions',
        description: 'HTML title for the discussion category page.',
    },
    discussionsTopicPageTitle: {
        id:'discussionsTopicPageTitle',
        defaultMessage: '{name} - Discussion',
        description: 'HTML title for the discussion topic page.',
    },
    discussionsCategories: {
        id:'discussionsCategories',
        defaultMessage: 'Categories',
        description: 'Title for Discussions home page.',
    },
    discussionsCategoryTopics: {
        id:'discussionsCategoryTopics',
        defaultMessage: 'Topics in {category}',
        description: 'Title for Discussions category page.',
    },
    discussionsAreaTitle: {
        id:'discussionsAreaTitle',
        defaultMessage: 'Discussions',
        description: 'Title for the Discussions Forum.',
    },
    discussionsPostEdit: {
        id:'discussionsPostEdit',
        defaultMessage: 'Edit',
        description: 'Edit button label for post author',
    },
    discussionsPostDelete: {
        id:'discussionsPostDelete',
        defaultMessage: 'Delete',
        description: 'Delete button label for post author',
    },
    discussionsPostAuthor: {
        id:'discussionsPostAuthor',
        defaultMessage: 'Authored by',
        description: 'Screen Reader text for post author',
    },
    discussionsPostNumberOfReply: {
        id:'discussionsPostNumberOfReply',
        defaultMessage: 'Authored by',
        description: 'Screen Reader text for post author',
    },
    discussionsReplyCount: {
        id:'discussionsReplyCount',
        defaultMessage: '{count, plural, =0 {} one {{count, number} Reply} other {{count, number} Replies}}',
        description: 'Number of replies for a post',
    },
    discussionsUserAvatar: {
        id:'discussionsUserAvatar',
        defaultMessage: 'Thread authored by {username}',
        description: 'User specific alt text for thread\'s author image',
    },
    discussionsViewCount: {
        id:'discussionsViewCount',
        defaultMessage: 'View',
        description: 'Number of views for a particular topic',
    },
    actionReport: {
        id:'actionReport',
        defaultMessage: 'Report',
        description: 'Button label that will report this item',
    },
    discussionsThreadShare: {
        id:'discussionsThreadShare',
        defaultMessage: 'Share',
        description: 'Button label to share a thread',
    },
    discussionsThreadLike: {
        id:'discussionsThreadLike',
        defaultMessage: 'Like',
        description: 'Button label to like a thread',
    },
    discussionsThreadLiked: {
        id:'discussionsThreadLiked',
        defaultMessage: 'Liked',
        description: 'Button label to unlike a thread',
    },
    discussionsThreadUnlike: {
        id:'discussionsThreadUnlike',
        defaultMessage: 'Unlike',
        description: 'Button label to unlike a thread',
    },
    discussionsThreadFollow: {
        id:'discussionsThreadFollow',
        defaultMessage: 'Follow',
        description: 'Button label to follow a thread',
    },
    discussionsThreadUnfollow: {
        id:'discussionsThreadUnfollow',
        defaultMessage: 'UnFollow',
        description: 'Button label to unfollow a thread',
    },
    discussionsAllThreads: {
        id:'discussionsAllThreads',
        defaultMessage: 'See all threads',
        description: 'Help text for the link to the all threads view',
    },
    discussionsAddNewThread: {
        id:'discussionsAddNewThread',
        defaultMessage: '+ Add new thread',
        description: 'Button label to add a new thread',
    },
    discussionsAddNewThreadAria: {
        id:'discussionsAddNewThreadAria',
        defaultMessage: 'Add new thread',
        description: 'Screen reader text for the add new thread button',
    },
    discussionsLastThread: {
        id:'discussionsLastThread',
        defaultMessage: 'Last thread',
        description: 'Header label for the date of the previous thread',
    },
    discussionsLastReply: {
        id:'discussionsLastReply',
        defaultMessage: 'Last reply',
        description: 'Header label for the date of the previous reply',
    },
    discussionsViews: {
        id:'discussionsViews',
        defaultMessage: 'Views',
        description: 'Header label for the count of thread views',
    },
    actionPin: {
        id:'actionPin',
        defaultMessage: 'Pin thread',
        description: 'Button label that will pin this item',
    },
    actionUnpin: {
        id:'actionUnpin',
        defaultMessage: 'Unpin thread',
        description: 'Button label that will unpin this item',
    },
    loadCategoryFailed: {
        id:'loadCategoryFailed',
        defaultMessage: 'Failed to load discussion category.',
        description: 'Message displayed when there\'s a failure loading a category.',
    },
    loadSubCategoryFailed: {
        id:'loadSubCategoryFailed',
        defaultMessage: 'Failed to load discussion subcategory.',
        description: 'Message displayed when there\'s a failure loading a subcategory.',
    },
    errorCreatingThread: {
        id:'errorCreatingThread',
        defaultMessage: 'Error while creating thread.',
        description: 'Error message when an error occur while creating discussion thread.',
    },
    errorDeletingThread: {
        id:'errorDeletingThread',
        defaultMessage: 'Error while deleting a thread.',
        description: 'Error message when an error occur while deleting a discussion thread.',
    },
    uiDiscussionsSearch: {
        id:'uiDiscussionsSearch',
        defaultMessage: 'Search discussion threads',
        description: 'Placeholder text for the Discussions Search box.',
    },
    errorUpdatingThread: {
        id:'errorUpdatingThread',
        defaultMessage: 'Error while updating thread.',
        description: 'Error message when an error occur while updating discussion thread.',
    },
    paginationSizeLabel: {
        id:'paginationSizeLabel',
        defaultMessage: 'Show',
        description: 'Help text for the pagination size label.',
    },
    showingResultCounter: {
        id:'showingResultCounter',
        defaultMessage: 'Showing {count, number} {count, plural, one {result} other {results}}',
        description: 'Number of results header.',
    },
    loadFailed: {
        id:'loadThreadFailed',
        defaultMessage: 'There was a problem loading the thread.',
        description: 'Error description when loading fails.',
    },
    sortByLabel: {
        id:'sortByLabel',
        defaultMessage: 'Sort by',
        description: 'Help text for the sortable threads.',
    },
    errorLoadingDiscussion: {
        id:'errorLoadingDiscussion',
        defaultMessage: 'Error while loading discussion.',
        description: 'Error message when an error occur while loading discussion.',
    },
    recentlySort: {
        id: 'recentlySort',
        defaultMessage: 'Recently',
        description: 'Text label on dropdown to sort by recency.',
    },
    popularitySort: {
        id: 'popularitySort',
        defaultMessage: 'Popularity',
        description: 'Text label on dropdown to sort by popularity.',
    },
    threadStatsLikesAria: {
        id: 'threadStatsLikesAria',
        defaultMessage: 'Likes count',
        description: 'Screen-reader text for the number of likes.',
    },
    threadStatsPostsAria: {
        id: 'threadStatsPostsAria',
        defaultMessage: 'Posts count',
        description: 'Screen-reader text for the number of posts.',
    },
    threadStatsViewsAria: {
        id: 'threadStatsViewsAria',
        defaultMessage: 'Views count',
        description: 'Screen-reader text for the number of views.',
    },
    threadStatsParticipantsAria: {
        id: 'threadStatsParticipantsAria',
        defaultMessage: 'Participants count',
        description: 'Screen-reader text for the number of participants.',
    },
    threadPostedDate: {
        id: 'threadPostedDate',
        defaultMessage: 'Posted {date}',
        description: 'Text label for the date a thread was posted.',
    },
});

export default messages;
