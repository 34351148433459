import React from 'react';
import PulseCard from '../PulseCard';

export interface CareerRadioButtonProps {
    id: number;
    selected: boolean;
    disabled?: boolean;
    onClick: (radioId: number) => void;
    otherProps?: React.HTMLProps<HTMLInputElement>;
    children?: React.ReactNode;
    altLabel?: string;
}

const CareerRadioButton: React.FC<CareerRadioButtonProps> = (props) => {
    const labelId = `label${props.id}`;

    const handleClick = () => {
        if (props.disabled) {
            return;
        }

        if (!props.selected) {
            props.onClick(props.id);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleClick();
        }
    };

    return (
        <PulseCard className='radio-pulse'>
            <div
                id={`radioButton-${props.id}`}
                role='radio'
                aria-checked={props.selected}
                aria-disabled={props.disabled}
                tabIndex={0}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                className='career-radio-button'
                aria-labelledby={labelId}
                {...(props.altLabel ? {'aria-label': props.altLabel} : {})}
                {...(props.otherProps ? props.otherProps : {})}
            >
                <div className={`radio-button ${props.selected ? 'selected' : ''}`}>
                    {props.selected && <div className='circle'></div>}
                </div>
                <span id={labelId} className='radio-button-label'>
                    {props.children}
                </span>
            </div>
        </PulseCard>
    );
};

export default CareerRadioButton;
