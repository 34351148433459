import React, { useState } from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import EducationStep from './EducationStep';
import DriverAndImpactStep from './DriverAndImpactStep';
import SubjectsStep from './SubjectsStep';
import InterestsStep from './InterestsStep';

export interface OnboardingProps {
    userStep?: number;
}

export const Onboarding: React.FC<OnboardingProps> = ({userStep=1}) => {
    const [currentStep, setCurrentStep] = useState(userStep);

    const onButtonClick = (nextStep: number) => {
        setCurrentStep(nextStep);
    };

    const renderSelectedStep = () => {
        switch (currentStep) {
            case 1:
                return <SubjectsStep currentStep={currentStep} onButtonClick={onButtonClick} />;
            case 2:
                return <InterestsStep currentStep={currentStep} onButtonClick={onButtonClick} />;
            case 3:
                return <DriverAndImpactStep currentStep={currentStep} onButtonClick={onButtonClick} />;
            case 4:
                return <DriverAndImpactStep currentStep={currentStep} onButtonClick={onButtonClick} isDriverScreen />;
            case 5:
                return <EducationStep currentStep={currentStep} onButtonClick={onButtonClick} />;
            default:
                return <OnboardingPageLayout title='' description='' currentStep={currentStep} onButtonClick={onButtonClick} />;
        }
    };

    return (
        <>
            {renderSelectedStep()}
        </>
    );
};

export default Onboarding;
