import * as React from 'react';
import { Button } from '../Buttons';
import messages from 'auth/components/displayMessages';

interface Props {
    active: boolean;
    onClick?: (newValue: boolean) => void;
}

export const ShowPasswordButton: React.FunctionComponent<Props> = (props) => {
    return (
        <div className={`show-password-button ${props.active ? 'active' : 'inactive'}`}>
            <Button
                btnStyle='link'
                size='sm'
                icon={props.active ? 'eye' : 'eye-closed'}
                iconOnly={true}
                iconFill={`${props.active ? 'white' : '#336589'}`}
                label={messages.lxAuthShowPassword}
                onClick={() => {if(props.onClick) props.onClick(!props.active);}}
            />
        </div>
    );
};
