import { defineMessages } from 'react-intl';

const messages = defineMessages({
    rejoinButtonText: {
        id:'rejoinButtonText',
        defaultMessage: 'Rejoin',
        description: 'label for the button to re-join a classroom from the past classes section of Learner Dashboard',
    },
    archivedLabelText: {
        id:'archivedLabelText',
        defaultMessage: 'Archived',
        description: 'label for the bottom bar of archived classrooms cards on the Learner Dashboard',
    },
    classCodeOverlayText: {
        id:'classCodeOverlayText',
        defaultMessage: 'Class code: {code}',
        description: 'Class code label on Classroom Cards on both Educator and Learner Dashboards',
    },
    unarchiveButtonText: {
        id:'unarchiveButtonText',
        defaultMessage: 'Unarchive',
        description: 'Educator Dashboard Inactive Classroom Card unarchive button text',
    },
    copyMenuText: {
        id:'copyMenuText',
        defaultMessage: 'Copy',
        description: 'Label for the button of a kebab menu item for an active classroom card in educator dashboard',
    },
    shareMenuText: {
        id:'shareMenuText',
        defaultMessage: 'Share',
        description: 'Label for the button of a kebab menu item for an active classroom card in educator dashboard',
    },
    archiveMenuText: {
        id:'archiveMenuText',
        defaultMessage: 'Archive',
        description: 'Label for the button of a kebab menu item for an active classroom card in educator dashboard',
    },
    deleteMenuText: {
        id:'deleteMenuText',
        defaultMessage: 'Delete',
        description: 'Label for the button of a kebab menu item for an active classroom card in educator dashboard',
    },
    cardMetadataLearnerCount: {
        id:'cardMetadataLearnerCount',
        defaultMessage: `{learnerCount, plural,
                         =0 {No Learners}
                         one {{learnerCount, number} Learner}
                         other {{learnerCount, number} Learners}}`,
        description: 'Label for the text in the card metadata section of the active classrooms on Educator Dashboard',
    },
    cardMetadataAssignedItemsCount: {
        id:'cardMetadataAssignedItemsCount',
        defaultMessage: `{itemCount, plural,
                         =0 {No Assigned Items}
                         one {{itemCount, number} Assigned Item}
                         other {{itemCount, number} Assigned Items}}`,
        description: 'Label for assigned items in the metadata section of active classroom cards on Educator Dashboard',
    },
    cardRequestPending: {
        id:'cardRequestPending',
        defaultMessage: 'Request pending',
        description: 'Label for pending request to join a class.',
    },
});

export default messages;
