import bind from 'bind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { ROUTES } from 'global/constants';

import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import { WrappedMessage, showSearchBarInHero } from 'utils';
import messages from './displayMessages';
import { OrganizationSearch } from './OrganizationSearch';
import { LibrarySearchHeader } from '../LibrarySearchHeader';
import { NavTabs } from 'search/components/NavTabs';

interface ReduxStateProps {
    isLoggedIn?: boolean;
}

interface Props extends RouteComponentProps<{}> {}

interface State {
    searchParams: string[];
    showFilterModal: boolean;

}

export class OrganizationSearchPage extends React.PureComponent<Props & ReduxStateProps, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            searchParams: [],
            showFilterModal: false,
        };
    }

    private onToggleFilter = () => {
        this.setState(prevState => ({
            showFilterModal: !prevState.showFilterModal
        }));
    }

    public render() {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.sort(); // stabilize to avoid false positives with changes

        const header = showSearchBarInHero() ? (
            <LibrarySearchHeader
              {...this.props}
              searchTitleUrl={ROUTES.Library.SEARCH_TITLE}
              searchUrl={ROUTES.Library.HOME}
              onToggleFilter={this.onToggleFilter}
              searchParams={searchParams}
            />
        ) :  <NavTabs {...this.props} />;

        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={header}
                searchUrl={ROUTES.Organizations.HOME}
                searchParams={this.state.searchParams}
            >
                <div className='org-search-page'>
                    <h1 className='sr-only'>
                        <WrappedMessage message={messages.pageTitle}/>
                    </h1>
                    <OrganizationSearch
                        onSearchParamsChanged={this.onSearchParamsChanged}
                        searchParams={searchParams.toString()}
                        isModalView={false}
                        showFilterModal={this.state.showFilterModal}
                    />
                </div>
            </StandardPageLayout>
        );
    }

    @bind private onSearchParamsChanged(newParams: URLSearchParams) {
        const newUrl = `${ROUTES.Organizations.HOME}?${newParams.toString()}`;
        this.props.history.push(newUrl);
        this.setState({searchParams: newParams.getAll('t')});
    }
}
