// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ItemStats
 */
export interface ItemStats {
    /**
     * 
     * @type {number}
     * @memberof ItemStats
     */
    favorites: number;
    /**
     * 
     * @type {number}
     * @memberof ItemStats
     */
    views: number;
    /**
     * 
     * @type {number}
     * @memberof ItemStats
     */
    remixes: number;
    /**
     * 
     * @type {number}
     * @memberof ItemStats
     */
    publicRemixes?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemStats
     */
    clones: number;
}

export function ItemStatsFromJSON(json: any): ItemStats {
    return {
        'favorites': json['favorites'],
        'views': json['views'],
        'remixes': json['remixes'],
        'publicRemixes': !exists(json, 'public_remixes') ? undefined : json['public_remixes'],
        'clones': json['clones'],
    };
}

export function ItemStatsToJSON(value?: ItemStats): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'favorites': value.favorites,
        'views': value.views,
        'remixes': value.remixes,
        'public_remixes': value.publicRemixes,
        'clones': value.clones,
    };
}


