// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationResult
 */
export interface OrganizationResult {
    /**
     * 
     * @type {string}
     * @memberof OrganizationResult
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResult
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResult
     */
    largeLogoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResult
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResult
     */
    organizationType: OrganizationResultOrganizationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResult
     */
    clusters: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResult
     */
    pathways: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResult
     */
    contentItems: number;
}

export function OrganizationResultFromJSON(json: any): OrganizationResult {
    return {
        'id': json['id'],
        'slug': json['slug'],
        'name': json['name'],
        'largeLogoUrl': json['large_logo_url'],
        'url': json['url'],
        'organizationType': json['organization_type'],
        'clusters': json['clusters'],
        'pathways': json['pathways'],
        'contentItems': json['content_items'],
    };
}

export function OrganizationResultToJSON(value?: OrganizationResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'name': value.name,
        'large_logo_url': value.largeLogoUrl,
        'url': value.url,
        'organization_type': value.organizationType,
        'clusters': value.clusters,
        'pathways': value.pathways,
        'content_items': value.contentItems,
    };
}

/**
* @export
* @enum {string}
*/
export enum OrganizationResultOrganizationTypeEnum {
    SPONSOR = 'SPONSOR',
    PARTNER = 'PARTNER',
    COMMUNITY = 'COMMUNITY',
    STRATEGICALLY = 'STRATEGIC-ALLY',
    OTHER = 'OTHER'
}


