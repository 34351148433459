import * as React from 'react';

import { LxConfig } from 'global/constants';
import { OrganizationResponse } from 'labxchange-client';

interface Props {
    organizationData: OrganizationResponse;
}

export const OrganizationPeopleDashboard: React.FunctionComponent<Props> = ({
    organizationData,
}) => {
    const perms = organizationData.permissions;
    return <>
        <p>Editing an organization's admin users here is not yet supported.</p>
        <p>
            If you have permission, you can{' '}
            <a href={`${LxConfig.BackendBaseUrl}/admin/library/organization/${organizationData.org.id}/change/`}>
                edit the org's people from the Django admin
            </a>.
        </p>
        <p>Your permissions for {organizationData.org.name}:</p>
        <table className='table'>
            <tbody>
                <tr><th>Can edit content</th><td>{perms.canEditContentOfOrganizationObject ? 'Yes' : 'No'}</td></tr>
                <tr><th>Can edit profile</th><td>{perms.canEditOrganizationProfileObject ? 'Yes' : 'No'}</td></tr>
                <tr><th>Can edit people</th><td>{perms.canEditMembersOfOrganizationObject ? 'Yes' : 'No'}</td></tr>
                <tr>
                    <th>Can view this dashboard and analytics</th>
                    <td>{perms.canViewOrganizationDashboardObject ? 'Yes' : 'No'}</td>
                </tr>
            </tbody>
        </table>
    </>;
};
