import bind from 'bind-decorator';
import { ClassroomsDashboardContent, ExistingClassroom } from 'classrooms/components';
import { ClassroomsApi } from 'global/api';
import { Classroom, ItemMetadata } from 'labxchange-client';
import { default as libraryMessages } from 'library/displayMessages';
import * as React from 'react';
import { Button, Modal, SearchBar, Spinner } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage, showSuccessMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { Tag } from 'ui/components';

interface Props {
    itemMetadata: ItemMetadata;
    onClose(): void;
}

interface State {
    classrooms: Classroom[];
    selectedItems: Classroom[];
    loading: boolean;
    sending: boolean;
    keywords?: string;
}

export default class AssignToClassroomModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            classrooms: [],
            loading: true,
            sending: false,
            selectedItems: [],
        };
    }

    public componentDidMount() {
        this.loadResults('');
    }

    public render() {
        return (
            <Modal
                size='large'
                onRequestClose={this.props.onClose}
                showTopBar={true}
                className='item-assign-modal item-assign-classroom-modal'
                title={
                    <WrappedMessage
                        message={messages.itemAssignToClassroomModalTitle}
                        values={{itemTitle: this.props.itemMetadata.title}}
                    />
                }
                content={
                    <div className='item-assing-classroom-modal-content'>
                        <SearchBar
                            onSubmit={this.setSearchKeywords}
                            searchMessage={messages.itemAssignToClassroomModalSearchText}
                        />
                        { this.state.keywords &&
                            <Tag
                                onClick={this.onTagRemove}
                            >
                                {this.state.keywords}
                            </Tag>
                        }
                        <div className={`${this.state.loading ? 'center-content' : ''}`}>
                            { this.state.loading
                                ? <Spinner />
                                : <ClassroomsDashboardContent
                                    isEducator={true}
                                    activeClassrooms={
                                        this.state.classrooms.filter((c) =>
                                            c.archived === false && c.id !== undefined) as ExistingClassroom[]
                                    }
                                    inactiveClassrooms={[]}
                                    selectedClassrooms={
                                        this.state.selectedItems.filter((c) =>
                                            c.archived === false && c.id !== undefined) as ExistingClassroom[]
                                    }
                                    onSelect={this.onItemSelected}
                                    showSkeleton={this.state.loading}
                                    hideMenus={true}
                                    hideTopControlls={true}
                                />
                            }
                        </div>
                    </div>
                }
                footer={ this.state.selectedItems.length !== 0 ?
                    <div className='modal-bottom-bar'>
                        <Button
                            btnStyle='link'
                            disabled={this.state.sending}
                            onClick={this.props.onClose}
                            label={uiMessages.uiCancelButton}/>
                        <Button
                            btnStyle='primary'
                            disabled={this.state.sending || this.state.selectedItems.length === 0}
                            onClick={this.onUpdate}
                            label={messages.itemAssignToClassroomModalSubmitButton}
                            labelValues={{counter: this.state.selectedItems.length}}/>
                    </div> : undefined
                }
            />
        );
    }

    @bind private async onUpdate() {
        this.setState({sending: true}, async () => {
            const existingItems: Classroom[] = [];
            await Promise.all(this.state.selectedItems.map(async (classroom) => {
                try {
                    const classroomItems = await ClassroomsApi.classroomItemsList(
                        {classroomId: classroom.id},
                    );
                    // Check if this item is already in the classroom
                    const alreadyExists = classroomItems.some(
                        (item) => item.item.metadata.id === this.props.itemMetadata.id,
                    );
                    // If this item is not already presents, add it
                    if (!alreadyExists) {
                        await ClassroomsApi.classroomItemsCreate({
                            data: {
                                item: this.props.itemMetadata.id,
                                classroom: classroom.id!,
                            },
                        });
                    } else {
                        existingItems.push(classroom);
                    }
                } catch (err) {
                    showErrorMessage(
                        <WrappedMessage message={messages.failedToAddItem} />,
                        {exception: err},
                    );
                }

            }));
            this.showSuccessModal(existingItems);
            this.setState({sending: false});
            this.props.onClose();
        });
    }

    private showSuccessModal(existingItems: Classroom[]) {
        showSuccessMessage(<div className='item-assign-success-modal'>
            <WrappedMessage
                message={messages.itemAssignToClassroomModalSuccessText}
                values={{count: this.state.selectedItems.length}}
            />
            { existingItems.length ? <div className='item-assign-success-modal-notice'>
                <WrappedMessage
                    message={messages.itemAssignToClassroomModalAdditionalText}
                    values={{count: existingItems.length}}
                />
                <ul>
                    {existingItems.map((classroom) => (
                        <li key={classroom.id}>{classroom.name}</li>
                    ))}
                </ul>
            </div> : null }
        </div>);
    }

    @bind private onItemSelected(classroom: Classroom) {
        const selectedItems = this.state.selectedItems;
        const index = selectedItems.indexOf(classroom);
        if (index > -1) {
            selectedItems.splice(index, 1);
        } else {
            selectedItems.push(classroom);
        }
        this.setState({selectedItems}, () => this.forceUpdate());
    }

    @bind private async loadResults(keywords: string) {
        try {
            const responseData = await ClassroomsApi.listAsEducator({search: keywords});
            this.setState({classrooms: responseData, loading: false});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={libraryMessages.searchError} />, {exception: err});
            this.setState({classrooms: [], loading: false});
        }
    }

    @bind private setSearchKeywords(keywords?: string) {
        this.setState({loading: true, keywords}, () => this.loadResults(keywords ?? ''));
    }

    @bind private onTagRemove() {
        this.setSearchKeywords(undefined);
    }
}
