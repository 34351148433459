// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModerationRequest,
    ModerationRequestFromJSON,
    ModerationRequestToJSON,
} from '../models';

export interface CreateRequest {
    data: ModerationRequest;
}

/**
 * no description
 */
export class ModerationApi extends runtime.BaseAPI {

    /**
     * Viewset for ModerationRequest model
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling create.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/moderation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModerationRequestToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Viewset for ModerationRequest model
     */
    async create(requestParameters: CreateRequest): Promise<any> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

}
