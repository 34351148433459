import { bind } from 'bind-decorator';
import * as React from 'react';
import { HtmlTextBox, ThinItemSection } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { SpecificEditorProps, TextEditorState } from './models';

type Props = SpecificEditorProps<TextEditorState>;

/**
 * Editor UI for Text components (html)
 */
export class TextBlockEditor extends React.PureComponent<Props> {

    public render() {
        const editorState = this.props.editorState;
        if (editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return (
                <ThinItemSection>
                    <HtmlTextBox
                        extraClassName='text-block-content'
                        label={<WrappedMessage message={messages.textEditorContentField} />}
                        required={true}
                        showErrors={this.props.showErrors}
                        defaultValue={editorState.data}
                        editorStyle={HtmlTextBox.EditorStyle.Full}
                        onChange={this.onDataChange}
                    />
                </ThinItemSection>
            );
        }
    }

    @bind private onDataChange(data: string) {
        this.props.onEditorStateChanged(
            {...this.props.editorState!, data},
        );
    }
}
