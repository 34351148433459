// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AddToTeachingGuide
 */
export interface AddToTeachingGuide {
    /**
     * 
     * @type {string}
     * @memberof AddToTeachingGuide
     */
    itemId: string;
    /**
     * 
     * @type {number}
     * @memberof AddToTeachingGuide
     */
    sectionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddToTeachingGuide
     */
    embed: boolean;
}

export function AddToTeachingGuideFromJSON(json: any): AddToTeachingGuide {
    return {
        'itemId': json['item_id'],
        'sectionId': !exists(json, 'section_id') ? undefined : json['section_id'],
        'embed': json['embed'],
    };
}

export function AddToTeachingGuideToJSON(value?: AddToTeachingGuide): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'item_id': value.itemId,
        'section_id': value.sectionId,
        'embed': value.embed,
    };
}


