import { bind } from 'bind-decorator';
import * as React from 'react';

import { CollapsibleWithChevron } from 'elements/components/Containers';
import { ImageField, TextBox, ThinItemSection, HtmlTextBox } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import {ImageEditorState, SpecificEditorProps} from './models';
import { intl } from 'i18n';
import { MAX_IMAGE_ASSET_SIZE } from 'global/constants';
import { showWarningMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import libraryMessages from 'library/displayMessages';

type Props = SpecificEditorProps<ImageEditorState>;

/**
 * Editor UI for Image components (lx_image)
 */
export class ImageBlockEditor extends React.PureComponent<Props> {

    public render() {
        const editorState = this.props.editorState;
        if (editorState === undefined) {
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            return <>
                {/* The Image */}
                <ThinItemSection>
                    <ImageField
                        required={true}
                        showErrors={this.props.showErrors}
                        currentUrl={editorState.imageUrl || ''}
                        currentFile={editorState.imageFile}
                        // For now we are only allowing uploads, not specifying a URL.
                        // In the future we may turn on allowUrlInput={true} for admins?
                        allowUrlInput={false}
                        onChange={this.onImageChange}
                        maxImageSize={MAX_IMAGE_ASSET_SIZE}
                        onMaxImageSize={this.onMaxImageSize}
                    />
                </ThinItemSection>

                {/* Alt Text */}
                <ThinItemSection>
                    <TextBox
                        label={<WrappedMessage message={messages.imageEditorAltTextField} />}
                        value={editorState.altText}
                        onChange={this.onAltTextChange}
                        placeholder={intl.formatMessage(messages.imageEditorAltTextPlaceholder)}
                        description={<WrappedMessage message={messages.imageEditorAltTextDescription}/>}
                        required={true}
                        showErrors={this.props.showErrors}
                    />
                </ThinItemSection>

                {/* Citation */}
                <ThinItemSection>
                    <TextBox
                        label={<WrappedMessage message={messages.imageEditorCitationField} />}
                        value={editorState.citationText}
                        onChange={this.onCitationChange}
                        placeholder={intl.formatMessage(messages.imageEditorCitationPlaceholder)}
                        description={<WrappedMessage message={messages.imageEditorCitationDescription}/>}
                        required={true}
                        showErrors={this.props.showErrors}
                    />
                </ThinItemSection>

                <ThinItemSection>
                    <div className='advanced-options'>
                        <CollapsibleWithChevron
                            title={messages.imageEditiorAdvancedOptions.defaultMessage}
                            defaultOpen={false}
                        >
                            {/* Caption */}
                            <ThinItemSection>
                                <HtmlTextBox
                                    label={<WrappedMessage message={messages.imageEditorCaptionField} />}
                                    defaultValue={editorState.captionText}
                                    onChange={this.onCaptionChange}
                                    placeholder={intl.formatMessage(messages.imageEditorCaptionPlaceholder)}
                                    editorStyle={HtmlTextBox.EditorStyle.Medium}
                                />
                                <a className='faq-link' href='https://labxchange.zendesk.com/hc/en-us/articles/4407407443095-Creating-an-Image-Asset' target='_blank' rel='noopener noreferrer'>
                                    <WrappedMessage message={messages.imageCaptionFAQ} />
                                </a>
                            </ThinItemSection>

                            {/* Extended Description */}
                            <ThinItemSection>
                                <HtmlTextBox
                                    label={<WrappedMessage message={messages.imageEditorExtendedDescField} />}
                                    onChange={this.onExtendedDescChange}
                                    defaultValue={editorState.extendedDescText}
                                    placeholder={intl.formatMessage(messages.imageEditorExtendedDescPlaceholder)}
                                    editorStyle={HtmlTextBox.EditorStyle.Full}
                                />
                                <a className='faq-link' href='https://labxchange.zendesk.com/hc/en-us/articles/4407407443095-Creating-an-Image-Asset' target='_blank' rel='noopener noreferrer'>
                                    <WrappedMessage message={messages.imageExtendedDescFAQ} />
                                </a>
                            </ThinItemSection>
                        </CollapsibleWithChevron>
                    </div>
                </ThinItemSection>
            </>;
        }
    }

    /**
     * The user has dragged a new image onto the dropzone or entered a URL
     *
     * Note that for now we only store the image data/URL locally in the
     * editorState; it doesn't get uploaded until the user saves the whole
     * form.
     */
    @bind private onImageChange(newFile: File|undefined, newUrl: string) {
        let imageFile;
        let imageUrl;
        if (newFile) {
            imageFile = newFile;
            imageUrl = '';
        } else {
            imageFile = undefined;
            imageUrl = newUrl; // May be '' if the image was cleared/deleted.
        }
        this.props.onEditorStateChanged({...this.props.editorState!, imageFile, imageUrl});
    }

    @bind private onAltTextChange(value: string) {
        this.props.onEditorStateChanged({...this.props.editorState!, altText: value});
    }

    @bind private onCaptionChange(value: string) {
        this.props.onEditorStateChanged({...this.props.editorState!, captionText: value});
    }

    @bind private onCitationChange(value: string) {
        this.props.onEditorStateChanged({...this.props.editorState!, citationText: value});
    }

    private onMaxImageSize() {
        showWarningMessage(intl.formatMessage(
            libraryMessages.maxImageSizeAlertMessage,
            {maxSizeNumber: MAX_IMAGE_ASSET_SIZE}
        ));
    }

    @bind private onExtendedDescChange(value: string) {
        this.props.onEditorStateChanged({...this.props.editorState!, extendedDescText: value});
    }
}
