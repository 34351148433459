import update from 'immutability-helper';

import { ActionTypes, Types } from './actions';
import { PathwayDetail, Subject } from 'labxchange-client';

/**
 * State that holds global data related to finding, viewing, or
 * editing items in the library.
 */
export interface GlobalLibraryState {
    totalPublicAssetCount: string;
    subjectTags: Subject[];
    subjectTagsStatus: 'loaded'|'loading'|'not-loaded'|'error';
    pathway?: PathwayDetail;
    pathwayId?: string;
    displayCongratulation: boolean;
}

const defaultState: GlobalLibraryState = {
    totalPublicAssetCount: '',
    subjectTags: [],
    subjectTagsStatus: 'not-loaded',
    displayCongratulation: false,
};

/**
 * Verify that all pathway items were completed.
 */
const pathwayCompleted = (pathway: PathwayDetail) => {
    const uncompletedPathwayItems = pathway.items.filter(({ item }) => item.userAttributes.completion !== 1);
    return uncompletedPathwayItems.length === 0;
};

/**
 * Reducer that maintains the state of the notifications
 */
export function libraryReducer(
    state: Readonly<GlobalLibraryState> = defaultState,
    action: ActionTypes,
): Readonly<GlobalLibraryState> {
    switch (action.type) {
        case Types.FETCHING_SUBJECT_AREAS:
            return update(state, {subjectTagsStatus: {$set: 'loading'}});
        case Types.FETCHED_SUBJECT_AREAS:
            if (action.success) {
                return update(state, {
                    subjectTagsStatus: {$set: 'loaded'},
                    subjectTags: {$set: action.subjectTags},
                });
            } else {
                return update(state, {subjectTagsStatus: {$set: 'error'}});
            }
        case Types.FETCHED_LIBRARY_COUNT:
            return update(state, {totalPublicAssetCount: {$set: action.totalPublicAssetCount}});
        case Types.LOAD_CURRENT_PATHWAY:
            let displayCongratulation = false;
            if (state.pathwayId === action.id && state.pathway !== undefined) {
                if (!pathwayCompleted(state.pathway) && pathwayCompleted(action.pathway)) {
                    displayCongratulation = true;
                }
            }
            return update(state, {
                pathwayId: {$set: action.id},
                pathway: {$set: action.pathway},
                displayCongratulation: {$set: displayCongratulation},
            });
        case Types.CLOSE_CONGRATULATIONS_MODAL:
            return update(state, {displayCongratulation: {$set: false}});
        default:
            return state;
    }
}
