import bind from 'bind-decorator';
import * as React from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

import { UI_IS_SM } from 'ui/breakpoints';
import { Button, TranscriptLanguageSelector, HtmlTextBox } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { AudioTranscripts } from './models';
import uiMessages from 'ui/components/displayMessages';
import { sanitizeUnsafeHTML } from '@labxchange/util-sanitization';


interface TranscriptProps {
    language: string;
    content: string;
    disabledLanguages: string[];
    onChangeLang: (newLang: string) => void;
    onChangeContent: (newContent: string) => void;
    onDelete?: () => void;
}

interface TranscriptState {
    showEditor: boolean;
    isMobile: boolean;
}

class Transcript extends React.PureComponent<TranscriptProps, TranscriptState> {
    constructor(props: TranscriptProps) {
        super(props);
        this.state = {
            showEditor: false,
            isMobile: false,
        };
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    @bind private onResize() {
        this.setState({
            isMobile: UI_IS_SM.matches,
        });
    }

    public render() {
        const delBtn = this.props.onDelete ?
            <Button
                label={messages.transcriptEditorDeleteButtonLabel}
                onClick={this.props.onDelete}
                icon='trashcan-small'
                btnStyle='link'
                className='del'
                // only show label on mobile layout
                iconOnly={!this.state.isMobile}
            /> : null;

        let components = null;
        if (this.state.showEditor) {
            const done = (
                <Button
                    label={uiMessages.uiDone}
                    onClick={() => this.setState({ showEditor: false })}
                    className='done'
                    btnStyle='primary'
                />
            );
            const editor = (
                <HtmlTextBox
                    extraClassName='editor'
                    label={<WrappedMessage message={messages.audioTrEditContent} />}
                    defaultValue={this.props.content}
                    onChange={this.props.onChangeContent}
                />
            );
            if (this.state.isMobile) {
                components = <>{editor}{done}{delBtn}</>;
            } else {
                components = <><div className='spacer' />{done}{delBtn}{editor}</>;
            }
        } else if (this.props.content === '' && this.props.onDelete === undefined) {
            components = (
                <Button
                    className='edit new'
                    label={messages.audioTrTextEditor}
                    onClick={() => this.setState({ showEditor: true })}
                    icon='text'
                    btnStyle='link'
                />
            );
        } else {
            components = (
                <>
                    {this.props.content &&
                        <HTMLEllipsis
                            className='preview'
                            unsafeHTML={sanitizeUnsafeHTML(this.props.content)}
                            basedOn='letters'
                        />
                    }
                    <Button
                        className='edit'
                        label={uiMessages.uiEdit}
                        onClick={() => this.setState({ showEditor: true })}
                        icon='pencil'
                        btnStyle='link'
                    />
                    {delBtn}
                </>
            );
        }

        return (
            <div className='audio-transcript-editor'>
                <TranscriptLanguageSelector
                    value={this.props.language}
                    onUpdate={this.props.onChangeLang}
                    disabledLanguages={this.props.disabledLanguages}
                    prompt={!this.props.language}
                />
                {components}
            </div>
        );
    }
}

interface Props {
    transcripts: AudioTranscripts;
    onChange: (transcripts: AudioTranscripts) => void;
}

interface State {
    newContent: string;
}

export class AudioTranscriptEditor extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            newContent: '',
        };
    }

    public render() {
        const existingLanguages = Object.keys(this.props.transcripts);

        return (
            <>
                {existingLanguages.map((language) => (
                    <Transcript
                        language={language}
                        content={this.props.transcripts[language].content}
                        disabledLanguages={existingLanguages.filter((x) => x !== language)}
                        onChangeLang={(newLang) => this.onChangeLang(language, newLang)}
                        onChangeContent={(content) => this.onChangeContent(language, content)}
                        onDelete={() => this.onRemove(language)}
                    />
                ))}
                <Transcript
                    language=''
                    content={this.state.newContent}
                    disabledLanguages={existingLanguages}
                    onChangeLang={this.onChangeNewLang}
                    onChangeContent={(newContent) => this.setState({ newContent })}
                    onDelete={this.state.newContent ? () => this.setState({ newContent: '' }) : undefined}
                />
            </>
        );
    }

    private onChangeLang(language: string, newLanguage: string) {
        // When changing the language of an existing transcript, we need to be careful to
        // maintain the insertion order of the transcripts object, so that the transcript field
        // corresponding to the change stays in the same position in the UI.
        const transcripts: AudioTranscripts = {};
        Object.keys(this.props.transcripts).forEach((key) => {
            transcripts[key === language ? newLanguage : key] = this.props.transcripts[key];
        });
        this.props.onChange(transcripts);
    }

    private onChangeContent(language: string, content: string) {
        // Do this update in two stages to ensure we don't mutate data,
        // and we retain the same insertion order.
        const transcripts = {
            ...this.props.transcripts,
        };
        transcripts[language] = {
            ...this.props.transcripts[language],
            content,
        };
        this.props.onChange(transcripts);
    }

    private onRemove(language: string) {
        const transcripts = {
            ...this.props.transcripts,
        };
        delete transcripts[language];
        this.props.onChange(transcripts);
    }

    @bind private onChangeNewLang(newLanguage: string) {
        if (newLanguage) {
            this.props.onChange({
                ...this.props.transcripts,
                [newLanguage]: { type: 'inlinehtml', content: this.state.newContent },
            });
            this.setState({ newContent: '' });
        }
    }
}
