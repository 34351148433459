// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomMembership
 */
export interface ClassroomMembership {
    /**
     * 
     * @type {string}
     * @memberof ClassroomMembership
     */
    readonly id?: string;
    /**
     * 
     * @type {Profile}
     * @memberof ClassroomMembership
     */
    user?: Profile;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMembership
     */
    status?: ClassroomMembershipStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMembership
     */
    feedback: string;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomMembership
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomMembership
     */
    readonly updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassroomMembership
     */
    classroom: string;
}

export function ClassroomMembershipFromJSON(json: any): ClassroomMembership {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : ProfileFromJSON(json['user']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'feedback': json['feedback'],
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
        'updated': !exists(json, 'updated') ? undefined : new Date(json['updated']),
        'classroom': json['classroom'],
    };
}

export function ClassroomMembershipToJSON(value?: ClassroomMembership): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'user': ProfileToJSON(value.user),
        'status': value.status,
        'feedback': value.feedback,
        'classroom': value.classroom,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClassroomMembershipStatusEnum {
    Requested = 'requested',
    Rejected = 'rejected',
    Joined = 'joined',
    Left = 'left'
}


