import { bind } from 'bind-decorator';
import moment from 'moment-shortformat';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { MentorshipsApi } from 'global/api';
import { ItemType } from 'items/models';
import {
    ItemMetadata,
    ItemProgress,
    Mentorship,
    MentorshipItemCreateFromJSON,
    MentorshipStatusEnum,
} from 'labxchange-client';
import { ContentPickerModal } from 'library/components/ContentPickerModal';
import { actionCanMessage, MessageModalState, RenderModals } from 'messages/components';
import {AssignedItemsReportModal} from 'ui/components';
import { showErrorMessage, showSuccessMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { UserCard, UserCardButton } from 'user/components/UserCard';
import { replicateElement, WrappedMessage } from 'utils';
import { StopMentorshipModal } from '../StopMentorshipModal';
import {mentorshipItemsToItemsProgress} from '../../util';
import messages from './displayMessages';
import { EmptyDashboardPage } from 'library/components/EmptyDashboardPage';
import { CollapsibleWithChevron } from 'elements/components/Containers';
import { KebabMenu, KebabMenuItem } from 'ui/components/KebabMenu';
import { skeletonMentorship } from 'skeletons';
import { intl } from 'i18n';

enum LocalModalType {
    None = 0,
    StopMentorship,
    ContentPicker,
    Assignments,
}

interface LocalModalTypeInterface {
    kind: 'local';
    modalType: LocalModalType;
}

interface MessageModalType {
    kind: 'message';
    modalType: MessageModalState;
}

type ModalType = LocalModalTypeInterface | MessageModalType;

interface MentorshipCardDetailsProps {
    mentorship: Mentorship;
    onClickAssign: () => void;
}

class MentorshipCardDetails extends React.PureComponent<MentorshipCardDetailsProps> {
    public render() {
        const mentorship = this.props.mentorship;
        let latestAssignment;
        if (mentorship.items !== undefined && mentorship.items.length >= 1) {
            latestAssignment = mentorship.items[0];
        }
        return (
            <div className='mentorship-card-details'>
                {latestAssignment !== undefined ?
                    <>
                        <div className='assigned'>
                            <WrappedMessage message={messages.assignedLabel}/>
                            : {latestAssignment.item.metadata.title}
                        </div>
                        <div className='assigned-date'>
                            {moment(latestAssignment.created).format('MMMM, DD YYYY')}
                        </div>
                        <div className='completion'>
                            <button
                                className='unstyled see-all-button'
                                onClick={() => this.props.onClickAssign()}
                            >
                                <WrappedMessage message={messages.seeAllAssignmentsButton}/>
                            </button>
                        </div>
                    </>
                : null}
            </div>
        );
    }
}

interface MentorshipCardCompactProps extends RouteComponentProps<{}> {
    mentorship: Mentorship;
    pastMentees?: boolean;
    compact?: boolean;
    onOpenModal: (mentorship: Mentorship, modalType: ModalType) => void;
    openAssignModal: (mentorship: Mentorship) => void;
    showSkeleton?: boolean;
}

class MentorshipCardCompact extends React.PureComponent<MentorshipCardCompactProps> {
    render() {
        const { mentorship, showSkeleton } = this.props;
        return <UserCard
            key={mentorship.id}
            menu={this.renderMenu(mentorship)}
            profile={mentorship.mentee}
            backgroundColor='blue'
            threeColumns={true}
            showSkeleton={showSkeleton}
        />;
    }

    private renderMenu(mentorship: Mentorship) {
        return (
            <KebabMenu
                darkKebab={true}
            >
                <KebabMenuItem
                    iconName='zap'
                    disabled={false}
                    message={messages.progressButton}
                    onClick={() => { this.onClickProgress(mentorship); }}
                />
            </KebabMenu>
        );
    }

    @bind private async onClickProgress(mentorship: Mentorship) {
        this.props.onOpenModal(mentorship,{kind: 'local', modalType: LocalModalType.Assignments});
    }
}

interface MentorshipCardDefaultProps extends RouteComponentProps<{}> {
    mentorship: Mentorship;
    pastMentees?: boolean;
    compact?: boolean;
    onOpenModal: (mentorship: Mentorship, modalType: ModalType) => void;
    onClickMessage: (mentorship: Mentorship) => void;
    openAssignModal: (mentorship: Mentorship) => void;
    showSkeleton?: boolean;
}

class MentorshipCardDefault extends React.PureComponent<MentorshipCardDefaultProps> {
    render() {
        const { mentorship } = this.props;
        const mentee = mentorship.mentee;
        return (
            <UserCard
                key={mentorship.id}
                menu={this.renderMenu(mentorship)}
                profile={mentee}
                extraDetailscomponent={
                    <MentorshipCardDetails
                        mentorship={mentorship}
                        onClickAssign={() => this.props.openAssignModal(mentorship)}
                    />
                }
                accessory={
                    !this.props.pastMentees &&
                    <>
                        <UserCardButton
                            onClick={() => { this.props.onClickMessage(mentorship); }}
                            iconName='message2'
                            label={messages.messageButton}
                            showSkeleton={this.props.showSkeleton}
                        />
                        <UserCardButton
                            onClick={() => { this.props.onOpenModal(
                                mentorship,
                                {kind: 'local', modalType: LocalModalType.ContentPicker},
                            ); }}
                            iconName='assign'
                            label={messages.assignButton}
                            showSkeleton={this.props.showSkeleton}
                        />
                        <UserCardButton
                            onClick={() => { this.onClickProgress(mentorship); }}
                            iconName='zap'
                            label={messages.progressButton}
                            showSkeleton={this.props.showSkeleton}
                        />
                    </>
                }
                threeColumns={true}
                extraClasses={this.props.pastMentees ? ['past-user-card'] : undefined}
                showSkeleton={this.props.showSkeleton}
            />
        );
    }

    private renderMenu(mentorship: Mentorship) {
        return (
            <KebabMenu
                darkKebab={true}
            >
                <KebabMenuItem
                    iconName='trashcan'
                    disabled={false}
                    message={messages.removeButton}
                    onClick={() => { this.onRemove(mentorship); }}
                />
            </KebabMenu>
        );
    }

    @bind private async onClickProgress(mentorship: Mentorship) {
        this.props.onOpenModal(mentorship,{kind: 'local', modalType: LocalModalType.Assignments});
    }

    @bind private async onRemove(mentorship: Mentorship) {
        this.props.onOpenModal(mentorship, {kind: 'local', modalType: LocalModalType.StopMentorship});
    }
}

interface MentorshipCardListProps extends RouteComponentProps<{}> {
    mentorships: Mentorship[];
    pastMentees?: boolean;
    onOpenModal: (mentorship: Mentorship, modalType: ModalType) => void;
    compact?: boolean;
    showSkeleton?: boolean;
}

interface MentorshipCardListState {
    mentorships: Mentorship[];
    modalType?: ModalType;
}

class MentorshipCardListInternal extends React.PureComponent<MentorshipCardListProps, MentorshipCardListState> {
    constructor(props: MentorshipCardListProps) {
        super(props);
        let mentorships = [];
        if (props.showSkeleton) {
            mentorships = replicateElement(skeletonMentorship, 1);
        } else {
            mentorships = props.mentorships;
        }
        this.state = {
            mentorships,
        };
    }

    componentDidUpdate(prevProps: Readonly<MentorshipCardListProps>, prevState: Readonly<MentorshipCardListState>, snapshot?: any) {
        this.setState({mentorships: this.props.mentorships});
    }

    public render() {
        const { mentorships } = this.state;
        if (mentorships.length === 0) { return null; }
        const mentorshipCards = mentorships.map((mentorship) => {
            if (this.props.compact) {
                return <MentorshipCardCompact
                    {...this.props}
                    mentorship={mentorship}
                    openAssignModal={this.openAssignModal}
                    showSkeleton={this.props.showSkeleton}
                />;
            }
            return <MentorshipCardDefault
                {...this.props}
                onClickMessage={this.onClickMessage}
                openAssignModal={this.openAssignModal}
                mentorship={mentorship}
                showSkeleton={this.props.showSkeleton}
            />;
        });
        return (
            <div className='user-cards-list-container mentees-list'>
                {mentorshipCards}
            </div>
        );
    }

    @bind private openAssignModal(mentorship: Mentorship) {
        return this.props.onOpenModal(
            mentorship,
            {kind: 'local', modalType: LocalModalType.Assignments}
        );
    }

    @bind private async onClickMessage(mentorship: Mentorship) {
        const newModalState = await actionCanMessage(
            mentorship.mentee.username, this.props.history,
        );
        this.setState({
            modalType: {kind: 'message', modalType: newModalState},
        });
    }
}

export const MentorshipCardList = withRouter(MentorshipCardListInternal);

interface State {
    isLoading: boolean;
    activeMentorships: Mentorship[];
    stoppedMentorships: Mentorship[];
    modalType: ModalType;
    modalMentorship?: Mentorship;
    mentorshipItems: ItemProgress[];
    modalItemsNavigation: {
        pageCount: number;
        currentPage: number;
    };
}

interface Props extends RouteComponentProps<{}> {
    loggedInUsername: string;
}

class EducatorMenteesDashboardInternal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            activeMentorships: [],
            stoppedMentorships: [],
            mentorshipItems: [],
            modalType: {kind: 'local', modalType: LocalModalType.None},
            modalItemsNavigation: {
                pageCount: 0,
                currentPage: 1,
            }
        };
    }

    public async componentDidMount() {
        await this.fetchMentorships();
    }

    public render() {
        return (
            <div className='educator-mentees-dashboard'>
                <h1 className='sr-only'>
                    <WrappedMessage message={messages.pageTitle}/>
                </h1>
                    {(this.state.activeMentorships.length > 0 || this.state.isLoading) ?
                        <>
                            <div className='description-text'>
                                <WrappedMessage message={messages.menteesDescription}/>
                            </div>
                            <div className='active-mentorships'>
                                <div className='list-header'>
                                    {this.state.isLoading ? <Skeleton/> :
                                        <WrappedMessage
                                            message={messages.showingResultCounter}
                                            values={{count: this.state.activeMentorships.length}}
                                        />
                                    }
                                </div>
                                <MentorshipCardList
                                    mentorships={this.state.activeMentorships}
                                    onOpenModal={this.onOpenModal}
                                    showSkeleton={this.state.isLoading}
                                />
                            </div>
                        </>
                    : this.renderEmptyPage()}
                    {this.state.stoppedMentorships.length > 0 ?
                        <CollapsibleWithChevron
                            title={intl.formatMessage(messages.pastMenteesSection)}
                            headerIcon='learner'
                            defaultOpen={true}
                        >
                            <MentorshipCardList
                                mentorships={this.state.stoppedMentorships}
                                onOpenModal={this.onOpenModal}
                                pastMentees={true}
                                compact={true}
                                showSkeleton={this.state.isLoading}
                            />
                        </CollapsibleWithChevron>
                    : null}
                {this.renderModals()}
            </div>
        );
    }

    @bind public async unassignItem(itemProgress: ItemProgress) {
        const { id } = itemProgress;
        try {
            await MentorshipsApi.mentorshipItemsDelete({ id });
            this.removeItemProgress(id);
        } catch (error) {
            showErrorMessage(<WrappedMessage
                message={messages.unassignContentFailed}
            />, {exception: error});
            return;
        }
    }

    private removeItemProgress(id: string) {
        // Immediately remove item from the Item Progress list.
        const { mentorshipItems } = this.state;
        const index = mentorshipItems.map(x => x.id).indexOf(id);
        mentorshipItems.splice(index, 1);
        this.setState({ mentorshipItems: [...mentorshipItems] });
    }

    @bind private async onOpenModal(mentorship: Mentorship, modalType: ModalType) {
        const { items, page, count } = await MentorshipsApi.items({ id: mentorship.id });
        const mentorshipItems = mentorshipItemsToItemsProgress(items || []);
        this.setState({
            modalType,
            modalMentorship: mentorship,
            mentorshipItems,
            modalItemsNavigation: {
                currentPage: page,
                pageCount: count,
            }
         });
    }

    @bind private onCloseModal() {
        this.setState({
            modalType: {kind: 'local', modalType: LocalModalType.None},
            modalMentorship: undefined,
            mentorshipItems: [],
        });
    }

    @bind private async onSuccessModal(mentorship: Mentorship) {
        await this.fetchMentorships();
        this.onCloseModal();
    }

    @bind private onErrorModal() {
        this.setState({
            modalType: {kind: 'local', modalType: LocalModalType.None},
            modalMentorship: undefined,
            mentorshipItems: [],
        });
        showErrorMessage(<WrappedMessage message={messages.onFailedToStop}/>);
    }

    private renderEmptyPage() {
        return (
            <EmptyDashboardPage
                title={messages.emptyMenteesDashboardTitle}
                secondaryText={messages.emptyMenteesDashboardSecondaryText}
            />
        );
    }

    private renderModals() {
        switch (this.state.modalType.kind) {
            case 'local': {
                switch (this.state.modalType.modalType) {
                    case LocalModalType.StopMentorship: {
                        if (!this.state.modalMentorship) { return; }
                        return (
                            <StopMentorshipModal
                                mentorship={this.state.modalMentorship}
                                byMentor={true}
                                onSuccess={this.onSuccessModal}
                                onError={this.onErrorModal}
                                onClose={this.onCloseModal}/>
                        );
                    }
                    case LocalModalType.ContentPicker: {
                        return (
                            <ContentPickerModal
                                onClose={this.onCloseModal}
                                onItemAdd={this.onItemAdd}
                                excludeTypes={[ItemType.Book, ItemType.Cluster]}
                            />
                        );
                    }
                    case LocalModalType.Assignments: {
                        if (!this.state.modalMentorship) { return; }
                        const mentorship = this.state.modalMentorship;
                        return (
                            <AssignedItemsReportModal
                                user={mentorship.mentee}
                                itemsProgress={this.state.mentorshipItems}
                                completion={mentorship.completion}
                                onClickRemove={() => this.onOpenModal(
                                    mentorship,
                                    {kind: 'local', modalType: LocalModalType.StopMentorship},
                                )}
                                onClose={this.onCloseModal}
                                navigation={this.state.modalItemsNavigation}
                                onNavigationChange={this.onModalItemNavigationChange}
                                unassignItem={this.unassignItem}
                                markAsIncomplete={this.markAsIncomplete}
                            />
                        );
                    }
                    default: {
                        return;
                    }
                }
            }

            case 'message': {
                // It's an external message modal type, so we defer to the
                // Messages modals
                const userProfile = this.state.modalMentorship && this.state.modalMentorship.mentee;
                if (userProfile === undefined) { return; }
                const onClose = this.onCloseModal;
                const history = this.props.history;
                const ownUsername = this.props.loggedInUsername;
                const modalType = this.state.modalType.modalType;

                return RenderModals({
                    userProfile,
                    onClose,
                    history,
                    ownUsername,
                    modalType,
                });
            }
        }
    }

    @bind private async onModalItemNavigationChange(currentPage: number) {
        const { items, page, count } = await MentorshipsApi.items({ id: this.state.modalMentorship!.id, currentPage });
        this.setState({
            mentorshipItems: mentorshipItemsToItemsProgress(items || []),
            modalItemsNavigation: {
                currentPage: page,
                pageCount: count,
            }
         });
    }

    private async fetchMentorships() {
        let mentorships = [];
        try {
            mentorships = await MentorshipsApi.list({
                mentor: this.props.loggedInUsername,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.failedToFetchMentorships} />, {
                exception: err,
                details: `Unable to fetch Mentorships for ${`this.props.loggedInUsername`}`,
            });
            return;
        }

        const activeMentorships = mentorships.filter(x => x.status === MentorshipStatusEnum.Accepted);
        const stoppedMentorships = mentorships.filter(
            x => [MentorshipStatusEnum.StoppedByMentor, MentorshipStatusEnum.StoppedByMentee].includes(x.status)
        );
        // TODO: display pending mentorships here somewhere?
        this.setState({ activeMentorships, stoppedMentorships, isLoading: false});
    }

    @bind private async markAsIncomplete(item: ItemProgress) {
        await MentorshipsApi.markIncomplete({ id: item.id, data: {} });

        const mentorshipItems = [...this.state.mentorshipItems];
        const index = mentorshipItems.map(x => x.id).indexOf(item.id);
        mentorshipItems[index] = {...mentorshipItems[index], completion: 0};
        this.setState({ mentorshipItems: [...mentorshipItems] });
    }

    @bind private async onItemAdd(item: ItemMetadata) {
        try {
            const data = MentorshipItemCreateFromJSON({
                item: item.id,
                mentorship: this.state.modalMentorship!.id,
            });
            await MentorshipsApi.mentorshipItemsCreate({ data });
            showSuccessMessage(<WrappedMessage message={messages.assignItemSuccess}/>);
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.failedToAddItem} />, {
                exception: err,
                details: `Unable to add ItemMetadata ${`item.id`} to Classroom ${`this.props.classroom.id`}`,
            });
        }
        this.onCloseModal();
        this.fetchMentorships();
    }
}

export const EducatorMenteesDashboard = withRouter(EducatorMenteesDashboardInternal);
