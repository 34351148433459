import * as React from 'react';

export function isKeyboardEvent(event: any): event is React.KeyboardEvent<any> {
    return event.nativeEvent && event.nativeEvent instanceof KeyboardEvent;
}

export function isKeyboardEnterEvent(event: any): event is React.KeyboardEvent<any> {
    return isKeyboardEvent(event) && (event as React.KeyboardEvent).key === 'Enter';
}

export function isMouseEvent(event: any): event is React.MouseEvent<any> {
    return event.nativeEvent && event.nativeEvent instanceof MouseEvent;
}

export function isMouseOrEnterEvent(event: any): boolean {
    return isKeyboardEnterEvent(event) || isMouseEvent(event);
}

export function clipboardCopy(text?: string) {
    if (!text) { return; }
    const tf = document.createElement('textarea');
    tf.innerText = text;
    document.body.appendChild(tf);
    tf.select();
    document.execCommand('copy');
    tf.remove();
}

let lastId = 0;

export function generateID(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}

/**
 * Simple random number generator
 * ref: https://stackoverflow.com/a/19303725/4302112
 *
 * seed: any number, avoid 0 or multiples of pi
 */
export function random(seed: number): number {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}
