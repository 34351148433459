// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SearchTitle
 */
export interface SearchTitle {
    /**
     * 
     * @type {string}
     * @memberof SearchTitle
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTitle
     */
    title: string;
}

export function SearchTitleFromJSON(json: any): SearchTitle {
    return {
        'id': json['id'],
        'title': json['title'],
    };
}

export function SearchTitleToJSON(value?: SearchTitle): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
    };
}


