import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headerResultCounter: {
        id:'headerResultCounter',
        defaultMessage: `{count, plural,
                         =0 {No result}
                         one {Showing {count, number} result}
                         other {Showing {count, number} results}}`,
        description: 'Number of memberships in classroom counter.',
    },
    activeLearnersCounter: {
        id: 'activeLearnersCounter',
        defaultMessage: `{count, plural,
                         =0 {No learners}
                         one {{count, number} Learner}
                         other {{count, number} Learners}}`,
        description: 'Number of active learners in classroom',
    },
    ascendingSortLabel: {
        id: 'ascendingSortLabel',
        defaultMessage: 'A-Z First name',
        description: 'Label for sorting the first names alphabetically.'
    },
    descendingSortLabel: {
        id: 'descendingSortLabel',
        defaultMessage: 'Z-A First name',
        description: 'Label for sorting by first name alphabetically in reverse'
    },
    searchLearnersPlaceholder: {
        id: 'searchLearnersPlaceholder',
        defaultMessage: 'Search learners',
        description: 'Placeholder for search learners input'
    },
    searchLearnersPlaceholderMobile: {
        id: 'searchLearnersPlaceholderMobile',
        defaultMessage: 'Search',
        description: 'Placeholder for search learners input on mobile'
    },
    showMoreResults: {
        id:'showMoreResults',
        defaultMessage: 'Show more results',
        description: 'Text for the infinite scroll button.',
    },
    removeLearnerButton: {
        id: 'removeLearnerButton',
        defaultMessage: 'Remove',
        description: 'Button to remove a class learner.',
    },
    messageButton: {
        id:'messageButton',
        defaultMessage: 'Message',
        description: 'Send a message button.',
    },
    progressButton: {
        id:'progressButton',
        defaultMessage: 'Progress',
        description: 'Button to view a learner\'s progress.',
    },
    assignContentButton: {
        id:'assignContentButton',
        defaultMessage: 'Assign',
        description: 'Button to assign content to a learner.',
    },
    acceptAllMembershipsLabel: {
        id:'acceptAllMembershipsLabel',
        defaultMessage: 'Approve all requests',
        description: 'Button to accept every membership requests.',
    },
    acceptAllMembershipsModalTitle: {
        id:'acceptAllMembershipsModalTitle',
        defaultMessage: 'Bulk approve requests',
        description: 'Modal title to accept every membership requests.',
    },
    acceptAllMembershipsModalBody: {
        id:'acceptAllMembershipsModalBody',
        defaultMessage: 'All pending requests to enroll in your class will be approved. You can remove learners at any time.',
        description: 'Modal body to accept every membership requests.',
    },
    acceptAllMembershipsModalConfirmLabel: {
        id:'acceptAllMembershipsModalConfirmLabel',
        defaultMessage: 'Approve requests',
        description: 'Modal confirm button to accept every membership requests.',
    },
    removeMembershipModalTitle: {
        id:'removeMembershipModalTitle',
        defaultMessage: 'Remove learner',
        description: 'Remove learner modal title.',
    },
    removeMembershipModalBody: {
        id:'removeMembershipModalBody',
        defaultMessage: 'Are you sure you would like to remove learner {fullName} from the class?',
        description: 'Remove learner modal body.',
    },
    assignItemSuccess: {
        id:'assignItemSuccess',
        defaultMessage: 'You have assigned content to {fullName}.',
        description: 'Message shown when items have been successfully assigned to a learner.',
    },
        errorMessageUpdateMembership: {
        id:'errorMessageUpdateMembership',
        defaultMessage: 'Cannot update this membership. Please try again later.',
        description: 'Error message shown when the system cannot update a membership.',
    },
    errorMessageRemoveMembership: {
        id:'errorMessageRemoveMembership',
        defaultMessage: 'Cannot remove this membership. Please try again later.',
        description: 'Error message shown when the system cannot remove a membership.',
    },
    errorMessageFetchMemberships: {
        id:'errorMessageFetchMemberships',
        defaultMessage: 'Cannot retrieve classroom memberships. Please try again later.',
        description: 'Error message shown when the system cannot retrieve user memberships.',
    },
    errorMessageFetchLearnerProgress: {
        id:'errorMessageFetchLearnerProgress',
        defaultMessage: 'Cannot retrieve learner progress. Please try again later.',
        description: 'Error message shown when the system cannot retrieve learner progress.',
    },
    errorAssignItem: {
        id:'errorAssignItem',
        defaultMessage: 'Cannot assign items to learner. Please try again later.',
        description: 'Error message shown when the system cannot assign items to a learner.',
    },
});

export default messages;
