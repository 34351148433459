import { defineMessages } from 'react-intl';

const messages = defineMessages({
    followThread: {
        id:'followThread',
        defaultMessage: 'Follow thread',
        description: 'Button label to follow a thread.',
    },
    unfollowThread: {
        id:'unfollowThread',
        defaultMessage: 'Unfollow thread',
        description: 'Button label to unfollow a thread.',
    },
    unfollowingThread: {
        id:'unfollowingThread',
        defaultMessage: 'Unfollowing thread',
        description: 'Button label to unfollow a thread.',
    },
    postReply: {
        id:'postReply',
        defaultMessage: 'Reply',
        description: 'Reply button text.',
    },
    postThreadReply: {
        id:'postThreadReply',
        defaultMessage: 'Reply to thread',
        description: 'Reply button text.',
    },
    deleteThreadPostModalTitle: {
        id:'deleteThreadPostModalTitle',
        defaultMessage: 'Delete post',
        description: 'Delete post confirmation modal title.',
    },
    deleteThreadPostModalConfirm: {
        id:'deleteThreadPostModalConfirm',
        defaultMessage: 'Delete',
        description: 'Delete post confirmation modal button.',
    },
    deleteThreadPostModalBody: {
        id:'deleteThreadPostModalBody',
        defaultMessage: 'Are you sure you want to delete this post?',
        description: 'Delete post confirmation modal body.',
    },
    deleteThreadPostModalCancel: {
        id:'deleteThreadPostModalCancel',
        defaultMessage: 'Cancel',
        description: 'Delete post confirmation modal cancel button.',
    },
    errorLoadingDiscussion: {
        id:'errorLoadingDiscussion',
        defaultMessage: 'Error while loading discussion.',
        description: 'Error message when an error occur while loading discussion.',
    },
    errorCreateThreadPost: {
        id:'errorCreateThreadPost',
        defaultMessage: 'Error while creating class post.',
        description: 'Error message when an error occur while creating discussion post.',
    },
    errorUpdateThreadPost: {
        id:'errorUpdateThreadPost',
        defaultMessage: 'Error while updating class post.',
        description: 'Error message when an error occur while updating discussion post.',
    },
    errorDeleteThreadPost: {
        id:'errorDeleteThreadPost',
        defaultMessage: 'Error while deleting class post.',
        description: 'Error message when an error occur while deleting discussion post.',
    },
    errorLikeThread: {
        id:'errorLikeThread',
        defaultMessage: 'Error while liking thread. Please try again later.',
        description: 'Error message when an error occur while liking discussion thread.',
    },
    errorFollowThread: {
        id:'errorFollowThread',
        defaultMessage: 'Error while following thread. Please try again later.',
        description: 'Error message when an error occur while following discussion thread.',
    },
    errorDeleteThread: {
        id:'errorDeleteThread',
        defaultMessage: 'Error while deleting thread.',
        description: 'Error message when an error occur while deleting a global discussion thread.',
    },
    errorDeleteClassroomThread: {
        id:'errorDeleteClassroomThread',
        defaultMessage: 'Error while deleting class thread.',
        description: 'Error message when an error occur while deleting a classroom discussion thread.',
    },
    deleteThreadModalTitle: {
        id:'deleteThreadModalTitle',
        defaultMessage: 'Delete thread',
        description: 'Delete thread confirmation modal title.',
    },
    deleteThreadModalBody: {
        id:'deleteThreadModalBody',
        defaultMessage: 'Are you sure you want to delete this thread?',
        description: 'Delete thread confirmation modal body.',
    },
    emptyDiscussionAction: {
        id:'emptyDiscussionAction',
        defaultMessage: 'Start conversation',
        description: 'Button text for an empty asset discussion.',
    },
    emptyDiscussionTitle: {
        id:'emptyDiscussionTitle',
        defaultMessage: 'Be the first to have a say!',
        description: 'Title for an empty asset discussion.',
    },
});

export default messages;
