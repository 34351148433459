// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CurriculumSubject,
    CurriculumSubjectFromJSON,
    CurriculumSubjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface CurriculumsList
 */
export interface CurriculumsList {
    /**
     * 
     * @type {string}
     * @memberof CurriculumsList
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumsList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumsList
     */
    country: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurriculumsList
     */
    isPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurriculumsList
     */
    readonly firstModuleLink?: string;
    /**
     * 
     * @type {Array<CurriculumSubject>}
     * @memberof CurriculumsList
     */
    subjects: Array<CurriculumSubject>;
}

export function CurriculumsListFromJSON(json: any): CurriculumsList {
    return {
        'slug': json['slug'],
        'name': json['name'],
        'country': json['country'],
        'isPublic': !exists(json, 'is_public') ? undefined : json['is_public'],
        'firstModuleLink': !exists(json, 'first_module_link') ? undefined : json['first_module_link'],
        'subjects': (json['subjects'] as Array<any>).map(CurriculumSubjectFromJSON),
    };
}

export function CurriculumsListToJSON(value?: CurriculumsList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'name': value.name,
        'country': value.country,
        'is_public': value.isPublic,
        'subjects': (value.subjects as Array<any>).map(CurriculumSubjectToJSON),
    };
}


