/**
 * Code for converting the "edit XBlock" API JSON data format
 * to/from the EditorState models used by our UI.
 */
import {olxFromAssessmentEditorState} from 'assessment-editor';
import { ItemsApi } from 'global/api';
import { EditorState, XBlockType } from './models';
import { uploadTranscripts } from './TranscriptEditor';
import { ItemMetadataFromJSON, ItemResponseFromJSON } from 'labxchange-client';

interface Transcript {
    filename?: string;
    content?: string;
}

// Process the transcripts from the transcript editor.
// This will be a combination of strings and Files.
// If a string: it's the filename for a transcript that hasn't been changed
// If a File: it's a newly uploaded transcript file
// Here we convert this to a data structure that can be consumed more easily by the backend
async function processTranscripts(
    transcripts: {
        [language: string]: File | string;
    }
): Promise<{[language: string]: Transcript}> {
    const fixedTranscripts: {[language: string]: Transcript} = {};
    for (const language of Object.keys(transcripts)) {
        const transcript = transcripts[language];
        if (transcript instanceof Blob) {
            fixedTranscripts[language] = {
                content: await transcript.text(),
            };
        } else {
            fixedTranscripts[language] = {
                filename: transcript,
            };
        }
    }
    return fixedTranscripts;
}

/**
 * Given the JSON data from ItemsApi.editXblock, create the EditorState
 * for rendering the edit UI with React.
 *
 * @param blockType What type of XBlock we're editing
 * @param data The data returned by the ItemsApi.editXblock API
 */
export function editApiResponseToEditorState(blockType: XBlockType, data: any): EditorState {
    switch (blockType) {
        case XBlockType.AnnotatedVideo:
            return {
                blockType: XBlockType.AnnotatedVideo,
                youTubeId: data.youtube_id_1_0,
                html5Sources: data.html5_sources,
                transcripts: data.transcripts,
                transcripts_urls: data.transcripts_urls,
                item: ItemMetadataFromJSON(data.item),
                annotations: data.annotations,
            };
        case XBlockType.Assignment:
            return {
                blockType: XBlockType.Assignment,
                assessments: data.assessments,
            };
        case XBlockType.Audio:
            return {
                blockType: XBlockType.Audio,
                embedCode: data.embed_code,
                audioTranscripts: data.transcripts,
            };
        case XBlockType.Document:
            return {
                blockType: XBlockType.Document,
                documentFile: undefined,
                documentUrl: data.document_url,
                originalDocumentUrl: data.document_url,
            };
        case XBlockType.Link:
            return {
                blockType: XBlockType.Link,
                documentUrl: data.document_url,
            };
        case XBlockType.Html:
            return {
                blockType: XBlockType.Html,
                data: data.data,
            };
        case XBlockType.Narrative:
            return {
                blockType: XBlockType.Narrative,
                narrative: data.narrative,
                keyPoints: data.key_points,
            };
        case XBlockType.Image:
            return {
                blockType: XBlockType.Image,
                imageFile: undefined,
                imageUrl: data.image_url,
                originalImageUrl: data.image_url,
                altText: data.alt_text,
                citationText: data.citation,
                captionText: data.caption,
                extendedDescText: data.extended_desc,
            };
        case XBlockType.Problem:
            return {
                blockType: XBlockType.Problem,
                olx: data.olx,
                isEditable: true,
            };
        case XBlockType.Video:
            return {
                blockType: XBlockType.Video,
                youTubeId: data.youtube_id_1_0,
                html5Sources: data.html5_sources,
                transcripts: data.transcripts,
                transcripts_urls: data.transcripts_urls,
            };
        case XBlockType.LXVideo:
            return {
                blockType: XBlockType.LXVideo,
                youTubeId: data.youtube_id_1_0,
                html5Sources: data.html5_sources,
                transcripts: data.transcripts,
                transcripts_urls: data.transcripts_urls,
            };
        case XBlockType.CaseStudy:
        case XBlockType.TeachingGuide:
            return {
                blockType,
                sections: data.sections.map((section: any, index: number) => {
                    // need to map with ItemResponseFromJSON to transform snake_case to camelCase.
                    return {
                        ...section,
                        children: section.children.map((x: any) => ({
                            ...x,
                            item: x.item === undefined ? undefined : ItemResponseFromJSON(x.item),
                        })),
                        expanded: Boolean(index === 0), // only expand the first section by default
                    };
                }),
                attachments: data.attachments.map(ItemResponseFromJSON),
            };
        case XBlockType.Question:
            return {
                blockType: XBlockType.Question,
                olx: data.olx,
                isEditable: true,
            };
        default:
            throw new Error('Unsupported XBlock type.');
    }
}

/**
 * Given the EditorState from a user editing this XBlock, prepare the
 * data that we need to send to the server to save the changes.
 *
 * Warning: this is not a pure function!
 * It makes api requests for some types, and reads from assessment editor global state.
 *
 * For video and annotated video types, this must be called _after_ the related itemmetadatas are created,
 * otherwise it will try uploading transcript files to non-existant items.
 */
export async function editorStateToApiRequest(editorState: EditorState, xblockId: string): Promise<any> {
    switch (editorState.blockType) {
        case XBlockType.Audio:
            return {
                transcripts: editorState.audioTranscripts,
                embed_code: editorState.embedCode,
            };
        case XBlockType.Image:
            let imageUrl = editorState.originalImageUrl;
            if (editorState.imageFile) {
                // Upload the file to Blockstore:
                imageUrl = (await ItemsApi.editXblockSaveAsset({
                    id: xblockId,
                    blockKey: xblockId,
                    filename: editorState.imageFile.name,
                    file: editorState.imageFile,
                })).url;
            } else if (editorState.imageUrl !== editorState.originalImageUrl) {
                imageUrl = editorState.imageUrl;
            }
            return {
                alt_text: editorState.altText,
                caption: editorState.captionText,
                citation: editorState.citationText,
                extended_desc: editorState.extendedDescText,
                image_url: imageUrl,
            };
        case XBlockType.Document:
            let documentUrl = editorState.documentUrl;
            if (editorState.documentFile) {
                // Upload the file to Blockstore:
                documentUrl = (await ItemsApi.editXblockSaveAsset({
                    id: xblockId,
                    blockKey: xblockId,
                    filename: editorState.documentFile.name,
                    file: editorState.documentFile,
                })).url;
            } else if (editorState.documentUrl !== editorState.originalDocumentUrl) {
                documentUrl = editorState.documentUrl;
            }
            const documentName: string|undefined = getDocumentName(editorState.documentFile, documentUrl);
            return {
                document_type: 'application/pdf',
                document_url: documentUrl,
                document_name: documentName,
            };
        case XBlockType.Link:
            return {
                document_url: editorState.documentUrl,
            };
        case XBlockType.Html:
            const data = await uploadLocalImages(xblockId, editorState.data);
            return {data};
        case XBlockType.Narrative:
            const narrative = await uploadLocalImages(xblockId, editorState.narrative);
            return {
                narrative,
                key_points: editorState.keyPoints,
            };
        case XBlockType.Problem:
            if (editorState.isEditable) {
                const olx = await olxFromAssessmentEditorState(uploadLocalImages.bind(null, xblockId));
                return {olx};
            } else {
                // If the block is not editable using the visual editor, keep the same OLX.
                return {olx: editorState.olx};
            }
        case XBlockType.Video:
            return {
                youtube_id_1_0: editorState.youTubeId,
                html5_sources: editorState.html5Sources,
                transcripts: await uploadTranscripts(editorState, xblockId),
            };
        case XBlockType.LXVideo:
            return {
                youtube_id_1_0: editorState.youTubeId,
                html5_sources: editorState.html5Sources,
                transcripts: await uploadTranscripts(editorState, xblockId),
            };
        case XBlockType.AnnotatedVideo:
            return {
                youtube_id_1_0: editorState.youTubeId,
                html5_sources: editorState.html5Sources,
                transcripts: await processTranscripts(editorState.transcripts),
                added_annotations: editorState.addedAnnotations,
                updated_annotations: editorState.updatedAnnotations,
                deleted_annotations: editorState.deletedAnnotations,
            };
        case XBlockType.Assignment:
            return {
                // Return just an ordered list of the IDs of the assessments
                assessments: editorState.assessments.map((assessment) => assessment.id),
            };
        case XBlockType.CaseStudy:
        case XBlockType.TeachingGuide:
            return {
                ...editorState,
                sections: await Promise.all(editorState.sections.map(async section => ({
                    ...section,
                    children: await Promise.all(section.children.map(async child => {
                        if (child.type === 'inline') {
                            return {
                                type: 'inline',
                                inlinehtml: await uploadLocalImages(xblockId, child.inlinehtml),
                            };
                        } else {
                            return child;
                        }
                    })),
                }))),
            };
        case XBlockType.Question:
            return {
                olx: await olxFromAssessmentEditorState(uploadLocalImages.bind(null, xblockId)),
            };
        default:
            throw new Error('Unsupported XBlock type.');
    }
}

function getDocumentName(documentFile?: File, documentUrl?: string|null): string|undefined {
    if (documentFile) {
        return documentFile.name;
    } else if (documentUrl) {
        return new URL(documentUrl).pathname.split('/').pop();
    }
    return undefined;
}

async function uploadLocalImages(xblockId: string, html: string): Promise<string> {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    // Scan for data-uri images.
    const dataImages: HTMLImageElement[] = Array.from(doc.querySelectorAll('img[src^="data:"]'));
    for (const img of dataImages) {
        const filename = img.dataset.lxUploadFilename || `image-${Date.now()}`;
        const blob = await fetch(img.src).then(r => r.blob());
        const {url} = await ItemsApi.editXblockSaveAsset({
            id: xblockId,
            blockKey: xblockId,
            filename,
            file: blob,
        });
        img.src = url;
        delete img.dataset.lxUploadFilename;
    }
    return doc.body.innerHTML;
}
