import { getEmbedCode } from 'items/youTubeApi';
import * as React from 'react';
import { ThinItemSection } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { ItemSection } from 'ui/components/ItemSection';
import { WrappedMessage } from 'utils';
import displayMessages from './displayMessages';
import { SpecificEditorProps, VideoEditorState } from './models';
import { TranscriptEditors } from './TranscriptEditor';
import { intl } from 'i18n';
import { RichText } from 'elements/components/RichText';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';

type VideoBlockEditorProps = SpecificEditorProps<VideoEditorState>;

interface VideoBlockEditorState {
    youTubeEmbedHtml?: string;
}

/**
 * Editor UI for Video components (video)
 */
export class VideoBlockEditor extends React.PureComponent<VideoBlockEditorProps, VideoBlockEditorState> {

    constructor(props: VideoBlockEditorProps) {
        super(props);
        this.state = {
            youTubeEmbedHtml: undefined,
        };
    }

    public componentDidMount() {
        if (this.props.editorState && this.props.editorState.youTubeId) {
            this.fetchYouTubeEmbedCode(this.props.editorState.youTubeId);
        }
    }

    public componentDidUpdate(prevProps: VideoBlockEditorProps) {
        const prevYouTubeId = prevProps.editorState && prevProps.editorState.youTubeId;
        const newYouTubeId = this.props.editorState && this.props.editorState.youTubeId;
        if (newYouTubeId && prevYouTubeId !== newYouTubeId) {
            this.fetchYouTubeEmbedCode(newYouTubeId);
        }
    }

    public render() {
        const editorState = this.props.editorState;
        const transcriptError = (this.props.itemMetadata?.isPublic && this.props.showErrors
                                 && Object.keys(editorState?.transcripts ?? []).length === 0)
                                 || (this.props.showWarnings
                                     && Object.keys(editorState?.transcripts ?? []).length === 0);
        if (editorState === undefined) {
            // if no state, then we're still loading
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else if (!editorState.youTubeId && (editorState.html5Sources.length === 0)) {
            // if we don't have either video source type, then we don't render anything
            return null;
        } else if (editorState.youTubeId && !this.state.youTubeEmbedHtml) {
            // Ok, here we have a youtube video, but no embed html yet, so we're still loading.
            // We check for falsey/truthy values here because sometimes they
            // are undefined, and other times they are empty strings.
            return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
        } else {
            // finally here, we are finished loading, and we either have a
            // youtube or a html5 video.
            return (
                <>
                    {this.state.youTubeEmbedHtml ?
                        <ThinItemSection>
                            <div
                                className='youtube-video-embed'
                                style={{paddingBottom: `${this.getVideoAspectRatio() * 100}%`}}
                            >
                                <RichText
                                    innerHtml={this.state.youTubeEmbedHtml}
                                    sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLEmbeded}
                                />
                            </div>
                        </ThinItemSection>
                    :
                        <ItemSection title='Video' sectionName='video'>
                            <div>
                                <WrappedMessage message={displayMessages.videoEditorEmbedOkayMessage} />
                            </div>
                        </ItemSection>
                    }
                    <ItemSection
                        title={intl.formatMessage(uiMessages.transcriptEditorTranscriptsSectionLabel)}
                        sectionName='transcript'
                        extraClasses={`transcripts ${transcriptError ? 'item-section-error error-all-section' : ''}`}
                        isMandatory={this.props.itemMetadata?.isPublic}
                    >
                        <TranscriptEditors editorState={this.props.editorState!}
                            onEditorStateChanged={this.props.onEditorStateChanged} />
                    </ItemSection>
                </>
            );
        }
    }

    private getVideoAspectRatio() {
        const html = this.state.youTubeEmbedHtml!;
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const iframeNode = doc.querySelector('iframe')!;
        const width = parseFloat(iframeNode.getAttribute('width') || '0');
        const height = parseFloat(iframeNode.getAttribute('height') || '0');
        return width === 0 ? 0.75 : (height / width);
    }

    private async fetchYouTubeEmbedCode(youTubeId: string) {
        try {
            const html = await getEmbedCode(youTubeId);
            this.setState({youTubeEmbedHtml: html});
        } catch (err) {
            showErrorMessage('Failed to load YouTube video', {exception: err});
            this.setState({youTubeEmbedHtml: undefined});
        }
    }
}
