import {
    ItemType,
} from 'items/models';
import {
    ItemMetadata,
} from 'labxchange-client';
import {
    CaseStudySection,
} from 'library/components/BlockEditor/models';

export interface BlockProps {
    blockData: BlockData;
    itemMetadata: ItemMetadata;
    isPathwayChild?: boolean;
    pathwayMetadata?: ItemMetadata;
    pathwayItems?: ItemMetadata[];
    currentItemIndex?: number;
    // Callback allowing the XBlock to request to be rendered at full screen width
    onExpandBlock: (expand: boolean) => void;
    classroomName?: string;
    username?: string;
}

export interface BlockData {
    studentViewData?: any;
    // Only set to READY if all succeeded.
    loadingState: LoadingState;
    // If this is a video in a pathway and the pathway author has set a specific start/stop time:
    videoPlaybackWindow?: [number, number];  // [start, stop] timestamps in seconds
}

export interface BlockScoreState {
    earned: number;
    possible: number;
}

export interface BlockStudentViewData {
    usage_id: string;
    block_type: ItemType;
    display_name: string;
    graded?: boolean;
    child_blocks?: BlockStudentViewData[];
    sections?: CaseStudySection[];
}

export const enum LoadingState {
    LOADING,
    READY,
    ERROR,
    UNAUTHORIZED,
}

export function getEmptyBlockData(): BlockData {
    return {
        loadingState: LoadingState.LOADING,
        studentViewData: {},
    };
}
