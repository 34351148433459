import React from 'react';
import ReactFlow, { Background, BackgroundVariant, Controls, ReactFlowProps } from 'reactflow';
import { Icon } from 'elements';
import messages from '../../../displayMessages';
import { intl } from 'i18n';
import useFullscreen from 'career-explorer/utils';

interface BaseFlowMapProps {
    flowMapProps: ReactFlowProps;
    children?: React.ReactNode;
    backgroundColor?: string;
}

const BaseFlowMap: React.FC<BaseFlowMapProps> = ({flowMapProps, children, backgroundColor='#F8FAFB'}) => {
    const { isFullscreen, loading, toggleFullscreen, elementRef } = useFullscreen();

    return (
        <div className='flow-map-container' ref={elementRef}>
            {!loading &&
                <ReactFlow {...flowMapProps}>
                    <Background color={backgroundColor} variant={BackgroundVariant.Lines} gap={0} />
                    <button className='btn-full-screen' onClick={toggleFullscreen} title={intl.formatMessage(messages.fullScreenButtonTitle)}>
                        <Icon name={isFullscreen ? 'full-screen-x' : 'full-screen'} />
                    </button>
                    <Controls showInteractive={false} showFitView={false} />
                    { children }
                </ReactFlow>
            }
        </div>
    );
};

export default BaseFlowMap;
