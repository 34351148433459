import React, { FC } from 'react';
import { intl } from 'i18n';

import messages from '../../../displayMessages';

export const TagContentPageHeader: FC<{ dropdowns: React.ReactNode }> = ({ dropdowns }) => {
  return (
    <header className='tag-content-page-header'>
      <div className='page-title-wrapper container'>
        <h2 className='page-title'>
          {intl.formatMessage(messages.tagContentPageHeaderTitle)}
        </h2>
        <div className='tag-content-dropdowns'>
          {dropdowns}
        </div>
      </div>
    </header>
  );
};

export default TagContentPageHeader;
