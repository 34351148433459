import * as React from 'react';
import {getLoggedInStatus} from 'auth/selectors';
import {getStore} from 'global/store';
import {UsersApi} from '../../../global/api';
import {bind} from 'bind-decorator';
import {locale, setLanguageCookie} from 'i18n';
import messages from './displayMessages';

import {WrappedMessage} from 'utils';
import {Button, Modal} from 'ui/components';
import {HeaderBackgroundStyle} from 'ui/components/Header/Header';
import {LanguageButton} from './LanguageButton';
import {ButtonType} from './types/ButtonType';
import {LanguageSelector} from './LanguageSelector';
import { EVENT_NAMES } from 'tracking/constants';
import { analyticsInstance } from '../../../tracking';

interface Props {
    className?: string;
    backgroundStyle?: HeaderBackgroundStyle;
    btnType?: ButtonType;
    hideChangeText?: boolean;
}

interface State {
    enabled: boolean;
    selectedLanguage: string;
}


export class LocaleSwitcher extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            enabled: false,
            selectedLanguage: locale,
        };
    }

    render() {
        return (
            <>
                <LanguageButton
                    onClick={() => this.handleClick()}
                    backgroundStyle={this.props.backgroundStyle}
                    renderAs={this.props.btnType}
                    hideChangeText={this.props.hideChangeText}
                />
                {this.state.enabled ? this.renderModal() : null}
            </>
        );
    }

    private handleClick() {
        analyticsInstance.track(EVENT_NAMES.LocaleSwitcherHeaderButtonClicked, { url: window.location.toString() });
        this.setState({ enabled: true });
    }

    private renderModal() {
        return (
            <Modal
                className='modal-close-header-action'
                onRequestClose={this.close}
                closeButtonText={messages.languageSelectCancel}
                size='normal'
                title={<WrappedMessage message={messages.languageSelectTitle} />}
                showTopBar={true}
                content={
                    <div className='translation-selector-modal'>
                        <LanguageSelector
                            currentLanguage={this.state.selectedLanguage}
                            onSetLanguage={(translation) => this.setState({selectedLanguage: translation})}
                        />
                        <Button
                            btnStyle='primary'
                            fullWidth={true}
                            label={messages.languageSelectConfirm}
                            onClick={this.onConfirm}
                        />
                    </div>
                }
            />
        );
    }

    @bind private close() {
        this.setState({ enabled: false });
    }

    @bind private async onConfirm() {
        const { selectedLanguage } = this.state;
        analyticsInstance.track(
            EVENT_NAMES.SetLanguageButtonClicked,
            {
                selected_language: selectedLanguage,
                url: window.location.toString()
            },
            { sendImmediately: true },
        );
        const loggedIn = getLoggedInStatus(getStore().getState());
        if (loggedIn) {
            await UsersApi.changeLanguage({data: {preferredLanguage: selectedLanguage}});
        }
        setLanguageCookie(selectedLanguage);
        window.location.reload();
    }
}
