import { defineMessages } from 'react-intl';

const messages = defineMessages({
    moveDown: {
        id:'moveDown',
        defaultMessage: 'Move down',
        description: 'Label for the button that moves an item in an ordered list to the next position.',
    },
    moveUp: {
        id:'moveUp',
        defaultMessage: 'Move up',
        description: 'Label for the button that moves an item in an ordered list to the previous position.',
    },
});

export default messages;
