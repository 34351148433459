import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';

/* Wrap text that should only be shown to screen readers, and visually hidden. */

interface ScreenReaderProps {
    children: React.ReactNode;
}

export const ScreenReader: React.FC<ScreenReaderProps> = ({children}) => (
    <span className='sr-only'>{children}</span>
);

interface ScreenReaderIntlProps {
    message: MessageDescriptor;
    values?: any;
}

export const ScreenReaderIntl: React.FC<ScreenReaderIntlProps> = ({message, values}) => (
    <ScreenReader>
        <WrappedMessage message={message} values={values}/>
    </ScreenReader>
);
