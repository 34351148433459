import { bind } from 'bind-decorator';
import moment from 'moment-shortformat';
import * as React from 'react';

import messages from 'notifications/displayMessages';
import { Notification, NotificationContentIconColorEnum } from 'labxchange-client';
import { RouteComponentProps, withRouter } from 'react-router';
import { Icon, IconSymbol, KebabMenu, KebabMenuItem } from 'ui/components';
import { UserAvatar, Avatar } from 'user/components/UserAvatar';


interface NotificationItemProps extends RouteComponentProps<{}> {
    notification: Readonly<Notification>;
    markRead: (notificationId: string) => void;
    markUnread: (notificationId: string) => void;
}

export class NotificationItemInternal extends React.Component<NotificationItemProps, {}> {
    public render() {
        const { notification } = this.props;
        const content = notification.content;

        return (
            <div className={`notification-item ${notification.unread ? 'unread' : ''}`}>
                <div className='notification-item-icon'>
                    {content.profileUrlUsername
                        ? <UserAvatar diameter='60px' username={content.profileUrlUsername} />
                        : <Avatar diameter='60px' src={notification.content.profileIconUrl} />
                    }
                </div>
                <div className='details'>
                    <div className='description'>
                        <button className='notification-item-button' onClick={this.markRead}>
                            {notification.content.text}
                        </button>
                    </div>
                    <div className='info'>
                        <span className={`type-icon ${notification.content.iconColor}`}>
                            <Icon
                                name={notification.content.iconName as IconSymbol}
                                fill={notification.content.iconColor === NotificationContentIconColorEnum.Orange
                                    ? 'black' : 'white'}
                            />
                        </span>
                        <span className='time-ago'>{moment(notification.createdAt).short(true)}</span>
                    </div>
                </div>
                <div className='menu'>
                    <KebabMenu>
                        {this.props.notification.unread ?
                            <KebabMenuItem
                                iconName={'check'}
                                message={messages.kebabMarkRead}
                                disabled={false}
                                onClick={this.markReadInPlace}
                            />
                            :
                            <KebabMenuItem
                                iconName={'email'}
                                message={messages.kebabMarkUnread}
                                disabled={false}
                                onClick={this.markUnreadInPlace}
                            />
                        }
                    </KebabMenu>
                </div>
            </div>
        );
    }

    @bind private markReadInPlace() {
        if (this.props.notification.id) {
            this.props.markRead(this.props.notification.id);
        }
    }

    @bind private markUnreadInPlace() {
        if (this.props.notification.id) {
            this.props.markUnread(this.props.notification.id);
        }
    }

    @bind private markRead(event: React.MouseEvent) {
        event.preventDefault();
        if (this.props.notification.id) {
            this.props.markRead(this.props.notification.id);
            if (this.props.notification.content.targetUrl) {
                this.props.history.push(this.props.notification.content.targetUrl);
            }
        }
    }
}

// General notifications for activities like updating account,
// joining class etc. use this component
export const NotificationItem = withRouter(NotificationItemInternal);
