import { defineMessages } from 'react-intl';

const messages = defineMessages({
    assignedAt: {
        id:'assignedAt',
        defaultMessage: 'Assigned',
        description: 'Assigned at label for assignment.',
    },
    completedOn: {
        id:'completedOn',
        defaultMessage: 'Completed on',
        description: 'Completed on label for assignment.',
    },
    notStarted: {
        id:'notStarted',
        defaultMessage: 'Not started',
        description: 'Marks an item the user has not started.',
    },
    partiallyComplete: {
        id:'partiallyComplete',
        defaultMessage: '{completion}% Complete',
        description: 'Marks an item the user has not completed.',
    },
    complete: {
        id:'complete',
        defaultMessage: 'Complete',
        description: 'Marks an item the user has completed.',
    },
    incompletable: {
        id:'incompletable',
        defaultMessage: '-',
        description: 'Marks an item that cannot be completed.',
    },
    scorePercentage: {
        id:'scorePercentage',
        defaultMessage: '{score}% Correct',
        description: 'Represents an item score as a fraction.',
    },
    unassignContent: {
        id:'unassignContent',
        defaultMessage: 'Unassign',
        description: 'Unassign content from a mentee',
    },
    unassignContentConfirmModal: {
        id:'unassignContentConfirmModal',
        defaultMessage: 'Are you sure you want to unassign {contentTitle}?',
        description: 'Unassign content confirmation modal text.',
    },
    markAsIncomplete: {
        id: 'markAsIncomplete',
        defaultMessage: 'Mark as incomplete',
        description: 'Label for button for marking asset as incomplete.',
    }
});

export default messages;
