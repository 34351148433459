// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface RelatedCareer
 */
export interface RelatedCareer {
    /**
     * A unique human-readable name, e.g., 'ent-specialist'
     * @type {string}
     * @memberof RelatedCareer
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareer
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareer
     */
    description: string;
    /**
     * SVG icon for the career.
     * @type {string}
     * @memberof RelatedCareer
     */
    readonly icon?: string;
}

export function RelatedCareerFromJSON(json: any): RelatedCareer {
    return {
        'slug': json['slug'],
        'title': json['title'],
        'description': json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function RelatedCareerToJSON(value?: RelatedCareer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'description': value.description,
    };
}


