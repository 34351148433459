import bind from 'bind-decorator';
import { ClassroomsApi } from 'global/api';
import { ClassroomItem } from 'labxchange-client';
import { Card, CardProps } from 'library/components/Card';
import { detailUrlForEntity } from 'library/utils';
import moment from 'moment-shortformat';
import * as React from 'react';
import * as url from 'url';
import { ReorderableList, ReorderableListItem } from 'ui/components';
import { CardAddButtonAccessory } from 'ui/components/Card/Cards';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { sortClassroomItemsByOrder } from '../../util';
import messages from './displayMessages';

interface Props {
    canReorder?: boolean;
    classroomId: string;
    classroomName: string;
    items: ClassroomItem[];
    renderKebabMenu?: (item: ClassroomItem) => React.ReactNode;
    // Show indexed hex decoration on the left which represents progress or index
    showHex?: boolean;
    // Show add item button and call this function as callback
    onAddItem?(item: ClassroomItem): void;
    showPostedDate?: boolean;
    showSkeleton?: boolean;
    showCompletion?: boolean;
    forceShowCompletion?: boolean;
}

interface State {
    items: (ClassroomItem|{})[];
    reorderableItems: ReorderableListItem[];
}

export class ClassroomItemsList extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        let items;
        if (props.showSkeleton) {
            items = [...Array(3)];
        } else {
            items = props.items;
        }
        this.state = {
            // Maintain two items list because of optimistic reordering
            // that doesn't wait for backend response to update the UI
            items,
            reorderableItems: this.getReorderableListItems(items),
        };
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.items !== this.props.items) {
            this.setState({
                items: this.props.items,
                reorderableItems: this.getReorderableListItems(this.props.items),
            });
        }
    }

    public render() {
        return <div className='classroom-items-list'>
            {this.props.canReorder
            ? this.renderList()
            : this.renderListAsReadOnly()}
        </div>;
    }

    private renderListAsReadOnly() {
        return (
            <ul className='list-unstyled'>
                {this.state.items.map((classroomItem, index) =>
                    <li key={`classroom-item-${index}`}>
                        {this.renderCard(classroomItem, index)}
                    </li>
                )}
            </ul>
        );
    }

    private renderList() {
        return <ReorderableList
            items={this.state.reorderableItems}
            onMove={this.onOrderUpdate}
            reverseOrder={false}
            showSkeleton={this.props.showSkeleton}
        />;
    }

    private renderCard(classroomItem: ClassroomItem|{}, index: number) {
        let cardProps: CardProps = {showSkeleton: true};
        if (classroomItem && 'classroom' in classroomItem) {
            // empty object
            const parsedUrl = url.parse(detailUrlForEntity(classroomItem.item.metadata));
            const urlParams = new URLSearchParams(parsedUrl.query? parsedUrl.query : '');
            urlParams.append('classroomName', this.props.classroomName);
            const cardUrl = `${parsedUrl.pathname}?${urlParams.toString()}`;
            cardProps = {
                metadata: (classroomItem as ClassroomItem).item.metadata,
                detailUrl: cardUrl,
                showMenuButton: false, // we use child props on <Card> to render the kebab menu
                userAttributes: classroomItem.item.userAttributes,
            };
        }
        // we use list index +1 as an item number
        const itemPositionNumber = index + 1;
        const itemsAmount = this.state ? this.state.items.length : 0;
        return (
            <div className='classroom-item'>
                {!this.props.canReorder && this.props.showHex &&
                    <div className={`classroom-item-status ${this.props.showSkeleton ? 'skeleton' : ''}`}>
                        <div className='hexagon-active' aria-hidden='true'>
                            <div className='counter'>{this.props.showSkeleton ? '' : itemPositionNumber}</div>
                        </div>
                        {itemsAmount > itemPositionNumber ?
                            <div className='vertical-separator-line' /> : null
                        }
                    </div>
                }
                <div className='card-container'>
                    {this.props.showSkeleton ? null : this.props.showPostedDate &&
                        <p className='shared-on-label'>
                            Shared on {moment('postedDate' in classroomItem ? classroomItem.postedDate : '').format('MMMM DD, YYYY')}
                        </p>
                    }
                    <Card
                        {...cardProps}
                        accessory={
                            this.props.onAddItem
                            ? <CardAddButtonAccessory onClick={() => {
                                if (classroomItem) {
                                    this.props.onAddItem!(classroomItem as ClassroomItem);
                                }
                              }}/>
                            : null
                        }
                        menu={
                            (this.props.renderKebabMenu && !this.props.showSkeleton) ? this.props.renderKebabMenu(classroomItem as ClassroomItem) : null
                        }
                        showSkeleton={this.props.showSkeleton}
                        showCompletion={this.props.showCompletion}
                        forceShowCompletion={this.props.forceShowCompletion}
                    />
                </div>
            </div>
        );
    }

    @bind private async onOrderUpdate(
        oldIndex: number,
        newIndex: number,
        items: ReorderableListItem[],
    ) {
        const movingItem = items[oldIndex];
        items.splice(oldIndex, 1);
        items.splice(newIndex, 0, movingItem);
        const data = items.reverse().map((item, index) => {
            return {item: item.key.toString(), order: index};
        });
        try {
            const response = await ClassroomsApi.classroomItemsReorder({
                data: {
                    classroom: this.props.classroomId,
                    items: data,
                },
            });
            // If items are being re-ordered we need to sort the response by order again.
            // We also store the reorder response into reorderableItems.
            this.setState({items: sortClassroomItemsByOrder(response),
                reorderableItems: this.getReorderableListItems(sortClassroomItemsByOrder(response))});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.failedToReOrderItem} />, {
                exception: err,
            });
        }
    }

    @bind private getReorderableListItems(items: ClassroomItem[]) {
        return items.map((classroomItem, index) => {
            const html = this.renderCard(classroomItem, index);
            return {key: classroomItem? classroomItem.id : '', html};
        });
    }
}
