import React, { useState, useEffect, useCallback, useRef } from 'react';
import messages from './displayMessages';
import uiMessages from 'ui/components/displayMessages';
import { connect } from 'react-redux';
import { DiscussionsApi } from 'global/api';
import { getLoggedInStatus, getUsername } from 'auth/selectors';
import {useHistory, useLocation} from 'react-router-dom';
import { RouteComponentProps, useParams } from 'react-router';
import { intl } from 'i18n';

import { MessageComposeForm, MessagePostItem, MessageTopicItem } from '../../components';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { RootState } from 'global/state';
import { LxConfig, ROUTES } from 'global/constants';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { UI_IS_SM } from 'ui/breakpoints';
import { WrappedMessage } from 'utils';
import { UserAvatar } from 'user/components';
import {
    Dropdown,
    Icon,
    Modal,
    UnderConstruction,
} from 'ui/components';

import {
    Conversation,
    ConversationInfo,
    ConversationInfoStatusEnum,
    ConversationStatus,
    ConversationWithMessages,
    DiscussionAuthor,
    DiscussionsMessageCreate,
} from 'labxchange-client';

enum ModalType {
    None,
    AskBlock,
    AskUnblock,
}

interface InternalProps extends ReduxStateProps, ContainerState {
    newConversationRecipient?: DiscussionAuthor;
    conversationsInfos: ConversationInfo[];
    selectConversation: (conversationId: string) => void;
    deSelectConversation: () => void;
    sendMessage: (data: DiscussionsMessageCreate) => void;
    onBlockUser: (username: string) => void;
    onUnblockUser: (username: string) => void;
    onAcceptPending: (username: string) => void;
    conversationStatus?: ConversationStatus;
}

const MessagePageInternal = (props: InternalProps) => {
    const { conversations, conversationStatus, currentConversation, newConversationRecipient, loading, sending } = props;
    const [isMobileView, setIsMobileView] = useState(false);
    const [autoScroll, setAutoScroll] = useState(false);
    const [currentModal, setCurrentModal] = useState(ModalType.None);
    const messageListRef = useRef(null);
    const mediaQuery = UI_IS_SM;

    const setIsMobileViewCallback = useCallback(() => {
        setIsMobileView(mediaQuery.matches);
    }, [mediaQuery]);

    useEffect(() => {
        mediaQuery.addListener(setIsMobileViewCallback);
        setIsMobileView(mediaQuery.matches);

        return () => {
            mediaQuery.removeListener(setIsMobileViewCallback);
        };
    }, [setIsMobileViewCallback]);


    useEffect(() => {
        if (loading || sending) {
            setAutoScroll(true);
        } else if (autoScroll) {
            const messageList = messageListRef.current;
            if (messageList) {
                // @ts-ignore
                messageList.scrollTop = messageList.scrollHeight;
            }
            setAutoScroll(false);
        }
    }, [loading, sending, autoScroll]);

    const renderModals = () => {
        const user = currentConversation?.conversation.recipient;
        if (!user) return null;

        switch (currentModal) {
            case ModalType.AskBlock:
                return (
                    <Modal
                        contentScrolls={false}
                        onRequestClose={onCloseModal}
                        showBackButton={false}
                        showTopBar={false}
                        className='messages-block-modal'
                        content={
                            <>
                                <UserAvatar username={user.username} />
                                <div className='block-question'>
                                    <WrappedMessage message={messages.askBlockUser} values={{ name: user.fullName }} />
                                </div>
                                <div className='block-info-msg'>
                                    <WrappedMessage message={messages.blockUserInfo} values={{ name: user.fullName }} />
                                </div>
                                <div>
                                    <button className='block-btn btn secondary-button btn-outline-primary' onClick={onBlockAddressee}>
                                        <WrappedMessage message={messages.blockButton} />
                                    </button>
                                    <button className='primary-button' onClick={onCloseModal}>
                                        <WrappedMessage message={messages.cancelBlockButton} />
                                    </button>
                                </div>
                            </>
                        }
                    />
                );
            case ModalType.AskUnblock:
                return (
                    <Modal
                        contentScrolls={false}
                        onRequestClose={onCloseModal}
                        showBackButton={false}
                        showTopBar={false}
                        className='messages-block-modal'
                        content={
                            <>
                                <UserAvatar username={user.username} />
                                <div className='block-question'>
                                    <WrappedMessage message={messages.askUnblockUser} values={{ name: user.fullName }} />
                                </div>
                                <div className='block-info-msg'>
                                    <WrappedMessage message={messages.unblockUserInfo} values={{ name: user.fullName }} />
                                </div>
                                <div>
                                    <button className='block-btn btn secondary-button btn-outline-primary' onClick={onCloseModal}>
                                        <WrappedMessage message={messages.cancelUnblockButton} />
                                    </button>
                                    <button className='primary-button' onClick={onUnblockAddressee}>
                                        <WrappedMessage message={messages.unblockButton} />
                                    </button>
                                </div>
                            </>
                        }
                    />
                );
            case ModalType.None:
            default:
                return null;
        }
    };

    const renderCurrentConversation = () => {
        if (!currentConversation) return null;

        const currentRecipient = currentConversation.conversation.recipient;
        let currentConversationInformation: ConversationInfo | undefined;
        if (currentRecipient !== undefined || !currentRecipient) {
            currentConversationInformation = getConversationInfo(currentRecipient);
        } else {
            return null;
        }

        if (currentConversationInformation && currentConversationInformation.status === ConversationInfoStatusEnum.PendingYou && !currentConversationInformation.isBlocked && !currentConversationInformation.hasBlockedYou) {
            return (
                <div className='message-pending-box'>
                    <h3>{intl.formatMessage(messages.messageRequest, { name: currentRecipient.fullName })}</h3>
                    <div className='message-pending-inner'>
                        {currentConversation.messages.slice().map((message) => (
                            <p key={message.id} className='message-post-content' dangerouslySetInnerHTML={{ __html: message.content }} />
                        ))}
                        <p className='signature'>{currentRecipient.fullName}</p>
                    </div>
                    <p>
                        <WrappedMessage message={messages.acceptMessageInvitation} values={{ name: currentRecipient.fullName }} />
                    </p>
                    <button className='block-btn btn secondary-button btn-outline-primary' onClick={askBlockAddressee}>
                        <WrappedMessage message={messages.declineButton} />
                    </button>
                    <button className='primary-button' onClick={onAcceptPendingAddressee}>
                        <WrappedMessage message={messages.acceptButton} />
                    </button>
                </div>
            );
        } else {
            const conversationMessages = currentConversation.messages.slice().map((message, i) => {
                let sameUser = false;
                let sameDay = false;
                if (i > 0) {
                    const previousMessage = currentConversation.messages[i - 1];
                    const postDay = new Date(message.created).toLocaleDateString();
                    const prevPostDay = new Date(previousMessage.created).toLocaleDateString();
                    sameDay = postDay === prevPostDay;
                    sameUser = sameDay && (message.author === previousMessage.author);
                }
                return (
                    <MessagePostItem
                        key={message.id}
                        message={message}
                        user={findUser(message.author)}
                        sameDay={sameDay}
                        sameUser={sameUser}
                        loggedInUsername={props.loggedInUsername}
                    />
                );
            });

            let alertBox = null;
            if (currentConversationInformation && currentConversationInformation.isBlocked) {
                alertBox = (
                    <div className='message-warning'>
                        <WrappedMessage message={messages.youBlockedThem} values={{ name: currentRecipient.fullName }} />
                        {' '}
                        <button className='link-button' onClick={onUnblockAddressee}>
                            <WrappedMessage message={messages.undo} />{'.'}
                        </button>
                    </div>
                );
            } else if (currentConversationInformation && currentConversationInformation.hasBlockedYou) {
                alertBox = (
                    <div className='message-info'>
                        <WrappedMessage message={messages.theyBlockedYou} />
                    </div>
                );
            } else if (currentConversationInformation && currentConversationInformation.status === ConversationInfoStatusEnum.PendingThem) {
                alertBox = (
                    <div className='message-info'>
                        <WrappedMessage message={messages.yourMessagePending} />
                    </div>
                );
            }

            return (
                <>
                    {conversationMessages}
                    {alertBox}
                </>
            );
        }
    };

    const findUser = (username: string) => {
        if (username === undefined) return undefined;
        return props.currentConversation?.participants.find((u) => u.username === username);
    };

    const isConversationSelected = (conversationId: string) => {
        return (currentConversation && (currentConversation.conversation.id === conversationId)) || false;
    };

    const getConversationInfo = (author: DiscussionAuthor) => {
        return props.conversationsInfos?.find((info) => info.username === author.username);
    };

    const askBlockAddressee = () => setCurrentModal(ModalType.AskBlock);

    const askUnblockAddressee = () => setCurrentModal(ModalType.AskUnblock);

    const onAcceptPendingAddressee = () => {
        const user = currentConversation?.conversation.recipient;
        if (user === undefined) return;
        props.onAcceptPending(user.username);
    };

    const onBlockAddressee = () => {
        onCloseModal();
        const user = currentConversation?.conversation.recipient;
        if (user === undefined) return;
        props.onBlockUser(user.username);
    };

    const onUnblockAddressee = () => {
        onCloseModal();
        const user = currentConversation?.conversation.recipient;
        if (user === undefined) return;
        props.onUnblockUser(user.username);
    };

    const onCloseModal = () => setCurrentModal(ModalType.None);

    let conversationRecipient;
    if (newConversationRecipient) {
        conversationRecipient = newConversationRecipient;
    } else if (currentConversation) {
        conversationRecipient = currentConversation.conversation.recipient;
    }

    let currentConversationInfo: ConversationInfo | undefined;
    if (conversationRecipient !== undefined) {
        currentConversationInfo = getConversationInfo(conversationRecipient);
    }

    let isDisabled = true;
    if (currentConversation && currentConversation.status) {
        isDisabled = !currentConversation.status.allowed;
    } else if (conversationStatus) {
        isDisabled = !conversationStatus.allowed;
    }

    return (
        <div>
            <div className={`message-page row ${loading ? 'message-page-loading' : 'message-page-ready'}`}>
                <div className={`inbox-wrapper col-12 ${isMobileView ? 'mobile' : ''}`}>
                    <div className='inbox white-box'>
                        <div className='inbox-top row'>
                            {(!isMobileView || !currentConversation) && (
                                <div className={`inbox-search col-4 ${isMobileView ? 'mobile col-12' : ''}`}>
                                    {/* Hiding the search function as it is not working */}
                                </div>
                            )}
                            {(!isMobileView || currentConversation) && (
                                <div className={`message-party col-8 ${isMobileView ? 'mobile col-12' : ''}`}>
                                    {(isMobileView && currentConversation) && (
                                        <button className='close-message-pane' onClick={props.deSelectConversation}>
                                            <Icon name='chevron-left' zoom='1.5em' />
                                        </button>
                                    )}
                                    <div className='message-header'>
                                        {currentConversation && conversationRecipient && (
                                            <h2>{conversationRecipient.fullName}</h2>
                                        )}
                                        <p>
                                            {currentConversation && conversationRecipient && (
                                                conversationRecipient.jobTitle ?
                                                    conversationRecipient.company ?
                                                        <WrappedMessage
                                                            message={messages.messagesAddresseeProfession}
                                                            values={{
                                                                jobTitle: conversationRecipient.jobTitle,
                                                                company: conversationRecipient.company,
                                                            }}
                                                        />
                                                        : conversationRecipient.jobTitle
                                                    : conversationRecipient.company
                                            )}
                                        </p>
                                    </div>
                                    {currentConversationInfo && (
                                        <Dropdown id='message-page-menu' title={intl.formatMessage(messages.messageActionsMenu)} label={<Icon fill='#003e6b' name='kebab-vertical' zoom='24' />}>
                                            <ul>
                                                {!currentConversationInfo.isBlocked && (
                                                    <li>
                                                        <button onClick={askBlockAddressee}>
                                                            <WrappedMessage message={messages.blockButton} />
                                                        </button>
                                                    </li>
                                                )}
                                                {currentConversationInfo.isBlocked && (
                                                    <li>
                                                        <button onClick={askUnblockAddressee}>
                                                            <WrappedMessage message={messages.unblockButton} />
                                                        </button>
                                                    </li>
                                                )}
                                                <li>
                                                    <UnderConstruction>
                                                        <button>
                                                            <WrappedMessage message={messages.reportButton} />
                                                        </button>
                                                    </UnderConstruction>
                                                </li>
                                            </ul>
                                        </Dropdown>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className='inbox-bottom row'>
                            {(!isMobileView || !currentConversation) && (
                                <div className={`topic-list col-4 ${isMobileView ? 'mobile col-12' : ''}`} role='list' tabIndex={-1}>
                                    <div className='list-group list-group-flush' aria-label='chat list' tabIndex={-1}>
                                        {newConversationRecipient && conversationRecipient && (
                                            <MessageTopicItem
                                                key={-1}
                                                conversation={{
                                                    id: `new-${newConversationRecipient.username}`,
                                                    updated: new Date(),
                                                    created: new Date(),
                                                    unread: 0,
                                                    latestReply: '',
                                                    author: newConversationRecipient,
                                                    recipient: newConversationRecipient,
                                                    latestReplyUpdated: new Date(),
                                                }}
                                                user={conversationRecipient}
                                                info={currentConversationInfo}
                                                selected={true}
                                            />
                                        )}
                                        {conversations &&
                                            conversations.filter(conversation => conversation.recipient.username !== newConversationRecipient?.username)
                                            .slice().map((conversation) => {
                                            const recipient = conversation.recipient;
                                            const isSelected = isConversationSelected(conversation.id);
                                            return (
                                                <MessageTopicItem
                                                    key={conversation.id}
                                                    conversation={conversation}
                                                    user={recipient}
                                                    info={recipient && getConversationInfo(recipient)}
                                                    selected={isSelected}
                                                    selectConversation={props.selectConversation}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            {(!isMobileView || currentConversation) && (
                                <div className={`message-pane col-8 ${isMobileView ? 'mobile col-12' : ''}`}>
                                    <div className={`messages ${loading ? 'spinner' : ''}`} ref={messageListRef}>
                                        {!loading ? (
                                            conversations?.length ? (
                                                <div className='list-group list-group-flush'>
                                                    {renderCurrentConversation()}
                                                </div>
                                            ) : (
                                                <div className='empty-messages'>
                                                    <div className='empty-message-header'>
                                                        <WrappedMessage message={messages.messagesEmpty} />
                                                    </div>
                                                    <div className='empty-message-description'>
                                                        <WrappedMessage message={messages.messagesEmptyDescription} />
                                                    </div>
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                    {conversationRecipient && (
                                        <MessageComposeForm
                                            conversation={currentConversation}
                                            recipient={conversationRecipient}
                                            sending={sending}
                                            sendMessage={props.sendMessage}
                                            disabled={isDisabled}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {renderModals()}
        </div>
    );
};

interface MatchProps {
    conversation?: string;
}

interface ContainerState {
    conversations: Conversation[];
    conversationsInfos: ConversationInfo[];
    conversationStatus?: ConversationStatus;
    currentConversation?: ConversationWithMessages;
    newConversationRecipient?: DiscussionAuthor;
    loading: boolean;
    sending: boolean;
    fetchConversationsTimer?: number;
}

interface ContainerProps extends ReduxStateProps, RouteComponentProps<MatchProps> { }

const MessagePageContainer: React.FC<ContainerProps> = ({ isLoggedIn, loggedInUsername }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [sending, setSending] = useState<boolean>(false);

    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [conversationsInfos, setConversationsInfos] = useState<ConversationInfo[]>([]);
    const [currentConversation, setCurrentConversation] = useState<ConversationWithMessages | undefined>(undefined);
    const [conversationStatus, setConversationStatus] = useState<ConversationStatus | undefined>(undefined);
    const [newConversationRecipient, setNewConversationRecipient] = useState<any>(undefined);
    const [fetchConversationsTimer, setFetchConversationsTimer] = useState<number | null>(null);

    const history = useHistory();
    const location = useLocation();
    const { conversation: conversationIdParam } = useParams<{ conversation: string }>();

    const clearFetchConversationsTimer = useCallback(() => {
        if (fetchConversationsTimer) {
            clearTimeout(fetchConversationsTimer);
        }
    }, [fetchConversationsTimer]);

    const loadConversations = useCallback(async () => {
        try {
            const response = await DiscussionsApi.conversationsList({});
            setConversations(response.conversations);
            setConversationsInfos(response.conversationsInfos);
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.messagesFailedLoading} />);
        }
    }, []);

    const loadCurrentConversation = useCallback(async (convId?: string) => {
        const conversationId = convId ? convId : conversationIdParam;
        if (conversationId) {
            const response = await DiscussionsApi.conversationsRead({ id: conversationId });
            setCurrentConversation(response);
            setConversationStatus(response.status);
        }
    }, [conversationIdParam]);

    const setFetchConversationsTimerCallback = useCallback(async () => {
        clearFetchConversationsTimer();
        await loadConversations();
        await loadCurrentConversation();
        const timer = window.setTimeout(setFetchConversationsTimerCallback, LxConfig.MessagePollDefaultInterval);
        setFetchConversationsTimer(timer);
    }, [clearFetchConversationsTimer, loadConversations, loadCurrentConversation]);

    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            await loadConversations();

            const params = new URLSearchParams(location.search);
            const username = params.get('recipient');
            let conversationId;
            if (username) {
                const matchedConversation = conversations.find(conv => conv.recipient.username === username);
                conversationId = matchedConversation ? matchedConversation.id : undefined;
            }
            if (username && (!conversationIdParam || conversationId === undefined)) {
                const newConversationRecipientResp = await DiscussionsApi.authorRead({ username });
                setNewConversationRecipient(newConversationRecipientResp);
                const conversationStatusResp = await DiscussionsApi.conversationsCheckCanMessage({ id: username });
                setConversationStatus(conversationStatusResp);
            } else {
                await loadCurrentConversation(conversationId);
            }
            setLoading(false);

        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.messagesFailedLoading} />);
        }
        window.setTimeout(setFetchConversationsTimerCallback, 5000);
    }, [location.search, conversations, conversationIdParam, loadConversations, loadCurrentConversation]);

    useEffect(() => {
        if (!isLoggedIn) return;

        if (location.pathname !== ROUTES.Messages.MESSAGE) {
            setCurrentConversation(undefined);
            setConversationStatus(undefined);
            loadData();
        } else {
            loadCurrentConversation();
        }
    }, [isLoggedIn, location.pathname]);

    const selectConversation = useCallback((conversationId: string) => {
        history.push(ROUTES.Messages.MESSAGE_SLUG(conversationId));
    }, [history]);

    const deSelectConversation = useCallback(() => {
        history.push(ROUTES.Messages.HOME);
    }, [history]);

    const onAcceptPending = useCallback(async (username: string) => {
        setLoading(true);
        try {
            await DiscussionsApi.conversationsAcceptPending({ id: username });
        } catch (err) {
            // @ts-ignore
            showErrorMessage('Error accepting message request.', { exception: err });
        }

        // Refresh to pull in the latest state (ie. thread now accepted)
        loadData();
    }, [loadData]);

    const onUnblockUser = useCallback(async (username: string) => {
        setLoading(true);
        try {
            await DiscussionsApi.conversationsUnblockUser({ id: username });
        } catch (err) {
            // @ts-ignore
            showErrorMessage('Error unblocking user.', { exception: err });
        }

        // Refresh to pull in the latest state (ie. user now unblocked)
        loadData();
    }, [loadData]);

    const onBlockUser = useCallback(async (username: string) => {
        setLoading(true);
        try {
            await DiscussionsApi.conversationsBlockUser({ id: username });
        } catch (err) {
            // @ts-ignore
            showErrorMessage('Error blocking user.', { exception: err });
        }

        // Refresh to pull in the latest state (ie. user blocked)
        loadData();
    }, [loadData]);

    const sendMessage = useCallback(async (data: any) => {
        setSending(true);
        let newMessage;
        try {
            newMessage = await DiscussionsApi.conversationsCreateMessage({ data });
        } catch (response) {
            setSending(false);
            // @ts-ignore
            response.json().then((body: { reason?: string }) => {
                let reason: string | undefined;
                if (body.reason) { reason = body.reason; }
                if (reason) {
                    const showError = (reasonMessage: string) => showErrorMessage(
                        <WrappedMessage message={messages.messagesFailedSendingMessageWithReason}
                                        values={{ reason: reasonMessage }} />,
                        { confirmText: uiMessages.uiConfirmationButton }
                    );
                    if (reason === 'SenderMessagesDisabled') {
                        showError(intl.formatMessage(messages.messagesFailedSenderDisabledMessaging));
                    } else if (reason === 'ReceiverMessagesDisabled') {
                        showError(intl.formatMessage(messages.messagesFailedReceiverDisabledMessaging));
                    } else if (reason === 'SenderBlockedReceiver') {
                        showError(intl.formatMessage(messages.messagesFailedSenderBlocked));
                    } else if (reason === 'ReceiverBlockedSender') {
                        showError(intl.formatMessage(messages.messagesFailedRecipientBlocked));
                    } else {
                        showError(reason);
                    }
                } else {
                    showErrorMessage(<WrappedMessage message={messages.messagesFailedSendingMessage} />);
                }
            });
            throw response;
        }

        if (newMessage) {
            if (currentConversation && currentConversation.conversation.id === newMessage.conversation) {
                const newMessages = [...currentConversation.messages, newMessage.message];
                setCurrentConversation(prev => ({
                    ...prev!,
                    messages: newMessages
                }));
            } else {
                selectConversation(newMessage.conversation);
                setNewConversationRecipient(undefined);
                await loadData();
            }

            setSending(false);
        }
    }, [currentConversation, selectConversation, loadData]);

    if (!isLoggedIn) {
        return <RegistrationGate
            title={intl.formatMessage(messages.notLoggedAlertMessageTitle)}
            body={intl.formatMessage(messages.notLoggedAlertMessageBody)}
            primaryButtonText={uiMessages.uiSignUp}
            secondaryButtonText={uiMessages.uiSignIn}
            image='/assets/images/access.svg'
        />;
    }

    return (
        <MessagePageInternal
            conversations={conversations}
            conversationsInfos={conversationsInfos}
            conversationStatus={conversationStatus}
            currentConversation={currentConversation}
            deSelectConversation={deSelectConversation}
            isLoggedIn={isLoggedIn}
            loading={loading}
            loggedInUsername={loggedInUsername}
            newConversationRecipient={newConversationRecipient}
            onAcceptPending={onAcceptPending}
            onBlockUser={onBlockUser}
            onUnblockUser={onUnblockUser}
            selectConversation={selectConversation}
            sending={sending}
            sendMessage={sendMessage}
        />
    );
};

interface ReduxStateProps {
    loggedInUsername: string;
    isLoggedIn: boolean;
}

export const MessagePage = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
        loggedInUsername: getUsername(state),
    }), {},
)(MessagePageContainer);
