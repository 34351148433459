import * as React from 'react';
import moment from 'moment-shortformat';
import {WrappedMessage} from 'utils';
import messages from './displayMessages';
import {ItemProgress} from 'labxchange-client';

interface AssignedItemCompletionProps {
    completion?: number;
    completionDate?: Date;
    showDate?: boolean;
}

export class AssignedItemCompletion extends React.PureComponent<AssignedItemCompletionProps> {

    public static defaultProps = {
        showDate: false
    };

    public render() {
        if (this.props.completion !== undefined) {
            if (this.props.completion === 0) {
                return (
                    <span className='incomplete'>
                    <WrappedMessage message={messages.notStarted}/>
                </span>
                );
            } else if (this.props.completion === 1) {
                return (
                    <span>
                    <WrappedMessage message={messages.complete}/>
                        &nbsp;
                    {(this.props.completionDate !== undefined && this.props.showDate) &&
                    moment(this.props.completionDate).format('YY.MM.DD')}
                </span>);
            } else {
                return (
                    <WrappedMessage
                        message={messages.partiallyComplete}
                        values={{completion: Math.round(100 * this.props.completion)}}
                    />
                );
            }
        }
        return <WrappedMessage message={messages.incompletable}/>;
    }
}

interface AssignedItemProgressProps {
    itemProgress: ItemProgress;
}

export class AssignedItemProgress extends React.PureComponent<AssignedItemProgressProps> {

    public render() {
        const earned = this.props.itemProgress.weightedEarned;
        const possible = this.props.itemProgress.weightedPossible;

        let score;

        if (this.props.itemProgress.completion !== undefined) {
            // If there is a score to display, show it as a percent
            if ((earned !== undefined) && possible) {
                score = Math.round(earned / possible * 100);
            }
        }

        return (
            <span>
                <AssignedItemCompletion
                    completion={this.props.itemProgress.completion}
                    completionDate={this.props.itemProgress.completionDate}
                    showDate={true}
                />
                {score !== undefined &&
                    <> | <WrappedMessage message={messages.scorePercentage} values={{score}} /> </>
                }
            </span>
        );
    }
}
