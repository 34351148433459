import bind from 'bind-decorator';
import { ItemsApi, OrganizationsApi } from 'global/api';
import { ItemMetadata, OrganizationResponse } from 'labxchange-client';
import { Card } from 'library/components/Card';
import { detailUrlForEntity } from 'library/utils';
import { AlternativeOrgProfileHeader, OrgSocialLinks } from 'org/components';
import messages from 'org/components/OrgProfile/displayMessages';
import * as React from 'react';
import { Button, StandardPageLayout } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { skeletonOrg } from 'skeletons';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ROUTES } from 'global/constants';

export interface OrganizationHeaderPartner {
    altText: string;
    logoUrl: string;
}

export interface OrganizationProgram {
    title: string;
    description: string;
    youtubeVideoId: string;
    logoUrl: string;
    logoAltText: string;
    firstButtonText: string;
    firstButtonUrl: string;
    firstButtonNewTab?: boolean;
    secondButtonText?: string;
    secondButtonUrl?: string;
    secondButtonNewTab?: boolean;
}

export interface OrganizationDetails {
    descriptionText: string;
    headerBackgroundUrl: string;
    programs: OrganizationProgram[];
    staffInvolvementText: string;
    staffInvolvementSecondText: string;
    cardIds: string[];
    staffEngagementTag: string;
    footerText: string;
    headersPartners: OrganizationHeaderPartner[];
}

interface Props {
    organizationId: string;
    details: OrganizationDetails;
}

interface State {
    loadingOrgData: boolean;
    orgData?: OrganizationResponse;
    cards?: ItemMetadata[];
}

export class AlternativeOrgProfilePage extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loadingOrgData: true
        };
    }

    public async componentDidMount() {
        this.fetchOrgProfile();
        setTimeout(this.fetchCards, 500);
    }

    private async fetchOrgProfile() {
        let orgResponse: OrganizationResponse|undefined;
        try {
            orgResponse = await OrganizationsApi.retrievePartner({id: this.props.organizationId});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadFailed}/>, {exception: err});
        }
        this.setState({orgData: orgResponse, loadingOrgData: false});
    }

    @bind private async fetchCards() {
        const cards = await Promise.all(this.props.details.cardIds.map(async (id) => {
            const resp = await ItemsApi.read({id});
            return resp.metadata;
        }));
        this.setState({cards});
    }

    public render() {
        const orgProfile = this.state.orgData ? this.state.orgData.org : skeletonOrg;
        const programsComponents = this.props.details.programs.map((program, index) =>
            <div className='partner' key={index}>
                <div className='content'>
                    <h3>{this.state.loadingOrgData ? <Skeleton /> : program.title}</h3>
                    <p>{this.state.loadingOrgData ? <Skeleton /> : program.description}</p>
                    <div className='video'>
                        {this.state.loadingOrgData ? <Skeleton />  :
                            < iframe
                                width='100%'
                                height='315'
                                src={`https://www.youtube.com/embed/${program.youtubeVideoId}`}
                                title={`Video - ${program.title}`}
                                frameBorder='0'
                                allow={
                                'accelerometer; autoplay; clipboard-write; ' +
                                'encrypted-media; gyroscope; picture-in-picture'
                                }
                                allowFullScreen>
                                </iframe>
                        }
                    </div>
                </div>
                <div className='footer'>
                    <div className='logo'>
                        {this.state.loadingOrgData ?
                            <Skeleton /> :
                            <img src={program.logoUrl} alt={program.logoAltText} />
                        }
                    </div>
                    <div className='links'>
                        {this.state.loadingOrgData ?
                            <Skeleton /> :
                            <Button
                                btnStyle='outline'
                                href={program.firstButtonUrl}
                                label={{defaultMessage: program.firstButtonText, id: 'no-id'}}
                                newWindow={program.firstButtonNewTab}
                            />
                        }

                        {this.state.loadingOrgData && program.secondButtonText && <Skeleton />}

                        {!this.state.loadingOrgData && program.secondButtonText &&
                            <Button
                                btnStyle='outline'
                                href={program.secondButtonUrl}
                                label={{defaultMessage: program.secondButtonText, id: 'no-id'}}
                                newWindow={program.secondButtonNewTab}
                            />
                        }
                    </div>
                </div>
            </div>
        );
        let cards;
        if (this.state.cards) {
            cards = this.state.cards.map((metadata, index) =>
                <Card
                    key={index}
                    metadata={metadata}
                    mobileViewMode={true}
                    detailUrl={detailUrlForEntity(metadata)}
                />
            );
        } else {
            cards = [
                <Card key={1} showSkeleton={true} mobileViewMode={true}/>,
                <Card key={2} showSkeleton={true} mobileViewMode={true}/>,
                <Card key={3} showSkeleton={true} mobileViewMode={true}/>,
                <Card key={4} showSkeleton={true} mobileViewMode={true}/>,
            ];
        }

        return (
            <StandardPageLayout
                headerBackgroundUrl={this.props.details.headerBackgroundUrl}
                pageTitle={messages.pageTitle}
                pageTitleValues={{
                    name: orgProfile ? (orgProfile.name || orgProfile.slug) : '...'}}
                headerFeature={<AlternativeOrgProfileHeader
                        profile={orgProfile}
                        partners={this.props.details.headersPartners}
                        showSkeleton={this.state.loadingOrgData}
                    />
                }
                footerFeature={
                    <div className='alternative-org-profile-footer'>
                        <span>{this.props.details.footerText}</span>
                        <img
                            src={orgProfile?.profilePageLogo}
                            alt={`Logo - ${orgProfile?.name}`}
                        />
                        {orgProfile &&
                            <OrgSocialLinks
                                organization={orgProfile}
                                showAll={false}
                                showWebsite={true}
                                showTwitter={true}
                                showLinkedin={true}
                            />
                        }
                    </div>
                }
                mainClassName='alternative-org-profile'
            >
                <h2 className='alternative-org-profile-section-title-small'>
                    { this.state.loadingOrgData ? <Skeleton /> : 'Our Mission' }
                </h2>
                <div className='alternative-org-profile-description'>
                    {this.state.loadingOrgData ? <Skeleton /> : this.props.details.descriptionText}
                </div>
                <h2 className='alternative-org-profile-section-title'>
                    { this.state.loadingOrgData ? <Skeleton /> : 'Our Programs' }
                </h2>
                <div className='alternative-org-profile-programs-content'>
                    {programsComponents}
                </div>
                <h2 className='alternative-org-profile-section-title'>
                    { this.state.loadingOrgData ? <Skeleton /> : 'Amgen Staff Engagement' }
                </h2>
                <div className='alternative-org-profile-section-description'>
                    <p>{ this.state.loadingOrgData ? <Skeleton /> : this.props.details.staffInvolvementText}</p>
                    <p>{ this.state.loadingOrgData ? <Skeleton className='second-text' /> : this.props.details.staffInvolvementSecondText}</p>
                </div>
                <div className='alternative-org-profile-cards'>{cards}</div>
                <Link to={`${ROUTES.Library.HOME}?t=Tag%3A${this.props.details.staffEngagementTag}`} className='btn btn-primary see-all-button'>
                    See all
                </Link>
            </StandardPageLayout>
        );
    }
}
