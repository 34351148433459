import { defineMessages } from 'react-intl';

const messages = defineMessages({
    joinClassTitle: {
        id:'joinClassTitle',
        defaultMessage: 'Join a class',
        description: 'Title text for for Join classroom Modal, which presents when a user attempts to join a classroom from a Learner Dashboard, or Classroom Page',
    },
    joinClassText: {
        id:'joinClassText',
        defaultMessage: 'If an educator sent you a class code, please enter it here. By joining this class, you consent to be mentored by the educator and to receive messages from the educator and other learners in the class.',
        description: 'Body text for for Join classroom Modal, which presents when a user attempts to ' +
            'join a classroom from a Learner Dashboard',
    },
    joinClassPreviouslyViewedTitle: {
        id:'joinClassPreviouslyViewedTitle',
        defaultMessage: 'You have to enter the class code to view this class.',
        description: 'Title text for for Join classroom Modal, which presents when a user attempts to ' +
            'join a classroom from a Classroom Page when they were not previously enrolled',
    },
    joinClassButtonText: {
        id:'joinClassButtonText',
        defaultMessage: 'Request to join class',
        description: 'Button label for for Join classroom Modal, which presents when a user attempts to ' +
            'join a classroom from a Learner Dashboard, or Classroom Page',
    },
    classCodeInputTextLabel: {
        id:'classCodeInputTextLabel',
        defaultMessage: 'Class code',
        description: 'Label for class code input field on Join classroom Modal, which presents' +
            ' when a user attempts to join a classroom from a Learner Dashboard, or Classroom Page',
    },
    classCodePlaceholderText: {
        id:'classCodePlaceholderText',
        defaultMessage: 'Enter class code',
        description: 'Placeholder text for class code input field on Join classroom Modal, which presents when a user attempts to join a classroom from a Learner Dashboard, or Classroom Page',
    },
});

export default messages;
