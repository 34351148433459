
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLayoutSize } from 'utils';

interface SuccessStoryProps {
    quote: string;
    attribution: string;
    img: string;
    name: string;
}

const successStories: SuccessStoryProps[] = [
    {
        quote: `“We are a tech-savvy generation, so it's great to have interactive
            tools like LabXchange that help expose us to new lab techniques.
            It should be incorporated into all biology classes.”`,
        name: `Ashanti Malcolm-Bates`,
        attribution: `High school student`,
        img: '/assets/images/testimonials/ashanti-malcolm-bates.jpg',
    }, {
        quote: `“In one place, I can find digital learning experiences to help me
            teach a specific topic in Biology that is at the appropriate level of
            complexity for my students.”`,
        name: `Toni Cox`,
        attribution: `High school anatomy teacher`,
        img: '/assets/images/testimonials/toni-cox.png',
    }, {
        quote: `“It is a great resource for students to build confidence and
            familiarity experimenting and learning lab skills.”`,
        name: `Taylah Hawks`,
        attribution: `Biomedical science teacher`,
        img: '/assets/images/testimonials/taylah-hawks.png',
    }
];

export const SuccessStories: React.FC = () => {

    return (
        <div className='success-stories'>
            {
                successStories.map((story, idx) => {
                    return (
                        <Story key={idx}
                            {...story}/>
                    );
                })
            }
        </div>
    );
};

const Story: React.FunctionComponent<SuccessStoryProps> = (props) => (
    <div className='story'>
        <div className='header'>
            <img src={props.img} alt={props.attribution} className='rounded-circle'/>
            <div className='attribution'>
                <strong>{props.name}</strong>
                {props.attribution}
            </div>
        </div>
        <div className='quote'>{props.quote}</div>
    </div>
);

export const SuccessStoriesSkelton: React.FC = () => {
    const layoutSize = useLayoutSize();
    const isMobile = layoutSize === 'mobile' || layoutSize === 'small';

    return (
        <>
            <h1><Skeleton width={isMobile ? 300 : 500} height={42} /></h1>
            <div className='success-stories'>
            {
                Array.from({ length: 3 }, (_, index) => (
                    <div className='story story-skeleton'>
                        <div className='header'>
                            <Skeleton className='rounded-circle' width={67} height={67} />
                            <Skeleton className='attribution' width={209} height={41} />
                        </div>
                        <Skeleton className='quote' width={292} height={103} />
                    </div>
                ))
            }
            </div>
        </>

    );
};
