import * as React from 'react';
import { WrappedMessage } from 'utils';
import iconMagGlass from 'assets/images/icons/magGlass.svg';
import messages from './displayMessages';


export const NoResultsWidget = () => {
    return (
        <div className='d-flex justify-content-center'>
            <div className='d-flex flex-column my-3 no-results-content'>
                <img src={iconMagGlass} className='my-0 no-results-search-icon' alt='' />
                <h3 className='mt-4 mb-1 mx-auto'>
                    <WrappedMessage message={messages.sorryNoResultsFound} />
                </h3>
                <p className='mx-auto'>
                    <WrappedMessage message={messages.noResultsFoundSuggestion} />
                </p>
            </div>
        </div>
    );
};