import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'global/constants';
import { LibraryContentList } from 'library/components/LibraryContentList';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { EmptyDashboardPage } from 'library/components/EmptyDashboardPage';


export class FavoritesDashboard extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <div className='library-page'>
                <h1 className='sr-only'>
                    <WrappedMessage message={messages.favoritesDashboardTabTitle}/>
                </h1>
                <LibraryContentList
                    cardsStyle='portrait'
                    isModalView={false}
                    displayAddButton={false}
                    openCardDetailUrlsInNewTab={false}
                    isPublicContentOnly={false}
                    searchOnlyFavoritedByUser={true}
                    placeholderView={
                        <EmptyDashboardPage
                            title={messages.favoritesEmpty}
                            buttons={
                                <>
                                    <NavLink to={ROUTES.Library.HOME} className='btn btn-outline-primary'>
                                        <WrappedMessage message={messages.visitLibrary} />
                                    </NavLink>
                                    <NavLink to={ROUTES.Explore.HOME} className='btn primary-button'>
                                        <WrappedMessage message={messages.exploreButtonText} />
                                    </NavLink>
                                </>
                            }
                        />
                    }
                />
            </div>
        );
    }
}
