import { defineMessages } from 'react-intl';

const messages = defineMessages({
    lxAuthUnableToLogin: {
        id: 'lxLoginUnableToLogin',
        defaultMessage: 'An error occurred during login.',
        description: 'Message of unknown internal server error during login.',
    },
    lxAuthUnableToSignup: {
        id: 'lxLoginUnableToSignup',
        defaultMessage: 'An error occurred during sign-up.',
        description: 'Message of unknown internal server error during sign-up.',
    },
    lxAuthUnableToSendEmailConfirmation: {
        id: 'lxAuthUnableToSendEmailConfirmation',
        defaultMessage: 'An error occurred during send email confirmation link.',
        description: 'Message of unknown internal server error during new email confirmation link.',
    },
    lxAuthRatelimit: {
        id: 'lxAuthRatelimit',
        defaultMessage: 'Too many failed attempts. Try again later.',
        description: 'Message when the user reaches the rate limit.',
    },
    lxAuthShowPassword: {
        id: 'lxAuthShowPassword',
        defaultMessage: 'Show password',
        description: 'Label of show password button',
    },
    lxAuthHidePassword: {
        id: 'lxAuthHidePassword',
        defaultMessage: 'Hide password',
        description: 'Label of hide password button',
    },
    lxAuthPasswordInputFeedback: {
        id: 'lxAuthPasswordInputFeedback',
        defaultMessage: 'Password must have 10 or more characters.',
        description: 'Feedback of the password input',
    },
    lxAuthAccountAlreadyCreatedMsg: {
        id: 'lxAuthAccountAlreadyCreatedMsg',
        defaultMessage: 'You have already created a LabXchange account.',
        description: 'Message when the user sign-up/sign-in with existing mail',
    },
    lxAuthSocialLoginCanceled: {
        id: 'lxAuthSocialLoginCanceled',
        defaultMessage: 'Canceled by the user.',
        description: 'Message when the user cancels the login with SSO',
    },
    lxAuthProviderButtonText: {
        id: 'lxAuthProvideButtonText',
        defaultMessage: 'Continue with {provider}',
        description: 'Text of login button of SSO providers',
    },
    lxAuthSignUpTitle: {
        id: 'lxAuthSignUpTitle',
        defaultMessage: 'SIGN UP. IT\'S FREE!',
        description: 'Title of the sign up page',
    },
    lxAuthSignUpJoinClassTitle: {
        id: 'lxAuthSignUpJoinClassTitle',
        defaultMessage: 'Sign up to join a class',
        description: 'Title of the sign up page, when user coming from join a class link.',
    },
    lxAuthSignUpSubtitle: {
        id: 'lxAuthSignUpSubtitle',
        defaultMessage: 'Sign up with an email address or edX account.',
        description: 'Subtitle of the sign up page',
    },
    lxAuthSignUpWithEmailSubtitle: {
        id: 'lxAuthSignUpWithEmailSubtitle',
        defaultMessage: 'Sign up with email.',
        description: 'Subtitle of the sign up with email page',
    },
    lxAuthSignUpAgeWarning: {
        id: 'lxAuthSignUpAgeWarning',
        defaultMessage: 'You must be 13 or older to sign up for a LabXchange account.',
        description: 'Text on the sign up page to warning about the age limit',
    },
    lxAuthSignUpNewsletterMessage: {
        id: 'lxAuthSignUpNewsletterMessage',
        defaultMessage: 'Subscribe to our newsletter for periodic updates.',
        description: 'Text on the sign up page to subscribe to the newsletter',
    },
    lxAuthSignUpPolicyMsgComplete: {
        id: 'lxAuthSignUpPolicyMsg',
        defaultMessage: 'By creating an account, you agree to the {terms} and you acknowledge that LabXchange processes your personal data in accordance with the {privacyNotice}.',
        description: 'terms and policy message on sign up',
    },
    lxAuthSignInLavel: {
        id: 'lxAuthSignInLavel',
        defaultMessage: 'Sign in',
        description: 'Label for buttons that redirects to the Sign in page',
    },
    lxAuthAlreadyAccountQuestion: {
        id: 'lxAuthAlreadyAccountQuestion',
        defaultMessage: 'Already have an account?',
        description: 'Label on the sign up page to redirect to the sign in page',
    },
    lxAuthUserAgeTitle: {
        id: 'lxAuthUserAgeTitle',
        defaultMessage: 'When were you born?',
        description: 'Title of the user age page',
    },
    lxAuthUserAgeSubtitle: {
        id: 'lxAuthUserAgeSubtitle',
        defaultMessage: 'We verify your age before you can create an account.',
        description: 'Subtitle of the user age page.',
    },
    lxAuthUserAgeTextPlaceholder: {
        id: 'lxAuthUserAgeTextPlaceholder',
        defaultMessage: 'Birthdate',
        description: 'Placeholder of the birthdate button.',
    },
    lxAuthSubmitLabel: {
        id: 'lxAuthSubmitLabel',
        defaultMessage: 'Submit',
        description: 'Label for the Submit button on the auth pages.',
    },
    lxAuthInvalidConfirmation: {
        id: 'lxAuthInvalidConfirmation',
        defaultMessage: 'The email confirmation link expired or is invalid. Request a new confirmation link.',
        description: 'Message when the user opens an invalid email confirmation link.',
    },
    lxAuthLoggedOUtInvalidConfirmation: {
        id: 'lxAuthLoggedOUtInvalidConfirmation',
        defaultMessage: 'The email confirmation link expired or is invalid. Sign in to confirm your email.',
        description: 'Message when the user opens an invalid email confirmation link.',
    },
    lxAuthEmailConfirmationRatelimit: {
        id: 'lxAuthEmailConfirmationRatelimit',
        defaultMessage: 'You have already generated a link before, to generate another you have to wait.',
        description: 'Message when the user reaches the rate limit of new email confirmation links',
    },
    lxAuthEmailConfirmationSended: {
        id: 'lxAuthEmailConfirmationSended',
        defaultMessage: 'Email confirmation link sent.',
        description: 'Message when the email confirmation link has sent successfully',
    },
    lxAuthEmailConfimationSuccess: {
        id: 'lxAuthEmailConfimationSuccess',
        defaultMessage: 'Account successfully activated',
        description: 'Message when the confirmation email has been given successfully.',
    },
    lxAuthEmailConfimationSuccessButtonLabel: {
        id: 'lxAuthEmailConfimationSuccessButtonLabel',
        defaultMessage: 'Let\'s start',
        description: 'Message of the button when the confirmation email has been given successfully.',
    },
    lxAuthSignInPolicyMsgComplete:{
        id: 'lxAuthSignInPolicyMsg',
        defaultMessage: 'Click “{msg}” to agree with LabXchange’s {terms} and acknowledge that LabXchange’s {privacy} applies to you.',
        description: 'Complete Sign in message for terms and privacy',
    },
    lxAuthSignInSignUpMessage: {
        id: 'lxAuthSignInSignUpMessage',
        defaultMessage: 'Don’t have an account yet?',
        description: 'Sign up message on sign in modal',
    },
    lxAuthSignUpLavel: {
        id: 'lxAuthSignUpLavel',
        defaultMessage: 'Sign up',
        description: 'Label for buttons that redirects to the Sign up page',
    },
    lxAuthResetPasswordTitle: {
        id: 'lxAuthResetPasswordTitle',
        defaultMessage: 'Reset your password',
        description: 'Title for the reset password page',
    },
    lxAuthResetPasswordSubtitleWithEmail: {
        id: 'lxAuthResetPasswordSubtitleWithEmail',
        defaultMessage: 'Please select a new password for ',
        description: 'Text for the reset password page',
    },
    lxAuthResetPasswordSubtitle: {
        id: 'lxAuthResetPasswordSubtitle',
        defaultMessage: 'Please select a new password',
        description: 'Text for the reset password page',
    },
    lxAuthResetPasswordConfirmInputPlaceholder: {
        id: 'lxAuthResetPasswordConfirmInputPlaceholder',
        defaultMessage: 'Confirm password',
        description: 'Placeholder for the confirm password input',
    },
    lxAuthResetPasswordSubmitButton: {
        id: 'lxAuthResetPasswordSubmitButton',
        defaultMessage: 'Reset password',
        description: 'Text for the reset password submit button',
    },
    lxAuthResetConfirmPasswordNoMatchAccessory: {
        id: 'lxAuthResetConfirmPasswordNoMatchAccessory',
        defaultMessage: 'No match',
        description: 'Text for when the confirm password does not match the password',
    },
    lxAuthSignUpLavelLowercase: {
        id: 'lxAuthSignUpLavelLowercase',
        defaultMessage: 'sign up',
        description: 'Label of sign up in lowercase used in auth pages',
    },
    lxAuthResetPasswordBadEmail: {
        id: 'lxAuthRestPasswordBadEmail',
        defaultMessage: 'Bad formatted email.',
        description: 'Message when the email is bad formatted on reset password view',
    },
    lxAuthResetPasswordEmailError: {
        id: 'lxAuthResetPasswordEmailError',
        defaultMessage: 'An error ocurred during sending the email.',
        description: 'Message of unknown internal server error during reset password',
    },
    lxAuthResetPasswordEmailSuccess: {
        id: 'lxAuthResetPasswordEmailSuccess',
        defaultMessage: 'Reset email link sent successfully.',
        description: 'Message when reset password email is success',
    },
    lxAuthResetPasswordSuccess: {
        id: 'lxAuthResetPasswordSuccess',
        defaultMessage: 'The password has been changed successfully. Please log in.',
        description: 'Message when the password was changed succesfully.',
    },
    lxAuthResetPasswordError: {
        id: 'lxAuthResetPasswordError',
        defaultMessage: 'An error occurred during reset password',
        description: 'Message of unknown internal server error during reset password',
    },
    lxAuthResetPasswordTokenError: {
        id: 'lxAuthResetPasswordTokenError',
        defaultMessage: 'Invalid token',
        description: 'Message when the token is invalid on reset password flow',
    },
    lxAuthResetPasswordInvalidPassword: {
        id: 'lxAuthResetPasswordInvalidPassword',
        defaultMessage: 'The passwords do not match',
        description: 'Message when the passwords are invalid on reset password flow',
    },
    lxAuthResetPasswordAuthenticatedError: {
        id: 'lxAuthResetPasswordAuthenticatedError',
        defaultMessage: 'You are logged in. Log out and reopen the link to reset your password',
        description: 'Message when a user open a reset password link with an authenticated user',
    },
    lxAuthSelectRoleViewTitle: {
        id: 'lxAuthSelectRoleViewTitle',
        defaultMessage: 'Tell us more',
        description: 'Title of the select role view.',
    },
    lxAuthSelectRoleViewQuestion: {
        id: 'lxAuthSelectRoleViewQuestion',
        defaultMessage: 'How would you describe yourself?',
        description: 'Question of the select role view',
    },
    lxAuthSelectRoleViewDescription: {
        id: 'lxAuthSelectRoleViewDescription',
        defaultMessage: 'We will use this information to improve our content recommendations for you.',
        description: 'Description of the select role view',
    },
    lxAuthSelectRoleViewLearnerLabel: {
        id: 'lxAuthSelectRoleViewLearnerLabel',
        defaultMessage: 'I am a learner',
        description: 'Label of the button to select learner as a role',
    },
    lxAuthSelectRoleViewEducatorLabel: {
        id: 'lxAuthSelectRoleViewEducatorLabel',
        defaultMessage: 'I am an educator',
        description: 'Label of the button to select educator as a role',
    },
    lxAuthDoneLabel: {
        id: 'lxAuthDoneLabel',
        defaultMessage: 'Done',
        description: 'Label for buttons to finish a form.',
    },
    lxAuthSkipLabel: {
        id: 'lxAuthSkipLabel',
        defaultMessage: 'Skip',
        description: 'Label for buttons to skip a form.',
    },
    lxAuthAccountCreatedMsg: {
        id: 'lxAuthAccountCreatedMsg',
        defaultMessage: 'Account created!',
        description: 'Message of the alert when the account is created.',
    },
    lxAuthSignInModalTitle: {
        id: 'lxAuthSignInModalTitle',
        defaultMessage: 'Welcome back',
        description: 'Title of the login modal',
    },
    lxAuthSignInModalSSOSubtitle: {
        id: 'lxAuthSignInModalSSOSubtitle',
        defaultMessage: 'Sign in with email, or with other accounts.',
        description: 'Subtitle of the login modal',
    },
    lxAuthNameInputPlaceholder: {
        id: 'lxAuthNameInputPlaceholder',
        defaultMessage: 'Full name',
        description: 'Placeholder of the name input on the email sign up page',
    },
    lxAuthSignInModalEmailSubtitle: {
        id: 'lxAuthSignInModalEmailSubtitle',
        defaultMessage: 'Sign in with email.',
        description: 'Subtitle of the login modal',
    },
    lxAuthContinueLabel: {
        id: 'lxAuthContinueLabel',
        defaultMessage: 'Continue',
        description: 'Continue label used on auth pages',
    },
    lxAuthEmailSignInTitle: {
        id: 'lxAuthEmailSignInTitle',
        defaultMessage: 'Email sign-in',
        description: 'Email sign in title',
    },
    lxAuthNextLabel: {
        id: 'lxAuthNextLabel',
        defaultMessage: 'Next',
        description: 'Next label used on buttons on auth pages',
    },
    lxAuthEmailInputPlaceholder: {
        id: 'lxAuthEmailInputPlaceholder',
        defaultMessage: 'Email',
        description: 'Placeholder of the email input on the email sign up page',
    },
    lxAuthPasswordInputPlaceholder: {
        id: 'lxAuthPasswordInputPlaceholder',
        defaultMessage: 'Password',
        description: 'Placeholder of the password input on the email sign up page',
    },
    lxAuthSignUpWithEmailComponentTitle: {
        id: 'lxAuthSignUpWithEmailComponentTitle',
        defaultMessage: 'Sign up with your email',
        description: 'Title of the sign up component on the email sign up page',
    },
    lxAuthSignUpInvalidBirthdayMessage: {
        id: 'lxAuthSignUpInvalidBirthdayMessage',
        defaultMessage: 'You have to be over 13 years old to register on LabXchange. In the meantime, you can {msg}.',
        description: 'Message when the birthday is invalid. The \'msg\' is \'lxAuthExploreOurLibraryLabel\' with a link',
    },
    lxAuthExploreOurLibraryLabel: {
        id: 'lxAuthExploreOurLibraryLabel',
        defaultMessage: 'explore our library',
        description: 'Final part of \'lxAuthSignUpInvalidBirthdayMessage\'',
    },
    lxAuthSignUpInvalidEmailMessage: {
        id: 'lxAuthSignUpInvalidEmailMessage',
        defaultMessage: 'This email address is in the wrong format.',
        description: 'Message when the email is invalid on the sign up page',
    },
    lxAuthSignInInvalidEmailMessage: {
        id: 'lxAuthSignInInvalidEmailMessage',
        defaultMessage: 'The email address is not formatted correctly.',
        description: 'Message when the email is invalid on the sign in page',
    },
    lxAuthRecoverPasswordLabel: {
        id: 'lxAuthRecoverPasswordLabel',
        defaultMessage: 'Recover password',
        description: 'Recover password label used on auth pages',
    },
    lxAuthPleaseTryAgainTitle: {
        id: 'lxAuthPleaseTryAgain',
        defaultMessage: 'Please try again',
        description: 'Title of the login modal when the email doesn\'t exist',
    },
    lxAuthPleaseTryAgainSubtitle: {
        id: 'lxAuthPleaseTryAgainSubtitle',
        defaultMessage: 'We don\'t recognize this email. Please try again.',
        description: 'Subtitle of the login modal when the email doesn\'t exist',
    },
    lxAuthSingInEmailDoesntExist: {
        id: 'lxAuthSingInEmailDoesntExist',
        defaultMessage: 'This email address isn\'t in our database. Try a different one, or create a {new_account}.',
        description: 'Message when the email doesn\'t exist',
    },
    lxAuthNewAccountLabel: {
        id: 'lxAuthNewAccountLabel',
        defaultMessage: 'new account',
        description: 'New account label',
    },
    lxAuthSingInPasswordIncorrect: {
        id: 'lxAuthSingInPasswordIncorrect',
        defaultMessage: 'Password is incorrect. Try again, or {recover_password}.',
        description: 'Message when the password is incorrect',
    },
    lxAuthRecoverPasswordLowerCaseLabel: {
        id: 'lxAuthRecoverPasswordLowerCaseLabel',
        defaultMessage: 'recover password',
        description: 'Recover password label used on auth pages',
    },
    lxAuthUnableToContinueTitle: {
        id: 'lxAuthUnableToContinueTitle',
        defaultMessage: 'Unable to continue',
        description: 'Title of the fail page when the country is blocked',
    },
    lxAuthCountryFailText: {
        id: 'lxAuthCountryFailText',
        defaultMessage: 'Our system indicates that you are trying to register for an account in a country or region where LabXchange is currently unavailable.',
        description: 'Text of the fail page when the country is blocked',
    },
    lxAuthTreasuryFailTitle: {
        id: 'lxAuthTreasuryFailTitle',
        defaultMessage: 'You can’t register for an account',
        description: 'Title of the fail page when the user is on the U.S. Treasury Department sanctions list',
    },
    lxAuthTreasuryFailText: {
        id: 'lxAuthTreasuryFailText',
        defaultMessage: 'Unfortunately, your account profile appears to match one or more records on a U.S. Treasury Department sanctions list. This means we cannot provide you with services and must suspend your learner account. If you have questions regarding clearing a match, please contact {email} for information about options for clearing a match. Your account will be suspended until this matter is resolved satisfactorily.',
        description: 'Text of the fail page when the user is on the U.S. Treasury Department sanctions list',
    },
    lxAuthBlockFailTitle: {
        id: 'lxAuthBlockFailTitle',
        defaultMessage: 'Account blocked',
        description: 'Title of the fail page when the account of the user is blocked',
    },
    lxAuthBlockFailText: {
        id: 'lxAuthBlockFailText',
        defaultMessage: 'Your account has violated our terms of service, so we have blocked it and the accosiated email address {email} If you believe this is a mistake, please contact the help desk who will assist you with recovering your account.',
        description: 'Text of the fail page when the account of the user is blocked',
    },
    lxAuthAgeFailText: {
        id: 'lxAuthAgeFailText',
        defaultMessage: 'You must be over 13 years old to register a LabXchange account. We can\'t wait until you\'re ready to join us!',
        description: 'Text of the fail page when the age of the user is invalid',
    },
    lxAuthFailText1: {
        id: 'lxAuthFailText1',
        defaultMessage: 'Please continue to explore our library of free learning resources.',
        description: 'One of the common text of the fail pages',
    },
    lxAuthFailText2: {
        id: 'lxAuthFailText2',
        defaultMessage: 'In the meantime, continue to explore our library of free learning resources.',
        description: 'One of the common text of the fail pages',
    },
    lxAuthFailVisitLibraryButton: {
        id: 'lxAuthFailVisitLibraryButton',
        defaultMessage: 'Visit library',
        description: 'Text of the button to visit library on the auth fail screen',
    },
    lxAuthFailExploreButton: {
        id: 'lxAuthFailExploreButton',
        defaultMessage: 'Explore content',
        description: 'Text of the button to explore content on the auth fail screen',
    },
    lxAuthHelloAgainLabel: {
        id: 'lxAuthHelloAgainLabel',
        defaultMessage: 'Hello again',
        description: 'Message used as title in login and register pages.',
    },
    lxAuthAccountCreatedWithOtherMethod: {
        id: 'lxAuthAccountCreatedWithOtherMethod',
        defaultMessage: 'You already signed up with {email} using {provider}. Click the button below to sign into your account.',
        description: 'Message of the page when the user have created the account with other method.',
    },
    lxAuthHelpDeskLabel: {
        id: 'lxAuthHelpDeskLabel',
        defaultMessage: 'Help Desk',
        description: 'Help desk button label',
    },
    lxAuthActivateAccountTitle: {
        id: 'lxAuthActivateAccountTitle',
        defaultMessage: 'Activate your account',
        description: 'Title of the activate account modal',
    },
    lxAuthActivateAccountEmailMsg: {
        id: 'lxAuthActivateAccountEmailMsg',
        defaultMessage: 'A confirmation email has been sent to {email}.',
        description: 'Text of the activate account modal',
    },
    lxAuthActivateAccountMsg: {
        id: 'lxAuthActivateAccountMsg',
        defaultMessage: 'Follow the link in the email to activate your account. (Don\'t see the email? Try checking your spam folder.)',
        description: 'Text of the activate account modal',
    },
    lxAuthActivateAccountLabelText: {
        id: 'lxAuthActivateAccountLabelText',
        defaultMessage: 'Learn more about {link}.',
        description: 'Text of the activate account help text',
    },
    lxAuthActivateAccountLabelLink: {
        id: 'lxAuthActivateAccountLabelLink',
        defaultMessage: 'account activation',
        description: 'Text of the activate account help link',
    },
    lxAuthActivateAccountResendEmailButton: {
        id: 'lxAuthActivateAccountResendEmailButton',
        defaultMessage: 'Resend email',
        description: 'Text of the activate account resend email button',
    },
    lxAuthSkipActivateEmailButton: {
        id: 'lxAuthSkipActivateEmailButton',
        defaultMessage: 'Skip',
        description: 'Text of the skip account activation email button',
    },
    lxAuthActivateAccountResendEmailWithCountButton: {
        id: 'lxAuthActivateAccountResendEmailWithCountButton',
        defaultMessage: 'Resend email in {count}s...',
        description: 'Text of the activate account resend email button with seconds',
    },
    lxAuthResetPasswordEmailSentTitle: {
        id: 'lxAuthResetPasswordEmailSentTitle',
        defaultMessage: 'Password reset email sent',
        description: 'Title of the Reset password email sent view',
    },
    lxAuthResetPasswordEmailSentBody: {
        id: 'lxAuthResetPasswordEmailSentBody',
        defaultMessage: 'A reset password email has been sent to {email}.',
        description: 'Body of the Reset password email sent view',
    },
    lxAuthResetPasswordEmailSentBodyMessage: {
        id: 'lxAuthResetPasswordEmailSentBodyMessage',
        defaultMessage: 'Follow the link in the email to create a new password. (Don\'t see the email? Try checking your spam folder.)',
        description: 'Body of the Reset password email sent view',
    },
    lxAuthResetPasswordEmailSentButtonLabel: {
        id: 'lxAuthResetPasswordEmailSentButtonLabel',
        defaultMessage: 'Resend email',
        description: 'Label of the button of the Reset password email sent view',
    },
    lxAuthResetPasswordEmailSentButtonLabelWithCount: {
        id: 'lxAuthResetPasswordEmailSentButtonLabelWithCount',
        defaultMessage: 'Resend email in {count}s...',
        description: 'Label of the button of the Reset password email sent view',
    },
    lxAuthResetPasswordEmailSentLabelText: {
        id: 'lxAuthResetPasswordEmailSentLabelText',
        defaultMessage: 'Learn more about {link}.',
        description: 'Text of the reset password help text',
    },
    lxAuthResetPasswordEmailSentLabelLink: {
        id: 'lxAuthResetPasswordEmailSentLabelLink',
        defaultMessage: 'password reset',
        description: 'Text of the reset password help link',
    },
    lxAuthQuestionsMessage: {
        id: 'lxAuthQuestionsMessage',
        defaultMessage: 'Questions? Contact our',
        description: 'Message displayed on the authentication page to send the user to the help desk',
    },
    lxAuthResetPasswordPageTitle: {
        id: 'lxAuthResetPasswordPageTitle',
        defaultMessage: 'Reset password',
        description: 'Title of the reset password page',
    },
    lxAuthResetPasswordPageSubTitle: {
        id: 'lxAuthResetPasswordPageSubTitle',
        defaultMessage: 'Enter your email address to reset your password.',
        description: 'Sub-title of the reset password page',
    },
    lxAuthEmailAddressLabel: {
        id: 'lxAuthEmailAddressLabel',
        defaultMessage: 'Email address',
        description: 'Label used on multiple auth pages',
    },
    lxAuthResetPasswordEmailNotExist: {
        id: 'lxAuthResetPasswordEmailNotExist',
        defaultMessage: 'There is no account with this email address. Try a different email address or {link}.',
        description: 'Feedback message if the email doesn\'t exist',
    },
    lxAuthJoinAClassViewTitle: {
        id: 'lxAuthJoinAClassViewTitle',
        defaultMessage: 'Last Step',
        description: 'Title text for join a class step.',
    },
    lxAuthJoinAClassViewSubHeading: {
        id: 'lxAuthJoinAClassViewSubHeading',
        defaultMessage: 'Join a Class',
        description: 'Sub heading for join a class step.',
    },
    lxAuthJoinAClassViewDescription: {
        id: 'lxAuthJoinAClassViewDescription',
        defaultMessage: 'If an educator sent you a class code, please enter it here. By joining this class, you consent to be mentored by the educator and to receive messages from the educator and other learners in the class.',
        description: 'Description for join a class step.',
    },
    lxAuthClassCodeTextPlaceholder: {
        id: 'lxAuthClassCodeTextPlaceholder',
        defaultMessage: 'Enter class code',
        description: 'Placeholder text for input on join a class view.',
    },
    lxAuthInavlidClassCode: {
        id: 'lxAuthInavlidClassCode',
        defaultMessage: 'This looks like an invalid class code.',
        description: 'Text on the sign up page to show error about the invalid class code.',
    },
    lxAuthClassCodeHelpText: {
        id: 'lxAuthClassCodeHelpText',
        defaultMessage: 'Enter a class code that is 6 characters long.',
        description: 'Help text on the sign up page about valid class code.',
    },
    lxAuthRequestToJoinLabel: {
        id: 'lxAuthRequestToJoinLabel',
        defaultMessage: 'Request to join',
        description: 'Label for button on join a class view.',
    },
    lxAuthSkipClassCode: {
        id: 'lxAuthSkipClassCode',
        defaultMessage: 'Skip for now',
        description: 'Label for button to skip join a class step.',
    },
    lxAuthCheckInbox: {
        id: 'lxAuthCheckInbox',
        defaultMessage: 'Check your inbox',
        description: 'Label for button to check inbox.',
    },
});

export default messages;
