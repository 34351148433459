import { defineMessages } from 'react-intl';

const messages = defineMessages({
    sorryNoResultsFound: {
        id:'sorryNoResultsFound',
        defaultMessage: 'Sorry, no results found.',
        description: 'Error message when search returns no results.',
    },
    noResultsFoundSuggestion: {
        id:'noResultsFoundSuggestion',
        defaultMessage: 'Try using different keywords.',
        description: 'Suggestion to users when search results come empty.',
    },
    resultsLoadingSRMessage: {
        id:'resultsLoadingSRMessage',
        defaultMessage: 'Please wait while the search results are loading.',
        description: 'Loading message for screen readers until search results are loaded.',
    },
    loadingDoneSRMessage: {
        id:'loadingDoneSRMessage',
        defaultMessage: 'Loading done.',
        description: 'Loading completed message for screen readers.',
    },
});

export default messages;
