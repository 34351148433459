import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import { RootState } from 'global/state';
import { getLoggedInStatus, getUserEmail } from 'auth/selectors';
import { isLxLoginActive } from 'auth/utils';
import { Redirect } from 'react-router';
import { ROUTES } from 'global/constants';
import { UsersApi } from '../../../global/api';
import { AuthPageLayout, Button, showErrorMessage, TextInput } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from '../displayMessages';
import { intl } from 'i18n';
import * as EmailValidator from 'email-validator';
import { MessageDescriptor } from 'react-intl';
import * as LoginActions from 'auth/actions';
import { NavLink } from 'react-router-dom';
import { history } from 'global/history';

export enum ResetPasswordEmailStep {
    Email = 'email',
    EmailSent = 'email-sent',
}

interface Props {
    userEmail: string;
    isLoggedIn: boolean;
    step?: ResetPasswordEmailStep;  // Used for testing
}

interface State {
    email: string;
    invalidEmail: boolean;
    invalidEmailMsg?: MessageDescriptor;
    invalidEmailMsgValues?: any;
    enableButton: boolean;
    step: ResetPasswordEmailStep;
    resendCount: number;
    resendCounter?: any;
}

@connect((state: RootState) => ({
    userEmail: getUserEmail(state),
    isLoggedIn: getLoggedInStatus(state),
}), {})
export class ResetPasswordEmailView extends React.PureComponent<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: this.props.userEmail || history.location.state?.email || '',
            invalidEmail: false,
            invalidEmailMsg: undefined,
            step: this.props.userEmail ? ResetPasswordEmailStep.EmailSent : props.step || ResetPasswordEmailStep.Email,
            enableButton: false,
            resendCount: 0,
        };
    }

    public componentDidMount(){
        if (this.state.email !== '') {
            this.onFocusOutEmail(this.state.email);
        }
    }

    public render(){
        if (!isLxLoginActive()) {
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }

        return this.renderView();
    }

    @bind public renderView() {
        switch (this.state.step) {
            case ResetPasswordEmailStep.Email:
                return this.renderEmailView();
            case ResetPasswordEmailStep.EmailSent:
                return this.renderEmailSentView();
            default:
                return null;
        }
    }

    @bind public renderEmailView() {
        return (
            <AuthPageLayout
                imageUrl='/assets/images/auth-layouts/scientists.svg'
                title={intl.formatMessage(messages.lxAuthResetPasswordPageTitle)}
                subtitle={intl.formatMessage(messages.lxAuthResetPasswordPageSubTitle)}
            >
                <div className='reset-password-email-container'>
                    <div className='email-container'>
                        <span className='container-title'>
                            { intl.formatMessage(messages.lxAuthEmailAddressLabel) }
                        </span>
                        <TextInput
                            className='sign-in-input'
                            inputKey='sign-in-email-input'
                            autoComplete='off'
                            value={this.state.email}
                            onChangeValue={this.onUpdateEmail}
                            onFocusOut={this.onFocusOutEmail}
                            type='text'
                            placeholder={messages.lxAuthEmailInputPlaceholder}
                            invalid={this.state.invalidEmail}
                            feedbackMessage={this.state.invalidEmailMsg}
                            feedbackMessageValues={this.state.invalidEmailMsgValues}
                        />
                        <Button
                            className='submit'
                            btnStyle='primary'
                            disabled={!this.state.enableButton}
                            label={messages.lxAuthResetPasswordPageTitle}
                            onClick={this.sendEmail}
                        />
                    </div>
                    <div className='sign-up-container'>
                        <div className='sign-up-text'>
                            {intl.formatMessage(messages.lxAuthSignInSignUpMessage)}
                        </div>
                        <Button
                            className='sign-up-button'
                            btnStyle='link'
                            label={messages.lxAuthSignUpLavel}
                            onClick={this.onSignUpButtonClick}
                        />
                    </div>
                </div>
            </AuthPageLayout>
        );
    }

    @bind public renderEmailSentView() {
        return (
            <AuthPageLayout
                imageUrl='/assets/images/auth-layouts/email-verification.svg'
                title={intl.formatMessage(messages.lxAuthResetPasswordEmailSentTitle)}
            >
                <div className='help-text'>
                    {intl.formatMessage(
                        messages.lxAuthResetPasswordEmailSentLabelText,
                        {
                            link: <a
                                href='https://labxchange.zendesk.com/hc/en-us/articles/360058278893-Troubleshooting-Login-Difficulties'>
                                {intl.formatMessage(messages.lxAuthResetPasswordEmailSentLabelLink)}
                            </a>
                        }
                    )}
                </div>
                <div className='email-sent-view-container'>
                    <div className='body-text'>
                        <span className='email-message'>
                             {intl.formatMessage(messages.lxAuthResetPasswordEmailSentBody,
                                 {email: <b>{this.state.email}</b>})}
                        </span>
                        <br />
                       <span>
                            {intl.formatMessage(messages.lxAuthResetPasswordEmailSentBodyMessage)}
                       </span>
                    </div>
                    {
                        this.state.resendCount > 0
                            ? <Button
                                disabled={true}
                                size='sm'
                                className='resend-button'
                                label={messages.lxAuthResetPasswordEmailSentButtonLabelWithCount}
                                labelValues={{ count: this.state.resendCount }}
                            />
                            : <Button className='resend-button' size='sm' label={messages.lxAuthResetPasswordEmailSentButtonLabel} onClick={this.sendEmail} />
                    }
                </div>

            </AuthPageLayout>
        );
    }

    private resetResendCounter() {
        if (this.state.resendCounter) { clearInterval(this.state.resendCounter); }
        this.setState({ resendCounter: undefined, resendCount: 0 });
    }

    @bind private onUpdateEmail(email: string) {
        const validEmail = EmailValidator.validate(email);
        this.setState({
            email,
            invalidEmail: false,
            invalidEmailMsg: undefined,
            enableButton: validEmail,
        });
    }

    @bind private onFocusOutEmail(email: string) {
        const validEmail = EmailValidator.validate(email);
        this.setState({
            email,
            invalidEmail: !validEmail,
            enableButton: validEmail,
            invalidEmailMsg: validEmail ? undefined : messages.lxAuthSignInInvalidEmailMessage,
        });
    }

    private onSignUpButtonClick() {
        LoginActions.redirectToLxSignUp();
    }

    @bind private async sendEmail() {
        this.setState({ enableButton: false });
        this.resetResendCounter();
        this.setState({ resendCount: 60 }, () => {
            const interval = setInterval(() => {
                if (this.state.resendCount > 0) {
                    this.setState({ resendCount: this.state.resendCount - 1 });
                } else {
                    this.resetResendCounter();
                }
            }, 1000);
            this.setState({ resendCounter: interval });
        });
        try {
            await UsersApi.resetPassword({ email: this.state.email });
            if (this.state.step === ResetPasswordEmailStep.Email) {
                this.setState({ step: ResetPasswordEmailStep.EmailSent });
            }
        }
        catch (error) {
            if (error.status === 401) {
                /// Email validation errors
                const errorMessage = (await error.json()).error_message;
                if (errorMessage) {
                    showErrorMessage(errorMessage);
                }
                else {
                    this.setState({
                        invalidEmail: true,
                        invalidEmailMsg: messages.lxAuthResetPasswordEmailNotExist,
                        invalidEmailMsgValues: {link:
                            <NavLink
                                to={ROUTES.General.SIGN_UP}
                            >
                                {intl.formatMessage(messages.lxAuthSignUpLavelLowercase)}
                            </NavLink>
                        }
                    });
                }
            }
            else if (error.status === 429) {
                showErrorMessage(<WrappedMessage message={messages.lxAuthRatelimit} />);
            }
            else {
                showErrorMessage(<WrappedMessage message={messages.lxAuthResetPasswordEmailError} />);
            }
        }
    }

}