import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ValueType } from 'react-select';

import {
  ExploreContent,
  ExploreSection,
  SectionSlab,
  SectionSlabAssetTypeEnum
} from 'labxchange-client';
import { StandardPageLayout } from 'ui/components';
import { ExploreContentsApi } from 'global/api';

import SubjectPageHeader from './SubjectPageHeader';
import ClassicCardsSlab from './ClassicCardsSlab';
import PathwaysSlab from './PathwaysSlab';
import InteractiveCardsSlab from './InteractiveCardsSlab';
import ClustersSlab from './ClustersSlab';
import TopicBarCards from './TopicBarCards';
import ContentTypesSection from './ContentTypesSection';
import SkeletonSlabs from './SkeletonSlabs';
import { Topic } from './SubjectsPage';
import {
  generateSubjectDropdownOptions,
  slugToTopicsMap,
  getSubjectContentTypes
} from './utils';
import { SubjectDropdownOption } from './types';

export const SubjectPage: FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const subjectSlug = pathSegments[pathSegments.length - 1];
  const [loading, setLoading] = useState<boolean>(false);
  const [sections, setSections] = useState<ExploreSection[]>([]);
  const [sectionSlabs, setSectionSlabs] = useState<SectionSlab[]>([]);
  const [getStartedSlab, setGetStartedSlab] = useState<SectionSlab[]>([]);
  const [otherSlabs, setOtherSlabs] = useState<SectionSlab[]>([]);
  const [selectedSection, setSelectedSection] = useState<ExploreSection | null>(null);
  const dropdownOptions = generateSubjectDropdownOptions(subjectSlug);

  let topics: Topic[] = [];
  if (selectedSection && selectedSection.slug) {
    topics = slugToTopicsMap[selectedSection.slug];
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response: ExploreContent = await ExploreContentsApi.contentsRead({ id: subjectSlug });
      if (response.exploreSections) {
        setSections(response.exploreSections);
        const firstSection = response.exploreSections[0];
        if (firstSection && firstSection.sectionSlabs) {
          setSelectedSection(firstSection);
          setSectionSlabs(firstSection.sectionSlabs);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [subjectSlug]);

  useEffect(() => {
    // Get started slab should always be shown first
    if (sectionSlabs && sectionSlabs.length > 0) {
      const res = sectionSlabs.find(slab => slab.slug.includes('get-started'));
      if (res) {
        setGetStartedSlab([res]);
        setOtherSlabs(sectionSlabs.filter(slab => !slab.slug.includes('get-started')));
      } else {
        setGetStartedSlab([sectionSlabs[0]]);
        setOtherSlabs(sectionSlabs.slice(1));
      }
    }
  }, [sectionSlabs]);

  const renderSlabs = (slabs: SectionSlab[]) => {
    return (
      slabs
      .sort((a, b) => a.order - b.order)
      .map((slab: SectionSlab, index: number) => {
        if (slab.assetType === SectionSlabAssetTypeEnum.Interactives) {
          return (
            <InteractiveCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
              moreLink={slab.seeMoreLink}
              moreText='More of this topic'
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.Pathways) {
          return (
            <PathwaysSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.SimpleCards) {
          return (
            <ClassicCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
              moreLink={slab.seeMoreLink}
              moreText='More of this topic'
            />
          );
        } else if (slab.assetType === SectionSlabAssetTypeEnum.Clusters) {
          return (
            <ClustersSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        }
        return null;
      })
    );
  };

  const onSectionChange = (selectedOption: ValueType<SubjectDropdownOption>) => {
    if (selectedOption && 'label' in selectedOption) {
      const newSelectedSection = sections.find((newSection: ExploreSection) => {
        return newSection.slug === selectedOption.value;
      });
      if (newSelectedSection && newSelectedSection.sectionSlabs) {
        setSelectedSection(newSelectedSection);
        setSectionSlabs(newSelectedSection.sectionSlabs);
      }
    }
  };

  const renderSkeleton = () => {
    return <SkeletonSlabs count={4} />;
  };

  const renderSubjectContent = () => {
    return (
      <>
        {getStartedSlab && renderSlabs(getStartedSlab)}
        <TopicBarCards topics={topics} />
        <ContentTypesSection contentTypes={getSubjectContentTypes(subjectSlug)} />
        {otherSlabs && renderSlabs(otherSlabs)}
      </>
    );
  };

  return (
    <StandardPageLayout
        backgroundStyle='light-header'
        mainClassName='subject-page'
        headerFeature={
          <SubjectPageHeader
            subjectTitle={selectedSection ? selectedSection.title : ''}
            loading={loading}
            dropdownOptions={dropdownOptions}
            onSectionChange={onSectionChange}
          />
        }
    >
      {(loading || !sectionSlabs) ? renderSkeleton() : renderSubjectContent()}
    </StandardPageLayout>
  );
};
