// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ThreadPostUpdate
 */
export interface ThreadPostUpdate {
    /**
     * 
     * @type {string}
     * @memberof ThreadPostUpdate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadPostUpdate
     */
    post: string;
}

export function ThreadPostUpdateFromJSON(json: any): ThreadPostUpdate {
    return {
        'content': json['content'],
        'post': json['post'],
    };
}

export function ThreadPostUpdateToJSON(value?: ThreadPostUpdate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
        'post': value.post,
    };
}


