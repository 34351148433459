import * as React from 'react';

import { ModalConfirmation } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import messages from './displayMessages';

interface Props {
    onCanceled: () => void;
    onConfirmed: () => void;
}

export class LoginClassroomModal extends React.PureComponent<Props, {}> {

    public render() {
        return (
            <ModalConfirmation
                onCanceled={this.props.onCanceled}
                onConfirmed={this.props.onConfirmed}
                title={uiMessages.loginClassTitle}
                body={messages.loginClassText}
                cancelButtonText={messages.loginClassCancel}
                confirmButtonText={messages.loginClassSignUp}
            />
        );
    }

}
