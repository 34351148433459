import * as React from 'react';
import * as uuid from 'uuid';

import { UI_IS_LG, UI_IS_MD, UI_IS_SM, UI_IS_XS } from 'ui/breakpoints';

import { FormattedMessage, MessageDescriptor } from 'react-intl';

export function modifySet<T>(setT: Set<T>, key: T, to: boolean): boolean {
    // Ensures that this.has(key) === to
    // Returns true if a change was made to the set.

    let changed = false;
    if (to && !setT.has(key)) {
        setT.add(key);
        changed = true;
    } else if (!to) {
        changed = setT.delete(key);
    }
    return changed;
}

/**
 * Check if object has a key in a way Typescript likes.
 *
 * Otherwise when trying to access the value if the key exists on the object,
 * Typescript will complain that the key may not exist:
 *
 * if (hasKey(o, keyName)) { value = o[keyName]; }
 */
export function hasKey<O>(obj: O, key: string | number | symbol): key is keyof O {
    return key in obj;
}

interface WrappedMessageProps {
    message: MessageDescriptor;
    values?: Record<string, any>;
    children?: (message: string) => React.ReactNode;
}

// A convenience wrapper over FormattedMessage that lets us call it with a single message argument,
// instead of spreading a MessageDescriptor to props on each call.
// One of the early ideas was to use this to add pseudolocalization or middleware here,
// but this is not recommended now, because there are also many places that directly call `intl.formatMessage` or equivalent, thus bypassing this wrapper.
export const WrappedMessage = (props: WrappedMessageProps) => {
    const {message, ...other} = props;
    return (
        <FormattedMessage
            {...message}
            {...other}
        />
    );
};

// We need a wrapper for random uuids so it returns a constant value for unit tests.
export const uuid4 = () => {
    if (process.env.NODE_ENV === 'test') {
        return '387d4cd9-264c-4ffa-8aba-deb91c94220a';
    } else {
        return uuid.v4();
    }
};

export const languageLocaleProxy = {
    get<T extends {[key: string]: MessageDescriptor}>(
        target: { [key: string]: T }, key: string, receiver: any
    ): T {
        if (!(key in target)) {
            for (const loc of Object.keys(target)) {
                if (loc.toLowerCase().startsWith(key.toLowerCase())) return target[loc];
            }
            return target.en;
        }
        return target[key];
    }
};

export const getCookie = (name: string): string|null => {
    if (document.cookie === '') { return null; }
    const cookies = document.cookie.split(';');
    for (const pair of cookies) {
        const [key, val] = pair.split('=');
        if (key.trim() === name) {
            return val.trim();
        }
    }
    return null;
};

export function setCookie(name: string, val: string, expire: number) {
    const date = new Date();
    const value = val;

    // Set it expire in seconds
    date.setTime(date.getTime() + expire);

    // Set it
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

export function deleteCookie(name: string, val: string) {
    // Set it expiry to past date to delete immediately
    document.cookie = `${name}=${val}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; max-age=0`;
}

export function replicateElement(element: any, length: number) {
    const arr = [];
    for (let i = 0; i < length; i++) {
        arr.push(element);
    }
    return arr;
}


export function isURL(value: string): boolean {
    let urlValue;
    try {
        urlValue = new URL(value);
        return urlValue.protocol === 'http:' || urlValue.protocol === 'https:';
    } catch (_) {
        return false;
    }
}

/*
 * Returns the number of lines of text that can fit into element
 */
export function getMaxTextLinesInElement(element: HTMLElement): number {
    const styles = getComputedStyle(element);
    const divHeight = element.offsetHeight;
    let lineHeight = styles.lineHeight;
    if (lineHeight === 'normal') {
        lineHeight = styles.fontSize;
    }
    const lineHeightNumber = parseInt(lineHeight, 10);
    // tslint:disable-next-line:no-bitwise
    return ~~(divHeight / lineHeightNumber);
}

export function convertTimeZone(date: Date | string, tzString: string): Date {
    return new Date((typeof date === 'string' ? new Date(date) : date)
            .toLocaleString('en-US', {timeZone: tzString}));
}

export function capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function showFilterTabsUi() {
    return /^\/(library|(dashboard\/?$|dashboard\/org\/\w+))/.test(window.location.pathname);
}

export function showSearchBarInHero() {
    {/* Returns true if the path matches either library, people, or organizations. */}
    const combinedPattern = /^\/(library|people|organizations)\/?$/;
    return combinedPattern.test(window.location.pathname) && 'show_search_bar_in_hero' in window && (window as any).show_search_bar_in_hero === true;
}

export function showExperiment(name: string) {
    // Check if running on localhost or review-app domain
    const isLocalOrReviewApp = ['localhost', 'review-app.labxchange-dev.org'].some(
        v => window.location.hostname.includes(v)
    );

    // Check for the variable in the window object and if it's set to true
    const isExperimentEnabled = name in window && (window as any)[name] === true;

    return isLocalOrReviewApp || isExperimentEnabled;
}

export type LayoutSize = 'desktop' | 'large' | 'medium' | 'mobile' | 'small';

export const useLayoutSize = () => {
  const getLayout = (): LayoutSize => {
    return UI_IS_XS.matches ? 'small' : UI_IS_SM.matches ? 'mobile'
    : UI_IS_MD.matches ? 'medium' : UI_IS_LG.matches ? 'large' : 'desktop';
  };

  const [layoutSize, setLayoutSize] = React.useState<LayoutSize>(getLayout);

  React.useEffect(() => {
    const handleResize = () => {
      setLayoutSize(getLayout());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return layoutSize;
};

export function showCardDescription() {
    {/* Returns true if the path matches /library, /library/pathway/new, or /classes.
    On these pages, cards are shown in landscape mode, so we do not want to hide the description.
    For recommended assets on the asset page, we will not display the description.
    */}
    return /^(\/(library|classes))(?!(\/items\/|\/clusters\/|\/pathway\/new|\/pathway\/[^\/]+))/.test(window.location.pathname);
}

export const resourceTypes = [
    {
        name:'Learner',
        value:'learner'
    },
    {
        name:'Educator',
        value:'educator'
    },
    {
        name:'Extension learning',
        value:'extension_task'
    },
];

export const NAVBAR_EXPERIMENT = 'show_new_navbar_design';

export const toggleHelpCenterVisibility = (state: string) => {
    // @ts-ignore
    if (window.zE) {
        // @ts-ignore
        window.zE('webWidget', state);
    }
};
