import { AnnotatedVideoAnnotation } from 'labxchange-client';

/**
 * Get the tags of all [annotations] without duplications
 */
export function getTags(annotations: AnnotatedVideoAnnotation[]) {
    const tags = new Set<string>();
    annotations.forEach((annotation) => {
        annotation.tags.forEach((tag) => {
            tags.add(tag);
        });
    });
    return Array.from(tags);
}
