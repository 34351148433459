import * as React from 'react';

import { ItemResponse, ModuleSlab } from 'labxchange-client';
import { CardProps } from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import { Icon } from 'elements';
import { NavLink } from 'react-router-dom';

interface ListTypeSlabProps {
    slab?: ModuleSlab;
    heading: string;
    description?: string;
    moreLink?: string;
    moreText?: string;
}

export const ListTypeSlab: React.FC<ListTypeSlabProps> = (props) => {
  const cardsStateFromData = (slab?: ModuleSlab) => {
    if (!slab || !slab.items) return ([]);
      const items: ItemResponse[] = slab.items;
      const cardsDetail: CardProps[] = items.map((item: ItemResponse) => {
          const result: CardProps = {
              detailUrl: detailUrlForEntity(item.metadata),
              metadata: item.metadata,
              userAttributes: item.userAttributes,
              mobileViewMode: true,
              showMenuButton: true,
              isPublicContentOnly: true,
          };
          return result;
      });
      return cardsDetail;
  };

  const listTypeAssets = cardsStateFromData(props.slab);

  const renderSkeleton = () => {
      return <div></div>;
  };

  return (
        <div className='list-type-container section'>

            <h1 className='slab-title'>{props.heading}</h1>

          <div className='list-type-items'>
                {
                  listTypeAssets.length === 0 ? renderSkeleton() :
                  <>
                    {listTypeAssets.map((cardProps, index) => (
                        <NavLink className='list-type-item' key={index} to={cardProps.detailUrl ? cardProps.detailUrl : '#'}>
                            <div className='svg-container'>
                              <Icon name={props.slab?.title === 'Assessments' ? 'question-item' : 'google-doc'} />
                            </div>
                            <span className='text'>{cardProps.metadata?.title}</span>
                        </NavLink>
                    ))}
                  </>
                }
          </div>
    </div>
  );
};

export default ListTypeSlab;
