import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import Skeleton from 'react-loading-skeleton';

import { getLoggedInStatus } from 'auth/selectors';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { Classroom } from 'labxchange-client';
import { CollapsibleWithChevron } from 'elements/components/Containers';
import {
    EducatorDashboardActiveClassroomCard,
    EducatorDashboardInactiveClassroomCard,
    getFirstOwnerEducator,
    LearnerDashboardActiveClassroomCard,
    LearnerDashboardInactiveClassroomCard,
} from 'classrooms/components';
import {
    Pagination,
    PaginationSizeOptions,
    JumpTo,
} from 'ui/components';
import { CardFauxAdd } from 'ui/components/Card/Cards';
import messages from './displayMessages';
import { replicateElement, WrappedMessage } from 'utils';
import { intl } from 'i18n';

interface ReduxStateProps {
    isLoggedIn: boolean;
}

export interface ExistingClassroom extends Classroom {
    readonly id: string;  // id is mandatory
    readonly join_request_pending?: boolean;
}

const PAGINATION_SIZE_OPTIONS = [
    { label: '15', value: 15 },
    { label: '31', value: 31 },
    { label: '47', value: 47 },
];

enum SortOrder {
    RecentlyAdded = 'recent',
    AtoZ = 'atoz',
    ZtoA = 'ztoa',
}

interface Props extends RouteComponentProps<{}> {
    isEducator: boolean; // whether to display a view tailored for an educator or not
    activeClassrooms: ExistingClassroom[];
    inactiveClassrooms: ExistingClassroom[];
    selectedClassrooms?: ExistingClassroom[];
    onFauxCardClick?: () => void;
    onUnarchiveClick?: (classroom: ExistingClassroom) => void;
    onCopyClick?: (classroom: ExistingClassroom) => void;
    onArchiveClick?: (classroom: ExistingClassroom) => void;
    onDeleteClick?: (classroom: ExistingClassroom) => void;
    onRejoinClick?: (classroom: ExistingClassroom) => void;
    onSelect?: (classroom: ExistingClassroom) => void; /// Set this to display the selection buttons, only for educators
    showSkeleton?: boolean;
    hideMenus?: boolean;
    hideTopControlls?: boolean;
}

interface State {
    activeClassroomsPage: number;
    inactiveClassroomsPage: number;
    paginationSize: number;
    sortOrder: SortOrder;
}

/**
 * Shared content of educator and learner dashboards.
 */
export class ClassroomsDashboardContentInternal extends React.PureComponent<
    Props & ReduxStateProps, State
> {
    classroomSkeleton = {
        name: '',
        id: '',
        description: '',
        joinCode: '',
    } as ExistingClassroom;

    constructor(props: Props & ReduxStateProps) {
        super(props);

        this.state = {
            activeClassroomsPage: 1,
            inactiveClassroomsPage: 1,
            paginationSize: PAGINATION_SIZE_OPTIONS[0].value,
            sortOrder: SortOrder.RecentlyAdded,
        };
    }

    public static defaultProps = {
        selectedClassrooms: [],
    };

    public render() {
        // We want onchange with select elements here to update immediately when user changes.

        let activeClassrooms = this.props.showSkeleton? replicateElement(this.classroomSkeleton, 2) : this.props.activeClassrooms.slice();
        let inactiveClassrooms = this.props.inactiveClassrooms.slice();

        switch (this.state.sortOrder) {
            case SortOrder.RecentlyAdded: {
                // We assume that the default order as provided is by recently added in reverse.
                // If we want to be more deterministic here, the backend must be modified to provide creation datestamps
                activeClassrooms.reverse();
                inactiveClassrooms.reverse();
                break;
            }
            case SortOrder.AtoZ: {
                activeClassrooms.sort((x, y) => x.name.localeCompare(y.name));
                inactiveClassrooms.sort((x, y) => x.name.localeCompare(y.name));
                break;
            }
            case SortOrder.ZtoA: {
                activeClassrooms.sort((x, y) => -x.name.localeCompare(y.name));
                inactiveClassrooms.sort((x, y) => -x.name.localeCompare(y.name));
                break;
            }
        }

        const activeTotalPages = Math.ceil(activeClassrooms.length / this.state.paginationSize);
        const activeStart = this.state.paginationSize * (this.state.activeClassroomsPage - 1);
        activeClassrooms = activeClassrooms.slice(activeStart, activeStart + this.state.paginationSize);

        const inactiveTotalPages = Math.ceil(inactiveClassrooms.length / this.state.paginationSize);
        const inactiveStart = this.state.paginationSize * (this.state.inactiveClassroomsPage - 1);
        inactiveClassrooms = inactiveClassrooms.slice(inactiveStart, inactiveStart + this.state.paginationSize);

        return (<div className='classroom-dashboard-content'>
            {!this.props.hideTopControlls &&
             <div className='classrooms-controls'>
                <div className='results-count'>
                    {this.props.showSkeleton? <Skeleton /> :
                        <WrappedMessage message={messages.showingResults}
                            values={{ count: this.props.activeClassrooms.length + this.props.inactiveClassrooms.length }}
                    />}
                </div>
                {this.props.showSkeleton ? <div className='control-skeleton'><Skeleton /></div> : <>
                    {activeTotalPages > 1 &&
                        <PaginationSizeOptions
                            name='classrooms-dashboard-content-pagination-size-options'
                            value={this.state.paginationSize}
                            options={PAGINATION_SIZE_OPTIONS}
                            // eslint-disable-next-line jsx-a11y/no-onchange
                            onChange={(size) => this.setState({
                                paginationSize: size,
                                activeClassroomsPage: 1,
                                inactiveClassroomsPage: 1,
                            })}
                        />
                    }

                    <div className='sort-control-wrapper'>
                        <label htmlFor='sort-select' >
                            <WrappedMessage message={messages.sortBy} />
                        </label>
                        <select // eslint-disable-line jsx-a11y/no-onchange
                            id='sort-select'
                            className='lx-select'
                            onChange={(e) => this.setState({ sortOrder: e.currentTarget.value as unknown as SortOrder })}
                        >
                            <option value={SortOrder.RecentlyAdded}>
                                {intl.formatMessage(messages.recentlyAdded)}
                            </option>
                            <option value={SortOrder.AtoZ}>
                                {intl.formatMessage(messages.atoZ)}
                            </option>
                            <option value={SortOrder.ZtoA}>
                                {intl.formatMessage(messages.ztoA)}
                            </option>
                        </select>
                    </div>
                </>}
            </div>}
            <div className='active-classrooms-list row'>
                {this.props.onFauxCardClick &&
                    <CardFauxAdd
                        onClick={this.props.onFauxCardClick}
                        message={this.fauxCardMessage()}
                        showSkeleton={this.props.showSkeleton}
                    />
                }

                {activeClassrooms.map((classroom, index) => {
                    const classOwner = getFirstOwnerEducator(classroom);
                    if (this.props.isEducator) {
                        // TODO: refactor all the Classroom Cards to be nicer to use
                        return <EducatorDashboardActiveClassroomCard
                            key={index}
                            id={classroom.id}
                            title={classroom.name}
                            text={classroom.description}
                            code={classroom.joinCode}
                            itemCount={classroom.itemsCount}
                            membershipsCount={classroom.membershipsCount}
                            imageUrl={classroom.imageUrl}
                            onClick={() => this.onCardClicked(classroom)}
                            onCopy={() => this.props.onCopyClick && this.props.onCopyClick(classroom)}
                            onShare={() => {
                                // TODO: implement
                            }}
                            onSelect={this.props.onSelect ? () => this.props.onSelect && this.props.onSelect(classroom) : undefined}
                            isSelected={this.isSelected(classroom)}
                            onArchive={() => this.props.onArchiveClick && this.props.onArchiveClick(classroom)}
                            onDelete={() => this.props.onDeleteClick && this.props.onDeleteClick(classroom)}
                            showSkeleton={this.props.showSkeleton}
                            hideMenu={this.props.hideMenus}
                        />;
                    } else {
                        return <LearnerDashboardActiveClassroomCard
                            key={index}
                            title={classroom.name}
                            text={classroom.description}
                            imageUrl={classroom.imageUrl}
                            code={classroom.joinCode}
                            educatorName={classOwner.fullName}
                            educatorUsername={classOwner.username}
                            onClick={() => this.onCardClicked(classroom)}
                            showSkeleton={this.props.showSkeleton}
                            joinRequestPending={classroom.joinRequestPending}
                        />;
                    }
                })}
            </div>

            {activeTotalPages > 1 &&
                <div className='pagination-controls'>
                    <JumpTo
                        pageCount={activeTotalPages}
                        currentPage={this.state.activeClassroomsPage}
                        onPageSelect={(page: number) => this.setState({ activeClassroomsPage: page })}
                    />
                    <Pagination
                        pageCount={activeTotalPages}
                        currentPage={this.state.activeClassroomsPage}
                        onPageSelect={(page: number) => this.setState({ activeClassroomsPage: page })}
                    />
                </div>
            }

            {inactiveClassrooms.length > 0 &&
                <CollapsibleWithChevron
                    title={intl.formatMessage(this.inactiveClassroomsSectionTitle())}
                    headerIcon='archive'
                    headerIconZoom='20'
                    defaultOpen={true}
                >
                    <div className='inactive-classrooms'>
                        <div className='inactive-classrooms-list row'>
                            {this.props.inactiveClassrooms.map((classroom, index) => {
                                if (this.props.isEducator) {
                                    return <EducatorDashboardInactiveClassroomCard
                                        key={`inactive-${classroom.id}`}
                                        title={classroom.name}
                                        id={classroom.id}
                                        text={classroom.description}
                                        imageUrl={classroom.imageUrl}
                                        code={classroom.joinCode}
                                        onClick={() => this.onCardClicked(classroom)}
                                        onCopy={() => this.props.onCopyClick && this.props.onCopyClick(classroom)}
                                        onDelete={() => this.props.onDeleteClick && this.props.onDeleteClick(classroom)}
                                        onUnarchiveClick={
                                            () => this.props.onUnarchiveClick && this.props.onUnarchiveClick(classroom)
                                        }
                                        />;
                                } else {
                                    return <LearnerDashboardInactiveClassroomCard
                                        key={`inactive-${classroom.id}`}
                                        title={classroom.name}
                                        text={classroom.description}
                                        imageUrl={classroom.imageUrl}
                                        code={classroom.joinCode}
                                        archived={classroom.archived ? classroom.archived : false}
                                        onClick={() => this.props.onRejoinClick && this.props.onRejoinClick(classroom)}
                                        onRejoinClick={() => this.props.onRejoinClick &&  this.props.onRejoinClick(classroom)}
                                    />;
                                }
                            })}
                        </div>
                        {inactiveTotalPages > 1 &&
                             <div className='pagination-controls'>
                                <JumpTo
                                    pageCount={inactiveTotalPages}
                                    currentPage={this.state.inactiveClassroomsPage}
                                    onPageSelect={(page: number) => this.setState({ inactiveClassroomsPage: page })}
                                />
                                <Pagination
                                    pageCount={inactiveTotalPages}
                                    currentPage={this.state.inactiveClassroomsPage}
                                    onPageSelect={(page: number) => this.setState({ inactiveClassroomsPage: page })}
                                />
                            </div>
                        }
                    </div>
                </CollapsibleWithChevron>
            }
        </div>);
    }

    private inactiveClassroomsSectionTitle() {
        if (this.props.isEducator) {
            return messages.archivedClassesTitle;
        } else {
            return messages.pastClassesTitle;
        }
    }

    private fauxCardMessage() {
        if (this.props.isEducator) {
            return messages.addNewClassroomCardTitle;
        } else {
            return messages.joinClassroomCardTitle;
        }
    }

    @bind private onCardClicked(classroom: ExistingClassroom) {
        /// Open a new tab if the selection functionality is enabled
        if (this.props.onSelect) {
            window.open(ROUTES.Classrooms.CLASSROOM_SLUG(classroom.id));
        }
        else {
            this.props.history.push(ROUTES.Classrooms.CLASSROOM_SLUG(classroom.id));
        }
    }

    @bind private isSelected(classroom: ExistingClassroom) {
        return this.props.selectedClassrooms && this.props.selectedClassrooms.includes(classroom);
     }
}

export const ClassroomsDashboardContent = connect<ReduxStateProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(withRouter(ClassroomsDashboardContentInternal));
