import { defineMessages } from 'react-intl';

const messages = defineMessages({
    userAvatar: {
        id:'userAvatar',
        defaultMessage: '{username}\'s avatar',
        description: 'User specific alt text for user avatar image',
    },
    defaultAvatar: {
        id: 'defaultAvatar',
        defaultMessage: 'default avatar image',
        description: 'alt-text for the default avatar image',
    },
});

export default messages;
