import React from 'react';
import { ValueType } from 'react-select';

import { SubjectDropdownOption } from './types';
import Skeleton from 'react-loading-skeleton';

interface SubjectPageHeaderProps {
  subjectTitle: string;
  loading: boolean;
  dropdownOptions: SubjectDropdownOption[];
  onSectionChange: (selectedOption: ValueType<SubjectDropdownOption>) => void;
  defaultValue?: SubjectDropdownOption;
}

export const SubjectPageHeader: React.FC<SubjectPageHeaderProps> = (props) => {
  return (
    <header className='subject-page-header'>
      <div className='subject-title-wrapper container'>
        {props.loading ? <Skeleton width={280} height={60} /> :
          <h2 className='subject-title'>
            {props.subjectTitle}
          </h2>
        }
      </div>
    </header>
  );
};

export default SubjectPageHeader;
