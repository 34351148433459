import moment from 'moment-shortformat';
import * as React from 'react';

export interface FullDateProps {
    date: Date;
}

/**
 * A component to show a date in the format: August 9, 2017
 */
export const FullDate: React.FunctionComponent<FullDateProps> = (props) => {
    const date = moment(props.date);
    return (
        <span>{date.format('LL')}</span>
    );
};
