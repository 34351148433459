import React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { Icon } from 'elements';
import { iconColors } from './EducationPathway';

const CustomNode: React.FC<NodeProps> = ({ data }) => {
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <div className='custom-node'>
        <Icon name={data.nodeName} fill={iconColors.iconColor[data.nodeType]} />
        <span className='node-label'>{data.label}</span>
        <Icon name='arrow-right-thin' fill='black' zoom='1em' />
      </div>
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export default CustomNode;
