// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Task,
    TaskFromJSON,
    TaskToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerDuty
 */
export interface CareerDuty {
    /**
     * 
     * @type {Array<Task>}
     * @memberof CareerDuty
     */
    tasks: Array<Task>;
    /**
     * Background image representing the duty.
     * @type {string}
     * @memberof CareerDuty
     */
    readonly backgroundImage?: string;
    /**
     * Insights about the duties.
     * @type {string}
     * @memberof CareerDuty
     */
    insights?: string;
}

export function CareerDutyFromJSON(json: any): CareerDuty {
    return {
        'tasks': (json['tasks'] as Array<any>).map(TaskFromJSON),
        'backgroundImage': !exists(json, 'background_image') ? undefined : json['background_image'],
        'insights': !exists(json, 'insights') ? undefined : json['insights'],
    };
}

export function CareerDutyToJSON(value?: CareerDuty): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'tasks': (value.tasks as Array<any>).map(TaskToJSON),
        'insights': value.insights,
    };
}


