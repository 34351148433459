import { defineMessages } from 'react-intl';

const messages = defineMessages({
    markAllRead: {
        id:'markAllRead',
        defaultMessage: 'Mark all as read',
        description: 'Text of the mark-all-as-read link.',
    },
    settingsLink: {
        id:'settingsLink',
        defaultMessage: 'Notification settings',
        description: 'Text of the notification settings link.',
    },
    noNotifications: {
        id:'noNotifications',
        defaultMessage: 'You don\'t have any notifications.',
        description: 'Text of the no notification message.',
    },
    seeAllNotifications: {
        id:'seeAllNotifications',
        defaultMessage: 'See all notifications',
        description: 'Text of the see-all-notifications message.',
    },
    todayNotificationsGroup: {
        id:'todayNotificationsGroup',
        defaultMessage: 'Today',
        description: 'Text of the notifications list grouped by notifications from today.',
    },
    yesterdayNotificationsGroup: {
        id:'yesterdayNotificationsGroup',
        defaultMessage: 'Yesterday',
        description: 'Text of the notifications list grouped by notifications from yesterday.',
    },
    lastWeekNotificationsGroup: {
        id:'lastWeekNotificationsGroup',
        defaultMessage: 'Last week',
        description: 'Text of the notifications list grouped by notifications from last week.',
    },
    earlierNotificationsGroup: {
        id:'earlierNotificationsGroup',
        defaultMessage: 'Earlier',
        description: 'Text of the notifications list grouped by notifications from earlier.',
    },
});

export default messages;
