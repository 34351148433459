import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

import { getLoggedInStatus } from 'auth/selectors';
import { RootState } from 'global/state';
import {
    Button,
    showErrorMessage,
    showLoginRequiredMessage,
} from 'ui/components';

import { Classroom } from 'labxchange-client';
import { EmptyDashboardPage } from 'library/components/EmptyDashboardPage';

import {
    ClassroomModals,
    ClassroomsDashboardContent,
    ExistingClassroom,
    ModalType,
 } from 'classrooms/components';

import messages from './displayMessages';

import { ClassroomsApi } from 'global/api';
import { WrappedMessage } from 'utils';
import { authLocalStorageManager } from 'auth/utils';

interface ReduxStateProps {
    isLoggedIn: boolean;
}

interface Props extends RouteComponentProps<{}> {}

interface State {
    modalType: ModalType;
    loading: boolean;
    activeClassrooms: Classroom[];
    inactiveClassrooms: Classroom[];
    selectedClassroom?: Classroom; // For maintaining state between UI actions
}

export class LearnerClassroomsDashboardInternal extends React.PureComponent<Props & ReduxStateProps, State> {

    constructor(props: Props & ReduxStateProps) {
        super(props);

        this.state = {
            modalType: ModalType.None,
            loading: true,
            activeClassrooms: [],
            inactiveClassrooms: [],
        };
    }

    public componentDidMount() {
        this.loadData();

        // If a learner is coming from the signup page and has joined a class.
        const classId = authLocalStorageManager.signedUpUsingClassCode;
        if (classId) {
            this.setState({modalType: ModalType.RequestSent}, () => {
                // remove this so we don't loop back into this modal.
                authLocalStorageManager.signedUpUsingClassCode = null;

                // set local storage so that the welcome modal is shown
                // when the user first visits the class after being accepted.
                // Details at https://github.com/labxchange/labxchange/blob/becef7690ecd7452df226dc2f27fb0bbae86d2ca/frontend/src/classrooms/components/ClassroomModals/ClassroomModals.tsx#L426
                localStorage.setItem(`firstTimeViewingClassroom-${classId}`, 'firstTime');
            });
        }
    }

    @bind public async loadData() {
        if (!this.props.isLoggedIn) {
            // The user is not logged in, so just show an empty list so they know what
            // sort of functionality they'll get if they log in.
            this.setState({loading: false, activeClassrooms: [], inactiveClassrooms: []});
            return;
        }
        this.setState({loading: true});
        try {
            const p1 = ClassroomsApi.listAsLearner({})
                .then((activeClassrooms) => this.setState({ activeClassrooms }));
            const p2 = ClassroomsApi.inactiveAsLearner({})
                .then((inactiveClassrooms) => this.setState({ inactiveClassrooms }));

            await Promise.all([p1, p2]);
            this.setState({ loading: false });
        } catch (err) {
            showErrorMessage('Unable to load classes list.', {exception: err});
            this.setState({
                loading: false,
                activeClassrooms: [],
                inactiveClassrooms: [],
            });
        }
    }

    public render() {
        const { activeClassrooms, inactiveClassrooms } = this.state;
        if (!this.state.loading && activeClassrooms.length === 0 && inactiveClassrooms.length === 0) {
            const buttons = (
                <>
                    {this.renderClassroomModals()}
                    <Button
                        onClick={this.onJoinClassroomClick}
                        className='btn primary-button join-class-link'
                        btnStyle='primary'
                        label={messages.emptyClassroomActionButton}
                    />
                </>
            );
            return <EmptyDashboardPage
                title={messages.emtpyClassroomTitle}
                secondaryText={messages.emptyClassroomSecondaryText}
                buttons={buttons}
            />;
        }
        return (
            <div className='learner-dashboard-page'>
                {this.renderClassroomModals()}
                <h1 className='sr-only'>
                    <WrappedMessage message={messages.pageTitle}/>
                </h1>
                <p className='learner-dashboard-page-description'>
                    <WrappedMessage message={messages.pageDescription}/>
                </p>
                <ClassroomsDashboardContent
                    isEducator={false}
                    activeClassrooms={
                        activeClassrooms.filter((c) => c.id !== undefined) as ExistingClassroom[]
                    }
                    inactiveClassrooms={
                        inactiveClassrooms.filter((c) => c.id !== undefined) as ExistingClassroom[]
                    }
                    onFauxCardClick={this.onJoinClassroomClick}
                    onRejoinClick={this.onRejoinClick}
                    showSkeleton={this.state.loading}
                />
            </div>
        );
    }

    @bind private onJoinClassroomClick() {
        if (!this.props.isLoggedIn) {
            // If the user is not logged in, tell them to log in first:
            showLoginRequiredMessage();
            return;
        }
        this.setState({ modalType: ModalType.JoinClassroom });
    }

    @bind private onRejoinClick(classroom: ExistingClassroom) {
        if (classroom.archived) {
            this.setState({
                selectedClassroom: classroom,
                modalType: ModalType.ClassroomArchived,
            });
        } else {
            this.setState({
                selectedClassroom: classroom,
                modalType: ModalType.RejoinConfirmation,
            });
        }
    }

    private renderClassroomModals() {
        return (
            <div className='learner-dashboard-page'>
                <ClassroomModals
                    classroom={this.state.selectedClassroom}
                    loading={this.state.loading}
                    modalType={this.state.modalType}
                    onChangeModal={(modalType) => this.setState({ modalType })}
                    onShouldReloadData={this.loadData}
                />
            </div>
        );
    }
}

export const LearnerClassroomsDashboard = connect<ReduxStateProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(withRouter(LearnerClassroomsDashboardInternal));
