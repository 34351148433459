import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';

interface Props {
    title?: MessageDescriptor;
    sectionName: string;
    toggleSwitch?: JSX.Element;
    children?: React.ReactNode;
}

export class UserProfileSection extends React.PureComponent<Props> {
    public renderTitle() {
        if (this.props.title !== undefined) {
            return (
                <div className='user-profile-section-title'>
                    <h2><WrappedMessage message={this.props.title}/></h2>
                    <div className='user-profile-section-title-toggle-switch'>
                        {this.props.toggleSwitch}
                    </div>
                </div>
            );
        }
        return (null);
    }

    public render() {
        return (
            <div className='user-profile-section' data-section={this.props.sectionName}>
                <div className='user-profile-section-wrapper'>
                    {this.renderTitle()}
                    <div className='user-profile-section-content'>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
