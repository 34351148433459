import bind from 'bind-decorator';
import * as React from 'react';
import { UI_IS_SM } from 'ui/breakpoints';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

export interface ReorderableListItem {
    /** A unique identifier for this list item */
    key: string|number;
    /** Is this a placeholder item? (Like an "Add item to this list" card). If so, it can't be moved up or down. */
    isPlaceholder?: boolean;
    /** React node for this item. */
    html: React.ReactNode;
}

interface Props {
    items: ReadonlyArray<ReorderableListItem>;
    onMove: (fromIndex: number, toIndex: number, items: ReorderableListItem[]) => void;
    reverseOrder?: boolean;
    // Sometimes we need to conditionally globally disable dragging on the list.
    // For example, to allow working with input elements in firefox.
    // cf. https://bugzilla.mozilla.org/show_bug.cgi?id=1189486
    disableDragging?: boolean;
    showSkeleton?: boolean;
}

interface State {
    preMovingIndex?: number;
    movingIndex?: number;
    droppedIndex?: number;
    overIndex?: number;
    isSmallDevice: boolean;
}

/**
 * A list whose items can be re-ordered.
 *
 * Things to consider when using the drag-and-drop is that
 * you may need to add two CSS rules into parent component.
 *
 * Drag and drop is always disable on mobile device (UI_IS_SM).
 *
 *  - ".reorderable-list-item-content-pre-moving": applied just
 *  before the moving card shape appeared. It is used to stylize the shape. This
 *  class is removed when the card is moving.
 *  - ".reorderable-list-item-content-moving": applied on the moving card, not
 *  the shape.
 */
export class ReorderableList extends React.PureComponent<Props, State> {

    private dropZonesRefs : React.RefObject<HTMLLIElement>[];
    private mediaQuerySmall = UI_IS_SM;


    constructor(props: Props) {
        super(props);
        this.dropZonesRefs = [];
        this.state = {
            isSmallDevice: false,
        };
    }

    public componentDidMount() {
        this.mediaQuerySmall.addListener(this.setDeviceClass);
        this.setDeviceClass();
        this.setUpDropZones();
    }

    public componentDidUpdate() {
        this.setUpDropZones();
    }

    public componentWillUnmount() {
        this.mediaQuerySmall.removeListener(this.setDeviceClass);
    }

    public render() {
        const items = this.props.items;
        const onMove = this.props.onMove;
        this.dropZonesRefs = [];

        const listElement = <ol className={
            `lx-reorderable-list` +
            `${this.state.movingIndex !== undefined ? ' lx-reorderable-list-moving' : ''}`}
            >
            {items.map((item, idx) => {
                const [isFirst, isLast] = [idx === 0, idx === items.length - 1];
                const noUpMoveAllowed = item.isPlaceholder || isFirst || (items[idx - 1].isPlaceholder);
                const noDownMoveAllowed = item.isPlaceholder || isLast || (items[idx + 1].isPlaceholder);
                const elementRef = React.createRef<HTMLLIElement>();

                const draggable = !this.state.isSmallDevice && !item.isPlaceholder && !this.props.disableDragging;

                this.dropZonesRefs.push(elementRef);
                // Note: using key={idx} results in strange behavior where e.g. when you click a "move down"
                // button, the "move down" button in the old position stays focused. So we require each item
                // to have a unique key other than its index so that React knows how to update the DOM correctly.
                return <li key={item.key} ref={elementRef} className={`${item.isPlaceholder ? 'is-placeholder' : ''}`}>
                    <div className={`position-controls-wrapper ${noUpMoveAllowed ? 'no-up' : ''} ${noDownMoveAllowed ? 'no-down' : ''} ${this.props.showSkeleton ? 'skeleton' : ''}`}>
                        <div className='position-controls'>
                            <button onClick={() => onMove(idx, idx - 1, [...items])} disabled={this.props.showSkeleton ? true : noUpMoveAllowed}>
                                <WrappedMessage message={messages.moveUp}/>
                            </button>
                            <span className={this.props.showSkeleton ? 'skeleton' : ''}>{this.props.showSkeleton ? '' : this.props.reverseOrder ? items.length - idx : idx + 1}</span>
                            <button onClick={() => onMove(idx, idx + 1, [...items])} disabled={this.props.showSkeleton ? true : noDownMoveAllowed}>
                                <WrappedMessage message={messages.moveDown}/>
                            </button>
                        </div>
                    </div>
                    <div
                        role='link'
                        tabIndex={0}
                        className={
                            `reorderable-list-item-content` +
                            `${this.state.preMovingIndex === idx
                                ? ' reorderable-list-item-content-pre-moving'
                                : ''}` +
                            `${this.state.movingIndex === idx
                                ? ' reorderable-list-item-content-moving'
                                : ''}` +
                            `${this.state.droppedIndex === idx
                                ? ' reorderable-list-item-content-dropped'
                                : ''}` +
                            `${draggable ? ' draggable' : ''}`
                        }
                        aria-dropeffect='move'
                        aria-grabbed={this.state.movingIndex === idx}
                        draggable={draggable}
                        onDragStart={(event) => this.onDragStart(event, idx)}
                        onDragEnd={this.onDragEnd}
                        drop-effect-allowed='all'
                        >
                        {item.html}
                    </div>
                    {!item.isPlaceholder ? this.renderDropZone(idx + 1) : null}
                    {idx === 0
                    ? this.renderDropZone(idx)
                    : null }
                </li>;
            })}
        </ol>;
        return listElement;
    }

    private setUpDropZones() {
        if (this.state.isSmallDevice) { return; }
        let previousListElementHeight: number;

        this.dropZonesRefs.reverse().forEach((ref) => {
            // Retrieve list element
            const listElement = ref.current;
            if (!listElement) { return; }
            // Retrieve draggable zone
            const draggableElement = listElement?.querySelector(
                '.reorderable-list-item-content') as HTMLElement;
            // If draggable element is not draggable, ignore this drop zone
            if (!draggableElement || !draggableElement.draggable) { return; }
            // Get current list element height
            const listElementHeight = listElement.offsetHeight;
            // If previousListElementHeight is undefined, set as current one
            if (previousListElementHeight === undefined) {
                previousListElementHeight = listElementHeight;
            }
            // Retrieve drop zones
            let dropZoneElement: HTMLElement;
            let firstDropZoneElement: HTMLElement | undefined;
            const dropZoneElements = listElement.querySelectorAll('.reorderable-list-dropzone');
            if (!dropZoneElements) { return; }
            dropZoneElement = dropZoneElements[0] as HTMLElement;
            // If there are two drop zones, it means that this is the first element
            if (dropZoneElements.length === 2) {
                firstDropZoneElement = dropZoneElements[1] as HTMLElement;
            }

            // Compute drop zone height and top value
            let dropZoneTop: number;
            let dropZoneHeight: number;

            dropZoneTop = listElementHeight / 2;
            dropZoneHeight = listElementHeight / 2 + previousListElementHeight / 2;

            // Configure first dropzone too
            if (firstDropZoneElement !== undefined) {
                firstDropZoneElement.style.top = `${-dropZoneTop}px`;
                firstDropZoneElement.style.height = `${listElementHeight}px`;
            }

            dropZoneElement.style.top = `${dropZoneTop}px`;
            dropZoneElement.style.height = `${dropZoneHeight}px`;

            previousListElementHeight = listElementHeight;
        });
    }

    private renderDropZone(index: number) {
        return <div
            tabIndex={0}
            role='link'
            onDrop={this.onDrop}
            onDragOver={(event) => this.onDragOver(event, index)}
            onDragLeave={(event) => this.onDragLeave(event, index)}
            className='reorderable-list-dropzone'
            data-id={index}>
        </div>;
    }

    @bind private onDrop(event: React.DragEvent) {
        event.preventDefault();
    }

    @bind private onDragStart(event: React.DragEvent, index: number) {
        event.dataTransfer.effectAllowed = 'move';
        this.setState({preMovingIndex: index}, () => {
            setTimeout(() => {
                this.setState({movingIndex: index});
            });
        });
    }

    @bind private onDragOver(event: React.DragEvent, index: number) {
        event.preventDefault();
        if (this.state.overIndex !== index) {
            this.setState({overIndex: index});
        }
        event.dataTransfer.dropEffect = 'move';
    }

    @bind private onDragLeave(event: React.DragEvent, index: number) {
        event.preventDefault();
        this.setState({overIndex: undefined});
    }

    @bind private onDragEnd(event: React.DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        let newIndex = this.state.overIndex;
        const movingIndex = this.state.movingIndex;
        this.setState({droppedIndex: this.state.movingIndex}, () => {
            if (movingIndex !== undefined && newIndex !== undefined) {
                if (newIndex === movingIndex
                    || newIndex === movingIndex + 1) {
                    this.resetState();
                    return;
                }
                if (movingIndex < newIndex) {
                    newIndex = newIndex - 1;
                }
                this.props.onMove(movingIndex, newIndex!, [...this.props.items]);
            }
            this.resetState();
        });
    }

    @bind private resetState() {
        this.setState({
            preMovingIndex: undefined,
            movingIndex: undefined,
            droppedIndex: undefined,
            overIndex: undefined,
        });
    }

    @bind private setDeviceClass() {
        if (this.mediaQuerySmall.matches) {
            this.setState({isSmallDevice: true});
        } else {
            this.setState({isSmallDevice: false});
        }
    }
}
