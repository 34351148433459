import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    attachResourceTitle: {
        id:'attachResourceTitle',
        defaultMessage: 'Attach Resources',
        description: 'Heading to show resources can be attached here',
    },
    editResourceTitle: {
        id:'editResourceTitle',
        defaultMessage: 'Change Resources',
        description: 'Heading to show resources can be edited here',
    },
    addNewResourcesHeading: {
        id:'addNewResourcesHeading',
        defaultMessage: 'Add Resource',
        description: 'Heading to show resources can be added here',
    },
    editResourcesHeading: {
        id:'editResourcesHeading',
        defaultMessage: 'Edit Resource',
        description: 'Heading to show resources can be added here',
    },
    resourceCategoryLabel: {
        id:'resourceCategoryLabel',
        defaultMessage: 'Resource category',
        description: 'field label for resource category',
    },
    overrideTitleLabel: {
        id:'overrideTitleLabel',
        defaultMessage: 'Title',
        description: 'field label for resource title',
    },
    isEducatorOnlyLabel: {
        id:'isEducatorOnlyLabel',
        defaultMessage: 'Access for educator accounts only',
        description: 'field label for private resource',
    },
    saveResourceButtonText: {
        id:'saveResourceButtonText',
        defaultMessage: 'Save',
        description: 'Save action button text',
    },
    closeResourceModalActionText: {
        id:'closeResourceModalActionText',
        defaultMessage: 'Close',
        description: 'Close modal action button text',
    },
    modalResourceHeading: {
        id:'modalResourceHeading',
        defaultMessage: 'Resources',
        description: 'Heading to show on top of modal when adding a new resource',
    },
    addNewActionLabel: {
        id:'addNewActionLabel',
        defaultMessage: '+ Add New',
        description: 'action label for adding new resource',
    }
});