import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleMessagePage',
        defaultMessage: 'Messaging',
        description: 'HTML title for the messages page.',
    },
    messagesAreaTitle: {
        id:'messagesAreaTitle',
        defaultMessage: 'Messaging',
        description: 'Title for the Messages page.',
    },
    messagesAddresseeProfession: {
        id:'messagesAddresseeProfession',
        defaultMessage: '{jobTitle} at {company}',
        description: 'Addressee profession and company.',
    },
    messagesFailedLoading: {
        id:'messagesFailedLoading',
        defaultMessage: 'Could not load your messages.',
        description: 'Addressee profession and company.',
    },
    messagesFailedLoadingTopic: {
        id:'messagesFailedLoadingTopic',
        defaultMessage: 'Could not load the selected topic.',
        description: 'Addressee profession and company.',
    },
    messagesFailedLoadingUser: {
        id:'messagesFailedLoadingUser',
        defaultMessage: 'Could not find the selected user.',
        description: 'Addressee profession and company.',
    },
    messagesFailedSendingMessage: {
        id:'messagesFailedSendingMessage',
        defaultMessage: 'Could not deliver this message.',
        description: 'Failure message when sending message.',
    },
    messagesFailedSendingMessageWithReason: {
        id:'messagesFailedSendingMessageWithReason',
        defaultMessage: 'This message could not be delivered. {reason}.',
        description: 'Failure message when sending message with a reason.',
    },
    messagesFailedSenderDisabledMessaging: {
        id:'messagesFailedSenderDisabledMessaging',
        defaultMessage: `You have disabled direct messaging.
         To enable messaging, please change the setting on your profile page`,
        description: 'Failure message when sender disabled direct messaging',
    },
    messagesFailedReceiverDisabledMessaging: {
        id:'messagesFailedReceiverDisabledMessaging',
        defaultMessage: `The recipient has disabled direct messaging`,
        description: 'Failure message when receiver disabled direct messaging',
    },
    messagesFailedSenderBlocked: {
        id:'messagesFailedSenderBlocked',
        defaultMessage: `You have ended the conversation with this user`,
        description: 'Failure message when sender blocked recipient',
    },
    messagesFailedRecipientBlocked: {
        id:'messagesFailedRecipientBlocked',
        defaultMessage: `The recipient has ended the conversation with you`,
        description: 'Failure message when recipient blocked sender',
    },
    messagesEmpty: {
        id:'messagesEmpty',
        defaultMessage: `No messages to display.`,
        description: `Message to display when there is no messages`,
    },
    messagesEmptyDescription: {
        id:'messagesEmptyDescription',
        defaultMessage: `You don't have any messages right now. Do you want to message another user?
         You'll need to be in a class together or connected through mentorship.`,
        description: 'Message shown on message invitation box',
    },
    askBlockUser: {
        id:'askBlockUser',
        defaultMessage: 'Do you want to block {name}?',
        description: 'Question asking user if they are sure they wish to block a user from direct messages.',
    },
    blockUserInfo: {
        id:'blockUserInfo',
        defaultMessage: 'If you block {name}, they won\'t be able to message you unless you remove the block.',
        description: 'Information shown to the user on the block user modal.',
    },
    cancelBlockButton: {
        id:'cancelBlockButton',
        defaultMessage: 'Don\'t block',
        description: 'Button to click to cancel blocking a user and close the modal.',
    },
    blockButton: {
        id:'blockButton',
        defaultMessage: 'Block',
        description: 'Button to click to block a user.',
    },
    blockButtonAlternate: {
        id:'blockButtonAlternate',
        defaultMessage: 'Block user',
        description: 'Button to click to block a user (variant used in message request box).',
    },
    askUnblockUser: {
        id:'askUnblockUser',
        defaultMessage: 'Do you want to unblock {name}?',
        description: 'Question asking user if they are sure they wish to unblock a user from direct messages.',
    },
    unblockUserInfo: {
        id:'unblockUserInfo',
        defaultMessage: 'If you unblock {name}, they will be able to message you.',
        description: 'Information shown to the user on the unblock user modal.',
    },
    cancelUnblockButton: {
        id:'cancelUnblockButton',
        defaultMessage: 'Cancel',
        description: 'Button to click to cancel unblocking a user and close the modal.',
    },
    unblockButton: {
        id:'unblockButton',
        defaultMessage: 'Unblock',
        description: 'Button to click to unblock a user.',
    },
    messageRequest: {
        id:'messageRequest',
        defaultMessage: 'New message request from {name}',
        description: 'Heading for a new message request information box.',
    },
    acceptButton: {
        id:'acceptButton',
        defaultMessage: 'Accept',
        description: 'Button to accept a pending message request.',
    },
    acceptMessageInvitation: {
        id:'acceptMessageInvitation',
        defaultMessage: `{name} would like to send you messages.
         Click Accept if you would like to exchange messages with {name}.
         Click Decline if you do not want to receive messages from {name}.`,
         description: 'Message shown on message invitation box',
    },
    declineButton: {
        id:'declineButton',
        defaultMessage: 'Decline',
        description: 'Button to decline message request',
    },
    yourMessagePending: {
        id:'yourMessagePending',
        defaultMessage: 'Your message request is still pending.',
        description: 'Notice shown when the user is still waiting for their first message to another user to be accepted.',
    },
    youBlockedThem: {
        id:'youBlockedThem',
        defaultMessage: `You have ended the conversation with {name}.
         You will no longer be able to send them messages.`,
        description: 'Notice shown on a private message thread when the user has blocked the remote user.',
    },
    theyBlockedYou: {
        id:'theyBlockedYou',
        defaultMessage: 'This user has ended the conversation. They will no longer receive messages from you.',
        description: 'Notice shown on a private message thread when the user has been blocked by the remote user.',
    },
    undo: {
        id:'undo',
        defaultMessage: 'Undo',
        description: 'Button to undo an action.',
    },
    messageActionsMenu: {
        id:'messageActionsMenu',
        defaultMessage: 'Message actions menu',
        description: 'Title for the menu to open a dropdown with actions including report, block, etc.',
    },
    reportButton: {
        id:'reportButton',
        defaultMessage: 'Report',
        description: 'Button to report a user from the menu on a private message thread.',
    },
    organizationDashboard: {
        id:'organizationDashboard',
        defaultMessage: '{organizationName} Dashboard ',
        description: 'Title for an organization dashboard home page.',
    },
    notLoggedAlertMessageTitle: {
        id: 'notLoggedAlertMessageTitle',
        defaultMessage: 'Log in to view dashboard',
        description: 'Title of the alert page when the user tries to go to dashboard messages without being logged in',
    },
    notLoggedAlertMessageBody: {
        id: 'notLoggedAlertMessageBody',
        defaultMessage: 'You can\'t view your dashboard without logging into LabXchange. You can log in or sign up by clicking the buttons below.',
        description: 'Body of the alert page when the user tries to go to dashboard messages without being logged in',
    },
});

export default messages;
