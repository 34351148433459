import bind from 'bind-decorator';
import { ItemsApi } from 'global/api';
import {
    ItemMetadata,
} from 'labxchange-client';
import * as React from 'react';
import { Button, Modal, SearchBar } from 'ui/components';
import { LibraryContentList } from 'library/components/LibraryContentList';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage, showSuccessMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import contentPickerMessages from 'library/components/ContentPickerModal/displayMessages';
import { ItemType } from 'items/models';

interface Props {
    itemMetadata: ItemMetadata;
    onClose(): void;
}

interface State {
    selectedItems: string[];
    sending: boolean;
    keywords?: string;
    searchParams?: string;
}

export default class AssignToPathwayModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            sending: false,
            selectedItems: [],
        };
    }

    public render() {
        const searchParams = new URLSearchParams(this.state.searchParams);
        if (this.state.keywords) {
            searchParams.set('q', this.state.keywords);
        }
        return (
            <Modal
                size='large'
                onRequestClose={this.props.onClose}
                showTopBar={true}
                className='item-assign-modal item-assign-pathway-modal'
                title={
                    <WrappedMessage
                        message={messages.itemAssignToPathwayModalTitle}
                        values={{itemTitle: this.props.itemMetadata.title}}
                    />
                }
                content={
                    <div className='item-assign-pathway-modal-content'>
                        <SearchBar
                            onSubmit={this.setSearchKeywords}
                            searchMessage={contentPickerMessages.searchBoxPlaceholder}
                        />
                        <LibraryContentList
                            searchParams={searchParams.toString()}
                            onSearchParamsChanged={this.onSearchParamsChanged}
                            isModalView={true}
                            displayAddButton={true}
                            openCardDetailUrlsInNewTab={true}
                            onItemAdd={this.onItemAdd}
                            selectedItems={new Set<string>(this.state.selectedItems)}
                            multipleSelect={true}
                            showCompletion={true}
                            onlyThisTypes={[ItemType.Pathway]}
                            searchOnlyOwnedByUser={true}
                        />
                    </div>
                }
                footer={
                    this.state.selectedItems.length > 0 ?
                    <div className='modal-bottom-bar'>
                        <Button
                            btnStyle='link'
                            disabled={this.state.sending}
                            label={uiMessages.uiCancelButton}
                            onClick={this.props.onClose}/>
                        <Button
                            btnStyle='primary'
                            onClick={this.onUpdate}
                            label={messages.itemAssignToPathwayModalSubmitButton}
                            labelValues={{counter: this.state.selectedItems.length}}
                            disabled={this.state.sending || this.state.selectedItems.length === 0}
                            />
                    </div>
                    : undefined
                }
            />
        );
    }

    @bind private async onUpdate() {
        this.setState({sending: true}, async () => {
            let error;
            await Promise.all(this.state.selectedItems.map(async (itemMetadata) => {
                try {
                    await ItemsApi.pathwayAssignItems({
                        id: itemMetadata,
                        data: [this.props.itemMetadata.id],
                    });
                } catch (err) {
                    error = err;
                }
            }));
            if (error) { this.showErrorModal(error); }
            else { this.showSuccessModal(); }
            this.setState({sending: false});
            this.props.onClose();
        });
    }

    private showSuccessModal() {
        showSuccessMessage(<WrappedMessage
            message={messages.itemAssignToPathwayModalSuccessText}
            values={{count: this.state.selectedItems.length}}
        />);
    }

    private showErrorModal(error: any) {
        showErrorMessage(
            <WrappedMessage message={messages.failedToAddItem} />,
            {exception: error},
        );
    }

    @bind private onSearchParamsChanged(searchParams: URLSearchParams) {
        this.setState({
            searchParams: searchParams.toString(),
            keywords: searchParams.get('q') || undefined,
        });
    }

    @bind private setSearchKeywords(keywords: string) {
        this.setState({keywords});
    }

    @bind private onItemAdd(itemMetadata: ItemMetadata) {
        const selectedItems = this.state.selectedItems;
        const index = selectedItems.indexOf(itemMetadata.id);
        if (index > -1) {
            selectedItems.splice(index, 1);
        } else {
            selectedItems.push(itemMetadata.id);
        }
        this.setState({selectedItems}, () => this.forceUpdate());
    }
}
