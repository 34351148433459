// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ModuleHierarchy
 */
export interface ModuleHierarchy {
    /**
     * 
     * @type {string}
     * @memberof ModuleHierarchy
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleHierarchy
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleHierarchy
     */
    grade: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleHierarchy
     */
    gradeSlug: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleHierarchy
     */
    gradeName: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleHierarchy
     */
    readonly subModules?: string;
    /**
     * Get learning objectives for the current module.
     * @type {Array<string>}
     * @memberof ModuleHierarchy
     */
    readonly learningObjectives?: Array<string>;
}

export function ModuleHierarchyFromJSON(json: any): ModuleHierarchy {
    return {
        'slug': json['slug'],
        'name': json['name'],
        'grade': json['grade'],
        'gradeSlug': json['grade_slug'],
        'gradeName': json['grade_name'],
        'subModules': !exists(json, 'sub_modules') ? undefined : json['sub_modules'],
        'learningObjectives': !exists(json, 'learning_objectives') ? undefined : json['learning_objectives'],
    };
}

export function ModuleHierarchyToJSON(value?: ModuleHierarchy): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'name': value.name,
        'grade': value.grade,
        'grade_slug': value.gradeSlug,
        'grade_name': value.gradeName,
    };
}


