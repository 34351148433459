import { RootState } from 'global/state';

export const getLoggedInStatus = (state: RootState) => state.loginState.username !== '';
export const getUsername = (state: RootState) => state.loginState.username;
export const getUserEmail = (state: RootState) => state.loginState.email;
export const getAvatarUrl = (state: RootState) => state.loginState.avatarUrl;
export const getUserFullName = (state: RootState) => state.loginState.name;
export const getUserPermissions = (state: RootState) => state.loginState.permissions;
export const getLoginCookieConsent = (state: RootState) => state.loginState.cookieConsent;
export const getLoginErrorStatus = (state: RootState) => state.loginState.loginErrorMessage;
export const getHasVerifiedEmail = (state: RootState) => state.loginState.hasVerifiedEmail;
export const getRole = (state: RootState) => state.loginState.role;
export const getDateOfBirth = (state: RootState) => state.loginState.dateOfBirth;
export const getOnboardingStatus = (state: RootState) => state.loginState.onboardingStatus;
export const getCurriculumGroup = (state: RootState) => state.loginState.curriculumGroup;
