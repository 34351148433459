import * as React from 'react';
import { connect } from 'react-redux';
import { getLoggedInStatus } from 'auth/selectors';
import { Link } from 'react-router-dom';
import { DiscussionCategory } from 'labxchange-client';
import messages from '../../displayMessages';
import { RouteComponentProps } from 'react-router';
import { RootState } from 'global/state';
import { ROUTES } from 'global/constants';
import { WrappedMessage } from 'utils';
import { DiscussionSearch } from '../DiscussionSearch';
import bind from 'bind-decorator';
import { CommunityLayout } from 'dashboard';
import { DiscussionsApi } from 'global/api';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/actions';

const searchUrl = ROUTES.Community.DISCUSSIONS;

interface DiscussionCategoryItemProps {
    category: DiscussionCategory;
}

const DiscussionCategoryItem: React.FunctionComponent<DiscussionCategoryItemProps> = ({ category }) => (
    <Link
        to={ROUTES.Community.CATEGORY_ROUTE_SLUG(category.id!)}
        className='discussion-category list-group-item'
    >
        <div className='discussion-category-icon'>
            {category.icon && <img src={category.icon} alt='' />}
        </div>
        <div className='discussion-category-name'>
            <h2>{category.name}</h2>
            <p>{category.description}</p>
        </div>
        <div className='discussion-category-stats'>
            <div>
                <div className='stats-header'>Topics</div><div className='stats-content'>{category.threadCount}</div>
            </div>
            <div>
                <div className='stats-header'>Posts</div><div className='stats-content'>{category.postCount}</div>
            </div>
            <div>
                <div className='stats-header'>Views</div><div className='stats-content'>{category.viewCount}</div>
            </div>
        </div>
    </Link>
);

interface State {
    categories: ReadonlyArray<DiscussionCategory>;
}

interface StateProps {
    isLoggedIn: boolean;
}

interface Props extends StateProps, RouteComponentProps<{}> { }

@connect<StateProps, {}, {}, Props, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)
export class DiscussionHome extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    public componentDidMount() {
        if (this.props.isLoggedIn) {
            this.fetchCategories();
        }
    }

    public render() {
        const { categories } = this.state;
        const searchParams = new URLSearchParams(this.props.location.search);
        const searchOrFilter = searchParams.get('q') || searchParams.get('t');
        return (
            <CommunityLayout
                pageTitle={messages.discussionsHomePageTitle}
                searchUrl={searchUrl}
            >
                <div className='discussions-home'>
                    <h1 className='sr-only'>
                        <WrappedMessage message={messages.discussionsHomePageTitle} />
                    </h1>
                    {searchOrFilter ?
                        <DiscussionSearch
                            onSearchParamsChanged={this.onSearchParamsChanged}
                            searchParams={searchParams.toString()}
                        /> :
                        <div className='list-group'>
                            {categories && categories.map((category) => (
                                <DiscussionCategoryItem key={category.id} category={category} />
                            ))}
                        </div>
                    }
                </div>
            </CommunityLayout>
        );
    }

    public async fetchCategories() {
        try {
            const response = await DiscussionsApi.categoriesList({});
            this.setState({ categories: response.results });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorLoadingDiscussion} />, { exception: err });
        }
    }

    @bind private onSearchParamsChanged(newParams: URLSearchParams) {
        const newUrl = `${ROUTES.Community.DISCUSSIONS}?${newParams.toString()}`;
        this.props.history.push(newUrl);
    }
}
