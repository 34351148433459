import { defineMessages } from 'react-intl';

const messages = defineMessages({
    learnerProgressPathwaysTitle: {
        id:'learnerProgressPathwaysTitle',
        defaultMessage: 'Completed pathways',
        description: 'Completed pathways title for learner progress dashboard',
    },
    learnerProgressPathwaysSeeAll: {
        id:'learnerProgressPathwaysSeeAll',
        defaultMessage: 'See all',
        description: 'See all pathways button for learner progress dashboard',
    },
    learnerProgressPathwaysTabAll: {
        id:'learnerProgressPathwaysTabAll',
        defaultMessage: 'All ({count})',
        description: 'Completed pathways "All" tab for learner progress dashboard',
    },
    learnerProgressPathwaysTabFavorites: {
        id:'learnerProgressPathwaysTabFavorites',
        defaultMessage: 'Favorites ({count})',
        description: 'Completed pathways "Favorites" tab for learner progress dashboard',
    },
    learnerProgressPathwaysTabAssigned: {
        id:'learnerProgressPathwaysTabAssigned',
        defaultMessage: 'Assigned ({count})',
        description: 'Completed pathways "Assigned" tab for learner progress dashboard',
    },
    learnerProgressScoresTitle: {
        id:'learnerProgressScoresTitle',
        defaultMessage: 'Total score: {score}%',
        description: 'Score title for learner progress dashboard',
    },
    learnerProgressScoresTitleHeader: {
        id:'learnerProgressScoresTitleHeader',
        defaultMessage: 'Name',
        description: 'Name header',
    },
    learnerProgressScoresTitleHeaderSort: {
        id:'learnerProgressScoresTitleHeaderSort',
        defaultMessage: 'Sort by name',
        description: 'Help text for the sortable Name column in the table',
    },
    learnerProgressScoresContentTypeHeader: {
        id:'learnerProgressScoresContentTypeHeader',
        defaultMessage: 'Content type',
        description: 'Content type header',
    },
    learnerProgressScoresContentTypeHeaderSort: {
        id:'learnerProgressScoresContentTypeHeaderSort',
        defaultMessage: 'Sort by content type',
        description: 'Help text for the sortable Content type column in the table',
    },
    learnerProgressScoresDateHeader: {
        id:'learnerProgressScoresDateHeader',
        defaultMessage: 'Date',
        description: 'Date header',
    },
    learnerProgressScoresDateHeaderSort: {
        id:'learnerProgressScoresDateHeaderSort',
        defaultMessage: 'Sort by date',
        description: 'Help text for the sortable date column in the table',
    },
    learnerProgressScoresScoreHeader: {
        id:'learnerProgressScoresScoreHeader',
        defaultMessage: 'Score',
        description: 'Score header',
    },
    learnerProgressScoresScoreHeaderSort: {
        id:'learnerProgressScoresScoreHeaderSort',
        defaultMessage: 'Sort by score',
        description: 'Help text for the sortable score column in the table',
    },
    learnerProgressScoresSeeAll: {
        id:'learnerProgressScoresSeeAll',
        defaultMessage: 'See all',
        description: 'See all scores button for learner progress dashboard',
    },
    learnerProgressScoresItemModalCompleted: {
        id:'learnerProgressScoresItemModalCompleted',
        defaultMessage: 'Completed',
        description: `Completed label for item modal for scores widget ` +
                     `on learner progress dashboard`,
    },
    learnerProgressScoresItemModalContentType: {
        id:'learnerProgressScoresItemModalContentType',
        defaultMessage: 'Content type',
        description: `Content type label for item modal for scores widget ` +
                     `on learner progress dashboard`,
    },
    learnerProgressScoresItemModalTotalScore: {
        id:'learnerProgressScoresItemModalTotalScore',
        defaultMessage: 'Total score',
        description: `Total score label for item modal for scores widget ` +
                     `on learner progress dashboard`,
    },
    learnerProgressClassroomsTitle: {
        id:'learnerProgressClassroomsTitle',
        defaultMessage: 'Class progress',
        description: 'Class progress title for learner progress dashboard',
    },
    learnerProgressClassroomsName: {
        id:'learnerProgressClassroomsName',
        defaultMessage: '{className}',
        description: 'Class progress name for learner progress dashboard',
    },
    loadingError: {
        id:'loadingError',
        defaultMessage: 'An error occured while loading dashboard. Please try again later.',
        description: 'Error message when there is a server error while loading data.',
    },
});

export default messages;
