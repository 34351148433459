// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Banner,
    BannerFromJSON,
    BannerToJSON,
} from './';

/**
 * 
 * @export
 * @interface BannerResponse
 */
export interface BannerResponse {
    /**
     * 
     * @type {Array<Banner>}
     * @memberof BannerResponse
     */
    banners: Array<Banner>;
}

export function BannerResponseFromJSON(json: any): BannerResponse {
    return {
        'banners': (json['banners'] as Array<any>).map(BannerFromJSON),
    };
}

export function BannerResponseToJSON(value?: BannerResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'banners': (value.banners as Array<any>).map(BannerToJSON),
    };
}


