import { bind } from 'bind-decorator';
import { DiscussionsApi } from 'global/api';
import { ConversationStatusReasonEnum, Profile } from 'labxchange-client';
import messagesMessages from 'messages/components/MessagePage/displayMessages';
import * as React from 'react';
import { Modal, TextBox } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';

interface Props {
    userProfile: Profile;
    onClose(): void;
    onSubmit?(): void;
}

interface State {
    content: string;
    sending: boolean;
}

export class MessageComposeModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            content: '',
            sending: false,
        };
    }

    public render() {
        const userProfile = this.props.userProfile;
        return (
            <Modal
                onRequestClose={this.props.onClose}
                className='message-compose-modal'
                content={
                    <>
                        <div className='message-compose-modal-title'>
                            <WrappedMessage
                                message={messages.messageModalTitle}
                                values={{ fullName: userProfile && userProfile.fullName }}
                            />
                        </div>
                        <div className='message-compose-modal-content'>
                            <TextBox
                                value={this.state.content}
                                hideLabel={true}
                                placeholder={intl.formatMessage(
                                    messages.messageTextAreaPlaceholder,
                                    { fullName: userProfile && userProfile.fullName })
                                }
                                onChange={(value) => this.setState({ content: value })}
                                label={<WrappedMessage message={messages.messageTextAreaLabel} />}
                            />
                            <div className='message-compose-modal-content-info-box'>
                                <img src='/assets/images/alert-blue.svg' className='info-box-icon' alt='Alert icon' />
                                <WrappedMessage
                                    message={messages.messageInfoBoxText}
                                    values={{
                                        fullName: userProfile && userProfile.fullName,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <>
                        <button
                            className='unstyled btn-link'
                            onClick={this.props.onClose}>
                            <WrappedMessage
                                message={uiMessages.uiCancelButton}
                            />
                        </button>
                        <button
                            className='primary-button message-compose-modal-submit'
                            disabled={!this.state.content || this.state.sending}
                            onClick={this.onSendMessage}>
                            <WrappedMessage
                                message={messages.messageSubmitButton}
                            />
                        </button>
                    </>
                }
            />
        );
    }

    @bind private async onSendMessage() {
        this.setState({ sending: true });
        try {
            await DiscussionsApi.conversationsCreateMessage({
                data: {
                    content: this.state.content,
                    recipient: this.props.userProfile.username,
                },
            });
        } catch (response) {
            // this.props.onClose();
            response.json().then((body: { reason?: string }) => {
                let reason: string | undefined;
                /**
                 * Backend messages meant to be human readable but
                 * we maybe still want to have a translations table in frontend.
                 */
                if (body.reason) { reason = body.reason; }
                if (reason) {
                    if (reason === ConversationStatusReasonEnum.SenderMessagesDisabled) {
                        showErrorMessage(
                            <WrappedMessage
                                message={messagesMessages.messagesFailedSendingMessageWithReason}
                                values={{ reason: intl.formatMessage(messagesMessages.messagesFailedSenderDisabledMessaging) }}
                            />,
                            { confirmText: uiMessages.uiConfirmationButton }
                        );
                    } else if (reason === ConversationStatusReasonEnum.ReceiverMessagesDisabled) {
                        showErrorMessage(
                            <WrappedMessage message={messagesMessages.messagesFailedSendingMessageWithReason}
                                values={{ reason: intl.formatMessage(messagesMessages.messagesFailedReceiverDisabledMessaging) }}
                            />,
                            { confirmText: uiMessages.uiConfirmationButton }
                        );
                    } else if (reason === ConversationStatusReasonEnum.SenderBlockedReceiver) {
                        showErrorMessage(
                            <WrappedMessage
                                message={messagesMessages.messagesFailedSendingMessageWithReason}
                                values={{ reason: intl.formatMessage(messagesMessages.messagesFailedSenderBlocked) }}
                            />,
                            { confirmText: uiMessages.uiConfirmationButton }
                        );
                    } else if (reason === ConversationStatusReasonEnum.ReceiverBlockedSender) {
                        showErrorMessage(
                            <WrappedMessage message={messagesMessages.messagesFailedSendingMessageWithReason}
                                values={{ reason: intl.formatMessage(messagesMessages.messagesFailedRecipientBlocked) }}
                            />,
                            { confirmText: uiMessages.uiConfirmationButton }
                        );
                    } else {
                        showErrorMessage(
                            <WrappedMessage
                                message={messagesMessages.messagesFailedSendingMessageWithReason}
                                values={{ reason }}
                            />, {
                            confirmText: uiMessages.uiConfirmationButton,
                        });
                    }
                } else {
                    showErrorMessage(<WrappedMessage message={messagesMessages.messagesFailedSendingMessage} />);
                }
            });
        }
        this.setState({ sending: false });
        if (this.props.onSubmit) { this.props.onSubmit(); }
        this.props.onClose();
    }
}
