import { ChildItem } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { ExpandableText, Modal, Tag } from 'ui/components';
import { WrappedMessage } from 'utils';
import { Block } from '..';
import { getItemAndBlockData } from '../../utils';
import { BlockData, getEmptyBlockData, LoadingState } from '../Block/models';
import { ContentSourceInfoBox } from '../ContentSourceInfoBox';
import { ItemMetadataTable } from '../ItemMetadataTable';
import { AuthorsList } from '../ItemPage/AuthorsList';

interface Props {
    child: ChildItem;
    onClose: () => void;
}

interface PathwayChildPreviewModalState {
    blockData: BlockData;
    loading: boolean;
}

/** Modal for Pathway asset preview */
export class PathwayChildPreviewModal extends React.PureComponent<Props, PathwayChildPreviewModalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            blockData: getEmptyBlockData(),
            loading: true,
        };
    }

    public async componentDidMount() {
        const response = await getItemAndBlockData(this.props.child.item.metadata.id);
        if (response.blockData.loadingState === LoadingState.READY) {
            const blockDataOverrides: Partial<BlockData> = {};
            if (this.props.child.videoStartTime !== undefined && this.props.child.videoStopTime !== undefined) {
                blockDataOverrides.videoPlaybackWindow = [
                    this.props.child.videoStartTime,
                    this.props.child.videoStopTime,
                ];
            }
            this.setState({
                blockData: {...response.blockData, ...blockDataOverrides},
                loading: false,
            });
        } else {
            this.setState({
                blockData: getEmptyBlockData(),
                loading: false,
            });
        }
    }

    public render() {
        const child = this.props.child;
        const metadata = child.item.metadata;

        return (
            <Modal
                onRequestClose={this.props.onClose}
                size='large'
                className='pathway-child-preview-modal'
                showTopBar={true}
                title={<WrappedMessage message={messages.itemPreview} />}
                content={
                    <div className='row'>
                        <div className='col-lg-9 col-12 content'>
                            <h2>{metadata.title}</h2>
                            <div className='description'>
                                <ExpandableText text={metadata.description} lines={2} html={true} />
                            </div>
                            { metadata.id &&
                            <div className='preview'>
                                {this.state.loading === false &&
                                    <Block
                                        blockData={this.state.blockData}
                                        itemMetadata={metadata}
                                        onExpandBlock={(expand: boolean) => {
                                            return;
                                        }}
                                    />
                                }
                            </div>
                            }
                            {metadata.authors
                                ? <AuthorsList authors={metadata.authors} />
                                : null
                            }
                        </div>
                        <div className='w-100 d-block d-lg-none'></div>
                        <div className='col-lg-3 col-12 sidebar'>
                            <div className='content-source'>
                                {metadata.source
                                    ? <ContentSourceInfoBox itemId={metadata.id} {...metadata.source}/>
                                    : null
                                }
                            </div>
                            <div className='metadata'>
                                <ItemMetadataTable item={metadata}/>
                            </div>
                            <div className='tags'>
                                {metadata.freeFormTags.map((tag, index) => (
                                    <Tag key={index}>{tag}</Tag>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}
