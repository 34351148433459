import update from 'immutability-helper';
import { BannerState } from './models';
import * as BannerActions from './actions';
import { setCookie } from 'utils';
import { analyticsInstance } from '../tracking';
import { EVENT_NAMES } from '../tracking/constants';

const emptyBannerState: BannerState = {
    banners: [],
    dismissed: new Set(),
};

const retrieveDismissed = (): ReadonlySet<string> | undefined => {
    try {
        const dismissedSerialized = localStorage.getItem('dismissedBanners');
        if (dismissedSerialized === null) {
            return undefined;
        }

        return new Set(JSON.parse(dismissedSerialized));
    } catch (err) {
        return undefined;
    }
};

const storeDismissed = (dismissed: ReadonlySet<string>) => {
    try {
        const dismissedSerialized = JSON.stringify(Array.from(dismissed));
        localStorage.setItem('dismissedBanners', dismissedSerialized);
    } catch {
        // Ignore errors when storing.
    }
};

/**
 * Reducer that maintains the state of the banners
 */
export function bannerReducer(
    state: Readonly<BannerState> = emptyBannerState,
    action: BannerActions.ActionTypes,
): Readonly<BannerState> {
    switch (action.type) {
        case BannerActions.Types.CACHE_BANNERS:
            const dismissed = retrieveDismissed() || state.dismissed;
            return update(state, {
                banners: {$set: action.response.banners},
                dismissed: {$set: dismissed},
            });
        case BannerActions.Types.DISMISS_BANNER: {
            let bannerId = action.banner.id!;
            if (bannerId === BannerActions.ActivateEmailBannerId) {
                /// Set the cookie for one day. Used to dismiss this banner for one day
                setCookie(BannerActions.ActivateEmailDismissCookie, '1', 1 * 24 * 60 * 60 * 1000);
                const date = new Date();
                const updateId = bannerId + date.toUTCString();
                return update(state, {dismissed: {$add: [updateId]}});
            }
            if(bannerId === BannerActions.PolicyAndTermsUpdatesBannerId){
                analyticsInstance.track(EVENT_NAMES.PolicyUpdatesAcceptClicked, { url: window.location.toString() });
            }
            if (action.banner.regexUrl !== undefined) {
                const path = window.location.href;
                bannerId = action.banner.id! + ':' + btoa(path);
            }
            const newState = update(state, {dismissed: {$add: [bannerId]}});
            storeDismissed(newState.dismissed);
            return newState;
        }
        default:
            return state;
    }
}
