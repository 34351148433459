// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from 'labxchange-client/runtime';
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    uuid?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    eventVersion: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    sessionId: string;
    /**
     * The pixel density of the users display.
     * @type {number}
     * @memberof Event
     */
    devicePixelRatio?: number;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    sentAt: Date;
    /**
     * The time the event happened in the frontend. Should be in UTC.
     * @type {Date}
     * @memberof Event
     */
    readonly eventTime?: Date;
    /**
     * ('An integer field which can be used to store ', 'a number which is used frequently in expensive queries.')
     * @type {number}
     * @memberof Event
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    eventType: string;
    /**
     * URL of the page on which the event was triggered.
     * @type {string}
     * @memberof Event
     */
    url?: string;
    /**
     * URL of the previous page.
     * @type {string}
     * @memberof Event
     */
    previousUrl?: string;
    /**
     * URL of the referring page that linked to our page.
     * @type {string}
     * @memberof Event
     */
    referrer?: string;
    /**
     * The value of window.innerHeight.
     * @type {number}
     * @memberof Event
     */
    viewportHeight?: number;
    /**
     * The value of window.innerWidth.
     * @type {number}
     * @memberof Event
     */
    viewportWidth?: number;
    /**
     * 
     * @type {any}
     * @memberof Event
     */
    data: any;
}

export function EventFromJSON(json: any): Event {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'eventVersion': json['event_version'],
        'sessionId': json['session_id'],
        'devicePixelRatio': !exists(json, 'device_pixel_ratio') ? undefined : json['device_pixel_ratio'],
        'createdAt': new Date(json['created_at']),
        'sentAt': new Date(json['sent_at']),
        'eventTime': !exists(json, 'event_time') ? undefined : new Date(json['event_time']),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'eventType': json['event_type'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'previousUrl': !exists(json, 'previous_url') ? undefined : json['previous_url'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
        'viewportHeight': !exists(json, 'viewport_height') ? undefined : json['viewport_height'],
        'viewportWidth': !exists(json, 'viewport_width') ? undefined : json['viewport_width'],
        'data': (json['data']),
    };
}

export function EventToJSON(value?: Event): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'uuid': value.uuid,
        'event_version': value.eventVersion,
        'session_id': value.sessionId,
        'device_pixel_ratio': value.devicePixelRatio,
        'created_at': value.createdAt.toISOString(),
        'sent_at': value.sentAt.toISOString(),
        'number': value.number,
        'event_type': value.eventType,
        'url': value.url,
        'previous_url': value.previousUrl,
        'referrer': value.referrer,
        'viewport_height': value.viewportHeight,
        'viewport_width': value.viewportWidth,
        'data': (value.data),
    };
}


