import { Action } from 'redux';
import { createAction } from 'redux-actions';

export enum Types {
    SHOW_SIDEBAR = 'SHOW_SIDEBAR',
    HIDE_SIDEBAR = 'HIDE_SIDEBAR',
}

export interface ShowSidebar extends Action {
    readonly type: Types.SHOW_SIDEBAR;
}

export interface HideSidebar extends Action {
    readonly type: Types.HIDE_SIDEBAR;
}

export type ActionTypes =
    | ShowSidebar
    | HideSidebar;

export const showSidebar = createAction(Types.SHOW_SIDEBAR);

export const hideSidebar = createAction(Types.HIDE_SIDEBAR);
