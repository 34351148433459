// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CookieConsent
 */
export interface CookieConsent {
    /**
     * 
     * @type {boolean}
     * @memberof CookieConsent
     */
    strictlyNecessary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CookieConsent
     */
    analytics?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CookieConsent
     */
    marketing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CookieConsent
     */
    performance?: boolean;
}

export function CookieConsentFromJSON(json: any): CookieConsent {
    return {
        'strictlyNecessary': !exists(json, 'strictly_necessary') ? undefined : json['strictly_necessary'],
        'analytics': !exists(json, 'analytics') ? undefined : json['analytics'],
        'marketing': !exists(json, 'marketing') ? undefined : json['marketing'],
        'performance': !exists(json, 'performance') ? undefined : json['performance'],
    };
}

export function CookieConsentToJSON(value?: CookieConsent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'strictly_necessary': value.strictlyNecessary,
        'analytics': value.analytics,
        'marketing': value.marketing,
        'performance': value.performance,
    };
}


