import { InputSelect } from '@edx/paragon';
import { bind } from 'bind-decorator';
import * as React from 'react';

import { Dropdown, Icon } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';

interface SortOptionsProps {
    name: string;
    value: string;
    options: {label: string, value: string}[];
    onChange: (sortOrder: string) => void;
    isMobileView?: boolean;
}

export class SortOptions extends React.Component<SortOptionsProps> {

    public render() {
        const {name, value, options, onChange, isMobileView} = this.props;

        if (isMobileView) {
            const icon = <Icon name='sort' zoom='1.4em' />;

            return (
                <Dropdown
                    id='sort-options-dropdown'
                    label={icon}
                    title={intl.formatMessage(messages.sortOptionsLabel)}
                    className='sort-options'
                >
                    <ul>
                        {options.slice().map((option, i) => {
                                const isSelected = option.value === this.props.value;
                                return (
                                    <li key={`option-${i}`}>
                                        <button
                                            onClick={(e) => this.onClickOption(e, option.value)}
                                            className={isSelected ? 'selected' : ''}
                                        >
                                            {option.label}
                                        </button>
                                    </li>
                                );
                        })}
                    </ul>
                </Dropdown>
            );
        } else {
            const label = (
                <span className='sr-only'>
                    <WrappedMessage message={messages.sortOptionsLabel}/>
                </span>
            );

            const inputSelect = (
                <div className='custom-select-sort-wrapper'>
                    <InputSelect
                        className='custom-select-sort'
                        label={label}
                        name={name}
                        value={value}
                        options={options}
                        onChange={onChange}
                    />
                    <Icon name='chevron-down' />
                </div>
            );

            return (
                <div className='sort-options'>
                    <WrappedMessage
                        message={messages.sortOptionsText}
                        values={{
                            options: inputSelect,
                        }}
                    />
                </div>
            );
        }
    }

    @bind private onClickOption(e: React.MouseEvent, value: string) {
        this.props.onChange(value);
    }
}
