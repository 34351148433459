import { defineMessages } from 'react-intl';

const messages = defineMessages({
    resultsCountTotal: {
        id:'resultsCountTotal',
        defaultMessage: 'Showing {totalCount, number} {totalCount, plural, one {result} other {results}}',
        description: 'Label for total number of results.',
    },
    resultsCountTotalNew: {
        id:'resultsCountTotalNew',
        defaultMessage: '{totalCount, number} {totalCount, plural, one {result} other {results}}',
        description: 'Label for total number of results with search query.',
    },
    resultsCountTotalWithQuery: {
        id:'resultsCountTotalWithQuery',
        defaultMessage: '{totalCount, number} {totalCount, plural, one {result} other {results}} for "{searchQuery}"',
        description: 'Label for total number of results with search query.',
    },
});

export default messages;
