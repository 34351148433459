import Drawer from '@material-ui/core/Drawer';
import bind from 'bind-decorator';
import { Resizable, ResizeCallback } from 're-resizable';
import * as React from 'react';

import { HtmlTextBox, TagsEditor } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';
import uiMessages from 'ui/components/displayMessages';
import { Button } from 'ui/components';

const MAX_TITLE_LENGTH = 250;

interface Props {
    close: () => void;
    open: boolean;
    parentNode?: HTMLElement;
    sending?: boolean;
    onSubmit: (title: string, content: string, tags?: string[]) => void;
    disableTitle?: boolean;
    defaultContent?: string;
    defaultTitle?: string;
    tags?: string[];
    onTagsUpdate?: (tags: string[]) => void;
    hideTags?: boolean;
    disableNewTags?: boolean;
}

interface State {
    maximized: boolean;
    currentHeight: number;
    title: string;
    content: string;
    tags: string[];
}

export class ThreadComposer extends React.PureComponent<Props, State> {
    private titleInputRef: React.RefObject<HTMLInputElement>;
    private contentInputRef: React.RefObject<HTMLTextAreaElement>;

    private defaultHeight: number;
    private minHeight: number;
    private maxTags: number;

    constructor(props: any) {
        super(props);
        this.titleInputRef = React.createRef();
        this.contentInputRef = React.createRef();
        this.defaultHeight = window.innerHeight * 0.40;
        this.minHeight = 375;
        this.maxTags = 10;
        this.state = {
            maximized: false,
            currentHeight: this.minHeight + (props.disableTitle ? 0 : props.hideTags ? 40 : 140),
            title: this.props.defaultTitle || '',
            content: this.props.defaultContent || '',
            tags: this.props.tags || [],
        };
    }

    public componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && this.props.open) {
            document.addEventListener('scroll', () => this.forceUpdate());
            let inputEl: any = this.titleInputRef.current;
            if (this.props.disableTitle) {
                inputEl = this.contentInputRef.current;
            }
            if (inputEl) { inputEl.focus(); }
        } else if (prevProps.open && !this.props.open) {
            document.removeEventListener('scroll', () => this.forceUpdate());
        }
        if (prevProps.open !== this.props.open) {
            this.resetValues();
        }
    }

    public componentDidUnMount() {
        document.removeEventListener('scroll', () => this.forceUpdate());
        this.resetValues();
    }

    public render() {
        const { open, parentNode, sending } = this.props;
        const { currentHeight, maximized } = this.state;
        const isSubmitDisabled = sending
            || (!this.state.content || (!this.props.disableTitle && !this.state.title));
        const width = '100%';
        let maxHeight: number;
        if (parentNode) {
            maxHeight = window.innerHeight - parentNode.getBoundingClientRect().top;
        } else {
            maxHeight = this.defaultHeight;
        }
        const height = maximized ? maxHeight : currentHeight;
        return (
            <Drawer anchor='bottom' open={open} variant='persistent'>
                <Resizable
                    size={{ width, height }}
                    maxHeight={maxHeight}
                    minHeight={this.minHeight}
                    enable={{
                        top: maximized ? false : true,
                        right: false,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    onResizeStop={this.onResize}
                    className='resizable'
                >
                    <form className='thread-composer container'>
                        <div className='thread-composer-input'>
                            {!this.props.disableTitle
                                ?
                                <label
                                    className={`thread-composer-title-input
                                        ${this.state.title.length > MAX_TITLE_LENGTH ? 'error' : ''}`}
                                >
                                    Title
                                    <input
                                        maxLength={MAX_TITLE_LENGTH}
                                        required
                                        type='text'
                                        ref={this.titleInputRef}
                                        defaultValue={this.props.defaultTitle || ''}
                                        onChange={(e) => this.setState({ title: e.currentTarget.value })}
                                        onBlur={(e) => this.setState({ title: e.currentTarget.value })}
                                    />
                                    <div className='character-count'>
                                        <WrappedMessage message={messages.characters}
                                            values={{
                                                current: this.state.title.length,
                                                max: MAX_TITLE_LENGTH,
                                            }}
                                        />
                                    </div>
                                </label>
                                : null
                            }
                            <HtmlTextBox
                                hideLabel={true}
                                label={intl.formatMessage(messages.composeLabel)}
                                defaultValue={this.state.content}
                                fullHeight={true}
                                onChange={(e) => this.setState({ content: e })}
                            />
                            {!this.props.hideTags && !this.props.disableTitle &&
                                <div className='thread-tags-editor'>
                                    <TagsEditor
                                        label={messages.threadTagsAdd}
                                        placeholder={messages.threadTagsPlaceholder}
                                        tags={this.state.tags}
                                        showErrors={false}
                                        required={false}
                                        onChange={this.updateFreeFormTags}
                                        hideToast={true}
                                        disableNewTags={this.props.disableNewTags}
                                        maxTags={this.maxTags}
                                        maxTagsError={messages.maxTagsError}
                                        maxTagsErrorValues={{ max: this.maxTags }}
                                    />
                                </div>
                            }
                            <div className='thread-composer-footer'>
                                <Button
                                    className='cancel-button'
                                    btnStyle='outline'
                                    label={uiMessages.uiCancelButton}
                                    onClick={this.onClickCancel}
                                />
                                <button
                                    onClick={this.onClickSubmit}
                                    className={
                                        'btn btn-light thread-composer-post-button ' +
                                        `${isSubmitDisabled ? '' : 'thread-composer-post-button-active'}` +
                                        `${sending ? 'spinner' : ''}`}
                                    disabled={isSubmitDisabled}
                                >
                                    <WrappedMessage message={messages.composePost} />
                                </button>
                            </div>
                        </div>
                    </form>
                </Resizable>
            </Drawer>
        );
    }

    @bind private updateFreeFormTags(tags: string[]) {
        this.setState({
            tags,
        });
        if (this.props.onTagsUpdate) {
            this.props.onTagsUpdate(tags);
        }
    }


    private onResize: ResizeCallback = (e, dir, ref, delta) => {
        e.preventDefault();
        this.setState({ currentHeight: this.state.currentHeight + delta.height });
    }

    @bind private async onClickSubmit(e: React.MouseEvent) {
        e.preventDefault();
        const isValid = (this.props.disableTitle || this.state.title)
            && this.state.content;
        if (isValid) {
            let error = false;
            try {
                await this.props.onSubmit(this.state.title, this.state.content, this.state.tags);
            } catch (e) {
                error = true;
            }

            if (!error) {
                this.setState({ content: '', title: '', tags: [] });
                this.resetValues();
                this.props.close();
                this.forceUpdate();
            }
        }
    }

    @bind private onClickCancel() {
        this.resetValues();
        this.props.close();
    }

    private resetValues() {
        if (this.titleInputRef !== null && this.titleInputRef.current) {
            this.titleInputRef.current.value = '';
        }
        this.setState({ content: '', title: '', tags: this.props.tags || [] });
    }
}
