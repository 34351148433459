// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemMetadata,
    ItemMetadataFromJSON,
    ItemMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompletedPathway
 */
export interface CompletedPathway {
    /**
     * 
     * @type {ItemMetadata}
     * @memberof CompletedPathway
     */
    pathway: ItemMetadata;
    /**
     * 
     * @type {Date}
     * @memberof CompletedPathway
     */
    completionDate: Date;
}

export function CompletedPathwayFromJSON(json: any): CompletedPathway {
    return {
        'pathway': ItemMetadataFromJSON(json['pathway']),
        'completionDate': new Date(json['completion_date']),
    };
}

export function CompletedPathwayToJSON(value?: CompletedPathway): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pathway': ItemMetadataToJSON(value.pathway),
        'completion_date': value.completionDate.toISOString(),
    };
}


