/**
 * Displays the Favorite icon
 *
 * Can be clicked/selected to add/remove the item from the current user's list
 * of favorites.
 */
import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { MessageDescriptor } from 'react-intl';

import { getLoggedInStatus } from 'auth/selectors';
import { FavoritesApi } from 'global/api';
import { RootState } from 'global/state';
import messages from 'library/displayMessages';
import { showErrorMessage, showLoginRequiredMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { intl } from 'i18n';
import classNames from 'classnames';
import { Icon } from 'elements/components/Icons';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';

export interface ReduxStateProps {
    isLoggedIn: boolean;
}

export interface FavoriteProps {
    itemId: string;
    isFavorite: boolean;
    btnStyle?: 'small'|'large'|'default'|'svg'| 'pathway-svg';
    color?: 'white'|'default';
    showLabel?: boolean;
    titleAddFavorite?: MessageDescriptor;
    titleRemoveFavorite?: MessageDescriptor;
    showTooltip?: boolean;
    itemType?: string;
}
interface Props extends ReduxStateProps, FavoriteProps {}

interface FavoriteState {
    isFavorite: boolean;
}

class FavoriteButtonInternal extends React.PureComponent<Props, FavoriteState> {

    public static defaultProps = {
        showLabel: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isFavorite: props.isFavorite,
        };
    }

    titleMessage() {
        const actionAdd = this.props.titleAddFavorite || messages.actionAddFavorite;
        const actionRemove = this.props.titleRemoveFavorite || messages.actionRemoveFavorite;
        return this.state.isFavorite ? actionRemove : actionAdd;
    }

    private labelMessage() {
        const actionAdd = messages.actionFavorite;
        const actionRemove = this.props.titleRemoveFavorite || messages.actionRemoveFavorite;
        return this.state.isFavorite ? actionRemove : actionAdd;
    }

    private sizedButton(iconClasses: string) {
        const size = this.props.btnStyle === 'small'? 'small' : 'large';
        return (
            <button
                className={classNames(`favorite-button favorite-button-${size}`)}
                title={intl.formatMessage(this.titleMessage())}
                onClick={this.onToggleFavorite}
                onKeyPress={this.onToggleFavorite}
            >
                <div className={iconClasses}></div>
                {this.props.showLabel ?
                    <WrappedMessage message={this.labelMessage()}>
                        {(label: string) => (
                            <div className='favorite-label'>{label}</div>
                        )}
                    </WrappedMessage>
                    : null}
            </button>
        );
    }

    private defaultButton(iconClasses: string) {
        return (
            <button
                className={classNames('btn lx-btn-outline lx-btn-left-icon button favorite-button favorite-button-dark')}
                onClick={this.onToggleFavorite}
            >
                <div className={iconClasses}></div>
                {this.props.showLabel &&
                    <div className={`favorite-label ${this.props.color}`}>
                        <WrappedMessage message={this.labelMessage()} />
                    </div>
                }
            </button>
        );
    }

    private renderSvgButton() {
        return (
            <button
                className='favorite-svg-button tooltip-button favorite-button action-button-link-design'
                onClick={this.onToggleFavorite}
                aria-label={intl.formatMessage(messages.ariaFavoriteButton)}
            >
                <Icon name={this.state.isFavorite ? 'favorite-toggled' : 'favorite'} />
                {this.props.showLabel &&
                  <span className='favorite-label action-button-link-design'>
                    <WrappedMessage message={this.labelMessage()}/>
                  </span>
                }
                {this.props.showTooltip &&
                  <span className='tooltip-span'>
                        <WrappedMessage message={this.labelMessage()}/>
                 </span>
                }
            </button>
        );
    }

    public render() {
        // TODO: this should use Icon instead of custom css classes and svg background images.

        const iconClasses = `${this.props.color} favorite-icon` + (this.state.isFavorite ? ' is-favorite' : '' );
        if (this.props.btnStyle === 'default') {
            return this.defaultButton(iconClasses);
        } else if (this.props.btnStyle === 'svg' || this.props.btnStyle === 'pathway-svg') {
            return this.renderSvgButton();
        }
        return this.sizedButton(iconClasses);
    }

    @bind private async onToggleFavorite() {
        if (!this.props.isLoggedIn) {
            showLoginRequiredMessage();
            return;
        }

        let isFavorite = this.state.isFavorite;
        try {
            if (isFavorite) {
                await FavoritesApi._delete({item: this.props.itemId});
                isFavorite = false;
            } else {
                await FavoritesApi.create({data: {item: this.props.itemId}});
                isFavorite = true;
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorChangeFavorite}/>, {
                exception: err,
            });
            return;
        }
        this.setState({isFavorite});
        analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
            url: window.location.toString(),
            actionText: isFavorite ? 'favorited' : 'remove from favorite',
            itemType: this.props.itemType || ''
        });
    }
}

export const FavoriteButton = connect<ReduxStateProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(FavoriteButtonInternal);
