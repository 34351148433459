import { ImageOption, ImageOptions } from 'assets/images/pathway-cover-images';
import { bind } from 'bind-decorator';
import { PATHWAY_COVER_IMAGE_URL } from 'global/constants';
import messages from 'library/displayMessages';
import * as React from 'react';
import { Modal } from 'ui/components';
import { WrappedMessage } from 'utils';

interface Props {
    images: ImageOption[];
    defaultValue?: string;
    onUpdate: (imageUrl: string) => void;
    onClose: () => void;
}

interface State {
    selectedImage?: number;
}

export class PathwayCoverUpdateModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedImage: this.props.defaultValue ? this.getSelectedImage(
                this.props.defaultValue) : undefined,
        };
    }

    public render() {
        const images: JSX.Element[] = [];
        ImageOptions.forEach((image: ImageOption, index: number) => {
            const selected = index === this.state.selectedImage;
            images.push(
                <button
                    key={index}
                    className={`image-option unstyled ${selected ? 'image-option-selected' : ''}`}
                    onClick={() => this.onClickImage(index)}>
                    <img src={PATHWAY_COVER_IMAGE_URL(image.value)} alt={image.label}/>
                </button>,
            );
        });
        return (
            <Modal
                onRequestClose={this.onClose}
                showTopBar={true}
                className='pathway-cover-image-modal'
                title={
                    <WrappedMessage message={messages.pathwayImageModalTitle} />
                }
                content={<>{images}</>}
                footer={
                    <div className='modal-bottom-bar'>
                        <button
                            className='primary-button btn-outline-primary'
                            onClick={this.onUpdate}
                        >
                            <WrappedMessage message={messages.pathwayImageModalSubmitButton}/>
                        </button>
                    </div>
                }
            />
        );
    }

    @bind private onClickImage(index: number) {
        this.setState({selectedImage: index});
        this.props.onUpdate(this.props.images[index].value);
    }

    @bind private onUpdate() {
        this.props.onClose();
    }

    @bind private onClose() {
        if (this.props.defaultValue) {
            this.props.onUpdate(this.props.defaultValue);
        }
        this.props.onClose();
    }

    private getSelectedImage(imageUrl: string) {
        return this.props.images.findIndex((x) => x.value === imageUrl);
    }
}
