// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Edition,
    EditionFromJSON,
    EditionToJSON,
    Section,
    SectionFromJSON,
    SectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Book
 */
export interface Book {
    /**
     * 
     * @type {string}
     * @memberof Book
     */
    id: string;
    /**
     * 
     * @type {Array<Section>}
     * @memberof Book
     */
    sections: Array<Section>;
    /**
     * 
     * @type {Array<Edition>}
     * @memberof Book
     */
    editions: Array<Edition>;
    /**
     * Date the book was published.
     * @type {Date}
     * @memberof Book
     */
    publishedDate?: Date;
    /**
     * Date the book was updated here.
     * @type {Date}
     * @memberof Book
     */
    updatedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Book
     */
    type: BookTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Book
     */
    imageUrl: string;
}

export function BookFromJSON(json: any): Book {
    return {
        'id': json['id'],
        'sections': (json['sections'] as Array<any>).map(SectionFromJSON),
        'editions': (json['editions'] as Array<any>).map(EditionFromJSON),
        'publishedDate': !exists(json, 'published_date') ? undefined : new Date(json['published_date']),
        'updatedDate': !exists(json, 'updated_date') ? undefined : new Date(json['updated_date']),
        'type': json['type'],
        'imageUrl': json['image_url'],
    };
}

export function BookToJSON(value?: Book): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'sections': (value.sections as Array<any>).map(SectionToJSON),
        'editions': (value.editions as Array<any>).map(EditionToJSON),
        'published_date': value.publishedDate === undefined ? undefined : value.publishedDate.toISOString(),
        'updated_date': value.updatedDate === undefined ? undefined : value.updatedDate.toISOString(),
        'type': value.type,
        'image_url': value.imageUrl,
    };
}

/**
* @export
* @enum {string}
*/
export enum BookTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}


