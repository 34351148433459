// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomError
 */
export interface ClassroomError {
    /**
     * 
     * @type {string}
     * @memberof ClassroomError
     */
    errorType: ClassroomErrorErrorTypeEnum;
}

export function ClassroomErrorFromJSON(json: any): ClassroomError {
    return {
        'errorType': json['error_type'],
    };
}

export function ClassroomErrorToJSON(value?: ClassroomError): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'error_type': value.errorType,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClassroomErrorErrorTypeEnum {
    ClassroomIsFull = 'Classroom is Full',
    ClassroomIsArchived = 'Classroom is Archived',
    EducatorCannotJoinTheirOwnClassroom = 'Educator cannot join their own classroom'
}


