import { bind } from 'bind-decorator';
import moment from 'moment-shortformat';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';

import { CalendarDate } from './CalendarDate';
export { CalendarDate };

interface DateInputProps {
    id?: string;
    label: MessageDescriptor;
    hiddenLabel?: boolean;
    defaultValue?: CalendarDate;
    onChange: (date?: CalendarDate) => void;
}

interface DateInputState {
    day?: number;
    month?: number; // Month, where 1 is January
    year?: number;
}

/**
 * A UI input for specifying a full date (year, month, and day)
 *
 * Note: never use a JS `Date` object to represent a date! It's too easy
 * to get off-by-one errors due to timezones, etc.
 */
export class DateInput extends React.PureComponent<DateInputProps, DateInputState> {

    constructor(props: DateInputProps) {
        super(props);
        const defaultDate = this.props.defaultValue;
        this.state = {
            day: defaultDate ? defaultDate.day : undefined,
            month: defaultDate ? defaultDate.month : undefined,
            year: defaultDate ? defaultDate.year : undefined,
        };
    }

    public render() {
        return (
            <fieldset className='form-group date-input'>
                <legend className={this.props.hiddenLabel ? 'sr-only' : ''}>
                    <WrappedMessage message={this.props.label}/>
                </legend>
                <div>
                    <select
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaMonthLabel)}
                        defaultValue={this.state.month !== undefined ? this.state.month.toString() : undefined}
                        onBlur={(e) => this.onMonthChange(e.currentTarget.value)}
                        onChange={(e) => this.onMonthChange(e.currentTarget.value)}>
                        {this.renderMonthSelect()}
                    </select>
                    <select
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaDayLabel)}
                        defaultValue={this.state.day !== undefined ? this.state.day.toString() : undefined}
                        onBlur={(e) => this.onDayChange(e.currentTarget.value)}
                        onChange={(e) => this.onDayChange(e.currentTarget.value)}>
                        {this.renderDaySelect(this.state.year, this.state.month)}
                    </select>
                    <select
                        className='form-control'
                        aria-label={intl.formatMessage(messages.ariaYearLabel)}
                        defaultValue={this.state.year !== undefined ? this.state.year.toString() : undefined}
                        onBlur={(e) => this.onYearChange(e.currentTarget.value)}
                        onChange={(e) => this.onYearChange(e.currentTarget.value)}>
                        {this.renderYearSelect()}
                    </select>
                </div>
            </fieldset>
        );
    }

    private renderDaySelect(year?: number, month?: number) {
        const days: JSX.Element[] = [];
        const daysInMonth = new Date(Date.UTC(year || 1970, (month || 1), 0)).getUTCDate();
        days.push(
            <option key='default-day' value=''>{intl.formatMessage(messages.defaultDay)}</option>
        );
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(
                <option key={i} value={i}>
                    {i}
                </option>,
            );
        }
        return days;
    }

    private renderMonthSelect() {
        const months: JSX.Element[] = [];
        months.push(
            <option key='default-month' value=''>{intl.formatMessage(messages.defaultMonth)}</option>
        );
        for (let i = 0; i < 12; i++) {
            months.push(
                <option key={i} value={i + 1}>
                    {moment.months(i)}
                </option>,
            );
        }
        return months;
    }

    private renderYearSelect() {
        const years: JSX.Element[] = [];
        const currentYear = moment().year();
        years.push(
            <option key='default-year' value=''>{intl.formatMessage(messages.defaultYear)}</option>
        );
        for (let i = currentYear; i >= currentYear - 110; i--) {
            years.push(
                <option key={i} value={i}>
                    {i}
                </option>,
            );
        }
        return years;
    }

    @bind private onChange() {
        if (this.state.day !== undefined &&
            this.state.month !== undefined &&
            this.state.year !== undefined
        ) {
            this.props.onChange(new CalendarDate(this.state.year, this.state.month, this.state.day));
        } else {
            this.props.onChange();
        }
    }

    @bind private onYearChange(yearStr: string) {
        let year: number|undefined = parseInt(yearStr, 10);
        if (isNaN(year)) { year = undefined; }
        this.setState({year}, () => this.onChange());
    }

    @bind private onMonthChange(monthStr: string) {
        let month: number|undefined = parseInt(monthStr, 10);
        if (isNaN(month)) { month = undefined; }
        this.setState({month}, () => this.onChange());
    }

    @bind private onDayChange(dayStr: string) {
        let day: number|undefined = parseInt(dayStr, 10);
        if (isNaN(day)) { day = undefined; }
        this.setState({day}, () => this.onChange());
    }
}
