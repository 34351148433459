// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    LearnerReport,
    LearnerReportFromJSON,
    LearnerReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomReport
 */
export interface ClassroomReport {
    /**
     * 
     * @type {Array<LearnerReport>}
     * @memberof ClassroomReport
     */
    learnerReports: Array<LearnerReport>;
    /**
     * 
     * @type {number}
     * @memberof ClassroomReport
     */
    readonly totalScore?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassroomReport
     */
    readonly totalCompletion?: number;
    /**
     * 
     * @type {Date}
     * @memberof ClassroomReport
     */
    readonly updatedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassroomReport
     */
    readonly csvExport?: string;
}

export function ClassroomReportFromJSON(json: any): ClassroomReport {
    return {
        'learnerReports': (json['learner_reports'] as Array<any>).map(LearnerReportFromJSON),
        'totalScore': !exists(json, 'total_score') ? undefined : json['total_score'],
        'totalCompletion': !exists(json, 'total_completion') ? undefined : json['total_completion'],
        'updatedDate': !exists(json, 'updated_date') ? undefined : new Date(json['updated_date']),
        'csvExport': !exists(json, 'csv_export') ? undefined : json['csv_export'],
    };
}

export function ClassroomReportToJSON(value?: ClassroomReport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'learner_reports': (value.learnerReports as Array<any>).map(LearnerReportToJSON),
    };
}


