import * as React from 'react';
import { DocumentField, ThinItemSection } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import { LinkEditorState, SpecificEditorProps } from './models';

type Props = SpecificEditorProps<LinkEditorState>;

const DocLinkEditor: React.FC<Props> = (props) => {
    const { editorState, showErrors, onEditorStateChanged } = props;

    const onDocumentFileChange = React.useCallback((newFile: File | undefined, newUrl: string) => {
        const newEditorState: LinkEditorState = {
            ...editorState!,
            documentUrl: newFile ? '' : newUrl,
        };
        onEditorStateChanged(newEditorState);
    }, [editorState, onEditorStateChanged]);

    if (!editorState) {
        return <p><WrappedMessage message={uiMessages.uiLoading}/></p>;
    }

    return (
        <ThinItemSection>
            <DocumentField
                required={true}
                showErrors={showErrors}
                currentUrl={editorState.documentUrl || ''}
                allowUrlInput
                allowUpload={false}
                onChange={onDocumentFileChange}
                docType='googleDoc'
            />
        </ThinItemSection>
    );
};

export default DocLinkEditor;
