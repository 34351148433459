import explore_footer_background from 'assets/images/explore-footer-background.svg';
import { ROUTES } from 'global/constants';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';

export class ExploreFooter extends React.PureComponent {
    public render() {
        return (
            <div className='explore-footer' style={
                { backgroundImage: `url(${explore_footer_background})` }}>
               <div className='explore-footer-title'>
                   <WrappedMessage message={messages.exploreFooterTitle}/>
               </div>
               <div className='explore-footer-baseline'>
                   <WrappedMessage message={messages.exploreFooterBaseline}/>
               </div>
               <Link to={ROUTES.Library.HOME} className='btn'>
                   <WrappedMessage message={messages.exploreFooterButton}/>
               </Link>
           </div>
        );
    }
}
