// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClassroomMembership,
    ClassroomMembershipFromJSON,
    ClassroomMembershipToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClassroomMembershipList
 */
export interface ClassroomMembershipList {
    /**
     * 
     * @type {number}
     * @memberof ClassroomMembershipList
     */
    count: number;
    /**
     * 
     * @type {Array<ClassroomMembership>}
     * @memberof ClassroomMembershipList
     */
    results: Array<ClassroomMembership>;
}

export function ClassroomMembershipListFromJSON(json: any): ClassroomMembershipList {
    return {
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(ClassroomMembershipFromJSON),
    };
}

export function ClassroomMembershipListToJSON(value?: ClassroomMembershipList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(ClassroomMembershipToJSON),
    };
}


