import { defineMessages } from 'react-intl';

const messages = defineMessages({
    subjectPageTitle: {
        id:'subjectPageTitle',
        defaultMessage: 'Subjects',
        description: 'HTML title for the Subject page.',
    },
    joinClassButton: {
        id:'joinClassButton',
        defaultMessage: 'Join a class',
        description: 'button label to join class from subjects page',
    },
    curriculumsButton: {
        id:'curriculumsButton',
        defaultMessage: 'Curricula',
        description: 'button label to go to curriculums list page from the subjects page',
    },
    moreContentLinkText: {
        id:'moreContentLinkText',
        defaultMessage: 'More content',
        description: 'link label text to explore more content on site from subjects page',
    },
    allTopicsButtonText: {
        id:'allTopicsButtonText',
        defaultMessage: 'All topics',
        description: 'link label text to explore all topics related to a subject',
    },
    clustersCardsCovers: {
        id:'clusterCardsCovers',
        defaultMessage: 'This cluster covers',
        description: 'Label for cluster carousel cards',
    },
    clustersCardsExploreButton: {
        id:'clustersCardsViewButton',
        defaultMessage: 'Explore cluster',
        description: 'Label for explore button on cluster carousel cards',
    },
    exploreTopicsSubjectPage: {
        id:'exploreTopicsSubjectPage',
        defaultMessage: 'Explore topics',
        description: 'Label for topic cards on subject page',
    },
    exploreRelatedTopicsInCurriculum: {
        id:'exploreRelatedTopicsInCurriculum',
        defaultMessage: 'Closely related topics',
        description: 'Label for topic cards on curriculum topic page',
    },
    librarySectionTitle: {
        id:'librarySectionTitle',
        defaultMessage: 'Looking for something else?',
        description: 'Subjects page library section title',
    },
    librarySectionDescription: {
        id:'librarySectionDescription',
        defaultMessage: 'Find over 28,500+ engaging resources in our library.',
        description: 'Subjects page library section description',
    },
    librarySectionActionLabel: {
        id:'librarySectionActionLabel',
        defaultMessage: 'Open Library',
        description: 'Subjects page library section action button label',
    },
    contentPageError: {
        id:'contentPageError',
        defaultMessage: 'Unable to load data for the provided subject & content type.',
        description: 'Error message for content page',
    },
    simulationCardHeadingText: {
        id:'simulationCardHeadingText',
        defaultMessage: 'About this content',
        description: 'Heading text for simulation card',
    },
    simulationCardHeadingTag: {
        id:'simulationCardHeadingTag',
        defaultMessage: 'NEW!',
        description: 'Heading tag for simulation card',
    },
    simulationCardOverviewButton: {
        id:'simulationCardOverviewButton',
        defaultMessage: 'Overview',
        description: 'Label for overview button on simulation card',
    },
    simulationCardLaunchButton: {
        id:'simulationCardLaunchButton',
        defaultMessage: 'Launch',
        description: 'Label for launch button on simulation card',
    },
    simulationCardIntro: {
        id:'simulationCardIntro',
        defaultMessage: 'Interactive materials like simulations and scrollable animations enhance learning by engaging students actively, promoting deeper understanding, and providing immediate feedback. They encourage critical thinking, adapt to individual needs, and make learning enjoyable, resulting in better retention of knowledge.',
        description: 'Text for simulation card intro',
    },
    subjectPageHeading: {
        id:'subjectPageHeading',
        defaultMessage: 'Free resources, \n for {placeholder}',
        description: 'Text for subject page header',
    },
    subjectPageHeadingPlaceholderText: {
        id:'subjectPageHeadingPlaceholderText',
        defaultMessage: 'everyone.',
        description: 'Text for subject page header',
    },
    browseSubjectHeading: {
        id:'browseSubjectHeading',
        defaultMessage: 'Browse Subjects',
        description: 'Text for subject page browse heading',
    },
    biologySubject: {
        id:'biologySubject',
        defaultMessage: 'Biology',
        description: 'biology subject name',
    },
    chemistrySubject: {
        id:'chemistrySubject',
        defaultMessage: 'Chemistry',
        description: 'chemistry subject name',
    },
    physicsSubject: {
        id:'physicsSubject',
        defaultMessage: 'Physics',
        description: 'physics subject name',
    },
    earthSpaceSubjects: {
        id:'earthSpaceSubjects',
        defaultMessage: 'Earth & Space',
        description: 'earth & science subject name',
    },
    moreContentButtonText: {
        id:'moreContentButtonText',
        defaultMessage: 'More content',
        description: 'more content button text to explore more content on platform',
    },
    introToSubjectsLine: {
        id:'introToSubjectsLine',
        defaultMessage: 'Introduction to {subject}',
        description: 'intro to specific subject heading',
    },
    overviewTextSubheading: {
        id:'overviewTextSubheading',
        defaultMessage: 'Overview of core topics',
        description: 'subheading to show under intro to subject line',
    },
    exploreClusterButton:{
        id:'exploreClusterButton',
        defaultMessage: 'Explore Cluster',
        description: 'Button text or explore cluster section',
    },
    earthScienceSection:{
        id:'earthScienceSection',
        defaultMessage: 'Science Mom & Earth Science',
        description: 'Earth science section heading',
    }
});

export default messages;
