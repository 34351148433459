import { defineMessages } from 'react-intl';

const messages = defineMessages({
    notificationsTitle: {
        id:'notificationsTitle',
        defaultMessage: 'Notifications',
        description: 'HTML title for the notifications home page.',
    },
    kebabMarkRead: {
        id:'kebabMarkRead',
        defaultMessage: 'Mark as read',
        description: 'kebab message to mark a notification as read',
    },
    kebabMarkUnread: {
        id:'kebabMarkUnread',
        defaultMessage: 'Mark as unread',
        description: 'kebab message to mark a notification as unread',
    },
});

export default messages;
