import * as React from 'react';
import Select from 'react-select';
import { defineMessages } from 'react-intl';
import { intl } from 'i18n';

const messages = defineMessages({
    title: {
        id: 'AnswerTypeSetting.title',
        defaultMessage: 'Answer type'
    },
    description: {
        id: 'AnswerTypeSetting.description',
        defaultMessage: 'Choose how the answers should be displayed.'
    },
    label: {
        id: 'AnswerTypeSetting.label',
        defaultMessage: 'Answer type'
    },
    note: {
        id: 'AnswerTypeSetting.note',
        defaultMessage: 'Note: We recommend using a dropdown menu if you have more than 10 answer options.'
    },
    noteWarning: {
        id: 'AnswerTypeSetting.warning',
        defaultMessage: 'Superscripts and subscripts can only be used in the radio button answer type.'
    },
    placeholder: {
        id: 'AnswerTypeSetting.placeholder',
        defaultMessage: '- Select -'
    }
})

export default class AnswerTypeSetting extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.state = {};

    }

    componentDidMount() {
        this.setState({...this.props});
    }

    handleTypeChange(value: any) {
        this.setState({
            selectedTypeOption: value
        });
    }

    render() {
        return (
            <div className='lxc-advanced-settings-block'>
                <div className='lxc-advanced-settings-block-title'>
                    {intl.formatMessage(messages.title)}
                </div>
                <div className='lxc-advanced-settings-block-description'>
                    {intl.formatMessage(messages.description)}
                </div>

                <div className='lxc-advanced-settings-form'>
                    <label className='lxc-advanced-settings-label lxc-advanced-settings-label_wrap' htmlFor='sas1'>
                        {intl.formatMessage(messages.label)}
                        <Select
                            id='sas1'
                            className='lxc-advanced-settings-select'
                            isSearchable={false}
                            placeholder={intl.formatMessage(messages.placeholder)}
                            value={this.props.answerTypeSelectedOption}
                            onChange={this.props.answerTypeChange}
                            options={this.props.answerTypeOptions}
                        />
                    </label>
                    <div className='lxc-advanced-settings-note'>
                        {intl.formatMessage(messages.note)}<br />
                        {intl.formatMessage(messages.noteWarning)}
                    </div>
                </div>
            </div>
        )
    }
};
