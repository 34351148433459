import {Icon, ZoomControls} from 'elements';
import * as React from 'react';
import ReactModal from 'react-modal';
import { PanZoom } from 'react-easy-panzoom';

import { LabXChangeLogo } from 'ui/components';
import { UI_IS_SM } from '../../../ui/breakpoints';
import { bind } from 'bind-decorator';

import messages from '../../displayMessages';
import { intl } from 'i18n';

export interface ImageTheaterData {
    src: string;
    alt: string;
}

interface TheatreProps extends ReactModal.Props {
    title: string;
    imageTheaterData?: ImageTheaterData;
}

interface State {
    zoomValue: number;
    moveX: number;
    moveY: number;
    isTabletView: boolean;
}

export class Theatre extends React.PureComponent<TheatreProps, State> {
    private mediaQuery = UI_IS_SM;  // This matches Bootstrap's definition of small (sm) breakpoint.
    private topbarButtonRef: React.RefObject<HTMLButtonElement>;

    constructor(props: TheatreProps) {
        super(props);

        this.state = {
            zoomValue: 1,
            moveX: 0,
            moveY: 0,
            isTabletView: false,
        };
        this.topbarButtonRef = React.createRef<HTMLButtonElement>();
    }

    public static defaultProps = {
        isOpen: true,
    };

    @bind private handleFocus(event: any) {
        const eventType = event.data.data ? event.data.data.type : null;
        if(eventType === 'returnFocusToPlatform'){
            this.topbarButtonRef.current?.focus();
        }
    }

    public componentDidMount() {
        this.mediaQuery.addListener(this.setIsTabletView);
        this.setIsTabletView();
        window.addEventListener('message', this.handleFocus);
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsTabletView);
        window.removeEventListener('message', this.handleFocus);
    }

    public render() {
        const imageTheaterData = this.props.imageTheaterData ? this.props.imageTheaterData : {
            'src': '',
            'alt': '',
        };
        return (
            <ReactModal {...this.reactModalProps()}>
                <div className='theatre-top-bar'>
                    <div className='theatre-top-bar-content'>
                        <button
                            tabIndex={-1}
                            ref={this.topbarButtonRef}
                            className='theatre-top-bar-button'
                            onClick={this.props.onRequestClose}
                            aria-label={intl.formatMessage(messages.topBarButtonText)}
                        >
                            <Icon name='chevron-left' zoom='x1.4'/>
                            <span className='theatre-top-bar-title'>
                                {this.props.title}
                            </span>
                        </button>
                    </div>
                    <div className='theatre-top-bar-logo'>
                        <LabXChangeLogo type='trim' theme='light' height={36} />
                    </div>
                </div>
                <div className='theatre-content'>
                    {this.props.children ? (this.props.children) : (this.state.isTabletView ? (
                        <div className='mobile-theater-container'>
                        <PanZoom>
                            <img
                                className='mobile-panzoom-image'
                                src={imageTheaterData.src}
                                alt={imageTheaterData.alt}
                            />
                        </PanZoom>
                        </div>
                        ) :
                        (<div className={'theater-image-content'}>
                            <div
                                className={'theater-image-container'}
                                style={{
                                    transform: `scale(${this.state.zoomValue}) translate(${this.state.moveX}%, ${this.state.moveY}%)`
                                }}
                            >
                                <div className={'theater-image-positioner'}>
                                    <img src={imageTheaterData.src} alt={imageTheaterData.alt} />
                                </div>
                            </div>
                            <ZoomControls
                                onMoveClick={this.onMoveClick}
                                onZoomClick={this.onZoomClick}
                                onZoomChange={this.onZoomChange}
                                zoomValue={this.state.zoomValue}
                            />
                        </div>)
                    )}
                </div>
            </ReactModal>
        );
    }

    private reactModalProps() {
        return {
            // We disable react-modal setting aria-hidden="true" to the main app div,
            // because if we don't do that, axe complains that hidden areas contain focusable elements
            // when a modal is open. It's not worth disabling all controls on the page when a model is
            // opened since react-modal already contains focus handling code that traps focus inside the modal.
            ariaHideApp: false,
            overlayClassName: 'lx-modal-backdrop',
            ...this.props,
            className: 'theatre-modal',
        };
    }

    @bind private onZoomChange(e: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ zoomValue: Number(e.target.value) });
    }

    @bind private onZoomClick(direction: 'up'|'down') {
        if (direction === 'up' && this.state.zoomValue < 2) {
            this.setState(prevState => ({zoomValue: prevState.zoomValue + 0.25}));
        } else if (direction === 'down' && this.state.zoomValue > 0.25) {
            this.setState(prevState => ({zoomValue: prevState.zoomValue - 0.25}));
        }
    }

    @bind private onMoveClick(direction?: string) {
        if (direction === 'left') {
            this.setState(prevState => ({moveX: prevState.moveX - 5}));
        }
        if (direction === 'right') {
            this.setState(prevState => ({moveX: prevState.moveX + 5}));
        }
        if (direction === 'up') {
            this.setState(prevState => ({moveY: prevState.moveY - 5}));
        }
        if (direction === 'down') {
            this.setState(prevState => ({moveY: prevState.moveY + 5}));
        }
    }

    @bind private setIsTabletView(): void {
        this.setState({ isTabletView: this.mediaQuery.matches });
    }
}
