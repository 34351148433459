import bind from 'bind-decorator';
import * as React from 'react';

import { redirectToLogin } from 'auth/actions';
import { ClassroomsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { history } from 'global/history';

import {
    Modal,
    ModalConfirmation,
    showErrorMessage,
} from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';

import {
    ClassroomEditModal, getFirstOwnerEducator,
    JoinClassroomModal,
    LeaveClassroomModal,
    LoginClassroomModal,
    ModalType,
} from 'classrooms/components';

import {
    Classroom,
    ClassroomErrorErrorTypeEnum,
} from 'labxchange-client';

import messages from './displayMessages';

interface Props {
    classroom?: Classroom;
    loading: boolean;
    modalType: ModalType;
    onChangeModal: (modalType: ModalType) => void;
    onShouldReloadData: () => void;
}

export class ClassroomModals extends React.PureComponent<Props, {}> {

    public render() {
        switch (this.props.modalType) {
            case ModalType.Update: {
                return (
                    <ClassroomEditModal
                        close={this.onCloseEditModal}
                        onArchiveUpdate={this.onArchiveUpdate}
                        onDelete={this.onDelete}
                        onUpdated={this.onUpdated}
                        classroom={this.props.classroom}
                        create={false}
                    />
                );
            }

            case ModalType.Archive: {
                return (
                    <ModalConfirmation
                        confirmButtonText={messages.confirmationModalArchiveConfirmButton}
                        title={messages.confirmationModalArchiveTitle}
                        body={messages.confirmationModalArchiveBody}
                        cancelButtonText={messages.confirmationModalArchiveCancelButton}
                        onConfirmed={() => this.onArchiveUpdateConfirmed(true)}
                        onCanceled={this.onCloseModal}
                    />
                );
            }

            case ModalType.Unarchive: {
                const id = this.props.classroom?.id;
                if (id === undefined) { return; }
                return (
                    <ModalConfirmation
                        confirmButtonText={messages.confirmationModalUnarchiveConfirmButton}
                        title={messages.confirmationModalUnarchiveTitle}
                        body={messages.confirmationModalUnarchiveBody}
                        cancelButtonText={messages.confirmationModalCancelButton}
                        onConfirmed={() => this.onArchiveUpdateConfirmed(false)}
                        onCanceled={this.onCloseModal}
                    />
                );
            }

            case ModalType.Delete: {
                return (
                    <ModalConfirmation
                        title={messages.confirmationModalDeleteTitle}
                        body={messages.confirmationModalDeleteBody}
                        confirmButtonText={messages.confirmationModalDeleteConfirmButton}
                        cancelButtonText={messages.confirmationModalDeleteCancelButton}
                        onConfirmed={this.onDeleteConfirmed}
                        onCanceled={this.onCloseModal}
                    />
                );
            }

            case ModalType.NewClassroom: {
                return (
                    <ClassroomEditModal
                        close={this.onCloseEditModal}
                        create={true}
                    />
                );
            }

            case ModalType.Copy: {
                return (
                    <ModalConfirmation
                        title={messages.confirmationModalCopyTitle}
                        body={messages.confirmationModalCopyBody}
                        confirmButtonText={messages.confirmationModalCopyConfirmButton}
                        cancelButtonText={messages.confirmationModalCopyCancelButton}
                        onConfirmed={this.onCopyConfirmed}
                        onCanceled={this.onCloseModal}
                    />
                );
            }

            case ModalType.JoinClassroom: {
                return (
                    <JoinClassroomModal
                        onJoinCodeEntered={this.onJoinCodeEntered}
                        onClose={this.onCloseModal}
                    />
                );
            }

            case ModalType.NeedJoinCode: {
                return (
                    <JoinClassroomModal
                        onJoinCodeEntered={this.onJoinCodeEntered}
                        onClose={this.onNeedJoinCodeClose}
                        userAttemptedToViewClassroomBeforeEnrolled={true}
                    />
                );
            }

            case ModalType.RejoinConfirmation: {
                return (
                    <ModalConfirmation
                        onConfirmed={this.onRejoinConfirmed}
                        onCanceled={this.onCloseModal}
                        title={messages.rejoinConfirmationTitle}
                        body={messages.rejoinConfirmationText}
                        confirmButtonText={messages.rejoinConfirmationButton}
                        cancelButtonText={messages.rejoinCancelButton}
                    />
                );
            }

            // Shown when a user attempts to join an archived classroom.
            // TODO: check why this is a confirmation modal
            // TODO: do we have a standard success and warning modal that doesn't go through showSuccessMessage
            case ModalType.ClassroomArchived: {
                return (
                    <ModalConfirmation
                        onConfirmed={this.onCloseModal}
                        onCanceled={this.onCloseModal}
                        title={messages.archivedModalTitle}
                        body={messages.archivedModalBody}
                        confirmButtonText={messages.rejoinConfirmationButton}
                        cancelButtonText={messages.rejoinCancelButton}
                    />
                );
            }

            case ModalType.LeaveClassroom: {
                return (
                    <LeaveClassroomModal
                        onDestructiveActionConfirmed={this.onConfirmLeaveClass}
                        onDestructiveActionCanceled={this.onCloseModal}
                    />
                );
            }

            case ModalType.Login: {
                return (
                    <LoginClassroomModal
                        onCanceled={this.onLoginModalClose}
                        onConfirmed={this.onLoginModalSignUp}
                    />
                );
            }

            case ModalType.Welcome: {
                const classOwner = getFirstOwnerEducator(this.props.classroom!);
                return (
                    <Modal
                        className='modal-info-classroom'
                        onRequestClose={this.onWelcomeClose}
                        content={
                            <>
                                <div className='title-icon'/>
                                <div className='title-text'>
                                    <WrappedMessage
                                        message={messages.welcomeClassTitle}
                                        values={{ classroomName: this.props.classroom?.name }}
                                    />
                                </div>
                                <div className='body-text'>
                                    <WrappedMessage
                                        message={messages.welcomeIsTaughtByText}
                                            values={{
                                                classroomName: (<b>{this.props.classroom?.name}</b>),
                                                educatorName: (<b>{classOwner.fullName}</b>),
                                        }}
                                    />
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <button
                                    className='primary-button modal-info-classroom-acknowledge-button'
                                    onClick={this.onWelcomeClose}
                                >
                                    <WrappedMessage message={messages.welcomeClassButtonText} />
                                </button>
                            </>
                        }
                    />
                );
            }

            case ModalType.RequestSent: {
                return (
                    <Modal
                        className='modal-info-classroom'
                        onRequestClose={this.onRequestSentClose}
                        content={
                            <>
                                <div className='title-icon'/>
                                <div className='title-text'>
                                    <WrappedMessage
                                        message={messages.requestHasBeenSentTitle}
                                        values={{ classroomName: this.props.classroom?.name }}
                                    />
                                </div>
                                <div className='body-text'>
                                    <WrappedMessage message={messages.requestHasBeenSentBody} />
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <button
                                    className='primary-button modal-info-classroom-acknowledge-button'
                                    onClick={this.onRequestSentClose}
                                >
                                    <WrappedMessage message={messages.requestHasBeenSentButton} />
                                </button>
                            </>
                        }
                    />
                );
            }

            case ModalType.None:
            default: {
                return null;
            }
        }
    }

    @bind private onCloseModal() {
        this.props.onChangeModal(ModalType.None);
    }

    @bind private onCloseEditModal() {
        this.props.onShouldReloadData();
        this.onCloseModal();
    }

    @bind private onRequestSentClose() {
        this.onCloseModal();
        this.props.onShouldReloadData();
        this.routeToLearnerDashboard();
    }

    @bind private onWelcomeClose() {
        const id = this.props.classroom?.id;
        if (id === undefined) { return; }
        localStorage.removeItem(`firstTimeViewingClassroom-${id}`);
        this.onCloseModal();
    }

    @bind private async onArchiveUpdate() {
        if (this.props.classroom?.archived === true) {
            this.props.onChangeModal(ModalType.Unarchive);
        } else {
            this.props.onChangeModal(ModalType.Archive);
        }
    }

    @bind private async onDelete() {
        this.props.onChangeModal(ModalType.Delete);
    }

    @bind private async onUpdated() {
        this.props.onShouldReloadData();
    }

    @bind private async onArchiveUpdateConfirmed(archived: boolean) {
        const id = this.props.classroom?.id;
        if (id === undefined) { return; }
        try {
            await ClassroomsApi.partialUpdate({
                id,
                data: { archived },
            });
        } catch (err) {
            this.onCloseModal();
            showErrorMessage(<WrappedMessage
                message={
                    archived ? messages.failedToArchiveClass : messages.failedToUnarchiveClass
                }
            />, {exception: err});
            return;
        }
        this.onCloseModal();
        this.props.onShouldReloadData();
        this.routeToEducatorDashboard();
    }

    @bind private async onDeleteConfirmed() {
        const id = this.props.classroom?.id;
        if (id === undefined) { return; }
        try {
            if (id) {
                await ClassroomsApi._delete({ id });
            }
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.failedToDeleteClass} />, {exception: err});
        }
        this.onCloseModal();
        this.props.onShouldReloadData();
        this.routeToEducatorDashboard();
    }

    @bind private async onCopyConfirmed() {
        const id = this.props.classroom?.id;
        if (id === undefined) { return; }
        let classroomCopy: Classroom | undefined;
        try {
            classroomCopy = await ClassroomsApi.copy({ id });
        } catch (err) {
            showErrorMessage('Could not copy classroom.', {exception: err});
        }
        this.onCloseModal();

        if (classroomCopy?.id) {
            const url = ROUTES.Classrooms.CLASSROOM_SLUG(classroomCopy.id);
            history.push(`${url}?copiedFrom=${id}`);
        }
    }

    private routeToLearnerDashboard() {
        const url = ROUTES.Dashboard.LEARNER_CLASSROOMS;
        if (window.location.pathname !== url) {
            history.push(url);
        }
    }

    private routeToEducatorDashboard() {
        const url = ROUTES.Dashboard.EDUCATOR_CLASSROOMS;
        if (window.location.pathname !== url) {
            history.push(url);
        }
    }

    @bind private onNeedJoinCodeClose() {
        this.onCloseModal();
        this.routeToLearnerDashboard();
    }

    @bind private async onJoinCodeEntered(joinCode: string) {
        let classroom;
        try {
            classroom = await ClassroomsApi.join({data: {joinCode}});
        } catch (err) {
            const resp = await err.json();

            let errorMessage = {
                body: messages.requestFailureMessage,
                title: messages.requestFailureTitle,
                details: '',
                confirmText: uiMessages.uiConfirmationButton,
            };
            switch (resp.detail) {
                case ClassroomErrorErrorTypeEnum.ClassroomIsFull:
                    errorMessage = {
                        body: messages.requestFailureClassFullBody,
                        title: messages.requestFailureClassFullTitle,
                        details: '',
                        confirmText: uiMessages.uiOk,
                    };
                    break;
                case ClassroomErrorErrorTypeEnum.ClassroomIsArchived:
                    errorMessage = {
                        body: messages.requestFailureClassArchivedBody,
                        title: messages.requestFailureClassArchivedTitle,
                        details: '',
                        confirmText: uiMessages.uiConfirmationButton,
                    };
                    break;
                case ClassroomErrorErrorTypeEnum.EducatorCannotJoinTheirOwnClassroom:
                    errorMessage = {
                        body: messages.requestFailureEducatorShouldNotJoinAsLearnerBody,
                        title: messages.requestFailureEducatorShouldNotJoinAsLearnerTitle,
                        details: '',
                        confirmText: uiMessages.uiConfirmationButton,
                    };
                    break;
                default:
            }
            this.onCloseModal();
            showErrorMessage(<WrappedMessage message={errorMessage.body} />, {
                title: (<WrappedMessage message={errorMessage.title} />),
                exception: err,
                details: errorMessage.details,
                onClose: this.routeToLearnerDashboard,
            });
            return;
        }

        if (classroom?.id) {
            // set local storage so that the welcome modal is shown
            // when the user first visits the class after being accepted.
            localStorage.setItem(`firstTimeViewingClassroom-${classroom.id}`, 'firstTime');
        }
        this.props.onChangeModal(ModalType.RequestSent);
    }

    @bind private async onRejoinConfirmed() {
        const id = this.props.classroom?.id;
        if (id === undefined) { return; }
        try {
            await ClassroomsApi.rejoin({ id, data: {} });
        } catch (err) {
            this.onCloseModal();
            showErrorMessage('Unable to rejoin class.', {exception: err});
            return;
        }

        this.onCloseModal();
        this.props.onShouldReloadData();
    }

    @bind private async onConfirmLeaveClass() {
        const id = this.props.classroom?.id;
        if (id === undefined) { return; }
        try {
            await ClassroomsApi.leave({ data: { id } });
            // navigate to the learner classroom dashboard if we aren't there already
            if (window.location.pathname !== ROUTES.Dashboard.LEARNER_CLASSROOMS) {
                const url = ROUTES.Dashboard.LEARNER_CLASSROOMS;
                history.push(url);
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err);
        }

        this.onCloseModal();
        this.props.onShouldReloadData();
    }

    @bind private onLoginModalClose() {
        this.onCloseModal();
        history.push(ROUTES.Library.HOME);
    }

    @bind private onLoginModalSignUp() {
        this.onCloseModal();
        redirectToLogin();
    }
}
