// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ClassroomAssignmentCreate
 */
export interface ClassroomAssignmentCreate {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassroomAssignmentCreate
     */
    items: Array<string>;
}

export function ClassroomAssignmentCreateFromJSON(json: any): ClassroomAssignmentCreate {
    return {
        'items': json['items'],
    };
}

export function ClassroomAssignmentCreateToJSON(value?: ClassroomAssignmentCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'items': value.items,
    };
}


