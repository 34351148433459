// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ItemCreation
 */
export interface ItemCreation {
    /**
     * 
     * @type {string}
     * @memberof ItemCreation
     */
    type: ItemCreationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ItemCreation
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemCreation
     */
    sourceId?: number;
}

export function ItemCreationFromJSON(json: any): ItemCreation {
    return {
        'type': json['type'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'sourceId': !exists(json, 'source_id') ? undefined : json['source_id'],
    };
}

export function ItemCreationToJSON(value?: ItemCreation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'title': value.title,
        'source_id': value.sourceId,
    };
}

/**
* @export
* @enum {string}
*/
export enum ItemCreationTypeEnum {
    AnnotatedVideo = 'annotated_video',
    Assignment = 'assignment',
    Assessment = 'assessment',
    Audio = 'audio',
    Book = 'book',
    CaseStudy = 'case_study',
    Cluster = 'cluster',
    Document = 'document',
    LxText = 'lx_text',
    Image = 'image',
    Interactive = 'interactive',
    LxVideo = 'lx_video',
    Narrative = 'narrative',
    Pathway = 'pathway',
    Question = 'question',
    Simulation = 'simulation',
    TeachingGuide = 'teaching_guide',
    Text = 'text',
    Video = 'video',
    Unknown = 'unknown',
    Link = 'link'
}


