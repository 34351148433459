import { bind } from 'bind-decorator';
import { AnnotatedVideoAnnotation } from 'labxchange-client';
import { AnnotationEditor } from 'library/components/BlockEditor/AnnotatedVideoBlockEditor';
import * as React from 'react';
import { CardFauxAdd } from 'ui/components/Card/Cards';
import videojs from 'video.js';
import * as UI from 'ui/components';
import { ROUTES } from 'global/constants';

import AnnotationComments from '@contently/videojs-annotation-comments';

import { AnnotatedVideoAttribution } from './AnnotatedVideoAttribution';
import { Annotation, AnnotationSkeleton } from './Annotation';
import { CoupledQuestions } from './CoupledQuestions';
import messages from './displayMessages';
import { OrganizationsApi } from 'global/api';

import jQuery from 'jquery';
Object.assign(window, { $: jQuery, jQuery });

interface VideoPlayerAnnotation {
    id: string;
    range: {start: number, end: number};
    shape: any;
}

interface Props {
    isSaving?: boolean;
    editable?: boolean;
    annotations: AnnotatedVideoAnnotation[];
    player: videojs.Player;
    ownerName?: string;
    ownerUrl?: string;
    ownerOrgId?: string;
    videoUrl?: string;
    videoAuthorName?: string;
    videoAuthorUrl?: string;
    videoAuthorLogoUrl?: string;
    onSelectAnnotation?(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]): void;
    show?: boolean;
    selectedTags: string[];
    onSelectTag(tag: string): void;
    onOpenQuestion?(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]): void;
    /// Function used to indicate if there is editing an annotation or not
    onChangeEditState(editState: boolean): void;
    onSaveAnnotation?(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined): void;
    onDeleteAnnotation?(annotation: AnnotatedVideoAnnotation): void;
    /// Function to send the annotation and the editor ref to get the annotation times and update the editor state
    onStartAnnotationForm?(annotation?: AnnotatedVideoAnnotation, editorRef?: React.RefObject<AnnotationEditor>): void;
    onStartQuestionForm?(questionTime?: number, editorRef?: React.RefObject<AnnotationEditor>): void;
    /// Function used in Annotation and AnnotationEditor components
    onStartAnnotationEdition?(startTimer: React.RefObject<UI.TimerInput>, endTimer: React.RefObject<UI.TimerInput>): void;
    onStartQuestionEdition?(startTimer: React.RefObject<UI.TimerInput>): void;
    /// Function used in Annotation and AnnotationEditor components
    onChangeAnnotationTimes?(isLeft: boolean, newTime: number): void;
    onChangeQuestionTime?(newTime: number): void;
    deletePaddles?():void;
    deleteQuestionPaddle?():void;
    isHorizontalList?: boolean;
    onOpenAnnotationMore?(annotation: AnnotatedVideoAnnotation): void;
    onWatch?(): void;
    loggedInUsername?: string;
    sideBarIsClosed?: boolean;
}

interface State {
    playerAnnotations?: VideoPlayerAnnotation[];
    selectedAnnotation?: string;
    newAnnotation: boolean;
    newQuestion: boolean;
    editorIsOpen: boolean;
    editorIndex?: number;
    canEditAnnoationMap: Map<string, boolean>;
}

export class AnnotationsList extends React.PureComponent<Props, State> {
    private annotationPlugin: any;
    private editorRef: React.RefObject<AnnotationEditor>;
    private organizationEditMap: Map<string, boolean>;

    constructor(props: Props) {
        super(props);
        this.state = {
            newAnnotation: false,
            newQuestion: false,
            editorIsOpen: false,
            canEditAnnoationMap: new Map<string, boolean>(),
        };
        this.editorRef = React.createRef();
        this.organizationEditMap = new Map<string, boolean>();
    }

    @bind private buildAnnotations(callback: () => void) {
        const annotations = this.groupAnnotationsByStartTime(this.props.annotations);
        const playerAnnotations = annotations.map((item, index) => {
            const annotation = Array.isArray(item) ? item[0]: item;
            return {
                id: annotation.id,
                range: {start: annotation.start, end: annotation.end},
                shape: { 'x1': 0, 'y1': 0, 'x2': 0, 'y2': 0 },
                comments: [],
            } as VideoPlayerAnnotation;
        });
        this.setState({playerAnnotations}, () => { if(callback) callback(); });
    }

    @bind private initializePlayer() {
        if (this.annotationPlugin) {
            this.annotationPlugin.dispose();
            this.props.player.trigger('playing');
        }
        else {
          videojs.registerPlugin('annotationComments', AnnotationComments(videojs));
          this.props.player.on('playing', this.onPlaying);
        }
    }

    @bind private getPlayerTime() {
        return this.props.player.currentTime();
    }

    @bind private onPlaying() {
        const pluginOptions = {
            annotationsObjects: this.state.playerAnnotations,
            meta: { user_id: null, user_name: null },
            bindArrowKeys: true,
            showControls: false,
            showCommentList: true,
            showFullScreen: true,
            showMarkerShapeAndTooltips: true,
            internalCommenting: false,
            startInAnnotationMode: true,
        };
        this.annotationPlugin = (this.props.player as any).annotationComments(pluginOptions);

        this.annotationPlugin.registerListener('annotationOpened', async (event: any) => {
            if (this.state.playerAnnotations) {
                let annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[] | undefined;
                const annotations = this.groupAnnotationsByStartTime(this.props.annotations);
                const results = annotations.filter(obj =>{
                    if (Array.isArray(obj)) {
                            return obj[0].id === event.detail.annotation.id;
                        } else {
                            return obj.id === event.detail.annotation.id;
                        }
                    }
                );
                if (results) { annotation = results[0]; }
                this.setState({selectedAnnotation: event.detail.annotation.id}, () => {
                    const annotationResult = Array.isArray(annotation) ? annotation[0] : annotation;
                    if (annotationResult) {
                        if (annotation && this.props.onSelectAnnotation && (!this.props.sideBarIsClosed || annotationResult.question)) {
                            this.props.onSelectAnnotation(annotation);
                            this.scrollToAnnotation(event.detail.annotation.id);
                        }
                    }
                });
                if (!Array.isArray(annotation)) {
                    if (!annotation?.question)
                        this.props.player.play();
                }
            }
        });

        this.annotationPlugin.registerListener('annotationClosed', async (event: any) => {
            this.setState({selectedAnnotation: undefined});
        });
    }

    public componentDidMount() {
        this.buildAnnotations(this.initializePlayer);
        if (this.props.editable) {
            this.computeEditAnnotations(this.props.annotations);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.editable && prevProps.annotations.length !== this.props.annotations.length) {
            this.computeEditAnnotations(this.props.annotations);
        }
    }

    public render () {
        const sortedAnnotations = this.props.annotations.sort(
            (a, b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
        const filteredAnnotations = sortedAnnotations.filter(
            (annotation) => this.isFiltered(annotation.tags));
        if (this.state.newAnnotation && !this.props.isSaving && this.props.onStartAnnotationForm) {
            this.props.onStartAnnotationForm(undefined, this.editorRef);
        }
        if (this.state.newQuestion && !this.props.isSaving && this.props.onStartQuestionForm) {
            this.props.onStartQuestionForm(undefined, this.editorRef);
        }
        const questionSetOrderMap = this.createQuestionSetOrderMap();
        return <ul className={
            `annotated-video-annotations-list
            ${!this.props.show ? 'annotated-video-annotations-list-hidden' : ''}
            ${this.props.isHorizontalList ? 'horizontal-list' : ''}
            `}>
            {this.props.editable ? this.createAnnotations(filteredAnnotations) : this.createAnnotations(this.groupAnnotationsByStartTime(filteredAnnotations))}
            {this.state.newAnnotation && !this.props.isSaving &&
                <AnnotationEditor
                    ref={this.editorRef}
                    onSave={this.onSaveAnnotation}
                    onDelete={this.onDeleteAnnotation}
                    startPosition={this.getPlayerTime()}
                    onChangeAnnotationTimes={this.props.onChangeAnnotationTimes}
                    onStartForm={this.props.onStartAnnotationEdition}
                    questionStartOrderMap={{}}
                />
            }
            {this.state.newQuestion && !this.props.isSaving &&
                <AnnotationEditor
                    ref={this.editorRef}
                    onSave={this.onSaveAnnotation}
                    onDelete={this.onDeleteAnnotation}
                    startPosition={this.getPlayerTime()}
                    onChangeAnnotationTimes={this.props.onChangeAnnotationTimes}
                    onChangeQuestionTime={this.props.onChangeQuestionTime}
                    withQuestion={true}
                    onStartQuestionEdition={this.props.onStartQuestionEdition}
                    questionStartOrderMap={questionSetOrderMap}
                />
            }
            {this.props.editable && !this.props.isSaving && !this.state.editorIsOpen &&
                <li className='annotated-video-annotations-list-button'>
                    <CardFauxAdd message={messages.addAnnotationButtonLabel} onClick={this.onAddAnnotation}/>
                    <CardFauxAdd message={messages.addQuestionButtonLabel} onClick={this.onAddQuestion}/>
                </li>
            }
            {!this.props.editable
             && !this.props.isHorizontalList
             && this.props.videoUrl
             && this.props.videoAuthorName
             && this.props.videoAuthorUrl
             && this.props.videoAuthorLogoUrl &&
                <AnnotatedVideoAttribution
                    videoUrl={this.props.videoUrl}
                    channelUrl={this.props.videoAuthorUrl}
                    channelName={this.props.videoAuthorName}
                    channelLogoUrl={this.props.videoAuthorLogoUrl}/>
            }
        </ul>;
    }

    @bind onEditAnnotation(index: number) {
        this.setState({
            editorIsOpen: true,
            editorIndex: index,
        });
        if (this.props.onChangeEditState) {
            this.props.onChangeEditState(true);
        }
    }

    @bind onDeleteAnnotation(annotation?: AnnotatedVideoAnnotation, withQuestion?: boolean) {
        if (this.props.deletePaddles) {
            this.props.deletePaddles();
        }
        if (this.props.editable && this.props.onDeleteAnnotation && annotation) {
            this.props.onDeleteAnnotation(annotation);
        }
        let newAnnotation = this.state.newAnnotation;
        let newQuestion = this.state.newQuestion;
        if (withQuestion) {
            newQuestion = false;
            if (this.props.deleteQuestionPaddle) {
                this.props.deleteQuestionPaddle();
            }
        }
        else { newAnnotation = false; }
        this.setState(
            {
                newAnnotation,
                newQuestion,
                editorIsOpen: false,
                editorIndex: undefined,
            },
            () => this.buildAnnotations(this.initializePlayer)
        );
        if (this.props.onChangeEditState) {
            this.props.onChangeEditState(false);
        }
    }

    @bind async onSaveAnnotation(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined,
        withQuestion: boolean) {
        if (this.props.editable && this.props.onSaveAnnotation) {
            await this.props.onSaveAnnotation(annotation, imageFile);
            if (this.props.deletePaddles) this.props.deletePaddles();
            let newAnnotation = this.state.newAnnotation;
            let newQuestion = this.state.newQuestion;
            if (withQuestion) {
                newQuestion = false;
                if (this.props.deleteQuestionPaddle) {
                    this.props.deleteQuestionPaddle();
                }
            }
            else { newAnnotation = false; }
            this.setState(
                {
                    newAnnotation,
                    newQuestion,
                    editorIsOpen: false,
                    editorIndex: undefined,
                },
                () => this.buildAnnotations(this.initializePlayer)
            );
        }
        if (this.props.onChangeEditState) {
            this.props.onChangeEditState(false);
        }
    }

    @bind private onAddAnnotation() {
        this.pausePlayer();
        this.setState({
            newAnnotation: true,
            editorIsOpen: true,
        });
        if (this.props.onChangeEditState) {
            this.props.onChangeEditState(true);
        }
    }

    @bind private onAddQuestion() {
        this.pausePlayer();
        this.setState({
            newQuestion: true,
            editorIsOpen: true,
        });
        if (this.props.onChangeEditState) {
            this.props.onChangeEditState(true);
        }
    }

    @bind private async onOpenQuestion(annotation: AnnotatedVideoAnnotation) {
        if(Array.isArray(annotation)) {
            this.onSelectAnnotation(annotation[0]);
        } else {
            this.onSelectAnnotation(annotation);
        }
        if (this.annotationPlugin === undefined) {
            this.props.player.play();
        }
        /// This waits to the play to after make a pause of the player. Also avoids a flicker
        window.setTimeout(() => {
            if (this.props.onOpenQuestion) { this.props.onOpenQuestion(annotation); }
        }, 1000);
    }

    @bind public async onSelectAnnotation(annotation: AnnotatedVideoAnnotation) {
        if (this.props.onWatch) { this.props.onWatch(); }
        if (annotation.question === undefined) {
           this.props.player.play();
        }
        if (this.annotationPlugin) {
            this.selectAnnotation(annotation);
        } else {
            window.setTimeout(() => {
                this.selectAnnotation(annotation);
            }, 1000);
        }
    }

    @bind private selectAnnotation(annotation: AnnotatedVideoAnnotation) {
        if (annotation.question === undefined) {
          this.annotationPlugin.fire('closeActiveAnnotation');
        }
        window.setTimeout(() => {
            this.annotationPlugin.fire('openAnnotation', { id: annotation.id });
            if (annotation.question === undefined) {
              this.props.player.play();
            }
            this.scrollToAnnotation(annotation.id);
        }, 100);
    }

    @bind private scrollToAnnotation(annotationId: string) {
        const selector = `
            .annotated-video-annotations-list
            .annotated-video-annotation[data-id="annotation-${annotationId}"]`;
        const annotationElement = document.querySelector(selector);
        if (annotationElement) {
            annotationElement.scrollIntoView(
                {behavior: 'smooth', block: 'start', inline: 'center'});
        }
    }

    @bind private isFiltered(tags: string[]): boolean {
        if (this.props.selectedTags.length === 0) { return true; }
        return this.props.selectedTags.some(item => tags.includes(item));
    }

    @bind public updateTags(selectedTags: string[]) {
        this.forceUpdate();
    }

    @bind private isTagsBarShowed() {
        return this.props.selectedTags.length !== 0;
    }

    /*
     * Used to know if the author of this annotation is the same of the logged user
     * I use the authorUrl because the author name can be the full name of the user, not the username
     */
    @bind private authorIsTheLoggedUser(authorUrl: string) {
        return this.props.loggedInUsername !== undefined
                && authorUrl === ROUTES.Users.PROFILE_SLUG(this.props.loggedInUsername);
    }

    /*
     * Used to verify if the user can edit the annotation.
     * First it verifies if is the author, otherwise verifies if can edit content in the organization
     * if the author is an organization
     */
    @bind private async canEditAnnotation(annotation: AnnotatedVideoAnnotation) {
        if (this.authorIsTheLoggedUser(annotation.authorUrl ?? this.props.ownerUrl ?? '')) {
            return true;
        }
        const authorOrgId = annotation.authorOrgId ?? this.props.ownerOrgId ?? '';
        if (authorOrgId !== '') {
            if (this.organizationEditMap.has(authorOrgId)) {
                return this.organizationEditMap.get(authorOrgId)!;
            }
            const orgData = await OrganizationsApi.retrieveById({id: authorOrgId});
            const canEdit = orgData.permissions.canEditContentOfOrganizationObject;
            this.organizationEditMap.set(authorOrgId, canEdit);
            return canEdit;
        }
        return false;
    }

    @bind private async computeEditAnnotations(annotations: AnnotatedVideoAnnotation[]) {
        const newCanEditAnnotationsMap = new Map<string, boolean>();
        for (const annotation of annotations) {
            if(!Array.isArray(annotation)) {
                newCanEditAnnotationsMap.set(
                    annotation.id,
                    await this.canEditAnnotation(annotation)
                );
            }
        }
        this.setState({canEditAnnoationMap: newCanEditAnnotationsMap});
    }

    @bind private pausePlayer() {
       if(this.props.player) {
        this.props.player.pause();
       }
    }

    private createAnnotations(annotations: (AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[])[]) {
        return (!this.state.newAnnotation && !this.state.newQuestion) && annotations.map((annotation, index) =>
            {
                if(!Array.isArray(annotation)) {
                    return (this.state.editorIndex === undefined || this.state.editorIndex === index) && <Annotation
                        editable={this.props.editable && !this.props.isSaving}
                        key={index}
                        index={index}
                        selected={annotation.id === this.state.selectedAnnotation}
                        annotation={annotation}
                        onWatch={() => this.onSelectAnnotation(annotation)}
                        onOpenQuestion={this.onOpenQuestion}
                        onSelectTag={this.props.onSelectTag}
                        onEdit={this.onEditAnnotation}
                        onSave={this.onSaveAnnotation}
                        onDelete={this.onDeleteAnnotation}
                        onStartAnnotationForm={this.props.onStartAnnotationForm}
                        onChangeAnnotationTimes={this.props.onChangeAnnotationTimes}
                        onChangeQuestionTime={this.props.onChangeQuestionTime}
                        onStartAnnotationEdition={this.props.onStartAnnotationEdition}
                        onStartQuestionEdition={this.props.onStartQuestionEdition}
                        isHorizontalBlock={this.props.isHorizontalList}
                        onOpenMore={this.props.onOpenAnnotationMore}
                        isTheFirst={index === 0}
                        isTheLast={index === annotations.length - 1}
                        isTagsBarShowed={this.isTagsBarShowed()}
                        onStartQuestionForm={this.props.onStartQuestionForm}
                        loggedInUsername={this.props.loggedInUsername}
                        ownerName={this.props.ownerName}
                        ownerUrl={this.props.ownerUrl}
                        ownerOrgId={this.props.ownerOrgId}
                        questionStartOrderMap={this.createQuestionSetOrderMap()}
                        canEditAnnotation={this.state.canEditAnnoationMap.get(annotation.id) ?? false}
                        authorIsTheLoggedUser={this.authorIsTheLoggedUser}
                        pausePlayer={this.pausePlayer}
                        />;
                } else {
                    return <CoupledQuestions
                        key={index}
                        index={index}
                        selected={annotation[0].id === this.state.selectedAnnotation}
                        annotation={annotation}
                        onOpenQuestion={this.onOpenQuestion}
                        isHorizontalBlock={this.props.isHorizontalList}
                        onOpenMore={this.props.onOpenAnnotationMore}
                        ownerName={this.props.ownerName}
                        ownerUrl={this.props.ownerUrl}
                        isTheFirst={index === 0}
                        isTheLast={index === annotations.length - 1}
                        isTagsBarShowed={this.isTagsBarShowed()}
                    />;
                }
            }
        );
    }

    private createQuestionSetOrderMap() {
        const questionSetOrderMap = {} as {[start: string]: number};
        this.props.annotations.forEach((annotation) => {
            if (annotation.question) {
                const start = annotation.start.toString();
                if(questionSetOrderMap[start] === undefined) {
                    questionSetOrderMap[start] = 1;
                } else {
                    questionSetOrderMap[start] += 1;
                }
            }
        });
        return questionSetOrderMap;
    }

    private groupAnnotationsByStartTime(annotations: AnnotatedVideoAnnotation[]) {
        const resultDict = {} as {[start: string]: AnnotatedVideoAnnotation[]};
        annotations.forEach((annotation) => {
            if (resultDict[annotation.start] === undefined) {
                resultDict[annotation.start] = [annotation];
            } else {
                resultDict[annotation.start].push(annotation);
            }
        });
        let resultArray = [] as (AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[])[];

        Object.keys(resultDict).forEach((key) => {
            if (resultDict[key].length === 1) {
                resultArray.push(resultDict[key][0]);
            } else {
                // Need to separate out annotations from questions and then group them
                const annotationArray = resultDict[key].filter((x) => x.question === undefined);
                const questionArray = resultDict[key].filter((x) => x.question);
                questionArray.sort((x, y) => {
                    return parseInt(x.questionSetOrder || '1', 10) - parseInt(y.questionSetOrder || '1', 10);
                });
                resultArray = resultArray.concat(annotationArray);
                if (questionArray.length !== 0)
                    resultArray.push(questionArray);
            }
        });
        return resultArray;
    }
}

export const AnnotationsListSkeleton = () => <ul
    className='annotated-video-annotations-list annotated-video-annotations-list-loading'>
    <AnnotationSkeleton />
    <AnnotationSkeleton />
    <AnnotationSkeleton />
    <AnnotationSkeleton />
    <AnnotationSkeleton />
</ul>;
