import {ContainerOne} from 'elements/components/Containers';
import { Funder } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import {WrappedMessage} from 'utils';

interface FundersListProps {
    funders: Funder[];
}

export class FundersList extends React.PureComponent<FundersListProps> {
    public render() {
        if (this.props.funders.length === 0) { return null; }
        return (
            <ContainerOne>
                <div className={
                    this.props.funders.length === 1 ? 'item-funders item-funders-one' : 'item-funders'
                }>
                    <div className='item-funders-title'>
                        <WrappedMessage message={messages.fundersListTitle} />
                    </div>
                    <ol className='item-funders-list'>
                        {
                            this.props.funders.map((funder, idx) => (
                                <li className='item-funders-list-item' key={idx}>
                                    <img src={funder.largeLogoUrl} alt={funder.name}></img>
                                </li>
                            ))
                        }
                    </ol>
                </div>
            </ContainerOne>
        );
    }
}
