import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

type Props = RouteComponentProps<{pathwayItemKey: string}>;

/**
 * Items in a pathway have special XBlock keys like this:
 *  lx-pb:2f61f065-0e3a-4500-ae23-d1f7d797c8a4:html:023101ed
 * If such an item gets accessed via a URL like
 *  /library/items/lx-pb:2f61f065-0e3a-4500-ae23-d1f7d797c8a4:html:023101ed
 * instead of the correct URL:
 *  /library/pathway/lx-pathway:2f61f065-0e3a-4500-ae23-d1f7d797c8a4/items/lx-pb:2f61f065-...
 * then this component will instantly and transparently rewrite that to
 * the correct URL.
 *
 * An example of how this can happen is if an item in a pathway is favorited,
 * and you click on the item from your Favorites Dashboard.
 */
export const RedirectToPathwayItemPage: React.FunctionComponent<Props> = (props) => {
    const parts = props.match.params.pathwayItemKey.split(':');
    if (parts.length < 4) {
        return <p>Invalid pathway item ID.</p>;
    }
    const uuid = parts[1];
    const newUrl = `/library/pathway/lx-pathway:${uuid}/items/${props.match.params.pathwayItemKey}`;
    return <Redirect to={newUrl} />;
};
