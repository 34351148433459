import * as React from 'react';
import { Icon } from '../../../elements';
import { ItemMetadataTypeEnum, ResourceRelationship } from '../../../labxchange-client';
import { Link } from 'react-router-dom';
import { detailUrlForEntity } from '../../utils';
import classNames from 'classnames';
import { getLoggedInStatus, getRole } from 'auth/selectors';
import { useSelector } from 'react-redux';
import { showLockedResourcesMessage } from '../../../ui/components';
import messages from './displayMessages';
import { WrappedMessage } from '../../../utils';
import { ItemIcon } from '../ItemIcon';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';

interface ResourcesListItemProps {
    resource: ResourceRelationship;
    assetId: string;
    assetType: ItemMetadataTypeEnum;
    assetName: string;
    isUserLoggedIn: boolean;
}

export const ResourceListItem : React.FC<ResourcesListItemProps> =({ resource, assetId, isUserLoggedIn, assetType, assetName }) => (
    <>
        {(resource.targetItemId && resource.targetItemType) &&
          <div className='resource-list-item'>
            <ItemIcon itemType={resource.targetItemType} zoom='16'/>
            <Link
              onClick={(e) => {
                  analyticsInstance.track(EVENT_NAMES.AssetResourceClicked, { resource, assetName, url: window.location.toString() });
                  if (!isUserLoggedIn && resource.isPrivate) {
                      e.preventDefault();
                      showLockedResourcesMessage();
                      return;
                  }
              }}
              to={detailUrlForEntity({id: resource.targetItemId, type: resource.targetItemType}, assetId, assetType, true)}
              key={resource.title}>
              <div className='resource-name-link'>
                  {resource.title}
              </div>
            </Link>
              {(resource.isPrivate && !isUserLoggedIn) &&
                <Icon name='lock-filled' zoom='14' data-testid='lock-icon'/>
              }
          </div>
        }
    </>
);



interface ResourcesListingProps {
    resources: ResourceRelationship[];
    assetId: string;
    assetType: ItemMetadataTypeEnum;
    assetName: string;
    isSideBarView?: boolean;
    isPathwayView?: boolean;
}


export const ResourcesListing: React.FC<ResourcesListingProps> = (props) => {
    const learnerResources: ResourceRelationship[] = [];
    const educatorResources: ResourceRelationship[] = [];
    const extensionTasks: ResourceRelationship[] = [];

    const isUserLoggedIn = useSelector(getLoggedInStatus);
    const userRole = useSelector(getRole);
    for (const resource of props.resources) {
        switch (resource.resourceCategory) {
            case 'learner':
                learnerResources.push(resource);
                break;
            case 'educator':
                if(((isUserLoggedIn && resource.isPrivate && userRole === 'educator') || !resource.isPrivate || !isUserLoggedIn))
                    educatorResources.push(resource);
                break;
            case 'extension_task':
                if(((isUserLoggedIn && resource.isPrivate && userRole === 'educator') || !resource.isPrivate || !isUserLoggedIn))
                    extensionTasks.push(resource);
                break;
            default:
                break;
        }
    }

    return (
        <>
            {props.resources.length > 0  &&
              <div data-testid='resources-list' className={classNames('', {'col-md-12 col-lg-10 offset-lg-1': props.isPathwayView, 'col-12': props.isSideBarView})}>
                <div className='resources'>
                  <div className='heading-section'>
                    <div className='icon'>
                      <Icon name='paper-clip'/>
                    </div>
                    <div className='heading-text'>
                      <WrappedMessage message={messages.resourcesHeading} />
                    </div>
                    <div className='new-icon'>
                      <WrappedMessage message={messages.resourcesHeadingNewIconText} />
                    </div>
                  </div>
                  <div className={classNames('list-section', { 'pathway': !props.isSideBarView })}>
                      {educatorResources.length > 0 &&
                        <div className='resource-list-section'>
                          <div className='resource-type-heading'>
                            <WrappedMessage message={messages.resourceTypeHeading} values={{ type: <WrappedMessage message={messages.educatorResourceName}/> }}/>
                          </div>
                            {educatorResources.map((resource: any) => (
                                <ResourceListItem
                                    resource={resource}
                                    assetId={props.assetId}
                                    assetType={props.assetType}
                                    assetName={props.assetName}
                                    isUserLoggedIn={isUserLoggedIn}
                                />
                            ))}
                        </div>
                      }
                      {learnerResources.length > 0 &&
                        <div className={classNames('resource-list-section', { 'column': props.isSideBarView })}>
                          <div className='resource-type-heading'>
                            <WrappedMessage message={messages.resourceTypeHeading} values={{ type: <WrappedMessage message={messages.learnerResourceName}/> }}/>
                          </div>
                            {learnerResources.map((resource: any) => (
                                <ResourceListItem
                                    resource={resource}
                                    assetId={props.assetId}
                                    assetType={props.assetType}
                                    assetName={props.assetName}
                                    isUserLoggedIn={isUserLoggedIn}
                                />
                            ))}
                        </div>
                      }
                      {extensionTasks.length > 0 &&
                        <div className={classNames('resource-list-section', { 'column': props.isSideBarView })}>
                          <div className='resource-type-heading'>
                             <WrappedMessage message={messages.extensionTaskName} />
                          </div>
                            {extensionTasks.map((resource: any) => (
                                <ResourceListItem
                                    resource={resource}
                                    assetId={props.assetId}
                                    assetType={props.assetType}
                                    assetName={props.assetName}
                                    isUserLoggedIn={isUserLoggedIn}
                                />
                            ))}
                        </div>
                      }
                  </div>
                </div>
              </div>

            }
        </>
    );
};
