import { defineMessages } from 'react-intl';

const messages = defineMessages({
    classDescriptionHeading: {
        id:'classDescriptionHeading',
        defaultMessage: 'About this class',
        description: 'Text for the heading above class description on class detail page',
    },
    messageClassButtonLabel: {
        id:'messageClassButtonLabel',
        defaultMessage: 'Message Class',
        description: 'Label for message class button in learners box on educator view of classrooms content tab',
    },
    addLearnersButtonLabel: {
        id:'addLearnersButtonLabel',
        defaultMessage: 'Add Learners',
        description: 'Label for add learners button in learners box on educator view of classrooms content tab',
    },
    addContentButtonLabel: {
        id:'addContentButtonLabel',
        defaultMessage: 'Add content',
        description: 'Label for add content button in educator view of classrooms content tab',
    },
    failedToAddItem: {
        id:'failedToAddItemToClass',
        defaultMessage: 'Unable to add items.',
        description: 'Error message for when a request to add an item with the content picker fails.',
    },
    failedToUpdateItem: {
        id:'failedToUpdateItem',
        defaultMessage: 'Unable to share item.',
        description: 'Error message for when a request share an item fails.',
    },
    actionUnpost: {
        id:'actionUnpost',
        defaultMessage: 'Unpost',
        description: 'Kebab menu button label on that will unpost a classroom content item on classrooms tab of Educator Dashboard, and hide it from the learner view of a classroom',
    },
    actionRemove: {
        id:'actionRemove',
        defaultMessage: 'Remove from class',
        description: 'Kebab menu button label on that will remove a classroom content item from classrooms tab of Educator Dashboard, and the learner view of a classroom',
    },
    duplicatesInClassConfirmDescription: {
        id:'duplicatesInClassConfirmDescription',
        defaultMessage: 'You have already added these assets to your class. Do you want to add them again?',
        description: 'Description to be shown on the confirm adding duplicates modal.',
    },
    unpostedContent: {
        id:'unpostedContent',
        defaultMessage: 'Unposted Content',
        description: 'Header section for unposted content',
    },
});

export default messages;
