import { intl } from 'i18n';
import * as React from 'react';
import { Button, showErrorMessage } from 'ui/components';
import { AuthPageLayout } from 'ui/components/AuthPageLayout';
import messages from './displayMessages';
import authMessages from '../displayMessages';
import { UsersApi } from '../../../global/api';
import { WrappedMessage } from '../../../utils';
import useAddQueryParam, { ProgressPercentageEnum } from '../../utils';
import classNames from 'classnames';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';


type Subject = {
    label: string;
    name: string;
};

interface Props extends React.PropsWithChildren {
    loggedInUserName: string;
    completeOnboarding: () => void;
}

enum TEACH_LEVELS {
    HIGH_SCHOOL = 'High School',
    MIDDLE_SCHOOL = 'Middle School',
    ADVANCED = 'Advance',
    Other = 'Other'
}


export const EducatorSubjectSelection: React.FunctionComponent<Props> = (props) => {
    const [subjectPayload, setSubjectPayload] = React.useState<string[]>([]);
    const [level, setLevel] = React.useState<string[]>([]);

    useAddQueryParam('step', 'EducatorPreference');

    const handleCheckboxChange = (subjectName: string) => {
        if (subjectPayload.includes(subjectName)) {
            const newSubjectPayload = subjectPayload.filter(subject => subject !== subjectName);
            setSubjectPayload(newSubjectPayload);
        } else {
            setSubjectPayload([...subjectPayload, subjectName]);
        }
    };
    const subjects: Subject[] = [
        {'label': 'Biology', 'name': 'Biological Sciences'},
        {'label': 'Earth and Space', 'name': 'Earth Science'},
        {'label': 'Physics', 'name': 'Physics'},
        {'label': 'Mathematics', 'name': 'Mathematics'},
        {'label': 'Chemistry', 'name': 'Chemistry'},
        {'label': 'Data Science', 'name': 'Data Science'},
        {'label': 'Other', 'name': 'Other'}
    ];

    const setEducatorPreferences = async () => {
        const updateData = {subjects: subjectPayload, 'educator_level': level};
        try {
            await UsersApi.profilesPartialUpdate({
                id: props.loggedInUserName,
                data: updateData,
            });
            props.completeOnboarding();
            analyticsInstance.track(
                EVENT_NAMES.OnboardingSubjectsSelected,
                {
                    ...updateData,
                    url: window.location.toString(),
                }
            );
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.subjectAddError}/>,
                {
                    exception: err,
                    details: 'Unable to update subject/level selections',
                },
            );
        }
    };

    const toggleLevel = (value: string) => {
        setLevel(prevLevel => {
            // If the value is already in the array, filter it out
            if (prevLevel.includes(value)) {
                return prevLevel.filter(item => item !== value);
            }

            // Otherwise, add the value to the array
            return [...prevLevel, value];
        });
    };

    const skipPreferenceSettings = () => {
        props.completeOnboarding();
        analyticsInstance.track(
            EVENT_NAMES.OnboardingSubjectsSkipped,
            { url: window.location.toString() }
        );
    };

    return (
        <AuthPageLayout
            imageUrl='/assets/images/auth-layouts/fail-layout.svg'
            title={intl.formatMessage(messages.lxAuthSubjectSelectPageHeading)}
            progressPercentage={ProgressPercentageEnum.EducatorPreferences}
            onClickLogo={props.completeOnboarding}
        >
            <div className='select-role-container'>
                <h5>{intl.formatMessage(messages.lxAuthSelectLevelPageHeading)}</h5>
                <div className='description'>
                    {intl.formatMessage(messages.lxAuthSelectLevelSelectMessage)}
                </div>
                <div className='level-select-button-container'>
                    <Button
                        className={classNames('select-button', {'active' : level.includes(TEACH_LEVELS.HIGH_SCHOOL)})}
                        btnStyle='outline'
                        onClick={() => toggleLevel(TEACH_LEVELS.HIGH_SCHOOL)}
                        label={messages.subjectSelectButtonHigh}
                    />
                    <Button
                        className={classNames('select-button', {'active' : level.includes(TEACH_LEVELS.MIDDLE_SCHOOL)})}
                        btnStyle='outline'
                        onClick={() => toggleLevel(TEACH_LEVELS.MIDDLE_SCHOOL)}
                        label={messages.subjectSelectButtonMid}
                    />
                    <Button
                        className={classNames('select-button', {'active' : level.includes(TEACH_LEVELS.ADVANCED)})}
                        btnStyle='outline'
                        onClick={() => toggleLevel(TEACH_LEVELS.ADVANCED)}
                        label={messages.subjectSelectButtonAdvance}
                    />
                    <Button
                        className={classNames('select-button', {'active' : level.includes(TEACH_LEVELS.Other)})}
                        btnStyle='outline'
                        onClick={() => toggleLevel(TEACH_LEVELS.Other)}
                        label={messages.subjectSelectButtonOther}
                    />
                </div>

                <div className='subject-container'>
                    <h5>{intl.formatMessage(messages.lxAuthSelectSubjects)}</h5>
                    <div className='description'>
                        {intl.formatMessage(messages.lxAuthSelectLevelSelectMessage)}
                    </div>

                    <div className='checkbox-container'>
                        {subjects.map((item) => (
                            <div key={item.name} className='checkbox-row'>
                                <input
                                    id={item.name}
                                    type='checkbox'
                                    tab-index='0'
                                    className='checkBox'
                                    name={item.name}
                                    value={item.name}
                                    checked={subjectPayload.includes(item.name)}
                                    onChange={() => handleCheckboxChange(item.name)}
                                />
                                <label htmlFor={item.name}>{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>

                <hr className='divider'/>

                <div className='buttons-container'>
                    <Button
                        className='skip-button'
                        label={authMessages.lxAuthSkipLabel}
                        btnStyle='link'
                        onClick={skipPreferenceSettings}
                    />
                    <Button
                        className='first role-done-btn'
                        label={authMessages.lxAuthDoneLabel}
                        btnStyle='primary'
                        onClick={setEducatorPreferences}
                        disabled={!level.length || !subjectPayload.length}
                    />

                </div>
            </div>

        </AuthPageLayout>
    );
};
