import bind from 'bind-decorator';
import { ClassroomProgressLearner } from 'classrooms/components';
import { ClassroomsApi } from 'global/api';
import { Classroom, ClassroomDetail } from 'labxchange-client';
import * as React from 'react';
import { SmallTabBar, SmallTabButton } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface State {
    classrooms: Classroom[];
    archivedClassrooms: Classroom[];
    classroomDetails?: ClassroomDetail;
    loading: boolean;
}

export class ClassroomsWidget extends React.PureComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            classrooms: [],
            archivedClassrooms: [],
            loading: true,
        };
    }

    public componentDidMount() {
        this.loadClassrooms();
    }

    public render() {
        return (
            <div className='learner-progress-classrooms-widget'>
                <h2>
                    <WrappedMessage message={messages.learnerProgressClassroomsTitle}/>
                </h2>
                <div className='learner-progress-classrooms-widget-content'>
                    {this.state.loading ? null
                    : <>
                        {this.renderTabs()}
                        {this.renderContent()}
                    </>}
                </div>
            </div>
        );
    }

    private getTabButtons(classrooms: Classroom[], archived: boolean): React.ReactNode[] {
        return classrooms.map((classroom) =>
            <SmallTabButton
                key={classroom.id}
                active={this.state.classroomDetails &&
                    this.state.classroomDetails.id === classroom.id}
                label={messages.learnerProgressClassroomsName}
                labelValues={{className: `${classroom.name} ${archived ? ' (archived)' : ''}`}}
                onClick={() => this.loadClassroomDetails(classroom)}
            />,
        );
    }

    private renderTabs() {
        const activeClassrooms = this.getTabButtons(this.state.classrooms, false);
        const archivedClassrooms = this.getTabButtons(
            this.state.archivedClassrooms, true,
        );
        return <SmallTabBar>{activeClassrooms}{archivedClassrooms}</SmallTabBar>;
    }

    private renderContent() {
        if (this.state.classroomDetails === undefined) { return; }
        // Add a key paramter here to force component to be refreshed
        return <ClassroomProgressLearner
            key={this.state.classroomDetails.id}
            classroom={this.state.classroomDetails}
        />;
    }

    @bind private async loadClassrooms() {
        this.setState({loading: true});
        try {
            const classrooms = await ClassroomsApi.listAsLearner({});
            if (classrooms.length && classrooms[0].id !== undefined) {
                await this.loadClassroomDetails(classrooms[0]);
            }
            this.setState({classrooms});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadingError}/>, {
                exception: err,
            });
        }
        await this.loadInactiveClassrooms();
        this.setState({loading: false});
    }

    @bind private async loadInactiveClassrooms() {
        try {
            const archivedClassrooms = await ClassroomsApi.inactiveAsLearner({});
            this.setState({archivedClassrooms});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadingError}/>, {
                exception: err,
            });
        }
    }

    @bind private async loadClassroomDetails(classroom: Classroom) {
        if (classroom.id === undefined) { return; }
        if (this.state.classroomDetails && classroom.id === this.state.classroomDetails.id) {
            return;
        }
        try {
            const classroomDetails = await ClassroomsApi.read({id: classroom.id});
            this.setState({classroomDetails});
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadingError}/>, {
                exception: err,
            });
        }
    }
}
