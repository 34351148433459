import { defineMessages } from 'react-intl';

const messages = defineMessages({
    bookPageTitle: {
        id:'bookPageTitle',
        defaultMessage: '{name} - Book',
        description: 'HTML title for the book page.',
    },
    bookLoadError: {
        id:'bookLoadError',
        defaultMessage: 'Error: Unable to display this book.',
        description: 'Text when a book fails to load correctly.',
    },
    bookSourceLinkLabel: {
        id:'bookSourceLinkLabel',
        defaultMessage: 'See original textbook',
        description: 'Label of the link to book source.',
    },
    bookStartButton: {
        id:'bookStartButton',
        defaultMessage: 'Start textbook',
        description: 'Label of button to view the first asset in the textbook.',
    },
    bookPublishingDetails: {
        id:'bookPublishingDetails',
        defaultMessage: 'Publishing Details',
        description: 'Title for publishing details section of books.',
    },
    bookPublishingDetailsAuthorsLabel: {
        id:'bookPublishingDetailsAuthorsLabel',
        defaultMessage: 'Authors',
        description: 'Label for book authors.',
    },
    bookPublishingDetailsPublishDateLabel: {
        id:'bookPublishingDetailsPublishDateLabel',
        defaultMessage: 'Publish Date',
        description: 'Label for book publish date.',
    },
    bookPublishingDetailsWebVersionLastUpdatedLabel: {
        id:'bookPublishingDetailsWebVersionLastUpdatedLabel',
        defaultMessage: 'Web Version Last Updated',
        description: 'Label for book web version last updated date.',
    },
    bookPublishingDetailsLicenseLabel: {
        id:'bookPublishingDetailsLicenseLabel',
        defaultMessage: 'License',
        description: 'Label for book license.',
    },
    bookPublishingDetailsPrintEditionLabel: {
        id:'bookPublishingDetailsPrintEditionLabel',
        defaultMessage: 'Print',
        description: 'Label for book print edition details.',
    },
    bookPublishingDetailsDigitalEditionLabel: {
        id:'bookPublishingDetailsDigitalEditionLabel',
        defaultMessage: 'Digital',
        description: 'Label for book digital edition details.',
    },
    bookPublishingDetailsIBooksEditionLabel: {
        id:'bookPublishingDetailsIBooksEditionLabel',
        defaultMessage: 'iBook',
        description: 'Label for book iBook edition details.',
    },
    bookPublishingDetailsIBooksISBN10Label: {
        id:'bookPublishingDetailsIBooksISBN10Label',
        defaultMessage: 'ISBN-10',
        description: 'Label for book edition ISBN-10.',
    },
    bookPublishingDetailsIBooksISBN13Label: {
        id:'bookPublishingDetailsIBooksISBN13Label',
        defaultMessage: 'ISBN-13',
        description: 'Label for book edition ISBN-13.',
    },
});

export default messages;
