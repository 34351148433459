import { defineMessages } from 'react-intl';

const messages = defineMessages({
    resourcesHeading: {
        id:'resourcesHeading',
        defaultMessage: 'Resources',
        description: 'Resources section heading',
    },
    resourcesHeadingNewIconText: {
        id:'resourcesHeadingNewIconText',
        defaultMessage: 'New!',
        description: 'Resources section heading new icon',
    },
    resourceTypeHeading: {
        id:'resourceTypeHeading',
        defaultMessage: '{type} resources',
        description: 'displays heading for resource type section',
    },
    learnerResourceName: {
        id:'learnerResourceName',
        defaultMessage: 'Learner',
        description: 'learner resource type name',
    },
    educatorResourceName: {
        id:'educatorResourceName',
        defaultMessage: 'Educator',
        description: 'educator resource type name',
    },
    extensionTaskName: {
        id:'extensionTaskName',
        defaultMessage: 'Extension learning',
        description: 'educator resource type name',
    },
});

export default messages;
