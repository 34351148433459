import { AnnotatedVideoAnnotation } from 'labxchange-client';
import moment from 'moment-shortformat';
import * as React from 'react';
import * as UI from 'ui/components';
import messages from './displayMessages';


interface Props {
    annotation: AnnotatedVideoAnnotation[];
    index?: number;
    selected?: boolean;
    onOpenQuestion?(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]): void;
    isHorizontalBlock?: boolean;
    onOpenMore?(annotation: AnnotatedVideoAnnotation): void;
    isTheFirst?: boolean;
    isTheLast?: boolean;
    isTagsBarShowed?: boolean;
    ownerName?: string;
    ownerUrl?: string;
    hideAttribution?: boolean;
}

export class CoupledQuestions extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
    }

    private formatDuration(duration: number): string {
        const d = moment.duration(duration, 'seconds');
        return (
            `${d.hours().toString().padStart(2, '0')}:` +
            `${d.minutes().toString().padStart(2, '0')}:` +
            `${d.seconds().toString().padStart(2, '0')}`
        );
    }

    public render () {
        const annotation = this.props.annotation;
        const onOpenQuestion = this.props.onOpenQuestion;
        const withQuestion = true;
        let authorList = annotation.map((anno) => (
            {
                name: anno.authorName || this.props.ownerName || '',
                url: anno.authorUrl || this.props.ownerUrl || '',
            }
        ));
        authorList = this.removeDuplicateAuthors(authorList);

        return <li data-id={`annotation-grouped-questions`} className={
              `annotated-video-annotation
              ${this.props.selected ? 'annotated-video-annotation-selected' : ''}
              ${this.props.isHorizontalBlock ? 'horizontal-block' : ''}
              ${this.props.isTheFirst ? 'first-annotation' : ''}
              ${this.props.isTheLast ? 'last-annotation' : ''}
              ${this.props.isTagsBarShowed ? 'with-tags-bar' : ''}
            `}>
            <div className='annotated-video-annotation-range'>
               {this.formatDuration(annotation[0]?.start)}
            </div>
            <div className='annotated-video-annotation-title'>
                {messages.coupledQuestionsTitle.defaultMessage}
            </div>
            <div className='annotated-video-annotation-description horizontal-block'/>
            <div className='annotated-video-annotation-buttons'>
                {(onOpenQuestion && withQuestion) ?
                    <UI.Button
                        btnStyle='outline'
                        label={messages.openProblemButtonPluralLabel}
                        onClick={() => onOpenQuestion(annotation)}
                    /> : null
                }
            </div>
            {!this.props.isHorizontalBlock && !this.props.hideAttribution &&
                <div className='annotated-video-annotation-attribution'>
                    {authorList.map((author, index, array) => (
                        <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={author.url}>
                        {`${author.name}`}
                        <UI.Icon name='link-external'/>
                        {array.length - 1 === index ? '' : ' ,'}
                    </a>
                    ))}
                </div>
            }
        </li>;
    }

    private removeDuplicateAuthors(authorList: {name: string, url: string}[]) {
        const stringifiedAuthors = authorList.map((author) => JSON.stringify(author));
        const uniqAuthors = [...new Set(stringifiedAuthors)];
        return uniqAuthors.map((author) => JSON.parse(author));
    }
}