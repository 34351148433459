import { bind } from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import * as LoginActions from 'auth/actions';
import { getLoggedInStatus } from 'auth/selectors';
import { UsersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import authMessages from '../displayMessages';
import { intl } from 'i18n';
import { showErrorMessage } from 'ui/components';
import { AuthFailView } from '../AuthFailView';

interface Props {
    loginUser: typeof LoginActions.login;
    isLoggedIn: boolean;
}

interface State {
    buttonClicked: boolean;
    isEmbargoedRegion?: boolean;
}

@connect((state: RootState) => ({
    isLoggedIn: getLoggedInStatus(state),
}), {
    loginUser: LoginActions.login,
})
export class LoginView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            buttonClicked: false,
        };
    }

    public async componentDidMount() {
        const errorParam = this.getErrorParam();

        if (errorParam) {
            if (errorParam === 'social_sign_up') {
                showErrorMessage(<WrappedMessage message={authMessages.lxAuthAccountAlreadyCreatedMsg} />);
            }
            else if (errorParam === 'login_cancelled') {
                showErrorMessage(<WrappedMessage message={authMessages.lxAuthSocialLoginCanceled} />);
            }
        }

        try {
            const result = await UsersApi.embargoStatus();
            this.setState({isEmbargoedRegion: Boolean(result.embargoedRegion)});
        } catch (err) {
            // If we cannot preform the check for whatever reason, let the user log in anyway.
            // tslint:disable-next-line:no-console
            console.error(err);
            this.setState({isEmbargoedRegion: false});
        }
    }

    public render() {
        if (this.props.isLoggedIn) {
            return <Redirect to={ROUTES.Explore.HOME} push={true} />;
        }

        if (this.state.buttonClicked && this.state.isEmbargoedRegion) {
            return <AuthFailView type='country'/>;
        }

        return (
            <StandardPageLayout pageTitle={messages.pageTitle}>
                <div className='login'>
                    <h1><WrappedMessage message={messages.loginTitle}/></h1>
                    <div className='box'>
                        <div className='logos'>
                            <img
                                alt=''
                                height='115px'
                                className='labxchange-logo'
                                src='/assets/images/labxchange-logo-full.svg'
                            />
                            <img
                                alt=''
                                height='72px'
                                className='edx-logo'
                                src='/assets/images/edx-logo.svg'
                            />
                        </div>
                        <h2><WrappedMessage message={messages.loginSubTitle}/></h2>
                        <div>
                            <WrappedMessage
                                message={messages.accountIsEdxAccount}
                                values={{ edxLink: <a target='_blank' rel='noopener noreferrer' href='http://edx.org'>edX.org</a> }}
                            />
                        </div>
                        <div className='sub-box'>
                            <h2><WrappedMessage message={messages.loginBoxTitle}/></h2>
                            <p><WrappedMessage message={messages.loginBoxParagraphOne}/></p>
                            <p><WrappedMessage message={messages.loginBoxParagraphTwo}/></p>
                        </div>
                        <button
                            className='btn btn-warning'
                            disabled={this.state.buttonClicked || this.isLoading()}
                            onClick={this.onButtonClick}
                            aria-label={intl.formatMessage(messages.getStartedButtonLabel)}>
                            <WrappedMessage message={messages.getStartedButtonLabel}/>
                        </button>
                        <div className='spinner' data-active={this.isLoading()} />
                    </div>
                </div>
            </StandardPageLayout>
        );
    }

    @bind private onButtonClick() {
        this.setState({buttonClicked: true});
        if (this.state.isEmbargoedRegion === false) {
            this.props.loginUser();
        }
    }

    private isLoading() {
        return this.state.isEmbargoedRegion === undefined;
    }

    private getErrorParam() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('error');
    }
}
