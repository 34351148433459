import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  Module,
  ModuleSlab,
  ModuleSlabDisplayTypeEnum,
} from 'labxchange-client';
import { StandardPageLayout } from 'ui/components';
import { ModulesApi } from 'global/api';
import { getCurriculumGroup } from 'auth/selectors';

import InteractiveCardsSlab from 'subjects/components/page/InteractiveCardsSlab';
import PathwaysSlab from 'subjects/components/page/PathwaysSlab';
import ClassicCardsSlab from 'subjects/components/page/ClassicCardsSlab';
import SkeletonSlabs from 'subjects/components/page/SkeletonSlabs';
import LearningObjectives from '../LearningObjectives/LearningObjectives';
import TopicPageHeader from '../TopicPageHeader/TopicPageHeader';
import ListTypeSlab from '../ListTypeSlab/ListTypeSlab';
import TopicBarCards from 'subjects/components/page/TopicBarCards';


export const CurriculumTopicPage: FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const curriculum = pathSegments[pathSegments.length - 4];
  const subject = pathSegments[pathSegments.length - 3];
  const grade = pathSegments[pathSegments.length - 2];
  const moduleSlug = pathSegments[pathSegments.length - 1];
  const version = new URLSearchParams(window.location.search).get('version') || undefined;
  const [data, setData] = useState<Module | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [moduleSlabs, setModuleSlabs] = useState<ModuleSlab[]>([]);
  const userCurriculumGroup = useSelector(getCurriculumGroup);
  const canUserGiveFeedback = userCurriculumGroup === curriculum;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response: Module = await ModulesApi.read({ id: moduleSlug, curriculum, subject, grade, version });
      setData(response);
      if (response.moduleSlabs) {
        setModuleSlabs(response.moduleSlabs);
      }
      setLoading(false);
    };

    fetchData();
  }, [moduleSlug]);

  const renderSlabs = () => {
    return moduleSlabs
      .map((slab: ModuleSlab, index: number) => {
        if (slab.displayType === ModuleSlabDisplayTypeEnum.Interactives && slab.items.length > 0) {
          return (
            <InteractiveCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
              showFeedbackButton={canUserGiveFeedback}
              curriculumSlug={curriculum}
            />
          );
        } else if (slab.displayType === ModuleSlabDisplayTypeEnum.Pathways && slab.items.length > 0) {
          return (
            <PathwaysSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.displayType === ModuleSlabDisplayTypeEnum.List && slab.items.length > 0) {
          return (
            <ListTypeSlab
              key={index}
              heading={slab.title}
              slab={slab}
            />
          );
        } else if (slab.displayType === ModuleSlabDisplayTypeEnum.SimpleCards && slab.items.length > 0) {
          return (
            <ClassicCardsSlab
              key={index}
              heading={slab.title}
              slab={slab}
              showFeedbackButton={canUserGiveFeedback}
              curriculumSlug={curriculum}
            />
          );
        }
        return null;
      });
  };

  const renderSkeleton = () => {
    return (
      <div className='loading-skeleton' data-testid='loading-container'>
        <SkeletonSlabs count={4} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        className={classNames('curriculum-content', {'cards-feedback-height': canUserGiveFeedback})}
        data-testid='cards-container'
      >
        {data && data.learningObjectives.length > 0 && <LearningObjectives learningObjectives={data.learningObjectives} heading={data.learningObjectivesHeading} />}
        {renderSlabs()}
        {data && data.siblings.length > 0 && <TopicBarCards curriculumPage={true} topics={data.siblings} />}
      </div>
    );
  };

  return (
    <StandardPageLayout
        backgroundStyle='light-header'
        mainClassName='curriculum-topic-page'
        headerFeature={<TopicPageHeader
            topicTitle={data ? data.name : ''}
            loading={loading}
            breadCrumbs={data?.breadcrumbs}
          />}
    >
      {(loading || !moduleSlabs) ? renderSkeleton() : renderContent()}
    </StandardPageLayout>
  );
};
