// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly id?: string;
    /**
     * The HTML content of the banner.
     * @type {string}
     * @memberof Banner
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    action?: BannerActionEnum;
    /**
     * Set this with a regex expression to only show the banner in the urls that comply with that expression. Leave blank to display the banner anywhere when starting the site.
     * @type {string}
     * @memberof Banner
     */
    regexUrl?: string;
    /**
     * If is True, the user will not be able to dismiss this banner.
     * @type {boolean}
     * @memberof Banner
     */
    disableDismiss?: boolean;
}

export function BannerFromJSON(json: any): Banner {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': json['content'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'regexUrl': !exists(json, 'regex_url') ? undefined : json['regex_url'],
        'disableDismiss': !exists(json, 'disable_dismiss') ? undefined : json['disable_dismiss'],
    };
}

export function BannerToJSON(value?: Banner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
        'action': value.action,
        'regex_url': value.regexUrl,
        'disable_dismiss': value.disableDismiss,
    };
}

/**
* @export
* @enum {string}
*/
export enum BannerActionEnum {
    CookieAcceptance = 'cookie_acceptance'
}


