import * as React from 'react';

import { LxConfig, ROUTES } from 'global/constants';
import { OrganizationResponse } from 'labxchange-client';

interface Props {
    organizationData: OrganizationResponse;
}

export const OrganizationProfileDashboard: React.FunctionComponent<Props> = ({
    organizationData,
}) => {
    return <>
        <p>
            In the future, you'll be able to edit this organization's{' '}
            <a href={ROUTES.Organizations.PROFILE_SLUG(organizationData.org.slug)}>public profile</a>{' '}
            here.
        </p>
        <p>
            Until then, if you have permission, you can{' '}
            <a href={`${LxConfig.BackendBaseUrl}/admin/library/organization/${organizationData.org.id}/change/`}>
                edit the profile from the Django admin
            </a> - or ask LabXchange staff.
        </p>
    </>;
};
