import { defineMessages } from 'react-intl';

const messages = defineMessages({
    assignmentEditorAddAssessment: {
        id:'assignmentEditorAddAssessment',
        defaultMessage: 'Add a Question',
        description: 'Button to add a new assessment to an assignment',
    },
    assignmentEditorAlreadyAdded: {
        id:'assignmentEditorAlreadyAdded',
        defaultMessage: 'That question cannot be added twice to the same question set.',
        description: 'Message when user tries to add the same assessment multiple times.',
    },
    assignmentEditorAssessmentsHeading: {
        id:'assignmentEditorAssessmentsHeading',
        defaultMessage: 'Questions',
        description: 'Heading for the list of assessments that have been added to an assignment.',
    },
    assignmentEditorAttempts: {
        id:'assignmentEditorAttempts',
        defaultMessage: 'Attempts',
        description: 'Heading that displays the number of attempts learners are allowed on this problem',
    },
    assignmentEditorAuthoredBy: {
        id:'assignmentEditorAuthoredBy',
        defaultMessage: 'Authored by: {authors}',
        description: 'Labels the list of authors',
    },
    assignmentEditorDeleteAssessment: {
        id:'assignmentEditorDeleteAssessment',
        defaultMessage: 'Delete this question',
        description: 'Explanation of the delete button next to each assessment',
    },
    assignmentEditorEditAssessment: {
        id:'assignmentEditorEditAssessment',
        defaultMessage: 'Edit this question',
        description: 'Explanation of the edit button next to each assessment',
    },
    assignmentEditorNewlyAdded: {
        id:'assignmentEditorNewlyAdded',
        defaultMessage: '"Attempts" and "Points" values for newly added questions will be shown after you save changes.',
        description: 'Message when user adds new assessments to explain why # points is not showing up.',
    },
    assignmentEditorTotalPoints: {
        id:'assignmentEditorTotalPoints',
        defaultMessage: 'Total Points: {totalPoints}',
        description: 'Heading that displays the total number of points learners can earn',
    },
    assignmentEditorPoints: {
        id:'assignmentEditorPoints',
        defaultMessage: 'Points',
        description: 'Heading that displays the number of points learners can earn on this problem',
    },
    audioEditorEmbedCodeLabel: {
        id:'audioEditorEmbedCodeLabel',
        defaultMessage: 'Enter embed code',
        description: 'Label for the embed code field in the audio block',
    },
    audioEditorEmbedCodePlaceholder: {
        id:'audioEditorEmbedCodePlaceholder',
        defaultMessage: 'Enter an embed code from SoundCloud or another streaming service.',
        description: 'Placeholder for the embed code field in the audio block',
    },
    audioEditorEmbedCodeInstructions: {
        id:'audioEditorEmbedCodeInstructions',
        defaultMessage: `To embed audio, you will need the embed code.
        We support SoundCloud, Stitcher, and other major services.
        Read our guidelines `,
        description: 'Instructions for the embed code field in the audio block',
    },
    audioEditorEmbedCodeInstructionsLinkName: {
        id:'audioEditorEmbedCodeInstructionsLinkName',
        defaultMessage: 'here.',
        description: 'Link name in the instructions.',
    },
    cannotEditThatType: {
        id:'cannotEditThatType',
        defaultMessage: 'This component cannot be edited on LabXchange yet. You can still edit its metadata using this page.',
        description: 'Message when editing a specific type of asset is not [yet] supported.',
    },
    imageEditorAltTextDescription: {
        id:'imageEditorAltTextDescription',
        defaultMessage: `This text will help screen-reading tools describe images to visually impaired readers.
         It will also be displayed if the image does not load correctly.`,
        description: 'Caption field detailed help.',
    },
    imageEditorAltTextField: {
        id:'imageEditorAltTextField',
        defaultMessage: 'Alternate text',
        description: 'Name of the "Alternate text" field when editing an image',
    },
    imageEditorAltTextPlaceholder: {
        id:'imageEditorAltTextPlaceholder',
        defaultMessage: 'Enter alternative text that describes the appearance and function of your image',
        description: 'Caption field placeholder text',
    },
    imageEditorCaptionField: {
        id:'imageEditorCaptionField',
        defaultMessage: 'Image Caption (Optional)',
        description: 'Name of the "Caption" field when editing an image',
    },
    imageEditorCaptionPlaceholder: {
        id:'imageEditorCaptionPlaceholder',
        defaultMessage: 'Enter a caption or figure legend for your image',
        description: 'Caption field placeholder text',
    },
    imageEditorExtendedDescField: {
        id:'imageEditorExtendedDescField',
        defaultMessage: 'Extended Description (Optional)',
        description: 'Name of the "Extended Description" field when editing an image',
    },
    imageEditorExtendedDescPlaceholder: {
        id:'imageEditorExtendedDescPlaceholder',
        defaultMessage: 'Enter extended description',
        description: 'Extended description field placeholder text',
    },
    imageEditiorAdvancedOptions: {
        id:'imageEditiorAdvancedOptions',
        defaultMessage: 'Advanced Options',
        description: 'Advanced options for an image.',
    },
    imageEditorCitationDescription: {
        id:'imageEditorCitationDescription',
        defaultMessage: `Please cite your source.
        It might be your name, another individual's name, a journal title, or a URL.
        This will display together with your caption underneath the image.`,
        description: 'Citation field detailed help.',
    },
    imageEditorCitationField: {
        id:'imageEditorCitationField',
        defaultMessage: 'Image source',
        description: 'Name of the "Citation" field when editing an image',
    },
    imageEditorCitationPlaceholder: {
        id:'imageEditorCitationPlaceholder',
        defaultMessage: 'Enter the source of your image',
        description: 'Citation field placeholder text',
    },
    imageExtendedDescFAQ: {
        id: 'imageExtendedDescFAQ',
        defaultMessage: 'What is an extended description?',
        description: 'About extended description'
    },
    imageCaptionFAQ: {
        id: 'imageCaptionFAQ',
        defaultMessage: 'What is an image caption?',
        description: 'About image caption'
    },
    textEditorContentField: {
        id:'textEditorContentField',
        defaultMessage: 'Text content',
        description: 'Text content field.',
    },
    textEditorDefaultText: {
        id:'textEditorDefaultText',
        defaultMessage: 'Enter your text',
        description: 'Default text in text editor',
    },
    narrativeEditorNarrativeField: {
        id:'narrativeEditorNarrativeField',
        defaultMessage: 'Narrative',
        description: 'Label for the main html content field for the narrative',
    },
    narrativeEditorNarrativeDescriptionField: {
        id:'narrativeEditorNarrativeDescriptionField',
        defaultMessage: `Your narrative might include information about your background,
         how you ended up in your chosen field or career, or how you engage with science in unique ways.`,
        description: 'Description for the main html content field for the narrative',
    },
    narrativeEditorKeyPointsField: {
        id:'narrativeEditorKeyPointsField',
        defaultMessage: 'Key Points',
        description: 'Label for the key points html content field for the narrative',
    },
    narrativeEditorKeyPointsDescriptionField: {
        id:'narrativeEditorKeyPointsDescriptionField',
        defaultMessage: 'Please enter a few takeaway messages for readers of your narrative.',
        description: 'Label for the key points description field for the narrative',
    },
    videoEditorEmbedOkayMessage: {
        id:'videoEditorEmbedOkayMessage',
        defaultMessage: 'Your video was successfully imported.',
        description: 'Message shown when a video link has been embedded when creating a new video asset.',
    },
    transcriptEditorUploadButtonLabel: {
        id:'transcriptEditorUploadButtonLabel',
        defaultMessage: 'Upload transcript',
        description: 'Label for the upload video transcript button',
    },
    transcriptEditorChangeButtonLabel: {
        id:'transcriptEditorChangeButtonLabel',
        defaultMessage: 'Change',
        description: 'Label for the change video transcript button',
    },
    transcriptEditorDownloadButtonLabel: {
        id:'transcriptEditorDownloadButtonLabel',
        defaultMessage: 'Download',
        description: 'Label for the upload video transcript button',
    },
    transcriptEditorDeleteButtonLabel: {
        id:'transcriptEditorDeleteButtonLabel',
        defaultMessage: 'Delete',
        description: 'Label for the delete video transcript button',
    },
    audioTrEditContent: {
        id: 'audioTrEditContent',
        defaultMessage: 'Transcript',
        description: 'Label for the content text editor for a transcript.',
    },
    audioTrTextEditor: {
        id: 'audioTrTextEditor',
        defaultMessage: 'Text editor',
        description: 'Label for the button to open a text editor.',
    },
    caseStudyEditorAddSectionLabel: {
        id:'caseStudyEditorAddSectionLabel',
        defaultMessage: '+ Add section',
        description: 'Label for the add section button on the case study editor',
    },
    caseStudyEditorExpandSectionLabel: {
        id:'caseStudyEditorExpandSectionLabel',
        defaultMessage: 'Expand section',
        description: 'SR only label for button to expand section in case study editor',
    },
    caseStudyEditorCollapseSectionLabel: {
        id:'caseStudyEditorCollapseSectionLabel',
        defaultMessage: 'Collapse section',
        description: 'SR only label for button to collapse section in case study editor',
    },
    caseStudyEditorRemoveSectionLabel: {
        id:'caseStudyEditorRemoveSectionLabel',
        defaultMessage: 'Remove',
        description: 'Label for button to remove section in case study editor',
    },
    caseStudyEditorAddTextboxLabel: {
        id:'caseStudyEditorAddTextboxLabel',
        defaultMessage: '+ Add textbox',
        description: 'Label for button to add text box to section in case study editor',
    },
    caseStudyEditorAddMediaLabel: {
        id:'caseStudyEditorAddMediaLabel',
        defaultMessage: '+ Add rich media',
        description: 'Label for button to open content picker to add media to section in case study editor',
    },
    caseStudyEditorOr: {
        id:'caseStudyEditorOr',
        defaultMessage: 'Or',
        description: 'Label to denote a choice between two buttons',
    },
    caseStudyEditorMoveUp: {
        id:'caseStudyEditorMoveUp',
        defaultMessage: 'Move up',
        description: 'Label on button to move asset up',
    },
    caseStudyEditorMoveDown: {
        id:'caseStudyEditorMoveDown',
        defaultMessage: 'Move down',
        description: 'Label on button to move asset down',
    },
    caseStudyEditorTextContentLabel: {
        id:'caseStudyEditorTextContentLabel',
        defaultMessage: 'text content',
        description: 'SR label for rich text content editors',
    },
    caseStudyEditorContentTitle: {
        id:'caseStudyEditorContentTitle',
        defaultMessage: 'Content',
        description: 'Title of the case study editor attachments section',
    },
    caseStudyEditorAddContent: {
        id:'caseStudyEditorAddContent',
        defaultMessage: 'Add content',
        description: 'Label on the button to add attachment to case study.',
    },
    caseStudyEditorSectionTitleInputLabel: {
        id:'caseStudyEditorSectionTitleInputLabel',
        defaultMessage: 'Title',
        description: 'Label for the section title input element.',
    },
    teachingGuideEditorAddContentLinkLabel: {
        id:'teachingGuideEditorAddContentLinkLabel',
        defaultMessage: '+ Add content link',
        description: 'Label for button to open content picker to add content link to section in teaching guide editor',
    },
    caseStudySummaryTitle: {
        id: 'caseStudySummaryTitle',
        defaultMessage: 'Summary',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyIntroTitle: {
        id: 'caseStudyIntroTitle',
        defaultMessage: 'Introduction',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyResultsTitle: {
        id: 'caseStudyResultsTitle',
        defaultMessage: 'Results',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyDiscussionTitle: {
        id: 'caseStudyDiscussionTitle',
        defaultMessage: 'Discussion',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyMaterialsTitle: {
        id: 'caseStudyMaterialsTitle',
        defaultMessage: 'Materials and Methods',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyAcknowledgementsTitle: {
        id: 'caseStudyAcknowledgementsTitle',
        defaultMessage: 'Acknowledgements',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyReferencesTitle: {
        id: 'caseStudyReferencesTitle',
        defaultMessage: 'References',
        description: 'One of the titles for the default new case study skeleton',
    },
    caseStudyCitationTitle: {
        id: 'caseStudyCitationTitle',
        defaultMessage: 'Citation',
        description: 'One of the titles for the default new case study skeleton',
    },
    narrativeDefaultContent: {
        id: 'narrativeDefaultContent',
        defaultMessage: 'Enter your narrative here',
        description: 'Default placeholder text for a new narrative asset',
    },
});

export default messages;
