import { defineMessages } from 'react-intl';

const messages = defineMessages({
    navlinkClass: {
        id:'navlinkClass',
        defaultMessage: 'Class',
        description: 'Header Navlink Tab to Class',
    },
    navlinkProgress: {
        id:'navlinkProgress',
        defaultMessage: 'Progress',
        description: 'Header Navlink Tab to Progress',
    },
    navlinkDiscussion: {
        id:'navlinkDiscussion',
        defaultMessage: 'Discussion',
        description: 'Header Navlink Tab to Discussion',
    },
    buttonMembershipJoin: {
        id:'buttonMembershipJoin',
        defaultMessage: 'Join Class',
        description: 'Header button for joining class',
    },
    buttonMembershipLeave: {
        id:'buttonMembershipLeave',
        defaultMessage: 'Leave class',
        description: 'Header button for leaving class',
    },
    learnerView: {
        id:'learnerView',
        defaultMessage: 'Learner view',
        description: 'Label for the learner view toggle in the educator view of the classroom page.',
    },
});

export default messages;
