import { defineMessages } from 'react-intl';

const messages = defineMessages({
    shareTabTitle: {
        id:'shareTabTitle',
        defaultMessage: 'Share',
        description: 'Title of the share tab',
    },
    embedTabTitle: {
        id:'embedTabTitle',
        defaultMessage: 'Embed',
        description: 'Title of the embed tab',
    },
    facebookShare: {
        id:'facebookShare',
        defaultMessage: 'Share to Facebook',
        description: 'Share to facebook button title.',
    },
    twitterShare: {
        id:'twitterShare',
        defaultMessage: 'Share to Twitter',
        description: 'Share to Twitter button title.',
    },
    pinterestShare: {
        id:'pinterestShare',
        defaultMessage: 'Share to Pinterest',
        description: 'Share to Pinterest button title.',
    },
    linkedinShare: {
        id:'linkedinShare',
        defaultMessage: 'Share to LinkedIn',
        description: 'Share to LinkedIn button title.',
    },
    classroomShare: {
        id:'classroomShare',
        defaultMessage: 'Share to Google Classroom',
        description: 'Share to Google Classroom button title.',
    },
    copyLink: {
        id:'copyLink',
        defaultMessage: 'Copy link to clipboard',
        description: 'Title on button to copy asset link to clipboard.',
    },
    successfulCopyTitle: {
        id:'successfulCopyTitle',
        defaultMessage: 'Success',
        description: 'Title on notification that copy to clipboard succeeded',
    },
    successfulCopyDescription: {
        id:'successfulCopyDescription',
        defaultMessage: 'Link copied to clipboard.',
        description: 'Description on notification that copy to clipboard succeeded',
    },
});

export default messages;
