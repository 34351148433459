import { ROUTES } from 'global/constants';
import { intl } from 'i18n';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Button, ShowPasswordButton, TextInput } from 'ui/components';
import messages from '../displayMessages';
import { NewsletterCheck } from './NewsletterCheck';
import { EVENT_NAMES } from 'tracking/constants';
import { useAnalytics } from 'use-analytics';

interface Props {
    name: string;
    birthdate: string;
    email: string;
    password: string;
    showPassword: boolean;
    bottomMessage?: MessageDescriptor;
    invalidBirthday?: boolean;
    invalidEmail?: boolean;
    invalidPassword?: boolean;
    disableSignUp?: boolean;
    newsletterChecked?: boolean;
    onChangeName: (name: string) => void;
    onChangeBirthdate: (birthdate: string) => void;
    onChangeEmail: (email: string) => void;
    onEmailFocusOut?: (email: string) => void;
    onChangePassword: (password: string) => void;
    onChangeShowPassword: (showPassword: boolean) => void;
    onPasswordFocusOut?: (password: string) => void;
    onCheck?: (value: boolean) => void;
    onSignUp: () => void;
}

export const EmailSignUpComponent: React.FunctionComponent<Props> = (props) => {
    const analytics = useAnalytics();
    return (
        <div className='email-sign-up-component'>
            <h5>{intl.formatMessage(messages.lxAuthSignUpWithEmailComponentTitle)}</h5>
            <TextInput
                className='sign-up-name'
                inputKey='sign-up-name'
                value={props.name}
                onChangeValue={props.onChangeName}
                type='text'
                placeholder={messages.lxAuthNameInputPlaceholder}
            />
            <TextInput
                className='birthdate-input'
                inputKey='sign-up-birthdate'
                value={props.birthdate}
                onChangeValue={props.onChangeBirthdate}
                invalid={props.invalidBirthday}
                feedbackMessage={
                    props.invalidBirthday ?
                        messages.lxAuthSignUpInvalidBirthdayMessage :
                        messages.lxAuthSignUpAgeWarning
                }
                feedbackMessageValues={{
                    msg: <NavLink
                        className='explore-link'
                        to={ROUTES.Explore.HOME}
                    >
                        {intl.formatMessage(messages.lxAuthExploreOurLibraryLabel)}
                    </NavLink>
                }}
                type='date'
                placeholder={messages.lxAuthUserAgeTextPlaceholder}
            />
            <TextInput
                className='sign-up-email'
                inputKey='sign-up-email'
                autoComplete='username'
                value={props.email}
                onChangeValue={props.onChangeEmail}
                onFocusOut={props.onEmailFocusOut}
                type='text'
                placeholder={messages.lxAuthEmailInputPlaceholder}
                invalid={props.invalidEmail}
                feedbackMessage={props.invalidEmail ? messages.lxAuthSignUpInvalidEmailMessage : undefined}
            />
            <TextInput
                className='password-input'
                inputKey='sign-up-password'
                autoComplete='new-password'
                value={props.password}
                onChangeValue={props.onChangePassword}
                type={props.showPassword ? 'text' : 'password'}
                placeholder={messages.lxAuthPasswordInputPlaceholder}
                feedbackMessage={messages.lxAuthPasswordInputFeedback}
                invalid={props.invalidPassword}
                onFocusOut={props.onPasswordFocusOut}
                inputAccessory={
                    <ShowPasswordButton
                        active={props.showPassword}
                        onClick={props.onChangeShowPassword}
                    />
                }
            />
            {props.bottomMessage && props.onCheck &&
                <NewsletterCheck
                    onCheck={props.onCheck}
                    checked={props.newsletterChecked}
                />
            }
            <Button
                className='sign-up-button'
                btnStyle='primary'
                label={messages.lxAuthSignUpLavel}
                disabled={props.disableSignUp}
                onClick={() => {
                    analytics.track(EVENT_NAMES.EmailSignUpFormSubmitted, { url: window.location.toString() }, { sendImmediately: true });
                    props.onSignUp();
                }}
            />
        </div>
    );
};
