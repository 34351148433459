import { ClassroomMembership, ClassroomMembershipStatusEnum } from 'labxchange-client';
import * as React from 'react';
import { Icon } from 'ui/components';
import { UserAvatar } from 'user/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface ClassroomMembershipBoxProps extends React.PropsWithChildren {
    members: ClassroomMembership[];
}

export const ClassroomMembershipBox: React.FunctionComponent<ClassroomMembershipBoxProps> = ({ members, children }) => {
    const [collapsed, setCollapse] = React.useState(true);
    const activeMembers = members.filter((member) => member.status === ClassroomMembershipStatusEnum.Joined);
    return (
        <div className={`classrooms-membership-box ${collapsed ? 'classrooms-membership-box-collapsed' : ''}`}>
            <div className='membership-box-heading'>
                <WrappedMessage
                    message={messages.membershipBoxHeading}
                    values={{ learnerCount: activeMembers.length }}
                />
                <button className='unstyled' onClick={() => setCollapse(!collapsed)}>
                    <Icon name='chevron-down' zoom='28'/>
                </button>
            </div>
            <div className='membership-box-content'>
                <div className='membership-box-members-list'>
                    {
                        activeMembers.map((member) => {
                            const username = member.user ? member.user.username : '';
                            return (
                                <div className='membership-box-member-item'>
                                    <UserAvatar
                                        username={username}
                                        diameter='36px'
                                        key={username}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
                {children}
            </div>
        </div>
    );
};
