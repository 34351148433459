// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
    NotificationCount,
    NotificationCountFromJSON,
    NotificationCountToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 
     * @type {NotificationCount}
     * @memberof NotificationResponse
     */
    count: NotificationCount;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationResponse
     */
    list: Array<Notification>;
}

export function NotificationResponseFromJSON(json: any): NotificationResponse {
    return {
        'count': NotificationCountFromJSON(json['count']),
        'list': (json['list'] as Array<any>).map(NotificationFromJSON),
    };
}

export function NotificationResponseToJSON(value?: NotificationResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': NotificationCountToJSON(value.count),
        'list': (value.list as Array<any>).map(NotificationToJSON),
    };
}


