import {getDefaultState} from '../../models';
import {SCORRING_TEMPTS_CHANGED, SCORRING_POINTS_CHANGED} from '../actions/action-types';

const ScorringSettingReducer = function(state = getDefaultState().scorringSettings, action: any) {
    switch(action.type) {
        case SCORRING_TEMPTS_CHANGED:
            const selectedTempt = {
                id: action.id,
                value: action.value,
                label: action.label
            };
            return Object.assign({}, state, {
                attemptsOptions: state.attemptsOptions.map(tempt => {
                    if (tempt.id === action.id) {
                        return {
                            id: action.id,
                            value: action.value,
                            label: action.label
                        };
                    } else {
                        return tempt;
                    }
                }),
                selectedAttemptsOption: selectedTempt
            });
        case SCORRING_POINTS_CHANGED:
            const selectedPoint = {
                id: action.id,
                value: action.value,
                label: action.label
            };
            return Object.assign({}, state, {
                pointsOptions: state.pointsOptions.map(point => {
                    if (point.id === action.id) {
                        return {
                            id: action.id,
                            value: action.value,
                            label: action.label
                        };
                    } else {
                        return point;
                    }
                }),
                selectedPointOption: selectedPoint
            });
        default:
            return state;
    }
};

export default ScorringSettingReducer;
