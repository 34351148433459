import { Thread } from 'labxchange-client';
import { IconSymbol } from 'elements';

export enum ThreadPinStatusEnum {
    Rules = 100,
    Announcements = 110,
    Pinned = 120,
    Default = 130,
}

export const getThreadIconName = (thread: Thread) : IconSymbol | null => {
    switch (thread.pinStatus) {
        case ThreadPinStatusEnum.Pinned:
            return 'pin';
        case ThreadPinStatusEnum.Announcements:
            return 'pin';
        case ThreadPinStatusEnum.Rules:
            return 'pin';
        default: return null;
    }
};
