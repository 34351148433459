import update from 'immutability-helper';

import { Notification, NotificationResponse } from 'labxchange-client';

import * as NotificationActions from './actions';

const emptyNotificationResponse: NotificationResponse = {
    count: {
        all: 0,
        unread: 0,
    },
    list: [],
};

/**
 * Reducer that maintains the state of the notifications
 */
export function notificationReducer(
    state: Readonly<NotificationResponse> = emptyNotificationResponse,
    action: NotificationActions.ActionTypes,
): Readonly<NotificationResponse> {
    switch (action.type) {
        case NotificationActions.Types.CACHE_NOTIFICATIONS:
            return Object.assign({}, action.response);
        case NotificationActions.Types.MARK_ALL_NOTIFICATIONS_READ:
            let newList: Notification[] = [];
            if (state.list) {
                newList = state.list.map((notification) => {
                    return update(notification, {unread: {$set: false}});
                });
            }
            return update(state, {
                count: {unread: {$set: 0}},
                list: {$set: newList},
            });
        case NotificationActions.Types.MARK_NOTIFICATION_READ: {
            if (state.list) {
                const index = state.list.findIndex((item) => {
                    return item.id === action.notificationId;
                });
                if (index > -1) {
                    const isUnread = state.list[index].unread;
                    if (isUnread) {
                        return update(state, {
                            count: {unread: (x?: number): number => x ? x - 1 : 0},
                            list: {[index]: {unread: {$set: false}}},
                        });
                    }
                }
            }
            return state;
        }
        case NotificationActions.Types.MARK_NOTIFICATION_UNREAD: {
            if (state.list) {
                const index = state.list.findIndex((item) => {
                    return item.id === action.notificationId;
                });
                if (index > -1) {
                    const isRead = !state.list[index].unread;
                    if (isRead) {
                        return update(state, {
                            count: {unread: (x?: number): number => x ? x + 1 : 0},
                            list: {[index]: {unread: {$set: true}}},
                        });
                    }
                }
            }
            return state;
        }
        default:
            return state;
    }
}
