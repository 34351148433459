import { defineMessages } from 'react-intl';

const messages = defineMessages({
    ownerEducatorTitle: {
        id:'ownerEducatorTitle',
        defaultMessage: 'Class Instructor',
        description: 'text for title underneath an educator that owns the class',
    },
    colleagueEducatorTitle: {
        id:'colleagueEducatorTitle',
        defaultMessage: 'Co-educator',
        description: 'text for title underneath a co-educator',
    },
    viewProfileButtonTitle: {
        id:'viewProfileButtonTitle',
        defaultMessage: 'View Profile',
        description: 'text for view profile button in box showing educator info in class detail page (learner view)',
    },
    classDescriptionHeading: {
        id:'classDescriptionHeading',
        defaultMessage: 'About this class',
        description: 'Text for the heading above class description on class detail page',
    },
    reportClassroom: {
        id: 'reportClassroomID',
        defaultMessage: 'Report',
        description: 'Learner Classroom kebab item to send report.',
    },
});

export default messages;
