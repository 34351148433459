// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ExploreSection,
    ExploreSectionFromJSON,
    ExploreSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExploreContent
 */
export interface ExploreContent {
    /**
     * 
     * @type {Array<ExploreSection>}
     * @memberof ExploreContent
     */
    readonly exploreSections?: Array<ExploreSection>;
    /**
     * 
     * @type {string}
     * @memberof ExploreContent
     */
    title: string;
    /**
     * A unique human readable name, e.g., 'biology-middle'
     * @type {string}
     * @memberof ExploreContent
     */
    slug: string;
    /**
     * This will help differentiate content for different levels.
     * @type {string}
     * @memberof ExploreContent
     */
    level?: ExploreContentLevelEnum;
}

export function ExploreContentFromJSON(json: any): ExploreContent {
    return {
        'exploreSections': !exists(json, 'explore_sections') ? undefined : (json['explore_sections'] as Array<any>).map(ExploreSectionFromJSON),
        'title': json['title'],
        'slug': json['slug'],
        'level': !exists(json, 'level') ? undefined : json['level'],
    };
}

export function ExploreContentToJSON(value?: ExploreContent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'slug': value.slug,
        'level': value.level,
    };
}

/**
* @export
* @enum {string}
*/
export enum ExploreContentLevelEnum {
    Middle = 'middle',
    High = 'high',
    Advance = 'advance'
}


