// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface VideoTranscript
 */
export interface VideoTranscript {
    /**
     * 
     * @type {number}
     * @memberof VideoTranscript
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof VideoTranscript
     */
    end: number;
    /**
     * 
     * @type {string}
     * @memberof VideoTranscript
     */
    text: string;
}

export function VideoTranscriptFromJSON(json: any): VideoTranscript {
    return {
        'start': json['start'],
        'end': json['end'],
        'text': json['text'],
    };
}

export function VideoTranscriptToJSON(value?: VideoTranscript): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'start': value.start,
        'end': value.end,
        'text': value.text,
    };
}


