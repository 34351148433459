import classNames from 'classnames';
import { Icon } from 'elements/components/Icons';
import * as React from 'react';
import ReactModal from 'react-modal';
import { MessageDescriptor } from 'react-intl';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { intl } from 'i18n';

export interface ModalProps extends ReactModal.Props {
    size: 'normal' | 'large' | 'max-9/16' | 'max';
    showTopBar: boolean;
    topBarStyle: 'normal' | 'white';
    showBackButton: boolean;
    backButtonText?: MessageDescriptor;
    closeButtonText: MessageDescriptor;
    title?: JSX.Element;
    content: JSX.Element;
    contentScrolls: boolean;
    contentPadding: 0 | 16 | 32;
    footer?: JSX.Element;
    footerShadow?: boolean;
    onRequestBack?(): void;
    onRequestClose(): void;
    modalExtraClassName?: string;
    ariaLabel?: MessageDescriptor;

}

export class Modal extends React.PureComponent<ModalProps> {

    public static defaultProps = {
        isOpen: true,
        size: 'normal',
        topBarStyle: 'normal',
        showTopBar: false,
        showBackButton: false,
        contentScrolls: false,
        contentPadding: 32,
        footerShadow: false,
        closeButtonText: messages.elementsModalCloseText,
    };

    public render() {
        const contentPaddingClasses = this.props.contentPadding > 0 ? `modal-content-padding-${this.props.contentPadding}` : '';
        const footerShadowClass = this.props.footerShadow ? 'modal-footer-shadow-enabled' : '';
        const backButtonClass = this.props.backButtonText ? 'with-text' : '';

        return (
            <ReactModal {...this.reactModalProps()}>
                {this.props.showTopBar && (
                    <div className={`modal-top-bar ${this.props.topBarStyle}`}>
                        {this.props.showBackButton && (
                            <button
                                aria-label={intl.formatMessage(messages.elementsModalBackText)}
                                onClick={this.props.onRequestBack}
                                className={`unstyled modal-top-bar-button ${backButtonClass}`}
                            >
                                <Icon name='chevron-left' zoom='x1.5'/>
                                { this.props.backButtonText &&
                                    <span><WrappedMessage message={this.props.backButtonText} /></span>
                                }
                            </button>
                        )}
                        <span className='modal-top-bar-title'>
                            {this.props.title}
                        </span>
                        <button onClick={this.props.onRequestClose} className='close-button'>
                            <span><WrappedMessage message={this.props.closeButtonText} /></span>
                            <span className='close-icon'><Icon name='x' zoom='14' /></span>
                        </button>
                    </div>
                )}
                <div className={
                    `lx-modal-body ${this.props.contentScrolls ? 'modal-content-scrolls' : ''} ${contentPaddingClasses}`
                }>
                        {this.props.content}
                </div>
                {this.props.footer &&
                    <div className={`lx-modal-footer ${footerShadowClass}`}>
                        {this.props.footer}
                    </div>
                }
            </ReactModal>
        );
    }

    private reactModalProps() {
        return {
            // We disable react-modal setting aria-hidden="true" to the main app div,
            // because if we don't do that, axe complains that hidden areas contain focusable elements
            // when a modal is open. It's not worth disabling all controls on the page when a model is
            // opened since react-modal already contains focus handling code that traps focus inside the modal.
            ariaHideApp: false,
            overlayClassName: 'modal-backdrop lx-modal-backdrop ' + this.props.modalExtraClassName ?? '',
            ...this.props,
            className: classNames(
                'lx-modal',
                this.props.className?.toString(),
                {
                    'lx-modal-normal-size': this.props.size === 'normal',
                    'lx-modal-large-size': this.props.size === 'large',
                    'lx-modal-height-auto': this.props.size === 'max-9/16',
                    'lx-modal-max-size': (this.props.size === 'max' || this.props.size === 'max-9/16'),
                },
            ),
            aria: {modal: true, label: this.props.ariaLabel ? intl.formatMessage(this.props.ariaLabel) : '' },
        };
    }
}
