/**
 * Spec for all the types are defined in the following docs:
 * 1. https://edx.readthedocs.io/projects/edx-open-learning-xml/en/latest/problem-xml/dropdown.html
 * 2. https://edx.readthedocs.io/projects/edx-open-learning-xml/en/latest/problem-xml/multiple_choice.html
 * 3. https://edx.readthedocs.io/projects/edx-open-learning-xml/en/latest/problem-xml/checkbox.html
 * 4. https://edx.readthedocs.io/projects/edx-open-learning-xml/en/latest/problem-xml/text_input.html
 */

import { getStore } from 'global/store';
import * as xmljs from 'xml-js';
import * as actionTypes from './store/actions/action-types';
import {
    getDefaultState,
    GlobalProblemBlockEditorState,
    GeneralFeedback,
    HintSetting,
    HintSettings,
    MultiSelectAnswer,
    ProblemBlockType,
    ProblemResponseType,
    ScoringSettings,
    ShortAnswer,
    ShortAnswerTypeText,
    SingleSelectAnswer,
    SingleSelectTypeRadio,
    SingleSelectTypeSelect,
} from './models';
import uiMessages from 'ui/components/displayMessages';
import { intl } from 'i18n';

export const setStateFromOlx = (olx: string) => {
    const problemState = parseStateFromOlx(olx);
    getStore().dispatch({
        type: actionTypes.PROBLEM_BLOCK_EDITOR_RESET_STATE,
        newState: problemState
    });
    return problemState.problemResponseType;
};

export const parseStateFromOlx = (olx: string): GlobalProblemBlockEditorState => {
    const olx_data = xmljs.xml2js(olx, {
        trim: true,
        nativeType: true,
        ignoreCdata: true,
    });

    const element: xmljs.Element | null = (olx_data.elements && olx_data.elements.length > 0) ? (
        olx_data.elements[0]
    ) : null;

    if (element === null) {
        throw new Error('Invalid OLX');
    }
    if (!['lx_question', 'problem'].includes(element.name ?? '')) {
        throw new Error('OLX is not of problem xblock type.');
    }
    const blockType: ProblemBlockType = element.name as ProblemBlockType;

    let problemState: GlobalProblemBlockEditorState;

    if (element.elements && element.elements.length > 0) {

        const responseElement: xmljs.Element = getResponseChildElement(element);
        if (responseElement.name === ProblemResponseType.ChoiceResponse) {
            problemState = parseChoiceResponseStateFromOlxData(responseElement);
        } else if (responseElement.name === ProblemResponseType.MultipleChoiceResponse) {
            problemState = parseMultipleChoiceResponseStateFromOlxData(responseElement);
        } else if (responseElement.name === ProblemResponseType.OptionResponse) {
            problemState = parseOptionResponseStateFromOlxData(responseElement);
        } else if (responseElement.name === ProblemResponseType.StringResponse) {
            problemState = parseStringResponseStateFromOlxData(responseElement);
        } else {
            throw new Error('Unknown responsetype.');
        }
    } else {
        throw new Error('No responsetypes found.');
    }

    problemState = {
        ...problemState,
        hintSettings: parseDemandHintStateFromOlxData(element),
        scorringSettings: parseScoringStateFromOlxData(element),
        blockType,
    };

    return problemState;
};

const parseChoiceResponseStateFromOlxData = (responseElement: xmljs.Element): GlobalProblemBlockEditorState => {

    let content = '';
    const labelElement = getChildElementWithName(responseElement, 'label', false);
    if (labelElement) {
        content = getElementInnerHtml(labelElement) || '';
    }

    const answersList: MultiSelectAnswer[] = [];
    const generalFeedbackList: GeneralFeedback[] = [];

    const checkboxGroupElement = getChildElementWithName(responseElement, 'checkboxgroup', true);
    if (checkboxGroupElement) {

        const choiceElements = getChildElementsWithName(checkboxGroupElement, 'choice');
        for (const choiceElement of choiceElements) {

            const choiceHintElements = getChildElementsWithName(choiceElement, 'choicehint');
            const selectedFeedbackElement = choiceHintElements.find((choiceHintElement: xmljs.Element) => {
                return (valueIsTrue(getElementAttribute(choiceHintElement, 'selected',)) === true);
            });
            const unselectedFeedbackElement = choiceHintElements.find((choiceHintElement: xmljs.Element) => {
                return (valueIsTrue(getElementAttribute(choiceHintElement, 'selected',)) === false);
            });

            answersList.push({
                id: String.fromCharCode(65 + answersList.length),
                title: getElementText(choiceElement) || '',
                correct: valueIsTrue(getElementAttribute(choiceElement, 'correct', false)),
                selectedFeedback: selectedFeedbackElement ? getElementText(selectedFeedbackElement) : undefined,
                unselectedFeedback: unselectedFeedbackElement ? getElementText(unselectedFeedbackElement) : undefined,
            })
        }

        const compoundHintElements = getChildElementsWithName(checkboxGroupElement, 'compoundhint');
        for (const compoundHintElement of compoundHintElements) {
            const hintForChoices: string = getElementAttribute(compoundHintElement, 'correct');
            if (hintForChoices !== undefined) {
                generalFeedbackList.push({
                    id: generalFeedbackList.length,
                    feedback: getElementText(compoundHintElement) || '',
                    // Here we strip out any invalid IDs; IDs must be single uppercase letters.
                    // This is to sanitize the choices after bugs caused invalid IDs to be saved.
                    correct: hintForChoices == "true" ? true : false,
                });
            }
        }
    }

    return {
        ...getDefaultState(),
        problemResponseType: ProblemResponseType.ChoiceResponse,
        multiSelectAnswers: {
            generalFeedbackList: generalFeedbackList,
            multiSelectAnswersList: answersList,
        },
        multiSelectEditor: {
            content: content,
        },
    }
};

const parseMultipleChoiceResponseStateFromOlxData = (responseElement: xmljs.Element): GlobalProblemBlockEditorState => {

    let content = '';
    const labelElement = getChildElementWithName(responseElement, 'label', false);
    if (labelElement) {
        content = getElementInnerHtml(labelElement) || '';
    }

    const answersList: SingleSelectAnswer[] = [];

    const choiceGroupElement = getChildElementWithName(responseElement, 'choicegroup', true);
    if (choiceGroupElement) {

        const choiceElements = getChildElementsWithName(choiceGroupElement, 'choice');
        for (const choiceElement of choiceElements) {

            const choiceHintElement = getChildElementWithName(choiceElement, 'choicehint', false);
            answersList.push({
                id: answersList.length,
                title: getElementText(choiceElement) || '',
                correct: valueIsTrue(getElementAttribute(choiceElement, 'correct', false)),
                feedback: choiceHintElement ? getElementText(choiceHintElement) : undefined,
            })
        }
    }

    return {
        ...getDefaultState(),
        problemResponseType: ProblemResponseType.MultipleChoiceResponse,
        singleSelectAnswers: {
            selectedType: SingleSelectTypeRadio,
            singleSelectAnswersList: answersList,
        },
        singleSelectEditor: {
            content: content,
        },
    }
};

const parseOptionResponseStateFromOlxData = (responseElement: xmljs.Element): GlobalProblemBlockEditorState => {

    let content = '';
    const labelElement = getChildElementWithName(responseElement, 'label', false);
    if (labelElement) {
        content = getElementInnerHtml(labelElement) || '';
    }

    const answersList: SingleSelectAnswer[] = [];

    const optionInputElement = getChildElementWithName(responseElement, 'optioninput', true);
    if (optionInputElement) {

        const optionElements = getChildElementsWithName(optionInputElement, 'option');
        for (const optionElement of optionElements) {

            const optionHintElement = getChildElementWithName(optionElement, 'optionhint', false);
            answersList.push({
                id: answersList.length,
                title: getElementText(optionElement) || '',
                correct: valueIsTrue(getElementAttribute(optionElement, 'correct', false)),
                feedback: optionHintElement ? getElementText(optionHintElement) : undefined,
            })
        }
    }

    return {
        ...getDefaultState(),
        problemResponseType: ProblemResponseType.OptionResponse,
        singleSelectAnswers: {
            selectedType: SingleSelectTypeSelect,
            singleSelectAnswersList: answersList,
        },
        singleSelectEditor: {
            content: content,
        },
    }
};

const parseStringResponseStateFromOlxData = (responseElement: xmljs.Element): GlobalProblemBlockEditorState => {

    let content = '';
    const labelElement = getChildElementWithName(responseElement, 'label', false);
    if (labelElement) {
        content = getElementInnerHtml(labelElement) || '';
    }

    const answersList: ShortAnswer[] = [];

    const answer = getElementAttribute(responseElement, 'answer');
    if (answer) {
        const correctHintElement = getChildElementWithName(responseElement, 'correcthint', false);
        answersList.push({
            id: answersList.length,
            value: answer,
            currentType: ShortAnswerTypeText,
            correct: true,
            feedback: correctHintElement ? getElementText(correctHintElement) : undefined,
        });
    }

    const additionalAnswerElements = getChildElementsWithName(responseElement, 'additional_answer');
    for (const additionalAnswerElement of additionalAnswerElements) {
        const answer = getElementAttribute(additionalAnswerElement, 'answer');
        if (answer) {
            const feedbackElem = getChildElementsWithName(additionalAnswerElement, 'correcthint');
            let feedback = feedbackElem.length > 0 ? getElementText(feedbackElem[0]) : undefined;
            // This is only used as a fallback to extract hints that were previously stored incorrectly.
            // There are likely several existing assets with this bug, so with this fallback,
            // we can be nice and autofix the issues in a single edit -> save flow.
            feedback = feedback || getElementText(additionalAnswerElement) || undefined;
            answersList.push({
                id: answersList.length,
                value: answer,
                currentType: ShortAnswerTypeText,
                correct: true,
                feedback: feedback,
            });
        }
    }

    const stringEqualHintElements = getChildElementsWithName(responseElement, 'stringequalhint');
    for (const stringEqualHintElement of stringEqualHintElements) {
        const answer = getElementAttribute(stringEqualHintElement, 'answer');
        if (answer) {
            answersList.push({
                id: answersList.length,
                value: answer,
                currentType: ShortAnswerTypeText,
                correct: false,
                feedback: getElementText(stringEqualHintElement) || undefined
            });
        }
    }

    return {
        ...getDefaultState(),
        problemResponseType: ProblemResponseType.StringResponse,
        shortAnswers: {
            shortAnswersList: answersList,
        },
        shortAnswerEditor: {
            content: content,
        },
    }
};

const parseDemandHintStateFromOlxData = (problemElement: xmljs.Element): HintSettings => {
    const hints: HintSetting[] = [];
    const demandHintElement = getChildElementWithName(problemElement, 'demandhint', false);
    if (demandHintElement) {
        const hintElements = getChildElementsWithName(demandHintElement, 'hint');
        for (const hintElement of hintElements) {
            const hintText = getElementText(hintElement);
            if (hintText !== undefined) {
                hints.push({
                    id: hints.length,
                    value: hintText,
                });
            }
        }
    }
    return {
        hints: hints,
    };
};

const parseScoringStateFromOlxData = (problemElement: xmljs.Element): ScoringSettings => {

    const selectedAttemptsOption = parseInt(getElementAttribute(problemElement, 'max_attempts'));
    const selectedPointOption = parseInt(getElementAttribute(problemElement, 'weight'));

    let unknownAttempt, unknownPoint, initAttempt, initPoint;

    initAttempt = { id: 0, value: '', label: intl.formatMessage(uiMessages.uiUnlimited) };
    initPoint = { id: 0, value: '', label: intl.formatMessage(uiMessages.uiUnlimited) };

    const maxAttempts = 11;
    const attemptsOptions = [...Array(maxAttempts).keys()].map(ind => {
        // set selected value of attempts

        if (!selectedAttemptsOption && !ind) {
            initAttempt = { id: ind, value: '', label: intl.formatMessage(uiMessages.uiUnlimited) };
        } else if (+selectedAttemptsOption === ind) {
            initAttempt = { id: ind, value: ind.toString(), label: ind.toString() };
        } else if (selectedAttemptsOption) {
            initAttempt = {
                id: +selectedAttemptsOption,
                value: +selectedAttemptsOption,
                label: selectedAttemptsOption.toString()
            };
            if (+selectedAttemptsOption > maxAttempts) {
                unknownAttempt = initAttempt;
            }
        }

        if (!ind) {
            return { id: ind, value: '', label: intl.formatMessage(uiMessages.uiUnlimited) };
        }
        return { id: ind, value: ind.toString(), label: ind.toString() };
    });

    const maxPoints = 11;
    const pointsOptions = [...Array(maxPoints).keys()].map(ind => {
        // set selected value of points
        if (!selectedPointOption && !ind) {
            initPoint = { id: ind, value: '', label: intl.formatMessage(uiMessages.uiUnlimited) };
        } else if (+selectedPointOption === ind) {
            initPoint = { id: ind, value: ind.toString(), label: ind.toString() };
        } else if (selectedPointOption) {
            initPoint = {
                id: +selectedPointOption,
                value: +selectedPointOption,
                label: selectedPointOption
            };
            if (+selectedPointOption > maxPoints) {
                unknownPoint = initPoint;
            }
        }
        if (!ind) {
            return { id: ind, value: '', label: intl.formatMessage(uiMessages.uiUnlimited) };
        }
        return { id: ind, value: ind.toString(), label: ind.toString() };
    });

    if (unknownAttempt) {
        attemptsOptions.push(unknownAttempt);
    }

    if (unknownPoint) {
        pointsOptions.push(unknownPoint);
    }

    return {
        attemptsOptions: attemptsOptions,
        pointsOptions: pointsOptions,
        selectedAttemptsOption: initAttempt,
        selectedPointOption: initPoint
    };
};

const getElementAttribute = (
    element: xmljs.Element, attribute: string, defaultValue?: any): (any | undefined) => {
    if (element.attributes && attribute in element.attributes) {
        return element.attributes[attribute];
    }
    return defaultValue;
};

const getElementText = (element: xmljs.Element, defaultValue?: string): (string | undefined) => {
    if (element.elements && element.elements.length > 0) {
        for (const childElement of element.elements) {
            if (childElement.type === 'text' && childElement.text !== undefined) {
                return childElement.text.toString();
            }
        }
    }
    return defaultValue;
};

const getElementInnerHtml = (element: xmljs.Element, defaultValue?: string): (string | undefined) => {
    let value = "";
    if (element.elements && element.elements.length > 0) {
        for (const childElement of element.elements) {
            if (childElement.type === 'text' && childElement.text !== undefined) {
                value += childElement.text.toString();
            } else if (childElement.type === 'element') {
                // Recurse through the child elements, preserving their tags and text.
                const childValue = getElementInnerHtml(childElement, defaultValue);
                if (childValue !== undefined) {
                    value += " <" + childElement.name + ">" + childValue + "</" + childElement.name + "> ";
                }
            }
        }
    }
    return value || defaultValue;
};

const getChildElementWithName = (element: xmljs.Element, name: string, mustExist: boolean): (xmljs.Element | undefined) => {
    const childElements = getChildElementsWithName(element, name);
    if (childElements.length === 0 && mustExist === false) {
        return undefined;
    } else if (childElements.length === 1) {
        return childElements[0];
    }
    throw new Error(`Unexpected number of ${name} elements.`)
};

const getChildElementsWithName = (element: xmljs.Element, name: string, count?: number): xmljs.Element[] => {
    const childElementsWithName: xmljs.Element[] = [];
    if (element.elements && element.elements.length > 0) {
        for (const childElement of element.elements) {
            if (childElement.name === name) {
                childElementsWithName.push(childElement);
            }
        }
    }

    if (count && childElementsWithName.length !== count) {
        throw new Error(`Unexpected number of ${name} elements.`)
    }
    return childElementsWithName;
};

const getResponseChildElement = (element: xmljs.Element): xmljs.Element => {
    const childElementsWithName: xmljs.Element[] = [];
    if (element.elements && element.elements.length > 0) {
        for (const childElement of element.elements) {
            if (childElement.name && (
                childElement.name === ProblemResponseType.ChoiceResponse ||
                childElement.name === ProblemResponseType.MultipleChoiceResponse ||
                childElement.name === ProblemResponseType.OptionResponse ||
                childElement.name === ProblemResponseType.StringResponse
            )
            ) {
                childElementsWithName.push(childElement);
            }
        }
    }

    if (childElementsWithName.length === 1) {
        return childElementsWithName[0];
    }

    throw new Error(`Unexpected number of response elements.`)
};

const valueIsTrue = (value: string): boolean => {
    return (value === 'True' || value === 'true');
};
