// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface DiscussionsMessageCreate
 */
export interface DiscussionsMessageCreate {
    /**
     * 
     * @type {string}
     * @memberof DiscussionsMessageCreate
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionsMessageCreate
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionsMessageCreate
     */
    conversation?: string;
}

export function DiscussionsMessageCreateFromJSON(json: any): DiscussionsMessageCreate {
    return {
        'content': json['content'],
        'recipient': json['recipient'],
        'conversation': !exists(json, 'conversation') ? undefined : json['conversation'],
    };
}

export function DiscussionsMessageCreateToJSON(value?: DiscussionsMessageCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content': value.content,
        'recipient': value.recipient,
        'conversation': value.conversation,
    };
}


