/* istanbul ignore file */
import {
    getLoggedInStatus,
    getUserEmail,
    getUsername,
    getUserPermissions,
    getCurriculumGroup,
} from 'auth/selectors';
import { bind } from 'bind-decorator';
import { ItemsApi, OrganizationsApi, XBlocksApi, AssetFeedbacksApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { ItemType } from 'items/models';
import update from 'immutability-helper';
import { intl } from 'i18n';
import {
    APIPermissions,
    Author,
    ItemMetadata,
    ItemResponse,
    ItemUserAttributes,
    AssetFeedbackResponse,
} from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
    Icon,
    StandardPageLayout,
    FeedbackModal,
    FeedbackButton
} from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/actions';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { WrappedMessage } from 'utils';
import {
    getItemAndBlockData,
    getTranscriptFilesFromVideoState,
    getFeedbackDetails
} from '../../utils';
import { getPageMainClass } from 'library/utils';
import { BlockData, getEmptyBlockData, LoadingState } from '../Block/models';
import { LibraryBreadcrumb, ResourceBreadcrumbButton } from '../Breadcrumb';
import { RecommendedContentWidget } from '../RecommendedContentWidget';
import { ItemPageBody } from './ItemPageBody';
import { InvalidContent } from '../InvalidContent';
import { LocationDescriptor } from 'history';

interface ReduxStateProps {
    userPermissions?: APIPermissions;
    isLoggedin?: boolean;
    loggedInUsername?: string;
    loggedInEmail?: string;
    curriculumGroup?: string;
}

interface MatchProps {
    itemKey: string;
}

interface ItemPageProps extends RouteComponentProps<MatchProps> {
}

interface ItemPageState {
    blockData: BlockData;
    contentMetadata?: ItemMetadata;
    contentUserAttributes?: ItemUserAttributes;
    // Does the XBlock request to be rendered with full width?
    expandBlock: boolean;
    loading: boolean;
    unauthorized: boolean;
    similarItems?: ItemResponse[];
    moreItemsBySource?: ItemResponse[];
    errStatus?: number;
    redirectTo?: LocationDescriptor;
    showFeedbackModal: boolean;
    feedbackData?: AssetFeedbackResponse;
    feedbackExists?: boolean;
    fetchingFeedback?: boolean;
}

/**
 * The ItemPage is the main view of a particular piece of content,
 * when viewed directly in the library and not as part of a Pathway.
 *
 * When viewed as part of a pathway, the PathwayItemPage should be used instead.
 */
export class ItemPageInternal extends React.PureComponent<ItemPageProps & ReduxStateProps, ItemPageState> {

    constructor(props: ItemPageProps) {
        super(props);
        this.state = this.defaultState();
    }

    public async componentDidMount() {
        this.loadItemMetadata(this.props.match.params.itemKey);
        this.fetchFeedback();
    }

    private async fetchFeedback() {
        const { curriculumSlug, canUserGiveFeedback } = getFeedbackDetails(this.props.curriculumGroup);
        if (curriculumSlug && this.props.loggedInEmail && canUserGiveFeedback) {
            this.setState({ fetchingFeedback: true });
            try {
                const response = await AssetFeedbacksApi.feedbacksGetFeedback({
                    assetId: this.props.match.params.itemKey,
                    curriculumSlug,
                    userEmail: this.props.loggedInEmail,
                });
                this.setState({
                    feedbackData: response,
                    feedbackExists: true,
                    fetchingFeedback: false
                });
            } catch (error) {
                this.setState({ feedbackExists: false, fetchingFeedback: false });
            }
        }
    }

    public componentDidUpdate(prevProps: ItemPageProps & ReduxStateProps) {
        const itemKey = this.props.match.params.itemKey;
        if (itemKey !== prevProps.match.params.itemKey ||
            this.props.isLoggedin !== prevProps.isLoggedin ||
            this.props.curriculumGroup !== prevProps.curriculumGroup
        ) {
            this.setState(this.defaultState());
            this.loadItemMetadata(itemKey);
            this.fetchFeedback();
        }
    }

    public renderHeader(title: string, itemType?: ItemType) {

        // When expandBlock is true, there is no source box so there is space to expand the title.
        const h1Classes = this.state.expandBlock ? 'col-12' : 'col-12 col-md-8 col-lg-9';
        const { location } = this.props;
        const queryParams = new URLSearchParams(location.search);
        const parentAsset = queryParams.get('assetId');
        const parentAssetType = queryParams.get('assetType');

        return (<div className='item-page-header-content container py-4 pb-md-2'>
            {(parentAsset && parentAssetType) ?
                <ResourceBreadcrumbButton
                    // @ts-ignore
                    itemType={parentAssetType}
                    itemId={parentAsset}
                /> :
                <LibraryBreadcrumb itemType={itemType} itemTitle={title} />
            }
            <div className='asset-name-design'>
                {parentAsset && <Icon name='paper-clip' />}
                <h1 className={h1Classes} dir='auto'>{title}</h1>
            </div>

            {this.state.contentUserAttributes && this.state.contentUserAttributes.canEditObject ?
                <NavLink to={ROUTES.Library.ITEM_EDIT_SLUG(this.props.match.params.itemKey)}
                            className='item-page-edit-button text-header'>
                    <Icon name='pencil'/>
                    <WrappedMessage message={messages.itemEdit} />
                </NavLink> : null
            }
        </div>);
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }

        const recommendedItemsFooter = (
            <RecommendedContentWidget
                similarItems={this.state.similarItems!}
                moreItemsBySource={this.state.moreItemsBySource!}
            />
        );

        if ((this.state.contentMetadata === undefined) ||
            (this.state.contentUserAttributes === undefined)) {
            if (this.state.loading) {
                const loadingText = intl.formatMessage(uiMessages.uiLoading);
                return (
                    <StandardPageLayout headerFeature={this.renderHeader(loadingText)}>
                        <p>{loadingText}</p>
                    </StandardPageLayout>
                );
            } else if (this.state.unauthorized) {
                /// If is not logged, then show the registration gate page
                return <RegistrationGate
                  title={intl.formatMessage(messages.uiPrivateAssetAlertTitle)}
                  body={intl.formatMessage(messages.uiPrivateAssetAlertBody)}
                  primaryButtonText={uiMessages.uiSignUp}
                  secondaryButtonText={uiMessages.uiSignIn}
                  image='/assets/images/access.svg'
                  footerFeature={recommendedItemsFooter}
                />;
            } else {
                // We weren't able to load the metadata about the content
                // Show content library specific 404 component
                return <InvalidContent statusCode={this.state.errStatus} />;
            }
        }

        const item = this.state.contentMetadata;
        const userAttributes = this.state.contentUserAttributes;

        // very specific case when we have to pass classroom information to the simulation asset iframe
        let classroomName: string | undefined;
        if (userAttributes.canSendPii) {
            const params = new URLSearchParams(this.props.location.search);
            if (params.has('classroomName')) {
                classroomName = params.get('classroomName')!;
            }
        }
        const { location } = this.props;
        const queryParams = new URLSearchParams(location.search);
        const parentAsset = queryParams.get('assetId');
        const headerBackground = parentAsset ? '/assets/images/resource-page-header.png' : undefined;
        const { curriculumSlug, canUserGiveFeedback } = getFeedbackDetails(this.props.curriculumGroup);
        const isSimFullScreen = queryParams.get('fullscreen') === 'true';

        return (
            <StandardPageLayout
                containerPaddingEnabled={this.state.expandBlock === false}
                headerContainerPaddingEnabled={this.state.expandBlock === false}
                mainClassName={getPageMainClass(this.state.expandBlock, this.state.contentMetadata?.type)}
                pageTitle={messages.itemPageTitle}
                pageTitleValues={{name: item.title}}
                headerFeature={this.renderHeader(item.title, item.type)}
                footerFeature={recommendedItemsFooter}
                headerBackgroundUrl={headerBackground}
            >
                {this.state.contentMetadata?.id && !this.state.fetchingFeedback &&
                    canUserGiveFeedback && curriculumSlug && !isSimFullScreen &&
                    <FeedbackButton
                        variant={this.state.feedbackExists ? 'submitted' : 'pending'}
                        onClick={() => this.setState({showFeedbackModal: true})}
                    />
                }
                {this.state.showFeedbackModal && this.state.contentMetadata &&
                    curriculumSlug &&
                    <FeedbackModal
                        assetId={this.state.contentMetadata.id}
                        curriculumSlug={curriculumSlug}
                        onClose={() => this.setState({showFeedbackModal: false})}
                        onSubmit={() => this.setState(
                            {showFeedbackModal: false, feedbackExists: true})
                        }
                    />
                }
                <ItemPageBody
                    contentMetadata={item}
                    contentUserAttributes={userAttributes}
                    blockData={this.state.blockData}
                    expandBlock={this.state.expandBlock}
                    onExpandBlock={this.onExpandBlock}
                    isUserLoggedIn={this.props.isLoggedin}
                    loggedInUsername={this.props.loggedInUsername}
                    screenReaderHeading={item.title}
                    userPermissions={this.props.userPermissions}
                    markAsIncomplete={this.markAsIncomplete}
                    classroomName={classroomName}
                    onCloneAnnotatedVideo={this.onCloneAnnotatedVideo}
                />
            </StandardPageLayout>
        );
    }

    private defaultState() {
        return {
            blockData: getEmptyBlockData(),
            contentMetadata: undefined,
            expandBlock: false,
            loading: true,
            unauthorized: false,
            similarItems: [],
            moreItemsByAuthor: [],
            errStatus: 404,
            showFeedbackModal: false,
            feedbackData: undefined,
            feedbackExists: false,
            fetchingFeedback: false,
        };
    }

    private async loadItemMetadata(itemKey: string) {
        const response = await getItemAndBlockData(this.props.match.params.itemKey);
        if (response.blockData.loadingState === LoadingState.READY) {
            this.setState({
                blockData: response.blockData,
                contentMetadata: response.itemData.metadata,
                contentUserAttributes: response.itemData.userAttributes,
                loading: false,
            });
        } else {
            this.setState({
                contentMetadata: undefined,
                loading: false,
                errStatus: response.statusCode,
                unauthorized: response.statusCode === 401,
            });
        }

        // Loading the XBlock requires a number of requests. Prioritize those over recommended items
        // which show at the bottom of the page.
        setTimeout(this.loadRecommendedItems, 2000);
    }

    @bind private loadRecommendedItems() {
        const itemKey = this.props.match.params.itemKey;
        ItemsApi.recommendedItems({
            id: itemKey,
            similar: true,
            moreBySource: true,
        }).then((recommendedItems) => {
            if (this.props.match.params.itemKey === itemKey) { // If itemKey hasn't changed while loading:
                this.setState({
                    similarItems: recommendedItems.similarItems ? recommendedItems.similarItems : [],
                    moreItemsBySource: recommendedItems.moreBySource ? recommendedItems.moreBySource : [],
                });
            }
        });
    }

    /**
     * The XBlock on this page has requested to take up the full page width
     * (or not).
     * @param expand Whether or not to render the XBlock in full width
     */
    @bind private onExpandBlock(expand: boolean) {
        this.setState(
            { expandBlock: expand },
        );
    }

    @bind private markAsIncomplete() {
        if (this.state.contentMetadata) {
            ItemsApi.markIncomplete({ id: this.props.match.params.itemKey})
                .then(() => {
                    this.setState({
                        contentUserAttributes: update(this.state.contentUserAttributes, {completion: {$set: 0}})
                    });
                })
                .catch(error => showErrorMessage('Error while changing completion state.', {exception: error}));
        }
    }

    @bind private async onCloneAnnotatedVideo() {
        if (this.state.contentMetadata !== undefined) {
            const videoBlockData = await XBlocksApi.annotatedVideoDataAndState({id: this.state.contentMetadata.id});
            const annotations = videoBlockData.annotations;
            const authorDetails = this.getAnnotatedVideoAuthorDetails();

            /// Get the video data and state to get the transcript data
            const videoDataState = await XBlocksApi.videoDataAndState({id: videoBlockData.video.id});
            const newTranscriptState = await getTranscriptFilesFromVideoState(videoBlockData.video.id, videoDataState);

            /// Build the annotations
            annotations.forEach((annotation, index) => {
                if (annotation.authorName === '') {
                    annotation.authorName = authorDetails.name;
                    annotation.authorUrl = authorDetails.url;
                    annotation.authorOrgId = authorDetails.orgId;
                }
                annotation.id = 'new-' + (index + 1).toString();
                annotations[index] = annotation;
            });

            /// Build the authors
            let authors: Author[] = this.state.contentMetadata.authors.map(author => {
                    return Object.assign({}, author, {originalAuthor: true});
            });
            if (this.state.contentMetadata.ownerIndividual) {
                const author = authors.find(author1 => author1.username === this.state.contentMetadata?.ownerIndividual);
                // Only add ownerIndividual if not in author list already
                if (author === undefined) {
                    authors.push({username: this.state.contentMetadata.ownerIndividual, fullName: '', originalAuthor: true});
                }
            }
            // When cloning from an org, the current user will be added later
            if (!this.state.contentMetadata.source && this.props.loggedInUsername) {
                const author = authors.find(author1 => author1.username === this.props.loggedInUsername);
                /// We need to add the logged user as first author to make him the new owner
                if (author === undefined) {
                    authors.unshift({username: this.props.loggedInUsername, fullName: ''});
                }
                else {
                    authors = authors.filter(author1 => author1.username !== author.username);
                    authors.unshift({username: this.props.loggedInUsername, fullName: ''});
                }
            }

            /// Build params
            const params = {
                title: this.state.contentMetadata.title + ' (Copy)',
                description: this.state.contentMetadata.description,
                language: this.state.contentMetadata.language,
                freeFormTags: this.state.contentMetadata.freeFormTags,
                authors,
                license: this.state.contentMetadata.license,
                imageUrl: 'https://i.ytimg.com/vi/' + videoBlockData.videoYoutubeId + '/sddefault.jpg',
                backgroundKnowledge: this.state.contentMetadata.backgroundKnowledge,
                subjectTags: this.state.contentMetadata.subjectTags,
                blockEditorDefaults: {
                    youTubeId: videoBlockData.videoYoutubeId,
                    transcripts: newTranscriptState,
                    annotations,
                    addedAnnotations: annotations,
                    deletedAnnotations: [],
                    updatedAnnotations: [],
                }
            } as any;
            if (videoBlockData.videoYoutubeId === undefined || videoBlockData.videoYoutubeId === '') {
                /// Build params for a Vimeo video
                const videoBlockDataState = await XBlocksApi.videoDataAndState({id: videoBlockData.video.id});
                params.blockEditorDefaults.html5Sources = [
                    videoBlockDataState.encodedVideos.fallback.url,
                ];
                params.imageUrl = '';
            }
            if (this.state.contentMetadata.source) {
                /// Build source params data
                const orgSlug = (
                    this.state.contentMetadata.source.sourceOrganizations &&
                    this.state.contentMetadata.source.sourceOrganizations[0].organization.slug
                );
                const orgData = await OrganizationsApi.read({id: orgSlug});
                const canCreateOrgContent = orgData.permissions.canEditContentOfOrganizationObject;
                if (canCreateOrgContent) {
                    params.orgSlug = orgSlug;
                    params.funders = this.state.contentMetadata.funders;
                }
            }
            this.setState({
                redirectTo: {
                    pathname: ROUTES.Library.ITEM_NEW_SLUG(ItemType.AnnotatedVideo, ''),
                    state: params,
                }
            });
        }
    }

    private getAnnotatedVideoAuthorDetails() {
        const item = this.state.contentMetadata;
        if (item === undefined) {
            return {
                name: '',
                url: '',
                orgId: '',
            };
        }
        let name: string | undefined;
        let url: string | undefined;
        let orgId: string | undefined;
        if (item.source && item.source.sourceOrganizations) {
            const sourceOrg = item.source.sourceOrganizations[0].organization;
            name = sourceOrg.name;
            url = sourceOrg.url;
            orgId = sourceOrg.id;
        } else if (item.authors.length > 0) {
            const author = item.authors[0];
            if (author.username) {
                name = author.fullName;
                url = ROUTES.Users.PROFILE_SLUG(author.username);
            }
        }
        return {name, url, orgId};
    }
}

export const ItemPage = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        isLoggedin: getLoggedInStatus(state),
        userPermissions: getUserPermissions(state),
        loggedInUsername: getUsername(state),
        loggedInEmail: getUserEmail(state),
        curriculumGroup: getCurriculumGroup(state),
    }),
)(ItemPageInternal);
