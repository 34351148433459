import { Icon, IconSymbol } from 'elements';
import { bind } from 'bind-decorator';
import { ItemMetadata } from 'labxchange-client';
import { MessageDescriptor } from 'react-intl';
import * as React from 'react';
import { WrappedMessage } from 'utils';
import AssignToClassroomModal from './AssignToClassroomModal';
import AssignToMentorshipModal from './AssignToMentorshipModal';
import AssignToPathwayModal from './AssignToPathwayModal';
import CreateAnnotatedVideoModal from './createAnnotatedVideoModal';
import { UI_IS_SM } from 'ui/breakpoints';
import messages from './displayMessages';

interface ItemAssignButtonProps {
    large?: boolean;
    icon: IconSymbol;
    label: MessageDescriptor;
    onClick(): void;
}

class ItemAssignButton extends React.PureComponent<ItemAssignButtonProps> {
    public render() {
        const isLarge = this.props.large;
        return (
            <button className={
                `unstyled item-assign-button ` +
                `${isLarge ? 'item-assign-button-large' : ''}`}
                onClick={this.props.onClick}>
                <Icon name={this.props.icon}/>
                <WrappedMessage message={this.props.label} />
            </button>
        );
    }
}

export enum ModalType {
    None = 0,
    Classroom,
    Mentorship,
    Pathway,
    AnnotatedVideo,
}

interface Props {
    itemMetadata: ItemMetadata;
    loggedInUsername: string;
    toPathway?: boolean;
    toClassroom?: boolean;
    toMentorship?: boolean;
    createAnnotatedVideo?: boolean;
}

interface State {
    modalType: ModalType;
    isMobileView: boolean;
}

export class ItemAssign extends React.PureComponent<Props, State> {

    private mediaQueryMobile = UI_IS_SM;

    constructor(props: Props) {
        super(props);
        this.state = {
            modalType: ModalType.None,
            isMobileView: false,
        };
    }

    public componentDidMount() {
        this.mediaQueryMobile.addListener(this.setIsMobileView);
    }

    public componentWillUnmount() {
        this.mediaQueryMobile.removeListener(this.setIsMobileView);
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQueryMobile.matches});
    }

    public render() {
        const toClassroom = this.props.toClassroom;
        const toMentorship = this.props.toMentorship;
        const toPathway = this.props.toPathway;
        const createAnnotatedVideo = this.props.createAnnotatedVideo;

        const numberOfChoice = [toClassroom, toMentorship, toPathway].filter(Boolean).length;
        if (numberOfChoice < 1 && !createAnnotatedVideo) { return null; }

        const isLarge = numberOfChoice === 1;

        return <>
            <div className='item-assign'>
                {!isLarge && numberOfChoice >= 1
                ? <div className='item-assign-label'><WrappedMessage message={messages.itemAssignLabel}/></div>
                : null}
                <div className='item-assign-actions'>
                    { toClassroom ?
                        <ItemAssignButton
                            icon='classroom'
                            onClick={() => this.setState({modalType: ModalType.Classroom})}
                            large={isLarge}
                            label={isLarge
                                ? messages.itemAssignToClassroomLargeLabel
                                : messages.itemAssignToClassroomLabel}
                        />
                    : null}
                    { toMentorship ?
                        <ItemAssignButton
                            icon='mentorship'
                            onClick={() => this.setState({modalType: ModalType.Mentorship})}
                            large={isLarge}
                            label={isLarge
                                ? messages.itemAssignToMentorshipLargeLabel
                                : messages.itemAssignToMentorshipLabel}
                        />
                    : null}
                    { toPathway ?
                        <ItemAssignButton
                            icon='pathway'
                            onClick={() => this.setState({modalType: ModalType.Pathway})}
                            large={isLarge}
                            label={isLarge
                                ? messages.itemAssignToPathwayLargeLabel
                                : messages.itemAssignToPathwayLabel}
                        />
                    : null}
                </div>
                {this.renderModals()}
            </div>
            <div className='item-assign'><div className='item-assign-actions create-annotated-video'>
            { createAnnotatedVideo && !this.state.isMobileView ?
                        <ItemAssignButton
                            icon='message3'
                            onClick={() => this.setState({modalType: ModalType.AnnotatedVideo})}
                            large={isLarge}
                            label={messages.itemConvertAnnotatedVideoLabel}
                        />
                    : null}
            </div></div>
            </>;
    }

    private renderModals() {
        switch (this.state.modalType) {
            case ModalType.Classroom:
                return (
                    <AssignToClassroomModal
                        itemMetadata={this.props.itemMetadata}
                        onClose={ () => this.setState({modalType: ModalType.None})}/>
                );
            case ModalType.Mentorship:
                return (
                    <AssignToMentorshipModal
                        loggedInUsername={this.props.loggedInUsername}
                        itemMetadata={this.props.itemMetadata}
                        onClose={ () => this.setState({modalType: ModalType.None})}/>
                );
            case ModalType.Pathway:
                return (
                    <AssignToPathwayModal
                        itemMetadata={this.props.itemMetadata}
                        onClose={ () => this.setState({modalType: ModalType.None})}/>
                );
            case ModalType.AnnotatedVideo:
                return (
                    <CreateAnnotatedVideoModal
                        itemMetadata={this.props.itemMetadata}
                        onClose={ () => this.setState({modalType: ModalType.None})}/>
                );
            default: return null;
        }
    }
}
