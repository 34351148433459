import React from 'react';
import { Icon } from '../../../elements';
import { useColor } from '../../ThemeProvider';
import { ROUTES } from '../../../global/constants';
import { NavLink } from 'react-router-dom';
import Stepper from './Stepper/Stepper';

interface BannerProps {
    title: string;
    description?: string;
    icon?: string;
    showStepper?: boolean;
    currentStep?: number;
    totalSteps?: number;
}

const Banner: React.FC<BannerProps> = ({
  title,
  description,
  icon,
  showStepper,
  currentStep,
  totalSteps=3,
}) => {
    const { selectedColor } = useColor();
    const defaultCategoryIcon = '/assets/images/career-explorer/career-card-placeholder.svg';
    return(
        <div className={`category-banner ${selectedColor.class}`}>
            <div className='banner-content'>
                <div>
                    {(showStepper && currentStep)?
                        <Stepper
                            totalSteps={totalSteps}
                            currentStep={currentStep}
                        />
                        :
                        <NavLink to={ROUTES.CareerExplorer.HOME}>
                            <Icon name='arrow-back' fill='#002F50' />
                        </NavLink>
                    }
                    <div className='banner-content__title'>{title}</div>
                    <div className='banner-content_description'>{description}</div>
                </div>
                <div className='banner-content__icon-section'>
                    <div className={`career-card-icon`} style={{background: selectedColor.iconColorFill}}></div>
                    <img
                        className='career-icon'
                        alt='Career Icon'
                        src={icon || defaultCategoryIcon}
                    />
                </div>
            </div>
        </div>
);};

export default Banner;
