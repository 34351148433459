import bind from 'bind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';

import { getLoggedInStatus } from 'auth/selectors';
import { SearchApi } from 'global/api';
import { DISCUSSIONS_SORT_OPTIONS, ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import {
    DiscussionsAppliedFilter,
    DiscussionsSearchRequestData,
    DiscussionsSearchRequestDataOrderingEnum,
    DiscussionsSearchResults,
    Thread,
} from 'labxchange-client';
import libraryMessages from 'library/displayMessages';

import { TaxonomyData } from 'search/components/Taxonomy';
import {
    eqSet,
    getAppliedSearchFilters,
    getCurrentPageFromString,
    getPaginationSizeFromString,
    getSortOrderingFromString,
    sanitizeSearchParams,
} from 'search/utils';
import { showErrorMessage, showLoginRequiredMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { ResponsiveFilteredSearch } from 'library/components/ResponsiveFilteredSearch/ResponsiveFilteredSearch';
import {buildFilterOptionsFromSearchData, DiscussionsSearchFilter} from './discussion-search';
import { ThreadCard, ThreadCardProps } from '../ThreadCard';

interface DiscussionSearchReduxProps {
    isLoggedIn: boolean;
}

interface DiscussionSearchProps extends DiscussionSearchReduxProps {
    /**
     * Initial search parameters that can be passed in by a parent component.
     * Must be parseable by URLSearchParams(). A string is used to avoid issues comparing for equality.
     */
    searchParams?: string;
    /**
     * Optional callback to call when search parameters are changed. Can be
     * used by the parent for eg. keeping the actual url up to date.
     */
    onSearchParamsChanged?: (newParams: URLSearchParams) => void;
}

interface DiscussionSearchState {
    cards: ThreadCardProps[];
    // /**
    //  * List of all the tag taxonomies that can be used to filter the search (Interests, Roles, etc.)
    //  * This comes from ElasticSeach, so it reflects only the subset of filter options that are actually in use.
    //  */
    availableSearchFilters: ReadonlyArray<TaxonomyData>;
    resultCount: number; // Number of matches that were returned from the search
    // // Pagination of search results
    currentPage: number;
    paginationSize: number;
    sortOrder: DiscussionsSearchRequestDataOrderingEnum;
    appliedTagIds: ReadonlySet<string>;
    searchKeywords: ReadonlySet<string>;
    loadingNewResults: boolean;
    // // How many times we have refreshed the search results. Used to avoid displaying old results.
    refreshCount: number;
    showMore: boolean;
}

/**
 * The DiscussionSearch component shows thread search results and filtering.
 *
 * It is comprised of a FilterBar for filtering results, a CardsListTopBar
 * for controlling pagination, a CardsListBottomBar for paging actions,
 * and an unordered list of ProfileCard components for displaying results.
 */
class DiscussionSearchInternal extends React.PureComponent<DiscussionSearchProps, DiscussionSearchState> {
    constructor(props: DiscussionSearchProps) {
        super(props);

        const allowedFilters = new Set(Object.keys(DiscussionsSearchFilter));
        const params = sanitizeSearchParams(props.searchParams || '', allowedFilters);

        this.state = {
            cards: [],
            currentPage: getCurrentPageFromString(params.get('page')),
            paginationSize: getPaginationSizeFromString(params.get('size')),
            resultCount: 0,
            sortOrder: getSortOrderingFromString(
                params.get('ordering'),
                DiscussionsSearchRequestDataOrderingEnum.Relevance,
                DISCUSSIONS_SORT_OPTIONS,
            ),
            availableSearchFilters: [],
            appliedTagIds: new Set(params.getAll('t')),
            searchKeywords: new Set(params.getAll('q')),
            loadingNewResults: false,
            refreshCount: 0,
            showMore: false,
        };
    }

    public componentDidMount() {
        this.refreshSearchResults();
    }

    public componentDidUpdate(prevProps: DiscussionSearchProps, prevState: DiscussionSearchState) {
        if (prevProps.searchParams !== this.props.searchParams) {
            const params = new URLSearchParams(this.props.searchParams);
            this.setState({
                currentPage: getCurrentPageFromString(params.get('page')),
                paginationSize: getPaginationSizeFromString(params.get('size')),
                sortOrder: getSortOrderingFromString(
                    params.get('ordering'),
                    DiscussionsSearchRequestDataOrderingEnum.Relevance,
                    DISCUSSIONS_SORT_OPTIONS,
                ),
                appliedTagIds: new Set(params.getAll('t')),
                searchKeywords: new Set(params.getAll('q')),
            });
        } else if (
            !eqSet(prevState.appliedTagIds, this.state.appliedTagIds) ||
            prevState.currentPage !== this.state.currentPage ||
            prevState.paginationSize !== this.state.paginationSize ||
            prevState.sortOrder !== this.state.sortOrder ||
            !eqSet(prevState.searchKeywords, this.state.searchKeywords)
        ) {
            this.refreshSearchResults();
        }
    }

    public render() {
        return (
            <ResponsiveFilteredSearch
                allowFiltering={true}
                appliedFilterIds={this.state.appliedTagIds}
                availableSearchFilters={this.state.availableSearchFilters}
                currentPage={this.state.currentPage}
                onFiltersChanged={this.onFiltersChanged}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
                onSortChange={this.onSortChange}
                paginationSize={this.state.paginationSize}
                resultCount={this.state.resultCount}
                searchKeywords={this.state.searchKeywords}
                showSearchForTaxonomies={[]}
                sortOrder={this.state.sortOrder}
                sortOrderOptions={DISCUSSIONS_SORT_OPTIONS}
                loadingNewResults={this.state.loadingNewResults}
                loadMore={this.loadMore}
                maxPagesDisplayed={60}
            >
                <div className='cards-list-container'>
                    {this.state.cards.map((cardProps, index) => (
                        <ThreadCard
                            key={index}
                            {...cardProps}
                        />
                    ))}
                </div>
            </ResponsiveFilteredSearch>
        );
    }

    private calculatePageCount() {
        return Math.ceil(this.state.resultCount / this.state.paginationSize);
    }

    @bind private onParamsChanged() {
        if (this.props.onSearchParamsChanged) {
            const params = new URLSearchParams();
            this.state.appliedTagIds.forEach((tagID)  => { params.append('t', tagID); });
            this.state.searchKeywords.forEach((kw)  => { params.append('q', kw); });
            params.set('page', this.state.currentPage.toString());
            params.set('size', this.state.paginationSize.toString());
            params.set('ordering', this.state.sortOrder);
            this.props.onSearchParamsChanged(params);
        }
    }

    @bind private onSizeChange(paginationSize: number) {
        this.setState({
            paginationSize,
        }, this.onParamsChanged);
    }

    @bind private onSortChange(sortOrder: string) {
        this.setState({
            sortOrder: sortOrder as DiscussionsSearchRequestDataOrderingEnum,
            currentPage: 1,
        }, this.onParamsChanged);
    }

    @bind private loadMore() {
        this.setState({
            showMore: true,
            currentPage: this.state.currentPage + 1
        });
    }

    @bind private onPageChange(newPage: number) {
        if (newPage === this.state.currentPage) { return; }

        this.setState({currentPage: newPage}, this.onParamsChanged);
    }

    @bind private onFiltersChanged(newFilters: ReadonlySet<string>, searchKeywords: ReadonlySet<string>): void {
        this.setState({
            currentPage: 1,
            appliedTagIds: newFilters,
            searchKeywords,
            cards: [],
        }, this.onParamsChanged);
    }

    /**
     * Refresh the search results, as well as the list of filter options
     */
    private async refreshSearchResults() {
        if (!this.props.isLoggedIn) {
            showLoginRequiredMessage();
            return;
        }

        const thisRefreshCount = this.state.refreshCount + 1; // Used to track which response matches which request
        this.setState({refreshCount: this.state.refreshCount + 1});
        const filters = Array.from(getAppliedSearchFilters(this.state.appliedTagIds)) as DiscussionsAppliedFilter[];
        const searchRequest: DiscussionsSearchRequestData = {
            filters,
            keywords: Array.from(this.state.searchKeywords).join(' '),
            currentPage: this.state.currentPage,
            paginationSize: this.state.paginationSize,
            ordering: this.state.sortOrder,
        };

        this.setState({loadingNewResults: true});
        try {
            const responseData = await SearchApi.discussionsSearch({data: searchRequest});

            // Now, the filters or pagination may have changed while the results were loading.
            // If so, we need to just discard these results and wait for the new ones.
            if (thisRefreshCount !== this.state.refreshCount) {
                return;
            }

            let newThreads: ThreadCardProps[] = this.cardsStateFromData(responseData);
            if (this.state.showMore) {
                newThreads = [...this.state.cards].concat(newThreads);
            }

            this.setState({
                cards: newThreads,
                resultCount: responseData.count,
                availableSearchFilters: buildFilterOptionsFromSearchData(responseData.aggregations),
                loadingNewResults: false,
            }, () => {
                // NOW we can sanitize the page number, because we have the
                // paginationSize and the resultCount set.
                const lastPage = this.calculatePageCount();
                if (this.state.currentPage > lastPage && lastPage > 0) {
                    this.onPageChange(lastPage);
                }
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={libraryMessages.searchError} />, {exception: err});
            this.setState({
                cards: [],
                resultCount: 0,
                loadingNewResults: false,
            });
        }
    }

    private cardsStateFromData(data: DiscussionsSearchResults): ThreadCardProps[] {
        return data.results.map(this.cardPropsForResult);
    }

    @bind private cardPropsForResult(thread: Thread): ThreadCardProps {
        return {
            thread,
            threadLink: thread.itemMetadata? ROUTES.Library.ITEM_SLUG(thread.itemMetadata) : ROUTES.Community.SUBCATEGORY_THREAD_ROUTE_SLUG(thread.subCategory, thread.id.toString()),
        };
    }
}

export const DiscussionSearch = connect<DiscussionSearchReduxProps, {}, {}, RootState>(
    (state: RootState) => ({
        isLoggedIn: getLoggedInStatus(state),
    }),
)(DiscussionSearchInternal);
