import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';

interface MailSubscribeBoxState {
    email: string;
}

interface MailSubscribeBoxProps {
    className?: string;
}

export class MailSubscribeBox extends React.Component<MailSubscribeBoxProps, MailSubscribeBoxState> {

    public constructor(props: MailSubscribeBoxProps) {
        super(props);
        this.state = {
            email: '',
        };
    }

    public render() {
        const formAction =
            'https://labxchange.us4.list-manage.com/subscribe/post?u=d18eaf718c1498daff6dcbd38&amp;id=48941281f4';

        return (
            <form
                action={formAction}
                method='post'
                target='_blank'
                data-testid='subscribe-form'
                noValidate
            >
                <label className='mb-0' htmlFor='subscribe-email'>
                    <WrappedMessage message={messages.footerStayConnectedInfo} />
                </label>
                <div className={`input-group ${this.props.className}`}>
                    <input
                        id='subscribe-email'
                        type='email'
                        name='EMAIL'
                        className='form-control'
                        placeholder={intl.formatMessage(messages.mailSubscribeEmail)}
                        onChange={
                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({email: e.target.value});
                            }
                        }
                        value={this.state.email}
                        required={true}
                        onFocus={() => {
                            analyticsInstance.track(
                                EVENT_NAMES.FooterSubscribeInputFocused,
                                {
                                    form_placeholder: messages.mailSubscribeEmail.defaultMessage,
                                    url: window.location.toString(),
                                }
                            );
                        }}
                    />
                    <div className='input-group-append'>
                        <button
                            className='btn btn-info'
                            type='submit'
                            name='subscribe'
                            disabled={!this.state.email}
                            onClick={() => {
                                analyticsInstance.track(
                                    EVENT_NAMES.FooterSubscribeButtonClicked,
                                    {
                                        button_text: messages.mailSubscribe.defaultMessage,
                                        url: window.location.toString(),
                                    }
                                );
                            }}
                        >
                            <WrappedMessage message={messages.mailSubscribe} />
                        </button>
                    </div>
                </div>
                <div className='bot-protect' aria-hidden='true'>
                    <input
                        type='text'
                        name='b_d18eaf718c1498daff6dcbd38_48941281f4'
                        tabIndex={-1}
                        onChange={() => null}
                        value=''
                    />
                </div>
            </form>
        );
    }
}
