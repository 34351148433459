import moment from 'moment-shortformat';
import * as React from 'react';
import { intl } from 'i18n';
import messages from '../../displayMessages';
import { Icon, KebabMenu, KebabMenuItem } from 'ui/components';
import { Link } from 'react-router-dom';
import { ModerationRequestContentTypeEnum, Thread, ThreadPost as ThreadPostElement } from 'labxchange-client';
import { ReportButton } from 'moderation/components';
import { RichText } from 'elements';
import { UserAvatar } from 'user/components';
import { WrappedMessage } from 'utils';

interface ThreadPostContentProps {
    isOriginalPost?: boolean;
    thread?: Thread;
    post: ThreadPostElement;
}

class ThreadPostContent extends React.PureComponent<ThreadPostContentProps> {
    public render() {
        return (
            <>
                <div className='thread-post-heading'>
                    {this.props.isOriginalPost
                        ? <h2 className='thread-post-heading-title'>{this.props.thread!.title}</h2>
                        : ''
                    }
                    <div className='thread-post-heading-details'>
                        <UserAvatar username={this.props.post.author.username} diameter='44px' />
                        <div className='thread-post-heading-details-block'>
                            <div className='thread-post-heading-username'>
                                {this.props.post.author.fullName}
                            </div>
                            <div className='thread-post-heading-created'>
                                {this.props.isOriginalPost
                                    ? <>
                                        {intl.formatMessage(messages.threadPostedDate,
                                            {date: moment(this.props.thread!.created).format('D MMMM YYYY')})}
                                      </>
                                    : <>
                                        {intl.formatMessage(messages.threadPostedDate,
                                            {date: moment(this.props.post.created).short()})}
                                      </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='thread-post-content'>
                    <RichText innerHtml={this.props.post.html} renderCode={true} renderMath={true}/>
                </div>
            </>
        );
    }
}

interface Props {
    post: ThreadPostElement;
    thread: Thread;
    isOriginalPost?: boolean;
    permalink: string;
    active: boolean;
    replies?: ThreadPostElement[];
    // canEdit property overwrite loggedInUsername check
    canEdit?: boolean;
    // canDelete property overwrite loggedInUsername check
    canDelete?: boolean;
    loggedInUsername: string;
    hideFirstPost?: boolean;
    onReply(postId: number): void;
    onEdit(postId: number): void;
    onDelete(postId: number, isOriginal?: boolean): void;
}

interface State {
    keepKebabExpanded: boolean;
    showReplies: boolean;
}

export class ThreadPost extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            keepKebabExpanded: false,
            showReplies: false,
        };
    }

    public static defaultProps = {
        hideFirstPost: false,
    };

    public render() {
        const post = this.props.post;
        const thread = this.props.thread;
        const isOriginalPost = this.props.isOriginalPost && thread !== undefined;

        return (this.props.hideFirstPost && isOriginalPost ? null :
            <div id={`post-${post.id}`}
                className={
                    'thread-post ' +
                    `${isOriginalPost ? 'thread-post-original' : ''} ` +
                    `${this.props.active ? 'thread-post-active' : ''}`
                }>
                <ThreadPostContent
                    thread={thread}
                    isOriginalPost={isOriginalPost}
                    post={post}
                />
                {this.renderBottomBar()}
            </div>
        );
    }

    private renderBottomBar() {
        const thread = this.props.thread;
        const replies = (this.props.replies || []).map((post) => {
            const canEditReply = this.props.canEdit || this.props.loggedInUsername === post.author.username;
            const canDeleteReply = this.props.canDelete || this.props.loggedInUsername === post.author.username;
            return (
                <div key={`post-${post.id}`} className='thread-post-bottom-bar-replies-reply'>
                    <ThreadPostContent post={post} />
                    <KebabMenu keepExpanded={this.state.keepKebabExpanded}>
                        {canEditReply &&
                            <KebabMenuItem
                                disabled={false}
                                onClick={() => this.props.onEdit(post.id)}
                                iconName='pencil'
                                message={messages.discussionsPostEdit} />
                        }
                        {canDeleteReply &&
                            <KebabMenuItem
                                disabled={false}
                                onClick={() => this.props.onDelete(post.id, this.props.isOriginalPost)}
                                iconName='trashcan'
                                message={messages.discussionsPostDelete} />
                        }
                        <ReportButton
                            asKebab={true}
                            itemId={post.id.toString()}
                            itemTitle={post.id.toString()}
                            itemType={ModerationRequestContentTypeEnum.DiscussionsPost}
                            extraContext={{ threadId: thread.id }}
                            label={messages.actionReport}
                            onOpen={() => this.setState({ keepKebabExpanded: true })}
                            onClose={() => this.setState({ keepKebabExpanded: false })}
                        />
                    </KebabMenu>
                </div>
            );
        });

        const canEdit = this.props.canEdit || (
            this.props.loggedInUsername === this.props.post.author.username);
        const canDelete = (this.props.canDelete || (
            this.props.loggedInUsername === this.props.post.author.username && !this.props.isOriginalPost));
        return (
            <div className='thread-post-bottom-bar'>
                <div className='thread-post-bottom-bar-actions'>
                    {this.props.post.replyCount && this.props.post.replyCount > 0
                        ? <button
                            className='unstyled thread-post-bottom-bar-actions-replies'
                            onClick={() => this.setState({ showReplies: !this.state.showReplies })}>
                            <Icon name='comment' zoom='x1.25' />
                            <WrappedMessage
                                message={messages.discussionsReplyCount}
                                values={{ count: this.props.post.replyCount }}
                            />
                            {this.state.showReplies
                                ? <Icon name='chevron-up' zoom='x1.25' />
                                : <Icon name='chevron-down' zoom='x1.25' />
                            }
                        </button>
                        : ''}
                    <Link aria-label='Permalink' to={this.props.permalink}>
                        <Icon name='link' zoom='x1.25' />
                    </Link>
                    <KebabMenu keepExpanded={this.state.keepKebabExpanded}>
                        {canEdit &&
                            <KebabMenuItem
                                disabled={false}
                                onClick={() => this.props.onEdit(this.props.post.id)}
                                iconName='pencil'
                                message={messages.discussionsPostEdit} />
                        }
                        {canDelete &&
                            <KebabMenuItem
                                disabled={false}
                                onClick={() => this.props.onDelete(this.props.post.id, this.props.isOriginalPost)}
                                iconName='trashcan'
                                message={messages.discussionsPostDelete} />
                        }
                        {this.props.loggedInUsername !== this.props.post.author.username && <ReportButton
                            asKebab={true}
                            itemId={this.props.post.id.toString()}
                            itemTitle={this.props.post.id.toString()}
                            itemType={ModerationRequestContentTypeEnum.DiscussionsPost}
                            extraContext={{ threadId: thread.id }}
                            label={messages.actionReport}
                            onOpen={() => this.setState({ keepKebabExpanded: true })}
                            onClose={() => this.setState({ keepKebabExpanded: false })}
                        />}
                    </KebabMenu>
                    {this.props.thread.permissions?.canReplyThreadObject ?
                        <button
                            className='unstyled'
                            onClick={() => this.props.onReply(this.props.post.id)}>
                            <Icon name='reply' zoom='x1.25' />
                            Reply
                        </button> : null}
                </div>
                {this.state.showReplies
                    ? <div className='thread-post-bottom-bar-replies'>
                        {replies}
                    </div>
                    : null}
                <div className='thread-post-bottom-bar-stats'>
                    {this.props.isOriginalPost && thread !== undefined
                        ?
                        <>
                            <div className='icon-wrapper' aria-label={`Likes: ${thread.likeCount}`} role='group'>
                                <Icon name='heart' zoom='x1.25' aria-hidden='true' />
                                <span aria-hidden='true'>{thread.likeCount}</span>
                            </div>
                            <div className='icon-wrapper' aria-label={`Comments: ${thread.postCount}`} role='group'>
                                <Icon name='comment' zoom='x1.25' aria-hidden='true' />
                                <span aria-hidden='true'>{thread.postCount}</span>
                            </div>
                            <div className='icon-wrapper' aria-label={`Views: ${thread.viewCount}`} role='group'>
                                <Icon name='eye' zoom='x1.25' aria-hidden='true' />
                                <span aria-hidden='true'>{thread.viewCount}</span>
                            </div>
                            <div className='icon-wrapper' aria-label={`Participants: ${thread.participantCount}`} role='group'>
                                <Icon name='person' zoom='x1.25' aria-hidden='true' />
                                <span aria-hidden='true'>{thread.participantCount}</span>
                            </div>
                            <div className='thread-post-bottom-bar-stats-last-reply'>
                                Last reply {moment(thread.updated).short()}
                            </div>
                        </>
                        : null}
                </div>
            </div>
        );
    }
}
