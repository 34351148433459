import * as React from 'react';
import bind from 'bind-decorator';

import { Modal } from 'elements/components/Modals';
import { ModerationApi } from 'global/api';
import {
    ModerationRequestContentTypeEnum,
    ModerationRequestReasonEnum,
} from 'labxchange-client';
import { TextBox } from 'ui/components';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { Option } from 'ui/components/Option';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { intl } from 'i18n';

interface Props {
    itemId: string;
    itemType: ModerationRequestContentTypeEnum;
    itemTitle: string;
    extraContext: {};
    onClose(): void;
    onSuccess(): void;
    onError(): void;
}

interface State {
    reason?: ModerationRequestReasonEnum;
    description: string;
    sending: boolean;
}

export class ReportSubmitModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            description: '',
            sending: false,
        };
    }

    public render() {
        const canSubmit = this.canSubmit();
        return (
            <Modal onRequestClose={this.props.onClose}
                className='moderation-modal moderation-report-modal'
                content={
                    <>
                        <div className={`title-icon ${this.props.itemType}`}/>
                        <div className='title-text'>
                            <WrappedMessage
                                message={messages.modalTitle}
                                values={{title: this.props.itemTitle}}
                            />
                        </div>
                        <div className='body-text'>
                            <WrappedMessage
                                message={messages.modalDescription}
                            />
                            <div className='moderation-report-modal-form'>
                                <label>
                                    <WrappedMessage
                                        message={messages.modalReasonLabel}
                                    />
                                    <select
                                        onBlur={this.onChangeReason}
                                        onChange={this.onChangeReason}
                                        required={true} aria-required={true}
                                    >
                                        <Option
                                            value=''
                                            labelDescriptor={messages.reasonDefault}
                                        />
                                        <Option
                                            value={ModerationRequestReasonEnum.Accessibility}
                                            labelDescriptor={messages.reasonAccessiblity}
                                        />
                                        <Option
                                            value={ModerationRequestReasonEnum.Offensive}
                                            labelDescriptor={messages.reasonOffensive}
                                        />
                                        <Option
                                            value={ModerationRequestReasonEnum.Unavailable}
                                            labelDescriptor={messages.reasonUnavailable}
                                        />
                                        <Option
                                            value={ModerationRequestReasonEnum.Quality}
                                            labelDescriptor={messages.reasonQualityIssue}
                                        />
                                        <Option
                                            value={ModerationRequestReasonEnum.Copyright}
                                            labelDescriptor={messages.reasonCopyright}
                                        />
                                        <Option
                                            value={ModerationRequestReasonEnum.Other}
                                            labelDescriptor={messages.reasonOther}
                                        />
                                    </select>
                                </label>
                                <TextBox
                                    value={this.state.description}
                                    label={
                                        <WrappedMessage
                                            message={messages.modalDescriptionLabel}
                                        />
                                    }
                                    onChange={this.onChangeDescription}
                                    placeholder={intl.formatMessage(messages.modalDescriptionPlaceholder)}
                                />
                            </div>
                        </div>
                        <div className='footer'>
                            <button
                                className='btn btn-primary'
                                disabled={!canSubmit}
                                aria-disabled={!canSubmit}
                                onClick={this.onSubmit}>
                                <WrappedMessage
                                    message={messages.modalSubmitButton}
                                />
                            </button>
                        </div>
                    </>
                }
            />
        );
    }

    @bind private async onSubmit() {
        if (!this.canSubmit()) { return; }

        this.setState({sending: true});

        try {
            await ModerationApi.create({
                data: {
                    objectId: this.props.itemId,
                    contentType: this.props.itemType,
                    reason: this.state.reason!,
                    description: this.state.description,
                    ...this.props.extraContext,
                },
            });
            this.props.onSuccess();
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorCreate} />, {
                exception: err,
                details: `Unable to create your report. Please try again later.`,
            });
            this.props.onError();
        }
    }

    @bind private onChangeReason(event: React.FormEvent<HTMLSelectElement>) {
        this.setState({reason: event.currentTarget.value as ModerationRequestReasonEnum});
    }

    @bind private onChangeDescription(value: string) {
        this.setState({description: value});
    }

    private canSubmit() {
        if (this.state.description && this.state.reason && !this.state.sending) {
            return true;
        }
        return false;
    }
}
