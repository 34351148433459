import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { AuthPageLayout } from '../../../ui/components';
import { LoginPageContent } from '../elements/LoginPageContent';

export interface LoginPageComponentProps extends React.PropsWithChildren {
    children: React.ReactElement;
    title: MessageDescriptor;
    subtitle: MessageDescriptor;
    showSignUpButton?: boolean;
    showBackButton?: boolean;
    termsAndPolicyMessage: MessageDescriptor;
    onSignUpButtonClick?: () => void;
    onRequestClose: () => void;
    onRequestBack?: () => void;
    onClickTermsOfService?: () => void;
    onClickPrivacyPolicy?: () => void;
}

export const LoginPageComponent: React.FunctionComponent<LoginPageComponentProps> = (props) => {

    return (
        <AuthPageLayout
            className='login-page'
            imageUrl='/assets/images/auth-layouts/astronaut.svg'
            enableBackButton={props.showBackButton}
            onClickBackButton={props.onRequestBack}
        >
            <LoginPageContent
                title={props.title}
                subtitle={props.subtitle}
                termsAndPolicyMessage={props.termsAndPolicyMessage}
                children={props.children}
                onSignUpButtonClick={props.onSignUpButtonClick}
            />
        </AuthPageLayout>
    );
};
