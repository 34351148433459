// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConversationList,
    ConversationListFromJSON,
    ConversationListToJSON,
    ConversationStatus,
    ConversationStatusFromJSON,
    ConversationStatusToJSON,
    ConversationWithMessages,
    ConversationWithMessagesFromJSON,
    ConversationWithMessagesToJSON,
    DiscussionAuthor,
    DiscussionAuthorFromJSON,
    DiscussionAuthorToJSON,
    DiscussionCategory,
    DiscussionCategoryFromJSON,
    DiscussionCategoryToJSON,
    DiscussionSubCategory,
    DiscussionSubCategoryFromJSON,
    DiscussionSubCategoryToJSON,
    DiscussionsMessageCreate,
    DiscussionsMessageCreateFromJSON,
    DiscussionsMessageCreateToJSON,
    DiscussionsMessageCreateResponse,
    DiscussionsMessageCreateResponseFromJSON,
    DiscussionsMessageCreateResponseToJSON,
    DiscussionsMessageStatus,
    DiscussionsMessageStatusFromJSON,
    DiscussionsMessageStatusToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    Thread,
    ThreadFromJSON,
    ThreadToJSON,
    ThreadCreate,
    ThreadCreateFromJSON,
    ThreadCreateToJSON,
    ThreadList,
    ThreadListFromJSON,
    ThreadListToJSON,
    ThreadPost,
    ThreadPostFromJSON,
    ThreadPostToJSON,
    ThreadPostCreate,
    ThreadPostCreateFromJSON,
    ThreadPostCreateToJSON,
    ThreadPostDelete,
    ThreadPostDeleteFromJSON,
    ThreadPostDeleteToJSON,
    ThreadPostUpdate,
    ThreadPostUpdateFromJSON,
    ThreadPostUpdateToJSON,
    ThreadUpdate,
    ThreadUpdateFromJSON,
    ThreadUpdateToJSON,
    ThreadWithPosts,
    ThreadWithPostsFromJSON,
    ThreadWithPostsToJSON,
} from '../models';

export interface AuthorReadRequest {
    username: string;
}

export interface CategoriesListRequest {
    page?: number;
}

export interface CategoriesReadRequest {
    id: string;
}

export interface ConversationsAcceptPendingRequest {
    id: string;
}

export interface ConversationsBlockUserRequest {
    id: string;
}

export interface ConversationsCheckCanMessageRequest {
    id: string;
}

export interface ConversationsCreateMessageRequest {
    data: DiscussionsMessageCreate;
}

export interface ConversationsListRequest {
    page?: number;
}

export interface ConversationsReadRequest {
    id: string;
}

export interface ConversationsUnblockUserRequest {
    id: string;
}

export interface SubcategoriesListRequest {
    page?: number;
}

export interface SubcategoriesReadRequest {
    id: string;
}

export interface ThreadsCreateRequest {
    data: ThreadCreate;
}

export interface ThreadsDeleteRequest {
    id: string;
}

export interface ThreadsFollowRequest {
    id: string;
}

export interface ThreadsLikeRequest {
    id: string;
}

export interface ThreadsListRequest {
    page?: number;
    subCategory?: string;
    currentPage?: number;
    paginationSize?: number;
    ordering?: string;
}

export interface ThreadsPartialUpdateRequest {
    id: string;
    data: ThreadUpdate;
}

export interface ThreadsPostCreateRequest {
    id: string;
    data: ThreadPostCreate;
}

export interface ThreadsPostDeleteRequest {
    id: string;
    data: ThreadPostDelete;
}

export interface ThreadsPostPartialUpdateRequest {
    id: string;
    data: ThreadPostUpdate;
}

export interface ThreadsReadRequest {
    id: string;
}

export interface ThreadsThreadForItemRequest {
    itemId: string;
    page?: number;
    onlyThread?: boolean;
}

/**
 * no description
 */
export class DiscussionsApi extends runtime.BaseAPI {

    /**
     * Get a single user
     */
    async authorReadRaw(requestParameters: AuthorReadRequest): Promise<runtime.ApiResponse<DiscussionAuthor>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling authorRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/author/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscussionAuthorFromJSON(jsonValue));
    }

    /**
     * Get a single user
     */
    async authorRead(requestParameters: AuthorReadRequest): Promise<DiscussionAuthor> {
        const response = await this.authorReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Readonly viewset for public discussion categories
     */
    async categoriesListRaw(requestParameters: CategoriesListRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * Readonly viewset for public discussion categories
     */
    async categoriesList(requestParameters: CategoriesListRequest): Promise<InlineResponse2002> {
        const response = await this.categoriesListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Readonly viewset for public discussion categories
     */
    async categoriesReadRaw(requestParameters: CategoriesReadRequest): Promise<runtime.ApiResponse<DiscussionCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling categoriesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/categories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscussionCategoryFromJSON(jsonValue));
    }

    /**
     * Readonly viewset for public discussion categories
     */
    async categoriesRead(requestParameters: CategoriesReadRequest): Promise<DiscussionCategory> {
        const response = await this.categoriesReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Accepting a pending message from user. Idempotent.
     */
    async conversationsAcceptPendingRaw(requestParameters: ConversationsAcceptPendingRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conversationsAcceptPending.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations/{id}/accept_pending`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Accepting a pending message from user. Idempotent.
     */
    async conversationsAcceptPending(requestParameters: ConversationsAcceptPendingRequest): Promise<any> {
        const response = await this.conversationsAcceptPendingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Block a user. Idempotent.
     */
    async conversationsBlockUserRaw(requestParameters: ConversationsBlockUserRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conversationsBlockUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations/{id}/block_user`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Block a user. Idempotent.
     */
    async conversationsBlockUser(requestParameters: ConversationsBlockUserRequest): Promise<any> {
        const response = await this.conversationsBlockUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check if logged in user is allowed to message the `pk` user. This is used by the frontend when clicking on message buttons to determine what action should be taken.
     */
    async conversationsCheckCanMessageRaw(requestParameters: ConversationsCheckCanMessageRequest): Promise<runtime.ApiResponse<ConversationStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conversationsCheckCanMessage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations/{id}/check_can_message`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationStatusFromJSON(jsonValue));
    }

    /**
     * Check if logged in user is allowed to message the `pk` user. This is used by the frontend when clicking on message buttons to determine what action should be taken.
     */
    async conversationsCheckCanMessage(requestParameters: ConversationsCheckCanMessageRequest): Promise<ConversationStatus> {
        const response = await this.conversationsCheckCanMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a conversation message
     */
    async conversationsCreateMessageRaw(requestParameters: ConversationsCreateMessageRequest): Promise<runtime.ApiResponse<DiscussionsMessageCreateResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling conversationsCreateMessage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations/create_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiscussionsMessageCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscussionsMessageCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates a conversation message
     */
    async conversationsCreateMessage(requestParameters: ConversationsCreateMessageRequest): Promise<DiscussionsMessageCreateResponse> {
        const response = await this.conversationsCreateMessageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for the Conversation model
     */
    async conversationsListRaw(requestParameters: ConversationsListRequest): Promise<runtime.ApiResponse<ConversationList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationListFromJSON(jsonValue));
    }

    /**
     * Viewset for the Conversation model
     */
    async conversationsList(requestParameters: ConversationsListRequest): Promise<ConversationList> {
        const response = await this.conversationsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for the Conversation model
     */
    async conversationsReadRaw(requestParameters: ConversationsReadRequest): Promise<runtime.ApiResponse<ConversationWithMessages>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conversationsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConversationWithMessagesFromJSON(jsonValue));
    }

    /**
     * Viewset for the Conversation model
     */
    async conversationsRead(requestParameters: ConversationsReadRequest): Promise<ConversationWithMessages> {
        const response = await this.conversationsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Unblock a user. Idempotent.
     */
    async conversationsUnblockUserRaw(requestParameters: ConversationsUnblockUserRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling conversationsUnblockUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/conversations/{id}/unblock_user`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Unblock a user. Idempotent.
     */
    async conversationsUnblockUser(requestParameters: ConversationsUnblockUserRequest): Promise<any> {
        const response = await this.conversationsUnblockUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Readonly viewsets for discussion subcategories.
     */
    async subcategoriesListRaw(requestParameters: SubcategoriesListRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/subcategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     * Readonly viewsets for discussion subcategories.
     */
    async subcategoriesList(requestParameters: SubcategoriesListRequest): Promise<InlineResponse2003> {
        const response = await this.subcategoriesListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Readonly viewsets for discussion subcategories.
     */
    async subcategoriesReadRaw(requestParameters: SubcategoriesReadRequest): Promise<runtime.ApiResponse<DiscussionSubCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling subcategoriesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/subcategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscussionSubCategoryFromJSON(jsonValue));
    }

    /**
     * Readonly viewsets for discussion subcategories.
     */
    async subcategoriesRead(requestParameters: SubcategoriesReadRequest): Promise<DiscussionSubCategory> {
        const response = await this.subcategoriesReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new Thread
     */
    async threadsCreateRaw(requestParameters: ThreadsCreateRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling threadsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ThreadCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Create a new Thread
     */
    async threadsCreate(requestParameters: ThreadsCreateRequest): Promise<Thread> {
        const response = await this.threadsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a thread
     */
    async threadsDeleteRaw(requestParameters: ThreadsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a thread
     */
    async threadsDelete(requestParameters: ThreadsDeleteRequest): Promise<void> {
        await this.threadsDeleteRaw(requestParameters);
    }

    /**
     * Follow a thread
     */
    async threadsFollowRaw(requestParameters: ThreadsFollowRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsFollow.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}/follow`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Follow a thread
     */
    async threadsFollow(requestParameters: ThreadsFollowRequest): Promise<Thread> {
        const response = await this.threadsFollowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Like a thread
     */
    async threadsLikeRaw(requestParameters: ThreadsLikeRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsLike.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}/like`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Like a thread
     */
    async threadsLike(requestParameters: ThreadsLikeRequest): Promise<Thread> {
        const response = await this.threadsLikeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for the Thread model
     */
    async threadsListRaw(requestParameters: ThreadsListRequest): Promise<runtime.ApiResponse<ThreadList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.subCategory !== undefined) {
            queryParameters['sub_category'] = requestParameters.subCategory;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.paginationSize !== undefined) {
            queryParameters['pagination_size'] = requestParameters.paginationSize;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadListFromJSON(jsonValue));
    }

    /**
     * Viewset for the Thread model
     */
    async threadsList(requestParameters: ThreadsListRequest): Promise<ThreadList> {
        const response = await this.threadsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Partially update a thread
     */
    async threadsPartialUpdateRaw(requestParameters: ThreadsPartialUpdateRequest): Promise<runtime.ApiResponse<Thread>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling threadsPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ThreadUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadFromJSON(jsonValue));
    }

    /**
     * Partially update a thread
     */
    async threadsPartialUpdate(requestParameters: ThreadsPartialUpdateRequest): Promise<Thread> {
        const response = await this.threadsPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create, update or delete a new thread post
     */
    async threadsPostCreateRaw(requestParameters: ThreadsPostCreateRequest): Promise<runtime.ApiResponse<ThreadPost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsPostCreate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling threadsPostCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}/post`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ThreadPostCreateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadPostFromJSON(jsonValue));
    }

    /**
     * Create, update or delete a new thread post
     */
    async threadsPostCreate(requestParameters: ThreadsPostCreateRequest): Promise<ThreadPost> {
        const response = await this.threadsPostCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create, update or delete a new thread post
     */
    async threadsPostDeleteRaw(requestParameters: ThreadsPostDeleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsPostDelete.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling threadsPostDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}/post`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ThreadPostDeleteToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Create, update or delete a new thread post
     */
    async threadsPostDelete(requestParameters: ThreadsPostDeleteRequest): Promise<any> {
        const response = await this.threadsPostDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create, update or delete a new thread post
     */
    async threadsPostPartialUpdateRaw(requestParameters: ThreadsPostPartialUpdateRequest): Promise<runtime.ApiResponse<ThreadPost>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsPostPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling threadsPostPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}/post`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ThreadPostUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadPostFromJSON(jsonValue));
    }

    /**
     * Create, update or delete a new thread post
     */
    async threadsPostPartialUpdate(requestParameters: ThreadsPostPartialUpdateRequest): Promise<ThreadPost> {
        const response = await this.threadsPostPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset for the Thread model
     */
    async threadsReadRaw(requestParameters: ThreadsReadRequest): Promise<runtime.ApiResponse<ThreadWithPosts>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadWithPostsFromJSON(jsonValue));
    }

    /**
     * Viewset for the Thread model
     */
    async threadsRead(requestParameters: ThreadsReadRequest): Promise<ThreadWithPosts> {
        const response = await this.threadsReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get or Create a thread from an item asset
     */
    async threadsThreadForItemRaw(requestParameters: ThreadsThreadForItemRequest): Promise<runtime.ApiResponse<ThreadWithPosts>> {
        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling threadsThreadForItem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemId !== undefined) {
            queryParameters['item_id'] = requestParameters.itemId;
        }

        if (requestParameters.onlyThread !== undefined) {
            queryParameters['only_thread'] = requestParameters.onlyThread;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/discussions/threads/thread_for_item`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThreadWithPostsFromJSON(jsonValue));
    }

    /**
     * Get or Create a thread from an item asset
     */
    async threadsThreadForItem(requestParameters: ThreadsThreadForItemRequest): Promise<ThreadWithPosts> {
        const response = await this.threadsThreadForItemRaw(requestParameters);
        return await response.value();
    }

}
