// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Redirect
 */
export interface Redirect {
    /**
     * Key for this redirect. Users will visit {FRONTEND_URL}/s/{key}
     * @type {string}
     * @memberof Redirect
     */
    key: string;
    /**
     * Url to redirect to. This must be a relative url beginning with a '/' (will be resolved relative to the frontend url).
     * @type {string}
     * @memberof Redirect
     */
    url: string;
}

export function RedirectFromJSON(json: any): Redirect {
    return {
        'key': json['key'],
        'url': json['url'],
    };
}

export function RedirectToJSON(value?: Redirect): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'key': value.key,
        'url': value.url,
    };
}


