import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import * as React from 'react';

import { ItemsApi } from 'global/api';
import { CreateItemStep } from 'items/components/CreateItemModal';
import { Card, ContentPickerModal, EmbeddedBlock, Tab } from 'library/components';
import { CardFauxAdd } from 'ui/components/Card/Cards';
import { detailUrlForEntity } from 'library/utils';
import {
    Button,
    EditorStyle,
    HtmlTextBox,
    Icon,
    KebabMenu,
    KebabMenuItem,
    Spinner,
    ItemSection,
    ReorderableList,
    ReorderableListItem,
} from 'ui/components';
import {
    CaseStudyEditorState,
    SpecificEditorProps,
} from './models';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { ItemType } from 'items/models';
import { ItemMetadata, ItemResponse } from 'labxchange-client';
import { intl } from 'i18n';

type Props = SpecificEditorProps<CaseStudyEditorState>;

enum ModalType {
    None,
    ContentPicker,
}

export enum Variant {
    CaseStudy,
    TeachingGuide,
}

interface State {
    // These hacks are a workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=1189486
    disableDragging: boolean;
    modalType: ModalType;
    selectedSectionIndex?: number; // section we're currently working on, for content picker modal to add to, etc.
    contentPickerTab: Tab;
    createItemStep: CreateItemStep;
    addAsEmbed: boolean;
    createItemSelectedItemType?: ItemType;
}

/**
 * Editor UI for Assignment components (lx_assignment)
 */
export class CaseStudyBlockEditor extends React.PureComponent<Props, State> {
    variant: Variant;
    private lastSectionTitleInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            disableDragging: false,
            modalType: ModalType.None,
            selectedSectionIndex: undefined,
            contentPickerTab: Tab.Library,
            createItemStep: CreateItemStep.SelectType,
            createItemSelectedItemType: undefined,
            addAsEmbed: true,
        };
        this.variant = Variant.CaseStudy;
        this.lastSectionTitleInputRef = React.createRef();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        // Here we check if a new section was added,
        // so we can auto focus the title input for the new section added.
        const titleInputEl: any = this.lastSectionTitleInputRef.current;
        if (
            titleInputEl
            && this.props.editorState
            && this.props.editorState.sections.length >
               (prevProps.editorState ? prevProps.editorState.sections.length : 0)
        ) {
            titleInputEl.focus();
        }
    }

    public render() {
        const editorState = this.props.editorState;

        // If we're loading just display a loading spinner
        if (editorState === undefined) {
            return <div className='p-4'><Spinner /></div>;
        }

        const sectionChildrenCards = [];
        for (let i=0; i<editorState.sections.length; i++) {
            const children = editorState.sections[i].children;
            for (let j=0; j<children.length; j++) {
                const child = children[j];
                if (child.type === 'block') {
                    sectionChildrenCards.push(<Card
                        mobileViewMode={true}
                        metadata={child.item.metadata}
                        showMenuButton={false}
                        hideFavoriteButton={true}
                        userAttributes={child.item.userAttributes}
                        detailUrl={detailUrlForEntity(child.item.metadata)}
                        menu={
                            <KebabMenu
                                darkKebab={true}
                            >
                                <KebabMenuItem
                                    iconName='trashcan'
                                    disabled={false}
                                    message={messages.caseStudyEditorRemoveSectionLabel}
                                    onClick={() => this.removeSectionAttachment(i, j)}
                                />
                            </KebabMenu>
                        }
                    />);
                }
            }
        }

        const attachmentCards = editorState.attachments.map((x: ItemResponse, i: number) => (
            <Card
                mobileViewMode={true}
                metadata={x.metadata}
                showMenuButton={false}
                hideFavoriteButton={true}
                userAttributes={x.userAttributes}
                detailUrl={detailUrlForEntity(x.metadata)}
                menu={
                    <KebabMenu
                    darkKebab={true}
                    >
                        <KebabMenuItem
                            iconName='trashcan'
                            disabled={false}
                            message={messages.caseStudyEditorRemoveSectionLabel}
                            onClick={() => this.removeAttachment(i)}
                        />
                    </KebabMenu>
                }
            />
        ));

        return (
            <>
                {this.renderModals()}
                <ItemSection sectionName='case-study-editor'>
                    <ReorderableList
                        disableDragging={this.state.disableDragging}
                        items={this.listSections()}
                        onMove={(oldIndex, newIndex) => {
                            const sections = editorState.sections.slice();
                            sections.splice(oldIndex, 1);
                            sections.splice(newIndex, 0, editorState.sections[oldIndex]);
                            this.props.onEditorStateChanged({
                                ...editorState,
                                sections,
                            });
                        }}
                    />
                </ItemSection>

                <ItemSection
                    sectionName='case-study-editor-attachments'
                    title={<WrappedMessage message={messages.caseStudyEditorContentTitle} />}
                >
                    <CardFauxAdd
                        message={messages.caseStudyEditorAddContent}
                        onClick={this.onRequestAddAttachment}
                    />
                    {sectionChildrenCards}
                    {attachmentCards}

                </ItemSection>
            </>
        );
    }

    // NOTE: the logic here must be kept in sync with:
    //       - VALID_*_TYPES from backend/labxchange/apps/library/models/item_type.py
    //       - add_item_to_*() from backend/labxchange/apps/library/api/v1/views.py
    private getExcludeTypes(): ItemType[] {
        if (this.state.selectedSectionIndex !== undefined && this.state.addAsEmbed) {
            // if we're adding an inline embedded asset, we need to restrict types
            return Object.values(ItemType).filter(
                (x) => ![ItemType.Audio, ItemType.Image, ItemType.Video].includes(x)
            );
        } else {
            if (this.variant === Variant.CaseStudy) {
                return [ItemType.Assignment, ItemType.Assessment, ItemType.Pathway, ItemType.Cluster];
            } else {
                return [];
            }
        }
    }

    private renderModals(): React.ReactNode {
        switch(this.state.modalType) {
            case ModalType.ContentPicker: {

                const excludeTypes = this.getExcludeTypes();

                return (
                    <ContentPickerModal
                        onClose={() => this.setState({ modalType: ModalType.None })}
                        onItemAdd={this.onAddAsset}
                        excludeTypes={excludeTypes}
                        multipleSelect={false}
                        showCreateItemTab={true}
                        tab={this.state.contentPickerTab}
                        onTabChange={this.onContentPickerTabChange}
                        createItemStep={this.state.createItemStep}
                        onCreateItemStepChange={this.onCreateItemStepChange}
                        createItemSelectedItemType={this.state.createItemSelectedItemType}
                        onCreateItemSelectedItemTypeChange={this.onCreateItemSelectedItemTypeChange}
                        onCreateItem={this.onNestedCreate}
                    />
                );
            }

            case ModalType.None:
            default: {
                return null;
            }
        }
    }

    private renderSection(index: number): React.ReactNode {
        if (this.props.editorState === undefined) { return; }
        const section = this.props.editorState.sections[index];
        const expanded = section.expanded;
        const isLastRef = index === this.props.editorState.sections.length - 1;

        return (
            <div className='case-study-section'>
                <div className='title-part'>
                    <input
                        // These hacks are a workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=1189486
                        onFocus={() => this.setState({ disableDragging: true })}
                        onBlur={() => this.setState({ disableDragging: false })}
                        onMouseEnter={() => this.setState({ disableDragging: true })}
                        onMouseLeave={() => this.setState({ disableDragging: false })}

                        aria-label={intl.formatMessage(messages.caseStudyEditorSectionTitleInputLabel)}
                        className='lx-input'
                        value={section.title}
                        onChange={(e) => this.onChangeSectionTitle(index, e.target.value)}
                        ref={isLastRef ? this.lastSectionTitleInputRef : null}
                        dir='auto'
                    />
                    <Button
                        btnStyle='unstyled'
                        className='expand-btn'
                        icon={expanded ? 'chevron-down' : 'chevron-up'}
                        iconOnly={true}
                        iconZoom='38'
                        label={expanded ?
                            messages.caseStudyEditorCollapseSectionLabel
                            : messages.caseStudyEditorExpandSectionLabel}
                        onClick={() => this.toggleExpandSection(index)}
                    />
                    <KebabMenu
                        darkKebab={true}
                    >
                        <KebabMenuItem
                            iconName='trashcan'
                            message={messages.caseStudyEditorRemoveSectionLabel}
                            onClick={() => this.removeSection(index)}
                            disabled={false}
                        />
                    </KebabMenu>
                </div>
                {expanded && this.renderSectionBody(index)}
            </div>
        );
    }

    private renderSectionBody(index: number): React.ReactNode {
        if (this.props.editorState === undefined) { return; }

        return (
            <div className='body-part'>
                {this.renderSectionAssets(index)}
                {this.renderSectionAddButtons(index)}
            </div>
        );
    }

    private renderSectionAddButtons(index: number): React.ReactNode {
        if (this.props.editorState === undefined) { return; }

        if (this.variant === Variant.CaseStudy) {
            // Shouldn't be able to add an inline text child immediately after another inline text child.
            const section = this.props.editorState.sections[index];
            const showBothBtns = (
                section.children.length === 0
                || section.children[section.children.length-1].type !== 'inline'
            );

            return (
                <div className='add-btns case-study'>
                    {showBothBtns &&
                        <>
                        <button
                            className='lxc-unit-another-btn'
                            onClick={() => this.onAddTextBox(index)}
                        >
                            <Icon name='text' zoom='22' />
                            <WrappedMessage message={messages.caseStudyEditorAddTextboxLabel} />
                        </button>
                        <div className='or'>
                            <WrappedMessage message={messages.caseStudyEditorOr} />
                        </div>
                        </>
                    }
                    <button
                        className='lxc-unit-another-btn'
                        onClick={() => this.onOpenContentPicker(index, true)}
                    >
                        <Icon name='device-camera-video' zoom='22' />
                        <WrappedMessage message={messages.caseStudyEditorAddMediaLabel} />
                    </button>
                </div>
            );
        } else {
            // Teaching guide
            return (
                <div className='add-btns teaching-guide'>
                    <button
                        className='lxc-unit-another-btn'
                        onClick={() => this.onAddTextBox(index)}
                    >
                        <Icon name='text' zoom='22' />
                        <WrappedMessage message={messages.caseStudyEditorAddTextboxLabel} />
                    </button>
                    <button
                        className='lxc-unit-another-btn'
                        onClick={() => this.onOpenContentPicker(index, true)}
                    >
                        <Icon name='device-camera-video' zoom='22' />
                        <WrappedMessage message={messages.caseStudyEditorAddMediaLabel} />
                    </button>
                    <button
                        className='lxc-unit-another-btn'
                        onClick={() => this.onOpenContentPicker(index, false)}
                    >
                        <Icon name='document' zoom='22' />
                        <WrappedMessage message={messages.teachingGuideEditorAddContentLinkLabel} />
                    </button>
                </div>
            );
        }
    }

    private renderSectionAssets(sectionIndex: number): React.ReactNode {
        if (this.props.editorState === undefined) { return; }
        const section = this.props.editorState.sections[sectionIndex];

        return section.children.map((_, childIndex) => {
            return (
                <div className='section-child'>
                    <div className='child-container'>
                        {this.renderSectionAssetInner(sectionIndex, childIndex)}
                    </div>
                    <KebabMenu
                        darkKebab={true}
                    >
                        <KebabMenuItem
                            iconName='trashcan'
                            message={messages.caseStudyEditorRemoveSectionLabel}
                            onClick={() => this.removeAsset(sectionIndex, childIndex)}
                            disabled={false}
                        />
                        <KebabMenuItem
                            iconName='move-arrow-up'
                            message={messages.caseStudyEditorMoveUp}
                            onClick={() => this.moveAssetUp(sectionIndex, childIndex)}
                            disabled={false}
                        />
                        <KebabMenuItem
                            iconName='move-arrow-down'
                            message={messages.caseStudyEditorMoveDown}
                            onClick={() => this.moveAssetDown(sectionIndex, childIndex)}
                            disabled={false}
                        />
                    </KebabMenu>
                </div>
            );
        });
    }

    private renderSectionAssetInner(sectionIndex: number, childIndex: number): React.ReactNode {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return null; }

        const section = editorState.sections[sectionIndex];
        const child = section.children[childIndex];
        if (child.type === 'inline') {
            return (
                <HtmlTextBox
                    defaultValue={child.inlinehtml}
                    editorStyle={EditorStyle.Full}
                    label={<WrappedMessage message={messages.caseStudyEditorTextContentLabel} />}
                    hideLabel={true}
                    required={true}
                    showErrors={false}
                    onChange={(text) => this.onTextAssetChange(sectionIndex, childIndex, text)}
                />
            );
        } else if (child.embed) {
            return (
                <EmbeddedBlock itemId={child.item.metadata.id} />
            );
        } else {
            return (
                <Card
                    metadata={child.item.metadata}
                    showMenuButton={false}
                    hideFavoriteButton={true}
                    userAttributes={child.item.userAttributes}
                    detailUrl={detailUrlForEntity(child.item.metadata)}

                />
            );
        }
    }

    @bind private async onAddAsset(item: ItemMetadata) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }

        const itemResponse = await ItemsApi.read({ id: item.id });
        const sectionIndex = this.state.selectedSectionIndex;

        if (sectionIndex === undefined) {
            // we're adding an attachment, rather than to a particular section
            this.props.onEditorStateChanged({
                ...editorState,
                attachments: [...editorState.attachments, itemResponse],
            });
        } else {
            this.props.onEditorStateChanged({
                ...editorState,
                sections: editorState.sections.map((section, index) => {
                    if (index === sectionIndex) {
                        return {
                            ...section,
                            children: [...section.children, {
                                type: 'block',
                                item: itemResponse,
                                embed: this.state.addAsEmbed,
                            }],
                        };
                    } else {
                        return section;
                    }
                }),
            });
        }

        this.setState({ modalType: ModalType.None });
    }

    @bind private onNestedCreate(location: LocationDescriptor) {
        this.setState({ modalType: ModalType.None });
        if (this.props.onNestedCreate) {
            this.props.onNestedCreate(
                location,
                {
                    sectionId: this.state.selectedSectionIndex,
                    embed: this.state.addAsEmbed,
                }
            );
        }
    }

    @bind private onContentPickerTabChange(contentPickerTab: Tab) {
        this.setState({ contentPickerTab });
    }

    @bind private onCreateItemStepChange(createItemStep: CreateItemStep) {
        this.setState({ createItemStep });
    }

    @bind private onCreateItemSelectedItemTypeChange(createItemSelectedItemType: ItemType) {
        this.setState({ createItemSelectedItemType });
    }

    @bind moveAssetUp(sectionIndex: number, childIndex: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((section, index) => {
                if (index === sectionIndex && childIndex > 0) {
                    const children = section.children.slice();
                    children.splice(childIndex, 1);
                    children.splice(childIndex-1, 0, section.children[childIndex]);
                    return {...section, children};
                } else {
                    return section;
                }
            }),
        });
    }

    @bind moveAssetDown(sectionIndex: number, childIndex: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((section, i) => {
                if (i === sectionIndex && childIndex < section.children.length-1) {
                    const children = section.children.slice();
                    children.splice(childIndex, 1);
                    children.splice(childIndex+1, 0, section.children[childIndex]);
                    return {...section, children};
                } else {
                    return section;
                }
            }),
        });
    }

    @bind removeAsset(sectionIndex: number, childIndex: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((section, i) => {
                if (i === sectionIndex) {
                    const children = section.children.slice();
                    children.splice(childIndex, 1);
                    return {...section, children};
                } else {
                    return section;
                }
            }),
        });
    }

    @bind onTextAssetChange(sectionIndex: number, childIndex: number, inlinehtml: string) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((section, i) => {
                if (i === sectionIndex) {
                    const children = section.children.slice();
                    children[childIndex] = {
                        type: 'inline',
                        inlinehtml,
                    };
                    return {...section, children};
                } else {
                    return section;
                }
            }),
        });
    }

    @bind onAddTextBox(index: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((x, i) => {
                if (i === index) {
                    return {...x, children: [...x.children, {
                        type: 'inline',
                        inlinehtml: '',
                    }]};
                } else {
                    return x;
                }
            }),
        });
    }

    @bind onOpenContentPicker(sectionIndex: number, addAsEmbed: boolean) {
        this.setState({
            selectedSectionIndex: sectionIndex,
            modalType: ModalType.ContentPicker,
            addAsEmbed,
        });
    }

    @bind onChangeSectionTitle(index: number, title: string) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((x, i) => {
                if (i === index) {
                    return {...x, title};
                } else {
                    return x;
                }
            }),
        });
    }

    @bind toggleExpandSection(index: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((x, i) => {
                if (i === index) {
                    return {...x,
                        expanded: !x.expanded,
                    };
                } else {
                    return x;
                }
            }),
        });
    }

    @bind removeSection(index: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.filter((_, i) => i !== index),
        });
    }

    @bind private removeSectionAttachment(sectionIndex: number, childIndex: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.map((section, i) => {
                if (i === sectionIndex) {
                    return {
                        ...section,
                        children: section.children.filter((_, j) => j !== childIndex),
                    };
                } else {
                    return section;
                }
            }),
        });
    }

    @bind private removeAttachment(index: number) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            attachments: editorState.attachments.filter((_, i) => i !== index),
        });
    }

    @bind private onRequestAddAttachment() {
        this.setState({
            selectedSectionIndex: undefined,
            modalType: ModalType.ContentPicker,
        });
    }

    /**
     * This maps sections from editorState onto items
     * to be displayed in the ReorderableList.
     */
    private listSections(): ReorderableListItem[] {
        if (this.props.editorState === undefined) { return []; }

        const sections = [];
        for (let i = 0; i < this.props.editorState.sections.length; i++) {
            sections.push({
                key: i,
                html: this.renderSection(i),
            });
        }

        sections.push({
            key: 'placeholder',
            isPlaceholder: true,
            html: (
                <button
                    className='lxc-unit-another-btn add-section-btn'
                    onClick={this.onAddSection}
                >
                    <WrappedMessage message={messages.caseStudyEditorAddSectionLabel} />
                </button>
            ),
        });

        return sections;
    }

    @bind private onAddSection() {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }
        this.props.onEditorStateChanged({
            ...editorState,
            sections: editorState.sections.concat({
                title: '',
                expanded: true,
                children: [],
            }),
        });
    }
}

export class TeachingGuideBlockEditor extends CaseStudyBlockEditor {
    constructor(props: Props) {
        super(props);
        this.variant = Variant.TeachingGuide;
    }
}
