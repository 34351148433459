// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationsGeneralPermissions
 */
export interface OrganizationsGeneralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationsGeneralPermissions
     */
    canCreateOrganization: boolean;
}

export function OrganizationsGeneralPermissionsFromJSON(json: any): OrganizationsGeneralPermissions {
    return {
        'canCreateOrganization': json['can_create_organization'],
    };
}

export function OrganizationsGeneralPermissionsToJSON(value?: OrganizationsGeneralPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'can_create_organization': value.canCreateOrganization,
    };
}


