import React, { useState } from 'react';
import { intl } from 'i18n';
import { Icon } from '../../../../elements';
import { useLayoutSize, WrappedMessage } from '../../../../utils';
import messages from '../../../../career-explorer/displayMessages';
import classNames from 'classnames';
import PulseCard from '../../PulseCard';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';

interface CareerIntroductionProps {
    description: string;
    imageUrl?: string;
    pronunciation: string;
    pronunciationAudio?: string;
    alternativeNames: string[];
    videoUrl?:string;
}

const CareerIntroduction: React.FC<CareerIntroductionProps> = ({
 description,
 imageUrl,
 pronunciation,
 pronunciationAudio,
 alternativeNames,
 videoUrl,
}) => {

    const [showAllNames, setShowAllNames] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const isMobile = ['small', 'mobile'].includes(useLayoutSize());
    const isMobileOrTablet = ['small', 'mobile', 'medium'].includes(useLayoutSize());

    const handleShowMore = () => {
        setShowAllNames(!showAllNames);
    };

    const toggleAudio = () => {
        if (pronunciationAudio) {
            const audio = new Audio(pronunciationAudio);
            if (!isAudioPlaying) {
                audio.play();
                setIsAudioPlaying(true);
                audio.onended = () => setIsAudioPlaying(false);
            }
        }
    };

    const defaultCountToShow = 4;
    const displayedNames = (!isMobileOrTablet ? true : showAllNames)
        ? alternativeNames
        : alternativeNames?.slice(0, defaultCountToShow);

    const CareerIllustration = () => (
            <div className='image-container'>
                {videoUrl ?
                    <VideoPlayer youtubeUrl={videoUrl}/>
                    :
                    <img
                        className='career-icon'
                        alt={intl.formatMessage(messages.altTextForIllustration)}
                        src={imageUrl}
                    />
                }
            </div>
        );

    return (
        <div className='career-intro'>
            <h2> <WrappedMessage message={messages.careerIntroSectionHeading} /></h2>
            <div className={classNames({'career-introduction-desktop': !isMobileOrTablet })}>
                <div className={classNames('career-introduction', {'text-content': !isMobileOrTablet})}>
                    <p>{description}</p>

                    {isMobileOrTablet && imageUrl &&
                      <CareerIllustration />
                    }
                {pronunciation &&
                  <PulseCard
                    className={classNames('pronunciation-container', {'wd-50': !imageUrl && !isMobile})}
                    onClick={toggleAudio}
                  >
                    <button className='pronunciation' data-testid='pronunciation-button'>
                      <div className='pronunciation-icon'>
                        <Icon name={'volume-up'} fill={'white'} zoom='2em' />
                      </div>
                      <div className='pronunciation-description'>
                        <span className='pronunciation-region'>
                            <WrappedMessage message={messages.careerPronunciationAmericanText} />
                        </span>
                        <span className='pronunciation-text'>{pronunciation}</span>
                      </div>
                    </button>
                  </PulseCard>
                }
                </div>

                {!isMobileOrTablet && imageUrl &&
                  <div className={classNames({'image-content': !isMobileOrTablet})}>
                    <CareerIllustration />
                  </div>
                }
            </div>
            {alternativeNames &&
                <div className='alternative-names'>
                    <h4 className='alternative-names-heading'>
                        <WrappedMessage message={messages.careerAlternativeNamesText} />
                    </h4>
                    <p>
                        {displayedNames && displayedNames.join(', ')}
                        {((alternativeNames.length > defaultCountToShow) && isMobileOrTablet) &&  (
                            <>
                                {showAllNames ? (
                                    <button className='expand-button' onClick={handleShowMore}>
                                        <WrappedMessage message={messages.showLessNamesText} />
                                    </button>
                                ) : (
                                    <button className='expand-button' onClick={handleShowMore}>
                                        <WrappedMessage
                                            message={messages.showAllNamesText}
                                            values={{count: alternativeNames.length - defaultCountToShow}}
                                        />
                                    </button>
                                )}
                            </>
                        )}
                    </p>
                </div>
            }
        </div>
    );
};

export default CareerIntroduction;
