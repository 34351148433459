// main org profile page wrapper
import { AnalyticsApi, OrganizationsApi } from 'global/api';
import { AnalyticsEventEventTypeEnum } from 'labxchange-client';
import { ROUTES } from 'global/constants';
import { Organization, OrganizationOrganizationTypeEnum } from 'labxchange-client';
import * as React from 'react';
import { StandardPageLayout } from 'ui/components';
import { Button } from 'ui/components/Buttons';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import {Link} from 'react-router-dom';

import messages from './displayMessages';

interface State {
    loadingPartners: boolean;
    partners?: Organization[];
}

export class CollaboratorsPage extends React.PureComponent<{}, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingPartners: true,
        };
    }

    public async componentDidMount() {
        this.fetchPartners();
    }

    public render() {
        if (this.state.partners === undefined) {
            if (this.state.loadingPartners) {
                return (
                    <StandardPageLayout>
                        <p><WrappedMessage message={uiMessages.uiLoading}/></p>
                    </StandardPageLayout>
                );
            } else {
                return (
                    <StandardPageLayout>
                        <h1><WrappedMessage message={uiMessages.uiError}/></h1>
                        <p><WrappedMessage message={messages.loadFailed}/></p>
                    </StandardPageLayout>
                );
            }
        }

        const partners = this.state.partners.slice();
        const orgTypes = OrganizationOrganizationTypeEnum;
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={
                    <div className='collaborate-page-header'>
                        <h1><WrappedMessage message={messages.pageTitle}></WrappedMessage></h1>
                        <p><WrappedMessage message={messages.pageSubTitle}></WrappedMessage></p>
                    </div>
                }
            >
                <div className='collaborate-page'>
                    <div className='sponsors'>
                        <div className='section-header'>
                            <h2><WrappedMessage message={messages.sponsors}></WrappedMessage></h2>
                            <p><WrappedMessage message={messages.sponsorsSub}></WrappedMessage></p>
                        </div>
                        <ul>
                        {partners && partners.filter((org: Organization) =>
                            org.organizationType === orgTypes.SPONSOR
                        ).sort(this.sortByName).map((org: Organization) => (
                            <li key={org.slug}>
                                <Link
                                    tabIndex={0}
                                    to={ROUTES.Organizations.PROFILE_SLUG(org.slug)}
                                    onClick={() => this.onVisitOrgProfile(org.slug)}
                                >
                                    <div>
                                    {org.largeLogo ?
                                        <img src={org.largeLogo} alt={org.name} />
                                    :
                                        <span>{org.name}</span>
                                    }
                                    </div>
                                    <span><WrappedMessage message={messages.seeProfile}></WrappedMessage></span>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className='collaborators'>
                        <div className='section-header'>
                            <h2><WrappedMessage message={messages.strategicAllies}></WrappedMessage></h2>
                            <p><WrappedMessage message={messages.strategicAlliesSub}></WrappedMessage></p>
                        </div>
                        <ul>
                        {partners && partners.filter((org: Organization) =>
                            org.organizationType === orgTypes.STRATEGICALLY
                        ).sort(this.sortByName).map((org: Organization) => (
                            <li key={org.slug}>
                                <a tabIndex={0} target='_blank' rel='noopener noreferrer' href={org.url}>
                                    <div>
                                    {org.largeLogo ?
                                        <img src={org.largeLogo} alt={org.name} />
                                    :
                                        <span>{org.name}</span>
                                    }
                                    </div>
                                    <span><WrappedMessage message={messages.viewWebsite}></WrappedMessage></span>
                                </a>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className='collaborators-content'>
                        <div className='section-header'>
                            <h2><WrappedMessage message={messages.collaborators}></WrappedMessage></h2>
                            <p><WrappedMessage message={messages.collaboratorsSub}></WrappedMessage></p>
                        </div>
                        <ul>
                        {partners && partners.filter((org: Organization) => {
                            return org.organizationType === orgTypes.PARTNER;
                        }).sort(this.sortByName).map((org: Organization) => (
                            <li key={org.slug}>
                                <Link
                                    tabIndex={0}
                                    to={ROUTES.Organizations.PROFILE_SLUG(org.slug)}
                                    onClick={() => this.onVisitOrgProfile(org.slug)}
                                >
                                    <div>
                                    {org.largeLogo ?
                                        <img src={org.largeLogo} alt={org.name} />
                                    :
                                        <span>{org.name}</span>
                                    }
                                    </div>
                                    <span><WrappedMessage message={messages.seeProfile}></WrappedMessage></span>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className='collaborate-with-us'>
                        <Button btnStyle='normal' label={messages.collaborateWithUs} href='mailto:collaborate@labxchange.org'/>
                    </div>
                </div>
            </StandardPageLayout>
        );

    }

    private onVisitOrgProfile(slug: string){
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.OrganizationLabXchangeProfileVisited,
                orgSlug: slug,
                data: {
                    from: 'collaborators',
                    pathname: window.location.pathname,
                },
            },
        });
    }

    private async fetchPartners() {
        let partners: Organization[] = [];
        try {
            partners = await OrganizationsApi.listPartners();
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadFailed}/>, {exception: err});
        }

        this.setState({
            partners,
            loadingPartners: false,
        });
    }

    private sortByName(a: Organization, b: Organization) {
        if (a.name === undefined && b.name === undefined) {
            return 0;
        } else if (a.name === undefined) {
            return 1;
        } else if (b.name === undefined) {
            return -1;
        }

        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }
}
