import React, {useState} from 'react';
import {intl} from 'i18n';
import messages from './displayMessages';
import {CareerSearchBar} from '../CareerSearchBar';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import CollapsibleComponent, { CollapsibleSize } from '../CareerCollapsible/CollapsibleComponent';
import { IconSymbol } from '../../../elements';
import CareerTag from '../CareerTag';

interface SubjectAreas {
    Business: string[];
    'Computer science': string[];
    English: string[];
    'Family and Consumer Science': string[];
    'Foreign Language': string[];
    Math: string[];
    'Performing Arts': string[];
    'Physical Education': string[];
    Science: string[];
    'Social Studies': string[];
    'Visual Arts': string[];
    'Vocational Education': string[];
}

export const subjectAreaIconsMapper: Record<string, IconSymbol> = {
    'Business': 'subject-business',
    'Computer science': 'subject-computer-science',
    'English': 'subject-english',
    'Family and Consumer Science': 'subject-family',
    'Foreign Language': 'subject-foreign-language',
    'Math': 'subject-maths',
    'Performing Arts': 'subject-performing-arts',
    'Physical Education': 'subject-physical-education',
    'Science':'subject-science',
    'Social Studies': 'subject-social-studies',
    'Visual Arts': 'subject-visual-arts',
    'Vocational Education':'subject-vocational-education'
};

export const subjectAreas: SubjectAreas = {
    'Business': [
        'Accounting', 'Business management', 'Business law', 'Consumer education',
        'Entrepreneurial skills', 'Introduction to business', 'Marketing', 'Personal finance'
    ],
    'Computer science': [
        'Animation', 'App development', 'Audio production', 'Computer programming', 'Computer repair',
        'Film production', 'Graphic design', 'Media technology', 'Music production', 'Typing',
        'Video game development', 'Word processing', 'Web design', 'Web programming'
    ],
    'English': [
        'American literature', 'British literature', 'Contemporary literature', 'Creative writing',
        'Communication skills', 'Debate', 'English language and composition', 'English literature and composition',
        'Humanities', 'Rhetoric', 'Literary analysis', 'Poetry', 'Journalism', 'Modern literature',
        'Popular literature', 'Technical writing', 'Works of Shakespeare', 'World literature', 'Written and oral communication'
    ],
    'Family and Consumer Science': [
        'Chemistry of foods', 'CPR training', 'Culinary arts', 'Early childhood development',
        'Early childhood education', 'Family studies', 'Fashion and retail merchandising',
        'Fashion construction', 'Home economics', 'Interior design', 'Nutrition'
    ],
    'Foreign Language': [
        'American Sign Language', 'Ancient Greek', 'Arabic',
        'Chinese', 'French', 'German', 'Hebrew', 'Italian',
        'Japanese', 'Korean', 'Latin', 'Portuguese', 'Russian', 'Spanish'
    ],
    'Math': [
        'Algebra 1', 'Algebra 2', 'Calculus', 'Computer math', 'Consumer math',
        'Fundamentals of math', 'Geometry', 'Integrated math', 'Math applications',
        'Multivariable calculus', 'Practical math', 'Pre-algebra', 'Pre-calculus',
        'Probability', 'Quantitative literacy', 'Statistics', 'Trigonometry'
    ],
    'Performing Arts': [
        'Choir', 'Concert band', 'Dance', 'Drama', 'Guitar', 'Jazz band',
        'Marching band', 'Music theory', 'Orchestra', 'Percussion', 'Piano',
        'Theater technology', 'World music'
    ],
    'Physical Education': [
        'Aerobics', 'Dance', 'Gymnastics', 'Health', 'Lifeguard training',
        'Pilates', 'Racket sports', 'Specialized sports', 'Swimming', 'Weight training',
        'Yoga'
    ],
    'Science': [
        'Agriculture', 'Astronomy', 'Biology', 'Botany', 'Chemistry', 'Earth science',
        'Electronics', 'Environmental science', 'Environmental studies', 'Forensic science',
        'Geology', 'Marine biology'
    ],
    'Social Studies': [
        'Cultural anthropology', 'Current events', 'European history', 'Geography',
        'Global studies', 'Human geography', 'International relations', 'Law',
        'Macroeconomics', 'Microeconomics', 'Modern world studies', 'Psychology',
        'Political studies', 'Religious studies', 'Physical anthropology', 'Sociology', 'US government',
        'US history', 'World history', 'Women\'s studies', 'World politics', 'World religions'
    ],
    'Visual Arts': [
        '3-D art', 'Art history', 'Ceramics', 'Digital media', 'Drawing', 'Film production',
        'Jewelry design', 'Painting', 'Photography', 'Printmaking', 'Sculpture'
    ],
    'Vocational Education': [
        'Auto body repair', 'Auto mechanics', 'Building construction', 'Cosmetology',
        'Computer-aided drafting', 'Criminal justice', 'Driver education', 'Electronics',
        'Plumbing', 'FFA (Future Farmers of America)', 'Robotics', 'Fire science',
        'Heating and cooling systems', 'Hospitality and tourism', 'Metalworking',
        'JROTC (Junior Reserve Officers\' Training Corps)', 'Networking',
        'Production technology', 'Refrigeration fundamentals', 'Woodworking'
    ]

};

export interface SubjectsStep {
    currentStep: number;
    onButtonClick: (nextStep: number) => void;
}

export const SubjectsStep: React.FC<SubjectsStep> = ({currentStep, onButtonClick}) => {

    const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const searchSubjects = (query: string) => {
        setSearchQuery(query);
    };

    const toggleSubjectSelection = (subject: string) => {
        setSelectedSubjects((prevSelected) =>
            prevSelected.includes(subject)
                ? prevSelected.filter((selected) => selected !== subject)
                : [...prevSelected, subject]
        );
    };

    const filteredSubjects = (subjects: string[]) => {
        if (!searchQuery) return subjects;
        return subjects.filter((subject) =>
            subject.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <OnboardingPageLayout
            title={intl.formatMessage(messages.careerSubjectStepTitle)}
            description={intl.formatMessage(messages.careerSubjectStepDescription)}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedSubjects.length !== 0}
        >
            <CareerSearchBar onSubmit={searchSubjects} viewType='other' />
            <div className='subject-area-container'>
                <div className='selected-subjects'>
                    {selectedSubjects.map((subject: string, index: number) => (
                        <div key={index} className='selected-subject'>
                            {subject}
                            <button onClick={() => toggleSubjectSelection(subject)}>&times;</button>
                        </div>
                    ))}
                </div>

                <div className='subject-sections'>
                    {Object.keys(subjectAreas).map((area) => (
                        <CollapsibleComponent
                            title={area}
                            headerIcon={subjectAreaIconsMapper[area]}
                            size={CollapsibleSize.large}
                        >
                            <div className='subject-section__list'>
                                {filteredSubjects(subjectAreas[area as keyof SubjectAreas]).map(
                                    (subject: string, index: number) => (
                                        <CareerTag
                                            key={index}
                                            cssClass={`subject-section__list__item ${
                                                selectedSubjects.includes(subject)
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() => toggleSubjectSelection(subject)}
                                        >
                                            {subject}
                                        </CareerTag>
                                    )
                                )}
                            </div>
                        </CollapsibleComponent>
                    ))}
                </div>
            </div>
        </OnboardingPageLayout>
    );
};

export default SubjectsStep;
