import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { showErrorMessage, Icon } from 'ui/components';
import { ChoiceResponse, GroupFeedback, ViewData, IncrementalHints, Submit, SpecificFeedback, } from './question-block-shared';
import { XBlocksApi } from 'global/api';
import { RichText } from 'elements';
import { sanitizeConfigOptions } from '@labxchange/util-sanitization';

interface Props {
    itemId: string;
    data: ViewData<ChoiceResponse>;
    onUpdateData: (data: ViewData<ChoiceResponse>) => void;
}

interface State {
    isAnswering: boolean;
    submitting: boolean;
    isTryAgain: boolean;
    currentHintIndex: number;
}

export class QuestionChoiceResponseBlock extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isAnswering: false,
            submitting: false,
            isTryAgain: props.data.studentAttempts !== 0,
            currentHintIndex: -1
        };
    }

    private async onSubmit() {
        this.setState({ submitting: true, isAnswering: false });

        const selected = this.props.data.questionData.choices
            .map((x, i) => x.checked ? i : -1)
            .filter((x) => x > -1);

        let data: ViewData<ChoiceResponse>;
        try {
            const response = await XBlocksApi.submitQuestionAnswer({
                id: this.props.itemId,
                data: {
                    selected,
                },
            });
            // parse because GenericObjectSerializer
            data = JSON.parse(response);
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorSubmittingAnswer} />,
                { exception: err }
            );
            this.setState({ submitting: false });
            return;
        }

        this.props.onUpdateData(data);
        this.setState({ submitting: false, isTryAgain: true});
    }

    private onTryAgain() {
        this.props.onUpdateData({
            ...this.props.data,
            correct: undefined,
            questionData: {
                ...this.props.data.questionData,
                studentAnswer: {},
                choices: this.props.data.questionData.choices.map((choice, idx) => (
                    {
                        ...choice,
                        checked: false,
                        comment: ''
                    }
                )),
            }
        });
        this.setState({isTryAgain: false});
    }

    private onGetHint() {
        if (this.state.currentHintIndex < this.props.data.hints.length - 1) {
            this.setState({currentHintIndex: this.state.currentHintIndex + 1});
        }
    }

    public render() {
        const data = this.props.data;
        return (
            <div className='question-content'>
                {this.renderQuestion()}
                <IncrementalHints hints={data.hints} currentHintIndex={this.state.currentHintIndex} />
                <Submit
                    hidden={data.correct || (data.maxAttempts > 0 && data.studentAttempts >= data.maxAttempts)}
                    isTryAgain={this.state.isTryAgain}
                    disabled={this.state.submitting}
                    maxAttempts={data.maxAttempts}
                    usedAttempts={data.studentAttempts}
                    onSubmit={() => this.state.isTryAgain ? this.onTryAgain() : this.onSubmit()}
                    showHintButton={data.correct===false && this.state.currentHintIndex+1<data.hints.length}
                    onGetHint={() => this.onGetHint()}
                />
            </div>
        );
    }

    private renderQuestion(): React.ReactNode {
        const qData = this.props.data.questionData;
        const id = `${this.props.itemId}-legend`;
        return <>
            <fieldset className='question-question' aria-describedby={id}>
                <legend id={id}>
                    <RichText
                        innerHtml={qData.question}
                        sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                        renderMath={true}
                        renderCode={true}
                    />
                </legend>
                {this.renderCheckboxes()}
            </fieldset>
            <GroupFeedback
              correct={this.props.data.correct}
              comment={this.props.data.questionData.comment}
              type={this.props.data.questionData.type}
            />
        </>;
    }

    private clearCheckboxes(i?: number) {
        this.props.onUpdateData({
            ...this.props.data,
            questionData: {
                ...this.props.data.questionData,
                studentAnswer: {},
                choices: this.props.data.questionData.choices.map((choice, idx) => (
                    {
                        ...choice,
                        checked: idx === i ? true : false,
                    }
                )),
            }
        });
    }

    private onOptionSelect(i: number) {
        if (this.props.data.correct) { return; }
        if (!this.state.isAnswering) {
            this.setState({ isAnswering: true, isTryAgain: false });
            this.clearCheckboxes(i);
            return;
        }
        this.props.onUpdateData({
            ...this.props.data,
            questionData: {
                ...this.props.data.questionData,
                choices: this.props.data.questionData.choices.map((choice, idx) => (
                    {
                        ...choice,
                        checked: idx === i ? !choice.checked : choice.checked,
                    }
                )),
            },
        });
    }

    private renderCheckboxes(): React.ReactNode {
        const data = this.props.data;
        return (
            data.questionData.choices.map((option, i) => {
                const id = `${this.props.itemId}-option-${i}`;
                const isAnswer = (data.questionData.studentAnswer.selected ?? []).includes(i);
                const isCorrect = (data.questionData.studentAnswer.selected_and_correct ?? []).includes(i);

                let extraClass;
                let icon;
                // data.correct is saving if overall question result is correct or not while
                // isCorrect is saving if this option is a part of answer. It will be helpful
                // when user submits partially corrected answer.
                if (isAnswer && (data.correct === true || (data.correct === false && isCorrect===true))) {
                    extraClass = 'correct';
                    icon = <Icon fill='white' zoom='28px' name='check' />;
                } else if (isAnswer && data.correct === false) {
                    extraClass = 'incorrect';
                    icon = <Icon fill='white' zoom='28px' name='x' />;
                } else {
                    icon = String.fromCharCode((i % 26) + 65);
                    extraClass = 'unchecked';
                }

                return (
                    <>
                        <div className={`multi field ${extraClass}`}>
                            <input
                                id={id}
                                type='checkbox'
                                value={i}
                                name={`${this.props.itemId}-choices`}
                                checked={option.checked}
                                onChange={() => this.onOptionSelect(i)}
                            />
                            <label htmlFor={id}>
                                <span className='i'>{icon}</span>
                                <span>
                                    <RichText
                                        innerHtml={option.content}
                                        sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                                        renderMath={true}
                                        renderCode={true}
                                    />
                                </span>
                            </label>
                        </div>
                        <SpecificFeedback
                          correct={isCorrect}
                          comment={option.comment}
                        />
                    </>
                );
            })
        );
    }
}
