// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotificationCount,
    NotificationCountFromJSON,
    NotificationCountToJSON,
    NotificationResponse,
    NotificationResponseFromJSON,
    NotificationResponseToJSON,
    NotificationSettings,
    NotificationSettingsFromJSON,
    NotificationSettingsToJSON,
    NotificationSettingsUpdate,
    NotificationSettingsUpdateFromJSON,
    NotificationSettingsUpdateToJSON,
} from '../models';

export interface DeleteRequest {
    id: string;
}

export interface MarkReadRequest {
    id: string;
}

export interface MarkUnreadRequest {
    id: string;
}

export interface UpdateSettingsRequest {
    data: NotificationSettingsUpdate;
}

/**
 * no description
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Delete a specific notification for the requesting user.
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling _delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific notification for the requesting user.
     */
    async _delete(requestParameters: DeleteRequest): Promise<void> {
        await this._deleteRaw(requestParameters);
    }

    /**
     * Return a count of all and unread notifications for the requesting user.
     */
    async countRaw(): Promise<runtime.ApiResponse<NotificationCount>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCountFromJSON(jsonValue));
    }

    /**
     * Return a count of all and unread notifications for the requesting user.
     */
    async count(): Promise<NotificationCount> {
        const response = await this.countRaw();
        return await response.value();
    }

    /**
     * Return notification settings for the current user.
     */
    async getSettingsRaw(): Promise<runtime.ApiResponse<NotificationSettings>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/get_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationSettingsFromJSON(jsonValue));
    }

    /**
     * Return notification settings for the current user.
     */
    async getSettings(): Promise<NotificationSettings> {
        const response = await this.getSettingsRaw();
        return await response.value();
    }

    /**
     * List all notifications for the requesting user.
     */
    async listRaw(): Promise<runtime.ApiResponse<NotificationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationResponseFromJSON(jsonValue));
    }

    /**
     * List all notifications for the requesting user.
     */
    async list(): Promise<NotificationResponse> {
        const response = await this.listRaw();
        return await response.value();
    }

    /**
     * List unread notifications for the requesting user.
     */
    async listUnreadRaw(): Promise<runtime.ApiResponse<NotificationResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/list_unread`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationResponseFromJSON(jsonValue));
    }

    /**
     * List unread notifications for the requesting user.
     */
    async listUnread(): Promise<NotificationResponse> {
        const response = await this.listUnreadRaw();
        return await response.value();
    }

    /**
     * Mark all notifications for the requesting user as read.
     */
    async markAllReadRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/mark_all_read`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark all notifications for the requesting user as read.
     */
    async markAllRead(): Promise<void> {
        await this.markAllReadRaw();
    }

    /**
     * Mark a specific notification for the requesting user as read.
     */
    async markReadRaw(requestParameters: MarkReadRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/{id}/mark_read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark a specific notification for the requesting user as read.
     */
    async markRead(requestParameters: MarkReadRequest): Promise<void> {
        await this.markReadRaw(requestParameters);
    }

    /**
     * Mark a specific notification for the requesting user as unread.
     */
    async markUnreadRaw(requestParameters: MarkUnreadRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markUnread.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/{id}/mark_unread`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark a specific notification for the requesting user as unread.
     */
    async markUnread(requestParameters: MarkUnreadRequest): Promise<void> {
        await this.markUnreadRaw(requestParameters);
    }

    /**
     * Return notification settings for the current user.
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/notifications/update_settings`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsUpdateToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Return notification settings for the current user.
     */
    async updateSettings(requestParameters: UpdateSettingsRequest): Promise<any> {
        const response = await this.updateSettingsRaw(requestParameters);
        return await response.value();
    }

}
