// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerDayActivity
 */
export interface CareerDayActivity {
    /**
     * 
     * @type {string}
     * @memberof CareerDayActivity
     */
    activityName: string;
    /**
     * Time when the activity takes place.
     * @type {string}
     * @memberof CareerDayActivity
     */
    activityTime: CareerDayActivityActivityTimeEnum;
    /**
     * Image representing the activity.
     * @type {string}
     * @memberof CareerDayActivity
     */
    readonly activityImage?: string;
    /**
     * Optional description of the activity.
     * @type {string}
     * @memberof CareerDayActivity
     */
    description?: string;
}

export function CareerDayActivityFromJSON(json: any): CareerDayActivity {
    return {
        'activityName': json['activity_name'],
        'activityTime': json['activity_time'],
        'activityImage': !exists(json, 'activity_image') ? undefined : json['activity_image'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CareerDayActivityToJSON(value?: CareerDayActivity): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'activity_name': value.activityName,
        'activity_time': value.activityTime,
        'description': value.description,
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerDayActivityActivityTimeEnum {
    _000000 = '00:00:00',
    _003000 = '00:30:00',
    _010000 = '01:00:00',
    _013000 = '01:30:00',
    _020000 = '02:00:00',
    _023000 = '02:30:00',
    _030000 = '03:00:00',
    _033000 = '03:30:00',
    _040000 = '04:00:00',
    _043000 = '04:30:00',
    _050000 = '05:00:00',
    _053000 = '05:30:00',
    _060000 = '06:00:00',
    _063000 = '06:30:00',
    _070000 = '07:00:00',
    _073000 = '07:30:00',
    _080000 = '08:00:00',
    _083000 = '08:30:00',
    _090000 = '09:00:00',
    _093000 = '09:30:00',
    _36000 = '36000',
    _37800 = '37800',
    _39600 = '39600',
    _41400 = '41400',
    _43200 = '43200',
    _45000 = '45000',
    _46800 = '46800',
    _48600 = '48600',
    _50400 = '50400',
    _52200 = '52200',
    _54000 = '54000',
    _55800 = '55800',
    _57600 = '57600',
    _59400 = '59400',
    _61200 = '61200',
    _63000 = '63000',
    _64800 = '64800',
    _66600 = '66600',
    _68400 = '68400',
    _70200 = '70200',
    _72000 = '72000',
    _73800 = '73800',
    _75600 = '75600',
    _77400 = '77400',
    _79200 = '79200',
    _81000 = '81000',
    _82800 = '82800',
    _84600 = '84600'
}


