// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Initiative
 */
export interface Initiative {
    /**
     * 
     * @type {string}
     * @memberof Initiative
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Initiative
     */
    readonly redirectUrl?: string;
}

export function InitiativeFromJSON(json: any): Initiative {
    return {
        'url': json['url'],
        'redirectUrl': !exists(json, 'redirect_url') ? undefined : json['redirect_url'],
    };
}

export function InitiativeToJSON(value?: Initiative): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
    };
}


