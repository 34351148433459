import bind from 'bind-decorator';
import { LearningApi } from 'global/api';
import { isKeyboardEnterEvent } from 'global/utils';
import { getItemTypeMeta } from 'items/models';
import { TotalScoreItem } from 'labxchange-client';
import { ItemIcon } from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import moment from 'moment-shortformat';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { Link } from 'react-router-dom';
import ReactTable, { Column } from 'react-table';
import { UI_IS_XS } from 'ui/breakpoints';
import { Button, Icon, JumpTo, Modal, Pagination, showErrorMessage } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';

interface State {
    items: TotalScoreItem[];
    totalScore: number;
    currentPage: number;
    pageCount: number;
    showModal: boolean;
    isMobileView: boolean;
    showItemModal: boolean;
    modalItem?: TotalScoreItem;
}

export class ScoresWidget extends React.PureComponent<{}, State> {
    private defaultPage = 1;
    private defaultPageSize = 5;
    private defaultModalPageSize = 20;
    private maxScore = 5;
    private mediaQueryMobile = UI_IS_XS;

    constructor(props: {}) {
        super(props);
        this.state = {
            items: [],
            totalScore: 0,
            currentPage: this.defaultPage,
            pageCount: 0,
            showModal: false,
            isMobileView: false,
            showItemModal: false,
        };
    }

    public componentDidMount() {
        this.mediaQueryMobile.addListener(this.setIsMobileView);
        this.setIsMobileView();
        this.loadItems(this.defaultPage, this.defaultPageSize);
    }

    public componentWillUnmount() {
        this.mediaQueryMobile.removeListener(this.setIsMobileView);
    }

    public render() {
        return (
            <div className='learner-progress-scores-widget'>
                <h2>
                    <WrappedMessage
                        message={messages.learnerProgressScoresTitle}
                        values={{score: this.state.totalScore}}
                    />
                </h2>
                {this.renderContent(false)}
                <Button
                    btnStyle='link'
                    label={messages.learnerProgressScoresSeeAll}
                    onClick={() => {
                        this.setState({showModal: !this.state.showModal});
                        this.loadItems(
                            this.defaultPage,
                            this.defaultModalPageSize,
                        );
                    }}
                />
                {this.renderModal()}
                {this.renderItemModal()}
            </div>
        );
    }

    @bind private setIsMobileView() {
        this.setState({isMobileView: this.mediaQueryMobile.matches});
    }

    private renderContent(asModal: boolean) {
        if (this.state.isMobileView) {
            const itemsList = this.state.items.map((item) =>
                <li key={item.item.id}>
                    <button
                        className='unstyled'
                        onClick={
                            () => this.setState({
                                showItemModal: true,
                                modalItem: item,
                            })
                        }>
                        <span className='score-completion-date'>
                            {moment(item.completionDate).format('MMM D, YYYY')}
                        </span>
                        <span className='score-title'>
                            {item.item.title}
                            <Icon name='chevron-right'/>
                        </span>
                        <span className='score-score'>
                            Score: {this.renderScore(item)}
                        </span>
                    </button>
                </li>,
            );
            return <ol className='learner-progress-scores-widget-mobile-list'>{itemsList}</ol>;
        } else {
            return (
                <ReactTable
                    columns={this.getHeaders()}
                    data={this.state.items.slice(0, asModal ? this.state.items.length : this.defaultPageSize)}
                    showPaginationBottom={false}
                    defaultSorted={[{id: 'itemCompletionDate', desc: true}]}
                    defaultPageSize={
                        this.state.showModal
                        ? this.defaultModalPageSize
                        : this.defaultPageSize
                    }
                    minRows={this.defaultPageSize}
                />
            );
        }
    }

    private renderItemModal() {
        if (
            !this.state.showItemModal ||
            this.state.modalItem === undefined) { return; }
        const item = this.state.modalItem;
        return <Modal
            size='max'
            showTopBar={true}
            contentPadding={0}
            title={<>{item.item.title}</>}
            showBackButton={true}
            onRequestBack={this.onCloseItemModal}
            onRequestClose={this.onCloseItemModal}
            content={<>
                <dl className='learner-progress-scores-widget-item-modal'>
                    <dt>
                        <WrappedMessage message={
                            messages.learnerProgressScoresItemModalCompleted
                        }/>:
                    </dt>
                    <dd>
                        {moment(item.completionDate).format('MM.DD.YYYY')}
                    </dd>
                    <dt>
                        <WrappedMessage message={
                            messages.learnerProgressScoresItemModalContentType
                        }/>:
                    </dt>
                    <dd>{this.renderContentType(item)}</dd>
                    <dt>
                        <WrappedMessage message={
                            messages.learnerProgressScoresItemModalTotalScore
                        }/>:
                    </dt>
                    <dd>{this.renderScore(item)}</dd>
                </dl>
                <Link
                    target='_blank'
                    to={detailUrlForEntity(item.item)}>
                    <ItemIcon itemType={item.item.type}/>
                    See {this.renderContentType(item)}
                </Link>
            </>}
        />;
    }

    @bind private onCloseItemModal() {
        this.setState({
            showItemModal: false,
            modalItem: undefined,
        });
    }

    private renderModal() {
        if (!this.state.showModal) { return; }
        return <Modal
            size='max'
            showTopBar={true}
            contentPadding={0}
            title={
                <WrappedMessage
                    message={messages.learnerProgressScoresTitle}
                    values={{score: this.state.totalScore}}
                />
            }
            contentScrolls={true}
            onRequestClose={() => this.setState({
                showModal: false,
                currentPage: this.defaultPage,
            })}
            content={
                <div className={
                        `learner-progress-scores-widget ` +
                        `learner-progress-scores-widget-modal`}>
                    {this.renderContent(true)}
                    <div className='learner-progress-scores-widget-modal-pagination'>
                        <JumpTo
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            onPageSelect={this.onPageSelect}
                        />
                        <Pagination
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            onPageSelect={this.onPageSelect}
                        />
                    </div>
                </div>
            }
        />;
    }

    private sortableHeader(label: React.ReactNode,
                           sortTitle: MessageDescriptor,
                           sortTitleValues?: Record<string, string>,
        ): React.ReactNode {
        return (
            <div
                className='sortable-column'
                title={intl.formatMessage(sortTitle, sortTitleValues)}
                role='button' tabIndex={0} onKeyDown={this.sortColumn}
            >
                <div className='sort-label'>{label}</div>
                <div className='sort-icons'></div>
            </div>
        );
    }

    @bind private onPageSelect(page: number) {
        this.loadItems(
            page,
            this.defaultModalPageSize,
        );
    }

    @bind private sortColumn(event: React.KeyboardEvent<HTMLDivElement>) {
        // Adds keyboard control of sortable columns by passing keyboard events on labels
        // through to the parent elements for action by ReactTable.
        if (isKeyboardEnterEvent(event) && event.currentTarget.parentElement) {
            event.currentTarget.parentElement.click();
        }
    }

    private getHeaders(): Column[] {
        const nameHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage message={messages.learnerProgressScoresTitleHeader}/>,
                messages.learnerProgressScoresTitleHeaderSort,
            ),
            accessor: 'item.title',
            Cell: (cellInfo) => this.renderTitle(cellInfo.original as TotalScoreItem),
            id: 'itemTitle',
            className: 'title',
            headerClassName: 'sticky sortable initial-sort-asc',
            sortable: true,
            resizable: false,
        };
        const contentTypeHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage
                    message={messages.learnerProgressScoresContentTypeHeader}
                />,
                messages.learnerProgressScoresContentTypeHeaderSort,
            ),
            accessor: 'item.type',
            Cell: (cellInfo) => this.renderContentType(cellInfo.original as TotalScoreItem),
            id: 'itemContentType',
            headerClassName: 'sortable',
            sortable: true,
            resizable: false,
        };
        const completionDateHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage
                    message={messages.learnerProgressScoresDateHeader}
                />,
                messages.learnerProgressScoresDateHeaderSort,
            ),
            accessor: 'completionDate',
            Cell: (cellInfo) => this.renderCompletionDate(cellInfo.original as TotalScoreItem),
            id: 'itemCompletionDate',
            headerClassName: 'sortable',
            sortable: true,
            resizable: false,
            width: 110,
        };
        const scoreHeader: Column = {
            Header: this.sortableHeader(
                <WrappedMessage
                    message={messages.learnerProgressScoresScoreHeader}
                />,
                messages.learnerProgressScoresScoreHeaderSort,
            ),
            className: 'score',
            accessor: (item) => this.getScore(item.weightedEarned, item.weightedPossible),
            Cell: (cellInfo) => this.renderScore(cellInfo.original as TotalScoreItem),
            id: 'itemScore',
            headerClassName: 'sortable',
            sortable: true,
            resizable: false,
            width: 70,
        };
        return [
            nameHeader,
            contentTypeHeader,
            completionDateHeader,
            scoreHeader,
        ];
    }

    private renderTitle(item: TotalScoreItem): React.ReactNode {
        return (<>
            <Link
                target='_blank'
                to={detailUrlForEntity(item.item)}>
                <ItemIcon itemType={item.item.type}/>
                {item.item.title}
            </Link>
        </>);
    }

    private renderCompletionDate(item: TotalScoreItem): React.ReactNode {
        return (<>{moment(item.completionDate).format('MMM D, YYYY')}</> );
    }

    private renderScore(item: TotalScoreItem): React.ReactNode {
        const score = this.getScore(item.weightedEarned, item.weightedPossible);
        return (<>{score}/{this.maxScore}</>);
    }

    private renderContentType(item: TotalScoreItem): React.ReactNode {
        const message = getItemTypeMeta(item.item.type).name;
        return (
            <span>
                <WrappedMessage
                    message={message}
                    values={{count: item.item.itemCount}}/>
            </span>
        );
    }

    private getScore(weightedEarned: number|null, weightedPossible: number|null): number {
        if (weightedEarned === null || weightedPossible === null) {
            return this.maxScore;
        } else {
            return (weightedEarned / weightedPossible) * this.maxScore;
        }
    }

    private async loadItems(page: number, paginationSize: number) {
        try {
            const filters = {page, paginationSize};
            const response = await LearningApi.progressTotalScore(filters);
            this.setState({
                items: response.items,
                pageCount: Math.ceil(response.count / paginationSize),
                currentPage: response.page,
                totalScore: response.totalScore,
            });
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.loadingError}/>,
                {exception: err},
            );
        }
    }
}
