// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    SearchTitle,
    SearchTitleFromJSON,
    SearchTitleToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchTitleResponse
 */
export interface SearchTitleResponse {
    /**
     * 
     * @type {Array<SearchTitle>}
     * @memberof SearchTitleResponse
     */
    results: Array<SearchTitle>;
}

export function SearchTitleResponseFromJSON(json: any): SearchTitleResponse {
    return {
        'results': (json['results'] as Array<any>).map(SearchTitleFromJSON),
    };
}

export function SearchTitleResponseToJSON(value?: SearchTitleResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(SearchTitleToJSON),
    };
}


