import * as React from 'react';
import { Link } from 'react-router-dom';

import {
    Cluster,
    ItemResponse,
    ClusterItem,
    ItemMetadataTypeEnum,
    OrganizationSummary,
    OrganizationSummaryOrganizationTypeEnum
} from 'labxchange-client';
import { getItemTypeMeta } from 'items/models';
import { ItemIcon } from 'library/components';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import { Button } from 'ui/components';
import { ROUTES } from 'global/constants';
import { detailUrlForEntity } from 'library/utils';

import messages from '../../displayMessages';

export interface ClusterCardProps {
    cluster: ItemResponse;
    clusterDetails: Cluster;
    onView(clusterId: string): void;
}

const ITEMS_TO_RENDER = 3;

export const ClusterCard: React.FC<ClusterCardProps> = (props) => {
    const cluster = props.cluster.metadata;
    const itemsNotRendered = props.clusterDetails
        ? props.clusterDetails.items.length - ITEMS_TO_RENDER
        : 0;
    const moreCount = Math.max(itemsNotRendered, 0);

    let sourceOrg = null;
    if (cluster.source && cluster.source.sourceOrganizations) {
        sourceOrg = cluster.source.sourceOrganizations[0].organization;
    }

    const renderItemTypeInfo = (item: ClusterItem) => {
        if (true) {
            return (
                <div className='media-info'>
                    <ItemIcon itemType={item.item.metadata.type} zoom='14'/>
                    <span>
                        <WrappedMessage
                            message={getItemTypeMeta(item.item.metadata.type).name}
                            values={{count: item.item.metadata.itemCount || 0}}
                        />
                    </span>
                </div>
            );
        }
    };

    const renderItem = (item: ClusterItem, index: number) => {
        const itemLink = detailUrlForEntity(item.item.metadata);
        return (
            <Link
                to={itemLink}
                key={index}
                className='cluster-content-row'
            >
                <span className='item-title'>
                    {item.item.metadata.title}
                </span>
                <div className='media-info-wrapper'>
                    {renderItemTypeInfo(item)}
                </div>
            </Link>
        );
    };

    const renderEmptyItem = (index: number) => {
        return (
            <div key={index} className='cluster-content-row hidden'>
                <span className='item-title'>
                    Filler text
                </span>
                <div className='media-info-wrapper'>
                    <div className='media-info'>
                        <ItemIcon itemType={ItemMetadataTypeEnum.Simulation} zoom='14'/>
                        <span>
                            <WrappedMessage
                                message={getItemTypeMeta(ItemMetadataTypeEnum.Simulation).name}
                            />
                        </span>
                </div>
                </div>
            </div>
        );
    };

    const renderItemsCards = (items: ClusterItem[]) => {
        const itemsToRender = items.slice(0, ITEMS_TO_RENDER);
        const emptyItemsCount = ITEMS_TO_RENDER - itemsToRender.length;
        const finalItems = [...itemsToRender, ...Array(emptyItemsCount).fill(null)];

        return finalItems.map((item, index) => {
            if (item) {
                return renderItem(item, index);
            } else {
                return renderEmptyItem(index);
            }
        });
    };

    const providerLink = (content: any, org: OrganizationSummary) => {
        let orgIsPartner = false;
        let orgUrl = '#';

        if (
            org.organizationType ===
                OrganizationSummaryOrganizationTypeEnum.SPONSOR ||
            org.organizationType ===
                OrganizationSummaryOrganizationTypeEnum.PARTNER
        ) {
            orgUrl = ROUTES.Organizations.PROFILE_SLUG(org.slug);
            orgIsPartner = true;
        } else if (org.url) {
            orgUrl = org.url;
            orgIsPartner = false;
        }

        if (orgIsPartner) {
            return (
                <Link to={orgUrl} className='item-source-provider-url'>
                    {content}
                </Link>
            );
        } else {
            return (
                <a
                    href={orgUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='item-source-provider-url'
                >
                    {content}
                </a>
            );
        }
    };

    return (
        <div className='cluster-card'>
            <Link
                to={ROUTES.Library.CLUSTER_SLUG(cluster.id)}
                className='left-section'
                style={{ backgroundImage: `url('${cluster.imageUrl}')` }}
            >
                <div className='left-section-content'>
                    <div className='cluster-title'>
                        <h3>{cluster.title}</h3>
                    </div>
                    {/* Disabling linter for next line because the div is used to stop event propagation */}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div className='provider-info' onClick={(e) => e.stopPropagation()}>
                        {sourceOrg ?
                            providerLink(
                                <>
                                    <img
                                        className='cluster-note-avatar rounded-circle'
                                        src={sourceOrg.logoUrl}
                                        style={{backgroundColor: sourceOrg.brandColor}}
                                        alt=''
                                    />
                                    <h4>{sourceOrg.name}</h4>
                                </>,
                                sourceOrg
                            )
                            : cluster.authors.length > 0 && cluster.authors[0].username ?
                                <div>
                                    <UserAvatar
                                        username={cluster.authors[0].username}
                                        diameter='36px'
                                    />
                                    <h4>{cluster.authors[0].fullName}</h4>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </Link>
            {props.clusterDetails ?
                <div className='right-section'>
                    <div className='right-section-content'>
                        <h4><WrappedMessage message={messages.clustersCardsCovers}/></h4>
                        {renderItemsCards(props.clusterDetails.items)}
                        <div className={moreCount > 0 ? 'more-count' : 'more-count transparent'}>
                            <span>+{moreCount} more</span>
                        </div>
                        <div className='action-buttons'>
                            <Button
                                className='view-button'
                                btnStyle='primary'
                                label={messages.clustersCardsExploreButton}
                                onClick={() => props.onView(cluster.id)}
                            />
                        </div>
                    </div>
                </div>
            : null}
        </div>
    );
};

export default ClusterCard;