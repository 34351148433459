import { bind } from 'bind-decorator';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MessageDescriptor } from 'react-intl';

import { authLocalStorageManager } from 'auth/utils';
import { ROUTES } from 'global/constants';
import { clipboardCopy, isMouseOrEnterEvent } from 'global/utils';
import { WrappedMessage } from 'utils';
import {Icon, IconSymbol} from '../Icons';
import Skeleton from 'react-loading-skeleton';

export interface LinkButtonProps {
    title: string;
    onClick: () => void;
}

export const LinkButton: React.FunctionComponent<LinkButtonProps> = (props) => {
    return (
        <a href='/' onClick={(e) => {
            e.preventDefault(); props.onClick();
        }}>
            {props.title}
        </a>
    );
};

export interface CopyLinkButtonProps {
    title: string;
    value: string;
}

export const CopyLinkButton: React.FunctionComponent<CopyLinkButtonProps> = (props) => {
    if (document.queryCommandSupported('copy')) {
        return (
            <a href='/' onClick={(e) => {
                e.preventDefault(); clipboardCopy(props.value);
            }}>
                {props.title}
            </a>
        );
    }
    return null;
};

export interface YellowActionButtonProps {
    titleDescriptor: MessageDescriptor;
    onClick: () => void;
    iconName?: IconSymbol;
}

/**
 *
 * Yellow action button used for starting assets, simulations etc.
 */
export const YellowActionButton: React.FunctionComponent<YellowActionButtonProps> = (props) => {
    return (
        <button className='yellow-action-button' onClick={props.onClick}>
            <Icon name={props.iconName ? props.iconName : 'triangle-right'}/>
            <WrappedMessage message={props.titleDescriptor}/>
        </button>
    );
};

export interface YellowActionLinkButtonProps {
    titleDescriptor: MessageDescriptor;
    route: string;
    onClick?: () => void;
    showSkeleton?: boolean;
}

/**
 *
 * Yellow action button used for starting assets, simulations etc.
 */
export const YellowActionLinkButton: React.FunctionComponent<YellowActionLinkButtonProps> = (props) => {
    return props.showSkeleton ? <Skeleton  className='yellow-action-button' /> :
            <NavLink
                to={props.route}
                className='yellow-action-button'
                onClick={props.onClick ? props.onClick : undefined}
            >
                <Icon name='triangle-right'/>
                <WrappedMessage message={props.titleDescriptor}/>
            </NavLink>
    ;
};

interface SignInLinkProps extends React.PropsWithChildren {
    to: string;
    signIn?: string;
    className?: string;
    isUserLoggedIn?: boolean;
}

/**
 *
 * Shows a Link to the given URL, via signIn if user is not already logged in.
 */
export class SignInLink extends React.PureComponent<SignInLinkProps, {}> {
    public render() {
        const signIn = this.props.signIn || ROUTES.General.SIGN_IN;
        const className = this.props.className || 'btn';
        return (
            <Link to={this.props.isUserLoggedIn ? this.props.to : signIn}
                  className={className}
                  onClick={this.onClick} onKeyDown={this.onClick}>
                {this.props.children}
            </Link>
        );
    }

    @bind private onClick(event: React.MouseEvent<any>|React.KeyboardEvent<any>) {
        // If the user is not logged in, redirect to `to` once authenticated
        if (isMouseOrEnterEvent(event) && !this.props.isUserLoggedIn) {
            authLocalStorageManager.authRedirectTo = this.props.to;
        }
        return true;
    }
}
