// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    OrganizationSummary,
    OrganizationSummaryFromJSON,
    OrganizationSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface SourceOrganization
 */
export interface SourceOrganization {
    /**
     * 
     * @type {OrganizationSummary}
     * @memberof SourceOrganization
     */
    organization: OrganizationSummary;
    /**
     * 
     * @type {number}
     * @memberof SourceOrganization
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SourceOrganization
     */
    customLink?: string;
}

export function SourceOrganizationFromJSON(json: any): SourceOrganization {
    return {
        'organization': OrganizationSummaryFromJSON(json['organization']),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'customLink': !exists(json, 'custom_link') ? undefined : json['custom_link'],
    };
}

export function SourceOrganizationToJSON(value?: SourceOrganization): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'organization': OrganizationSummaryToJSON(value.organization),
        'order': value.order,
        'custom_link': value.customLink,
    };
}


