import { defineMessages } from 'react-intl';

const messages = defineMessages({
    watchClipButtonLabel: {
        id:'watchClipButtonLabel',
        defaultMessage: 'Watch clip',
        description: 'Label for a watch clip button',
    },
    learnMoreButtonLabel: {
        id:'learnMoreButtonLabel',
        defaultMessage: 'Learn more',
        description: 'Label for a learn more button',
    },
    coupledQuestionsTitle: {
        id: 'coupledQuestionsLabel',
        defaultMessage: 'Questions',
        description: 'title to be shown for coupled questions'
    },
    coupledQuestionsInnerQuestionLabel: {
        id: 'coupledQuestionsInnerQuestionLabel',
        defaultMessage: 'QUESTION',
        description: 'label shown on the box question inner label'
    },
    openProblemButtonSingularLabel: {
        id:'openProblemButtonSingularLabel',
        defaultMessage: 'Start question',
        description: 'Label for the start questions button',
    },
    openProblemButtonPluralLabel: {
        id:'openProblemButtonPluralLabel',
        defaultMessage: 'Start questions',
        description: 'Label for the start questions button',
    },
    closeTagsFilterViewButtonLabel: {
        id:'closeTagsFilterViewButtonLabel',
        defaultMessage: 'Close tags filter panel',
        description: 'Label for the close tags filter panel button',
    },
    clearAllFilteredTagsButtonLabel: {
        id:'clearAllTagsButtonLabel',
        defaultMessage: 'Clear all filtered tags button',
        description: 'Label for the clear all filtered tags button',
    },
    filterTagsButtonLabel: {
        id:'filterTagsButtonLabel',
        defaultMessage: 'Filter',
        description: 'Label for the filter tags submit button',
    },
    submitQuestionButtonLabel: {
        id:'submitQuestionButtonLabel',
        defaultMessage: 'Submit answer',
        description: 'Label for the question submit button',
    },
    endQuestionButtonLabel: {
        id:'endQuestionButtonLabel',
        defaultMessage: 'Close question',
        description: 'Label for close question button',
    },
    editAnnotationButtonLabel: {
        id:'editAnnotationButtonLabel',
        defaultMessage: 'Edit annotation',
        description: 'Label for the edit annotation button',
    },
    addAnnotationButtonLabel: {
        id:'addAnnotationButtonLabel',
        defaultMessage: 'Add annotation',
        description: 'Label for the add annotation button',
    },
    addQuestionButtonLabel: {
        id:'addQuestionButtonLabel',
        defaultMessage: 'Add question',
        description: 'Label for the add question button',
    },
    saveAnnotationsButtonLabel: {
        id:'saveAnnotationsButtonLabel',
        defaultMessage: 'Save annotated video',
        description: 'Label for the save annotation video button',
    },
    savingAnnotationsButtonLabel: {
        id:'savingAnnotationsButtonLabel',
        defaultMessage: 'Saving...',
        description: 'Label for the saving annotation video button',
    },
    questionCorrectResults: {
        id:'questionCorrectResults',
        defaultMessage: 'Correct!',
        description: 'Text for correct results for an annotated video question',
    },
    questionInCorrectResults: {
        id:'questionInCorrectResults',
        defaultMessage: 'Not quite, try again.',
        description: 'Text for Incorrect results for an annotated video question',
    },
    multiCorrectQuestionIncorrectResults: {
        id:'multiCorrectQuestionIncorrectResults',
        defaultMessage: 'You haven\'t selected all the correct options',
        description: 'Text for incorrect results for an annotated video multi correct question',
    },
    editToggleLabel: {
        id:'editToggleLabel',
        defaultMessage: 'Edit view',
        description: 'Text for edit toggle for annotated video',
    },
    videoByText: {
        id: 'videoByText',
        defaultMessage: 'This video is by',
        description: 'Text for the video author attribution for annotated video',
    },
    annotatedVideoAttribution1Text: {
        id: 'annotatedVideoAttribution1Text',
        defaultMessage: 'This is content from',
        description: 'Text for the video author attribution for annotated video (first part)',
    },
    annotatedVideoAttribution2Text: {
        id: 'annotatedVideoAttribution2Text',
        defaultMessage: ', on',
        description: 'Text for the video author attribution for annotated video (second part)',
    },
    annotatedVideoAttributionWatchOnYoutubeText: {
        id: 'annotatedVideoAttributionWatchOnYoutubeText',
        defaultMessage: 'Watch on YouTube',
        description: 'Text for the video author attribution for annotated video (watch on YT)',
    },
    readMoreAnnotationButtonText: {
        id: 'readMoreAnnotationButtonText',
        defaultMessage: 'Read more',
        description: 'Text of the button to read all description of an annotation',
    },
    annotationAuthorMadeByYou: {
        id: 'annotationAuthorMadeByYou',
        defaultMessage: 'Made by you',
        description: 'Label used when the logged user is the author of an annotation',
    },
    deleteAnnotationButtonLabel: {
        id: 'deleteAnnotationButtonLabel',
        defaultMessage: 'Delete annotation',
        description: 'Label for the delete annotation button',
    },
    giveFeedbackButtonLabel: {
        id: 'giveFeedbackButtonLabel',
        defaultMessage: 'Give Feedback',
        description: 'Label for the give feedback button',
    }
});

export default messages;
