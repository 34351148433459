import { defineMessages } from 'react-intl';

const messages = defineMessages({
    educationPathwaySectionTitle: {
        id:'educationPathwaySectionTitle',
        defaultMessage: 'Education pathway',
        description: 'Career detail page education pathway section title',
    },
    typicalPathTitle: {
        id:'typicalPathTitle',
        defaultMessage: 'Typical path',
        description: 'Education pathway typical path title',
    },
    alternativePathTitle: {
        id:'alternativePathTitle',
        defaultMessage: 'Alternative path',
        description: 'Education pathway alternative path title',
    },
    currentPathTitle: {
        id:'currentPathTitle',
        defaultMessage: 'You',
        description: 'Education pathway users cuurrent path title',
    },
    viewGuideLinkLabel: {
        id:'viewGuideLinkLabel',
        defaultMessage: 'View guide',
        description: 'View guide link label',
    },
    otherCareerOptionsLabel: {
        id:'otherCareerOptionsLabel',
        defaultMessage: 'Other career options',
        description: 'Other career options label',
    },
});

export default messages;
