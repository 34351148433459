import { bind } from 'bind-decorator';
import { ChildItem } from 'labxchange-client';
import messages from 'library/displayMessages';
import * as React from 'react';
import { Button, Modal } from 'ui/components';
import { WrappedMessage } from 'utils';
import { ClipVideo } from '../ClipVideo';

export interface PathwayChildClipVideoProps {
    videoStartTime?: number;
    videoStopTime?: number;
}

interface Props {
    child: ChildItem;
    onClose: () => void;
    onUpdate: (props: PathwayChildClipVideoProps) => void;
}

interface State {
    videoTimestampCustomized: boolean;
    startTime: number;
    endTime: number;
    videoDuration?: number;
}

export class PathwayChildClipVideoModal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            videoTimestampCustomized: this.props.child.videoStartTime !== undefined,
            startTime: this.props.child.videoStartTime || 0,
            endTime: this.props.child.videoStopTime || 10,
        };
    }

    public render() {
        return (
            <Modal
                onRequestClose={this.props.onClose}
                size='large' showTopBar={true}
                title={
                    <WrappedMessage message={messages.metadataClipVideos} />
                }
                className='pathway-child-clip-video-modal'
                content={
                    <ClipVideo
                        pathwayChild={this.props.child}
                        minClippedLength={10}
                        onTimeUpdate={this.onTimeUpdate}
                        onShowControls={this.onShowControls}
                    />
                }
                footer={
                    <>
                        <Button
                            btnStyle='outline'
                            label={messages.itemCancel}
                            onClick={this.props.onClose}
                        />
                        <button className='primary-button' onClick={this.updateChild}>
                            <WrappedMessage message={messages.pathwayCardSave}/>
                        </button>
                    </>
                }
            />
        );
    }

    @bind private onTimeUpdate(startTime: number, endTime: number) {
        this.setState({startTime, endTime});
    }

    @bind private onShowControls(videoDuration: number) {
        this.setState({
            videoTimestampCustomized: true,
            videoDuration,
        });
    }

    @bind private getUpdatedTime() {
        if (this.state.videoTimestampCustomized && this.state.videoDuration) {
            if (this.state.startTime !== 0 || this.state.endTime !== this.state.videoDuration) {
                return [this.state.startTime, this.state.endTime];
            }
        }
        return [undefined, undefined];
    }

    @bind private updateChild() {
        const updatedTime = this.getUpdatedTime();
        this.props.onUpdate({
            videoStartTime: updatedTime[0],
            videoStopTime: updatedTime[1],
        });
        this.props.onClose();
    }

}
