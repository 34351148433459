import * as React from 'react';
import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import messages from '../../displayMessages';
import { ExploreContent } from '../ExploreContent';
import { ExploreFooter } from '../ExploreFooter';
import { ExploreHeader } from '../ExploreHeader';


export class ExplorePage extends React.PureComponent {
    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.explorePageTitle}
                headerFeature={<ExploreHeader/>}
                headerBackgroundUrl={'/assets/images/explore-header-background.svg'}
                bottomContent={<ExploreFooter/>}
                backgroundExplore={true}
            >
                <ExploreContent/>
            </StandardPageLayout>
        );
    }
}
