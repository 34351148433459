import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleLibraryPage',
        defaultMessage: 'Library',
        description: 'Page title for the library page',
    },
    myContentPageTitle: {
        id:'myContentPageLibraryTitle',
        defaultMessage: 'My content',
        description: 'HTML title for the "My Content" page',
    },
});

export default messages;
