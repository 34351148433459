import * as React from 'react';
import './styles.scss';
import { UI_IS_SM } from '../breakpoints';

interface PaginationProps {
  buttonLabels: {
    currentPage: string;
    next: string;
    page: string;
    pageOfCount: string;
    previous: string;
  };
  currentPage: number;
  pageCount: number;
  onPageSelect: (page: number) => void;
  className?: string;
  paginationLabel: string;
}

export function Pagination({
  buttonLabels,
  currentPage,
  pageCount,
  onPageSelect,
  className = '',
  paginationLabel,
}: PaginationProps) {
  const [isSmallScreen, setIsSmallScreen] = React.useState<boolean>(UI_IS_SM.matches);

  // Update isSmallScreen when the screen resizes
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function pageItem(page:number) {
    return <li
      key={page}
      className={`page-item ${page === currentPage ? 'active' : ''}`}
    >
      <button
        aria-label={`${buttonLabels.page} ${page}`}
        className="btn page-link"
        onClick={() => onPageSelect(page)}
      >
        {page}
      </button>
    </li>
  }

  const renderEllipsis = () => (
      <span className='ellipses'>...</span>
  );

  const paginationContent = () => {
    if (pageCount <= 5) {
      // Render all page items if total pages <= 5
      return (<>
        {[...Array(pageCount)].map((_, index) => pageItem(index + 1))}
      </>)
    }

    if (currentPage <= 3) {
      // Show first five page items, ellipses and then last page item
      return (
        <>
          {[...Array(Math.min(5, pageCount))].map((_, index) => pageItem(index + 1))}
          {pageCount > 5 && (
            <>
              {renderEllipsis()}
              {pageItem(pageCount)}
            </>
          )}
        </>
      );
    }
    else if (currentPage >= pageCount - 2) {
      // When current page is one of last three pages, show first page item, ellipses, then last 5 page items
      return (
        <>
          {pageItem(1)}
          {renderEllipsis()}
          {
            [...Array(Math.min(5, pageCount))].map((_, index) =>
              pageItem(Math.max(1, pageCount - 4 + index))
            )
          }
        </>
      )

    }
    else if (currentPage > 3) {
      // Display the first page item, ellipsis, previous page item, current page item, next page item, 
      //another ellipsis, and finally the last page item.
      return (
        <>
          {pageItem(1)}
          {currentPage >= 4 && renderEllipsis()}
          {pageItem(currentPage - 1)}
          {pageItem(currentPage)}
          {currentPage < pageCount && pageItem(currentPage + 1)}
          {currentPage + 1 < pageCount && (
            <>
              {renderEllipsis()}
              {pageItem(pageCount)}
            </>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <nav aria-label={paginationLabel} className={className}>
      <div
        className="sr-only"
        aria-live="polite"
        aria-relevant="text"
        aria-atomic="true"
      >
        {buttonLabels.pageOfCount.replace('{current}', currentPage.toString()).replace('{total}', pageCount.toString())}
      </div>

      {/* Previous page button */}
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            aria-label={`${buttonLabels.previous}, ${buttonLabels.page} ${currentPage - 1}`}
            className="btn previous page-link"
            onClick={() => onPageSelect(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <div>{buttonLabels.previous}</div>
          </button>
        </li>

        {/* Page items */}
        {isSmallScreen ? (
          <span className="pagination-text">
            {currentPage} of {pageCount}
          </span>
        ) : (
          paginationContent()
        )}

        {/* Next page button */}
        <li className={`page-item ${currentPage === pageCount ? 'disabled' : ''}`}>
          <button
            aria-label={buttonLabels.next}
            className="btn next page-link"
            onClick={() => onPageSelect(currentPage + 1)}
            disabled={currentPage === pageCount}
          >
            <div>{buttonLabels.next}</div>
          </button>
        </li>
      </ul>
    </nav>
  );
}
