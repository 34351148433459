import bind from 'bind-decorator';
import { ClassroomEducatorType, ClassroomItemsList, ClassroomMembershipBox } from 'classrooms/components';
import { ROUTES } from 'global/constants';
import { ClassroomDetail, ClassroomItem, ClassroomMembership } from 'labxchange-client';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { UI_IS_MD } from 'ui/breakpoints';
import { UserAvatar } from 'user/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { KebabMenu } from 'ui/components/KebabMenu';
import { ReportButton } from 'moderation/components';
import { ModerationRequestContentTypeEnum } from 'labxchange-client';
import Skeleton from 'react-loading-skeleton';

interface Props {
    classroom?: ClassroomDetail;
    showSkeleton?: boolean;
}

/**
 * ClassroomContentEducatorBox displays details about an Educator
 * It is used currently in the Learner-perspective of the content tab on a ClassroomPage.
 */
const ClassroomContentEducatorBox: React.FC<Props> = ({ classroom, showSkeleton}) => {
    return <div className='educators-box-container'>
        {(classroom?.educators || [{username: '', fullName: ''}]).map((educator) => (
            <div className='educators-box'>
                <div className='educator-box'>
                    <UserAvatar
                        username={educator.username}
                        diameter='78px'
                        showSkeleton={showSkeleton}
                    />
                    {showSkeleton ? <div className='educator-box-skeleton'><Skeleton count={3}/></div> : <>
                        <p className='educator-name'>
                            {educator.fullName}
                        </p>
                        <p className='educator-title'>
                            <WrappedMessage
                                message={
                                    educator.type === ClassroomEducatorType.Owner ?
                                        messages.ownerEducatorTitle :
                                        messages.colleagueEducatorTitle
                                }
                            />
                        </p>
                        <NavLink to={ROUTES.Users.PROFILE_SLUG(educator.username)} className='primary-button'>
                            <WrappedMessage message={messages.viewProfileButtonTitle}/>
                        </NavLink>
                    </>}
                </div>
            </div>
        ))}
    </div>;
};

interface ClassroomContentLearnerProps extends RouteComponentProps<{}> {
    classroom?: ClassroomDetail;
    showSkeleton?: boolean;
}

interface State {
    isMediumDevice: boolean;
    keepReportKebabExpanded: boolean;
}

export class ClassroomContentLearnerInternal extends React.PureComponent<ClassroomContentLearnerProps, State> {

    private mediaQueryMedium = UI_IS_MD;

    constructor(props: any) {
        super(props);

        this.state = {
            isMediumDevice: false,
            keepReportKebabExpanded: false,
        };
    }

    public async componentDidMount() {
        this.mediaQueryMedium.addListener(this.setDeviceClass);
        this.setDeviceClass();
    }

    public componentWillUnmount() {
        this.mediaQueryMedium.removeListener(this.setDeviceClass);
    }

    public render() {
        let itemsPosted: ClassroomItem[] = [];
        if (this.props.classroom &&  this.props.classroom.items) {
            itemsPosted = this.props.classroom.items.filter((items) => items.status === 'posted');
            itemsPosted.sort((a, b) => b.order - a.order);
        }

        const members: ClassroomMembership[] = this.props.classroom? this.props.classroom.members ? this.props.classroom.members : [] : [];

        return (
            <div className='classroom-content-learner'>
                <div className='classroom-content-main'>
                    <div className='classroom-description'>
                        <div className='title'>
                            <h1>
                                {this.props.showSkeleton ? <Skeleton/> :
                                    <WrappedMessage message={messages.classDescriptionHeading}/>
                                }
                            </h1>
                            {!this.props.showSkeleton && <KebabMenu
                                darkKebab={true}
                                icon='kebab-horizontal'
                                keepExpanded={this.state.keepReportKebabExpanded}
                            >
                                <ReportButton
                                    asKebab={true}
                                    icon='flag'
                                    itemId={this.props.classroom? this.props.classroom.id : ''}
                                    itemTitle={this.props.classroom? this.props.classroom.name : ''}
                                    itemType={ModerationRequestContentTypeEnum.ClassroomsClassroom}
                                    label={messages.reportClassroom}
                                    onOpen={() => this.setState({keepReportKebabExpanded: true})}
                                    onClose={() => this.setState({keepReportKebabExpanded: false})}
                                />
                            </KebabMenu>}
                        </div>
                        {this.props.showSkeleton ? <div className='skeleton-description'><Skeleton /></div> : <p>{this.props.classroom?.description}</p>}
                    </div>

                    { this.state.isMediumDevice ?
                        <>
                            <ClassroomContentEducatorBox classroom={this.props.classroom} showSkeleton={this.props.showSkeleton}/>
                            {this.props.showSkeleton? null : <ClassroomMembershipBox members={members}/>}
                        </>
                    : null }
                    <ClassroomItemsList
                        classroomId={this.props.classroom? this.props.classroom.id : ''}
                        classroomName={this.props.classroom? this.props.classroom.name : ''}
                        items={itemsPosted}
                        showHex={true}
                        showPostedDate={true}
                        showSkeleton={this.props.showSkeleton}
                        showCompletion={true}
                        forceShowCompletion={true}
                    />
                </div>

                { !this.state.isMediumDevice ?
                    <div className='side'>
                        <ClassroomContentEducatorBox classroom={this.props.classroom} showSkeleton={this.props.showSkeleton}/>
                        {this.props.showSkeleton ? null : <ClassroomMembershipBox members={members}/>}
                    </div>
                : null }
            </div>
        );
    }

    @bind private setDeviceClass() {
        if (this.mediaQueryMedium.matches) {
            this.setState({isMediumDevice: true});
        } else {
            this.setState({isMediumDevice: false});
        }
    }
}

export const ClassroomContentLearner = withRouter(ClassroomContentLearnerInternal);
