// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Author
 */
export interface Author {
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Author
     */
    fullName: string;
    /**
     * 
     * @type {boolean}
     * @memberof Author
     */
    originalAuthor?: boolean;
}

export function AuthorFromJSON(json: any): Author {
    return {
        'username': !exists(json, 'username') ? undefined : json['username'],
        'fullName': json['full_name'],
        'originalAuthor': !exists(json, 'original_author') ? undefined : json['original_author'],
    };
}

export function AuthorToJSON(value?: Author): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'full_name': value.fullName,
        'original_author': value.originalAuthor,
    };
}


