
import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { intl } from 'i18n';

import { WrappedMessage, useLayoutSize } from 'utils';
import uiMessages from 'ui/components/displayMessages';
import { Icon } from 'elements';

import messages from '../../displayMessages';
import { Topic } from './SubjectsPage';

interface TopicBarCardsProps {
  topics: Topic[];
  curriculumPage?: boolean;
}

export const TopicBarCards: FC<TopicBarCardsProps> = (props) => {
  const layoutSize = useLayoutSize();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (layoutSize === 'mobile' || 'medium') {
      setCurrentPage(0);
    }
  }, [layoutSize]);

  const getCardsPerPage = (cardsLength: number): number => {
    if (layoutSize === 'desktop') {
     return 8;
   } else if (layoutSize === 'large') {
     return 6;
   }
   return cardsLength;
 };

  const cardsPerPage = getCardsPerPage(props.topics.length);
  const totalPages = Math.ceil(props.topics.length / cardsPerPage);
  const startIndex = currentPage * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const topicsToRender = props.topics.slice(startIndex, endIndex);
  const emptyCardsCount = Math.max(cardsPerPage - topicsToRender.length, 0);

  const handleNextClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderTopicCard = (topic: Topic) => (
    <Link
      to={topic.url}
      className='topic-card'
    >
      <span>{topic.name}</span>
    </Link>
  );

  const renderEmptyCard = () => (
    <div
      className='topic-card empty'
    >
      <span></span>
    </div>
  );

  return (
    <div className='topic-cards-container container'>
      <h1 className='topic-cards-title'>
        <WrappedMessage message={props.curriculumPage? messages.exploreRelatedTopicsInCurriculum : messages.exploreTopicsSubjectPage} />
      </h1>
      {props.topics && (
        <div className='topic-cards-list'>
          <button
            className={classNames('previous-button', { hide: currentPage === 0 })}
            title={intl.formatMessage(uiMessages.uiPrevious)}
            onKeyDown={handlePrevClick}
            onClick={handlePrevClick}
          >
            <Icon name='chevron-left' />
          </button>
          {topicsToRender.map((topic: Topic) => renderTopicCard(topic))}
          {Array.from(Array(emptyCardsCount)).map(() => renderEmptyCard())}
          <button
            className={classNames('next-button', { hide: currentPage === totalPages - 1 })}
            title={intl.formatMessage(uiMessages.uiNext)}
            onKeyDown={handleNextClick}
            onClick={handleNextClick}
          >
            <Icon name='chevron-right' />
          </button>
        </div>
      )}
    </div>
  );
};

export default TopicBarCards;
