import * as React from 'react';
import { Button } from 'ui/components';
import Confetti from 'react-confetti';
import { Modal } from 'elements/components/Modals';
import { WrappedMessage } from 'utils';
import messages from 'library/displayMessages';

interface Props {
    onClose: () => void;
}

export class CongratulationsModal extends React.PureComponent<Props> {

    render() {
        return (
            <>
                <Confetti
                    colors={['#FAAC48', '#0063C3', '#00AEEF', '#ED3431', '#84BE60']}
                />
                <Modal
                    className='modal-close-header-action text-center my-5 congratulation-modal'
                    onRequestClose={this.props.onClose}
                    size='normal'
                    showTopBar={true}
                    content={
                        <div>
                            <img
                                src='/assets/images/pathway/celebrate.svg'
                                className='mb-4'
                                alt='Celebrate icon'
                            />
                            <h1><WrappedMessage message={messages.congratulationModalHeader} /></h1>
                            <WrappedMessage message={messages.congratulationModalText} />
                        </div>
                    }
                    footer={
                        <div className='col-8 mx-auto'>
                            <Button
                                btnStyle='primary'
                                className='close-congratulation-modal'
                                label={messages.congratulationModalButton}
                                fullWidth={true}
                                onClick={this.props.onClose}
                            />
                        </div>
                    }
                />
            </>
        );
    }
}