import { defineMessages } from 'react-intl';

const messages = defineMessages({
    readOnlyTitle: {
        id:'readOnlyTitle',
        defaultMessage: 'Mentorship request',
        description: 'Title for read only.',
    },
    readOnlyNeedHelpLabel: {
        id:'readOnlyNeedHelpLabel',
        defaultMessage: 'What subject does {fullName} need help with?',
        description: 'Why need help label of read only.',
    },
    readOnlyNeedMentorLabel: {
        id:'readOnlyNeedMentorLabel',
        defaultMessage: 'What kind of advice is {fullName} looking for?',
        description: 'Why need a mentor label of read only.',
    },
    announce: {
        id:'announce',
        defaultMessage: 'You\'ve received a new mentorship request from {fullName}!',
        description: 'Mentorship request announce message content.',
    },
    lookingForSubject: {
        id:'lookingForSubject',
        defaultMessage: `{fullName} is looking for help with: “{pendingSubject}”.`,
        description: 'Mentorship request looking for message content.',
    },
    wouldLikeReason: {
        id:'wouldLikeReason',
        defaultMessage: `{fullName} would like a mentor because: “{pendingReason}”.`,
        description: 'Mentorship request would like message content.',
    },
    isAgree: {
        id:'isAgree',
        defaultMessage: `Do you consent to mentor and be messaged by {fullName}?
         Use the links below to accept or decline.`,
        description: 'Mentorship request is agree message content.',
    },
    acceptLink: {
        id:'acceptLink',
        defaultMessage: 'Yes, I will mentor {fullName}',
        description: 'Mentorship request accept link message content.',
    },
    orConjonction: {
        id:'orConjonction',
        defaultMessage: 'or',
        description: 'Or conjonction',
    },
    refuseLink: {
        id:'refuseLink',
        defaultMessage: 'No, I decline to mentor {fullName}',
        description: 'Mentorship request refuse link message content.',
    },
    letsChat: {
        id:'letsChat',
        defaultMessage: `Once you make a decision, use the links above to notify {fullName}. `,
        description: 'Mentorship request let\'s chat message content.',
    },
});

export default messages;
