import { ROUTES } from 'global/constants';
import { intl } from 'i18n';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ShowPasswordButton, TextInput } from 'ui/components';
import { UserAvatar } from 'user/components';
import messages from '../displayMessages';

interface Props {
    inputKey: string;
    email: string;
    password: string;
    showPassword: boolean;
    invalidPassword: boolean;
    disableButton: boolean;
    onUpdatePassword: (password: string) => void;
    onUpdateShowPassword: (showPassword: boolean) => void;
    onClickResetPassword: () => void;
    onSubmit: () => void;
}

export const LoginPasswordComponent: React.FunctionComponent<Props> = (props) => {
    return (
        <div className='login-password-container'>
            <div className='email-container'>
                <UserAvatar
                    email={props.email}
                />
                <div className='email'>
                    {props.email}
                </div>
            </div>
            <TextInput
                className='password-input'
                inputKey={props.inputKey}
                autoComplete='current-password'
                value={props.password}
                onChangeValue={props.onUpdatePassword}
                type={props.showPassword ? 'text' : 'password'}
                placeholder={messages.lxAuthPasswordInputPlaceholder}
                invalid={props.invalidPassword}
                feedbackMessage={props.invalidPassword ? messages.lxAuthSingInPasswordIncorrect : undefined}
                feedbackMessageValues={{recover_password:
                    <NavLink
                        to={ROUTES.General.RESET_PASSWORD_EMAIL}
                        onClick={props.onClickResetPassword}
                    >
                        {intl.formatMessage(messages.lxAuthRecoverPasswordLowerCaseLabel)}
                    </NavLink>
                }}
                inputAccessory={
                    <ShowPasswordButton
                        active={props.showPassword}
                        onClick={props.onUpdateShowPassword}
                    />
                }
            />
            <Button
                className='next-button'
                btnStyle='primary'
                label={messages.lxAuthSignInLavel}
                disabled={props.disableButton}
                onClick={props.onSubmit}
            />
            <div className='recover-button-container'>
                <Button
                    className='recover-button'
                    btnStyle='link'
                    label={messages.lxAuthRecoverPasswordLabel}
                    onClick={props.onClickResetPassword}
                />
            </div>
        </div>
    );
};
