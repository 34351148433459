import { ROUTES } from 'global/constants';
import { getItemTypeMeta, ItemType } from 'items/models';
import { ItemMetadataTypeEnum, PartialBook } from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { WrappedMessage } from 'utils';
import libraryMessages from '../../displayMessages';
import discussionMessages from '../../../discussions/displayMessages';
import { Icon } from 'ui/components';
import { intl } from '../../../i18n';

interface LibraryBreadcrumbProps {
    itemType?: ItemType;
    book?: PartialBook;
    itemTitle?: string;
    itemId?: string;
}

const generateItemRoute = (itemType: ItemType, itemId: string) => {
    if (itemId) {
        return itemType === ItemMetadataTypeEnum.Pathway ?
            `${ROUTES.Library.HOME}/${itemType}/${itemId}` :
            `${ROUTES.Library.HOME}/items/${itemId}`;
    } else {
        return '#';
    }
};

export const LibraryBreadcrumb: React.FunctionComponent<LibraryBreadcrumbProps> = (props) => {
    const nestedLinks: React.ReactNode[] = [];

    // If the current item is part of a textbook, then we show the hierarchy as
    // library > book > {book title} > {item title}
    if (props.book) {
        nestedLinks.push(
            <li key='bookType'>
                <NavLink to={{ pathname: ROUTES.Library.HOME, search: `?t=ItemType:${ItemType.Book}` }} exact>
                    <WrappedMessage message={getItemTypeMeta(ItemType.Book).name} values={{ count: 0 }} />
                </NavLink>
            </li>,
            <li key='bookTitle'>
                <NavLink to={detailUrlForEntity({ id: props.book.id, type: ItemType.Book })} exact>
                    {props.book.title}
                </NavLink>
            </li>
        );
    } else if (props.itemType && props.itemType !== ItemType.Unknown) {
        nestedLinks.push(
            <li key='itemType'>
                <NavLink to={{ pathname: ROUTES.Library.HOME, search: `?t=ItemType:${props.itemType}` }} exact>
                    <WrappedMessage message={getItemTypeMeta(props.itemType!).name} values={{ count: 0 }} />
                </NavLink>
            </li>
        );
    }

    if (props.itemTitle) {
        nestedLinks.push(
            <li key='itemTitle'>
                {props.itemType && props.itemId ?
                    <NavLink to={generateItemRoute(props.itemType, props.itemId)} aria-current='page'>
                        {props.itemTitle}
                    </NavLink> :
                    <NavLink to='#' aria-current='page'>
                        {props.itemTitle}
                    </NavLink>
                }
            </li>
        );
    }

    return (
        <nav className='library-breadcrumb' aria-label='Breadcrumb'>
            <ol>
                <li>
                    <NavLink to={ROUTES.Library.HOME} exact>
                        <WrappedMessage message={libraryMessages.libraryCatalog} />
                    </NavLink>
                </li>
                {nestedLinks.map((link, index) => (
                    <React.Fragment key={index}>
                        <li aria-hidden='true'>&nbsp;&gt;&nbsp;</li>
                        {link}
                    </React.Fragment>
                ))}
            </ol>
        </nav>
    );
};


export interface NavLinkInfo {
    route: string;
    message: string;
}

interface DiscussionBreadcrumbProps {
    navLinksInfo: NavLinkInfo[];
}

export const DiscussionBreadcrumb: React.FunctionComponent<DiscussionBreadcrumbProps> = (props) => {
    return (
        <nav className='discussions-breadcrumb'>
            <ol>
                <li>
                    <NavLink
                        to={ROUTES.Community.DISCUSSIONS}
                        exact
                    >
                        <WrappedMessage message={discussionMessages.discussionsHomeTitle}/>
                    </NavLink>
                </li>
                {props.navLinksInfo.map((navLinkInfo, i) => {
                    return (
                        <React.Fragment key={i}>
                            <li aria-hidden='true'>&nbsp;&gt;&nbsp;</li>
                            <li>
                                <NavLink to={navLinkInfo.route}>
                                    {navLinkInfo.message}
                                </NavLink>
                            </li>
                        </React.Fragment>
                    );
                })}
            </ol>
        </nav>);
};

interface ResourceBreadcrumbButtonProps {
    itemType: ItemMetadataTypeEnum;
    itemId: string;
}

export const ResourceBreadcrumbButton: React.FunctionComponent<ResourceBreadcrumbButtonProps> = (props) => {

    const itemType = intl.formatMessage(getItemTypeMeta(props.itemType).simpleName ?? getItemTypeMeta(props.itemType).name );

    return (
        <NavLink to={detailUrlForEntity({ id: props.itemId, type: props.itemType })} className='resource-breadcrumb-container'>
            <div className='resource-breadcrumb'>
                <Icon name='chevron-left' className='breadcrumb-icon' />
                <div className='breadcrumb-text'>
                    <WrappedMessage message={libraryMessages.backToAssetButtonText}  values={{ itemType }} />
                </div>
            </div>
        </NavLink>
    );
};
