import { MessageDescriptor } from 'react-intl';
import {
    AlignElementRightContainer,
    CollapsibleWithChevron,
    ContainerOne,
    ContainerWithTitleOutside,
    ElementsInColumnContainer,
    FullDate,
    HeadingFive,
    HeadingFour,
    Icon,
    OneContentWidthSecondFlexibleWidthElementsInRowContainer,
    OrderedListContainer,
    OutermostCenteredContainer,
    TextOne,
    TwoEqualWidthElementsInRowContainer,
    YellowActionLinkButton,
} from 'elements';
import { BooksApi, ItemsApi } from 'global/api';
import { ItemType } from 'items/models';
import {
    Author,
    ContentSource,
    EditionEditionTypeEnum,
    ItemMetadata,
    ItemMetadataLicenseEnum,
    ItemResponse,
    ItemUserAttributes,
} from 'labxchange-client';
import { LibraryBreadcrumb } from 'library/components/Breadcrumb';
import { MetaHeader } from 'library/components/MetaHeader';
import { Card } from 'library/components/Card';
import { getContentLicenseLabel } from 'library/displayMessages';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ExpandableText, StandardPageLayout } from 'ui/components';
import { WrappedMessage } from 'utils';

import messages from '../../displayMessages';
import { InvalidContent } from 'library/components/InvalidContent';
import { Book, Edition, Section } from '../../models';
import { createAssetViewedEventForItem } from 'library/components/Block/utils';
import Skeleton from 'react-loading-skeleton';
import { skeletonBook, skeletonBookPageData, skeletonItemMetadata } from 'skeletons';

interface MatchProps {
    itemKey: string;
}

interface BookPageProps extends RouteComponentProps<MatchProps> {
}

interface BookPageData {
    book: Book;
    itemMetadata: ItemMetadata;
    itemUserAttributes: ItemUserAttributes;
}

interface BookPageState {
    dataLoading: boolean;
    data?: BookPageData;
    errStatus?: number;
}

export class BookPage extends React.PureComponent<BookPageProps, BookPageState> {

    constructor(props: BookPageProps) {
        super(props);
        this.state = {
            dataLoading: true,
            errStatus: 404,
            data: skeletonBookPageData,
        };
    }

    public componentDidMount() {
        this.loadData(this.itemKey);
    }

    public async componentDidUpdate(prevProps: BookPageProps) {
        if (this.itemKey !== prevProps.match.params.itemKey) {
            this.loadData(this.itemKey);
        }
    }

    public render() {
        if (!this.state.dataLoading && !this.state.data) {
            return <InvalidContent statusCode={this.state.errStatus} />;
        }
        return <BookPageContent itemKey={this.itemKey} data={this.state.data} showSkeleton={this.state.dataLoading}/>;
    }

    private get itemKey() {
        return this.props.match.params.itemKey;
    }

    private async loadData(pathwayKey: string) {
        this.setState({
            dataLoading: true,
        });
        try {
            const [item, book] = await Promise.all([
                ItemsApi.read({id: this.itemKey}),
                BooksApi.read({id: this.itemKey}),
            ]);
            this.setState({
                dataLoading: false,
                data: {
                    book,
                    itemMetadata: item.metadata,
                    itemUserAttributes: item.userAttributes,
                },
            });
        } catch (err) {
            this.setState({
                dataLoading: false,
                data: undefined,
                errStatus: err.status ? err.status : 500,
            });
        }
    }
}

const onBookStart = (metadataId: string) => createAssetViewedEventForItem(metadataId, false);

interface BookPageContentProps {
    itemKey: string;
    data?: BookPageData;
    showSkeleton?: boolean;
}

const BookPageContent: React.FunctionComponent<BookPageContentProps> = (props) => {

    const headerFeature = (
        <OutermostCenteredContainer>
            <div className='mt-5 mb-1'>
                <LibraryBreadcrumb
                    itemType={ItemType.Book}
                    itemTitle={props.data?.itemMetadata.title}
                />
            </div>
        </OutermostCenteredContainer>
    );

    const sectionItems: any = props.data?.book.sections.map((section) => {
        return section.items;
    });
    const items: ItemResponse[] = [].concat.apply([], sectionItems);
    const firstItem = props.showSkeleton ? skeletonItemMetadata : (items.length > 0 ? items[0].metadata : null);

    return (
        <StandardPageLayout
            backgroundStyle='navy-gradient'
            containerClassName='none'
            headerFeature={headerFeature}
            pageTitle={messages.bookPageTitle}
            pageTitleValues={{name: props.data ? props.data.itemMetadata.title : ''}}
        >
            <OutermostCenteredContainer>
                <BookAbout
                    imageUrl={props.data ? props.data.book.imageUrl? props.data.book.imageUrl : props.data.itemMetadata.imageUrl : ''}
                    title={props.data ? props.data.itemMetadata.title : ''}
                    description={props.data ? props.data.itemMetadata.description : ''}
                    source={props.data ? props.data.itemMetadata.source : undefined}
                    showSkeleton={props.showSkeleton}
                    bookData={props.data}
                />
                { firstItem &&
                    <BookStartBar
                        firstItem={firstItem}
                        onClick={() => props.data && onBookStart(props.data.itemMetadata.id)}
                        showSkeleton={props.showSkeleton}
                    />
                }
                <BookSections
                    sections={props.data? props.data.book.sections : []}
                    bookMetadataId={props.data?.itemMetadata.id}
                    showSkeleton={props.showSkeleton}
                />
                <BookPublishingDetails
                    authors={props.data?.itemMetadata.authors}
                    book={props.data? props.data.book : skeletonBook}
                    license={props.data? props.data.itemMetadata.license : ItemMetadataLicenseEnum.LX1}
                />
            </OutermostCenteredContainer>
        </StandardPageLayout>
    );
};

interface BookAboutProps {
    imageUrl: string;
    title: string;
    description: string;
    source?: ContentSource;
    showSkeleton?: boolean;
    bookData?: BookPageData;
}

/**
 * BookAbout renders basic information about the book.
 */
export const BookAbout: React.FunctionComponent<BookAboutProps> = (props) => {
    return (
        <div>
            {(props.showSkeleton || (props.source && props.source.infoUrl)) &&
                <div className='mt-3 mb-3'>
                    <BookSourceLink bookUrl={props.source?.infoUrl} showSkeleton={props.showSkeleton}/>
                </div>
            }
            <div className='mt-3 mb-4'>
                <ContainerOne>
                    <OneContentWidthSecondFlexibleWidthElementsInRowContainer>
                        <div className='book-about-featured-image'>
                            {props.showSkeleton ? <Skeleton /> : <img src={props.imageUrl} alt='' />}
                        </div>
                        <div className='book-source'>
                            {props.showSkeleton ? <div className='book-source-skeleton'>
                                <span className='book-source-provider-logo'><Skeleton circle={true} /></span>
                                <Skeleton className='book-source-provider-title' />
                            </div> : (props.source && props.source.title) &&
                                <HeadingFour level={3}>
                                    {props.source.sourceOrganizations && props.source.sourceOrganizations[0].organization.logoUrl &&
                                        <img
                                            className='book-source-provider-logo'
                                            src={props.source.sourceOrganizations[0].organization.logoUrl}
                                            alt=''
                                        />
                                    }
                                    <span className='book-source-provider-title'>{props.source.title}</span>
                                </HeadingFour>
                            }
                            <div className='book-about-title'>
                                <h1>{props.showSkeleton ? <Skeleton /> : props.title}</h1>
                            </div>
                            <div>
                                <TextOne expandedLineHeight={true}>
                                    <ExpandableText text={props.description} lines={3} html={true} showSkeleton={props.showSkeleton}/>
                                </TextOne>
                            </div>
                        </div>
                    </OneContentWidthSecondFlexibleWidthElementsInRowContainer>
                    <hr className='divider' />
                    {props.bookData && <MetaHeader metadata={props.bookData.itemMetadata} />}
                </ContainerOne>
            </div>
        </div>
    );
};

interface BookSourceLinkProps {
    bookUrl?: string;
    showSkeleton?: boolean;
}

export const BookSourceLink: React.FunctionComponent<BookSourceLinkProps> = (props) => {
    return (
        <AlignElementRightContainer>
            <a className='book-source-link' href={props.bookUrl}>
                <Icon name='textbook' zoom='32' showSkeleton={props.showSkeleton} />
                {props.showSkeleton ? <Skeleton className='source-message-skeleton' /> : <WrappedMessage message={messages.bookSourceLinkLabel}/>}
            </a>
        </AlignElementRightContainer>
    );
};

interface BookStartBarProps {
    firstItem: ItemMetadata;
    onClick?: () => void;
    showSkeleton?: boolean;
}

/**
 * BookStartBar renders the Start textbook button.
 */
export const BookStartBar: React.FunctionComponent<BookStartBarProps> = (props) => {
    return (
        <div className='mt-4 mb-4 col-12 col-lg-10 mx-auto'>
            <AlignElementRightContainer>
                <YellowActionLinkButton
                    titleDescriptor={messages.bookStartButton}
                    route={detailUrlForEntity(props.firstItem)}
                    onClick={props.onClick? props.onClick : undefined}
                    showSkeleton={props.showSkeleton}
                />
            </AlignElementRightContainer>
        </div>
    );
};

interface BookSectionsProps {
    sections: Section[];
    bookMetadataId?: string;
    showSkeleton?: boolean;
}

/**
 * BookSections renders the sections and items in the book.
 */
export const BookSections: React.FunctionComponent<BookSectionsProps> = (props) => {
    if (!props.showSkeleton && props.sections.length === 0) {
        return null;
    }

    return (
        <div className='book-sections mt-4 mb-4 col-12 col-lg-10 mx-auto'>
            {props.sections.length === 1 ? <BookSectionItems
                items={props.sections[0].items}
                bookMetadataId={props.bookMetadataId}
            /> : <OrderedListContainer>
                {props.sections.map((section, index) => (
                    <li key={index}>
                        <BookSection
                            title={section.title ? section.title : ''}
                            defaultOpen={false}
                            showSkeleton={props.showSkeleton}
                        >
                            <BookSectionItems
                                items={section.items}
                                bookMetadataId={props.bookMetadataId}
                            />
                        </BookSection>
                    </li>
                ))}
            </OrderedListContainer>}
        </div>
    );
};

interface BookSectionProps extends React.PropsWithChildren {
    title?: string;
    defaultOpen: boolean;
    showSkeleton?: boolean;
}

export const BookSection: React.FunctionComponent<BookSectionProps> = (props) => {
    const title = (
        <HeadingFour level={2} title={props.title}/>
    );
    return (
        <div className={`book-section ${props.showSkeleton ? 'skeleton' : ''}`}>
            {props.showSkeleton ? <Skeleton /> :
            <CollapsibleWithChevron
                title={title}
                defaultOpen={props.defaultOpen}
            >
                {props.children}
            </CollapsibleWithChevron>}
        </div>
    );
};

interface BookSectionItemProps {
    items: ItemResponse[];
    bookMetadataId?: string;
}

export const BookSectionItems: React.FunctionComponent<BookSectionItemProps> = (props) => {
    return (
        <div className='child-items-cards-list-wrapper'>
            <ol className='child-items-cards-list'>
                {props.items.map((child, index) => (
                    <li key={child.metadata.id} className={0 === index ? 'current' : ''}>
                        <Card
                            {...child}
                            miniMobileMode={true}
                            detailUrl={detailUrlForEntity(child.metadata)}
                            onCardClick={() =>  {
                                if (props.bookMetadataId) {
                                    onBookStart(props.bookMetadataId);
                                }
                            }}
                        />
                    </li>
                ))}
            </ol>
        </div>
    );
};

interface BookPublishingDetailsProps {
    authors?: Author[];
    book: Book;
    license?: ItemMetadataLicenseEnum;
}

/**
 * BookPublishingDetails renders the publishing details of a book.
 */
export const BookPublishingDetails: React.FunctionComponent<BookPublishingDetailsProps> = (props) => {

    const title = <h2><WrappedMessage message={messages.bookPublishingDetails} /></h2>;
    const printEdition = editionOfType(props.book.editions, EditionEditionTypeEnum.Print);
    const digitalEdition = editionOfType(props.book.editions, EditionEditionTypeEnum.Digital);
    const ibooksEdition = editionOfType(props.book.editions, EditionEditionTypeEnum.Ibooks);

    return (
        <div className='book-publishing-details mt-5'>
            <ContainerWithTitleOutside title={title} theme='navy'>
                <TwoEqualWidthElementsInRowContainer>
                    <ElementsInColumnContainer>
                        { (props.authors && props.authors.length > 0) && <div>
                            <HeadingFive level={3} titleDescriptor={messages.bookPublishingDetailsAuthorsLabel}/>
                            <ul>
                                {
                                    props.authors.map((author, index) => {
                                        return (
                                            <li key={index}>{author.fullName}</li>
                                        );
                                    })
                                }
                            </ul>
                        </div> }
                        { props.book.publishedDate && <div>
                            <HeadingFive level={3} titleDescriptor={messages.bookPublishingDetailsPublishDateLabel}/>
                            <FullDate date={props.book.publishedDate}/>
                        </div> }
                        { props.book.updatedDate && <div>
                            <HeadingFive
                                level={3}
                                titleDescriptor={messages.bookPublishingDetailsWebVersionLastUpdatedLabel}
                            />
                            <FullDate date={props.book.updatedDate}/>
                        </div> }
                        { props.license && <div>
                            <HeadingFive level={3} titleDescriptor={messages.bookPublishingDetailsLicenseLabel}/>
                            <span><WrappedMessage message={getContentLicenseLabel(props.license!)} /></span>
                        </div> }
                    </ElementsInColumnContainer>
                    <ElementsInColumnContainer>
                        { printEdition && <BookEditionDetails
                            titleDescriptor={messages.bookPublishingDetailsPrintEditionLabel}
                            edition={printEdition}
                        /> }
                        { digitalEdition && <BookEditionDetails
                            titleDescriptor={messages.bookPublishingDetailsDigitalEditionLabel}
                            edition={digitalEdition}
                        /> }
                        { ibooksEdition && <BookEditionDetails
                            titleDescriptor={messages.bookPublishingDetailsIBooksEditionLabel}
                            edition={ibooksEdition}
                        /> }
                    </ElementsInColumnContainer>
                </TwoEqualWidthElementsInRowContainer>
            </ContainerWithTitleOutside>
        </div>
    );
};

interface  BookEditionDetailsProps {
    titleDescriptor: MessageDescriptor;
    edition: Edition;
}

const BookEditionDetails: React.FunctionComponent<BookEditionDetailsProps> = (props) => {
    return (
        <div>
            <HeadingFive level={3} titleDescriptor={props.titleDescriptor}/>
            { props.edition.isbn10 &&
                <div>
                    <WrappedMessage message={messages.bookPublishingDetailsIBooksISBN10Label}/>
                    <span>{props.edition.isbn10} </span>
                </div>
            }
            { props.edition.isbn13 &&
                <div>
                    <WrappedMessage message={messages.bookPublishingDetailsIBooksISBN13Label}/>
                    <span>{props.edition.isbn13} </span>
                </div>
            }
        </div>
    );
};

const editionOfType = (editions: Edition[], editionType: EditionEditionTypeEnum) => {
    return editions.find((edition) => {
        return (edition.editionType === editionType);
    });
};
