// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionSlab
 */
export interface SectionSlab {
    /**
     * 
     * @type {string}
     * @memberof SectionSlab
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SectionSlab
     */
    sectionTitle: string;
    /**
     * 
     * @type {string}
     * @memberof SectionSlab
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof SectionSlab
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof SectionSlab
     */
    assetType: SectionSlabAssetTypeEnum;
    /**
     * 
     * @type {Array<ItemResponse>}
     * @memberof SectionSlab
     */
    items?: Array<ItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof SectionSlab
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof SectionSlab
     */
    seeMoreLink: string;
}

export function SectionSlabFromJSON(json: any): SectionSlab {
    return {
        'title': json['title'],
        'sectionTitle': json['section_title'],
        'description': json['description'],
        'order': json['order'],
        'assetType': json['asset_type'],
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<any>).map(ItemResponseFromJSON),
        'slug': json['slug'],
        'seeMoreLink': json['see_more_link'],
    };
}

export function SectionSlabToJSON(value?: SectionSlab): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'section_title': value.sectionTitle,
        'description': value.description,
        'order': value.order,
        'asset_type': value.assetType,
        'items': value.items === undefined ? undefined : (value.items as Array<any>).map(ItemResponseToJSON),
        'slug': value.slug,
        'see_more_link': value.seeMoreLink,
    };
}

/**
* @export
* @enum {string}
*/
export enum SectionSlabAssetTypeEnum {
    Interactives = 'interactives',
    Pathways = 'pathways',
    SimpleCards = 'simple_cards',
    Clusters = 'clusters',
    FeatureCard = 'feature_card'
}


