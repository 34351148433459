import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { LocationDescriptor } from 'history';

import { APIPermissions, DiscussionSubCategory, Thread, ThreadList } from 'labxchange-client';
import { DiscussionsApi } from 'global/api';
import messages from 'discussions/displayMessages';
import { JumpTo, Pagination, PaginationSizeOptions, showErrorMessage, SortOptions } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { WrappedMessage } from 'utils';
import { ThreadComposer } from '../ThreadComposer';
import { ThreadCardList } from '../ThreadCardList';
import { DiscussionBreadcrumb } from '../../../library/components/Breadcrumb';
import { PAGINATION_OPTIONS, ROUTES } from '../../../global/constants';
import { ThreadPinStatusEnum } from '../../models';
import { getCurrentPageFromString, getPaginationSizeFromString } from 'search/utils';
import { RootState } from 'global/state';
import { getUserPermissions } from 'auth/selectors';
import { CommunityLayout } from 'dashboard';
import { intl } from 'i18n';


interface ReduxStateProps {
    userPermissions?: APIPermissions;
}

interface InternalState {
    isComposing: boolean;
    parentNode?: HTMLElement;
    sending: boolean;
    redirectTo?: LocationDescriptor;
    internalLoading: boolean;
}

interface InternalProps {
    subCategory?: DiscussionSubCategory;
    loading: boolean;
    threadList?: ThreadList;
    onThreadsChange: (threads: Thread[]) => void;
    paginationSize: number;
    onPaginationSizeSelect: (paginationSize: number) => void;
    ordering: string;
    onOrderingSelect: (ordering: string) => void;
    currentPage: number;
    onPageSelect: (page: number) => void;
    onThreadDelete: (threadId: number) => void;
}

class DiscussionSubCategoryPageInternal extends React.PureComponent<
    InternalProps & ReduxStateProps, InternalState
> {
    constructor(props: InternalProps & ReduxStateProps) {
        super(props);
        this.state = {
            internalLoading: true,
            sending: false,
            isComposing: false,
        };
    }

    public async componentDidMount() {
        const parentNode = document.body.parentElement;
        if (parentNode && parentNode instanceof HTMLElement) {
            this.setState({parentNode});
        }
    }

    public render() {
        if (this.state.redirectTo) {
            return <Redirect push={true} to={this.state.redirectTo}/>;
        }
        if (this.props.loading) {
            return (
                <CommunityLayout
                    pageTitle={messages.discussionsHomePageTitle}>
                    <WrappedMessage message={uiMessages.uiLoading} />
                </CommunityLayout>
            );
        }

        const pageCount = Math.ceil(
            (this.props.threadList ? this.props.threadList.count : 0) / this.props.paginationSize
        );

        return (this.props.subCategory? (
            <CommunityLayout
                pageTitle={messages.discussionsCategoryPageTitle}
                pageTitleValues={{name: this.props.subCategory.name}}
            >
                <DiscussionBreadcrumb
                    navLinksInfo={[
                        {
                            route: ROUTES.Community.CATEGORY_ROUTE_SLUG(this.props.subCategory.category),
                            message: this.props.subCategory.categoryName? this.props.subCategory.categoryName : '',
                        },
                        {
                            route: '#',
                            message: this.props.subCategory.name,
                        }
                    ]}
                />
                <div className='subcategory-discussion'>
                    <div className='subcategory-discussion-header'>
                        <button data-testid='new-thread' className='btn btn-primary' onClick={this.onClickNewThread}>
                            <WrappedMessage
                                message={messages.discussionsAddNewThread}
                            />
                        </button>
                        {this.props.threadList
                        ?
                            <div className='subcategory-discussion-header-actions'>
                                <span className='subcategory-discussion-header-count'>
                                    <WrappedMessage
                                        message={messages.showingResultCounter}
                                        values={{count: this.props.threadList.count}}
                                    />
                                </span>
                                <PaginationSizeOptions
                                    name={intl.formatMessage(messages.paginationSizeLabel)}
                                    value={this.props.paginationSize}
                                    onChange={this.props.onPaginationSizeSelect}
                                    options={PAGINATION_OPTIONS}
                                />
                                <SortOptions
                                    name={intl.formatMessage(messages.sortByLabel)}
                                    value={this.props.ordering}
                                    onChange={this.props.onOrderingSelect}
                                    options={[
                                        {
                                            label: intl.formatMessage(
                                                messages.recentlySort,
                                            ),
                                            value: 'recently',
                                        }, {
                                            label: intl.formatMessage(
                                                messages.popularitySort,
                                            ),
                                            value: 'popularity',
                                        },
                                    ]}
                                />
                            </div>
                        : null }
                    </div>
                    {this.props.threadList ?
                        <div className='subcategory-discussion-content'>
                            <ThreadCardList
                                threads={this.props.threadList.results}
                                getThreadLink={this.getThreadLink}
                                onPin={this.onPinThread}
                                onDelete={this.props.onThreadDelete}
                            />
                            <div className='subcategory-discussion-pagination'>
                                <JumpTo
                                    pageCount={pageCount}
                                    currentPage={this.props.currentPage}
                                    onPageSelect={this.props.onPageSelect}
                                />
                                <Pagination
                                    pageCount={pageCount}
                                    currentPage={this.props.currentPage}
                                    onPageSelect={this.props.onPageSelect}
                                />
                            </div>
                        </div>
                    : null}
                    <ThreadComposer
                        close={this.closeComposeDrawer}
                        open={this.state.isComposing}
                        parentNode={this.state.parentNode}
                        sending={this.state.sending}
                        onSubmit={this.submitThread}
                        tags={this.props.subCategory.predefinedTags}
                        disableNewTags={!this.props.userPermissions?.profile.canAddTagsToThreads}
                    />
                </div>
            </CommunityLayout>
        ) : null);
    }

    @bind private async onPinThread(threadId: number, pinned: boolean) {
        if (this.props.subCategory === undefined) {
            showErrorMessage(<WrappedMessage message={messages.errorUpdatingThread} />);
        } else {
            try {
                const pinStatus = pinned ? ThreadPinStatusEnum.Pinned : ThreadPinStatusEnum.Default;
                const updatedThread = await DiscussionsApi.threadsPartialUpdate(
                    {
                        id: threadId.toString(),
                        data: {
                            pinStatus,
                        },
                    },
                );

                const threadSet = this.props.threadList ? this.props.threadList.results : [];
                const threads = (threadSet).map((thread) => {
                    if (thread.id === updatedThread.id) {
                        return updatedThread;
                    }
                    return thread;
                });
                this.props.onThreadsChange(threads);
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorUpdatingThread}/>, {exception: err});
            }
        }
    }

    @bind private getThreadLink(threadId: number) {
        if (this.props.subCategory) {
            return ROUTES.Community.SUBCATEGORY_THREAD_ROUTE_SLUG(this.props.subCategory.id, threadId.toString());
        } else {return '#'; }
    }

    @bind private onClickNewThread() {
        this.setState({
            isComposing: !this.state.isComposing,
        });
    }

    @bind private closeComposeDrawer() {
        this.setState({isComposing: false});
    }

    @bind private async submitThread(title: string, content: string, tags?: string[]) {
        this.setState({sending: true});
        if (this.props.subCategory) {
            try {
                const thread = await DiscussionsApi.threadsCreate({
                    data: {
                        subCategory: this.props.subCategory.id,
                        title,
                        content,
                        freeFormTags: tags,
                    },
                });
                this.setState({
                    sending: false,
                    redirectTo: ROUTES.Community.SUBCATEGORY_THREAD_ROUTE_SLUG(this.props.subCategory.id, thread.id.toString()),
                });
                return;
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.errorCreatingThread}/>, {exception: err});
                this.setState({sending: false});
            }
        }
    }
}

interface MatchProps {
    subCategory: string;
}

interface Props  extends RouteComponentProps<MatchProps> {
}

interface State {
    subCategory?: DiscussionSubCategory;
    loading: boolean;
    threadList?: ThreadList;
    paginationSize: number;
    currentPage: number;
    ordering: string;
}

class DiscussionSubCategoryPageHandler extends React.PureComponent<Props & ReduxStateProps , State> {
    constructor(props: Props) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);

        this.state = {
            loading: false,
            paginationSize: getPaginationSizeFromString(params.get('size')),
            currentPage: getCurrentPageFromString(params.get('page')),
            ordering: params.get('ordering') || 'recently',
        };
    }

    public async componentDidMount() {
        await this.loadSubCategoryData();
    }

    public render() {
        return (
            <DiscussionSubCategoryPageInternal
                subCategory={this.state.subCategory}
                threadList={this.state.threadList}
                loading={this.state.loading}
                onThreadsChange={this.onThreadsChange}
                paginationSize={this.state.paginationSize}
                onPaginationSizeSelect={this.onPaginationSizeSelect}
                ordering={this.state.ordering}
                onOrderingSelect={this.onOrderingSelect}
                currentPage={this.state.currentPage}
                onPageSelect={this.onPageSelect}
                onThreadDelete={this.onThreadDelete}
                {...this.props}
            />
        );
    }

    @bind private onPageSelect(page: number) {
        this.setState({currentPage: page}, () => {
            const params = new URLSearchParams();
            params.set('page', this.state.currentPage.toString());
            this.updateURLParams(params);
        });
    }

    @bind private onOrderingSelect(ordering: string) {
        this.setState({ordering}, () => {
            const params = new URLSearchParams();
            params.set('ordering', this.state.ordering.toString());
            this.updateURLParams(params);
        });
    }

    private updateURLParams(newParams: URLSearchParams) {
        if (this.state.subCategory)
        this.props.history.push(
            ROUTES.Community.SUBCATEGORY_ROUTE_SLUG(this.state.subCategory.id) +
            `?${newParams.toString()}`,
        );
        this.loadThreadsData();
    }

    public async loadThreadsData() {
        this.setState({loading: true});
        if (this.state.subCategory) {
            try {
                const threadList = await DiscussionsApi.threadsList({
                    subCategory: this.state.subCategory.id,
                    currentPage: this.state.currentPage,
                    ordering: this.state.ordering,
                    paginationSize: this.state.paginationSize,
                });
                this.setState({loading: false, threadList});
            } catch (err) {
                if (err.status !== 404) {
                    showErrorMessage(<WrappedMessage message={messages.loadFailed}/>);
                    throw err;
                }
                this.setState({loading: false, threadList: undefined});
            }
        }
    }

    @bind private async onThreadDelete(threadId: number) {
        this.setState({loading: true});
        try {
            await DiscussionsApi.threadsDelete({
                id: threadId.toString(),
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.errorDeletingThread} />, {exception: err});
        }
        await this.loadThreadsData();
        this.setState({loading: false});
    }

    @bind private onThreadsChange(threads: Thread[]) {
        this.setState({
            threadList: update(
                this.state.threadList,
                {results: {$set: threads}}
            ),
        });
    }

    @bind private onPaginationSizeSelect(paginationSize: number) {
        this.setState({paginationSize}, () => {
            const params = new URLSearchParams();
            params.set('pagination_size', this.state.paginationSize.toString());
            this.updateURLParams(params);
        });
    }

    private async loadSubCategoryData() {
        this.setState({loading: true});

        try {
            const subCategoryId = this.props.match.params.subCategory;
            const subCategory = await DiscussionsApi.subcategoriesRead({id: subCategoryId});
            const threadList = await DiscussionsApi.threadsList({
                    subCategory: subCategory.id,
                    currentPage: this.state.currentPage,
                    ordering: this.state.ordering,
                    paginationSize: this.state.paginationSize,
                });
            this.setState({
                subCategory,
                threadList,
            });
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.loadSubCategoryFailed}/>, {exception: err});
        }

        this.setState({loading: false});
    }
}

export const DiscussionSubCategoryPage = connect<ReduxStateProps, {}, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
    }),
)(DiscussionSubCategoryPageHandler);
