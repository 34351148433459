// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    TotalScoreItem,
    TotalScoreItemFromJSON,
    TotalScoreItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface TotalScoreResponse
 */
export interface TotalScoreResponse {
    /**
     * 
     * @type {Array<TotalScoreItem>}
     * @memberof TotalScoreResponse
     */
    items: Array<TotalScoreItem>;
    /**
     * 
     * @type {number}
     * @memberof TotalScoreResponse
     */
    totalScore: number;
    /**
     * 
     * @type {number}
     * @memberof TotalScoreResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof TotalScoreResponse
     */
    count: number;
}

export function TotalScoreResponseFromJSON(json: any): TotalScoreResponse {
    return {
        'items': (json['items'] as Array<any>).map(TotalScoreItemFromJSON),
        'totalScore': json['total_score'],
        'page': json['page'],
        'count': json['count'],
    };
}

export function TotalScoreResponseToJSON(value?: TotalScoreResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'items': (value.items as Array<any>).map(TotalScoreItemToJSON),
        'total_score': value.totalScore,
        'page': value.page,
        'count': value.count,
    };
}


