// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SaveUserStateVideo
 */
export interface SaveUserStateVideo {
    /**
     * 
     * @type {number}
     * @memberof SaveUserStateVideo
     */
    savedVideoPosition?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveUserStateVideo
     */
    speed?: number;
}

export function SaveUserStateVideoFromJSON(json: any): SaveUserStateVideo {
    return {
        'savedVideoPosition': !exists(json, 'saved_video_position') ? undefined : json['saved_video_position'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
    };
}

export function SaveUserStateVideoToJSON(value?: SaveUserStateVideo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'saved_video_position': value.savedVideoPosition,
        'speed': value.speed,
    };
}


