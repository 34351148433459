import { defineMessages } from 'react-intl';

const messages = defineMessages({
    popularity: {
        id: 'sortPopularity',
        defaultMessage: 'Popularity',
        description: 'sort by popularity',
    },
    ncontent: {
        id: 'sortNContent',
        defaultMessage: 'Content',
        description: 'sort by quantity of content',
    },
});

export default messages;
