// import {ROUTES} from 'global/constants';
import messages from './displayMessages';
import * as React from 'react';
import { WrappedMessage } from 'utils';
import { Button } from 'ui/components';
import { StandardPageLayout } from 'ui/components';

interface Props {
    statusCode: number;
}

/**
 * Library Content not found page
 */
export class InvalidContent extends React.PureComponent<Props> {

    public static defaultProps = {
        statusCode: 404,
    };

    public render() {
        return (
            <div className='library-invalid-content-wrapper'>
                <StandardPageLayout
                    headerFeature={
                        <div className='library-invalid-content'>
                            <h1>{this.props.statusCode}</h1>
                            <h4><WrappedMessage message={messages.missingContentDescription} /></h4>
                            <Button
                                btnStyle='outline'
                                className='action'
                                label={messages.backToHomeButton}
                                href='/'
                            />
                        </div>
                    }
                    headerFeatureContainer={true}
                    headerContainerPaddingEnabled={false}
                    backgroundStyle='navy-gradient'
                    bottomContent={
                        <div className='library-invalid-content-footer'>
                            <img src='/assets/images/library-error.svg' alt='' />
                            <div className='empty-overlay'></div>
                        </div>
                    }
                >
                </StandardPageLayout>
            </div>
        );
    }
}
