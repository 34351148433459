import { ROUTES } from 'global/constants';
import { history } from 'global/history';
import { intl } from 'i18n';
import * as React from 'react';
import { Button } from 'ui/components';
import { AuthPageLayout } from 'ui/components/AuthPageLayout';
import { getCookie } from 'utils';
import messages from '../displayMessages';

interface Props extends React.PropsWithChildren {
    type: 'country' | 'sdn' | 'blocked' | 'age';
}

export const AuthFailView: React.FunctionComponent<Props> = (props) => {
    let title;
    let bodyText;
    let bodyValues;
    let continueText = messages.lxAuthFailText1;
    switch (props.type) {
        case 'country':
            title = messages.lxAuthUnableToContinueTitle;
            bodyText = messages.lxAuthCountryFailText;
            bodyValues = {};
            break;
        case 'sdn':
            title = messages.lxAuthTreasuryFailTitle;
            bodyText = messages.lxAuthTreasuryFailText;
            bodyValues = { email: <a href='mailto:ofac.reconsideration@treasury.gov'>ofac.reconsideration@treasury.gov</a> };
            break;
        case 'blocked':
            const email = getEmailCookie() || history.location.state?.email || ' ';
            title = messages.lxAuthBlockFailTitle;
            bodyText = messages.lxAuthBlockFailText;
            bodyValues = { email: <b>{email}.<br /><br /></b> };
            break;
        case 'age':
            title = messages.lxAuthUnableToContinueTitle;
            bodyText = messages.lxAuthAgeFailText;
            continueText = messages.lxAuthFailText2;
            break;
    }

    return (
        <AuthPageLayout
            imageUrl='/assets/images/auth-layouts/fail-layout.svg'
            title={intl.formatMessage(title)}
        >
            <div className='auth-fail-page'>
                <div className='bar' />
                <div className='body-text'>
                    {intl.formatMessage(bodyText, bodyValues)}
                </div>
                <div className='continue-text'>
                    {intl.formatMessage(continueText)}
                </div>
                <div className='buttons'>
                    <Button
                        btnStyle='outline'
                        label={messages.lxAuthFailVisitLibraryButton}
                        onClick={onVisitLibrary}
                    />
                    <Button
                        className='last'
                        btnStyle='primary'
                        label={messages.lxAuthFailExploreButton}
                        onClick={onExploreContent}
                    />
                </div>
            </div>
        </AuthPageLayout>
    );
};

const onVisitLibrary = () => {
    history.push(ROUTES.Library.HOME);
};

const onExploreContent = () => {
    history.push(ROUTES.Explore.HOME);
};

const getEmailCookie = () => {
    return getCookie('blocked_user_email')?.replace(/\"/gi, '');
};
