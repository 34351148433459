import bind from 'bind-decorator';
import classNames from 'classnames';
import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { createAnalyticsEventWithDelay } from 'tracking/utils';
import { Icon } from 'elements';
import { ROUTES } from 'global/constants';
import { getItemTypeMeta } from 'items/models';
import {
    AnalyticsEventEventTypeEnum,
    APIPermissions,
    ItemMetadata,
    ItemUserAttributes,
    ModerationRequestContentTypeEnum,
    OrganizationSummaryOrganizationTypeEnum,
    ItemMetadataTypeEnum,
} from 'labxchange-client';
import {
    DeleteButton,
    ItemIcon,
} from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import { ReportButton } from 'moderation/components';
import { UI_IS_SM } from 'ui/breakpoints';
import {
    KebabMenu,
    KebabMenuItem,
    FavoriteButton,
    FeedbackModal,
    FeedbackButton
} from 'ui/components';
import { ClipLines, ShareModal } from 'ui/components';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage, getMaxTextLinesInElement, showCardDescription } from 'utils';
import messages from '../../displayMessages';
import Skeleton from 'react-loading-skeleton';
import {ProgressBar} from '../ProgressBar';
import { intl } from 'i18n';

function durationString(duration: number): string {
    let hours = 0;
    if (duration >= 3600) {
        hours = Math.floor(duration / 3600);
        duration = duration % 3600;
    }
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    let durationStr = '';
    if (hours > 0) {
        durationStr += hours + ':';
    }
    durationStr += (minutes > 9 ? minutes : '0' + minutes);
    durationStr += ':';
    durationStr += (seconds > 9 ? seconds : '0' + seconds);
    return durationStr;
}

export interface CardProps extends React.PropsWithChildren {
    metadata?: ItemMetadata;
    notes?: string;
    userAttributes?: ItemUserAttributes;
    // miniMobileMode property provide smallest UI displaying of a card
    miniMobileMode?: boolean;
    // mobileViewMode property force triggering of mobile view (not miniMobileMode)
    mobileViewMode?: boolean;
    detailUrl?: string;
    openDetailUrlInNewTab?: boolean;
    showMenuButton?: boolean;
    onClickPreview?: () => void;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
    menu?: React.ReactNode;
    accessory?: React.ReactNode;
    pathwayMetadata?: ItemMetadata;
    isPublicContentOnly?: boolean;
    isUserLoggedIn?: boolean;
    userPermissions?: APIPermissions;
    showPathwayItemsMenu?: boolean;
    showLibraryActions?: boolean;
    analyticsEventType?: AnalyticsEventEventTypeEnum;
    hideFavoriteButton?: boolean;
    selectedStyle?: boolean;
    onDelete?: () => void;
    onCardClick?: () => void;
    highlightedKeywords?: ReadonlySet<string>;
    showSkeleton?: boolean;
    showCompletion?: boolean;
    forceShowCompletion?: boolean;
    isEditModalOpen?: boolean;
    isClipped?: boolean;
    onClickClipVideo?: () => void;
    hasNotes?: boolean;
    videoStartTime?: number;
    videoStopTime?: number;
    isKebabMenuVisible?: boolean;
    onClickViewItem?: () => void;
    onClickLaunchItem?: () => void;
    isAssetCard?: boolean;
    showFeedbackButton?: boolean;
    curriculumSlug?: string;
}

interface State {
    isMobileView: boolean;
    showKebabMenu: boolean;
    shareModalVisible: boolean;
    headerLines?: number;
    showFeedbackModal: boolean;
    feedbackSubmitted: boolean;
}

type DetailsLinkProps = Partial<NavLinkProps>;

enum DescriptionLines {
    Zero = '0',
    One = '1',
    Two = '2',
    Three = '3'
}

/**
 * The Card displays an overview of a particular piece of content.
 *
 * It is intended to be displayed in a list and to be generic
 * enough to handle multiple types of library content.
 */
export class Card extends React.PureComponent<CardProps, State> {

    public static defaultProps = {
        miniMobileMode: false,
        mobileViewMode: false,
        showCompletion: true,
    };

    // TODO: Temporary solution to display properly notes
    // Will be resolved in the separate ticket.
    private notesLineHeight = 20;
    private defaultImage = '/assets/images/default-featured.png';

    // This matches Bootstrap's definition of small (sm) breakpoint.
    private mediaQuery = UI_IS_SM;
    private headerRef: React.RefObject<any>;

    constructor(props: CardProps) {
        super(props);
        this.state = {
            isMobileView: false,
            showKebabMenu: false,
            shareModalVisible: false,
            showFeedbackModal: false,
            feedbackSubmitted: false,
        };
        this.headerRef = React.createRef();
    }

    public componentDidUpdate() {
        if (this.headerRef.current) {
            const lines = getMaxTextLinesInElement(this.headerRef.current);
            this.setState({headerLines: lines});
        }
    }

    public componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        window.addEventListener('resize', this.handleResize);
        this.setIsMobileView();
        if (this.headerRef.current) {
            const lines = getMaxTextLinesInElement(this.headerRef.current);
            this.setState({headerLines: lines});
        }
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
        window.removeEventListener('resize', this.handleResize);
    }

    public render() {
        const item = this.props.metadata;
        const pathway = this.props.pathwayMetadata;
        const detailsLinkProps: DetailsLinkProps = {};

        // We need to create space for a completion bar if it is available.
        const showCompletion = this.props.forceShowCompletion || (this.props.showCompletion && this.props.userAttributes?.completion);
        let descriptionLines: DescriptionLines;
        if (!this.state.headerLines || this.state.headerLines <= 1) {
            descriptionLines = showCompletion ? DescriptionLines.Two : DescriptionLines.Three;
        }
        else if (this.state.headerLines === 2) {
            descriptionLines = showCompletion ? DescriptionLines.One : DescriptionLines.Two;
        }
        else {
            descriptionLines = showCompletion ? DescriptionLines.Zero : DescriptionLines.One;
        }
        if (this.props.openDetailUrlInNewTab) {
            detailsLinkProps.target = '_blank';
        }
        const showEditMenu = !this.state.isMobileView && (
            this.props.accessory === undefined && this.props.showMenuButton);

        let defaultCardImage = this.defaultImage;
        if (item) {
            defaultCardImage = getItemTypeMeta(item.type)?.defaultCardImage || this.defaultImage;
        }
        const cardImage = item?.imageUrl || defaultCardImage;

        const showDescription = showCardDescription();
        return (
            <div data-testid='card' className={classNames('card-item',
                    {'card-item-selected': this.props.selectedStyle},
                    {'card-item-mini-mobile-mode': this.props.miniMobileMode},
                    {'card-item-mobile': this.state.isMobileView},
                    {'card-has-accessory': !!this.props.accessory},
                    {'card-completed': showCompletion && this.props.userAttributes?.completion === 1},
                    {'skeleton': this.props.showSkeleton},
                    {'show-description': showDescription},
                    {'card-item-overflow': this.props.showLibraryActions},
                    {'show-feedback-btn': this.props.showFeedbackButton},
                )}
            >
                {(this.props.metadata && this.state.shareModalVisible) &&
                    <ShareModal
                        getItemUrl={() => detailUrlForEntity(this.props.metadata!, pathway?.id)}
                        itemTitle={this.props.metadata.title}
                        itemDescription={this.props.metadata.description}
                        itemImageUrl={this.props.metadata.imageUrl}
                        onClose={this.onCloseShareModal}
                    />
                }

                {this.state.isMobileView && !this.props.miniMobileMode && !this.props.showLibraryActions
                    ? this.renderMobileDetails(detailsLinkProps)
                    : null
                }
                <div className='image' style={this.props.showSkeleton ? {padding: 0} : { backgroundImage: `url('${cardImage}')` }}>
                    {this.props.showSkeleton? <div className='card-image-skeleton'><Skeleton /></div> :
                    <>
                        <NavLink to={this.props.detailUrl ? this.props.detailUrl : '#'}
                            className='image-link item-detail-page-link'
                            aria-hidden='true'
                            draggable={false}
                            tabIndex={-1}
                            {...detailsLinkProps}
                            onClick={this.onCardClick}
                        >
                            {/*
                                This is a transparent rectangle that covers the image, to turn it into a link.
                                We can't just wrap the <a> around the whole <div .image>, because the <div>
                                contains other interactive elements (i.e. the star button), and the HTML5 spec
                                quite rightly says that an <a> should never contain other interactive elements
                                like buttons or links. We hide this link from screen readers since they can use
                                the normal <h2> item heading link, which is more descriptive.
                            */}
                        </NavLink>
                        {(item && this.props.userAttributes && !this.props.hideFavoriteButton &&
                            !this.props.showLibraryActions && !this.props.isAssetCard) &&
                            <FavoriteButton
                                itemId={item.id}
                                isFavorite={this.props.userAttributes.isFavorite}/>
                        }
                        {item && this.props.userAttributes && this.props.isAssetCard && (
                            <FavoriteButton
                                btnStyle='svg'
                                itemId={item.id}
                                isFavorite={this.props.userAttributes.isFavorite}
                                showLabel={false}
                                showTooltip={false}
                            />
                        )}

                        {this.props.miniMobileMode && this.state.isMobileView ?
                            this.renderMiniMobileModeImageOverlays() :
                            null
                        }
                        <div className='media-extra-info-wrapper'>
                            {this.renderMediaExtraInfo()}
                        </div>
                        <div className='media-info-wrapper'>
                            {this.renderPrivateChip()}
                            {this.renderItemTypeInfo()}
                        </div>
                    </>}
                </div>
                <div className={`content-container ${this.props.showSkeleton ? 'skeleton' : ''}`}>
                    {this.props.showSkeleton? <Skeleton count={3} /> :
                    <>
                        {item &&
                            <div className='details' dir='auto'>
                                <NavLink
                                    to={this.props.detailUrl ? this.props.detailUrl : '#'}
                                    {...detailsLinkProps}
                                    className='details-link item-detail-page-link'
                                    aria-label={item.title}
                                    onClick={this.onCardClick}
                                    draggable={false}
                                />
                                <div className='card-item-header' data-testid={item.title} dir='auto'>
                                    <h2 ref={this.headerRef}>
                                        <ClipLines
                                            lines={showDescription ? '3' : '4'}
                                            text={item.title}
                                            highlightedKeywords={this.props.highlightedKeywords}
                                        />
                                    </h2>
                                    {(this.state.isMobileView && !this.props.showLibraryActions)
                                        ? null
                                        : <p className='authors'>{this.renderAuthorInfo(detailsLinkProps)}</p>
                                    }
                                    {this.props.isAssetCard && <small>{this.renderAuthorInfo(detailsLinkProps)}</small>}
                                </div>
                                <div
                                    className={`description ${showEditMenu ? 'description-with-menu' : ''} ${this.props.notes && pathway ? 'description-notes' : ''}`}
                                    dir='auto'
                                >
                                    {
                                        // If the pathway author has added a note to this specific item, show that:
                                        this.props.notes && pathway ?
                                            <>
                                                {pathway.source && pathway.source.sourceOrganizations && pathway.source.sourceOrganizations[0].organization.logoUrl ?
                                                    <img
                                                        className='pathway-note-avatar rounded-circle'
                                                        src={pathway.source.sourceOrganizations[0].organization.logoUrl}
                                                        style={{backgroundColor: pathway.source.sourceOrganizations[0].organization.brandColor}}
                                                        alt=''
                                                    />
                                                    : pathway.authors.length > 0 && pathway.authors[0].username ?
                                                        <UserAvatar
                                                            username={pathway.authors[0].username}
                                                            diameter='36px'
                                                        />
                                                        : null}
                                                <div className='note'>
                                                    <ClipLines
                                                        lines={descriptionLines}
                                                        text={'“' + this.props.notes + '”'}
                                                    />
                                                </div>
                                            </>
                                            : // Otherwise, just show the normal item description:
                                            <ClipLines
                                                lines={descriptionLines}
                                                text={item.description}
                                                highlightedKeywords={this.props.highlightedKeywords}
                                            />
                                    }
                                </div>
                                {showCompletion ? <div className={`card-progress-bar${this.state.isMobileView ? '-mobile' : ''}`}>
                                    <ProgressBar percentage={(Math.floor((this.props.userAttributes?.completion? this.props.userAttributes.completion : 0) * 100)).toString().concat('%')} />
                                </div> : null}

                                {this.props.showPathwayItemsMenu ? this.renderPathwayItemsMenu(false): null}
                                {this.props.showLibraryActions ? this.renderLibraryActions(false): null}

                                {(!this.state.isMobileView || this.props.miniMobileMode) && !this.props.showLibraryActions && this.renderSourceBadge()}
                            </div>
                        }
                        {
                            this.props.accessory ? this.props.accessory : null
                        }
                    </>}
                    {this.props.showLibraryActions ? this.renderLibraryActions(true) : null}
                    {this.props.showFeedbackButton &&
                        <>
                            <FeedbackButton
                                position='inline'
                                variant={
                                    (
                                        this.state.feedbackSubmitted ||
                                        this.props.userAttributes?.hasUserSubmittedFeedback
                                    ) ? 'submitted' : 'pending'
                                }
                                onClick={() => this.setState({showFeedbackModal: true})}
                            />
                            {this.state.showFeedbackModal && this.props.curriculumSlug && item &&
                                <FeedbackModal
                                    assetId={item.id}
                                    curriculumSlug={this.props.curriculumSlug}
                                    onClose={() => this.setState({showFeedbackModal: false})}
                                    onSubmit={() => this.setState({feedbackSubmitted: true})}
                                />
                            }
                        </>
                    }
                </div>

                {this.props.menu ? this.props.menu : null}
                {this.props.children}

                {!this.props.showSkeleton && !this.props.showPathwayItemsMenu && !this.props.showLibraryActions && !this.state.isMobileView && this.props.showMenuButton && this.props.isKebabMenuVisible ?
                    this.renderKebabMenu()
                : null}

                {this.props.showPathwayItemsMenu ?
                    <div className='white-box bottom-menu'>
                        {this.renderPathwayItemsMenu(true)}
                    </div>
                : null}
            </div>
        );
    }

    private renderLibraryActions(isMobile: boolean): React.ReactNode {
        if (this.props.showSkeleton && isMobile) return null;

        const className = isMobile ? 'bottom-library-actions' : 'library-actions';
        return (
            <div className={className}>
                <button
                    className='unstyled tooltip-button'
                    onClick={this.props.onClickViewItem}
                    aria-label={intl.formatMessage(messages.ariaViewButton)}
                >
                    <Icon name='eye-navy' zoom='18' />
                    {isMobile && <span className='action-label'><WrappedMessage message={messages.libraryCardMenuViewTitle} /></span>}
                    {!isMobile &&
                        <span className='tooltip-span'>
                            <WrappedMessage message={messages.libraryCardMenuViewTitle} />
                        </span>
                    }
                </button>
                {(this.props.metadata?.type === ItemMetadataTypeEnum.Simulation
                    || this.props.metadata?.type === ItemMetadataTypeEnum.Interactive
                 ) && (
                    <button
                        className='unstyled tooltip-button'
                        onClick={this.props.onClickLaunchItem}
                        aria-label={intl.formatMessage(messages.ariaLaunchButton)}
                    >
                        <Icon name='launch' zoom='18' />
                        {isMobile &&  <span className='action-label'><WrappedMessage message={messages.libraryCardMenuLaunchTitle} /></span>}
                        {!isMobile &&
                            <span className='tooltip-span'>
                                <WrappedMessage message={messages.libraryCardMenuLaunchTitle} />
                            </span>
                        }
                    </button>
                )}
                {(this.props.metadata && this.props.userAttributes) && (
                    <FavoriteButton
                        itemId={this.props.metadata.id}
                        isFavorite={this.props.userAttributes.isFavorite}
                        btnStyle='svg'
                        showLabel={isMobile}
                        showTooltip={!isMobile}
                        titleRemoveFavorite={messages.actionUnfavorite}
                    />
                )}
            </div>
        );
    }

    @bind private setIsMobileView(): void {
        this.setState({isMobileView: Boolean(this.mediaQuery.matches || this.props.mobileViewMode)});
    }

    @bind private handleResize(): void {
        if (this.headerRef.current) {
            const lines = getMaxTextLinesInElement(this.headerRef.current);
            this.setState({headerLines: lines});
        }
    }

    private renderSourceBadge() {
        if(this.props.metadata) {
            const source = this.props.metadata.source;
            // Only show the source badge if the menu edit buttons aren't displayed
            // (otherwise they will be hidden) and if there is a source
            // organization who is a sponsor or a partner.
            const sourceOrg = source && source.sourceOrganizations && source.sourceOrganizations[0].organization;
            if (!this.props.showPathwayItemsMenu && !this.props.showLibraryActions &&
                sourceOrg &&
                (
                    sourceOrg.organizationType === OrganizationSummaryOrganizationTypeEnum.SPONSOR ||
                    sourceOrg.organizationType === OrganizationSummaryOrganizationTypeEnum.PARTNER
                )
            ) {
                return (<>
                    <div className='source-badge'
                        style={{backgroundColor: sourceOrg.brandColor || 'transparent'}}/>
                    <img
                        className='source-badge-img'
                        alt={intl.formatMessage(
                            messages.orgLogoAltBadge,
                            {name: sourceOrg.name},
                        )}
                        src={sourceOrg.badgeIconUrl ? sourceOrg.badgeIconUrl : sourceOrg.logoUrl}
                        draggable={false}
                    />
                </>);
            } else {
                return null;

            }
        }
        return null;
    }

    private renderKebabMenu() {
        if (this.props.metadata && this.props.userAttributes) {
            return (
                <KebabMenu
                    keepExpanded={this.state.showKebabMenu}
                    darkKebab={true}
                >
                    <KebabMenuItem
                        iconName='reply'
                        disabled={!this.props.metadata.isPublic}
                        message={messages.actionShare}
                        onClick={this.onOpenShareModal}
                    />
                    {!this.props.userAttributes.isItemOwner && this.props.isUserLoggedIn ?
                        <ReportButton
                            asKebab={true}
                            itemId={this.props.metadata.id}
                            itemTitle={this.props.metadata.title}
                            itemType={ModerationRequestContentTypeEnum.LibraryItemmetadata}
                            label={messages.actionReport}
                            onOpen={() => this.setState({showKebabMenu: true})}
                            onClose={() => this.setState({showKebabMenu: false})}
                        />
                        : null}
                    {this.props.isUserLoggedIn && this.props.userAttributes.canEditObject ?
                        <>
                            <KebabMenuItem
                                iconName='pencil'
                                disabled={false}
                                message={messages.libraryCardMenuEditTitle}
                                href={this.props.detailUrl + '/edit'}
                            />
                            <DeleteButton
                                item={this.props.metadata}
                                onOpen={() => this.setState({showKebabMenu: true})}
                                onClose={() => this.setState({showKebabMenu: false})}
                                onDelete={this.props.onDelete}
                            />
                        </>
                        : null}
                </KebabMenu>
            );
        }
        return null;
    }

    @bind private onOpenShareModal() {
        this.setState({
            shareModalVisible: true,
        });
    }

    @bind private onCloseShareModal() {
        this.setState({
            shareModalVisible: false,
        });
    }

    private renderMiniMobileModeImageOverlays(): React.ReactNode {
        if (this.props.metadata && this.props.detailUrl) {
            return (
                <>
                    <div className='mini-mobile-mode-title'>
                        <h2>
                            <NavLink to={this.props.detailUrl}
                                     aria-label={this.props.metadata.title}>
                                <ClipLines lines='3' text={this.props.metadata.title}/>
                            </NavLink>
                        </h2>
                    </div>
                </>
            );
        } else {
            return null;
        }
    }

    private renderAuthorInfo(detailsLinkProps: DetailsLinkProps, showLink: boolean = false): React.ReactNode {
        if (this.props.metadata && this.props.detailUrl) {
            if (this.props.metadata.source) {
                const sourceOrg = this.props.metadata.source.sourceOrganizations && this.props.metadata.source.sourceOrganizations[0].organization;
                return showLink ? (
                    <NavLink to={this.props.detailUrl}
                             className='image-link'
                             aria-hidden='true'
                             tabIndex={-1}
                             {...detailsLinkProps}
                    >
                        {sourceOrg.name}
                    </NavLink>
               ) : sourceOrg.name;
            }

            if (this.props.metadata.authors.length === 0) {
                return null;
            }

            const author = this.props.metadata.authors[0];
            let authorLink;
            if (author.username) {
                authorLink = <NavLink to={ROUTES.Users.PROFILE_SLUG(author.username)}>{author.fullName}</NavLink>;
            } else {
                authorLink = <span>{author.fullName}</span>;
            }

            const authorCount = this.props.metadata.authors.length;
            if (authorCount > 1) {
                return <WrappedMessage message={messages.libraryCardAuthors}
                                       values={{firstAuthor: authorLink, count: authorCount - 1}}/>;
            } else {
                return authorLink;
            }
        }
        return null;
    }

    /**
     * Render the details seen on some mobile layouts, as well as on desktop for things
     * like the Explore page.
     */
    private renderMobileDetails(detailsLinkProps: DetailsLinkProps): React.ReactNode {
        if (this.props.metadata) {
            const source = this.props.metadata.source;
            const firstAuthor = this.props.metadata.authors[0];
            const logoUrl = source && source.sourceOrganizations && source.sourceOrganizations[0].organization.logoUrl;

            let authorImage = <UserAvatar/>;
            if (source && logoUrl) {
                // TODO: use actual avatar component
                // - may need to generalize or split component for composability
                authorImage = <img
                    className='rounded-circle user-avatar-image normal'
                    src={logoUrl} alt=''
                />;
            } else if (firstAuthor && firstAuthor.username) {
                authorImage = <UserAvatar username={firstAuthor.username}/>;
            }

            return (
                <div className='mobile-details'>
                    <p className='author-image'>
                        {this.props.showSkeleton ? <Skeleton circle={true}/> : authorImage}
                    </p>
                    <p className='authors'>
                        {this.props.showSkeleton ? <Skeleton/> : this.renderAuthorInfo(detailsLinkProps)}
                    </p>
                    {!this.props.showSkeleton && this.props.showMenuButton && this.props.isKebabMenuVisible ?
                        this.renderKebabMenu()
                        : null}
                </div>
            );
        }
        return null;
    }

    /**
     * Render an icon and description indicating what type of item this is.
     * If it's a pathway/cluster, it will mention how many items it contains.
     */
    private renderItemTypeInfo(): React.ReactNode {
        if (this.props.metadata) {
            return (
                <div className='media-info'>
                    <ItemIcon itemType={this.props.metadata.type} zoom='14'/>
                    <span>
                        <WrappedMessage
                            message={getItemTypeMeta(this.props.metadata.type).name}
                            values={{count: this.props.metadata.itemCount || 0}}
                        />
                    </span>
                </div>
            );
        }
        return null;
    }

    private renderPrivateChip(): React.ReactNode {
        if (this.props.metadata && this.props.metadata.isPublic === false) {
            return (
                <div className='media-info'>
                    <Icon name='eye-closed' zoom='14'/>
                    <span>
                        <WrappedMessage message={messages.privateIconText} />
                    </span>
                </div>
            );
        }
        return null;
    }

    private renderMediaExtraInfo(): React.ReactNode {
        if (this.props.metadata) {
            if (!this.props.metadata.duration) { // Hide duration if it's undefined (non-video) or 0 (not auto-detected)
                return null;
            }
            let videoDuration = this.props.metadata.duration;
            if (this.props.isClipped && this.props.videoStartTime && this.props.videoStopTime) {
                videoDuration = this.props.videoStopTime - this.props.videoStartTime;
            }
            return <div className='media-extra-info'>{durationString(videoDuration)}</div>;
        }
        return null;
    }

    /**
     * Render the items of the pathway menu. There are two kinds of pathway menus depending of the size of
     * the device. One to the right of the card (desktop) and one below the card (mobile).
     * Set [isMobile] to true to render the mobile pathway, otherwise to render the desktop pathway.
     */
    private renderPathwayItemsMenu(isMobile: boolean): React.ReactNode {
        let className;
        if (isMobile) {
            className = 'bottom-pathway-items-menu';
        }
        else {
            className = 'pathway-items-menu';
        }
        return (
            <div className={className}>
                <button className='unstyled tooltip-button' onClick={this.props.onClickPreview} >
                    <Icon name='eye-navy' zoom='18' />
                    <span className='tooltip-span'>
                        {intl.formatMessage(messages.libraryCardMenuViewTitle)}
                    </span>
                </button>
                {this.props.metadata?.type === ItemMetadataTypeEnum.Video && (
                    <button className='unstyled tooltip-button' onClick={this.props.onClickClipVideo} >
                        <Icon name={this.props.isClipped ? 'clip-active' : 'clip'} zoom='18' />
                        <span className='tooltip-span'>
                            {intl.formatMessage(messages.libraryCardMenuClipVideoTitle)}
                        </span>
                    </button>
                )}
                <button className='unstyled tooltip-button' onClick={this.props.onClickEdit} >
                    <Icon name={this.props.hasNotes ? 'notes-active' : 'notes'} zoom='18'/>
                    <span className='tooltip-span'>
                        {intl.formatMessage(messages.libraryCardMenuNotesTitle)}
                    </span>
                </button>
                <button className='unstyled tooltip-button' onClick={this.props.onClickDelete} >
                    <Icon name='trashcan-navy' zoom='18'/>
                    <span className='tooltip-span'>
                        {intl.formatMessage(messages.libraryCardMenuDeleteTitle)}
                    </span>
                </button>
            </div>
        );
    }

    @bind private onCardClick(event: React.MouseEvent) {
        if (this.props.metadata) {
            createAnalyticsEventWithDelay({
                data: {
                    eventType: this.props.analyticsEventType ? (
                        this.props.analyticsEventType
                    ) : AnalyticsEventEventTypeEnum.AssetCardClicked,
                    objectId: this.props.metadata.id,
                    data: {
                        url: window.location.href,
                        pathname: window.location.pathname,
                    },
                },
            });
            if (this.props.onCardClick) {
                this.props.onCardClick();
            }
        }
    }
}

///////////////////////////////////////

export interface FauxCardProps extends React.PropsWithChildren {
    miniMobileMode?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isError: boolean;
    isRequired?: boolean;
    ariaLabel?: string;
}

/**
 * The FauxCard is an empty Card which can be used to hint where additional
 * content can be added to a list, such as in a PathwayEditPage.
 */
export class FauxCard extends React.PureComponent<FauxCardProps> {

    public static defaultProps = {
        miniMobileMode: false,
        isError: false,
        isRequired: false,
    };

    public render() {
        return (
            <button className={classNames(
                    'faux-card-item faux-card-item-link',
                    {
                        'card-item-mini-mobile-mode': this.props.miniMobileMode,
                        'faux-card-error': this.props.isError,
                        'required-status': this.props.isRequired,
                    },
                )}
                onClick={this.props.onClick}
                {...(this.props.ariaLabel && {'aria-label': this.props.ariaLabel})}
            >
                    {this.props.children}
            </button>
        );
    }
}
