import {ROUTES} from 'global/constants';
import { ClassroomDetail } from 'labxchange-client';
import * as React from 'react';
import { Route } from 'react-router';
import { Icon, NavTabs, NavTabsLink, ToggleSwitch } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { intl } from 'i18n';
import allMessages from '../../displayMessages';

interface Props {
    classroom: ClassroomDetail;
    learnerViewEnabled: boolean;
    onLeaveButtonPressed: () => void;
    onJoinButtonPressed: () => void;
    onToggleLearnerView: (setFlag: boolean) => void;
}

export const ClassroomPageHeaderLearner: React.FC<Props> = (
    {classroom, learnerViewEnabled, onLeaveButtonPressed, onJoinButtonPressed, onToggleLearnerView}) => (
        <>
            <div className='container container-sm-full'>
                <div className='classroom-header-learner'>
                    <div className='classroom-details'>
                        <h1 className='classroom-name'>{classroom.name}</h1>
                        <p className='classroom-join-code'>Class code: {classroom.joinCode}</p>
                    </div>

                    <div className='classroom-header-learner-spacer' />

                    <div className='class-membership-button'>
                        {classroom.isMember || learnerViewEnabled ?
                            <button onClick={onLeaveButtonPressed}>
                                <Icon name='sign-out' zoom='20' />
                                <WrappedMessage message={messages.buttonMembershipLeave} />
                            </button>
                            :
                            <button onClick={onJoinButtonPressed}>
                                <Icon name='sign-in' zoom='20' />
                                <WrappedMessage message={messages.buttonMembershipJoin} />
                            </button>
                        }
                    </div>
                </div>
            </div>
            <NavTabs>
                <NavTabsLink
                    exact
                    to={ROUTES.Classrooms.CLASSROOM_SLUG(classroom.id)}
                    icon='file-submodule'
                    message={messages.navlinkClass}
                />
                <NavTabsLink
                    exact
                    to={ROUTES.Classrooms.CLASSROOM_PROGRESS_SLUG(classroom.id)}
                    icon='rocket'
                    message={messages.navlinkProgress}
                />
                <NavTabsLink
                    to={ROUTES.Classrooms.CLASSROOM_DISCUSSION_SLUG(classroom.id)}
                    icon='comment-discussion'
                    message={messages.navlinkDiscussion}
                />
                {learnerViewEnabled &&
                <Route exact path={ROUTES.Classrooms.CLASSROOM_SLUG(classroom.id)}>
                    <div className='learner-view-toggle-container'>
                        <ToggleSwitch
                            isChecked={learnerViewEnabled}
                            hideLabel={false}
                            displayText={intl.formatMessage(messages.learnerView)}
                            ariaText={intl.formatMessage(allMessages.learnerViewToggleAria)}
                            onToggleSwitchSelect={onToggleLearnerView}
                            labelPosition='left'
                        />
                    </div>
                </Route>
                }
            </NavTabs>
        </>
);
