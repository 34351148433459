// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Funder
 */
export interface Funder {
    /**
     * 
     * @type {string}
     * @memberof Funder
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Funder
     */
    largeLogoUrl?: string;
}

export function FunderFromJSON(json: any): Funder {
    return {
        'name': json['name'],
        'largeLogoUrl': !exists(json, 'large_logo_url') ? undefined : json['large_logo_url'],
    };
}

export function FunderToJSON(value?: Funder): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'large_logo_url': value.largeLogoUrl,
    };
}


