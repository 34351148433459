import * as React from 'react';
import { Link } from 'react-router-dom';

interface ContentTypeCardProps {
  name: string;
  image: string;
  contentType: string;
  url: string;
  showNewTag?: boolean;
}

export const ContentTypeCard: React.FunctionComponent<ContentTypeCardProps> = (props) => {
  return (
    <Link
      to={props.url}
      className='content-type-card'
    >
      <img src={props.image} alt={props.name} />
      <span className='content-type-name'>{props.name}</span>
      {props.showNewTag && <span className='new-tag'>NEW</span>}
    </Link>
  );
};

export default ContentTypeCard;
