import bind from 'bind-decorator';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import classNames from 'classnames';

import iconFacebook from 'assets/images/icons/facebook.svg';
import iconInstagram from 'assets/images/icons/instagram.svg';
import iconLinkedin from 'assets/images/icons/linkedin.svg';
import iconTwitter from 'assets/images/icons/twitter.svg';
import iconYoutube from 'assets/images/icons/youtube.png';
import iconPinterest from 'assets/images/icons/pinterest.svg';

import { ROUTES } from 'global/constants';
import { NavLink } from 'react-router-dom';
import { Button, UnderConstruction, LocaleSwitcherV2 } from 'ui/components';
import { Collapsible } from 'ui/components/Collapsible';
import { WrappedMessage, NAVBAR_EXPERIMENT, showExperiment } from 'utils';
import { MailSubscribeBox } from '../MailSubscribeBox';
import messages from './displayMessages';
import { intl } from 'i18n';
import { HarvardUniversityLogo } from '../HarvardUniversityLogo';
import { authLocalStorageManager } from 'auth/utils';
import { history } from 'global/history';
import { getLoggedInStatus } from 'auth/selectors';
import { useSelector } from 'react-redux';
import { RootState } from 'global/state';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';
import { useLayoutSize } from 'utils';
import * as CookieConsent from 'vanilla-cookieconsent';

interface FooterGroupProps {
    title: string;
    children: React.ReactNode;
}

interface FooterGroupState {
    isOpen: boolean;
}

export class FooterGroup extends React.Component<FooterGroupProps, FooterGroupState> {

    public constructor(props: FooterGroupProps) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    public render() {
        const title = (
            <div className='footer-caption'>
                {this.props.title}
            </div>
        );
        return (
            <Collapsible
                title={title}
                isOpen={this.state.isOpen}
                isCollapsible={false}
                onToggle={this.onToggle} >

                {this.props.children}

            </Collapsible>
        );
    }

    @bind private onToggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }
}

const footerLinks = {
    main: [
        {
            message: messages.footerLibrary,
            url: ROUTES.Library.HOME,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterLibraryClicked
        },
        {
            message: messages.footerMyClasses,
            url: ROUTES.Dashboard.LEARNER_CLASSROOMS,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterMyClassesClicked
        },
        {
            message: messages.footerMyContent,
            url: ROUTES.Dashboard.LEARNER_CLASSROOMS,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterMyContentClicked
        },
        {
            message: messages.footerMessages,
            url: ROUTES.Dashboard.LEARNER_CLASSROOMS,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterMessagesClicked
        },
        {
            message: messages.footerCommunity,
            url: ROUTES.Community.HOME,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterCommunityClicked
        },
        {
            message: messages.footerPeople,
            url: ROUTES.People.HOME,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterPeopleClicked
        }
    ],

    resources: [
        {
            message: messages.footerAboutLink,
            url: ROUTES.General.ABOUT_SITE,
            clickable: true,
            eventName: EVENT_NAMES.FooterAboutClicked
        },
        {
            message: messages.footerBlog,
            url: ROUTES.General.BLOG,
            clickable: true,
            eventName: EVENT_NAMES.FooterBlogClicked
        },
        {
            message: messages.footerNews,
            url: ROUTES.General.NEWS,
            clickable: true,
            eventName: EVENT_NAMES.FooterNewsClicked
        },
        {
            message: messages.footerCollaborators,
            url: ROUTES.Organizations.COLLABORATORS,
            clickable: true,
            eventName: EVENT_NAMES.FooterCollaboratorsClicked
        },
        {
            message: messages.footerTermsOfService,
            url: 'https://about.labxchange.org/tos',
            clickable: true,
            eventName: EVENT_NAMES.FooterTermsOfServiceClicked
        },
        {
            message: messages.footerPrivacyLink,
            url: 'https://about.labxchange.org/privacy-notice',
            clickable: true,
            eventName: EVENT_NAMES.FooterPrivacyLinkClicked
        },
        {
            message: messages.footerPrivacyAndSecurity,
            url: 'https://trust.labxchange.org',
            clickable: true,
            eventName: EVENT_NAMES.FooterPrivacyLinkClicked
        },
        {
            message: messages.footerPrivacyChoices,
            url: 'privacy_link',
            clickable: true,
            eventName: EVENT_NAMES.FooterPrivacyChoicesClicked
        },
    ],

    support: [
        {
            message: messages.footerHelpCenter,
            url: ROUTES.General.HELP_CENTER,
            clickable: true,
            newTab: true,
            eventName: EVENT_NAMES.FooterHelpCenterClicked
        },
        {
            message: messages.footerTutorials,
            url: ROUTES.General.TUTORIALS,
            clickable: true,
            newTab: true,
            eventName: EVENT_NAMES.FooterTutorialsClicked
        },
        {
            message: messages.footerGettingStarted,
            url: ROUTES.General.GETTING_STARTED,
            clickable: true,
            newTab: true,
            eventName: EVENT_NAMES.FooterGetStartedClicked
        },
        {
            message: messages.footerTeacherResources,
            url: ROUTES.General.TEACHER_RESOURCES,
            clickable: true,
            newTab: true,
            eventName: EVENT_NAMES.FooterTeacherResourcesClicked
        },
        {
            message: messages.footerAccessibility,
            url: ROUTES.General.ACCESSIBILITY,
            clickable: true,
            newTab: true,
            eventName: EVENT_NAMES.FooterAccessibilityClicked
        },
    ],

    social: [
        {
            message: 'Facebook',
            url: 'https://www.facebook.com/LabXchange',
            img: iconFacebook,
            hidden: false,
            newTab: true,
            eventName: EVENT_NAMES.FooterFacebookClicked
        },
        {
            message: 'X',
            url: 'https://twitter.com/LabXchange',
            img: iconTwitter,
            hidden: false,
            newTab: true,
            eventName: EVENT_NAMES.FooterXClicked
        },
        {
            message: 'Instagram',
            url: 'https://www.instagram.com/labxchange/',
            img: iconInstagram,
            hidden: false,
            newTab: true,
            eventName: EVENT_NAMES.FooterInstagramClicked
        },
        {
            message: 'LinkedIn',
            url: 'https://www.linkedin.com/company/labxchange',
            img: iconLinkedin,
            hidden: false,
            newTab: true,
            eventName: EVENT_NAMES.FooterLinkedInClicked
        },
        {
            message: 'Pinterest',
            url: 'https://www.pinterest.com/labxchange/',
            img: iconPinterest,
            hidden: false,
            newTab: true,
            eventName: EVENT_NAMES.FooterPinterestClicked
        },
        {
            message: 'YouTube',
            url: 'https://www.youtube.com/c/labxchange',
            img: iconYoutube,
            hidden: false,
            newTab: true,
            eventName: EVENT_NAMES.FooterYouTubeClicked
        },
    ],
};

interface FooterLinkProps {
    message: MessageDescriptor;
    url: string;
    clickable: boolean;
    newTab?: boolean;
    eventName: string;
}

export const FooterLink: React.FunctionComponent<FooterLinkProps> = (props) => {
    const isUserLoggedIn = useSelector((state: RootState) => getLoggedInStatus(state));

    const handleJoinClass = () => {
        if (isUserLoggedIn) {
            history.push(ROUTES.Dashboard.LEARNER_CLASSROOMS);
        } else {
            // save this in localstorage as we want to show different copy to the user
            // who starts the signup process using the "Join a Class" button.
            authLocalStorageManager.signupUsingJoinClassButton = true;
            authLocalStorageManager.authRedirectTo = window.location.pathname;
            history.push(ROUTES.General.SIGN_UP);
        }
    };

    const handleClick = (sendImmediately = false) => {
        analyticsInstance.track(
            props.eventName,
            { button_text: props.message.defaultMessage, url: window.location.toString() },
            { sendImmediately },
        );
    };

    if (props.clickable) {
        if (props.url.startsWith('http')) {
            return (
                <a
                    target={props.newTab ? '_blank' : undefined}
                    tabIndex={0}
                    className='footer-link'
                    href={props.url}
                    onClick={() => handleClick(true)}
                    rel='noreferrer'
                >
                    <WrappedMessage message={props.message}></WrappedMessage>
                </a>
            );
        } else if (props.url === ROUTES.Dashboard.LEARNER_CLASSROOMS) {
            return (
                <Button
                    className='footer-link join-class-link'
                    label={props.message}
                    btnStyle='link'
                    onClick={() => { handleJoinClass(); handleClick(); }}
                />
            );
        } else if (props.url === 'privacy_link') {
            return (
                <a
                    href='#'
                    className='footer-link'
                    onClick={() => CookieConsent.showPreferences()} >
                    <WrappedMessage message={props.message}></WrappedMessage>
                </a>
            );
        } else {
            return (
                <NavLink
                    tabIndex={0} className='footer-link'
                    to={props.url}
                    onClick={() => handleClick()}
                >
                    <WrappedMessage message={props.message}></WrappedMessage>
                </NavLink>
            );
        }
    } else {
        return (
            <UnderConstruction>
                <NavLink
                    tabIndex={0}
                    className='footer-link'
                    to={props.url}
                    onClick={() => handleClick()}
                >
                    <WrappedMessage message={props.message}></WrappedMessage>
                </NavLink>
            </UnderConstruction>
        );
    }
};

interface FooterProps {
    backgroundUrl?: string;
    footerFeature?: React.ReactNode;
}

export const Footer: React.FunctionComponent<FooterProps> =
({
    backgroundUrl,
    footerFeature,
}) => {
    const isUserLoggedIn = useSelector((state: RootState) => getLoggedInStatus(state));
    const layoutSize = useLayoutSize();
    const renderSocialItemsBottom = ['small', 'mobile', 'medium'].includes(layoutSize);
    const navbarExpLoggedInMainLinks = [
        {
            message: messages.footerMessages,
            url: ROUTES.Messages.HOME,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterMessagesClicked,
        },
        {
            message: messages.footerCommunity,
            url: ROUTES.Community.HOME,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterCommunityClicked,
        },
    ];
    const navbarExpLoggedOutMainLinks = [
        {
            message: messages.footerExplore,
            url: ROUTES.Explore.HOME,
            clickable: true,
            hidden: false,
            eventName: EVENT_NAMES.FooterExploreClicked,
        },
    ];

    const handleSocialItemClick = (eventName: string, message: string) => {
        analyticsInstance.track(
            eventName,
            { button_text: message, url: window.location.toString() },
            { sendImmediately: true },
        );
    };


    const renderSocialItems = () => (
        <>
            <MailSubscribeBox className='footer-subscribe-box' />
            <div className='social-icons'>
                {footerLinks.social.filter((link) => !link.hidden).map((link, i) => {
                    return (
                        <a
                            key={i}
                            tabIndex={0}
                            target={link.newTab ? '_blank' : undefined}
                            href={link.url}
                            rel='noreferrer'
                            onClick={() => handleSocialItemClick(link.eventName, link.message)}
                        >
                            <img alt={link.message} src={link.img} />
                        </a>
                    );
                })}
            </div>
        </>
    );

    return (
        <>
            <div className='footer-feature'>
                {footerFeature ? footerFeature : null}
            </div>
            <footer>
                {/* Hide this section when user is on homepage */}
                {!/^\/?$/.test(window.location.pathname) &&
                    <div className='footer-harvard-panel'>
                        <HarvardUniversityLogo width={214} height={54} />
                    </div>
                }
                <div className='footer-main' style={
                    backgroundUrl
                        ? { backgroundImage: `url(${backgroundUrl})` }
                        : {}
                }>

                    <div className='footer-links'>
                        <div className='footer-row'>
                            <div className='footer-group'>
                                <FooterGroup title={intl.formatMessage(messages.footerCaptionQuickLinks)} >
                                    {isUserLoggedIn && showExperiment(NAVBAR_EXPERIMENT)
                                        && navbarExpLoggedInMainLinks.map((link, i) => {
                                            return (
                                                <FooterLink key={i} {...link} />
                                            );
                                    })}
                                    {!isUserLoggedIn && showExperiment(NAVBAR_EXPERIMENT)
                                        && navbarExpLoggedOutMainLinks.map((link, i) => {
                                            return (
                                                <FooterLink key={i} {...link} />
                                            );
                                    })}
                                    {footerLinks.main.filter((link) => !link.hidden).map((link, i) => {
                                        return (
                                            <FooterLink key={i} {...link} />
                                        );
                                    })}
                                </FooterGroup>
                            </div>
                            <div className='footer-group'>
                                <FooterGroup title={intl.formatMessage(messages.footerResources)} >
                                    {footerLinks.resources.map((link, i) => {
                                        return (
                                            <FooterLink key={i} {...link} />
                                        );
                                    })}
                                </FooterGroup>
                            </div>
                        </div>
                        <div className='footer-row'>
                            <div className='footer-group'>
                                <FooterGroup title={intl.formatMessage(messages.footerSupport)} >
                                    {footerLinks.support.map((link, i) => {
                                        return (
                                            <FooterLink key={i} {...link} />
                                        );
                                    })}
                                </FooterGroup>
                            </div>
                            <div className='footer-group'>
                                <FooterGroup title={intl.formatMessage(messages.footerStayConnected)} >
                                <FooterLink
                                    newTab={true}
                                    message={messages.footerContactUs}
                                    url={ROUTES.General.CONTACT_US}
                                    clickable={true}
                                    eventName={EVENT_NAMES.FooterContactUsClicked}
                                />
                                <FooterLink
                                    newTab={true}
                                    message={messages.footerShareStory}
                                    url={ROUTES.General.SHARE_STORY}
                                    clickable={true}
                                    eventName={EVENT_NAMES.FooterShareStoryClicked}
                                />
                                <FooterLink
                                    newTab={true}
                                    message={messages.footerEvents}
                                    url={ROUTES.General.EVENTS}
                                    clickable={true}
                                    eventName={EVENT_NAMES.FooterEventsClicked}
                                />
                                <FooterLink
                                    newTab={false}
                                    message={messages.footerDiscussion}
                                    url={ROUTES.Community.DISCUSSIONS}
                                    clickable={true}
                                    eventName={EVENT_NAMES.FooterDiscussionClicked}
                                />

                                </FooterGroup>
                                {!renderSocialItemsBottom && renderSocialItems()}
                            </div>
                        </div>
                    </div>
                    {renderSocialItemsBottom && (
                        <div className='mobile-social-items'>
                            {renderSocialItems()}
                        </div>
                    )}

                    <div className={classNames('footer-row', 'footer-ls-wrapper')}>
                        <div className='footer-locale-switcher'>
                            <LocaleSwitcherV2 />
                        </div>
                    </div>
                </div>
                <div className='footer-notes'>
                    <WrappedMessage message={messages.footerCopyright} values={{ year: new Date().getFullYear() }} />
                </div>
            </footer>
        </>
    );
};
