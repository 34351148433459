import bind from 'bind-decorator';
import * as React from 'react';

import defaultClassroomImage from 'assets/images/default-classroom.png';
import {
    CardBarButton,
    CardDetails,
    CardMedia,
    CardMediaOverlayText,
    CardStandard,
} from 'ui/components/Card/Cards';
import { KebabMenu, KebabMenuItem } from 'ui/components/KebabMenu';
import { intl } from 'i18n';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface EducatorDashboardInactiveClassroomCardProps {
    title: string;
    text: string;
    imageUrl?: string;
    id: string;
    code: React.ReactNode;
    onClick: () => void;
    onCopy?: () => void;
    onDelete?: () => void;
    onUnarchiveClick: () => void;
}

export class EducatorDashboardInactiveClassroomCard extends React.PureComponent<EducatorDashboardInactiveClassroomCardProps, {}> {
    public render() {
        return (
            <CardStandard
                media={
                    <CardMedia
                        mediaUrl={this.props.imageUrl || defaultClassroomImage}
                        overlays={
                            [
                                <CardMediaOverlayText
                                    message={
                                        <WrappedMessage
                                            message={messages.classCodeOverlayText}
                                            values={{ code: this.props.code }}
                                        />
                                    }
                                    additionalStyling='media-overlay-position-bottom-left'
                                    key='classCodeCardMediaOverlay'
                                />,
                            ]
                        }
                    />
                }
                details={
                    <CardDetails
                        title={this.props.title}
                        text={this.props.text}
                    />
                }
                barBottom={
                    // XXX: this does not support triggering button with keyboard.
                    <CardBarButton
                        iconName='archive'
                        text={intl.formatMessage(messages.unarchiveButtonText)}
                        onClick={this.onUnarchiveClick}
                    />
                }
                showBarBottomSeparator={true}
                showBarTopSeparator={false}
                onClick={this.props.onClick}
                additionalStyling='inactive'
            >
                <KebabMenu buttonId={`classroom-card-menu-${this.props.id}`}>
                    <KebabMenuItem
                        iconName='diff'
                        message={messages.copyMenuText}
                        onClick={this.props.onCopy}
                        disabled={false}
                    />
                    <KebabMenuItem
                        iconName='trashcan'
                        message={messages.deleteMenuText}
                        onClick={this.props.onDelete}
                        disabled={false}
                    />
                </KebabMenu>
            </CardStandard>
        );
    }

    @bind private onUnarchiveClick(e: React.MouseEvent) {
        // TODO, XXX: this is messy and should not be required. The problem is
        // that the entire card has an onClick method, and this needs to stop
        // propogation of the click event, otherwise it triggers both...
        e.preventDefault(); e.stopPropagation();
        this.props.onUnarchiveClick();
    }
}
