import { defineMessages } from 'react-intl';

const messages = defineMessages({
    licenseSelectLabel: {
        id:'licenseSelectLabel',
        defaultMessage: 'License',
        description: 'Unit license select label.',
    },
    backgroundKnowledgeSelectLabel: {
        id:'backgroundKnowledgeSelectLabel',
        defaultMessage: 'Background Knowledge',
        description: 'Unit background knowledge select label.',
    },
    sourceSelectLabel: {
        id:'contentSourceSelectLabel',
        defaultMessage: 'Source*',
        description: 'Source select label.',
    },
    subjectSelectLabel: {
        id:'subjectSelectLabel',
        defaultMessage: 'Subject',
        description: 'Label for the subject area select box.',
    },
    languageSelectLabel: {
        id:'languageSelectLabel',
        defaultMessage: 'Language',
        description: 'Label for the language select dropdown',
    },
    defaultSelectValue: {
        id:'defaultSelectValue',
        defaultMessage: 'Select',
        description: 'Default select value.',
    },
    itemPublicContentSectionTitle: {
        id:'itemPublicContentSectionTitle',
        defaultMessage: 'Public content',
        description: 'Public content section title.',
    },
    ariaItemPublicContentToggleLabel: {
        id:'ariaItemPublicContentToggleLabel',
        defaultMessage: 'Public content toggle',
        description: 'Public content toggle.',
    },
    itemPublicContentInformation: {
        id:'itemPublicContentInformation',
        defaultMessage: 'Public content can be remixed and viewed by other users on the platform.',
        description: 'Public content information text.',
    },
    itemPublicContentNote: {
        id:'itemPublicContentNote',
        defaultMessage: 'Note: Credit will be given to you for sharing this content.',
        description: 'Public content note text.',
    },
    itemFeaturedImageSectionTitle: {
        id:'itemFeaturedImageSectionTitle',
        defaultMessage: 'Featured image',
        description: 'Featured image section title.',
    },
    sendPIIMetadata: {
        id: 'sendPIIMetadata',
        defaultMessage: 'Send PII Metadata',
        description: 'Text for the check of sending PII Metadata.',
    },
    itemNotificationCheck: {
        id: 'itemNotificationCheck',
        defaultMessage: 'Notify users who have used this content in their pathways of the new update.',
        description: 'Checkbox label used by the user to notify (or not) a change in one of his item to another users that uses the item',
    },
});

export default messages;
