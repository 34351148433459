import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import { Icon } from 'elements/components/Icons';
import * as React from 'react';
import { HeaderBackgroundStyle } from 'ui/components';
import { ButtonType } from './types/ButtonType';
import { intl, localeInfo } from 'i18n';


interface ButtonOptions {
    onClick: () => void;
    backgroundStyle?: HeaderBackgroundStyle|undefined;
    renderAs?: ButtonType;
    hideChangeText?: boolean;
}


const buttonIcon = (backgroundStyle?: HeaderBackgroundStyle) => {
    const iconColor = backgroundStyle === 'light' ? '#003e6b' : 'white';
    const textStyle = { color: iconColor };
    return (<>
        <Icon
            name='language'
            zoom='25'
            fill={iconColor}
        />
        <span className='language-label' style={textStyle}>
            {localeInfo.nativeName}
        </span>
    </>);
};

const buttonText = (backgroundStyle?: HeaderBackgroundStyle, hideChangeText?: boolean) => {
    const iconColor = backgroundStyle === 'light' ? '#003e6b' : 'white';
    return (
        <>
            <Icon name='language' zoom='25' fill={iconColor} />
            {!hideChangeText && <WrappedMessage message={messages.changeLanguage} />}
        </>
    );
};

export const LanguageButton = ({onClick, backgroundStyle, renderAs, hideChangeText}: ButtonOptions) => {
    const button = renderAs === 'text' ?
        buttonText(backgroundStyle, hideChangeText) : buttonIcon(backgroundStyle);

    return (
        <button
            className={'locale-switcher unstyled' + (renderAs === 'text' ? ' text-locale-button' : '')}
            onClick={onClick}
            aria-label={intl.formatMessage(messages.changeLanguageIcon)}
        >
            {button}
            <span className='chevron-down-icon'>
                    <Icon
                        name='chevron-down'
                        className={
                            (backgroundStyle === 'light' ? 'chevron-dark' : 'chevron-light') +
                            (renderAs === 'text' ? ' mt-1' : '')
                        }
                    />
                </span>
        </button>
    );
};
