import {
    BookTypeEnum,
    ItemMetadataBackgroundKnowledgeEnum,
    ItemMetadataLicenseEnum,
    ItemMetadataTypeEnum,
    MentorshipInitiatorEnum,
    MentorshipStatusEnum,
    ProfileRoleEnum
} from 'labxchange-client';
import {replicateElement} from './utils';

export const skeletonMentorship = {
    id: '',
    mentor: {bio: '', fullName: '', role: ProfileRoleEnum.Educator, username: ''},
    mentee: {bio: '', fullName: '', role: ProfileRoleEnum.Learner, username: ''},
    source: '',
    target: '',
    initiator: MentorshipInitiatorEnum.MenteeIsInitiator,
    status: MentorshipStatusEnum.Accepted,
    created: new Date(),
    updated: new Date(),
    completion: {weighedEarned: 0, weighedPossible: 0},
};

export const skeletonItemMetadata = {
    id: '',
    authors: [],
    funders: [],
    backgroundKnowledge: ItemMetadataBackgroundKnowledgeEnum.Empty,
    clonedFrom: '',
    description: '',
    references: '',
    freeFormTags: [],
    imageUrl: '',
    language: '',
    learningObjectives: [],
    subjectTags: [],
    stats: {
        favorites: 0,
        views: 0,
        remixes: 0,
        clones: 0,
    },
    title: '',
    type: ItemMetadataTypeEnum.Unknown,
    license: ItemMetadataLicenseEnum.LX1,
    sendPiiMetadata: false,
};

export const skeletonUserAttributes = {
    isFavorite: false,
    canEditObject: false,
    showPublicToggle: false,
    isItemOwner: false,
};


export const skeletonPathwayDetail = {
    items: replicateElement({item: {metadata: skeletonItemMetadata, userAttributes: skeletonUserAttributes}}, 3),
};

export const skeletonBook = {
    id: '',
    sections: replicateElement({order: 1, items: []}, 3),
    editions: [],
    type: BookTypeEnum.Unknown,
    imageUrl: '',
};

export const skeletonBookPageData = {
    book: skeletonBook,
    itemMetadata: skeletonItemMetadata,
    itemUserAttributes: skeletonUserAttributes,
};

export const skeletonOrgPermissions = {
    canViewOrganizationDashboardObject: false,
    canEditOrganizationProfileObject: false,
    canEditMembersOfOrganizationObject: false,
    canEditContentOfOrganizationObject: false,
    canPublishContentToPublicLibrary: false,
};

export const skeletonOrg = {
    coverImageUrl: '',
    name: '',
    slug: '',
    profilePageLogo: '',
    permissions: skeletonOrgPermissions
};

export const skeletonItem = {
    metadata: skeletonItemMetadata,
    userAttributes: skeletonUserAttributes
};