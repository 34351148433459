import { bind } from 'bind-decorator';
import { XBlocksApi } from 'global/api';
import { ItemResponse } from 'labxchange-client';
import * as React from 'react';
import { intl } from 'i18n';

import {
    AlignElementRightContainer,
    ElementsInColumnContainer,
    Heading,
    YellowActionButton,
} from 'elements';

import messages from '../../displayMessages';
import { getItemAndBlockData } from '../../utils';
import { convertTimeZone } from 'utils';
import {
    BlockOverview,
    BlockOverviewBack,
    BlockOverviewOutlineItem,
    BlockOverviewSidebar,
} from './BlockOverview';
import { EmbeddedBlock } from './EmbeddedBlock';
import {
    BlockData,
    BlockProps,
    BlockScoreState,
    BlockStudentViewData,
} from './models';
import { createAssetViewedEventForItem } from './utils';
import { ItemType, getItemTypeMeta } from 'items/models';
import { LxConfig } from 'global/constants';
import { AlertBox } from 'ui/components/AlertBox/AlertBox';

interface AssignmentChildBlockState {
    score?: BlockScoreState;
}

interface AssignmentStudentViewState {
    score: BlockScoreState;
    child_blocks: { [key: string]: AssignmentChildBlockState };
}

interface AssignmentBlockProps extends BlockProps {
    studentViewData: BlockStudentViewData;
}

interface AssignmentBlockState {
    showOverview: boolean;
    selectedChildBlockIndex: number;
    selectedChildBlockLoaded: boolean;
    selectedChildBlockData?: BlockData;
    selectedChildItemData?: ItemResponse;
    blockState?: AssignmentStudentViewState;
}

export class AssignmentBlock extends React.PureComponent<AssignmentBlockProps, AssignmentBlockState> {

    constructor(props: AssignmentBlockProps) {
        super(props);
        this.state = {
            showOverview: true,
            selectedChildBlockIndex: -1,
            selectedChildBlockLoaded: false,
        };
    }

    public componentDidMount() {
        this.fetchStudentViewUserState();
    }

    public render() {
        if (this.state.showOverview) {
            return this.renderOverview();
        } else {
            return this.renderContent();
        }
    }

    private renderOverview() {
        return (
            <div>
                <BlockOverview
                    iconUrl='/assets/images/assignments/assignment-colored.svg'
                    startButtonTitleDescriptor={messages.blockAssignmentStartLabel}
                    onStartButtonClick={this.onStart}
                    outlineItems={this.outlineItems()}
                    score={this.scoreForAssignment()}
                    theme='assignment'
                />
            </div>
        );
    }

    private renderContent() {

        let childItemMetadata;
        if (this.state.selectedChildItemData && this.state.selectedChildBlockData) {
            childItemMetadata = this.state.selectedChildItemData.metadata;
        }

        const onLastChildIndex = (
                this.studentViewData &&
                this.studentViewData.child_blocks &&
                this.state.selectedChildBlockIndex === (this.studentViewData.child_blocks.length - 1)
        );

        const previousChildData = this.state.selectedChildBlockIndex > 0 ? (
            this.outlineItems()[this.state.selectedChildBlockIndex - 1]
        ) : undefined;

        const selectedChildData = this.state.selectedChildBlockIndex >= 0 ? (
            this.outlineItems()[this.state.selectedChildBlockIndex]
        ) : undefined;

        const selectedChildBlockId = selectedChildData ? selectedChildData.id : '' ;
        const selectedChildBlockTitle = selectedChildData ? selectedChildData.title : '' ;
        const selectedChildBlockHeading =  `${this.state.selectedChildBlockIndex + 1}. ${selectedChildBlockTitle}`;
        let toBeUpdated = this.state.selectedChildItemData?.metadata.toBeUpdated;
        if (toBeUpdated) {
            /// This date is stored in UTC on the database
            /// but in the frontend it's showed in the local time of the user.
            /// Only the date is shown, so it can show different days for different users.
            /// For that reason I convert this date to UTC-0 to show the exact day
            /// that is in the database to all users.
            toBeUpdated = convertTimeZone(toBeUpdated, 'Africa/Abidjan');
        }

        return (
            <div className='block-expanded-container block-assignment'>
                <div className='block-expanded-sidebar block-assignment d-none d-md-block col-lg-3'>
                    <BlockOverviewSidebar
                        itemType={ItemType.Assignment}
                        backButtonTitleDescriptor={messages.blockOverviewBackLabel}
                        onBackButtonClick={this.onBack}
                        onItemClick={this.onItemClick}
                        outlineItems={this.outlineItems()}
                        score={this.scoreForAssignment()}
                        theme='assignment'
                    />
                </div>
                <div className='block-expanded-content block-assignment col-12 col-md-9 my-0 my-md-4 px-0 px-md-auto'>
                    <div className='block-expanded-back col-12 d-md-none px-0 mb-5'>
                        <BlockOverviewBack
                            onBackToOverviewButtonClick={this.onBack}
                            onPreviousItemClick={() => {
                                this.selectChildItem(this.state.selectedChildBlockIndex - 1);
                            }}
                            previousItemIndex={this.state.selectedChildBlockIndex - 1}
                            previousItem={previousChildData}
                        />
                    </div>
                    {childItemMetadata && this.state.selectedChildBlockData &&
                        <div className='px-3 px-md-auto'>
                            <ElementsInColumnContainer>
                                <div className='d-md-none'>
                                    {this.state.selectedChildBlockLoaded === true &&
                                        <Heading
                                            headingStyle='heading-style-2'
                                            level={3}
                                            title={selectedChildBlockHeading}
                                        />
                                    }
                                </div>
                                { toBeUpdated && this.state.selectedChildItemData &&
                                    <AlertBox
                                        type='warning'
                                        bodyMessage={messages.updateWarningBodyMessage}
                                        bodyMessageValues={{
                                            itemType: intl.formatMessage(getItemTypeMeta(this.state.selectedChildItemData.metadata.type).name),
                                            date: intl.formatDate(toBeUpdated, {
                                                year: 'numeric',
                                                month: 'long',
                                                day: '2-digit',
                                            })
                                        }}
                                        link={LxConfig.DeprecatedItemAlertUrl}
                                        linkMessage={messages.updateWarningLinkMessage}
                                    />
                                }
                                <EmbeddedBlock
                                    key={selectedChildBlockId}
                                    itemId={selectedChildBlockId}
                                    onLoad={this.onChildInitDone}
                                    username={this.props.username}
                                />
                                { this.state.selectedChildBlockLoaded === true && onLastChildIndex === false &&
                                    <AlignElementRightContainer>
                                        <YellowActionButton
                                            titleDescriptor={messages.blockAssignmentStartNextLabel}
                                            onClick={this.onNextButtonClick}
                                        />
                                    </AlignElementRightContainer>
                                }
                                { this.state.selectedChildBlockLoaded === true && onLastChildIndex &&
                                    <AlignElementRightContainer>
                                        <YellowActionButton
                                            titleDescriptor={messages.blockOverviewBackLabel}
                                            onClick={this.onBack}
                                            iconName='triangle-left'
                                        />
                                    </AlignElementRightContainer>
                                }
                                {/*Currently new style pathways do not support auto-cloning of ItemMetadata so we*/}
                                {/*need to disable this temporarily.*/}
                                {/*{this.state.selectedChildBlockLoaded === true && childItemMetadata.authors &&*/}
                                {/*    <AuthorsList authors={childItemMetadata.authors}/>*/}
                                {/*}*/}
                            </ElementsInColumnContainer>
                        </div>
                    }
                </div>
            </div>
        );
    }

    private outlineItems(): BlockOverviewOutlineItem[] {
        const studentViewData = this.studentViewData;
        if (studentViewData && studentViewData.child_blocks && studentViewData.child_blocks.length > 0) {
            return studentViewData.child_blocks.map((childBlock, index) => {
                return {
                    id: childBlock.usage_id,
                    title: childBlock.display_name,
                    selected: (this.state.showOverview === false && this.state.selectedChildBlockIndex === index),
                    score: this.scoreForItem(childBlock.usage_id),
                };
            });
        }
        return [];
    }

    private scoreForAssignment(): BlockScoreState|undefined {
        if (this.state.blockState && this.state.blockState.score) {
            return {
                earned: this.state.blockState.score.earned,
                possible: this.state.blockState.score.possible,
            };
        }
        return undefined;
    }

    private scoreForItem(usageKey: string): BlockScoreState|undefined {
        if (this.state.blockState && this.state.blockState.child_blocks) {
            const childBlockState: AssignmentChildBlockState = (this.state.blockState.child_blocks as any)[usageKey];
            if (childBlockState && childBlockState.score) {
                return {
                    earned: childBlockState.score.earned,
                    possible: childBlockState.score.possible,
                };
            }
        }
        return undefined;
    }

    private get studentViewData(): BlockStudentViewData {
        return this.props.blockData.studentViewData;
    }

    @bind private async onStart() {
        await this.selectChildItem(0);
        this.setState({
            showOverview: false,
        });
        this.props.onExpandBlock(true);
        this.fetchStudentViewUserState();
        createAssetViewedEventForItem(this.props.itemMetadata.id);
    }

    @bind private async onBack() {
        window.scrollTo(0, 0);
        this.setState({
            showOverview: true,
        });
        this.props.onExpandBlock(false);
        this.fetchStudentViewUserState();
    }

    @bind private async onItemClick(index: number) {
        this.selectChildItem(index);
    }

    @bind private async onNextButtonClick() {
        if (this.studentViewData && this.studentViewData.child_blocks) {
            if (this.state.selectedChildBlockIndex + 1 < this.studentViewData.child_blocks.length) {
                this.selectChildItem(this.state.selectedChildBlockIndex + 1);
            }
        }
    }

    @bind private async onChildInitDone() {
        // Wait for the xblock to be loaded before showing the components below it.
        const component = this;
        setTimeout(() => {
            component.setState({
                selectedChildBlockLoaded: true,
            });
        }, 250);
    }

    private async selectChildItem(childBlockIndex: number) {
        if (this.studentViewData.child_blocks && childBlockIndex < this.studentViewData.child_blocks.length) {
            const childStudentViewData: BlockStudentViewData = this.studentViewData.child_blocks[childBlockIndex];
            if (childStudentViewData) {
                const response = await getItemAndBlockData(childStudentViewData.usage_id);
                this.setState({
                    selectedChildBlockIndex: childBlockIndex,
                    selectedChildBlockLoaded: false,
                    selectedChildBlockData: response.blockData,
                    selectedChildItemData: response.itemData,
                });
                this.fetchStudentViewUserState();

                if (this.state.showOverview) {
                    window.scrollTo(0, 0);
                } else {
                    const blockTop = document.getElementsByClassName(
                        'block-expanded-content',
                    )[0].getBoundingClientRect().top;
                    if (blockTop < 0) {
                        const top = (window.pageYOffset || document.documentElement.scrollTop) + blockTop - 10;
                        window.scrollTo(0, top);
                    }
                }
            }
        }
    }

    private fetchStudentViewUserState() {
        XBlocksApi.studentViewUserState({id: this.props.itemMetadata.id}).then((stateRaw) => {
            this.setState({
                blockState: JSON.parse(stateRaw),
            });
        });
    }
}
