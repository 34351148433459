// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DiscussionCategory,
    DiscussionCategoryFromJSON,
    DiscussionCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    previous?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    count: number;
    /**
     * 
     * @type {Array<DiscussionCategory>}
     * @memberof InlineResponse2002
     */
    results: Array<DiscussionCategory>;
}

export function InlineResponse2002FromJSON(json: any): InlineResponse2002 {
    return {
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(DiscussionCategoryFromJSON),
    };
}

export function InlineResponse2002ToJSON(value?: InlineResponse2002): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'next': value.next,
        'previous': value.previous,
        'count': value.count,
        'results': (value.results as Array<any>).map(DiscussionCategoryToJSON),
    };
}


