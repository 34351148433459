import * as React from 'react';
import { Link } from 'react-router-dom';
import vectorImage from '../assets/images/Vectors-Wrapper.svg';
import heroImage from '../assets/images/hero_illustration.svg';
import arrow from '../assets/images/arrow.svg';
import { Button, StandardPageLayout } from '../../../ui/components';
import messages from '../../displayMessages';
import DynamicContentComponent from './DynamicContentComponent';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';
import { NavLink } from 'react-router-dom';
import { WrappedMessage } from '../../../utils';
import { useSelector } from 'react-redux';
import { getLoggedInStatus } from '../../../auth/selectors';
import { ROUTES } from '../../../global/constants';
import { history } from 'global/history';
import { Icon } from '../../../ui/components';
import { CollapsibleWithChevron } from 'elements';

import { authLocalStorageManager } from 'auth/utils';
import { useLayoutSize } from '../../../utils';

// Define the interface for a topic
export interface Topic {
    name: string;
    url: string;
}

// Define the interface for each category (MIDDLE_SCHOOL, HIGH_SCHOOL, ADVANCED)
interface Category {
    topics: Topic[];
    browseTopicLink?: string;
    browseTopicTitle?: string;
    level: string;
}

// Define the interface for the entire data structure
interface SubjectData {
    name: string;
    data: {
        'MIDDLE_SCHOOL': Category;
        'HIGH_SCHOOL': Category;
        'ADVANCED': Category;
    };
}

enum Subjects {
    BIOLOGY = 'Biology',
    CHEMISTRY = 'Chemistry',
    PHYSICS = 'Physics',
    EARTH_SPACE = 'Earth & Space'
}

// Biology Data
export const biology: SubjectData = {
    name: Subjects.BIOLOGY,
    data: {
        'MIDDLE_SCHOOL': {
            topics: [
                {
                    name: 'Photosynthesis',
                    url: '/topic/biology-middle-photosynthesis'
                },
                {
                    name: 'Ecology',
                    url: '/library/pathway/lx-pathway:04e8f365-dc4f-47a9-bb60-3612e4210bdb'
                },
                {
                    name: 'Evolution',
                    url: '/library/pathway/lx-pathway:3bc47a00-b825-47e2-8451-03bf8fc9c472'
                },
                {
                    name: 'DNA',
                    url: '/library/pathway/lx-pathway:1d8c2b7c-2bcc-4b1c-a995-655c3107771d'
                },
                {
                    name: 'Heredity',
                    url: '/library/pathway/lx-pathway:20881e47-c931-46d9-b6dd-ab9fc59ee538'
                },
                {
                    name: 'The Human Body',
                    url: '/library/pathway/lx-pathway:a06432a8-ddb3-4c1f-bb19-f02deb9fe530'
                },
                {
                    name: 'Intro to Cells',
                    url: '/library/pathway/lx-pathway:087f7cb8-09cf-406e-bc60-5193fee3512e'
                },
                {
                    name: 'Biodiversity',
                    url: '/library/pathway/lx-pathway:cc85ed38-eaca-4f70-80f7-5ca2f075cb69'
                }
            ],
            level: 'Middle School'
        },
        'HIGH_SCHOOL': {
            topics: [
                {
                    name: 'Cell Structure',
                    url: '/library/pathway/lx-pathway:087f7cb8-09cf-406e-bc60-5193fee3512e'
                },
                {
                    name: 'Classic Genetics',
                    url: '/library/pathway/lx-pathway:32d6db6f-45d2-4640-aa98-91f5fcfa719c'
                },
                {
                    name: 'The Body\'s Systems',
                    url: '/library/pathway/lx-pathway:d912c78c-ff2f-4219-9ab5-88dede05e167'
                },
                {
                    name: 'DNA: Inheritance',
                    url: '/library/pathway/lx-pathway:fa11f9ec-1ee4-3353-8b52-360b90420360'
                },
                {
                    name: 'Biomolecules',
                    url: '/library/pathway/lx-pathway:68bc5673-6b6f-4b5e-b032-412833d7d901'
                },
                {
                    name: 'Natural Selection',
                    url: '/library/pathway/lx-pathway:7aa64255-200e-484c-af31-a6d30592a1bf'
                },
                {
                    name: 'Cell Division',
                    url: '/library/pathway/lx-pathway:a3d0a7d3-bdab-4e05-ad19-368d2593292e'
                },
                {
                    name: 'Energy in Ecosystems',
                    url: '/library/pathway/lx-pathway:2d9fe437-cbd4-4697-a0d3-5290c66576cc'
                }
            ],
            browseTopicLink: '/subject/biology',
            browseTopicTitle: 'Browse Subject',
            level: 'High School'
        },
        'ADVANCED': {
            topics: [
                {
                    name: 'AP: Chemistry of Life',
                    url: '/topic/biology-advance-chemistry-of-life'
                },
                {
                    name: 'AP: Cell Structure & Function',
                    url: '/topic/biology-advance-cell-structure-and-function'
                },
                {
                    name: 'AP: Cellular Energetics',
                    url: '/topic/biology-advance-cellular-energetics'
                },
                {
                    name: 'AP: Cell Cycle & Communication',
                    url: '#'
                },
                {
                    name: 'AP: Heredity',
                    url: '#'
                },
                {
                    name: 'AP: Gene Expression & Regulation',
                    url: '#'
                },
                {
                    name: 'AP: Natural Selection',
                    url: '/topic/biology-advance-natural-selection'
                },
                {
                    name: 'AP: Ecology',
                    url: '/topic/biology-advance-ecology'
                },
                {
                    name: 'Biotechnology',
                    url: '/topic/biology-advance-biotechnology'
                },
                {name: 'Genome Editing', url: '/library/clusters/lx-cluster:lxc-crispr-hiv'},
                {name: 'Precision Medicine', url: '/library/clusters/lx-cluster:lxc-pm'},
            ],
            browseTopicLink: '/subject/ap-biology',
            browseTopicTitle: 'Browse AP Bio',
            level: 'AP & College'
        }
    }
};

// Changing only high school links to topic links for now
export const biologyWithTopicLinks: SubjectData = {
    ...biology,
    data: {
        ...biology.data,
        'HIGH_SCHOOL': {
            topics: [
                {
                    name: 'Cell Structure',
                    url: '/topic/biology-high-cell-structure'
                },
                {
                    name: 'Classic Genetics',
                    url: '/topic/biology-high-classic-genetics'
                },
                {
                    name: 'The Body\'s Systems',
                    url: '/topic/biology-high-the-bodys-systems'
                },
                {
                    name: 'DNA: Inheritance',
                    url: '/topic/biology-high-dna-inheritance'
                },
                {
                    name: 'Biomolecules',
                    url: '/topic/biology-high-biomolecules'
                },
                {
                    name: 'Natural Selection',
                    url: '/topic/biology-high-natural-selection'
                },
                {
                    name: 'Cell Division',
                    url: '/topic/biology-high-cell-division'
                },
                {
                    name: 'Energy in Ecosystems',
                    url: '/topic/biology-high-energy-in-ecosystems'
                },
                {
                    name: 'Racial Equity in Science & Health',
                    url: '/topic/biology-high-racial-equity-health-science'
                }
            ],
            level: 'High School'
        }
    }
};

// Earth & Space Data
export const earthSpace: SubjectData = {
    name: Subjects.EARTH_SPACE,
    data: {
        'MIDDLE_SCHOOL': {
            topics: [
                {
                    name: 'Water',
                    url: '/topic/earth-science-middle-water'
                },
                {
                    name: 'Earth\'s Systems',
                    url: '/library/clusters/lx-cluster:Science_Mom_Earth_Science'
                },
                {
                    name: 'Plate Tectonics',
                    url: '/library?q=%24rescore%21+tectonics&amp;t=Language%3Aen'
                },
                {
                    name: 'The Solar System',
                    url: '/library?q=%24rescore%21+solar+system&amp;t=Language%3Aen'
                },
                {name: 'Stars', url: '/library?q=%24rescore%21+stars&amp;t=Language%3Aen'},
                {name: 'Weather', url: '/library?q=%24rescore%21+weather&amp;t=Language%3Aen'}
            ],
            level: 'Middle School'
        },
        'HIGH_SCHOOL': {
            topics: [
                {
                    name: 'Transpiration',
                    url: '/library/pathway/lx-pathway:c4162bec-4eaa-4803-9662-ba15558ec189'
                },
                {
                    name: 'Agriculture',
                    url: '/library/pathway/lx-pathway:17a1f2f4-d0b6-4c2e-9637-9aabf290e45d'
                },
                {
                    name: 'The Water Cycle',
                    url: '/library/pathway/lx-pathway:ca72f39b-a282-4e2c-bc38-583eb10430ec'
                },
                {
                    name: 'Astrobotany: Food',
                    url: '/library/pathway/lx-pathway:b12716fa-d835-4e43-9c63-4c05a54e2d86'
                },
                {
                    name: 'Understanding Oceans',
                    url: '/library/pathway/lx-pathway:c38adb5f-0827-43ad-8356-dd8d5a7d95a5'
                },
                {
                    name: 'Plant Growth',
                    url: '/library/pathway/lx-pathway:e2226cc6-2a3c-4b10-b6d9-e11e1dd66610'
                }
            ],
            level: 'High School'
        },
        'ADVANCED': {
            topics: [
                {
                    name: 'Populations',
                    url: '/library?q=%24rescore%21+population&amp;t=Language%3Aen'
                },
                {
                    name: 'Atmospheric Pollution',
                    url: '/library?q=atmosphere+pollution&amp;t=Language%3Aen'
                },
                {name: 'Global Change', url: '/library?q=global+change&amp;t=Language%3Aen'},
                {name: 'Land Use', url: '/library?q=land&amp;t=Language%3Aen'},
                {
                    name: 'The Geology of Mars',
                    url: '/library/pathway/lx-pathway:df478d17-34a9-4418-b530-13faed0d56da'
                },
                {
                    name: 'Mammalian Dive Response',
                    url: '/library/pathway/lx-pathway:6c18de5e-0284-480a-b868-cd39ea887383'
                }
            ],
            level: 'Advanced'
        }
    }
};

// Physics Data
export const physics: SubjectData = {
    name : Subjects.PHYSICS,
    data: {
        'MIDDLE_SCHOOL': {
            topics: [
                {
                    name: 'Magnetic Fields',
                    url: '/topic/physics-middle-magnetic-fields'
                },
                {   name: 'Forces & Motion',
                    url: '/topic/physics-middle-forces-and-motion'
                },
                {
                    name: 'Circuits',
                    url: '/topic/physics-middle-circuits'
                },
                {
                    name: 'Potential & Kinetic Energy',
                    url: '/library/pathway/lx-pathway:28798600-9588-4eed-a1f9-1b7ed5ca8a7c'
                },
                {
                    name: 'Waves & Energy Transfer',
                    url: '/library/pathway/lx-pathway:9124ca2f-92f0-44a0-9498-24808acee176'
                },
                {
                    name: 'Conservation of Energy',
                    url: '/library?q=energy+conservation&amp;t=Language%3Aen'
                }
            ],
            level: 'Middle School'
        },
        'HIGH_SCHOOL': {
            topics: [
                {
                    name: 'Light',
                    url: '/topic/physics-high-light'
                },
                {
                    name: 'One-dimensional Motion',
                    url: '/library/pathway/lx-pathway:7846e678-4298-4fe6-ba9f-e75b171accde'
                },
                {
                    name: 'Momentum',
                    url: '/library/pathway/lx-pathway:1e9d8f5f-be71-41e7-a8aa-3be5addc3b37'
                },
                {
                    name: 'Sound',
                    url: '/library/pathway/lx-pathway:b1ab2716-6a2f-49a9-94e5-430f860cc75a'
                },
                {
                    name: 'Electrodynamics',
                    url: '/library/pathway/lx-pathway:b92e42c8-ea41-4620-abb5-457959b4b92e'
                },
                {
                    name: 'Universal Gravity',
                    url: '/library/pathway/lx-pathway:d1c660c4-1f96-4033-994c-85cc6314702e'
                }
            ],
            level: 'High School'
        },
        'ADVANCED': {
            topics: [
                {
                    name: 'Two-dimensional Motion',
                    url: '/library/pathway/lx-pathway:bb9bdb60-d8ca-4592-9a66-da3d46dc74d5'
                },
                {
                    name: 'Oscillatory Motion',
                    url: '/library/pathway/lx-pathway:70da76e8-f77e-4fba-bf04-55c510f33443'
                },
                {
                    name: 'Atomic Physics',
                    url: '/library/pathway/lx-pathway:411d7965-21d0-43e9-b809-8ca9e9fc2c8d'
                },
                {
                    name: 'Particle Physics',
                    url: '/library/pathway/lx-pathway:43bac050-e0a4-44ba-ad51-9c9cf7f27608'
                },
                {
                    name: 'Wave Optics',
                    url: '/library/pathway/lx-pathway:105064a5-fd9c-4787-8344-96b485d4012a'
                },
                {
                    name: 'Rotational Motion',
                    url: '/library/pathway/lx-pathway:9ffd4f05-72a8-477e-bded-6d67a66f0cff'
                }
            ],
            level: 'Advanced'
        }
    }
};

// Chemistry Data
export const chemistry: SubjectData = {
    name: Subjects.CHEMISTRY,
    data: {
        'MIDDLE_SCHOOL': {
            topics: [
                {
                    name: 'The Atom',
                    url: '/topic/chemistry-middle-the-atom'
                },
                {
                    name: 'Chemical Reactions',
                    url: '/topic/chemistry-middle-chemical-reactions'
                },
                {
                    name: 'Energy & Phases',
                    url: '/library/pathway/lx-pathway:635b966f-3e45-492a-b51f-a2836bc1889b'
                },
                {
                    name: 'Properties of Water',
                    url: '/library/pathway/lx-pathway:532d06d6-6ead-4520-8c47-3d63ba09b15a'
                },
                {
                    name: 'Matter & Change',
                    url: '/library/pathway/lx-pathway:14fc26bf-b721-4902-bf62-1286021a0afc'
                },
                {
                    name: 'Making Measurements',
                    url: '/library/pathway/lx-pathway:20cd7957-9de9-47cb-ba19-647cc4e127f8'
                }
            ],
            level: 'Middle School'
        },
        'HIGH_SCHOOL': {
            topics: [
                {
                    name: 'The Periodic Table',
                    url: '/topic/chemistry-high-periodic-table'
                },
                {
                    name: 'Organic Chemistry',
                    url: '/topic/chemistry-high-organic-chemistry'
                },
                {
                    name: 'Solutions & Concentration',
                    url: '/library/pathway/lx-pathway:901ed25b-bcb6-4fb0-bbfd-45e6e61a0a55'
                },
                {
                    name: 'Macromolecules',
                    url: '/library/pathway/lx-pathway:b067e0de-9c21-473b-a61e-56eb861e40bb'
                },
                {
                    name: 'Acids & Bases',
                    url: '/library/pathway/lx-pathway:18ea7812-5ca5-449a-b762-34a92eb89136'
                },
                {
                    name: 'Chemical Bonding',
                    url: '/library/pathway/lx-pathway:b2cd0ddb-06d8-4e98-8092-7f739ddaff9e'
                }
            ],
            level: 'High School'
        },
        'ADVANCED': {
            topics: [
                {
                    name: 'Rates of Reactions',
                    url: '/library/pathway/lx-pathway:9bc13661-ec63-4406-b6e8-1264115336cf'
                },
                {name: 'Thermodynamics', url: '/library?q=Thermodynamics&amp;t=Language%3Aen'},
                {
                    name: 'Le Châtelier\'s Principle',
                    url: '/library/pathway/lx-pathway:0eeeecfb-60ae-4dfb-8e93-7b68725f4be9'
                },
                {
                    name: 'Nuclear Chemistry',
                    url: '/library/pathway/lx-pathway:cb8915fd-4f16-48e9-bb0b-4d618d036717'
                },
                {
                    name: 'Chemical Kinetics',
                    url: '/library?q=Chemical+Kinetics&t=Language%3Aen'
                },
                {
                    name: 'Thermodynamics & Acidity',
                    url: '/library?q=Thermodynamics+%26+Acidity&t=Language%3Aen'
                }
            ],
            level: 'Advanced'
        }
    }
};


export const SubjectsPage: React.FunctionComponent = () => {
    const [selectedSubject, setSelectedSubject] = React.useState(0);
    const layoutSize = useLayoutSize();
    const showCollapsibles = layoutSize === 'small';

    const isLoggedIn = useSelector(getLoggedInStatus);
    const handleScroll = () => {
        // Get the position of each tab
        const tabs = document.querySelectorAll('.frame-19');
        const tabPositions = Array.from(tabs).map((tab) => tab.getBoundingClientRect().top);

        // get the mid-point of the window
        const windowHeight = window.innerHeight;
        const midPoint = windowHeight / 2;

        // Calculate which tab is in the visible area based on scroll position
        let activeTab = 0;
        for (let i = tabPositions.length - 1; i >= 0; i--) {
            if (midPoint >= tabPositions[i]) {
                activeTab = i;
                break;
            }
        }

        // Update the selected tab in the state
        setSelectedSubject(activeTab);
    };

    const subjects = ['Biology','Chemistry', 'Physics', 'Earth & Space'];

    const onSideBarClicked = (tab: number) => {
        const subject = subjects[tab];
        const targetSection = document.getElementById(subject);
        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        window.history.replaceState(null, '', `#${subject}`);

        const eventData = {
            order: tab + 1,
            subject_text: subjects[tab],
            url: window.location.toString(),
        };
        analyticsInstance.track(EVENT_NAMES.SubjectsPageSideBarItemClicked, eventData);
    };

    React.useEffect(() => {
        const hash = decodeURIComponent(window.location.hash.replace('#', ''));

        if (hash) {
            const targetSection = document.getElementById(hash);
            if (targetSection) {
                setTimeout(() => {
                    targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 100);
            }

            const subjectIndex = subjects.indexOf(hash);
            if (subjectIndex >= 0) {
                setSelectedSubject(subjectIndex);
            }
        }
    }, []);

    const handleJoinClass = () => {
        if (isLoggedIn) {
            history.push(ROUTES.Dashboard.LEARNER_CLASSROOMS);
        } else {
            // save this in localstorage as we want to show different copy to the user
            // who starts the signup process using the "Join a Class" button.
            authLocalStorageManager.signupUsingJoinClassButton = true;
            authLocalStorageManager.authRedirectTo = window.location.pathname;
            history.push(ROUTES.General.SIGN_UP);
        }
    };

    const handleCurriculumsButton = () => {
        history.push(ROUTES.CurriculumsPage);
    };

    React.useEffect(() => {
        // Attach scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [window.scrollY]);

    const renderSubjectContent = (subjectData: SubjectData) => {
        const middleLevelTitle = subjectData.data.MIDDLE_SCHOOL.level;
        const middleLevelKey = `${subjectData.name}-${subjectData.data.MIDDLE_SCHOOL.level}`;
        const middleLevelContent = (
            <DynamicContentComponent
                browseTopicTitle={subjectData.data.MIDDLE_SCHOOL.browseTopicTitle}
                browseTopicLink={subjectData.data.MIDDLE_SCHOOL.browseTopicLink}
                topics={subjectData.data.MIDDLE_SCHOOL.topics}
                level={subjectData.data.MIDDLE_SCHOOL.level}
                subject={subjectData.name}
            />
        );

        const highLevelTitle = subjectData.data.HIGH_SCHOOL.level;
        const highLevelKey = `${subjectData.name}-${subjectData.data.HIGH_SCHOOL.level}`;
        const isBioSubject = subjectData.name === Subjects.BIOLOGY;
        const highLevelContent = (
            <DynamicContentComponent
                browseTopicTitle={subjectData.data.HIGH_SCHOOL.browseTopicTitle}
                browseTopicLink={subjectData.data.HIGH_SCHOOL.browseTopicLink}
                topics={
                    isBioSubject ? biologyWithTopicLinks.data.HIGH_SCHOOL.topics :
                    subjectData.data.HIGH_SCHOOL.topics
                }
                level={subjectData.data.HIGH_SCHOOL.level}
                subject={subjectData.name}
            />
        );

        const advancedLevelTitle = subjectData.data.ADVANCED.level;
        const advancedLevelKey = `${subjectData.name}-${subjectData.data.ADVANCED.level}`;
        const advancedLevelContent = (
            <DynamicContentComponent
                browseTopicTitle={subjectData.data.ADVANCED.browseTopicTitle}
                browseTopicLink={subjectData.data.ADVANCED.browseTopicLink}
                topics={subjectData.data.ADVANCED.topics}
                level={subjectData.data.ADVANCED.level}
                subject={subjectData.name}
            />
        );

        if (showCollapsibles) {
            return (
                <>
                    <CollapsibleWithChevron
                        key={middleLevelKey}
                        title={middleLevelTitle}
                        defaultOpen={false}
                    >
                        {middleLevelContent}
                    </CollapsibleWithChevron>
                    <CollapsibleWithChevron
                        key={highLevelKey}
                        title={highLevelTitle}
                        defaultOpen={false}
                    >
                        {highLevelContent}
                    </CollapsibleWithChevron>
                    <CollapsibleWithChevron
                        key={advancedLevelKey}
                        title={advancedLevelTitle}
                        defaultOpen={false}
                    >
                        {advancedLevelContent}
                    </CollapsibleWithChevron>
                </>
            );
        } else {
            return (
                <>
                    <div key={middleLevelKey} role='listitem' className='level-column w-dyn-item'>
                        <div className='level'>{middleLevelTitle}</div>
                        {middleLevelContent}
                    </div>
                    <div key={highLevelKey} role='listitem' className='level-column w-dyn-item'>
                        <div className='level'>{highLevelTitle}</div>
                        {highLevelContent}
                    </div>
                    <div key={advancedLevelKey} role='listitem' className='level-column w-dyn-item'>
                        <div className='level'>{advancedLevelTitle}</div>
                        {advancedLevelContent}
                    </div>
                </>
            );
        }
    };

    return (
        <StandardPageLayout
            pageTitle={messages.subjectPageTitle}
            headerBackgroundUrl={'/assets/images/subject-header.svg'}
            headerFeature={
                <div className='subjects-page'>
                    <section className='section wf-section'>
                        <div className='w-layout-blockcontainer w-container'>
                            <div className='w-layout-hflex hero_contents'>
                                <img src={heroImage}
                                    loading='lazy'
                                    alt='Friendly female scientist with a welcome gesture'
                                    className='hero_image'
                                />
                                <h1 className='hero_headline'>
                                    {
                                        <WrappedMessage
                                            message={messages.subjectPageHeading}
                                            values={{
                                                placeholder: <span style={{color: '#FAAC48'}}>
                                                <WrappedMessage message={messages.subjectPageHeadingPlaceholderText}/>
                                            </span>
                                            }}
                                        />
                                    }
                                </h1>
                            </div>
                        </div>
                    </section>
                </div>
            }
        >
            <div className='subjects-page'>
                <section className='main wf-section'>
                    <div className='w-layout-blockcontainer container-2 w-container'>
                        <div className='side-nav-wrapper'>
                            {subjects.map((subject, index) => (
                                <div
                                    key={index}
                                    data-testid={subject}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSideBarClicked(index);
                                    }}
                                    className={`side-nav-item w-inline-block ${selectedSubject === index ? 'w--current' : ''}`}
                                >
                                    <span className='side-nav-text'>{subject}</span>
                                </div>
                            ))}
                            {/* <NavLink to='/library' onClick={() => onSideBarClicked(4)}
                                     className={`side-nav-item more w-inline-block ${selectedSubject === 4 ? 'w--current' : ''}`}>
                                <div className='side-nav-text'>{ <WrappedMessage message={messages.moreContentLinkText} />}</div>
                                <img src={arrow} loading='lazy' width='16' alt='arrow'/>
                            </NavLink> */}
                            <Button
                                data-testid='sidebar-join-a-class-button'
                                className='sidebar-join-a-class-button'
                                label={messages.joinClassButton}
                                btnStyle='link'
                                onClick={handleJoinClass}
                            >
                                <img src={arrow} loading='lazy' width='16' alt='arrow'/>
                            </Button>
                            <Button
                                data-testid='sidebar-curriculums-button'
                                className='sidebar-curriculums-button'
                                label={messages.curriculumsButton}
                                btnStyle='outline'
                                onClick={handleCurriculumsButton}
                            />
                        </div>

                        <div className='div-block-40'>
                            <h2 className='heading'>Browse Subjects</h2>
                            <Button
                                data-testid='heading-curriculums-button-mobile'
                                className='heading-curriculums-button-mobile'
                                label={messages.curriculumsButton}
                                btnStyle='link'
                                onClick={handleCurriculumsButton}
                            />
                            <div className='menu-full'>
                                <div id='Biology' className='menu-section'>
                                    <Link
                                        to={ROUTES.Subject.SUBJECT_SLUG('biology')}
                                        className='subject-name'
                                    >
                                        <span>{<WrappedMessage message={messages.biologySubject}/>}</span>
                                        <Icon name='arrow-link-tilted' className='heading-icon' />
                                    </Link>
                                    <div className='frame-19'>
                                        <div className='menu-section-column'>
                                            <div className='collection-list-wrapper w-dyn-list'>
                                                <div role='list' className='collection-list w-dyn-items'>
                                                    {renderSubjectContent(biology)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flagship-content-callout'>
                                        <div className='div-block-39'>
                                            <div className='frame-789'>
                                                <img
                                                    src={vectorImage}
                                                    loading='lazy' width='29' height='29' alt=''
                                                    className='vectors-wrapper'/>
                                                <div className='frame-729'>
                                                    <div className='cluster-name'>
                                                        <WrappedMessage
                                                            message={messages.introToSubjectsLine}
                                                            values={{subject: <WrappedMessage message={messages.biologySubject}/>}}
                                                        />
                                                    </div>
                                                    <div className='cluster-subtitle'>{<WrappedMessage message={messages.overviewTextSubheading} />}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <NavLink
                                            to='/library/clusters/lx-cluster:IntroBio'
                                            className='tertiary-button-navy w-inline-block'
                                            data-testid='bio-cluster'
                                            onClick={() => {
                                                analyticsInstance.track(EVENT_NAMES.SubjectsPageFlagshipLinkClicked, {
                                                    subject_text: 'Biology',
                                                    callout_title: 'Introduction to biology',
                                                    callout_subtitle: 'Overview of core topics',
                                                    button_text: 'Explore Cluster',
                                                    button_link: '/library/clusters/lx-cluster:IntroBio',
                                                    url: window.location.toString(),
                                                });
                                            }}
                                        >
                                            <div className='cluster-btn-text'>
                                                <WrappedMessage message={messages.exploreClusterButton}/>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div id='Chemistry' className='menu-section'>
                                    <div className='subject-name'>{<WrappedMessage message={messages.chemistrySubject}/>}</div>
                                    <div className='frame-19'>
                                        <div className='menu-section-column'>
                                            <div className='collection-list-wrapper w-dyn-list'>
                                                <div role='list' className='collection-list w-dyn-items'>
                                                    {renderSubjectContent(chemistry)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flagship-content-callout'>
                                        <div className='div-block-39'>
                                            <div className='frame-789'>
                                                <img
                                                    src={vectorImage}
                                                    loading='lazy' width='29' height='29' alt=''
                                                    className='vectors-wrapper'/>
                                                <div className='frame-729'>
                                                    <div className='cluster-name'>
                                                        <WrappedMessage
                                                            message={messages.introToSubjectsLine}
                                                            values={{subject: <WrappedMessage message={messages.chemistrySubject}/>}}
                                                        />
                                                    </div>
                                                    <div className='cluster-subtitle'>
                                                        {<WrappedMessage message={messages.overviewTextSubheading} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <NavLink
                                            to='/library/clusters/lx-cluster:ChemistryResources'
                                            className='tertiary-button-navy w-inline-block'
                                            data-testid='chemistry-cluster'
                                            onClick={() => {
                                                analyticsInstance.track(EVENT_NAMES.SubjectsPageFlagshipLinkClicked, {
                                                    subject_text: 'Chemistry',
                                                    callout_title: 'Introduction to Chemistry',
                                                    callout_subtitle: 'Overview of core topics',
                                                    button_text: 'Explore Cluster',
                                                    button_link: '/library/clusters/lx-cluster:ChemistryResources',
                                                    url: window.location.toString(),
                                                });
                                            }}
                                        >
                                            <div className='cluster-btn-text'>{<WrappedMessage message={messages.exploreClusterButton}/>}</div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div id='Physics' className='menu-section'>
                                    <div className='subject-name'>{<WrappedMessage message={messages.physicsSubject}/>}</div>
                                    <div className='frame-19'>
                                        <div className='menu-section-column'>
                                            <div className='collection-list-wrapper w-dyn-list'>
                                                <div role='list' className='collection-list w-dyn-items'>
                                                    {renderSubjectContent(physics)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flagship-content-callout'>
                                        <div className='div-block-39'>
                                            <div className='frame-789'>
                                                <img
                                                    src={vectorImage}
                                                    loading='lazy' width='29' height='29' alt=''
                                                    className='vectors-wrapper'/>
                                                <div className='frame-729'>
                                                    <div className='cluster-name'>
                                                        <WrappedMessage
                                                            message={messages.introToSubjectsLine}
                                                            values={{subject: <WrappedMessage message={messages.physicsSubject}/>}}
                                                        />
                                                    </div>
                                                    <div className='cluster-subtitle'><WrappedMessage message={messages.overviewTextSubheading}/></div>
                                                </div>
                                            </div>
                                        </div>
                                        <NavLink
                                            to='/library/clusters/lx-cluster:PhysicsResources'
                                            className='tertiary-button-navy w-inline-block'
                                            data-testid='physics-resources-button'
                                            onClick={() => {
                                                analyticsInstance.track(EVENT_NAMES.SubjectsPageFlagshipLinkClicked, {
                                                    subject_text: 'Physics',
                                                    callout_title: 'Introduction to physics',
                                                    callout_subtitle: 'Overview of core topics',
                                                    button_text: 'Explore Cluster',
                                                    button_link: '/library/clusters/lx-cluster:PhysicsResources',
                                                    url: window.location.toString(),
                                                });
                                            }}
                                        >
                                            <div className='cluster-btn-text'><WrappedMessage message={messages.exploreClusterButton}/></div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div id='Earth & Space' className='menu-section last'>
                                    <div className='subject-name'><WrappedMessage message={messages.earthSpaceSubjects}/></div>
                                    <div className='frame-19'>
                                        <div className='menu-section-column'>
                                            <div className='collection-list-wrapper w-dyn-list'>
                                                <div role='list' className='collection-list w-dyn-items'>
                                                    {renderSubjectContent(earthSpace)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flagship-content-callout'>
                                        <div className='div-block-39'>
                                            <div className='frame-789'>
                                                <img src={vectorImage} loading='lazy' width='29' height='29' alt=''
                                                     className='vectors-wrapper'/>
                                                <div className='frame-729'>
                                                    <div className='cluster-name'>
                                                        <WrappedMessage message={messages.earthScienceSection}/>
                                                    </div>
                                                    <div className='cluster-subtitle'><WrappedMessage message={messages.overviewTextSubheading}/></div>
                                                </div>
                                            </div>
                                        </div>
                                        <NavLink
                                            to='/library/clusters/lx-cluster:Science_Mom_Earth_Science'
                                            className='tertiary-button-navy w-inline-block'
                                            data-testid='earth-and-science-cluster-button'
                                            onClick={() => {
                                                analyticsInstance.track(EVENT_NAMES.SubjectsPageFlagshipLinkClicked, {
                                                    subject_text: 'Earth & science',
                                                    callout_title: 'Introduction to earth\'s science',
                                                    callout_subtitle: 'Overview of core topics',
                                                    button_text: 'Explore Cluster',
                                                    button_link: '/library/clusters/lx-cluster:Science_Mom_Earth_Science',
                                                    url: window.location.toString(),
                                                });
                                            }}
                                        >
                                            <div className='cluster-btn-text'><WrappedMessage message={messages.exploreClusterButton}/></div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='menu-section last callout'>
                                {!showCollapsibles && (
                                    <>
                                        <div className='subject-name'><WrappedMessage message={messages.librarySectionTitle} /></div>
                                        <p className='paragraph'><WrappedMessage message={messages.librarySectionDescription} /></p>
                                    </>
                                )}
                               <NavLink
                                    to='/library'
                                    className='tertiary-button-navy secondary w-inline-block'
                                    data-testid='open-library-button'
                                    onClick={() => { analyticsInstance.track(EVENT_NAMES.SubjectsPageOpenLibraryClicked, { subjects, url: window.location.toString() });}}
                                >
                                    <span className='cluster-btn-text'><WrappedMessage message={messages.librarySectionActionLabel} /></span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </StandardPageLayout>
    );
};
