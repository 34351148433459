// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Thread,
    ThreadFromJSON,
    ThreadToJSON,
} from './';

/**
 * 
 * @export
 * @interface DiscussionSubCategory
 */
export interface DiscussionSubCategory {
    /**
     * 
     * @type {string}
     * @memberof DiscussionSubCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionSubCategory
     */
    name: string;
    /**
     * Verbose description of the discussion subcategory.
     * @type {string}
     * @memberof DiscussionSubCategory
     */
    description?: string;
    /**
     * Icon to be displayed alongside the subcategory in the discussions page.  Must be either SVG or PNG.
     * @type {string}
     * @memberof DiscussionSubCategory
     */
    readonly icon?: string;
    /**
     * 
     * @type {Date}
     * @memberof DiscussionSubCategory
     */
    latestReply?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscussionSubCategory
     */
    latestThread?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscussionSubCategory
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscussionSubCategory
     */
    readonly updated?: Date;
    /**
     * 
     * @type {number}
     * @memberof DiscussionSubCategory
     */
    author: number;
    /**
     * 
     * @type {number}
     * @memberof DiscussionSubCategory
     */
    replyCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscussionSubCategory
     */
    viewCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscussionSubCategory
     */
    participantCount?: number;
    /**
     * Serialize subcategory threads adding the respective permissions
     * @type {Array<Thread>}
     * @memberof DiscussionSubCategory
     */
    readonly threadSet?: Array<Thread>;
    /**
     * 
     * @type {string}
     * @memberof DiscussionSubCategory
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof DiscussionSubCategory
     */
    readonly categoryName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscussionSubCategory
     */
    predefinedTags?: Array<string>;
}

export function DiscussionSubCategoryFromJSON(json: any): DiscussionSubCategory {
    return {
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'latestReply': !exists(json, 'latest_reply') ? undefined : new Date(json['latest_reply']),
        'latestThread': !exists(json, 'latest_thread') ? undefined : new Date(json['latest_thread']),
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
        'updated': !exists(json, 'updated') ? undefined : new Date(json['updated']),
        'author': json['author'],
        'replyCount': !exists(json, 'reply_count') ? undefined : json['reply_count'],
        'viewCount': !exists(json, 'view_count') ? undefined : json['view_count'],
        'participantCount': !exists(json, 'participant_count') ? undefined : json['participant_count'],
        'threadSet': !exists(json, 'thread_set') ? undefined : (json['thread_set'] as Array<any>).map(ThreadFromJSON),
        'category': json['category'],
        'categoryName': !exists(json, 'category_name') ? undefined : json['category_name'],
        'predefinedTags': !exists(json, 'predefined_tags') ? undefined : json['predefined_tags'],
    };
}

export function DiscussionSubCategoryToJSON(value?: DiscussionSubCategory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'latest_reply': value.latestReply === undefined ? undefined : value.latestReply.toISOString(),
        'latest_thread': value.latestThread === undefined ? undefined : value.latestThread.toISOString(),
        'author': value.author,
        'reply_count': value.replyCount,
        'view_count': value.viewCount,
        'participant_count': value.participantCount,
        'category': value.category,
        'predefined_tags': value.predefinedTags,
    };
}


