import { LxConfig } from 'global/constants';
import { DEFAULT_UI_LANGUAGE, parseSupportedLanguageCode } from 'i18n';
import { ItemMetadataLicenseEnum } from 'labxchange-client';
import { Author } from 'labxchange-client';

const YOUTUBE_V3_API_URL = 'https://www.googleapis.com/youtube/v3';

function youTubeV3ApiUrl(endpoint: string, params: {}) {
    const query = new URLSearchParams({key: LxConfig.YouTubeApiKey, ...params}).toString();
    return `${YOUTUBE_V3_API_URL}/${endpoint}?${query}`;
}

export async function getVideoDetails(videoId: string) {
    const params = {
        id: videoId,
        part: 'snippet,status',
    };

    const response = await fetch(youTubeV3ApiUrl('videos', params));
    const json = await response.json();

    if (response.status !== 200) {
        let message = 'YouTube API request failed.';
        if (json.error && json.error.message) {
            message += ' ' + json.error.message;
        }
        throw new Error(message);
    }
    return json;
}

export async function getEmbedCode(videoId: string) {
    const params = {
        id: videoId,
        part: 'player',
    };

    const response = await fetch(youTubeV3ApiUrl('videos', params));
    const json = await response.json();

    if (response.status !== 200) {
        let message = 'YouTube API request failed.';
        if (json.error && json.error.message) {
            message += ' ' + json.error.message;
        }
        throw new Error(message);
    }

    return json.items[0].player.embedHtml;
}

export async function getChannelDetails(channelId: string) {
    const params = {
        id: channelId,
        part: 'snippet,status',
    };

    const response = await fetch(youTubeV3ApiUrl('channels', params));
    const json = await response.json();

    if (response.status !== 200) {
        let message = 'YouTube API request failed.';
        if (json.error && json.error.message) {
            message += ' ' + json.error.message;
        }
        throw new Error(message);
    }
    return json;
}

export function getYouTubeIdFromUrl(url: string) {
    // https://youtube.com/watch?v=MCTA280TzWc or youtu.be/watch?v=MCTA280TzWc
    let match = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/);
    if (!match) {
        // https://youtu.be/MCTA280TzWc
        match = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be)\/([^&]+)/);
    }
    return match && match[1];
}

export async function getYoutubeParams(youTubeVideoId: string, author?: Author) {
    const videoDetails = await getVideoDetails(youTubeVideoId);
    const video = videoDetails.items[0];

    // Here we try to detect the video language.
    // If the language was set on the video, but we couldn't match a supported language,
    // then set originalLanguage so we can show a warning on the ItemEditPage.
    let language = DEFAULT_UI_LANGUAGE.key;
    let originalLanguage;
    const defaultLanguage = video.snippet.defaultLanguage || video.snippet.defaultAudioLanguage;
    if (defaultLanguage) {
        const parsedLanguage = parseSupportedLanguageCode([defaultLanguage]);
        if (parsedLanguage) {
            language = parsedLanguage;
        } else {
            originalLanguage = defaultLanguage;
        }
    }

    const thumbnails = video.snippet.thumbnails;
    // Some YouTube videos don't contain all thumbnail sizes.
    const thumbnail = thumbnails.standard || thumbnails.high || thumbnails.medium || thumbnails.default;

    const authors = [];
    if (author) {
        authors.push(author);
    }
    authors.push({fullName: video.snippet.channelTitle, originalAuthor: true});
    const params = {
        title: video.snippet.title,
        description: video.snippet.description,
        language,
        originalLanguage,
        freeFormTags: video.snippet.tags,
        authors,
        license: video.status.license === 'creativeCommons' ?
            ItemMetadataLicenseEnum.CCBY40 :
            ItemMetadataLicenseEnum.LX1,
        imageUrl: thumbnail && thumbnail.url,
        blockEditorDefaults: {
            youTubeId: youTubeVideoId,
            transcripts: {},
        },
    };
    return params;
}