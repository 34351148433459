import update from 'immutability-helper';
import { MessageDescriptor } from 'react-intl';
import { ActionTypes, MessageType, Types } from './actions';

export interface Message {
    type: MessageType;
    title?: React.ReactNode;
    message: React.ReactNode;
    bodyHtmlAppend?: React.ReactNode;
    onConfirm?: () => void;
    onClose?: () => void;
    confirmText?: MessageDescriptor;
    cancelText?: MessageDescriptor;
}

export interface GlobalMessagesState {
    messages: ReadonlyArray<Message>;
}

/**
 * Reducer that maintains the state of the notifications
 */
export function globalMessagesReducer(
    state: Readonly<GlobalMessagesState> = {messages: []},
    action: ActionTypes,
): Readonly<GlobalMessagesState> {
    switch (action.type) {
        case Types.DISMISS_MESSAGE:
            // Pop the first message from the array
            return update(state, {messages: {$splice: [[0, 1]]}});
        case Types.SHOW_MESSAGE:
            const newMessage: Message = {
                type: action.messageType,
                title: action.title,
                message: action.message,
                bodyHtmlAppend: action.bodyHtmlAppend,
                onConfirm: action.onConfirm,
                onClose: action.onClose,
                confirmText: action.confirmText,
                cancelText: action.cancelText,
            };
            // Push the new message onto the end of the array:
            return update(state, {messages: {$push: [newMessage]}});
        default:
            return state;
    }
}
