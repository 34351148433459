import * as React from 'react';
import { useHistory } from 'react-router';

import messages from 'clusters/displayMessages';
import { ItemsApi } from 'global/api';
import { ItemMetadata, ItemTranslation } from 'labxchange-client';
import { TranslationSelectorModal } from 'library/components';
import libraryMessages, {
    getBackgroundKnowledgeLabel,
    getContentLicenseLabel,
} from 'library/displayMessages';
import { SubjectTags } from 'library/components/ItemMetadataTable';

import { detailUrlForEntity } from 'library/utils';
import { Icon, showErrorMessage } from 'ui/components';
import { getLanguageNativeName } from 'i18n';
import { WrappedMessage } from 'utils';
import { LanguageItem } from 'library/components/TranslationSelectorModal/TranslationSelectorModal';

// Only show views if more than this number.
const ViewsDisplayThreshold = 1000;

interface ItemActionsProps {
    metadata: ItemMetadata;
}

interface ItemActionsState {
    showLanguageSwitcherModal: boolean;
    showDetails: boolean;
    translations: LanguageItem[];
}


export const MetaHeader = (props: ItemActionsProps) => {
    const history = useHistory();
    const [showLanguageSwitcherModal, setShowLanguageSwitcherModal] = React.useState(false);
    const [showDetails, setShowDetails] = React.useState(false);
    const [translations, setTranslations] = React.useState<ItemTranslation[]>([]);

    // Retrieve translations for item
    const retrieveTranslations = async () => {
        setShowLanguageSwitcherModal(false);
        if (props.metadata.id !== '') {
            try {
                const response = await ItemsApi.languages({id: props.metadata.id});
                setTranslations(response);
            } catch (error) {
                showErrorMessage(<WrappedMessage message={messages.loadingPathwayTranslationsError}/>);
            }
        }
    };
    // Run if ID changes and when component first loads
    React.useEffect(() => {
        retrieveTranslations();
    }, [props.metadata]);

    // Render component
    const { metadata } = props;
    const languageCode = metadata.language;
    const languageName = getLanguageNativeName(languageCode);

    const toggleIcon = showDetails ? 'chevron-up' : 'chevron-down';
    const toggleMessage = showDetails ? messages.clusterHideDetails : messages.clusterShowDetails;

    return (
        <>
            <button
                onClick={() => setShowDetails(!showDetails)}
                className='show-details lx-btn hide-on-tablet-and-above'
            >
                <WrappedMessage message={toggleMessage}/>
                <Icon name={toggleIcon} />
            </button>
            <ul className={`meta-information row ${!showDetails ? 'lxc-hide-mobile' : ''}`}>
                <li className='col-lg-2 col-md-4 col-sm-12'>
                    <span className='key'>
                        <Icon name='star' /><WrappedMessage message={libraryMessages.metadataFavorites}/>
                    </span>
                    <span className='value'>
                        {metadata.stats.favorites}
                    </span>
                </li>

                {metadata.stats.views > ViewsDisplayThreshold &&
                    <li className='col-lg-2 col-md-4 col-sm-12'>
                        <span className='key'>
                            <Icon name='eye'/><WrappedMessage message={libraryMessages.metadataViews}/>
                        </span>
                        <span className='value'>
                            {metadata.stats.views}
                        </span>
                    </li>
                }
                <li className='col-lg-2 col-md-4 col-sm-12'>
                    <span className='key'>
                        <Icon name='subject' /><WrappedMessage message={libraryMessages.metadataSubject}/>
                    </span>
                    <span className='value'>
                        <SubjectTags subjectTags={metadata.subjectTags} />
                    </span>
                </li>
                <li className='col-lg-2 col-md-4 col-sm-12'>
                    <span className='key'>
                        <Icon name='language' /><WrappedMessage message={libraryMessages.metadataLanguage}/>
                    </span>
                    <span className='value'>
                        {languageName}
                        {translations.length > 1 &&
                            <button
                                className='language-switcher'
                                onClick={() => setShowLanguageSwitcherModal(true)}
                            >
                                &nbsp;(<WrappedMessage message={messages.clusterLanguageChange} />)
                            </button>
                        }
                    </span>
                </li>
                <li className='col-lg-2 col-md-4 col-sm-12'>
                    <span className='key'>
                        <Icon name='background-knowledge' />
                        <WrappedMessage message={libraryMessages.metadataBackgroundKnowledge}/>
                    </span>
                    <span className='value'>
                        <WrappedMessage message={getBackgroundKnowledgeLabel(metadata.backgroundKnowledge)}/>
                    </span>
                </li>
                <li className='col-lg-2 col-md-4 col-sm-12'>
                    <span className='key'>
                        <Icon name='license' /><WrappedMessage message={libraryMessages.metadataLicense}/>
                    </span>
                    <span className='value'>
                        <WrappedMessage message={getContentLicenseLabel(metadata.license)} />
                    </span>
                </li>
                {showLanguageSwitcherModal &&
                    <TranslationSelectorModal
                        translations={translations}
                        metadata={metadata}
                        onClose={() => setShowLanguageSwitcherModal(false)}
                        onConfirm={(translation) => {
                            history.push(detailUrlForEntity(
                                {id: translation.item, type: translation.type},
                            ));
                        }}
                    />
                }
            </ul>
        </>
    );
};
