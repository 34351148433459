import * as React from 'react';
import bind from 'bind-decorator';
import { CollapsibleWithChevron } from 'elements/components/Containers';
import {
    VideoTranscript,
    ItemMetadata,
    ItemTranslation, VideoDataState
} from 'labxchange-client';
import { Icon } from 'ui/components';
import messages from 'library/displayMessages';
import { XBlocksApi } from 'global/api';
import { WrappedMessage } from 'utils';
import { TranslationSelectorModal } from 'library/components';
import { LanguageItem } from 'library/components/TranslationSelectorModal/TranslationSelectorModal';
import { getAvailableTranscript, availableTranscriptLanguages } from 'library/components/Transcript/utils';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { intl } from 'i18n';

interface RecordProps {
    id: string;
    text: string;
    start: number;
    end: number;
    active?: boolean;
    setVideoTimePosition: () => void;
}

class Record extends React.Component<RecordProps, {}> {

    render() {
        return (
            <div
                id={`timestamp-${this.props.start}`}
                className={`row ${this.props.active ? 'active': ''}`}
                onClick={this.props.setVideoTimePosition}
                onKeyDown={this.keyPressHandler}
                role='link'
                tabIndex={0}
            >
                <div className='timestamp' aria-hidden='true'>{this.displayTimestamps()}</div>
                <div className='text'>{this.props.text}</div>
            </div>
        );
    }

    @bind private keyPressHandler(e: any) {
        // Jump to timestamp on enter key press
        if (e.keyCode === 13) {
            this.props.setVideoTimePosition();
        }
    }

    private displayTimestamps() {
        const timeString = new Date(this.props.start * 1000).toISOString();
        let time = timeString.substr(14, 5);
        if (this.props.start >= 60 * 60) {
            // If video length more then 60 min, display hh:mm:ss
            time = timeString.substr(11, 8);
        }
        return time;
    }
}

interface TrackingTranscript extends VideoTranscript {
    active?: boolean;
}

interface AvailableTranscriptLanguages {
    code: string;
    languageNative: string;
}

interface TranscriptProps {
    currentTime: number;
    setVideoTimePosition: (time: number) => void;
    itemMetadata: ItemMetadata;
    videoBlockState: VideoDataState;
    transcriptVideoID?: string;
}

interface TranscriptState {
    freeze: boolean;
    translations: LanguageItem[];
    displayModal: boolean;
    transcripts: TrackingTranscript[];
    languages: {code: string, languageNative: string}[];
    queriedTranscripts: Map<string, TrackingTranscript[]>;
}

export class Transcript extends React.Component<TranscriptProps, TranscriptState> {

    constructor(props: TranscriptProps) {
        super(props);
        this.state = {
            freeze: false,
            displayModal: false,
            translations: [],
            transcripts: [],
            languages: [],
            queriedTranscripts: new Map()
        };
    }

    componentDidMount() {
        const availableTranscript = getAvailableTranscript(this.props.videoBlockState.transcripts, this.props.itemMetadata.language);
        if (availableTranscript) {
            this.findLanguageTranscript(availableTranscript);
        }
        this.setState({
            languages: availableTranscriptLanguages(this.props.videoBlockState.transcripts || [])
        });
    }

    componentDidUpdate() {
        for (const transcript of this.state.transcripts) {
            transcript.active = false;
            if (this.props.currentTime > transcript.start && this.props.currentTime < transcript.end) {
                transcript.active = true;
                const id = `timestamp-${transcript.start}`;
                const element = document.getElementById(id);
                if (element && element.parentElement && !this.state.freeze) {
                const offsetStep = 40;
                    element.parentElement.scroll({ top: element.offsetTop - element.offsetHeight - offsetStep});
                }
                break;
            }
        }
    }

    render() {
        if (this.state.transcripts.length === 0 ) {
            return null;
        }
        return (
            <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                className='transcripts-widget mt-4 mb-1'
                onMouseEnter={() => this.setState({ freeze: true })}
                onMouseLeave={() => this.setState({ freeze: false })}
                role='region'
            >
                <CollapsibleWithChevron
                    title={intl.formatMessage(messages.videoTranscriptsHeader)}
                    defaultOpen={false}
                    headerActions={this.headerActions()}
                >
                    {this.state.transcripts.map(transcript =>
                        <Record
                            id={`timestamp-${transcript.start}`}
                            key={`timestamp-${transcript.start}-${transcript.end}`}
                            setVideoTimePosition={() => this.props.setVideoTimePosition(transcript.start)}
                            {...transcript}
                        />
                    )}
                </CollapsibleWithChevron>
                {this.state.displayModal ?
                    <TranslationSelectorModal
                        translations={this.getTranslations()}
                        metadata={this.props.itemMetadata}
                        onClose={() => this.closeModal()}
                        onConfirm={this.loadLanguageTranscript}
                    />
                : null}
            </div>
        );
    }

    /**
     * If languages doesn't exists - provide empty span element
     */
    private headerActions() {
        if (this.state.languages.length > 1) {
            return (<button
                    className='btn lx-btn-unstyled translation-switcher'
                    onClick={this.translationSwitchHandler}
                >
                    <Icon name='language' />
                    <WrappedMessage message={messages.videoTranscriptsTranslate} />
                </button>
            );
        }
        return <span></span>;
    }

    @bind getTranslations(): LanguageItem[] {
        return this.state.languages.map(lang => ({
            language: lang.code,
            languageNative: lang.languageNative
        }));
    }

    @bind private closeModal() {
        this.setState({ displayModal: false });
    }

    @bind private loadLanguageTranscript(language: ItemTranslation) {
        this.findLanguageTranscript(language.language);
        this.closeModal();
    }

    @bind private translationSwitchHandler(event: any) {
        this.setState({ displayModal: true });
        event.stopPropagation();
    }

    @bind public async findLanguageTranscript(language: string) {
        let transcripts = this.state.queriedTranscripts.get(language) || [];
        if (transcripts.length === 0) {
            try {
                transcripts = await XBlocksApi.videoTranscriptJson({ id: this.props.transcriptVideoID || this.props.itemMetadata.id, language});

                // Update states of queried languages
                const queriedTranscripts = this.state.queriedTranscripts;
                queriedTranscripts.set(language, transcripts);
                this.setState({
                    queriedTranscripts
                });
            } catch (error) {
                showErrorMessage(<WrappedMessage message={messages.errorLoadingVideo} />, {
                    exception: error,
                });
                return;
            }
        }
        this.setState({ transcripts });
    }
}
