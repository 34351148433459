import React from 'react';
import { Icon, IconSymbol } from 'elements';
import { iconColors, NodeDataProps } from './EducationPathway';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface SidePanelRowProps {
    icon: {
        iconName: IconSymbol,
        iconColor: string,
        iconBackground: string,
    };
    title: string;
    subtitle: string;
}

const SidePanelRow: React.FC<SidePanelRowProps> = ({ icon, title, subtitle }) => {
  return (
    <>
      <div className='side-panel__divider' />
      <div className='side-panel__row'>
        <div className='side-panel__icon' style={{backgroundColor: icon.iconBackground}}>
            <Icon name={icon.iconName} fill={icon.iconColor} />
        </div>
        <div className='side-panel__info-content'>
          <div className='side-panel__info-title'>{title}</div>
          <div className='side-panel__info-subtitle'>{subtitle}</div>
        </div>
      </div>
    </>
  );
};

interface SidePanelProps {
    nodeData: NodeDataProps;
    onViewGuide: (event: any) => void;
}

const SidePanel: React.FC<SidePanelProps> = ({ nodeData, onViewGuide }) => {

    return (
        <div className='side-panel'>
            <div className='side-panel__body'>
                <div className='side-panel__info'>
                    {nodeData.dataRows.map((row, i) => (
                        <SidePanelRow
                            key={i}
                            title={row.title}
                            subtitle={row.subTitle}
                            icon={{
                                iconName: row.iconName,
                                iconColor: iconColors.iconColor[nodeData.nodeType],
                                iconBackground: iconColors.iconBackground[nodeData.nodeType],
                            }}
                        />
                    ))}
                    {nodeData.dataRows.length > 0 &&
                        <div className='side-panel__divider' />
                    }
                </div>

                {nodeData.guide &&
                    <div className='side-panel__guide'>
                        <p className='side-panel__guide-text'>
                            {nodeData.guide.overview}
                        </p>
                        {nodeData.guide.details &&
                            <div className='side-panel__link-container'>
                                <a className='side-panel__link' onClick={onViewGuide} href='#'>
                                    <WrappedMessage message={messages.viewGuideLinkLabel} />
                                </a>
                                <Icon name='arrow-right-thin' fill='#00AEEF'/>
                            </div>
                        }
                    </div>
                }

                {nodeData.otherCareers &&
                    <div className='side-panel__others'>
                        <span className='side-panel__others-heading'><WrappedMessage message={messages.otherCareerOptionsLabel} /></span>
                        <p className='side-panel__others-text'>
                            {nodeData.otherCareers.description}
                        </p>
                        <ul>
                            {nodeData.otherCareers.careers.map((career: any, i: number) => (
                                <li key={i}>
                                    <a className='side-panel__link' href={career.url}>
                                        {career.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </div>
        </div>
    );
};

export default SidePanel;
