// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ChildItem,
    ChildItemFromJSON,
    ChildItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface PathwayDetail
 */
export interface PathwayDetail {
    /**
     * 
     * @type {string}
     * @memberof PathwayDetail
     */
    image?: string;
    /**
     * 
     * @type {Array<ChildItem>}
     * @memberof PathwayDetail
     */
    items: Array<ChildItem>;
}

export function PathwayDetailFromJSON(json: any): PathwayDetail {
    return {
        'image': !exists(json, 'image') ? undefined : json['image'],
        'items': (json['items'] as Array<any>).map(ChildItemFromJSON),
    };
}

export function PathwayDetailToJSON(value?: PathwayDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'image': value.image,
        'items': (value.items as Array<any>).map(ChildItemToJSON),
    };
}


