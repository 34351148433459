// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddToAssignment,
    AddToAssignmentFromJSON,
    AddToAssignmentToJSON,
    AddToCaseStudy,
    AddToCaseStudyFromJSON,
    AddToCaseStudyToJSON,
    AddToTeachingGuide,
    AddToTeachingGuideFromJSON,
    AddToTeachingGuideToJSON,
    ImageUpload,
    ImageUploadFromJSON,
    ImageUploadToJSON,
    ItemCloneForTranslation,
    ItemCloneForTranslationFromJSON,
    ItemCloneForTranslationToJSON,
    ItemCreation,
    ItemCreationFromJSON,
    ItemCreationToJSON,
    ItemMetadataUpdate,
    ItemMetadataUpdateFromJSON,
    ItemMetadataUpdateToJSON,
    ItemResponse,
    ItemResponseFromJSON,
    ItemResponseToJSON,
    ItemTranslation,
    ItemTranslationFromJSON,
    ItemTranslationToJSON,
    PathwayDetail,
    PathwayDetailFromJSON,
    PathwayDetailToJSON,
    PathwayUpdate,
    PathwayUpdateFromJSON,
    PathwayUpdateToJSON,
    RecommendedItems,
    RecommendedItemsFromJSON,
    RecommendedItemsToJSON,
    Subject,
    SubjectFromJSON,
    SubjectToJSON,
    XBlockOLX,
    XBlockOLXFromJSON,
    XBlockOLXToJSON,
    XBlockStaticUploadResult,
    XBlockStaticUploadResultFromJSON,
    XBlockStaticUploadResultToJSON,
} from '../models';

export interface DeleteRequest {
    id: string;
}

export interface AddAssessmentToAssignmentRequest {
    id: string;
    data: AddToAssignment;
}

export interface AddItemToCaseStudyRequest {
    id: string;
    data: AddToCaseStudy;
}

export interface AddItemToTeachingGuideRequest {
    id: string;
    data: AddToTeachingGuide;
}

export interface BlocksOlxPartialUpdateRequest {
    id: string;
    data: Array<XBlockOLX>;
}

export interface BlocksOlxReadRequest {
    id: string;
}

export interface CloneForTranslationRequest {
    id: string;
    data: ItemCloneForTranslation;
}

export interface CreateRequest {
    data: ItemCreation;
}

export interface EditXblockRequest {
    blockKey: string;
    id: string;
}

export interface EditXblockSaveRequest {
    blockKey: string;
    id: string;
    data: any;
}

export interface EditXblockSaveAssetRequest {
    blockKey: string;
    filename: string;
    id: string;
    file?: Blob;
}

export interface ImageCreateRequest {
    id: string;
    image?: Blob;
    imageUrl?: string;
}

export interface ImageDeleteRequest {
    id: string;
}

export interface LanguagesRequest {
    id: string;
}

export interface MarkCompleteRequest {
    id: string;
}

export interface MarkIncompleteRequest {
    id: string;
}

export interface PartialUpdateRequest {
    id: string;
    data: ItemMetadataUpdate;
}

export interface PathwayRequest {
    id: string;
}

export interface PathwayAssignItemsRequest {
    id: string;
    data: any;
}

export interface PathwayCloneRequest {
    id: string;
}

export interface PathwayUpdateRequest {
    id: string;
    data: PathwayUpdate;
}

export interface ReadRequest {
    id: string;
}

export interface RecommendedItemsRequest {
    id: string;
    similar?: boolean;
    moreByAuthor?: boolean;
    moreBySource?: boolean;
}

export interface SubjectAreaTranslationsRequest {
    id: string;
}

/**
 * no description
 */
export class ItemsApi extends runtime.BaseAPI {

    /**
     * Delete an item
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling _delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Delete an item
     */
    async _delete(requestParameters: DeleteRequest): Promise<string> {
        const response = await this._deleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add an assessment asset to an assignment asset. Convenience method for the flow of creating and adding an assessment to an assignment.
     */
    async addAssessmentToAssignmentRaw(requestParameters: AddAssessmentToAssignmentRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addAssessmentToAssignment.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addAssessmentToAssignment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/add_assessment_to_assignment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddToAssignmentToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Add an assessment asset to an assignment asset. Convenience method for the flow of creating and adding an assessment to an assignment.
     */
    async addAssessmentToAssignment(requestParameters: AddAssessmentToAssignmentRequest): Promise<any> {
        const response = await this.addAssessmentToAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * If `section_id` is empty/none, add as an attachment rather than a section item.
     * Add an asset to a case study asset. Convenience method for the flow of creating and adding an item to a case study.
     */
    async addItemToCaseStudyRaw(requestParameters: AddItemToCaseStudyRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addItemToCaseStudy.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addItemToCaseStudy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/add_item_to_case_study`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddToCaseStudyToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * If `section_id` is empty/none, add as an attachment rather than a section item.
     * Add an asset to a case study asset. Convenience method for the flow of creating and adding an item to a case study.
     */
    async addItemToCaseStudy(requestParameters: AddItemToCaseStudyRequest): Promise<any> {
        const response = await this.addItemToCaseStudyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add an asset to a teaching guide asset. Convenience method for the flow of creating and adding an item to a teaching guide.  If `section_id` is empty/none, add as an attachment rather than a section item.
     */
    async addItemToTeachingGuideRaw(requestParameters: AddItemToTeachingGuideRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addItemToTeachingGuide.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addItemToTeachingGuide.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/add_item_to_teaching_guide`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddToTeachingGuideToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Add an asset to a teaching guide asset. Convenience method for the flow of creating and adding an item to a teaching guide.  If `section_id` is empty/none, add as an attachment rather than a section item.
     */
    async addItemToTeachingGuide(requestParameters: AddItemToTeachingGuideRequest): Promise<any> {
        const response = await this.addItemToTeachingGuideRaw(requestParameters);
        return await response.value();
    }

    /**
     * This endpoint also allows patch requests to make arbitrary updates to the olx.
     * Get a list of all the constituent XBlocks for this item as well as the OLX source code of each block.
     */
    async blocksOlxPartialUpdateRaw(requestParameters: BlocksOlxPartialUpdateRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blocksOlxPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling blocksOlxPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/blocks_olx`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(XBlockOLXToJSON),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * This endpoint also allows patch requests to make arbitrary updates to the olx.
     * Get a list of all the constituent XBlocks for this item as well as the OLX source code of each block.
     */
    async blocksOlxPartialUpdate(requestParameters: BlocksOlxPartialUpdateRequest): Promise<any> {
        const response = await this.blocksOlxPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * This endpoint also allows patch requests to make arbitrary updates to the olx.
     * Get a list of all the constituent XBlocks for this item as well as the OLX source code of each block.
     */
    async blocksOlxReadRaw(requestParameters: BlocksOlxReadRequest): Promise<runtime.ApiResponse<Array<XBlockOLX>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blocksOlxRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/blocks_olx`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(XBlockOLXFromJSON));
    }

    /**
     * This endpoint also allows patch requests to make arbitrary updates to the olx.
     * Get a list of all the constituent XBlocks for this item as well as the OLX source code of each block.
     */
    async blocksOlxRead(requestParameters: BlocksOlxReadRequest): Promise<Array<XBlockOLX>> {
        const response = await this.blocksOlxReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * This endpoint is very similar to pathway_clone but it can handle other than pathway items, check for specific permissions and duplicate item children too.
     * Clone an item/pathway in order to create a translation.
     */
    async cloneForTranslationRaw(requestParameters: CloneForTranslationRequest): Promise<runtime.ApiResponse<ItemResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cloneForTranslation.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling cloneForTranslation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/clone_for_translation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCloneForTranslationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint is very similar to pathway_clone but it can handle other than pathway items, check for specific permissions and duplicate item children too.
     * Clone an item/pathway in order to create a translation.
     */
    async cloneForTranslation(requestParameters: CloneForTranslationRequest): Promise<ItemResponse> {
        const response = await this.cloneForTranslationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create an item
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<ItemResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling create.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCreationToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemResponseFromJSON(jsonValue));
    }

    /**
     * Create an item
     */
    async create(requestParameters: CreateRequest): Promise<ItemResponse> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

    /**
     * The data that this API method accepts and returns depends on what type of XBlock is being edited.  Most items have a single XBlock, but it's necessary to specify a block ID to allowing edits in the case of items that are made of multiple blocks, such as a \"Video\" block that is built from a <unit> containing an <html> and a <video> block.
     * Get or save the settings data for one of the XBlocks that comprises this item.
     */
    async editXblockRaw(requestParameters: EditXblockRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.blockKey === null || requestParameters.blockKey === undefined) {
            throw new runtime.RequiredError('blockKey','Required parameter requestParameters.blockKey was null or undefined when calling editXblock.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editXblock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/blocks/{block_key}/edit/`.replace(`{${"block_key"}}`, encodeURIComponent(String(requestParameters.blockKey))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * The data that this API method accepts and returns depends on what type of XBlock is being edited.  Most items have a single XBlock, but it's necessary to specify a block ID to allowing edits in the case of items that are made of multiple blocks, such as a \"Video\" block that is built from a <unit> containing an <html> and a <video> block.
     * Get or save the settings data for one of the XBlocks that comprises this item.
     */
    async editXblock(requestParameters: EditXblockRequest): Promise<any> {
        const response = await this.editXblockRaw(requestParameters);
        return await response.value();
    }

    /**
     * The data that this API method accepts and returns depends on what type of XBlock is being edited.  Most items have a single XBlock, but it's necessary to specify a block ID to allowing edits in the case of items that are made of multiple blocks, such as a \"Video\" block that is built from a <unit> containing an <html> and a <video> block.
     * Get or save the settings data for one of the XBlocks that comprises this item.
     */
    async editXblockSaveRaw(requestParameters: EditXblockSaveRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.blockKey === null || requestParameters.blockKey === undefined) {
            throw new runtime.RequiredError('blockKey','Required parameter requestParameters.blockKey was null or undefined when calling editXblockSave.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editXblockSave.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling editXblockSave.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/blocks/{block_key}/edit/`.replace(`{${"block_key"}}`, encodeURIComponent(String(requestParameters.blockKey))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * The data that this API method accepts and returns depends on what type of XBlock is being edited.  Most items have a single XBlock, but it's necessary to specify a block ID to allowing edits in the case of items that are made of multiple blocks, such as a \"Video\" block that is built from a <unit> containing an <html> and a <video> block.
     * Get or save the settings data for one of the XBlocks that comprises this item.
     */
    async editXblockSave(requestParameters: EditXblockSaveRequest): Promise<any> {
        const response = await this.editXblockSaveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Save or replace a static asset associated with an XBlock. Returns the URL to save in the XBlock. Note that the returned URL may be a URL like \"/static/image.png\"
     */
    async editXblockSaveAssetRaw(requestParameters: EditXblockSaveAssetRequest): Promise<runtime.ApiResponse<XBlockStaticUploadResult>> {
        if (requestParameters.blockKey === null || requestParameters.blockKey === undefined) {
            throw new runtime.RequiredError('blockKey','Required parameter requestParameters.blockKey was null or undefined when calling editXblockSaveAsset.');
        }

        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling editXblockSaveAsset.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editXblockSaveAsset.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/items/{id}/blocks/{block_key}/asset/{filename}`.replace(`{${"block_key"}}`, encodeURIComponent(String(requestParameters.blockKey))).replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => XBlockStaticUploadResultFromJSON(jsonValue));
    }

    /**
     * Save or replace a static asset associated with an XBlock. Returns the URL to save in the XBlock. Note that the returned URL may be a URL like \"/static/image.png\"
     */
    async editXblockSaveAsset(requestParameters: EditXblockSaveAssetRequest): Promise<XBlockStaticUploadResult> {
        const response = await this.editXblockSaveAssetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send a POST to /api/v1/items/<item id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images. This image is used as the card image
     */
    async imageCreateRaw(requestParameters: ImageCreateRequest): Promise<runtime.ApiResponse<ImageUpload>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const formData = new FormData();
        if (requestParameters.image !== undefined) {
            formData.append('image', requestParameters.image as any);
        }

        if (requestParameters.imageUrl !== undefined) {
            formData.append('image_url', requestParameters.imageUrl as any);
        }

        const response = await this.request({
            path: `/items/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageUploadFromJSON(jsonValue));
    }

    /**
     * Send a POST to /api/v1/items/<item id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images. This image is used as the card image
     */
    async imageCreate(requestParameters: ImageCreateRequest): Promise<ImageUpload> {
        const response = await this.imageCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send a POST to /api/v1/items/<item id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images. This image is used as the card image
     */
    async imageDeleteRaw(requestParameters: ImageDeleteRequest): Promise<runtime.ApiResponse<ImageUpload>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageUploadFromJSON(jsonValue));
    }

    /**
     * Send a POST to /api/v1/items/<item id>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving images. This image is used as the card image
     */
    async imageDelete(requestParameters: ImageDeleteRequest): Promise<ImageUpload> {
        const response = await this.imageDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return additionnal languages available of an item
     */
    async languagesRaw(requestParameters: LanguagesRequest): Promise<runtime.ApiResponse<Array<ItemTranslation>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling languages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/languages`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ItemTranslationFromJSON));
    }

    /**
     * Return additionnal languages available of an item
     */
    async languages(requestParameters: LanguagesRequest): Promise<Array<ItemTranslation>> {
        const response = await this.languagesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Mark an item completed.
     */
    async markCompleteRaw(requestParameters: MarkCompleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/mark_complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Mark an item completed.
     */
    async markComplete(requestParameters: MarkCompleteRequest): Promise<any> {
        const response = await this.markCompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * User may mark any library item as incomplete.
     */
    async markIncompleteRaw(requestParameters: MarkIncompleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markIncomplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/mark_incomplete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * User may mark any library item as incomplete.
     */
    async markIncomplete(requestParameters: MarkIncompleteRequest): Promise<any> {
        const response = await this.markIncompleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an item's metadata, and return the result.
     */
    async partialUpdateRaw(requestParameters: PartialUpdateRequest): Promise<runtime.ApiResponse<ItemResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling partialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ItemMetadataUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemResponseFromJSON(jsonValue));
    }

    /**
     * Update an item's metadata, and return the result.
     */
    async partialUpdate(requestParameters: PartialUpdateRequest): Promise<ItemResponse> {
        const response = await this.partialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a Pathway.
     */
    async pathwayRaw(requestParameters: PathwayRequest): Promise<runtime.ApiResponse<PathwayDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathway.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/pathway`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayDetailFromJSON(jsonValue));
    }

    /**
     * Get a Pathway.
     */
    async pathway(requestParameters: PathwayRequest): Promise<PathwayDetail> {
        const response = await this.pathwayRaw(requestParameters);
        return await response.value();
    }

    /**
     * Receive list of ItemMetadata id's to assign to pathway
     * Assign items to pathway.
     */
    async pathwayAssignItemsRaw(requestParameters: PathwayAssignItemsRequest): Promise<runtime.ApiResponse<PathwayDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathwayAssignItems.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling pathwayAssignItems.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/pathway_assign_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayDetailFromJSON(jsonValue));
    }

    /**
     * Receive list of ItemMetadata id's to assign to pathway
     * Assign items to pathway.
     */
    async pathwayAssignItems(requestParameters: PathwayAssignItemsRequest): Promise<PathwayDetail> {
        const response = await this.pathwayAssignItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Clone the specified pathway.
     */
    async pathwayCloneRaw(requestParameters: PathwayCloneRequest): Promise<runtime.ApiResponse<ItemResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathwayClone.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/pathway_clone`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemResponseFromJSON(jsonValue));
    }

    /**
     * Clone the specified pathway.
     */
    async pathwayClone(requestParameters: PathwayCloneRequest): Promise<ItemResponse> {
        const response = await this.pathwayCloneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Partial update of \"items\" is not possible; \"items\" must be a complete list of children. Updating the cover image (\"image\") is optional.  To delete all children, send an empty list of items.
     * Update Pathway content (its list of items and its cover image)
     */
    async pathwayUpdateRaw(requestParameters: PathwayUpdateRequest): Promise<runtime.ApiResponse<PathwayDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pathwayUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling pathwayUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/pathway_update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PathwayUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PathwayDetailFromJSON(jsonValue));
    }

    /**
     * Partial update of \"items\" is not possible; \"items\" must be a complete list of children. Updating the cover image (\"image\") is optional.  To delete all children, send an empty list of items.
     * Update Pathway content (its list of items and its cover image)
     */
    async pathwayUpdate(requestParameters: PathwayUpdateRequest): Promise<PathwayDetail> {
        const response = await this.pathwayUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * An Item is an XBlock or a Pathway.
     * View to get metadata and user_attributes about an item.
     */
    async readRaw(requestParameters: ReadRequest): Promise<runtime.ApiResponse<ItemResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling read.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemResponseFromJSON(jsonValue));
    }

    /**
     * An Item is an XBlock or a Pathway.
     * View to get metadata and user_attributes about an item.
     */
    async read(requestParameters: ReadRequest): Promise<ItemResponse> {
        const response = await this.readRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get lists of recommended/suggested items and items from the same author as a given item.
     */
    async recommendedItemsRaw(requestParameters: RecommendedItemsRequest): Promise<runtime.ApiResponse<RecommendedItems>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling recommendedItems.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.similar !== undefined) {
            queryParameters['similar'] = requestParameters.similar;
        }

        if (requestParameters.moreByAuthor !== undefined) {
            queryParameters['more-by-author'] = requestParameters.moreByAuthor;
        }

        if (requestParameters.moreBySource !== undefined) {
            queryParameters['more-by-source'] = requestParameters.moreBySource;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/recommended_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecommendedItemsFromJSON(jsonValue));
    }

    /**
     * Get lists of recommended/suggested items and items from the same author as a given item.
     */
    async recommendedItems(requestParameters: RecommendedItemsRequest): Promise<RecommendedItems> {
        const response = await this.recommendedItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get translated subject areas. Item API return data from ES. Translated data can't be stored in ES.
     */
    async subjectAreaTranslationsRaw(requestParameters: SubjectAreaTranslationsRequest): Promise<runtime.ApiResponse<Array<Subject>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling subjectAreaTranslations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/items/{id}/subject_area_translations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectFromJSON));
    }

    /**
     * Get translated subject areas. Item API return data from ES. Translated data can't be stored in ES.
     */
    async subjectAreaTranslations(requestParameters: SubjectAreaTranslationsRequest): Promise<Array<Subject>> {
        const response = await this.subjectAreaTranslationsRaw(requestParameters);
        return await response.value();
    }

}
