import * as React from 'react';

import {
    ItemMetadata,
} from 'labxchange-client';
import { getItemAndBlockData } from 'library/utils';
import { Card } from 'library/components';
import { Block } from './Block';
import { BlockData, LoadingState as BlockLoadingState } from './models';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { intl } from '../../../i18n';
import libraryMessages from '../../displayMessages';
import uiMessages from '../../../ui/components/displayMessages';

interface Props {
    itemId: string;
    onLoad?: () => void;
    username?: string;
}

enum Status {
    Loading,
    Ready,
    Error,
}

interface ReadyState {
    status: Status.Ready;
    item: ItemMetadata;
    block: BlockData;
}

interface LoadingState {
    status: Status.Loading;
}

interface ErrorState {
    status: Status.Error;
    errorStatus: number;
}

type State = ReadyState | LoadingState | ErrorState;

const initialState: LoadingState = {
    status: Status.Loading,
};

export class EmbeddedBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = initialState;
    }

    public async componentDidMount() {
        this.setState(initialState, () => this.loadItemMetadata());
    }

    private async loadItemMetadata() {
        const response = await getItemAndBlockData(this.props.itemId);
        if (response.blockData.loadingState === BlockLoadingState.READY) {
            this.setState({
                status: Status.Ready,
                block: response.blockData,
                item: response.itemData.metadata,
            });
        } else {
            this.setState({
                status: Status.Error,
                errorStatus: response.statusCode,
            });
        }
        if (this.props.onLoad !== undefined) {
            this.props.onLoad();
        }
    }

    public render() {
        if (this.state.status === Status.Loading) {
            return (
                <div className='embedded-block loading'>
                    <Card showSkeleton={true} />
                </div>
            );
        } else if (this.state.status === Status.Ready) {
            return (
                <div className='embedded-block ready'>
                    <Block
                        blockData={this.state.block}
                        itemMetadata={this.state.item}
                        onExpandBlock={() => {/* empty because we don't want to do anything */}}
                        username={this.props.username}
                    />
                </div>
            );
        } else {
            return (
                <div className='embedded-block error'>
                    <RegistrationGate
                        title={intl.formatMessage(libraryMessages.uiPrivateAssetAlertTitle)}
                        body={intl.formatMessage(libraryMessages.uiPrivateAssetAlertBody)}
                        primaryButtonText={uiMessages.uiSignUp}
                        secondaryButtonText={uiMessages.uiSignIn}
                        image='/assets/images/access.svg'
                        contentInBody={true}
                        hideLayout={true}
                    />
                </div>
            );
        }
    }
}
