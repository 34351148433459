import { bind } from 'bind-decorator';
import { DiscussionsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { ConversationStatusReasonEnum, Profile } from 'labxchange-client';
import * as React from 'react';
import { Modal, ModalConfirmation } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import userMessages from 'user/components/displayMessages';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';
import {
    MessageComposeModal,
} from './MessageComposeModal';

interface ReceiverDirectMessageDisabledModalProps {
    userProfile: Profile;
    onClose(): void;
}

export class ReceiverDirectMessageDisabledModal extends React.PureComponent<
    ReceiverDirectMessageDisabledModalProps> {
    public render() {
        return (
            <Modal
                onRequestClose={this.props.onClose}
                content={
                    <>
                        <div className='warning-icon'></div>
                        <div className='title-text'>
                            <WrappedMessage
                                message={messages.errorReceiverDirectMessagesDisabled}
                                values={{ name: this.props.userProfile.username }}
                            />
                        </div>
                    </>
                }
                footer={
                    <>
                        <button
                            className='primary-button'
                            onClick={this.props.onClose}>
                            <WrappedMessage message={uiMessages.uiOk} />
                        </button>
                    </>
                }
            />
        );
    }
}

interface SenderDirectMessageDisabledModalProps {
    userProfile: Profile;
    history: any;
    ownUsername: string;
    onClose(): void;
}

export class SenderDirectMessageDisabledModal extends React.PureComponent<
    SenderDirectMessageDisabledModalProps
> {
    public render() {
        return (
            <ModalConfirmation
                title={messages.errorSenderDirectMessagesDisabled}
                titleValues={{ name: this.props.userProfile.username }}
                confirmButtonText={userMessages.updateProfileButtonLabel}
                cancelButtonText={userMessages.cancelButtonLabel}
                onCanceled={this.props.onClose}
                onConfirmed={this.onSubmit}
            />
        );
    }

    @bind private onSubmit() {
        this.props.onClose();
        this.props.history.push(ROUTES.Users.PROFILE_SLUG(this.props.ownUsername));
    }
}

export enum MessageModalState {
    None = 0,
    SenderDirectMessageDisabledError,
    ReceiverDirectMessageDisabledError,
    MessageComposer,
}

interface RenderModalsProps {
    modalType: MessageModalState;
    userProfile: Profile;
    history: any;
    ownUsername: string;
    onClose(): void;
}

// Below are helper functions, slightly messy, but done this way to facilitate
// reusing this code between all the various places where there are buttons to
// message a user.

export const RenderModals: React.FC<RenderModalsProps> = (props) => {
    switch (props.modalType) {
        case MessageModalState.SenderDirectMessageDisabledError:
            return (
                <SenderDirectMessageDisabledModal
                    userProfile={props.userProfile}
                    onClose={props.onClose}
                    history={props.history}
                    ownUsername={props.ownUsername}
                />
            );
        case MessageModalState.ReceiverDirectMessageDisabledError:
            return (
                <ReceiverDirectMessageDisabledModal
                    userProfile={props.userProfile}
                    onClose={props.onClose}
                />
            );
        case MessageModalState.MessageComposer:
            return (
                <MessageComposeModal
                    userProfile={props.userProfile}
                    onClose={props.onClose}
                />
            );
        default:
            return null;
    }
};

export async function actionCanMessage(username: string, history: any): Promise<MessageModalState> {
    try {
        const info = await DiscussionsApi.conversationsCheckCanMessage({ id: username });
        if (info.allowed) {
            if (info.reason === ConversationStatusReasonEnum.NewTopicOk) {
                // We're allowed to send a message, but it needs to be a
                // message request.
                return MessageModalState.MessageComposer;
            } else {
                // We can directly send a message without requesting first;
                // redirect directly to the messaging page for this
                // recipient.
                history.push(ROUTES.Messages.HOME + `?recipient=${username}`);
                return MessageModalState.None;
            }
        } else {
            if (info.reason === ConversationStatusReasonEnum.SenderMessagesDisabled) {
                return MessageModalState.SenderDirectMessageDisabledError;
            } else if (info.reason === ConversationStatusReasonEnum.ReceiverMessagesDisabled) {
                return MessageModalState.ReceiverDirectMessageDisabledError;
            } else {
                showErrorMessage(
                    <WrappedMessage message={messages.failedSendingWithReason} values={{ reason: info.reason }} />, {
                    confirmText: uiMessages.uiConfirmationButton,
                });
                return MessageModalState.None;
            }
        }
    } catch (err) {
        showErrorMessage(<WrappedMessage message={messages.errorCheckingIfCanMessage} />, { exception: err });
        return MessageModalState.None;
    }
}
