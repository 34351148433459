// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    PeopleAppliedFilter,
    PeopleAppliedFilterFromJSON,
    PeopleAppliedFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface PeopleSearchRequestData
 */
export interface PeopleSearchRequestData {
    /**
     * 
     * @type {string}
     * @memberof PeopleSearchRequestData
     */
    keywords?: string;
    /**
     * If provided, performs a wildcard match on the profile full_name_lower and username_lower keyword fields.
     * @type {string}
     * @memberof PeopleSearchRequestData
     */
    wildcardNames?: string;
    /**
     * 
     * @type {Array<PeopleAppliedFilter>}
     * @memberof PeopleSearchRequestData
     */
    filters?: Array<PeopleAppliedFilter>;
    /**
     * 
     * @type {string}
     * @memberof PeopleSearchRequestData
     */
    ordering?: PeopleSearchRequestDataOrderingEnum;
    /**
     * 
     * @type {number}
     * @memberof PeopleSearchRequestData
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PeopleSearchRequestData
     */
    paginationSize?: number;
}

export function PeopleSearchRequestDataFromJSON(json: any): PeopleSearchRequestData {
    return {
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'wildcardNames': !exists(json, 'wildcard_names') ? undefined : json['wildcard_names'],
        'filters': !exists(json, 'filters') ? undefined : (json['filters'] as Array<any>).map(PeopleAppliedFilterFromJSON),
        'ordering': !exists(json, 'ordering') ? undefined : json['ordering'],
        'currentPage': !exists(json, 'current_page') ? undefined : json['current_page'],
        'paginationSize': !exists(json, 'pagination_size') ? undefined : json['pagination_size'],
    };
}

export function PeopleSearchRequestDataToJSON(value?: PeopleSearchRequestData): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'keywords': value.keywords,
        'wildcard_names': value.wildcardNames,
        'filters': value.filters === undefined ? undefined : (value.filters as Array<any>).map(PeopleAppliedFilterToJSON),
        'ordering': value.ordering,
        'current_page': value.currentPage,
        'pagination_size': value.paginationSize,
    };
}

/**
* @export
* @enum {string}
*/
export enum PeopleSearchRequestDataOrderingEnum {
    Relevance = 'relevance',
    NameAtoz = 'name-atoz',
    NameZtoa = 'name-ztoa',
    RecentlyJoined = 'recently-joined'
}


